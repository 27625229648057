import * as React from 'react';

import { OpenTokVideoContent, VideoSource } from '@models/Activity/ActivityContent';
import { ActivityBuilderMode } from '@models/Activity/ActivityMode';

import OpentokRecorder from '@components/OpentokRecorder';

interface RecordVideoContentProps {
   item: OpenTokVideoContent<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<OpenTokVideoContent<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const RecordVideoContent: React.FC<RecordVideoContentProps> = ({ item, handleItemUpdate }) => {
   const handleRecordingComplete = (sessionId: number, url: string): void => {
      const update: Partial<OpenTokVideoContent<ActivityBuilderMode>> = {
         ...item,
         fileUrl: url,
         sessionId,
         source: VideoSource.openTok,
      };
      handleItemUpdate(update);
   };

   return (
      <div className='flex-1'>
         <OpentokRecorder
            onRecordingComplete={handleRecordingComplete}
            className='flex-1 max-width-720'
         />
      </div>
   );
};

export default RecordVideoContent;
