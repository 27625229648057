import * as React from 'react';

import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import { CourseProfile } from '@models/Course';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import CourseworkTable from './CourseworkTable';
import { UserCoursework } from './EnrollmentOverview';
import { GradebookSettings } from './Gradebook/Gradebook';

interface MyGradesResponse {
   course: CourseProfile;
   coursework: readonly UserCoursework[];
   grade: number;
   gradebookSettings: GradebookSettings;
   school: {
      id: number;
      name: string;
   };
}

const MyGrades: React.FC = () => {
   const {
      routes: {
         courses: { dashboard, myGrades },
      },
   } = Constants;

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [course, setCourse] = React.useState<Maybe<{ id: number; name: string }>>(null);
   const [coursework, setCoursework] = React.useState<readonly UserCoursework[]>([]);
   const [grade, setGrade] = React.useState<Maybe<number>>(null);
   const [gradebookSettings, setGradebookSettings] = React.useState<Maybe<GradebookSettings>>(null);
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [filteredAssignmentIds, setFilteredAssignmentIds] = React.useState<readonly number[]>([]);

   const { courseId } = useParams<{ courseId: string }>();

   React.useEffect(() => {
      if (courseId !== null) {
         fetchCoursework();
      }
   }, [courseId]);

   React.useEffect(() => {
      if (!isFetching) {
         calculateFilteredAssignmentIds();
      }
   }, [isFetching, coursework, searchQuery]);

   const fetchCoursework = (): void => {
      setIsFetching(true);
      if (!courseId) {
         return;
      }
      HttpService.getWithAuthToken<MyGradesResponse>(`/api/courses/${courseId}/grades`).then(
         (response) => {
            const { data } = response;
            setCourse(data.course);
            setGrade(data.grade);
            setGradebookSettings(data.gradebookSettings);
            setCoursework(data.coursework);
            setIsFetching(false);
            setBreadcrumbs({
               breadcrumbs: [
                  {
                     link: dashboard.replace(':courseId', courseId),
                     text: data.course.name,
                     contextInfo: { courseId },
                  },
                  { link: myGrades.replace(':courseId', courseId), text: 'Grades' },
               ],
               next: null,
               prev: null,
            });
         },
      );
   };

   const calculateFilteredAssignmentIds = (): void => {
      const filterSearchQuery = (i: UserCoursework): boolean =>
         !searchQuery || i.itemName.toLowerCase().includes(searchQuery.toLowerCase());

      const updatedFilteredAssignmentIds = coursework
         .filter((i) => filterSearchQuery(i))
         .map(({ moduleItemId }) => moduleItemId);

      setFilteredAssignmentIds(updatedFilteredAssignmentIds);
   };

   const getGradeClass = (): string => {
      let color = '';
      if (typeof grade === 'number' && gradebookSettings) {
         if (grade > gradebookSettings.green.min) {
            color = 'green';
         } else if (grade > gradebookSettings.yellow.min) {
            color = 'yellow';
         } else {
            color = 'red';
         }
      }
      return classnames('overall-grade', 'enrollment-overview', color);
   };

   const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value);
   };

   if (isFetching) {
      return <Loader />;
   }

   const filteredCoursework = coursework.filter((i) =>
      filteredAssignmentIds.includes(i.moduleItemId),
   );

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>{course ? `Grades - ${course.name}` : 'Loading...'}</DocumentTitle>
         <div className='row'>
            <div className='col-xs-12'>
               <div className='card no-padding'>
                  <div className='card-title has-button'>
                     <div className='flex items-center'>
                        <div className='title'>Coursework</div>
                        <input
                           name='search'
                           type='search'
                           value={searchQuery}
                           onChange={handleSearchQueryChange}
                        />
                     </div>
                     <div className='overall-grade-wrapper'>
                        <span className={getGradeClass()}>
                           {typeof grade === 'number' ? `${grade.toFixed(1)}%` : '-'}
                        </span>
                     </div>
                  </div>
                  <CourseworkTable
                     coursework={filteredCoursework}
                     requestor={AccountType.student}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default MyGrades;
