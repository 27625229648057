import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import Appearance from '@models/Appearance';
import CourseService from '@services/CourseService';
import Tippy from '@tippyjs/react';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import ModalDialog from '@components/Core/ModalDialog';
import ExtendedCourseProfile from './ExtendedCourseProfile';

interface CourseActionsCellProps {
   row: ExtendedCourseProfile;
   onUpdate(row: ExtendedCourseProfile): void;
   onDelete(): void;
}

const CourseActionsCell: React.FC<CourseActionsCellProps> = ({ row, onDelete }) => {
   const {
      routes: {
         courses: { edit: editCourse },
      },
   } = Constants;

   const [isDeleteCourseModalOpen, setIsDeleteCourseModalOpen] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   const closeDeleteModal = (): void => {
      setIsDeleteCourseModalOpen(false);
   };

   const openDeleteCourseModal = (): void => {
      setIsDeleteCourseModalOpen(true);
   };

   const handleDeleteCourse = (): Promise<void> => {
      setIsLoading(true);
      return CourseService.deleteCourse(row.id).then(() => {
         setIsLoading(false);
         setIsDeleteCourseModalOpen(false);
         onDelete();
      });
   };

   return (
      <>
         <div className='table-row-action'>
            <div className='icon-action-wrap'>
               <Tippy content='Edit' delay={[400, 0]}>
                  <div className='action-icon'>
                     <Link
                        to={editCourse.replace(':courseId', row.id.toString())}
                        className='icon-action'
                     >
                        <IconHighlight />
                     </Link>
                  </div>
               </Tippy>
               <Tippy content='Delete' delay={[400, 0]}>
                  <div className='icon-action' onClick={openDeleteCourseModal}>
                     <IconBin />
                  </div>
               </Tippy>
            </div>
         </div>
         {isDeleteCourseModalOpen && (
            <ModalDialog
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               heading='Delete Course'
               onClose={closeDeleteModal}
               actions={[
                  {
                     text: 'Delete',
                     onClick: handleDeleteCourse,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closeDeleteModal },
               ]}
            >
               <p>Are you sure that you want to delete {row.name}?</p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
      </>
   );
};

export default CourseActionsCell;
