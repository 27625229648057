import * as _ from 'lodash';
import * as React from 'react';
import * as VideoSearchHelpers from './helpers';

import IconClose from '@icons/nova-solid/02-Status/close.svg';
import IconFilmStripOff from '@icons/videos/film-strip-off.svg';
import IconFilmStrip from '@icons/videos/film-strip.svg';
import Language, { isLanguage, LanguageLookup } from '@models/Language';
import { BasicLevelConstants, BasicLevelName, isBasicLevel } from '@models/Proficiency';
import {
   InterviewQuestionSummary,
   Video,
   VideoFeature,
   VideoFeatureCollection,
   VideoSummary,
} from '@models/Video';
import VideoService from '@services/VideoService';
import classnames from 'classnames';
import { Feature, Geometry } from 'geojson';
import pluralize from 'pluralize';

import EmptyState from '@components/Core/EmptyState/EmptyState';
import Loader from '@components/Loader';
import { StringSelection } from './';
import VideoSearchResult from './VideoSearchResult';

import { FuseResult } from 'fuse.js';

const defaultSearchString: StringSelection = {
   isSelection: false,
   value: '',
};

type Props = {
   interviewQuestionResults: FuseResult<InterviewQuestionSummary>[];
   isLoadingResults: boolean;
   mapFeatureCollection: VideoFeatureCollection;
   searchString?: StringSelection;
   selectedClusterVideoFeatures?: VideoFeature[];
   selectedLanguage?: Language;
   selectedLevel?: BasicLevelName;
   videoSearchResults: Video[];
   onVideoResultClick(video: Video): void;
   onSearchStringChange(searchString: StringSelection): void;
   setInterviewQuestionResults(
      interviewQuestionSearchResult: FuseResult<InterviewQuestionSummary>[],
   ): void;
   setIsLoadingResults(isLoadingResults: boolean): void;
   setMapFeatureCollection(videoFeatureCollectio: VideoFeatureCollection): void;
   setSelectedLanguage(language?: Language): void;
   setSelectedLevel(level?: BasicLevelName): void;
   setVideoSearchResults(videos: Video[]): void;
   unselectCluster(): void;
   getFilteredVideoSummaryMapResults(
      interviewQuestion?: StringSelection,
      language?: Language,
      level?: BasicLevelName,
   ): Feature<Geometry, VideoSummary>[];
};

const VideoSearchBar: React.FC<Props> = ({
   interviewQuestionResults,
   isLoadingResults,
   mapFeatureCollection,
   searchString,
   selectedClusterVideoFeatures,
   selectedLanguage,
   selectedLevel,
   videoSearchResults,
   getFilteredVideoSummaryMapResults,
   onSearchStringChange: propsOnSearchStringChange,
   onVideoResultClick,
   setInterviewQuestionResults,
   setIsLoadingResults,
   setMapFeatureCollection,
   setSelectedLanguage,
   setSelectedLevel,
   setVideoSearchResults,
   unselectCluster,
}) => {
   const videoSummaries =
      VideoSearchHelpers.getInterviewQuestionSummaryFromVideoFeatureCollection(
         mapFeatureCollection,
      );
   const videoIndex = VideoSearchHelpers.getIndex(videoSummaries);
   const availableLanguages = _.uniq(
      mapFeatureCollection.features.map((x) => x.properties.language),
   );
   const hasClusterClicked =
      (selectedClusterVideoFeatures && selectedClusterVideoFeatures?.length > 0) || false;
   const hasInterviewQuestionSelected = (searchString && searchString.isSelection) || false;

   React.useEffect(() => {
      if (selectedClusterVideoFeatures) {
         setIsLoadingResults(true);
         debouncedFetchFilteredVideoResults(
            selectedClusterVideoFeatures,
            undefined,
            selectedLanguage,
            selectedLevel,
         );
      }
   }, [selectedClusterVideoFeatures]);

   const search = (query?: string, language?: Language, level?: BasicLevelName) => {
      if (query === undefined) return;

      const results = VideoSearchHelpers.search(videoIndex, query, videoSummaries, language, level);
      setInterviewQuestionResults(results);
   };

   const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (videoSearchResults.length > 0) {
         setVideoSearchResults([]);

         onFilterUpdate(
            selectedClusterVideoFeatures,
            defaultSearchString,
            selectedLanguage,
            selectedLevel,
         );
      }

      const newSearchString = e.target.value;
      propsOnSearchStringChange({ isSelection: false, value: newSearchString });
      search(newSearchString, selectedLanguage, selectedLevel);
   };

   // This function is more for the listed video results after a search has been made and video results are listed in the result section.
   const fetchFilteredVideoResults = async (
      clusterFeatures?: VideoFeature[],
      interviewQuestion?: StringSelection,
      language?: Language,
      level?: BasicLevelName,
   ): Promise<void> => {
      const clusterVideoIds = clusterFeatures?.map((x) => x.properties.videoId);
      const interviewResult = videoSummaries.find((x) =>
         interviewQuestion?.isSelection ? x.interviewQuestion === interviewQuestion?.value : false,
      );

      // You must either have a cluster or interview question selected to see results
      if (interviewResult === undefined && clusterVideoIds === undefined) {
         setVideoSearchResults([]);
         return;
      }

      const filteredVideos = mapFeatureCollection.features.filter(
         (x) =>
            (interviewQuestion?.isSelection
               ? x.properties.interviewQuestion === interviewQuestion?.value
               : true) &&
            (clusterVideoIds ? clusterVideoIds.includes(x.properties.videoId) : true) &&
            (language ? x.properties.language === language : true) &&
            (level ? x.properties.level === level : true),
      );

      const videoIds = filteredVideos.map((x) => x.properties.videoId);

      if (videoIds.length === 0) {
         setVideoSearchResults([]);
         return;
      }

      setIsLoadingResults(true);
      const videos = await VideoService.getAllSummaries(videoIds);
      setIsLoadingResults(false);
      setVideoSearchResults(videos);
   };

   const debouncedFetchFilteredVideoResults = React.useCallback(
      _.debounce(fetchFilteredVideoResults, 100),
      [],
   );

   const onFilterUpdate = async (
      clusterFeatures?: VideoFeature[],
      interviewQuestion?: StringSelection,
      language?: Language,
      level?: BasicLevelName,
   ): Promise<void> => {
      unselectCluster();
      const filteredResults = getFilteredVideoSummaryMapResults(interviewQuestion, language, level);

      setMapFeatureCollection({
         type: 'FeatureCollection',
         features: filteredResults,
      });

      await debouncedFetchFilteredVideoResults(clusterFeatures, interviewQuestion, language, level);
   };

   const onLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const language = isLanguage(e.target.value) ? e.target.value : undefined;
      setSelectedLanguage(language);
      search(searchString?.value, language, selectedLevel);
      onFilterUpdate(selectedClusterVideoFeatures, searchString, language, selectedLevel);
   };

   const clearLanguage = () => {
      setSelectedLanguage(undefined);
      search(searchString?.value, undefined, selectedLevel);
      onFilterUpdate(selectedClusterVideoFeatures, searchString, undefined, selectedLevel);
   };

   const onLevelChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const level = isBasicLevel(e.target.value) ? e.target.value : undefined;
      setSelectedLevel(level);
      search(searchString?.value, selectedLanguage, level);
      onFilterUpdate(selectedClusterVideoFeatures, searchString, selectedLanguage, level);
   };

   const clearLevel = () => {
      setSelectedLevel(undefined);
      search(searchString?.value, selectedLanguage, undefined);
      onFilterUpdate(selectedClusterVideoFeatures, searchString, selectedLanguage, undefined);
   };

   const onInterviewQuestionClick = async (
      interviewQuestionSummary: InterviewQuestionSummary,
   ): Promise<void> => {
      const nextInterviewQuestion = {
         isSelection: true,
         value: interviewQuestionSummary.interviewQuestion,
      };
      propsOnSearchStringChange(nextInterviewQuestion);
      onFilterUpdate(
         selectedClusterVideoFeatures,
         nextInterviewQuestion,
         selectedLanguage,
         selectedLevel,
      );
   };

   const renderEmptyState = (): React.ReactNode => (
      <div className='empty-video-results'>
         <EmptyState
            icon={<IconFilmStripOff className='large' aria-hidden />}
            heading='No Results'
         />
      </div>
   );

   const renderInterviewQuestionResults = (): React.ReactNode => {
      // If there is an interview question selected do not render interview questions
      if (searchString?.isSelection) return null;

      // If there is loading render the loader
      if (isLoadingResults) return <Loader />;

      // If there are no results render the empty state
      if (interviewQuestionResults.length === 0 && searchString?.value !== '') {
         return renderEmptyState();
      }

      return interviewQuestionResults.map((x) => (
         <div
            className='prompt-result-row'
            key={x.item.interviewQuestion}
            onClick={() => onInterviewQuestionClick(x.item)}
         >
            <IconFilmStrip />
            <div className='prompt'>{x.item.interviewQuestion}</div>
            <div className='video-count'>
               <strong>{x.item.videoSummaries.length}</strong>{' '}
               {pluralize('video', x.item.videoSummaries.length)}
            </div>
         </div>
      ));
   };

   const renderVideoResults = (): React.ReactNode => {
      if (isLoadingResults) {
         return <Loader />;
      } else if (videoSearchResults === undefined || videoSearchResults.length === 0) {
         return renderEmptyState();
      } else {
         return videoSearchResults.map((x) => (
            <VideoSearchResult key={x.id} video={x} onClick={() => onVideoResultClick(x)} />
         ));
      }
   };

   const showVideoResults = hasInterviewQuestionSelected || hasClusterClicked || false;

   return (
      <div className='video-control-panel'>
         <div className='half max-width-400'>
            <input
               className='search-box'
               onChange={onSearchStringChange}
               placeholder='Search for videos by question'
               type='search'
               value={searchString?.value}
            />
            <div
               className={classnames('results margin-top-sm', {
                  'video-results': showVideoResults,
                  'interview-results': !showVideoResults,
               })}
            >
               {showVideoResults ? renderVideoResults() : renderInterviewQuestionResults()}
            </div>
         </div>
         <div className='select-wrapper'>
            {selectedLanguage && <IconClose onClick={clearLanguage} className='clear-select' />}
            <select
               name='level'
               value={selectedLanguage ?? ''}
               onChange={onLanguageChange}
               className={classnames({ selected: selectedLanguage })}
            >
               <option value={''}>Language...</option>
               {availableLanguages.map((x) => (
                  <option key={x} value={x}>
                     {LanguageLookup[x]}
                  </option>
               ))}
            </select>
         </div>
         <div className='select-wrapper'>
            {selectedLevel && <IconClose onClick={clearLevel} className='clear-select' />}
            <select
               name='level'
               value={selectedLevel ?? ''}
               onChange={onLevelChange}
               className={classnames({ selected: selectedLevel })}
            >
               <option value={''}>Level...</option>
               {BasicLevelConstants.map((x) => (
                  <option key={x} value={x}>
                     {x}
                  </option>
               ))}
            </select>
         </div>
      </div>
   );
};

export default VideoSearchBar;
