import * as _ from 'lodash';
import * as Mousetrap from 'mousetrap';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { AnnotationType, AudioAnnotation, AudioRecordingResponse } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { MessageResponse } from '@models/Core';
import { uploadAudio } from '@services/AssetService';
import HttpService from '@services/HttpService';
import { diff } from 'deep-object-diff';

import { AppStateContext } from '../../../../AppState';
import { NewAudioAnnotation } from './Annotation';
import AudioWaveform, { AudioWaveformHandles } from './AudioWaveform';

interface RecordingContentProps {
   isActive: boolean;
   responseId: number;
   response: AudioRecordingResponse;
   updateResponse(update: Partial<AudioRecordingResponse>): Promise<void>;
}

const RecordingPrompt: React.FC<RecordingContentProps> = ({
   isActive,
   responseId,
   response: { fileUrl, instructorAnnotations, annotationAuthors },
   updateResponse,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { id: userId, firstName, lastName, profileImageUrl, email } = userProfile;

   const audioWaveformRef = React.useRef<AudioWaveformHandles>(null);
   const [isWaveformRendered, setIsWaveformRendered] = React.useState<boolean>(false);

   // FIXME: Doesn't always work because isActive doesn't rerender it.
   React.useEffect(() => {
      Mousetrap.bind('space', handleSpace);
      return () => {
         Mousetrap.unbind('space');
      };
   }, [isWaveformRendered, isActive]);

   React.useEffect(() => {
      if (isActive && !isWaveformRendered && audioWaveformRef.current) {
         audioWaveformRef.current.loadWaveform();
      }
   }, [isActive, isWaveformRendered]);

   const getAuthor = (createdBy?: number): BasicUserProfile =>
      createdBy && createdBy !== userId
         ? annotationAuthors[createdBy]
         : { id: userId, firstName, lastName, profileImageUrl, email };

   const handleDeleteAnnotation = (annotationId: string | number): Promise<void> => {
      updateResponse({
         instructorAnnotations: instructorAnnotations.filter((i) => i.id !== annotationId),
      });
      return HttpService.deleteWithAuthToken<MessageResponse>(
         `/api/activities/responses/${responseId}/annotations/${annotationId}`,
      ).then();
   };

   const isExistingAudioAnnotation = (
      annotation: AudioAnnotation | NewAudioAnnotation,
   ): annotation is AudioAnnotation => typeof annotation.id === 'number';

   const formatAnnotationTime = <T extends AudioAnnotation | NewAudioAnnotation>(
      annotation: T,
   ): T =>
      ({
         ...annotation,
         start: +annotation.start.toFixed(3),
         end: +annotation.end.toFixed(3),
      }) as T;

   const formatAnnotationData = (
      annotation: AudioAnnotation,
      audioUrl: string,
      storedAudioFilename: string,
   ): AudioAnnotation => ({
      ...formatAnnotationTime(annotation),
      audioUrl,
      storedAudioFilename,
      blob: undefined,
   });

   const updateLocalAnnotations = (updatedAnnotation: AudioAnnotation) =>
      instructorAnnotations.map((i) => (i.id === updatedAnnotation.id ? updatedAnnotation : i));

   const handleSaveAnnotation = async (annotation: AudioAnnotation | NewAudioAnnotation) => {
      let audioUrl = annotation.audioUrl;
      let storedAudioFilename = '';

      if (annotation.blob) {
         const uploadResult = await uploadAudio(annotation.blob);
         storedAudioFilename = uploadResult.filename;
         audioUrl = uploadResult.url;
      }

      if (isExistingAudioAnnotation(annotation)) {
         return updateExistingAnnotation(annotation, audioUrl, storedAudioFilename);
      } else {
         return createNewAnnotation(annotation, audioUrl, storedAudioFilename);
      }
   };

   const updateExistingAnnotation = async (
      annotation: AudioAnnotation,
      audioUrl: string,
      storedAudioFilename: string,
   ): Promise<{ id: number } | void> => {
      const existingAnnotation = instructorAnnotations.find((i) => i.id === annotation.id);
      if (!existingAnnotation) {
         return Promise.reject(new Error('Annotation not found'));
      }
      const updatedAnnotation = formatAnnotationData(annotation, audioUrl, storedAudioFilename);
      const annotationDiff = _.omit(diff(existingAnnotation, updatedAnnotation), [
         'audioUrl',
         'blob',
      ]);
      if (!annotationDiff) {
         return Promise.resolve();
      }

      await HttpService.patchWithAuthToken<MessageResponse>(
         `/api/activities/responses/${responseId}/annotations/${annotation.id}`,
         snakeCaseKeys(annotationDiff),
      );

      updateResponse({
         instructorAnnotations: updateLocalAnnotations(updatedAnnotation),
      });

      return { id: annotation.id };
   };

   const createNewAnnotation = async (
      annotation: NewAudioAnnotation,
      audioUrl: string,
      storedAudioFilename: string,
   ): Promise<{ id: number }> => {
      const formattedAnnotation: NewAudioAnnotation = {
         ...formatAnnotationTime(annotation),
         audioUrl,
         storedAudioFilename,
         annotationType: AnnotationType.audio,
         id: null,
         blob: undefined,
         comments: annotation.comments,
      };

      const response = await HttpService.postWithAuthToken<{
         msg: string;
         annotation: AudioAnnotation;
      }>(`/api/activities/responses/${responseId}/annotations`, snakeCaseKeys(formattedAnnotation));

      const { id, createdBy, createdOn } = response.data.annotation;

      updateResponse({
         instructorAnnotations: [
            ...instructorAnnotations,
            {
               ...formattedAnnotation,
               id,
               createdBy,
               createdOn,
            },
         ],
      });

      return { id };
   };

   const handleSpace = (event: Mousetrap.ExtendedKeyboardEvent): void => {
      event.preventDefault();
      if (isActive) {
         audioWaveformRef.current?.playPause();
      }
   };

   return (
      <div className='row'>
         <div className='col-xs-12'>
            {fileUrl ? (
               <AudioWaveform
                  annotations={instructorAnnotations}
                  ref={audioWaveformRef}
                  audioFileSource={fileUrl}
                  canEdit
                  getAuthor={getAuthor}
                  loadWaveformOnMount={false}
                  onDeleteAnnotation={handleDeleteAnnotation}
                  onSaveAnnotation={handleSaveAnnotation}
                  onWaveformRendered={() => setIsWaveformRendered(true)}
               />
            ) : (
               <p>
                  <i>No Recording</i>
               </p>
            )}
         </div>
      </div>
   );
};

export default React.memo(RecordingPrompt);
