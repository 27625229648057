// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import IconLayout1 from '@icons/nova-line/54-Alignment/layout-1.svg';
import classnames from 'classnames';
import { diff } from 'deep-object-diff';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@components/Common/Button';
import Switch from '@components/Common/Switch';
import Droplist from '@components/Core/Droplist';

export enum GradingStatus {
   graded = 'graded',
   ungraded = 'ungraded',
}

enum CompletionStatus {
   notStarted = 'not-started',
   inProgress = 'in-progress',
   complete = 'complete',
}

export interface SubmissionParamFilters {
   grades: readonly string[] | string;
   completions: readonly string[] | string;
   sections: readonly (string | number)[] | string;
   columns: readonly string[] | string;
   name: string;
}

export type Filter<T> = {
   id: T;
   name: string;
   show: boolean;
};

export type FilterIdTypes = number | string | CompletionStatus | GradingStatus;

export interface IActivitySubmissionsFilters {
   columnFilters: readonly Filter<string>[];
   completionFilters: readonly Filter<CompletionStatus>[];
   gradingFilters: readonly Filter<GradingStatus>[];
   nameFilter: string;
   sectionFilters: readonly Filter<number>[];
}

interface ActiveActivitySubmissionsFilters {
   hasColumnFilters: boolean;
   hasCompletionFilters: boolean;
   hasGradingFilters: boolean;
   hasSectionFilters: boolean;
}

interface ActivitySubmissionsFiltersProps {
   children?: React.ReactNode;
   columnFilters: readonly Filter<string>[];
   sectionFilters: readonly Filter<number>[];
   filter(filters: IActivitySubmissionsFilters): void;
}

const ActivitySubmissionsFilters: React.FC<ActivitySubmissionsFiltersProps> = (props) => {
   const navigate = useNavigate();
   const location = useLocation();
   const defaultFilters: IActivitySubmissionsFilters = {
      columnFilters: props.columnFilters,
      sectionFilters: props.sectionFilters,
      gradingFilters: [
         { id: GradingStatus.graded, show: true, name: 'Graded' },
         { id: GradingStatus.ungraded, show: true, name: 'Ungraded' },
      ],
      completionFilters: [
         { id: CompletionStatus.notStarted, show: true, name: 'Not Started' },
         { id: CompletionStatus.inProgress, show: true, name: 'In Progress' },
         { id: CompletionStatus.complete, show: true, name: 'Complete' },
      ],
      nameFilter: '',
   };
   const [activeFilters, setActiveFilters] = React.useState<ActiveActivitySubmissionsFilters>({
      hasColumnFilters: false,
      hasSectionFilters: false,
      hasGradingFilters: false,
      hasCompletionFilters: false,
   });
   const checkUrlParams = React.useRef<boolean>(true);
   const [state, setState] = React.useState<IActivitySubmissionsFilters>(defaultFilters);

   const onCompletionFiltersChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked, id } = event.target;
      setState((prevState) => ({
         ...prevState,
         completionFilters: prevState.completionFilters.map((i) =>
            i.id === id ? { ...i, show: checked } : i,
         ),
      }));
   };

   const onGradedFiltersChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked, id } = event.target;
      setState((prevState) => ({
         ...prevState,
         gradingFilters: prevState.gradingFilters.map((i) =>
            i.id === id ? { ...i, show: checked } : i,
         ),
      }));
   };

   const onSectionsFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { id, checked } = event.target;
      setState((prevState) => ({
         ...prevState,
         sectionFilters: prevState.sectionFilters.map((i) =>
            i.id.toString() === id ? { ...i, show: checked } : i,
         ),
      }));
   };

   const onShowColumnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { id, checked } = event.target;
      setState((prevState) => ({
         ...prevState,
         columnFilters: prevState.columnFilters.map((i) =>
            i.id === id ? { ...i, show: checked } : i,
         ),
      }));
   };

   const updateUrlParams = (diffFromDefault: Partial<IActivitySubmissionsFilters>): void => {
      // Get and save any params that could be used somewhere else in the site
      const filterParamsForPage: SubmissionParamFilters = {
         grades: [],
         columns: [],
         completions: [],
         sections: [],
         name: '',
      };
      const currentParams = _.omit(
         qs.parse(location.search, { ignoreQueryPrefix: true }),
         Object.keys(filterParamsForPage),
      );

      const filters: Partial<SubmissionParamFilters> = {
         ...(diffFromDefault?.gradingFilters && {
            grades: state.gradingFilters.filter((i) => i.show).map((i) => i.id),
         }),
         ...(diffFromDefault?.completionFilters && {
            completions: state.completionFilters.filter((i) => i.show).map((i) => i.id),
         }),
         ...(diffFromDefault?.sectionFilters && {
            sections: state.sectionFilters.filter((i) => i.show).map((i) => i.id),
         }),
         ...(diffFromDefault?.columnFilters && {
            columns: state.columnFilters.filter((i) => i.show).map((i) => i.id),
         }),
         ...(diffFromDefault?.nameFilter && { name: state.nameFilter }),
         ...currentParams,
      };

      navigate(
         {
            pathname: location.pathname,
            search: qs.stringify(filters, { indices: false }),
         },
         { replace: true },
      );
   };

   React.useEffect(() => {
      props.filter(state);
      if (checkUrlParams.current) {
         setStateFromParams();
         checkUrlParams.current = false;
      } else {
         const diffFromDefault: Partial<IActivitySubmissionsFilters> = diff(defaultFilters, state);
         setActiveFilters({
            hasColumnFilters: !!diffFromDefault?.columnFilters,
            hasSectionFilters: !!diffFromDefault?.sectionFilters,
            hasGradingFilters: !!diffFromDefault?.gradingFilters,
            hasCompletionFilters: !!diffFromDefault?.completionFilters,
         });
         updateUrlParams(diffFromDefault);
      }
   }, [state]);

   const setStateFromParams = (): void => {
      const filterParams: Partial<SubmissionParamFilters> = qs.parse(location.search, {
         ignoreQueryPrefix: true,
      });
      setActiveFilters({
         hasGradingFilters: !!filterParams.grades,
         hasColumnFilters: !!filterParams.columns,
         hasCompletionFilters: !!filterParams.completions,
         hasSectionFilters: !!filterParams.sections,
      });

      const paramCheckerArrayOrString = (
         params: readonly (string | number)[] | string,
         filter: Filter<FilterIdTypes>,
      ): boolean =>
         (typeof params === 'string' && params === filter.id.toString()) ||
         (typeof params !== 'string' && params.includes(filter.id.toString()));

      setState((prevState) => ({
         gradingFilters: filterParams.grades
            ? prevState.gradingFilters.map((i) =>
                 paramCheckerArrayOrString(filterParams.grades, i)
                    ? { ...i, show: true }
                    : { ...i, show: false },
              )
            : prevState.gradingFilters,
         columnFilters: filterParams.columns
            ? prevState.columnFilters.map((i) =>
                 paramCheckerArrayOrString(filterParams.grades, i)
                    ? { ...i, show: true }
                    : { ...i, show: false },
              )
            : prevState.columnFilters,
         completionFilters: filterParams.completions
            ? prevState.completionFilters.map((i) =>
                 paramCheckerArrayOrString(filterParams.completions, i)
                    ? { ...i, show: true }
                    : { ...i, show: false },
              )
            : prevState.completionFilters,
         sectionFilters: filterParams.sections
            ? prevState.sectionFilters.map((i) =>
                 paramCheckerArrayOrString(filterParams.sections, i)
                    ? { ...i, show: true }
                    : { ...i, show: false },
              )
            : prevState.sectionFilters,
         nameFilter: filterParams.name ? filterParams.name : prevState.nameFilter,
      }));
   };

   const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const updatedNameFilter = event.target.value;
      setState((prevState) => ({
         ...prevState,
         nameFilter: updatedNameFilter,
      }));
   };

   const switchDropDown = (
      filterList: readonly Filter<string>[],
      onChangeFunc: (event: React.ChangeEvent<HTMLInputElement>) => void,
   ): readonly React.ReactElement[] =>
      filterList.map(({ id, name, show }) => (
         <div className='filter-entry' key={id}>
            <Switch
               checked={show}
               className='ignore-click'
               id={id.toString()}
               onChange={onChangeFunc}
               type='checkbox'
            >
               <label className='ignore-click' htmlFor={id.toString()}>
                  {name}
               </label>
            </Switch>
         </div>
      ));

   const filterDropDown = (
      filterList: readonly Filter<FilterIdTypes>[],
      onChangeFunc: (event: React.ChangeEvent<HTMLInputElement>) => void,
   ): readonly React.ReactElement[] =>
      filterList.map(({ id, name, show }) => (
         <div className='filter-entry' key={id} data-test={`section-filter-drop-list-item-${id}`}>
            <input
               checked={show}
               className='ignore-click'
               id={id.toString()}
               onChange={onChangeFunc}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));

   const renderColumnOptions = (): readonly React.ReactElement[] =>
      switchDropDown(state.columnFilters, onShowColumnChange);

   const renderFilterSectionsItems = (): readonly React.ReactElement[] =>
      filterDropDown(state.sectionFilters, onSectionsFilterChange);

   const renderFilterCompletionItems = (): readonly React.ReactElement[] =>
      filterDropDown(state.completionFilters, onCompletionFiltersChange);

   const renderFilterGradedItems = (): readonly React.ReactElement[] =>
      filterDropDown(state.gradingFilters, onGradedFiltersChange);

   return (
      <div className='card-title submissions-header has-button'>
         <div className='row'>
            <div className='col-xs-12 col-sm-3'>
               <input
                  name='nameFilter'
                  type='search'
                  placeholder='Search Students'
                  className='full-width no-margin'
                  value={state.nameFilter}
                  onChange={handleNameFilterChange}
               />
            </div>
            <div className='col-xs-12 col-sm-9'>
               <div className='submissions-header-options'>
                  <Droplist
                     pullRight
                     className='margin-left-s'
                     items={renderFilterCompletionItems()}
                  >
                     <Button
                        className={classnames({
                           'active-filter': activeFilters.hasCompletionFilters,
                        })}
                        icon={<IconContentFilter aria-hidden />}
                        line
                     >
                        Completion
                     </Button>
                  </Droplist>
                  <Droplist pullRight className='margin-left-s' items={renderFilterGradedItems()}>
                     <Button
                        className={classnames({
                           'active-filter': activeFilters.hasGradingFilters,
                        })}
                        icon={<IconContentFilter aria-hidden />}
                        line
                     >
                        Graded
                     </Button>
                  </Droplist>
                  {!!state.sectionFilters.length && (
                     <Droplist
                        pullRight
                        className='margin-left-s'
                        items={renderFilterSectionsItems()}
                     >
                        <Button
                           className={classnames({
                              'active-filter': activeFilters.hasSectionFilters,
                           })}
                           icon={<IconContentFilter aria-hidden />}
                           line
                           data-test='submission-section-filter'
                        >
                           Sections
                        </Button>
                     </Droplist>
                  )}
                  {!!state.columnFilters.length && (
                     <>
                        <Droplist items={renderColumnOptions()} className='margin-left-s'>
                           <Button
                              className={classnames({
                                 'active-filter': activeFilters.hasColumnFilters,
                              })}
                              icon={<IconLayout1 aria-hidden />}
                              line
                           >
                              Columns
                           </Button>
                        </Droplist>
                     </>
                  )}
                  {props.children}
               </div>
            </div>
         </div>
      </div>
   );
};

export default ActivitySubmissionsFilters;
