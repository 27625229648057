import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import HttpService from '@services/HttpService';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import DocumentTitle from './DocumentTitle';

interface ConfirmPasswordResponse {
   passwordResetToken: string;
   msg: string;
   resent: boolean;
}

const ConfirmEmail: React.FC = () => {
   const navigate = useNavigate();
   const location = useLocation();

   const {
      statusCodes: { badRequest },
      routes: {
         dashboard,
         auth: { login },
         resetPassword,
      },
   } = Constants;
   const { confirmUser, userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [error, setError] = React.useState<string>('');
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [isResent, setIsResent] = React.useState<boolean>(false);

   React.useEffect(() => {
      const token = getQueryParameterByName(location, 'token');
      if (token) {
         HttpService.post<ConfirmPasswordResponse>('/api/users/confirm_email', {
            token,
         })
            .then((response) => {
               const { data } = response;

               if (data.passwordResetToken) {
                  navigate(`${resetPassword}?token=${data.passwordResetToken}&initial=true`);
               } else if (data.resent) {
                  setError(data.msg);
                  setIsResent(data.resent);
               } else if (userProfile) {
                  confirmUser();
                  navigate(dashboard);
               } else {
                  navigate(login);
               }
            })
            .catch(({ response: { status, data } }) => {
               setIsLoading(false);
               if (status === badRequest) {
                  setError(data.msg);
               } else {
                  setError('Unable to confirm email.');
               }
            });
      }
   }, []);

   const pageTitle = error ? 'Error' : 'Confirm Email';

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>{pageTitle}</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           {pageTitle}
                        </h2>
                     </div>
                     <p>{isLoading ? 'Confirming Email...' : error}</p>
                     {isResent && (
                        <p className='padding-bottom-m'>We've sent another confirmation email.</p>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ConfirmEmail;
