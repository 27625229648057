import * as React from 'react';

import UserService from '@services/UserService';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import SectionCard from './SectionCard';

export interface UserParam {
   userId?: string;
}

const ImpersonateUser: React.FC = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const { isImpersonating, userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!isImpersonating && !userProfile?.isAdmin) {
      navigate('/');
      return <></>;
   }

   const userParam: Partial<UserParam> = qs.parse(location.search, {
      ignoreQueryPrefix: true,
   });

   const handleSwitchImpersonating = async (): Promise<void> => {
      await UserService.cancelImpersonation();
      await UserService.impersonateUser(Number(userParam?.userId));
      navigate('/');
   };

   const handleStartImpersonating = async (): Promise<void> => {
      await UserService.impersonateUser(Number(userParam?.userId));
      navigate('/');
   };

   return (
      <div className='content-main equal-margin'>
         <DocumentTitle>Impersonate User Page</DocumentTitle>

         <div className='row center-xs'>
            <SectionCard title='Impersonate User'>
               {!userParam?.userId && 'No user id provided in url'}
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {userParam?.userId && isImpersonating ? (
                     <Button className='margin-top-s' onClick={handleSwitchImpersonating}>
                        Switch impersonate from {userProfile?.id} to {userParam?.userId}
                     </Button>
                  ) : (
                     <Button className='margin-top-s' onClick={handleStartImpersonating}>
                        Start impersonating
                     </Button>
                  )}
               </div>
            </SectionCard>
         </div>
      </div>
   );
};

export default ImpersonateUser;
