import * as React from 'react';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import ModalDialog from '@components/Core/ModalDialog';
import TagsInput from '@components/Core/TagsInput';
import LocationSearch, { LocationSuggestion } from '@components/MapBox/LocationSearch';
import MultiSelectTypeAhead from '@components/MultiSelectTypeAhead';
import SelectTypeAhead from '@components/SelectTypeAhead';
import sonixLanguageOptions from '@helpers/SonixLanguageOptions';
import { generateThumbnail } from '@helpers/ThumbnailUtils';
import useUpdateEffect from '@hooks/use-update-effect';
import AttentionIcon from '@icons/general/attention.svg';
import IconRadioTickIcon from '@icons/general/icon-radio-tick-copy.svg';
import FileRefresh1Icon from '@icons/nova-line/85-Files-Basic/file-refresh-1.svg';
import SonixLogoIcon from '@icons/videos/sonix-logo.svg';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import Appearance from '@models/Appearance';
import { IdName } from '@models/Core';
import {
   FetchTranscriptsErrorResponse,
   isFetchTranscriptsErrorResponse,
   isSupportedSonixLanguage,
   SupportedSonixLanguages,
} from '@models/MediaTranscript';
import { StringOption } from '@models/ReactSelectHelperTypes';
import { VideoFormModel } from '@models/Video';
import InterviewQuestionService from '@services/InterviewQuestionService';
import MapboxService from '@services/MapboxService';
import MediaTranscriptService from '@services/MediaTranscriptService';
import SpeakerService from '@services/SpeakerService';
import { isAxiosError } from 'axios';
import Skeleton from 'react-loading-skeleton';
import ReactPlayer from 'react-player';

import { getPartialLocationFromLocation } from './helpers';
import TranscriptModal from './TranscriptModal';

type Props = {
   isVideoUploading: boolean;
   uploadProgress: number;
   videoForm: VideoFormModel;
   setVideoForm(
      videoForm: { id: number } & Partial<VideoFormModel>,
      shouldCallServer: boolean,
   ): void;
};

const BulkVideoForm: React.FC<Props> = (props) => {
   const { videoForm } = props;
   const selectedInterviewOption: StringOption | undefined =
      videoForm.interviewQuestionId && videoForm.interviewQuestionName
         ? {
              label: videoForm.interviewQuestionName,
              value: videoForm.interviewQuestionId.toString(),
           }
         : undefined;
   const [selectedInterviewQuestion, setSelectedInterviewQuestion] = React.useState<
      StringOption | undefined
   >(selectedInterviewOption);

   const partialSuggestion = getPartialLocationFromLocation(videoForm.location);

   const [selectedLocation, setSelectedLocation] = React.useState<LocationSuggestion | undefined>(
      partialSuggestion,
   );

   const [isFetchingTranscripts, setIsFetchingTranscripts] = React.useState(false);
   const [fetchErrorResponse, setFetchErrorResponse] =
      React.useState<FetchTranscriptsErrorResponse>();

   const [selectedSpeakers, setSelectedSpeakers] = React.useState<StringOption[]>(
      videoForm.speakers.map((x) => ({
         value: x.id.toString(), // TODO: check on switching to numberoption
         label: x.name,
      })),
   );

   const [thumbnailUrl, setThumbnailUrl] = React.useState<string>(videoForm.imageUrl ?? '');
   const [thumbnailIsLoading, setThumbnailIsLoading] = React.useState<boolean>(
      thumbnailUrl === '' ? true : false,
   );

   const [duration, setDuration] = React.useState<number | null>(videoForm.duration);
   const [hasClickedReviewButton, setHasClickedReviewButton] = React.useState<boolean>(false);
   const [shouldShowPreviewModal, setShouldShowPreviewModal] = React.useState<boolean>(false);
   const [newLanguage, setNewLanguage] = React.useState<SupportedSonixLanguages>();

   const setVideoForm = (
      newVideoForm: { id: number } & Partial<VideoFormModel>,
      shouldCallServer = true,
   ): void => {
      props.setVideoForm(newVideoForm, shouldCallServer);
   };

   useUpdateEffect(() => {
      setVideoForm({ id: videoForm.id, duration });
   }, [duration]);

   useUpdateEffect(() => {
      setSelectedLocation(videoForm.suggestion);
   }, [videoForm.suggestion]);

   useUpdateEffect(() => {
      if (videoForm.interviewQuestionId && videoForm.interviewQuestionName) {
         setSelectedInterviewQuestion({
            value: videoForm.interviewQuestionId.toString(),
            label: videoForm.interviewQuestionName,
         });
      }
   }, [videoForm.interviewQuestionId]);

   useUpdateEffect(() => {
      const newSelectedSpeakers = videoForm.speakers.map((x) => ({
         value: x.id.toString(),
         label: x.name,
      }));
      setSelectedSpeakers(newSelectedSpeakers);
   }, [videoForm.speakers]);

   const onLanguageChangeConfirmed = async () => {
      if (videoForm.sonixId) {
         try {
            await MediaTranscriptService.deleteMedia(videoForm.sonixId);
         } catch (e) {
            alert('Failed to delete sonix video. Try again once the video is done transribing.');
            return;
         }

         try {
            const newVideo = await MediaTranscriptService.submitNewMedia({
               mediaId: videoForm.id,
               mediaType: 'video',
            });

            setVideoForm({
               ...newVideo,
               language: newLanguage,
               targetLanguageTextTrack: null,
               nativeLanguageTextTrack: null,
            });
            setHasClickedReviewButton(false);
            setNewLanguage(undefined);
         } catch (e) {
            alert('Failed to submit new media after deleting.');
         }
      }
   };

   const handleInputOrSelectChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      if (name === 'language') {
         if (isSupportedSonixLanguage(value)) {
            setNewLanguage(value);
         }
      } else {
         setVideoForm({ id: videoForm.id, [name]: value });
      }
   };

   const handleInterviewQuestionChange = (option: StringOption | undefined): void => {
      setSelectedInterviewQuestion(option);
      const interviewQuestionId = option !== undefined ? parseInt(option.value) : null;
      setVideoForm({ id: videoForm.id, interviewQuestionId });
   };

   const handleSpeakerChange = (options: readonly StringOption[]): void => {
      setSelectedSpeakers([...options]);
      const speakers: IdName[] = options.map((x) => ({
         id: parseInt(x.value),
         name: x.label,
      }));
      setVideoForm({ id: videoForm.id, speakers });
   };

   const handleLocationChange = (suggestion: SearchBoxSuggestion | undefined): void => {
      setSelectedLocation(suggestion);

      if (suggestion) {
         MapboxService.getDetails(suggestion).then((mapProviderResponse) => {
            const firstFeature = mapProviderResponse.features[0];
            setVideoForm({
               id: videoForm.id,
               location: {
                  name: firstFeature.properties.name,
                  placeFormatted: firstFeature.properties.place_formatted,
                  displayName: firstFeature.properties.place_formatted,
                  latitude: firstFeature.properties.coordinates.latitude,
                  longitude: firstFeature.properties.coordinates.longitude,
                  countryCode: firstFeature.properties.context.country?.country_code_alpha_3 ?? '',
                  mapProviderId: firstFeature.properties.mapbox_id,
                  mapProviderResponse,
               },
            });
         });
      } else {
         setVideoForm({ id: videoForm.id, location: null });
      }
   };

   const handleTagChange = (tags: readonly string[]) => {
      setVideoForm({ id: videoForm.id, tags });
   };

   const handleGenerateThumbnail = () => {
      setThumbnailIsLoading(true);
      const videoUrl = videoForm.localFileURL || videoForm.url;
      const selector = `[src='${videoUrl}']`;
      const filenameParam = videoForm.imageFilename || undefined;

      generateThumbnail(selector, filenameParam).then((result) => {
         setVideoForm({
            id: videoForm.id,
            imageFilename: result.filename,
         });
         setThumbnailUrl(result.localUrl);
         setThumbnailIsLoading(false);
      });
   };

   const onVideoReady = (): void => {
      if (thumbnailUrl === '' && !videoForm.imageFilename) {
         // I'm using a timeout here because even once the video loads the initial preview
         // is not necessarily there. There is an onloaded even on the video player which
         // should fire, but I played with it and there seems to be some sort of race between
         // using on ready and on loaded.
         setTimeout(handleGenerateThumbnail, 250);
      }
   };

   const onReviewInSonix = (): void => {
      if (videoForm.sonixId) {
         setHasClickedReviewButton(true);
      }
   };

   const fetchTranscripts = async () => {
      const { sonixId } = props.videoForm;
      if (sonixId) {
         try {
            setIsFetchingTranscripts(true);
            const video = await MediaTranscriptService.updateTextTrackFromSonix(sonixId);
            const { nativeLanguageTextTrack, targetLanguageTextTrack } = video;
            setVideoForm(
               {
                  id: video.id,
                  nativeLanguageTextTrack,
                  targetLanguageTextTrack,
               },
               false,
            );
            setIsFetchingTranscripts(false);
         } catch (e) {
            console.error(e);
            if (isAxiosError(e)) {
               const data = e.response?.data;
               if (isFetchTranscriptsErrorResponse(data)) {
                  setFetchErrorResponse(data);
               } else {
                  alert(e.response?.data.error);
               }
            }
            setIsFetchingTranscripts(false);
         }
      }
   };

   const renderTranscriptsButtons = (): React.ReactNode => {
      const hasTranscripts =
         videoForm && videoForm.targetLanguageTextTrack && videoForm.nativeLanguageTextTrack;

      const doesNotHaveSonixId = !videoForm.sonixId;

      const fetchTranscriptsButton = (
         <Button
            className='full-width'
            disabled={doesNotHaveSonixId}
            loading={isFetchingTranscripts}
            icon={<FileRefresh1Icon />}
            line
            onClick={fetchTranscripts}
         >
            Fetch transcripts
         </Button>
      );

      if (hasTranscripts) {
         return (
            <>
               <Button
                  className='full-width margin-s'
                  disabled={false}
                  icon={<IconRadioTickIcon className='icon-green' />}
                  line
                  onClick={() => setShouldShowPreviewModal(true)}
               >
                  Transcripts Loaded | Preview
               </Button>
               {fetchTranscriptsButton}
            </>
         );
      }

      if (hasClickedReviewButton && videoForm.sonixId) {
         return fetchTranscriptsButton;
      }

      return (
         <Link
            className='review-link flex-center'
            disabled={!videoForm.sonixId}
            external
            onClick={onReviewInSonix}
            to={`https://my.sonix.ai/recordings/${videoForm.sonixId}`}
         >
            <AttentionIcon className='icon-orange margin-right-s' /> Review transcription in Sonix
         </Link>
      );
   };

   const renderModals = (): React.ReactNode => {
      if (fetchErrorResponse) {
         return (
            <TranscriptModal
               appearance={Appearance.danger}
               heading={'VTT File Mismatch'}
               nativeLanguageTextTrack={fetchErrorResponse?.native_vtt_string}
               targetLanguageTextTrack={fetchErrorResponse?.target_vtt_string}
               onOk={() => setFetchErrorResponse(undefined)}
            />
         );
      }

      if (
         videoForm &&
         typeof videoForm.nativeLanguageTextTrack === 'string' &&
         typeof videoForm.targetLanguageTextTrack === 'string' &&
         shouldShowPreviewModal
      ) {
         return (
            <TranscriptModal
               appearance={Appearance.info}
               heading={'Preview Transcripts'}
               onOk={() => setShouldShowPreviewModal(false)}
               nativeLanguageTextTrack={videoForm.nativeLanguageTextTrack}
               targetLanguageTextTrack={videoForm.targetLanguageTextTrack}
            />
         );
      }

      if (newLanguage) {
         return (
            <ModalDialog
               actions={[
                  { text: 'Change', onClick: () => onLanguageChangeConfirmed() },
                  { text: 'Cancel', onClick: () => setNewLanguage(undefined) },
               ]}
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               bodyClassName='modal-body'
               heading='Confirm Language Change'
               onClose={() => setNewLanguage(undefined)}
               shouldCloseOnEscapePress
               shouldCloseOnOverlayClick
            >
               <div>
                  Changing language will <strong>delete the current video in Sonix</strong> along
                  with the associated transcripts.
               </div>
               <br />
               <div>
                  A new version will be automatically submitted to Sonix and transcripts will need
                  to be reviewed and fetch again
               </div>
            </ModalDialog>
         );
      }
   };

   return (
      <form className='flex flex-center video-form'>
         {renderModals()}

         {props.isVideoUploading && (
            <div className='barcontainer'>
               <div className='bar' style={{ height: `${props.uploadProgress}%` }} />
            </div>
         )}
         <div className='first-column'>
            <div className='video-container'>
               <ReactPlayer
                  width='100%'
                  height='100%'
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  url={videoForm.localFileURL || videoForm.url}
                  onReady={onVideoReady}
                  onDuration={setDuration}
                  controls
                  config={{
                     file: {
                        attributes: {
                           preload: 'metadata',
                           crossOrigin: 'true',
                        },
                     },
                  }}
               />
            </div>
            <div className='video-menu'>
               <label>{videoForm.fileName}</label>
            </div>
            <div>
               <label className='field-title'>Thumbnail</label>
               <div className='flex-align-center flex-wrap'>
                  <div className='thumbnail-container'>
                     {thumbnailUrl ? (
                        <img src={thumbnailUrl} alt='Thumbnail preview' className='thumbnail-img' />
                     ) : (
                        <Skeleton className='thumbnail-img' count={1} />
                     )}
                  </div>
                  <Button
                     className='thumbnail-button margin-left-s'
                     onClick={handleGenerateThumbnail}
                     loading={thumbnailIsLoading}
                  >
                     Use Current Frame
                  </Button>
               </div>
            </div>
         </div>

         <div className='second-column'>
            <label className='field-title'>Title</label>
            <input
               name='name'
               onChange={handleInputOrSelectChange}
               type='text'
               value={videoForm.name}
            />
            <label className='field-title'>Prompt</label>
            <SelectTypeAhead
               fieldName='interviewQuestionId'
               placeHolder='Start typing an interview question.'
               rowLoader={InterviewQuestionService.search}
               selectedOption={selectedInterviewQuestion}
               setSelectedOption={handleInterviewQuestionChange}
            />
            <label className='field-title'>Tags</label>
            <TagsInput
               inputProps={{ placeholder: '+ Add Tag' }}
               onChange={handleTagChange}
               value={videoForm.tags}
            />
            <label className='field-title'>Speakers</label>
            <MultiSelectTypeAhead
               placeHolder='Start typing a speaker name.'
               rowLoader={SpeakerService.search}
               selectedOptions={selectedSpeakers}
               setSelectedOptions={handleSpeakerChange}
            />
            <label className='field-title'>Location</label>
            <LocationSearch
               selectedSuggestion={selectedLocation}
               setSelectedSuggestion={handleLocationChange}
            />
         </div>
         <div className='third-column'>
            <label className='field-title'>Language</label>
            <select
               className='cs-select cs-select-style no-margin'
               name='language'
               onChange={handleInputOrSelectChange}
               value={videoForm.language}
            >
               {sonixLanguageOptions}
            </select>
            <label className='field-title'>Level</label>
            <select
               className='no-margin'
               name='level'
               value={videoForm.level || undefined}
               onChange={handleInputOrSelectChange}
            >
               <option value={undefined} />
               <option value='Novice'>Novice</option>
               <option value='Intermediate'>Intermediate</option>
               <option value='Advanced'>Advanced</option>
            </select>
            <label className='field-title'>Transcripts</label>
            <div className='transcripts-container'>
               <Link
                  className='btn line use-icon-default-size full-width margin-s'
                  disabled={!videoForm.sonixId}
                  external
                  to={`https://my.sonix.ai/recordings/${videoForm.sonixId}`}
                  onClick={onReviewInSonix}
               >
                  <SonixLogoIcon width={'100%'} height={'18px'} aria-hidden />
               </Link>
               {renderTranscriptsButtons()}
            </div>
         </div>
      </form>
   );
};

export default BulkVideoForm;
