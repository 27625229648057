import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { SectionCard } from '@components/Common/SectionCard';
import DocumentTitle from '@components/DocumentTitle';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import { formatCurrency } from '@helpers/CurrencyUtil';
import { formatDateConcise } from '@helpers/FormatTime';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { Maybe } from '@models/Core';
import {
   NationalExam,
   NationalExamAbbreviation,
   NationalExamLevel,
   NationalExamRegistrationOrder,
} from '@models/NationalExam';
import HttpService from '@services/HttpService';
import NationalExamService from '@services/NationalExamService';
import classnames from 'classnames';

import { AppStateContext } from '../../../AppState';
import Constants, { NationalExamNameAbbreviationValuesRecord } from '../../../Constants';
import NationalExamOrderHistory from './NationalExamOrderHistory';
import RegistrationClosed from './RegistrationClosed';

interface NationalExamRegistrationProps {
   examUrlSlug: Maybe<string>;
}

interface InstructorInfo {
   firstName: string;
   lastName: string;
   email: string;
   phone: string;
   isMember: Maybe<boolean>;
   chapterId: Maybe<number>;
}
interface SchoolInfo {
   schoolName: string;
   schoolAddress: string;
   schoolCity: string;
   schoolState: string;
   schoolZip: string;
   schoolType: string;
}

interface ExamInfo {
   levelIds: readonly number[];
   totalSeats: number;
}

const NationalExamRegistrationForm: React.FC<NationalExamRegistrationProps> = ({ examUrlSlug }) => {
   const { stateAbbreviations } = Constants;

   const {
      setBreadcrumbs,
      setAvailableNationalExams,
      availableNationalExams,
      userProfile,
      schoolProfile,
   } = React.useContext(AppStateContext);

   const [exam, setExam] = React.useState<NationalExam>();
   const [schoolTypeOptions, setSchoolTypeOptions] = React.useState<readonly string[]>([]);
   const [levels, setLevels] = React.useState<readonly NationalExamLevel[]>([]);
   const [orderHistory, setOrderHistory] = React.useState<readonly NationalExamRegistrationOrder[]>(
      [],
   );
   const [showOrderHistory, setShowOrderHistory] = React.useState<boolean>(true);
   const [isRegistering, setIsRegistering] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [orderPlaced, setOrderPlaced] = React.useState<boolean>(false);

   const [instructorInfo, setInstructorInfo] = React.useState<InstructorInfo>({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isMember: null,
      chapterId: null,
   });

   const [schoolInfo, setSchoolInfo] = React.useState<SchoolInfo>({
      schoolName: '',
      schoolAddress: '',
      schoolCity: '',
      schoolState: '',
      schoolZip: '',
      schoolType: '',
   });

   const [examInfo, setExamInfo] = React.useState<ExamInfo>({
      levelIds: [],
      totalSeats: 0,
   });

   const [errors, setErrors] = React.useState<Record<string, boolean>>({
      chapterId: false,
      schoolState: false,
      schoolType: false,
      levelIds: false,
      isMember: false,
      totalSeats: false,
   });

   const mostRecentOrder = orderHistory?.length > 0 ? orderHistory[0] : null;

   // Set default values giving preference to the most recent orders
   React.useEffect(() => {
      if (mostRecentOrder) {
         setInstructorInfo((prevInstructorInfo) => ({
            ...prevInstructorInfo,
            firstName: mostRecentOrder.firstName,
            lastName: mostRecentOrder.lastName,
            email: mostRecentOrder.email,
            phone: mostRecentOrder.phone,
            chapterId: mostRecentOrder.chapterId,
            isMember: mostRecentOrder.isMember,
         }));
         setSchoolInfo((prevSchoolInfo) => ({
            ...prevSchoolInfo,
            schoolAddress: mostRecentOrder.schoolAddress,
            schoolCity: mostRecentOrder.schoolCity,
            schoolName: mostRecentOrder.schoolName,
            schoolState: mostRecentOrder.schoolState,
            schoolZip: mostRecentOrder.schoolZip,
            schoolType: mostRecentOrder.schoolType,
         }));
      } else {
         if (userProfile) {
            setInstructorInfo((prevInstructorInfo) => ({
               ...prevInstructorInfo,
               firstName: userProfile.firstName,
               lastName: userProfile.lastName,
               email: userProfile.email,
            }));
         }
         if (schoolProfile) {
            setSchoolInfo((prevSchoolInfo) => ({
               ...prevSchoolInfo,
               schoolName: schoolProfile.name,
               schoolCity: schoolProfile.city,
               schoolState: schoolProfile.state,
            }));
         }
      }
   }, [userProfile, schoolProfile, mostRecentOrder]);

   React.useEffect(() => {
      if (!examUrlSlug) {
         return;
      }
      NationalExamService.getExamRegistrationInformation(examUrlSlug).then((data) => {
         setOrderHistory(data.orderHistory);
         setExam(data.exam);
         setBreadcrumbs({
            breadcrumbs: [{ link: '#', text: `${data.exam.nameAbbr.toUpperCase()} Registration` }],
            next: null,
            prev: null,
         });
         setLevels(data.levels);
         setSchoolTypeOptions(
            _.compact([
               'Private',
               'Public',
               // HACK - Should be data-driven
               [
                  NationalExamNameAbbreviationValuesRecord.nge,
                  NationalExamNameAbbreviationValuesRecord.nge_spring,
               ].includes(
                  data.exam?.nameAbbr.toString().toLowerCase() as NationalExamAbbreviation,
               ) && 'Sprachschule',
               'Other/No Association',
            ]),
         );
         setIsFetching(false);
      });
   }, [examUrlSlug]);

   const handleSchoolInfoChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setSchoolInfo((prevSchoolInfo) => ({ ...prevSchoolInfo, [name]: value }));
   };

   const handleInstructorInfoChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setInstructorInfo((prevInstructorInfo) => ({ ...prevInstructorInfo, [name]: value }));
   };

   const handleIsMemberChange = (event: React.FormEvent<HTMLFieldSetElement>) => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      setInstructorInfo((prevInstructorInfo) => ({
         ...prevInstructorInfo,
         isMember: value === 'Yes',
      }));
   };

   const handleExamInfoChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value, type } = event.target;
      const patchedValue = type === 'number' ? parseInt(value) : value;
      setExamInfo((prevExamInfo) => ({ ...prevExamInfo, [name]: patchedValue }));
   };

   const toggleExamLevel = (levelId: number) => {
      setExamInfo((prevExamInfo) => ({
         ...prevExamInfo,
         levelIds: prevExamInfo.levelIds.includes(levelId)
            ? prevExamInfo.levelIds.filter((i) => i !== levelId)
            : [...prevExamInfo.levelIds, levelId],
      }));
   };

   const handleSubmit = (event: React.FormEvent): Promise<void> => {
      event.preventDefault();
      if (!exam) {
         return Promise.reject();
      }
      const { totalSeats, levelIds } = examInfo;
      const { firstName, lastName, email, phone, chapterId, isMember } = instructorInfo;
      const { schoolAddress, schoolCity, schoolName, schoolState, schoolZip, schoolType } =
         schoolInfo;
      const updatedErrors: Record<string, boolean> = {
         chapterId: chapterId === null && (exam.chapters?.length ?? 0) > 0,
         schoolState: schoolState === '' || schoolState === null,
         schoolType: schoolType === '' || schoolType === null,
         levelIds: levelIds.length === 0,
         isMember: isMember === null,
         totalSeats: totalSeats === 0,
      };
      setErrors(updatedErrors);
      if (Object.values(updatedErrors).some((i) => !!i)) {
         return Promise.resolve();
      }
      setIsRegistering(true);
      const additionalFee = calculateNonMemberFee();
      const order: NationalExamRegistrationOrder = {
         id: null,
         createdOn: null,
         schoolName,
         isMember: isMember ?? false,
         totalSeats,
         price: calculatePricePerSeat(),
         lateFee: calculateLateFee(),
         additionalFee,
         additionalFeeName: additionalFee ? 'Non-Member Fee' : '',
         // totalPrice: null, Total Price is calculated on the backend
         lastName,
         firstName,
         email,
         phone,
         schoolType,
         schoolAddress,
         schoolCity,
         schoolState,
         schoolZip,
         chapterId, // Replaced with NationalExamChapter
         levels: levelIds.map((i) => levels.find((j) => j.id === i)?.levelName).join(', '),
         levelIds,
      };
      const url = `api/national_exams/${examUrlSlug}/register`;
      return HttpService.postWithAuthToken<{
         orderHistory: readonly NationalExamRegistrationOrder[];
      }>(url, snakeCaseKeys(order)).then((response) => {
         const { data } = response;
         setOrderPlaced(true);
         setShowOrderHistory(false);
         setOrderHistory(data.orderHistory);
         setIsRegistering(false);
         if (!availableNationalExams.includes(exam.nameAbbr)) {
            setAvailableNationalExams([...availableNationalExams, exam.nameAbbr]);
         }
      });
   };

   const calculateLateFee = (): number => {
      const hasOrderHistory = orderHistory.length > 0;
      if (!hasOrderHistory && exam?.isLate) {
         return exam.lateFee;
      }
      return 0;
   };

   const calculatePricePerSeat = (): number => {
      if (exam) {
         return instructorInfo.isMember ? exam.memberPrice : exam.price;
      }
      return 0;
   };

   const calculateNonMemberFee = (): number => {
      /** Only charge non-member fee is one exists and its the first order. Adding additional seats should not incur an additional fee. */
      if (
         exam?.nonMemberFee &&
         exam.nonMemberFee > 0 &&
         !instructorInfo.isMember &&
         orderHistory.length === 0
      ) {
         return exam.nonMemberFee;
      }
      return 0;
   };

   const calculateOrderTotal = (): number =>
      calculatePricePerSeat() * (examInfo.totalSeats || 0) +
      calculateLateFee() +
      calculateNonMemberFee();

   if (isFetching) {
      return <Loader />;
   }

   const regIsOpen = !exam?.isClosed;

   const renderRegistrationClosed = (): React.ReactNode => {
      if (!exam) {
         return <></>;
      }
      return <RegistrationClosed exam={exam} />;
   };

   const renderRegistration = (): React.ReactNode => {
      if (!exam) {
         return <></>;
      }
      return (
         <>
            <SectionCard title={`Register for the ${exam.name}`}>
               <form onSubmit={handleSubmit}>
                  <div className='row'>
                     <div className='col-xs-12'>
                        {exam.isLate && (
                           <div className='alert alert-primary'>
                              Normal registration is closed, but the late registration period is
                              open between {exam.lateRegDate && formatDateConcise(exam.lateRegDate)}{' '}
                              and {formatDateConcise(exam.regEndDate)}.
                              {exam.lateFee
                                 ? ` An additional ${exam.lateFee} fee will be added to the order, if this is your first order.`
                                 : ''}
                           </div>
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>First Name</label>
                        <input
                           className={classnames({ error: errors?.firstName })}
                           name='firstName'
                           onChange={handleInstructorInfoChange}
                           required
                           type='text'
                           value={instructorInfo.firstName}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Last Name</label>
                        <input
                           className={classnames({ error: errors?.lastName })}
                           name='lastName'
                           onChange={handleInstructorInfoChange}
                           required
                           type='text'
                           value={instructorInfo.lastName}
                        />
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Email</label>
                        <input
                           className={classnames({ error: errors?.email })}
                           name='email'
                           onChange={handleInstructorInfoChange}
                           required
                           type='email'
                           value={instructorInfo.email}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>
                           Phone{' '}
                           <InfoTooltip>
                              Used only by exam organizers in case they have follow-up questions on
                              your order.
                           </InfoTooltip>
                        </label>
                        <input
                           className={classnames({ error: errors?.phone })}
                           name='phone'
                           data-test='input-phone'
                           onChange={handleInstructorInfoChange}
                           required
                           type='text'
                           value={instructorInfo.phone}
                        />
                     </div>
                  </div>
                  <div className='row margin-top-m'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>School Name</label>
                        <input
                           className={classnames({ error: errors?.schoolName })}
                           name='schoolName'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolName}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>School Type</label>
                        <select
                           className={classnames({ error: errors?.schoolType })}
                           name='schoolType'
                           onChange={handleSchoolInfoChange}
                           required
                           value={schoolInfo.schoolType}
                           data-test='select-school-type'
                        >
                           <option hidden value='' />
                           {schoolTypeOptions.map((x) => (
                              <option key={x} value={x}>
                                 {x}
                              </option>
                           ))}
                        </select>
                     </div>
                  </div>
                  {!!exam?.chapters && (
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>{exam.membershipName} Chapter</label>
                           <select
                              className={classnames({ error: errors?.chapterId })}
                              name='chapterId'
                              onChange={handleInstructorInfoChange}
                              required
                              value={instructorInfo.chapterId ?? ''}
                           >
                              <option hidden value='' />
                              {exam.chapters.map((x) => (
                                 <option key={x.id} value={x.id}>
                                    {x.name}
                                 </option>
                              ))}
                           </select>
                        </div>
                     </div>
                  )}
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>School Street Address</label>
                        <input
                           className={classnames({ error: errors?.schoolAddress })}
                           name='schoolAddress'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolAddress}
                           data-test='input-school-address'
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>City</label>
                        <input
                           className={classnames({ error: errors?.schoolCity })}
                           name='schoolCity'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolCity}
                           data-test='input-school-city'
                        />
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>State</label>
                        <select
                           className={classnames({ error: errors?.schoolState })}
                           name='schoolState'
                           onChange={handleSchoolInfoChange}
                           required
                           value={schoolInfo.schoolState}
                           data-test='select-school-state'
                        >
                           <option hidden value='' />
                           {stateAbbreviations.map((x) => (
                              <option key={x} value={x}>
                                 {x}
                              </option>
                           ))}
                        </select>
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Postal Code</label>
                        <input
                           className={classnames({ error: errors?.schoolZip })}
                           name='schoolZip'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolZip}
                           data-test='input-school-zip'
                        />
                     </div>
                  </div>
                  <div className='row margin-top-m'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>
                           Which levels of the {exam.nameAbbr} are your students taking?
                        </label>
                        <fieldset className={classnames('form-group', { error: errors?.levelIds })}>
                           {levels.map((level) => (
                              <p key={level.id}>
                                 <input
                                    checked={examInfo.levelIds.includes(level.id)}
                                    onChange={() => toggleExamLevel(level.id)}
                                    type='checkbox'
                                    id={`levels[${level.id}].value`}
                                    data-test={`levels[${level.id}].value`}
                                 />
                                 <label htmlFor={`levels[${level.id}].value`} className='pointer'>
                                    {level.levelName}
                                 </label>
                              </p>
                           ))}
                        </fieldset>
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>
                           Do you have an up-to-date {exam.membershipName} membership for{' '}
                           {exam.examYear}?
                        </label>
                        <fieldset
                           className={classnames('form-group flex padding-top-m', {
                              error: errors?.isMember,
                           })}
                           onChange={handleIsMemberChange}
                        >
                           <div style={{ marginRight: '10px' }}>
                              <input
                                 checked={instructorInfo.isMember === true}
                                 type='radio'
                                 id='is-member-yes'
                                 data-test='input-is-member-yes'
                                 value='Yes'
                              />
                              <label htmlFor='is-member-yes'>Yes</label>
                           </div>
                           <div>
                              <input
                                 checked={instructorInfo.isMember === false}
                                 type='radio'
                                 id='is-member-no'
                                 data-test='input-is-member-no'
                                 value='No'
                              />
                              <label htmlFor='is-member-no'>No</label>
                           </div>
                        </fieldset>
                     </div>
                  </div>
                  <div className='row margin-top-m'>
                     <div className='col-xs-12 col-sm-3'>
                        <label className='field-title'>Seats to reserve</label>
                        <input
                           name='totalSeats'
                           className={classnames({ error: errors?.totalSeats })}
                           onChange={handleExamInfoChange}
                           value={examInfo.totalSeats}
                           data-test='input-total-seats'
                           type='number'
                           min='1'
                        />
                     </div>
                     {instructorInfo.isMember === null && (
                        <>
                           <div className='col-xs-12 col-sm-8'>
                              <label className='field-title'>Price per seat</label>
                              <div className=''>
                                 <p>*Indicate your membership status above to see price</p>
                              </div>
                           </div>
                        </>
                     )}
                     {instructorInfo.isMember !== null && (
                        <>
                           <div className='col-xs-12 col-sm-3'>
                              <label className='field-title'>Price per seat</label>
                              <div className='padding-top-s'>
                                 <strong>{formatCurrency(calculatePricePerSeat())}</strong>
                              </div>
                           </div>
                           {!!calculateNonMemberFee() && (
                              <div className='col-xs-12 col-sm-3'>
                                 <label className='field-title'>Non-member fee</label>
                                 <div className='padding-top-s'>
                                    <strong>{formatCurrency(calculateNonMemberFee())}</strong>
                                 </div>
                              </div>
                           )}
                           {!!calculateLateFee() && (
                              <div className='col-xs-12 col-sm-3'>
                                 <label className='field-title'>Late fee</label>
                                 <div className='padding-top-s'>
                                    <strong>{formatCurrency(calculateLateFee())}</strong>
                                 </div>
                              </div>
                           )}
                           <div className='col-xs-12 col-sm-3'>
                              <label className='field-title'>Total</label>
                              <div className='padding-top-s'>
                                 <strong>{formatCurrency(calculateOrderTotal())}</strong>
                              </div>
                           </div>
                        </>
                     )}
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 text-right'>
                        <label className='field-title' />
                        <div className='action-buttons'>
                           <Button
                              loading={isRegistering}
                              disabled={!(examInfo.totalSeats > 0)}
                              data-test='button-submit'
                              type='submit'
                           >
                              Place Order
                           </Button>
                        </div>
                     </div>
                  </div>
               </form>
            </SectionCard>
         </>
      );
   };

   const toggleShowOrderHistory = (): void => setShowOrderHistory(!showOrderHistory);

   const renderOrderSuccess = (): React.ReactNode => {
      if (!mostRecentOrder || !exam) {
         return <></>;
      }
      return (
         <SectionCard title={`Order #${mostRecentOrder.id} has been placed`}>
            <div className='row'>
               <div className='col-xs-12'>
                  <div className='alert alert-success'>
                     <div className='text'>Your order for the {exam.name} has been placed!</div>
                     <p className='text'>
                        Check out your{' '}
                        <Link
                           data-test='link-dashboard'
                           className='alert-link'
                           to={`/national_exams/${exam.nameAbbr.toLowerCase()}`}
                        >
                           dashboard page
                        </Link>{' '}
                        to see the exam courses containing your practice material, help guides, and
                        other useful information.
                     </p>
                  </div>

                  {!showOrderHistory && (
                     <Button textLink onClick={toggleShowOrderHistory}>
                        Click here to view your full order history
                     </Button>
                  )}
               </div>
            </div>
         </SectionCard>
      );
   };

   return (
      <>
         {exam && <DocumentTitle>{`Register for ${exam.name}`}</DocumentTitle>}
         <div className='content-main margin-right-m'>
            <div className='row center-xs national-exam-reg'>
               {!regIsOpen && renderRegistrationClosed()}
               {regIsOpen && (orderPlaced ? renderOrderSuccess() : renderRegistration())}
               {showOrderHistory && examUrlSlug && (
                  <NationalExamOrderHistory orderHistory={orderHistory} />
               )}
            </div>
         </div>
      </>
   );
};

export default NationalExamRegistrationForm;
