import * as React from 'react';

import { ProficiencyLevelName } from '@models/Proficiency';
import classNames from 'classnames';

import { Maybe } from '@models/Core';

interface ProficiencyLevelLabelProps {
   className?: string;
   children: Maybe<ProficiencyLevelName>;
}

const ProficiencyLevelLabel: React.FC<ProficiencyLevelLabelProps> = ({ className, children }) => {
   const classNameMap: Record<ProficiencyLevelName, string> = {
      'Novice Low': 'light-blue',
      'Novice Mid': 'mid-blue',
      'Novice High': 'blue',
      'Intermediate Low': 'light-green',
      'Intermediate Mid': 'mid-green',
      'Intermediate High': 'green',
      'Advanced Low': 'light-yellow',
      'Advanced Mid': 'mid-green',
      'Advanced High': 'green',
   };

   const abbreviationMap: Record<ProficiencyLevelName, string> = {
      'Novice Low': 'Nov.-Low',
      'Novice Mid': 'Nov.-Mid',
      'Novice High': 'Nov.-High',
      'Intermediate Low': 'Int.-Low',
      'Intermediate Mid': 'Int.-Mid',
      'Intermediate High': 'Int.-High',
      'Advanced Low': 'Adv.-Low',
      'Advanced Mid': 'Adv.-Mid',
      'Advanced High': 'Adv.-High',
   };

   if (!children) {
      return null;
   }

   return (
      <div className={classNames('lozenge', className, classNameMap[children])}>
         {abbreviationMap[children]}
      </div>
   );
};

export default ProficiencyLevelLabel;
