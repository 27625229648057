import { randomShortId } from '@helpers/RandomStringUtils';

import {
   BooleanComparison,
   BooleanPredicate,
   OperatorType,
   PredicateGroup,
   PrimitiveType,
   StringComparison,
   StringPredicate,
} from '@components/PredicateEditor/Models';

const authenticatedPredicate: BooleanPredicate = {
   attribute: 'user.authenticated',
   comparison: BooleanComparison.true,
   type: PrimitiveType.boolean,
   id: randomShortId(),
};

const notDemoUserPredicate: BooleanPredicate = {
   attribute: 'user.demo',
   comparison: BooleanComparison.false,
   id: randomShortId(),
   type: PrimitiveType.boolean,
};

const notLingcoUserPredicate: StringPredicate = {
   attribute: 'user.email',
   comparison: StringComparison.doesNotContain,
   id: randomShortId(),
   type: PrimitiveType.string,
   value: '@lingco.io',
};

const nestedLegitimateUserPredicate: PredicateGroup = {
   id: randomShortId(),
   predicates: [authenticatedPredicate, notDemoUserPredicate, notLingcoUserPredicate],
   type: OperatorType.and,
};

const notLingcoSchoolPredicate: StringPredicate = {
   attribute: 'school.name',
   comparison: StringComparison.doesNotEqual,
   id: randomShortId(),
   type: PrimitiveType.string,
   value: 'Lingco Language Labs',
};

const notArchivedPredicate: BooleanPredicate = {
   attribute: 'course.archived',
   comparison: BooleanComparison.false,
   id: randomShortId(),
   type: PrimitiveType.boolean,
};

export const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [notLingcoSchoolPredicate, notArchivedPredicate, nestedLegitimateUserPredicate],
};
