import * as React from 'react';

import IconGrade from '@icons/general/icon-grade.svg';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { AssignableModuleItemType, Assignment, GradingCategory } from '@models/Course';
import LMSName from '@models/LMSName';
import DateTime from '@services/DateTimeService';
import classnames from 'classnames';

import Switch from '@components/Common/Switch';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import { lmsDisplayName } from '@components/Core/LMS';
import LMSButton from '@components/Core/LMS/LMSButton';
import {
   CreatableSelect,
   selectComponents,
   selectStyle,
   selectTheme,
} from '@components/Core/Select';
import InfoTooltip from '@components/InfoTooltip';
import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';

interface GradingPanelProps {
   assignment: Assignment;
   canEditModule: boolean;
   gradingCategories: readonly GradingCategory[];
   isOpen: boolean;
   itemType: AssignableModuleItemType;
   lmsCourseworkIds?: readonly number[];
   lmsName: Maybe<LMSName>;
   createGradingCategory(label: string): Promise<number>;
   onAssignmentUpdate(update: Partial<Assignment>): void;
   postItemToLMS(): Promise<void>;
   setIsInvalid(isInValid: boolean): void;
   syncGradesWithLMS(): Promise<void>;
   toggleOpen(): void;
}

const GradingPanel: React.FC<GradingPanelProps> = ({
   assignment,
   canEditModule,
   gradingCategories,
   isOpen,
   itemType,
   lmsCourseworkIds,
   lmsName,
   createGradingCategory,
   onAssignmentUpdate,
   postItemToLMS,
   syncGradesWithLMS,
   toggleOpen,
   setIsInvalid,
}) => {
   const { gradeAutomatically, gradingCategoryId, shortName } = assignment;

   const isActivity = itemType === ContentType.activity;
   const gradingCategoryOptions = gradingCategories.map(({ name, id }) => ({
      label: name,
      value: id,
   }));
   const gradingCategory = gradingCategoryOptions.find((i) => i.value === gradingCategoryId);

   const [isGradeCategoryLoading, setIsGradeCategoryLoading] = React.useState<boolean>(false);
   const [isPostingToLMS, setIsPostingToLMS] = React.useState<boolean>(false);
   const [isSyncingWithLMS, setIsSyncingWithLMS] = React.useState<boolean>(false);
   const [lmsEndDateError, setLmsEndDateError] = React.useState<boolean>(false);

   const [attemptsAllowedInputValue, setAttemptsAllowedInputValue] = React.useState<number>(1);
   const showAttemptsAllowedInput = assignment.attemptsAllowed > 1;

   React.useEffect(() => {
      setAttemptsAllowedInputValue(assignment.attemptsAllowed);
   }, [assignment.attemptsAllowed]);

   // Either update or reset back to valid number
   const handleAttemptsAllowedInputBlur = (): void => {
      if (attemptsAllowedInputValue > 1) {
         onAssignmentUpdate({ attemptsAllowed: attemptsAllowedInputValue });
      } else {
         setAttemptsAllowedInputValue(assignment.attemptsAllowed);
      }
   };

   const handleAttemptsAllowInputChange = (value: number): void => {
      setIsInvalid(true);
      setAttemptsAllowedInputValue(value);
   };

   const getLMSDisplayName = (): Maybe<string> => {
      if (lmsName === LMSName.googleClassroom) {
         return 'Classroom';
      }
      return lmsName ? lmsDisplayName[lmsName] : null;
   };

   const handleCreateCategory = async (inputValue: string): Promise<void> => {
      if (!isGradeCategoryLoading) {
         setIsGradeCategoryLoading(true);
         const newGradingCategoryId = await createGradingCategory(inputValue);
         onAssignmentUpdate({ gradingCategoryId: newGradingCategoryId });
         setIsGradeCategoryLoading(false);
      }
   };

   const toggleMultipleAttemptsAllowed = (): void => {
      onAssignmentUpdate({
         attemptsAllowed: assignment.attemptsAllowed === 1 ? 2 : 1,
      });
   };

   const handleCategoryChange = (option: { value: number; label: string } | null): void => {
      onAssignmentUpdate({ gradingCategoryId: option ? option.value : null });
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked, name, type, value } = event.target;
      onAssignmentUpdate({ [name]: type === 'checkbox' ? checked : value });
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   const handleTextBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.value = event.target.value.trim();
      handleChange(event);
   };

   const handlePostToLMS = (): void => {
      // Google Classroom only allows assignments in the future
      setLmsEndDateError(false);
      if (DateTime.now() < assignment.endDate || lmsName !== LMSName.googleClassroom) {
         setIsPostingToLMS(true);
         postItemToLMS().then(() => {
            setIsPostingToLMS(false);
         });
      } else {
         setLmsEndDateError(true);
      }
   };

   const handleSyncGradesWithLMS = (): void => {
      setIsSyncingWithLMS(true);
      syncGradesWithLMS().then(() => {
         setIsSyncingWithLMS(false);
      });
   };

   const renderLMSButton = (): React.ReactNode => {
      if (lmsName && lmsCourseworkIds) {
         if (lmsCourseworkIds.length) {
            return (
               <LMSButton
                  disabled={!canEditModule}
                  fullWidth
                  lmsName={lmsName}
                  loading={isSyncingWithLMS}
                  onClick={handleSyncGradesWithLMS}
               >
                  {`Sync Grades with ${getLMSDisplayName()}`}
               </LMSButton>
            );
         } else {
            return (
               <>
                  <LMSButton
                     disabled={!canEditModule}
                     fullWidth
                     lmsName={lmsName}
                     loading={isPostingToLMS}
                     onClick={handlePostToLMS}
                  >
                     Share as Graded Assignment
                  </LMSButton>
                  {lmsEndDateError && <p className='error'>End Date must be in the future</p>}
               </>
            );
         }
      }
      return null;
   };

   return (
      <ModuleItemSettingsAccordion
         collapsible
         icon={<IconGrade aria-hidden />}
         isOpen={isOpen}
         onToggle={toggleOpen}
         title='Grading'
         data-tour='grading-tab'
         data-test='grading-tab'
      >
         {isActivity && (
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <Switch
                     checked={gradeAutomatically}
                     disabled={!canEditModule}
                     name='gradeAutomatically'
                     onChange={handleChange}
                     title='Grade Automatically'
                     data-test='grade-automatically-switch'
                     tooltip='Students will see their grade immediately upon submission for all auto-gradable prompts.'
                  />
               </div>
               {!!lmsName && <div className='col-xs-12 col-sm-6'>{renderLMSButton()}</div>}
            </div>
         )}
         {gradeAutomatically && (
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <Switch
                     checked={assignment.attemptsAllowed > 1}
                     disabled={!canEditModule}
                     onChange={toggleMultipleAttemptsAllowed}
                     title='Allow Multiple Attempts'
                  />
               </div>
               {showAttemptsAllowedInput && (
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Attempts Allowed</label>
                     <NumberInput
                        className={(value) => (value < 2 ? 'error' : '')}
                        disabled={!canEditModule}
                        integer
                        min={2}
                        name='attemptsAllowed'
                        data-test='attempts-allowed'
                        onBlur={handleAttemptsAllowedInputBlur}
                        onFocus={handleFocus}
                        onValueChange={handleAttemptsAllowInputChange}
                        value={attemptsAllowedInputValue}
                        allowSetValueWhenFocused
                     />
                     {attemptsAllowedInputValue < 2 && <p className='error'>Must be at least 2</p>}
                  </div>
               )}
            </div>
         )}
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               <label className='field-title'>
                  Grading Category
                  <InfoTooltip>
                     Used in the Gradebook to group similar assignments together (i.e homework,
                     quizzes, etc.)
                  </InfoTooltip>
               </label>
               <div className='grading-category-selector'>
                  <CreatableSelect
                     className='react-select'
                     components={selectComponents}
                     isClearable
                     isDisabled={!canEditModule}
                     isLoading={isGradeCategoryLoading}
                     noOptionsMessage={() => 'No Grading Categories'}
                     onChange={handleCategoryChange}
                     onCreateOption={handleCreateCategory}
                     options={gradingCategoryOptions}
                     styles={selectStyle}
                     theme={selectTheme}
                     value={gradingCategory || null}
                  />
               </div>
            </div>
            <div className='col-xs-12 col-sm-6 '>
               <label className='field-title'>
                  Short Name
                  <InfoTooltip>Optional abbreviated name for display in gradebook.</InfoTooltip>
               </label>
               <input
                  className={classnames({ disabled: !canEditModule })}
                  disabled={!canEditModule}
                  name='shortName'
                  onBlur={handleTextBlur}
                  onChange={handleChange}
                  type='text'
                  value={shortName}
               />
            </div>
         </div>
         {!!lmsName && !isActivity && (
            <div className='row margin-top-s'>
               <div className='col-sm-offset-6 col-sm-6 col-sx-12'>{renderLMSButton()}</div>
            </div>
         )}
      </ModuleItemSettingsAccordion>
   );
};

export default GradingPanel;
