/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
   String.prototype.replaceAll = function (
      str: string | RegExp,
      newStr: string | ((substring: string, ...args: any[]) => string),
   ) {
      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
         if (typeof newStr === 'function') {
            return this.replace(str as RegExp, newStr);
         }
         return this.replace(str as RegExp, newStr as string);
      }

      // If a string
      if (typeof newStr === 'function') {
         return this.replace(new RegExp(str, 'g'), newStr);
      }
      return this.replace(new RegExp(str, 'g'), newStr as string);
   };
}
