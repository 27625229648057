import * as React from 'react';

import { IdName } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import BookStoreCodeService from '@services/BookstoreCodeService';

import SelectTypeAhead from '@components/SelectTypeAhead';
import { CommandProps } from '@components/CommandPalette/CommandController';

const BookstoreCodeSearch: React.FC<CommandProps> = () => {
   const search = (filters: PagedSearchFilters): Promise<PagedResponse<IdName>> =>
      BookStoreCodeService.search(filters).then((response) => ({
         ...response,
         rows: response.rows.map((x) => ({
            ...x,
            name: `${x.productName || 'Course Code'}, ${x.code}, ${
               x.bookstoreCodeActivationId ? '' : 'Not '
            }Used`,
         })),
      }));

   return (
      <div
         className='modal command-palette-modal'
         role='dialog'
         aria-label='Command Palette'
         tabIndex={-1}
      >
         <div className='command-palette-header'>
            <div className='title small'>Bookstore Code Search</div>
         </div>
         <SelectTypeAhead placeHolder='Start Typing Bookstore Code' rowLoader={search} autoFocus />
      </div>
   );
};

export default BookstoreCodeSearch;
