import * as React from 'react';

/**
 * useStateWithReset is a custom React hook that enhances the useState hook
 * by providing a mechanism to reset the state value to a subscribed value.
 *
 * @template T The type of the state value.
 * @param {T} subscribedValue The value to which the state will be reset.
 * @param {readonly unknown[]} [additionalDependencies=[]] Additional dependencies for the useEffect hook.
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]} A tuple containing the current state
 * value and a function to update the state with a reset capability.
 */
const useStateWithReset = <T>(
   subscribedValue: T,
   additionalDependencies: readonly unknown[] = [],
): [T, React.Dispatch<React.SetStateAction<T>>] => {
   const [state, setState] = React.useState<T>(subscribedValue);

   React.useEffect(() => {
      setState(subscribedValue);
   }, [subscribedValue, ...additionalDependencies]);

   return [state, setState];
};

export default useStateWithReset;
