import * as React from 'react';

import { GetTrackProps, SliderItem } from 'react-compound-slider';

interface TrackProps {
   disabled?: boolean;
   source: SliderItem;
   target: SliderItem;
   getTrackProps: GetTrackProps;
}

const Track: React.FC<TrackProps> = ({ disabled, source, target, getTrackProps }) => (
   <div
      style={{
         position: 'absolute',
         left: '0',
         width: `${target.percent - source.percent}%`,
         height: 7,
         zIndex: 1,
         backgroundColor: '#007CBB',
         borderRadius: 7,
         cursor: disabled ? 'initial' : 'pointer',
      }}
      {...getTrackProps()}
   />
);

export default React.memo(Track);
