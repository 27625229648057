import * as React from 'react';

import Button from '@components/Common/Button';
import { Select } from '@components/Core/Select';
import { Feature } from '@generated/gql/graphql';
import { StringOption } from '@models/ReactSelectHelperTypes';
import FeatureService from '@services/FeatureService';
import { SingleValue } from 'react-select';

import Table, { Column } from '@components/Common/Table';

interface FeaturesSectionProps {
   value: Feature[];
   onChange(features: Feature[]): void;
   inputRef?: unknown; // Just have this here to silence an error
}

const FeaturesSection: React.FC<FeaturesSectionProps> = (props) => {
   const [features, setFeatures] = React.useState<Feature[]>([]);

   React.useEffect(() => {
      FeatureService.getFeatures().then((fs) =>
         setFeatures(fs.map((f) => ({ id: String(f.id), feature: f.feature }))),
      );
   }, []);

   const handleAddFeature = (newValue: SingleValue<StringOption>) => {
      if (newValue) {
         props.onChange([...props.value, { id: newValue.value, feature: newValue.label }]);
      }
   };

   const handleRemoveFeature = (id: string) => {
      props.onChange(props.value.filter((i) => i.id !== id));
   };

   const selectedFeatureIds = props.value.map((i) => i.id);
   const mappedOptions = features
      .filter((i) => !selectedFeatureIds.includes(i.id))
      .map((i) => ({ value: i.id, label: i.feature }));

   const userFeatureColumns: readonly Column<Feature>[] = [
      {
         id: 'feature',
         header: 'Feature',
         canSort: true,
         sortFunc: (i) => i.feature.toLowerCase(),
         cell: (i) => i.feature,
      },
      {
         id: 'delete',
         header: 'Remove',
         headerClassName: 'text-xs',
         cell: (i) => (
            <Button subtle onClick={() => handleRemoveFeature(i.id)}>
               Remove
            </Button>
         ),
      },
   ];

   return (
      <>
         <Table<Feature>
            rowKey='id'
            className='table small user-feature-table'
            rows={props.value}
            columns={userFeatureColumns}
         />
         <Select<StringOption>
            placeholder='Add feature...'
            className='react-select margin-top-8'
            options={mappedOptions}
            onChange={handleAddFeature}
            value={null}
            isDisabled={features.length === 0}
            menuPlacement='auto'
         />
      </>
   );
};

export default FeaturesSection;
