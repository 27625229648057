import { Maybe } from '@models/Core';

import { ActivityVariableType } from './ActivityVariable';

export type ActivityLogicUnit = ActivityLogicGroup | ActivityLogicRule;

export enum ActivityLogicCombinator {
   and = 'and',
   or = 'or',
}

export interface ActivityLogicGroup {
   /** Prefixed with temp- if new, otherwise an integer */
   id: string | number;
   rules: readonly ActivityLogicUnit[];
   /** Prefixed with temp- if new, otherwise an integer */
   parentId: Maybe<string | number>;
   index: Maybe<number>;
   combinator: Maybe<ActivityLogicCombinator>;
   actions: readonly ActivityLogicAction[];
}

export enum ActivityLogicRuleOperator {
   equals = '=',
   doesNotEqual = '!=',
   greaterThan = '>',
   greaterThanOrEqualTo = '>=',
   lessThan = '<',
   lessThanOrEqualTo = '<=',
   in = 'in',
   notIn = 'notIn',
   contains = 'contains',
   beginsWith = 'beginsWith',
   endsWith = 'endsWith',
   doesNotContain = 'doesNotContain',
   doesNotBeginWith = 'doesNotBeginWith',
   doesNotEndWith = 'doesNotEndWith',
}

export enum ActivityVariableField {
   questionScore = 'question_score',
   activityScore = 'activity_score',
   tagScore = 'tag_score',
   variable = 'variable',
}

export interface ActivityLogicRule {
   /** Prefixed with temp- if new, otherwise an integer */
   id: string | number;
   /** Prefixed with temp- if new, otherwise an integer */
   parentId: string | number;
   index: Maybe<number>;
   field: Maybe<ActivityVariableField>;
   subfield: Maybe<string | number>;
   operator: Maybe<ActivityLogicRuleOperator>;
   value: Maybe<string>;
   unit: Maybe<string>;
}

export enum ActivityLogicActionType {
   goToNextQuestion = 'go_to_next_question',
   goToQuestion = 'go_to_question',
   setVariable = 'set_variable',
}

interface BaseActivityLogicAction {
   /** Prefixed with temp- if new, otherwise an integer */
   id: string | number;
   groupId: string | number;
   actionType: Maybe<ActivityLogicActionType>;
   index: number;
   executeIfTrue: boolean;
}

export interface GoToNextQuestionAction extends BaseActivityLogicAction {
   actionType: ActivityLogicActionType.goToNextQuestion;
}

export interface GoToQuestionAction extends BaseActivityLogicAction {
   actionType: ActivityLogicActionType.goToQuestion;
   /** Prefixed with temp- if new, otherwise an integer */
   questionId: Maybe<string | number>;
}

export interface SetVariableAction extends BaseActivityLogicAction {
   actionType: ActivityLogicActionType.setVariable;
   /** Prefixed with temp- if new, otherwise an integer */
   variableId: Maybe<string | number>;
   variableValue: Maybe<string | number | boolean>;
}

export interface ActivityLogicActionOption {
   value: ActivityLogicActionType;
   label: string;
   placeholder?: string;
   options?: readonly { id: string | number; label: string }[];
}

export type ActivityLogicAction =
   | BaseActivityLogicAction
   | GoToNextQuestionAction
   | GoToQuestionAction
   | SetVariableAction;

export interface ActivityLogicField {
   /** Field name */
   value: ActivityVariableField;
   /** Field label */
   label: string;
   /** Either a variable type, or a function to determine it from the variable id */
   type: ActivityVariableType | ((id: string | number) => Maybe<ActivityVariableType>);
   /** Optional list of units to choose from such as points, percentage, etc. */
   units?: readonly { value: string; label: string }[];
   /** Optional list of operators to choose from */
   operators?:
      | readonly { value: ActivityLogicRuleOperator; label: string }[]
      | ((id: string | number) => readonly {
           value: ActivityLogicRuleOperator;
           label: string;
        }[]);
   /* Optional subfield to choose from such as a specific question, variable, or tag */
   subfield?: {
      label?: string;
      placeholder?: string;
      type: ActivityVariableType;
      options?: readonly { id: string | number; label: string }[];
   };
}
