import * as React from 'react';

import { Maybe } from '@models/Core';

/**
 * Converts a React child to an element: non-empty string or number or
 * `React.Fragment` (React 16.3+) is wrapped in given tag name; empty strings
 * are discarded by default.
 *
 * @param {React.ReactNode | undefined} child - The child to be converted to an element.
 * @param {keyof JSX.IntrinsicElements} [tagName='span'] - The tag name to use for wrapping the child. Defaults to 'span'.
 * @param {JSX.IntrinsicElements['span']} [props={}] - The props to apply to the wrapping element.
 * @param {boolean} [forceElement=false] - If true, forces the creation of an element even for empty strings.
 * @returns {Maybe<React.ReactNode>} - The created element or undefined if the input is null or an unforced empty string.
 */
export const ensureElement = (
   child: React.ReactNode | undefined,
   tagName: keyof JSX.IntrinsicElements = 'span',
   props: JSX.IntrinsicElements['span'] = {},
   forceElement = false,
): Maybe<React.ReactNode> => {
   if (child === null) {
      if (forceElement) {
         const result = React.createElement(tagName, props);
         return result;
      } else {
         return undefined;
      }
   } else if (typeof child === 'string') {
      // If forceElement is true, create an element even for an empty string
      if (forceElement) {
         return React.createElement(tagName, props, child);
      } else {
         // cull whitespace strings
         return child.trim().length > 0 ? React.createElement(tagName, props, child) : undefined;
      }
   } else if (
      typeof child === 'number' ||
      (React.isValidElement(child) && typeof child.type === 'symbol')
   ) {
      // React.Fragment has a symbol type
      return React.createElement(tagName, {}, child);
   } else {
      return child;
   }
};

/**
 * Typesafe method for retrieving the name of a property for a specific type
 */
export const nameof = <T>(name: keyof T): keyof T => name;

export const sleep = (ms?: number): Promise<void> =>
   new Promise((resolve) => {
      setTimeout(resolve, ms);
   });
