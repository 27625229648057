/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import Link from '@components/Common/Link';
import EmptyState from '@components/Core/EmptyState';
import FeatureCheck from '@components/Core/FeatureCheck';
import ModalDialog from '@components/Core/ModalDialog';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import omitDeep from '@helpers/OmitDeep';
import { parseHtml } from '@helpers/ParseHtml';
import IconInterfaceAlertCircle from '@icons/nova-solid/22-Interface-Feedback/interface-alert-circle.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import ContentItemProfile from '@models/ContentItemProfile';
import ContentLibraryName from '@models/ContentLibraryName';
import { Maybe } from '@models/Core';
import {
   Assignment,
   ContentModuleItem,
   CourseInfo,
   DefaultAssignmentSettings,
   Module as ModuleType,
   ModuleItem,
   ModuleStatus,
   NonContentModuleItemType,
} from '@models/Course';
import LMSName from '@models/LMSName';
import DateTimeService from '@services/DateTimeService';
import ModuleItemService from '@services/ModuleItemService';
import ModuleService from '@services/ModuleService';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import Constants from '../../../Constants';
import mixPanelActions from '../../../Mixpanel';
import ModuleCanDoStatementEditor from './ModuleCanDoStatementEditor';
import ModuleContent from './ModuleContent';
import ModuleEditor from './ModuleEditor';
import {
   getModuleMode,
   isActivity,
   isAssigned,
   isVideo,
   isVocabSet,
   ModuleMode,
} from './ModuleUtils';

interface ModuleProps {
   accountType: AccountType;
   activeModule: Maybe<ModuleType>;
   canEditModules: boolean;
   canEditSecretCode: boolean;
   canPreviewContent: boolean;
   courseInfo: CourseInfo;
   lmsName: Maybe<LMSName>;
   moduleCount: number;
   appendNewModule(module: ModuleType): void;
   createGradingCategory(label: string): Promise<number>;
   deleteModule(): Promise<void>;
   setDraggingItemIds(itemIds: readonly number[]): void;
   updateModuleProperties(update: Partial<ModuleType>): void;
}

const Module: React.FC<ModuleProps> = ({
   accountType,
   activeModule,
   canEditModules,
   canEditSecretCode,
   canPreviewContent,
   courseInfo,
   lmsName,
   moduleCount,
   appendNewModule,
   createGradingCategory,
   deleteModule,
   setDraggingItemIds,
   updateModuleProperties,
}) => {
   const {
      routes: {
         courses: { editModule, viewModule },
      },
   } = Constants;

   const location = useLocation();
   const navigate = useNavigate();

   const mode = getModuleMode(location.pathname);

   const { walkthrough, taskState, stepId, nextStep, startWalkthrough } =
      React.useContext<IOnboardingProps>(OnboardingContext);

   const [adding, setAdding] = React.useState<readonly number[]>([]);
   const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isSaving, setIsSaving] = React.useState<boolean>(false);

   const defaultName = '';
   const defaultDescription = '';
   const defaultStatus = ModuleStatus.published;

   const getAssignmentDefaults = (): DefaultAssignmentSettings => {
      if (activeModule && mode !== ModuleMode.create) {
         const defaultValues = [
            'defaultAssignmentAllowLateSubmission',
            'defaultAssignmentAttemptsAllowed',
            'defaultAssignmentEndDate',
            'defaultAssignmentGradeAutomatically',
            'defaultAssignmentStartDate',
            'defaultAssignmentTimeLimit',
         ];
         return _.pick(activeModule, defaultValues) as DefaultAssignmentSettings;
      } else {
         // Create and return defaults
         const startDate = DateTimeService.momentNow().startOf('day').toDate();
         const endDate = moment(startDate).add(7, 'days').toDate();

         if (courseInfo.defaultDueTime) {
            endDate.setHours(courseInfo.defaultDueTime.getHours());
            endDate.setMinutes(courseInfo.defaultDueTime.getMinutes());
            endDate.setSeconds(0);
         }
         return {
            defaultAssignmentAttemptsAllowed: 1,
            defaultAssignmentTimeLimit: -1,
            defaultAssignmentEndDate: endDate,
            defaultAssignmentGradeAutomatically: true,
            defaultAssignmentAllowLateSubmission: false,
            defaultAssignmentStartDate: startDate,
         };
      }
   };

   const getEmptyAssignment = (): Assignment => {
      const { defaultAssignmentStartDate, defaultAssignmentEndDate } = getAssignmentDefaults();
      const assignment = {
         endDate:
            defaultAssignmentEndDate <= courseInfo.endDate
               ? defaultAssignmentEndDate
               : courseInfo.endDate,
         gradingCategoryId: null,
         allowLateSubmission: false,
         lockAfterClosed: false,
         shortName: '',
         startDate:
            defaultAssignmentStartDate >= courseInfo.startDate
               ? defaultAssignmentStartDate
               : courseInfo.startDate,
         trackStudentEvents: false,
         sectionDates: {},
         attemptsAllowed: -1,
      };
      if (courseInfo.defaultDueTime) {
         assignment.endDate.setHours(courseInfo.defaultDueTime.getHours());
         assignment.endDate.setMinutes(courseInfo.defaultDueTime.getMinutes());
         assignment.endDate.setSeconds(0);
      }
      return assignment;
   };

   /** Walkthrough Assign Item */
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.assignActivity &&
         stepId === 'assign_module_item'
      ) {
         nextStep();
      }
   }, [walkthrough?.id, activeModule?.items?.filter(isAssigned).length]);

   /** Walkthrough Grade Activity */
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.gradeActivity &&
         location.pathname === walkthrough?.startRoute &&
         taskState === OnboardingTaskState.initialized
      ) {
         startWalkthrough();
      }
   }, [walkthrough?.id, courseInfo.id, taskState]);

   const addContentItem = async (
      { itemId, itemType, itemName, itemLanguage }: ContentItemProfile,
      library?: ContentLibraryName,
   ): Promise<number | void> => {
      if (adding.includes(itemId) || !activeModule?.items) {
         return Promise.resolve();
      }
      setAdding((prevAdding) => [...prevAdding, itemId]);
      const newModuleItem: Omit<ContentModuleItem, 'id'> = {
         itemId,
         itemName,
         itemType,
         itemLanguage,
         index: activeModule.items.length,
      };
      const {
         defaultAssignmentAllowLateSubmission: allowLateSubmission,
         defaultAssignmentAttemptsAllowed: attemptsAllowed,
         defaultAssignmentEndDate: endDate,
         defaultAssignmentGradeAutomatically: gradeAutomatically,
         defaultAssignmentStartDate: startDate,
         defaultAssignmentTimeLimit: timeLimit,
      } = getAssignmentDefaults();
      if (isActivity(newModuleItem)) {
         newModuleItem.assignment = {
            ...getEmptyAssignment(),
            allowLateSubmission,
            attemptsAllowed,
            endDate,
            gradeAutomatically,
            startDate,
            timeLimit,
         };
      } else if (isVocabSet(newModuleItem)) {
         newModuleItem.assignment = {
            ...getEmptyAssignment(),
            startDate,
            endDate,
         };
      } else if (isVideo(newModuleItem)) {
         newModuleItem.enableNativeSubtitles = true;
         newModuleItem.enableTargetSubtitles = true;
      }
      return ModuleItemService.createModuleItem(courseInfo.id, activeModule.id, newModuleItem).then(
         (moduleItem) => {
            if (activeModule.items) {
               updateModuleProperties({ items: [...activeModule.items, moduleItem] });
               setAdding((prevAdding) => prevAdding.filter((i) => i !== itemId));
               mixPanelActions.track(
                  'Module Item Created',
                  snakeCaseKeys({
                     contentId: itemId,
                     courseId: courseInfo.id,
                     courseName: courseInfo.name,
                     id: moduleItem.id,
                     language: itemLanguage,
                     library,
                     moduleId: activeModule.id,
                     moduleName: activeModule.name,
                     name: itemName,
                     type: itemType,
                  }),
               );
               return moduleItem.id;
            }
         },
      );
   };

   const createItem = (itemType: NonContentModuleItemType): Promise<number | void> => {
      if (!activeModule?.items) {
         return Promise.resolve();
      }
      const defaultNames = {
         [NonContentModuleItemType.file]: 'New File',
         [NonContentModuleItemType.link]: 'New Link',
      };
      const name = defaultNames[itemType];
      const newModuleItem = {
         name,
         itemType,
         index: activeModule.items.length,
      };
      return ModuleItemService.createModuleItem(courseInfo.id, activeModule.id, newModuleItem).then(
         (moduleItem) => {
            if (!activeModule.items) {
               return;
            }
            updateModuleProperties({ items: [...activeModule.items, moduleItem] });

            mixPanelActions.track(
               'Module Item Created',
               snakeCaseKeys({
                  courseId: courseInfo.id,
                  courseName: courseInfo.name,
                  id: moduleItem.id,
                  moduleId: activeModule.id,
                  moduleName: activeModule.name,
                  type: itemType,
               }),
            );

            return moduleItem.id;
         },
      );
   };

   const closeDeleteModal = (): void => setIsDeleteModalOpen(false);

   const openDeleteModal = (): void => setIsDeleteModalOpen(true);

   const handleDeleteModule = async (): Promise<void> => {
      deleteModule().then(closeDeleteModal);
   };

   const editItem = (itemId: number, update: Partial<ModuleItem>): void => {
      if (activeModule?.items) {
         updateModuleProperties({
            items: activeModule.items.map((i) =>
               i.id === itemId ? { ...i, ...update } : i,
            ) as readonly ModuleItem[],
         });
      }
   };

   const handleCreate = (newModule: ModuleType): void => {
      setIsLoading(true);
      ModuleService.createModule(courseInfo.id, newModule).then((newModuleId) => {
         mixPanelActions.track(
            'Module Created',
            snakeCaseKeys({
               courseId: courseInfo.id,
               courseName: courseInfo.name,
               id: newModuleId,
               name: newModule.name,
            }),
         );
         appendNewModule({ ...newModule, id: newModuleId });
         setIsLoading(false);
      });
   };

   const handleSave = async (update: Partial<ModuleType>): Promise<void> => {
      if (!activeModule) {
         return;
      }
      setIsLoading(true);
      ModuleService.updateModule(courseInfo.id, activeModule.id, update).then(() => {
         setIsLoading(false);
         updateModuleProperties(update);
         navigate(
            viewModule
               .replace(':courseId', courseInfo.id.toString())
               .replace(':moduleId', activeModule.id.toString()),
            { replace: true },
         );
      });
   };

   const handleAssignmentDefaultsUpdate = async (
      updatedDefaults: DefaultAssignmentSettings,
      overwriteItems: boolean,
   ): Promise<void> => {
      if (overwriteItems && activeModule?.id && activeModule.items) {
         const {
            defaultAssignmentAllowLateSubmission: allowLateSubmission,
            defaultAssignmentAttemptsAllowed: attemptsAllowed,
            defaultAssignmentEndDate: endDate,
            defaultAssignmentGradeAutomatically: gradeAutomatically,
            defaultAssignmentStartDate: startDate,
            defaultAssignmentTimeLimit: timeLimit,
         } = updatedDefaults;
         const activityUpdates = activeModule.items.filter(isActivity).map((i) => ({
            id: i.id,
            assignment: {
               allowLateSubmission,
               attemptsAllowed,
               endDate,
               gradeAutomatically,
               startDate,
               timeLimit,
            },
         }));
         const vocabSetUpdates = activeModule.items
            .filter(isVocabSet)
            .map((i) => ({ id: i.id, assignment: { startDate, endDate } }));
         const itemUpdates = [...activityUpdates, ...vocabSetUpdates];
         return ModuleItemService.updateModuleItems(
            courseInfo.id,
            activeModule.id,
            itemUpdates,
         ).then(() => {
            if (!activeModule.items) {
               return;
            }
            const updatedItems = activeModule.items.map((item) => {
               const itemUpdate = itemUpdates.find((i) => i.id === item.id);
               return itemUpdate === undefined ? item : _.merge(item, itemUpdate);
            });
            updateModuleProperties({ ...updatedDefaults, items: updatedItems });
         });
      } else {
         updateModuleProperties(updatedDefaults);
         return Promise.resolve();
      }
   };

   const removeItem = async (moduleItemId: number): Promise<void> => {
      const activeModuleItem = activeModule?.items?.find((item) => item.id === moduleItemId);
      if (!activeModule || !activeModuleItem) {
         return;
      }
      const { itemName, itemType } = activeModuleItem;
      return ModuleItemService.deleteModuleItem(courseInfo.id, activeModule.id, moduleItemId).then(
         () => {
            if (!activeModule.items) {
               return;
            }
            mixPanelActions.track(
               'Module Item Deleted',
               snakeCaseKeys({
                  courseId: courseInfo.id,
                  courseName: courseInfo.name,
                  id: moduleItemId,
                  moduleId: activeModule.id,
                  moduleName: activeModule.name,
                  name: itemName,
                  type: itemType,
               }),
            );
            updateModuleProperties({
               items: activeModule.items
                  .filter((item) => item.id !== moduleItemId)
                  .map((i, j) => ({
                     ...i,
                     prerequisites: i.prerequisites
                        ? i.prerequisites.filter((x) => x.prereqItemId !== moduleItemId)
                        : [],
                     index: j,
                  })),
            });
         },
      );
   };

   const saveItem = async (itemId: number): Promise<void> => {
      if (!activeModule?.items) {
         return;
      }
      const updatedItem = _.cloneDeep(activeModule.items.find((i) => i.id === itemId));
      omitDeep(updatedItem, ['fileUrl', 'itemName', 'itemType', 'canEdit']);
      if (_.isEmpty(updatedItem)) {
         return Promise.resolve();
      }
      setIsSaving(true);

      return ModuleItemService.updateModuleItem(
         courseInfo.id,
         activeModule.id,
         itemId,
         updatedItem,
      ).then(() => {
         setIsSaving(false);
      });
   };

   if (mode === ModuleMode.view && activeModule === null) {
      return null;
   }

   const editLink = activeModule?.id
      ? editModule
           .replace(':courseId', courseInfo.id.toString())
           .replace(':moduleId', activeModule.id.toString())
      : undefined;

   const shouldShowModuleEditing =
      (canEditModules || (!!activeModule && activeModule.courseOverrideAllowEdit)) &&
      accountType === AccountType.instructor;

   return (
      <>
         <div className='module-content' data-tour='module-content'>
            {mode === ModuleMode.view && activeModule ? (
               <>
                  <div className='card inner margin-bottom-m'>
                     <div className='card-title has-button full-width'>
                        <div data-tour='module-name-heading' className='title'>
                           {activeModule.name}
                        </div>
                        {shouldShowModuleEditing && (
                           <div className='right-options-wrapper'>
                              <Link className='btn' to={editLink}>
                                 Edit
                              </Link>
                           </div>
                        )}
                     </div>
                     <div className='row' data-tour='module-description'>
                        <div className='col-xs-12'>
                           <div className='no-margin-bottom'>
                              {parseHtml(activeModule.description)}
                           </div>
                        </div>
                     </div>
                  </div>
                  <FeatureCheck feature='proficiency_tracking'>
                     <ModuleCanDoStatementEditor
                        activeModule={activeModule}
                        canEditModules={canEditModules}
                        courseCanDoStatements={courseInfo.canDoStatements}
                     />
                  </FeatureCheck>
               </>
            ) : canEditModules || activeModule ? (
               <ModuleEditor
                  assignmentDefaults={getAssignmentDefaults()}
                  courseEndDate={courseInfo.endDate}
                  courseStartDate={courseInfo.startDate}
                  description={activeModule?.description ?? defaultDescription}
                  handleCreate={handleCreate}
                  handleDelete={openDeleteModal}
                  handleSave={handleSave}
                  isLoading={isLoading}
                  itemCount={activeModule?.items?.length ?? 0}
                  moduleCount={moduleCount}
                  name={activeModule?.name ?? defaultName}
                  status={activeModule?.status ?? defaultStatus}
                  updateAssignmentDefaults={handleAssignmentDefaultsUpdate}
               />
            ) : (
               <EmptyState
                  icon={<IconInterfaceAlertCircle className='large' aria-hidden />}
                  heading='Content not available'
                  description="We're in the process of preparing content for this course.  Please check back in a few minutes."
               />
            )}
            {activeModule?.items && (
               <ModuleContent
                  accountType={accountType}
                  canEditModule={shouldShowModuleEditing}
                  canEditSecretCode={canEditSecretCode}
                  canPreviewContent={
                     canPreviewContent || activeModule.courseOverrideAllowPreviewContent
                  }
                  courseInfo={courseInfo}
                  emptyAssignment={getEmptyAssignment()}
                  isSaving={isSaving}
                  items={activeModule.items}
                  lmsName={lmsName}
                  moduleId={activeModule.id}
                  addContentItem={addContentItem}
                  createGradingCategory={createGradingCategory}
                  createItem={createItem}
                  editItem={editItem}
                  removeItem={removeItem}
                  saveItem={saveItem}
                  setDraggingItemIds={setDraggingItemIds}
                  updateModuleProperties={updateModuleProperties}
               />
            )}
         </div>
         {isDeleteModalOpen && activeModule && (
            <ModalDialog
               actions={[
                  { text: 'Delete', onClick: handleDeleteModule },
                  { text: 'Cancel', onClick: closeDeleteModal },
               ]}
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               heading='Delete Module'
               onClose={closeDeleteModal}
            >
               <p>
                  Are you sure that you want to delete "{activeModule.name}" and all of its
                  associated coursework?
               </p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
      </>
   );
};

export default Module;
