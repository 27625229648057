type MethodName =
   | 'constructor'
   | 'render'
   | 'componentWillMount'
   | 'componentDidMount'
   | 'componentWillReceiveProps'
   | 'shouldComponentUpdate'
   | 'componentWillUpdate'
   | 'componentDidUpdate'
   | 'componentWillUnmount'
   | string;

const wontBind: MethodName[] = [
   'constructor',
   'render',
   'componentWillMount',
   'componentDidMount',
   'componentWillReceiveProps',
   'shouldComponentUpdate',
   'componentWillUpdate',
   'componentDidUpdate',
   'componentWillUnmount',
];

const autobind = (context: object) => {
   const objPrototype = Object.getPrototypeOf(context);
   const toBind = Object.getOwnPropertyNames(objPrototype) as MethodName[];

   toBind.forEach((method: MethodName) => {
      const descriptor = Object.getOwnPropertyDescriptor(objPrototype, method);

      if (descriptor === undefined) {
         console.warn(`Autobind: "${method}" method not found in class.`);
         return;
      }

      if (wontBind.indexOf(method) !== -1 || typeof descriptor.value !== 'function') {
         return;
      }

      Object.defineProperty(objPrototype, method, boundMethod(objPrototype, method, descriptor));
   });
};

/**
 * From autobind-decorator (https://github.com/andreypopp/autobind-decorator/tree/master)
 * Return a descriptor removing the value and returning a getter
 * The getter will return a .bind version of the function
 * and memoize the result against a symbol on the instance
 */
const boundMethod = (objPrototype: object, method: MethodName, descriptor: PropertyDescriptor) => {
   const fn = descriptor.value as (...args: unknown[]) => unknown;

   return {
      configurable: true,
      get() {
         if (this === objPrototype || Object.prototype.hasOwnProperty.call(this, method)) {
            return fn;
         }

         const boundFn = fn.bind(this);
         Object.defineProperty(this, method, {
            value: boundFn,
            configurable: true,
            writable: true,
         });
         return boundFn;
      },
   };
};

export default autobind;
