import * as React from 'react';

import UserService from '@services/UserService';
import { OnSuggestionSelected } from 'react-autosuggest';
import { useNavigate } from 'react-router-dom';

import { UserTypeaheadFragment } from '@generated/gql/graphql';
import UserAutoSuggest from '@components/UserAutoSuggest';
import { CommandProps } from '@components/CommandPalette/CommandController';

const theme = {
   container: 'command-palette-container',
   containerOpen: 'command-container--open',
   input: 'command-input',
   inputOpen: 'command-input--open',
   inputFocused: 'command-input--focused',
   suggestionsContainer: 'command-suggestions-container',
   suggestionsContainerOpen: 'command-suggestions-container--open',
   suggestionsList: 'command-suggestions-list',
   suggestion: 'command-suggestion',
   suggestionFirst: 'command-suggestion--first',
   suggestionHighlighted: 'command-suggestion--highlighted',
   sectionContainer: 'command-palette-section-container',
   sectionContainerFirst: 'command-palette-section-container--first',
   sectionTitle: 'command-palette-section-title',
};

const ImpersonateUser: React.FC<CommandProps> = () => {
   const navigate = useNavigate();

   const onSuggestionSelected: OnSuggestionSelected<UserTypeaheadFragment> = (
      _event,
      { suggestion },
   ): void => {
      UserService.impersonateUser(suggestion.id).then(() => {
         navigate('/');
      });
   };

   return (
      <div
         className='modal command-palette-modal'
         role='dialog'
         aria-label='Command Palette'
         tabIndex={-1}
      >
         <div className='command-palette-header'>
            <div className='title'>Impersonate User</div>
         </div>
         <UserAutoSuggest
            onUserSelection={onSuggestionSelected}
            theme={theme}
            inputProps={{
               autoFocus: true,
            }}
         />
      </div>
   );
};

export default ImpersonateUser;
