import * as React from 'react';

import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { Maybe } from '@models/Core';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import { IOnboardingStep } from './Models';
import Overlay from './Overlay';

interface PostProps {
   index?: number;
   showBackButton?: boolean;
   size?: number;
   step: IOnboardingStep;
   onBackClick?(): void;
   onCloseClick(): void;
   onPrimaryClick(): void;
}

const Post: React.FC<PostProps> = ({
   index,
   size,
   showBackButton,
   step: {
      content,
      footer = true,
      overlay = true,
      backButton: stepBackButton = true,
      disableBodyScroll: disableBodyScrollProp = true,
      primaryButton = true,
      author = null,
   },
   onBackClick,
   onCloseClick,
   onPrimaryClick,
}) => {
   const [postElement, setPostElement] = React.useState<Maybe<Element>>(null);
   const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (postElement) {
         if (disableBodyScrollProp) {
            disableBodyScroll(postElement);
         }
      }
      return () => {
         clearAllBodyScrollLocks();
      };
   }, [postElement]);

   const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      setIsScrolling(!!(event.target as HTMLDivElement).scrollTop);
   };

   if (index === undefined) {
      return null;
   }

   const backButtonEnabled = showBackButton && stepBackButton !== false && index > 0 && onBackClick;

   return (
      <>
         {overlay && <Overlay />}
         <div className='onboarding-post-wrapper' ref={setPostElement}>
            <div className='onboarding-post'>
               <div
                  className={classnames('onboarding-post-header', {
                     scrolling: isScrolling,
                  })}
               >
                  {!!author && (
                     <>
                        <div className='post-avatar'>
                           <img src={author.imageUrl} />
                        </div>
                        <div className='post-author'>
                           <span className='name'>{author.name}</span>
                           <span className='company'>{` from ${author.company}`}</span>
                        </div>
                     </>
                  )}
                  <div className='close'>
                     <IconClose onClick={onCloseClick} />
                  </div>
               </div>
               <div className='onboarding-post-body' onScroll={handleScroll}>
                  {content}
               </div>
               {footer && (
                  <div
                     className={classnames('onboarding-post-footer', {
                        'back-enabled': backButtonEnabled,
                     })}
                  >
                     {backButtonEnabled && (
                        <div className='previous-button-wrapper'>
                           <Button line onClick={onBackClick}>
                              Back
                           </Button>
                        </div>
                     )}
                     <div className='step'>{`${index + 1} of ${size}`}</div>
                     {primaryButton && (
                        <div className='next-button-wrapper'>
                           <Button onClick={onPrimaryClick}>
                              {index + 1 === size ? 'Done' : 'Next'}
                           </Button>
                        </div>
                     )}
                  </div>
               )}
            </div>
         </div>
      </>
   );
};

export default Post;
