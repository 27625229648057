import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Droplist from '@components/Core/Droplist';
import DataTestLoader from '@components/DataTestLoader';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { IOnboardingProps, withOnboarding } from '@components/Onboarding';
import { faker } from '@faker-js/faker';
import autobind from '@helpers/autobind';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconFileDownload2 from '@icons/nova-line/85-Files-Basic/file-download-2.svg';
import AccountType from '@models/AccountType';
import { Breadcrumb } from '@models/Breadcrumbs';
import { IdName, Maybe, MessageResponse } from '@models/Core';
import {
   AssignableModuleItemType,
   CourseSection,
   GradingCategory,
   ModuleProfile,
} from '@models/Course';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import { CSVLink } from 'react-csv';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { RouteComponentProps } from '../../../types/Routing';
import GradebookFilters from './GradebookFilters';
import GradebookGrid from './GradebookGrid';
import {
   buildGradebookCSV,
   getGradeDataByAssignment,
   getGradeDataByGradingCategory,
   getGradeDataByModule,
   GradeData,
} from './Utils';

type SubmissionInfo = Record<
   number,
   {
      lastUpdated?: Date;
      score: number;
      modifiedOn?: Date;
      numGraded?: number;
      percentGraded?: number;
      submissionId?: number;
   }
>;

export enum GradebookGrouping {
   gradingCategory = 'gradingCategory',
   module = 'module',
   assignment = 'assignment',
}

export interface GradeRange {
   min: number;
   max: number;
}

export interface GradebookSettings {
   green: GradeRange;
   yellow: GradeRange;
   red: GradeRange;
}

export interface GradebookAssignmentProfile {
   contentId: number;
   endOfModule: boolean;
   gradingCategoryId: number;
   index: number;
   itemType: AssignableModuleItemType;
   moduleId: number;
   moduleItemId: number;
   name: string;
   needsGrading: boolean;
   pointsPossible: number;
   shortName: string;
   submissions: SubmissionInfo;
}

export interface EnrollmentProfile {
   accountType: AccountType;
   email: string;
   enrollmentId: number;
   excludedFromCalc: boolean;
   firstName: string;
   lastName: string;
   lastSeen?: Date;
   profileImageUrl: string;
   sections?: readonly IdName[];
   userId: number;
}

export interface GradebookProfile extends EnrollmentProfile {
   grade: number;
   canvas?: {
      loginId: string;
      sisUserId: string;
   };
}

interface GetGradebookResponse {
   assignments: readonly GradebookAssignmentProfile[];
   courseName: string;
   gradebookSettings: GradebookSettings;
   gradingCategories: readonly GradingCategory[];
   modules: Record<number, ModuleProfile>;
   roster: readonly GradebookProfile[];
   sections: readonly CourseSection[];
}

export interface GradebookProps extends IOnboardingProps, RouteComponentProps {}

interface GradebookState {
   assignments: readonly GradebookAssignmentProfile[];
   courseId: Maybe<number>;
   courseName: string;
   displayGradesAs: 'points' | 'percentage';
   filteredAssignmentModuleItemIds: readonly number[];
   filteredAssignments: readonly GradebookAssignmentProfile[];
   filteredRosterUserIds: readonly number[];
   filterMenuOpen: boolean;
   gradebookSettings: Maybe<GradebookSettings>;
   gradingCategories: readonly GradingCategory[];
   groupGradesBy: GradebookGrouping;
   isFetching: boolean;
   modules: Record<number, ModuleProfile>;
   roster: readonly GradebookProfile[];
   sections: readonly CourseSection[];
   showDemoStudents: boolean;
   showItemsWithNoGradingCategory: boolean;
   showNeedsGrading: boolean;
   showStudentsWithNoSection: boolean;
   sortRosterBy: string;
}

class Gradebook extends React.Component<GradebookProps, GradebookState> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   constructor(props: GradebookProps) {
      super(props);
      autobind(this);

      this.state = {
         assignments: [],
         filteredAssignments: [],
         courseId: null,
         courseName: '',
         displayGradesAs: 'percentage',
         filteredAssignmentModuleItemIds: [],
         filteredRosterUserIds: [],
         filterMenuOpen: false,
         gradebookSettings: null,
         showNeedsGrading: false,
         gradingCategories: [],
         groupGradesBy: GradebookGrouping.assignment,
         isFetching: false,
         modules: {},
         roster: [],
         sections: [],
         showDemoStudents: false,
         showItemsWithNoGradingCategory: true,
         showStudentsWithNoSection: true,
         sortRosterBy: 'lastName',
      };
   }

   componentDidMount(): void {
      const {
         params: { courseId },
      } = this.props;
      if (courseId) {
         this.fetchGradebook(parseInt(courseId));
      }
   }

   componentDidUpdate(prevProps: GradebookProps, prevState: GradebookState): void {
      const {
         params: { courseId: prevCourseId },
      } = prevProps;
      const {
         params: { courseId },
      } = this.props;
      if (courseId && prevCourseId !== courseId) {
         this.fetchGradebook(parseInt(courseId));
      }

      const {
         assignments: prevAssignments,
         filteredAssignmentModuleItemIds: prevFilteredAssignmentModuleItemIds,
         showNeedsGrading: prevShowNeedsGrading,
         groupGradesBy: prevGroupGradesBy,
      } = prevState;
      const { assignments, filteredAssignmentModuleItemIds, showNeedsGrading, groupGradesBy } =
         this.state;

      if (
         assignments !== prevAssignments ||
         filteredAssignmentModuleItemIds !== prevFilteredAssignmentModuleItemIds ||
         showNeedsGrading !== prevShowNeedsGrading ||
         groupGradesBy !== prevGroupGradesBy
      ) {
         this.setFilteredAssignments();
      }
   }

   fetchGradebook(courseId: number): void {
      const {
         routes: {
            courses: { dashboard, gradebook },
         },
      } = Constants;
      this.setState({ isFetching: true });
      const url = `/api/courses/${courseId}/gradebook`;
      HttpService.getWithAuthToken<GetGradebookResponse>(url).then(async (response) => {
         const {
            assignments,
            courseName,
            gradingCategories,
            gradebookSettings,
            modules,
            roster,
            sections,
         } = response.data;
         const patchedRoster = (await UserService.checkFeature('fake_names'))
            ? roster.map((i) => ({
                 ...i,
                 firstName: faker.name.firstName(),
                 lastName: faker.name.lastName(),
              }))
            : roster;
         this.setState(
            {
               assignments,
               courseId,
               courseName,
               gradingCategories: gradingCategories.map((i) => ({
                  ...i,
                  show: true,
               })),
               gradebookSettings,
               isFetching: false,
               modules,
               roster: patchedRoster,
               sections,
            },
            this.setFilteredKeys,
         );
         const breadcrumbs: readonly Breadcrumb[] = [
            {
               link: dashboard.replace(':courseId', courseId.toString()),
               text: courseName,
               contextInfo: { courseId },
            },
            {
               link: gradebook.replace(':courseId', courseId.toString()),
               text: 'Gradebook',
            },
         ];
         this.context.setBreadcrumbs({
            breadcrumbs,
            next: null,
            prev: null,
         });
      });
   }

   handleChange(event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void {
      const target = event.target as HTMLSelectElement & HTMLInputElement;
      const { name, type, value } = target;
      const inputValue: string | boolean = type === 'checkbox' ? target.checked : value;

      this.setState(
         (prevState) =>
            ({
               ...prevState,
               [name]: inputValue,
            }) as Pick<GradebookState, keyof GradebookState>,
         this.setFilteredKeys,
      );
   }

   handleSortRosterByChange(event: React.ChangeEvent<HTMLSelectElement>): void {
      const value = event.target.value as keyof GradebookProfile;
      const lowerCaseSort = (item: GradebookProfile): number | string | null => {
         const i = item[value];
         if (_.isString(i)) {
            return i.toLowerCase();
         } else if (typeof i === 'number') {
            return i;
         }
         return null;
      };
      this.setState((prevState) => ({
         sortRosterBy: value,
         roster: _.orderBy(prevState.roster, [lowerCaseSort], ['asc']),
      }));
   }

   handleShowDemoStudentsChange(event: React.ChangeEvent<HTMLSelectElement>): void {
      const { value } = event.target;
      this.setState({ showDemoStudents: value === 'show' }, this.setFilteredKeys);
   }

   handleNeedsGradingChange(): void {
      this.setState((prevState) => ({
         showNeedsGrading: !prevState.showNeedsGrading,
      }));
   }

   handleModulesFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
      const { courseId } = this.state;
      const moduleId = Number(event.target.id);
      const showInGradebook = event.target.checked;
      const url = `/api/courses/${courseId}/modules/${moduleId}`;
      HttpService.patchWithAuthToken<MessageResponse>(url, snakeCaseKeys({ showInGradebook }));
      this.setState(
         (prevState) => ({
            modules: {
               ...prevState.modules,
               [moduleId]: {
                  ...prevState.modules[moduleId],
                  showInGradebook,
               },
            },
         }),
         this.setFilteredKeys,
      );
   }

   handleSectionsFilterChange(show: boolean, sectionId: number): void {
      const { courseId } = this.state;

      const url = `/api/courses/${courseId}/sections/${sectionId}`;
      HttpService.patchWithAuthToken<MessageResponse>(url, snakeCaseKeys({ show }));
      this.setState(
         (prevState) => ({
            sections: prevState.sections.map((i) => (i.id === sectionId ? { ...i, show } : i)),
         }),
         this.setFilteredKeys,
      );
   }

   handleGradingCategoriesFilterChange(event: React.ChangeEvent<HTMLInputElement>): void {
      const gradingCategory = Number(event.target.id);
      const show = event.target.checked;
      this.setState(
         (prevState) => ({
            gradingCategories: prevState.gradingCategories.map((i) =>
               i.id === gradingCategory ? { ...i, show } : i,
            ),
         }),
         this.setFilteredKeys,
      );
   }

   setFilteredAssignments(): void {
      const { assignments, filteredAssignmentModuleItemIds, showNeedsGrading, groupGradesBy } =
         this.state;
      if (showNeedsGrading && groupGradesBy === GradebookGrouping.assignment) {
         const roster = this.getFilteredRoster();
         const filteredAssignments = assignments.filter((i) =>
            filteredAssignmentModuleItemIds.includes(i.moduleItemId),
         );
         const filteredAssignmentsNeedsGrading = filteredAssignments.filter((i) =>
            roster.some((u) => (i.submissions[u.userId]?.percentGraded ?? 0) < 1),
         );
         this.setState({
            filteredAssignments: filteredAssignmentsNeedsGrading,
         });
      } else {
         this.setState({
            filteredAssignments: assignments.filter((i) =>
               filteredAssignmentModuleItemIds.includes(i.moduleItemId),
            ),
         });
      }
   }

   getFilteredRoster(): readonly GradebookProfile[] {
      const { roster, filteredRosterUserIds } = this.state;
      return roster.filter((i) => filteredRosterUserIds.includes(i.userId));
   }

   getAssignmentByModuleItemId(moduleItemId: number): Maybe<GradebookAssignmentProfile> {
      return this.state.assignments.find((i) => i.moduleItemId === moduleItemId);
   }

   renderExportGradesItems(
      roster: readonly GradebookProfile[],
      gradeData: readonly GradeData[],
   ): readonly React.ReactElement[] {
      const { sections, filteredAssignments } = this.state;
      if (!filteredAssignments.length) {
         return [];
      }

      const getCSVData = (format = 'Standard'): readonly (readonly string[])[] =>
         buildGradebookCSV(format, roster, sections, gradeData);

      return [
         <CSVLink
            filename='LingcoStandard_export.csv'
            data={getCSVData()}
            key='standard'
            className='black-text'
         >
            Standard CSV
         </CSVLink>,
         <CSVLink
            filename='Lingco2Blackboard_export.csv'
            data={getCSVData('Blackboard')}
            key='blackboard'
            className='black-text'
         >
            Blackboard CSV
         </CSVLink>,
         <CSVLink
            filename='Lingco2Canvas_export.csv'
            data={getCSVData('Canvas')}
            key='canvas'
            className='black-text'
         >
            Canvas CSV
         </CSVLink>,
         <CSVLink
            filename='Lingco2D2L_export.csv'
            data={getCSVData('D2L')}
            key='d2l'
            className='black-text'
         >
            Desire2Learn CSV
         </CSVLink>,
      ];
   }

   getGradeData(filteredRoster: readonly GradebookProfile[]): readonly GradeData[] {
      const { filteredAssignments, groupGradesBy, modules, gradingCategories, courseId } =
         this.state;
      if (groupGradesBy === GradebookGrouping.module && courseId) {
         return getGradeDataByModule(filteredRoster, filteredAssignments, modules, courseId);
      } else if (groupGradesBy === GradebookGrouping.gradingCategory) {
         return getGradeDataByGradingCategory(
            filteredRoster,
            filteredAssignments,
            gradingCategories,
         );
      }
      return getGradeDataByAssignment(filteredRoster, filteredAssignments);
   }

   setFilteredKeys(): void {
      this.setState((prevState) => {
         const visibleSectionIds = prevState.sections.filter((i) => i.show).map((i) => i.id);
         const visibleModuleIds = Object.values(prevState.modules)
            .filter((i) => i.showInGradebook)
            .map((i) => i.id);
         const visibleGradingCategoryIds = prevState.gradingCategories
            .filter((i) => i.show)
            .map((i) => i.id);
         const filterSections = (i: GradebookProfile): boolean => {
            const noFilter = !visibleSectionIds.length && !prevState.showStudentsWithNoSection;
            const noSection = prevState.showStudentsWithNoSection && !i.sections?.length;

            if (noFilter || noSection || !i.sections) {
               return true;
            }

            return (
               i.sections.filter((section) => visibleSectionIds.includes(section.id)).length > 0
            );
         };
         const filterDemoStudents = (i: GradebookProfile): boolean =>
            prevState.showDemoStudents || !i.excludedFromCalc;
         const filterGradingCategory = (i: GradebookAssignmentProfile): boolean =>
            (prevState.showItemsWithNoGradingCategory && !i.gradingCategoryId) ||
            visibleGradingCategoryIds.includes(i.gradingCategoryId);
         const filterAssignments = (i: GradebookAssignmentProfile): boolean =>
            visibleModuleIds.includes(i.moduleId) && filterGradingCategory(i);

         const filteredRoster = prevState.roster.filter(
            (i) => filterDemoStudents(i) && filterSections(i),
         );

         return {
            filteredAssignmentModuleItemIds: prevState.assignments
               .filter(filterAssignments)
               .map((i) => i.moduleItemId),
            filteredRosterUserIds: filteredRoster.map((i) => i.userId),
         };
      });
   }

   toggleShowItemsWithNoGradingCategory(): void {
      this.setState(
         (prevState) => ({
            showItemsWithNoGradingCategory: !prevState.showItemsWithNoGradingCategory,
         }),
         this.setFilteredKeys,
      );
   }

   toggleShowStudentsWithNoSection(): void {
      this.setState(
         (prevState) => ({
            showStudentsWithNoSection: !prevState.showStudentsWithNoSection,
         }),
         this.setFilteredKeys,
      );
   }

   render(): React.ReactNode {
      const {
         assignments,
         courseName,
         courseId,
         displayGradesAs,
         gradebookSettings,
         gradingCategories,
         groupGradesBy,
         isFetching,
         showNeedsGrading,
         modules,
         roster: unfilteredRoster,
         sections,
         showDemoStudents,
         showItemsWithNoGradingCategory,
         showStudentsWithNoSection,
         filteredAssignments,
         sortRosterBy,
      } = this.state;

      if (isFetching || !courseId || !gradebookSettings) {
         return <Loader />;
      }

      const hasDemoStudents = unfilteredRoster.some((i) => i.excludedFromCalc);
      const filteredRoster = this.getFilteredRoster();
      const gradeData = this.getGradeData(filteredRoster);
      const exportGradesItems = this.renderExportGradesItems(filteredRoster, gradeData);
      const assignmentCount = assignments.length;
      const rosterCount = unfilteredRoster.length;

      return (
         <div className='content-main margin-right-m'>
            <DataTestLoader isLoading={isFetching} />
            <DocumentTitle>
               {isFetching ? 'Loading Gradebook...' : `Gradebook - ${courseName}`}
            </DocumentTitle>
            <div className='card no-padding'>
               <div className='card-title has-button'>
                  <div className='content-title-wrapper'>
                     <div className='title'>Gradebook</div>
                     <div className='icon-action-wrap title-dropdown'>
                        <GradebookFilters
                           displayGradesAs={displayGradesAs}
                           gradingCategories={gradingCategories}
                           groupGradesBy={groupGradesBy}
                           hasDemoStudents={hasDemoStudents}
                           needsGrading={showNeedsGrading}
                           modules={modules}
                           roster={filteredRoster}
                           sections={sections}
                           showDemoStudents={showDemoStudents}
                           showItemsWithNoGradingCategory={showItemsWithNoGradingCategory}
                           showStudentsWithNoSection={showStudentsWithNoSection}
                           sortRosterBy={sortRosterBy}
                           onChange={this.handleChange}
                           onGradingCategoriesFilterChange={
                              this.handleGradingCategoriesFilterChange
                           }
                           onModulesFilterChange={this.handleModulesFilterChange}
                           onNeedsGradingChange={this.handleNeedsGradingChange}
                           onSectionsFilterChange={this.handleSectionsFilterChange}
                           onShowDemoStudentsChange={this.handleShowDemoStudentsChange}
                           onSortRosterByChange={this.handleSortRosterByChange}
                           toggleShowItemsWithNoGradingCategory={
                              this.toggleShowItemsWithNoGradingCategory
                           }
                           toggleShowStudentsWithNoSection={this.toggleShowStudentsWithNoSection}
                        />
                     </div>
                  </div>
                  <div className='right-options-wrapper gradebook-header'>
                     <Droplist pullRight items={exportGradesItems}>
                        <Button line icon={<IconFileDownload2 aria-hidden />}>
                           Export
                        </Button>
                     </Droplist>
                  </div>
               </div>
               <GradebookGrid
                  assignments={filteredAssignments}
                  courseName={courseName}
                  courseId={courseId}
                  displayGradesAs={displayGradesAs}
                  gradeData={gradeData}
                  gradebookSettings={gradebookSettings}
                  groupGradesBy={groupGradesBy}
                  roster={filteredRoster}
                  showNeedsGrading={showNeedsGrading}
                  totalAssignments={assignmentCount}
                  totalRoster={rosterCount}
               />
            </div>
         </div>
      );
   }
}

export default withOnboarding(Gradebook);
