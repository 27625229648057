import {
   SearchBoxAdministrativeUnitTypes,
   SearchBoxCore,
   SearchBoxRetrieveResponse,
   SearchBoxSuggestion,
   SessionToken,
} from '@mapbox/search-js-core';

import Config from '../Config';

const sessionToken = new SessionToken();
const search = new SearchBoxCore({
   accessToken: Config.mapBoxAccessToken,
});
const suggest = async (searchText: string): Promise<SearchBoxSuggestion[]> => {
   const response = await search
      .suggest(searchText, {
         sessionToken,
         limit: 10,
         types: new Set<SearchBoxAdministrativeUnitTypes>([
            'country',
            'district',
            'neighborhood',
            'postcode',
            'region',
            'place',
         ]),
      })
      .then((r) => r.suggestions);

   const filteredResponse = response.filter((x) => x.place_formatted);
   if (response.length !== filteredResponse.length) {
      console.warn('Some results were missing place_formatted and have been removed.');
   }
   return filteredResponse;
};

const getDetails = async (
   searchBoxSuggestion: SearchBoxSuggestion,
): Promise<SearchBoxRetrieveResponse> => {
   const response = await search.retrieve(searchBoxSuggestion, { sessionToken }).then((r) => r);
   if (response.features[0].properties.place_formatted === undefined) {
      response.features[0].properties.place_formatted = searchBoxSuggestion.place_formatted;
      console.warn('Retrieved undefined place_formatted, overridden by searchBoxSuggestion.');
   }
   return response;
};

export default {
   suggest,
   getDetails,
};
