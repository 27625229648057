import * as React from 'react';

import InfoTooltip from '@components/InfoTooltip';
import {
   ActivityBuilderMode,
   FillBlanksPrompt,
   FillBlanksScoringMethod,
   FillBlanksSize,
} from '@models/Activity';

import Switch from '@components/Common/Switch';
import { PromptOptionsProps } from '@components/Activity/Builder/Prompt';

const FillBlanksPromptOptions: React.FC<
   PromptOptionsProps<FillBlanksPrompt<ActivityBuilderMode>>
> = ({ prompt, onUpdate }) => {
   const handleScoringMethodChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const scoringMethod = event.target.value as FillBlanksScoringMethod;
      onUpdate({ scoringMethod });
   };

   const handleBlankTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const blankType = event.target.value as FillBlanksSize;
      onUpdate({ blankType });
   };

   return (
      <div>
         <div>
            <label className='field-title'>
               Scoring
               <InfoTooltip>
                  Use Case Insensitive to not count capitalization. Regular Expressions are for
                  advanced users.
               </InfoTooltip>
            </label>
            <select
               name='scoringMethod'
               value={prompt.scoringMethod}
               onChange={handleScoringMethodChange}
            >
               <option value={FillBlanksScoringMethod.caseInsensitive}>Case Insensitive</option>
               <option value={FillBlanksScoringMethod.caseSensitive}>Case Sensitive</option>
               <option value={FillBlanksScoringMethod.regex}>Regular Expression</option>
            </select>
         </div>
         <div className='margin-top-xs'>
            <label className='field-title'>
               Blank Sizes
               <InfoTooltip>
                  Use based on content for a random width 100-120% of the answer&apos;s size.
                  Otherwise use equal to make the blanks all the same size.
               </InfoTooltip>
            </label>
            <select name='blankType' value={prompt.blankType} onChange={handleBlankTypeChange}>
               <option value={FillBlanksSize.fitToContent}>Based on Content</option>
               <option value={FillBlanksSize.fitToLargestBlank}>Equal</option>
            </select>
         </div>
         <div className='margin-top-s'>
            <Switch
               name='draggable'
               checked={prompt.draggable}
               onChange={() => onUpdate({ draggable: !prompt.draggable })}
               tooltip='Students can drag and drop words from a word bank.'
            >
               Draggable
            </Switch>
         </div>
         {prompt.draggable && (
            <div className='margin-top-s'>
               <Switch
                  name='static-word-bank'
                  checked={prompt.showUniqueWords}
                  onChange={() => onUpdate({ showUniqueWords: !prompt.showUniqueWords })}
                  tooltip="If a word appears twice in the word bank, we'll only show it once."
               >
                  Show Unique Words
               </Switch>
            </div>
         )}
      </div>
   );
};

export default FillBlanksPromptOptions;
