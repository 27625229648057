import VocabSet from '@models/VocabSet';
import HttpService from '@services/HttpService';

const getWithProgress = (vocabSetId: number): Promise<VocabSet<true>> =>
   HttpService.getWithAuthToken<VocabSet<true>>(
      `/api/content/vocab_sets/${vocabSetId}?progress=true`,
   ).then((response) => response.data);

const get = (vocabSetId: number): Promise<VocabSet<false>> =>
   HttpService.getWithAuthToken<VocabSet<false>>(
      `/api/content/vocab_sets/${vocabSetId}?progress=true`,
   ).then((response) => response.data);

export default {
   getWithProgress,
   get,
};
