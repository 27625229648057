import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowLeft from '@icons/general/icon-arrow-left.svg';
import classnames from 'classnames';

import Constants from '../../../Constants';
import InfoTooltip from '@components/InfoTooltip';

interface ModuleItemSettingsAccordionProps {
   badgeValue?: string | number;
   bodyClass?: string;
   children: React.ReactNode;
   collapsible?: boolean;
   icon?: React.ReactNode;
   isOpen?: boolean;
   title: string;
   tooltip?: string;
   'data-tour'?: string;
   'data-test'?: string;
   onOpen?(): void;
   onToggle?(): void;
}

const ModuleItemSettingsAccordion: React.FC<ModuleItemSettingsAccordionProps> = ({
   children,
   title,
   tooltip,
   badgeValue,
   icon,
   isOpen = true,
   collapsible = false,
   bodyClass,
   onOpen,
   onToggle,
   ...rest
}) => {
   const {
      keys: { enter, space },
   } = Constants;

   const toggleIsOpen = (): void => {
      if (!collapsible) {
         return;
      }
      onToggle?.();
      if (isOpen && onOpen) {
         onOpen();
      }
   };

   const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === enter || event.key === space) {
         toggleIsOpen();
         event.preventDefault();
      }
   };

   return (
      <div
         className={classnames('module-content-settings-accordion', {
            open: isOpen,
         })}
         {...rest}
      >
         <div
            className={classnames('accordion-header', { collapsible })}
            aria-expanded={isOpen}
            tabIndex={0}
            role='button'
            onClick={toggleIsOpen}
            onKeyDown={handleKeyPress}
         >
            {icon}
            <div className='accordion-header-title'>
               <div className='title'>{title}</div>
               {badgeValue && <div className='badge'>{badgeValue}</div>}
               {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
            </div>
            {collapsible && (
               <div className='accordion-arrow'>
                  {isOpen ? <IconArrowDown /> : <IconArrowLeft />}
               </div>
            )}
         </div>
         {isOpen && <div className={classnames('accordion-body', bodyClass)}>{children}</div>}
      </div>
   );
};

export default ModuleItemSettingsAccordion;
