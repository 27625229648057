import * as React from 'react';

import { Maybe } from '@models/Core';
import { Placement } from '@popperjs/core';

import { CursorProps } from './Cursor';
import { IOverlayProps } from './Overlay';

export interface IOnboardingProps<P = unknown, S = unknown> {
   checklistOpen: boolean;
   completedOnboardingTasks: readonly OnboardingTask[];
   demoCourseId: Maybe<number>;
   observers: readonly MutationObserver[];
   stepId: Maybe<string>;
   task: Maybe<IOnboardingTask>;
   taskState: OnboardingTaskState;
   walkthrough: Maybe<IOnboardingWalkthrough>;
   walkthroughVisible: boolean;
   clearCurrentTask(): void;
   getOnboardingClassName?(stepId: string): string;
   nextStep(): void;
   onStateUpdate(
      newState: Maybe<S>,
      prevState: Maybe<S>,
      newProps?: Maybe<P>,
      prevProps?: Maybe<P>,
   ): void;
   previousStep(): void;
   setChecklistOpen(checklistOpen: boolean | ((prevChecklistOpen: boolean) => boolean)): void;
   setStepId(stepId: string): void;
   setTask(task: IOnboardingTask): void;
   setWalkthroughVisible(walkthroughVisible: boolean): void;
   startWalkthrough(): void;
   walkthroughIsComplete(walkthroughId: OnboardingWalkthrough): boolean;
}

export enum OnboardingStepType {
   beacon = 'beacon',
   custom = 'custom',
   post = 'post',
   videoPost = 'videoPost',
   pointer = 'pointer',
   videoPointer = 'videoPointer',
}

export enum OnboardingTaskState {
   closed = 'closed',
   initialized = 'initialized',
   started = 'started',
   inProgress = 'inProgress',
   completed = 'completed',
}

export enum OnboardingTask {
   createActivity = 'create_activity',
   exploreContentLibrary = 'explore_content_library',
   assignActivity = 'assign_activity',
   gradeActivity = 'grade_activity',
   exploreGradebook = 'explore_gradebook',
}

export enum OnboardingWalkthrough {
   activityBuilderIntro = 'activity_builder_intro',
   assignActivity = 'assign_activity',
   contentLibraryIntro = 'content_library_intro',
   discussionActivity = 'discussion_activity',
   gradeActivity = 'grade_activity',
   gradebookIntro = 'gradebook_intro',
   lessonBuilderIntro = 'lesson_builder_intro',
   recordingActivity = 'recording_activity',
   videoComprehensionActivity = 'video_comprehension_activity',
   vocabBuilderIntro = 'vocab_builder_intro',
}

export interface IOnboardingStep<P = unknown, S = unknown> {
   advanceDelay?: number;
   advanceWhenHidden?: string;
   advanceWhenVisible?: string;
   author?: {
      name: string;
      company: string;
      imageUrl: string;
   };
   backButton?: boolean;
   className?: string;
   component?: React.ReactElement;
   confetti?: boolean;
   content?: string | React.ReactElement;
   cursor?: CursorProps;
   disableBodyScroll?: boolean;
   disableInteraction?: boolean;
   expectedLocationChanges?: readonly (string | RegExp)[];
   fallbackPlacements?: readonly Placement[];
   footer?: boolean;
   heading?: React.ReactElement;
   hideWhenRemoved?: string;
   hideWhenVisible?: string;
   id: string;
   overlay?: boolean;
   overlayOptions?: Partial<IOverlayProps>;
   placement?: Placement;
   primaryButton?: boolean;
   selector?: string;
   type: OnboardingStepType;
   videoUrl?: string;
   wistiaId?: string;
   beforeUnmount?(): void;
   onCloseClick?(): void;
   onEnd?(): void;
   onMount?(element: Element): void;
   onPause?(): void;
   onPlay?(): void;
   onPrimaryClick?(): void;
   shouldAdvance?(state: S, prevState?: S, props?: P, prevProps?: P): boolean;
}

export interface IOnboardingTask {
   id: OnboardingTask;
   title: string;
   walkthrough: IOnboardingWalkthrough;
   repeatable?: boolean;
}

export interface IOnboardingWalkthrough {
   id: OnboardingWalkthrough;
   startRoute?: string;
   showBackButton?: boolean;
   steps: readonly IOnboardingStep[];
   isComplete?(stepId: Maybe<string>): boolean;
}
