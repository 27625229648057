import * as React from 'react';

import IconAdd1 from '@icons/nova-solid/27-Remove&Add/add-1.svg';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import Droplist from '@components/Core/Droplist';
import { PredicateEditorContext } from './PredicatesEditor';

interface AttributePickerOpenerProps {
   className?: string;
   inline?: boolean;
   disabled?: boolean;
   tooltip?: string;
   onAdd(attribute: string): void;
}

const AttributePickerOpener: React.FC<AttributePickerOpenerProps> = ({
   className,
   inline,
   disabled = false,
   tooltip,
   onAdd,
}) => {
   const { attributes } = React.useContext<PredicateEditorContext>(PredicateEditorContext);

   const droplistItems = attributes.map((i) => {
      const Icon = i.icon;
      return {
         text: i.label,
         icon: <Icon />,
         onClick: () => onAdd(i.identifier),
      };
   });

   return (
      <div className={classnames('add-filter', className)}>
         <Tippy content={tooltip} disabled={!tooltip}>
            <span>
               <Droplist items={droplistItems} disabled={disabled} pullRight={inline}>
                  <Button
                     icon={<IconAdd1 aria-hidden />}
                     className={classnames('add-filter-btn', {
                        subtle: !inline,
                        blue: !inline,
                        disabled,
                     })}
                  >
                     {!inline && 'Add Filter'}
                  </Button>
               </Droplist>
            </span>
         </Tippy>
      </div>
   );
};

export default AttributePickerOpener;
