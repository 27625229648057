import * as React from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import Select from 'react-select';

import BookstoreCodeService, {
   GetBookstoreCodeParams,
   ProductSummary,
} from '@services/BookstoreCodeService';
import Button from '@components/Common/Button';
import CardBody from '@components/Common/CardBody';
import Link from '@components/Common/Link';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';

const searchFields = yup.object().shape({
   quantity: yup.number().required(),
   product: yup.number().required(),
   distributedTo: yup.string().required(),
});

type BookstoreCodeForm = {
   quantity: number;
   product: number;
   distributedTo: string;
};

const BookstoreCodeGenerator: React.FC = () => {
   const [products, setProducts] = React.useState<readonly ProductSummary[]>([]);
   const [codeResponse, setCodeResponse] = React.useState<Blob>();
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   // Get products that codes can be generated for.
   React.useEffect(() => {
      BookstoreCodeService.getProductsWithCanHaveBookstoreCodes().then((resp) => {
         setProducts(resp);
      });
   }, []);

   const { handleSubmit, register, control } = useForm({
      resolver: yupResolver<BookstoreCodeForm>(searchFields),
   });

   const onSubmit = (data: BookstoreCodeForm): void => {
      setIsLoading(true);
      const requestParams: GetBookstoreCodeParams = {
         quantity: data.quantity,
         productId: data.product,
         distributedTo: data.distributedTo,
      };

      BookstoreCodeService.generateCodes(requestParams).then((response) => {
         setCodeResponse(response);
         setIsLoading(false);
      });
   };

   const onInvalid = (errors: FieldErrors) => console.error(errors);

   return (
      <>
         <CardBody documentTitle='Bookstore Code Generator' cardTitle='Bookstore Code Generator'>
            <form className='padding-l' onSubmit={handleSubmit(onSubmit, onInvalid)}>
               <div className='flex flex-gap-s'>
                  <div className='flex-1'>
                     <label className='field-title'>Product</label>
                     <Controller
                        control={control}
                        name='product'
                        render={({ field }) => (
                           <Select
                              options={products}
                              onChange={(option) => {
                                 if (option) {
                                    field.onChange(option.id);
                                 }
                              }}
                              className='react-select'
                              formatOptionLabel={(option) => (
                                 <p>
                                    {option.name} {option.isbn ? `| ISBN: ${option.isbn}` : ''}
                                 </p>
                              )}
                              getOptionValue={(option) => String(option.id)}
                              value={products.find((c) => c.id === field.value)}
                              required
                           />
                        )}
                     />
                  </div>
               </div>
               <div className='flex-1'>
                  <label className='field-title'>Quantity</label>
                  <input {...register('quantity')} type='number' min='1' step='1' />
               </div>
               <div className='flex-1'>
                  <label className='field-title'>
                     Distributed To
                     <InfoTooltip>
                        Name of the publisher or retailer these codes will be given to, e.g. "GUP"
                        or "MTSU Bookstore"
                     </InfoTooltip>
                  </label>
                  <input {...register('distributedTo')} type='text' />
               </div>
               <div className='flex justify-center'>
                  <Button className='flex-1 margin-top-m max-third-width' type='submit'>
                     Generate Codes
                  </Button>
               </div>
            </form>
         </CardBody>
         {isLoading && <Loader />}
         {codeResponse !== undefined && (
            <CardBody cardTitle='Codes'>
               <div className='flex'>
                  <p className='padding-l'>
                     Your codes have been generated! Click{' '}
                     <Link to={URL.createObjectURL(codeResponse)} external>
                        here
                     </Link>{' '}
                     to redownload them, or refresh the page to generate more codes (you will be
                     unable to redownload this batch of codes).
                  </p>
               </div>
            </CardBody>
         )}
      </>
   );
};

export default BookstoreCodeGenerator;
