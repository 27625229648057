export enum GoogleClassroomCourseState {
   courseStateUnspecified = 'COURSE_STATE_UNSPECIFIED',
   active = 'ACTIVE',
   archived = 'ARCHIVED',
   provisioned = 'PROVISIONED',
   declined = 'DECLINED',
   suspended = 'SUSPENDED',
}

interface IDriveFolder {
   id: string;
   title: string;
   alternateLink: string;
}

export interface IGoogleClassroomCourse {
   id: string;
   name: string;
   section: string;
   descriptionHeading: string;
   description: string;
   room: string;
   ownerId: string;
   creationTime: string;
   updateTime: string;
   enrollmentCode: string;
   courseState: GoogleClassroomCourseState;
   alternateLink: string;
   teacherGroupEmail: string;
   courseGroupEmail: string;
   teacherFolder: IDriveFolder;
   guardiansEnabled: boolean;
   calendarId: string;
}
