import * as React from 'react';

import { ActivityBuilderMode, TextPrompt, TextPromptLength } from '@models/Activity';

import Switch from '@components/Common/Switch';
import { PromptOptionsProps } from '@components/Activity/Builder/Prompt';

const TextPromptOptions: React.FC<PromptOptionsProps<TextPrompt<ActivityBuilderMode>>> = ({
   prompt: { length, showWordCount },
   onUpdate,
}) => {
   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const { name, value } = event.target;
      onUpdate({ [name]: value });
   };

   const toggleShowWordCount = (): void => onUpdate({ showWordCount: !showWordCount });

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='margin-top-s'>
               <label className='field-title'>Length</label>
               <select name='length' value={length} onChange={handleChange}>
                  <option value={TextPromptLength.short}>Short</option>
                  <option value={TextPromptLength.long}>Long</option>
               </select>
            </div>
            <div className='margin-top-s'>
               <Switch name='showWordCount' checked={showWordCount} onChange={toggleShowWordCount}>
                  Show Word Count
               </Switch>
            </div>
         </div>
      </div>
   );
};

export default TextPromptOptions;
