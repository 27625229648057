import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import { applyDrag } from '@components/Activity/DragUtils';
import Button from '@components/Common/Button';
import { Container, Draggable, DropResult } from '@components/Core/DragAndDrop';
import Toggle from '@components/Core/Toggle';
import InfoTooltip from '@components/InfoTooltip';
import { randomShortId } from '@helpers/RandomStringUtils';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import Constants from '../../../../../Constants';

interface BlankSettingsPopupProps {
   blankElement: HTMLElement;
   onChange(blankConfiguration: BlankConfiguration): void;
   onSave(blankConfiguration: BlankConfiguration): void;
}

export enum BlankType {
   dropdown = 'dropdown',
   blank = 'blank',
}

export enum GradingMethod {
   automatic = 'automatic',
   manual = 'manual',
}

export enum FillBlanksDataAttr {
   altAnswers = 'data-alt-answers',
   blank = 'data-blank',
   grading = 'data-grading',
   hint = 'data-hint',
   id = 'data-id',
   options = 'data-options',
}

export interface DropdownOption {
   value: string;
   correct: boolean;
   key: string;
}

export interface AlternateAnswer {
   value: string;
   key: string;
}

export interface BlankConfiguration {
   alternateAnswers: readonly AlternateAnswer[];
   blankType: BlankType;
   answer: string;
   options: readonly DropdownOption[];
   gradingMethod: GradingMethod;
}

export const elementToBlankConfiguration = (
   blankElement: HTMLSpanElement,
): Maybe<BlankConfiguration> => {
   if (!blankElement) {
      return null;
   }
   const gradingMethod =
      (blankElement.getAttribute(FillBlanksDataAttr.grading) as GradingMethod) ||
      GradingMethod.automatic;

   const answer = blankElement.innerText;

   const alternateAnswers = (blankElement.getAttribute(FillBlanksDataAttr.altAnswers) || '')
      .split('|')
      .filter((i) => !!i.length)
      .map((i) => ({ value: i, key: randomShortId() }));

   const options = (blankElement.getAttribute(FillBlanksDataAttr.options) || '')
      .split('|')
      .filter((i) => !!i.length)
      .map((i) => ({ value: i, key: randomShortId(), correct: i === answer }));

   const blankType = options.length ? BlankType.dropdown : BlankType.blank;
   const blankConfiguration = {
      alternateAnswers,
      blankType,
      answer,
      options,
      gradingMethod,
   };

   return blankConfiguration;
};

export const blankConfigurationToElement = (
   blankConfiguration: BlankConfiguration,
   blankElement: Maybe<HTMLSpanElement>,
): void => {
   if (!(blankElement && blankConfiguration)) {
      return;
   }
   const isDropdown = blankConfiguration.blankType === BlankType.dropdown;
   const isBlank = blankConfiguration.blankType === BlankType.blank;
   const isAutomatic = blankConfiguration.gradingMethod === GradingMethod.automatic;
   const isManual = blankConfiguration.gradingMethod === GradingMethod.manual;
   const filteredOptions = blankConfiguration.options.filter((i) => !!i.value);
   const filteredAlternateAnswers = blankConfiguration.alternateAnswers.filter((i) => !!i.value);
   // Handle Dropdown
   if (isDropdown) {
      const option = blankConfiguration.options.find((i) => i.correct);
      if (option) {
         blankElement.innerText = option.value;
      }
      blankElement.setAttribute(
         FillBlanksDataAttr.options,
         filteredOptions.map((i) => i.value).join('|'),
      );
      blankElement.removeAttribute(FillBlanksDataAttr.grading);
   } else {
      blankElement.removeAttribute(FillBlanksDataAttr.options);
   }

   if (isBlank) {
      blankElement.innerText = blankConfiguration.answer;
      if (isManual) {
         blankElement.setAttribute(FillBlanksDataAttr.grading, blankConfiguration.gradingMethod);
      } else if (isAutomatic) {
         blankElement.removeAttribute(FillBlanksDataAttr.grading);
      }
   }

   if (filteredAlternateAnswers.length > 0 && isAutomatic && isBlank) {
      blankElement.setAttribute(
         FillBlanksDataAttr.altAnswers,
         filteredAlternateAnswers.map((i) => i.value).join('|'),
      );
   } else {
      blankElement.removeAttribute(FillBlanksDataAttr.altAnswers);
   }
};

const BlankSettingsPopup: React.FC<BlankSettingsPopupProps> = ({
   blankElement,
   onChange,
   onSave,
}) => {
   const { nonDragAreaSelector } = Constants;

   const { language } = React.useContext<ActivityContext>(ActivityContext);
   const [blankConfiguration, setBlankConfiguration] = React.useState<BlankConfiguration>({
      alternateAnswers: [],
      blankType: BlankType.blank,
      answer: '',
      options: [],
      gradingMethod: GradingMethod.automatic,
   });
   const [isAddingNewAlternateAnswer, setIsAddingNewAlternateAnswer] =
      React.useState<boolean>(false);
   const [isAddingNewOption, setIsAddingNewOption] = React.useState<boolean>(false);

   React.useEffect(() => {
      const updatedBlankConfiguration = elementToBlankConfiguration(blankElement);
      if (updatedBlankConfiguration) {
         setBlankConfiguration(updatedBlankConfiguration);
      }
   }, []);

   React.useEffect(() => {
      if (blankConfiguration) {
         onChange(blankConfiguration);
      }
   }, [blankConfiguration]);

   const handleAddOption = (): void => {
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         options: [
            ...prevBlankConfiguration.options,
            { value: '', correct: false, key: randomShortId() },
         ],
      }));
      setIsAddingNewOption(true);
   };

   const handleAddAlternateAnswer = (): void => {
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         alternateAnswers: [
            ...prevBlankConfiguration.alternateAnswers,
            { value: '', key: randomShortId() },
         ],
      }));
      setIsAddingNewAlternateAnswer(true);
   };

   const handleInputBlur = (): void => {
      setIsAddingNewOption(false);
      setIsAddingNewAlternateAnswer(false);
   };

   const handleRemoveOption = (key: string): void => {
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         options: prevBlankConfiguration.options.filter((i) => i.key !== key),
      }));
   };

   const handleRemoveAlternateAnswer = (key: string): void => {
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         alternateAnswers: prevBlankConfiguration.alternateAnswers.filter((i) => i.key !== key),
      }));
   };

   const handleOptionChange = (key: string, event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         options: prevBlankConfiguration.options.map((i) => (i.key === key ? { ...i, value } : i)),
      }));
   };

   const handleAlternateAnswerChange = (
      key: string,
      event: React.ChangeEvent<HTMLInputElement>,
   ): void => {
      const { value } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         alternateAnswers: prevBlankConfiguration.alternateAnswers.map((i) =>
            i.key === key ? { ...i, value } : i,
         ),
      }));
   };

   const toggleOptionCorrect = (key: string, event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => {
         if (!prevBlankConfiguration) {
            return prevBlankConfiguration;
         }
         const option = prevBlankConfiguration.options.find((i) => i.key === key);
         return {
            ...prevBlankConfiguration,
            options: prevBlankConfiguration.options.map((i) =>
               i.key === key ? { ...i, correct: checked } : { ...i, correct: false },
            ),
            answer: option ? option.value : prevBlankConfiguration.answer,
         };
      });
   };

   const handleBlankTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         blankType: value as BlankType,
         options:
            value === BlankType.dropdown
               ? [{ key: randomShortId(), value: prevBlankConfiguration.answer, correct: true }]
               : [],
      }));
   };

   const handleGradingMethodChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         gradingMethod: value as GradingMethod,
      }));
   };

   const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         answer: value,
      }));
   };

   const handleDrop = (event: DropResult): void => {
      setBlankConfiguration((prevBlankConfiguration) => ({
         ...prevBlankConfiguration,
         options: applyDrag(prevBlankConfiguration.options, event),
      }));
   };

   const handleSaveClick = (): void => {
      onSave(blankConfiguration);
   };

   if (!blankConfiguration) {
      return null;
   }

   return (
      <div className='card blank-settings-popup no-drag'>
         <div className='card-title full-width'>
            <div className='title'>Blank Options</div>
            <Button onClick={handleSaveClick} line>
               Save
            </Button>
         </div>
         <label className='field-title'>Type</label>
         <Toggle
            leftLabel='Blank'
            className='margin-top-s small'
            leftValue={BlankType.blank}
            rightLabel='Dropdown'
            rightValue={BlankType.dropdown}
            value={blankConfiguration.blankType}
            onChange={handleBlankTypeChange}
         />
         {blankConfiguration.blankType === BlankType.blank && (
            <>
               {blankConfiguration.gradingMethod === GradingMethod.automatic && (
                  <div>
                     <label className='field-title'>Answer</label>
                     <AccentTextbox
                        value={blankConfiguration.answer}
                        className='margin-top-s'
                        onChange={handleAnswerChange}
                        language={language}
                     />
                     <label className='field-title'>Alternate Answers</label>
                     <div>
                        {blankConfiguration.alternateAnswers.map((i, index, arr) => (
                           <div className='option-row' key={i.key}>
                              <AccentTextbox
                                 value={i.value}
                                 language={language}
                                 onBlur={handleInputBlur}
                                 autoFocus={index === arr.length - 1 && isAddingNewAlternateAnswer}
                                 onChange={(e) => handleAlternateAnswerChange(i.key, e)}
                              />
                              <IconCloseSmall
                                 className='delete-row-btn'
                                 onClick={() => handleRemoveAlternateAnswer(i.key)}
                              />
                           </div>
                        ))}
                        <Button
                           line
                           fullWidth
                           className='margin-top-s'
                           onClick={handleAddAlternateAnswer}
                        >
                           <IconAddSmall />
                           Add Alternate Answer
                        </Button>
                     </div>
                  </div>
               )}
               <label className='field-title'>
                  Grading
                  <InfoTooltip>
                     Manual grading allows any response and defers the grading to the instructor.
                  </InfoTooltip>
               </label>
               <Toggle
                  leftLabel='Automatic'
                  className='margin-top-s small'
                  leftValue={GradingMethod.automatic}
                  rightLabel='Manual'
                  rightValue={GradingMethod.manual}
                  value={blankConfiguration.gradingMethod}
                  onChange={handleGradingMethodChange}
               />
            </>
         )}
         {blankConfiguration.blankType === BlankType.dropdown && (
            <div>
               <label className='field-title'>Options</label>
               <div className='options-list'>
                  <Container
                     groupName='options-list'
                     getChildPayload={(i) => blankConfiguration.options[i]}
                     nonDragAreaSelector={nonDragAreaSelector}
                     onDrop={handleDrop}
                  >
                     {blankConfiguration.options.map((i, index, arr) => (
                        <Draggable key={i.key}>
                           <div className='option-row' key={i.key}>
                              <input
                                 type='radio'
                                 className={classnames({ correct: i.correct })}
                                 checked={i.correct}
                                 onChange={(e) => toggleOptionCorrect(i.key, e)}
                              />
                              <AccentTextbox
                                 value={i.value}
                                 language={language}
                                 onBlur={handleInputBlur}
                                 autoFocus={index === arr.length - 1 && isAddingNewOption}
                                 onChange={(e) => handleOptionChange(i.key, e)}
                              />
                              <IconCloseSmall
                                 className='delete-row-btn'
                                 onClick={() => handleRemoveOption(i.key)}
                              />
                           </div>
                        </Draggable>
                     ))}
                  </Container>
                  <Button line fullWidth className='margin-top-s' onClick={handleAddOption}>
                     <IconAddSmall />
                     Add Option
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};

export default React.memo(BlankSettingsPopup);
