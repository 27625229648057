import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import useWindowSize from '@hooks/use-window-size';
import IconBuilderUploadGrey from '@icons/activities/icon-builder-upload-gray.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import {
   ActivityBuilderMode,
   ImageLabelingHotspot,
   ImageLabelingPrompt as ImageLabelingPromptType,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import AutosizeInput from 'react-18-input-autosize';
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';

interface ImageLabelingPromptProps {
   hotspots: readonly ImageLabelingHotspot<ActivityBuilderMode>[];
   fileUrl: Maybe<string>;
   onUpdate(update: Partial<ImageLabelingPromptType<ActivityBuilderMode>>): void;
}

interface Position {
   x: number;
   y: number;
}

const ImageLabelingPrompt: React.FC<ImageLabelingPromptProps> = ({
   fileUrl,
   hotspots,
   onUpdate,
}) => {
   // We want the component to rerender when the window size changes.
   const [_windowWidth, _windowHeight] = useWindowSize();
   const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
   const [draggedId, setDraggedId] = React.useState<Maybe<string | number>>(null);
   const [showDeleteDropzone, setShowDeleteDropzone] = React.useState<boolean>(false);
   const [startDragPosition, setStartDragPosition] = React.useState<Maybe<Position>>(null);
   const [isOverDeleteDropzone, setIsOverDeleteDropzone] = React.useState<boolean>(false);
   const deleteHotspotRef = React.useRef<HTMLDivElement>(null);
   const imageRef = React.useRef<HTMLImageElement>(null);

   const addHotspot = ({ x, y } = { x: 0, y: 0 }): void => {
      const { x: xPer, y: yPer } = pixelsToPercentage({ x, y }) ?? {};
      if (!(xPer && yPer)) {
         return;
      }

      onUpdate({
         hotspots: [
            ...hotspots,
            {
               id: randomShortId(),
               x: xPer,
               y: yPer,
               content: 'Enter Label',
            },
         ],
      });
   };

   const deleteHotspot = (id: string | number): void => {
      onUpdate({
         hotspots: hotspots.filter((i) => i.id !== id),
      });
   };

   const handleDrag: DraggableEventHandler = (
      event: DraggableEvent,
      { x: x2, y: y2 }: DraggableData,
   ): void => {
      if (showDeleteDropzone) {
         if (deleteHotspotRef.current) {
            const node = event.target as HTMLElement;
            const rect1 = node.getBoundingClientRect();
            const rect2 = deleteHotspotRef.current.getBoundingClientRect();
            const overlap = !(
               rect1.right < rect2.left ||
               rect1.left > rect2.right ||
               rect1.bottom < rect2.top ||
               rect1.top > rect2.bottom
            );
            setIsOverDeleteDropzone(overlap);
         }
      } else if (draggedId && startDragPosition) {
         const { x: x1, y: y1 } = startDragPosition;
         const dist = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
         if (dist > 30) {
            setShowDeleteDropzone(true);
         }
      }
   };

   const handleDragStart = (id: number | string): void => {
      const { x: xPer, y: yPer } = hotspots.find((i) => i.id === id) ?? {};
      if (!(xPer && yPer)) {
         return;
      }
      const { x: xPix, y: yPix } = percentageToPixels({ x: xPer, y: yPer }) ?? {};
      if (xPix && yPix) {
         setDraggedId(id);
         setStartDragPosition({ x: xPix, y: yPix });
      }
   };

   const handleDragStop = (
      id: string | number,
      e: DraggableEvent,
      { x, y }: DraggableData,
   ): void => {
      const xPix = x;
      const yPix = y;
      const { x: xPer, y: yPer } = pixelsToPercentage({ x: xPix, y: yPix }) ?? {};
      if (!(xPer && yPer)) {
         return;
      }
      if (isOverDeleteDropzone) {
         deleteHotspot(id);
      } else {
         onUpdate({
            hotspots: hotspots.map((i) => (i.id === id ? { ...i, x: xPer, y: yPer } : i)),
         });
      }
      setDraggedId(null);
      setIsOverDeleteDropzone(false);
      setShowDeleteDropzone(false);
      setStartDragPosition(null);
   };

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = () => {
            onUpdate({
               fileUrl: reader.result as string,
               file,
            });
         };
         reader.readAsDataURL(file);
      }
   };

   const handleImageDoubleClick = (event: React.MouseEvent<HTMLElement>): void => {
      const node = event.target as HTMLElement;
      const rect = node.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      addHotspot({ x, y });
   };

   const handleInputChange = (
      id: string | number,
      event: React.ChangeEvent<HTMLInputElement>,
   ): void => {
      const { value } = event.target;

      onUpdate({
         hotspots: hotspots.map((i) => (i.id === id ? { ...i, content: value } : i)),
      });
   };

   const getContentById = (id: string | number): Maybe<string> =>
      hotspots.find((i) => i.id === id)?.content;

   const pixelsToPercentage = ({ x, y }: Position): Maybe<Position> => {
      const rect = imageRef.current?.getBoundingClientRect();
      if (rect) {
         return {
            x: (x / rect.width) * 100,
            y: (y / rect.height) * 100,
         };
      }
      return null;
   };

   const percentageToPixels = ({ x, y }: Position): Maybe<Position> => {
      const rect = imageRef.current?.getBoundingClientRect();
      if (rect) {
         return {
            x: (x * rect.width) / 100,
            y: (y * rect.height) / 100,
         };
      }
   };

   const renderHotspot = ({
      id,
      x,
      y,
   }: ImageLabelingHotspot<ActivityBuilderMode>): Maybe<React.ReactNode> => {
      const { x: xPix, y: yPix } = percentageToPixels({ x, y }) ?? {};

      if (!(xPix && yPix)) {
         return null;
      }

      return (
         <Draggable
            key={id}
            bounds='parent'
            onDrag={handleDrag}
            onStart={() => handleDragStart(id)}
            onStop={(e, p) => handleDragStop(id, e, p)}
            position={{ x: xPix, y: yPix }}
         >
            <AutosizeInput
               type='text'
               className='image-label'
               id={`hotspot-${id}`}
               value={getContentById(id) ?? ''}
               onChange={(e) => handleInputChange(id, e)}
               onClick={(e) => (e.target as HTMLInputElement)?.select()}
            />
         </Draggable>
      );
   };

   if (fileUrl) {
      return (
         <div className='row'>
            <div className='col-xs-12'>
               <div className='drag-parent'>
                  <img
                     src={fileUrl}
                     className='no-drag'
                     onDoubleClick={handleImageDoubleClick}
                     onLoad={() => setImageLoaded(true)}
                     ref={imageRef}
                  />
                  {imageLoaded && hotspots.map(renderHotspot)}
                  {showDeleteDropzone && (
                     <div
                        className={classnames('delete-hotspot-dropzone', {
                           'is-over': isOverDeleteDropzone,
                        })}
                        ref={deleteHotspotRef}
                     >
                        <div className='delete-dropzone-header'>
                           <IconBin aria-hidden />
                           Drop to delete
                        </div>
                        <div className='delete-dropzone-body'>Drop hotspot here to delete</div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      );
   }

   return (
      <div className='upload-dropzone'>
         <div className='icon-wrapper'>
            <IconBuilderUploadGrey aria-hidden />
         </div>
         <input type='file' accept='image/*' onChange={handleFileChange} />
         <span>Choose an image or drag it here</span>
      </div>
   );
};

export default ImageLabelingPrompt;
