import * as React from 'react';

import Button from '@components/Common/Button';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import InfoTooltip from '@components/InfoTooltip';
import { formatCurrency } from '@helpers/CurrencyUtil';
import { formatDateConcise } from '@helpers/FormatTime';
import IconDataDownload8 from '@icons/nova-line/23-Data-Transfer/data-download-8.svg';
import { NationalExamRegistrationOrder } from '@models/NationalExam';
import NationalExamService from '@services/NationalExamService';

interface NationalExamOrderHistoryProps {
   orderHistory: readonly NationalExamRegistrationOrder[];
}

const NationalExamOrderHistory: React.FC<NationalExamOrderHistoryProps> = ({ orderHistory }) => {
   const numSeatsFromOrderHistory = orderHistory.reduce((a, b) => a + b.totalSeats, 0);
   const totalSumFromOrderHistory = orderHistory.reduce(
      (a, b) => a + (b.price * b.totalSeats + b.lateFee + b.additionalFee),
      0,
   );

   const renderTotalPrice = (i: NationalExamRegistrationOrder): React.ReactNode => {
      const tooltip = i.lateFee ? (
         <InfoTooltip>{`${formatCurrency(i.lateFee)} late fee included`}</InfoTooltip>
      ) : (
         <></>
      );
      return (
         <>
            {formatCurrency(i.totalSeats * i.price + i.lateFee + i.additionalFee)} {tooltip}
         </>
      );
   };

   const columns: readonly Column<NationalExamRegistrationOrder>[] = [
      { id: 'id', header: 'ID', cell: (i) => `#${i.id}` },
      {
         id: 'createdOn',
         header: 'Created On',
         cell: (i) => (i.createdOn ? formatDateConcise(i.createdOn) : ''),
      },
      {
         id: 'totalSeats',
         header: 'Seats',
         cell: (i) => i.totalSeats.toString(),
      },
      { id: 'totalPrice', header: 'Total Price', cell: renderTotalPrice },
      {
         id: 'downloadInvoice',
         header: 'Order Confirmation',
         cell: (i) => (
            <div className='download-invoice'>
               <Button
                  onClick={() => NationalExamService.downloadInvoice(i.id)}
                  icon={<IconDataDownload8 />}
               />
            </div>
         ),
      },
   ];

   return (
      <>
         {orderHistory.length > 0 && (
            <SectionCard title='Summary of past orders'>
               <div className='row'>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title'>Total seats reserved</label>
                     <div data-test='total-seats-ordered'>{numSeatsFromOrderHistory}</div>
                  </div>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title'>Number of orders</label>
                     <div>{orderHistory.length}</div>
                  </div>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title'>Grand total of orders</label>
                     <div data-test='total-price-of-ordered'>
                        {formatCurrency(totalSumFromOrderHistory)}
                     </div>
                  </div>
               </div>
            </SectionCard>
         )}

         {orderHistory.length > 0 && (
            <SectionCard title='Order History' className='exam-order-history-card'>
               <div className='row'>
                  <div className='col-xs-12'>
                     <Table<NationalExamRegistrationOrder>
                        className='exam-order-history-table'
                        columns={columns}
                        rowKey='id'
                        rows={orderHistory}
                     />
                  </div>
               </div>
            </SectionCard>
         )}
      </>
   );
};

export default NationalExamOrderHistory;
