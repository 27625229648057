import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { InterviewQuestion } from '@models/Video';
import AxiosService from './AxiosService';

export type InterviewQuestionFields = Omit<InterviewQuestion, 'id'>;
export const isInterviewQuestion = (
   data: InterviewQuestion | InterviewQuestionFields,
): data is InterviewQuestion => (data as InterviewQuestion).id !== undefined;

const API = '/api/interview_questions';

const search = (filters: PagedSearchFilters): Promise<PagedResponse<InterviewQuestion>> =>
   AxiosService.get<PagedResponse<InterviewQuestion>>(`${API}/search`, {
      params: filters,
   }).then((response) => response.data);

const create = (data: InterviewQuestionFields): Promise<InterviewQuestion> =>
   AxiosService.post<InterviewQuestion>(API, data).then((response) => response.data);

const update = (data: InterviewQuestion): Promise<InterviewQuestion> =>
   AxiosService.patch<InterviewQuestion>(`${API}/${data.id}`, data).then(
      (response) => response.data,
   );

const remove = (id: number): Promise<void> =>
   AxiosService.delete<void>(`${API}/${id}`, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

export default {
   create,
   remove,
   search,
   update,
};
