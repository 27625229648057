import Emitter from '@utilities/Emitter';
import { BeforeCapture, DragStart, DropResult } from 'react-beautiful-dnd';
import { Subscription } from 'rxjs';

type SubscriptionCallback = (result: DropResult) => void;

export enum ModuleDroppableId {
   ModuleContent = 'ModuleContent',
   ModuleList = 'ModuleList',
}

enum EventType {
   ModuleItemOnDragEnd = 'ModuleItemOnDragEnd',
   ModuleListOnDragEnd = 'ModuleListOnDragEnd',
   OnBeforeDragCapture = 'OnBeforeDragCapture',
   OnDragStart = 'OnDragStart',
   OnDragUpdate = 'OnDragUpdate',
}

const eventEmitter = new Emitter();

export const ModuleDndEmitter = {
   emit: {
      moduleItemOnDropEnd: (result: DropResult) =>
         eventEmitter.emit(EventType.ModuleItemOnDragEnd, result),
      moduleListOnDropEnd: (result: DropResult) =>
         eventEmitter.emit(EventType.ModuleListOnDragEnd, result),
      onBeforeDragCapture: (before: BeforeCapture) =>
         eventEmitter.emit(EventType.OnBeforeDragCapture, before),
      onDropStart: (result: DragStart) => eventEmitter.emit(EventType.OnDragStart, result),
   },
   subscribeTo: {
      moduleItemOnDragEnd: (handler: SubscriptionCallback): Subscription =>
         eventEmitter.listen(EventType.ModuleItemOnDragEnd, handler),
      moduleListOnDragEnd: (handler: SubscriptionCallback): Subscription =>
         eventEmitter.listen(EventType.ModuleListOnDragEnd, handler),
      onBeforeDragCapture: (handler: SubscriptionCallback): Subscription =>
         eventEmitter.listen(EventType.OnBeforeDragCapture, handler),
      onDragStart: (handler: SubscriptionCallback): Subscription =>
         eventEmitter.listen(EventType.OnDragStart, handler),
   },
};
