import * as React from 'react';

import { Editor } from 'tinymce';

import { createRoot } from 'react-dom/client';

export default function (
   editor: Editor,
   document: Document,
   child: React.ReactElement,
   containerClassName = 'editor-popup-container',
): void {
   let container = document.querySelector(`.${containerClassName}`);
   if (!container) {
      container = document.createElement('div');
      container.className = containerClassName;
      document.body.appendChild(container);
   }
   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
   const root = createRoot(container!);

   const handleDismiss = (): void => {
      root.unmount();
      editor.focus(false);
   };
   root.render(
      React.cloneElement(child, { onDismiss: handleDismiss, editor, ...child.props }, null),
   );
}
