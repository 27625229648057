import Language from '@models/Language';
import TranslationService from '@services/TranslationService';
import tinymce from 'tinymce';

import { getLanguage } from '@components/Core/Editor/utils/language';

tinymce.PluginManager.add('translate', (editor) => {
   [
      { title: 'English', code: 'en' },
      { title: 'Spanish', code: 'es' },
      { title: 'French', code: 'fr' },
      { title: 'German', code: 'de' },
   ].forEach(({ title, code: targetLanguage }) => {
      editor.ui.registry.addToggleMenuItem(`translate-${targetLanguage}`, {
         text: title,
         onAction: () => {
            const selectedText = editor.selection.getContent({ format: 'text' });
            const sourceLanguage = getLanguage(editor.selection.getNode());
            if (sourceLanguage) {
               const bookmark = editor.selection.getBookmark();
               TranslationService.translate(
                  selectedText,
                  sourceLanguage as Language,
                  targetLanguage as Language,
               ).then((translatedText) => {
                  const node = editor.selection.getNode();
                  node.setAttribute('lang', targetLanguage);
                  editor.selection.setContent(translatedText);
                  editor.selection.moveToBookmark(bookmark);
               });
            }
         },
         onSetup: (api) => {
            api.setActive(getLanguage(editor.selection.getNode()) === targetLanguage);
         },
      });
   });

   editor.ui.registry.addNestedMenuItem('translate', {
      text: 'Translate',
      icon: 'translation-line',
      getSubmenuItems: () => 'translate-en translate-es translate-fr translate-de',
   });

   editor.ui.registry.addContextMenu('translate', {
      update: (element) => {
         if (
            ['IMG', 'AUDIO', 'VIDEO', 'DIV', 'TABLE'].includes(element.tagName) ||
            (editor.selection.isCollapsed() && getLanguage(editor.selection.getNode()))
         ) {
            return '';
         }
         return 'translate';
      },
   });
});
