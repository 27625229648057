import * as _ from 'lodash';
import * as React from 'react';

import Switch from '@components/Common/Switch';
import { lmsDisplayName } from '@components/Core/LMS';
import LMSSelect from '@components/Core/LMS/LMSSelect';
import ModalDialog from '@components/Core/ModalDialog';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import LMSName from '@models/LMSName';
import { OrganizationLmsInfo } from '@models/Organization';
import OrganizationService, { PartialOrganizationLmsInfo } from '@services/OrganizationService';

import { AppStateContext } from '../../AppState';

interface LmsConnectionModalProps {
   organizationName: string;
   organizationId: number;
   onCancel(): void;
}

const OrganizationLmsInfoModal: React.FC<LmsConnectionModalProps> = ({
   organizationId,
   organizationName,
   onCancel,
}) => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const [lmsConnection, setLmsConnection] = React.useState<PartialOrganizationLmsInfo>({});

   const isCanvas = lmsConnection?.lms === LMSName.canvas;
   const isBrightspace = lmsConnection?.lms === LMSName.brightspace;
   const isBlackboard = lmsConnection?.lms === LMSName.blackboard;
   const isMoodle = lmsConnection?.lms === LMSName.moodle;
   const isSchoology = lmsConnection?.lms === LMSName.schoology;

   const urlPlaceholders: Record<LMSName, string> = {
      [LMSName.blackboard]: 'https://blackboard.example.edu/',
      [LMSName.brightspace]: 'https://example.brightspace.com/',
      [LMSName.canvas]: 'https://example.instructure.com',
      [LMSName.moodle]: 'https://moodle.example.edu/',
      [LMSName.googleClassroom]: '',
      [LMSName.schoology]: 'https://example.schoology.com',
      [LMSName.microsoftTeams]: '',
   };

   React.useEffect(() => {
      if (organizationId) {
         getLmsConnection(organizationId);
      }
   }, [organizationId]);

   const getLmsConnection = async (id: number) => {
      try {
         setIsLoading(true);
         const data = await OrganizationService.getLmsConnection(id);
         setLmsConnection(data);
      } catch (error) {
         console.error(error);
      } finally {
         setIsLoading(false);
      }
   };

   const handleSave = async () => {
      try {
         if (isSaving || !lmsConnection) {
            return;
         }
         setIsSaving(true);

         await OrganizationService.upsertLmsConnection(organizationId, lmsConnection);
         dispatchToast({
            title: 'LMS Info Updated',
            message: `${organizationName} has been successfully updated.`,
            appearance: Appearance.danger,
         });
         onCancel();
      } catch (error) {
         console.error(error);
      } finally {
         setIsSaving(false);
      }
   };

   const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value, name } = event.target;

      setLmsConnection(
         (prevLMSConnection) =>
            ({ ...(prevLMSConnection ?? {}), [name]: value }) as OrganizationLmsInfo,
      );
   };

   const handleLMSChange = (updatedLMS: Maybe<LMSName>): void => {
      setLmsConnection((prevLMSConnection) => ({
         ...prevLMSConnection,
         lms: updatedLMS,
         clientId: '',
         clientSecret: '',
         domain: '',
      }));
   };

   const toggleShouldAllowLtiAppInstallation = (): void => {
      setLmsConnection((prevLMSConnection) =>
         prevLMSConnection
            ? {
                 ...prevLMSConnection,
                 shouldAllowLtiAppInstallation: !prevLMSConnection.shouldAllowLtiAppInstallation,
              }
            : {},
      );
   };

   if (isLoading) {
      return (
         <ModalDialog
            appearance={Appearance.primary}
            bodyClassName='no-center padding-bottom-s'
            heading={`Lms Connection - ${organizationName}`}
            actions={[
               { text: 'Save', onClick: handleSave, loading: isSaving, disabled: true },
               { text: 'Cancel', onClick: onCancel },
            ]}
         >
            <Loader />
         </ModalDialog>
      );
   }

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='modal-body'
         heading={`Lms Connection - ${organizationName}`}
         onClose={onCancel}
         actions={[
            { text: 'Save', onClick: handleSave, loading: isSaving, disabled: isSaving },
            { text: 'Cancel', onClick: onCancel },
         ]}
      >
         <div className='row'>
            <div className='col-xs-12'>
               <label className='field-title'>LMS</label>
               <LMSSelect name='lms' onChange={handleLMSChange} value={lmsConnection?.lms} />
            </div>
         </div>
         {_.some([isBlackboard, isBrightspace, isCanvas, isSchoology, isMoodle]) &&
            lmsConnection?.lms && (
               <div className='row'>
                  <div className='col-xs-12'>
                     <label className='field-title'>{lmsDisplayName[lmsConnection?.lms]} URL</label>
                     <input
                        type='text'
                        name='domain'
                        placeholder={urlPlaceholders[lmsConnection?.lms]}
                        value={lmsConnection?.domain}
                        onChange={handleOnChange}
                     />
                  </div>
               </div>
            )}
         {_.some([isBrightspace, isBlackboard, isCanvas, isMoodle]) && (
            <>
               <div className='row'>
                  <div className='col-xs-12'>
                     <label className='field-title'>Client ID</label>
                     <input
                        type='text'
                        name='clientId'
                        value={lmsConnection?.clientId}
                        onChange={handleOnChange}
                     />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12'>
                     <label className='field-title'>Client Secret</label>
                     <input
                        type='text'
                        name='clientSecret'
                        value={lmsConnection?.clientSecret}
                        onChange={handleOnChange}
                     />
                  </div>
               </div>
            </>
         )}

         {isCanvas && (
            <div className='row'>
               <div className='col-xs-12'>
                  <Switch
                     checked={lmsConnection?.shouldAllowLtiAppInstallation}
                     name='shouldAllowLtiAppInstallation'
                     onChange={toggleShouldAllowLtiAppInstallation}
                  >
                     <label className='field-title'>
                        Allow instructors to install LTI app?
                        <InfoTooltip>
                           Disable if the organization does not allow instructors to create LTI
                           applications
                        </InfoTooltip>
                     </label>
                  </Switch>
               </div>
            </div>
         )}
      </ModalDialog>
   );
};

export default OrganizationLmsInfoModal;
