import {
   DndContext as DndKitDndContext,
   DndContextProps,
   MouseSensor as DndKitMouseSensor,
   TouchSensor as DndKitTouchSensor,
   useSensor,
   useSensors,
} from '@dnd-kit/core';
import React, { MouseEvent, TouchEvent } from 'react';

import Constants from '../../../Constants';

const isElementThatNeedsDrag = (element: Element | null) => {
   const interactiveElements = ['input', 'textarea'];
   if (element?.tagName && interactiveElements.includes(element.tagName.toLowerCase())) {
      return true;
   }

   return false;
};

/* 
Block DnD event propagation if element has:
1.  "data-no-dnd"
2. Is an interactive element (input, textarea, select, option)
3. Is a child of an element with a non-drag area selector
 */
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
   // If any element is interactive, prevent dragging
   if (isElementThatNeedsDrag(event.target as Element)) {
      return false;
   }

   let cur = event.target as HTMLElement;
   const selectors = Constants.nonDragAreaSelector.split(', ');
   while (cur) {
      // If any parent element have "data-no-dnd" attribute, prevent dragging
      if (cur.dataset && cur.dataset.noDnd) {
         return false;
      }

      // If any parent element has non-drag area selector, prevent dragging
      for (const selector of selectors) {
         if (cur.matches(selector)) {
            return false;
         }
      }

      cur = cur.parentElement as HTMLElement;
   }

   return true;
};

export class MouseSensor extends DndKitMouseSensor {
   static activators = [
      { eventName: 'onMouseDown', handler },
   ] as (typeof DndKitMouseSensor)['activators'];
}

export class TouchSensor extends DndKitTouchSensor {
   static activators = [
      { eventName: 'onTouchStart', handler },
   ] as (typeof DndKitTouchSensor)['activators'];
}

const DNDContext: React.FC<DndContextProps> = (props) => {
   const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
         distance: 10,
      },
   });
   const touchSensor = useSensor(TouchSensor);

   const sensors = useSensors(mouseSensor, touchSensor);

   return (
      <DndKitDndContext {...props} sensors={sensors}>
         {props.children}
      </DndKitDndContext>
   );
};

export default DNDContext;
