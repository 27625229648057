import * as React from 'react';

import {
   GroupingPromptCategory as GroupingPromptCategoryType,
   GroupingPromptItem,
   GroupingResponse,
} from '@models/Activity';

import GroupingPromptCategory from './GroupingPromptCategory';

interface GroupingPromptProps {
   categories: readonly GroupingPromptCategoryType[];
   items: readonly GroupingPromptItem[];
   response: GroupingResponse;
}

const GroupingPrompt: React.FC<GroupingPromptProps> = ({
   items = [],
   categories = [],
   response,
}) => (
   <div className='row'>
      <div className='col-xs-12'>
         <div className='dnd-prompt-container closed'>
            {categories.map((category) => (
               <GroupingPromptCategory
                  key={category.id}
                  category={category}
                  entries={response.filter((i) => i.categoryId === category.id)}
                  items={items.filter(
                     (i) => response.find((k) => k.itemId === i.id)?.categoryId === category.id,
                  )}
               />
            ))}
            <GroupingPromptCategory
               category={{
                  name: 'Origin',
                  id: 'origin',
                  index: categories.length,
               }}
               entries={response.filter((i) => i.categoryId === null)}
               items={items.filter(
                  (i) => response.find((k) => k.itemId === i.id)?.categoryId === null,
               )}
            />
         </div>
      </div>
   </div>
);

export default GroupingPrompt;
