import DateTime from '@services/DateTimeService';
import moment from 'moment';
import pluralize from 'pluralize';

const formatSeconds = (seconds: number): string => {
   const hrs = Math.floor(seconds / 3600);
   const mins = Math.floor((seconds % 3600) / 60);
   const secs = Math.floor(seconds % 60);
   let ret = '';
   if (hrs > 0) {
      ret += `${hrs}:${mins < 10 ? '0' : ''}`;
   }
   ret += `${mins}:${secs < 10 ? '0' : ''}${secs}`;
   return ret;
};

const altFormatSeconds = (secNum: number): string => {
   const hours = Math.floor(secNum / 3600);
   const minutes = Math.floor((secNum - hours * 3600) / 60);
   const seconds = Math.floor(secNum - hours * 3600 - minutes * 60);
   if (hours) {
      return `${hours}h ${minutes}m`;
   } else if (minutes) {
      return `${minutes}m ${seconds}s`;
   } else {
      return `${seconds}s`;
   }
};

const formatDate = (date: Date): string =>
   new Date(date).toLocaleString([], {
      month: 'numeric',
      year: '2-digit',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
   });

const formatDateL = (date: Date): string => moment(date).format('L');

const formatDateConcise = (date: Date): string => moment(date).format('MMM Do YYYY, h:mm:ss A');

const relativeTime = (date: Date): string => {
   const msPerMinute = 60 * 1000;
   const msPerHour = msPerMinute * 60;
   const msPerDay = msPerHour * 24;
   const msPerWeek = msPerDay * 7;
   const msPerMonth = msPerDay * 30;
   const msPerYear = msPerDay * 365;
   const now = DateTime.now();
   const diff = Math.abs(date.getTime() - now.getTime());
   const pastDueDate = date < now;
   const suffix = pastDueDate ? ' ago' : '';
   let num;
   let unit;

   if (diff < msPerMinute) {
      num = Math.round(diff / 1000);
      unit = 'second';
   } else if (diff < msPerHour) {
      num = Math.round(diff / msPerMinute);
      unit = 'minute';
   } else if (diff < msPerDay) {
      num = Math.round(diff / msPerHour);
      unit = 'hour';
   } else if (diff < msPerWeek) {
      num = Math.round(diff / msPerDay);
      unit = 'day';
   } else if (diff < msPerMonth) {
      num = Math.round(diff / msPerWeek);
      unit = 'week';
   } else if (diff < msPerYear) {
      num = Math.round(diff / msPerMonth);
      unit = 'month';
   } else {
      num = Math.round(diff / msPerYear);
      unit = 'year';
   }
   return `${num} ${pluralize(unit, num)} ${suffix}`;
};

const secondsToHHMMSSString = (seconds: number): string => {
   const hours = Math.floor(seconds / 3600);
   const minutes = Math.floor((seconds - hours * 3600) / 60);
   const sec = seconds - hours * 3600 - minutes * 60;

   const hoursAsString = hours < 10 ? '0' + Math.round(hours) : String(Math.round(hours));
   const minutesAsString = minutes < 10 ? '0' + Math.round(minutes) : String(Math.round(minutes));
   const secAsString = sec < 10 ? '0' + Math.round(sec) : String(Math.round(sec));
   if (hours > 0) {
      return hoursAsString + ':' + minutesAsString + ':' + secAsString;
   } else {
      return minutesAsString + ':' + secAsString;
   }
};

export {
   altFormatSeconds,
   formatSeconds,
   formatDateL,
   formatDate,
   formatDateConcise,
   relativeTime,
   secondsToHHMMSSString,
};
