import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import useInterval from '@hooks/use-interval';
import IconDataSyncing from '@icons/nova-line/23-Data-Transfer/data-syncing.svg';
import { TextPromptLength, TextResponse } from '@models/Activity';
import Language from '@models/Language';
import pluralize from 'pluralize';

import AccentTextbox from '@components/AccentTextbox';
import Button from '@components/Common/Button';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface TextPromptProps extends CommonPromptProps {
   autoHeight?: boolean;
   disableSpellCheck: boolean;
   language: Language;
   length: TextPromptLength;
   response: TextResponse;
   showWordCount: boolean;
   setResponse(response: TextResponse, callback?: () => void): void;
}

const TextPrompt: React.FC<TextPromptProps> = ({
   autoHeight,
   disableSpellCheck,
   isClosed,
   language,
   length,
   response,
   showWordCount,
   saveResponse,
   setResponse,
}) => {
   const hasEdits = !!response.edit?.content;
   const [responseTextHasChanged, setResponseTextHasChanged] = React.useState<boolean>(false);
   const [isFocused, setIsFocused] = React.useState<boolean>(false);
   const [showOrig, setShowOrig] = React.useState<boolean>(false);

   useInterval(
      () => {
         if (responseTextHasChanged) {
            initiateApiSave();
         }
      },
      5000,
      isFocused,
   );

   const initiateApiSave = (): void => {
      setResponseTextHasChanged(false);
      saveResponse();
   };

   const handleOnBlur = (): void => {
      setIsFocused(false);
      initiateApiSave();
   };

   const handleOnFocus = (): void => {
      setIsFocused(true);
   };

   const handleUpdateResponse = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setResponseTextHasChanged(true);
      setResponse({ ...response, text: value });
   };

   const getWordCount = (): number => {
      const text = response.text?.trim() ?? '';
      return text.length > 0 ? text.split(/\s+/).length : 0;
   };

   const inputProps = {
      autoHeight,
      value: response.text,
      spellCheck: !disableSpellCheck,
      disabled: isClosed,
      placeholder: isClosed ? '' : 'Enter your response here...',
      'aria-label': isClosed ? '' : 'Enter your response here.',
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleUpdateResponse(e),
   };

   const wordCount = getWordCount();

   return (
      <>
         {hasEdits ? (
            <div className='text-prompt-display'>
               {showOrig ? response.text : parseHtml(response.edit?.content ?? '')}
            </div>
         ) : (
            <AccentTextbox
               {...inputProps}
               data-test='text-prompt-input'
               className='text-prompt-input'
               tag={length === TextPromptLength.short ? 'input' : 'textarea'}
               language={language}
            />
         )}
         {showWordCount && (showOrig || !hasEdits) && (
            <div className='word-count'>{`${wordCount} ${pluralize('Word', wordCount)}`}</div>
         )}
         {hasEdits && (
            <div className='text-prompt-edit-btn-container'>
               <Button
                  subtle
                  icon={<IconDataSyncing aria-hidden />}
                  data-test='toggle-edits'
                  onClick={() => setShowOrig((prevShowOrig) => !prevShowOrig)}
               >
                  {`Show ${showOrig ? 'Edits' : 'Original'}`}
               </Button>
            </div>
         )}
      </>
   );
};

export default TextPrompt;
