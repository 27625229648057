import { IdName } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';

import AxiosService from './AxiosService';

const search = (filters: PagedSearchFilters): Promise<PagedResponse<IdName>> =>
   AxiosService.get<PagedResponse<IdName>>('/api/performance_indicators/search', {
      params: filters,
   }).then((response) => response.data);

export default {
   search,
};
