export enum LicenseType {
   course = 'course',
   product = 'product',
}

export enum PaymentType {
   stripe = 'stripe',
   bookstoreCode = 'bookstore_code',
}

interface BasePayment {
   id: number;
   type: PaymentType;
}

export interface StripePayment extends BasePayment {
   type: PaymentType.stripe;
   stripePaymentIntentId: string;
   amount: number;
}

export interface BookstoreCodeActivation extends BasePayment {
   type: PaymentType.bookstoreCode;
   bookstoreCode: string;
}

export type Payment = StripePayment | BookstoreCodeActivation;

interface BaseLicense {
   id: number;
   type: LicenseType;
   activatedOn: Date;
   user: {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
   };
   school: {
      id: number;
      name: string;
   };
   payment: Payment;
}

export interface ProductLicense extends BaseLicense {
   type: LicenseType.product;
   productId: number;
   productName: string;
}

export interface CourseLicense extends BaseLicense {
   type: LicenseType.course;
   courseId: number;
   courseName: string;
}

export type License = ProductLicense | CourseLicense;
