import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import {
   ActivityLogicCombinator,
   ActivityLogicField,
   ActivityLogicGroup,
   ActivityLogicRule,
   ActivityLogicRuleOperator,
   ActivityVariableField,
   ActivityVariableType,
} from '@models/Activity';
import { Maybe } from '@models/Core';

import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import LogicGroup from './LogicGroup';
import { isRuleGroup } from './LogicUtils';

interface QuestionLogicProps {
   logic: ActivityLogicGroup;
   questionId: string | number;
   onLogicUpdate(logic: ActivityLogicGroup): void;
}

const combinators: readonly {
   value: ActivityLogicCombinator;
   label: string;
}[] = [
   { value: ActivityLogicCombinator.and, label: 'AND' },
   { value: ActivityLogicCombinator.or, label: 'OR' },
];

/**
 * FIXME: This whole file should be rewritten to use PredicateEditor.
 */

const QuestionLogic: React.FC<QuestionLogicProps> = ({ logic, questionId, onLogicUpdate }) => {
   const { questionTags, variables } = React.useContext<ActivityContext>(ActivityContext);

   const basicOperators = [
      { value: ActivityLogicRuleOperator.equals, label: 'is' },
      { value: ActivityLogicRuleOperator.doesNotEqual, label: 'is not' },
   ];

   const numericOperators = [
      { value: ActivityLogicRuleOperator.lessThan, label: 'is less than' },
      {
         value: ActivityLogicRuleOperator.greaterThan,
         label: 'is greater than',
      },
      {
         value: ActivityLogicRuleOperator.lessThanOrEqualTo,
         label: 'is less than or equal to',
      },
      {
         value: ActivityLogicRuleOperator.greaterThanOrEqualTo,
         label: 'is greater than or equal to',
      },
   ];

   const stringOperators = [
      { value: ActivityLogicRuleOperator.in, label: 'in' },
      { value: ActivityLogicRuleOperator.notIn, label: 'not in' },
      { value: ActivityLogicRuleOperator.contains, label: 'contains' },
      { value: ActivityLogicRuleOperator.beginsWith, label: 'begins with' },
      { value: ActivityLogicRuleOperator.endsWith, label: 'ends with' },
      {
         value: ActivityLogicRuleOperator.doesNotContain,
         label: 'does not contain',
      },
      {
         value: ActivityLogicRuleOperator.doesNotBeginWith,
         label: 'does not begin with',
      },
      {
         value: ActivityLogicRuleOperator.doesNotEndWith,
         label: 'does not end with',
      },
   ];

   const getVariableType = (variableId: string | number): Maybe<ActivityVariableType> =>
      variableId ? variables.find((i) => i.id === variableId)?.type || null : null;

   const getVariableOperators = (
      variableId: string | number,
   ): readonly { value: ActivityLogicRuleOperator; label: string }[] => {
      const variableType = getVariableType(variableId);
      if (!variableType) {
         return [];
      } else if (
         [ActivityVariableType.integer, ActivityVariableType.float].includes(variableType)
      ) {
         return [...basicOperators, ...numericOperators];
      } else if (variableType === ActivityVariableType.string) {
         return [...basicOperators, ...stringOperators];
      } else if (variableType === ActivityVariableType.boolean) {
         return [...basicOperators];
      }
      return [];
   };

   const fields: readonly ActivityLogicField[] = [
      {
         value: ActivityVariableField.questionScore,
         label: 'Question Score',
         type: ActivityVariableType.float,
         operators: [...basicOperators, ...numericOperators],
         units: [
            { value: 'points', label: 'Points' },
            { value: 'percent', label: 'Percent' },
         ],
      },
      {
         value: ActivityVariableField.activityScore,
         label: 'Activity Score',
         type: ActivityVariableType.float,
         operators: [...basicOperators, ...numericOperators],
         units: [
            { value: 'points', label: 'Points' },
            { value: 'percent', label: 'Percent' },
         ],
      },
      {
         value: ActivityVariableField.tagScore,
         label: 'Tag Score',
         type: ActivityVariableType.float,
         operators: (tag) => (tag ? [...basicOperators, ...numericOperators] : []),
         subfield: {
            placeholder: 'Select Tag',
            type: ActivityVariableType.string,
            options: questionTags.map((i) => ({ id: i, label: i })),
         },
         units: [
            { value: 'points', label: 'Points' },
            { value: 'percent', label: 'Percent' },
         ],
      },
      {
         value: ActivityVariableField.variable,
         label: 'Variable',
         type: getVariableType,
         operators: getVariableOperators,
         subfield: {
            placeholder: 'Select Variable',
            type: ActivityVariableType.string,
            options: variables.map((i) => ({ id: i.id, label: i.name })),
         },
      },
   ];

   const createRule = (): Omit<ActivityLogicRule, 'parentId'> => ({
      field: null,
      id: randomShortId(),
      index: null,
      operator: null,
      subfield: '',
      unit: null,
      value: null,
   });

   const createRuleGroup = (): Omit<ActivityLogicGroup, 'parentId'> => ({
      id: randomShortId(),
      rules: [],
      combinator: ActivityLogicCombinator.and,
      actions: [],
      index: null,
   });

   const onRuleAdd = (
      rule: Omit<ActivityLogicRule, 'parentId'>,
      parentId: number | string,
   ): void => {
      const rootCopy = { ...logic };
      const parent = findRule(parentId, rootCopy);
      if (isRuleGroup(parent)) {
         parent.rules = [
            ...parent.rules,
            {
               ...rule,
               parentId,
               value: null,
            },
         ];
      }
      onLogicUpdate(rootCopy);
   };

   const onRuleRemove = (ruleId: number | string, parentId: number | string): void => {
      const rootCopy = { ...logic };
      const parent = findRule(parentId, rootCopy);
      if (isRuleGroup(parent)) {
         parent.rules = parent.rules.filter((i) => i.id !== ruleId);
      }
      onLogicUpdate(rootCopy);
   };

   const onGroupAdd = (
      group: Omit<ActivityLogicGroup, 'parentId'>,
      parentId: Maybe<number | string>,
   ): void => {
      const rootCopy = { ...logic };
      const parent = findRule(parentId, rootCopy);
      if (isRuleGroup(parent)) {
         parent.rules = [...parent.rules, { ...group, parentId }];
         onLogicUpdate(rootCopy);
      } else {
         // handle case where parent is not a rule group or doesn't exist
         console.warn(`Invalid parentId provided to onGroupAdd: ${parentId}`);
      }
   };

   const onGroupRemove = (groupId: number | string, parentId: Maybe<number | string>): void => {
      const rootCopy = { ...logic };
      const parent = findRule(parentId, rootCopy);
      if (isRuleGroup(parent)) {
         parent.rules = parent.rules.filter((i) => i.id !== groupId);
      }
      onLogicUpdate(rootCopy);
   };

   const onPropChange = (
      ruleId: number | string,
      update: Partial<ActivityLogicRule | ActivityLogicGroup>,
   ): void => {
      const rootCopy = { ...logic };
      const rule = findRule(ruleId, rootCopy);
      if (rule) {
         Object.assign(rule, update);
         onLogicUpdate(rootCopy);
      }
   };

   const findRule = (
      id: Maybe<string | number>,
      parent: ActivityLogicRule | ActivityLogicGroup,
   ): ActivityLogicRule | ActivityLogicGroup | undefined => {
      if (parent.id === id) {
         return parent;
      }

      if (isRuleGroup(parent)) {
         for (const rule of parent.rules) {
            if (rule.id === id) {
               return rule;
            } else if (isRuleGroup(rule)) {
               const subRule = findRule(id, rule);
               if (subRule) {
                  return subRule;
               }
            }
         }
      }

      console.warn(`Rule id ${id} not found`);
      return undefined;
   };

   return (
      <LogicGroup
         group={logic}
         combinators={combinators}
         currentQuestionId={questionId}
         fields={fields}
         createRule={createRule}
         createRuleGroup={createRuleGroup}
         onRuleAdd={onRuleAdd}
         onGroupAdd={onGroupAdd}
         onRuleRemove={onRuleRemove}
         onGroupRemove={onGroupRemove}
         onPropChange={onPropChange}
      />
   );
};

export default QuestionLogic;
