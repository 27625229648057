import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import useIsMount from '@hooks/use-is-mount';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { Maybe, RowLoader } from '@models/Core';
import axios, { CancelTokenSource } from 'axios';
import classnames from 'classnames';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import Button from './Common/Button';
import Table, { Column, SortByEntry } from './Common/Table';
import EmptyState from './Core/EmptyState';
import DocumentTitle from './DocumentTitle';
import Loader from './Loader';
import { Attribute, OperatorType, PredicateGroup } from './PredicateEditor/Models';
import PredicatesEditor from './PredicateEditor/PredicatesEditor';
import { getInvalidFilterIds, getNewPredicate } from './PredicateEditor/Utils';

interface FilterableTableProps<T> {
   attributes: readonly Attribute[];
   columns: readonly Column<T>[];
   defaultPredicate: PredicateGroup;
   defaultSortOrder: readonly SortByEntry[];
   pageTitle: string;
   searchInputPlaceholder: string;
   shouldRefetch?: boolean;
   rowsLoader: RowLoader<T>;
   onAddRowClick?(): void;
   refetchComplete?(): void;
}

const FilterableTable = <T extends { id: number | string }>(props: FilterableTableProps<T>) => {
   const {
      keys: { enter },
   } = Constants;

   const location = useLocation();
   const isMount = useIsMount();

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isPredicateEditorOpen, setIsPredicateEditorOpen] = React.useState<boolean>(false);
   const [predicateChangedSinceLastFetch, setPredicateChangedSinceLastFetch] =
      React.useState<boolean>(false);
   const [page, setPage] = React.useState<Maybe<number>>(null);
   const [pages, setPages] = React.useState<Maybe<number>>(null);
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [total, setTotal] = React.useState<Maybe<number>>(null);
   const [sortOrder, setSortOrder] = React.useState<readonly SortByEntry[]>(props.defaultSortOrder);
   const [predicate, setPredicate] = React.useState<Maybe<PredicateGroup>>(null);
   const [rows, setRows] = React.useState<readonly T[]>([]);
   const cancelTokenSource = React.useRef<Maybe<CancelTokenSource>>(null);

   const setSearchFromParams = (search: string): void => {
      setSearchQuery(search);
      debounceFetchRows();
   };

   const noResults = rows.length === 0 && !isFetching;

   React.useEffect(() => {
      if (props.shouldRefetch === true) {
         debounceFetchRows()?.then(() => {
            props.refetchComplete?.();
         });
      }
   }, [props.shouldRefetch]);

   React.useEffect(() => {
      if (location.search) {
         const searchParams = new URLSearchParams(location.search);
         const searchValue = searchParams.get('search');
         if (searchValue !== null) {
            setPredicate(props.defaultPredicate);
            setSearchFromParams(searchValue);
         } else {
            const searchParamsPredicate: PredicateGroup = {
               id: randomShortId(),
               type: OperatorType.and,
               predicates: [],
            };
            Array.from(searchParams.entries()).forEach(([attribute, value]) => {
               const newPredicate = getNewPredicate(attribute, props.attributes);
               if (newPredicate) {
                  searchParamsPredicate.predicates = [
                     ...searchParamsPredicate.predicates,
                     {
                        ...newPredicate,
                        value,
                     },
                  ];
               }
            });
            setPredicate(searchParamsPredicate);
         }
      } else {
         setPredicate(props.defaultPredicate);
      }
   }, [location.search, props.defaultPredicate]);

   React.useEffect(() => {
      setBreadcrumbs({
         breadcrumbs: [{ text: props.pageTitle, link: location.pathname }],
         next: null,
         prev: null,
      });
   }, [props.pageTitle, location.pathname]);

   React.useEffect(() => {
      if (!isMount) {
         if (!isPredicateEditorOpen && predicateChangedSinceLastFetch) {
            debounceFetchRows();
         }
      }
   }, [isPredicateEditorOpen, predicateChangedSinceLastFetch]);

   React.useEffect(() => {
      if (!isMount && predicate) {
         if (!getInvalidFilterIds(predicate).length) {
            setPredicateChangedSinceLastFetch(true);
         }
      }
   }, [predicate]);

   React.useEffect(() => {
      if (!isMount) {
         debounceFetchRows();
      }
   }, [sortOrder]);

   React.useEffect(() => {
      if (page === null && predicate) {
         debounceFetchRows();
      }
   }, [page, predicate]);

   const fetchRows = (pageToFetch = 1): Promise<void> => {
      const filters = {
         query: searchQuery,
         page: pageToFetch,
         predicate,
      };
      if (cancelTokenSource.current) {
         cancelTokenSource.current.cancel();
      }
      const newCancelTokenSource = axios.CancelToken.source();
      cancelTokenSource.current = newCancelTokenSource;
      return props
         .rowsLoader(filters, sortOrder, {
            cancelToken: newCancelTokenSource.token,
         })
         .then((result) => {
            if (!result) {
               setTotal(0);
               setIsFetching(false);
               return;
            }
            setRows((prevRows) => {
               if (result.currentPageNumber === 1) {
                  return result.rows;
               }
               const existingRowIds = prevRows.map((i) => i.id);
               const filteredRows = result.rows.filter((i) => !existingRowIds.includes(i.id));
               return [...prevRows, ...filteredRows];
            });
            setPage(result.currentPageNumber);
            setPages(result.totalPageCount);
            setTotal(result.queryResultTotalCount);
            setIsFetching(false);
            setPredicateChangedSinceLastFetch(false);
         })
         .catch((error) => {
            if (axios.isCancel(error)) {
               return;
            }
         });
   };
   const debounceFetchRows = React.useCallback(_.debounce(fetchRows, 500), [
      props.rowsLoader,
      page,
      searchQuery,
      predicate,
      sortOrder,
   ]);

   const handleLoadMore = async (): Promise<void> => {
      if (_.isNumber(page) && _.isNumber(pages) && page < pages) {
         return await debounceFetchRows(page + 1);
      }
      return Promise.resolve();
   };

   const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchQuery(value);
   };

   const handleQueryKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === enter) {
         debounceFetchRows();
      }
   };

   const handleSortChange = (updatedSortBy: readonly SortByEntry[]): void => {
      setSortOrder(updatedSortBy);
   };

   const handlePredicateChange = (updatedPredicate: PredicateGroup): void => {
      setPredicate(updatedPredicate);
   };

   const handleEditorOpen = (): void => {
      setIsPredicateEditorOpen(true);
   };

   const handleEditorClose = (): void => {
      setIsPredicateEditorOpen(false);
   };

   const handleRowDelete = (row: T): void => {
      setRows((prevRows) => prevRows.filter((i) => i.id !== row.id));
      setTotal((prevTotal) => (_.isNumber(prevTotal) ? prevTotal - 1 : prevTotal));
   };

   const handleRowUpdate = (row: T): void => {
      setRows((prevRows) => prevRows.map((i) => (i.id === row.id ? row : i)));
   };

   const handleAddRowClick = (): void => {
      props.onAddRowClick?.();
   };

   if (isFetching || !predicate) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>
            {isFetching ? `Loading ${props.pageTitle}...` : props.pageTitle}
         </DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='card no-padding filterable-table'>
               <div className='card-title has-button'>
                  <div className='flex items-baseline flex-1'>
                     <div className='flex-none'>
                        <div className='flex items-center'>
                           <div className='title'>{props.pageTitle}</div>
                           <input
                              type='search'
                              name='searchQuery'
                              autoFocus
                              className='mousetrap'
                              value={searchQuery}
                              placeholder={props.searchInputPlaceholder}
                              onKeyDown={handleQueryKeyDown}
                              onChange={handleQueryChange}
                           />
                        </div>
                        <div className='result-count'>{`${total?.toLocaleString(
                           'en-US',
                        )} total`}</div>
                     </div>
                     <PredicatesEditor
                        className='margin-left-s'
                        value={predicate}
                        attributes={props.attributes}
                        onChange={handlePredicateChange}
                        onEditorClose={handleEditorClose}
                        onEditorOpen={handleEditorOpen}
                     />
                     {props.onAddRowClick && (
                        <Button
                           className='margin-left-auto'
                           onClick={handleAddRowClick}
                           style={{ fontSize: '20px' }}
                           aria-label='Add Row'
                        >
                           +
                        </Button>
                     )}
                  </div>
               </div>
               <Table<T>
                  className={classnames('sticky', { 'card-table': !noResults })}
                  columns={props.columns}
                  defaultSortBy={sortOrder}
                  hasMore={_.isNumber(page) && _.isNumber(pages) && page < pages}
                  infiniteScroll
                  onRowDelete={handleRowDelete}
                  onRowUpdate={handleRowUpdate}
                  onLoadMore={handleLoadMore}
                  onSortChanged={handleSortChange}
                  rowKey='id'
                  rows={rows}
               />
               {noResults && (
                  <EmptyState
                     icon={<IconBinoculars className='large' aria-hidden />}
                     heading='No Results'
                     description='Try changing your filters.'
                  />
               )}
            </div>
         </div>
      </>
   );
};

export default FilterableTable;
