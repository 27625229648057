import * as OT from '@opentok/client';
import * as React from 'react';

import { formatSeconds } from '@helpers/FormatTime';
import HttpService from '@services/HttpService';

import Config from '../Config';
import Constants from '../Constants';
import { Maybe } from '@models/Core';
import Button from './Common/Button';

enum RecorderState {
   not_ready = 'not_ready',
   ready = 'ready',
   starting = 'starting',
   recording = 'recording',
   stopping = 'stopping',
   stopped = 'stopped',
   pausing = 'pausing',
   paused = 'paused',
}

enum ServerConnectionStatus {
   connected = 'connected',
   connecting = 'connecting',
   disconnected = 'disconnected',
   reconnecting = 'reconnecting',
   failed = 'failed',
}

enum ArchiveStatus {
   started = 'started',
   paused = 'paused',
   stopped = 'stopped',
   uploaded = 'uploaded',
   available = 'available',
   expired = 'expired',
   failed = 'failed',
}

type OpentokSessionResponse = {
   msg: string;
   id: number;
   opentokSessionId: string;
   token: string;
};

const stateLabels: Record<RecorderState, string> = {
   [RecorderState.not_ready]: 'Record',
   [RecorderState.ready]: 'Record',
   [RecorderState.starting]: 'Finish', // 'Pause',
   [RecorderState.recording]: 'Finish', // 'Pause',
   [RecorderState.pausing]: 'Resume',
   [RecorderState.paused]: 'Resume',
   [RecorderState.stopping]: 'Finish',
   [RecorderState.stopped]: 'Finish',
};

interface OpentokRecorderProps {
   className?: string;
   onRecordingComplete(sessionId: number, url: string): void;
}

const OpentokRecorder: React.FC<OpentokRecorderProps> = ({ className, onRecordingComplete }) => {
   const apiKey = Constants.opentok[Config.environmentType];
   const [imageOverlay, setImageOverlay] = React.useState<string | null>(null);
   const [serverConnectionStatus, setServerConnectionStatus] =
      React.useState<ServerConnectionStatus>(ServerConnectionStatus.connecting);
   const [recorderState, setRecorderState] = React.useState<RecorderState>(RecorderState.not_ready);
   const [recordingTime, setRecordingTime] = React.useState<number | null>(null);
   const [opentokSessionResponse, setOpentokSessionResponse] =
      React.useState<OpentokSessionResponse>();

   const publisher = React.useRef<OT.Publisher | null>(null);
   const session = React.useRef<OT.Session | null>(null);
   const container = React.useRef<HTMLDivElement | null>(null);
   const timer = React.useRef<NodeJS.Timeout | null>(null);

   React.useEffect(() => {
      getOpentokSessionInformation();

      return () => {
         // Cleanup logic
         if (timer.current) {
            clearInterval(timer.current);
         }
         session.current?.off();
         publisher.current?.off();
         publisher.current?.destroy();
         session.current?.disconnect();
      };
   }, []);

   React.useEffect(() => {
      if (container.current && opentokSessionResponse) {
         initSession(
            opentokSessionResponse.id,
            opentokSessionResponse.opentokSessionId,
            opentokSessionResponse.token,
         );
      }
   }, [container.current, opentokSessionResponse]);

   const getOpentokSessionInformation = async () => {
      const response = await HttpService.postWithAuthToken<OpentokSessionResponse>(
         '/api/opentok/sessions',
      );
      setOpentokSessionResponse(response.data);
   };

   const initPublisher = () => {
      if (container.current !== null && publisher.current === null) {
         publisher.current = OT.initPublisher(container.current, {
            mirror: false,
            insertMode: 'append',
            width: '100%',
            height: '100%',
            fitMode: 'contain',
            style: {
               nameDisplayMode: 'on',
               buttonDisplayMode: 'off',
               audioLevelDisplayMode: 'off',
               archiveStatusDisplayMode: 'off',
            },
         });
      }
   };

   const initSession = async (sessionId: number, opentokSessionId: string, token: string) => {
      if (!opentokSessionId || !token) {
         console.error('Session ID or token is missing');
         return;
      }

      initPublisher();
      session.current = OT.initSession(apiKey, opentokSessionId);
      session.current.on({
         archiveStarted: () => {
            setRecorderState(RecorderState.recording);
            startTimer();
         },
         archiveStopped: () => {
            let newRecorderState: Maybe<RecorderState> = null;
            if (recorderState === RecorderState.pausing) {
               newRecorderState = RecorderState.paused;
            } else if (recorderState === RecorderState.stopping) {
               newRecorderState = RecorderState.stopped;
            }
            if (newRecorderState) {
               setRecorderState(newRecorderState);
            }
            stopTimer();
         },
         sessionConnected: () => {
            setServerConnectionStatus(ServerConnectionStatus.connected);
            setRecorderState(RecorderState.ready);
         },
         sessionDisconnected: () => {
            setServerConnectionStatus(ServerConnectionStatus.disconnected);
         },
         sessionReconnecting: () => {
            setServerConnectionStatus(ServerConnectionStatus.reconnecting);
         },
      });

      session.current.connect(token, (error) => {
         if (error) {
            console.error(error);
         } else if (publisher.current) {
            session.current?.publish(publisher.current);
         }
      });
      // Handle signals for archive status update
      session.current.on(
         'signal:archiveStatusUpdate',
         (
            event: OT.Event<string, OT.Session> & {
               data?: { status: ArchiveStatus; url: string };
            },
         ) => {
            if (event.data) {
               const { status: newArchiveStatus, url: newUrl } = event.data;
               if (
                  [ArchiveStatus.available, ArchiveStatus.uploaded].includes(newArchiveStatus) &&
                  newUrl &&
                  sessionId
               ) {
                  onRecordingComplete(sessionId, newUrl);
               }
            }
         },
      );
   };

   const startTimer = () => {
      const startTime = Date.now() - (recordingTime || 0);
      timer.current = setInterval(() => {
         setRecordingTime(Date.now() - startTime);
      }, 100);
   };

   const stopTimer = () => {
      if (timer.current) {
         clearInterval(timer.current);
         timer.current = null;
      }
   };

   const startArchiving = async () => {
      if (opentokSessionResponse) {
         setRecorderState(RecorderState.starting);
         await HttpService.postWithAuthToken<{ archiveId: string }>(
            `/api/opentok/sessions/${opentokSessionResponse.id}/archive`,
         );
      }
   };

   const stopArchiving = async () => {
      if (recorderState === RecorderState.paused) {
         setRecorderState(RecorderState.stopped);
      } else if (recorderState === RecorderState.recording && opentokSessionResponse) {
         setRecorderState(RecorderState.stopping);
         const imageData = publisher.current?.getImgData();
         setImageOverlay(imageData ? `data:image/png;base64,${imageData}` : null);
         await HttpService.putWithAuthToken(
            `/api/opentok/sessions/${opentokSessionResponse.id}/archive`,
         );
         // Unpublish and destroy the publisher to turn off the camera
         if (session.current && publisher.current) {
            session.current.unpublish(publisher.current);
            publisher.current.destroy();
            publisher.current = null;
         }
      }
   };

   const recordingButtonDisabled =
      [
         RecorderState.not_ready,
         RecorderState.starting,
         RecorderState.pausing,
         RecorderState.stopping,
      ].includes(recorderState) || serverConnectionStatus === ServerConnectionStatus.disconnected;

   const toggleRecording = () => {
      if ([RecorderState.recording, RecorderState.pausing].includes(recorderState)) {
         stopArchiving();
      } else {
         startArchiving();
      }
   };

   return (
      <div className={className}>
         <div className='video-responsive standard'>
            {imageOverlay ? (
               <>
                  <img className='elem overlay-image' src={imageOverlay} alt='Overlay' />
                  <div className='elem processing-overlay'>
                     <span>Processing...</span>
                  </div>
               </>
            ) : (
               <div className='elem' ref={container} />
            )}
         </div>
         {recorderState !== RecorderState.stopped && (
            <div className='recorder-footer'>
               <div className='btn-wrapper'>
                  <Button color='red' disabled={recordingButtonDisabled} onClick={toggleRecording}>
                     {stateLabels[recorderState]}
                  </Button>
               </div>
               {recorderState === RecorderState.recording && (
                  <svg
                     viewBox='0 0 100 100'
                     xmlns='http://www.w3.org/2000/svg'
                     className='recording-indication animated pulse infinite'
                  >
                     <circle cx='50' cy='50' r='50' />
                  </svg>
               )}
               <div className='recording-time'>{formatSeconds((recordingTime ?? 0) / 1000)}</div>
            </div>
         )}
      </div>
   );
};

export default OpentokRecorder;
