import * as _ from 'lodash';
import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import { Maybe } from '@models/Core';
import { Subtitle } from '@models/Video';
import classNames from 'classnames';
import { Language } from 'unsplash-js';

interface VideoSubtitlesProps {
   loop: boolean;
   nativeSubtitles?: readonly Subtitle[];
   progress: number;
   showNativeSubtitles?: boolean;
   showTargetSubtitles?: boolean;
   targetSubtitles?: readonly Subtitle[];
   targetLanguage?: Language;
   seekTo?(seconds: number): void;
}

const VideoSubtitles: React.FC<VideoSubtitlesProps> = ({
   loop,
   nativeSubtitles = [],
   progress,
   showNativeSubtitles = true,
   showTargetSubtitles = true,
   targetLanguage,
   targetSubtitles = [],
   seekTo,
}) => {
   const [currentNativeSubtitle, setCurrentNativeSubtitle] = React.useState<Maybe<Subtitle>>();
   const [currentTargetSubtitle, setCurrentTargetSubtitle] = React.useState<Maybe<Subtitle>>();

   React.useEffect(() => {
      if (
         loop &&
         currentTargetSubtitle &&
         progress === currentTargetSubtitle?.endInSeconds &&
         _.isNumber(currentTargetSubtitle?.startInSeconds)
      ) {
         seekTo?.(currentTargetSubtitle.startInSeconds);
      }

      const targetSubtitle = targetSubtitles.find(
         (x) => x.startInSeconds < progress && x.endInSeconds > progress,
      );
      const nativeSubtitle = nativeSubtitles.find(
         (x) => x.startInSeconds < progress && x.endInSeconds > progress,
      );

      setCurrentTargetSubtitle(targetSubtitle);
      setCurrentNativeSubtitle(nativeSubtitle);
   }, [progress, loop, targetSubtitles, nativeSubtitles]);

   const singleSubtitles =
      !(targetSubtitles.length > 0 && nativeSubtitles.length > 0) ||
      !(showTargetSubtitles && showNativeSubtitles);

   const noSubtitles =
      (targetSubtitles.length === 0 || !showTargetSubtitles) &&
      (nativeSubtitles.length === 0 || !showNativeSubtitles);

   if (noSubtitles) {
      return;
   }

   return (
      <div
         className={classNames('subtitles-wrapper', {
            'single-line': singleSubtitles,
         })}
      >
         {currentTargetSubtitle &&
            showTargetSubtitles &&
            currentTargetSubtitle.lines.map((x) => (
               <div className='subtitle target' key={randomShortId()} lang={targetLanguage}>
                  {x}
               </div>
            ))}
         {currentNativeSubtitle &&
            showNativeSubtitles &&
            currentNativeSubtitle.lines.map((x) => (
               <div className='subtitle native' key={randomShortId()} lang='en'>
                  {x}
               </div>
            ))}
      </div>
   );
};

export default VideoSubtitles;
