import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowUp from '@icons/general/icon-arrow-up.svg';
import IconHidden from '@icons/general/icon-hidden.svg';
import IconRowMover from '@icons/general/icon-row-mover.svg';
import classnames from 'classnames';

import Constants from '../../../Constants';
import { ModuleStatus } from '@models/Course/Module';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';

interface ModuleListItemProps {
   canEditModules: boolean;
   courseId: number;
   isActive: boolean;
   isDropTarget: boolean;
   isExpanded: boolean;
   isParent: boolean;
   isSubmodule: boolean;
   itemCount: number;
   moduleId: number;
   name: string;
   status: ModuleStatus;
   toggleExpanded(): void;
}

const ModuleListItem: React.FC<ModuleListItemProps> = ({
   canEditModules,
   courseId,
   isActive,
   isDropTarget,
   isExpanded,
   isParent,
   isSubmodule,
   itemCount,
   moduleId,
   name,
   status,
   toggleExpanded,
}) => {
   const {
      routes: {
         courses: { viewModule },
      },
   } = Constants;
   const link = viewModule
      .replace(':courseId', courseId.toString())
      .replace(':moduleId', moduleId.toString());

   const handleToggleExpandedClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      // Click event shouldn't propagate to link. Otherwise a non-active item toggled will always become active
      event.preventDefault();
      toggleExpanded();
   };

   return (
      <Link to={link} data-test={`link-module-${moduleId}`}>
         <div
            className={classnames('module-item', {
               active: isActive,
               'is-drop-target': isDropTarget,
               'sub-module': isSubmodule,
            })}
         >
            {canEditModules && (
               <div className='row-mover'>
                  <IconRowMover />
               </div>
            )}
            <div className='title'>{name}</div>
            <div className='attribute-wrapper'>
               {status === ModuleStatus.draft && (
                  <div className='module-item-hidden'>
                     <IconHidden />
                  </div>
               )}
               {isParent && (
                  <Button
                     ariaLabel={`${isExpanded ? 'close' : 'expand'} submodules`}
                     className='expand-module-button'
                     icon={isExpanded ? <IconArrowUp /> : <IconArrowDown />}
                     onClick={handleToggleExpandedClick}
                     subtle
                  />
               )}
               <div
                  className={classnames('module-item-number', {
                     active: isActive,
                  })}
               >
                  {itemCount}
               </div>
            </div>
         </div>
      </Link>
   );
};

export default ModuleListItem;
