import * as React from 'react';

import classnames from 'classnames';

interface DroppableProps {
   children: React.ReactNode;
   className?: string;
   disabled?: boolean;
   droppableId: string;
   value: string;
   'data-test'?: string;
   onChange(value: string): void;
   onDrop(event: React.DragEvent<HTMLDivElement>, dropTarget: string): void;
}

const Droppable: React.FC<DroppableProps> = ({
   children,
   className = '',
   value,
   droppableId,
   onChange,
   onDrop,
   ...rest
}) => {
   const [isDraggingOver, setIsDraggingOver] = React.useState<boolean>(false);
   const [dropped, setDropped] = React.useState<boolean>(false);

   const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      setIsDraggingOver(true);
   };

   const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
      onDrop(event, droppableId);
      setIsDraggingOver(false);
      setDropped(true);
   };

   const handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
      setIsDraggingOver(false);
   };

   const handleMouseLeave = (): void => {
      setDropped(false);
   };

   return (
      <span
         className={classnames(
            'droppable',
            { 'dragging-over': isDraggingOver, empty: !value, dropped },
            className,
         )}
         data-test={rest['data-test']}
         onDragOver={handleDragOver}
         onDragLeave={handleDragLeave}
         onMouseLeave={handleMouseLeave}
         onDrop={handleDrop}
      >
         {children}
      </span>
   );
};

export default Droppable;
