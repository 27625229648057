import * as React from 'react';

import { Container, Draggable, DropResult } from '@components/Core/DragAndDrop';
import EmptyState from '@components/Core/EmptyState';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import { ActivityBuilderMode, ActivityQuestion } from '@models/Activity';

import Constants from '../../../Constants';
import Question from './Question';

interface ActivityContentProps {
   isDraggingPrompt: boolean;
   questions: readonly ActivityQuestion<ActivityBuilderMode>[];
   duplicateItem(itemKey: string, questionId: string | number): void;
   duplicateQuestion(id: string | number): void;
   handleQuestionDrop(event: DropResult): void;
   handleQuestionUpdate(
      id: string | number,
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   removeQuestion(id: string | number): void;
}

const ActivityContent: React.FC<ActivityContentProps> = ({
   questions,
   duplicateItem,
   duplicateQuestion,
   handleQuestionDrop,
   handleQuestionUpdate,
   removeQuestion,
   isDraggingPrompt,
}) => {
   const { nonDragAreaSelector } = Constants;

   return (
      <div className='question-card-container builder'>
         <Container
            groupName='builder-questions'
            getChildPayload={(i) => questions[i]}
            nonDragAreaSelector={nonDragAreaSelector}
            onDrop={handleQuestionDrop}
         >
            {questions.length ? (
               questions.map((question) => (
                  <Draggable key={question.id}>
                     <Question
                        isDraggingPrompt={isDraggingPrompt}
                        question={question}
                        removeQuestion={() => removeQuestion(question.id)}
                        duplicateItem={duplicateItem}
                        duplicateQuestion={() => duplicateQuestion(question.id)}
                        handleQuestionUpdate={(u, c) => handleQuestionUpdate(question.id, u, c)}
                     />
                  </Draggable>
               ))
            ) : (
               <EmptyState
                  icon={<IconContentBook2 aria-hidden />}
                  heading='No Questions Yet!'
                  description={
                     <p className='small-text gray-text margin-top-s margin-bottom-s'>
                        Add a question section to begin, or double click on the item that you want
                        to add.
                     </p>
                  }
               />
            )}
         </Container>
      </div>
   );
};

export default ActivityContent;
