import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import { AdminExamOrder } from '@models/NationalExam';
import AdminNationalExamOrderService from '@services/AdminNationalExamOrderService';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import { Column } from '@components/Common/Table';
import { renderDateCell } from '@components/Common/TableCellHelpers';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';

const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [],
};

const ExamOrdersTable: React.FC = () => {
   const columns: readonly Column<AdminExamOrder>[] = [
      { id: 'id', header: 'Order id', cell: (i) => orderLink(i.id) },
      { id: 'school_name', header: 'School', cell: (i) => i.schoolName },
      {
         id: 'instructor_name',
         header: 'Instructor Name',
         cell: (i) => `${i.instructorFirstName} ${i.instructorLastName}`,
      },
      {
         id: 'instructor_email',
         header: 'Order Email',
         cell: (i) => i.instructorEmail,
      },
      { id: 'exam_levels', header: 'Levels', cell: (i) => i.examLevels },
      { id: 'total_seats', header: 'Seats', cell: (i) => i.totalSeats },
      { id: 'exam_name', header: 'Exam Name', cell: (i) => i.examName },
      { id: 'exam_year', header: 'Exam Year', cell: (i) => i.examYear },
      {
         id: 'instructor_id',
         header: 'Connected',
         cell: (i) => (i.instructorId ? 'Yes' : 'No'),
      },
      {
         id: 'created_on',
         header: 'Created',
         cell: (i) => renderDateCell(i.createdOn),
      },
   ];

   const orderLink = (id: number): React.ReactNode => {
      const linkToOrder = Constants.routes.admin.nationalExam.viewExamOrderDetails.replace(
         ':orderId',
         `${id}`,
      );
      return <Link to={linkToOrder}>{`${id}`}</Link>;
   };

   return (
      <FilterableTable<AdminExamOrder>
         attributes={[]}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'id', desc: true }]}
         pageTitle='Exam Orders'
         searchInputPlaceholder='Search Orders'
         rowsLoader={AdminNationalExamOrderService.getExamOrders}
      />
   );
};

export default ExamOrdersTable;
