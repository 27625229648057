import * as React from 'react';

import Appearance from '@models/Appearance';

import ModalDialog from './ModalDialog';

interface ConfirmModalProps {
   appearance?: Appearance;
   header?: string;
   message?: React.ReactNode;
   onClose(): void;
   onPrimaryClick(): void;
   primaryClickText?: string;
   closeText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
   appearance,
   header,
   message,
   onClose,
   onPrimaryClick,
   primaryClickText,
   closeText,
}) => (
   <ModalDialog
      appearance={appearance || Appearance.warning}
      heading={header || 'Are you sure?'}
      onClose={onClose}
      animations={{ enter: 'animated bounceInDown' }}
      actions={[
         { text: primaryClickText || 'Yes', onClick: onPrimaryClick },
         { text: closeText || 'No', onClick: onClose },
      ]}
   >
      {message && <div>{message || 'Are you sure?'}</div>}
   </ModalDialog>
);

export default ConfirmModal;
