import * as React from 'react';

import ModalDialog from '@components/Core/ModalDialog';
import SelectTypeAhead from '@components/SelectTypeAhead';
import {
   Organization,
   OrganizationProfileFragment,
   OrganizationSearchResultFragment,
} from '@generated/gql/graphql';
import { GraphqlClient } from '@graphql/client';
import { insertChildOrganizationMutation, organizationTypeaheadQuery } from '@graphql/queries';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { StringOption } from '@models/ReactSelectHelperTypes';
import { useMutation } from 'urql';

import { AppStateContext } from '../../../AppState';

interface Props {
   organization: OrganizationProfileFragment;
   refetch(): void;
   setIsModalOpen(isOpen: boolean): void;
}

const AddChildOrganizationModal: React.FC<Props> = (props) => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);

   const [selectedOrganizationOption, setSelectedOrganizationOption] =
      React.useState<Maybe<StringOption>>();

   const renderOrganization = (organization: Organization): string =>
      [organization.name, organization.city, organization.state, organization.zipCode].join(', ');

   const loadOrganizations = async (
      filters: PagedSearchFilters,
   ): Promise<PagedResponse<OrganizationSearchResultFragment>> => {
      const results = await GraphqlClient.query(organizationTypeaheadQuery, {
         searchString: filters.query,
         currentPageNumber: 1,
         pageSize: 10,
         orderBy: [],
      });

      if (!results.data) {
         throw new Error('No data returned from GraphQL query');
      }

      return {
         currentPageNumber: 1,
         pageSize: 10,
         queryResultTotalCount: results.data.organizationSearch.queryResultTotalCount,
         rows: results.data.organizationSearch.rows,
         totalPageCount: 1,
      };
   };

   const [{ fetching: isInsertingChildOrganization }, executeInsertChildOrganizationMutation] =
      useMutation(insertChildOrganizationMutation);

   const handleSubmit = async (): Promise<void> => {
      if (selectedOrganizationOption === undefined || selectedOrganizationOption === null) {
         return;
      }

      const parentId = props.organization.id;
      const childId = selectedOrganizationOption.value;

      const response = await executeInsertChildOrganizationMutation({
         childId,
         parentId,
      });

      if (response.error) {
         dispatchToast({
            message:
               response.error.message.length > 0
                  ? response.error.message
                  : 'Error inserting organization hierarchy',
            appearance: Appearance.danger,
         });
      } else {
         dispatchToast({
            message: 'Organization hierarchy added',
            appearance: Appearance.success,
         });

         props.refetch();
         props.setIsModalOpen(false);
      }

      return;
   };

   return (
      <ModalDialog
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit,
               disabled:
                  selectedOrganizationOption === undefined ||
                  selectedOrganizationOption === null ||
                  isInsertingChildOrganization,
            },
            { text: 'Cancel', onClick: () => props.setIsModalOpen(false) },
         ]}
         bodyClassName='content-row-container'
         footerClassName='card-footer text-center'
         heading={`Add Child Organization to ${props.organization.name}`}
      >
         <div className='row'>
            <div className='col-xs-12'>
               <label className='field-title'>Child Organization</label>
               <SelectTypeAhead
                  labelContent={renderOrganization}
                  placeHolder='Select an organization'
                  rowLoader={loadOrganizations}
                  selectedOption={selectedOrganizationOption}
                  setSelectedOption={setSelectedOrganizationOption}
               />
            </div>
         </div>
      </ModalDialog>
   );
};

export default AddChildOrganizationModal;
