export enum PrimitiveType {
   boolean = 'boolean',
   date = 'date',
   float = 'float',
   integer = 'integer',
   string = 'string',
}

export enum OperatorType {
   and = 'and',
   or = 'or',
}

export enum BooleanComparison {
   true = 'true',
   false = 'false',
   isUnknown = 'unknown',
   hasAnyValue = 'known',
}

export enum StringComparison {
   equals = 'eq',
   doesNotEqual = 'ne',
   startsWith = 'starts_with',
   endsWith = 'ends_with',
   contains = 'contains',
   doesNotContain = 'not_contains',
   isUnknown = 'unknown',
   hasAnyValue = 'known',
}

export enum RelativeComparison {
   greaterThan = 'gt',
   lessThan = 'lt',
   is = 'eq',
   isNot = 'ne',
   isUnknown = 'unknown',
   hasAnyValue = 'known',
}

export interface AbsoluteDatePredicateValue {
   month: number;
   day: number;
   year: number;
}

interface AttributeOption {
   value: string;
   label: string;
}

export interface Attribute {
   icon: React.SVGFactory;
   identifier: string;
   label: string;
   type: PrimitiveType;
   options?: readonly AttributeOption[];
}

export type Predicate = StringPredicate | DatePredicate | NumericPredicate | BooleanPredicate;

export type PredicateType = PrimitiveType | OperatorType;

export type PredicateComparison = StringComparison | RelativeComparison | BooleanComparison;

export type PredicateValue = AbsoluteDatePredicateValue | string;

export interface PredicateGroup {
   id: string;
   predicates: readonly (Predicate | PredicateGroup)[];
   type: OperatorType;
}

export interface BasePredicate {
   attribute: string;
   comparison: PredicateComparison;
   id: string;
   type: PredicateType;
   value?: PredicateValue;
}

export interface StringPredicate extends BasePredicate {
   comparison: StringComparison;
   type: PrimitiveType.string;
   value: string;
}

export interface RelativeDatePredicate extends BasePredicate {
   comparison: RelativeComparison;
   type: PrimitiveType.date;
   value: string;
}

export interface AbsoluteDatePredicate extends BasePredicate {
   comparison: RelativeComparison;
   type: PrimitiveType.date;
   value: AbsoluteDatePredicateValue;
}

export type DatePredicate = RelativeDatePredicate | AbsoluteDatePredicate;

export interface NumericPredicate extends BasePredicate {
   comparison: RelativeComparison;
   type: PrimitiveType.integer | PrimitiveType.float;
   value: string;
}

export interface BooleanPredicate extends BasePredicate {
   comparison: BooleanComparison;
   type: PrimitiveType.boolean;
}
