import * as React from 'react';

import Language from '@models/Language';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';

interface CourseDescriptionProps {
   language: Language;
   description: string;
   handleDescriptionChange(description: string): void;
}

const CourseDescription: React.FC<CourseDescriptionProps> = ({
   language,
   description,
   handleDescriptionChange,
}) => (
   <div className='card course-card'>
      <div className='card-title full-width'>
         <div className='title'>Description</div>
      </div>
      <div className='content-form'>
         <div className='row'>
            <div className='col-xs-12'>
               <div className='description-input'>
                  <Editor
                     onChange={handleDescriptionChange}
                     rtl={language === 'ar'}
                     language={language}
                     toolbarAppearance={ToolbarAppearance.fixed}
                     value={description}
                  />
               </div>
            </div>
         </div>
      </div>
   </div>
);

export default React.memo(CourseDescription);
