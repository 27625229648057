const getDisabledRosterTooltip = (isArchived: boolean, isDemo: boolean): string => {
   if (isArchived || isDemo) {
      return `Roster modification is disabled for ${isDemo ? 'demo' : 'archived'} courses`;
   }
   return '';
};

const CourseStrings = {
   getDisabledRosterTooltip,
};

export default CourseStrings;
