import * as React from 'react';

import Constants from '../../../Constants';
import { GradebookSettings } from './Gradebook';

interface GradeRangesProps {
   gradebookSettings: GradebookSettings;
}

const GradeRanges: React.FC<GradeRangesProps> = ({ gradebookSettings }) => {
   const { staticUrl } = Constants;
   // SVG looses shadow when imported through react-svg
   const gradeIcons: Record<keyof GradebookSettings, JSX.Element> = {
      green: <img src={`${staticUrl}/img/icon-green-dot.svg`} />,
      red: <img src={`${staticUrl}/img/icon-red-dot.svg`} />,
      yellow: <img src={`${staticUrl}/img/icon-yellow-dot.svg`} />,
   };

   const renderGradeRangeItems = (): readonly React.ReactNode[] =>
      ['green', 'yellow', 'red'].map((i) => {
         const min =
            gradebookSettings === null ? 0 : gradebookSettings[i as keyof GradebookSettings].min;
         const max =
            gradebookSettings === null ? 0 : gradebookSettings[i as keyof GradebookSettings].max;
         return (
            <li key={i}>
               <div className='score-dot'>{gradeIcons[i as keyof GradebookSettings]}</div>
               {min} - {max}
            </li>
         );
      });

   return (
      <div className='gradebook-course-detail-bottom'>
         <ul>{renderGradeRangeItems()}</ul>
      </div>
   );
};

export default GradeRanges;
