import * as _ from 'lodash';

import { ActivityMode, ActivityResponseEvaluation } from '@models/Activity';
import { MessageResponse } from '@models/Core';

import AxiosService from './AxiosService';

export const update = (
   responseId: number,
   data: ActivityResponseEvaluation<ActivityMode.grade>,
): Promise<MessageResponse> =>
   AxiosService.put<MessageResponse>(
      `/api/activities/responses/${responseId}/evaluation`,
      _.omit(data, 'isSaving', 'isSaved'),
   ).then((response) => response.data);

export default {
   update,
};
