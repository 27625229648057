import { useDroppable } from '@dnd-kit/core';
import { ID } from '@models/Core';
import React from 'react';

type Props<T extends object> = {
   children: React.ReactNode;
   className?: string;
   data: T;
   id: ID;
};

const Droppable = <T extends object>(props: Props<T>) => {
   const { isOver, setNodeRef } = useDroppable({
      id: props.id,
      data: props.data,
   });
   const style = {
      color: isOver ? 'green' : undefined,
   };

   return (
      <div ref={setNodeRef} className={props.className} style={style}>
         {props.children}
      </div>
   );
};

export default Droppable;
