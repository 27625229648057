import * as _ from 'lodash';

import ExtendedCourseProfile from '@components/AdminCoursesTable/ExtendedCourseProfile';
import { SortByEntry } from '@components/Common/Table';
import { encodePredicate } from '@components/PredicateEditor/Utils';
import { serializeDates } from '@helpers/DateUtils';
import EncodingUtils from '@helpers/EncodingUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { AccountType } from '@models/AccountType';
import { ID, MessageResponse } from '@models/Core';
import {
   BasicCourseProfile,
   Course,
   CourseEnrollment,
   CourseInvite,
   CourseLMSConnection,
   CourseMode,
   CoursePermissions,
   CourseSectionSummary,
} from '@models/Course';
import { NationalExamAbbreviation } from '@models/NationalExam';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';

import AxiosService from './AxiosService';
import HttpService, { HttpServiceRequestOptions } from './HttpService';

interface BaseCourseResponse {
   msg?: string;
   courses: {
      archived: readonly BasicCourseProfile[];
      current: readonly BasicCourseProfile[];
   };
}

interface CourseInviteProfile {
   courseId: number;
   courseName: string;
   inviteId: number;
   autoAccept: boolean;
}

interface CreateCourseResponse extends BaseCourseResponse {
   courses: {
      archived: readonly BasicCourseProfile[];
      current: readonly BasicCourseProfile[];
   };
   id: number;
}

interface JoinCourseResponse extends BaseCourseResponse {
   courseId: number;
}

interface GetCoursesResponse extends BaseCourseResponse {
   invites: readonly CourseInviteProfile[];
   nationalExams: readonly NationalExamAbbreviation[];
}

interface SendCourseInvitesResponse {
   enrollments: readonly CourseEnrollment[];
   invites: readonly CourseInvite[];
   msg: string;
}

interface GetCourseSectionsResponse {
   courseSections: readonly CourseSectionSummary[];
   instructorSections: readonly CourseSectionSummary[];
}

export type SimpleCourse = {
   id: number;
   name: string;
   isOrganizationStandard: boolean;
};

export type CreateCourseFromTemplateResponse = {
   newCourseId: number;
   newCourseName: string;
};

type CloneableCoursesResponse = {
   courses: readonly SimpleCourse[];
};

const sendInvites = (
   courseId: number,
   invites: readonly { email: string; studentId?: string }[],
   accountType: AccountType = AccountType.student,
): Promise<SendCourseInvitesResponse> =>
   HttpService.postWithAuthToken<SendCourseInvitesResponse>(
      `/api/courses/${courseId}/invites`,
      snakeCaseKeys({ invites, accountType }),
   ).then((response) => response.data);

const updateTrialEndOn = (
   courseId: number,
   userIds: readonly number[] | number,
   trialEndOn: Date,
): Promise<void> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/trial_end_on`,
      snakeCaseKeys({
         userIds: _.isArray(userIds) ? userIds : [userIds],
         trialEndOn,
      }),
   ).then();

const joinCourseByCode = (courseCode: string): Promise<number> =>
   HttpService.postWithAuthToken<JoinCourseResponse>(
      '/api/courses/enrollments',
      snakeCaseKeys({ courseCode }),
      { handleNotFound: false, handleForbidden: false },
   ).then((response) => {
      const {
         courses: { archived, current },
         courseId,
      } = response.data;
      store.dispatch(actionFunctions.setCourses(current, archived));
      return courseId;
   });

const joinCourseByInvite = (inviteId: string): Promise<number> =>
   HttpService.postWithAuthToken<JoinCourseResponse>(
      '/api/courses/join',
      snakeCaseKeys({ inviteId }),
      {
         handleNotFound: false,
         handleForbidden: false,
      },
   ).then((response) => {
      const {
         courses: { archived, current },
         courseId,
      } = response.data;
      store.dispatch(actionFunctions.setCourses(current, archived));
      return courseId;
   });

const leaveCourse = (courseId: number): Promise<BaseCourseResponse> =>
   AxiosService.delete<BaseCourseResponse>(`/api/enrollments/leave_course/${courseId}`).then(
      (response) => {
         const {
            courses: { archived, current },
         } = response.data;
         store.dispatch(actionFunctions.setCourses(current, archived));
         return { courses: { archived, current } };
      },
   );

const resetStudentCourseCode = (courseId: number): Promise<string> =>
   HttpService.postWithAuthToken<{ courseCode: string }>(
      `/api/courses/${courseId}/course_code`,
   ).then((response) => response.data.courseCode);

const resetInstructorCourseCode = (courseId: number): Promise<string> =>
   HttpService.postWithAuthToken<{ instructorCourseCode: string }>(
      `/api/courses/${courseId}/instructor_code`,
   ).then((response) => response.data.instructorCourseCode);

const resetSectionCourseCode = (courseId: number, sectionId: string | number): Promise<string> =>
   HttpService.postWithAuthToken<{ courseCode: string }>(
      `/api/courses/${courseId}/sections/${sectionId}/course_code`,
   ).then((response) => response.data.courseCode);

const clearUserLicensingCache = (courseId: number): Promise<number> =>
   HttpService.deleteWithAuthToken<{ deletedCount: number }>(
      `/api/admin/cache/clear_user_licensing_for_course/${courseId}`,
   ).then((i) => {
      const { deletedCount } = i.data;
      return deletedCount;
   });

const generateMissingSubmissions = (courseId: number): Promise<number> =>
   HttpService.postWithAuthToken<{ generatedCount: number }>(
      `/api/admin/submissions/generate_missing_submissions_for_course/${courseId}`,
   ).then((i) => {
      const { generatedCount } = i.data;
      return generatedCount;
   });

const createGradingCategory = (courseId: number, gradingCategoryName: string): Promise<number> =>
   HttpService.postWithAuthToken<{ id: number }>(
      `/api/courses/${courseId}/grading_categories`,
      snakeCaseKeys({ name: gradingCategoryName }),
   ).then((response) => response.data.id);

const searchCourses = (
   { predicate, ...rest }: PagedSearchFilters,
   sortOrder: readonly SortByEntry[],
   options: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<ExtendedCourseProfile>> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(rest));
   if (predicate) {
      encodedQuery.set('predicate', encodePredicate(predicate));
   }
   if (sortOrder) {
      const str = JSON.stringify(sortOrder);
      encodedQuery.set('order_by', EncodingUtils.stringToB64(str));
   }
   return HttpService.getWithAuthToken<PagedResponse<ExtendedCourseProfile>>(
      `/api/admin/courses?${encodedQuery}`,
      options,
   ).then((response) => response.data);
};

const getCourses = (): Promise<GetCoursesResponse> =>
   HttpService.getWithAuthToken<GetCoursesResponse>('/api/courses').then(
      (response) => response.data,
   );

const deleteCourse = (courseId: number): Promise<BaseCourseResponse> =>
   HttpService.deleteWithAuthToken<BaseCourseResponse>(`/api/courses/${courseId}`).then(
      (response) => {
         const {
            courses: { archived, current },
            courses,
         } = response.data;
         store.dispatch(actionFunctions.setCourses(current, archived));
         return { courses };
      },
   );

const patchCourse = (
   courseId: number | string,
   updates: Partial<Course<CourseMode.edit>>,
): Promise<BaseCourseResponse> =>
   HttpService.patchWithAuthToken<BaseCourseResponse>(
      `/api/courses/${courseId}`,
      snakeCaseKeys(serializeDates(updates)),
   ).then((response) => response.data);

const createCourse = (course: Course<CourseMode.create>): Promise<CreateCourseResponse> =>
   HttpService.postWithAuthToken<CreateCourseResponse>(
      '/api/courses',
      snakeCaseKeys(serializeDates(course)),
   ).then((response) => response.data);

const getCourseSections = (courseId: number | string): Promise<GetCourseSectionsResponse> =>
   HttpService.getWithAuthToken<GetCourseSectionsResponse>(
      `/api/courses/${courseId}/sections`,
   ).then((response) => response.data);

const checkIfCourseExistsByName = (courseName: string, excludeId?: number): Promise<boolean> =>
   AxiosService.get<{ exists: boolean }>('/api/courses/check_if_exists_by_name', {
      params: {
         courseName,
         excludeId,
      },
   }).then((response) => response.data.exists);

const getCloneableCourses = (): Promise<readonly SimpleCourse[]> =>
   AxiosService.get<CloneableCoursesResponse>('/api/courses/cloneable_courses').then(
      (response) => response.data.courses,
   );

const createCourseFromTemplate = (
   templateCourseId: number,
   course: Partial<Course<CourseMode.create>>,
): Promise<CreateCourseFromTemplateResponse> => {
   const { id: _oldCourseId, ...rest } = course;

   return AxiosService.post<CreateCourseFromTemplateResponse>(
      `/api/courses/${templateCourseId}/clone_from_template`,
      rest,
   ).then((response) => response.data);
};

const getLMSConnections = (): Promise<readonly CourseLMSConnection[]> =>
   AxiosService.get<{ lmsConnections: readonly CourseLMSConnection[] }>(
      '/api/lms/lms_connections',
   ).then((response) => response.data.lmsConnections);

const updatePermissions = async (
   courseId: number,
   permissions: CoursePermissions,
): Promise<CoursePermissions> => {
   const reponse = await AxiosService.patch<CoursePermissions>(
      `/api/courses/${courseId}/permissions`,
      permissions,
   );
   return reponse.data;
};

const getPermissions = async (courseId: number): Promise<CoursePermissions> => {
   const response = await AxiosService.get<CoursePermissions>(
      `/api/courses/${courseId}/permissions`,
   );
   return response.data;
};

const shouldStudentsPay = async (courseId: ID): Promise<boolean> => {
   const response = await AxiosService.get<{ shouldStudentPay: boolean }>(
      `/api/courses/${courseId}/should_students_pay`,
   );
   return response.data.shouldStudentPay;
};

export default {
   checkIfCourseExistsByName,
   clearUserLicensingCache,
   createCourse,
   createCourseFromTemplate,
   createGradingCategory,
   deleteCourse,
   generateMissingSubmissions,
   getCloneableCourses,
   getCourseSections,
   getCourses,
   getLMSConnections,
   getPermissions,
   joinCourseByCode,
   joinCourseByInvite,
   leaveCourse,
   patchCourse,
   resetInstructorCourseCode,
   resetSectionCourseCode,
   resetStudentCourseCode,
   searchCourses,
   sendInvites,
   shouldStudentsPay,
   updatePermissions,
   updateTrialEndOn,
};
