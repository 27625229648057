import * as React from 'react';

import classnames from 'classnames';

import { IFeedback } from './Models';

interface IFeedbackProps {
   feedback: IFeedback;
   show: boolean;
}

interface IFeedbackState {
   isEntering: boolean;
   isExiting: boolean;
   shouldMount: boolean;
}

const ENTER_ANIMATION_CLASS = 'animated slideInUp faster';
const EXIT_ANIMATION_CLASS = 'animated fadeOut faster';

const Feedback: React.FC<IFeedbackProps> = ({ feedback, show }) => {
   const [state, setState] = React.useState<IFeedbackState>({
      isEntering: false,
      isExiting: false,
      shouldMount: false,
   });

   React.useEffect(() => {
      setState({
         isEntering: feedback && show,
         isExiting: !show,
         shouldMount: !!feedback,
      });
   }, [feedback, show]);

   const handleAnimationEnd = (): void => {
      setState((prevState) => ({
         isEntering: false,
         isExiting: false,
         shouldMount: prevState.isEntering,
      }));
   };

   if (state.shouldMount && feedback) {
      const animationClasses = {
         [ENTER_ANIMATION_CLASS]: state.isEntering,
         [EXIT_ANIMATION_CLASS]: state.isExiting,
      };

      return (
         <div
            className={classnames(
               'card padding-bottom-m padding-top-m session-feedback',
               animationClasses,
            )}
            onAnimationEnd={handleAnimationEnd}
         >
            <div className={classnames('answer', feedback.class)}>
               <p>{feedback.text}</p>
            </div>
         </div>
      );
   }

   return <></>;
};

export default React.memo(Feedback);
