// @ts-strict-ignore
import * as React from 'react';

import { TooltipProps } from 'recharts';

import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const TooltipContent: React.FC<TooltipProps<ValueType, NameType>> = (props) => {
   if (props.active) {
      const startDate = props.payload[0].payload.startDate;
      const endDate = props.payload[0].payload.endDate;
      const weekIndex = props.payload[0].payload.weekIndex;
      const reversedArray = props.payload.slice().reverse();

      return (
         <div className='student-proficiency-tooltip-content'>
            <span className='row bold'>Week {weekIndex + 1}</span>
            <span className='row'>
               {startDate} - {endDate}
            </span>
            {reversedArray.map((x) => (
               <div className='margin-top-s' key={x.name}>
                  <div className='row space-between'>
                     <span>{x.name}</span>
                     <span className='bold margin-left-s'>{x.payload[x.dataKey]}</span>
                  </div>
               </div>
            ))}
         </div>
      );
   }

   return null;
};

export default TooltipContent;
