import { Maybe } from '@models/Core';

export const formatNumberToMaybeString = (
   v: Maybe<number>,
   forceSign = true,
   decimal = true,
): Maybe<string> => {
   if (v === null || v === undefined) {
      return '';
   }

   const decimalValueString = v.toLocaleString('en-US', {
      minimumFractionDigits: decimal ? 1 : 0,
   });
   const formattedWithPositiveNegativeSign =
      v >= 0 ? (forceSign ? '+' : '') + decimalValueString : decimalValueString;
   return formattedWithPositiveNegativeSign;
};

export const formatValueString = (v: string): Maybe<string> => {
   const floatValue = parseFloat(v);
   if (isNaN(floatValue)) {
      return null;
   }
   const formattedString = formatNumberToMaybeString(floatValue);
   return formattedString;
};

export const getFloatValueFromString = (v: string): Maybe<number> => {
   const floatValue = parseFloat(v);
   if (isNaN(floatValue)) {
      return null;
   }
   return floatValue;
};

export const getIntegerValueFromString = (v: string): Maybe<number> => {
   const floatValue = parseInt(v);
   if (isNaN(floatValue)) {
      return null;
   }
   return floatValue;
};
