// @ts-strict-ignore
import * as React from 'react';

import useStateWithReset from '@hooks/use-state-with-reset';
import IconInterfaceInformation from '@icons/nova-solid/22-Interface-Feedback/interface-information.svg';
import Appearance from '@models/Appearance';
import { DefaultAssignmentSettings, Module } from '@models/Course';
import classnames from 'classnames';

import Switch from '@components/Common/Switch';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import ModalDialog from '@components/Core/ModalDialog';
import AssignmentDatePicker from './AssignmentDatePicker';
import {
   getAttemptsAllowedError,
   getTimeLimitError,
   MIN_ATTEMPTS_ALLOWED,
   MIN_TIME_LIMIT,
} from './ModuleUtils';

interface AssignmentDefaultsModalProps {
   allowOverwrite: boolean;
   assignmentDefaults: DefaultAssignmentSettings;
   courseEndDate: Date;
   courseStartDate: Date;
   onClose(): void;
   onSave(update: Partial<Module>, overwriteAssignments?: boolean): void;
}

const AssignmentDefaultsModal: React.FC<AssignmentDefaultsModalProps> = ({
   allowOverwrite,
   assignmentDefaults,
   courseEndDate,
   courseStartDate,
   onClose,
   onSave,
}) => {
   const [settings, setSettings] = React.useState<DefaultAssignmentSettings>({
      ...assignmentDefaults,
   });
   const [applyOnSave, setApplyOnSave] = React.useState<boolean>(false);
   const [attemptsAllowedInputValue, setAttemptsAllowedInputValue] = useStateWithReset<number>(
      settings.defaultAssignmentAttemptsAllowed,
   );
   const [isInvalid, setIsInvalid] = React.useState<boolean>(false);
   const [timeLimitInputValue, setTimeLimitInputValue] = useStateWithReset<number>(
      settings.defaultAssignmentTimeLimit,
   );

   const showAttemptsAllowedInput =
      settings.defaultAssignmentGradeAutomatically && settings.defaultAssignmentAttemptsAllowed > 1;
   const timeLimitEnabled = settings.defaultAssignmentTimeLimit !== -1;

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   const toggleMultipleAttemptsAllowed = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setSettings((prevSettings) => ({
         ...prevSettings,
         defaultAssignmentAttemptsAllowed: checked ? 2 : 1,
      }));
   };

   const toggleTimeLimit = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setSettings((prevSettings) => ({
         ...prevSettings,
         defaultAssignmentTimeLimit: checked ? 60 : -1,
      }));
   };

   const handleApplyOnSaveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setApplyOnSave(event.target.checked);
   };

   // Either update or reset back to valid number
   const handleTimeLimitBlur = (): void => {
      if (timeLimitInputValue >= 1) {
         handleSettingsUpdate({
            defaultAssignmentTimeLimit: timeLimitInputValue,
         });
      } else {
         setTimeLimitInputValue(settings.defaultAssignmentTimeLimit);
      }
   };

   // Either update or reset back to valid number
   const handleAttemptsAllowedBlur = (): void => {
      if (attemptsAllowedInputValue > 1) {
         handleSettingsUpdate({
            defaultAssignmentAttemptsAllowed: attemptsAllowedInputValue,
         });
      } else {
         setAttemptsAllowedInputValue(settings.defaultAssignmentAttemptsAllowed);
      }
   };

   const handleSave = (): void => {
      onSave(settings, applyOnSave);
   };

   const handleSettingsUpdate = (update: Partial<DefaultAssignmentSettings>): void => {
      setSettings((prevSettings) => ({ ...prevSettings, ...update }));
   };

   const handleDatesUpdate = (startDate: Date, endDate: Date): void => {
      handleSettingsUpdate({
         defaultAssignmentStartDate: startDate,
         defaultAssignmentEndDate: endDate,
      });
      setIsInvalid(false);
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { type, checked, value, name } = event.target;
      handleSettingsUpdate({ [name]: type === 'checkbox' ? checked : value });
   };

   const handleInvalidDate = (): void => {
      setIsInvalid(true);
   };

   const timeLimitError = getTimeLimitError(timeLimitInputValue);
   const attemptsAllowedError = getAttemptsAllowedError(attemptsAllowedInputValue);

   return (
      <ModalDialog
         actions={[
            { text: 'Save', onClick: handleSave, disabled: isInvalid },
            { text: 'Cancel', onClick: onClose },
         ]}
         bodyClassName='assignment-defaults-modal'
         footerClassName='card-footer'
         heading='Module Assignment Defaults'
         appearance={Appearance.primary}
         onClose={onClose}
         shouldCloseOnOverlayClick={false}
      >
         <div className='row'>
            <div className='col-xs-12'>
               <div className='directions'>
                  <IconInterfaceInformation />
                  <span className='text-sm black-text'>
                     Set the properties to be used by default for all new assignments added to this
                     module.
                  </span>
               </div>
            </div>
         </div>
         <AssignmentDatePicker
            courseEndDate={courseEndDate}
            courseStartDate={courseStartDate}
            startDate={settings.defaultAssignmentStartDate}
            endDate={settings.defaultAssignmentEndDate}
            onValidDateChange={handleDatesUpdate}
            onInvalidDate={handleInvalidDate}
         />
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               <Switch
                  checked={settings.defaultAssignmentGradeAutomatically}
                  name='defaultAssignmentGradeAutomatically'
                  onChange={handleChange}
                  title='Grade Automatically'
               />
            </div>
            <div className='col-xs-12 col-sm-6'>
               <Switch
                  checked={settings.defaultAssignmentAllowLateSubmission}
                  name='defaultAssignmentAllowLateSubmission'
                  onChange={handleChange}
                  title='Allow Late Submissions'
               />
            </div>
         </div>
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               <Switch
                  checked={timeLimitEnabled}
                  onChange={toggleTimeLimit}
                  title='Set time limit'
               />
            </div>
            <div className='col-xs-12 col-sm-6'>
               {settings.defaultAssignmentGradeAutomatically && (
                  <Switch
                     checked={settings.defaultAssignmentAttemptsAllowed > 1}
                     onChange={toggleMultipleAttemptsAllowed}
                     title='Allow Multiple Attempts'
                  />
               )}
            </div>
         </div>
         <div className='row no-margin-top'>
            {timeLimitEnabled && (
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title no-margin-top'>Time Limit (Min)</label>
                  <NumberInput
                     className={(value) => classnames({ error: value < MIN_TIME_LIMIT })}
                     integer
                     min={MIN_TIME_LIMIT}
                     name='timeLimit'
                     onBlur={handleTimeLimitBlur}
                     onFocus={handleFocus}
                     onValueChange={setTimeLimitInputValue}
                     value={timeLimitInputValue}
                  />
                  {timeLimitError && <p className='error text-sm'>{timeLimitError}</p>}
               </div>
            )}
            {showAttemptsAllowedInput && (
               <div
                  className={classnames(
                     'col-xs-12 col-sm-6',
                     !timeLimitEnabled && 'col-sm-offset-6',
                  )}
               >
                  <label className='field-title'>Attempts Allowed</label>
                  <NumberInput
                     className={(value) => classnames({ error: value < MIN_ATTEMPTS_ALLOWED })}
                     integer
                     min={MIN_ATTEMPTS_ALLOWED}
                     onBlur={handleAttemptsAllowedBlur}
                     onFocus={handleFocus}
                     onValueChange={setAttemptsAllowedInputValue}
                     value={attemptsAllowedInputValue}
                  />
                  {attemptsAllowedError && <p className='error text-sm'>{attemptsAllowedError}</p>}
               </div>
            )}
         </div>
         {allowOverwrite && (
            <div className='row'>
               <div className='col-xs-12'>
                  <input checked={applyOnSave} onChange={handleApplyOnSaveChange} type='checkbox' />
                  <label className='field-title'>Apply settings to existing assignments</label>
               </div>
            </div>
         )}
      </ModalDialog>
   );
};

export default AssignmentDefaultsModal;
