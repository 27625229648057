import * as _ from 'lodash';
import * as React from 'react';

import { createApi } from 'unsplash-js';

import { Basic } from 'unsplash-js/dist/methods/photos/types';

const unsplash = createApi({
   apiUrl: `${window.location.origin}/api/services/unsplash-proxy`,
});

interface UnsplashTabProps {
   defaultImages: Basic[];
   onImageInsertion(src: string, attributes?: Record<string, string>): void;
}

const UnsplashTab: React.FC<UnsplashTabProps> = ({ defaultImages, onImageInsertion }) => {
   const [searchTerm, setSearchTerm] = React.useState<string>('');
   const [images, setImages] = React.useState<Basic[]>([]);

   const loadOptions = (query: string): void => {
      unsplash.search.getPhotos({ query, page: 1, perPage: 30 }).then((result) => {
         if (result.response) {
            setImages(result.response.results);
         }
      });
   };

   const debouncedLoadOptions = React.useCallback(_.debounce(loadOptions, 500), []);

   React.useEffect(() => {
      if (searchTerm) {
         debouncedLoadOptions(searchTerm);
      }
   }, [searchTerm]);

   const handleImageClick = (image: Basic): void => {
      const attributes: Record<string, string> = {
         class: 'responsive',
         alt: image.description ?? '',
      };
      if (image.blur_hash) {
         attributes['data-blur-hash'] = image.blur_hash;
      }
      onImageInsertion(image.urls.full, attributes);
   };

   return (
      <div className='unsplash-grid-wrapper'>
         <input
            type='search'
            autoFocus
            placeholder='Search for an image...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
         />
         <div className='unsplash-grid'>
            {(searchTerm ? images : defaultImages).map((image) => (
               <div className='photo' key={image.id}>
                  <img
                     role='button'
                     tabIndex={-1}
                     onKeyDown={(e) => e.key === 'Enter' && handleImageClick(image)}
                     src={image.urls.small}
                     alt={image.description ?? ''}
                     onClick={() => handleImageClick(image)}
                  />
                  <div className='creator'>
                     by <a href={image.user.links.html}>{image.user.name}</a>
                  </div>
               </div>
            ))}
         </div>
         <div className='flex justify-center small-text gray-text padding-s'>
            Search to find more results.
         </div>
      </div>
   );
};

export default UnsplashTab;
