import * as React from 'react';

import { Blocker, Transition } from 'history';
import {
   UNSAFE_NavigationContext as NavigationContext,
   useInRouterContext,
} from 'react-router-dom';

interface NavigationPromptProps {
   when: boolean;
   message: string;
}

const NavigationPrompt: React.FC<NavigationPromptProps> = (props) => {
   usePrompt(props.message, props.when);

   return <></>;
};

// eslint-disable-next-line func-style
function invariant(cond: unknown, message: string): asserts cond {
   if (!cond) {
      throw new Error(message);
   }
}

export const useBlocker = (blocker: Blocker, when = true): void => {
   invariant(
      useInRouterContext(),
      // TODO: This error is probably because they somehow have 2 versions of the
      // router loaded. We can help them understand how to avoid that.
      'useBlocker() may be used only in the context of a <Router> component.',
   );

   const { navigator } = React.useContext(NavigationContext);

   React.useEffect(() => {
      if (!when) {
         return;
      }

      // @ts-expect-error - This is a known issue with the types for Navigator
      // https://github.com/remix-run/react-router/issues/8139#issuecomment-1262630360
      const unblock = navigator.block((tx: Transition) => {
         const autoUnblockingTx = {
            ...tx,
            retry() {
               // Automatically unblock the transition so it can play all the way
               // through before retrying it. TODO: Figure out how to re-enable
               // this block if the transition is cancelled for some reason.
               unblock();
               tx.retry();
            },
         };

         blocker(autoUnblockingTx);
      });

      // eslint-disable-next-line consistent-return
      return unblock;
   }, [navigator, blocker, when]);
};

export const usePrompt = (message: string, when = true): void => {
   const blocker = React.useCallback(
      (tx: Transition) => {
         if (window.confirm(message)) {
            tx.retry();
         }
      },
      [message],
   );

   useBlocker(blocker, when);
};

export default NavigationPrompt;
