enum Appearance {
   danger = 'danger',
   primary = 'primary',
   secondary = 'secondary',
   success = 'success',
   warning = 'warning',
   info = 'info',
}

export default Appearance;
