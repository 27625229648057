import * as React from 'react';

import ScrollOnDrag from '@helpers/ScrollOnDrag';
import useWindowSize from '@hooks/use-window-size';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

export interface DraggableProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> {
   value: string;
   disabled?: boolean;
   onDragEnd(event: React.DragEvent<HTMLSpanElement>): void;
   onDragStart(event: React.DragEvent<HTMLSpanElement>): void;
   children({ isDragging }: { isDragging: boolean }): React.ReactNode;
}

const Draggable: React.FC<DraggableProps> = ({
   children,
   className,
   value,
   disabled,
   onDragStart,
   onDragEnd,
   ...rest
}) => {
   const [windowWidth, windowHeight] = useWindowSize();
   const scrollManagerRef = React.useRef<Maybe<ScrollOnDrag>>(null);
   const el = React.useRef<HTMLElement>(null);
   const [isDragging, setIsDragging] = React.useState<boolean>(false);

   // we'll use ScrollOnDragManager to make sure the parent element is scrolled while
   // the user is dragging near the edges of the container
   React.useEffect(() => {
      scrollManagerRef.current = new ScrollOnDrag();
      return () => {
         scrollManagerRef.current?.resetDragging();
      };
   }, []);

   // resizing the window might cause content to overflow on a parent div
   // (thus making it scrollable)
   React.useEffect(() => {
      if (el.current) {
         scrollManagerRef.current?.findScrollableParent(el.current);
      }
   }, [windowWidth, windowHeight]);

   const handleDragStart = (event: React.DragEvent<HTMLSpanElement>): void => {
      event.persist();
      scrollManagerRef.current?.handleDragStart(event, () => {
         setIsDragging(true);
         onDragStart(event);
      });
   };

   const handleDragEnd = (event: React.DragEvent<HTMLSpanElement>): void => {
      event.persist();
      scrollManagerRef.current?.handleDragEnd(() => {
         setIsDragging(false);
         onDragEnd(event);
      });
   };

   return (
      <span
         {...rest}
         className={classnames('draggable', className, { disabled, dragging: isDragging })}
         onDragStart={handleDragStart}
         onDragEnd={handleDragEnd}
         draggable={!disabled}
         data-value={value}
         ref={el}
      >
         {children({ isDragging })}
      </span>
   );
};

export default Draggable;
