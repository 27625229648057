import juice from 'juice';
import tinymce from 'tinymce';

tinymce.PluginManager.add('inlinecss', (editor) => {
   const getInlinedContent = async () => {
      const originalContent = editor.getContent();

      // Extract styles from all stylesheets
      const styleSheets = document.styleSheets;

      let styles = '';
      let fontFaces = '';

      // Fetch external stylesheets' content
      const fetchStyleSheet = async (href: string) => {
         try {
            const response = await fetch(href);
            const css = await response.text();
            styles += css;
         } catch (error) {
            console.error('Error fetching the stylesheet content:', error);
         }
      };

      // Iterate over stylesheets
      // TODO: Account for style sheets for fonts like <link href='https://fonts.googleapis.com/css?family=Karla' rel='stylesheet'>
      return Promise.all(
         Array.from(styleSheets).map(async (styleSheet) => {
            if (styleSheet.href) {
               await fetchStyleSheet(styleSheet.href);
            } else {
               // Get rules from internal stylesheets
               const rules = styleSheet.cssRules || styleSheet.rules;
               Array.from(rules).forEach((rule) => {
                  if (rule.cssText.startsWith('@font-face')) {
                     const fontFamilyMatch = rule.cssText.match(
                        /font-family\s*:\s*['"]?(.+?)['"]?;/i,
                     );
                     if (fontFamilyMatch) {
                        fontFaces += rule.cssText;
                     }
                  } else {
                     styles += rule.cssText;
                  }
               });
            }
         }),
      ).then(() => {
         let inlinedContent = juice.inlineContent(originalContent, styles);
         // Add custom fonts
         if (fontFaces) {
            const headTag = /<head[^>]*>/i;
            const htmlTag = /<html[^>]*>/i;

            if (headTag.test(inlinedContent)) {
               // If there is a head tag, insert the custom fonts inside it
               inlinedContent = inlinedContent.replace(headTag, `$&<style>${fontFaces}</style>`);
            } else if (htmlTag.test(inlinedContent)) {
               // If there is no head tag but there is an html tag, insert a head tag with custom fonts
               inlinedContent = inlinedContent.replace(
                  htmlTag,
                  `$&<head><style>${fontFaces}</style></head>`,
               );
            } else {
               // If there is neither a head nor an html tag, insert a head tag with custom fonts at the beginning
               inlinedContent = `<head><style>${fontFaces}</style></head>${inlinedContent}`;
            }
         }
         // Inline styles using Juice
         return inlinedContent;
      });
   };

   const open = async () => {
      const editorContent = await getInlinedContent();
      editor.windowManager.open({
         title: 'Exportable Content',
         size: 'large',
         body: {
            type: 'panel',
            items: [
               {
                  type: 'textarea',
                  name: 'code',
               },
            ],
         },
         buttons: [
            {
               type: 'cancel',
               name: 'cancel',
               text: 'Close',
            },
         ],
         initialData: { code: editorContent },
         onSubmit: (api) => {
            api.close();
         },
      });
   };

   editor.addCommand('mceExport', async () => {
      open();
   });

   return {
      getMetadata: () => ({
         name: 'Inline CSS',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
