import { DraggableLocation } from 'react-beautiful-dnd';

import { ModuleItem } from '@models/Course/ModuleItem';
import { ModuleDroppableId } from './ModuleDndEmitter';

interface ReorderArgs {
   items: readonly ModuleItem[];
   selectedItemIds: readonly number[];
   source: DraggableLocation;
   destination: DraggableLocation;
}

interface ReorderResult {
   items: readonly ModuleItem[];
   selectedItemIds: readonly number[];
}

export const multiDragAwareReorder = (args: ReorderArgs): ReorderResult => {
   if (args.selectedItemIds.length > 1) {
      return reorderMultiDrag(args);
   } else {
      return reorderSingleDrag(args);
   }
};

const reorderSingleDrag = ({
   items,
   selectedItemIds,
   source,
   destination,
}: ReorderArgs): ReorderResult => {
   // moving in the same list
   if (source.droppableId === destination.droppableId) {
      const reordered = Array.from(items);
      const [removed] = reordered.splice(source.index, 1);
      reordered.splice(destination.index, 0, removed);

      return {
         items: reordered.map((i, j) => ({ ...i, index: j })),
         selectedItemIds,
      };
   } else {
      return {
         items: items
            .filter((i) => !selectedItemIds.includes(i.id))
            .map((i, j) => ({ ...i, index: j })),
         selectedItemIds: [],
      };
   }
};

const reorderMultiDrag = ({
   items,
   selectedItemIds,
   source,
   destination,
}: ReorderArgs): ReorderResult => {
   const dragged = items[source.index].id;

   const insertAtIndex: number = (() => {
      const destinationIndexOffset: number = selectedItemIds.reduce(
         (previous: number, current: number): number => {
            if (current === dragged) {
               return previous;
            }

            const index: number = items.findIndex((i) => i.id === current);

            if (index >= destination.index) {
               return previous;
            }

            // the selected item is before the destination index
            // we need to account for this when inserting into the new location
            return previous + 1;
         },
         0,
      );

      const result: number = destination.index - destinationIndexOffset;
      return result;
   })();

   // doing the ordering now as we are required to know original ordering
   const orderedSelectedItemIds = [...selectedItemIds];
   orderedSelectedItemIds.sort((a: number, b: number): number => {
      // moving the dragged item to the top of the list
      if (a === dragged) {
         return -1;
      }
      if (b === dragged) {
         return 1;
      }

      // sorting by their natural indexes
      const indexOfA = items.findIndex((i) => i.id === a);
      const indexOfB = items.findIndex((i) => i.id === b);

      if (indexOfA !== indexOfB) {
         return indexOfA - indexOfB;
      }

      // sorting by their order in the selectedItemIds list
      return -1;
   });

   // we need to remove all of the selected tasks from their columns
   const withRemovedItems = items.filter((i) => !selectedItemIds.includes(i.id));

   const withInserted: number[] = (() => {
      const base: number[] = withRemovedItems.map((i) => i.id);
      base.splice(insertAtIndex, 0, ...orderedSelectedItemIds);
      return base;
   })();

   const updatedItems = withInserted
      .map((i) => items.find((j) => i === j.id))
      .filter(Boolean)
      .map((i, j) => ({ ...(i as ModuleItem), index: j }));

   const droppedInSameList = source.droppableId === destination.droppableId;

   return {
      items: updatedItems,
      selectedItemIds: droppedInSameList ? orderedSelectedItemIds : [],
   };
};

export const multiSelectTo = (
   items: readonly ModuleItem[],
   selectedItemIds: readonly number[],
   newItemId: number,
): readonly number[] | null => {
   // Nothing already selected
   if (!selectedItemIds.length) {
      return [newItemId];
   }

   const indexOfNew = items.findIndex((i) => i.id === newItemId);
   const lastSelected = selectedItemIds[selectedItemIds.length - 1];

   const indexOfLast = items.findIndex((i) => i.id === lastSelected);

   // multi selecting in the same column
   // need to select everything between the last index and the current index inclusive

   // nothing to do here
   if (indexOfNew === indexOfLast) {
      return null;
   }

   const isSelectingForwards = indexOfNew > indexOfLast;
   const start = isSelectingForwards ? indexOfLast : indexOfNew;
   const end = isSelectingForwards ? indexOfNew : indexOfLast;

   const inBetween = items.slice(start, end + 1).map((i) => i.id);

   // everything inbetween needs to have it's selection toggled.
   // with the exception of the start and end values which will always be selected

   // if already selected: then no need to select it again
   const toAdd = inBetween.filter((itemId) => !selectedItemIds.includes(itemId));

   const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
   const combined = [...selectedItemIds, ...sorted];

   return combined;
};

export const splitDraggableId = (draggableId: string): [ModuleDroppableId, number] => {
   const [draggableType, strId] = draggableId.split('-');
   return [draggableType as ModuleDroppableId, Number(strId)];
};
