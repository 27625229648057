// @ts-strict-ignore
/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import { createAudioElement } from '@helpers/Audio';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { randomShortId } from '@helpers/RandomStringUtils';
import useMousetrap from '@hooks/use-mousetrap';
import Appearance from '@models/Appearance';
import Content from '@models/Content';
import { Maybe, MessageResponse } from '@models/Core';
import { IVocabTermProgress } from '@models/IVocabTerm';
import HttpService from '@services/HttpService';
import Emitter from '@utilities/Emitter';
import { useNavigate, useParams } from 'react-router-dom';

import ModalDialog from '@components/Core/ModalDialog';
import Constants from '../../Constants';
import Color from '../../types/Color';
import Link from '@components/Common/Link';
import NavigationPrompt from '@components/Core/NavigationPrompt';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import Card from './Card/Card';
import Feedback from './Feedback';
import Footer from './Footer';
import Header from './Header/Header';
import {
   IBaseVocabSessionCard,
   IFeedback,
   IVocabSessionCard,
   IVocabSessionStats,
   IVocabSessionTerm,
   VocabSessionType,
} from './Models';
import NextButton from './NextButton';
import PauseOverlay from './PauseOverlay';
import vocabSessionReducer, { VocabSessionAction, VocabSessionActionType } from './reducer';
import SpeedCountdown from './SpeedCountdown';
import SpeedSessionOverlay from './SpeedSessionOverlay';
import Summary from './Summary';
import TimerWrapper from './TimerWrapper';
import ToggleAudioButton from './ToggleAudioButton';

export interface IVocabSessionState {
   cards: readonly (IBaseVocabSessionCard | IVocabSessionCard)[];
   disableVocabSetTimer: boolean;
   feedback: Maybe<IFeedback>;
   id: number;
   index: number;
   isComplete: boolean;
   isFetching: boolean;
   isMuted: boolean;
   isPaused: boolean;
   isReady: boolean;
   progress: Record<number, IVocabTermProgress>;
   sessionType: VocabSessionType;
   settings: Content;
   showCard: boolean;
   showFeedback: boolean;
   showNext: boolean;
   showProgressBar: boolean;
   showSpeedCountdown: boolean;
   showSpeedOverlay: boolean;
   showSummary: boolean;
   showToggleAudio: boolean;
   showAutoplayBlocked: boolean;
   speedSessionSuccessful: boolean;
   stats: IVocabSessionStats;
   terms: Record<number, IVocabSessionTerm>;
}

export interface IVocabSessionContext {
   card: IVocabSessionCard;
   emitter: Emitter;
   progress: IVocabTermProgress;
   sessionColor: Color;
   state: IVocabSessionState;
   term: IVocabSessionTerm;
   dispatch(action: VocabSessionAction): void;
}

interface CreateVocabSessionResponse {
   cards: readonly IBaseVocabSessionCard[];
   disableVocabSetTimer: boolean;
   id: number;
   progress: readonly IVocabTermProgress[];
   settings: Content;
   terms: readonly IVocabSessionTerm[];
}

export const SPEED_CARD_DURATION = 8;
export const SPEED_NUM_LIVES = 3;

const SESSION_COLORS = {
   [VocabSessionType.learn]: Color.green,
   [VocabSessionType.review]: Color.blue,
   [VocabSessionType.speedReview]: Color.darkRed,
};

const SESSION_NAME = {
   [VocabSessionType.learn]: 'Learning',
   [VocabSessionType.review]: 'Review',
   [VocabSessionType.speedReview]: 'Speed Review',
};

export const VocabSessionContext = React.createContext<IVocabSessionContext>({
   card: null,
   dispatch: null,
   emitter: null,
   progress: null,
   sessionColor: null,
   state: null,
   term: null,
});

type VocabSessionParams = {
   vocabSetId: string;
   sessionType: VocabSessionType;
};

export const VocabSessionProvider = VocabSessionContext.Provider;
export const VocabSessionConsumer = VocabSessionContext.Consumer;

const VocabSession: React.FC = () => {
   const params = useParams<VocabSessionParams>();

   const [state, dispatch] = React.useReducer(vocabSessionReducer, {
      cards: null,
      feedback: null,
      id: null,
      index: null,
      isComplete: false,
      isFetching: true,
      isMuted: false,
      isPaused: false,
      isReady: false,
      progress: null,
      sessionType: null,
      showCard: false,
      showFeedback: false,
      showNext: false,
      disableVocabSetTimer: false,
      showProgressBar: false,
      showSpeedCountdown: false,
      showSpeedOverlay: false,
      showSummary: false,
      showToggleAudio: false,
      showAutoplayBlocked: false,
      speedSessionSuccessful: null,
      terms: null,
      settings: null,
      stats: {
         correct: 0,
         incorrect: 0,
         timeSpent: 0,
         currentStreak: 0,
      },
   });
   const navigate = useNavigate();
   const emitter = React.useRef<Emitter>(new Emitter());
   const cardRef = React.useRef<HTMLDivElement>(null);
   const [dismissedAutoPlayWarning, setDismissedAutoPlayWarning] = React.useState<boolean>(false);

   const redirectToViewer = React.useCallback((): void => {
      if (params.vocabSetId) {
         navigate(-1);
      }
   }, [params.vocabSetId]);

   useMousetrap('esc', redirectToViewer);

   const handelAutoplayDismissal = (): void => {
      setDismissedAutoPlayWarning(true);
   };

   React.useEffect(() => {
      setupSession();
   }, []);

   React.useEffect(() => {
      if (state.showSummary) {
         completeSession();
      }
   }, [state.showSummary]);

   React.useEffect(() => {
      if (state.isReady && state.index === null) {
         if (state.sessionType === VocabSessionType.speedReview) {
            toggleSpeedCountdown();
         } else {
            advance();
         }
      }
   }, [state.isReady, state.index, state.sessionType]);

   const advance = React.useCallback(
      (resequenceCardId?: string): void => {
         if (
            state.sessionType === VocabSessionType.speedReview &&
            state.stats.incorrect === SPEED_NUM_LIVES &&
            !state.showSpeedOverlay
         ) {
            dispatch({
               type: VocabSessionActionType.showSpeedOverlay,
               success: false,
            });
         } else {
            dispatch({
               type: VocabSessionActionType.advanceCard,
               resequenceCardId,
            });
         }
      },
      [state.index, state.sessionType, state.cards, state.stats, state.showSpeedOverlay],
   );

   const completeSession = React.useCallback((): void => {
      if (state.cards.length) {
         const data = snakeCaseKeys({ completed: true });
         HttpService.patchWithAuthToken<MessageResponse>(
            `/api/vocab_sets/sessions/${state.id}`,
            data,
         );
      }
   }, [state.showSummary]);

   const registerResponse = (
      cardId: string,
      cardResponse: string,
      correct: boolean,
      timeSpent: number,
   ): Promise<void> => {
      const { termId, choices, ...rest } = state.cards.find(
         (i) => i.id === cardId,
      ) as IVocabSessionCard;
      updateProgress(termId, correct, timeSpent);
      const data = {
         termId,
         timeSpent,
         correct,
         response: cardResponse,
         ...rest,
      };
      return HttpService.postWithAuthToken<{ newLevel: number }>(
         `/api/vocab_sets/sessions/${state.id}/responses`,
         snakeCaseKeys(data),
      ).then((response) => {
         const { newLevel } = response.data;
         dispatch({
            type: VocabSessionActionType.updateLevel,
            termId,
            newLevel,
         });
      });
   };

   const updateProgress = (termId: number, correct: boolean, timeSpent: number): void => {
      const termProgress = state.progress[termId];
      const progressUpdate = {
         ...termProgress,
         attempts: termProgress.attempts + 1,
         correct: termProgress.correct + Number(correct),
         streak: correct
            ? Math.max(termProgress.streak, 0) + 1
            : Math.min(termProgress.streak, 0) - 1,
      };
      const {
         correct: totalCorrect,
         incorrect: totalIncorrect,
         timeSpent: totalTimeSpent,
         currentStreak,
      } = state.stats;
      const stats = {
         correct: totalCorrect + Number(correct),
         incorrect: totalIncorrect + Number(!correct),
         timeSpent: totalTimeSpent + timeSpent,
         currentStreak: correct ? Math.max(currentStreak, 0) + 1 : Math.min(currentStreak, 0) - 1,
      };
      dispatch({
         type: VocabSessionActionType.updateProgress,
         termId,
         progress: progressUpdate,
         stats,
      });
   };

   const renderOverlay = (): React.ReactNode => {
      if (state.isPaused) {
         return <PauseOverlay togglePause={togglePause} />;
      } else if (state.showSpeedOverlay) {
         return (
            <SpeedSessionOverlay success={state.speedSessionSuccessful} onContinueClick={advance} />
         );
      }
      return null;
   };

   const setupSession = React.useCallback((): void => {
      if (!params.sessionType) {
         return;
      } else if (Object.values(VocabSessionType).includes(params.sessionType)) {
         const data = snakeCaseKeys({ sessionType: params.sessionType });
         HttpService.postWithAuthToken<CreateVocabSessionResponse>(
            `/api/vocab_sets/${params.vocabSetId}/sessions`,
            data,
         ).then((response) => {
            const {
               cards: unmappedCards,
               disableVocabSetTimer,
               id,
               progress: unkeyedProgress,
               settings,
               terms: unkeyedTerms,
            } = response.data;
            const terms = _.keyBy(
               unkeyedTerms.map((i) => {
                  const image = i.imageUrl ? new Image() : null;
                  if (image) {
                     image.src = i.imageUrl;
                  }
                  return {
                     ...i,
                     audio: i.audioUrl
                        ? createAudioElement(i.audioUrl, 'Setup vocab session')
                        : null,
                     image,
                  };
               }),
               'id',
            );
            const progress = _.keyBy(
               unkeyedProgress.map((i) => ({
                  ...i,
                  halfLife: i.halfLife ? new Date(i.halfLife) : null,
               })),
               'id',
            );
            const cards = unmappedCards.map((i) => ({
               ...i,
               id: randomShortId(),
            }));
            dispatch({
               type: VocabSessionActionType.setSessionData,
               cards,
               id,
               progress,
               sessionType: params.sessionType,
               settings,
               terms,
               disableVocabSetTimer,
            });
         });
      } else {
         console.error(`Invalid session type: ${params.sessionType}`);
      }
   }, [params]);

   const showFeedback = (feedback: IFeedback): void => {
      if (state.sessionType !== VocabSessionType.speedReview) {
         dispatch({ type: VocabSessionActionType.showFeedback, feedback });
      }
   };

   const hideFeedback = (): void => {
      dispatch({ type: VocabSessionActionType.hideFeedback });
   };

   const setNextButton = (show: boolean): void => {
      dispatch({ type: VocabSessionActionType.setNextButton, show });
   };

   const toggleMute = (): void => {
      dispatch({ type: VocabSessionActionType.toggleMute });
   };

   const togglePause = (): void => {
      dispatch({ type: VocabSessionActionType.togglePause });
   };

   const toggleSpeedCountdown = (): void => {
      dispatch({ type: VocabSessionActionType.toggleSpeedCountdown });
   };

   const setAutoplayBlocked = (show: boolean): void => {
      dispatch({ type: VocabSessionActionType.setShowAutoplayBlocked, show });
   };

   const currentCard =
      state.index === null || state.cards === null || state.showSummary
         ? null
         : (state.cards[state.index] as IVocabSessionCard);
   const currentTerm =
      state.index === null || state.terms === null || currentCard === null
         ? null
         : state.terms[currentCard.termId];
   const currentProgress =
      state.index === null || state.progress === null || currentCard === null
         ? null
         : state.progress[currentCard.termId];

   const {
      siteLinks: { autoPlayHelpArticle },
   } = Constants;
   if (state.isFetching) {
      return <Loader />;
   }

   return (
      <VocabSessionProvider
         value={{
            state,
            dispatch,
            emitter: emitter.current,
            sessionColor: SESSION_COLORS[state.sessionType],
            card: currentCard,
            term: currentTerm,
            progress: currentProgress,
         }}
      >
         <DocumentTitle>
            {state.sessionType && `${SESSION_NAME[state.sessionType]} Session`}
         </DocumentTitle>
         <TimerWrapper isReady={state.isReady} isPaused={state.isPaused}>
            <div className='product-content-container'>
               <Header togglePause={togglePause} />
               {renderOverlay()}
               <div className='session-content-container'>
                  <div className='row'>
                     <div
                        className={`col-xs-12 ${
                           state.showSummary ? 'col-sm-1 col-lg-3' : 'col-md-3'
                        } session-control`}
                     >
                        <ToggleAudioButton
                           toggleMute={toggleMute}
                           isMuted={state.isMuted}
                           showToggleAudio={state.showToggleAudio}
                        />
                     </div>
                     <div
                        className={`col-xs-12 ${
                           state.showSummary ? 'col-sm-10 col-lg-6' : 'col-md-6'
                        }`}
                     >
                        <SpeedCountdown
                           show={state.showSpeedCountdown}
                           onCountdownFinish={advance}
                        />
                        {state.showCard && (
                           <Card
                              ref={cardRef}
                              advance={advance}
                              showFeedback={showFeedback}
                              hideFeedback={hideFeedback}
                              registerResponse={registerResponse}
                              setNextButton={setNextButton}
                              setAutoplayBlocked={setAutoplayBlocked}
                           />
                        )}
                        {state.showAutoplayBlocked && !dismissedAutoPlayWarning && (
                           <ModalDialog
                              appearance={Appearance.warning}
                              heading='Autoplay Sound Blocked'
                              onClose={handelAutoplayDismissal}
                              animations={{ enter: 'animated bounceInDown' }}
                              actions={[
                                 {
                                    text: 'Ok',
                                    onClick: handelAutoplayDismissal,
                                 },
                              ]}
                           >
                              <p>
                                 Your browser is blocking Lingco from playing sounds. To hear them
                                 you'll need to update your settings.
                              </p>
                              <Link
                                 to={autoPlayHelpArticle}
                                 external
                                 rel='noreferrer'
                                 className='margin-top-s pointer'
                              >
                                 Show me how
                              </Link>
                           </ModalDialog>
                        )}

                        {state.showSummary && <Summary />}
                        <Feedback show={state.showFeedback} feedback={state.feedback} />
                        <Footer show={state.showProgressBar} />
                     </div>
                     <div
                        className={`col-xs-12 ${
                           state.showSummary ? 'col-sm-1 col-lg-3' : 'col-md-3'
                        }`}
                     >
                        <NextButton
                           show={state.showNext}
                           cardHeight={cardRef.current ? cardRef.current.offsetHeight : 0}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </TimerWrapper>
         <NavigationPrompt
            when={!state.isComplete}
            message='Are you sure you want to quit? Changes you made may not be saved.'
         />
      </VocabSessionProvider>
   );
};

export default React.memo(VocabSession);
