import * as _ from 'lodash';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import omitDeep from '@helpers/OmitDeep';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import Content, { ContentType } from '@models/Content';
import { Maybe, MessageResponse } from '@models/Core';
import { uploadImage } from '@services/AssetService';
import ContentService from '@services/ContentService';
import HttpService from '@services/HttpService';
import LessonService from '@services/LessonService';
import { diff } from 'deep-object-diff';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Editor as TinyMCEEditor } from 'tinymce';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import mixPanelActions from '../../Mixpanel';
import StyleWrapper from '@components/Activity/StyleWrapper';
import Button from '@components/Common/Button';
import ContentBuilderHeader from '@components/ContentBuilderHeader';
import { PageEditor } from '@components/Core/Editor';
import NavigationPrompt from '@components/Core/NavigationPrompt';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';

const LessonBuilder: React.FC = () => {
   const {
      routes: {
         content: { viewLesson, newLesson },
      },
   } = Constants;

   const { lessonId } = useParams();
   const location = useLocation();
   const navigate = useNavigate();

   const mode = location.pathname === newLesson ? 'create' : 'edit';

   const { userProfile, ...appContext } = React.useContext<AppStateContext>(AppStateContext);

   if (!userProfile) {
      return;
   }

   const [isDirty, setIsDirty] = React.useState<boolean>(false);
   const [isReadyToRedirect, setIsReadyToRedirect] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const editor = React.useRef<Maybe<TinyMCEEditor>>(null);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [content, setContent] = React.useState<string>('');
   const [settings, setSettings] = React.useState<Content>({
      createdBy: userProfile.id,
      createdOn: null,
      description: '',
      folderId: null,
      styleSheetId: null,
      id: null,
      imageFilename: null,
      imageUrl: null,
      language: userProfile.language,
      modifiedOn: null,
      name: '',
      tags: [],
      type: ContentType.lesson,
   });
   const [origSettings, setOrigSettings] = React.useState<Maybe<Content>>(null);

   React.useEffect(() => {
      if (mode === 'create') {
         const folderId = getQueryParameterAsNumber(location, 'folder', null);
         setSettings((prevState) => ({
            ...prevState,
            folderId,
            name: 'Untitled Lesson',
         }));
      } else if (mode === 'edit') {
         fetchLesson();
      }
   }, []);

   React.useEffect(() => {
      if (settings.id) {
         setBreadcrumbs();
      }
   }, [settings.id, settings.folderId, mode]);

   React.useEffect(() => {
      if (isReadyToRedirect && !isDirty && settings.id) {
         const moduleItemId = getQueryParameterAsNumber(location, 'moduleItemId', null);
         const redirectRoute = viewLesson.replace(':lessonId', settings.id.toString());
         navigate(moduleItemId ? `${redirectRoute}?moduleItemId=${moduleItemId}` : redirectRoute);
      }
   }, [isReadyToRedirect, isDirty, settings.id]);

   const editorLoaded = (editorRef: TinyMCEEditor): void => {
      editor.current = editorRef;
   };

   const commitThenRedirect = async (): Promise<void> => {
      const settingsCopy = { ...settings };
      const keysToOmit = ['errors', 'key', 'file', 'image', 'fileUrl', 'imageUrl'];
      const updatedContent = editor.current?.getContent();
      setIsLoading(true);
      if (settingsCopy.file) {
         await uploadImage(settingsCopy.file).then((f) => {
            settingsCopy.imageFilename = f;
         });
      }
      if (mode === 'create') {
         const data = { ...settingsCopy, content: updatedContent };
         omitDeep(data, keysToOmit);
         await HttpService.postWithAuthToken<{ id: number }>(
            '/api/content/lessons',
            snakeCaseKeys(data),
         ).then((response) => {
            const { id: newLessonId } = response.data;
            mixPanelActions.track('Lesson Created', {
               id: newLessonId,
               language: settings.language,
               name: settings.name,
            });
            setSettings((prevSettings) => ({ ...prevSettings, id: newLessonId }));
            setIsDirty(false);
            setIsReadyToRedirect(true);
         });
         return;
      }

      if (!lessonId || !origSettings) {
         return;
      }

      const updatedSettings: Partial<Content> = _.cloneDeep(diff(origSettings, settingsCopy));
      omitDeep(updatedSettings, keysToOmit);
      if (updatedSettings.tags) {
         updatedSettings.tags = settings.tags;
      }

      HttpService.patchWithAuthToken<MessageResponse>(
         `/api/content/lessons/${settings.id}`,
         snakeCaseKeys({ ...updatedSettings, content: updatedContent }),
      ).then(() => {
         setIsDirty(false);
         setIsReadyToRedirect(true);
      });
   };

   const editSettings = (update: Partial<Content>): void => {
      setSettings((prevSettings) => ({ ...prevSettings, ...update }));
      setIsDirty(true);
   };

   const fetchLesson = (): void => {
      setIsFetching(true);
      LessonService.fetchLesson(Number(lessonId)).then(
         ({ lesson: { content: responseContent, ...responseSettings } }) => {
            setSettings(responseSettings);
            setOrigSettings(responseSettings);
            setContent(responseContent);
            editor.current?.setContent(responseContent);
            setIsFetching(false);
         },
      );
   };

   const handleContentChange = (_updatedContent: string): void => {
      // setContent(updatedContent);
      setIsDirty(true);
   };

   const removeContentImage = (): void => {
      setSettings((prevSettings) => ({ ...prevSettings, imageUrl: null }));
   };

   const setBreadcrumbs = async (): Promise<void> => {
      if (!userProfile) {
         return Promise.reject();
      }
      const { accountType, id: userId } = userProfile;
      const { createdBy = userId, folderId, name: contentName, id: contentId } = settings;
      ContentService.getBreadcrumbs(
         {
            accountType,
            canEditContent: true,
            contentId,
            contentName,
            contentType: ContentType.lesson,
            createdBy,
            folderId,
            userId,
         },
         location,
      ).then(appContext.setBreadcrumbs);
   };
   const isNew = mode === 'create';
   const mainButtonText = `${isNew ? 'Create' : 'Save'} Lesson`;

   if (isFetching) {
      return <Loader />;
   }

   return (
      <div className='content-main lesson-wrapper'>
         <DocumentTitle>{`${isNew ? 'Create' : 'Edit'} Lesson`}</DocumentTitle>
         <div className='card no-padding'>
            <div className='card-title has-button'>
               <ContentBuilderHeader
                  removeContentImage={removeContentImage}
                  editSettings={editSettings}
                  settings={settings}
               />
               <div className='right-options-wrapper'>
                  <Button onClick={commitThenRedirect} loading={isLoading}>
                     {mainButtonText}
                  </Button>
               </div>
            </div>
            <div className='lesson-editor'>
               <StyleWrapper styleId={settings.styleSheetId}>
                  <PageEditor
                     autoFocus
                     editorRef={editorLoaded}
                     rtl={settings.language === 'ar'}
                     language={settings.language}
                     initialValue={content}
                     onChange={handleContentChange}
                  />
               </StyleWrapper>
            </div>
         </div>
         {isDirty && !isReadyToRedirect && (
            <NavigationPrompt
               when={isDirty}
               message='Are you sure you want to leave? Changes you made will not be saved.'
            />
         )}
      </div>
   );
};

export default LessonBuilder;
