// @ts-strict-ignore
import * as React from 'react';

import { Maybe } from '@models/Core';

export default class ScrollOnDrag {
   public scrollableParent!: HTMLElement;
   public scrollableParentBounds!: DOMRect;
   public enableScrollUp!: boolean;
   public enableScrollDown!: boolean;
   public previousHighestY = 0;
   public previousLowestY = 0;
   public isDragging!: boolean;
   public timers: number[] = [];

   public handleDragStart(event: React.DragEvent<HTMLSpanElement>, callback?: () => void): void {
      this.previousHighestY = event.clientY;
      this.previousLowestY = event.clientY;
      window.document.addEventListener('dragover', this.handleOnDragOver.bind(this));
      this.isDragging = true;
      callback?.();
   }

   public handleDragEnd(callback?: () => void): void {
      this.resetDragging();
      callback?.();
   }

   public findScrollableParent(rootElement: HTMLElement): void {
      const isScrollable = (element: Maybe<HTMLElement>): boolean => {
         if (!element) {
            return false;
         }
         const overflowY = window.getComputedStyle(element).getPropertyValue('overflow-y');
         if (['auto', 'scroll'].includes(overflowY)) {
            return element.scrollHeight > element.clientHeight;
         }
         return false;
      };

      // we'll walk the DOM structure to find the current element's scrollable parent
      let firstScrollableParent: Maybe<HTMLElement> = null;
      let currentParent = rootElement.parentElement;

      while (!firstScrollableParent) {
         if (isScrollable(currentParent)) {
            firstScrollableParent = currentParent;
         } else if (currentParent?.parentElement) {
            currentParent = currentParent.parentElement;
         } else {
            firstScrollableParent = currentParent;
         }
      }

      this.scrollableParent = firstScrollableParent;
      this.scrollableParentBounds = firstScrollableParent.getBoundingClientRect();
   }

   public clearTimers(): void {
      this.timers.forEach((t) => clearTimeout(t));
      this.timers = [];
   }

   public handleOnDragOver(event: React.DragEvent<HTMLSpanElement>): void {
      this.handleMousemove(event, this.scrollableParent, this.scrollableParentBounds);
   }

   public resetDragging(): void {
      // Don't add 'if check' here we should always clean up if resetDragging is called.
      window.document.removeEventListener('dragover', this.handleOnDragOver.bind(this));
      this.previousHighestY = 0;
      this.previousLowestY = 0;
      this.enableScrollDown = false;
      this.enableScrollUp = false;
      this.isDragging = false;
      this.clearTimers();
   }

   // eslint-disable-next-line complexity
   public handleMousemove(
      event: React.DragEvent<HTMLSpanElement>,
      parent: HTMLElement,
      parentBounds: DOMRect,
   ): void {
      // Core concepts pull from Ben Nadal:
      // https://www.bennadel.com/blog/3460-automatically-scroll-the-window-when-the-user-approaches-the-viewport-edge-in-javascript.htm
      const edgeSize = 80;
      const initialBuffer = 20;

      const parentBottomEdge = parentBounds.top + parentBounds.height - edgeSize;
      const viewportBottomEdge = window.document.documentElement.clientHeight - edgeSize;
      const edgeBottom = Math.min(parentBottomEdge, viewportBottomEdge);
      const edgeTop = parentBounds.top + edgeSize;
      const handleStartDragEventFinished = this.previousHighestY !== 0;

      // We do a check here to make sure we don't immediately scroll when grabbing a word
      const isInTopEdge =
         handleStartDragEventFinished &&
         event.clientY < edgeTop &&
         this.previousHighestY - initialBuffer > event.clientY;
      const isInBottomEdge =
         handleStartDragEventFinished &&
         event.clientY > edgeBottom &&
         this.previousLowestY + initialBuffer < event.clientY;

      // We want to prevent an immediate jump when grabbing an item but still allow it after moving a bit.
      if (
         handleStartDragEventFinished &&
         this.previousHighestY &&
         event.clientY - initialBuffer > this.previousHighestY
      ) {
         this.previousHighestY = event.clientY;
      }
      if (
         handleStartDragEventFinished &&
         this.previousHighestY &&
         event.clientY + initialBuffer < this.previousLowestY
      ) {
         this.previousLowestY = event.clientY;
      }

      if (!isInTopEdge) {
         this.enableScrollUp = true;
      }

      if (!isInBottomEdge) {
         this.enableScrollUp = true;
      }

      // If the mouse is not in the viewport edge, there's no need to calculate anything else.
      if (!(isInTopEdge || isInBottomEdge)) {
         this.clearTimers();
         return;
      }

      // The max scrollTop value is the full height of the element
      // minus that element's viewport height
      const maxScrollY = parent.scrollHeight - parentBounds.height;

      const adjustElementScrollPosition = (): boolean => {
         const currentScrollY = parent.scrollTop;
         const canScrollUp = currentScrollY > 0 && this.enableScrollUp;
         const canScrollDown = currentScrollY < maxScrollY && this.enableScrollUp;
         let nextScrollY = currentScrollY;

         // As we examine the mouse position within the edge, we want to make the
         // incremental scroll changes more "intense" the closer that the user
         // gets the viewport edge. As such, we'll calculate the percentage that
         // the user has made it "through the edge" when calculating the delta.
         // Then, that use that percentage to back-off from the "max" step value.
         const maxStep = 30;
         const intensityDampingFactor = 0.2;

         if (isInTopEdge && canScrollUp) {
            const intensity = ((edgeTop - event.clientY) / edgeSize) * intensityDampingFactor;
            nextScrollY = nextScrollY - maxStep * intensity;
         } else if (isInBottomEdge && canScrollDown) {
            const intensity = ((event.clientY - edgeBottom) / edgeSize) * intensityDampingFactor;
            nextScrollY = nextScrollY + maxStep * intensity;
         }

         nextScrollY = Math.max(0, Math.min(maxScrollY, nextScrollY));

         if (nextScrollY !== currentScrollY) {
            parent.scrollTop = nextScrollY;
            return true;
         } else {
            return false;
         }
      };

      // As we examine the mousemove event, we want to adjust the window scroll in
      // immediate response to the event; but, we also want to continue adjusting
      // the window scroll if the user rests their mouse in the edge boundary. To
      // do this, we'll invoke the adjustment logic immediately. Then, we'll setup
      // a timer that continues to invoke the adjustment logic while the window can
      // still be scrolled in a particular direction.
      const checkForWindowScroll = (): void => {
         this.clearTimers();

         if (adjustElementScrollPosition() && !this.isDragging) {
            this.timers.push(window.setTimeout(checkForWindowScroll, 30));
         }
      };
      checkForWindowScroll();
   }

   // useful for viewing the scroll zones
   /*
   // add
   private shouldDrawScrollLines = true;
   private SCROLL_DISPLAY_ID = 'SCROLL_DISPLAY_ID';
   // add to reset dragger
   this.removeScrollLines();
   this.shouldDrawScrollLines = true;
   // Add to handleMousemove
   if (this.shouldDrawScrollLines) {
      const bottom = window.document.documentElement.clientHeight - edgeBottom;
      const right = window.document.documentElement.clientWidth - parentBounds.right;
      this.drawScrollLines(edgeTop, bottom, parentBounds.left, right);
      this.shouldDrawScrollLines = false;
   }
   private drawScrollLines(edgeTop: number, edgeBottom: number, left: number, right: number): void {
      this.removeScrollLines();
      const visibleEdge = document.createElement('span');
      visibleEdge.id = this.SCROLL_DISPLAY_ID;
      visibleEdge.style.position = 'fixed';
      visibleEdge.style.pointerEvents = 'none';
      visibleEdge.style.top = (edgeTop + 'px');
      visibleEdge.style.bottom = (edgeBottom + 'px');
      visibleEdge.style.left = (left + 'px');
      visibleEdge.style.right = (right + 'px');
      visibleEdge.style.background = '#CC0000';
      visibleEdge.style.opacity = '0.2';
      document.body.appendChild(visibleEdge);
   }

   private removeScrollLines(): void {
      const visibleEdge = document.getElementById(this.SCROLL_DISPLAY_ID);
      if (visibleEdge) {
         document.body.removeChild(visibleEdge);
      }
   }
   */
}
