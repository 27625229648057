import * as React from 'react';

import { IMaskInput } from 'react-imask';

interface IProductCodeProps {
   setCode(code: string): void;
}

const ProductCode: React.FC<IProductCodeProps> = ({ setCode }) => {
   const [productCode, setProductCode] = React.useState<string>('');

   const updateCode = (value: string, complete: boolean): void => {
      setProductCode(value);

      if (complete) {
         setCode(value);
      } else {
         setCode('');
      }
   };

   return (
      <IMaskInput
         value={productCode}
         mask='*****-*****-*****-*****-*****'
         lazy={false}
         type='text'
         onAccept={(value) => updateCode(value as unknown as string, false)}
         onComplete={(value) => updateCode(value as unknown as string, true)}
         data-test='bookstore-code-input'
      />
   );
};

export default ProductCode;
