import * as React from 'react';

import { applyDrag } from '@components/Activity/DragUtils';
import {
   isAudioContent,
   isLessonContent,
   isPrompt,
   isTextContent,
   isVideoContent,
   isVocabSetItem,
} from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import { Container, Draggable, DropResult } from '@components/Core/DragAndDrop';
import DNDKitDraggable from '@components/Core/DragAndDropKit/Draggable';
import Droplist, { IMenuItem } from '@components/Core/Droplist';
import TagsInput from '@components/Core/TagsInput';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { getAllInteractivePromptsForQuestion } from '@components/Video/helpers';
import { randomTempId } from '@helpers/RandomStringUtils';
import IconOptionGrey from '@icons/general/icon-option-gray.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconControlSplit from '@icons/nova-line/29-Controls/control-split.svg';
import IconTag1 from '@icons/nova-line/30-Bookmarks&Tags/tag-1.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import {
   ActivityBuilderMode,
   ActivityItem,
   ActivityLogicCombinator,
   ActivityLogicGroup,
   ActivityPrompt,
   ActivityQuestion,
   PromptType,
} from '@models/Activity';
import { ID } from '@models/Core';
import AutosizeInput from 'react-18-input-autosize';

import Constants from '../../../Constants';
import AudioContent from './Content/AudioContent';
import LessonContent from './Content/LessonContent';
import TextContent from './Content/TextContent';
import VideoContent from './Content/VideoContent';
import VocabSetItem from './Content/VocabSetItem';
import QuestionLogic from './Logic/QuestionLogic';
import Prompt from './Prompt';

export interface BaseItemProps {
   item: ActivityItem<ActivityBuilderMode>;
   duplicateItem(key?: string): void;
   handleItemUpdate(
      update: Partial<ActivityItem<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   removeItem(key?: string): void;
}

interface QuestionProps {
   isDraggingPrompt: boolean;
   question: ActivityQuestion<ActivityBuilderMode>;
   duplicateItem(itemKey: string, questionId: string | number): void;
   duplicateQuestion(): void;
   handleQuestionUpdate(
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   removeQuestion(): void;
}

const Question: React.FC<QuestionProps> = ({
   duplicateItem,
   duplicateQuestion,
   handleQuestionUpdate,
   isDraggingPrompt,
   question,
   removeQuestion,
}) => {
   const { nonDragAreaSelector } = Constants;
   const { title, logic, tags } = question;

   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [showLogic, setShowLogic] = React.useState<boolean>(false);
   const [showTags, setShowTags] = React.useState<boolean>(false);

   const handleItemDrop = (event: DropResult): void => {
      handleQuestionUpdate({ items: applyDrag(question.items, event) });
   };

   const allInteractivePromptsForQuestionn = getAllInteractivePromptsForQuestion(question);

   const handleVideoPromptSecondsUpdate = (
      videoID: ID,
      promptId: ID,
      promptTimeInSeconds: number,
      promptType: PromptType,
   ): void => {
      const videoItem = question.items.find((i) => i.id === videoID);

      if (videoItem && isVideoContent(videoItem) && videoItem.promptTimes) {
         const updatedPromptTimes = [...videoItem.promptTimes];
         if (videoItem.id && !videoItem.promptTimes.map((i) => i.promptId).includes(promptId)) {
            updatedPromptTimes.push({
               videoId: videoItem.id,
               promptTimeInSeconds: 0,
               promptId,
               promptType,
            });
         }
         handleItemUpdate(videoItem.key, {
            promptTimes: updatedPromptTimes.map((i) =>
               i.promptId === promptId ? { ...i, promptTimeInSeconds } : i,
            ),
         });
      }
   };

   const handleItemUpdate = (
      itemKey: string,
      update: Partial<ActivityItem<ActivityBuilderMode>>,
      callback?: () => void,
   ): void => {
      if (!itemKey) throw new Error('itemKey is empty');

      // get item by key and infer type
      const item = question.items.find((i) => i.key === itemKey);
      if (item && isVideoContent(item)) {
         if ('isInteractiveVideo' in update) {
            // If we are changing if a video is interactive or not we need to reset the prompt times
            update.promptTimes = [];
         }
      }

      handleQuestionUpdate(
         {
            items: question.items.map((i) =>
               i.key === itemKey ? { ...i, ...update } : i,
            ) as readonly ActivityItem<ActivityBuilderMode>[],
         },
         () => callback?.(),
      );
   };

   const itemFactory = (item: ActivityItem<ActivityBuilderMode>): React.ReactNode => {
      const commonProps = {
         handleItemUpdate: (
            update: Partial<ActivityItem<ActivityBuilderMode>>,
            callback?: () => void,
         ) => handleItemUpdate(item.key, update, callback),
         duplicateItem: () => duplicateItem(item.key, question.id),
         removeItem: () => removeItem(item.key),
      };

      if (isPrompt(item)) {
         let descriptionClassName = '';
         if (question.index === 0) {
            descriptionClassName = getOnboardingClassName?.('add_prompt_description_pointer') ?? '';
         }

         const isInteractiveVideoPrompt =
            item.id !== null && allInteractivePromptsForQuestionn.includes(item.id);

         if (!isInteractiveVideoPrompt) {
            return (
               <Prompt
                  prompt={item}
                  key={item.key}
                  descriptionClassName={descriptionClassName}
                  duplicateItem={() => duplicateItem(item.key, question.id)}
                  onUpdate={(
                     update: Partial<ActivityPrompt<ActivityBuilderMode>>,
                     callback?: () => void,
                  ) => handleItemUpdate(item.key, update, callback)}
                  onRemove={() => removeItem(item.key)}
               />
            );
         }
      } else if (isTextContent(item)) {
         return <TextContent {...commonProps} item={item} key={item.key} />;
      } else if (isLessonContent(item)) {
         return <LessonContent {...commonProps} item={item} key={item.key} />;
      } else if (isVocabSetItem(item)) {
         return <VocabSetItem {...commonProps} item={item} key={item.key} />;
      } else if (isVideoContent(item)) {
         return (
            <VideoContent
               handleItemUpdate={handleItemUpdate}
               handleQuestionUpdate={handleQuestionUpdate}
               handleVideoContentUpdate={(update) => handleItemUpdate(item.key, update)}
               handleVideoPromptSecondsUpdate={handleVideoPromptSecondsUpdate}
               isDraggingPrompt={isDraggingPrompt}
               item={item}
               key={item.key}
               question={question}
               removeItem={removeItem}
            />
         );
      } else if (isAudioContent(item)) {
         return <AudioContent {...commonProps} item={item} key={item.key} />;
      }
      return null;
   };

   const addLogic = (): void => {
      handleQuestionUpdate(
         {
            logic: {
               id: randomTempId(),
               parentId: null,
               rules: [],
               combinator: ActivityLogicCombinator.and,
               index: 0,
               actions: [],
            },
         },
         () => {
            setShowLogic(true);
         },
      );
   };

   const addTags = (): void => {
      handleQuestionUpdate(
         {
            tags: [],
         },
         () => {
            setShowTags(true);
         },
      );
   };

   const removeLogic = (): void => {
      handleQuestionUpdate({ logic: null });
      setShowLogic(false);
   };

   const removeTags = (): void => {
      handleQuestionUpdate({ tags: null });
      setShowTags(false);
   };

   const handleTagsChange = (updatedTags: readonly string[]): void => {
      handleQuestionUpdate({ tags: updatedTags });
   };

   const handleLogicUpdate = (updatedLogic: ActivityLogicGroup): void => {
      handleQuestionUpdate({ logic: updatedLogic });
   };

   const toggleShowLogic = (): void => {
      setShowLogic((prevShowLogic) => !prevShowLogic);
   };

   const toggleShowTags = (): void => {
      setShowTags((prevShowTags) => !prevShowTags);
   };

   const removeItem = (keyToDelete: string): void => {
      const itemToDelete = question.items.find((i) => i.key === keyToDelete);
      const filterInteractiveVideoTime = (
         item: ActivityItem<ActivityBuilderMode>,
      ): ActivityItem<ActivityBuilderMode> => {
         if (
            itemToDelete?.id !== undefined &&
            isPrompt(itemToDelete) &&
            isVideoContent(item) &&
            item?.isInteractiveVideo &&
            item.promptTimes
         ) {
            return {
               ...item,
               promptTimes: item.promptTimes.filter((i) => i.promptId !== itemToDelete.id),
            };
         } else {
            return item;
         }
      };
      handleQuestionUpdate({
         items: question.items.filter((i) => i.key !== keyToDelete).map(filterInteractiveVideoTime),
      });
   };

   const droplistItems: readonly IMenuItem[] = [
      {
         text: `${tags ? 'Remove' : 'Add'} Tags`,
         onClick: tags ? removeTags : addTags,
         icon: <IconTag1 />,
      },
      {
         text: `${logic ? 'Remove' : 'Add'} Logic`,
         onClick: logic ? removeLogic : addLogic,
         icon: <IconControlSplit />,
      },
      { text: 'Duplicate', onClick: duplicateQuestion, icon: <IconFileCopy /> },
      { text: 'Remove', onClick: removeQuestion, icon: <IconBin /> },
   ].filter((i) => !!i);

   return (
      <div className='card inner'>
         <div className='card-title full-width activity-builder-title'>
            <AutosizeInput
               className='title-input question-title'
               value={title}
               type='text'
               name='question-section-title'
               placeholder='Untitled Section'
               onChange={(e) => handleQuestionUpdate({ title: e.target.value })}
            />
            <Droplist pullRight items={droplistItems}>
               <IconOptionGrey className='pointer icon-gray' />
            </Droplist>
         </div>
         <div className='activity-builder-container'>
            <Container
               groupName='builder'
               getChildPayload={(i) => question.items[i]}
               nonDragAreaSelector={nonDragAreaSelector}
               onDrop={handleItemDrop}
            >
               {question.items.map((i) => (
                  <Draggable key={i.key}>
                     {isPrompt(i) ? (
                        <DNDKitDraggable id={i.key} data={i}>
                           {itemFactory(i)}
                        </DNDKitDraggable>
                     ) : (
                        itemFactory(i)
                     )}
                  </Draggable>
               ))}
            </Container>
            {tags && (
               <div className='activity-builder-row'>
                  {showTags && (
                     <TagsInput
                        value={tags}
                        className='margin-bottom-s'
                        tagProps={{
                           className: 'tag',
                           classNameRemove: 'remove ignore-click',
                        }}
                        onChange={handleTagsChange}
                     />
                  )}
                  <Button subtle onClick={toggleShowTags} icon={<IconTag1 aria-hidden />}>
                     {`${showTags ? 'Hide' : 'Show'} Tags`}
                  </Button>
               </div>
            )}
            {logic && (
               <div className='activity-builder-row'>
                  {showLogic && (
                     <div className='margin-bottom-s'>
                        <QuestionLogic
                           questionId={question.id}
                           logic={logic}
                           onLogicUpdate={handleLogicUpdate}
                        />
                     </div>
                  )}
                  <Button subtle onClick={toggleShowLogic}>
                     <IconControlSplit aria-hidden />
                     {`${showLogic ? 'Hide' : 'Show'} Logic Rules`}
                  </Button>
               </div>
            )}
         </div>
      </div>
   );
};

export default React.memo(Question);
