import * as _ from 'lodash';
import * as React from 'react';

import Link from '@components/Common/Link';
import Droplist from '@components/Core/Droplist';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { IOnboardingProps, OnboardingTaskState, withOnboarding } from '@components/Onboarding';
import autobind from '@helpers/autobind';
import indexDeep from '@helpers/IndexDeep';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import omitDeep from '@helpers/OmitDeep';
import {
   getQueryParameterByName,
   getQueryParametersByNameAsNumberArray,
} from '@helpers/QueryParameter';
import ToggleMenuClose from '@icons/general/toggle-menu-close.svg';
import {
   Activity,
   ActivityCollapsedRubricEntry,
   ActivityMode,
   ActivityPrompt,
   ActivityResponse,
   ActivityResponseEvaluation,
   ActivityRubric,
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGroup,
   ActivityRubricScoringType,
   GradingMode,
} from '@models/Activity';
import { Breadcrumb } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import { IdMessageResponse, Maybe, MessageResponse } from '@models/Core';
import HttpService from '@services/HttpService';
import ResponseEvaluationService from '@services/ResponseEvaluationService';
import smoothscroll from 'smoothscroll-polyfill';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { RouteComponentProps } from '../../../types/Routing';
import {
   getPromptName,
   isPrompt,
   isRubricItem,
   isRubricItemGropWithItems,
} from '@components/Activity/Utils';
import ActivityGraderFooter from './ActivityGraderFooter';
import Sidebar from './ActivityGraderSidebar';
import PromptList from './PromptList';
import Response from './Response';

export interface GraderResponse {
   id: number;
   ownerName: string;
   response: ActivityResponse;
   promptId: number;
}

interface ActivityGraderParams extends RouteComponentProps {
   promptId?: string;
   moduleItemId?: string;
   submissionId?: string;
}

interface ActivityGraderResponse {
   activity: Activity<ActivityMode.grade>;
   breadcrumbsInfo: {
      canEdit: boolean;
      courseId: number;
      courseName: string;
      moduleId: number;
      moduleName: string;
   };
   evaluations: readonly ActivityResponseEvaluation<ActivityMode.grade>[];
   ownerName?: string;
   moduleItemSubmissionIds: readonly { id: number }[];
   responses: readonly GraderResponse[];
   rubric?: ActivityRubric;
   rubrics?: readonly ActivityRubric[];
}

export interface ActivityGraderProps extends IOnboardingProps, ActivityGraderParams {}

export interface ActivityGraderState {
   activeIndex: number;
   activity: Maybe<Activity<ActivityMode.grade>>;
   courseId: Maybe<number>;
   evaluations: readonly ActivityResponseEvaluation<ActivityMode.grade>[];
   expandedRubricItemGroupId: Maybe<number>;
   gradingMode: Maybe<GradingMode>;
   isDragging: boolean;
   isFetching: boolean;
   moduleItemId: Maybe<number>;
   moduleItemSubmissionIds: readonly number[];
   nextUngraded: number;
   previousUngraded: number;
   promptId: Maybe<number>;
   responses: readonly GraderResponse[];
   rubric?: ActivityRubric;
   rubrics?: readonly ActivityRubric[];
   selectedSubmissionIds: readonly number[];
   sidebarOpen: boolean;
   submissionId: Maybe<number>;
}

class ActivityGrader extends React.Component<ActivityGraderProps, ActivityGraderState> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   nodes: Map<number, HTMLDivElement>;
   debouncedPutEvaluation: _.DebouncedFunc<(index: number) => void>;

   constructor(props: ActivityGraderProps) {
      super(props);
      autobind(this);

      this.state = {
         activeIndex: 0,
         activity: null,
         courseId: null,
         evaluations: [],
         expandedRubricItemGroupId: null,
         gradingMode: null,
         isDragging: false,
         isFetching: true,
         moduleItemId: null,
         moduleItemSubmissionIds: [],
         nextUngraded: -1,
         previousUngraded: -1,
         promptId: null,
         responses: [],
         selectedSubmissionIds: [],
         sidebarOpen: true,
         submissionId: null,
      };
      this.nodes = new Map();
      this.debouncedPutEvaluation = _.debounce(this.putEvaluation, 2000);
   }

   async componentDidMount(): Promise<void> {
      const {
         location,
         params: { promptId, submissionId, moduleItemId },
      } = this.props;
      smoothscroll.polyfill();
      if (promptId || submissionId) {
         this.setState(
            {
               gradingMode: promptId ? GradingMode.byPrompt : GradingMode.bySubmission,
               moduleItemId: moduleItemId ? parseInt(moduleItemId, 10) : null,
               promptId: promptId ? parseInt(promptId, 10) : null,
               selectedSubmissionIds: getQueryParametersByNameAsNumberArray(location, 'sid'),
               submissionId: submissionId ? parseInt(submissionId, 10) : null,
            },
            this.fetchResponses,
         );
      }
   }

   componentWillUnmount(): void {
      // When leaving the page we need to make sure we send off updates
      this.cleanupEvaluationDebounce(false);
   }

   componentDidUpdate(prevProps: ActivityGraderProps, prevState: ActivityGraderState): void {
      const {
         params: { moduleItemId, promptId, submissionId },
         taskState,
         onStateUpdate,
      } = this.props;
      const {
         params: {
            moduleItemId: prevModuleItemId,
            promptId: prevPromptId,
            submissionId: prevSubmissionId,
         },
      } = prevProps;

      if (submissionId && submissionId !== prevSubmissionId) {
         // When switching to a new student/submission send off updates
         this.cleanupEvaluationDebounce(false);
      }

      if (
         (moduleItemId && moduleItemId !== prevModuleItemId) ||
         (promptId && promptId !== prevPromptId) ||
         (submissionId && submissionId !== prevSubmissionId)
      ) {
         this.setState(
            {
               gradingMode: promptId ? GradingMode.byPrompt : GradingMode.bySubmission,
               moduleItemId: moduleItemId ? parseInt(moduleItemId, 10) : null,
               promptId: promptId ? parseInt(promptId, 10) : null,
               submissionId: submissionId ? parseInt(submissionId, 10) : null,
               isFetching: true,
            },
            this.fetchResponses,
         );
      }
      if (taskState === OnboardingTaskState.inProgress) {
         onStateUpdate(this.state, prevState);
      }
   }

   cleanupEvaluationDebounce(updateState: boolean): void {
      const { activeIndex, evaluations } = this.state;
      if (this.debouncedPutEvaluation) {
         this.debouncedPutEvaluation.cancel();
      }
      if (
         evaluations[activeIndex] &&
         !evaluations[activeIndex].isSaved &&
         !evaluations[activeIndex].isSaving
      ) {
         this.putEvaluation(activeIndex, updateState);
      }
   }

   createRubricItem(data: Partial<ActivityRubricItem> = {}, focus = true): Promise<number> {
      const rubric = this.getRubric();
      if (!rubric) {
         return Promise.reject('No rubric found');
      }
      const { id: rubricId, items } = rubric;
      return HttpService.postWithAuthToken<IdMessageResponse>(
         `/api/activities/rubrics/${rubricId}/items`,
         snakeCaseKeys(data),
      ).then((response) => {
         const { id } = response.data;
         this.updateRubric({
            items: [
               ...items,
               {
                  description: '',
                  groupId: null,
                  id,
                  index: null,
                  isNew: focus,
                  weight: '0.0',
                  type: ActivityRubricEntryType.item,
                  ...data,
               },
            ],
         });
         return id;
      });
   }

   createRubricItemGroup(
      data: Partial<ActivityRubricItemGroup> = {},
      focus = true,
   ): Promise<number> {
      const rubric = this.getRubric();
      if (!rubric) {
         return Promise.reject('No rubric found');
      }
      const { id: rubricId, groups } = rubric;
      return HttpService.postWithAuthToken<IdMessageResponse>(
         `/api/activities/rubrics/${rubricId}/groups`,
         snakeCaseKeys(data),
      ).then((response) => {
         const { id } = response.data;
         this.updateRubric({
            groups: [
               ...groups,
               {
                  description: '',
                  id,
                  index: null,
                  isNew: focus,
                  mutuallyExclusive: false,
                  type: ActivityRubricEntryType.group,
                  ...data,
               },
            ],
         });
         return id;
      });
   }

   deleteRubricItem(itemId: number): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }
      const { items } = rubric;
      this.updateRubric({ items: items.filter((i) => i.id !== itemId) }, this.reindexRubric);
   }

   deleteRubricItemGroup(groupId: number): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }
      const { groups, items } = rubric;
      this.updateRubric(
         {
            groups: groups.filter((i) => i.id !== groupId),
            items: items.filter((i) => i.groupId !== groupId),
         },
         this.reindexRubric,
      );
   }

   async fetchResponses(): Promise<void> {
      const {
         routes: { activities, courses },
      } = Constants;
      const { gradingMode, moduleItemId, promptId, submissionId } = this.state;
      this.setState({ isFetching: true });
      let url = '';
      const params = [];
      if (gradingMode === GradingMode.byPrompt) {
         url = `/api/activities/${moduleItemId}/responses/${promptId}`;
         const selectedSubmissionIdsString =
            this.state.selectedSubmissionIds.length > 0
               ? '?id=' + this.state.selectedSubmissionIds.join('&id=')
               : '';
         url += selectedSubmissionIdsString;
      } else if (gradingMode === GradingMode.bySubmission) {
         url = `/api/activities/${moduleItemId}/submissions/${submissionId}`;
         params.push('mode=grade');
      } else {
         return;
      }
      url = params.length ? `${url}?${params.join('&')}` : url;
      await HttpService.getWithAuthToken<ActivityGraderResponse>(url).then((response) => {
         const {
            activity,
            breadcrumbsInfo: { canEdit, courseId, courseName, moduleId, moduleName },
            evaluations,
            ownerName,
            moduleItemSubmissionIds,
            responses,
            rubric,
            rubrics,
         } = response.data;
         const ref = getQueryParameterByName(this.props.location, 'ref');
         const fromGradebook = ref === 'gradebook';
         const refLink = fromGradebook
            ? courses.gradebook.replace(':courseId', courseId.toString())
            : courses.viewModule
                 .replace(':courseId', courseId.toString())
                 .replace(':moduleId', moduleId.toString());
         const breadcrumbs: Breadcrumb[] = [
            {
               link: courses.dashboard.replace(':courseId', courseId.toString()),
               text: courseName,
               contextInfo: { courseId, moduleId },
            },
            {
               link: refLink,
               text: fromGradebook ? 'Gradebook' : moduleName,
            },
            {
               link: activities.viewAllSubmissions
                  .replace(':moduleItemId', moduleItemId?.toString() ?? '')
                  .concat(fromGradebook ? '?ref=gradebook' : ''),
               text: `${activity.name} Submissions`,
               contextInfo: {
                  contentId: activity.id,
                  contentType: ContentType.activity,
                  canEditContent: canEdit,
               },
            },
         ];
         if (gradingMode === GradingMode.byPrompt) {
            breadcrumbs.push({
               link: activities.gradeResponses
                  .replace(':moduleItemId', moduleItemId?.toString() ?? '')
                  .replace(':promptId', promptId?.toString() ?? ''),
               text: 'Grade Responses',
            });
         } else if (gradingMode === GradingMode.bySubmission && ownerName) {
            const link = moduleItemId
               ? activities.gradeSubmissionFromModuleId
                    .replace(':moduleItemId', moduleItemId.toString())
                    .replace(':submissionId', submissionId?.toString() ?? '')
               : activities.gradeSubmission.replace(
                    ':submissionId',
                    submissionId?.toString() ?? '',
                 );
            breadcrumbs.push({ link, text: ownerName });
         }
         this.context.setBreadcrumbs({
            breadcrumbs,
            next: null,
            prev: null,
         });
         this.setState(
            {
               activity,
               courseId,
               evaluations: evaluations.map((i) => ({
                  ...i,
                  isSaved: true,
                  isSaving: false,
               })),
               isFetching: false,
               moduleItemSubmissionIds: moduleItemSubmissionIds
                  ? moduleItemSubmissionIds.map((i) => i.id)
                  : [],
               responses,
               rubric,
               rubrics,
            },
            () => {
               this.setIndices();
               const evaluationsGraded = this.state.evaluations.filter((i) => i.graded).length;
               if (evaluationsGraded !== this.state.evaluations.length) {
                  this.handleSetActive(_.findIndex(this.state.evaluations, (i) => !i.graded));
               }
            },
         );
      });
   }

   getScore(): Maybe<string> {
      const { activeIndex, evaluations } = this.state;
      const rubric = this.getRubric();
      if (!rubric) {
         return undefined;
      }
      const { ceiling, floor, items, scoringType, weight } = rubric;
      const evaluation = evaluations[activeIndex];
      let score = 0;
      const parsedWeight = parseFloat(weight);
      const rubricTotal = _.sum(
         evaluation.rubricItemIds.map((i) => {
            const item = items.find(({ id }) => i === id);
            if (item && /^-?\d*\.{0,1}\d+$/.test(item.weight)) {
               return parseFloat(item.weight);
            }
            return undefined;
         }),
      );
      if (evaluation.rubricItemIds.length) {
         if (scoringType === ActivityRubricScoringType.positive) {
            score = rubricTotal;
         } else if (scoringType === ActivityRubricScoringType.negative) {
            score = parsedWeight - rubricTotal;
         }
      }
      if (evaluation.pointAdjustment) {
         score += evaluation.pointAdjustment;
      }
      if (floor) {
         score = Math.max(score, 0);
      }
      if (ceiling) {
         score = Math.min(score, parsedWeight);
      }
      return Number.isNaN(score) ? '0.0' : Number(score).toFixed(1);
   }

   getPromptByIndex(index: number): Maybe<ActivityPrompt<ActivityMode.grade>> {
      const { activity, gradingMode, promptId } = this.state;
      if (!activity) {
         return undefined;
      }
      const { questions } = activity;
      const flattenedPrompts = questions.map(({ items }) => items.filter(isPrompt)).flat();
      if (gradingMode === GradingMode.bySubmission) {
         return flattenedPrompts[index];
      } else {
         return flattenedPrompts.find((i) => i.id === promptId);
      }
   }

   getPromptById(promptId: number): Maybe<ActivityPrompt<ActivityMode.grade>> {
      const { activity } = this.state;
      if (!activity) {
         return undefined;
      }
      const { questions } = activity;
      const flattenedPrompts = questions.map(({ items }) => items.filter(isPrompt)).flat();
      return flattenedPrompts.find((i) => i.id === promptId);
   }

   getRubric(): Maybe<ActivityRubric> {
      const { activeIndex, gradingMode, rubric, rubrics } = this.state;
      if (gradingMode === GradingMode.byPrompt && rubric) {
         return rubric;
      } else if (gradingMode === GradingMode.bySubmission && rubrics) {
         return rubrics[activeIndex];
      }
      return undefined;
   }

   getSubmissionsLink(): string {
      const { viewAllSubmissions } = Constants.routes.activities;
      const { moduleItemId } = this.state;
      const submissionsLink = viewAllSubmissions.replace(
         ':moduleItemId',
         moduleItemId?.toString() ?? '',
      );
      const ref = getQueryParameterByName(this.props.location, 'ref');
      return ref ? `${submissionsLink}?ref=${ref}` : submissionsLink;
   }

   putEvaluation(index: number, updateState = true): void {
      const { id: responseId } = this.state.responses[index];
      if (updateState) {
         this.setState((prevState) => ({
            evaluations: prevState.evaluations.map((i, j) =>
               j === index ? { ...i, isSaving: true } : i,
            ),
         }));
      }

      const { isSaved, isSaving, ...data } = this.state.evaluations[index];
      ResponseEvaluationService.update(responseId, data).then(() => {
         if (updateState) {
            this.setState((prevState) => ({
               evaluations: prevState.evaluations.map((i, j) =>
                  j === index ? { ...i, isSaved: true, isSaving: false } : i,
               ),
            }));
         }
      });
   }

   putRubric(): void {
      const rubric = _.cloneDeep(this.getRubric());
      if (!rubric) {
         return;
      }
      omitDeep(rubric, ['isNew']);
      HttpService.putWithAuthToken<MessageResponse>(
         `/api/activities/rubrics/${rubric.id}`,
         snakeCaseKeys(rubric),
      );
   }

   handleSetActive(index: number): void {
      if (index !== this.state.activeIndex) {
         this.cleanupEvaluationDebounce(true);
         this.setState({ activeIndex: index }, this.setIndices);
         const node = this.nodes.get(index);
         if (node) {
            const grandparentElement = node.parentNode?.parentElement;
            grandparentElement?.scroll({
               top: node.offsetTop - grandparentElement.offsetTop,
               left: 0,
               behavior: 'smooth',
            });
         }
      }
   }

   shouldShowNextStudent(): boolean {
      const { gradingMode, nextUngraded } = this.state;
      const isSubmissionMode = gradingMode === GradingMode.bySubmission;
      const isEndOfCurrentSubmission = nextUngraded === -1;
      return isSubmissionMode && isEndOfCurrentSubmission;
   }

   getNextSubmissionLink(
      currentSubmissionId: number,
      currentModuleItemId: number,
      submissionIds: readonly number[],
   ): string {
      const currentIndex = submissionIds.indexOf(currentSubmissionId);
      const isCurrentlyAtEnd = currentIndex === submissionIds.length - 1;
      const nextIndex = isCurrentlyAtEnd ? 0 : currentIndex + 1;
      const nextSubmissionId = submissionIds[nextIndex];

      const submissionLink = Constants.routes.activities.gradeSubmissionFromModuleId
         .replace(':moduleItemId', currentModuleItemId.toString())
         .replace(':submissionId', nextSubmissionId.toString());

      const withExistingQueryParams = submissionLink + location.search;
      return withExistingQueryParams;
   }

   getNextStudentLink(): Maybe<string> {
      const { moduleItemId, moduleItemSubmissionIds, submissionId, selectedSubmissionIds } =
         this.state;
      const hasSelectedSubmissionIds = selectedSubmissionIds?.length > 0;
      const submissionIdsToUse = hasSelectedSubmissionIds
         ? selectedSubmissionIds
         : moduleItemSubmissionIds;
      if (
         submissionId !== undefined &&
         submissionId !== null &&
         !!moduleItemId &&
         submissionIdsToUse.indexOf(submissionId) + 1 < submissionIdsToUse.length
      ) {
         return this.getNextSubmissionLink(submissionId, moduleItemId, submissionIdsToUse);
      }
      return null;
   }

   renderPromptList(): React.ReactNode {
      const { activeIndex, activity, gradingMode, moduleItemId, promptId } = this.state;
      const prompt = this.getPromptByIndex(activeIndex);
      if (!activity || !prompt) {
         return null;
      }
      const { questions } = activity;
      const { itemType } = prompt;

      if (gradingMode === GradingMode.byPrompt) {
         return (
            <Droplist
               className='prompt-list-dropdown'
               component={
                  <PromptList
                     currentPromptId={promptId}
                     questions={questions}
                     moduleItemId={moduleItemId}
                  />
               }
            >
               <p>{getPromptName(itemType)}</p>
            </Droplist>
         );
      } else if (gradingMode === GradingMode.bySubmission) {
         return <p>{getPromptName(itemType)}</p>;
      }
   }

   reindexRubric(): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }
      const { groups, items } = rubric;
      const groupedItems = _.groupBy(items, 'groupId');
      const entries = _.orderBy(
         [
            ...(groupedItems.null || []),
            ...groups.map((i) => ({
               ...i,
               rubricItems: _.orderBy(groupedItems[i.id] || [], ['index'], ['asc']),
            })),
         ],
         ['index'],
         ['asc'],
      );
      this.updateRubricEntryPositions(entries);
   }

   setIndices(): void {
      const { activeIndex, evaluations } = this.state;
      this.setState({
         previousUngraded:
            activeIndex === 0
               ? -1
               : _.findIndex(evaluations.slice(0, activeIndex), (i) => !i.graded),
         nextUngraded:
            activeIndex + 1 === evaluations.length
               ? -1
               : _.findIndex(evaluations, (i) => !i.graded, activeIndex + 1),
      });
   }

   updateEvaluation(
      index: number,
      update: Partial<ActivityResponseEvaluation<ActivityMode.grade>>,
   ): void {
      const { graded: gradedBefore } = this.state.evaluations[index];
      this.setState(
         (prevState) => {
            const evaluations = prevState.evaluations.map((i, j) =>
               j === index ? { ...i, ...update, isSaved: false } : i,
            );
            const { rubricItemIds, pointAdjustment } = evaluations[index];
            evaluations[index].graded = !!(rubricItemIds.length || pointAdjustment);
            return { evaluations };
         },
         () => {
            if (gradedBefore !== this.state.evaluations[index].graded) {
               this.setIndices();
            }
            this.debouncedPutEvaluation(index);
         },
      );
   }

   updateRubric(update: Partial<ActivityRubric>, callback?: () => void): void {
      this.setState(
         (prevState) => {
            const { activeIndex, gradingMode } = prevState;
            if (gradingMode === GradingMode.byPrompt && prevState.rubric) {
               return {
                  ...prevState,
                  rubric: {
                     ...prevState.rubric,
                     ...update,
                  },
               };
            } else if (gradingMode === GradingMode.bySubmission) {
               return {
                  ...prevState,
                  rubrics: prevState.rubrics?.map((rubric, index) =>
                     index === activeIndex ? { ...rubric, ...update } : rubric,
                  ),
               };
            }
            return prevState;
         },
         () => {
            this.putRubric();
            callback?.();
         },
      );
   }

   toggleRubricItem(itemId: number): void {
      const { activeIndex, evaluations } = this.state;
      const { rubricItemIds: ids } = { ...evaluations[activeIndex] };
      const updatedIds = ids.includes(itemId)
         ? ids.filter((i) => i !== itemId)
         : ids.concat(itemId);
      this.updateEvaluation(activeIndex, { rubricItemIds: updatedIds });
   }

   toggleRubricItemGroup(groupId: number): void {
      this.setState((prevState) => ({
         expandedRubricItemGroupId:
            prevState.expandedRubricItemGroupId === groupId ? null : groupId,
      }));
   }

   toggleSidebarOpen(): void {
      // Prevent the user from closing the menu while doing the onboarding tour
      const isOnboarding = document.getElementsByClassName('onboarding-pointer').length > 0;
      if (isOnboarding) {
         return;
      }

      this.setState((prevState) => ({
         sidebarOpen: !prevState.sidebarOpen,
      }));
   }

   updateResponse(responseId: number, update: Partial<GraderResponse>): Promise<void> {
      return new Promise((resolve) => {
         this.setState(
            (prevState) => ({
               responses: prevState.responses.map((i) =>
                  i.id === responseId ? { ...i, response: { ...i.response, ...update } } : i,
               ),
            }),
            resolve,
         );
      });
   }

   updateRubricEntries(updates: {
      [ActivityRubricEntryType.item]: Record<number, Partial<ActivityRubricItem>>;
      [ActivityRubricEntryType.group]: Record<number, Partial<ActivityRubricItemGroup>>;
   }): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }

      const updatedItems = rubric.items.map((item) => {
         const update = updates[ActivityRubricEntryType.item][item.id];
         return update ? ({ ...item, ...update } as ActivityRubricItem) : item;
      });

      const updatedGroups = rubric.groups.map((group) => {
         const update = updates[ActivityRubricEntryType.group][group.id];
         return update ? ({ ...group, ...update } as ActivityRubricItemGroup) : group;
      });

      this.updateRubric({ items: updatedItems, groups: updatedGroups });
   }

   updateRubricEntryPositions(rubricEntries: readonly ActivityCollapsedRubricEntry[]): void {
      const updatedEntries = [...rubricEntries];
      indexDeep(updatedEntries);
      const filteredItems = updatedEntries.filter(isRubricItem);
      const filteredGroups = updatedEntries.filter(isRubricItemGropWithItems);
      const updatedItems = [
         ...filteredItems,
         ..._.flatten(filteredGroups.map((i) => i.rubricItems)),
      ];
      // Remove rubricItems from group and convert from ActivityRubricItemGropWithItems to ActivityRubricItemGroup
      const updatedGroups = filteredGroups.map(({ rubricItems, ...i }) => i);
      this.updateRubric({
         items: updatedItems,
         groups: updatedGroups,
      });
   }

   updateRubricItem(itemId: number, update: Partial<ActivityRubricItem>): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }
      const updatedItems = rubric.items.map((item) =>
         item.id === itemId ? { ...item, ...update } : item,
      );
      this.updateRubric({ items: updatedItems });
   }

   updateRubricItemGroup(groupId: number, update: Partial<ActivityRubricItemGroup>): void {
      const rubric = this.getRubric();
      if (!rubric) {
         return;
      }
      const updatedGroups = rubric.groups.map((group) =>
         group.id === groupId ? { ...group, ...update } : group,
      );
      this.updateRubric({ groups: updatedGroups });
   }

   render(): React.ReactNode {
      const {
         activeIndex,
         activity,
         courseId,
         evaluations,
         expandedRubricItemGroupId,
         gradingMode,
         isFetching,
         moduleItemId,
         nextUngraded,
         previousUngraded,
         responses,
         sidebarOpen,
      } = this.state;

      const rubric = this.getRubric();

      if (
         isFetching ||
         !activity ||
         !evaluations ||
         !responses ||
         !gradingMode ||
         !courseId ||
         !moduleItemId ||
         !rubric
      ) {
         return <Loader />;
      }

      const { name: activityName, language } = activity;
      const evaluationsGraded = evaluations.filter((i) => i.graded).length;
      const score = this.getScore();
      const submissionsLink = this.getSubmissionsLink();
      const previousCommentsUnfiltered = evaluations
         .map(({ comments }) => comments)
         .filter((i) => (i || '').replace(/<[^>]+>/g, '').trim().length);
      const previousComments = [...new Set(previousCommentsUnfiltered)];

      return (
         <div className='grader-content-container'>
            <DocumentTitle>
               {isFetching ? 'Loading Grader...' : `Grader - ${activityName}`}
            </DocumentTitle>
            <div className='row'>
               <div className='session-control col-xs-12 col-sm-2 col-md-1 col-lg-2'>
                  <div className='session-control-button'>
                     <Link to={submissionsLink} data-test='exit-grader'>
                        Exit
                     </Link>
                  </div>
               </div>
               <div
                  className={`grader-content-toggle col-xs-12 ${
                     sidebarOpen ? 'col-sm-6 col-md-7 col-lg-6' : 'col-sm-8 col-md-9 col-lg-8'
                  }`}
               >
                  <div className='question-card-container'>
                     {responses.map(({ id: responseId, response, ownerName, promptId }, i) => (
                        <Response
                           key={responseId}
                           id={responseId}
                           response={response}
                           language={language}
                           isActive={activeIndex === i}
                           prompt={this.getPromptById(promptId)}
                           gradingMode={gradingMode}
                           title={`Response ${i + 1}`}
                           ownerName={ownerName}
                           setActive={() => {
                              this.handleSetActive(i);
                           }}
                           setRef={(e) => (e ? this.nodes.set(i, e) : undefined)}
                           updateResponse={this.updateResponse}
                        />
                     ))}
                  </div>
               </div>
               <div
                  className={`grader-content-sidebar-toggle col-xs-12 ${
                     sidebarOpen
                        ? 'col-sm-4 col-md-4 col-lg-3 col-lg-offset-1'
                        : 'col-sm-2 sidebar-collapsed'
                  }`}
               >
                  {sidebarOpen ? (
                     <Sidebar
                        courseId={courseId}
                        language={language}
                        evaluation={evaluations[activeIndex]}
                        evaluationsGraded={evaluationsGraded}
                        expandedRubricItemGroupId={expandedRubricItemGroupId}
                        moduleItemId={moduleItemId}
                        previousComments={previousComments}
                        promptId={this.getPromptByIndex(activeIndex)?.id}
                        prompt={this.getPromptByIndex(activeIndex)}
                        responsesCount={responses.length}
                        rubric={rubric}
                        score={score}
                        createRubricItem={this.createRubricItem}
                        createRubricItemGroup={this.createRubricItemGroup}
                        deleteRubricItem={this.deleteRubricItem}
                        deleteRubricItemGroup={this.deleteRubricItemGroup}
                        renderPromptList={this.renderPromptList}
                        toggleRubricItem={this.toggleRubricItem}
                        toggleRubricItemGroup={this.toggleRubricItemGroup}
                        toggleSidebarOpen={this.toggleSidebarOpen}
                        updateEvaluation={(update) => {
                           this.updateEvaluation(activeIndex, update);
                        }}
                        updateRubric={this.updateRubric}
                        updateRubricEntries={this.updateRubricEntries}
                        updateRubricEntryPositions={this.updateRubricEntryPositions}
                        updateRubricItem={this.updateRubricItem}
                        updateRubricItemGroup={this.updateRubricItemGroup}
                     />
                  ) : (
                     <div className='sidebar-toggle open show' onClick={this.toggleSidebarOpen}>
                        <ToggleMenuClose />
                     </div>
                  )}
               </div>
            </div>
            <ActivityGraderFooter
               nextUngradedIndex={nextUngraded}
               previousUngradedIndex={previousUngraded}
               activeIndex={activeIndex}
               responseCount={responses.length}
               setActive={this.handleSetActive}
               shouldShowNextStudent={this.shouldShowNextStudent()}
               nextStudentLink={this.getNextStudentLink()}
            />
         </div>
      );
   }
}

export default withOnboarding(ActivityGrader);
