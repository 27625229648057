import * as React from 'react';

import IconGrade from '@icons/general/icon-grade.svg';
import IconAlarm from '@icons/nova-line/05-Time/alarm.svg';
import IconAlarmSilent from '@icons/nova-solid/05-Time/alarm-silent.svg';
import Notification, { NotificationType } from '@models/Notification';
import NotificationService from '@services/NotificationService';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import Color from '../../../types/Color';
import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';
import NotificationComponent from './Notification';

const NotificationsDropdown: React.FC = () => {
   const {
      routes: {
         activities: { completeSubmission },
      },
   } = Constants;

   const navigate = useNavigate();

   const { notifications, userProfile, setNotifications } =
      React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }

   const [isOpen, setIsOpen] = React.useState<boolean>(false);

   const toggleIsOpen = (): void => setIsOpen((prevIsOpen) => !prevIsOpen);

   const count = notifications.filter((i) => i.read === false).length;

   const getNotificationIcon = (): React.ReactNode => <IconGrade />;

   const getNotificationColor = (): Color => Color.blue;

   const getNotificationLink = (notification: Notification): string => {
      if (
         [NotificationType.feedbackLeft, NotificationType.assignmentGraded].includes(
            notification.notificationType,
         )
      ) {
         return completeSubmission
            .replace(':submissionId', notification.data.submissionId.toString())
            .concat(`?moduleItemId=${notification.data.moduleItemId}`);
      }
      return '';
   };

   const getNotificationText = (notification: Notification): string => {
      if (notification.notificationType === NotificationType.feedbackLeft) {
         return `New feedback left on ${notification.data.contentName}`;
      }
      return '';
   };

   const markNotificationAsRead = (notificationId: number): void => {
      NotificationService.markNotificationAsRead(userProfile.id, notificationId).then(() => {
         setNotifications(
            notifications.map((i) => (i.id === notificationId ? { ...i, read: true } : i)),
         );
      });
   };

   const markAllNotificationsAsRead = (): void => {
      const unreadNotificationIds = notifications.filter((i) => !i.read).map((i) => i.id);
      NotificationService.markNotificationsAsRead(userProfile.id, unreadNotificationIds).then(
         () => {
            setNotifications(notifications.map((i) => ({ ...i, read: true })));
         },
      );
   };

   const handleNotificationClick = (notificationId: number): void => {
      setIsOpen(false);
      const notification = notifications.find((i) => i.id === notificationId);
      if (!notification) {
         return;
      }
      const link = getNotificationLink(notification);
      if (!notification.read) {
         markNotificationAsRead(notificationId);
      }
      if (link) {
         navigate(link);
      }
   };

   return (
      <div className='nav-notifications'>
         <div
            aria-expanded={isOpen}
            aria-haspopup
            aria-label='Notifications'
            className='nav-notification-icon'
            onClick={toggleIsOpen}
            role='button'
            tabIndex={0}
         >
            <IconAlarm />
            {count > 0 && <div className='badge yellow'>{count}</div>}
         </div>
         {isOpen && (
            <div className='dropdown-menu nav-notification-dropdown'>
               <div className='card-title'>
                  <div className='title'>Notifications</div>
                  {!!notifications.length && (
                     <Button subtle onClick={markAllNotificationsAsRead}>
                        Mark all as read
                     </Button>
                  )}
               </div>
               <div className='notifications'>
                  {notifications.map((i) => (
                     <NotificationComponent
                        key={i.id}
                        icon={getNotificationIcon()}
                        color={getNotificationColor()}
                        text={getNotificationText(i)}
                        date={i.createdOn}
                        onClick={() => handleNotificationClick(i.id)}
                        unread={!i.read}
                     />
                  ))}
               </div>
               {!notifications.length && (
                  <EmptyState
                     icon={<IconAlarmSilent aria-hidden />}
                     heading='No notifications'
                     description="You're up to date!"
                  />
               )}
            </div>
         )}
      </div>
   );
};

export default NotificationsDropdown;
