import * as _ from 'lodash';
import * as React from 'react';

import indexDeep from '@helpers/IndexDeep';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconDataUpload8 from '@icons/nova-line/23-Data-Transfer/data-upload-8.svg';
import { Maybe, MessageResponse } from '@models/Core';
import { Module } from '@models/Course';
import HttpService from '@services/HttpService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import {
   BeforeCapture,
   Draggable,
   DraggableStateSnapshot,
   DragStart,
   Droppable,
   DroppableProvided,
   DropResult,
} from 'react-beautiful-dnd';

import Constants from '../../../Constants';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { AssignActivityStep } from '@components/Onboarding/Walkthroughs/AssignActivity';
import { ModuleDndEmitter, ModuleDroppableId } from './ModuleDndEmitter';
import ModuleListItem from './ModuleListItem';

interface ModuleListProps {
   activeModuleId: Maybe<number>;
   canEditModules: boolean;
   courseId: number;
   draggingItemIds: readonly number[];
   modules: readonly Module[];
   openImportModulesModal(): void;
   setModules(modules: readonly Module[]): void;
}

const ModuleList: React.FC<ModuleListProps> = ({
   activeModuleId,
   canEditModules,
   courseId,
   draggingItemIds,
   modules,
   openImportModulesModal,
   setModules,
}) => {
   const {
      routes: {
         courses: { newModule },
      },
   } = Constants;

   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [expandedModuleIds, setExpandedModuleIds] = React.useState<readonly number[]>([]);
   const [canDropOnModule, setCanDropOnModule] = React.useState<boolean>(false);
   const [canCombineOnly, setCanCombineOnly] = React.useState<boolean>(false);

   const expandModule = (moduleId: number): void => {
      setExpandedModuleIds((prevExpandedModules) => [...prevExpandedModules, moduleId]);
   };

   const collapseModule = (moduleId: number): void => {
      setExpandedModuleIds((prevExpandedModules) =>
         prevExpandedModules.filter((i) => i !== moduleId),
      );
   };

   const isSubmodule = (moduleId: number): boolean =>
      modules.find((i) => i.id === moduleId)?.parentModuleId !== null;

   const isParent = (id: number): boolean => modules.some((i) => i.parentModuleId === id);

   React.useEffect(() => {
      if (activeModuleId) {
         if (isSubmodule(activeModuleId)) {
            const module = modules.find((i) => i.id === activeModuleId);
            if (module?.parentModuleId) {
               expandModule(module.parentModuleId);
            }
         } else if (isParent(activeModuleId)) {
            expandModule(activeModuleId);
         }
      }
   }, [activeModuleId]);

   React.useEffect(() => {
      const dragStartSubscription = ModuleDndEmitter.subscribeTo.onDragStart(handleDragStart);
      const dragEndSubscription = ModuleDndEmitter.subscribeTo.moduleListOnDragEnd(handleDragEnd);
      const dragBeforeCaptureSubscription =
         ModuleDndEmitter.subscribeTo.onBeforeDragCapture(handleBeforeDragCapture);
      return () => {
         dragEndSubscription?.unsubscribe();
         dragStartSubscription?.unsubscribe();
         dragBeforeCaptureSubscription?.unsubscribe();
      };
   });

   const isDropTarget = (module: Module, snapshot?: DraggableStateSnapshot): boolean => {
      if (snapshot?.combineTargetFor) {
         const [_combineTargetType, combineTargetId] = splitDraggableId(snapshot.combineTargetFor);
         if (modules.find((i) => i.id === combineTargetId)) {
            return !isSubmodule(module.id);
         } else {
            return module.id !== activeModuleId;
         }
      }
      return false;
   };

   /** Maps the dragged index which ignores collapsed submodules to their actual index */
   const mapDraggedIndexToActualIndex = (): Record<number, number | null> => {
      const actualIndexes = modules
         .map((i, j) => (shouldRenderModule(i) ? j : null))
         .filter((i) => i !== null);
      const result = Object.fromEntries(actualIndexes.map((actual, dragged) => [dragged, actual]));
      // Account for dragging to end
      result[actualIndexes.length - 1] = modules.length - 1;
      return result;
   };

   const splitDraggableId = (draggableId: string): [ModuleDroppableId, number] => {
      const [draggableType, strId] = draggableId.split('-');
      return [draggableType as ModuleDroppableId, Number(strId)];
   };

   const handleBeforeDragCapture = (before: BeforeCapture): void => {
      const [draggedType, draggedId] = splitDraggableId(before.draggableId);
      // Collapse dragged module if it's expanded
      if (draggedType === ModuleDroppableId.ModuleList && isParent(draggedId)) {
         collapseModule(draggedId);
      }
   };

   const handleDragStart = (start: DragStart): void => {
      const [draggedType, draggedId] = splitDraggableId(start.draggableId);
      const draggingContent = draggedType === ModuleDroppableId.ModuleContent;
      const draggingModule = draggedType === ModuleDroppableId.ModuleList;
      const canDropOnParent = draggingContent || (draggingModule && !isParent(draggedId));
      setCanCombineOnly(draggingContent);
      setCanDropOnModule(canDropOnParent);
   };

   const handleDragEnd = async (result: DropResult): Promise<void> => {
      if (result.destination) {
         handleModuleReorder(result);
      } else if (result.combine) {
         const [draggedType, _draggedId] = splitDraggableId(result.draggableId);
         if (draggedType === ModuleDroppableId.ModuleContent) {
            moveModuleContentFromDndResult(result);
         } else if (draggedType === ModuleDroppableId.ModuleList) {
            const [_destinationType, destinationId] = splitDraggableId(result.combine.draggableId);
            handleModuleCombine(result, destinationId);
         }
      }
      setCanCombineOnly(false);
   };

   const shouldRenderModule = (module: Module): boolean =>
      !!module.id &&
      !(
         isSubmodule(module.id) &&
         module.parentModuleId &&
         !expandedModuleIds.includes(module.parentModuleId)
      );

   const handleModuleCombine = async (
      result: DropResult,
      parentModuleId: number,
   ): Promise<void> => {
      const sourceIndex = mapDraggedIndexToActualIndex()[result.source.index];

      if (isSubmodule(parentModuleId) || sourceIndex === null) {
         return; // no double nesting
      }

      const updatedModules = [..._.cloneDeep(modules)];

      const [removed] = updatedModules.splice(sourceIndex, 1);
      removed.parentModuleId = parentModuleId;

      // Place at the end after any other sub modules or after the parent
      const parentModuleIndex = updatedModules.findIndex((i) => i.id === parentModuleId);
      const lastSubmoduleIndex = updatedModules
         .map((i) => i.parentModuleId)
         .lastIndexOf(parentModuleId);
      const destinationIndex = lastSubmoduleIndex === -1 ? parentModuleIndex : lastSubmoduleIndex;

      updatedModules.splice(destinationIndex + 1, 0, removed);

      indexDeep(updatedModules);
      await updateModuleChanges(updatedModules);
   };

   const handleModuleReorder = async (result: DropResult): Promise<void> => {
      const mapping = mapDraggedIndexToActualIndex();

      const sourceIndex = modules
         .map((i, j) => (shouldRenderModule(i) ? j : null))
         .filter((i) => i !== null)[result.source.index];

      if (!result.destination || sourceIndex === null) {
         return;
      }
      const destinationIndex = mapping[result.destination.index];

      if (destinationIndex === null || destinationIndex === sourceIndex) {
         return;
      }

      const updatedModules = _.cloneDeep([...modules]);

      const [removed] = updatedModules.splice(sourceIndex, 1);

      updatedModules.splice(destinationIndex, 0, removed);
      indexDeep(updatedModules);
      if (isParent(removed.id)) {
         const subModules = _.remove(updatedModules, (i) => i.parentModuleId === removed.id);
         let parentIndex = updatedModules.findIndex((i) => i.id === removed.id);
         subModules.forEach((subModule) => {
            updatedModules.splice(parentIndex + 1, 0, subModule);
            parentIndex++;
         });
         indexDeep(updatedModules);
      } else {
         const potentialParentId = getParentId(removed, updatedModules);
         if (potentialParentId && expandedModuleIds.includes(potentialParentId)) {
            removed.parentModuleId = potentialParentId;
         } else {
            removed.parentModuleId = null; // no parent
         }
      }
      await updateModuleChanges(updatedModules);
   };

   const getParentId = (module: Module, mods: readonly Module[]): Maybe<number> => {
      if (module.index <= 0) {
         return null;
      }

      // Previous module has same ParentId or isParent
      if (module.parentModuleId) {
         const previousModule = mods[module.index - 1];
         if (
            previousModule.id === module.parentModuleId ||
            previousModule.parentModuleId === module.parentModuleId
         ) {
            return module.parentModuleId;
         }
      }

      // Next Module has ParentId that isn't mod.id
      if (module.index < mods.length - 1) {
         const nextModule = mods[module.index + 1];
         if (nextModule.parentModuleId && nextModule.parentModuleId !== module.id) {
            return nextModule.parentModuleId;
         }
      }

      return null;
   };

   const updateModuleChanges = async (updatedModules: readonly Module[]): Promise<void> => {
      setModules(updatedModules);
      const data = {
         modules: updatedModules.map((i) => ({
            id: i.id,
            index: i.index,
            parentModuleId: i.parentModuleId,
         })),
      };
      await HttpService.patchWithAuthToken<MessageResponse>(
         `/api/courses/${courseId}/modules`,
         snakeCaseKeys(data),
      );
   };

   const moveModuleContentFromDndResult = async (result: DropResult): Promise<void> => {
      const [draggedType, _draggedId] = splitDraggableId(result.draggableId);
      if (!result.combine) {
         return;
      }
      const [targetType, targetModuleId] = splitDraggableId(result.combine.draggableId);
      if (
         draggedType !== ModuleDroppableId.ModuleContent ||
         targetType !== ModuleDroppableId.ModuleList
      ) {
         return;
      }

      // Skip attempts to move an item to it's existing module
      if (targetModuleId === activeModuleId) {
         return;
      }

      const updatedModules = _.cloneDeep(modules);
      const targetModule = updatedModules.find((i) => i.id === targetModuleId);
      const activeModule = updatedModules.find((i) => i.id === activeModuleId);
      if (!targetModule || !activeModule?.items) {
         return;
      }
      const targetItemCount = targetModule.itemCount;
      if (targetItemCount === undefined) {
         return;
      }
      const draggedModuleItems = activeModule.items
         .filter((i) => draggingItemIds.includes(i.id))
         .map((i, j) => ({ ...i, index: targetItemCount + j }));

      // Can't drop stuff in uneditable modules
      if (
         !canEditModules &&
         targetModule.courseOverrideAllowEdit &&
         activeModule.courseOverrideAllowEdit
      ) {
         return;
      }

      // update the current module
      activeModule.items = activeModule.items
         .filter((i) => !draggingItemIds.includes(i.id))
         .map((i, j) => ({ ...i, index: j }));
      activeModule.itemCount = getItemCount(activeModule);

      // then update the target update
      if (targetModule.items) {
         targetModule.items = [...targetModule.items, ...draggedModuleItems];
      }
      if (targetModule.itemCount !== undefined) {
         targetModule.itemCount += draggedModuleItems.length;
      }
      setModules(updatedModules);

      const activeModuleUrl = `/api/courses/${courseId}/modules/${activeModuleId}/items`;
      const activeModuleData = [
         ...activeModule.items.map((i) => ({
            id: i.id,
            index: i.index,
            moduleId: activeModuleId,
         })),
         ...draggedModuleItems.map((i) => ({
            id: i.id,
            index: i.index,
            moduleId: targetModuleId,
         })),
      ];
      await HttpService.patchWithAuthToken<MessageResponse>(
         activeModuleUrl,
         snakeCaseKeys({ items: activeModuleData }),
      );
   };

   const renderImportModuleButton = (): React.ReactNode => {
      const label = 'Import Modules';
      const noModules = !moduleListItems.length;
      const button = (
         <Button
            className='import-modules-button'
            fullWidth={noModules}
            icon={<IconDataUpload8 aria-hidden />}
            line
            onClick={openImportModulesModal}
         >
            {!moduleListItems.length && label}
         </Button>
      );
      if (noModules) {
         return button;
      } else {
         return (
            <span className='padding-left-s'>
               <Tippy content={label} offset={[0, 20]}>
                  <span>{button}</span>
               </Tippy>
            </span>
         );
      }
   };

   const getItemCount = (module: Module): number => {
      let count = module?.items?.length ?? module.itemCount ?? 0;
      if (count !== undefined) {
         count += modules.filter((i) => i.parentModuleId === module.id).length;
      }
      return count;
   };

   const toggleExpanded = (moduleId: number): void => {
      if (!isParent(moduleId)) {
         return;
      } else if (expandedModuleIds.includes(moduleId)) {
         collapseModule(moduleId);
      } else {
         expandModule(moduleId);
      }
   };

   const getDraggableModuleListItemStyle = (
      style: Maybe<React.CSSProperties>,
      snapshot: DraggableStateSnapshot,
   ): React.CSSProperties => {
      if (!style) {
         return {};
      } else if (!canCombineOnly) {
         return style;
      } else if (!snapshot.isDragging) {
         return {};
      } else if (!snapshot.isDropAnimating) {
         return style;
      } else {
         return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: '0.001s',
         };
      }
   };

   const renderDroppablePlaceHolder = (provided: DroppableProvided): React.ReactNode => {
      if (canCombineOnly) {
         return <>{provided.placeholder}</>;
      } else {
         return <div style={{ display: 'none' }}>{provided.placeholder}</div>;
      }
   };

   const renderModuleListItem = (
      moduleItem: Module,
      snapshot?: DraggableStateSnapshot,
   ): React.ReactNode => (
      <ModuleListItem
         canEditModules={canEditModules}
         courseId={courseId}
         isActive={activeModuleId === moduleItem.id}
         isDropTarget={isDropTarget(moduleItem, snapshot)}
         isExpanded={expandedModuleIds.includes(moduleItem.id)}
         isParent={isParent(moduleItem.id)}
         isSubmodule={isSubmodule(moduleItem.id)}
         itemCount={getItemCount(moduleItem)}
         key={moduleItem.id}
         moduleId={moduleItem.id}
         name={moduleItem.name}
         status={moduleItem.status}
         toggleExpanded={() => toggleExpanded(moduleItem.id)}
      />
   );

   const moduleListItems = modules.filter(shouldRenderModule).map((module, i) => {
      if (canEditModules) {
         return (
            <Draggable
               draggableId={`${ModuleDroppableId.ModuleList}-${module.id}`}
               index={i}
               key={module.id}
            >
               {(provided, snapshot) => (
                  <div
                     onMouseDown={(e) => e.currentTarget.focus()}
                     ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     className='module-list-item-drag-wrapper'
                     style={getDraggableModuleListItemStyle(
                        provided.draggableProps.style,
                        snapshot,
                     )}
                  >
                     {renderModuleListItem(module, snapshot)}
                  </div>
               )}
            </Draggable>
         );
      } else {
         return renderModuleListItem(module);
      }
   });

   return (
      <div className='module-item-col margin-bottom-m-md'>
         {canEditModules && (
            <div className='add-modules-section'>
               {moduleListItems.length > 0 && (
                  <Link
                     className={classnames(
                        'btn line full-width',
                        getOnboardingClassName?.(AssignActivityStep.addModule),
                     )}
                     data-tour='add-module-button'
                     to={newModule.replace(':courseId', courseId.toString())}
                  >
                     <IconAddSmall aria-hidden />
                     Add new module
                  </Link>
               )}
               {renderImportModuleButton()}
            </div>
         )}
         <div
            className={classnames('module-item-container', {
               'can-combine': canDropOnModule,
               'view-only': !canEditModules,
            })}
         >
            {canEditModules ? (
               <Droppable
                  droppableId={ModuleDroppableId.ModuleList}
                  isCombineEnabled={canDropOnModule}
               >
                  {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps}>
                        {moduleListItems}
                        {renderDroppablePlaceHolder(provided)}
                     </div>
                  )}
               </Droppable>
            ) : (
               moduleListItems
            )}
         </div>
      </div>
   );
};

export default ModuleList;
