import * as React from 'react';

interface LogicDebuggerProps {
   variables: Record<string, boolean | string | number>;
}

const LogicDebugger: React.FC<LogicDebuggerProps> = ({ variables }) => (
   <div className='logic-debugger card'>
      <div className='card-title'>
         <div className='title'>Logic Debugger</div>
      </div>
      <ul>
         {Object.entries(variables).map(([k, v]) => (
            <li key={k}>
               {k}: {v ?? 'None'}
            </li>
         ))}
      </ul>
   </div>
);

export default LogicDebugger;
