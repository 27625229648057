// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
const stringToB64 = (str: string): string => {
   const escapedString = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1: string) =>
      String.fromCharCode(parseInt(p1, 16)),
   );
   return window.btoa(escapedString);
};

const b64ToString = (b64String: string): string => {
   const percentEncodedString = window
      .atob(b64String)
      .split('')
      .map((char) => {
         const charCode = `00${char.charCodeAt(0).toString(16)}`;
         return `%${charCode.slice(-2)}`;
      })
      .join('');
   return decodeURIComponent(percentEncodedString);
};

export default {
   stringToB64,
   b64ToString,
};
