import { Maybe } from '@models/Core';

import { ModuleItem } from './ModuleItem';

export enum ModuleStatus {
   draft = 'draft',
   published = 'published',
}

export interface DefaultAssignmentSettings {
   defaultAssignmentAllowLateSubmission: boolean;
   defaultAssignmentAttemptsAllowed: number;
   defaultAssignmentEndDate: Date;
   defaultAssignmentGradeAutomatically: boolean;
   defaultAssignmentStartDate: Date;
   defaultAssignmentTimeLimit: number;
}
export interface ModuleCourseOverridePermissions {
   courseOverrideAllowEdit: boolean;
   courseOverrideAllowPreviewContent: boolean;
   courseOverrideAllowGrade: boolean;
}

export interface ModuleSummary {
   id: number;
   name: string;
   parentModuleId: Maybe<number>;
}

export interface ModuleProfile {
   id: number;
   name: string;
   index: number;
   itemCount?: number;
   showInGradebook?: boolean;
   status: ModuleStatus;
}

export interface Module
   extends ModuleProfile,
      ModuleSummary,
      DefaultAssignmentSettings,
      ModuleCourseOverridePermissions {
   courseId: number;
   description: string;
   items?: readonly ModuleItem[];
}
