import { Maybe } from './Core';
import { Language } from './Language';

// Taken from Supported Languages section of https://sonix.ai/docs/api
export const SonixLanguageConstants = [
   'en',
   'fr',
   'de',
   'es',
   'es-en',
   'ar',
   'hy-AM',
   'bg',
   'ca',
   'hr',
   'yue-Hant-HK',
   'cmn-Hans-CN',
   'cs',
   'da',
   'nl',
   'fi',
   'el',
   'he-IL',
   'hi',
   'hu',
   'id-ID',
   'it',
   'ja',
   'ko',
   'lv',
   'lt',
   'ms-MY',
   'nb-NO',
   'pl',
   'pt',
   'ro',
   'ru',
   'sk',
   'sl',
   'sv',
   'th-TH',
   'tr-TR',
   'uk',
   'vi-VN',
] as const;

export type SonixLanguage = (typeof SonixLanguageConstants)[number];

// We can only support the common languages between what we have listed and what Sonix had listed as what they support.
export type SupportedSonixLanguages = SonixLanguage & Language;

export const SupportedSonixLanguageLookup: Record<SupportedSonixLanguages, string> = {
   ar: 'Arabic',
   de: 'German',
   en: 'English',
   es: 'Spanish',
   fr: 'French',
   it: 'Italian',
   ja: 'Japanese',
   ko: 'Korean',
   pt: 'Portuguese',
   ru: 'Russian',
   el: 'Greek',
};

export const isSupportedSonixLanguage = (
   maybeLanguage: Maybe<string>,
): maybeLanguage is SupportedSonixLanguages =>
   !!maybeLanguage && SonixLanguageConstants.includes(maybeLanguage as SupportedSonixLanguages);

// If we wish to support audio in the future we need to implement that.
type MediaType = 'video';

export type NewMediaRequestInput = {
   mediaId: number;
   mediaType: MediaType;
};

export type FetchTranscriptsErrorResponse = {
   error: string;
   native_vtt_string: string;
   target_vtt_string: string;
};

export const isFetchTranscriptsErrorResponse = (
   r: Maybe<FetchTranscriptsErrorResponse | unknown>,
): r is FetchTranscriptsErrorResponse => {
   const possibleFetchTranscriptsErrorResponse = r as FetchTranscriptsErrorResponse;

   return (
      possibleFetchTranscriptsErrorResponse &&
      possibleFetchTranscriptsErrorResponse.error !== undefined &&
      possibleFetchTranscriptsErrorResponse.native_vtt_string !== undefined &&
      possibleFetchTranscriptsErrorResponse.target_vtt_string !== undefined
   );
};
