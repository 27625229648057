import * as React from 'react';

import Switch from '@components/Common/Switch';
import { ModuleItemVideo } from '@models/Course/ModuleItem';
import { LanguageLookup } from '@models/Language';

import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';

interface VideoDetailsProps {
   canEditModule: boolean;
   item: ModuleItemVideo;
   editItem(update: Partial<ModuleItemVideo>): void;
}

const VideoDetails: React.FC<VideoDetailsProps> = ({ canEditModule, item, editItem }) => {
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked, name } = event.target;
      editItem({ [name]: checked });
   };

   if (!item.itemLanguage) {
      return;
   }

   return (
      <ModuleItemSettingsAccordion title='Video Details'>
         <label className='field-title'>Enable Subtitles</label>
         <div className='col-xs-12'>
            <Switch
               checked={item.enableTargetSubtitles}
               name='enableTargetSubtitles'
               onChange={handleChange}
               disabled={!canEditModule}
               title={LanguageLookup[item.itemLanguage]}
            />
         </div>
         <div className='col-xs-12'>
            <Switch
               checked={item.enableNativeSubtitles}
               name='enableNativeSubtitles'
               onChange={handleChange}
               disabled={!canEditModule}
               title={LanguageLookup['en']}
            />
         </div>
      </ModuleItemSettingsAccordion>
   );
};

export default VideoDetails;
