import * as _ from 'lodash';
import * as React from 'react';

import moment from 'moment';
import {
   Bar,
   BarChart,
   CartesianGrid,
   Legend,
   ResponsiveContainer,
   Tooltip,
   TooltipProps,
   XAxis,
   YAxis,
} from 'recharts';

import { VocabStat } from './VocabStats';

interface VocabSessionsCompletedChartProps {
   data: readonly VocabStat[];
   startDate?: Date;
   onBarClick?(data: VocabStat, index: number): void;
}

const VocabSessionsCompletedChart: React.FC<VocabSessionsCompletedChartProps> = ({
   data,
   onBarClick = () => null,
}) => {
   const sessionTypes: Record<string, string> = {
      learn: 'Learn',
      review: 'Review',
      speedReview: 'Speed Review',
   };

   const formatLegend = (value: string, _entry: unknown, _index: number): string =>
      sessionTypes[value];

   const formatXAxis = (_value: string, index: number): string => {
      const entry = data[index];
      if (entry !== undefined) {
         return moment(entry.day).format('MMM D').toUpperCase();
      }
      return '';
   };

   const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
      if (active && payload) {
         const { day, ...sessions } = payload[0].payload;
         const total = _.sum(Object.values(sessions));
         return (
            <div className='card day-summary'>
               <div className='title small'>{moment(day).format('ddd MMM D')}</div>
               {Object.entries(sessions).map(([sessionType, count]) => (
                  <div key={sessionType}>
                     {sessionTypes[sessionType]}: {count as number}
                  </div>
               ))}
               <div className='total'>Total: {total}</div>
            </div>
         );
      }
      return null;
   };

   return (
      <ResponsiveContainer>
         <BarChart
            width={500}
            height={300}
            data={[...data]}
            margin={{
               top: 20,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
               tickFormatter={formatXAxis}
               stroke='#EEEEEE'
               domain={['auto', 'auto']}
               tick={{
                  fill: '#9C9BA1',
                  fontFamily: 'Brandon',
                  fontSize: '12px',
               }}
            />
            <YAxis
               type='number'
               tickLine={false}
               stroke='#EEEEEE'
               tick={{
                  fill: '#9C9BA1',
                  fontFamily: 'Brandon',
                  fontSize: '12px',
               }}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ fill: '#E7E7E7' }} />
            <Legend formatter={formatLegend} />
            <Bar dataKey='learn' stackId='a' fill='#4A7D35' onClick={onBarClick} />
            <Bar dataKey='review' stackId='a' fill='#007CBB' onClick={onBarClick} />
            <Bar dataKey='speedReview' stackId='a' fill='#CD1D1D' onClick={onBarClick} />
         </BarChart>
      </ResponsiveContainer>
   );
};

export default React.memo(VocabSessionsCompletedChart);
