import * as React from 'react';

import IconClose from '@icons/nova-solid/02-Status/close.svg';
import IconSpaceRocket from '@icons/nova-solid/66-Space/space-rocket.svg';
import classnames from 'classnames';

import { AppStateContext } from '../../AppState';
import { IOnboardingProps, IOnboardingTask } from './Models';
import { OnboardingContext } from './OnboardingContext';
import onboardingTasks from './OnboardingTasks';

const Checklist: React.FC = () => {
   const { checklistOpen, completedOnboardingTasks, setChecklistOpen, setTask } =
      React.useContext<IOnboardingProps>(OnboardingContext);

   const isComplete = (task: IOnboardingTask): boolean =>
      completedOnboardingTasks.includes(task.id);

   const nextIndex = onboardingTasks.findIndex((i) => !isComplete(i));
   const incompleteTasks = onboardingTasks.length - completedOnboardingTasks.length;

   const { userProfile } = React.useContext(AppStateContext);

   React.useEffect(() => {
      if (userProfile?.isAdmin) {
         setChecklistOpen(false);
      } else if (incompleteTasks) {
         setChecklistOpen(true);
      }
   }, []);

   const handleStartTask = (task: IOnboardingTask): void => setTask(task);

   const toggleIsOpen = (): void => setChecklistOpen((prevIsOpen) => !prevIsOpen);

   const handleTaskClick = (event: React.MouseEvent<HTMLElement>, task: IOnboardingTask): void => {
      if (!isComplete(task) || task.repeatable) {
         handleStartTask(task);
      }
   };

   if (!incompleteTasks) {
      return null;
   }

   return (
      <>
         {checklistOpen && (
            <div className='onboarding-checklist'>
               <div className='card'>
                  <div className='card-title'>
                     <div className='title'>Getting Started 🎉</div>
                     <div className='close'>
                        <IconClose onClick={toggleIsOpen} />
                     </div>
                  </div>
                  <ul>
                     {onboardingTasks.map((task, i) => (
                        <li
                           className={classnames({
                              complete: isComplete(task),
                              repeatable: task.repeatable,
                           })}
                           key={task.id}
                           onClick={(e) => handleTaskClick(e, task)}
                        >
                           {isComplete(task) && !task.repeatable ? (
                              task.title
                           ) : (
                              <span className='pointer'>{task.title}</span>
                           )}
                           <div className='check-wrapper'>
                              <div className='check' />
                              {i === nextIndex && <div className='pulse' />}
                           </div>
                        </li>
                     ))}
                  </ul>
               </div>
            </div>
         )}
         <div className='onboarding-checklist-launcher' onClick={toggleIsOpen}>
            {!!incompleteTasks && <div className='badge dark-red'>{incompleteTasks}</div>}
            <IconSpaceRocket />
         </div>
      </>
   );
};

export default Checklist;
