import * as _ from 'lodash';
import * as React from 'react';

import UserService from '@services/UserService';
import Autosuggest, {
   OnSuggestionSelected,
   RenderSuggestion,
   SuggestionsFetchRequestedParams,
} from 'react-autosuggest';

import { UserTypeaheadFragment } from '@generated/gql/graphql';
import { AppStateContext } from '../AppState';
import Avatar from './Core/Avatar';

interface UserAutoSuggestProps {
   inputProps?: Pick<
      React.InputHTMLAttributes<HTMLElement>,
      'autoFocus' | 'disabled' | 'placeholder'
   >;
   maxDisplayed?: number;
   onUserSelection: OnSuggestionSelected<UserTypeaheadFragment>;
   theme?: Record<string, string>;
}

const defaultTheme = {
   container: 'user-autosuggest-search-container',
   input: 'user-autosuggest-search-light',
   suggestionsContainer: 'user-autosuggest-suggestions-container',
   suggestionsList: 'user-autosuggest-suggestions-list',
   suggestion: 'user-autosuggest-suggestion',
   suggestionFirst: 'user-autosuggest-suggestion--first',
   suggestionHighlighted: 'user-autosuggest-suggestion--highlighted',
};

const UserAutoSuggest: React.FC<UserAutoSuggestProps> = ({
   inputProps = {
      autoFocus: false,
      disabled: false,
      placeholder: "Type a user's name or email (5 character minimum)",
   },
   maxDisplayed = 7,
   onUserSelection,
   theme = defaultTheme,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [inputValue, setInputValue] = React.useState<string>('');
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const [suggestions, setSuggestions] = React.useState<UserTypeaheadFragment[]>([]);

   const onChange = (
      _event: React.FormEvent<HTMLElement>,
      { newValue }: Autosuggest.ChangeEvent,
   ): void => {
      setInputValue(newValue);
   };

   const loadSuggestions = (query: string): Promise<void> => {
      if (!isFetching && query.length) {
         setIsFetching(true);
         return UserService.getUsersTypeAhead(query).then((response) => {
            if (response) {
               setSuggestions(response.filter((i) => parseInt(i.id) !== userProfile?.id));
               setIsFetching(false);
            }
         });
      }
      return Promise.resolve();
   };

   // Autosuggest will call this function every time you need to update suggestions.
   const onSuggestionsFetchRequested = (params: SuggestionsFetchRequestedParams): void => {
      loadSuggestions(params.value.trim());
   };

   const debouncedOnSuggestionsFetchRequested = React.useCallback(
      _.debounce(onSuggestionsFetchRequested, 500),
      [],
   );

   const onSuggestionsClearRequested = (): void => {
      setSuggestions([]);
   };

   const renderSuggestion: RenderSuggestion<UserTypeaheadFragment> = ({
      firstName,
      lastName,
      email,
      id,
      profileImageUrl,
   }): React.ReactElement => (
      <div className='user-suggestion'>
         <Avatar
            firstName={firstName}
            lastName={lastName}
            src={profileImageUrl}
            hashValue={parseInt(id)}
            size='small'
         />
         {`${firstName} ${lastName} (${email})`}
      </div>
   );

   return (
      <Autosuggest<UserTypeaheadFragment>
         alwaysRenderSuggestions
         getSuggestionValue={(suggestion) => `${suggestion.firstName} ${suggestion.lastName}`}
         highlightFirstSuggestion
         onSuggestionsClearRequested={onSuggestionsClearRequested}
         onSuggestionSelected={onUserSelection}
         onSuggestionsFetchRequested={debouncedOnSuggestionsFetchRequested}
         renderSuggestion={renderSuggestion}
         suggestions={suggestions.slice(0, maxDisplayed)}
         theme={theme}
         inputProps={{
            autoFocus: inputProps.autoFocus,
            className: inputProps.disabled ? `${theme.input} disabled` : theme.input,
            disabled: inputProps.disabled,
            onChange,
            placeholder: inputProps.placeholder || "Type a user's name or email",
            type: 'search',
            value: inputValue,
         }}
      />
   );
};

export default UserAutoSuggest;
