import * as React from 'react';

import Button from '@components/Common/Button';
import { SectionCard } from '@components/Common/SectionCard';
import Switch from '@components/Common/Switch';
import TagsInput from '@components/Core/TagsInput';
import { OrganizationInput, OrganizationProfileFragment } from '@generated/gql/graphql';
import { upsertOrganizationMutation } from '@graphql/queries';
import { zodResolver } from '@hookform/resolvers/zod';
import Appearance from '@models/Appearance';
import Toast from '@models/Toast';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { z } from 'zod';

import Constants from '../../../Constants';

interface Props {
   organization?: OrganizationProfileFragment;
   dispatchToast(toast: Toast): void;
}

const OrganizationInputSchema = z.object({
   name: z.string().default(''),
   shortName: z.string().default(''),
   city: z.string().default(''),
   state: z.string().default(''),
   zipCode: z.string().default(''),
   domains: z.array(z.string()).default([]),
   domainRestricted: z.boolean().default(false),
});

const SchoolInformationPanel: React.FC<Props> = (props) => {
   const {
      routes: {
         schools: { schoolDetails },
      },
   } = Constants;

   const navigate = useNavigate();

   const defaultValues = OrganizationInputSchema.parse({
      ...props.organization,
      domains: props.organization?.domains.map((domain) => domain.domain),
      shortName: props.organization?.shortName ?? '',
      city: props.organization?.city ?? '',
      state: props.organization?.state ?? '',
      zipCode: props.organization?.zipCode ?? '',
   });

   const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      register,
      reset,
   } = useForm<OrganizationInput>({
      resolver: zodResolver(OrganizationInputSchema),
      defaultValues,
   });

   React.useEffect(() => {
      reset(defaultValues);
   }, [props.organization]);

   const [{ fetching }, executeCreateOrUpdateOrganizationMutation] = useMutation(
      upsertOrganizationMutation,
   );

   const onSubmit = async (organizationInput: OrganizationInput): Promise<void> => {
      const idOrNone: string | undefined = props.organization?.id;

      const organizationUpdateResponse = await executeCreateOrUpdateOrganizationMutation({
         organizationId: idOrNone,
         organizationInput,
      });

      if (organizationUpdateResponse.error) {
         props.dispatchToast({
            message: 'Error updating organization',
            appearance: Appearance.danger,
         });
      } else {
         props.dispatchToast({
            message: 'Organization updated',
            appearance: Appearance.success,
         });
         if (!props.organization && organizationUpdateResponse.data?.organization?.id) {
            navigate(
               schoolDetails.replace(
                  ':organizationId',
                  organizationUpdateResponse.data.organization.id,
               ),
            );
         }
         reset(organizationInput);
      }

      return;
   };

   const handleEvent = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
   ): Promise<void> => {
      handleSubmit(onSubmit)(event);
   };

   return (
      <SectionCard
         headerButton={
            <Button
               appearance={Appearance.primary}
               className='margin-left-auto'
               disabled={!isDirty || fetching}
               onClick={handleEvent}
               type='submit'
            >
               {props.organization ? 'Save' : 'Create'}
            </Button>
         }
         title='Organization Information'
      >
         <form>
            <div className='row'>
               <div className='col-xs-12 col-sm-8'>
                  <label className='field-title'>Name</label>
                  <input
                     {...register('name')}
                     type='text'
                     className={classNames({ error: !!errors.name })}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>Short Name</label>
                  <input
                     {...register('shortName')}
                     type='text'
                     className={classNames({ error: !!errors.shortName })}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>City</label>
                  <input
                     {...register('city')}
                     type='text'
                     className={classNames({ error: !!errors.city })}
                  />
               </div>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>State</label>
                  <input
                     {...register('state')}
                     type='text'
                     className={classNames({ error: !!errors.state })}
                  />
               </div>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>Zip Code</label>
                  <input
                     {...register('zipCode')}
                     type='text'
                     className={classNames({ error: !!errors.zipCode })}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-9'>
                  <label className='field-title'>Email Domains</label>
                  <Controller
                     name='domains'
                     control={control}
                     render={({ field: { ref, value, ...rest } }) => (
                        <div className='margin-top-s'>
                           <TagsInput
                              inputProps={{ placeholder: 'Add a domain' }}
                              {...{ ...rest, ref }}
                              value={value ?? []}
                           />
                        </div>
                     )}
                  />
               </div>
               <div className='col-xs-3'>
                  <label className='field-title'>Domain Restricted</label>
                  <Controller
                     name='domainRestricted'
                     control={control}
                     render={({ field: { ref, value, ...rest } }) => (
                        <Switch {...rest} checked={value} />
                     )}
                  />
               </div>
            </div>
         </form>
      </SectionCard>
   );
};

export default SchoolInformationPanel;
