import * as React from 'react';

import { ensureElement } from '@helpers/utils';
import sanitizeHtml from 'sanitize-html';

interface EmptyStateProps {
   /** Title that briefly describes the page to the user. */
   heading?: string;
   /** The main block of text that holds some additional information. */
   description?: React.ReactNode;
   /** Icon that will be shown above the title. */
   icon?: React.ReactNode;
   /** Primary action button for the page, usually it will be something like "Create" (or "Retry" for error pages). */
   primaryAction?: React.ReactNode;
   /** Secondary action button for the page. */
   secondaryAction?: React.ReactNode;
   /** Button with link to some external resource like documentation or tutorial, it will be opened in a new tab. */
   tertiaryAction?: React.ReactNode;
   className?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
   heading,
   description,
   icon,
   primaryAction,
   secondaryAction,
   tertiaryAction,
   className,
}) => {
   const actionsContainer =
      primaryAction || secondaryAction ? (
         <div className='actions-container'>
            {primaryAction}
            {secondaryAction}
         </div>
      ) : null;

   let parsedDesription = description;
   if (typeof description === 'string') {
      const sanitizedHTML = sanitizeHtml(description);
      parsedDesription = (
         <div className='margin-s' style={{ maxWidth: '320px' }}>
            <div className='small gray' dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
         </div>
      );
   }

   return (
      <div className={`empty-state ${className || ''}`}>
         {icon && <div className='empty-state-visual'>{icon}</div>}
         {heading && <div className='title medium'>{heading}</div>}
         {typeof description === 'string' && parsedDesription}
         {typeof description !== 'string' &&
            ensureElement(description, 'p', { className: 'small gray' })}
         {actionsContainer}
         {tertiaryAction}
      </div>
   );
};

export default EmptyState;
