import * as React from 'react';

import { ActivityBuilderMode, ActivityPrompt } from '@models/Activity';
import { Maybe } from '@models/Core';

import Constants from '../../Constants';

interface WeightInputProps {
   weight: string;
   onUpdate(update: Partial<ActivityPrompt<ActivityBuilderMode>>, callback?: () => void): void;
   dataTest?: string;
}

const WeightInput: React.FC<WeightInputProps> = ({ weight, onUpdate, dataTest }) => {
   const [value, setValue] = React.useState<string>(weight);
   const weightInput = React.useRef<Maybe<HTMLInputElement>>(null);

   const isInvalid = (): boolean => isNaN(Number(value)) || isNaN(parseFloat(value));

   const handleBlur = (): void => {
      if (isInvalid()) {
         setValue(value ? weight : '0.0');
         return;
      }
      const formattedWeight = parseFloat(parseFloat(value).toPrecision(10))
         .toFixed(4)
         .replace(/0{0,3}$/, '');
      setValue(formattedWeight);
      onUpdate({ weight: formattedWeight });
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(event.target.value.replace(/\s/g, ''));
   };

   const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const { enter, escape } = Constants.keys;
      if ([enter, escape].includes(event.key)) {
         weightInput.current?.blur();
      }
   };

   return (
      <div className='weight'>
         <input
            type='text'
            name='weight'
            value={value}
            ref={(r) => (weightInput.current = r)}
            className={isInvalid() ? 'invalid' : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            data-test={dataTest}
         />
         <label>pts</label>
      </div>
   );
};

export default WeightInput;
