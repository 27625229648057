import * as React from 'react';

import { SectionCard } from '@components/Common/SectionCard';
import { formatDateConcise } from '@helpers/FormatTime';
import { NationalExam } from '@models/NationalExam';
import DateTime from '@services/DateTimeService';

type Props = {
   exam: NationalExam;
};
const RegistrationClosed: React.FC<Props> = ({ exam }) => {
   const regIsPast = exam ? DateTime.momentNow().isAfter(exam.regEndDate) : null;

   return (
      <>
         <SectionCard title='Registration is currently closed'>
            <div className='row'>
               <div className='col-xs-12'>
                  <p>
                     Registration for the {exam.name} {regIsPast ? 'was' : 'is'} open between{' '}
                     {formatDateConcise(exam.regStartDate)} and {formatDateConcise(exam.regEndDate)}
                     .
                  </p>
               </div>
            </div>
         </SectionCard>
      </>
   );
};

export default RegistrationClosed;
