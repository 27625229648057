import * as _ from 'lodash';

import ExtendedSchoolProfile from '@components/AdminSchoolsTable/ExtendedSchoolProfile';
import { SortByEntry } from '@components/Common/Table';
import { encodePredicate } from '@components/PredicateEditor/Utils';
import EncodingUtils from '@helpers/EncodingUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import SchoolDetails from '@models/SchoolDetails';
import SchoolProfile from '@models/SchoolProfile';

import HttpService, { HttpServiceRequestOptions } from './HttpService';

interface SchoolSearchFilters {
   query?: string;
   domain?: string;
   ssoOnly?: boolean;
}

export const isSchoolProfile = (school: unknown): school is SchoolProfile =>
   _.isObject(school) &&
   _.isNumber(_.get(school, 'id')) &&
   _.isString(_.get(school, 'name')) &&
   _.isString(_.get(school, 'city')) &&
   _.isString(_.get(school, 'state')) &&
   _.isString(_.get(school, 'zipCode'));

const getSchools = (
   { predicate, ...rest }: PagedSearchFilters,
   sortOrder?: readonly SortByEntry[],
   options?: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<ExtendedSchoolProfile>> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(rest));
   if (predicate) {
      encodedQuery.set('predicate', encodePredicate(predicate));
   }
   if (sortOrder) {
      const str = JSON.stringify(sortOrder);
      encodedQuery.set('order_by', EncodingUtils.stringToB64(str));
   }
   return HttpService.getWithAuthToken<PagedResponse<ExtendedSchoolProfile>>(
      `/api/admin/schools?${encodedQuery}`,
      options,
   ).then((response) => response.data);
};

const getSchoolDetails = (organizationId: number | string): Promise<SchoolDetails> =>
   HttpService.getWithAuthToken<SchoolDetails>(`/api/organizations/${organizationId}/details`).then(
      (response) => response.data,
   );

const deleteSchool = (organizationId: number): Promise<void> =>
   HttpService.deleteWithAuthToken<null>(`/api/organizations/${organizationId}`).then();

const schoolSearch = (filters: SchoolSearchFilters): Promise<readonly SchoolProfile[]> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(filters));
   return HttpService.get<{ schools: readonly SchoolProfile[] }>(
      `/api/organizations/search?${encodedQuery}`,
   ).then((response) => response.data.schools);
};

const isEmailValid = (organizationId: number, email: string): Promise<boolean> =>
   HttpService.post<{ valid: boolean }>(
      `/api/organizations/${organizationId}/email_valid`,
      snakeCaseKeys({ email }),
   ).then((response) => response.data.valid);

const getNoSchoolFound = (): Promise<SchoolProfile> =>
   HttpService.get<SchoolProfile>('/api/organizations/not_found').then((response) => response.data);

export default {
   getSchools,
   getSchoolDetails,
   deleteSchool,
   schoolSearch,
   getNoSchoolFound,
   isEmailValid,
};
