import * as React from 'react';

import Appearance from '@models/Appearance';

import Button from '@components/Common/Button';
import { IAction } from './Modal';

interface FooterProps {
   /** Buttons to render in the footer */
   actions?: readonly IAction[];
   /** Appearance of the primary action and header.  */
   appearance?: Appearance;
   /** Class name for the modal footer */
   className?: string;
   /** Component to render the footer of the moda.l */
   component?: React.ReactElement;
}

const Footer: React.FC<FooterProps> = ({
   actions = [],
   appearance = 'primary',
   className,
   component,
}) => {
   if (!component && !actions) {
      return null;
   } else if (component && actions && actions.length > 0) {
      console.error('You can provide `component` OR `actions`, not both.');
      return null;
   } else if (component) {
      return component;
   }

   return (
      <div className={className || 'card-footer text-center no-margin-top'}>
         {actions.map(({ text, ...rest }, idx) => (
            <Button
               line={idx !== 0}
               className={idx ? '' : `margin-right-m ${appearance}`}
               data-test={`modal-${text}`}
               key={idx}
               {...rest}
            >
               {text}
            </Button>
         ))}
      </div>
   );
};

export default Footer;
