import DateTime from '@services/DateTimeService';
import moment from 'moment';

// FIXME: abstract this for session and local storage as needed
const rootKeyName = 'expiringStorage';

interface BrowserStorageItem {
   key: string;
   value: string;
   expiresAt: string;
}

const removeExpiredItems = (): void => {
   const allValues = loadAllValues();
   let deletedCount = 0;
   for (const key in allValues) {
      const item = allValues[key];
      if (item.expiresAt && moment(item.expiresAt).isBefore(DateTime.momentNow())) {
         delete allValues[key];
         deletedCount++;
      }
   }

   if (deletedCount) {
      saveAllValues(allValues);
   }
};

const loadAllValues = (): Record<string, BrowserStorageItem> => {
   try {
      const allValues = sessionStorage.getItem(rootKeyName);
      return allValues ? JSON.parse(allValues) : {};
   } catch (error) {
      console.warn('Could not load storage values:', error);
      return {};
   }
};

const saveAllValues = (allValues: Record<string, BrowserStorageItem>): void => {
   try {
      sessionStorage.setItem(rootKeyName, JSON.stringify(allValues));
   } catch (error) {
      console.warn('Could not save storage values:', error);
   }
};

const setString = (key: string, value: string, lifetimeInSeconds: number): void => {
   const allValues = loadAllValues();
   const expiresAt = DateTime.momentNow().add(lifetimeInSeconds, 'seconds').toISOString();
   allValues[key] = { key, value, expiresAt } as BrowserStorageItem;
   saveAllValues(allValues);
};

const getString = (key: string, clear?: boolean): string => {
   const allValues = loadAllValues();
   const value = allValues[key]?.value;

   if (clear) {
      deleteString(key);
   }

   return value;
};

const deleteString = (key: string): void => {
   const allValues = loadAllValues();
   delete allValues[key];
   saveAllValues(allValues);
};

// every 30 seconds we'll remove any expired keys
setInterval(removeExpiredItems, 30 * 1000);

const BrowserStorage = {
   setString,
   getString,
   deleteString,
};

export default BrowserStorage;
