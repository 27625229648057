import * as React from 'react';

import ModuleItemService from '@services/ModuleItemService';
import { useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../AppState';
import Loader from './Loader';

const ViewModuleItem: React.FC = () => {
   const navigate = useNavigate();
   const { courseId, moduleItemId } = useParams<{
      courseId: string;
      moduleItemId: string;
   }>();

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   React.useEffect(() => {
      if (!moduleItemId || !courseId || !userProfile?.accountType) {
         return;
      }
      const intModuleItemId = Number(moduleItemId);
      const intCourseId = Number(courseId);
      ModuleItemService.getRedirectLink(intCourseId, intModuleItemId, userProfile.accountType).then(
         (link) => {
            navigate(link ? link : '/404');
         },
      );
   }, [courseId, moduleItemId, userProfile?.accountType]);

   return <Loader />;
};

export default ViewModuleItem;
