import * as React from 'react';

import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import { IAction } from '@components/Core/ModalDialog/Modal';
import IconVoteStar from '@icons/nova-solid/19-Vote&Rewards/vote-star.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { CourseEnrollment, isEnrollment, RosterRecord } from '@models/Course';
import CourseEnrollmentService from '@services/CourseEnrollmentService';
import pluralize from 'pluralize';

interface MissingEmail {
   fullName: string;
   lmsUserId: string;
   lmsConnectionId: number;
}

export interface LMSSyncResult {
   added: readonly string[];
   missingEmails: readonly MissingEmail[];
   status: string;
   toRemove: readonly string[];
}

interface LMSSyncResultModalProps {
   courseId: number;
   isRemovingStudents: boolean;
   lmsName: string;
   roster: readonly RosterRecord[];
   syncResult: LMSSyncResult;
   handleRemoveStudents(): void;
   onClose(): void;
   onRosterUpdate?(updates: readonly Partial<RosterRecord>[]): void;
}

const LMSSyncResultModal: React.FC<LMSSyncResultModalProps> = ({ courseId, ...props }) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const [rosterUpdates, setRosterUpdates] = React.useState<readonly Partial<RosterRecord>[]>([]);

   const isStudentWithoutLMSId = (row: RosterRecord): boolean =>
      row.accountType === AccountType.student && isEnrollment(row) && row.lmsConnectionId === null;

   const addCount = props.syncResult.added.length;
   const removeCount = props.syncResult.toRemove.length;
   const missingCount = props.syncResult.missingEmails.length;

   const handleRosterSave = (): void => {
      setIsSaving(true);
      CourseEnrollmentService.updateRoster(courseId, rosterUpdates).then(() => {
         setIsSaving(false);
         props.onRosterUpdate?.(rosterUpdates);
         props.onClose();
      });
   };

   const onStudentSelected = (
      event: React.ChangeEvent<HTMLSelectElement>,
      missingEmail: MissingEmail,
   ): void => {
      const enrollmentId = event.target.value;
      if (enrollmentId) {
         const enrollmentIdNumber = Number(enrollmentId);
         setRosterUpdates((prevRosterUpdates) => [
            ...prevRosterUpdates.filter(
               (i) =>
                  Object.prototype.hasOwnProperty.call(i, 'lmsUserId') &&
                  (i as Partial<CourseEnrollment>).lmsUserId !== missingEmail.lmsUserId,
            ), // A single enrollment id should only be in the list one time.
            {
               enrollmentId: enrollmentIdNumber,
               ...missingEmail,
            },
         ]);
      } else {
         setRosterUpdates((prevRosterUpdates) =>
            prevRosterUpdates.filter(
               (i) =>
                  Object.prototype.hasOwnProperty.call(i, 'lmsUserId') &&
                  (i as Partial<CourseEnrollment>) !== missingEmail.lmsUserId,
            ),
         );
      }
   };

   const studentsWithoutLMSUserId = props.roster.filter(isStudentWithoutLMSId).filter(isEnrollment);

   const getModalAppearance = (): Appearance.primary | Appearance.danger => {
      if (missingCount === 0 && removeCount > 0) {
         return Appearance.danger;
      }

      return Appearance.primary;
   };

   const getActions = (): readonly IAction[] => {
      if (missingCount > 0) {
         return [
            {
               loading: isSaving,
               text: 'Save',
               onClick: handleRosterSave,
            },
            {
               text: 'Close',
               onClick: props.onClose,
            },
         ];
      }
      if (removeCount > 0) {
         return [
            {
               loading: props.isRemovingStudents,
               text: `Remove ${removeCount} ${pluralize('student', removeCount)}`,
               onClick: props.handleRemoveStudents,
            },
            {
               text: 'Close',
               onClick: props.onClose,
            },
         ];
      }

      return [
         {
            text: 'Close',
            onClick: props.onClose,
         },
      ];
   };

   return (
      <ModalDialog
         appearance={getModalAppearance()}
         heading='LMS Sync Result'
         onClose={props.onClose}
         bodyClassName='lms-sync-modal-body'
         // footerClassName="lms-sync-modal card-footer"
         actions={getActions()}
      >
         <div className='content-row padding-top-s'>
            {!!addCount && (
               <div className='row bottom-xs'>
                  <div className='col-xs-12'>
                     <div className='title'>{`Added ${addCount} ${pluralize(
                        'student',
                        addCount,
                     )}`}</div>
                     <ol className='wrapped-list small-text'>
                        {props.syncResult.added.map((i) => {
                           const invite = props.roster.find((j) => j.email === i);
                           if (invite) {
                              return (
                                 <li
                                    key={invite.email}
                                 >{`${invite.firstName} ${invite.lastName} (${invite.email})`}</li>
                              );
                           }
                        })}
                     </ol>
                  </div>
               </div>
            )}
            {props.syncResult.missingEmails.length > 0 && (
               <div className='row bottom-xs'>
                  <div className='col-xs-12'>
                     <div className='title'>
                        {`Unable to automatically add ${missingCount} ${pluralize(
                           'student',
                           missingCount,
                        )}.`}
                     </div>
                     <p>
                        {`If the following ${pluralize('student', missingCount)} ${pluralize(
                           'is',
                           missingCount,
                        )} already enrolled in your course, you can match them.  Otherwise they will need to be added using another method.`}
                     </p>
                     <div className='flex user-updates-header'>
                        <div className='flex-1'>
                           <div className='title'>{props.lmsName} Roster Entry</div>
                        </div>
                        <div className='flex-1'>
                           <div className='title'>Lingco Roster Entry</div>
                        </div>
                     </div>
                     <ol className='user-updates-list'>
                        {props.syncResult.missingEmails.map((missingEmailEntry) => (
                           <div className='flex items-center' key={missingEmailEntry.lmsUserId}>
                              <li className='flex-1'>{`${missingEmailEntry.fullName}`}</li>
                              <select
                                 className='flex-1'
                                 onChange={(e) => onStudentSelected(e, missingEmailEntry)}
                              >
                                 <option value=''>Select</option>
                                 {studentsWithoutLMSUserId.map((enrollment) => {
                                    const currentSelection = rosterUpdates.filter(
                                       (i) =>
                                          Object.prototype.hasOwnProperty.call(i, 'enrollmentId') &&
                                          (i as Partial<CourseEnrollment>).enrollmentId ===
                                             enrollment.enrollmentId,
                                    );
                                    const wasSelected = currentSelection.length > 0;
                                    const wasSelectedForThisMissingEmail =
                                       wasSelected &&
                                       (currentSelection[0] as Partial<CourseEnrollment>)
                                          .lmsUserId === missingEmailEntry.lmsUserId;
                                    if (!wasSelected || wasSelectedForThisMissingEmail) {
                                       return (
                                          <option
                                             key={enrollment.enrollmentId}
                                             value={enrollment.enrollmentId}
                                          >
                                             {`${enrollment.firstName} ${enrollment.lastName}`}
                                          </option>
                                       );
                                    }
                                    return null;
                                 })}
                              </select>
                           </div>
                        ))}
                     </ol>
                  </div>
               </div>
            )}
            {removeCount > 0 && props.syncResult.missingEmails.length === 0 && (
               <div className='row bottom-xs'>
                  <div className='col-xs-12'>
                     <div className='title'>{`Found ${removeCount} ${pluralize(
                        'student',
                        removeCount,
                     )} to remove`}</div>
                     <ol className='wrapped-list'>
                        {props.syncResult.toRemove.map((i) => {
                           const rosterEntry = props.roster.find(
                              (j) => j.email.toLowerCase() === i.toLowerCase(),
                           );
                           if (rosterEntry) {
                              return (
                                 <li key={rosterEntry.email}>
                                    {`${rosterEntry.firstName} ${rosterEntry.lastName}`} (
                                    {rosterEntry.email})
                                 </li>
                              );
                           }
                        })}
                     </ol>
                  </div>
               </div>
            )}
            {!addCount && !removeCount && !missingCount && (
               <div className='row'>
                  <div className='col-xs-12'>
                     <EmptyState
                        icon={<IconVoteStar className='large' aria-hidden />}
                        heading='Roster is up to date!'
                     />
                  </div>
               </div>
            )}
         </div>
      </ModalDialog>
   );
};

export default LMSSyncResultModal;
