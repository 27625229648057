import * as React from 'react';

import { SectionCard } from '@components/Common/SectionCard';
import { ProgressBar } from '@components/Core/ProgressBar';
import Loader from '@components/Loader';
import NationalExamAdminService, { ExamSummary } from '@services/NationalExamAdminService';
import Tippy from '@tippyjs/react';

import EmptyState from '@components/Core/EmptyState';

interface ExamProgressPanelProps {
   examId: number | string;
   hasCachedResults: boolean;
}

const ExamProgressPanel: React.FC<ExamProgressPanelProps> = (props) => {
   const [examSummary, setExamSummary] = React.useState<ExamSummary>();

   React.useEffect(() => {
      NationalExamAdminService.getExamSummary(props.examId).then((data) => {
         setExamSummary(data);
      });
   }, [props.examId]);

   if (!props.hasCachedResults) {
      return (
         <SectionCard title='Exam Progress'>
            <EmptyState
               heading='Results not cached'
               description='Currently this exam is not being cached. This does not mean we do not have the data. If you want to view exam progress please reach out to us.'
            />
         </SectionCard>
      );
   }

   if (!examSummary) {
      return (
         <SectionCard title='Exam Progress'>
            <Loader />;
         </SectionCard>
      );
   }

   return (
      <SectionCard title='Exam Progress'>
         <div style={{ padding: '20px' }}>
            <span className='flex flex-col'>
               {examSummary.totalSeatsOrdered > 0 ? (
                  <>
                     <ProgressBar
                        color='blue'
                        className=''
                        percentage={
                           (examSummary.totalExamsTaken / examSummary.totalSeatsOrdered) * 100
                        }
                     />
                     <Tippy content={'total exams taken / total seats ordered'}>
                        <label style={{ width: 'fit-content' }}>
                           {examSummary.totalExamsTaken.toLocaleString()}/
                           {examSummary.totalSeatsOrdered.toLocaleString()}
                        </label>
                     </Tippy>
                  </>
               ) : (
                  <EmptyState
                     heading='No Seats Ordered'
                     description='We do not show that any seats have been ordered for this exam yet.'
                  />
               )}
            </span>
         </div>
      </SectionCard>
   );
};

export default ExamProgressPanel;
