import { Maybe } from '@models/Core';
import Language from '@models/Language';
import LMSName from '@models/LMSName';
import { ProficiencyCanDoStatementDetailed, ProficiencyLevelName } from '@models/Proficiency';
import SchoolProfile from '@models/SchoolProfile';

import { CourseLMSConnection } from './CourseLMSConnection';

export type ProficiencyGoals = {
   listeningGoalLevel?: ProficiencyLevelName;
   listeningEntryLevel?: ProficiencyLevelName;
   readingEntryLevel?: ProficiencyLevelName;
   readingGoalLevel?: ProficiencyLevelName;
   speakingEntryLevel?: ProficiencyLevelName;
   speakingGoalLevel?: ProficiencyLevelName;
   writingEntryLevel?: ProficiencyLevelName;
   writingGoalLevel?: ProficiencyLevelName;
};

export enum CourseMode {
   create = 'create',
   edit = 'edit',
   view = 'view',
}

export interface GradingCategory {
   id: number;
   name: string;
   courseId?: number;
   weight?: number;
   show?: boolean;
}

export type CourseSectionSummary = {
   id: number;
   name: string;
};

export interface CourseSection<Mode extends CourseMode = CourseMode.view> {
   id: Mode extends CourseMode.view ? number : string | number;
   courseCode?: string;
   defaultDueTime?: Maybe<Date>;
   enrolledCount?: number;
   name: string;
   show?: boolean;
}

export interface BasicCourseProfile {
   archived?: boolean;
   demo: boolean;
   id: number;
   name: string;
   needsToPurchaseLicense: boolean;
   trialEndOn: Maybe<Date>;
}

export interface CourseProfile {
   archived: boolean;
   demo: boolean;
   endDate: Date;
   id: number;
   name: string;
   startDate: Date;
}

export interface Course<Mode extends CourseMode = CourseMode.view> {
   archived: boolean;
   canLeaveCourse?: boolean;
   canEdit: boolean;
   courseCode: Maybe<string>;
   createdOn?: Date;
   defaultDueTime: Date;
   defaultPrice?: number;
   demo: boolean;
   description: string;
   endDate: Date;
   isOrganizationStandard: boolean;
   isSharedWithOrganization: boolean;
   isProtected: boolean;
   shouldHideStudentGradebook?: boolean;
   shouldHideInstructorGradebook?: boolean;
   id: Mode extends CourseMode.create ? null : number;
   language: Mode extends CourseMode.create ? Maybe<Language> : Language;
   lmsConnections: readonly CourseLMSConnection[];
   lmsInfo?: Mode extends CourseMode.create
      ? undefined
      : {
           domain: string;
           lms: LMSName;
           shouldAllowLtiAppInstallation: boolean;
        };
   clonedFromId?: number;
   isClonedCourse: boolean;
   isNationalExamPractice: boolean;
   levelId?: number;
   ltiSecretKey?: string;
   name: string;
   price?: number;
   priceOverride?: Maybe<number>;
   school?: Mode extends CourseMode.create ? undefined : SchoolProfile;
   sections: readonly CourseSection<Mode>[];
   instructorSections?: readonly CourseSection<Mode>[];
   showVocabStats?: boolean;
   startDate: Date;
   template?: boolean;
   proficiencyGoals?: ProficiencyGoals;
}

export interface CourseInfo {
   id: number;
   archived: boolean;
   startDate: Date;
   endDate: Date;
   demo: boolean;
   name: string;
   defaultDueTime: Date;
   language: Language;
   gradingCategories: readonly GradingCategory[];
   sections: readonly CourseSection[];
   lmsConnections: readonly CourseLMSConnection[];
   canDoStatements: readonly ProficiencyCanDoStatementDetailed[];
}
