import { IdName } from '@models/Core';
import { CountryName } from '@models/Country';
import { LandingPageContent } from '@models/LandingPageContent';
import {
   FullNationalExam,
   NationalExamCourseSearchRow,
   NationalExamReportLinks,
} from '@models/NationalExam';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';

import BasicUserProfile from '@models/BasicUserProfile';
import AxiosService from './AxiosService';

const BASE_API = '/api/national_exam_admin';

export type GetExamsResponse = {
   exams: readonly IdName[];
};

export type GetExamCoursesParams = {
   nationalExamId: number;
   hostInstructorName?: string;
   hostInstructorEmail?: string;
   hostSchoolName?: string;
};

export type ExamSummary = {
   totalExamsTaken: number;
   totalSeatsOrdered: number;
};

export type ExamScoreSummary = {
   id: string;
   studentId: number;
   isDisqualified: boolean;
   studentEmail: string;
   studentFirstName: string;
   studentLastName: string;
   levelId: number;
   levelName: string;
   instructorId: number;
   instructorFirstName: string;
   instructorLastName: string;
   instructorEmail: string;
   chapterId: number;
   chapterName: string;
   schoolType: string;
   schoolName: string;
   schoolAddress: string;
   schoolCity: string;
   schoolState: string;
   schoolZip: number;
   schoolCountry?: CountryName;
   examId: number;
   courseId: number;
   categoryName: string;
   totalScore: number;
   totalWeight: number;
   totalPercentage: number;
   activityArray: unknown;
};

export type GetExamReportsResponse = {
   reports: NationalExamReportLinks[];
};

export type MessageIdResponse = {
   messageId: string;
};

export type NationalExamCourseSummary = {
   id: number;
   categories: string[];
   courseId: number;
   courseName: string;
   isPractice: boolean | null;
   levelName: string | null;
   levelSortOrder: number | null;
   requiresRegistration: boolean | null;
   shouldEnforceCategoryAssignment: boolean | null;
};

type GetExamResponse = {
   exam: FullNationalExam;
   examCourses: NationalExamCourseSummary[];
   landingPageContent: LandingPageContent;
   examAdmins: BasicUserProfile[];
};

const getExam = (examId: number | string): Promise<GetExamResponse> =>
   AxiosService.get<GetExamResponse>(`${BASE_API}/national_exams/${examId}`).then(
      (response) => response.data,
   );

const getNationalExams = (): Promise<readonly IdName[]> =>
   AxiosService.get<GetExamsResponse>(`${BASE_API}/national_exams`).then(
      (response) => response.data.exams,
   );

const getExamCourses = (
   params: GetExamCoursesParams,
): Promise<PagedResponse<NationalExamCourseSearchRow>> =>
   AxiosService.get<PagedResponse<NationalExamCourseSearchRow>>(
      `${BASE_API}/national_exams/${params.nationalExamId}/courses`,
      {
         params: {
            ...params,
         },
      },
   ).then((response) => response.data);

const getExamSummary = (nationalExamId: number | string): Promise<ExamSummary> =>
   AxiosService.get<ExamSummary>(`${BASE_API}/national_exams/${nationalExamId}/summary`).then(
      (response) => response.data,
   );

const searchExamScoreSummaries = (
   nationalExamId: number,
   filters: PagedSearchFilters,
): Promise<PagedResponse<ExamScoreSummary>> =>
   AxiosService.get<PagedResponse<ExamScoreSummary>>(
      `${BASE_API}/national_exams/${nationalExamId}/summaries`,
      {
         params: {
            ...filters,
         },
      },
   ).then((response) => response.data);

const getGeneratedReports = (nationalExamId: number | string): Promise<NationalExamReportLinks[]> =>
   AxiosService.get<GetExamReportsResponse>(
      `${BASE_API}/national_exams/${nationalExamId}/reports`,
   ).then((response) => response.data.reports);

const generateExamScoreSummariesReport = (examId: number | string): Promise<string> =>
   AxiosService.post<MessageIdResponse>(
      `${BASE_API}/national_exams/${examId}/exam_score_summaries`,
   ).then((response) => response.data.messageId);

const getCheaterReport = (examId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/national_exams/${examId}/cheater_report`, {
      downloadFile: true,
   }).then((response) => response.data);

const getInstructorList = (examId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/national_exams/${examId}/instructor_list`, {
      downloadFile: true,
   }).then((response) => response.data);

const getAllOrders = (examId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/national_exams/${examId}/all_orders`, {
      downloadFile: true,
   }).then((response) => response.data);

const generateQuestionnaireForExamCourse = (
   courseId: number | string,
): Promise<MessageIdResponse> =>
   AxiosService.get<MessageIdResponse>(
      `${BASE_API}/national_exam_course/${courseId}/questionnaire_results`,
   ).then((response) => response.data);

export default {
   generateExamScoreSummariesReport,
   getCheaterReport,
   getExam,
   getExamCourses,
   getAllOrders,
   getExamSummary,
   getGeneratedReports,
   getInstructorList,
   getNationalExams,
   generateQuestionnaireForExamCourse,
   searchExamScoreSummaries,
};
