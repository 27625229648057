import * as React from 'react';

import useStateWithReset from '@hooks/use-state-with-reset';
import IconCogBox from '@icons/nova-line/03-Settings/cog-box.svg';
import { Maybe } from '@models/Core';
import { DefaultAssignmentSettings, Module, ModuleStatus } from '@models/Course';
import classnames from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Editor as TinyMCEEditor } from 'tinymce';

import Constants from '../../../Constants';
import AccentTextbox from '@components/AccentTextbox';
import Button from '@components/Common/Button';
import { Editor } from '@components/Core/Editor';
import InfoTooltip from '@components/InfoTooltip';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { AssignActivityStep } from '@components/Onboarding/Walkthroughs/AssignActivity';
import AssignmentDefaultsModal from './AssignmentDefaultsModal';
import { LanguageConsumer } from './Modules';
import { getModuleMode, ModuleMode } from './ModuleUtils';

interface ModuleEditorProps {
   assignmentDefaults: DefaultAssignmentSettings;
   courseEndDate: Date;
   courseStartDate: Date;
   description: string;
   isLoading: boolean;
   itemCount: number;
   moduleCount: number;
   name: string;
   status: ModuleStatus;
   handleCreate(update: Partial<Module>): void;
   handleDelete(): void;
   handleSave(update: Pick<Module, 'name' | 'description' | 'status'>): void;
   updateAssignmentDefaults(
      update: DefaultAssignmentSettings,
      overwriteAssignments?: boolean,
   ): void;
}

const ModuleEditor: React.FC<ModuleEditorProps> = ({
   assignmentDefaults: propsAssignmentDefaults,
   courseEndDate,
   courseStartDate,
   description: propsDescription,
   isLoading,
   itemCount,
   moduleCount,
   name: propsName,
   status: propsStatus,
   handleCreate,
   handleDelete,
   handleSave,
   updateAssignmentDefaults,
}) => {
   const {
      routes: {
         courses: { modules: modulesRoute, viewModule },
      },
   } = Constants;
   const { courseId, moduleId } = useParams<{
      courseId: string;
      moduleId: string;
   }>();
   const location = useLocation();
   const navigate = useNavigate();
   const mode = getModuleMode(location.pathname);

   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [name, setName] = useStateWithReset<string>(propsName, [mode]);
   const [description, setDescription] = useStateWithReset<string>(propsDescription, [mode]);
   const [status, setStatus] = useStateWithReset<ModuleStatus>(propsStatus, [mode]);
   const [showBlankNameError, setShowBlankNameError] = React.useState<boolean>(false);
   const [assignmentDefaults, setAssignmentDefaults] =
      useStateWithReset<DefaultAssignmentSettings>(propsAssignmentDefaults);
   const [assignmentDefaultsModalOpen, setAssignmentDefaultsModalOpen] =
      React.useState<boolean>(false);
   const [isEditorLoaded, setIsEditorLoaded] = React.useState<boolean>(false);

   const editorRef = React.useRef<Maybe<TinyMCEEditor>>(null);

   React.useEffect(() => {
      if (name.length > 0) {
         setShowBlankNameError(false);
      }
   }, [name]);

   React.useEffect(() => {
      if (mode === ModuleMode.create && isEditorLoaded) {
         editorRef.current?.resetContent();
      }
   }, [mode, isEditorLoaded]);

   const handleSaveClick = (): void => {
      if (!name.length) {
         setShowBlankNameError(true);
      } else if (mode === ModuleMode.create) {
         handleCreate({
            name,
            status,
            description,
            index: moduleCount,
            ...assignmentDefaults,
         });
      } else if (mode === ModuleMode.edit) {
         handleSave({ name, status, description });
      }
   };

   const handleCancel = (): void => {
      if (mode === ModuleMode.edit && moduleId && courseId) {
         navigate(
            viewModule
               .replace(':courseId', courseId.toString())
               .replace(':moduleId', moduleId.toString()),
            { replace: true },
         );
      } else if (mode === ModuleMode.create && courseId) {
         navigate(modulesRoute.replace(':courseId', courseId.toString()), {
            replace: true,
         });
      }
   };

   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setName(event.target.value);
   };

   const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setStatus(event.target.value as ModuleStatus);
   };

   const handleDescriptionChange = (updatedDescription: string): void => {
      setDescription(updatedDescription);
   };

   const handleDefaultAssignmentModalSave = (
      updatedDefaults: DefaultAssignmentSettings,
      overwriteAssignments: boolean,
   ): void => {
      closeAssignmentDefaultsModal();
      if (mode === ModuleMode.edit) {
         updateAssignmentDefaults(updatedDefaults, overwriteAssignments);
         if (name.length) {
            handleSave({ name, status, description, ...updatedDefaults });
         }
      } else if (mode === ModuleMode.create) {
         setAssignmentDefaults(updatedDefaults);
      }
   };

   const openAssignmentDefaultsModal = (): void => {
      setAssignmentDefaultsModalOpen(true);
   };

   const closeAssignmentDefaultsModal = (): void => {
      setAssignmentDefaultsModalOpen(false);
   };

   const canCancel = moduleCount > 1 || mode === ModuleMode.edit;

   return (
      <>
         <div className='card inner margin-bottom-m'>
            <div className='card-title has-button full-width'>
               <LanguageConsumer>
                  {(language) => (
                     <AccentTextbox
                        autoFocus
                        className={classnames('title-text', 'mousetrap', {
                           error: showBlankNameError,
                        })}
                        data-test='module-name'
                        data-tour='module-name'
                        language={language}
                        name='name'
                        onChange={handleNameChange}
                        placeholder='Untitled Module'
                        value={name}
                     />
                  )}
               </LanguageConsumer>
               <div className='right-options-wrapper'>
                  {canCancel && (
                     <Button line onClick={handleCancel}>
                        Cancel
                     </Button>
                  )}
                  {mode === ModuleMode.edit && (
                     <Button color='dark-red' onClick={handleDelete}>
                        Delete
                     </Button>
                  )}
                  <Button
                     className={classnames(
                        getOnboardingClassName?.(AssignActivityStep.createModule),
                     )}
                     onClick={handleSaveClick}
                     loading={isLoading}
                     keyboardShortcut='mod+enter'
                     data-test='create-module-btn'
                     data-tour='primary-module-btn'
                  >
                     {mode === ModuleMode.create ? 'Create' : 'Save'}
                  </Button>
               </div>
            </div>
            <div className='row'>
               <div className='col xs-12 col-sm-2'>
                  <label className='field-title'>
                     Status
                     <InfoTooltip>
                        Modules set as draft will not be visible to the student and will be excluded
                        from the gradebook
                     </InfoTooltip>
                  </label>
                  <select name='status' onChange={handleStatusChange} value={status}>
                     <option value={ModuleStatus.published}>Published</option>
                     <option value={ModuleStatus.draft}>Draft</option>
                  </select>
               </div>
               <div className='col xs-12 col-sm-3'>
                  <div className='assignment-defaults-wrapper'>
                     <Button
                        icon={<IconCogBox aria-hidden />}
                        onClick={openAssignmentDefaultsModal}
                        subtle
                     >
                        Assignment Defaults
                     </Button>
                  </div>
               </div>
            </div>
            <div className='row margin-top-s' data-tour='module-description'>
               <div className='col-xs-12'>
                  <label className='field-title'>Description</label>
                  <LanguageConsumer>
                     {(language) => (
                        <Editor
                           className='module-description-editor'
                           editorRef={(editor) => (editorRef.current = editor)}
                           onChange={handleDescriptionChange}
                           onInit={() => setIsEditorLoaded(true)}
                           rtl={language === 'ar'}
                           value={description}
                        />
                     )}
                  </LanguageConsumer>
               </div>
            </div>
         </div>
         {assignmentDefaultsModalOpen && (
            <AssignmentDefaultsModal
               allowOverwrite={mode === ModuleMode.edit && itemCount > 0}
               assignmentDefaults={assignmentDefaults}
               courseEndDate={courseEndDate}
               courseStartDate={courseStartDate}
               onClose={closeAssignmentDefaultsModal}
               onSave={handleDefaultAssignmentModalSave}
            />
         )}
      </>
   );
};

export default ModuleEditor;
