import * as React from 'react';

import DocumentTitle from '@components/DocumentTitle';

interface CardBodyProps {
   documentTitle?: string;
   cardTitle?: string;
   children: React.ReactNode;
}

const CardBody: React.FC<CardBodyProps> = (props) => (
   <>
      {props.documentTitle && <DocumentTitle>{props.documentTitle}</DocumentTitle>}
      <div className='content-main margin-right-m'>
         <div className='card bottom-padding no-padding'>
            {props.cardTitle && (
               <div className='card-title'>
                  <div className='title'>{props.cardTitle}</div>
               </div>
            )}
            {props.children}
         </div>
      </div>
   </>
);

export default CardBody;
