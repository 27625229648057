import * as React from 'react';

import Button from '@components/Common/Button';
import Toggle from '@components/Core/Toggle';
import IconContentViewList from '@icons/nova-line/18-Content/content-view-list.svg';
import IconContentViewGrid from '@icons/nova-line/18-Content/content-view-module-1.svg';
import IconVideoMap from '@icons/videos/icon-video-map.svg';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import Tippy from '@tippyjs/react';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';

interface ContentLibraryHeaderProps {
   canCreate: boolean;
   canImportActivityJSON: boolean;
   createNewDropdown: React.ReactNode;
   hasContent: boolean;
   isImporting: boolean;
   layoutView: ContentLibraryLayout;
   handleImportActivityJSON(event: React.ChangeEvent<HTMLInputElement>): void;
   toggleLayoutView(): void;
}
const ContentLibraryHeader: React.FC<ContentLibraryHeaderProps> = ({
   canCreate,
   canImportActivityJSON,
   createNewDropdown,
   hasContent,
   isImporting,
   layoutView,
   handleImportActivityJSON,
   toggleLayoutView,
}) => {
   const uploadRef = React.useRef<HTMLInputElement>(null);

   const {
      routes: {
         content: { viewVideoMap: videoMapRoute },
      },
   } = Constants;

   const navigate = useNavigate();

   return (
      <div className='content-library-header'>
         {hasContent && (
            <Toggle
               className='layout-view-toggle'
               leftLabelIcon={<IconContentViewGrid />}
               leftValue={ContentLibraryLayout.grid}
               rightLabelIcon={<IconContentViewList />}
               rightValue={ContentLibraryLayout.list}
               onChange={toggleLayoutView}
               value={layoutView}
            />
         )}
         <Tippy content='Go to video map'>
            <span>
               <Button
                  icon={<IconVideoMap aria-hidden />}
                  onClick={() => navigate(videoMapRoute)}
               />
            </span>
         </Tippy>
         {canCreate && hasContent && (
            <>
               {canImportActivityJSON && (
                  <>
                     <Button
                        color='yellow'
                        loading={isImporting}
                        className='margin-right-s'
                        onClick={() => uploadRef.current?.click()}
                     >
                        Import Activity
                     </Button>
                     <input
                        type='file'
                        accept='.json'
                        multiple
                        ref={uploadRef}
                        style={{ display: 'none' }}
                        onChange={handleImportActivityJSON}
                     />
                  </>
               )}
               {createNewDropdown}
            </>
         )}
      </div>
   );
};

export default ContentLibraryHeader;
