import * as React from 'react';

import Link from '@components/Common/Link';
import Blanket from '@components/Core/Blanket';
import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import IconMenuPlus from '@icons/general/icon-menu-plus.svg';
import IconContentBox2 from '@icons/nova-line/18-Content/content-box-2.svg';
import IconFolderNetwork from '@icons/nova-line/86-Files-Folders/folder-network.svg';
import AccountType from '@models/AccountType';
import classnames from 'classnames';
import { createPortal } from 'react-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';

interface ICourseDrawerProps {
   open: boolean;
   onClose(): void;
}

const CoursesDrawer: React.FC<ICourseDrawerProps> = ({ open, onClose }) => {
   const {
      routes: {
         courses: { dashboard, join, create },
      },
   } = Constants;
   const { currentCourses, archivedCourses, userProfile } =
      React.useContext<AppStateContext>(AppStateContext);
   const isInstructor = userProfile?.accountType === AccountType.instructor;

   if (!open) {
      return null;
   }

   return (
      <div className='product-side-menu-container' onClick={onClose} id='courses-drawer'>
         {createPortal(<Blanket canClickThrough onBlanketClicked={onClose} />, document.body)}
         <ul>
            <li>
               <div className='nav-item-wrapper'>
                  <IconFolderNetwork />
                  Current Courses
               </div>
               <ul className='list-sub-level'>
                  {currentCourses.map(({ id, name }) => (
                     <li key={id}>
                        <div className='nav-icon-wrap'>
                           <div className='nav-icon'>
                              <IconMenuLevel />
                           </div>
                        </div>
                        <div className='list-sub-level-item-wrap'>
                           <div className='list-sub-level-item'>
                              <Link to={dashboard.replace(':courseId', id.toString())}>{name}</Link>
                           </div>
                        </div>
                     </li>
                  ))}
                  {isInstructor && userProfile?.permissions.canManageCourses && (
                     <li className={classnames('blue', { disabled: !userProfile?.authenticated })}>
                        <div className='nav-icon-wrap'>
                           <div className='nav-icon icon-menu-plus'>
                              <IconMenuPlus />
                           </div>
                        </div>
                        <div className='list-sub-level-item-wrap'>
                           <div className='list-sub-level-item'>
                              <Link data-test='link-create-course' to={create}>
                                 Create Course
                              </Link>
                           </div>
                        </div>
                     </li>
                  )}
                  <li className='blue'>
                     <div className='nav-icon-wrap'>
                        <div className='nav-icon icon-menu-plus'>
                           <IconMenuPlus />
                        </div>
                     </div>
                     <div className='list-sub-level-item-wrap'>
                        <div className='list-sub-level-item'>
                           <Link data-test='join-course-link' to={join}>
                              Join Course
                           </Link>
                        </div>
                     </div>
                  </li>
               </ul>
            </li>
            {!!archivedCourses.length && (
               <li>
                  <div className='nav-item-wrapper'>
                     <IconContentBox2 />
                     Archived Courses
                  </div>
                  <ul className='list-sub-level'>
                     {archivedCourses.map(({ name, id }) => (
                        <li key={id}>
                           <div className='nav-icon-wrap'>
                              <div className='nav-icon'>
                                 <IconMenuLevel />
                              </div>
                           </div>
                           <div className='list-sub-level-item-wrap'>
                              <div className='list-sub-level-item'>
                                 <Link to={dashboard.replace(':courseId', id.toString())}>
                                    {name}
                                 </Link>
                              </div>
                           </div>
                        </li>
                     ))}
                  </ul>
               </li>
            )}
         </ul>
      </div>
   );
};

export default CoursesDrawer;
