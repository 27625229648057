import { Maybe } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import {
   Modes,
   ProficiencyCanDoStatementDetailed,
   ProficiencyCanDoStatementDetailedWithActivities,
   ProficiencyCanDoStatementFields,
   ProficiencyLevelName,
   ProficiencyThemes,
   Skill,
} from '@models/Proficiency';

import AxiosService from './AxiosService';

const filterableTableSearch = (
   filters: PagedSearchFilters,
): Promise<PagedResponse<ProficiencyCanDoStatementDetailedWithActivities>> =>
   AxiosService.get<PagedResponse<ProficiencyCanDoStatementDetailedWithActivities>>(
      '/api/can_do_statements/search_with_activities',
      {
         params: {
            ...filters,
         },
      },
   ).then((response) => response.data);

const search = (
   filters: PagedSearchFilters,
   mode?: Modes,
   level?: Maybe<ProficiencyLevelName>,
   skill?: Maybe<Skill>,
): Promise<PagedResponse<ProficiencyCanDoStatementDetailed>> =>
   AxiosService.get<PagedResponse<ProficiencyCanDoStatementDetailed>>(
      '/api/can_do_statements/search',
      {
         params: {
            ...filters,
            mode,
            level,
            skill,
         },
      },
   ).then((response) => response.data);

const getThemes = (): Promise<ProficiencyThemes> =>
   AxiosService.get<ProficiencyThemes>('/api/can_do_statements/themes').then(
      (response) => response.data,
   );

const create = (newStatement: ProficiencyCanDoStatementFields): Promise<void> =>
   AxiosService.post<void>('/api/can_do_statements', newStatement, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

const update = (
   id: number,
   updatedStatement: Partial<ProficiencyCanDoStatementFields>,
): Promise<void> =>
   AxiosService.patch<void>(`/api/can_do_statements/${id}`, updatedStatement, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

const remove = (id: number): Promise<void> =>
   AxiosService.delete<void>(`/api/can_do_statements/${id}`, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

export default {
   create,
   filterableTableSearch,
   getThemes,
   remove,
   search,
   update,
};
