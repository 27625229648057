/**
 * Get the median of an array
 */
export const getMedian = (arr: number[]): number | undefined => {
   if (!arr.length) return undefined;

   const sorted = [...arr].sort((a, b) => a - b);
   const middle = Math.floor(sorted.length / 2);

   if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
   }

   return sorted[middle];
};

const round = (value: number, precision: number): number => {
   const multiplier = Math.pow(10, precision || 0);
   return Math.round(value * multiplier) / multiplier;
};

export { round };
