import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import { Column } from '@components/Common/Table';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';
import DeleteAction from '@components/TableActions/DeleteAction';
import { randomShortId } from '@helpers/RandomStringUtils';
import InterviewQuestionService from '@services/InterviewQuestionService';
import { v4 as uuidv4 } from 'uuid';

import { InterviewQuestion, Translations } from '@models/Video';
import InterviewQuestionEditorModal from './InterviewQuestionEditorModal';

const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [],
};

const InterviewQuestionTable: React.FC = () => {
   const [isCreateModalOpen, setIsCreateModalOpen] = React.useState<boolean>(false);
   const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);
   const [selectedInterviewQuestion, setSelectedInterviewQuestion] =
      React.useState<InterviewQuestion>();

   const openUpdateModal = (interviewQuestion: InterviewQuestion): void => {
      setSelectedInterviewQuestion({ ...interviewQuestion });
      setIsCreateModalOpen(true);
   };

   const renderId = (interviewQuestion: InterviewQuestion): React.ReactNode => (
      <Button
         textLink
         className='black hover-blue'
         onClick={() => {
            openUpdateModal(interviewQuestion);
         }}
      >
         {interviewQuestion.id}
      </Button>
   );

   const onDelete = (interviewQuestionId: number): void => {
      InterviewQuestionService.remove(interviewQuestionId).then(() => {
         setShouldRefetch(true);
      });
   };

   const renderActions = (interviewQuestion: InterviewQuestion): React.ReactNode => (
      <DeleteAction
         onDelete={() => {
            onDelete(interviewQuestion.id);
         }}
         objectName={interviewQuestion.name}
      />
   );

   const renderTranslations = (translations: Translations): React.ReactNode =>
      Object.entries(translations).map((i) => (
         <div key={uuidv4()}>
            <span className='bold-text'>{i[0]}</span>: <span>{i[1]}</span>
         </div>
      ));

   const columns: readonly Column<InterviewQuestion>[] = [
      { id: 'id', header: 'Id', cell: renderId },
      { id: 'name', header: 'Statement', cell: (i) => i.name },
      {
         id: 'translations',
         header: 'Translations',
         cell: (i) => renderTranslations(i.translations),
      },

      { id: 'delete', header: 'Delete', cell: renderActions },
   ];

   const onSave = (): void => {
      setShouldRefetch(true);
      setIsCreateModalOpen(false);
   };

   const onAddRowClick = (): void => {
      setSelectedInterviewQuestion(undefined);
      setIsCreateModalOpen(true);
   };

   const handleCancel = (): void => {
      setIsCreateModalOpen(false);
   };

   const refetchComplete = (): void => {
      setShouldRefetch(false);
   };

   return (
      <>
         {isCreateModalOpen && (
            <InterviewQuestionEditorModal
               onCancel={handleCancel}
               onSave={onSave}
               selectedInterviewQuestion={selectedInterviewQuestion}
            />
         )}
         <FilterableTable<InterviewQuestion>
            attributes={[]}
            columns={columns}
            defaultPredicate={defaultPredicate}
            defaultSortOrder={[]}
            pageTitle='Interview Questions'
            searchInputPlaceholder='Search Interview Questions'
            rowsLoader={InterviewQuestionService.search}
            onAddRowClick={onAddRowClick}
            shouldRefetch={shouldRefetch}
            refetchComplete={refetchComplete}
         />
      </>
   );
};

export default InterviewQuestionTable;
