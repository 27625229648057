import * as _ from 'lodash';

import Language from '@models/Language';
import { SequenceMatcher } from 'difflib';

const scoreString = (response: string, term: string, language: Language): number => {
   const correctAnswers = [term];
   if (/\((.*?)\)/.test(term)) {
      correctAnswers.push(
         term.replace(/["()]/g, ''), // Remove parenthesis, keep inner
         term.replace(/\(.*?\)/g, ''), // Remove parenthesis and inner
      );
   }
   if (/’/.test(term)) {
      correctAnswers.push(term.replace(/’/, "'"));
   }
   const strict = !['de', 'es', 'fr', 'it', 'pt'].includes(language);
   if (language === 'ru') {
      const sanitizedResponse = stripAccents(response);
      const sanitizedTerm = stripAccents(term);
      return new SequenceMatcher(null, sanitizedResponse, sanitizedTerm).ratio();
   } else if (strict) {
      return new SequenceMatcher(null, response, term).ratio();
   } else {
      const sanitizedResponse = sanitizeString(response);
      const uniqueCorrectAnswers = [...new Set(correctAnswers)];
      return (
         _.max(
            uniqueCorrectAnswers.map((i) =>
               new SequenceMatcher(null, sanitizedResponse, sanitizeString(i)).ratio(),
            ),
         ) ?? 0
      );
   }
};

const stripAccents = (str: string): string => normalizeNFD(str).replace(/[\u0300-\u036f]/g, '');

const replace =
   (regex: RegExp, value: string) =>
   (str: string): string =>
      str.replace(regex, value);

const lowercase = (str: string): string => str.toLowerCase();

const trim = (str: string): string => str.trim();

const normalizeNFC = (str: string): string => str.normalize('NFC');

const normalizeNFD = (str: string): string => str.normalize('NFD');

const sanitizeString = _.flow([
   normalizeNFC,
   replace(/’/, "'"),
   replace(/[^a-zA-Z0-9 \u00C0-\u00FF\-']/g, ' '),
   replace(/\s\s+/g, ' '),
   lowercase,
   trim,
]);

const checkAccentError = (response: string, term: string, language: Language): boolean => {
   if (['de', 'es', 'fr', 'it', 'pt'].includes(language)) {
      return stripAccents(response) === stripAccents(term);
   }
   return false;
};

export { checkAccentError, scoreString };
