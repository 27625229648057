import * as React from 'react';
import { useParams } from 'react-router-dom';
import DocumentTitle from '@components/DocumentTitle';
import NationalExamService from '@services/NationalExamService';
import Loader from '@components/Loader';

type Params = {
   orderId: string;
};

const InvoiceDownloadPage: React.FC = () => {
   const { orderId } = useParams<Params>();
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   if (!orderId) {
      return null;
   }

   React.useEffect(() => {
      NationalExamService.downloadInvoice(parseInt(orderId)).then(() => {
         setIsLoading(false);
      });
   }, []);

   if (isLoading) {
      return <Loader />;
   }
   return (
      <>
         <div className='content-main margin-right-m'>
            <DocumentTitle>Download Invoice</DocumentTitle>
            <div className='card center col-lg-6 padding-l'>
               <div className='title large center-text margin-bottom-m'>Invoice downloaded</div>
               <div style={{ textAlign: 'center' }}>
                  <p className='small gray'>
                     Thank you for downloading the invoice for order #{orderId}. To download another
                     copy, please refresh this page.
                  </p>
               </div>
            </div>
         </div>
      </>
   );
};

export default InvoiceDownloadPage;
