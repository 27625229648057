import * as React from 'react';

import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import IconFolderNetwork from '@icons/nova-line/86-Files-Folders/folder-network.svg';
import { NationalExamAbbreviation } from '@models/NationalExam';
import { createPortal } from 'react-dom';

import { AppStateContext } from '../../../AppState';
import Constants, { AbbreviationExamNameRecord } from '../../../Constants';
import Link from '@components/Common/Link';
import Blanket from '@components/Core/Blanket';

interface IExamDrawerProps {
   open: boolean;
   onClose(): void;
}

const ExamsDrawer: React.FC<IExamDrawerProps> = ({ open, onClose }) => {
   const {
      routes: {
         nationalExam: { dashboard },
      },
   } = Constants;
   const { availableNationalExams } = React.useContext<AppStateContext>(AppStateContext);
   if (availableNationalExams) {
      const examsAvailableToUser = [...availableNationalExams];
      examsAvailableToUser.sort();
      if (!open) {
         return null;
      }
      return (
         <div className='product-side-menu-container' onClick={onClose} id='exams-drawer'>
            {createPortal(<Blanket canClickThrough onBlanketClicked={onClose} />, document.body)}
            <ul>
               <li>
                  <div className='nav-item-wrapper'>
                     <IconFolderNetwork />
                     National Exams
                  </div>
                  <ul className='list-sub-level'>
                     {examsAvailableToUser.map((examAbbr: NationalExamAbbreviation) => (
                        <li key={examAbbr}>
                           <div className='nav-icon-wrap'>
                              <div className='nav-icon'>
                                 <IconMenuLevel />
                              </div>
                           </div>
                           <div className='list-sub-level-item-wrap'>
                              <div className='list-sub-level-item'>
                                 <Link to={dashboard.replace(':examUrlSlug', examAbbr)}>
                                    {AbbreviationExamNameRecord[examAbbr]}
                                 </Link>
                              </div>
                           </div>
                        </li>
                     ))}
                  </ul>
               </li>
            </ul>
         </div>
      );
   } else {
      return null;
   }
};

export default ExamsDrawer;
