// @ts-strict-ignore
import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import classnames from 'classnames';

import AttributePickerOpener from './AttributePickerOpener';
import FilterBlockEditor from './FilterBlockEditor';
import LogicModeSelect from './LogicModeSelect';
import { OperatorType, Predicate, PredicateGroup } from './Models';
import { PredicateEditorContext } from './PredicatesEditor';
import { getNewPredicate, isPredicateGroup } from './Utils';

export interface FilterGroupEditorProps {
   predicate: Predicate | PredicateGroup;
   onEditorClose(predicateId: string): void;
   onEditorOpen(predicateId: string): void;
   onRemove(): void;
   onUpdate(predicate: Predicate | PredicateGroup);
}

const FilterGroupEditor: React.FC<FilterGroupEditorProps> = ({
   predicate: parentPredicate,
   onEditorClose,
   onEditorOpen,
   onRemove,
   onUpdate,
}) => {
   const { attributes, invalidFilterIds, orLimit } =
      React.useContext<PredicateEditorContext>(PredicateEditorContext);

   const [connectionSwitcherHovered, setConnectionSwitcherHovered] = React.useState<boolean>(false);

   const wrapped = isPredicateGroup(parentPredicate);
   const predicates = wrapped ? parentPredicate.predicates : [parentPredicate];
   const cannotSwitchToOr =
      parentPredicate.type === OperatorType.and && predicates.length > orLimit;
   const isAttributePickerDisabled = invalidFilterIds.length > 0;

   const addPredicate = (attribute: string): void => {
      if (wrapped) {
         onUpdate({
            ...parentPredicate,
            predicates: [...parentPredicate.predicates, getNewPredicate(attribute, attributes)],
         });
      } else {
         // need to wrap
         onUpdate({
            id: randomShortId(),
            predicates: [parentPredicate, getNewPredicate(attribute, attributes)],
            type: OperatorType.and,
         });
      }
   };

   const switchLogicalMode = (mode: OperatorType): void => {
      if (wrapped) {
         onUpdate({
            ...parentPredicate,
            type: mode,
         });
      }
   };

   const updatePredicate = (predicate: Predicate): void => {
      if (wrapped) {
         onUpdate({
            ...parentPredicate,
            predicates: parentPredicate.predicates.map((i) =>
               i.id === predicate.id ? { ...predicate } : i,
            ),
         });
      } else {
         onUpdate(predicate);
      }
   };

   const removePredicate = (predicate: Predicate | PredicateGroup): void => {
      if (wrapped) {
         const remainingPredicates = parentPredicate.predicates.filter(
            (i) => i.id !== predicate.id,
         );
         if (remainingPredicates.length > 1) {
            onUpdate({
               ...parentPredicate,
               predicates: remainingPredicates,
            });
         } else {
            // Unwrap predicate
            onUpdate(remainingPredicates[0]);
         }
      } else {
         onRemove();
      }
   };

   const getAddAttributeTooltip = (): string => {
      if (invalidFilterIds.length > 0) {
         return 'One of your filters is missing a value. Finish editing it before adding another.';
      } else if (parentPredicate.type === OperatorType.or && predicates.length === orLimit) {
         return `You can have up to ${orLimit} filters connected with 'or'. Remove a few, or switch to 'and'.`;
      } else if (predicates.length === 1) {
         return 'Create a filter group';
      } else {
         return 'Add filter to this group';
      }
   };

   const getLogicModeSelectTooltip = (): string => {
      if (cannotSwitchToOr) {
         return `You have too many filters to switch to 'or' (the limit is ${orLimit}). Remove a few first.`;
      }
      return null;
   };

   return (
      <span className='filter-group'>
         {predicates.map((predicate, predicateIndex) => (
            <React.Fragment key={predicate.id}>
               <FilterBlockEditor
                  onEditorClose={() => onEditorClose(predicate.id)}
                  onEditorOpen={() => onEditorOpen(predicate.id)}
                  onRemove={() => removePredicate(predicate)}
                  onUpdate={updatePredicate}
                  predicate={predicate as Predicate}
               />
               {wrapped && predicateIndex !== predicates.length - 1 && (
                  <LogicModeSelect
                     className={classnames({
                        'connection-switcher-hovered': connectionSwitcherHovered,
                     })}
                     disabled={cannotSwitchToOr}
                     onMouseEnter={() => setConnectionSwitcherHovered(true)}
                     onMouseLeave={() => setConnectionSwitcherHovered(false)}
                     onSelect={switchLogicalMode}
                     selected={parentPredicate.type}
                     tooltip={getLogicModeSelectTooltip()}
                  />
               )}
            </React.Fragment>
         ))}
         <AttributePickerOpener
            inline
            disabled={isAttributePickerDisabled}
            tooltip={getAddAttributeTooltip()}
            onAdd={addPredicate}
         />
      </span>
   );
};

export default FilterGroupEditor;
