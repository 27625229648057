import IUserInfo from '@models/IUserInfo';
import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { Maybe } from 'yup';

import { isProd } from './Config';
import { store } from './redux/Store';

const mixPanelActions = {
   identify: (id?: string): void => {
      try {
         if (isProd) mixpanel.identify(id);
      } catch (e) {
         console.error(e);
      }
   },
   alias: (alias: string, original?: string | undefined): void => {
      try {
         if (isProd) mixpanel.alias(alias, original);
      } catch (e) {
         console.error(e);
      }
   },
   track: (
      eventName: string,
      properties?: Dict,
      optionsOrCallback?: RequestOptions | Callback,
      callback?: Callback,
   ): void => {
      try {
         if (isProd) mixpanel.track(eventName, properties, optionsOrCallback, callback);
      } catch (e) {
         console.error(e);
      }
   },
   trackPageView: (userInfo: Maybe<IUserInfo>): void => {
      try {
         let user: Maybe<IUserInfo> = undefined;
         if (userInfo?.loggedIn) {
            user = userInfo;
         } else {
            // For some reason this user is undefined after login until the user refreshes the page
            // so Mixpanel is not getting user data on a fresh login properly. This accounts for that.
            user = store.getState().user;
         }
         if (user?.loggedIn && isProd) {
            // Add custom properties in support of ClientSuccess per
            // https://help.clientsuccess.com/hc/en-us/articles/15182681021837-MixPanel-Integration
            const properties = {
               identity: {
                  user: {
                     id: user.userProfile?.id,
                     email: user.userProfile?.email,
                     name: [user.userProfile?.firstName, user.userProfile?.lastName]
                        .filter(Boolean)
                        .join(' '),
                  },
                  organization: {
                     id: user.districtProfile?.id || user.schoolProfile?.id,
                     name: user.districtProfile?.name || user.schoolProfile?.name,
                  },
               },
            };
            mixpanel.track_pageview(properties);
         }
      } catch (e) {
         console.error(e);
      }
   },
   people: {
      set: (prop: Dict, callback?: Callback): void => {
         try {
            if (isProd) mixpanel.people.set(prop, callback);
         } catch (e) {
            console.error(e);
         }
      },
      setOnce: (prop: Dict, callback?: Callback): void => {
         try {
            if (isProd) mixpanel.people.set_once(prop, callback);
         } catch (e) {
            console.error(e);
         }
      },
   },
   reset: (): void => {
      try {
         if (isProd) mixpanel.reset();
      } catch (e) {
         console.error(e);
      }
   },
};

export default mixPanelActions;
