import * as React from 'react';

import Button from '@components/Common/Button';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import AccountType from '@models/AccountType';
import { StudentProficiencySummaryRow } from '@models/Proficiency';

import { AppStateContext } from '../../../../AppState';
import EvidenceModal from './EvidenceModal';

interface StatusTableProps {
   courseId: number;
   studentProficiencySummaryRows: StudentProficiencySummaryRow[];
   userId: number;
}

const StatusTable: React.FC<StatusTableProps> = ({
   courseId,
   studentProficiencySummaryRows,
   userId,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [evidenceModalCanDoStatement, setEvidenceModalCanDoStatement] = React.useState<
      StudentProficiencySummaryRow | undefined
   >(undefined);

   // There are no can-do statements
   if (studentProficiencySummaryRows.length === 0) {
      return (
         <EmptyState
            heading='Nothing to show'
            icon={<IconBinoculars className='large' aria-hidden />}
            description='No can-do statements have been found for this course.'
         />
      );
   }

   const closeEvidenceModal = () => {
      setEvidenceModalCanDoStatement(undefined);
   };

   const renderCompletionStatus = (row: StudentProficiencySummaryRow): React.ReactNode => {
      const completedStatuses = row.statuses.filter((x) => x.completionStatus);

      if (completedStatuses.length > 0 && userProfile?.accountType !== AccountType.instructor) {
         return <IconRadioTick className='icon-green' />;
      }

      if (completedStatuses.length > 0) {
         return (
            <Button
               className={'status-button'}
               onClick={() => setEvidenceModalCanDoStatement(row)}
               tooltip='View Evidence'
               icon={<IconRadioTick className='icon-green' aria-label='Completed' />}
               data-test={`button-proficiency-status-${row.id}`}
            />
         );
      }

      return <span className='empty-circle' />;
   };

   // There are can-do statements
   const columns: readonly Column<StudentProficiencySummaryRow>[] = [
      {
         id: 'canDoName',
         header: 'Can-Do Statements',
         cell: (i) => i.name,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'levelName',
         header: 'Level',
         cell: (i) => i.level,
         canSort: true,
         sortFunc: (i) => i.level?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'skillName',
         header: 'Skill',
         cell: (i) => i.skill,
         canSort: true,
         sortFunc: (i) => i.skill.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'modeName',
         header: 'Mode',
         cell: (i) => i.mode,
         canSort: true,
         sortFunc: (i) => i.mode?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'completionStatus',
         header: 'Status',
         cell: renderCompletionStatus,
         cellClassName: 'text-bold text-uppercase',
      },
   ];

   return (
      <>
         <Table<StudentProficiencySummaryRow>
            className='canDoStatementStatusTable'
            columns={columns}
            rowKey='id'
            rows={studentProficiencySummaryRows}
         />

         {evidenceModalCanDoStatement && (
            <EvidenceModal
               canDoStatement={evidenceModalCanDoStatement}
               closeModal={closeEvidenceModal}
               courseId={courseId}
               userId={userId}
            />
         )}
      </>
   );
};

export default StatusTable;
