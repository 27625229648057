import * as _ from 'lodash';
import * as React from 'react';

import Language from '@models/Language';
import classnames from 'classnames';

import Constants from '../../../../../Constants';
import {
   CSSObjectWithLabel,
   DropdownIndicator,
   Select,
   selectTheme,
   SingleValue,
   SingleValueProps,
   StylesConfig,
} from '@components/Core/Select';

const { staticUrl } = Constants;

export interface DropdownOption {
   value: string;
   label: string;
   joinLeft?: boolean;
   joinRight?: boolean;
   language?: Language;
   color?: string;
}

export interface FillBlanksSelectProps {
   className?: string;
   disabled?: boolean;
   joinLeft?: boolean;
   joinRight?: boolean;
   language?: Language;
   options: readonly string[];
   styles?: StylesConfig<DropdownOption, false>;
   value: string;
   blankId: string;
   onValueChange(value: string): void;
   onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
}

const FillBlanksSelect: React.FC<FillBlanksSelectProps> = ({
   className,
   disabled,
   joinLeft = false,
   joinRight = false,
   language,
   blankId,
   options: propsOptions,
   styles: propsStyle = {},
   value: propsValue,
   onBlur,
   onValueChange,
}) => {
   const mappedOptions = propsOptions.filter((i) => !!i).map((i) => ({ value: i, label: i }));

   // if any options have multiple values don't use single character display
   const singleCharOptions = !mappedOptions.some((i) => i.value.length > 1);

   return (
      <>
         {joinRight && <>&zwj;</>}
         <div className='fill-blanks-select' data-test={`fill-blanks-select-${blankId}`}>
            <Select<DropdownOption, false>
               className={classnames('react-select', className, language, {
                  'single-char': singleCharOptions,
               })}
               components={selectComponents}
               isDisabled={disabled}
               openMenuOnClick
               classNames={{
                  dropdownIndicator: () => 'dropdown-indicator',
                  singleValue: () => 'single-value',
                  option: () => 'option',
               }}
               menuPortalTarget={document.body}
               isSearchable={false}
               onBlur={onBlur}
               onChange={(e) => onValueChange(e?.value ?? '')}
               options={mappedOptions}
               styles={{
                  ...selectStyle,
                  ...propsStyle,
                  // Ensure menu pops out in front of everything with a z-index >= 1
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (base) => ({ ...base, fontSize: 'inherit' }),
               }}
               theme={selectTheme}
               value={
                  propsValue
                     ? {
                          value: propsValue,
                          label: propsValue,
                          joinLeft,
                          joinRight,
                          language,
                       }
                     : null
               }
            />
         </div>
         {joinLeft && <>&zwj;</>}
      </>
   );
};

export default FillBlanksSelect;

const getBackgroundColor = (className: string): string => {
   if (className.includes('incorrect')) {
      return '#FFF0F0';
   } else if (className.includes('correct')) {
      return '#F1F7ED';
   }
   return '';
};

const selectStyle: StylesConfig<DropdownOption, false> = {
   container: (styles, { selectProps: { className = '' } }) => {
      if (className.includes('ar')) {
         return styles;
      }
      return {
         ...styles,
         minWidth: '80px',
      };
   },
   control: (styles, { selectProps: { className = '' } }) => ({
      ...styles,
      minHeight: '0',
      backgroundColor: getBackgroundColor(className) || styles.backgroundColor,
      height: className.includes('ar') ? styles.height : '32px',
      '&:hover': {},
   }),
   indicatorsContainer: (styles, { selectProps: { className = '' } }) => {
      if (className.includes('ar')) {
         return {
            background: '#007CBB',
            position: 'absolute',
            left: 'calc(50% - 8px)',
            bottom: '-10px',
            borderRadius: '50%',
         };
      }
      return styles;
   },
   dropdownIndicator: (styles, { selectProps: { className = '' } }) => {
      if (className.includes('ar')) {
         return {
            ...styles,
            padding: '4px',
            svg: {
               fill: 'white',
               height: '8px',
               width: '8px',
            },
         };
      }
      return styles;
   },
   menu: (styles) => ({
      ...styles,
      whiteSpace: 'nowrap',
      width: 'auto',
      right: 0,
   }),
   option: (styles, { data, isDisabled, isSelected, selectProps: { className = '' } }) => ({
      ...styles,
      ':active': {
         ...styles[':active'],
         backgroundColor: !isDisabled && isSelected && data.color ? data.color : '#007CBB',
         color: 'white',
      },
      fontSize: className.includes('ar') ? 24 : styles.fontSize,
   }),
   placeholder: (styles) => ({
      ...styles,
      color: '#807F86',
      lineHeight: '1.4',
   }),
   singleValue: (styles, { selectProps: { className = '' } }) => {
      const extraStyles: CSSObjectWithLabel = {};
      if (!className.includes('single-char') && !className.includes('ar')) {
         if (className.includes('incorrect')) {
            extraStyles.background = `url('${staticUrl}/icons/icon-close-red-small.svg') no-repeat left`;
            extraStyles.paddingLeft = '26px';
         } else if (className.includes('correct')) {
            extraStyles.background = `url('${staticUrl}/icons/icon-tick-green.svg') no-repeat left`;
            extraStyles.paddingLeft = '26px';
         }
      }
      return {
         ..._.omit(styles, ['maxWidth', 'position', 'transform']),
         margin: 0,
         lineHeight: '1.4',
         ...extraStyles,
      };
   },
   valueContainer: (styles, { hasValue, selectProps: { className = '' } }) => ({
      ...styles,
      ...(className.includes('ar') && hasValue ? { padding: '0px' } : {}),
   }),
};

const selectComponents = {
   IndicatorSeparator: () => null,
   LoadingIndicator: () => null,
   Placeholder: () => null,
   DropdownIndicator,
   SingleValue: ({ children, data, ...props }: SingleValueProps<DropdownOption>) => (
      <SingleValue data={data} {...props}>
         {data.joinRight && <>&zwj;</>}
         {children}
         {data.joinLeft && <>&zwj;</>}
      </SingleValue>
   ),
};
