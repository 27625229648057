import EncodingUtils from '@helpers/EncodingUtils';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { License } from '@components/AdminLicensesTable/Models';
import { SortByEntry } from '@components/Common/Table';
import { encodePredicate } from '@components/PredicateEditor/Utils';
import AxiosService from './AxiosService';

const getLicenses = (
   { predicate, ...filters }: PagedSearchFilters,
   orderBy?: readonly SortByEntry[],
   options?: Partial<AxiosRequestConfig>,
): Promise<PagedResponse<License>> => {
   const predicateEncodedString = predicate ? encodePredicate(predicate) : undefined;
   const orderByEncodedString = orderBy
      ? EncodingUtils.stringToB64(JSON.stringify(orderBy))
      : undefined;

   return AxiosService.get('/api/admin/licenses', {
      cancelToken: options?.cancelToken,
      params: {
         ...filters,
         predicate: predicateEncodedString,
         orderBy: orderByEncodedString,
      },
   }).then((resp) => resp.data);
};

const revokeLicense = (licenseId: number, refund = true): Promise<AxiosResponse> =>
   AxiosService.post(`/api/admin/licenses/${licenseId}/revoke`, { refund });

export default {
   getLicenses,
   revokeLicense,
};
