import Language from '@models/Language';
import { BasicLevelName } from '@models/Proficiency';
import { InterviewQuestionSummary, VideoFeatureCollection, VideoSummary } from '@models/Video';
import _, { Dictionary } from 'lodash';

import Fuse, { FuseIndex, FuseResult } from 'fuse.js';

const options = {
   // isCaseSensitive: false,
   includeScore: true,
   // shouldSort: true,
   includeMatches: true,
   // findAllMatches: false,
   minMatchCharLength: 0,
   // location: 0,
   threshold: 0.4,
   // distance: 100,
   // useExtendedSearch: false,
   ignoreLocation: true,
   // ignoreFieldNorm: false,
   keys: ['interviewQuestion', 'videoSummaries.level', 'videoSummaries.language'],
};

export const getInterviewQuestionSummaryFromVideoFeatureCollection = (
   videoFeatures: VideoFeatureCollection,
): InterviewQuestionSummary[] => {
   const interviewQuestionLookup: Dictionary<VideoSummary[]> = {};

   for (const feature of videoFeatures.features) {
      const summary = feature.properties;
      if (summary.interviewQuestion) {
         if (interviewQuestionLookup[summary.interviewQuestion]) {
            interviewQuestionLookup[summary.interviewQuestion].push(summary);
         } else {
            interviewQuestionLookup[summary.interviewQuestion] = [summary];
         }
      }
   }

   return Object.entries(interviewQuestionLookup).map(([interviewQuestion, videoSummaries]) => ({
      interviewQuestion,
      videoSummaries,
   }));
};

export const getIndex = (
   interviewQuestionSummaries: InterviewQuestionSummary[],
): FuseIndex<InterviewQuestionSummary> => {
   const index = Fuse.createIndex(options.keys, interviewQuestionSummaries);
   return index;
};

export const search = (
   index: FuseIndex<InterviewQuestionSummary>,
   searchString: string,
   videoSummaries: readonly InterviewQuestionSummary[],
   language?: Language,
   level?: BasicLevelName,
): FuseResult<InterviewQuestionSummary>[] => {
   const fuse = new Fuse(videoSummaries, options, index);

   const query = {
      $and: [
         {
            $path: ['interviewQuestion'],
            $val: searchString,
         },
      ],
   };

   if (language) {
      query.$and.push({
         $path: ['videoSummaries.language'],
         $val: language,
      });
   }

   if (level) {
      query.$and.push({
         $path: ['videoSummaries.level'],
         $val: level,
      });
   }

   const results = fuse.search(query);

   // Remove video summaries that do not match filters
   const filteredResults = results
      .map((x) => {
         const filteredVideoSummaries = x.item.videoSummaries.filter(
            (y) =>
               (language ? y.language === language : true) && (level ? y.level === level : true),
         );

         return {
            ...x,
            item: {
               ...x.item,
               videoSummaries: filteredVideoSummaries,
            },
         };
      })
      .filter((x) => x.item.videoSummaries.length > 0);

   return filteredResults;
};
