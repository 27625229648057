import { IdName, Maybe } from '@models/Core';
import Language from '@models/Language';

export const BasicLevelConstants = ['Novice', 'Intermediate', 'Advanced'] as const;

export type BasicLevelName = (typeof BasicLevelConstants)[number];

export const isBasicLevel = (maybeLevel: Maybe<string>): maybeLevel is BasicLevelName =>
   !!maybeLevel && BasicLevelConstants.includes(maybeLevel as BasicLevelName);

export const ProficiencyLevelConstants = [
   'Novice Low',
   'Novice Mid',
   'Novice High',
   'Intermediate Low',
   'Intermediate Mid',
   'Intermediate High',
   'Advanced Low',
   'Advanced Mid',
   'Advanced High',
] as const;

export type ProficiencyLevelName = (typeof ProficiencyLevelConstants)[number];

export const ProficiencyLevelHierarchy: Record<ProficiencyLevelName, number> = {
   'Novice Low': 0,
   'Novice Mid': 1,
   'Novice High': 2,
   'Intermediate Low': 3,
   'Intermediate Mid': 4,
   'Intermediate High': 5,
   'Advanced Low': 6,
   'Advanced Mid': 7,
   'Advanced High': 8,
};

export const SkillsConstants = ['Reading', 'Writing', 'Speaking', 'Listening'] as const;

export type Skill = (typeof SkillsConstants)[number];

export const isSkill = (value: string): value is Skill => SkillsConstants.includes(value as Skill);

export const isProficiencyLevelName = (value: string): value is ProficiencyLevelName =>
   ProficiencyLevelConstants.includes(value as ProficiencyLevelName);

export const ModesConstant = ['Interpretive', 'Interpersonal', 'Presentational'] as const;

export type Modes = (typeof ModesConstant)[number];

export type ProficiencyCanDoStatementBase = {
   id: number;
};

export type ProficiencyCanDoStatementFields = {
   /** Not needed for create */
   level?: ProficiencyLevelName;
   /** Not needed for create */
   mode?: Modes;
   name: string;
   performanceIndicatorId: number;
   skill: Skill;
   themeId: number | null;
};

export type ProficiencyCanDoStatement = ProficiencyCanDoStatementBase &
   ProficiencyCanDoStatementFields;

export type CourseProficiencyCanDoStatement = ProficiencyCanDoStatement & {
   weekIndexOfExpectedCompletion?: Maybe<number>;
};

export type ProficiencyCanDoStatementDetailed = ProficiencyCanDoStatement & {
   theme: string;
   performanceIndicator: string;
};

export type ProficiencyActivitySummary = {
   activityId: number;
   activityLanguage: Language;
   activityName: string;
   canDoStatementId: number;
};

export type ProficiencyCanDoStatementDetailedWithActivities = ProficiencyCanDoStatementDetailed & {
   activities: readonly ProficiencyActivitySummary[];
};

export type ProficiencyLevels = {
   levels: readonly ProficiencyLevelName[];
};

export type ProficiencySkills = {
   skills: readonly Skill[];
};

export type ProficiencyModes = {
   modes: readonly Modes[];
};

export type ProficiencyThemes = {
   themes: readonly IdName[];
};

export type ProficiencyCanDoStatementLevelsAndSkills = ProficiencyLevels & ProficiencySkills;

export type ProficiencyCanDoStatementOptions = ProficiencyCanDoStatementLevelsAndSkills &
   ProficiencyModes &
   ProficiencyThemes;

export interface StudentProficiencyCanDoStatementStatus {
   completionStatus: boolean;
   dateCompleted: Date | null; // Modification date - double check ordering prompts
   dateAvailable: Date;
}

export type StudentProficiencySummaryRow = ProficiencyCanDoStatementDetailed & {
   statuses: StudentProficiencyCanDoStatementStatus[];
};

export type StudentProficiencyReport = {
   courseName: string;
   courseEndDate: Date;
   courseStartDate: Date;
   reportName: string;
   studentSummary: StudentProficiencySummaryRow[];
};

export type ProficiencyEvidenceItems = {
   activityName: string;
   moduleId: number;
   moduleItemId: number;
   moduleName: string;
   submissionId: number;
};

// ------------------- Proficiency Reports -------------------
export type CourseProficiencySummaryDetails = {
   averageMet: number;
   totalPresented: number;
   totalInProgram: number;
};

export type CourseCanDoStatementSummary = {
   canDoStatementId: number;
   canDoStatementSkill: Skill;
   weekIndexOfExpectedCompletion: number;
};

export type ProficiencyReport = {
   courseCanDoStatementSummaries: CourseCanDoStatementSummary[];
   courseEndDate: Date;
   courseStartDate: Date;
   reportName: string;
};

export type ProficiencySummaryRow = {
   countOfCompletedCanDoStatements: number;
   skill: Skill;
};

export type ProficiencyReportSkillData = {
   completedAverage: number;
   presentedCount: number;
   totalCount: number;
};

export type ProficiencySkillsPlusOverall = Skill | 'Overall';

export type ProficiencyReportTileBreakdownBySkill = {
   [key in ProficiencySkillsPlusOverall]: ProficiencyReportSkillData;
};

export type ProficiencyDistrictReportBreadcrumbs = {
   instructorReportName: string;
   programReportName: string;
   schoolReportName: string;
};

// ------------------- Instructor Summary -------------------
export type StudentInInstructorProficiencyCourse = IdName & { courseId: number };

export type InstructorProficiencySummaryRow = ProficiencySummaryRow & {
   studentId: number;
   studentName: string;
};

export type InstructorProficiencyReport = ProficiencyReport & {
   courseName: string;
   instructorSummary: InstructorProficiencySummaryRow[];
   studentInInstructorProficiencyCourses: StudentInInstructorProficiencyCourse[];
};

// ------------------- School Summary -------------------

export type SchoolProficiencySummaryRow = ProficiencySummaryRow & {
   instructorId: number;
};

export type SchoolProficiencyReport = ProficiencyReport & {
   schoolSummary: SchoolProficiencySummaryRow[];
   instructorsInProficiencyCourse: IdName[];
};

// ------------------- Program Summary -------------------

export type ProgramProficiencySummaryRow = ProficiencySummaryRow & {
   organizationId: number;
};

export type ProgramProficiencyReport = ProficiencyReport & {
   programSummary: ProgramProficiencySummaryRow[];
   schoolsHostingProficiencyCourse: IdName[];
};

// ------------------- District Summary -------------------

export type CanDoStatementSummariesByCourse = {
   canDoStatements: CourseCanDoStatementSummary[];
   courseEndDate: Date;
   courseStartDate: Date;
   courseId: number;
};

export type DistrictProficiencySummaryRow = ProficiencySummaryRow & {
   standardCourseId: number;
};

export type DistrictProficiencyReport = {
   canDoStatementSummariesByCourse: CanDoStatementSummariesByCourse[];
   districtSummary: DistrictProficiencySummaryRow[];
   districtProficiencyCourses: IdName[];
};

export type DistrictSummariesByCourse = Record<
   string | number,
   ProficiencyReportTileBreakdownBySkill
>;

// ------------------- All Reports -------------------
export type CanDoStatmentSummaryRow = {
   canDoStatementId: number;
   canDoStatementName: string;
   canDoStatementSkill: Skill;
   performanceIndicatorLevel: ProficiencyLevelName;
   performanceIndicatorMode: Modes;
   totalNumberCompleted: number;
};

export type CanDoStatementReportSummary = {
   canDoStatementSummaries: CanDoStatmentSummaryRow[];
   studentCount: number;
};
