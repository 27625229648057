/* eslint-disable camelcase */
import { NationalExamAbbreviation } from '@models/NationalExam';
import { ProficiencyLevelName, Skill } from '@models/Proficiency';

const constants = {
   awsHealthCheck: 'https://lingco-public.s3.us-east-2.amazonaws.com/internet-connection-check',
   basePrice: 48.0,
   currencies: {
      USD: '$',
      GBP: '£',
      EUR: '€',
   },
   currencyDecimalScales: {
      USD: 2,
      GBP: 2,
      EUR: 2,
   },
   keys: {
      backspace: 'Backspace',
      delete: 'Delete',
      enter: 'Enter',
      escape: 'Escape',
      left: 'ArrowLeft',
      right: 'ArrowRight',
      space: ' ',
      shift: 'Shift',
      tab: 'Tab',
   },
   errorTypes: {
      missingNationalExamCategory: 'missing_national_exam_category',
   },
   statusCodes: {
      ok: 200,
      created: 201,
      accepted: 202,
      noContent: 204,
      badRequest: 400,
      unauthorized: 401,
      paymentRequired: 402,
      forbidden: 403,
      notFound: 404,
      requestTimeout: 408,
      conflict: 409,
      unprocessableEntity: 422,
      serverError: 500,
      gatewayTimeout: 504,
   },
   maxVocabCardLevel: 4,
   vocabLearnedLevel: 5,
   nonDragAreaSelector: '.no-drag, .mce-wrapper, input, textarea, .grouping-prompt-drag-context',
   routes: {
      root: '/',
      studentVocabStats: '/vocab_stats',
      confirmEmail: '/confirm_email',
      dashboard: '/',
      forgotPassword: '/forgot_password',
      register: '/register',
      resetPassword: '/reset_password',
      activities: {
         completeSubmission: '/activities/submission/:submissionId/complete',
         completeSubmissionNoSubmissionId: '/activities/moduleItem/:moduleItemId/complete',
         gradeResponses: '/activities/:moduleItemId/responses/:promptId',
         gradeSubmission: '/activities/:submissionId/grade',
         gradeSubmissionFromModuleId: '/activities/:moduleItemId/submissions/:submissionId',
         viewAllSubmissions: '/activities/:moduleItemId/submissions',
      },
      admin: {
         bookstoreCodeGenerator: '/admin/bookstore_code_generator',
         impersonateUser: '/admin/impersonate_user',
         instructorInvitations: '/admin/instructor_invitations',
         nationalExam: {
            viewExamOrders: '/admin/national_exams/orders',
            viewExamOrderDetails: '/admin/national_exams/orders/:orderId',
            viewExams: '/admin/national_exams',
            newExam: '/admin/national_exams/new',
            examSummaryDetails: '/admin/national_exams/:examId',
         },
         user: {
            userDetails: '/admin/users/:userId',
         },
         styleSheets: '/admin/style_sheets',
      },
      auth: {
         login: '/auth/login',
         loginSso: '/auth/login/sso',
         logout: '/auth/logout',
         tokenLogin: '/auth/token_login',
         tokenLoginFailure: '/auth/token_login_failure',
         requestCallback: '/auth/request_callback',
      },
      courses: {
         clone: '/courses/clone',
         create: '/courses/create',
         createFromCanvas: '/courses/create_from_canvas',
         dashboard: '/courses/:courseId',
         edit: '/courses/:courseId/edit',
         editModule: '/courses/:courseId/modules/:moduleId/edit',
         gradebook: '/courses/:courseId/gradebook',
         join: '/courses/join',
         modules: '/courses/:courseId/modules',
         myGrades: '/courses/:courseId/grades',
         newModule: '/courses/:courseId/modules/new',
         canDoStatements: '/courses/:courseId/proficiency/can_do_statements',
         root: '/courses',
         roster: '/courses/:courseId/roster',
         viewCourseEnrollments: '/course_enrollments',
         viewCourses: '/courses',
         viewModule: '/courses/:courseId/modules/:moduleId',
         viewModuleItem: '/courses/:courseId/module_items/:moduleItemId',
         vocabStats: '/courses/:courseId/vocab_stats',
         studentSummary: '/courses/:courseId/proficiency/student_summary',
         instructorSummary: '/courses/:courseId/proficiency',
         instructorStudentSummary: '/courses/:courseId/proficiency/student_summary/:studentId',
      },
      enrollments: {
         byId: '/enrollments/:enrollmentId',
      },
      content: {
         root: '/content',
         bulkVideoUpload: '/content/videos/bulk_upload',
         editActivity: '/content/activities/:activityId/edit',
         editLesson: '/content/lessons/:lessonId/edit',
         editVocabSet: '/content/vocab_sets/:vocabSetId/edit',
         interviewQuestions: '/content/videos/interview_questions',
         newActivity: '/content/activities/new',
         newLesson: '/content/lessons/new',
         newVocabSet: '/content/vocab_sets/new',
         previewActivity: '/content/activities/:activityId/preview',
         speakers: '/content/videos/speakers',
         viewLesson: '/content/lessons/:lessonId/view',
         viewVideo: '/content/videos/:videoId/view',
         viewVideoMap: '/content/videos/map',
         viewVocabSet: '/content/vocab_sets/:vocabSetId/view',
         vocabMatching: '/content/vocab_sets/:vocabSetId/matching',
         vocabSession: '/content/vocab_sets/:vocabSetId/session/:sessionType',
      },
      contentLibrary: {
         root: '/content_library',
         library: '/content_library/:libraryName',
         folder: '/content_library/:libraryName/:folderId',
      },
      landing: '/goto/:urlSlug',
      licenses: {
         viewLicenses: '/licenses',
      },
      nationalExamAdmin: {
         courseSearch: '/national_exams/admin/course_search',
         exams: '/national_exams/admin/exams',
      },
      schools: {
         viewSchools: '/schools',
         createSchool: '/schools/new',
         schoolDetails: '/schools/:organizationId',
      },
      settings: '/settings',
      techCheck: '/tech_check',
      users: {
         viewUsers: '/users',
      },
      vocabSets: {
         overview: '/vocab_sets/:moduleItemId/overview',
      },
      nationalExam: {
         dashboard: '/national_exams/:examUrlSlug',
         registration: '/national_exams/:examUrlSlug/register',
         setup: '/national_exams/:examUrlSlug/setup',
      },
      proficiencyTracking: {
         canDoStatements: '/can_do_statements',
         districtSummary: '/proficiency/district_report',
         instructorSummary:
            '/proficiency/district_report/standard_course/:standardCourseId/school/:organizationId/instructor/:instructorId',
         programSummary: '/proficiency/district_report/standard_course/:standardCourseId',
         schoolSummary:
            '/proficiency/district_report/standard_course/:standardCourseId/school/:organizationId',
         districtStudentSummary:
            '/proficiency/district_report/standard_course/:standardCourseId/school/:organizationId/instructor/:instructorId/course/:courseId/student/:studentId',
      },
   },
   siteLinks: {
      changelog: 'https://feedback.lingco.io/changelog',
      feedback: 'https://feedback.lingco.io/',
      privacyPolicy: 'https://lingco.io/privacy_policy',
      statusPage: 'https://status.lingco.io',
      helpDocs: 'https://help.lingco.io/',
      termsOfService: 'https://lingco.io/terms_of_service',
      autoPlayHelpArticle:
         'https://help.lingco.io/en/articles/5477821-how-to-allow-autoplay-sounds-for-lingco-chrome-firefox-safari',
   },
   socketUrls: {
      local: 'ws://127.0.0.1:5001',
      development: 'wss://classroom-socket-dev.lingco.io',
      test: 'wss://classroom-socket-test.lingco.io',
      production: 'wss://class-socket.lingco.io',
   },
   staticUrl: 'https://s3.us-east-2.amazonaws.com/lingco-static',
   stripe: {
      testPublicKey: 'pk_test_inpMyqO09ZNnQmW2LfmIOX7K00z0LVdQrv',
      livePublicKey: 'pk_live_4V9regtiOLtJ0qrmq0Xxxp8V008z5kUxYl',
   },
   canny: '5b29a146a68813425051b797',
   blackboard: 'bb81a98c-261b-4baa-9646-26476f5a7543',
   intercom: {
      test: 'fcr6ug4x',
      prod: 'wa8qoxkg',
   },
   googleClientId: {
      development: '1052438871189-ha0gitb76gpe0vpesjbstbhj08b6o1q0.apps.googleusercontent.com',
      test: '1052438871189-ha0gitb76gpe0vpesjbstbhj08b6o1q0.apps.googleusercontent.com',
      production: '1052438871189-u4l9os7d0psopkphar0q36kfbl14itn1.apps.googleusercontent.com',
   },
   googleScopes: {
      manageClassroomCourses: 'https://www.googleapis.com/auth/classroom.courses',
      manageClassroomCoursework: 'https://www.googleapis.com/auth/classroom.coursework.students',
      manageClassroomRosters: 'https://www.googleapis.com/auth/classroom.rosters',
      viewClassromEmails: 'https://www.googleapis.com/auth/classroom.profile.emails',
      viewClassroomCourses: 'https://www.googleapis.com/auth/classroom.courses.readonly',
      viewClassroomRosters: 'https://www.googleapis.com/auth/classroom.rosters.readonly',
   },
   dataDog: {
      applicationId: '15b4589f-b2b1-4c06-9137-bbe34d2be185',
      clientToken: 'pub788865f9ad5a211afa98fe0019cc8860',
      site: 'datadoghq.com',
      service: 'classroom',
      version: '1.0.0',
      env: {
         development: 'dev',
         test: 'test',
         production: 'prod',
      },
   },
   mixPanel: {
      development: '',
      test: '',
      production: '3cd69ff093ad50fac2f62b67a030777e',
   },
   opentok: {
      development: '46469232',
      test: '46469232',
      production: '46468672',
   },
   features: {
      addToGlobalLibrary: 'global_library',
   },
   minPasswordLength: 8,
   maxPasswordLength: 128,
   alphabets: {
      ru: [...'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'],
      ja: [
         ...'あいうえおかきくけこがぎぐげごさしすせそざじずぜぞたちつてとだぢづでどなにぬねのはひふへほばびぶべぼぱぴぷぺぽまみむめもやゆよらりるれろわをんっゝゞゃゅょ',
      ],
      ko: [...'ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ'],
   },
   regex: {
      allArabicLetters:
         /[\u0621-\u064A\u066E-\u06D3\u06D5\u06EE\u06EF\u06FA-\u06FC\u06FF\uFB50-\uFDC7\uFE80-\uFEFC]/,
      arabicLettersWithNoLeftJoin:
         /[\u0622-\u0625\u0627\u0629\u062F-\u0632\u0648\u0671-\u0673\u0675-\u0677\u0688-\u0699\u06C0\u06C3-\u06CB\u06CD\u06CF\u06D2-\u06D3\u06D5\u06EE-\u06EF\u0710\u0715-\u0719\u071E\u0728\u072A\u072C\u072F\u074D\u0759-\u075B\u076B-\u076C\u0771\u0773-\u0774\u0778-\u0779\u0840\u0846-\u0847\u0849\u0854\u0867\u0869-\u086A\u08AA-\u08AC\u08AE\u08B1-\u08B2\u08B9\uFE8D\uFEA9-\uFEB0\uFEED\uFEEE]/,
      youtubeUrl: /^.*(youtu.be\/|v\/|shorts\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
   },
   stateAbbreviations: [
      'AL',
      'AK',
      'AS',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FM',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MH',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'MP',
      'OH',
      'OK',
      'OR',
      'PW',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
      'Other',
   ],
   soundEffectsFolder:
      'https://lingco-classroom-prod.s3.us-east-2.amazonaws.com/uploaded_audio/master_files/sound_effects',
   onboardingAuthor: {
      name: 'Seth',
      company: 'Lingco Language Labs',
      imageUrl: 'https://lingco-static.s3.us-east-2.amazonaws.com/img/seth.png',
   },
   chatMessages: {
      noSchoolFound: 'Hello! I am unable to find my school. Can you help?',
      error: 'Hello! I received an error. Can you help me?',
      registrationError: 'Hello! I received an error while creating my account. Can you help me?',
   },
};

export type NationalExamStudentClassificationTypes = 'Division' | 'Category' | 'Seat Assignment';

export const NationalExamStudentClassificationWord: Record<
   NationalExamAbbreviation,
   NationalExamStudentClassificationTypes
> = {
   nfc: 'Division',
   nge: 'Category',
   nge_spring: 'Category',
   nie: 'Category',
   nie_pilot: 'Category',
   nle: 'Category',
   npe: 'Category',
   nse: 'Category',
   nsc: 'Seat Assignment',
   nsa: 'Seat Assignment',
   nse_prep: 'Seat Assignment',
   nge_prep: 'Seat Assignment',
   nsc_prep: 'Seat Assignment',
   ele: 'Category',
   mme: 'Category',
   ncee: 'Category',
   ngre: 'Category',
   nhce: 'Category',
   nrce: 'Category',
   pme: 'Category',
   nlve: 'Category',
};

export const NationalExamNameAbbreviationValuesRecord: Record<
   NationalExamAbbreviation,
   NationalExamAbbreviation
> = {
   nfc: 'nfc',
   nge: 'nge',
   nge_spring: 'nge_spring',
   nge_prep: 'nge_prep',
   nie: 'nie',
   nie_pilot: 'nie_pilot',
   nle: 'nle',
   npe: 'npe',
   nsa: 'nsa',
   nsc: 'nsc',
   nse: 'nse',
   nse_prep: 'nse_prep',
   nsc_prep: 'nsc_prep',
   ncee: 'ncee',
   nrce: 'nrce',
   pme: 'pme',
   mme: 'mme',
   ele: 'ele',
   ngre: 'ngre',
   nlve: 'nlve',
   nhce: 'nhce',
};

export const AbbreviationExamNameRecord: Record<NationalExamAbbreviation, string> = {
   nfc: 'National French Contest',
   nge: 'National German Exam',
   nge_spring: 'National German Exam Spring',
   nge_prep: 'National German Exam (Prep)',
   nie: 'National Italian Exam',
   nie_pilot: 'National Italian Exam (Pilot)',
   nle: 'National Latin Exam',
   npe: 'National Portuguese Exam',
   nsa: 'National Spanish Assessment',
   nsc: 'National Spanish Challenge',
   nse: 'National Spanish Exam',
   nse_prep: 'National Spanish Assessment and National Spanish Exam (Prep)',
   nsc_prep: 'National Spanish Challenge (Prep)',
   ncee: 'National Classical Etymology Exam',
   nrce: 'National Roman Civilization Exam',
   pme: 'Pegasus Mythology Exam',
   mme: 'Medusa Mythology Exam',
   ele: 'Exploratory Latin Exam',
   ngre: 'National Greek Exams',
   nlve: 'National Latin Vocabulary Exam',
   nhce: 'National Hellenic Civilization Exam',
};

export const ProficiencyLevelSkillGraderDescriptions: Record<
   ProficiencyLevelName,
   Record<Skill, string[]>
> = Object.freeze({
   'Novice Low': {
      Listening: [
         'can occasionally recognize isolated words or high frequency phrases.',
         'show virtually no comprehension of even the most basic spoken messages.',
      ],
      Reading: [
         'can recognize a limited number of letters, characters, or symbols.',
         'can occasionally recognize high frequency words or phrases.',
      ],
      Speaking: [
         'function at the word level.',
         'can exchange greetings, give their name, and name a few familiar objects.',
         'cannot yet engage in conversation.',
      ],
      Writing: [
         'can write some words in alphabetic systems or',
         'can copy and produce isolated basic strokes in languages that use syllabaries or characters.',
      ],
   },
   'Novice Mid': {
      Listening: [
         'can recognize and understand highly frequent and highly contextualized words and phrases.',
         'can recognize aural cognates and borrowed words.',
         'cannot generally understand more than one phrase at a time.',
         'will generally require repetition.',
      ],
      Reading: [
         'can recognize the letters of an alphabetic writing system, the symbols of a syllabic system or some characters in a character based writing system.',
         'can identify some highly contextualized words or phrases including cognates and borrowings.',
         'can rarely understand material exceeding a single phrase.',
      ],
      Speaking: [
         'function at the word level.',
         'can communicate minimally using individual words (e.g. lists) and/or memorized phrases.',
         'have frequent pauses and interference from their primary language.',
      ],
      Writing: [
         'can reproduce words and some phrases from memory.',
         'can supply limited information on personal or biographic topics.',
         'will have high accuracy on well-practiced forms, but Novice Mid writers lack functional writing skills.',
      ],
   },
   'Novice High': {
      Listening: [
         'may understand information from sentence level speech.',
         'can understand speech dealing with areas of practical need and personal information.',
         'can understand standardized messages, phrases, or instructions if the vocabulary has been learned.',
         'will need some extralinguistic support such as gestures and repetitions.',
      ],
      Reading: [
         'can understand easily key words and cognates.',
         'can understand formulaic phrases across different contexts.',
         'can understand predictable texts in predictable contexts (schedules, road maps, weather maps, etc).',
         'can derive meaning from short, non-complex, contextualized texts.',
      ],
      Speaking: [
         'function inconsistently at the sentence level.',
         'rely heavily on memorized phrases.',
         'can handle uncomplicated communicative tasks.',
         'can respond to simple direct questions.',
      ],
      Writing: [
         'can meet limited writing needs such as lists, short messages, post cards, etc. focused on daily life.',
         'generally cannot sustain sentence level writing except for practiced material.',
      ],
   },
   'Intermediate Low': {
      Listening: [
         'can understand some information from sentence length speech.',
         'can understand information in basic personal and social contexts with uneven comprehension.',
         'will not generally get meaning from advanced oral texts.',
      ],
      Reading: [
         'function at the basic sentence level.',
         'can understand some information from very simple connected texts.',
         'will have trouble to derive meaning from texts of any length beyond a few sentences.',
      ],
      Speaking: [
         'function at the sentence level.',
         'create novel utterances in straightforward, uncomplicated situations.',
         'converse on predictable topics.',
         'are primarily reactive and will exhibit hesitancy and inaccuracies in their speech.',
      ],
      Writing: [
         'can meet some limited practical writing needs including creating sentences.',
         'can ask questions on familiar topics.',
         'will have short sentences with basic word order and little variation in structure.',
         'will have basic errors in grammar, word choice, and spelling.',
      ],
   },
   'Intermediate Mid': {
      Listening: [
         'can understand simple, sentence length speech in a variety of basic personal and social contexts.',
         'will have accuracy in comprehension on highly familiar and predictable topics.',
         'may get meaning from more advanced oral texts.',
      ],
      Reading: [
         'function at the sentence level.',
         'can understand short, non-complex texts that convey basic information or deal with basic personal and social topics.',
         'will likely exhibit some misunderstandings of these texts.',
      ],
      Speaking: [
         'function at the sentence level.',
         'at times will be able to link ideas to effect longer utterances (i.e. spoken paragraphs).',
         'create language by focusing  on personal information in concrete and uncomplicated exchanges.',
         'can ask a variety of questions.',
         'may be able to narrate in different time frames, although will often fall back to using the present tense.',
      ],
      Writing: [
         'can meet a number of practical writing needs.',
         'can write at the sentence level primarily in the present time frame.',
         'may be able to write in other time frames.',
         'will write mostly in discrete sentences or questions loosely strung together without deliberate organization more closely resembling oral discourse.',
      ],
   },
   'Intermediate High': {
      Listening: [
         'can understand with ease and confidence simple sentence length speech in basic personal and social contexts',
         'can get meaning from some advanced oral texts with connected ideas',
      ],
      Reading: [
         'function at the sentence level.',
         'understand fully and with ease short, non-complex texts that convey basic information or deal with basic personal and social topics.',
         'can understand some connected texts that deal with description or narration.',
      ],
      Speaking: [
         'function at the spoken paragraph level, although they will have difficulty sustaining this.',
         'can function easily and confidently in uncomplicated, straightforward social situations.',
         'can ask questions to get necessary information.',
         'can narrate in all major time frames using connected discourse.',
         'will have speech marked by some hesitations, searching for vocabulary, and the inability to sustain narration for long.',
      ],
      Writing: [
         'can often write at the paragraph level.',
         'can narrate and describe events in all major time frames.',
         'will function better when writing about everyday events and situations.',
         'will have errors in grammar at this level but writing is generally comprehensible to non-natives.',
      ],
   },
   'Advanced Low': {
      Listening: [
         'can understand short narratives and descriptive texts with a clear underlying structure.',
         'can understand main facts and some supporting details in oral texts of conventional topics.',
         'may have uneven comprehension.',
         'will need to rely on situational and subject matter knowledge for comprehension.',
      ],
      Reading: [
         'function at the paragraph level.',
         'understand uncomplicated narrative and descriptive texts with a clear underlying structure and high frequency vocabulary.',
         'will understand main ideas and some supporting details.',
      ],
      Speaking: [
         'function at the spoken paragraph level.',
         'can handle a variety of communicative tasks that go beyond personal, uncomplicated situations.',
         'can narrate and describe in all major time frames with some control of aspect in paragraph length discourse.',
         'can handle situations with an unexpected complication.',
         'will have limited quality and quantity for this level and there may be some grammatical roughness present.',
      ],
      Writing: [
         'can narrate at the paragraph level in all major time frames.',
         'can meet basic work or academic writing needs.',
         'will demonstrate a lack the quantity and quality required of more advanced writers.',
         'will lack some control of common structures associated with the Advanced level.',
      ],
   },
   'Advanced Mid': {
      Listening: [
         'can understand narrative and descriptive texts. ',
         'can understand narrations about past, present, and future events.',
         'can understand main facts and many supporting details in familiar situations or language patterns.',
         'use subject-matter knowledge for comprehension, but also rely on a facility with the language itself.',
      ],
      Reading: [
         'function at the paragraph level.',
         'can understand descriptive texts and narrations in all major time frames.',
         'will understand main ideas, facts and supporting details.',
         'may derive meaning from texts that are structurally and conceptually more complex.',
      ],
      Speaking: [
         'function at the paragraph level.',
         'can communicate about personal topics as well as those related to more public issues.',
         'can effectively narrate in all time frames with good control of aspect.',
         'can successfully handle unexpected complications.',
         'Have substantial flow in their speech and vocabulary which is fairly extensive.',
      ],
      Writing: [
         'can narrate and describe in detail in all major time frames over several paragraphs.',
         'write thoughts that are generally expressed clearly and supported by some elaboration.',
         'can write straightforward summaries on topics of general interest.',
         'will demonstrate writing that is organized with respect to features of the target language but may also contain some stylistic interference from the native language.',
      ],
   },
   'Advanced High': {
      Listening: [
         'can understand with ease and confidence narratives and descriptive texts of any length.',
         'can typically follow some essential points of complex or augmentative speech.',
         'can derive meaning from oral texts that deal with unfamiliar topics or situations.',
      ],
      Reading: [
         'function at the discourse level (i.e. connected paragraphs).',
         'understand fully and with ease descriptions and narrations in all major time frames.',
         'can follow some major points in argumentative texts.',
         'can understand parts of texts that deal with unfamiliar topics.',
         'can go beyond the facts of a text and begin to recognize inferences and points of view.',
      ],
      Speaking: [
         'can function at the discourse level.',
         'can string multiple paragraphs together into structured arguments.',
         'can handle complex linguistic tasks including those with unexpected complications using precise grammar and vocabulary.',
         'can narrate with supporting details in all time frames with good control of aspect.',
         'may have some linguistic breakdowns occur at some point especially when talking about hypothetical situations.',
      ],
      Writing: [
         'can write easily on concrete topics at length with significant precision and detail in all major time frames with solid control of aspect.',
         'can handle both formal and informal correspondence with regard to appropriate conventions.',
         'can construct arguments and hypothesize but are not able to do so consistently.',
         'have good control of grammar and use a wide range of vocabulary.',
      ],
   },
});

export default Object.freeze(constants);
