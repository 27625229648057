// @ts-strict-ignore
/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import { randomDistinguishableCode } from '@helpers/RandomStringUtils';
import IconGrade from '@icons/general/icon-grade.svg';
import IconCalendar1 from '@icons/nova-line/05-Time/calendar-1.svg';
import IconInterfaceInformation from '@icons/nova-solid/22-Interface-Feedback/interface-information.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import {
   Assignment,
   CourseSection,
   GradingCategory,
   ModuleItem,
   ModuleItemActivity,
   SectionDates,
} from '@models/Course';
import classnames from 'classnames';

import Switch from '@components/Common/Switch';
import DateTimePicker from '@components/Core/DateTimePicker';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import ModalDialog from '@components/Core/ModalDialog';
import {
   CreatableSelect,
   selectComponents,
   selectStyle,
   selectTheme,
} from '@components/Core/Select';
import AssignmentDatePicker, { AssignmentDatePickerProvided } from './AssignmentDatePicker';
import {
   checkSectionDatesValid,
   getAttemptsAllowedError,
   getSecretCodeError,
   getTimeLimitError,
   MAX_SECRET_CODE_LENGTH,
   MIN_ATTEMPTS_ALLOWED,
   MIN_TIME_LIMIT,
} from './ModuleUtils';

enum EditableProperty {
   allowLateSubmission = 'allowLateSubmission',
   allowMultipleAttempts = 'allowMultipleAttempts',
   dates = 'dates',
   gradeAutomatically = 'gradeAutomatically',
   gradingCategoryId = 'gradingCategoryId',
   isAssigned = 'isAssigned',
   lockAfterClosed = 'lockAfterClosed',
   requireSecretCode = 'requireSecretCode',
   sectionDatesEnabled = 'sectionDatesEnabled',
   timeLimitEnabled = 'timeLimitEnabled',
}

export interface BulkEditResult extends Partial<Omit<ModuleItemActivity, 'assignment'>> {
   assignment: Partial<Assignment>;
}

interface BulkEditModalProps {
   courseEndDate: Date;
   courseStartDate: Date;
   defaultAssignment: Assignment;
   gradingCategories: readonly GradingCategory[];
   sections: readonly CourseSection[];
   selectedItems: readonly ModuleItem[];
   createGradingCategory(label: string): Promise<number>;
   onClose(): void;
   onSave(bulkEditResult: BulkEditResult): void;
}

interface AssignmentProperties {
   allowLateSubmission: boolean;
   allowMultipleAttempts: boolean;
   attemptsAllowed: number;
   endDate: Date;
   gradeAutomatically: boolean;
   gradingCategoryId: number | null;
   isAssigned: boolean;
   lockAfterClosed: boolean;
   requireSecretCode: boolean;
   secretCode: string;
   startDate: Date;
   sectionDatesEnabled: boolean;
   sectionDates: SectionDates;
   timeLimit: number;
   timeLimitEnabled: boolean;
}

const BulkEditModal: React.FC<BulkEditModalProps> = ({
   courseEndDate,
   courseStartDate,
   defaultAssignment,
   gradingCategories,
   sections,
   selectedItems,
   createGradingCategory,
   onClose,
   onSave,
}) => {
   const [isGradeCategoryLoading, setIsGradeCategoryLoading] = React.useState<boolean>(false);
   const [propertiesToEdit, setPropertiesToEdit] = React.useState<readonly EditableProperty[]>([]);
   const [values, setValues] = React.useState<AssignmentProperties>({
      allowLateSubmission: false,
      allowMultipleAttempts: false,
      attemptsAllowed: null,
      endDate: null,
      gradeAutomatically: false,
      gradingCategoryId: null,
      isAssigned: false,
      lockAfterClosed: false,
      requireSecretCode: false,
      secretCode: '',
      sectionDates: null,
      sectionDatesEnabled: false,
      startDate: null,
      timeLimit: null,
      timeLimitEnabled: false,
   });
   const [datesValid, setDatesValid] = React.useState({
      startDate: true,
      endDate: true,
      sectionDates: true,
   });

   const shouldEdit = (property: EditableProperty): boolean => propertiesToEdit.includes(property);

   const showTimeLimitInput =
      shouldEdit(EditableProperty.timeLimitEnabled) && values.timeLimitEnabled;
   const showSecretCodeInput =
      shouldEdit(EditableProperty.requireSecretCode) && values.requireSecretCode;
   const showSectionDates =
      shouldEdit(EditableProperty.sectionDatesEnabled) && !_.isEmpty(values.sectionDates);
   const showAttemptsAllowedInput =
      shouldEdit(EditableProperty.allowMultipleAttempts) && values.allowMultipleAttempts;
   const attemptsAllowedError =
      shouldEdit(EditableProperty.allowMultipleAttempts) &&
      values.allowMultipleAttempts &&
      getAttemptsAllowedError(values.attemptsAllowed);
   const timeLimitError =
      shouldEdit(EditableProperty.timeLimitEnabled) && getTimeLimitError(values.timeLimit);
   const secretCodeError =
      shouldEdit(EditableProperty.requireSecretCode) &&
      showSecretCodeInput &&
      getSecretCodeError(values.secretCode);

   const startEndDatesValid =
      (datesValid.startDate && datesValid.endDate) || !shouldEdit(EditableProperty.dates);
   const sectionDatesValue = datesValid.sectionDates || !showSectionDates;
   const isValid =
      startEndDatesValid &&
      sectionDatesValue &&
      !attemptsAllowedError &&
      !timeLimitError &&
      !secretCodeError;

   const gradingCategoryOptions = gradingCategories.map(({ name, id }) => ({
      label: name,
      value: id,
   }));
   const gradingCategory = gradingCategoryOptions.find((i) => i.value === values.gradingCategoryId);

   React.useEffect(() => {
      const update: Partial<AssignmentProperties> = {};
      if (!shouldEdit(EditableProperty.allowLateSubmission)) {
         update.allowLateSubmission = false;
      }

      if (!shouldEdit(EditableProperty.allowMultipleAttempts)) {
         update.allowMultipleAttempts = false;
         update.attemptsAllowed = 1;
      } else if (
         shouldEdit(EditableProperty.allowMultipleAttempts) &&
         values.allowMultipleAttempts &&
         values.attemptsAllowed === 1
      ) {
         update.attemptsAllowed = 2;
      }

      if (!shouldEdit(EditableProperty.dates)) {
         update.startDate = null;
         update.endDate = null;
      } else if (shouldEdit(EditableProperty.dates)) {
         if (values.startDate === null) {
            update.startDate = defaultAssignment.startDate;
         }
         if (values.endDate === null) {
            update.endDate = defaultAssignment.endDate;
         }
      }

      if (!shouldEdit(EditableProperty.gradeAutomatically)) {
         update.gradeAutomatically = false;
      }

      if (!shouldEdit(EditableProperty.gradingCategoryId)) {
         update.gradingCategoryId = null;
      }

      if (!shouldEdit(EditableProperty.isAssigned)) {
         update.isAssigned = false;
      }

      if (!shouldEdit(EditableProperty.lockAfterClosed)) {
         update.lockAfterClosed = false;
      }

      if (!shouldEdit(EditableProperty.requireSecretCode) || !values.requireSecretCode) {
         update.requireSecretCode = false;
         update.secretCode = '';
      } else if (shouldEdit(EditableProperty.requireSecretCode) && values.secretCode === '') {
         update.secretCode = randomDistinguishableCode();
      }

      if (!shouldEdit(EditableProperty.timeLimitEnabled)) {
         update.timeLimitEnabled = false;
         update.timeLimit = null;
      } else if (shouldEdit(EditableProperty.timeLimitEnabled) && values.timeLimit === null) {
         update.timeLimit = 60;
      }

      if (!shouldEdit(EditableProperty.sectionDatesEnabled) || !values.sectionDatesEnabled) {
         update.sectionDatesEnabled = false;
         update.sectionDates = null;
      } else if (
         shouldEdit(EditableProperty.sectionDatesEnabled) &&
         values.sectionDatesEnabled &&
         values.sectionDates === null
      ) {
         update.sectionDates = Object.fromEntries(
            sections.map((section) => {
               const startDate =
                  shouldEdit(EditableProperty.dates) && values.startDate !== null
                     ? values.startDate
                     : defaultAssignment.startDate;
               const endDate =
                  shouldEdit(EditableProperty.dates) && values.endDate !== null
                     ? values.endDate
                     : defaultAssignment.endDate;
               const sectionEndDate = new Date(endDate);
               if (section.defaultDueTime) {
                  sectionEndDate.setHours(section.defaultDueTime.getHours());
                  sectionEndDate.setMinutes(section.defaultDueTime.getMinutes());
                  sectionEndDate.setSeconds(0);
               }
               return [section.id, { startDate, endDate: sectionEndDate }];
            }),
         );
         setDatesValid((prevDatesValid) => ({
            ...prevDatesValid,
            sectionDates: checkSectionDatesValid(
               update.sectionDates,
               courseStartDate,
               courseEndDate,
            ),
         }));
      }

      if (!_.isEmpty(update)) {
         setValues((prevValues) => ({ ...prevValues, ...update }));
      }
   }, [
      defaultAssignment.endDate,
      defaultAssignment.startDate,
      propertiesToEdit,
      sections,
      values.allowMultipleAttempts,
      values.attemptsAllowed,
      values.endDate,
      values.isAssigned,
      values.requireSecretCode,
      values.secretCode,
      values.sectionDates,
      values.sectionDatesEnabled,
      values.startDate,
      values.timeLimit,
   ]);

   const handleSave = (): void => {
      if (!isValid) {
         return;
      }
      const result: BulkEditResult = { assignment: {} };
      if (shouldEdit(EditableProperty.isAssigned)) {
         result.isAssigned = values.isAssigned;
      }
      if (shouldEdit(EditableProperty.allowLateSubmission)) {
         result.assignment.allowLateSubmission = values.allowLateSubmission;
      }
      if (shouldEdit(EditableProperty.allowMultipleAttempts)) {
         result.assignment.attemptsAllowed = values.attemptsAllowed;
      }
      if (shouldEdit(EditableProperty.dates)) {
         result.assignment.startDate = values.startDate;
         result.assignment.endDate = values.endDate;
      }
      if (shouldEdit(EditableProperty.gradeAutomatically)) {
         result.assignment.gradeAutomatically = values.gradeAutomatically;
      }
      if (shouldEdit(EditableProperty.gradingCategoryId)) {
         result.assignment.gradingCategoryId = values.gradingCategoryId;
      }
      if (shouldEdit(EditableProperty.lockAfterClosed)) {
         result.assignment.lockAfterClosed = values.lockAfterClosed;
      }
      if (shouldEdit(EditableProperty.requireSecretCode)) {
         result.assignment.secretCode = values.secretCode;
      }
      if (shouldEdit(EditableProperty.sectionDatesEnabled)) {
         result.assignment.sectionDates = values.sectionDates;
      }
      if (shouldEdit(EditableProperty.timeLimitEnabled)) {
         result.assignment.timeLimit = values.timeLimit;
      }
      onSave(result);
   };

   const toggleEditProperty = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const property = event.target.name as EditableProperty;
      if (propertiesToEdit.includes(property)) {
         setPropertiesToEdit((prevPropertiesToEdit) =>
            prevPropertiesToEdit.filter((i) => i !== property),
         );
      } else {
         setPropertiesToEdit((prevPropertiesToEdit) => [...prevPropertiesToEdit, property]);
      }
   };

   const updateValue = (update: Partial<AssignmentProperties>): void => {
      setValues((prevValues) => ({ ...prevValues, ...update }));
   };

   const handleCreateCategory = async (inputValue: string): Promise<void> => {
      if (!isGradeCategoryLoading) {
         setIsGradeCategoryLoading(true);
         const gradingCategoryId = await createGradingCategory(inputValue);
         updateValue({ gradingCategoryId });
         setIsGradeCategoryLoading(false);
      }
   };

   const handleCategoryChange = (option: { value: number; label: string }): void => {
      updateValue({ gradingCategoryId: option ? option.value : null });
   };

   const handleSectionDateUpdate =
      (sectionId: number | string) => (startDate: Date, endDate: Date) => {
         setValues((prevValues) => ({
            ...prevValues,
            sectionDates: {
               ...prevValues.sectionDates,
               [sectionId]: {
                  ...prevValues.sectionDates[sectionId],
                  startDate,
                  endDate,
               },
            },
         }));
         setDatesValid((prevDatesValid) => ({
            ...prevDatesValid,
            sectionDates: true,
         }));
      };

   const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked, name, type, value } = event.target;
      const fixedValue = type === 'number' ? Number(value) : value;
      updateValue({ [name]: type === 'checkbox' ? checked : fixedValue });
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   const handleNumberBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (event.target.value === '') {
         event.target.value = event.target.min ?? '0';
         handleValueChange(event);
      } else if (+event.target.min > +event.target.value) {
         event.target.value = event.target.min;
         handleValueChange(event);
      }
   };

   const handleInvalidDate = (): void => {
      setDatesValid((prevDatesValid) => ({
         ...prevDatesValid,
         startDate: false,
         endDate: false,
      }));
   };

   const handleInvalidSectionDate = (): void => {
      setDatesValid((prevDatesValid) => ({
         ...prevDatesValid,
         sectionDates: false,
      }));
   };

   const handleDateUpdate = (startDate: Date, endDate: Date): void => {
      setValues((prevValues) => ({ ...prevValues, startDate, endDate }));
      setDatesValid((prevDatesValid) => ({
         ...prevDatesValid,
         startDate: true,
         endDate: true,
      }));
   };

   const renderTogglePropertyCheckbox = (property: EditableProperty): React.ReactNode => (
      <input
         checked={shouldEdit(property)}
         className='edit-property'
         name={property}
         onChange={toggleEditProperty}
         type='checkbox'
      />
   );

   const renderAssignmentDatePicker = (
      startDateProvided: AssignmentDatePickerProvided,
      startDateError: Maybe<string>,
      endDateProvided: AssignmentDatePickerProvided,
      endDateError: Maybe<string>,
   ): JSX.Element => (
      <div className='assignment-dates-wrapper'>
         <div className='start-date-wrapper'>
            <label className='field-title'>Start Date</label>
            <DateTimePicker {...startDateProvided} />
            {startDateError && <p className='error'>{startDateError}</p>}
         </div>
         <div className='end-date-wrapper'>
            <label className='field-title'>End Date</label>
            <DateTimePicker {...endDateProvided} />
            {endDateError && <p className='error'>{endDateError}</p>}
         </div>
      </div>
   );

   return (
      <ModalDialog
         actions={[
            {
               text: 'Save',
               onClick: handleSave,
               disabled: !isValid,
               keyboardShortcut: 'mod+enter',
            },
            { text: 'Cancel', onClick: onClose },
         ]}
         onClose={onClose}
         appearance={Appearance.primary}
         bodyClassName='bulk-edit-modal'
         heading='Bulk Item Edit'
      >
         <div className='row'>
            <div className='col-xs-12'>
               <div className='directions'>
                  <IconInterfaceInformation />
                  <span>
                     Select the properties that you want to overwrite for the{' '}
                     <strong>{selectedItems.length} items</strong> selected
                  </span>
               </div>
            </div>
         </div>
         <div className='row margin-top-s'>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.isAssigned)}
                  <Switch
                     checked={values.isAssigned}
                     disabled={!shouldEdit(EditableProperty.isAssigned)}
                     name={EditableProperty.isAssigned}
                     onChange={handleValueChange}
                     title='Assign to Class'
                  />
               </div>
            </div>
         </div>
         <div className='bulk-edit-section-heading'>
            <IconCalendar1 />
            <div className='title'>Availability</div>
         </div>
         <div className='assignment-dates-section'>
            {renderTogglePropertyCheckbox(EditableProperty.dates)}
            <AssignmentDatePicker
               courseEndDate={courseEndDate}
               courseStartDate={courseStartDate}
               disabled={!shouldEdit(EditableProperty.dates)}
               allowNull={!shouldEdit(EditableProperty.dates)}
               endDate={values.endDate}
               onInvalidDate={handleInvalidDate}
               onValidDateChange={handleDateUpdate}
               startDate={values.startDate}
            >
               {renderAssignmentDatePicker}
            </AssignmentDatePicker>
         </div>
         {!!sections.length && (
            <div className='row margin-top-s'>
               <div className='col-xs-12 col-sm-6'>
                  <div className='flex-align-center'>
                     {renderTogglePropertyCheckbox(EditableProperty.sectionDatesEnabled)}
                     <Switch
                        checked={values.sectionDatesEnabled}
                        disabled={!shouldEdit(EditableProperty.sectionDatesEnabled)}
                        name={EditableProperty.sectionDatesEnabled}
                        onChange={handleValueChange}
                        title='Set dates by section'
                     />
                  </div>
               </div>
            </div>
         )}
         {showSectionDates &&
            sections.map((section) => (
               <div className='section-dates-section' key={section.id}>
                  <div className='title small'>{section.name}</div>
                  <div className='section-dates'>
                     <AssignmentDatePicker
                        courseEndDate={courseEndDate}
                        courseStartDate={courseStartDate}
                        endDate={values.sectionDates[section.id].endDate}
                        onInvalidDate={handleInvalidSectionDate}
                        onValidDateChange={handleSectionDateUpdate(section.id)}
                        startDate={values.sectionDates[section.id].startDate}
                     >
                        {renderAssignmentDatePicker}
                     </AssignmentDatePicker>
                  </div>
               </div>
            ))}
         <div className='row margin-top-s'>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.allowLateSubmission)}
                  <Switch
                     checked={values.allowLateSubmission}
                     disabled={!shouldEdit(EditableProperty.allowLateSubmission)}
                     name={EditableProperty.allowLateSubmission}
                     onChange={handleValueChange}
                     title='Allow late submissions'
                  />
               </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.lockAfterClosed)}
                  <Switch
                     checked={values.lockAfterClosed}
                     disabled={!shouldEdit(EditableProperty.lockAfterClosed)}
                     name={EditableProperty.lockAfterClosed}
                     onChange={handleValueChange}
                     title='Lock after close'
                  />
               </div>
            </div>
         </div>
         <div className='row margin-top-s'>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.timeLimitEnabled)}
                  <Switch
                     checked={values.timeLimitEnabled}
                     disabled={!shouldEdit(EditableProperty.timeLimitEnabled)}
                     name='timeLimitEnabled'
                     onChange={handleValueChange}
                     title='Set time limit'
                  />
               </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.requireSecretCode)}
                  <Switch
                     checked={values.requireSecretCode}
                     disabled={!shouldEdit(EditableProperty.requireSecretCode)}
                     name={EditableProperty.requireSecretCode}
                     onChange={handleValueChange}
                     title='Require secret code'
                  />
               </div>
            </div>
         </div>
         <div className='row'>
            {showTimeLimitInput && (
               <div className='col-xs-12 col-sm-6'>
                  <div className='secondary-field'>
                     <label className='field-title no-margin-top'>Time Limit (Min)</label>
                     <NumberInput
                        className={(value) =>
                           classnames('time-limit-input', {
                              error: value < MIN_TIME_LIMIT,
                           })
                        }
                        integer
                        min={MIN_TIME_LIMIT}
                        onBlur={handleNumberBlur}
                        onFocus={handleFocus}
                        onValueChange={(timeLimit) => updateValue({ timeLimit })}
                        value={values.timeLimit}
                     />
                     {timeLimitError && <p className='error'>{timeLimitError}</p>}
                  </div>
               </div>
            )}
            {showSecretCodeInput && (
               <div
                  className={classnames(
                     'col-xs-12 col-sm-6',
                     !showTimeLimitInput && 'col-sm-offset-6',
                  )}
               >
                  <div className='secondary-field'>
                     <label className='field-title no-margin-top'>Secret Code</label>
                     <input
                        className={classnames('secret-code-input', {
                           error: !!secretCodeError,
                        })}
                        maxLength={MAX_SECRET_CODE_LENGTH}
                        name='secretCode'
                        onChange={handleValueChange}
                        onFocus={handleFocus}
                        type='text'
                        value={values.secretCode ?? ''}
                     />
                     {secretCodeError && <p className='error'>{secretCodeError}</p>}
                  </div>
               </div>
            )}
         </div>
         <div className='bulk-edit-section-heading'>
            <IconGrade />
            <div className='title'>Grading</div>
         </div>
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.gradeAutomatically)}
                  <Switch
                     checked={values.gradeAutomatically}
                     disabled={!shouldEdit(EditableProperty.gradeAutomatically)}
                     name={EditableProperty.gradeAutomatically}
                     onChange={handleValueChange}
                     title='Grade Automatically'
                  />
               </div>
            </div>
            <div className='col-xs-12 col-sm-6'>
               <div className='flex-align-center'>
                  {renderTogglePropertyCheckbox(EditableProperty.allowMultipleAttempts)}
                  <Switch
                     checked={values.allowMultipleAttempts}
                     disabled={!shouldEdit(EditableProperty.allowMultipleAttempts)}
                     name='allowMultipleAttempts'
                     onChange={handleValueChange}
                     title='Allow Multiple Attempts'
                  />
               </div>
            </div>
         </div>
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               {renderTogglePropertyCheckbox(EditableProperty.gradingCategoryId)}
               <label className='field-title'>Grading Category</label>
               <div className='grading-category-selector'>
                  <CreatableSelect
                     className='react-select'
                     components={selectComponents}
                     isClearable
                     isDisabled={!shouldEdit(EditableProperty.gradingCategoryId)}
                     isLoading={isGradeCategoryLoading}
                     menuPortalTarget={window.document.body}
                     noOptionsMessage={() => 'No Grading Categories'}
                     onChange={handleCategoryChange}
                     onCreateOption={handleCreateCategory}
                     options={gradingCategoryOptions}
                     placeholder={shouldEdit(EditableProperty.gradingCategoryId) ? 'Select...' : ''}
                     styles={{
                        ...selectStyle,
                        menuPortal: (styles) => ({ ...styles, zIndex: 1001 }),
                     }}
                     theme={selectTheme}
                     value={gradingCategory || null}
                  />
               </div>
            </div>
            {showAttemptsAllowedInput && (
               <div className='col-xs-12 col-sm-6'>
                  <div className='secondary-field'>
                     <label className='field-title'>Attempts Allowed</label>
                     <NumberInput
                        className={(value) =>
                           classnames('attempts-allowed-input', {
                              error: value < MIN_ATTEMPTS_ALLOWED,
                           })
                        }
                        integer
                        min={MIN_ATTEMPTS_ALLOWED}
                        onBlur={handleNumberBlur}
                        onFocus={handleFocus}
                        onValueChange={(attemptsAllowed) => updateValue({ attemptsAllowed })}
                        value={values.attemptsAllowed}
                     />
                     {attemptsAllowedError && <p className='error'>{attemptsAllowedError}</p>}
                  </div>
               </div>
            )}
         </div>
      </ModalDialog>
   );
};

export default BulkEditModal;
