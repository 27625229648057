import * as _ from 'lodash';
import * as React from 'react';

import elementReady from '@helpers/ElementReady';
import elementRemoved from '@helpers/ElementRemoved';
import { IApplicationState } from '@models/ApplicationState';
import { Maybe } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import IUserInfo from '@models/IUserInfo';
import UserService from '@services/UserService';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
   IOnboardingProps,
   IOnboardingTask,
   IOnboardingWalkthrough,
   OnboardingTask,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from './Models';
import { OnboardingContext } from './OnboardingContext';
import Walkthroughs from './Walkthroughs';

interface OnboardingProviderProps {
   children: React.ReactNode;
}

const OnboardingProvider: React.FC<OnboardingProviderProps> = ({ children }) => {
   const navigate = useNavigate();
   const location = useLocation();

   const currentCourses = useSelector<IApplicationState, readonly BasicCourseProfile[]>(
      (state) => state.currentCourses,
   );

   const user = useSelector<IApplicationState, Maybe<IUserInfo>>((state) => state.user);

   const completedOnboardingTasks = user?.userProfile?.completedOnboardingTasks ?? [];

   const [state, setState] = React.useState<IOnboardingProps>({
      demoCourseId: null,
      stepId: null,
      walkthrough: null,
      taskState: OnboardingTaskState.closed,
      walkthroughVisible: false,
      observers: [],
      checklistOpen: false,
      completedOnboardingTasks: [],
      task: null,
      clearCurrentTask: _.noop,
      nextStep: _.noop,
      onStateUpdate: _.noop,
      previousStep: _.noop,
      setChecklistOpen: _.noop,
      setStepId: _.noop,
      setTask: _.noop,
      setWalkthroughVisible: _.noop,
      startWalkthrough: _.noop,
      walkthroughIsComplete: () => false,
   });

   const [checklistOpen, setChecklistOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      const demoCourse = currentCourses.find((i) => i.demo && /Lingco.*Demo/i.test(i.name));
      setState((prevState) => ({
         ...prevState,
         demoCourseId: demoCourse !== undefined ? demoCourse.id : null,
      }));
   }, [currentCourses]);

   React.useEffect(() => {
      if (completedOnboardingTasks.length) {
         setChecklistOpen(true);
      }
   }, [completedOnboardingTasks]);

   React.useEffect(() => {
      if (state.stepId !== null && state.walkthrough !== null) {
         const {
            shouldAdvance,
            advanceDelay,
            advanceWhenVisible,
            advanceWhenHidden,
            hideWhenRemoved,
            hideWhenVisible,
         } = state.walkthrough?.steps.find((i) => i.id === state.stepId) ?? {};

         if (hideWhenRemoved) {
            handleHideWhenRemoved(hideWhenRemoved);
         }

         if (hideWhenVisible) {
            handleHideWhenVisible(hideWhenVisible);
         }

         if (!shouldAdvance && advanceWhenVisible) {
            handleAdvanceWhenVisible(advanceWhenVisible, advanceDelay);
         }

         if (!shouldAdvance && advanceWhenHidden) {
            handleAdvanceWhenHidden(advanceWhenHidden, advanceDelay);
         }
      }
   }, [state.stepId, state.walkthrough]);

   React.useEffect(() => {
      navigateToWalkthroughStartRoute();
   }, [state.walkthrough]);

   React.useEffect(() => {
      const { walkthrough, stepId } = state;
      const pathname = applySampleIdsToLocationPath(location.pathname) as string;
      const step = walkthrough?.steps.find((s) => s.id === stepId);
      if (!step) {
         return;
      }
      const expectedPaths = applySampleIdsToLocationPaths(step.expectedLocationChanges);
      const startRoute = walkthrough?.startRoute
         ? applySampleIdsToLocationPath(walkthrough?.startRoute)
         : null;

      if (!walkthrough) {
         return;
      }

      if (expectedPaths.length === 1 && expectedPaths[0] === '*') {
         return;
      }

      let isExpectedLocationChange = true;

      const hasPathMatch = !!expectedPaths.filter((expectedPath) =>
         expectedPath instanceof RegExp ? expectedPath.test(pathname) : expectedPath === pathname,
      ).length;

      if (step) {
         isExpectedLocationChange = expectedPaths && expectedPaths.length > 0 && hasPathMatch;
      } else {
         isExpectedLocationChange = pathname === startRoute;
      }

      if (!isExpectedLocationChange) {
         clearCurrentTask();
      }
   }, [location]);

   React.useEffect(() => {
      if (state.walkthrough && state.taskState === OnboardingTaskState.completed) {
         UserService.completeWalkthrough(state.walkthrough.id);
         setState((prevState) => ({
            ...prevState,
            stepId: null,
            walkthrough: null,
            taskState: OnboardingTaskState.closed,
         }));
      }
   }, [state.taskState]);

   const applySampleIdsToLocationPath = (path?: string | RegExp): string | RegExp => {
      if (!path) {
         return '';
      }

      const idReplacer = (pathWithTokens: string): string =>
         pathWithTokens.replace(':courseId', (state.demoCourseId || '').toString());

      if (path instanceof RegExp) {
         let pathString = path.toString();
         pathString = pathString.substr(1, pathString.length - 2);
         pathString = idReplacer(pathString);
         return new RegExp(pathString, 'gm');
      }
      return idReplacer(path);
   };

   const applySampleIdsToLocationPaths = (
      locations?: readonly (string | RegExp)[],
   ): readonly (string | RegExp)[] =>
      locations ? locations.map(applySampleIdsToLocationPath) : [];

   const navigateToWalkthroughStartRoute = (): void => {
      const { walkthrough } = state;
      const startRoute = applySampleIdsToLocationPath(walkthrough?.startRoute) as string;
      if (walkthrough?.startRoute && location.pathname !== startRoute) {
         navigate(startRoute);
      }
   };

   const completeTask = (taskId: OnboardingTask): void => {
      UserService.completeOnboardingTask(taskId);
      setState((prevState) => ({
         ...prevState,
         task: null,
         taskState: OnboardingTaskState.completed,
      }));
   };

   const nextStep = (): void => {
      const currentStep = state.walkthrough?.steps.find((i) => i.id === state.stepId);
      currentStep?.beforeUnmount?.();
      const nextStepId = getNextStepId();
      if (nextStepId) {
         setStepId(nextStepId);
      }
   };

   const previousStep = (): void => {
      const currentStep = state.walkthrough?.steps.find((i) => i.id === state.stepId);
      currentStep?.beforeUnmount?.();
      const previousStepId = getPreviousStepId();
      if (previousStepId) {
         setStepId(previousStepId);
      }
   };

   const getOnboardingClassName = (stepId: string): string => {
      if (state.stepId === null || !state.walkthrough || state.stepId !== stepId) {
         return '';
      }
      const step = state.walkthrough?.steps.find((i) => i.id === state.stepId);
      return step?.className ? step.className : '';
   };

   const getNextStepId = (): Maybe<string> => {
      if (state.stepId === null || !state.walkthrough) {
         return undefined;
      }
      const index = state.walkthrough?.steps.findIndex((i) => i.id === state.stepId);
      if (index !== undefined && index + 1 < state.walkthrough.steps.length) {
         return state.walkthrough?.steps[index + 1].id;
      }
      return null;
   };

   const getPreviousStepId = (): Maybe<string> => {
      if (state.stepId === null || !state.walkthrough) {
         return undefined;
      }
      const index = state.walkthrough?.steps.findIndex((i) => i.id === state.stepId);
      if (_.isInteger(index) && index > 0) {
         return state.walkthrough.steps[index - 1].id;
      }
      return null;
   };

   const handleStateUpdate = (
      newState: unknown,
      prevState: unknown,
      newProps: unknown,
      prevProps: unknown,
   ): void => {
      if (state.stepId === null || !state.walkthrough?.id) {
         return;
      }
      const walkthrough = Walkthroughs[state.walkthrough.id].steps.find(
         (i) => i.id === state.stepId,
      );

      if (!walkthrough) {
         return;
      }

      const { shouldAdvance, advanceDelay, advanceWhenHidden, advanceWhenVisible } = walkthrough;

      if (shouldAdvance && shouldAdvance(newState, prevState, newProps, prevProps)) {
         if (advanceWhenVisible) {
            handleAdvanceWhenVisible(advanceWhenVisible, advanceDelay);
         } else if (advanceWhenHidden) {
            handleAdvanceWhenHidden(advanceWhenHidden, advanceDelay);
         } else if (advanceDelay && advanceDelay > 0) {
            setTimeout(nextStep, 1000 * advanceDelay);
         } else {
            nextStep();
         }
      }
   };

   const addObserver = (observer: MutationObserver): void => {
      setState((prevState) => ({
         ...prevState,
         observers: [...(prevState.observers || []), observer],
      }));
   };

   const handleHideWhenRemoved = (selector: string): void => {
      elementRemoved({ selector, createdCallback: addObserver }).then(() => {
         setState((prevState) => ({
            ...prevState,
            walkthroughVisible: false,
         }));
      });
   };

   const handleHideWhenVisible = (selector: string): void => {
      elementReady(selector, addObserver).then(() => {
         setState((prevState) => ({
            ...prevState,
            walkthroughVisible: false,
         }));
      });
   };

   const handleAdvanceWhenVisible = (selector: string, delay?: number): void => {
      elementReady(selector, addObserver).then(() => {
         if (delay && delay > 0) {
            setTimeout(nextStep, 1000 * delay);
         } else {
            nextStep();
         }
      });
   };

   const handleAdvanceWhenHidden = (selector: string, delay?: number): void => {
      elementRemoved({ selector, createdCallback: addObserver }).then(() => {
         if (delay && delay > 0) {
            setTimeout(nextStep, 1000 * delay);
         } else {
            nextStep();
         }
      });
   };

   const setWalkthroughId = (walkthroughId: OnboardingWalkthrough | null): void => {
      if (walkthroughId !== null) {
         const walkthrough = Walkthroughs[walkthroughId];
         walkthrough.startRoute = applySampleIdsToLocationPath(walkthrough.startRoute) as string;
         setState((prevState) => ({
            ...prevState,
            walkthrough,
            taskState: OnboardingTaskState.initialized,
            stepId: null,
            walkthroughVisible: false,
         }));
      } else {
         setState((prevState) => ({
            ...prevState,
            walkthrough: null,
            taskState: OnboardingTaskState.closed,
            stepId: null,
            walkthroughVisible: false,
         }));
      }
   };

   const setStepId = (stepId: string | null): void => {
      setState((prevState) => {
         const isComplete =
            prevState.walkthrough && isWalkthroughComplete(prevState.walkthrough, prevState.stepId);
         const completedState = isComplete
            ? OnboardingTaskState.completed
            : OnboardingTaskState.closed;
         return {
            ...prevState,
            stepId,
            taskState: stepId !== null ? OnboardingTaskState.inProgress : completedState,
            walkthroughVisible: true,
         };
      });
   };

   const setWalkthroughVisible = (walkthroughVisible: boolean): void => {
      setState((prevState) => ({ ...prevState, walkthroughVisible }));
   };

   const walkthroughIsComplete = (walkthroughId: OnboardingWalkthrough): boolean => {
      if (walkthroughId !== state.walkthrough?.id) {
         return false;
      }
      return isWalkthroughComplete(state.walkthrough, state.stepId);
   };

   const setTask = (task: IOnboardingTask): void => {
      setState((prevState) => ({ ...prevState, task }));
      setWalkthroughId(task.walkthrough.id);
      navigateToWalkthroughStartRoute();
   };

   const clearCurrentTask = (): void => {
      state.observers.forEach((o) => {
         o.disconnect();
      });

      setState((prevState) => ({
         ...prevState,
         observers: [],
      }));

      if (
         state.walkthrough &&
         state.task?.id &&
         isWalkthroughComplete(state.walkthrough, state.stepId)
      ) {
         completeTask(state.task.id);
      } else {
         setWalkthroughId(null);
         setStepId(null);
      }
   };

   const startWalkthrough = (): void => {
      setState((prevState) => ({
         ...prevState,
         taskState: OnboardingTaskState.started,
         walkthroughVisible: true,
      }));
   };

   return (
      <OnboardingContext.Provider
         value={{
            checklistOpen,
            completedOnboardingTasks,
            demoCourseId: state.demoCourseId,
            stepId: state.stepId,
            walkthrough: state.walkthrough,
            walkthroughVisible: state.walkthroughVisible,
            task: state.task,
            taskState: state.taskState,
            observers: state.observers,
            clearCurrentTask,
            nextStep,
            onStateUpdate: handleStateUpdate,
            previousStep,
            getOnboardingClassName,
            setChecklistOpen,
            setStepId,
            setTask,
            setWalkthroughVisible,
            startWalkthrough,
            walkthroughIsComplete,
         }}
      >
         {children}
      </OnboardingContext.Provider>
   );
};

export default OnboardingProvider;

const isWalkthroughComplete = (
   { steps, isComplete }: IOnboardingWalkthrough,
   stepId: Maybe<string>,
): boolean => (isComplete ? isComplete(stepId) : stepId === steps[steps.length - 1].id);
