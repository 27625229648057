// @ts-strict-ignore
import * as debugLib from 'debug';

export const defaultNamespace = 'lingco';

class LogService {
   private readonly logger;
   public namespace: string;

   constructor(namespace: string) {
      this.logger = debugLib.default(namespace);
      this.namespace = namespace;
   }

   public debug(...args): void {
      this.logger(...args);
   }

   public extend(childNamespace: string): LogService {
      return new LogService(`${this.namespace}:${childNamespace}`);
   }
}

export const logger = new LogService(defaultNamespace);
