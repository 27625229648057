import * as _ from 'lodash';
import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import Link from '@components/Common/Link';
import ContentIcon from '@components/ContentIcon';
import Droplist, { IMenuItem } from '@components/Core/Droplist';
import ModalDialog from '@components/Core/ModalDialog';
import SelectFolderModal from '@components/SelectFolderModal';
import IconOptionGrey from '@icons/general/icon-option-gray.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import IconFileShare1 from '@icons/nova-line/85-Files-Basic/file-share-1.svg';
import IconFileSwap from '@icons/nova-line/85-Files-Basic/file-swap.svg';
import IconTextInput1 from '@icons/nova-solid/52-Text/text-input-1.svg';
import Appearance from '@models/Appearance';
import ContentItemProfile from '@models/ContentItemProfile';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import ContentLibraryName from '@models/ContentLibraryName';
import DraggableType from '@models/DraggableType';
import ContentService from '@services/ContentService';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';

import { AppStateContext } from '../../AppState';
import { ContentLibraryContext } from './ContentLibraryContext';
import ShareContentModal from './ShareContentModal';

interface ContentTileProps {
   canClone: boolean;
   canEdit: boolean;
   inGlobalLibrary: boolean;
   inSchoolLibrary: boolean;
   isCreator: boolean;
   item: ContentItemProfile;
   deleteItem?(): void;
   duplicateItem(): void;
   modifyGlobalLibrary(isGlobal: boolean): void;
   modifySchoolLibrary(inSchool: boolean): void;
   moveItem(folderId: number): void;
   renameItem(name: string): void;
}

const ContentTile: React.FC<ContentTileProps> = ({
   canClone,
   canEdit,
   inGlobalLibrary,
   inSchoolLibrary,
   isCreator,
   item: { imageUrl, itemLanguage, itemName, itemType, itemId, folderId },
   item,
   deleteItem,
   duplicateItem,
   modifyGlobalLibrary,
   modifySchoolLibrary,
   moveItem,
   renameItem,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const isAdmin = userProfile?.isAdmin;

   const { folders, activeLibrary, layoutView } =
      React.useContext<ContentLibraryContext>(ContentLibraryContext);

   const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
   const [renameModalOpen, setRenameModalOpen] = React.useState<boolean>(false);
   const [shareModalOpen, setShareModalOpen] = React.useState<boolean>(false);
   const [selectFolderModalOpen, setSelectFolderModalOpen] = React.useState<boolean>(false);
   const [rename, setRename] = React.useState<string>('');

   const handleDeleteItem = (): void => {
      deleteItem?.();
      toggleDeleteModal();
   };

   const droplistItemFactory = (): readonly IMenuItem[] => {
      const items: IMenuItem[] = [];
      if (isCreator || canEdit || isAdmin) {
         items.push(
            ...[
               {
                  text: 'Edit',
                  to: generateEditLink(),
                  icon: <IconHighlight className='icon-gray' />,
               },
               {
                  text: 'Rename',
                  onClick: openRenameModal,
                  icon: <IconTextInput1 className='icon-gray' />,
               },
            ],
         );
      }
      if (isCreator || isAdmin) {
         if (!inGlobalLibrary) {
            items.push({
               text: 'Delete',
               onClick: toggleDeleteModal,
               icon: <IconBin className='icon-grey' />,
            });
         }

         items.push({
            text: 'Share',
            onClick: toggleShareModal,
            icon: <IconFileShare1 className='icon-grey' />,
         });

         if (activeLibrary === ContentLibraryName.personal) {
            items.push({
               text: 'Move',
               onClick: openSelectFolderModal,
               icon: <IconFileSwap className='icon-gray' />,
            });
         }
      }
      if ((isCreator || canClone || isAdmin) && item.itemType !== 'video') {
         items.push({
            text: 'Duplicate',
            onClick: duplicateItem,
            icon: <IconFileCopy className='icon-gray' />,
         });
      }
      return items;
   };

   const generateEditLink = (): string => ContentService.getEditUrl(item);

   const generateViewLink = (): string => ContentService.getViewUrl(item);

   const handleRenameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setRename(event.target.value);
   };

   const toggleDeleteModal = (): void => {
      setDeleteModalOpen((prevDeleteModalOpen) => !prevDeleteModalOpen);
   };

   const toggleShareModal = (): void => {
      setShareModalOpen((prevShareModalOpen) => !prevShareModalOpen);
   };

   const closeRenameModal = (): void => {
      setRenameModalOpen(false);
      setRename('');
   };

   const openRenameModal = (): void => {
      setRename(itemName);
      setRenameModalOpen(true);
   };

   const handleRenameItem = (): void => {
      renameItem(rename);
      closeRenameModal();
   };

   const closeSelectFolderModal = (): void => {
      setSelectFolderModalOpen(false);
   };

   const openSelectFolderModal = (): void => {
      setSelectFolderModalOpen(true);
   };

   const handleFolderSelect = (
      _event: React.MouseEvent<HTMLButtonElement>,
      updatedFolderId: number,
   ): void => {
      moveItem(updatedFolderId);
      closeSelectFolderModal();
   };

   const droplistItems = droplistItemFactory();
   const imageSrc = imageUrl ? imageUrl : `https://picsum.photos/id/${itemId % 200}/400/300`;
   const iconClass = classnames('content-tile-type', _.kebabCase(itemType));

   const [{ opacity }, drag] = useDrag(() => ({
      type: DraggableType.CONTENT_FILE,
      item: () => ({ id: itemId }),
      collect: (monitor) => ({
         opacity: monitor.isDragging() ? 0.5 : 1,
      }),
   }));

   return (
      <>
         <div ref={drag} className='tile' style={{ opacity }}>
            <div className='card content-library'>
               <Link to={generateViewLink()}>
                  <div className='content-tile-img'>
                     <div className={iconClass}>
                        <ContentIcon itemType={itemType} />
                     </div>
                     {layoutView === ContentLibraryLayout.grid && (
                        <img draggable={false} src={imageSrc} alt={itemName} />
                     )}
                  </div>
               </Link>
               <div className='content-tile-title'>
                  <Link to={generateViewLink()}>
                     <div className='title' title={itemName}>
                        {itemName}
                     </div>
                  </Link>
               </div>
               <div className='content-tile-subtitle'>
                  {!!droplistItems.length && (
                     <Droplist items={droplistItems} pullRight>
                        <IconOptionGrey />
                     </Droplist>
                  )}
               </div>
            </div>
         </div>
         {deleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading={`Delete ${itemName}`}
               onClose={toggleDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: handleDeleteItem },
                  { text: 'Cancel', onClick: toggleDeleteModal },
               ]}
            >
               <p>
                  Are you sure that you want to delete <strong>{itemName}</strong>?
               </p>
            </ModalDialog>
         )}
         {renameModalOpen && (
            <ModalDialog
               appearance={Appearance.primary}
               heading={`Rename ${itemName}`}
               onClose={closeRenameModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Rename', onClick: handleRenameItem },
                  { text: 'Cancel', onClick: closeRenameModal },
               ]}
            >
               <AccentTextbox
                  autoFocus
                  language={itemLanguage}
                  name='rename'
                  value={rename}
                  onChange={handleRenameChange}
               />
            </ModalDialog>
         )}
         {shareModalOpen && (
            <ShareContentModal
               itemName={itemName}
               itemId={itemId}
               closeShareModal={toggleShareModal}
               inSchoolLibrary={inSchoolLibrary}
               inGlobalLibrary={inGlobalLibrary}
               modifySchoolLibrary={modifySchoolLibrary}
               modifyGlobalLibrary={modifyGlobalLibrary}
            />
         )}
         {selectFolderModalOpen && (
            <SelectFolderModal
               currentFolderId={folderId}
               folders={folders}
               closeModal={closeSelectFolderModal}
               onPrimaryClick={handleFolderSelect}
               primaryBtnText='Move'
               heading={`Move ${itemName}`}
            />
         )}
      </>
   );
};

export default ContentTile;
