import * as React from 'react';

import ArabLeagueFlag from '@icons/flags/arab-league.svg';
import ChinaFlag from '@icons/flags/cn.svg';
import GermanyFlag from '@icons/flags/de.svg';
import SpainFlag from '@icons/flags/es.svg';
import FranceFlag from '@icons/flags/fr.svg';
import IsraelFlag from '@icons/flags/il.svg';
import ItalyFlag from '@icons/flags/it.svg';
import JapanFlag from '@icons/flags/jp.svg';
import KoreaFlag from '@icons/flags/kr.svg';
import PortugalFlag from '@icons/flags/pt.svg';
import RussiaFlag from '@icons/flags/ru.svg';
import TurkeyFlag from '@icons/flags/tr.svg';
import VietnamFlag from '@icons/flags/vn.svg';
import { Maybe } from '@models/Core';
import Language from '@models/Language';

interface FlagProps {
   language: Language;
}

const Flag: React.FC<FlagProps> = ({ language }) => {
   const renderFlag = (): Maybe<React.ReactNode> => {
      switch (language) {
         case 'ar':
            return <ArabLeagueFlag />;
         case 'cmn':
            return <ChinaFlag />;
         case 'de':
            return <GermanyFlag />;
         case 'es':
            return <SpainFlag />;
         case 'fr':
            return <FranceFlag />;
         case 'he':
            return <IsraelFlag />;
         case 'it':
            return <ItalyFlag />;
         case 'ja':
            return <JapanFlag />;
         case 'ko':
            return <KoreaFlag />;
         case 'pt':
            return <PortugalFlag />;
         case 'ru':
            return <RussiaFlag />;
         case 'tr':
            return <TurkeyFlag />;
         case 'vi':
            return <VietnamFlag />;
      }
      return null;
   };

   return <div className='language-flag'>{renderFlag()}</div>;
};

export default React.memo(Flag);
