import 'pure-react-carousel/dist/react-carousel.es.css';

import * as React from 'react';

import IconArrowLeft from '@icons/general/icon-arrow-left.svg';
import IconArrowRight from '@icons/general/icon-arrow-right.svg';
import IconImageClose from '@icons/general/icon-image-close.svg';
import IconRadioTick from '@icons/general/icon-radio-tick.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconFileImagesView from '@icons/nova-solid/93-Files-Images/file-images-view.svg';
import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import {
   ButtonBack,
   ButtonNext,
   CarouselProvider,
   Image,
   Slide,
   Slider,
} from 'pure-react-carousel';

import Constants from '../../Constants';
import EmptyState from '@components/Core/EmptyState';
import Loader from '@components/Loader';

interface ImageSearchResult {
   id: number;
   thumbnail: string;
   url: string;
}

interface ImagePopupProps {
   imageUrl: Maybe<string>;
   show: boolean;
   removeImage(): void;
   setImageByFile(file: File): void;
   setImageByUrl(imageUrl: string): void;
}

const ImagePopup: React.FC<ImagePopupProps> = ({
   imageUrl,
   show,
   removeImage,
   setImageByFile,
   setImageByUrl,
}) => {
   const {
      keys: { enter },
   } = Constants;
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [noResults, setNoResults] = React.useState<boolean>(false);
   const [activeImageId, setActiveImageId] = React.useState<Maybe<number>>(null);
   const [searchResults, setSearchResults] = React.useState<readonly ImageSearchResult[]>([]);
   const fileInput = React.useRef(null);

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = () => {
            setImageByFile(file);
         };
         reader.readAsDataURL(file);
         setActiveImageId(null);
      }
   };

   const handleKeyDown = (event: React.KeyboardEvent): void => {
      if (event.key === enter && searchQuery.length) {
         setNoResults(false);
         setIsLoading(true);
         setSearchResults([]);
         HttpService.getWithAuthToken<{ results: ImageSearchResult[] }>(
            `/api/content/images?query=${searchQuery}`,
         ).then((response) => {
            const { results } = response.data;
            setSearchResults(results);
            setIsLoading(false);
            if (!results.length) {
               setNoResults(true);
            }
         });
      }
   };

   const handleRemoveImage = (): void => {
      setActiveImageId(null);
      removeImage();
   };

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value);
   };

   const handleImageSelect = (id: number): void => {
      const image = searchResults.find((i) => i.id === id);
      if (image) {
         setActiveImageId(id);
         setImageByUrl(image.thumbnail);
      }
   };

   if (!show) {
      return null;
   }

   return (
      <div className='dropdown-menu popup-image'>
         <div className='card-title has-button'>
            <div className='title'>Images</div>
            <input
               name='search'
               type='search'
               placeholder='Search Images'
               onKeyDown={handleKeyDown}
               onChange={handleSearchChange}
               value={searchQuery}
            />
            {/* <Button line>+ Upload</Button> */}
         </div>
         <div className='popup-content'>
            <div className='term-img-wrap'>
               {imageUrl && (
                  <div className='term-image-close' onClick={handleRemoveImage}>
                     <IconImageClose className='ignore-click' />
                  </div>
               )}
               <div className='term-img'>
                  {imageUrl ? (
                     <img src={imageUrl} />
                  ) : (
                     <input
                        type='file'
                        accept='image'
                        onChange={handleFileChange}
                        ref={fileInput}
                     />
                  )}
               </div>
            </div>
            {searchResults.length ? (
               <CarouselProvider
                  visibleSlides={3}
                  totalSlides={searchResults.length}
                  step={3}
                  naturalSlideWidth={125}
                  naturalSlideHeight={100}
                  hasMasterSpinner={false}
               >
                  <ButtonBack className='btn-back'>
                     <IconArrowLeft />
                  </ButtonBack>
                  <div className='carousel-container'>
                     <Slider>
                        {searchResults.map(({ id, url }, i) => (
                           <Slide index={i} key={id} className='slide'>
                              <div className='img-wrapper' onClick={() => handleImageSelect(id)}>
                                 <Image src={url} hasMasterSpinner={false} />
                              </div>
                              {activeImageId === id && <IconRadioTick className='selected' />}
                           </Slide>
                        ))}
                     </Slider>
                  </div>
                  <ButtonNext className='btn-next'>
                     <IconArrowRight />
                  </ButtonNext>
               </CarouselProvider>
            ) : (
               <div className='empty-carousel-state'>
                  {noResults && (
                     <EmptyState
                        icon={<IconBinoculars className='medium' aria-hidden />}
                        heading='No Results'
                        description='Try changing your filters.'
                     />
                  )}
                  {isLoading && (
                     <div className='loader-wrapper'>
                        <Loader />
                     </div>
                  )}
                  {!searchResults.length && !noResults && !isLoading && (
                     <EmptyState
                        icon={<IconFileImagesView className='medium' aria-hidden />}
                        description='Search for an Image'
                     />
                  )}
               </div>
            )}
         </div>
      </div>
   );
};

export default React.memo(ImagePopup);
