import * as _ from 'lodash';
import * as React from 'react';

const colors: readonly string[] = [
   'rgb(18, 113, 196)',
   'rgb(31, 140, 235)',
   'rgb(38, 166, 154)',
   'rgb(77, 208, 225)',
   'rgb(102, 187, 106)',
   'rgb(108, 228, 179)',
   'rgb(126, 97, 234)',
   'rgb(179, 163, 237)',
   'rgb(184, 241, 80)',
   'rgb(185, 220, 250)',
   'rgb(186, 104, 200)',
   'rgb(230, 82, 90)',
   'rgb(242, 108, 115)',
   'rgb(255, 64, 129)',
   'rgb(255, 171, 122',
   'rgb(255, 211, 122',
   'rgb(255, 230, 69)',
];

interface ConfettiProps {
   count?: number;
   minDuration?: number;
   maxDuration?: number;
   onAnimationEnd?(event: React.AnimationEvent<SVGSVGElement>): void;
}

const Confetti: React.FC<ConfettiProps> = ({
   minDuration = 500,
   maxDuration = 2000,
   count = 60,
   onAnimationEnd,
}) => {
   const durations = _.shuffle(
      [...Array(count).keys()].map((i) => minDuration + ((maxDuration - minDuration) / count) * i),
   );
   const maxIndex = durations.indexOf(_.max(durations) ?? minDuration);

   const handleAnimationEnd = (event: React.AnimationEvent<SVGSVGElement>): void => {
      onAnimationEnd?.(event);
   };

   return (
      <div className='onboarding-confetti-wrapper'>
         {[...Array(count).keys()].map((i) => (
            <svg
               key={i}
               viewBox='0 0 6.6 8.4'
               className='onboarding-confetti'
               style={{
                  animationDuration: `${durations[i]}ms`,
                  color: _.sample(colors),
                  left: `${(100 / count) * i}vw`,
               }}
               onAnimationEnd={(e) => (i === maxIndex ? handleAnimationEnd(e) : null)}
            >
               <path
                  fill='currentColor'
                  d='M-.017 6.91L4.035.012l2.587 1.52L2.57 8.43z'
                  transform='rotate(89, 3.3, 4.2)'
               />
            </svg>
         ))}
      </div>
   );
};

export default React.memo(Confetti);
