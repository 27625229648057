import * as _ from 'lodash';
import * as React from 'react';

import { numberWithCommas } from '@helpers/NumberUtils';
import Tippy from '@tippyjs/react';
import moment from 'moment';

import CalendarHeatmap from '@components/Core/CalendarHeatmap';
import { IVocabStat } from './StudentVocabStats';

interface QuestionsAnsweredHeatmapProps {
   data: readonly IVocabStat[];
}

const QuestionsAnsweredHeatmap: React.FC<QuestionsAnsweredHeatmapProps> = ({ data }) => {
   const totalQuestionsAnswered = _.sum(data.map((i) => i.questionsAnswered));
   const sortedQuestionsAnswered = _.sortBy(
      data.filter((i) => i.questionsAnswered),
      'questionsAnswered',
   );

   const minDay = _.min(data.map((i) => i.day));
   const maxDay = _.max(data.map((i) => i.day));

   const getClass = (value: IVocabStat): string => {
      if (!(value && value.questionsAnswered)) {
         return 'color-github-0';
      }
      const percentile =
         (_.findIndex(sortedQuestionsAnswered, {
            questionsAnswered: value.questionsAnswered,
         }) /
            sortedQuestionsAnswered.length) *
         100;
      if (percentile <= 25) {
         return 'color-github-1';
      } else if (percentile <= 50) {
         return 'color-github-2';
      } else if (percentile <= 75) {
         return 'color-github-3';
      } else if (percentile <= 100) {
         return 'color-github-4';
      }

      console.warn('Unable to get class for', value);
      return '';
   };

   const transformDayElement = (
      rect: JSX.Element,
      value: IVocabStat | null,
      index: number,
   ): React.ReactNode => {
      if (!value) {
         return rect;
      }
      const content = (
         <>
            <strong>{numberWithCommas(value.questionsAnswered)} questions</strong> on{' '}
            {moment(value.day).format('ddd MMM D')}
         </>
      );
      return (
         <Tippy key={index} theme='lingco-yellow' content={content}>
            {rect}
         </Tippy>
      );
   };

   return (
      <div className='card no-padding' style={{ height: '100%' }}>
         <div className='card-title'>
            <div className='title'>
               {numberWithCommas(totalQuestionsAnswered)} Questions Answered In The Last Year
            </div>
         </div>
         <div className='learning-stats-calendar'>
            <CalendarHeatmap<IVocabStat>
               dateProperty='day'
               startDate={minDay}
               endDate={maxDay}
               values={data}
               classForValue={getClass}
               transformDayElement={transformDayElement}
               showWeekdayLabels
            />
         </div>
      </div>
   );
};

export default React.memo(QuestionsAnsweredHeatmap);
