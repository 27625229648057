import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import { Maybe } from '@models/Core';
import SchoolProfile from '@models/SchoolProfile';
import { useLocation } from 'react-router-dom';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import SchoolSelect from '@components/SchoolSelect';

const LoginSSO: React.FC = () => {
   const {
      routes: {
         auth: { login },
      },
   } = Constants;

   const location = useLocation();
   const [value, setValue] = React.useState<Maybe<SchoolProfile>>(null);

   const schoolShortName = getQueryParameterByName(location, 'school', '');
   const schoolPreSelected = !!schoolShortName;

   let samlLoginRoute = value ? `/auth/saml/${value.shortName}` : '';
   if (schoolPreSelected) {
      samlLoginRoute = `/auth/saml/${schoolShortName}`;
   }
   const message = schoolPreSelected
      ? "Your school uses a single sign-on system to log you in to Lingco.  Click 'Continue' to start this process now."
      : "Select your school from the list below.  If you can't find your school then Lingco isn't configured for single sign-on yet.";

   const continueBtn = (
      <Link
         className='btn full-width'
         disabled={!samlLoginRoute}
         external
         to={samlLoginRoute}
         target={schoolPreSelected ? '_blank' : '_self'}
      >
         Continue
      </Link>
   );

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>School Login</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           School Login
                        </h2>
                        <p className='gray-text text-sm'>{message}</p>
                     </div>
                     {schoolPreSelected ? (
                        <div className='margin-bottom-s'>{continueBtn}</div>
                     ) : (
                        <>
                           <SchoolSelect
                              autoFocus
                              minCharacters={2}
                              onChange={setValue}
                              placeholder="Type your school's name"
                              ssoOnly
                              value={value}
                           />
                           <div className='margin-top-m'>{continueBtn}</div>
                           <div className='margin-top-m padding-top-s border-top space-between align-items-center'>
                              <span className='gray-text text-sm'>Want to log in another way?</span>
                              <Link to={login} className='btn line small'>
                                 Go back
                              </Link>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default LoginSSO;
