import qs from 'qs';

import Constants from '../../Constants';
import {
   GradingStatus,
   SubmissionParamFilters,
} from '@components/Course/Submissions/ActivitySubmissionsFilters';

export const getUngradedSubmissionsLink = (moduleItemId: number): string => {
   const { viewAllSubmissions } = Constants.routes.activities;
   const unGradedFilter: Partial<SubmissionParamFilters> = {
      grades: [GradingStatus.ungraded],
   };
   const ungradedFilterParam = qs.stringify(unGradedFilter, { indices: false });
   const submissionsLink = viewAllSubmissions.replace(':moduleItemId', moduleItemId.toString());
   return submissionsLink + '?' + ungradedFilterParam;
};
