import * as React from 'react';

import UserService from '@services/UserService';

import Constants from '../Constants';
import Button from './Common/Button';
import Link from './Common/Link';
import DocumentTitle from './DocumentTitle';
import ErrorDisplay from './ErrorDisplay';

const ForgotPassword: React.FC = () => {
   const {
      routes: {
         auth: { login },
      },
   } = Constants;

   const [email, setEmail] = React.useState<string>('');
   const [error, setError] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [emailSent, setEmailSent] = React.useState<boolean>(false);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setEmail(event.target.value);
   };

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      setIsLoading(true);
      UserService.forgotPasswordRequest(email)
         .then(() => {
            setEmailSent(true);
         })
         .catch(() => {
            setError(true);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>Forgot Password</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           Forgot Password?
                        </h2>
                        <p className='gray-text text-sm'>Enter your email to reset your password</p>
                     </div>
                     {!error &&
                        (emailSent ? (
                           <p data-test='sent-message'>
                              If an account exists for <strong>{email}</strong>, an email will be
                              sent with a link to recover your password.
                           </p>
                        ) : (
                           <form className='content-form' onSubmit={handleSubmit}>
                              <div className='row margin-top-m'>
                                 <div className='col-xs-12'>
                                    <label htmlFor='email'>Email address</label>
                                    <input
                                       name='email'
                                       type='email'
                                       id='email'
                                       className='margin-top-s'
                                       data-test='email'
                                       spellCheck={false}
                                       autoCapitalize='off'
                                       value={email}
                                       onChange={handleChange}
                                    />
                                 </div>
                              </div>
                              <Button
                                 type='submit'
                                 fullWidth
                                 data-test='send'
                                 className='margin-top-m'
                                 loading={isLoading}
                              >
                                 Send
                              </Button>
                           </form>
                        ))}
                     {error && <ErrorDisplay statusCode={500} />}
                     <div className='margin-top-m padding-top-s  border-top flex-end'>
                        <Link to={login} className='btn line small'>
                           Login
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ForgotPassword;
