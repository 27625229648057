import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import { ActivityBuilderMode, FillBlanksPrompt as FillBlanksPromptType } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import { usePopper } from 'react-popper';
import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';
import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import BlankSettingsPopup, {
   BlankConfiguration,
   blankConfigurationToElement,
} from './BlankSettingsPopup';

interface FillBlanksPromptProps {
   content: string;
   rtl: boolean;
   onUpdate(
      update: Partial<FillBlanksPromptType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const FillBlanksPrompt: React.FC<FillBlanksPromptProps> = ({ content, rtl, onUpdate }) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const editor = React.useRef<Maybe<TinyMCEEditor>>(null);
   const [uninitialized, setUninitialized] = React.useState<boolean>(true);
   const [activeBlankId, setActiveBlankId] = React.useState<Maybe<string>>(null);
   const [popperElement, setPopperElement] = React.useState<Maybe<HTMLDivElement>>(null);

   const referenceElement = activeBlankId
      ? document.querySelector<HTMLSpanElement>(`span[data-id="${activeBlankId}"]`)
      : null;

   const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [
         {
            name: 'offset',
            options: {
               offset: [0, 10],
            },
         },
         {
            name: 'computeStyles',
            options: {
               gpuAcceleration: false, // disables transform: translate3d
               adaptive: false, // prevents from adjusting popper's position on each update, reduces the jitter
            },
         },
      ],
   });

   const editorLoaded = (editorRef: TinyMCEEditor): void => {
      editorRef.on('BlankActive', (e: { node: HTMLSpanElement }) => {
         const newBlankId = e ? e.node.getAttribute('data-id') : null;
         setActiveBlankId(newBlankId);
         if (newBlankId) {
            editorRef.mode.set('readonly');
         } else {
            editorRef.mode.set('design');
         }
      });
      editor.current = editorRef;
   };

   const toggleBlank = React.useCallback(() => {
      editor.current?.execCommand('toggleBlank');
   }, [editor.current]);

   const handleFocus = (): void => {
      if (uninitialized) {
         setUninitialized(false);
      }
   };

   const handleChange = (value: string): void => {
      onUpdate({ content: value });
   };

   const handleBlankChange = (blankConfiguration: BlankConfiguration): void => {
      if (referenceElement) {
         blankConfigurationToElement(blankConfiguration, referenceElement);
      }
   };

   const handleSave = (blankConfiguration: BlankConfiguration): void => {
      const blankId = referenceElement?.getAttribute('data-id');
      const parsedContent = new DOMParser().parseFromString(content, 'text/html');
      const blankElement = parsedContent.querySelector<HTMLSpanElement>(
         `span[data-id="${blankId}"]`,
      );
      blankConfigurationToElement(blankConfiguration, blankElement);

      const updatedValue = parsedContent.body.innerHTML;
      onUpdate({ content: updatedValue });
      setActiveBlankId(null);
      editor.current?.mode.set('design');
      editor.current?.fire('focus');
   };

   const editorClassName = React.useMemo(
      () => classnames('no-padding', 'fill-blanks-form', { uninitialized }),
      [uninitialized],
   );
   const footer = React.useMemo(
      () => (
         <div className='fill-blanks-options flex-end'>
            <Button line color='green' onClick={toggleBlank}>
               Mark as blank
            </Button>
         </div>
      ),
      [toggleBlank],
   );

   return (
      <div>
         <Editor
            className={editorClassName}
            editorRef={editorLoaded}
            extraPlugins={['fillblanks']}
            footer={footer}
            language={language}
            onChange={handleChange}
            onFocus={handleFocus}
            rtl={rtl}
            toolbarAppearance={ToolbarAppearance.fixed}
            value={content}
         />
         {referenceElement && (
            <div
               ref={setPopperElement}
               data-test='blank-settings-popup'
               style={{
                  ...styles.popper,
                  zIndex: 10,
               }}
               {...attributes.popper}
            >
               <BlankSettingsPopup
                  blankElement={referenceElement}
                  onChange={handleBlankChange}
                  onSave={handleSave}
               />
            </div>
         )}
      </div>
   );
};

export default React.memo(FillBlanksPrompt);
