import * as React from 'react';

import Appearance from '@models/Appearance';

import ModalDialog from '@components/Core/ModalDialog';

interface ResetCourseCodeModalProps {
   section: boolean;
   instructor: boolean;
   reset(): void;
   close(): void;
}

const ResetCourseCodeModal: React.FC<ResetCourseCodeModalProps> = ({
   section,
   instructor,
   reset,
   close,
}) => {
   const type = instructor ? 'Instructor' : section ? 'section' : 'Student';

   return (
      <ModalDialog
         appearance={Appearance.danger}
         heading={`Reset ${type} Course Code`}
         onClose={close}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[
            { text: 'Reset', onClick: reset },
            { text: 'Cancel', onClick: close },
         ]}
      >
         <p>
            Are you sure that you want to reset your {type} course code?{' '}
            {instructor ? 'Instructors' : 'Students'} will no longer be able to use your existing
            code.
         </p>
      </ModalDialog>
   );
};

export default ResetCourseCodeModal;
