import * as React from 'react';

import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';
import OpentokRecorder from '@components/OpentokRecorder';
import Modal from '@components/Core/ModalDialog';

interface RecordVideoModalProps {
   editor?: TinyMCEEditor;
   onDismiss?(): void;
}

const RecordVideoModal: React.FC<RecordVideoModalProps> = ({ editor, onDismiss }) => {
   const videoElement = React.useRef<HTMLVideoElement>(null);
   const [fileUrl, setFileUrl] = React.useState<string>('');

   const clearFileUrl = (): void => setFileUrl('');

   const handleRecordingComplete = (_sessionId: number, url: string): void => {
      setFileUrl(url);
   };

   const handleInsertVideo = (): void => {
      if (!(editor && onDismiss)) {
         return;
      }
      const editorHtml = editor?.dom.createHTML('video', {
         src: fileUrl,
         controls: 'controls',
      });
      editor.insertContent(editorHtml);
      onDismiss();
   };

   if (!onDismiss) {
      return null;
   }

   return (
      <Modal
         onClose={onDismiss}
         actions={[
            { text: 'Insert', onClick: handleInsertVideo, disabled: !fileUrl },
            { text: 'Cancel', onClick: onDismiss },
         ]}
         heading='Record Video'
         className='editor-record-video-modal'
         bodyClassName='editor-record-video-modal-body'
         footerClassName='card-footer'
      >
         <div className='row'>
            <div className='col-xs-12'>
               {fileUrl ? (
                  <>
                     <div className='video-responsive standard'>
                        <video className='elem' src={fileUrl} controls ref={videoElement} />
                     </div>
                     <div className='align-items-center justify-center margin-top-s'>
                        <Button color='red' onClick={clearFileUrl}>
                           Clear Submission
                        </Button>
                     </div>
                  </>
               ) : (
                  <OpentokRecorder onRecordingComplete={handleRecordingComplete} />
               )}
            </div>
         </div>
      </Modal>
   );
};

export default RecordVideoModal;
