import { isVideoContent } from '@components/Activity/Utils';
import { randomShortId, randomTempId } from '@helpers/RandomStringUtils';
import {
   ActivityBuilderMode,
   ActivityContent,
   ActivityContentType,
   ActivityItemType,
   ActivityItemVisibility,
   ActivityPrompt,
   BaseActivityContent,
   BaseActivityPrompt,
   FillBlanksScoringMethod,
   FillBlanksSize,
   LessonContentDisplay,
   MultipleChoiceScoringMethod,
   PromptType,
   TextPromptLength,
   VideoSource,
   VocabSetItemDisplay,
} from '@models/Activity';

const BASE_CONTENT: BaseActivityContent<ActivityBuilderMode> = {
   id: null,
   index: null,
   itemType: null,
   key: '',
   show: ActivityItemVisibility.always,
};

export const DEFAULT_CONTENT_ITEMS: readonly ActivityContent<ActivityBuilderMode>[] = Object.freeze(
   [
      {
         ...BASE_CONTENT,
         itemType: ActivityContentType.textContent,
         text: '',
      },
      {
         ...BASE_CONTENT,
         disablePausing: false,
         fileUrl: null,
         itemType: ActivityContentType.audioContent,
         limitPlaybacks: false,
         originalFilename: null,
         playbacks: null,
         recordingDelay: null,
         recordUponCompletion: false,
         storedFilename: null,
      },
      {
         ...BASE_CONTENT,
         isInteractiveVideo: false,
         itemType: ActivityContentType.videoContent,
         playSnippet: false,
         enableTargetSubtitles: true,
         enableNativeSubtitles: true,
         promptTimes: [],
         snippetEnd: 0,
         snippetStart: 0,
         source: VideoSource.library,
      },
      {
         ...BASE_CONTENT,
         display: LessonContentDisplay.overlay,
         itemType: ActivityContentType.lessonContent,
         lessonId: null,
      },
      {
         ...BASE_CONTENT,
         display: VocabSetItemDisplay.overlay,
         itemType: ActivityContentType.vocabSet,
         vocabSetId: null,
      },
   ],
);

const BASE_PROMPT: BaseActivityPrompt<ActivityBuilderMode> = Object.freeze({
   canDoStatementId: null,
   canDoStatementName: null,
   description: '',
   feedback: '',
   hint: '',
   id: null,
   index: null,
   itemType: null,
   weight: '1.0',
   key: '',
   canDoStatementSkill: null,
   canDoStatementLevel: null,
   percentageGraded: undefined,
   scores: undefined,
});

export const DEFAULT_PROMPTS: readonly ActivityPrompt<ActivityBuilderMode>[] = Object.freeze([
   {
      ...BASE_PROMPT,
      itemType: PromptType.textPrompt,
      length: TextPromptLength.short,
      showWordCount: false,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.multipleChoicePrompt,
      multiple: false,
      options: [],
      scoringMethod: MultipleChoiceScoringMethod.allOrNothing,
      showFeedback: false,
      shuffle: false,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.orderingPrompt,
      options: [],
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.groupingPrompt,
      categories: [],
      items: [],
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.fillBlanksPrompt,
      content: '',
      scoringMethod: FillBlanksScoringMethod.caseInsensitive,
      showUniqueWords: false,
      blankType: FillBlanksSize.fitToContent,
      draggable: false,
      wordBank: null,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.markTokensPrompt,
      content: '',
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.imageLabelingPrompt,
      storedFilename: '',
      hotspots: [],
      fileUrl: null,
      wordBank: undefined,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.audioRecordingPrompt,
      allowUploading: false,
      limitAttempts: false,
      attempts: null,
      limitDuration: false,
      duration: null,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.videoRecordingPrompt,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.spokenResponsePrompt,
      acceptedResponses: [],
      saveRecording: true,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.discussionBoardPrompt,
      requirePostBeforeSeeingReplies: false,
   },
   {
      ...BASE_PROMPT,
      itemType: PromptType.filePrompt,
   },
]);

const shouldGetTempId = (
   item: ActivityContent<ActivityBuilderMode> | ActivityPrompt<ActivityBuilderMode>,
): boolean => {
   if (isVideoContent(item)) {
      return true;
   }

   // Future cases should go here

   return false;
};

const getDefaultItem = <
   T extends ActivityContent<ActivityBuilderMode> | ActivityPrompt<ActivityBuilderMode>,
>(
   item: T,
): T => {
   if (shouldGetTempId(item)) {
      return {
         ...item,
         id: randomTempId(),
         key: randomShortId(),
      };
   }

   return {
      ...item,
      key: randomShortId(),
   };
};

export const getDefaultContentByIndex = (index: number): ActivityContent<ActivityBuilderMode> => {
   const content = DEFAULT_CONTENT_ITEMS[index];

   if (!content) {
      throw new Error(`No default content item found for index ${index}`);
   }

   return getDefaultItem(content);
};

export const getDefaultContentOrPromptByItemType = (
   activityItemType: ActivityItemType,
): ActivityContent<ActivityBuilderMode> | ActivityPrompt<ActivityBuilderMode> => {
   const allDefaultItems = [...DEFAULT_CONTENT_ITEMS, ...DEFAULT_PROMPTS];
   const item = allDefaultItems.find((i) => i.itemType === activityItemType);

   if (!item) {
      throw new Error(`No default content item found for type ${activityItemType}`);
   }

   return getDefaultItem(item);
};
