import * as React from 'react';

import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconFieldPlus from '@icons/general/icon-field-plus.svg';
import {
   ActivityBuilderMode,
   ActivityMode,
   ActivityResponseEvaluation,
   SpokenResponsePrompt as SpokenResponsePromptType,
} from '@models/Activity';
import Language from '@models/Language';
import Tippy from '@tippyjs/react';

import AccentTextbox from '@components/AccentTextbox';
import SpokenResponsePromptCompleter from '@components/Activity/Completer/Prompts/SpokenResponsePrompt';

interface SpokenResponsePromptProps {
   acceptedResponses?: readonly string[];
   language: Language;
   onUpdate(
      update: Partial<SpokenResponsePromptType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const SpokenResponsePrompt: React.FC<SpokenResponsePromptProps> = ({
   acceptedResponses = [],
   language,
   onUpdate,
}) => {
   React.useEffect(() => {
      if (!acceptedResponses.length) {
         addResponse();
      }
   }, []);

   const addResponse = (): void => {
      onUpdate({
         acceptedResponses: [...acceptedResponses, ''],
      });
   };

   const removeResponse = (index: number): void => {
      onUpdate({
         acceptedResponses: acceptedResponses.filter((i, j) => j !== index),
      });
   };

   const updateResponse = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
      onUpdate({
         acceptedResponses: acceptedResponses.map((i, j) =>
            j === index ? event.target.value.normalize() : i,
         ),
      });
   };

   const blankEvaluation: ActivityResponseEvaluation<ActivityMode.preview> = {
      autograded: false,
      comments: '',
      graded: false,
      id: null,
      isProficient: null,
      pointAdjustment: null,
      rubricComments: [],
      rubricItemIds: [],
      score: 0.0,
   };

   return (
      <div>
         <SpokenResponsePromptCompleter
            className='no-padding-left'
            mode={ActivityMode.preview}
            acceptedResponses={acceptedResponses}
            language={language}
            evaluation={blankEvaluation}
            response={{ attempts: 0, accepted: null, result: null }}
            text='Test Prompt'
            disabledText='Add an accepted response below'
            weight='0.0'
         />
         <div className='margin-top-s'>
            <label className='field-title'>Accepted Responses</label>
            <div className='two-thirds-width'>
               {acceptedResponses.map((response, index) => (
                  <div key={index} className='align-items-center margin-top-s'>
                     <AccentTextbox
                        name='data'
                        placeholder='Student Response'
                        className='no-margin'
                        value={response}
                        onChange={(e) => updateResponse(e, index)}
                        language={language}
                     />
                     <div className='align-items-center justify-center fixed-45-width margin-left-s'>
                        {index === 0 ? (
                           <Tippy content='Add Response' delay={[2000, 0]}>
                              <div className='pointer'>
                                 <IconFieldPlus onClick={addResponse} />
                              </div>
                           </Tippy>
                        ) : (
                           <div className='delete-row'>
                              <IconCloseSmall
                                 onClick={() => removeResponse(index)}
                                 role='button'
                                 aria-label='Remove Response'
                              />
                           </div>
                        )}
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default SpokenResponsePrompt;
