import * as _ from 'lodash';
import * as React from 'react';
import * as yup from 'yup';

import ModalDialog from '@components/Core/ModalDialog';
import TagsInput from '@components/Core/TagsInput/TagsInput';
import { yupResolver } from '@hookform/resolvers/yup';
import Appearance from '@models/Appearance';
import AdminNationalExamService, {
   NationalExamCourseInput,
} from '@services/AdminNationalExamService';
import { NationalExamCourseSummary } from '@services/NationalExamAdminService';
import { isAxiosError } from 'axios';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';

type Props = {
   examId: number;
   // selectedExamCourse?: NationalExamCourseSummary;
   closeModal(): void;
   setExamCourses(courses: NationalExamCourseSummary[]): void;
};

const NationalExamCourseInputSchema = yup.object().shape({
   courseId: yup.number().required().positive().integer(),
   examModuleIds: yup.array().of(yup.string().required()).required(),
   practiceModuleIds: yup.array().of(yup.string().required()).required(),
   categories: yup.array().of(yup.string().required().trim()).required(),
   isPractice: yup.boolean().required(),
   levelName: yup.string().nullable().trim(),
   levelSortOrder: yup.number().nullable(),
   requiresRegistration: yup.boolean().required(),
   shouldEnforceCategoryAssignment: yup.boolean().required(),
   shouldGetDefaultExamCoursePermissions: yup.boolean().required(),
});

const AddCourseModal: React.FC<Props> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const {
      handleSubmit,
      register,
      control,
      formState: { errors, isDirty },
   } = useForm<NationalExamCourseInput>({
      resolver: yupResolver<NationalExamCourseInput>(NationalExamCourseInputSchema),
      defaultValues: {
         categories: [],
         examModuleIds: [],
         levelName: null,
         levelSortOrder: null,
         practiceModuleIds: [],
         requiresRegistration: true,
         shouldEnforceCategoryAssignment: true,
         shouldGetDefaultExamCoursePermissions: true,
         // ...props.selectedExamCourse,
      },
   });

   const onSubmit = async (data: NationalExamCourseInput): Promise<void> => {
      setIsSaving(true);

      try {
         const response = await AdminNationalExamService.connectCourseToNationalExam(
            props.examId,
            data,
         );
         props.setExamCourses(response.nationalExamCourseSummaries);
         setIsSaving(false);
         props.closeModal();
      } catch (e) {
         setIsSaving(false);
         console.error(e);
         if (isAxiosError(e)) {
            alert(e.response?.data.error);
         }
      }
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='Add Course to Exam'
         onClose={props.closeModal}
         className='no-padding'
         bodyClassName='modal-body'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            { text: 'Close', onClick: props.closeModal },
         ]}
      >
         <div className='text-left margin-left-s margin-top-s margin-right-s margin-bottom-s'>
            {Object.keys(errors).map((x) => (
               <p key={x}>{x}</p>
            ))}
            <form>
               <label className='field-title'>Course Id</label>
               <input
                  {...register('courseId')}
                  type='number'
                  className={classnames({ error: !!errors.courseId })}
               />
               <label className='field-title'>Level Name</label>
               <input
                  {...register('levelName')}
                  type='text'
                  className={classnames({ error: !!errors.levelName })}
               />
               <label className='field-title'>Level Sort Order</label>
               <input
                  {...register('levelSortOrder')}
                  type='number'
                  className={classnames({ error: !!errors.levelSortOrder })}
               />
               <label className='field-title'>Practice Modules</label>
               <Controller
                  name='practiceModuleIds'
                  control={control}
                  render={({ field }) => (
                     <TagsInput
                        inputProps={{ placeholder: '+ Practice Module Id' }}
                        {...{ ...field, ref: null }}
                        className={classnames({ error: !!errors.practiceModuleIds })}
                     />
                  )}
               />
               <label className='field-title'>Exam Modules</label>
               <Controller
                  name='examModuleIds'
                  control={control}
                  render={({ field }) => (
                     <TagsInput
                        inputProps={{ placeholder: '+ Exam Module Id' }}
                        {...{ ...field, ref: null }}
                        className={classnames({ error: !!errors.examModuleIds })}
                     />
                  )}
               />
               <label className='field-title'>Categories</label>
               <Controller
                  name='categories'
                  control={control}
                  render={({ field }) => (
                     <TagsInput
                        inputProps={{ placeholder: '+ Category' }}
                        {...{ ...field, ref: null }}
                        className={classnames({ error: !!errors.categories })}
                     />
                  )}
               />
               <div className='switch-wrapper'>
                  <input
                     type='checkbox'
                     className={classnames('react-style switch yellow', {
                        error: !!errors.isPractice,
                     })}
                     {...register('isPractice')}
                  />
                  <label className='field-title'>Is practice?</label>
               </div>
               <div className='switch-wrapper'>
                  <input
                     type='checkbox'
                     className={classnames('react-style switch yellow', {
                        error: !!errors.requiresRegistration,
                     })}
                     {...register('requiresRegistration')}
                  />
                  <label className='field-title'>Requires registration?</label>
               </div>
               <div className='switch-wrapper'>
                  <input
                     type='checkbox'
                     className={classnames('react-style switch yellow', {
                        error: !!errors.shouldEnforceCategoryAssignment,
                     })}
                     {...register('shouldEnforceCategoryAssignment')}
                  />
                  <label className='field-title'>Should enforce category assignment?</label>
               </div>
               <div className='switch-wrapper'>
                  <input
                     type='checkbox'
                     className={classnames('react-style switch yellow', {
                        error: !!errors.shouldGetDefaultExamCoursePermissions,
                     })}
                     {...register('shouldGetDefaultExamCoursePermissions')}
                  />
                  <label className='field-title'>Should get default exam course permissions?</label>
               </div>
            </form>
         </div>
      </ModalDialog>
   );
};

export default AddCourseModal;
