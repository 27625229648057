import * as React from 'react';

function useUpdateEffect(
   effect: React.EffectCallback,
   deps?: React.DependencyList,
): void {
   const isInitialMount = React.useRef<boolean>(true);

   React.useEffect(() => {
      if (isInitialMount.current) {
         isInitialMount.current = false;
      } else {
         effect();
      }
   }, deps);
}

export default useUpdateEffect;
