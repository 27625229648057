import * as React from 'react';

import { Maybe } from '@models/Core';
import classnames from 'classnames';
import flatpickr from 'flatpickr';
import Flatpickr from 'react-flatpickr';

import Constants from '../../Constants';

import { DateOption } from 'flatpickr/dist/types/options';

export interface FlatpickrElement extends Element {
   _flatpickr: flatpickr.Instance;
}

interface DateTimePickerProps {
   allowNull?: boolean;
   allowInvalidPreload?: boolean;
   /** Shows the day selection in calendar. */
   calendar?: boolean;
   /** A string of characters which are used to define how the date will be displayed in the input box. */
   dateFormat?: string;
   /** Selector for automated testing */
   'data-test'?: string;
   className?: string;
   inline?: boolean;
   disabled?: boolean;
   /** The maximum date that a user can pick to (inclusive). */
   maxDate?: DateOption;
   /** The minimum date that a user can start picking from (inclusive). */
   minDate?: DateOption;
   /** Shows a time picker */
   time?: boolean;
   /** Value */
   value?: Maybe<string | Date | number | readonly (string | Date | number)[]>;
   id?: string;
   onChange?(newDate: Date): void;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
   allowNull = false,
   allowInvalidPreload = true,
   calendar = true,
   className,
   dateFormat,
   disabled = false,
   maxDate,
   minDate,
   inline = false,
   time = true,
   value = undefined,
   id,
   onChange,
   ...rest
}) => {
   const {
      keys: { backspace, delete: deleteKey },
   } = Constants;

   const handleChange = (selectedDates: readonly Date[]): void => {
      onChange?.(new Date(selectedDates[0]));
   };

   const handleKeyDownCapture = (event: React.KeyboardEvent): void => {
      if (!allowNull && [backspace, deleteKey].includes(event.key)) {
         event.preventDefault();
         event.stopPropagation();
      }
   };

   const getDateFormat = (): string => {
      if (dateFormat) {
         return dateFormat;
      } else {
         const result = [];
         if (calendar) {
            result.push('m/d/Y');
         }
         if (time) {
            result.push('h:i K');
         }
         return result.join(' ');
      }
   };

   return (
      <Flatpickr
         id={id}
         className={classnames(className, 'date-time-picker', { inline })}
         data-enable-time={time}
         disabled={disabled}
         onChange={handleChange}
         onKeyDownCapture={handleKeyDownCapture}
         options={{
            dateFormat: getDateFormat(),
            minDate,
            maxDate,
            noCalendar: !calendar,
            allowInvalidPreload,
            inline,
         }}
         value={value ?? undefined}
         {...rest}
      />
   );
};

export default DateTimePicker;
