/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isWindows } from '@helpers/PlatformUtils';
import useCommands from '@hooks/use-commands';
import useMousetrap from '@hooks/use-mousetrap';
import usePrevious from '@hooks/use-previous';
import IconFileTasksChecked from '@icons/nova-line/88-Files-Tasks/file-tasks-checklist.svg';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import ContentItemProfile from '@models/ContentItemProfile';
import ContentLibraryName from '@models/ContentLibraryName';
import { Maybe, MessageResponse } from '@models/Core';
import {
   Assignment,
   CourseInfo,
   Module,
   ModuleItem,
   NonContentModuleItemType,
} from '@models/Course';
import LMSName from '@models/LMSName';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { diff } from 'deep-object-diff';
import {
   Draggable,
   DraggableProvided,
   DraggableStateSnapshot,
   DragStart,
   Droppable,
   DropResult,
} from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';

import Constants from '../../../Constants';
import Button from '@components/Common/Button';
import AddContentModal from '@components/ContentLibrary/AddContentModal';
import Droplist from '@components/Core/Droplist';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { AssignActivityStep } from '@components/Onboarding/Walkthroughs/AssignActivity';
import { GradeActivityStep } from '@components/Onboarding/Walkthroughs/GradeActivity';
import BulkEditModal, { BulkEditResult } from './BulkEditModal';
import { multiDragAwareReorder, multiSelectTo as multiSelect, splitDraggableId } from './DragUtils';
import ModuleContentItem from './ModuleContentItem';
import { ModuleDndEmitter, ModuleDroppableId } from './ModuleDndEmitter';
import ModuleItemDetails from './ModuleItemDetails';
import {
   ACTIVITY_ASSIGNMENT_PROPERTIES,
   getModuleMode,
   isActivity,
   isContent,
   isVocabSet,
   ModuleMode,
   VOCAB_SET_ASSIGNMENT_PROPERTIES,
} from './ModuleUtils';

interface ModuleContentProps {
   accountType: AccountType;
   canEditModule: boolean;
   canEditSecretCode: boolean;
   canPreviewContent: boolean;
   courseInfo: CourseInfo;
   emptyAssignment: Assignment;
   isSaving: boolean;
   items: readonly ModuleItem[];
   lmsName: Maybe<LMSName>;
   moduleId: number;
   addContentItem(item: ContentItemProfile, library?: ContentLibraryName): void;
   createGradingCategory(label: string): Promise<number>;
   createItem(itemType: NonContentModuleItemType): Promise<number | void>;
   editItem(itemId: number, update: Partial<ModuleItem>): void;
   removeItem(itemId: number): void;
   saveItem(itemId: number): Promise<void>;
   setDraggingItemIds(itemIds: readonly number[]): void;
   updateModuleProperties(update: Partial<Module>): void;
}

const ModuleContent: React.FC<ModuleContentProps> = ({
   accountType,
   canEditModule,
   canEditSecretCode,
   canPreviewContent,
   courseInfo,
   emptyAssignment,
   isSaving,
   items = [],
   lmsName,
   moduleId,
   addContentItem,
   createGradingCategory,
   createItem,
   editItem,
   removeItem,
   saveItem,
   setDraggingItemIds,
   updateModuleProperties,
}) => {
   const {
      keys: { enter, escape },
   } = Constants;

   const location = useLocation();
   const mode = getModuleMode(location.pathname);

   const { walkthrough, stepId, taskState, getOnboardingClassName, setWalkthroughVisible } =
      React.useContext<IOnboardingProps>(OnboardingContext);

   const [addContentModalOpen, setAddContentModalOpen] = React.useState<boolean>(false);
   const [bulkEditModalOpen, setBulkEditModalOpen] = React.useState<boolean>(false);
   const [draggingItemId, setDraggingItemId] = React.useState<Maybe<number>>(null);
   const [isDropDisabled, setIsDropDisabled] = React.useState<boolean>(false);
   const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);
   const [selectedItemIds, setSelectedItemIds] = React.useState<readonly number[]>([]);
   const [showCheckboxes, setShowCheckboxes] = React.useState<boolean>(false);

   const activeItemId = selectedItemIds.length === 1 && !showCheckboxes ? selectedItemIds[0] : null;
   const activeItem = activeItemId ? items?.find((i) => i.id === activeItemId) ?? null : null;

   const prevActiveItem = usePrevious<Maybe<ModuleItem>>(activeItem);

   useCommands(
      [
         {
            id: 'add_content',
            title: 'Add Content',
            scale: 1.5,
            action: () => setAddContentModalOpen(true),
            showIf: () => canEditModule,
         },
      ],
      [canEditModule],
   );

   // Editing the module should have no active items
   React.useEffect(() => {
      if (mode === ModuleMode.edit) {
         setSelectedItemIds([]);
         setShowCheckboxes(false);
      }
   }, [mode, moduleId]);

   // Save to server on active item update
   React.useEffect(() => {
      if (activeItem !== null && prevActiveItem !== null && activeItem.id === prevActiveItem?.id) {
         const exemptKeys = ['index', 'itemName', 'fileUrl'];
         const changedKeys = Object.keys(diff(activeItem, prevActiveItem)).filter(
            (i) => !exemptKeys.includes(i),
         );
         if (changedKeys.length > 0) {
            saveItem(activeItem.id);
         }
      }
   }, [activeItem]);

   // Selected Item Ids should never have an id that's not part of the module (i.e handle if they're moved or deleted)
   React.useEffect(() => {
      if (items) {
         const itemIds = items.map((i) => i.id);
         setSelectedItemIds((prevSelectedItemIds) =>
            prevSelectedItemIds.filter((i) => itemIds.includes(i)),
         );
      }
   }, [items]);

   const isInstructor = accountType === AccountType.instructor;
   const displayDetails = isInstructor && items.length > 0 && mode === ModuleMode.view;
   const canEditItems = isInstructor && canEditModule && mode === ModuleMode.view;
   const showBulkEditItems =
      canEditItems &&
      ((showCheckboxes && selectedItemIds.length >= 1) || selectedItemIds.length >= 2);

   // FIXME: Why is this coming in in two places (activeItem.permissions and course permissions)
   const canPreviewModuleItemContent =
      activeItem?.permissions?.canPreviewContent || canPreviewContent;

   const setItemActive = (itemId: Maybe<number>): void =>
      setSelectedItemIds(itemId ? [itemId] : []);

   const isItemSelected = (item: ModuleItem): boolean => selectedItemIds.includes(item.id);

   const selectAll = (): void => setSelectedItemIds(items.map((i) => i.id));

   const unselectAll = (): void => setSelectedItemIds([]);

   const handleSelectAll = (event: KeyboardEvent): void => {
      event.preventDefault();
      selectAll();
   };

   const handleWindowKeyDown = (event: KeyboardEvent): void => {
      if (event.defaultPrevented || bulkEditModalOpen || selectedItemIds.length === 1) {
         return;
      } else if (event.key === escape) {
         unselectAll();
      }
   };

   const handleWindowClick = (event: MouseEvent): void => {
      if (
         event.defaultPrevented ||
         bulkEditModalOpen ||
         selectedItemIds.length === 1 ||
         showCheckboxes
      ) {
         return;
      }

      unselectAll();
   };

   const handleWindowTouchEnd = (event: TouchEvent): void => {
      if (
         event.defaultPrevented ||
         selectedItemIds.length === 1 ||
         bulkEditModalOpen ||
         showCheckboxes
      ) {
         return;
      }

      unselectAll();
   };

   useMousetrap('mod+a', handleSelectAll);

   const handleCommandEnter = React.useCallback(() => {
      if (selectedItemIds.length) {
         setBulkEditModalOpen(true);
      }
   }, [selectedItemIds.length]);

   useMousetrap('mod+enter', handleCommandEnter);

   React.useEffect(() => {
      window.addEventListener('click', handleWindowClick);
      window.addEventListener('keydown', handleWindowKeyDown);
      window.addEventListener('touchend', handleWindowTouchEnd);
      return () => {
         window.removeEventListener('click', handleWindowClick);
         window.removeEventListener('keydown', handleWindowKeyDown);
         window.removeEventListener('touchend', handleWindowTouchEnd);
      };
   }, [bulkEditModalOpen, selectedItemIds, showCheckboxes]);

   React.useEffect(() => {
      const subscription = ModuleDndEmitter.subscribeTo.moduleItemOnDragEnd(handleDragEnd);
      return () => subscription?.unsubscribe();
   });

   React.useEffect(() => {
      const subscription = ModuleDndEmitter.subscribeTo.onDragStart(handleDragStart);
      return () => subscription?.unsubscribe();
   });

   // AssignActivity walkthrough actions
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.assignActivity &&
         taskState === OnboardingTaskState.inProgress &&
         stepId === AssignActivityStep.moduleAddButton
      ) {
         if (addContentModalOpen && !!items.length) {
            setWalkthroughVisible(false);
            setAddContentModalOpen(false);
            setItemActive(items[items.length - 1].id);
         }
      }
   }, [items.length]);

   // GradingActivity walkthrough actions
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.gradeActivity &&
         taskState === OnboardingTaskState.inProgress &&
         stepId === GradeActivityStep.modulesLink
      ) {
         const demoActivity = items.find((i) => i.itemName.startsWith('Lingco Grading Demo'));
         if (demoActivity) {
            setItemActive(demoActivity.id);
         }
      }
   }, [items.length, stepId]);

   const handleDragEnd = async (result: DropResult): Promise<void> => {
      const destination = result.destination;
      const source = result.source;

      // Nothing to do
      if (!destination || result.reason === 'CANCEL') {
         setDraggingItemId(null);
         setDraggingItemIds([]);
         return;
      }

      const processed = multiDragAwareReorder({
         items,
         selectedItemIds,
         source,
         destination,
      });

      setSelectedItemIds(processed.selectedItemIds);
      updateModuleProperties({ items: processed.items });

      const data = {
         items: processed.items.map((i) => ({ id: i.id, index: i.index })),
      };
      HttpService.patchWithAuthToken<MessageResponse>(
         `/api/courses/${courseInfo.id}/modules/${moduleId}/items`,
         snakeCaseKeys(data),
      );
   };

   const handleDragStart = (start: DragStart): void => {
      const [_draggedType, draggedId] = splitDraggableId(start.draggableId);
      const selected = selectedItemIds.find((i) => i === draggedId);

      if (!selected) {
         unselectAll();
      }

      setDraggingItemId(draggedId);
      if (selectedItemIds.length) {
         setDraggingItemIds(selectedItemIds);
      } else {
         setDraggingItemIds([draggedId]);
      }

      const canDrop = start.source.droppableId === ModuleDroppableId.ModuleContent;
      setIsDropDisabled(!canDrop);
   };

   const toggleSelectAll = (): void => {
      if (selectedItemIds.length === items.length) {
         unselectAll();
      } else {
         selectAll();
      }
   };

   // Behaviour matches the MacOSX finder selection
   const multiSelectTo = (newItemId: number): void => {
      const updated = multiSelect(items, selectedItemIds, newItemId);
      if (updated !== null) {
         setSelectedItemIds(updated);
      }
   };

   const toggleSelectionInGroup = (itemId: number): void => {
      setSelectedItemIds((prevSelectedItemIds) =>
         prevSelectedItemIds.includes(itemId)
            ? prevSelectedItemIds.filter((i) => i !== itemId)
            : [...prevSelectedItemIds, itemId],
      );
   };

   const onKeyDown = (
      itemId: number,
      event: React.KeyboardEvent,
      snapshot: DraggableStateSnapshot,
   ): void => {
      if (event.defaultPrevented || snapshot.isDragging || event.key !== enter) {
         return;
      }
      // we are using the event for selection
      event.preventDefault();
      performAction(itemId, event);
   };

   // Using onClick as it will be correctly preventing if there was a drag
   const onContentItemClick = (itemId: number, event: React.MouseEvent): void => {
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
      const primaryButton = 0;
      if (event.defaultPrevented || event.button !== primaryButton || mode === ModuleMode.edit) {
         return;
      }
      // marking the event as used
      event.preventDefault();
      performAction(itemId, event);
   };

   const onTouchEnd = (itemId: number, event: React.TouchEvent): void => {
      if (
         event.defaultPrevented ||
         bulkEditModalOpen ||
         selectedItemIds.length === 1 ||
         showCheckboxes
      ) {
         return;
      }

      // marking the event as used
      event.preventDefault();
      toggleSelectionInGroup(itemId);
   };

   const performAction = (itemId: number, event: React.MouseEvent | React.KeyboardEvent): void => {
      // Determines if the platform specific toggle selection in group key was used
      const toggleInSelectionGroupKeyUsed = isWindows() ? event.ctrlKey : event.metaKey;
      if (toggleInSelectionGroupKeyUsed) {
         toggleSelectionInGroup(itemId);
      } else if (event.shiftKey) {
         multiSelectTo(itemId);
      } else if (showCheckboxes) {
         toggleSelectionInGroup(itemId);
      } else {
         setItemActive(itemId);
      }
   };

   const renderModuleContentContainer = (): React.ReactNode | readonly React.ReactNode[] => {
      const moduleContentItems = items.map((item, i) => {
         const itemId = item.id;
         const isSelected = selectedItemIds.includes(itemId);

         const contentItem = (
            <ModuleContentItem
               accountType={accountType}
               canEdit={canEditItems}
               isSelected={isSelected}
               item={item}
               key={itemId}
               onCheckboxClick={(e) => performAction(itemId, e)}
               showCheckbox={showCheckboxes}
            />
         );

         if (canEditItems) {
            const isGhosting = isSelected && Boolean(draggingItemId) && draggingItemId !== itemId;
            const selectionCount = selectedItemIds.length;
            const multipleItemsSelected = selectionCount > 1;
            return (
               <Draggable
                  draggableId={`${ModuleDroppableId.ModuleContent}-${itemId}`}
                  index={i}
                  key={itemId}
               >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                     const shouldShowSelection = snapshot.isDragging && multipleItemsSelected;
                     const isDragging = snapshot.isDragging;
                     return (
                        <div
                           ref={provided.innerRef}
                           {...provided.draggableProps}
                           {...provided.dragHandleProps}
                           className={classnames('module-content-item-drag-wrapper', {
                              selected: isSelected,
                              ghosting: isGhosting,
                              dragging: isDragging,
                           })}
                           data-test={isInstructor ? `module-item-${item.itemName}` : undefined}
                           onClick={(e) => onContentItemClick(itemId, e)}
                           onKeyDown={(e) => onKeyDown(itemId, e, snapshot)}
                           onTouchEnd={(e) => onTouchEnd(itemId, e)}
                           onMouseDown={(e) => e.currentTarget.focus()}
                        >
                           {contentItem}
                           {shouldShowSelection && (
                              <div className='selection-count'>{selectionCount}</div>
                           )}
                        </div>
                     );
                  }}
               </Draggable>
            );
         } else if (isInstructor) {
            return (
               <div
                  className={classnames('module-content-item-drag-wrapper', {
                     selected: isSelected,
                     'edit-view': mode === ModuleMode.edit,
                  })}
                  onClick={(e) => onContentItemClick(itemId, e)}
                  key={itemId}
               >
                  {contentItem}
               </div>
            );
         }
         return contentItem;
      });
      const addContentDescription = (
         <p>
            Click the "Add Content" Button to add content from the Content Library to your module.
         </p>
      );
      if (moduleContentItems.length) {
         if (canEditItems) {
            return (
               <Droppable
                  droppableId={ModuleDroppableId.ModuleContent}
                  isDropDisabled={isDropDisabled}
               >
                  {(provided) => (
                     <div ref={provided.innerRef} {...provided.droppableProps}>
                        {moduleContentItems}
                        {provided.placeholder}
                     </div>
                  )}
               </Droppable>
            );
         }
         return moduleContentItems;
      }
      return (
         <EmptyState
            description={canEditItems && addContentDescription}
            heading='No Content Yet!'
            icon={<IconContentBook2 aria-hidden />}
         />
      );
   };

   const handleBulkEditItems = (update: BulkEditResult): Promise<void> => {
      const getBase = (i: ModuleItem): { id: number; isAssigned?: boolean } => ({
         id: i.id,
         ..._.pick(update, ['isAssigned']),
      });
      const updateData = [
         ...items
            .filter(isItemSelected)
            .filter(isActivity)
            .map((i) => ({
               ...getBase(i),
               assignment: _.pick(update.assignment, ACTIVITY_ASSIGNMENT_PROPERTIES),
            })),
         ...items
            .filter(isItemSelected)
            .filter(isVocabSet)
            .map((i) => ({
               ...getBase(i),
               assignment: _.pick(update.assignment, VOCAB_SET_ASSIGNMENT_PROPERTIES),
            })),
      ];
      const moduleUrl = `/api/courses/${courseInfo.id}/modules/${moduleId}/items`;
      return HttpService.patchWithAuthToken<MessageResponse>(
         moduleUrl,
         snakeCaseKeys({ items: updateData }),
      ).then(() => {
         const updatedItems = items.map((item) => {
            if (isItemSelected(item)) {
               const itemUpdate = updateData.find((i) => i.id === item.id);
               return _.merge(item, itemUpdate);
            }
            return item;
         });
         updateModuleProperties({ ...update, items: updatedItems });
         closeBulkEditModal();
      });
   };

   const postItemToLMS = async (): Promise<void> => {
      if (activeItemId) {
         HttpService.postWithAuthToken<{
            lmsCourseworkIds: readonly number[];
         }>(
            `/api/courses/${courseInfo.id}/modules/${moduleId}/items/${activeItemId}/post_to_lms`,
         ).then((response) => {
            const { lmsCourseworkIds } = response.data;
            editItem(activeItemId, { lmsCourseworkIds });
         });
      }
   };

   const syncGradesWithLMS = async (): Promise<void> => {
      if (activeItemId) {
         HttpService.postWithAuthToken<{
            msg: string;
            failedUserIds: readonly number[];
         }>(
            `/api/courses/${courseInfo.id}/modules/${moduleId}/items/${activeItemId}/lms_sync`,
         ).then(() => null);
      }
   };

   const toggleAddContentModal = (): void => {
      if (
         walkthrough &&
         walkthrough.id === OnboardingWalkthrough.assignActivity &&
         stepId === AssignActivityStep.moduleAddButton
      ) {
         return;
      }
      setAddContentModalOpen((prevAddContentModalOpen) => !prevAddContentModalOpen);
   };

   const handleRemoveItem = (): void => {
      if (activeItemId) {
         removeItem(activeItemId);
         setRemoveModalOpen(false);
      }
   };

   const closeRemoveItemModal = (): void => {
      setRemoveModalOpen(false);
   };

   const openRemoveItemModal = (): void => {
      setRemoveModalOpen(true);
   };

   const toggleShowCheckboxes = (): void => {
      setShowCheckboxes((prevShowCheckboxes) => !prevShowCheckboxes);
   };

   const handleBulkEditItemsClick = (
      event: React.MouseEvent | Mousetrap.ExtendedKeyboardEvent,
   ): void => {
      event.preventDefault();
      setBulkEditModalOpen(true);
   };

   const handleFromContentLibraryClick = (): void => {
      if (walkthrough && walkthrough.id === OnboardingWalkthrough.assignActivity && stepId === '') {
         setWalkthroughVisible(false);
      }
      toggleAddContentModal();
   };

   const closeBulkEditModal = (): void => setBulkEditModalOpen(false);

   const renderInstructorHeader = () => {
      const addContentDroplistItems = [
         { text: 'From Content Library', onClick: handleFromContentLibraryClick },
         {
            text: 'New File',
            onClick: async () =>
               setItemActive((await createItem(NonContentModuleItemType.file)) || null),
         },
         {
            text: 'New Link',
            onClick: async () =>
               setItemActive((await createItem(NonContentModuleItemType.link)) || null),
         },
      ];
      return (
         <div className='card-title full-width has-button'>
            <div className='left-content'>
               {showCheckboxes && items.length > 0 && (
                  <input
                     checked={selectedItemIds.length === items.length}
                     className='module-content-select-all'
                     onChange={toggleSelectAll}
                     type='checkbox'
                  />
               )}
               <div className='title'>Content</div>
            </div>
            <div className='right-options-wrapper'>
               {canEditItems && items.length > 0 && (
                  <Button
                     icon={<IconFileTasksChecked aria-hidden />}
                     line
                     onClick={toggleShowCheckboxes}
                     tooltip='Show Checkboxes'
                  />
               )}
               {showBulkEditItems && (
                  <Button color='purple' onClick={handleBulkEditItemsClick}>
                     Bulk Edit Items ({selectedItemIds.length})
                  </Button>
               )}
               {!showBulkEditItems && mode === ModuleMode.view && (
                  <Droplist items={addContentDroplistItems} pullRight>
                     <Button
                        className={classnames(
                           getOnboardingClassName?.(AssignActivityStep.addContent),
                        )}
                        color='green'
                        data-test='add-content-btn'
                        data-tour='add-content-button'
                        disabled={!canEditModule}
                     >
                        + Add Content
                     </Button>
                  </Droplist>
               )}
            </div>
         </div>
      );
   };

   const renderStudentHeader = () => (
      <div className='card-title full-width module-content-title'>
         <div className='content-heading-wrapper'>
            <div className='title'>Content</div>
         </div>
         {items.length > 0 && (
            <>
               <div className='end-date-heading-wrapper'>
                  <div className='title'>End Date</div>
               </div>
               <div className='complete-heading-wrapper'>
                  <div className='title'>Complete</div>
               </div>
            </>
         )}
      </div>
   );

   return (
      <div className='card inner module-content-item-container'>
         {isInstructor ? renderInstructorHeader() : renderStudentHeader()}
         <div
            className={classnames('row module-content-inner-container ', {
               details: displayDetails,
            })}
         >
            <div className={`module-content-list ${displayDetails ? 'col-xs-6' : 'col-xs-12'}`}>
               {renderModuleContentContainer()}
            </div>
            {displayDetails && (
               <div className='module-content-details col-xs-6'>
                  <ModuleItemDetails
                     courseId={courseInfo.id}
                     moduleId={moduleId}
                     activeItem={activeItem}
                     canEditModule={canEditModule}
                     canEditModuleItem={canEditModule}
                     canEditSecretCode={canEditSecretCode}
                     canPreviewModuleItemContent={canPreviewModuleItemContent}
                     courseEndDate={courseInfo.endDate}
                     courseStartDate={courseInfo.startDate}
                     createGradingCategory={createGradingCategory}
                     editItem={(update) =>
                        activeItem ? editItem(activeItem.id, update) : undefined
                     }
                     emptyAssignment={emptyAssignment}
                     gradingCategories={courseInfo.gradingCategories}
                     isSaving={isSaving}
                     lmsName={lmsName}
                     openRemoveItemModal={openRemoveItemModal}
                     postItemToLMS={postItemToLMS}
                     sections={courseInfo.sections}
                     selectedItemCount={selectedItemIds.length}
                     showCheckboxes={showCheckboxes}
                     syncGradesWithLMS={syncGradesWithLMS}
                  />
               </div>
            )}
         </div>
         {canEditModule && addContentModalOpen && (
            <AddContentModal
               addItem={addContentItem}
               addedItemIds={items.filter(isContent).map((i) => i.itemId)}
               language={courseInfo.language}
               moduleId={moduleId}
               onClose={toggleAddContentModal}
               heading='Add Module Content'
            />
         )}
         {canEditModule && activeItem && removeModalOpen && (
            <ModalDialog
               actions={[
                  { text: 'Remove', onClick: handleRemoveItem },
                  { text: 'Cancel', onClick: closeRemoveItemModal },
               ]}
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               heading='Remove Module Item'
               onClose={closeRemoveItemModal}
            >
               <p>Are you sure that you want to remove "{activeItem.itemName}"?</p>
               {activeItem.itemType === ContentType.activity && (
                  <p>Removing an activity will delete all associated student submissions.</p>
               )}
            </ModalDialog>
         )}
         {canEditModule && bulkEditModalOpen && (
            <BulkEditModal
               courseEndDate={courseInfo.endDate}
               courseStartDate={courseInfo.startDate}
               createGradingCategory={createGradingCategory}
               defaultAssignment={emptyAssignment}
               gradingCategories={courseInfo.gradingCategories}
               onClose={closeBulkEditModal}
               sections={courseInfo.sections}
               onSave={handleBulkEditItems}
               selectedItems={items.filter((i) => selectedItemIds.includes(i.id))}
            />
         )}
      </div>
   );
};

export default ModuleContent;
