import * as React from 'react';

type Props = {
   title: string;
   icon: React.ReactNode;
};
const DropOverlayBadge: React.FC<Props> = (props) => (
   <div className='overlay-badge'>
      <div className='title'>
         {props.icon} {props.title}
      </div>
   </div>
);

export default DropOverlayBadge;
