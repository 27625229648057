import * as React from 'react';

import { convertPrecisionToSeconds, convertToPrecision } from '@components/Video/helpers';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';

interface InteractiveVideoSliderProps {
   disabled: boolean;
   duration: number;
   furthestProgressMade: number;
   progress: number;
   ticksInSeconds: readonly number[];
   onUpdate(seconds: number): void;
}

const InteractiveVideoSlider: React.FC<InteractiveVideoSliderProps> = (props) => {
   const domain = convertToPrecision(props.duration);
   const progress = convertToPrecision(props.progress);
   const tickValues = props.ticksInSeconds.map(convertToPrecision);

   const onUpdate = (values: readonly number[]): void => {
      const seconds = convertPrecisionToSeconds(values[0]);

      if (seconds < props.furthestProgressMade) {
         props.onUpdate(seconds);
      }
   };

   return (
      <Slider
         mode={1}
         step={1}
         className='slider'
         domain={[0, domain || 1]}
         disabled={props.disabled}
         values={[progress]}
         onUpdate={onUpdate}
      >
         <Rail>{({ getRailProps }) => <div className='rail disabled' {...getRailProps()} />}</Rail>
         <Handles>
            {({ handles, getHandleProps }) => (
               <>
                  {handles.map((handle) => (
                     <div
                        key={handle.id}
                        role='slider'
                        aria-valuenow={handle.value}
                        className='handle'
                        style={{ left: `${handle.percent}%` }}
                        {...getHandleProps(handle.id)}
                     />
                  ))}
               </>
            )}
         </Handles>
         <Tracks right={false}>
            {(trackProps) => (
               <>
                  {trackProps.tracks.map((track) => (
                     <div key={track.id}>
                        <div
                           className='main-track'
                           style={{
                              width: `${track.target.percent - track.source.percent}%`,
                              cursor: props.disabled ? 'not-allowed' : 'pointer',
                           }}
                           {...trackProps.getTrackProps()}
                        />
                        <div
                           className='furthest-progress-track'
                           style={{
                              width: `${(props.furthestProgressMade / props.duration) * 100}%`,
                              cursor: props.disabled ? 'not-allowed' : 'pointer',
                           }}
                           {...trackProps.getTrackProps()}
                        />
                     </div>
                  ))}
               </>
            )}
         </Tracks>
         <Ticks values={tickValues}>
            {({ ticks }) => (
               <div className='slider-ticks'>
                  {ticks.map((tick) => (
                     <div
                        key={tick.id}
                        className='item-ticks'
                        style={{
                           left: `${tick.percent}%`,
                        }}
                     />
                  ))}
               </div>
            )}
         </Ticks>
      </Slider>
   );
};

export default React.memo(InteractiveVideoSlider);
