import * as React from 'react';

import {
   isOpenTokVideoContent,
   isUploadedVideoContent,
   isVideoLibraryContent,
   isYouTubeVideoContent,
} from '@components/Activity/Utils';
import IconVideoCall1 from '@icons/nova-solid/15-Video-Meeting/video-call-1.svg';
import IconContentBox2 from '@icons/nova-solid/18-Content/content-box-2.svg';
import IconDataUpload8 from '@icons/nova-solid/23-Data-Transfer/data-upload-8.svg';
import IconVideoClip3 from '@icons/nova-solid/36-Videos/video-clip-3.svg';
import {
   ActivityBuilderMode,
   VideoContent,
   VideoLibraryContent,
   VideoSource,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import { VideoGetResponse } from '@models/Video';
import VideoService from '@services/VideoService';

export const defaultItem: Partial<VideoContent<ActivityBuilderMode>> = {
   snippetStart: 0,
   snippetEnd: 0,
   playSnippet: false,
   enableTargetSubtitles: true,
   enableNativeSubtitles: true,
   source: null,
};

export const sourceOptions = [
   {
      text: 'Record',
      icon: <IconVideoCall1 />,
      source: VideoSource.openTok,
   },
   {
      text: 'Upload',
      icon: <IconDataUpload8 />,
      source: VideoSource.upload,
   },
   {
      text: 'YouTube',
      icon: <IconVideoClip3 />,
      source: VideoSource.youTube,
   },
   {
      text: 'Content Library',
      icon: <IconContentBox2 />,
      source: VideoSource.library,
   },
];

export const isVideoLoaded = (item: VideoContent<ActivityBuilderMode>) =>
   !!item.source &&
   !!(
      (isVideoLibraryContent(item) && item.videoId) ||
      (isYouTubeVideoContent(item) && item.youtubeId) ||
      (isUploadedVideoContent(item) && item.fileUrl) ||
      (isOpenTokVideoContent(item) && item.fileUrl)
   );

export const getVideoUrl = (
   item: VideoContent<ActivityBuilderMode>,
   video: Maybe<VideoGetResponse>,
): string => {
   if (isYouTubeVideoContent(item)) {
      return item.youtubeId ? `https://youtu.be/${item.youtubeId}` : '';
   } else if (isOpenTokVideoContent(item)) {
      return item.fileUrl;
   } else if (isUploadedVideoContent(item)) {
      return item.fileUrl ?? '';
   } else if (isVideoLibraryContent(item)) {
      return video?.url ?? '';
   }
   return '';
};

export const useFetchVideo = (item: VideoContent<ActivityBuilderMode>) => {
   const [video, setVideo] = React.useState<Maybe<VideoGetResponse>>();

   React.useEffect(() => {
      if (isVideoLibraryContent(item) && item.videoId && (!video || video.id !== item.videoId)) {
         VideoService.get(item.videoId).then(setVideo);
      }
   }, [item.source, (item as VideoLibraryContent<ActivityBuilderMode>)?.videoId]);

   return video;
};
