import * as _ from 'lodash';
import * as React from 'react';

import useOutsideAlerter from '@hooks/use-outside-alerter';
import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import { CourseSection, GradingCategory, ModuleProfile } from '@models/Course';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import Switch from '@components/Common/Switch';
import Droplist from '@components/Core/Droplist';
import { GradebookGrouping, GradebookProfile } from './Gradebook';

interface GradebookFiltersProps {
   displayGradesAs: 'percentage' | 'points';
   gradingCategories: readonly GradingCategory[];
   groupGradesBy: string;
   hasDemoStudents: boolean;
   modules: Record<number, ModuleProfile>;
   needsGrading: boolean;
   roster: readonly GradebookProfile[];
   sections: readonly CourseSection[];
   showDemoStudents: boolean;
   showItemsWithNoGradingCategory: boolean;
   showStudentsWithNoSection: boolean;
   sortRosterBy: string;
   onChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void;
   onGradingCategoriesFilterChange(event: React.ChangeEvent<HTMLInputElement>): void;
   onModulesFilterChange(event: React.ChangeEvent<HTMLInputElement>): void;
   onNeedsGradingChange(event: React.ChangeEvent<HTMLInputElement>): void;
   onSectionsFilterChange(show: boolean, sectionId: number): void;
   onShowDemoStudentsChange(event: React.ChangeEvent<HTMLSelectElement>): void;
   onSortRosterByChange(event: React.ChangeEvent<HTMLSelectElement>): void;
   toggleShowItemsWithNoGradingCategory(): void;
   toggleShowStudentsWithNoSection(): void;
}

const GradebookFilters: React.FC<GradebookFiltersProps> = ({
   displayGradesAs,
   gradingCategories,
   needsGrading,
   groupGradesBy,
   hasDemoStudents,
   modules,
   sections,
   showDemoStudents,
   showItemsWithNoGradingCategory,
   showStudentsWithNoSection,
   sortRosterBy,
   onChange,
   onGradingCategoriesFilterChange,
   onModulesFilterChange,
   onSectionsFilterChange,
   onNeedsGradingChange,
   onShowDemoStudentsChange,
   onSortRosterByChange,
   toggleShowItemsWithNoGradingCategory,
   toggleShowStudentsWithNoSection,
}) => {
   const wrapperRef = React.useRef<HTMLDivElement>(null);

   const [filterMenuOpen, setFilterMenuOpen] = React.useState<boolean>(false);

   const closeFilterMenu = (): void => setFilterMenuOpen(false);

   const toggleFilterMenu = (): void =>
      setFilterMenuOpen((prevFilterMenuOpen) => !prevFilterMenuOpen);

   useOutsideAlerter(closeFilterMenu, wrapperRef);

   const renderFilterModulesItems = (): readonly React.ReactElement[] =>
      _.orderBy(Object.values(modules), ['index']).map(({ id, showInGradebook, name }) => (
         <div className='show-module-entry' key={id}>
            <input
               checked={showInGradebook}
               className='ignore-click'
               id={id.toString()}
               onChange={onModulesFilterChange}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));

   const renderFilterSectionsItems = (): readonly React.ReactElement[] => {
      const items = _.orderBy(sections, ['name']).map(({ id, show, name }) => (
         <div className='show-module-entry' key={id}>
            <input
               checked={show}
               className='ignore-click'
               id={id.toString()}
               onChange={(e) => onSectionsFilterChange(e.target.checked, id as number)}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));
      const noSectionFilter = (
         <div className='show-module-entry'>
            <input
               id='no-category-filter'
               checked={showStudentsWithNoSection}
               className='ignore-click'
               onChange={toggleShowStudentsWithNoSection}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor='no-category-filter'>
               No Section
            </label>
         </div>
      );
      return [noSectionFilter, ...items];
   };

   const renderFilterGradingCategoriesItems = (): readonly React.ReactElement[] => {
      const items = _.orderBy(gradingCategories, ['name']).map(({ id, show, name }) => (
         <div className='show-module-entry' key={id}>
            <input
               checked={show}
               className='ignore-click'
               id={id.toString()}
               onChange={onGradingCategoriesFilterChange}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));
      const noCategoryFilter = (
         <div className='show-module-entry'>
            <input
               id='no-category-filter'
               checked={showItemsWithNoGradingCategory}
               className='ignore-click'
               onChange={toggleShowItemsWithNoGradingCategory}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor='no-category-filter'>
               None
            </label>
         </div>
      );
      return [noCategoryFilter, ...items];
   };

   const hasGradingCategories = !!gradingCategories.length;

   return (
      <div className='dropdown' ref={wrapperRef}>
         <div
            aria-expanded={filterMenuOpen}
            aria-label='Filtering and Sorting'
            className='dropdown-toggle'
            data-toggle='dropdown'
            onClick={toggleFilterMenu}
         >
            <Tippy content='Filtering and Sorting'>
               <div
                  className={classnames('icon-action', {
                     open: filterMenuOpen,
                  })}
               >
                  <IconContentFilter aria-label='Filtering and Sorting' />
               </div>
            </Tippy>
         </div>
         {filterMenuOpen && (
            <div className='dropdown-menu gradebook-filters-popup'>
               <div className='card-title'>
                  <div className='title'>Filtering and Sorting</div>
               </div>
               <div className='popup-content'>
                  <div className='row first-row'>
                     <div className='col-xs-12'>
                        <label className='field-title'>Filters</label>
                        <div className='gradebook-filters'>
                           <Droplist items={renderFilterModulesItems()}>
                              <Button line>Modules</Button>
                           </Droplist>
                           {hasGradingCategories && (
                              <Droplist items={renderFilterGradingCategoriesItems()}>
                                 <Button line>Grading Categories</Button>
                              </Droplist>
                           )}
                           {!!sections.length && (
                              <Droplist items={renderFilterSectionsItems()}>
                                 <Button line>Sections</Button>
                              </Droplist>
                           )}
                           <Switch
                              checked={
                                 groupGradesBy === GradebookGrouping.assignment && needsGrading
                              }
                              onChange={onNeedsGradingChange}
                              disabled={groupGradesBy !== GradebookGrouping.assignment}
                              tooltip={
                                 groupGradesBy !== GradebookGrouping.assignment
                                    ? 'Group by Grades must be None'
                                    : undefined
                              }
                           >
                              <label className='field-title'>Needs Grading</label>
                           </Switch>
                        </div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12'>
                        <label className='field-title margin-top-s' htmlFor='groupGradesBy'>
                           Group Grades By
                        </label>
                        <select
                           name='groupGradesBy'
                           id='groupGradesBy'
                           value={groupGradesBy}
                           onChange={onChange}
                        >
                           <option value={GradebookGrouping.assignment}>None</option>
                           <option value={GradebookGrouping.module}>Module</option>
                           {hasGradingCategories && (
                              <option value={GradebookGrouping.gradingCategory}>
                                 Grading Category
                              </option>
                           )}
                        </select>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12'>
                        <label className='field-title margin-top-s' htmlFor='sortRosterBy'>
                           Sort Roster By
                        </label>
                        <select
                           name='sortRosterBy'
                           id='sortRosterBy'
                           value={sortRosterBy}
                           onChange={onSortRosterByChange}
                        >
                           <option value='firstName'>First Name</option>
                           <option value='lastName'>Last Name</option>
                           <option value='grade'>Grade</option>
                        </select>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12'>
                        <label className='field-title margin-top-s' htmlFor='displayGradesAs'>
                           Display Grades As
                        </label>
                        <select
                           name='displayGradesAs'
                           id='displayGradesAs'
                           value={displayGradesAs}
                           onChange={onChange}
                        >
                           <option value='percentage'>Percentage</option>
                           <option value='points'>Points</option>
                        </select>
                     </div>
                  </div>
                  {hasDemoStudents && (
                     <div className='row'>
                        <div className='col-xs-12'>
                           <label className='field-title' htmlFor='showDemoStudents'>
                              Demo Students
                           </label>
                           <select
                              name='showDemoStudents'
                              id='showDemoStudents'
                              value={showDemoStudents ? 'show' : 'hide'}
                              onChange={onShowDemoStudentsChange}
                           >
                              <option value='show'>Show</option>
                              <option value='hide'>Hide</option>
                           </select>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         )}
      </div>
   );
};

export default React.memo(GradebookFilters);
