// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import deepEqual from 'fast-deep-equal';

const normalizeHtml = (str: string): string => {
   if (str) {
      return str.replace(/&nbsp;|<br>|\u202F|\u00A0/g, ' ');
   } else {
      return str;
   }
};

const replaceCaret = (el: HTMLElement): void => {
   // Place the caret at the end of the element
   const target = document.createTextNode('');
   el.appendChild(target);
   // do not move caret if element was not focused
   const isTargetFocused = document.activeElement === el;
   if (target !== null && target.nodeValue !== null && isTargetFocused) {
      const sel = window.getSelection();
      if (sel !== null) {
         const range = document.createRange();
         range.setStart(target, target.nodeValue.length);
         range.collapse(true);
         sel.removeAllRanges();
         sel.addRange(range);
      }
      if (el instanceof HTMLElement) {
         el.focus();
      }
   }
};

export type ContentEditableEvent = React.SyntheticEvent<any, Event> & {
   target: { value: string };
};

export interface IContentEditableProps extends React.HTMLProps<HTMLDivElement> {
   html: string;
   innerRef?: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement) => void);
}

class ContentEditable extends React.Component<IContentEditableProps> {
   lastHtml: string = this.props.html;
   el: any =
      typeof this.props.innerRef === 'function'
         ? { current: null }
         : React.createRef<HTMLElement>();

   getEl = (): HTMLDivElement =>
      (this.props.innerRef && typeof this.props.innerRef !== 'function'
         ? this.props.innerRef
         : this.el
      ).current;

   shouldComponentUpdate(nextProps: IContentEditableProps): boolean {
      const { props } = this;
      const el = this.getEl();

      // We need not rerender if the change of props simply reflects the user's edits.
      // Rerendering in this case would make the cursor/caret jump

      // Rerender if there is no element yet... (somehow?)
      if (!el) {
         return true;
      }

      // ...or if html really changed... (programmatically, not by user edit)
      if (normalizeHtml(nextProps.html) !== normalizeHtml(el.innerHTML)) {
         return true;
      }

      // Handle additional properties
      return (
         props.disabled !== nextProps.disabled ||
         props.className !== nextProps.className ||
         props.innerRef !== nextProps.innerRef ||
         !deepEqual(props.style, nextProps.style)
      );
   }

   componentDidUpdate(): void {
      const el = this.getEl();
      if (!el) {
         return;
      }

      // Perhaps React (whose VDOM gets outdated because we often prevent
      // rerendering) did not update the DOM. So we update it manually now.
      if (this.props.html !== el.innerHTML) {
         el.innerHTML = this.props.html;
      }
      this.lastHtml = this.props.html;
      replaceCaret(el);
   }

   emitChange = (originalEvt: React.SyntheticEvent<any>): void => {
      const el = this.getEl();
      if (!el) {
         return;
      }

      const html = normalizeHtml(el.innerHTML);
      if (this.props.onChange && html !== this.lastHtml) {
         // Clone event with Object.assign to avoid
         // "Cannot assign to read only property 'target' of object"
         const evt = Object.assign({}, originalEvt, {
            target: {
               value: html,
               innerText: el.innerText,
            },
         });
         this.props.onChange(evt);
      }
      this.lastHtml = html;
   };

   handlePaste = (event: React.ClipboardEvent): void => {
      event.preventDefault();
      const target = event.target as HTMLDivElement;
      const text = event.clipboardData.getData('text/plain');
      let sel, range;
      if (window.getSelection) {
         sel = window.getSelection();
         if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode(text));
         }
      } else if (document.selection && document.selection.createRange) {
         document.selection.createRange().text = text;
      }
      const evt = Object.assign(
         {},
         {
            target: {
               value: target.innerHTML,
               innerText: target.innerText,
            },
         },
      );
      this.props.onChange(evt as any);
   };

   render(): React.ReactNode {
      const { html, innerRef, ...props } = this.props;
      return (
         <div
            {...props}
            ref={
               typeof innerRef === 'function'
                  ? (current: HTMLDivElement) => {
                       innerRef(current);
                       this.el.current = current;
                    }
                  : innerRef || this.el
            }
            onPaste={this.handlePaste}
            onInput={this.emitChange}
            onBlur={this.props.onBlur || this.emitChange}
            onKeyUp={this.props.onKeyUp || this.emitChange}
            onKeyDown={this.props.onKeyDown || this.emitChange}
            contentEditable={!this.props.disabled}
            dangerouslySetInnerHTML={{ __html: html }}
         >
            {this.props.children}
         </div>
      );
   }
}

export default ContentEditable;
