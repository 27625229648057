import { useDraggable } from '@dnd-kit/core';
import { ID } from '@models/Core';
import React from 'react';

type Props<T extends object> = {
   children: React.ReactNode;
   className?: string;
   data: T;
   id: ID;
};

const Draggable = <T extends object>(props: Props<T>) => {
   const { attributes, listeners, setNodeRef, transform } = useDraggable({
      id: props.id,
      data: props.data,
   });

   const style = transform
      ? {
           transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        }
      : undefined;

   return (
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
         {props.children}
      </div>
   );
};

export default Draggable;
