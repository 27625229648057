import { IdName, Maybe } from '@models/Core';
import { CountryName } from '@models/Country';

export enum NationalExamAttachmentType {
   rawDataSummary = 'raw_data_summary',
   studentReportCard = 'student_report_card',
   studentCertificate = 'student_certificates',
}

export const NationalExamAbbreviationConstants = [
   'nfc',
   'nge',
   'nge_prep',
   'nge_spring',
   'nie',
   'nie_pilot',
   'nle',
   'npe',
   'nsa',
   'nsc',
   'nsc_prep',
   'nse',
   'nse_prep',
   'nrce',
   'pme',
   'mme',
   'ele',
   'ncee',
   'ngre',
   'nlve',
   'nhce',
] as const;

export type NationalExamAbbreviation = (typeof NationalExamAbbreviationConstants)[number];
export interface NationalExamChapter {
   id: number;
   examId: number;
   name: string;
   externalId: Maybe<string>;
   regionName: Maybe<string>;
   externalRegionId: Maybe<string>;
}

export type NationalExamFormats = 'Paper' | 'Online';

type NameLink = {
   name: string;
   link: string;
};

export interface NationalExam {
   id: number;
   additionalFee: number;
   additionalFeeName: string;
   chapters?: readonly NationalExamChapter[];
   examFormats: NationalExamFormats[];
   examYear: number;
   handlingFeeDomesticLarge: number;
   handlingFeeDomesticSmall: number;
   handlingFeeInternational: number;
   hasLingcoRegistration: boolean;
   hasPublicPracticeCourses: boolean;
   internationalPrice: number;
   isClosed: boolean;
   isLate: boolean;
   isPaperClosed: boolean;
   lateFee: number;
   lateRegDate: Date | null;
   memberPrice: number;
   membershipName: string;
   name: string;
   nameAbbr: NationalExamAbbreviation;
   nonMemberFee: number | null;
   paperRegEndDate: Date | null;
   price: number;
   regEndDate: Date;
   regStartDate: Date;
   seatCutoff: number;
   shortName: string;
   helpLinks: readonly NameLink[] | null;
   reportLinks: readonly NameLink[] | null;
}

export type FullNationalExam = NationalExam & {
   orderSpreadsheet: string | null;
   orderWorksheet: string | null;
   invoiceTemplate: string | null;
   shouldCacheResults: boolean;
};

export type AdminNationalExam = Pick<
   NationalExam,
   'id' | 'name' | 'examYear' | 'regStartDate' | 'regEndDate' | 'lateRegDate'
>;

export interface NationalExamLevel {
   id: number;
   levelName: string;
   levelCatName: string;
   sortOrder: number;
}

export interface NationalExamLevelOrder {
   levelId: number;
   qty: number;
}

export type ExamOrderResponseAlreadyClonedCourses = IdName & { clonedFromId: number };

export interface NationalExamRegistrationOrder {
   id: Maybe<number>;
   schoolName: string;
   isMember: boolean;
   /** Total seats; only used during registration */
   totalSeats: number;
   /** Price per exam; FIXME refactor name. */
   price: number;
   lateFee: number;
   additionalFeeName: string;
   additionalFee: number;
   /** Chapter ID; only used during registration */
   chapterId: Maybe<number>;
   lastName: string;
   firstName: string;
   email: string;
   phone: string;
   schoolType: string;
   schoolAddress: string;
   schoolCity: string;
   schoolState: string;
   schoolZip: string;
   schoolCountry?: CountryName;
   /** Not used by NLE */
   levels?: string;
   createdOn: Maybe<Date>;
   /** Used during registration */
   levelIds?: readonly number[];
   seats?: NationalExamLevelOrder[];
   /** FIXME: Change to Exam format */
   paperExam?: boolean;
   mailExamsTo?: string;
   principalName?: string;
   administratorName?: string;
}

export type AdminExamOrder = {
   id: number;
   createdOn: Date;
   examLevels: string;
   examName: string;
   examYear: number;
   hasPerLevelSeats: boolean;
   instructorEmail: string;
   instructorFirstName: string;
   instructorId: string;
   instructorLastName: string;
   orderStatus: string;
   schoolName: string;
   totalSeats: number;
};

export type NationalExamLevelSeatAllocation = {
   levelId: number;
   levelName: string;
   seatsUsed: number;
   seatsPurchased: number;
};

export type AdminNationalExamLevelSeatAllocation = Pick<
   NationalExamLevelSeatAllocation,
   'levelId' | 'levelName' | 'seatsPurchased'
>;

export type AdminNationalExamLevel = Pick<NationalExamLevel, 'id' | 'levelName'>;

export type NationalExamReportLinks = {
   createdOn: Date;
   fileName: string;
   fileType: string;
   link: string;
   typeName: string;
   typeDisplayName: string;
   messageId?: string;
};

// These report runs are created, but not persisted to the assets table.
export type TransientNationalExamReportTaskResponse = {
   fileUrl: string;
   messageId: string;
};

export const isTransientNationalExamReportTaskResponse = (
   data: unknown,
): data is TransientNationalExamReportTaskResponse => {
   const possibleTransientResponse = data as TransientNationalExamReportTaskResponse;
   return (
      possibleTransientResponse !== undefined &&
      possibleTransientResponse.messageId !== undefined &&
      possibleTransientResponse.fileUrl !== undefined
   );
};

// These report runs are persisted to the assets table.
export type PersistedNationalExamReportTaskResponse = {
   fileUrl: string;
   reports: NationalExamReportLinks[];
};

export const isPersistedNationalExamReportTaskResponse = (
   data: unknown,
): data is PersistedNationalExamReportTaskResponse =>
   (data as PersistedNationalExamReportTaskResponse).reports !== undefined;

export type NationalExamReportTaskResponse =
   | PersistedNationalExamReportTaskResponse
   | TransientNationalExamReportTaskResponse;

export type NationalExamCourseSearchRow = {
   courseId: number;
   courseName: string;
   userId: number;
   userEmail: string;
   userFirstName: string;
   userLastName: string;
   organizationId: number;
   organizationName: string;
};
