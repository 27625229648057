import * as _ from 'lodash';
import * as React from 'react';

import { IApplicationState } from '@models/ApplicationState';
import { isTag, isText, Node } from 'domhandler';
import Parser, { domToReact, HTMLReactParserOptions } from 'html-react-parser';

import Link from '@components/Common/Link';
import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import { store } from '@redux/Store';

export { isTag, isText };

const parserOptions: HTMLReactParserOptions = {
   replace: (tag) => {
      if (isTag(tag) && tag.attribs) {
         if (tag.name === 'audio' && tag.attribs['data-player-size']) {
            return (
               <AudioPlayer
                  src={tag.attribs.src}
                  className={tag.attribs.class}
                  size={tag.attribs['data-player-size'] as AudioPlayerSize}
               />
            );
         } else if (tag.name === 'span' && tag.attribs.class?.split(' ').includes('inline-audio')) {
            const audio = tag.children.find((i: Node) => isTag(i) && i.name === 'audio');
            if (audio && isTag(audio)) {
               const children = Array.from(tag.children).filter((child) => child !== audio);
               return (
                  <AudioPlayer src={audio.attribs.src} className={tag.attribs.class}>
                     {domToReact(children)}
                  </AudioPlayer>
               );
            }
         } else if (tag.name === 'video' && tag.attribs['data-responsive']) {
            const { height, width, style = '', ...attribs } = tag.attribs;
            return (
               <div className='video-wrapper-outer'>
                  <div className='video-wrapper-inner'>
                     <video controls style={getStyleObjectFromString(style)} {...attribs}>
                        {domToReact(tag.children)}
                     </video>
                  </div>
               </div>
            );
         } else if (tag.name === 'a' && tag.attribs['href']?.startsWith('/')) {
            return (
               <Link to={tag.attribs['href']} className={tag.attribs['class']}>
                  {domToReact(tag.children)}
               </Link>
            );
         }
         if (tag.attribs['contenteditable']) {
            delete tag.attribs['contenteditable'];
         }
      }
      return null;
   },
};

export const parseHtml = (
   input: string,
   options: HTMLReactParserOptions = parserOptions,
   customVariables: Record<string, string | boolean | number> = {},
): string | React.ReactNode | readonly React.ReactNode[] => {
   const filledInput = replaceVariables(input, customVariables);
   const result = Parser(filledInput, options);

   return _.isArray(result) && result.length === 0 ? null : result;
};

const getStyleObjectFromString = (str: string): React.CSSProperties => {
   const style: Record<string, string> = {};
   str.split(';').forEach((el) => {
      const [property, value] = el.split(':');
      if (!property) {
         return;
      }

      const formattedProperty = _.camelCase(property.trim());
      style[formattedProperty] = value.trim();
   });

   return style;
};

const replaceVariables = (
   content: string,
   customVariables: Record<string, string | boolean | number> = {},
): string => {
   if (!content) {
      return content;
   }
   const state: IApplicationState = store.getState();
   const userProfile = state?.user?.userProfile;
   const variables = { ...customVariables };
   if (userProfile) {
      const lingcoVariables: Record<string, string> = {
         userEmail: userProfile.email,
         userFirstName: userProfile.firstName,
         userLastName: userProfile.lastName,
         userId: userProfile.id.toString(),
      };
      Object.assign(variables, lingcoVariables);
   }

   let filledContent = content;
   Object.keys(variables).forEach((key) => {
      filledContent = filledContent.replaceAll(`{{${key}}}`, (variables[key] ?? '').toString());
   });

   return filledContent;
};

export default parseHtml;
