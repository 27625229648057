import * as React from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { IdName } from '@models/Core';
import { NationalExamCourseSearchRow } from '@models/NationalExam';
import NationalExamAdminService, { GetExamCoursesParams } from '@services/NationalExamAdminService';
import { useForm } from 'react-hook-form';

import Constants from '../../Constants';
import Button from '@components/Common/Button';
import CardBody from '@components/Common/CardBody';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';

const searchFields = yup.object().shape({
   nationalExamId: yup.number().required(),
   hostInstructorName: yup.string(),
   hostInstructorEmail: yup.string(),
   hostSchoolName: yup.string(),
});

const NationalExamCourseSearch: React.FC = () => {
   const [nationalExams, setNationalExams] = React.useState<readonly IdName[]>([]);
   const [courseSearchResult, setCourseSearchResult] = React.useState<
      readonly NationalExamCourseSearchRow[]
   >([]);

   const { handleSubmit, register } = useForm({
      resolver: yupResolver<GetExamCoursesParams>(searchFields),
   });

   React.useEffect(() => {
      NationalExamAdminService.getNationalExams().then((x) => {
         setNationalExams(x);
      });
   }, []);

   const onSubmit = (data: GetExamCoursesParams): void => {
      NationalExamAdminService.getExamCourses(data).then((x) => {
         setCourseSearchResult(x.rows);
      });
   };

   const columns: readonly Column<NationalExamCourseSearchRow>[] = [
      {
         id: 'courseId',
         cell: (i) => (
            <Link
               to={Constants.routes.courses.dashboard.replace(':courseId', i.courseId.toString())}
            >
               {i.courseId}
            </Link>
         ),
         cellClassName: 'text-left',
         header: 'Course Id',
         headerClassName: 'text-left',
      },
      {
         id: 'courseName',
         cell: (i) => i.courseName,
         cellClassName: 'text-left',
         header: 'Course Name',
         headerClassName: 'text-left',
      },
      {
         id: 'organizationName',
         cell: (i) => i.organizationName,
         cellClassName: 'text-left',
         header: 'Organization Name',
         headerClassName: 'text-left',
      },
      {
         id: 'userFullName',
         cell: (i) => `${i.userLastName}, ${i.userFirstName}`,
         cellClassName: 'text-left',
         header: 'Instructor Name',
         headerClassName: 'text-left',
      },
      {
         id: 'userEmail',
         cell: (i) => i.userEmail,
         cellClassName: 'text-left',
         header: 'Instructor Email',
         headerClassName: 'text-left',
      },
   ];

   return (
      <CardBody documentTitle='Course Search' cardTitle='Course Search'>
         <form className='padding-l' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-gap-s'>
               <div className='flex-1'>
                  <label className='field-title'>Exam</label>
                  <select {...register('nationalExamId')}>
                     {nationalExams.map((x) => (
                        <option key={x.id} value={x.id}>
                           {x.name}
                        </option>
                     ))}
                  </select>
               </div>
               <div className='flex-1'>
                  <label className='field-title'>Instructor Name</label>
                  <input {...register('hostInstructorName')} type='text' />
               </div>
            </div>
            <div className='flex flex-gap-s'>
               <div className='flex-1'>
                  <label className='field-title'>Instructor Email</label>
                  <input {...register('hostInstructorEmail')} type='text' />
               </div>
               <div className='flex-1'>
                  <label className='field-title'>School Name</label>
                  <input {...register('hostSchoolName')} type='text' />
               </div>
            </div>
            <div className='flex justify-center'>
               <Button className='flex-1 margin-top-m max-third-width' type='submit'>
                  Search
               </Button>
            </div>
         </form>
         {courseSearchResult.length === 0 ? (
            <EmptyState
               // icon={<IconSlider1 aria-hidden />}
               heading='No Search Results'
               description='Please enter '
            />
         ) : (
            <Table<NationalExamCourseSearchRow>
               columns={columns}
               rowKey='courseId'
               rows={courseSearchResult}
            />
         )}
      </CardBody>
   );
};

export default NationalExamCourseSearch;
