import * as _ from 'lodash';
import * as React from 'react';

import AccountType from '@models/AccountType';
import { Maybe, MessageResponse } from '@models/Core';
import {
   CourseEnrollment,
   CourseInvite,
   CourseSection,
   isEnrollment,
   RosterRecord,
} from '@models/Course';
import LMSName from '@models/LMSName';
import CourseService from '@services/CourseService';
import HttpService from '@services/HttpService';
import validateEmails from '@services/ValidateEmails';
import Axios, { AxiosResponse } from 'axios';
import classnames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@components/Common/Button';
import { lmsDisplayName } from '@components/Core/LMS';
import LMSButton from '@components/Core/LMS/LMSButton';
import ModalDialog from '@components/Core/ModalDialog';
import CourseCodes from '@components/Course/CourseCodes';
import CourseCode from './CourseCode';
import LMSSyncResultModal, { LMSSyncResult } from './LMSSyncResultModal';
import ResetCourseCodeModal from './ResetCourseCodeModal';

interface AddToRosterModalProps {
   courseCode: string;
   courseId: number;
   existingEmails: readonly string[];
   instructorCourseCode: string;
   lmsName: LMSName;
   roster: readonly RosterRecord[];
   schoolDomainRestricted: boolean;
   organizationId: number;
   sections: readonly CourseSection[];
   onClose(): void;
   removeRosterEntriesByEmail(emails: readonly string[]): void;
   resetInstructorCourseCode(): void;
   resetSectionCourseCode(sectionId: number): void;
   resetStudentCourseCode(): void;
   updateRoster(invites: readonly CourseInvite[], enrollments: readonly CourseEnrollment[]): void;
}

interface LMSRosterSyncResponse {
   enrollments: readonly CourseEnrollment[];
   invites: readonly CourseInvite[];
   syncResult: LMSSyncResult;
}

const AddToRosterModal: React.FC<AddToRosterModalProps> = ({
   courseCode,
   courseId,
   existingEmails,
   instructorCourseCode,
   lmsName,
   roster,
   schoolDomainRestricted,
   organizationId,
   sections,
   resetStudentCourseCode,
   resetSectionCourseCode,
   resetInstructorCourseCode,
   onClose,
   removeRosterEntriesByEmail,
   updateRoster,
}) => {
   const [domains, setDomains] = React.useState<readonly string[]>([]);
   const [domainsMessage, setDomainsMessage] = React.useState<string>('');
   const [emails, setEmails] = React.useState<readonly string[]>([]);
   const [emailsInput, setEmailsInput] = React.useState<string>('');
   const [errorMessage, setErrorMessage] = React.useState<string>('');
   const [selectedResetCodeSectionId, setSelectedResetCodeSectionId] =
      React.useState<Maybe<number>>(null);
   const [inviteLinkCopied, setInviteLinkCopied] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isRemovingStudents, setIsRemovingStudents] = React.useState<boolean>(false);
   const [isSyncingRoster, setIsSyncingRoster] = React.useState<boolean>(false);
   const [isResetCourseCodeModalOpen, setIsResetCourseCodeModalOpen] =
      React.useState<boolean>(false);
   const [selectedInviteType, setSelectedInviteType] = React.useState<AccountType>(
      AccountType.student,
   );
   const [syncResult, setSyncResult] = React.useState<Maybe<LMSSyncResult>>(null);

   React.useEffect(() => {
      if (schoolDomainRestricted) {
         validateEmails.schoolEmailDomains(organizationId).then((response) => {
            if (response.length > 0) {
               setDomains(response);
               if (response.length === 1) {
                  setDomainsMessage(
                     'Please ensure all emails follow this domain restriction: ' + response,
                  );
               } else {
                  setDomainsMessage(
                     'Please ensure all emails follow these domain restrictions: ' +
                        response.join(', '),
                  );
               }
            }
         });
      }
   }, [schoolDomainRestricted]);

   const sendInvites = (accountType: AccountType): Promise<void> => {
      setIsLoading(true);
      return CourseService.sendInvites(
         courseId,
         emails.map((email) => ({ email })),
         accountType,
      ).then(({ invites: updatedInvites, enrollments: updatedEnrollments }) => {
         updateRoster(updatedInvites, updatedEnrollments);
         onClose();
      });
   };

   const submit = async (): Promise<void> => {
      sendInvites(selectedInviteType);
   };

   const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const newInput = event.target.value;
      setEmailsInput(newInput);
      let newErrorMessage = '';
      let newEmails: readonly string[] = [];
      if (newInput.length > 0) {
         const parsedEmails = parseEmails(newInput);
         const filteredEmails = filterOutExistingEmails(parsedEmails);
         if (parsedEmails.length <= 0) {
            newErrorMessage = 'Input one or more valid emails.';
         } else if (filteredEmails.length <= 0) {
            newErrorMessage = 'These emails all have existing invites.';
         } else if (
            domains.length > 0 &&
            !validateEmails.validateEmailsForDomains(filteredEmails, domains)
         ) {
            newErrorMessage = domainsMessage;
         } else {
            newEmails = filteredEmails;
         }
      }
      setEmails(newEmails);
      setErrorMessage(newErrorMessage);
   };

   const filterOutExistingEmails = (emailsToFilter: readonly string[]): readonly string[] =>
      emailsToFilter.filter((i) => !existingEmails.includes(i));

   const parseEmails = (input: string): readonly string[] => {
      const tokens = input.split(/[\s\n;",;:()<>[\]\\]+/);
      // regex sauce: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      const re =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return _.uniq(tokens.filter((i) => i.match(re)).map((i) => i.toLowerCase()));
   };

   const renderLMSSyncButton = (): Maybe<React.ReactNode> => {
      const displayName = lmsDisplayName[lmsName];
      if (displayName) {
         return (
            <LMSButton lmsName={lmsName} loading={isSyncingRoster} onClick={handleSyncWithLMS}>
               {`Sync with ${displayName}`}
            </LMSButton>
         );
      }
      return null;
   };

   const handleResetCourseCode = (): void => {
      if (selectedInviteType === AccountType.instructor) {
         resetInstructorCourseCode();
      } else if (selectedResetCodeSectionId) {
         resetSectionCourseCode(selectedResetCodeSectionId);
      } else {
         resetStudentCourseCode();
      }
      closeResetCourseCodeModal();
   };

   const closeResetCourseCodeModal = (): void => {
      setSelectedResetCodeSectionId(null);
      setIsResetCourseCodeModalOpen(false);
   };

   const handleRemoveStudents = async (): Promise<void> => {
      setIsRemovingStudents(true);
      if (!syncResult) {
         return;
      }
      const requests: Promise<AxiosResponse>[] = [];
      syncResult.toRemove.forEach((email) => {
         const rosterEntry = roster.find((i) => isEnrollment(i) && i.email === email);
         if (rosterEntry && isEnrollment(rosterEntry)) {
            requests.push(
               HttpService.deleteWithAuthToken<MessageResponse>(
                  `/api/courses/${courseId}/roster/${rosterEntry.enrollmentId}`,
               ),
            );
         }
      });
      return Axios.all(requests).then(() => {
         removeRosterEntriesByEmail(syncResult.toRemove);
         setIsRemovingStudents(false);
         onClose();
      });
   };

   const handleSyncWithLMS = (): Promise<void> => {
      setIsSyncingRoster(true);
      return HttpService.postWithAuthToken<LMSRosterSyncResponse>(
         `/api/courses/${courseId}/roster/lms_sync`,
      ).then((response) => {
         const {
            invites: updatedInvites,
            enrollments: updatedEnrollments,
            syncResult: updatedSyncResult,
         } = response.data;
         updateRoster(updatedInvites, updatedEnrollments);
         setIsSyncingRoster(false);
         setSyncResult(updatedSyncResult);
      });
   };

   const renderTab = (name: AccountType): React.ReactNode => (
      <div
         className={classnames('col', { active: name === selectedInviteType })}
         data-test={`div-${name}-invite-tab`}
         onClick={() => setSelectedInviteType(name)}
      >
         <div className='title small'>{name}</div>
      </div>
   );

   const openResetCourseCodeModal = (sectionId: Maybe<number> = null): void => {
      setSelectedResetCodeSectionId(sectionId);
      setIsResetCourseCodeModalOpen(true);
   };

   if (syncResult) {
      return (
         <LMSSyncResultModal
            lmsName={lmsName}
            courseId={courseId}
            handleRemoveStudents={handleRemoveStudents}
            isRemovingStudents={isRemovingStudents}
            onClose={onClose}
            roster={roster}
            syncResult={syncResult}
         />
      );
   }

   if (isResetCourseCodeModalOpen) {
      return (
         <ResetCourseCodeModal
            instructor={selectedInviteType === AccountType.instructor}
            section={selectedResetCodeSectionId !== null}
            reset={handleResetCourseCode}
            close={closeResetCourseCodeModal}
         />
      );
   }

   const inviteLink = (): string => {
      if (selectedInviteType === AccountType.instructor) {
         return `${window.location.origin}/courses/join?code=${instructorCourseCode}`;
      }
      return `${window.location.origin}/register?courseCode=${courseCode}`;
   };

   return (
      <ModalDialog
         heading='+ Add/Invite to Course'
         onClose={onClose}
         className='no-padding'
         bodyClassName='content-row-container'
         footerClassName='card-footer padding-right-m padding-left-m'
         headerClassName='card-title padding-right-m padding-left-m'
         actions={[
            {
               text: `Invite ${selectedInviteType}s`,
               onClick: submit,
               disabled: !emails.length,
               loading: isLoading,
            },
            { text: 'Cancel', onClick: onClose },
         ]}
      >
         <div className='section-tab'>
            <div className='row'>
               {renderTab(AccountType.student)}
               {renderTab(AccountType.instructor)}
            </div>
         </div>
         <div className='invite-students'>
            {selectedInviteType === AccountType.student && (
               <>
                  {!!lmsName && (
                     <div className='row bottom-xs'>
                        <div className='col-xs-12'>{renderLMSSyncButton()}</div>
                     </div>
                  )}
                  <CourseCodes
                     resetStudentCourseCode={openResetCourseCodeModal}
                     resetSectionCourseCode={openResetCourseCodeModal}
                     courseCode={courseCode}
                     sections={sections}
                  />
                  <div className='hr-sect'>or</div>
               </>
            )}
            {selectedInviteType === AccountType.instructor && (
               <>
                  <div>
                     <CourseCode
                        name='Instructor'
                        code={instructorCourseCode}
                        resetCode={openResetCourseCodeModal}
                     />
                  </div>
                  <div className='row bottom-xs'>
                     <div className='col-xs-12'>
                        <p className='small gray'>
                           Give this code to other instructors and they will be able to join the
                           course.
                        </p>
                     </div>
                  </div>
                  <div className='hr-sect'>or</div>
               </>
            )}
            <div className='row bottom-xs row-duplicate'>
               <div className='col-xs-12'>
                  <p className='field-title'>Email Addresses to Invite</p>
                  <textarea
                     name='emails'
                     value={emailsInput}
                     onChange={handleEmailChange}
                     placeholder='name@example.com, name2@example.com...'
                  />
                  {errorMessage ? (
                     <p className='invalid'>{errorMessage}</p>
                  ) : (
                     domains.length > 0 && <p>{domainsMessage}</p>
                  )}
               </div>
            </div>
            <div className='hr-sect'>or</div>
            <div className='row bottom-xs row-duplicate'>
               <div className='col-xs-12'>
                  <p className='field-title'>Share link</p>
                  <div className='copy-field'>
                     <CopyToClipboard text={inviteLink()} onCopy={() => setInviteLinkCopied(true)}>
                        <Button color='white' className='copy-button'>
                           {inviteLinkCopied ? 'Copied' : 'Copy'}
                        </Button>
                     </CopyToClipboard>
                     <input
                        readOnly
                        type='text'
                        name='inviteLink'
                        className='disabled'
                        value={inviteLink()}
                     />
                  </div>
                  {selectedInviteType === AccountType.instructor ? (
                     <p className='small gray'>
                        Give this link to other instructors and they will be able to join the
                        course.
                     </p>
                  ) : (
                     <p className='small gray'>
                        Give this link to your students and they will be able to join the course.
                     </p>
                  )}
               </div>
            </div>
         </div>
      </ModalDialog>
   );
};

export default AddToRosterModal;
