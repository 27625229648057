import * as React from 'react';

import classnames from 'classnames';

import Constants from '../../../Constants';

interface ProgressDotsProps {
   level: number;
}

const ProgressDots: React.FC<ProgressDotsProps> = ({ level }) => {
   const { vocabLearnedLevel } = Constants;

   return (
      <div className='session-progress'>
         {[...Array(vocabLearnedLevel).keys()].map((i) => (
            <div
               key={i}
               className={classnames('session-progress-dot', {
                  active: i < level,
               })}
            />
         ))}
      </div>
   );
};

export default React.memo(ProgressDots);
