import tinymce from 'tinymce';

tinymce.PluginManager.add('fixmenus', (editor) => {
   const isEditable = (node: Element | null): boolean => editor.dom.isEditable(node);
   const isInEditableContext = (el: Element) => isEditable(el.parentElement);
   const isImage = (node: Element): boolean =>
      node.nodeName === 'IMG' ||
      (node.nodeName === 'FIGURE' && /image/i.test(node.className) && isInEditableContext(node));
   const isAudio = (node: Element): boolean => node.nodeName === 'AUDIO';

   const shouldNotShowContextMenu = (element: Element) =>
      ['.example-container', '.callout', '.conversation', '.conjugation-table'].some((i) =>
         element.closest(i),
      );

   const allowDeleteElement = (node: Node) =>
      editor.dom.getRoot().childElementCount > 1 &&
      node.parentElement === editor.dom.getRoot() &&
      ['P', 'H1', 'H2', 'H3'].includes(node.nodeName);

   // if (menus.table) {
   //    menus.table.update = (element) => (element.closest('.conjugation-table') ? '' : 'table');
   // }

   editor.ui.registry.addContextMenu('custom-media', {
      update: (element) =>
         shouldNotShowContextMenu(element) && element.nodeName !== 'AUDIO' ? '' : 'media',
   });

   editor.ui.registry.addMenuItem('delete-element', {
      text: 'Delete Element',
      icon: 'remove',
      onAction: () => {
         const node = editor.selection.getNode();
         if (node.parentNode) {
            node.parentNode.removeChild(node);
         }
      },
   });

   editor.ui.registry.addContextMenu('custom-delete', {
      update: (element) =>
         editor.selection.isCollapsed() && allowDeleteElement(element) ? 'delete-element' : '',
   });

   editor.on('init', () => {
      const { contextMenus, contextToolbars } = editor.ui.registry.getAll();

      if (contextMenus.image) {
         contextMenus.image.update = (element) =>
            shouldNotShowContextMenu(element) && !isImage(element) ? '' : 'image';
      }

      if (contextMenus.link) {
         contextMenus.link.update = (element) => (element.nodeName === 'A' ? 'link' : '');
      }

      if (contextToolbars.textselection) {
         contextToolbars.textselection.predicate = (node) =>
            !isImage(node) && !isAudio(node) && !editor.selection.isCollapsed() && isEditable(node);
      }
   });

   return {
      getMetadata: () => ({
         name: 'Fix Context Menus',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
