import * as _ from 'lodash';
import * as React from 'react';

import ImpersonateUserButton from '@components/AdminUsersTable/ImpersonateUserButton';
import Button from '@components/Common/Button';
import { SectionCard } from '@components/Common/SectionCard';
import Switch from '@components/Common/Switch';
import SelectTypeAhead from '@components/SelectTypeAhead';
import {
   Feature,
   Organization,
   OrganizationSearchResultFragment,
   UserInput,
   UserPasswordUpdateInput,
   UserProfileFragment,
   UserProfileOrganizationFragment,
} from '@generated/gql/graphql';
import { GraphqlClient } from '@graphql/client';
import {
   organizationTypeaheadQuery,
   updateUserMutation,
   updateUserPasswordMutation,
} from '@graphql/queries';
import languageOptions from '@helpers/LanguageOptions';
import { zodResolver } from '@hookform/resolvers/zod';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { StringOption } from '@models/ReactSelectHelperTypes';
import Toast from '@models/Toast';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { z } from 'zod';

import FeaturesSection from './FeaturesSection';

interface UserInformationPanelProps {
   disableUpdates: boolean;
   dispatchToast(toast: Toast): void;
   user: UserProfileFragment;
}

type UserFormInput = Omit<UserInput, 'featureIds' | '__typename'> &
   Omit<UserPasswordUpdateInput, '__typename'> & {
      features: Feature[];
      organization: Organization;
   };

const FeatureSchema = z.object({
   id: z.string(),
   feature: z.string(),
});

const OrganizationSchema = z.object({
   id: z.string(),
   name: z.string(),
});

const UserInputSchema = z
   .object({
      accountType: z.string(),
      authenticated: z.boolean(),
      disabled: z.boolean(),
      email: z.string().email(),
      features: z.array(FeatureSchema),
      organization: OrganizationSchema,
      firstName: z.string(),
      language: z.string(),
      lastName: z.string(),
      newPassword: z.string().default(''),
      confirmPassword: z.string().default(''),
      requireReset: z.boolean().default(false),
   })
   .superRefine(({ newPassword, confirmPassword }, refinementContext) => {
      const hasNewPassword = newPassword !== '';
      const hasConfirmPassword = confirmPassword !== '';
      if (hasNewPassword || hasConfirmPassword) {
         // If newPassword is set, confirmPassword must be the same and vice versa
         if (newPassword !== confirmPassword) {
            return refinementContext.addIssue({
               code: z.ZodIssueCode.custom,
               message: 'forms.validations.passwordsDoNotMatch',
               path: ['confirmPassword'],
            });
         }
      }
   });

const UserInformationPanel: React.FC<UserInformationPanelProps> = (props) => {
   const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      register,
      reset,
   } = useForm<UserFormInput>({
      resolver: zodResolver(UserInputSchema),
      defaultValues: UserInputSchema.parse({
         ...props.user,
         requireReset: false,
      }),
   });

   const [{ fetching: isUpdatingUser }, executeUpdateUserMutation] =
      useMutation(updateUserMutation);

   const [{ fetching: isUpdatingPassword }, executeUpdateUserPasswordMutation] = useMutation(
      updateUserPasswordMutation,
   );

   const fetching = isUpdatingUser || isUpdatingPassword;

   React.useEffect(() => {
      reset({
         ...UserInputSchema.parse(props.user),
         requireReset: false,
      });
   }, [props.user]);

   const onSubmit = async (userFormInput: UserFormInput): Promise<void> => {
      const { features, organization, newPassword, confirmPassword, requireReset, ...basicProps } =
         userFormInput;
      const userUpdateResponse = await executeUpdateUserMutation({
         userId: props.user.id,
         userInput: {
            ...basicProps,
            featureIds: features.map((f) => f.id),
            organizationId: selectedOrganizationOption?.value,
         },
      });

      if (userUpdateResponse.error) {
         props.dispatchToast({
            message: 'Error updating user',
            appearance: Appearance.danger,
         });
      } else {
         props.dispatchToast({
            message: 'User updated',
            appearance: Appearance.success,
         });
      }

      if (newPassword !== '' && confirmPassword !== '') {
         const passwordUpdateResponse = await executeUpdateUserPasswordMutation({
            userId: props.user.id,
            passwordInput: {
               newPassword,
               requireReset,
               confirmPassword,
            },
         });

         if (passwordUpdateResponse.error) {
            props.dispatchToast({
               message: 'Error updating user password',
               appearance: Appearance.danger,
            });
         } else {
            reset({
               ...UserInputSchema.parse(props.user),
               requireReset: false,
            });
            props.dispatchToast({
               message: 'User password updated',
               appearance: Appearance.success,
            });
         }
      }

      return;
   };

   const renderOrganization = (organization: UserProfileOrganizationFragment): string =>
      [organization.name, organization.city, organization.state, organization.zipCode].join(', ');

   const loadOrganizations = async (
      filters: PagedSearchFilters,
   ): Promise<PagedResponse<OrganizationSearchResultFragment>> => {
      const results = await GraphqlClient.query(organizationTypeaheadQuery, {
         searchString: filters.query,
         currentPageNumber: 1,
         pageSize: 10,
         orderBy: [],
      });

      if (!results.data) {
         throw new Error('No data returned from GraphQL query');
      }

      return {
         currentPageNumber: 1,
         pageSize: 10,
         queryResultTotalCount: results.data.organizationSearch.queryResultTotalCount,
         rows: results.data.organizationSearch.rows,
         totalPageCount: 1,
      };
   };

   const [selectedOrganizationOption, setSelectedOrganizationOption] = React.useState<
      Maybe<StringOption>
   >({ label: renderOrganization(props.user.organization), value: props.user.organization.id });

   const handleEvent = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
   ): Promise<void> => {
      handleSubmit(onSubmit)(event);
   };

   return (
      <SectionCard
         title='User Information'
         headerButton={
            !props.disableUpdates ? (
               <div>
                  <ImpersonateUserButton
                     userFullName={props.user.fullName}
                     userId={props.user.id}
                  />
                  <Button
                     className='margin-left-auto'
                     disabled={!isDirty || fetching}
                     onClick={handleEvent}
                     type='submit'
                  >
                     {props.user ? 'Save' : 'Create'}
                  </Button>
               </div>
            ) : undefined
         }
      >
         <form>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>First Name</label>
                  <input
                     {...register('firstName')}
                     type='text'
                     className={classNames({ error: !!errors.firstName })}
                  />
               </div>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>Last Name</label>
                  <input
                     {...register('lastName')}
                     type='text'
                     className={classNames({ error: !!errors.lastName })}
                  />
               </div>
            </div>

            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>Email</label>
                  <input
                     {...register('email')}
                     type='email'
                     className={classNames({ error: !!errors.email })}
                  />
               </div>
               <div className='col-xs-4 col-sm-2'>
                  <label className='field-title'>Authenticated</label>
                  <Controller
                     name='authenticated'
                     control={control}
                     render={({ field: { ref, value, ...rest } }) => (
                        <Switch {...rest} checked={value} disabled={props.disableUpdates} />
                     )}
                  />
               </div>
               <div className='col-xs-4 col-sm-2'>
                  <label className='field-title'>Admin</label>
                  <Switch checked={props.user.isAdmin} disabled />
               </div>
               <div className='col-xs-4 col-sm-2'>
                  <label className='field-title'>Disabled</label>
                  <Controller
                     name='disabled'
                     control={control}
                     render={({ field: { ref, value, ...rest } }) => (
                        <Switch {...rest} checked={value} disabled={props.disableUpdates} />
                     )}
                  />
               </div>
            </div>
            <div className='row margin-bottom-m'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>Language</label>
                  <select
                     {...register('language')}
                     className={classNames({ error: !!errors.language })}
                     disabled={props.disableUpdates}
                  >
                     {languageOptions}
                  </select>
               </div>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>Account Type</label>
                  <select
                     {...register('accountType')}
                     className={classNames({ error: !!errors.accountType })}
                     disabled={props.disableUpdates}
                  >
                     <option value={AccountType.instructor}>Instructor</option>
                     <option value={AccountType.student}>Student</option>
                  </select>
               </div>
            </div>
            <div className='margin-bottom-m'>
               <label className='field-title'>Organization</label>
               <SelectTypeAhead
                  control={control}
                  fieldName='organizationId'
                  labelContent={renderOrganization}
                  placeHolder='Select an organization'
                  rowLoader={loadOrganizations}
                  selectedOption={selectedOrganizationOption}
                  setSelectedOption={setSelectedOrganizationOption}
               />
            </div>
            <div>
               <div className='title small'>Change Password</div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title' htmlFor='newPassword'>
                        New Password
                     </label>
                     <input
                        {...register('newPassword')}
                        type='password'
                        data-dd-privacy='input-ignored'
                     />
                  </div>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title' htmlFor='confirmNewPassword'>
                        Confirm Password
                     </label>
                     <input
                        {...register('confirmPassword')}
                        type='password'
                        data-dd-privacy='input-ignored'
                     />
                  </div>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title'>Require Reset</label>
                     <Controller
                        control={control}
                        name='requireReset'
                        render={({ field: { ref, value, ...rest } }) => (
                           <Switch {...rest} checked={value} disabled={props.disableUpdates} />
                        )}
                     />
                  </div>
               </div>
            </div>
            <Controller
               name='features'
               control={control}
               render={({ field: { ref, ...rest } }) => <FeaturesSection {...rest} />}
            />
         </form>
      </SectionCard>
   );
};

export default UserInformationPanel;
