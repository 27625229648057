import * as React from 'react';

import IconCursorDial from '@icons/nova-line/04-Login/cursor-dial.svg';
import IconAlarmClock from '@icons/nova-line/05-Time/alarm-clock.svg';
import Language from '@models/Language';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';
import Button from '@components/Common/Button';
import { VocabSessionType } from '@components/VocabSession/Models';

interface VocabSessionActionsProps {
   language: Language;
   learnedCount: number;
   reviewCount: number;
   termCount: number;
   vocabSetId: number | null;
}

const VocabSessionActions: React.FC<VocabSessionActionsProps> = ({
   language,
   learnedCount,
   reviewCount,
   termCount,
   vocabSetId,
}) => {
   const {
      routes: {
         content: { vocabMatching, vocabSession },
      },
   } = Constants;

   const navigate = useNavigate();

   const navigateToSession = (sessionType: VocabSessionType) => {
      const link = vocabSetId
         ? vocabSession
              .replace(':vocabSetId', vocabSetId.toString())
              .replace(':sessionType', sessionType)
         : '';
      navigate(link);
   };

   const navigateToMatching = () => {
      if (vocabSetId) {
         navigate(vocabMatching.replace(':vocabSetId', vocabSetId.toString()));
      }
   };

   return (
      <div className='flex flex-gap-s'>
         {language !== 'asl' && (
            <div className='session-mode-button'>
               <Button color='purple' tooltip='Matching' onClick={navigateToMatching}>
                  <IconCursorDial className='icon-sm no-margin no-padding' />
               </Button>
            </div>
         )}
         {language !== 'asl' && (
            <div className='session-mode-button'>
               <Button
                  color='dark-red'
                  tooltip='Speed Review'
                  disabled={!learnedCount}
                  onClick={() => navigateToSession(VocabSessionType.speedReview)}
               >
                  <IconAlarmClock className='icon-sm no-margin no-padding' />
               </Button>
            </div>
         )}
         <div className='session-mode-button'>
            <Button
               color='blue'
               disabled={!learnedCount}
               tooltip='Review Learned Terms'
               onClick={() => navigateToSession(VocabSessionType.review)}
               keyboardShortcut={reviewCount ? 'mod+enter' : undefined}
            >
               {`Review${reviewCount ? ` (${reviewCount})` : ''}`}
            </Button>
         </div>
         <div className='session-mode-button'>
            <Button
               color='green'
               disabled={termCount === learnedCount}
               onClick={() => navigateToSession(VocabSessionType.learn)}
               keyboardShortcut={
                  reviewCount || termCount === learnedCount ? undefined : 'mod+enter'
               }
            >
               Learn New Terms
            </Button>
         </div>
      </div>
   );
};

export default VocabSessionActions;
