/* eslint-disable complexity */
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconMoneyNote from '@icons/nova-solid/47-Money/money-note.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import ProductsService, { ProductPrice } from '@services/ProductsService';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Config from '../../../../scripts/Config';
import Constants from '../../../../scripts/Constants';
import { AppStateContext } from '../../../AppState';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import Loader from '@components/Loader';
import { BannerName } from '@components/Wrappers/Layout/Banner';
import BookstoreCodePayment from './BookstoreCodePayment';
import ChooseProduct from './ChooseProduct';
import PaymentModalFooter from './PaymentModalFooter';
import StripeCheckoutForm from './StripeCheckoutForm';

const { environmentType } = Config;
const { stripe } = Constants;
const stripeInstance = loadStripe(
   environmentType === 'production' ? stripe.livePublicKey : stripe.testPublicKey,
);

export interface PaymentIntentInfo {
   clientSecret: string;
   receiptEmail: string;
}

interface CreatePaymentIntentResponse {
   clientSecret: string;
   receiptEmail: string;
   price: number;
}

interface CreatePaymentIntentRequest {
   courseId: number;
   productIds: Maybe<readonly number[]>;
   price: Maybe<number>;
}

enum PaymentStep {
   payWithCode = 'payWithCode',
   payWithCreditCard = 'payWithCreditCard',
   chooseProduct = 'chooseProduct',
}

const PaymentModal: React.FC = () => {
   const trialBanners = [BannerName.COURSE_TRIAL, BannerName.EXPIRED_COURSE_TRIAL];

   const {
      archivedCourses,
      currentCourses,
      banner,
      paidCourseId: courseId,
      setCourses,
      setPaidCourseId,
      setBanner,
   } = React.useContext<AppStateContext>(AppStateContext);

   const [availableProducts, setAvailableProducts] = React.useState<readonly ProductPrice[]>([]);
   const [courseName, setCourseName] = React.useState<string>('');
   const [currentStep, setCurrentStep] = React.useState<Maybe<PaymentStep>>(null);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isSuccessful, setIsSuccessful] = React.useState<boolean>(false);
   const [paymentIntentInfo, setPaymentIntentInfo] = React.useState<Maybe<PaymentIntentInfo>>(null);
   const [price, setPrice] = React.useState<Maybe<number>>(null);
   const [productNames, setProductNames] = React.useState<readonly string[]>([]);
   const [remainingPayment, setRemainingPayment] = React.useState<boolean>(false);
   const [selectedProduct, setSelectedProduct] = React.useState<Maybe<ProductPrice>>();
   const [showPaymentModal, setShowPaymentModal] = React.useState<boolean>(false);

   const courseHasProducts = !!availableProducts?.length;

   const shouldBookstoreCodeBeFirst = (productPrices: readonly ProductPrice[]): boolean =>
      productPrices
         .flatMap((x) => x.products)
         .map((x) => x.showBookstoreCodePaymentFirst)
         .includes(true);

   React.useEffect(() => {
      if (showPaymentModal) {
         setIsSuccessful(false);
      }
   }, [showPaymentModal]);

   React.useEffect(() => {
      if (isSuccessful && !remainingPayment) {
         store.dispatch(actionFunctions.setNetworkError(null));
      }
   }, [isSuccessful, remainingPayment]);

   React.useEffect(() => {
      if (courseId) {
         const course = currentCourses.find((i) => i.id === courseId);
         setCourseName(course?.name || '');

         setIsLoading(true);
         ProductsService.getProducts(courseId)
            .then((response) => {
               const { products } = response.data;
               const hasProducts = !!products?.length;
               if (hasProducts) {
                  const someProductsFlaggedToshowBookstoreCodePaymentFirst =
                     shouldBookstoreCodeBeFirst(products);
                  const multipleProductsAvailable = products?.length > 1;
                  const initialStep = someProductsFlaggedToshowBookstoreCodePaymentFirst
                     ? PaymentStep.payWithCode
                     : multipleProductsAvailable
                     ? PaymentStep.chooseProduct
                     : PaymentStep.payWithCreditCard;
                  setCurrentStep(initialStep);
                  setAvailableProducts(products);
                  setSelectedProduct(products[0]);
                  setIsLoading(false);
               } else {
                  setCurrentStep(PaymentStep.payWithCreditCard);
                  createPaymentIntent();
               }
            })
            .catch(() => {
               setIsLoading(false);
            });
      }
      setShowPaymentModal(courseId !== null);
      setIsSuccessful(false);
   }, [courseId]);

   React.useEffect(() => {
      if (selectedProduct) {
         createPaymentIntent();
      }
      deDupProductNames(selectedProduct);
   }, [selectedProduct]);

   const deDupProductNames = (productPrice: ProductPrice | undefined | null): void => {
      if (!productPrice) {
         setProductNames([]);
         return;
      }

      const names = [...new Set(productPrice.products.map((p) => p.name))];
      setProductNames(names);
   };

   const createPaymentIntent = (): void => {
      if (!courseId) {
         return;
      }
      const requestData: CreatePaymentIntentRequest = {
         courseId,
         productIds: selectedProduct?.products.map((p) => p.id) || null,
         price: selectedProduct?.totalPrice || null,
      };

      setPaymentIntentInfo(null);
      HttpService.postWithAuthToken<CreatePaymentIntentResponse>(
         '/api/payment/intent',
         snakeCaseKeys(requestData),
      )
         .then((response) => {
            const { clientSecret, receiptEmail, price: responsePrice } = response.data;
            setPaymentIntentInfo({
               clientSecret,
               receiptEmail,
            });
            setPrice(responsePrice);
         })
         .catch((error) => {
            alert(error.response?.data.msg);
            location.reload();
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const clearPaymentInfo = (): void => {
      setAvailableProducts([]);
      setSelectedProduct(null);
      setPrice(null);
      setPaymentIntentInfo(null);
   };

   const handleModalClose = (): void => {
      setPaidCourseId(null);
      clearPaymentInfo();
   };

   const handleBookstoreCodePayment = (): void => {
      if (!courseId) {
         return;
      }
      ProductsService.getProducts(courseId)
         .then((response) => {
            const { products } = response.data;
            const hasProducts = !!products?.length;
            if (hasProducts) {
               clearPaymentInfo();
               setAvailableProducts(products);
               setSelectedProduct(products[0]);
               setRemainingPayment(true);
               setIsSuccessful(true);
               setIsLoading(false);
            } else {
               handleSuccess();
            }
         })
         .catch(() => {
            handleSuccess();
         });
   };

   const handleSuccess = (): void => {
      if (
         banner &&
         banner.bannerName &&
         banner.data &&
         trialBanners.includes(banner.bannerName) &&
         banner.data.courseId === courseId
      ) {
         setBanner({ body: '', show: false });
      }
      setCourses(
         currentCourses.map((i) =>
            i.id === courseId
               ? {
                    id: i.id,
                    name: i.name,
                    demo: false,
                    needsToPurchaseLicense: false,
                    trialEndOn: new Date(),
                 }
               : i,
         ),
         archivedCourses,
      );
      setRemainingPayment(false);
      setIsSuccessful(true);
   };

   const handleChooseProductNext = (): void => {
      setCurrentStep(PaymentStep.payWithCreditCard);
   };

   const togglePaymentMethod = (): void => {
      const multipleProductsAvailable = availableProducts?.length > 1;

      if (currentStep === PaymentStep.payWithCode) {
         if (multipleProductsAvailable) {
            setCurrentStep(PaymentStep.chooseProduct);
         } else {
            setCurrentStep(PaymentStep.payWithCreditCard);
         }
      } else if (currentStep === PaymentStep.chooseProduct) {
         setCurrentStep(PaymentStep.payWithCode);
      } else if (currentStep === PaymentStep.payWithCreditCard) {
         if (multipleProductsAvailable) {
            setCurrentStep(PaymentStep.chooseProduct);
         } else {
            setCurrentStep(PaymentStep.payWithCode);
         }
      }
   };

   const handleProductSelected = (product: ProductPrice): void => {
      setSelectedProduct(product);
   };

   const renderProductsPrice = (productPrice: ProductPrice): React.ReactNode => (
      <>
         {productPrice.products.map((product) => (
            <p key={product.id}>
               You will have access to <strong>{product.name}</strong> for{' '}
               <strong data-test='course-price'>${product.price}</strong>. This is a one-time fee
               and access will last for {product.licenseDurationInMonths} months.
            </p>
         ))}
      </>
   );

   const renderPaymentTitle = (): React.ReactNode => {
      if (PaymentStep.payWithCode === currentStep) {
         if (productNames.length > 0) {
            return (
               <p>
                  Enter the code for <strong>{productNames.join(' and ')}</strong> found in your
                  textbook to gain access to the course.
               </p>
            );
         }
         return <p>Enter the code found in your textbook to gain access to the course.</p>;
      } else if (courseHasProducts) {
         if (selectedProduct && currentStep === PaymentStep.payWithCreditCard) {
            return renderProductsPrice(selectedProduct);
         } else {
            return <p>This course requires a license. Choose one of the options below:</p>;
         }
      } else {
         return (
            <p>
               You will have access to <strong>{courseName}</strong> for{' '}
               <strong data-test='course-price'>${price}</strong>. This is a one-time fee and access
               will last through the end of the course.
            </p>
         );
      }
   };

   if (courseId === null || !showPaymentModal) {
      return null;
   }

   const codeLabel = courseHasProducts ? 'Textbook' : 'Bookstore';
   const body = isLoading ? (
      <Loader />
   ) : (
      <>
         {renderPaymentTitle()}
         {currentStep === PaymentStep.payWithCode && courseId && (
            <BookstoreCodePayment
               codeLabel={codeLabel}
               hasProducts={courseHasProducts}
               courseId={courseId}
               onSuccess={handleBookstoreCodePayment}
               togglePaymentMethod={togglePaymentMethod}
            />
         )}
         {currentStep === PaymentStep.chooseProduct && selectedProduct && (
            <ChooseProduct
               productPrices={availableProducts}
               selectedProduct={selectedProduct}
               onNext={handleChooseProductNext}
               onProductSelected={handleProductSelected}
               togglePaymentMethod={togglePaymentMethod}
            />
         )}
         {currentStep === PaymentStep.payWithCreditCard && paymentIntentInfo && price && (
            <>
               <StripeCheckoutForm
                  paymentIntentInfo={paymentIntentInfo}
                  codeLabel={codeLabel}
                  price={price}
                  showBack={courseHasProducts}
                  onSuccess={handleSuccess}
                  togglePaymentMethod={togglePaymentMethod}
                  onBack={togglePaymentMethod}
               />
            </>
         )}
      </>
   );

   return (
      <Elements
         stripe={stripeInstance}
         options={{
            fonts: [
               {
                  cssSrc: 'https://fonts.googleapis.com/css?family=Karla',
               },
            ],
         }}
      >
         <ModalDialog
            className='payment-modal'
            heading={isSuccessful ? 'Thank you' : `Pay for ${courseName}`}
            onClose={handleModalClose}
            width='small'
            bodyClassName='payment-modal-body'
            appearance={isSuccessful ? Appearance.success : Appearance.primary}
            actions={undefined}
            footer={<PaymentModalFooter />}
         >
            <div className='row bottom-xs'>
               <div className='col-xs-12'>
                  {isSuccessful ? (
                     <>
                        <EmptyState
                           icon={<IconMoneyNote aria-hidden />}
                           heading='Payment Successful'
                           description='Your payment has been successfully submitted.'
                        />
                        {remainingPayment && (
                           <Button
                              className='payment-btn'
                              fullWidth
                              loading={isLoading}
                              onClick={() => {
                                 setIsSuccessful(false);
                              }}
                           >
                              Use Additional Code or pay for remaining Access.
                           </Button>
                        )}
                     </>
                  ) : (
                     body
                  )}
               </div>
            </div>
         </ModalDialog>
      </Elements>
   );
};
export default PaymentModal;
