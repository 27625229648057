import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import {
   ActivityBuilderMode,
   MultipleChoiceOption as MultipleChoiceOptionType,
} from '@models/Activity';
import classNames from 'classnames';

import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';

interface MultipleChoiceOptionPrompt extends MultipleChoiceOptionType<ActivityBuilderMode> {
   checked: boolean;
   groupName?: string;
   multiple: boolean;
   showFeedback: boolean;
   showRemove: boolean;
   optionKey?: string;
   onOptionCheck(event: React.ChangeEvent<HTMLInputElement>): void;
   onOptionUpdate(updatedOption: Partial<MultipleChoiceOptionType<ActivityBuilderMode>>): void;
   onRemoveOption(): void;
}

const MultipleChoiceOption: React.FC<MultipleChoiceOptionPrompt> = ({
   checked,
   data,
   feedback,
   groupName,
   optionKey,
   multiple,
   showFeedback,
   showRemove,
   onOptionCheck,
   onOptionUpdate,
   onRemoveOption,
}) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);

   return (
      <>
         <div
            className='align-items-center'
            data-testid={`${optionKey}`}
            data-test='multiple-choice-row'
         >
            <input
               data-test={`option-checkbox-${optionKey}`}
               name={multiple ? '' : groupName}
               type={multiple ? 'checkbox' : 'radio'}
               className={classNames(
                  { correct: checked, square: multiple },
                  'flex-fixed margin-left-right-s',
               )}
               checked={checked}
               onChange={onOptionCheck}
            />
            <div className='flex-grow-1'>
               <Editor
                  className='no-p-margins'
                  dataTest={`option-text-${optionKey}`}
                  language={language}
                  config={{ placeholder: 'Option' }}
                  onChange={(v) => onOptionUpdate({ data: v })}
                  rtl={language === 'ar'}
                  toolbarAppearance={ToolbarAppearance.floating}
                  value={data}
               />
            </div>
            {showRemove && (
               <div className='flex-fixed margin-left-right-s'>
                  <IconCloseSmall
                     onClick={onRemoveOption}
                     aria-label='Remove Option'
                     role='button'
                     className='delete-row-btn'
                     data-test={`delete-row-btn-${optionKey}`}
                  />
               </div>
            )}
         </div>
         {showFeedback && (
            <div className='flex-grow-1 option-feedback builder'>
               <Editor
                  className='no-p-margins'
                  dataTest={`option-feedback-${optionKey}`}
                  language={language}
                  onChange={(v) => onOptionUpdate({ feedback: v })}
                  rtl={language === 'ar'}
                  config={{ placeholder: 'Feedback' }}
                  toolbarAppearance={ToolbarAppearance.floating}
                  value={feedback}
               />
            </div>
         )}
      </>
   );
};

export default MultipleChoiceOption;
