import * as React from 'react';

import IconInterfaceAlertCircle from '@icons/nova-solid/22-Interface-Feedback/interface-alert-circle.svg';
import NationalExamService from '@services/NationalExamService';

import { AppStateContext } from '../AppState';
import EmptyState from './Core/EmptyState';
import DocumentTitle from './DocumentTitle';

const ExamMissingCategoryDisplay: React.FC = () => {
   const { networkError } = React.useContext<AppStateContext>(AppStateContext);

   const courseName = networkError?.data.courseName;
   const categoryClassification = NationalExamService.getStudentClassificationWord(
      networkError?.data.examNameAbbr,
   );
   const headingText = `${categoryClassification} Assignment Required`;

   const renderDescription = (): React.ReactNode => (
      <div className='error-description'>
         In order to access <strong>{courseName}</strong> you must be assigned to a{' '}
         {categoryClassification.toLowerCase()}.
      </div>
   );

   const renderPrimaryAction = (): React.ReactNode => (
      <div className='error-action'>
         <div>
            Please ask your instructor to set your {categoryClassification.toLowerCase()} in the
            roster for this course.
         </div>
      </div>
   );

   return (
      <div className='row margin-top-m exam-missing-category-error'>
         <DocumentTitle>{headingText}</DocumentTitle>
         <div className='col-xs-12 col-md-12 col-lg-10'>
            <EmptyState
               icon={<IconInterfaceAlertCircle className='large' aria-hidden />}
               heading={headingText}
               description={renderDescription()}
               primaryAction={renderPrimaryAction()}
            />
         </div>
      </div>
   );
};

export default ExamMissingCategoryDisplay;
