import * as React from 'react';

import { LegendProps, Surface, Symbols } from 'recharts';

const CircleLegendContent: React.FC<LegendProps> = ({ payload }) => {
   if (!payload) {
      return null;
   }

   const reversedArray = payload.slice().reverse();

   return (
      <div className='student-proficiency-summary-legend'>
         {reversedArray.map((entry) => (
            <div key={entry.value}>
               <span>
                  <Surface
                     height={14}
                     width={14}
                     style={{
                        display: 'inline-block',
                        marginRight: '4',
                        verticalAlign: 'middle',
                     }}
                     viewBox={{
                        height: 32,
                        width: 32,
                        x: 0,
                        y: 0,
                     }}
                  >
                     <Symbols
                        type='circle'
                        cx={16}
                        cy={16}
                        fill={entry.color}
                        size={32}
                        sizeType='diameter'
                     />
                  </Surface>
                  {entry.value}
               </span>
            </div>
         ))}
      </div>
   );
};

export default CircleLegendContent;
