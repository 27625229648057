import * as React from 'react';

import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { ActivityMode, ActivityQuestion } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import Constants from '../../../Constants';
import Link from '@components/Common/Link';
import { ProgressBar } from '@components/Core/ProgressBar';
import { getPromptName, isPrompt } from '@components/Activity/Utils';

interface PromptListProps {
   currentPromptId: Maybe<number>;
   questions: readonly ActivityQuestion<ActivityMode.grade>[];
   moduleItemId: Maybe<number>;
}

const PromptList: React.FC<PromptListProps> = ({ currentPromptId, questions, moduleItemId }) => {
   const {
      routes: {
         activities: { gradeResponses },
      },
   } = Constants;

   return (
      <div className='dropdown-menu' data-tour='prompt-list'>
         {questions.map(({ id: questionId, items, title }) => (
            <React.Fragment key={`q-${questionId}`}>
               <div className='title'>{title}</div>
               <ul className='list-sub-level'>
                  {items
                     .filter(isPrompt<ActivityMode.grade>)
                     .map(({ id: promptId, itemType, percentageGraded }) => (
                        <li key={`p-${promptId}`}>
                           <div className='nav-icon-wrap'>
                              <div className='nav-icon'>
                                 <IconMenuLevel />
                              </div>
                           </div>
                           <div className='list-sub-level-item-wrap'>
                              <div className='list-sub-level-item'>
                                 <div className='row'>
                                    <div className='col-xs-8'>
                                       <Link
                                          to={
                                             moduleItemId
                                                ? gradeResponses
                                                     .replace(
                                                        ':moduleItemId',
                                                        moduleItemId.toString(),
                                                     )
                                                     .replace(':promptId', promptId.toString())
                                                : undefined
                                          }
                                          className={classnames({
                                             active: promptId === currentPromptId,
                                          })}
                                       >
                                          {getPromptName(itemType)}
                                       </Link>
                                    </div>
                                    <div className='col-xs-4'>
                                       <ProgressBar percentage={percentageGraded} color='green' />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </li>
                     ))}
               </ul>
            </React.Fragment>
         ))}
      </div>
   );
};

export default PromptList;
