import * as React from 'react';

import { Maybe } from '@models/Core';

interface BeaconProps {
   className?: string;
   style?: React.CSSProperties;
   elementRef?: React.Dispatch<React.SetStateAction<Maybe<HTMLElement>>>;
   onClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

const Beacon: React.FC<BeaconProps> = ({ className, elementRef = null, ...props }) => (
   <button className='onboarding-beacon animated fadeIn' type='button' ref={elementRef} {...props}>
      <span className='onboarding-beacon-inner' />
      <span className='onboarding-beacon-outer' />
   </button>
);

export default Beacon;
