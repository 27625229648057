import { Maybe } from '@models/Core';

const getLanguage = (node: Node): Maybe<string> => {
   while (node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
         const language = (node as Element).getAttribute('lang');
         if (language) {
            return language;
         }
      }
      if (node.parentNode === null) {
         break;
      }
      node = node.parentNode as Node;
   }
   return null;
};

export { getLanguage };
