/* eslint-disable complexity */
import * as React from 'react';

import IconCogMagnify from '@icons/nova-line/03-Settings/cog-magnify.svg';
import IconCalendarAdd from '@icons/nova-line/05-Time/calendar-add.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import IconBusinessGraphBar1 from '@icons/nova-line/44-Business/business-graph-bar-1.svg';
import AccountType from '@models/AccountType';
import { ActivityMode } from '@models/Activity';
import { Maybe } from '@models/Core';
import { Assignment } from '@models/Course';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Button from '@components/Common/Button';
import TransferOwnershipButton from '@components/TransferOwnershipButton';

interface ActivityHeaderProps {
   allowGoingBack: boolean;
   assignment: Maybe<Assignment>;
   canDebugLogic: boolean;
   canEdit: boolean;
   canShowResponseDistributions: boolean;
   disableSubmit: boolean;
   isActivityOwner: boolean;
   isAutograding: boolean;
   isClosed: boolean;
   isLoadingNextQuestion: boolean;
   isLocked: boolean;
   isSaving: boolean;
   isSettingQuestionId: boolean;
   isSubmitting: boolean;
   mode: ActivityMode;
   moduleItemId: Maybe<number>;
   name: string;
   oneQuestionAtATime: boolean;
   questionCount: number;
   questionIndex: Maybe<number>;
   showResponseDistributions: boolean;
   startedOn: Maybe<Date>;
   editActivity(): void;
   fetchActivity(): void;
   onNextClick(): void;
   onPreviousClick(): void;
   onSubmitClick(): void;
   openLogicDebugger(): void;
   openAddToCourseModal(): void;
   toggleShowResponseDistributions(): void;
}

const ActivityHeader: React.FC<ActivityHeaderProps> = ({
   allowGoingBack,
   assignment,
   canDebugLogic,
   canEdit,
   canShowResponseDistributions,
   disableSubmit,
   isActivityOwner,
   isAutograding,
   isClosed,
   isLoadingNextQuestion,
   isLocked,
   isSettingQuestionId,
   isSubmitting,
   mode,
   moduleItemId,
   name,
   oneQuestionAtATime,
   questionCount,
   questionIndex,
   showResponseDistributions,
   startedOn,
   editActivity,
   fetchActivity,
   onNextClick,
   onPreviousClick,
   onSubmitClick,
   openLogicDebugger,
   openAddToCourseModal,
   toggleShowResponseDistributions,
}) => {
   const { activityId } = useParams<{ activityId: string }>();

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const isInstructor = userProfile?.accountType === AccountType.instructor;

   const unassignedCompleter = assignment === null && mode === ActivityMode.complete;
   const isFirstQuestion = questionIndex === 0;
   const isLastQuestion = typeof questionIndex === 'number' && questionIndex + 1 === questionCount;
   const isUnstartedTimedAssignment =
      assignment?.timeLimit && assignment?.timeLimit > 0 && !startedOn;

   const showPrevious = () =>
      oneQuestionAtATime &&
      questionCount > 1 &&
      allowGoingBack &&
      !isFirstQuestion &&
      !isUnstartedTimedAssignment &&
      !isLocked;

   const showNext = () =>
      oneQuestionAtATime &&
      questionCount > 1 &&
      !isLastQuestion &&
      !isUnstartedTimedAssignment &&
      !isLocked;

   const showSubmit = () =>
      !isLocked &&
      !isUnstartedTimedAssignment &&
      ((oneQuestionAtATime && isLastQuestion) || !oneQuestionAtATime);

   const isSubmitLoading = isSubmitting || isAutograding;

   return (
      <>
         <div className='card-title has-button'>
            <div className='content-title-wrapper'>
               <div className='title'>{name}</div>
               <div className='icon-action-wrap'>
                  <>
                     {mode === ActivityMode.preview && canEdit && activityId && (
                        <>
                           <Tippy content='Edit'>
                              <div
                                 className='icon-action'
                                 onClick={editActivity}
                                 role='button'
                                 aria-label='Edit Activity'
                              >
                                 <IconHighlight aria-hidden />
                              </div>
                           </Tippy>
                           <TransferOwnershipButton
                              contentId={parseInt(activityId)}
                              onOwnershipTransfer={fetchActivity}
                              isContentOwner={isActivityOwner}
                           />
                        </>
                     )}
                     {canDebugLogic && (
                        <Tippy content='Logic Debugger'>
                           <div
                              className='icon-action'
                              onClick={openLogicDebugger}
                              role='button'
                              aria-label='Open Logic Debugger'
                           >
                              <IconCogMagnify />
                           </div>
                        </Tippy>
                     )}
                     {canShowResponseDistributions && (
                        <Tippy content='Toggle Response Distributions'>
                           <div
                              className={classNames('icon-action', {
                                 open: showResponseDistributions,
                              })}
                              onClick={toggleShowResponseDistributions}
                           >
                              <IconBusinessGraphBar1 />
                           </div>
                        </Tippy>
                     )}
                     {mode === ActivityMode.preview && moduleItemId === null && isInstructor && (
                        <Tippy content='Add to Course Module'>
                           <div
                              aria-label='Add to Course Module'
                              className='icon-action'
                              onClick={openAddToCourseModal}
                              role='button'
                              tabIndex={-1}
                           >
                              <IconCalendarAdd />
                           </div>
                        </Tippy>
                     )}
                  </>
               </div>
            </div>
            <div className='right-options-wrapper'>
               {showPrevious() && (
                  <Button
                     onClick={onPreviousClick}
                     loading={!isLoadingNextQuestion && isSettingQuestionId}
                     disabled={isFirstQuestion || isLoadingNextQuestion}
                  >
                     Previous
                  </Button>
               )}
               {showNext() && (
                  <Button
                     onClick={onNextClick}
                     loading={isLoadingNextQuestion}
                     disabled={!isLoadingNextQuestion && isSettingQuestionId}
                  >
                     Next
                  </Button>
               )}
               {showSubmit() && (
                  <Button
                     data-test='primary-submit-button'
                     onClick={onSubmitClick}
                     loading={isSubmitLoading}
                     tooltip={unassignedCompleter ? 'Activity is not assigned' : undefined}
                     disabled={disableSubmit || isClosed || unassignedCompleter}
                     color='green'
                  >
                     Submit
                  </Button>
               )}
            </div>
         </div>
      </>
   );
};

export default ActivityHeader;
