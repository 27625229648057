import * as _ from 'lodash';
import * as React from 'react';

import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { ActivityPromptScore, PromptType } from '@models/Activity';
import classnames from 'classnames';

import { getPromptName } from '@components/Activity/Utils';
import Link from '@components/Common/Link';
import { ProgressBar } from '@components/Core/ProgressBar';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { GradeActivityStep } from '@components/Onboarding/Walkthroughs/GradeActivity';

interface PromptRowProps {
   id: number;
   itemType: PromptType;
   lastPrompt: boolean;
   promptLink: string;
   scores: readonly ActivityPromptScore[];
   weight: string;
}

const PromptRow: React.FC<PromptRowProps> = ({
   itemType,
   weight,
   scores,
   lastPrompt,
   promptLink,
}) => {
   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const gradedScores = scores.filter(({ graded }) => graded);
   const percentCompleted = scores.length
      ? _.sum(scores.map(({ completed }) => Number(completed))) / scores.length
      : 0;
   const percentGraded = scores.length ? gradedScores.length / scores.length : 0;
   const avgScore = gradedScores.length
      ? _.sum(gradedScores.map(({ score }) => score)) / gradedScores.length
      : 0;

   const onboardingClassName =
      itemType === PromptType.audioRecordingPrompt
         ? getOnboardingClassName?.(GradeActivityStep.questionsSubmissionsTable)
         : null;
   const promptCell = promptLink ? (
      <Link to={promptLink}>{getPromptName(itemType)}</Link>
   ) : (
      <span>{getPromptName(itemType)}</span>
   );

   return (
      <tr className={classnames('sub-tr', { 'last-sub-tr': lastPrompt })}>
         <td data-th='Sub Question'>
            <div className='nav-icon-wrap'>
               <div className='nav-icon'>
                  <IconMenuLevel />
               </div>
            </div>
            <div className='list-sub-level-item-wrap'>
               <div className={classnames('list-sub-level-item', onboardingClassName)}>
                  {promptCell}
               </div>
            </div>
         </td>
         <td data-th='Sub Point'>{weight}</td>
         <td data-th='Average'>{percentGraded ? avgScore.toFixed(2) : '-'}</td>
         <td data-th='% Completed'>
            <ProgressBar animate percentage={percentCompleted * 100} color='blue' />
            {percentCompleted ? Math.floor(percentCompleted * 100) : 0}%
         </td>
         <td data-th='% Graded'>
            <ProgressBar animate percentage={percentGraded * 100} color='green' />
            {percentGraded ? Math.floor(percentGraded * 100) : 0}%
         </td>
      </tr>
   );
};

export default PromptRow;
