import * as React from 'react';

import DateTime from '@services/DateTimeService';
import moment from 'moment';

import Constants from '../../Constants';
import { ProgressBar } from '@components/Core/ProgressBar';

interface IVocabTermProgressProps {
   attempts: number | null;
   level: number | null;
   halfLife: Date | null;
}

const VocabTermCardProgress: React.FC<IVocabTermProgressProps> = ({
   attempts,
   level,
   halfLife,
}) => {
   const { vocabLearnedLevel } = Constants;

   if (attempts && halfLife === null && level !== null) {
      return (
         <div className='progress-bar-section'>
            <ProgressBar percentage={(level / vocabLearnedLevel) * 100} color='green' />
         </div>
      );
   } else {
      return (
         <div className='progress-text-section'>
            {attempts === 0 && <div className='ready-indication learn'>Ready to learn</div>}
            {halfLife !== null && halfLife > DateTime.now() && (
               <div className='ready-indication review'>{`Review ${moment(
                  halfLife,
               ).fromNow()}`}</div>
            )}
            {halfLife !== null && halfLife <= DateTime.now() && (
               <div className='ready-indication review'>Ready to review</div>
            )}
         </div>
      );
   }
};

export default VocabTermCardProgress;
