import * as React from 'react';

import Textarea from 'react-textarea-autosize';

interface NotesPopupProps {
   notes: string;
   setNotes(notes: string): void;
}

const NotesPopup: React.FC<NotesPopupProps> = ({ notes, setNotes }) => {
   const textareaRef = React.useRef<HTMLTextAreaElement>(null);

   React.useEffect(() => {
      textareaRef.current?.focus();
   }, []);

   const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      setNotes(event.target.value);
   };

   return (
      <div className='dropdown-menu popup-notes'>
         <div className='card-title'>
            <div className='title'>Notes</div>
         </div>
         <div className='popup-content'>
            <Textarea ref={textareaRef} name='comments' value={notes} onChange={handleChange} />
         </div>
      </div>
   );
};

export default React.memo(NotesPopup);
