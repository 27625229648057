import { Feature } from '@models/Feature';
import HttpService from './HttpService';

const getFeatures = (): Promise<readonly Feature[]> =>
   HttpService.getWithAuthToken<{ features: readonly Feature[] }>('/api/features').then(
      (response) => response.data.features,
   );

export default {
   getFeatures,
};
