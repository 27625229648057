import * as React from 'react';

import Appearance from '@models/Appearance';

import ModalDialog from '@components/Core/ModalDialog';

interface AttemptsInputProps {
   firstName: string;
   lastName: string;
   originalAttempts: number;
   maxAttempts: number;
   updateAttempts(newAttemptValue: number): void;
}

const AttemptsInput: React.FC<AttemptsInputProps> = ({
   firstName,
   lastName,
   originalAttempts,
   maxAttempts,
   updateAttempts,
}) => {
   const [currentAttempts, setCurrentAttempts] = React.useState<number>(originalAttempts);
   const [modifyAttemptsModalOpen, setModifyAttemptsModalOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      setCurrentAttempts(originalAttempts);
   }, [originalAttempts]);

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCurrentAttempts(parseInt(event.target.value, 10));
   };

   const handleChange = (): void => {
      updateAttempts(currentAttempts);
      toggleModal();
   };

   const handleCancel = (): void => {
      setCurrentAttempts(originalAttempts);
      toggleModal();
   };

   const toggleModal = (event?: React.MouseEvent<HTMLDivElement>): void => {
      event?.preventDefault();
      setModifyAttemptsModalOpen((i) => !i);
   };

   return (
      <>
         {modifyAttemptsModalOpen && (
            <ModalDialog
               appearance={Appearance.primary}
               heading={`Modify ${firstName} ${lastName}'s Attempts`}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Change', onClick: handleChange },
                  { text: 'Cancel', onClick: handleCancel },
               ]}
            >
               <input
                  type='number'
                  autoFocus
                  onChange={handleInputChange}
                  defaultValue={currentAttempts.toString()}
                  min={0}
                  max={maxAttempts}
               />
            </ModalDialog>
         )}
         <div className='attempts-container'>
            <div className='attempts-text' onDoubleClick={toggleModal}>
               {originalAttempts}
            </div>
         </div>
      </>
   );
};

export default React.memo(AttemptsInput);
