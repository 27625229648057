import HttpService from '@services/HttpService';

const validateEmailsForDomains = (emails: readonly string[], domains: readonly string[]): boolean =>
   emails.every((email) => {
      const emailDomain = email.split('@')[1];
      if (domains.includes(emailDomain)) {
         return true;
      } else {
         return domains.some((d) => {
            const index = d.indexOf('*');
            const regex = new RegExp(`${d.slice(0, index)}.${d.slice(index, d.length)}`);
            return emailDomain.match(new RegExp(regex));
         });
      }
   });

const schoolEmailDomains = (organizationId: number): Promise<readonly string[]> =>
   HttpService.getWithAuthToken<{ domains: readonly string[] }>(
      `/api/organizations/${organizationId}?domains=true`,
   ).then((response) => response.data.domains);

export default {
   validateEmailsForDomains,
   schoolEmailDomains,
};
