import * as _ from 'lodash';
import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconTick from '@icons/general/icon-tick.svg';
import {
   GroupingPromptCategory as GroupingPromptCategoryType,
   GroupingPromptItem,
   GroupingResponse,
} from '@models/Activity';
import classnames from 'classnames';
import { Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';

interface GroupingPromptCategoryProps {
   category: GroupingPromptCategoryType;
   className?: string;
   entries: GroupingResponse;
   isClosed: boolean;
   items: readonly GroupingPromptItem[];
   showMissed?: boolean;
}

const GroupingPromptCategory: React.FC<GroupingPromptCategoryProps> = ({
   category,
   category: { name },
   className = '',
   entries,
   isClosed,
   items,
}) => {
   const renderEntries = (): readonly React.ReactNode[] =>
      _.sortBy(entries, 'index').map((entry, entryIndex) => {
         const item = items.find((i) => i.id === entry.itemId);
         if (!item) {
            return;
         }

         const unmodified = entry.modified === false;
         const correct = unmodified && entry.correct === true;
         const incorrect = unmodified && entry.correct === false;

         return (
            <Draggable
               key={entry.itemId}
               isDragDisabled={isClosed}
               draggableId={entry.itemId.toString()}
               index={entryIndex}
            >
               {(draggableProvided: DraggableProvided) => (
                  <div
                     className={classnames('dnd-item', { correct, incorrect })}
                     ref={draggableProvided.innerRef}
                     {...draggableProvided.draggableProps}
                     {...draggableProvided.dragHandleProps}
                  >
                     <div className='dnd-item-inner'>
                        {(correct || incorrect) && (
                           <div className='dnd-item-icon'>
                              {correct ? <IconTick /> : <IconCloseSmall />}
                           </div>
                        )}
                        <div className='dnd-item-text'>
                           {ensureElement(parseHtml(item.data) as React.ReactNode, 'p')}
                        </div>
                     </div>
                  </div>
               )}
            </Draggable>
         );
      });

   return (
      <div className={classnames('dnd-column-container', className)}>
         <div className='dnd-column-heading'>
            <div className='title large'>{name}</div>
         </div>
         <Droppable
            droppableId={category.id === null ? 'origin' : category.id.toString()}
            type='ITEM'
         >
            {(droppableProvided: DroppableProvided) => (
               <div className='dnd-column-items-wrapper' {...droppableProvided.droppableProps}>
                  <div
                     className='dnd-column-items grouping-column-items'
                     ref={droppableProvided.innerRef}
                  >
                     {renderEntries()}
                     {droppableProvided.placeholder}
                  </div>
               </div>
            )}
         </Droppable>
      </div>
   );
};

export default GroupingPromptCategory;
