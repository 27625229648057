import * as _ from 'lodash';
import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import {
   ActivityBuilderMode,
   VocabSetItem as VocabSetItemType,
   VocabSetItemDisplay,
} from '@models/Activity';
import Appearance from '@models/Appearance';
import Content, { ContentType } from '@models/Content';
import ContentItemProfile from '@models/ContentItemProfile';
import { Maybe } from '@models/Core';
import IVocabTerm from '@models/IVocabTerm';
import VocabSetService from '@services/VocabSetService';
import Tippy from '@tippyjs/react';

import { AppStateContext } from '../../../../AppState';
import Constants from '../../../../Constants';
import Link from '@components/Common/Link';
import ContentIcon from '@components/ContentIcon';
import AddContentModal from '@components/ContentLibrary/AddContentModal';
import ModalDialog from '@components/Core/ModalDialog';
import InlineVocabSet from '@components/Activity/Completer/Content/InlineVocabSet';
import ItemOptions from '@components/Activity/Builder/ItemOptions';
import { BaseItemProps } from '@components/Activity/Builder/Question';
import VocabSetItemOptions from './VocabSetItemOptions';

interface VocabSetItemProps extends BaseItemProps {
   item: VocabSetItemType<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<VocabSetItemType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const VocabSetItem: React.FC<VocabSetItemProps> = ({
   item: { display, vocabSetId },
   item,
   handleItemUpdate,
   removeItem,
}) => {
   const {
      routes: {
         content: { viewVocabSet },
      },
   } = Constants;

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!userProfile) {
      return;
   }
   const [addContentModalOpen, setAddContentModalOpen] = React.useState<boolean>(false);
   const [viewContentModalOpen, setViewContentModalOpen] = React.useState<boolean>(false);

   const [settings, setSettings] = React.useState<Maybe<Content>>(null);
   const [terms, setTerms] = React.useState<readonly IVocabTerm[]>([]);

   const toggleViewContentModal = (): void => {
      setViewContentModalOpen(!viewContentModalOpen);
   };

   React.useEffect(() => {
      if (!vocabSetId) {
         setSettings(null);
         setTerms([]);
         return;
      }
      VocabSetService.get(vocabSetId).then((response) => {
         const { terms: responseTerms, canEdit: responseCanEdit, ...responseSettings } = response;
         setTerms(_.sortBy(responseTerms, 'index'));
         setSettings(responseSettings);
      });
   }, [vocabSetId]);

   const openAddContentModal = (): void => {
      setAddContentModalOpen(true);
   };

   const closeAddContentModal = (): void => {
      setAddContentModalOpen(false);
   };

   const clearVocabSet = (): void => {
      handleItemUpdate({ vocabSetId: null });
   };

   const handleAddItem = ({ itemId }: ContentItemProfile): void => {
      handleItemUpdate({ vocabSetId: itemId }, closeAddContentModal);
   };

   return (
      <div className='activity-builder-row'>
         <div className='align-items-center'>
            {terms && settings?.language ? (
               <div className='flex-grow-1'>
                  {display === VocabSetItemDisplay.inline ? (
                     <InlineVocabSet
                        terms={terms}
                        name={settings.name}
                        language={settings.language}
                        vocabSetId={settings.id}
                     />
                  ) : (
                     <>
                        <div className='activity-builder-lesson'>
                           <div className='content-icon-wrapper'>
                              <div className='activity-item-icon' onClick={toggleViewContentModal}>
                                 <ContentIcon line itemType={ContentType.vocabSet} />
                              </div>
                              {!!vocabSetId && (
                                 <IconClose
                                    onClick={clearVocabSet}
                                    aria-label='Clear Lesson'
                                    className='clear-lesson'
                                 />
                              )}
                           </div>
                           {display === VocabSetItemDisplay.link && vocabSetId && (
                              <Link
                                 className='lesson-name'
                                 to={viewVocabSet.replace(':lessonId', vocabSetId.toString())}
                                 rel='noopener noreferrer'
                                 external
                              >
                                 {settings.name}
                              </Link>
                           )}
                           {display === VocabSetItemDisplay.overlay && (
                              <p onClick={toggleViewContentModal} className='lesson-name'>
                                 {settings.name}
                              </p>
                           )}
                        </div>
                        {viewContentModalOpen && (
                           <ModalDialog
                              appearance={Appearance.primary}
                              heading={settings.name}
                              width='xl'
                              onClose={toggleViewContentModal}
                              bodyClassName='vocab-set-content-modal'
                           >
                              <InlineVocabSet
                                 terms={terms}
                                 language={settings.language}
                                 vocabSetId={settings.id}
                              />
                           </ModalDialog>
                        )}
                     </>
                  )}
               </div>
            ) : (
               <div className='flex-grow-1'>
                  <div className='upload-dropzone' onClick={openAddContentModal}>
                     <div className='icon-wrapper'>
                        <ContentIcon line itemType={ContentType.vocabSet} />
                     </div>
                     <span>Click here to choose a vocab set</span>
                  </div>
               </div>
            )}
            <div className='flex-end margin-left-s'>
               <div className='weight-actions-wrapper'>
                  <div className='icon-action-wrap'>
                     <ItemOptions title='Vocab Set Options'>
                        <VocabSetItemOptions item={item} handleItemUpdate={handleItemUpdate} />
                     </ItemOptions>
                     <Tippy content='Delete Item' delay={[500, 0]}>
                        <div
                           aria-label='Delete Item'
                           className='icon-action'
                           onClick={() => removeItem()}
                           onKeyDown={(e) => {
                              e.key === 'Enter' && removeItem();
                           }}
                           role='button'
                           tabIndex={0}
                        >
                           <IconBin />
                        </div>
                     </Tippy>
                  </div>
               </div>
            </div>
         </div>
         {addContentModalOpen && (
            <AddContentModal
               addItem={handleAddItem}
               contentTypes={[ContentType.vocabSet]}
               heading='Add Vocab Set to Activity'
               addButtonTooltip='Add Vocab Set'
               language={userProfile.language}
               onClose={closeAddContentModal}
            />
         )}
      </div>
   );
};

export default VocabSetItem;
