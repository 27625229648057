import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { SectionCard } from '@components/Common/SectionCard';
import ConfirmModal from '@components/Core/ConfirmModal';
import DocumentTitle from '@components/DocumentTitle';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import { formatCurrency } from '@helpers/CurrencyUtil';
import { formatDateConcise } from '@helpers/FormatTime';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { CountryConstants, CountryName } from '@models/Country';
import {
   NationalExam,
   NationalExamFormats,
   NationalExamLevel,
   NationalExamRegistrationOrder,
} from '@models/NationalExam';
import { actionFunctions } from '@redux/Actions';
import HttpService from '@services/HttpService';
import NationalExamService from '@services/NationalExamService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import NationalExamOrderHistory from './NationalExamOrderHistory';
import RegistrationClosed from './RegistrationClosed';

// HACK - should be data driven
const schoolTypeOptions = [
   { id: 1, name: 'Elementary School' },
   { id: 2, name: 'Middle School' },
   { id: 3, name: 'High School' },
   { id: 4, name: 'College / University' },
   { id: 5, name: 'Home School' },
   { id: 6, name: 'Title One School' },
];

interface InstructorInfo {
   firstName: string;
   lastName: string;
   email: string;
   phone: string;
}
interface SchoolInfo {
   schoolName: string;
   schoolAddress: string;
   schoolCity: string;
   schoolState: string;
   schoolZip: string;
   schoolCountry: CountryName;
   /** School located in the U.S or Canada */
   principalName: string;
   attributeIds: readonly number[];
}

interface ExamInfo {
   administratorName: string;
   examFormat: NationalExamFormats;
   mailExamsTo: string;
   seatsByLevel: Record<number, number>;
}

interface NationalExamRegistrationProps {
   examUrlSlug: string;
}

// eslint-disable-next-line complexity
const NationalExamOnlineOrPaperRegistrationForm: React.FC<NationalExamRegistrationProps> = ({
   examUrlSlug,
}) => {
   const { stateAbbreviations } = Constants;

   const {
      setBreadcrumbs,
      setAvailableNationalExams,
      availableNationalExams,
      userProfile,
      schoolProfile,
   } = React.useContext(AppStateContext);

   const dispatch = useDispatch();

   const [orderPlaced, setOrderPlaced] = React.useState<boolean>(false);
   const [orderHistory, setOrderHistory] = React.useState<readonly NationalExamRegistrationOrder[]>(
      [],
   );
   const [showOrderHistory, setShowOrderHistory] = React.useState<boolean>(true);

   const [exam, setExam] = React.useState<NationalExam>();
   const [paperRegEndMessage, setPaperRegEndMessage] = React.useState<string>('');
   const [levels, setLevels] = React.useState<readonly NationalExamLevel[]>([]);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isRegistering, setIsRegistering] = React.useState<boolean>(false);
   const [isMember, setIsMember] = React.useState<boolean>(false); // Default to false since not used in all exams
   const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<boolean>(false);

   const [instructorInfo, setInstructorInfo] = React.useState<InstructorInfo>({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
   });

   const [schoolInfo, setSchoolInfo] = React.useState<SchoolInfo>({
      schoolName: '',
      schoolAddress: '',
      schoolCity: '',
      schoolState: '',
      schoolZip: '',
      schoolCountry: 'United States',
      principalName: '',
      attributeIds: [],
   });

   const hasPaperExams = exam && exam.examFormats.includes('Paper');
   const hasMembership = exam && (exam.memberPrice !== 0 || exam.nonMemberFee !== null);
   const languageWord = exam && exam.nameAbbr === 'nle' ? 'Latin' : 'language';
   const additionalFeeName =
      exam && exam.nameAbbr !== 'nle' ? 'Handling and non-member fees' : 'Handling fee';

   const [examInfo, setExamInfo] = React.useState<ExamInfo>({
      administratorName: '',
      examFormat: exam && exam.nameAbbr === 'nle' ? 'Paper' : 'Online',
      mailExamsTo: '',
      seatsByLevel: {},
   });

   const [errors, setErrors] = React.useState<Record<string, boolean>>({});

   const totalSeats = _.sum(Object.values(examInfo.seatsByLevel));

   const mostRecentOrder = orderHistory?.length > 0 ? orderHistory[0] : null;

   const [usePrevAddress, setUsePrevAddress] = React.useState<boolean>(false);

   // Set default values giving preference to the most recent orders
   React.useEffect(() => {
      if (mostRecentOrder) {
         setInstructorInfo((prevInstructorInfo) => ({
            ...prevInstructorInfo,
            firstName: mostRecentOrder.firstName,
            lastName: mostRecentOrder.lastName,
            email: mostRecentOrder.email,
            phone: mostRecentOrder.phone,
         }));

         setSchoolInfo((prevSchoolInfo) => ({
            ...prevSchoolInfo,
            schoolAddress: mostRecentOrder.schoolAddress,
            schoolCity: mostRecentOrder.schoolCity,
            schoolName: mostRecentOrder.schoolName,
            schoolState: mostRecentOrder.schoolState,
            schoolZip: mostRecentOrder.schoolZip,
            schoolCountry: mostRecentOrder.schoolCountry
               ? mostRecentOrder.schoolCountry
               : 'United States',
            principalName: mostRecentOrder.principalName ?? '',
         }));
         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            administratorName: mostRecentOrder.administratorName ?? '',
            examFormat: mostRecentOrder.paperExam ? 'Paper' : 'Online',
            mailExamsTo: mostRecentOrder.mailExamsTo ?? '',
         }));

         setUsePrevAddress(true);
      } else {
         if (userProfile) {
            setInstructorInfo((prevInstructorInfo) => ({
               ...prevInstructorInfo,
               firstName: userProfile.firstName,
               lastName: userProfile.lastName,
               email: userProfile.email,
            }));
         }
         if (
            schoolProfile &&
            schoolProfile.name !== 'Lingco Language Labs' &&
            schoolProfile.name !== 'No School Found'
         ) {
            setSchoolInfo((prevSchoolInfo) => ({
               ...prevSchoolInfo,
               schoolName: schoolProfile.name,
               schoolCity: schoolProfile.city,
               schoolState: schoolProfile.state,
            }));
         }
      }
   }, [userProfile, schoolProfile, mostRecentOrder]);

   React.useEffect(() => {
      NationalExamService.getExamRegistrationInformation(examUrlSlug).then((data) => {
         setOrderHistory(data.orderHistory);
         setExam(data.exam);
         setBreadcrumbs({
            breadcrumbs: [{ link: '#', text: `${data.exam.nameAbbr.toUpperCase()} Registration` }],
            next: null,
            prev: null,
         });
         setLevels(data.levels);

         if (data.exam.paperRegEndDate) {
            setPaperRegEndMessage(
               `Deadline for Paper Exams: ${moment(data.exam.paperRegEndDate).format(
                  'MMM Do, YYYY',
               )}`,
            );
         }

         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            seatsByLevel: Object.fromEntries(data.levels.map((i) => [i.id, 0])),
         }));

         setIsFetching(false);
      });
   }, [examUrlSlug]);

   // Override any defaults for exam format if paper is closed
   React.useEffect(() => {
      if (exam && exam.isPaperClosed) {
         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            examFormat: 'Online',
         }));
      }
   }, [exam?.isPaperClosed]);

   const handleSchoolInfoChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;

      if (name === 'schoolCountry' && getIsDomestic(value) === false) {
         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            examFormat: 'Online',
         }));
      }

      setSchoolInfo((prevSchoolInfo) => ({ ...prevSchoolInfo, [name]: value }));
   };

   const toggleSchoolAttribute = (attributeId: number) => {
      setSchoolInfo((prevSchoolInfo) => ({
         ...prevSchoolInfo,
         attributeIds: prevSchoolInfo.attributeIds.includes(attributeId)
            ? prevSchoolInfo.attributeIds.filter((i) => i !== attributeId)
            : [...prevSchoolInfo.attributeIds, attributeId],
      }));
   };

   const handleInstructorInfoChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = event.target;
      setInstructorInfo((prevInstructorInfo) => ({ ...prevInstructorInfo, [name]: value }));
   };

   const handleExamInfoChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setExamInfo((prevExamInfo) => ({ ...prevExamInfo, [name]: value }));
   };

   const handleExamFormatChange = (event: React.FormEvent<HTMLFieldSetElement>): void => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      setExamInfo((prevExamInfo) => ({
         ...prevExamInfo,
         examFormat: value as NationalExamFormats,
      }));
   };

   const handleUsePrevAddress = (event: React.FormEvent<HTMLFieldSetElement>): void => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      if (value === 'Yes' && mostRecentOrder) {
         setUsePrevAddress(true);

         // Reset address fields to those of previous order, to prevent users from changing a field then checking "yes" again
         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            examFormat: mostRecentOrder.paperExam && !exam?.isPaperClosed ? 'Paper' : 'Online',
            mailExamsTo: mostRecentOrder.mailExamsTo ?? '',
         }));
         setSchoolInfo((prevSchoolInfo) => ({
            ...prevSchoolInfo,
            schoolAddress: mostRecentOrder.schoolAddress,
            schoolCountry: mostRecentOrder.schoolCountry
               ? mostRecentOrder.schoolCountry
               : 'United States',
            schoolState: mostRecentOrder.schoolState,
            schoolCity: mostRecentOrder.schoolCity,
            schoolZip: mostRecentOrder.schoolZip,
         }));
      } else {
         setUsePrevAddress(false);
      }
   };

   const addressHasChanged = (): boolean => {
      for (let i = 0; i < orderHistory.length; i++) {
         if (
            orderHistory[i].schoolAddress === schoolInfo.schoolAddress &&
            orderHistory[i].schoolCountry === schoolInfo.schoolCountry &&
            orderHistory[i].schoolState === schoolInfo.schoolState &&
            orderHistory[i].schoolCity === schoolInfo.schoolCity &&
            orderHistory[i].schoolZip === schoolInfo.schoolZip &&
            orderHistory[i].mailExamsTo === examInfo.mailExamsTo &&
            ((orderHistory[i].paperExam === true && examInfo.examFormat === 'Paper') ||
               (orderHistory[i].paperExam === false && examInfo.examFormat === 'Online')) &&
            orderHistory[i].mailExamsTo === examInfo.mailExamsTo
         ) {
            return false;
         }
      }
      return true;
   };

   const handleSeatsByLevelChange = (
      levelId: number,
      event: React.ChangeEvent<HTMLInputElement>,
   ): void => {
      const parsedInt = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value);
      setExamInfo((prevExamInfo) => ({
         ...prevExamInfo,
         seatsByLevel: { ...prevExamInfo.seatsByLevel, [levelId]: parsedInt },
      }));
   };

   const handleIsMember = (event: React.FormEvent<HTMLFieldSetElement>): void => {
      const target = event.target as HTMLInputElement;
      const value = target.value;
      if (value === 'Yes') {
         setIsMember(true);
      } else {
         setIsMember(false);
      }
   };

   const toggleShowOrderHistory = (): void =>
      setShowOrderHistory((prevShowOrderHistory) => !prevShowOrderHistory);

   const getIsDomestic = (country: string): boolean =>
      country === 'United States' || country === 'Canada';

   const isDomestic = getIsDomestic(schoolInfo.schoolCountry);

   const calculatePricePerExam = (): number => {
      if (!exam) {
         return 0;
      }

      // Membership-based pricing
      if (exam.memberPrice !== 0) {
         if (isMember) {
            return exam.memberPrice;
         } else {
            return exam.price;
         }
      }

      // Location-based pricing
      if (isDomestic) {
         // Domestic Price
         return exam.price;
      } else {
         return exam.internationalPrice;
      }
   };

   // HACK - business logic should not be in the form
   const calculateHandlingFee = (): number => {
      if (!exam) {
         return 0;
      }

      if (totalSeats === 0 || usePrevAddress === true) {
         return 0;
      }

      const addressCheck = addressHasChanged();
      if (addressCheck === false) {
         return 0;
      }

      if (isDomestic) {
         // US and Canada
         if (totalSeats >= 1 && totalSeats <= exam.seatCutoff) {
            return exam.handlingFeeDomesticSmall;
         } else if (totalSeats > exam.seatCutoff) {
            return exam.handlingFeeDomesticLarge;
         }
      }

      // Non-Domestic
      return exam.handlingFeeInternational;
   };

   const calculateNonMemberFee = (): number => {
      if (!exam || hasMembership === false) {
         return 0;
      }

      if (
         exam.nonMemberFee !== null &&
         mostRecentOrder === null && // First order only
         isMember === false
      ) {
         return exam.nonMemberFee;
      }

      return 0;
   };

   const calculateLateFee = (): number => {
      if (exam) {
         return exam.isLate && exam.lateFee ? exam.lateFee : 0;
      }
      return 0;
   };

   const getTotal = (): number =>
      totalSeats * calculatePricePerExam() +
      calculateHandlingFee() +
      calculateNonMemberFee() +
      calculateLateFee();

   const onSubmitButtonClick = (event: React.FormEvent): void => {
      event.preventDefault();
      if (!exam) {
         return;
      }

      setIsConfirmModalOpen(true);
   };

   const handleSubmit = async (): Promise<void> => {
      setIsConfirmModalOpen(false);
      if (!exam) {
         return;
      }

      // Remove paper exam recipient if online order
      if (examInfo.examFormat === 'Online') {
         setExamInfo((prevExamInfo) => ({
            ...prevExamInfo,
            mailExamsTo: '',
         }));
      }

      const { firstName, lastName, email, phone } = instructorInfo;
      const { administratorName, mailExamsTo, examFormat } = examInfo;
      const {
         schoolAddress,
         schoolCity,
         schoolName,
         schoolState,
         schoolZip,
         schoolCountry,
         principalName,
         attributeIds,
      } = schoolInfo;
      const updatedErrors = {
         schoolState:
            schoolCountry === 'United States' ? schoolState === '' || schoolState === null : false,
         schoolType: attributeIds.length === 0,
         totalSeats: totalSeats === 0,
         examFormat: examFormat.toString() === '' || examFormat === null,
      };
      setErrors(updatedErrors);
      if (Object.values(updatedErrors).some((i) => !!i)) {
         return Promise.resolve();
      }

      setIsRegistering(true);
      const order: NationalExamRegistrationOrder = {
         id: null,
         createdOn: null,
         /** Instructor Info */
         firstName,
         lastName,
         email,
         phone,
         /** School Info */
         schoolAddress,
         schoolCity,
         schoolName,
         schoolState,
         schoolZip,
         schoolCountry,
         principalName,
         /** Exam Info */
         additionalFee: calculateHandlingFee() + calculateNonMemberFee(),
         additionalFeeName,
         lateFee: calculateLateFee(),
         price: calculatePricePerExam(),
         totalSeats,
         /** NLE-Specific */
         paperExam: examInfo.examFormat === 'Paper',
         seats: Object.keys(examInfo.seatsByLevel)
            .map((k) => ({
               levelId: parseInt(k),
               qty: examInfo.seatsByLevel[parseInt(k)],
            }))
            .filter((i) => i.qty > 0),
         schoolType: attributeIds
            .map((i) => schoolTypeOptions.find((j) => j.id === i)?.name)
            .join(', '),
         administratorName,
         mailExamsTo,
         levelIds: Object.entries(examInfo.seatsByLevel)
            .filter(([_k, v]) => v > 0)
            .map(([k, _v]) => Number.parseInt(k)),
         isMember,
         chapterId: null,
      };
      const url = `api/national_exams/${examUrlSlug}/register`;
      return HttpService.postWithAuthToken<{
         orderHistory: readonly NationalExamRegistrationOrder[];
      }>(url, snakeCaseKeys(order))
         .then((response) => {
            const { data } = response;
            setOrderPlaced(true);
            setShowOrderHistory(false);
            setOrderHistory(data.orderHistory);
            setIsRegistering(false);
            if (!availableNationalExams.includes(exam.nameAbbr)) {
               setAvailableNationalExams([...availableNationalExams, exam.nameAbbr]);
            }
         })
         .catch((err) => {
            dispatch(actionFunctions.setNetworkError(err.response));
         });
   };

   if (isFetching) {
      return <Loader />;
   }

   const regIsOpen = !exam?.isClosed;

   const renderRegistrationClosed = (): React.ReactNode => {
      if (!exam) {
         return <></>;
      }
      return <RegistrationClosed exam={exam} />;
   };

   // eslint-disable-next-line complexity
   const renderRegistration = (): React.ReactNode => {
      if (!exam) {
         return <></>;
      }
      return (
         <form style={{ display: 'contents' }} onSubmit={onSubmitButtonClick}>
            <SectionCard title='Instructor Information'>
               <>
                  <div className='row'>
                     <div className='col-xs-12'>
                        {exam.isLate && (
                           <div className='alert alert-primary'>
                              Normal registration is closed, but the late registration period is
                              open between {exam.lateRegDate && formatDateConcise(exam.lateRegDate)}{' '}
                              and {formatDateConcise(exam.regEndDate)}. An additional $
                              {exam.lateFee} fee will be added to the order, if this is your first
                              order.
                           </div>
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>First Name</label>
                        <input
                           className={classnames({ error: errors?.firstName })}
                           name='firstName'
                           onChange={handleInstructorInfoChange}
                           required
                           type='text'
                           value={instructorInfo.firstName}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Last Name</label>
                        <input
                           className={classnames({ error: errors?.lastName })}
                           name='lastName'
                           onChange={handleInstructorInfoChange}
                           required
                           type='text'
                           value={instructorInfo.lastName}
                        />
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Email</label>
                        <input
                           className={classnames({ error: errors?.email })}
                           name='email'
                           onChange={handleInstructorInfoChange}
                           required
                           type='email'
                           value={instructorInfo.email}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>
                           Phone{' '}
                           <InfoTooltip>
                              Used only by exam organizers in case they have follow-up questions on
                              your order.
                           </InfoTooltip>
                        </label>
                        <input
                           className={classnames({ error: errors?.phone })}
                           value={instructorInfo.phone}
                           onChange={handleInstructorInfoChange}
                           required
                           name='phone'
                           type='text'
                        />
                     </div>
                  </div>
               </>
            </SectionCard>
            <SectionCard title='School Information'>
               <>
                  <div className='row margin-top-s'>
                     <div className='col-xs-12'>
                        <label className='field-title'>School Name</label>
                        <input
                           className={classnames({ error: errors?.schoolName })}
                           name='schoolName'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolName}
                        />
                     </div>
                  </div>
                  <div className='row margin-top-s'>
                     <div className='col-xs-6'>
                        <label className='field-title'>Principal Name</label>
                        <input
                           className={classnames({ error: errors?.principalName })}
                           name='principalName'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.principalName}
                        />
                     </div>
                     <div className='col-xs-6'>
                        <label className='field-title'>Exam Administrator Name</label>
                        <input
                           className={classnames({ error: errors?.administratorName })}
                           name='administratorName'
                           onChange={handleExamInfoChange}
                           required
                           type='text'
                           value={examInfo.administratorName}
                        />
                        <p>
                           <i>May be a {languageWord} teacher</i>
                        </p>
                     </div>
                  </div>
                  <div className='row margin-top-s'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>School Type (select all that apply)</label>
                        <fieldset
                           className={classnames('form-group', { error: errors?.schoolType })}
                        >
                           {schoolTypeOptions.map((attribute) => (
                              <p key={attribute.id}>
                                 <input
                                    checked={schoolInfo.attributeIds.includes(attribute.id)}
                                    onChange={() => toggleSchoolAttribute(attribute.id)}
                                    type='checkbox'
                                    id={`attributes[${attribute.id}].value`}
                                 />
                                 <label
                                    htmlFor={`attributes[${attribute.id}].value`}
                                    className='pointer'
                                 >
                                    {attribute.name}
                                 </label>
                              </p>
                           ))}
                        </fieldset>
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        {hasMembership && (
                           <>
                              <label className='field-title'>Membership</label>
                              <fieldset
                                 className='form-group flex padding-top-m'
                                 onChange={handleIsMember}
                              >
                                 <div style={{ marginRight: '10px' }}>
                                    <input
                                       checked={isMember === true}
                                       id='is-member-true'
                                       type='radio'
                                       value='Yes'
                                    />
                                    <label htmlFor='is-member-true'>Yes</label>
                                 </div>
                                 <div>
                                    <input
                                       checked={isMember === false}
                                       id='is-member-false'
                                       type='radio'
                                       value='No'
                                    />
                                    <label htmlFor='is-member-false'>No</label>
                                 </div>
                              </fieldset>
                           </>
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     {orderHistory.length > 0 && (
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>
                              Use previous address?
                              <InfoTooltip>
                                 An additional handling fee is charged for each address.
                              </InfoTooltip>
                           </label>
                           <fieldset
                              className='form-group flex padding-top-m'
                              onChange={handleUsePrevAddress}
                              disabled={orderHistory.length === 0}
                           >
                              <div style={{ marginRight: '10px' }}>
                                 <input
                                    checked={usePrevAddress === true}
                                    id='use-prev-address'
                                    type='radio'
                                    value='Yes'
                                 />
                                 <label htmlFor='use-prev-address'>Yes</label>
                              </div>
                              <div>
                                 <input
                                    checked={usePrevAddress === false}
                                    id='dont-use-prev-address'
                                    type='radio'
                                    value='No'
                                 />
                                 <label htmlFor='dont-use-prev-address'>No</label>
                              </div>
                           </fieldset>
                        </div>
                     )}
                     {exam.paperRegEndDate !== null && (
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>
                              Exam Format
                              <InfoTooltip>{paperRegEndMessage}</InfoTooltip>
                           </label>

                           <fieldset
                              className={classnames(
                                 'form-group flex padding-top-m',
                                 {
                                    error: errors?.examFormat,
                                 },
                                 usePrevAddress ? 'disabled' : '',
                              )}
                              onChange={handleExamFormatChange}
                              disabled={usePrevAddress}
                           >
                              <div style={{ marginRight: '10px' }}>
                                 <Tippy
                                    content='Paper exams cannot be sent outside the United States or Canada.'
                                    disabled={isDomestic}
                                 >
                                    <span>
                                       <input
                                          checked={examInfo.examFormat === 'Paper'}
                                          id='is-paper'
                                          type='radio'
                                          value='Paper'
                                          disabled={
                                             moment().isAfter(exam.paperRegEndDate) ||
                                             isDomestic === false
                                          }
                                       />
                                    </span>
                                 </Tippy>
                                 <label htmlFor='is-paper'>Paper</label>
                              </div>
                              <div>
                                 <input
                                    checked={examInfo.examFormat === 'Online'}
                                    id='is-online'
                                    type='radio'
                                    value='Online'
                                 />
                                 <label htmlFor='is-online'>Online</label>
                              </div>
                           </fieldset>
                        </div>
                     )}
                  </div>
                  {hasPaperExams && (
                     <div className='row'>
                        <div className='col-xs-12'>
                           <label className='field-title'>
                              Paper Exam Recipient (May&nbsp;
                              <i>
                                 <u>not</u>
                              </i>
                              &nbsp;be a {languageWord} teacher)
                              <InfoTooltip>
                                 This is the person the exams will be sent to. They must not be a
                                 &nbsp;{languageWord} teacher.
                              </InfoTooltip>
                           </label>
                           <input
                              className={classnames(
                                 { error: errors?.mailExamsTo },
                                 usePrevAddress ? 'disabled' : '',
                              )}
                              name='mailExamsTo'
                              onChange={handleExamInfoChange}
                              required
                              type='text'
                              value={examInfo.mailExamsTo}
                              disabled={usePrevAddress}
                           />
                        </div>
                     </div>
                  )}
                  <div className='row'>
                     <div className='col-xs-12'>
                        <label className='field-title'>
                           School Street Address (P.O. Box&nbsp;
                           <i>
                              <u>not</u>
                           </i>
                           &nbsp;acceptable)
                           <InfoTooltip>
                              Awards and paper exams will be sent to the school address and it must
                              not be a PO box.
                           </InfoTooltip>
                        </label>
                        <input
                           className={classnames(
                              { error: errors?.schoolAddress },
                              usePrevAddress ? 'disabled' : '',
                           )}
                           name='schoolAddress'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolAddress}
                           disabled={usePrevAddress}
                        />
                     </div>
                  </div>
                  <div className='row margin-top-m'>
                     <div className='col-xs-6 col sm-6'>
                        <label className='field-title'>Country</label>
                        <select
                           className={classnames(
                              {
                                 error: errors?.schoolCountry,
                              },
                              usePrevAddress ? 'disabled' : '',
                           )}
                           name='schoolCountry'
                           onChange={handleSchoolInfoChange}
                           required
                           value={schoolInfo.schoolCountry}
                           disabled={usePrevAddress}
                        >
                           <option hidden value='' />
                           {CountryConstants.map((x) => (
                              <option key={x} value={x}>
                                 {x}
                              </option>
                           ))}
                        </select>
                     </div>

                     <div className='col-xs-6'>
                        {schoolInfo.schoolCountry === 'United States' ? (
                           <>
                              <label className='field-title'>State</label>
                              <select
                                 className={classnames(
                                    {
                                       error: errors?.schoolState,
                                    },
                                    usePrevAddress ? 'disabled' : '',
                                 )}
                                 name='schoolState'
                                 onChange={handleSchoolInfoChange}
                                 required
                                 value={schoolInfo.schoolState}
                                 disabled={usePrevAddress}
                              >
                                 <option hidden value='' />
                                 {stateAbbreviations.map((x) => (
                                    <option key={x} value={x}>
                                       {x}
                                    </option>
                                 ))}
                              </select>
                           </>
                        ) : (
                           <>
                              <label className='field-title'>State/Province/Region</label>
                              <input
                                 className={classnames(
                                    {
                                       error: errors?.schoolState,
                                    },
                                    usePrevAddress ? 'disabled' : '',
                                 )}
                                 name='schoolState'
                                 onChange={handleSchoolInfoChange}
                                 required
                                 type='text'
                                 value={schoolInfo.schoolState}
                                 disabled={usePrevAddress}
                              />
                           </>
                        )}
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-6'>
                        <label className='field-title'>City</label>
                        <input
                           className={classnames(
                              { error: errors?.schoolCity },
                              usePrevAddress ? 'disabled' : '',
                           )}
                           name='schoolCity'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolCity}
                           disabled={usePrevAddress}
                        />
                     </div>
                     <div className='col-xs-6 col-sm-6'>
                        <label className='field-title'>Postal Code</label>
                        <input
                           className={classnames(
                              { error: errors?.schoolZip },
                              usePrevAddress ? 'disabled' : '',
                           )}
                           name='schoolZip'
                           onChange={handleSchoolInfoChange}
                           required
                           type='text'
                           value={schoolInfo.schoolZip}
                           disabled={usePrevAddress}
                        />
                     </div>
                  </div>
               </>
            </SectionCard>
            <SectionCard title='Exam Order'>
               <>
                  <div className='row margin-top-m'>
                     <div className='col-xs-12'>
                        <label className='field-title'>
                           How many exams are your students taking?
                        </label>
                        <fieldset className='form-group'>
                           {levels.map((level) => (
                              <p style={{ height: '30px' }} key={level.id}>
                                 <label htmlFor={`levels[${level.id}].value`} className='pointer'>
                                    {level.levelName}
                                 </label>
                                 <input
                                    value={examInfo.seatsByLevel[level.id]}
                                    onChange={(e) => handleSeatsByLevelChange(level.id, e)}
                                    style={{
                                       margin: '0',
                                       float: 'right',
                                       width: '100px',
                                    }}
                                    type='number'
                                    min='0'
                                    id={`levels[${level.id}].value`}
                                 />
                              </p>
                           ))}
                        </fieldset>
                     </div>
                  </div>
                  <div className='row margin-top-m'>
                     <div className='col-xs-12 col-sm-3'>
                        <label className='field-title'>Exams Total</label>
                        <input
                           className={classnames({ error: errors?.totalSeats })}
                           min='1'
                           readOnly
                           required
                           type='number'
                           value={totalSeats}
                        />
                     </div>
                     {schoolInfo.schoolCountry === null && (
                        <>
                           <div className='col-xs-12 col-sm-8'>
                              <label className='field-title'>Price per exam</label>
                              <div className=''>
                                 <p>
                                    *Indicate the country where the exams are taken to see price.
                                 </p>
                              </div>
                           </div>
                        </>
                     )}
                     {schoolInfo.schoolCountry !== null && (
                        <>
                           <div className='col-xs-12 col-sm-3'>
                              <label className='field-title'>Price per Exam</label>
                              <div className='padding-top-s'>
                                 <strong>{formatCurrency(calculatePricePerExam())}</strong>
                              </div>
                           </div>
                           {!!calculateLateFee() && (
                              <div className='col-xs-12 col-sm-3'>
                                 <label className='field-title'>Late Fee</label>
                                 <div className='padding-top-s'>
                                    <strong>{formatCurrency(calculateLateFee())}</strong>
                                 </div>
                              </div>
                           )}
                           {!!calculateHandlingFee() && (
                              <div className='col-xs-12 col-sm-3'>
                                 <label className='field-title'>Handling Fee</label>
                                 <div className='padding-top-s'>
                                    <strong>{formatCurrency(calculateHandlingFee())}</strong>
                                 </div>
                              </div>
                           )}
                           {!!calculateNonMemberFee() && (
                              <div className='col-xs-12 col-sm-3'>
                                 <label className='field-title'>Non-Member Fee</label>
                                 <div className='padding-top-s'>
                                    <strong>{formatCurrency(calculateNonMemberFee())}</strong>
                                 </div>
                              </div>
                           )}
                           <div className='col-xs-12 col-sm-3'>
                              <label className='field-title'>Total</label>
                              <div className='padding-top-s'>
                                 <strong>{formatCurrency(getTotal())}</strong>
                                 {isDomestic === false && (
                                    <strong> + International Shipping and Handling*</strong>
                                 )}
                              </div>
                           </div>
                           {isDomestic === false && (
                              <i className='margin-top-m'>
                                 *For orders outside the United States and Canada, the handling fee
                                 is determined by the {exam.nameAbbr.toUpperCase()} office. Please
                                 contact the {exam.nameAbbr.toUpperCase()} office to obtain an
                                 accurate total price.
                              </i>
                           )}
                        </>
                     )}
                  </div>
                  <div className='row'>
                     <div className='col-xs-12 text-right'>
                        <label className='field-title' />
                        <div className='action-buttons'>
                           <Button
                              loading={isRegistering}
                              disabled={!(totalSeats > 0)}
                              type='submit'
                           >
                              Place Order
                           </Button>
                        </div>
                     </div>
                  </div>
               </>
            </SectionCard>
            {isConfirmModalOpen && (
               <ConfirmModal
                  message={
                     <div>
                        Place <strong>{examInfo.examFormat.toUpperCase()}</strong> exam order?
                     </div>
                  }
                  onClose={() => setIsConfirmModalOpen(false)}
                  onPrimaryClick={() => handleSubmit()}
               />
            )}
         </form>
      );
   };

   const renderOrderSuccess = (): React.ReactNode => {
      if (!mostRecentOrder || !exam) {
         return <></>;
      }
      return (
         <SectionCard title={`Order #${mostRecentOrder.id} has been placed`}>
            <div className='row'>
               <div className='col-xs-12'>
                  <div className='alert alert-success'>
                     <div className='text'>Your order for the {exam.name} has been placed!</div>
                     <p className='text'>
                        Check out your{' '}
                        <Link
                           className='alert-link'
                           to={`/national_exams/${exam.nameAbbr.toLowerCase()}`}
                        >
                           dashboard page
                        </Link>{' '}
                        to see the courses containing your practice material, help guides, and other
                        useful information..
                     </p>
                  </div>

                  {!showOrderHistory && (
                     <Button textLink onClick={toggleShowOrderHistory}>
                        Click here to view your full order history
                     </Button>
                  )}
               </div>
            </div>
         </SectionCard>
      );
   };

   return (
      <>
         {exam && <DocumentTitle>{`Register for ${exam.name}`}</DocumentTitle>}
         <div className='content-main margin-right-m'>
            <div className='row center-xs national-exam-reg'>
               {!regIsOpen && renderRegistrationClosed()}
               {regIsOpen && (orderPlaced ? renderOrderSuccess() : renderRegistration())}
               {showOrderHistory && <NationalExamOrderHistory orderHistory={orderHistory} />}
            </div>
         </div>
      </>
   );
};

export default NationalExamOnlineOrPaperRegistrationForm;
