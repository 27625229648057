import * as React from 'react';

import { Maybe } from '@models/Core';
import { CourseSection } from '@models/Course';

import Button from '@components/Common/Button';
import CourseCode from './Roster/CourseCode';

interface CodeCodesProps {
   courseCode: Maybe<string>;
   sections: readonly CourseSection[];
   resetStudentCourseCode(): void;
   resetSectionCourseCode(sectionId: Maybe<number>): void;
}

const CourseCodes: React.FC<CodeCodesProps> = ({
   courseCode,
   sections,
   resetStudentCourseCode,
   resetSectionCourseCode,
}) => {
   const [showSectionCodes, setShowSectionCodes] = React.useState<boolean>(false);

   const toggleShowSectionCodes = (): void => setShowSectionCodes((i) => !i);

   return (
      <div>
         {courseCode && (
            <CourseCode name='Student' code={courseCode} resetCode={resetStudentCourseCode} />
         )}
         {showSectionCodes &&
            sections.map(({ id: sectionId, courseCode: sectionCode, name: sectionName }) => {
               if (sectionCode) {
                  return (
                     <CourseCode
                        key={sectionId}
                        name={sectionName}
                        code={sectionCode}
                        resetCode={() => resetSectionCourseCode(sectionId as number)}
                     />
                  );
               }
            })}
         <div className='row bottom-xs'>
            <div className='col-xs-12'>
               {!!sections.length && (
                  <Button
                     subtle
                     color='blue'
                     className='margin-top-s'
                     onClick={toggleShowSectionCodes}
                  >
                     {`${showSectionCodes ? 'Hide' : 'Show'} Section Codes`}
                  </Button>
               )}
               <p className='small gray'>
                  Give this code to your students and they will be able to join the course.
               </p>
            </div>
         </div>
      </div>
   );
};

export default CourseCodes;
