import * as _ from 'lodash';
import * as React from 'react';

import {
   isAudioContent,
   isAudioRecordingPrompt,
   isLessonContent,
   isOpenTokVideoContent,
   isPrompt,
   isTextContent,
   isUploadedVideoContent,
   isVideoContent,
   isVideoLibraryContent,
   isVocabSetItem,
   isYouTubeVideoContent,
} from '@components/Activity/Utils';
import {
   getAllInteractivePromptsForQuestion,
   getPromptsForInteractiveVideoFromQuestion,
} from '@components/Video/helpers';
import {
   ActivityCompleterMode,
   ActivityItem,
   ActivityQuestion,
   ActivityResponse,
   ActivityResponseEvaluation,
} from '@models/Activity';
import { Maybe } from '@models/Core';

import CompleterPrompt from './CompleterPrompt';
import AudioContent from './Content/AudioContent';
import LessonContent from './Content/LessonContent';
import TextContent from './Content/TextContent';
import VideoContent from './Content/VideoContent';
import VocabSetItem from './Content/VocabSetItem';

interface QuestionProps {
   evaluations: Record<number, ActivityResponseEvaluation<ActivityCompleterMode>>;
   isSubmitting: boolean;
   question: ActivityQuestion<ActivityCompleterMode>;
   responses: Record<number, ActivityResponse>;
   showMissed: boolean;
   checkAnswers(promptId: string | number): void;
   postDiscussionBoardResponse(itemId: string | number, entry: string, parentId: number): void;
   saveResponse(itemId: string | number): void;
   setDisableSubmit(disableSubmit: boolean): void;
   setResponse(itemId: string | number, response: ActivityResponse, callback?: () => void): void;
}

interface QuestionContext {
   readyToRecord: Maybe<{
      delay: Maybe<number>;
      promptId: string | number;
   }>;
}

const QuestionContext = React.createContext<QuestionContext>({ readyToRecord: null });

export const QuestionProvider = QuestionContext.Provider;
export const QuestionConsumer = QuestionContext.Consumer;

const Question: React.FC<QuestionProps> = ({
   evaluations,
   question,
   responses,
   showMissed,
   isSubmitting,
   checkAnswers,
   postDiscussionBoardResponse,
   setDisableSubmit,
   setResponse,
   saveResponse,
}) => {
   const [readyToRecord, setReadyToRecord] = React.useState<
      Maybe<{
         delay: Maybe<number>;
         promptId: string | number;
      }>
   >(null);

   const onReadyToRecord = (audioContentId: string | number): void => {
      const contentIndex = question.items.findIndex((i) => i.id === audioContentId);
      const content = question.items[contentIndex];
      const prompt = question.items[contentIndex + 1];
      if (!isAudioContent(content) || !isAudioRecordingPrompt(prompt)) {
         return;
      }
      setReadyToRecord({ promptId: prompt.id, delay: content.recordingDelay });
   };

   const allInteractivePromptsForQuestionn = getAllInteractivePromptsForQuestion(question);

   // eslint-disable-next-line complexity
   const itemFactory = (item: ActivityItem<ActivityCompleterMode>): React.ReactNode => {
      const { key } = item;
      if (isPrompt(item)) {
         const isInteractiveVideoPrompt =
            item.id !== null && allInteractivePromptsForQuestionn.includes(item.id);

         if (!isInteractiveVideoPrompt) {
            return (
               <CompleterPrompt
                  key={item.key}
                  evaluations={evaluations}
                  prompt={item}
                  readyToRecord={readyToRecord}
                  responses={responses}
                  showMissed={showMissed}
                  postDiscussionBoardResponse={postDiscussionBoardResponse}
                  setResponse={setResponse}
                  saveResponse={saveResponse}
                  setDisableSubmit={setDisableSubmit}
               />
            );
         }
      } else if (isTextContent(item)) {
         const { text } = item;
         return <TextContent key={key} text={text} />;
      } else if (isAudioContent(item)) {
         return (
            <AudioContent
               disablePausing={item.disablePausing}
               fileUrl={item.fileUrl}
               key={key}
               limitPlaybacks={item.limitPlaybacks}
               playbacks={item.playbacks}
               recordUponCompletion={item.recordUponCompletion}
               onReadyToRecord={() => {
                  onReadyToRecord(item.id);
               }}
            />
         );
      } else if (isVideoContent(item)) {
         return (
            <VideoContent
               checkAnswers={checkAnswers}
               fileUrl={
                  isUploadedVideoContent(item) || isOpenTokVideoContent(item) ? item.fileUrl : null
               }
               enableNativeSubtitles={item.enableNativeSubtitles}
               enableTargetSubtitles={item.enableTargetSubtitles}
               evaluations={evaluations}
               interactivePrompts={getPromptsForInteractiveVideoFromQuestion(question, item)}
               isInteractiveVideo={item.isInteractiveVideo}
               isSubmitting={isSubmitting}
               key={key}
               playSnippet={item.playSnippet}
               postDiscussionBoardResponse={postDiscussionBoardResponse}
               promptTimes={item.promptTimes}
               readyToRecord={readyToRecord}
               responses={responses}
               saveResponse={saveResponse}
               setDisableSubmit={setDisableSubmit}
               setResponse={setResponse}
               showMissed={showMissed}
               snippetEnd={item.snippetEnd}
               snippetStart={item.snippetStart}
               videoId={isVideoLibraryContent(item) ? item.videoId : null}
               youtubeId={isYouTubeVideoContent(item) ? item.youtubeId : null}
            />
         );
      } else if (isLessonContent(item)) {
         const { lessonId, display } = item;
         return <LessonContent key={key} lessonId={lessonId} display={display} />;
      } else if (isVocabSetItem(item)) {
         const { vocabSetId, display } = item;
         return <VocabSetItem key={key} vocabSetId={vocabSetId} display={display} />;
      }

      return null;
   };

   return (
      <div className='card lingco-question'>
         <div className='card-title full-width activity-builder-title'>
            <div className='title'>{question.title || `Question ${question.index + 1}`}</div>
         </div>
         <div className='activity-builder-container'>
            {question.items.map((i) => itemFactory(i))}
         </div>
      </div>
   );
};

export default Question;
