import * as React from 'react';

import Switch from '@components/Common/Switch';
import { CircularProgressBar } from '@components/Core/ProgressBar';

interface OverallProgressProps {
   hasDemoStudents: boolean;
   includeDemoStudents: boolean;
   percentTotalCompleted: number;
   percentTotalGraded: number;
   toggleIncludeDemoStudents(event: React.ChangeEvent<HTMLInputElement>): void;
}

const OverallProgress: React.FC<OverallProgressProps> = ({
   hasDemoStudents,
   includeDemoStudents,
   percentTotalCompleted,
   percentTotalGraded,
   toggleIncludeDemoStudents,
}) => (
   <div
      className='card submission-progress-card margin-bottom-m'
      data-tour='overall-progress-chart'
   >
      <div className='card-title'>
         <div className='title'>Overall Progress</div>
      </div>
      <div className='row progress-chart-wrapper'>
         <div className='col-xs-6'>
            <div className='gray-text text-sm margin-top-xs'>% Completed</div>
            <div className='progress-chart margin-top-s'>
               <CircularProgressBar
                  counterClockwise
                  animate
                  color='dark-red'
                  percentage={percentTotalCompleted ? Math.floor(percentTotalCompleted * 100) : 0}
               />
            </div>
         </div>
         <div className='col-xs-6'>
            <div className='gray-text text-sm margin-top-xs'>% Graded</div>
            <div className='progress-chart margin-top-s'>
               <CircularProgressBar
                  counterClockwise
                  animate
                  color='yellow'
                  percentage={percentTotalGraded ? Math.floor(percentTotalGraded * 100) : 0}
               />
            </div>
         </div>
      </div>
      {hasDemoStudents && (
         <div className='include-demo-wrapper'>
            <Switch
               name='includeDemoStudents'
               checked={includeDemoStudents}
               onChange={toggleIncludeDemoStudents}
            >
               Include Demo Students
            </Switch>
         </div>
      )}
   </div>
);

export default OverallProgress;
