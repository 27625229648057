import React from 'react';

type DataTestLoaderProps = {
   isLoading: boolean;
};

// Some times in our e2e tests we need to wait for some data to be loaded before we can interact with the page.
// The e2e tests usually wait, but there are times when a function gets what is visible on the page and it is not loaded yet.
const DataTestLoader: React.FC<DataTestLoaderProps> = ({ isLoading }) => (
   <div data-test={isLoading ? 'isLoading' : 'isLoaded'} style={{ visibility: 'hidden' }} />
);

export default DataTestLoader;
