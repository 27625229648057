import * as _ from 'lodash';
import * as React from 'react';

import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { DowntimeBanner, IApplicationState } from '@models/ApplicationState';
import Banner from '@models/Banner';
import Breadcrumbs from '@models/Breadcrumbs';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import { BasicCourseProfile } from '@models/Course';
import ISchool from '@models/ISchool';
import IUserProfile from '@models/IUserProfile';
import IWarningModal from '@models/IWarningModal';
import { NationalExamAbbreviation, NationalExamReportTaskResponse } from '@models/NationalExam';
import Notification from '@models/Notification';
import Toast from '@models/Toast';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';

import { Command } from './components/CommandPalette/Models';
import { HelpLauncherSize } from './components/HelpLauncher';
import { BannerName } from './components/Wrappers/Layout/Banner';
import { WarningName } from './components/Wrappers/Layout/WarningModal';
import { isChrome, isDesktopOrLaptop } from './helpers/BrowserUtils';
import { randomShortId } from './helpers/RandomStringUtils';
import { Maybe } from './Models/Core';
import IUserInfo from './Models/IUserInfo';
import { actionFunctions } from './redux/Actions';
import CourseService from './services/CourseService';
import DateTime from './services/DateTimeService';
import DowntimeService from './services/DowntimeService';
import NotificationService from './services/NotificationService';
import { OnlineCheckService } from './services/OnlineCheckService';
import UserService from './services/UserService';

export interface AppStateContext {
   archivedCourses: readonly BasicCourseProfile[];
   availableNationalExams: readonly NationalExamAbbreviation[];
   banner: Maybe<Banner>;
   breadcrumbs: Breadcrumbs;
   commands: readonly Command[];
   contentLibraryLayout: ContentLibraryLayout;
   currentCourses: readonly BasicCourseProfile[];
   districtProfile: Maybe<ISchool>;
   helpLauncherSize: HelpLauncherSize;
   isImpersonating: boolean;
   loggedIn: boolean;
   networkError: Maybe<AxiosResponse>;
   notifications: readonly Notification[];
   paidCourseId: Maybe<number>;
   reportTaskResponse: Maybe<NationalExamReportTaskResponse>;
   schoolProfile: Maybe<ISchool>;
   toasts: readonly Toast[];
   userProfile: Maybe<IUserProfile>;
   warningModal: Maybe<IWarningModal>;
   appendCourse(course: BasicCourseProfile): void;
   confirmUser(): void;
   dismissToast(toastId: number | string): void;
   dispatchToast(toast: Toast): void;
   registerCommands(commands: readonly Command[]): void;
   setAvailableNationalExams(exams: readonly NationalExamAbbreviation[]): void;
   setBanner(banner: Banner): void;
   setBreadcrumbs(breadcrumbs: Breadcrumbs): void;
   setContentLibraryLayout(contentLibraryLayout: ContentLibraryLayout): void;
   setNotifications(notifications: readonly Notification[]): void;
   unregisterCommands(commands: readonly Command[]): void;
   setCourses(
      currentCourses: readonly BasicCourseProfile[],
      archivedCourses: readonly BasicCourseProfile[],
   ): void;
   setHelpLauncherSize(helpLauncherSize: HelpLauncherSize): void;
   setNetworkError(error: AxiosResponse): void;
   setPaidCourseId(paidCourseId: number | null): void;
   setReportTaskResponse(reportTaskResponse: NationalExamReportTaskResponse | null): void;
   setWarning(warningModal: IWarningModal): void;
}

export const AppStateContext = React.createContext<AppStateContext>({
   archivedCourses: [],
   availableNationalExams: [],
   banner: null,
   commands: [],
   breadcrumbs: { next: null, prev: null, breadcrumbs: [] },
   contentLibraryLayout: ContentLibraryLayout.grid,
   currentCourses: [],
   districtProfile: null,
   helpLauncherSize: HelpLauncherSize.large,
   isImpersonating: false,
   loggedIn: false,
   networkError: null,
   notifications: [],
   paidCourseId: null,
   reportTaskResponse: null,
   schoolProfile: null,
   toasts: [],
   userProfile: null,
   warningModal: null,
   appendCourse: _.noop,
   confirmUser: _.noop,
   dismissToast: _.noop,
   dispatchToast: _.noop,
   registerCommands: _.noop,
   setAvailableNationalExams: _.noop,
   setBanner: _.noop,
   setBreadcrumbs: _.noop,
   setContentLibraryLayout: _.noop,
   setCourses: _.noop,
   setHelpLauncherSize: _.noop,
   setNetworkError: _.noop,
   setNotifications: _.noop,
   setPaidCourseId: _.noop,
   setReportTaskResponse: _.noop,
   setWarning: _.noop,
   unregisterCommands: _.noop,
});

export const AppStateProvider = AppStateContext.Provider;
export const AppStateConsumer = AppStateContext.Consumer;

interface AppState {
   banner: Banner;
   breadcrumbs: Breadcrumbs;
   commands: readonly Command[];
   helpLauncherSize: HelpLauncherSize;
   notifications: readonly Notification[];
   paidCourseId: Maybe<number>;
   toasts: readonly Toast[];
   warningModal: IWarningModal;
}

const AppState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   let onlineSubscription: Maybe<Subscription> = null;

   const dispatch = useDispatch();

   const archivedCourses = useSelector<IApplicationState, readonly BasicCourseProfile[]>(
      (state) => state.archivedCourses,
   );
   const availableNationalExams = useSelector<
      IApplicationState,
      readonly NationalExamAbbreviation[]
   >((state) => state.availableNationalExams);
   const contentLibraryLayout = useSelector<IApplicationState, ContentLibraryLayout>(
      (state) => state.contentLibraryLayout,
   );
   const currentCourses = useSelector<IApplicationState, readonly BasicCourseProfile[]>(
      (state) => state.currentCourses,
   );
   const hideDowntimeBanner = useSelector<IApplicationState, DowntimeBanner>(
      (state) => state.hideDowntimeBanner,
   );
   const networkError = useSelector<IApplicationState, Maybe<AxiosResponse>>(
      (state) => state.networkError,
   );
   const reportTaskResponse = useSelector<IApplicationState, Maybe<NationalExamReportTaskResponse>>(
      (state) => state.reportTaskResponse,
   );
   const user = useSelector<IApplicationState, Maybe<IUserInfo>>((state) => state.user);

   const authenticated = user?.userProfile?.authenticated ?? false;

   const appendCourse = (course: BasicCourseProfile) =>
      dispatch(actionFunctions.appendCourse(course));

   const confirmUser = () => dispatch(actionFunctions.confirmUser());

   const setContentLibraryLayout = (updatedContentLibraryLayout: ContentLibraryLayout) =>
      dispatch(actionFunctions.setContentLibraryLayout(updatedContentLibraryLayout));

   const setNetworkError = (updatedNetworkError: AxiosResponse) =>
      dispatch(actionFunctions.setNetworkError(updatedNetworkError));

   const setCourses = (
      updatedCurrentCourses: readonly BasicCourseProfile[],
      updatedArchivedCourses: readonly BasicCourseProfile[],
   ) => dispatch(actionFunctions.setCourses(updatedCurrentCourses, updatedArchivedCourses));

   const setAvailableNationalExams = (exams: readonly NationalExamAbbreviation[]) =>
      dispatch(
         actionFunctions.setAvailableNationalExams(
            _.uniq(exams.map((x) => x.toLowerCase())) as readonly NationalExamAbbreviation[],
         ),
      );

   const setReportTaskResponse = (updatedReportTaskResponse: NationalExamReportTaskResponse) =>
      dispatch(actionFunctions.setReportTaskResponse(updatedReportTaskResponse));

   const [state, setState] = React.useState<AppState>({
      banner: {
         show: false,
      },
      breadcrumbs: { next: null, prev: null, breadcrumbs: [] },
      commands: [],
      helpLauncherSize: HelpLauncherSize.large,
      notifications: [],
      paidCourseId: null,
      toasts: [],
      warningModal: {
         show: false,
      },
   });

   React.useEffect(() => {
      if (user?.loggedIn) {
         if (!onlineSubscription || onlineSubscription.closed) {
            OnlineCheckService.start();
            onlineSubscription = OnlineCheckService.subscribeTo.online(setOfflineBanner);
         }
      }
      return () => {
         OnlineCheckService.stop();
         onlineSubscription?.unsubscribe();
      };
   }, [user?.loggedIn, state.banner]);

   React.useEffect(() => {
      // TODO: We should consider pausing the app-load process until we load important data from the server
      // such as feature switches, server time, auth status, etc
      DateTime.resetTimeDiff();
   }, []);

   React.useEffect(() => {
      if (user?.loggedIn) {
         checkIsDesktop();
         fetchCourses();
         UserService.checkFeature('browser_banner').then((i) => i && setBrowserBanner());
      } else {
         setHelpLauncherSize();
      }
   }, [user?.loggedIn, user?.impersonatorRefreshToken]);

   React.useEffect(() => {
      if (user?.loggedIn && user.userProfile) {
         setTrialBanner();
         NotificationService.getNotifications(user.userProfile.id).then(setNotifications);
      }
   }, [user?.loggedIn, currentCourses]);

   React.useEffect(() => {
      if (user?.loggedIn) {
         setConfirmEmailBanner();
      }
   }, [user?.loggedIn, authenticated]);

   React.useEffect(() => {
      DowntimeService.getDowntimeMessage().then((response) => {
         setDowntimeBanner(response);
      });
   }, []);

   const dispatchToast = (toast: Toast): void => {
      setState((prevState) => ({
         ...prevState,
         toasts: [...prevState.toasts, { id: randomShortId(), ...toast }],
      }));
   };

   const dismissToast = (toastId: string | number): void => {
      setState((prevState) => ({
         ...prevState,
         toasts: prevState.toasts.filter((i) => i.id !== toastId),
      }));
   };

   const setPaidCourseId = (paidCourseId: number): void => {
      setState((prevState) => ({ ...prevState, paidCourseId }));
   };

   const setBanner = (banner: Banner): void => {
      setState((prevState) => ({ ...prevState, banner }));
   };

   const setWarning = (warningModal: IWarningModal): void => {
      setState((prevState) => ({ ...prevState, warningModal }));
   };

   const setBreadcrumbs = (breadcrumbs: Breadcrumbs): void => {
      setState((prevState) => ({ ...prevState, breadcrumbs }));
   };

   const setHelpLauncherSize = (helpLauncherSize?: HelpLauncherSize): void => {
      if (helpLauncherSize) {
         setState((prevState) => ({ ...prevState, helpLauncherSize }));
      } else {
         const isStudent = user?.userProfile?.accountType === AccountType.student;
         const defaultSize =
            user?.loggedIn && isStudent ? HelpLauncherSize.small : HelpLauncherSize.large;
         setState((prevState) => ({
            ...prevState,
            helpLauncherSize: defaultSize,
         }));
      }
   };

   const setTrialBanner = (): void => {
      const { bannerName = null } = state.banner;
      const trialCourse = currentCourses.find((i) => i.needsToPurchaseLicense);
      const trialBanners = [BannerName.COURSE_TRIAL, BannerName.EXPIRED_COURSE_TRIAL];

      if (trialCourse) {
         const { trialEndOn } = trialCourse;
         const now = DateTime.now();
         const data = {
            courseName: trialCourse.name,
            courseId: trialCourse.id,
         };
         if (trialEndOn && now > trialEndOn) {
            setBanner({
               bannerName: BannerName.EXPIRED_COURSE_TRIAL,
               appearance: Appearance.danger,
               show: true,
               data,
            });
         } else {
            setBanner({
               bannerName: BannerName.COURSE_TRIAL,
               show: true,
               dismissable: true,
               data: {
                  ...data,
                  remainingTime: moment(trialEndOn).from(now),
               },
            });
         }
      } else if (bannerName && trialBanners.includes(bannerName)) {
         setBanner({ body: '', show: false });
      }
   };

   const setBrowserBanner = (): void => {
      const desktopCheck = isDesktopOrLaptop();
      const chromeCheck = isChrome();
      if (!(desktopCheck && chromeCheck)) {
         setBanner({
            bannerName: BannerName.NONIDEAL_BROWSER,
            show: true,
            dismissable: true,
            data: { isDesktopOrLaptop: desktopCheck },
         });
      }
   };

   const checkIsDesktop = (): void => {
      if (!isDesktopOrLaptop()) {
         setWarning({
            name: WarningName.UNSUPPORTED_DEVICE,
            show: true,
         });
      }
   };

   const fetchCourses = (): void => {
      const { bannerName = null } = state.banner;
      CourseService.getCourses().then(({ invites, courses, nationalExams }) => {
         const filteredInvites = invites.filter((i) => !i.autoAccept);
         if (filteredInvites.length) {
            const { courseId, courseName, inviteId } = filteredInvites[0];
            setBanner({
               bannerName: BannerName.JOIN_COURSE,
               show: true,
               dismissable: true,
               data: {
                  courseName,
                  courseId,
                  inviteId,
               },
            });
         } else if (bannerName === BannerName.JOIN_COURSE) {
            setBanner({ body: '', show: false });
         }
         if (courses.archived !== archivedCourses || courses.current !== currentCourses) {
            setCourses(courses.current, courses.archived);
         }
         if (nationalExams) {
            setAvailableNationalExams(nationalExams);
         }
      });
   };

   const setConfirmEmailBanner = (): void => {
      const { bannerName = null } = state.banner;
      if (authenticated === false) {
         setBanner({
            bannerName: BannerName.CONFIRM_EMAIL,
            show: true,
            dismissable: true,
         });
      } else if (bannerName === BannerName.CONFIRM_EMAIL) {
         setBanner({ body: '', show: false });
      }
   };

   const setOfflineBanner = (online: boolean): void => {
      const { bannerName = null } = state.banner;
      if (online && bannerName === BannerName.BROWSER_OFFLINE) {
         setBanner({ body: '', show: false });
      }
      if (!online) {
         setBanner({
            bannerName: BannerName.BROWSER_OFFLINE,
            body: "It looks like you've gone offline. Check your internet connection.",
            appearance: Appearance.danger,
            className: 'fixed',
            show: true,
            dismissable: true,
         });
      }
   };

   const setDowntimeBanner = (message: string | null): void => {
      if (message !== hideDowntimeBanner.messageStore) {
         dispatch(
            actionFunctions.setDowntimeBanner({
               messageStore: message === null ? undefined : message,
               dismissed: message === null ? true : false,
            }),
         );
      }

      if (message !== null) {
         setBanner({
            bannerName: BannerName.DOWNTIME_EVENT,
            body: message,
            appearance: Appearance.info,
            show: !hideDowntimeBanner.dismissed,
            dismissable: true,
         });
      }
   };

   const setNotifications = (notifications: readonly Notification[]): void => {
      setState((prevState) => ({
         ...prevState,
         notifications,
      }));
   };

   const registerCommands = (commands: readonly Command[]): void => {
      const commandIdsToOverwrite = commands.map((i) => i.id);
      setState((prevState) => ({
         ...prevState,
         commands: [
            ...prevState.commands.filter((i) => !commandIdsToOverwrite.includes(i.id)),
            ...commands,
         ],
      }));
   };

   const unregisterCommands = (commands: readonly Command[]): void => {
      const commandIdsToUnregister = commands.map((i) => i.id);
      setState((prevState) => ({
         ...prevState,
         commands: prevState.commands.filter((i) => !commandIdsToUnregister.includes(i.id)),
      }));
   };

   return (
      <AppStateProvider
         value={{
            archivedCourses,
            availableNationalExams,
            banner: state.banner,
            commands: state.commands,
            breadcrumbs: state.breadcrumbs,
            contentLibraryLayout,
            currentCourses,
            helpLauncherSize: state.helpLauncherSize,
            isImpersonating: !!user?.impersonatorRefreshToken,
            loggedIn: user?.loggedIn ?? false,
            networkError,
            notifications: state.notifications,
            paidCourseId: state.paidCourseId,
            reportTaskResponse,
            districtProfile: user?.districtProfile,
            schoolProfile: user?.schoolProfile,
            toasts: state.toasts,
            userProfile: user?.userProfile,
            warningModal: state.warningModal,
            appendCourse,
            confirmUser,
            dismissToast,
            dispatchToast,
            registerCommands,
            setAvailableNationalExams,
            setBanner,
            setBreadcrumbs,
            setContentLibraryLayout,
            setCourses,
            setHelpLauncherSize,
            setNetworkError,
            setNotifications,
            setPaidCourseId,
            setReportTaskResponse,
            setWarning,
            unregisterCommands,
         }}
      >
         {children}
      </AppStateProvider>
   );
};

export default AppState;
