/* eslint-disable complexity */
import * as React from 'react';

import { formatSeconds } from '@helpers/FormatTime';
import IconPause from '@icons/general/video-control-pause.svg';
import IconPlay from '@icons/general/video-control-play.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import AudioSlider from './AudioSlider';

export enum AudioPlayerSize {
   full = 'full',
   small = 'small',
   minimal = 'minimal',
}

interface AudioPlayerProps {
   className?: string;
   disabled?: boolean;
   disablePausing?: boolean;
   disableSeeking?: boolean;
   minimal?: boolean;
   size?: AudioPlayerSize;
   src: string;
   children?: React.ReactNode;
   containerAttrs?: React.HTMLAttributes<HTMLDivElement>;
   onEnded?(event: Event): void;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
   className = '',
   disabled = false,
   disablePausing = false,
   disableSeeking = false,
   size = AudioPlayerSize.full,
   src,
   containerAttrs = {},
   children,
   onEnded,
}) => {
   const PRECISION = 100;
   const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
   const [isSeeking, setIsSeeking] = React.useState<boolean>(false);
   const [values, setValues] = React.useState<Maybe<readonly number[]>>(null);
   const [domain, setDomain] = React.useState<Maybe<readonly number[]>>(null);
   const audioRef = React.useRef<Maybe<HTMLAudioElement>>(null);

   React.useEffect(() => () => audioRef.current?.pause(), []);

   React.useEffect(() => {
      audioRef.current = src ? initializeAudio(src) : null;
   }, [src]);

   React.useEffect(() => {
      if (onEnded && audioRef.current) {
         audioRef.current.onended = (event) => {
            setIsPlaying(false);
            onEnded?.(event);
         };
      }
   }, [onEnded]);

   const convert = (value: number): number => Math.round(value * PRECISION);

   const toSeconds = (value: number): number => (value ? value / PRECISION : 0);

   const handleLoadedMetaData = (event: Event): void => {
      if (event.target instanceof HTMLAudioElement) {
         setDomain([0, convert(event.target.duration)]);
         setValues([convert(event.target.currentTime)]);
      }
   };

   const handleTimeUpdate = (event: Event): void => {
      if (!isSeeking && event.target instanceof HTMLAudioElement) {
         setValues([convert(event.target.currentTime)]);
      }
   };

   const handleSlideStart = (): void => {
      if (disableSeeking) {
         return;
      }
      setIsSeeking(true);
      audioRef.current?.pause();
   };

   const handleChange = (newValues: readonly number[]): void => {
      if (disableSeeking) {
         return;
      }
      if (audioRef.current) {
         audioRef.current.currentTime = toSeconds(newValues[0]);
      }
      if (isPlaying) {
         audioRef.current?.play();
      }
      setIsSeeking(false);
      setValues(newValues);
   };

   const handleUpdate = (newValues: readonly number[]): void => {
      if (disableSeeking) {
         return;
      }
      setValues(newValues);
      if (audioRef.current) {
         audioRef.current.currentTime = toSeconds(newValues[0]);
      }
   };

   const initializeAudio = (audioSrc: string): HTMLAudioElement => {
      const audio = new Audio();
      audio.src = audioSrc;
      audio.onended = () => {
         setIsPlaying(false);
      };
      audio.onloadedmetadata = handleLoadedMetaData;
      audio.ontimeupdate = handleTimeUpdate;
      return audio;
   };

   const togglePlaying = (event: React.MouseEvent<HTMLDivElement>): void => {
      if (disabled) {
         return;
      }
      event.stopPropagation();
      if (isPlaying) {
         if (!disablePausing) {
            audioRef.current?.pause();
            setIsPlaying(false);
         }
      } else {
         audioRef.current?.play();
         setIsPlaying(true);
      }
   };

   if (!(domain && values)) {
      return null;
   }

   if (children) {
      return (
         <span className={className} onClick={togglePlaying}>
            {children}
         </span>
      );
   }

   if (size === 'minimal') {
      return (
         <div className={classnames('audio-player-minimal', className)} onClick={togglePlaying}>
            {isPlaying ? <IconPause /> : <IconPlay />}
         </div>
      );
   } else if (size === 'small') {
      return (
         <div className={classnames('audio-player-small', className)} onClick={togglePlaying}>
            {isPlaying ? <IconPause /> : <IconPlay />}
         </div>
      );
   }
   return (
      <div
         className={classnames('audio-player', className, {
            disabled: (disablePausing && isPlaying) || disabled,
         })}
         {...containerAttrs}
      >
         <div className='audio-icon-container' onClick={togglePlaying}>
            {isPlaying ? <IconPause /> : <IconPlay />}
         </div>
         <span className='current-time'>
            {values ? formatSeconds(toSeconds(values[0])) : '0:00'}
         </span>
         <div className='slider-container'>
            <AudioSlider
               disabled={(isPlaying && (disablePausing || disableSeeking)) || disabled}
               domain={domain}
               values={values}
               onChange={handleChange}
               onUpdate={handleUpdate}
               onSlideStart={handleSlideStart}
            />
         </div>
         <span className='duration'>{domain ? formatSeconds(toSeconds(domain[1])) : '0:00'}</span>
      </div>
   );
};

export default React.memo(AudioPlayer);
