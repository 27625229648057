import * as React from 'react';

import AddButton from '@components/Common/AddButton';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import { renderDateCell } from '@components/Common/TableCellHelpers';
import EmptyState from '@components/Core/EmptyState/EmptyState';
import StatusIndicator from '@components/Core/StatusIndicator';
import { InstructorLicense } from '@generated/gql/graphql';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import classnames from 'classnames';

import InstructorLicenseModal from './AddInstructorLicenseModal';

type Props = {
   disableUpdates: boolean;
   instructorId: string;
   panelTitle: string;
   refetch(): void;
   value: InstructorLicense[];
};

const InstructorLicensePanel: React.FC<Props> = (props) => {
   const [isAddModalOpen, setIsAddModalOpen] = React.useState<boolean>(false);
   const [currentInstructorLicense, setCurrentInstructorLicense] =
      React.useState<InstructorLicense>();

   const openModal = () => {
      setIsAddModalOpen(true);
   };

   const closeModal = () => {
      setIsAddModalOpen(false);
      setCurrentInstructorLicense(undefined);
   };

   const onSave = () => {
      props.refetch();
      closeModal();
   };

   const getIsExpired = (expiresOn: Date | null | undefined) =>
      expiresOn ? new Date(expiresOn) < new Date() : false;

   const instructorLicenseColumns: readonly Column<InstructorLicense>[] = [
      {
         id: 'id',
         header: 'Id',
         cell: (i) => <a onClick={() => setCurrentInstructorLicense(i)}>{i.id}</a>,
      },
      {
         id: 'expiresOn',
         header: 'Expires',
         cell: (i) => (
            <span className={classnames({ 'red-text': getIsExpired(i.expiresOn) })}>
               {renderDateCell(i.expiresOn) || 'Never'}
            </span>
         ),
      },
      {
         id: 'numberOfSeats',
         cell: (i) => i.numberOfSeats,
         centerCell: true,
         centerHeader: true,
         header: 'Seats',
      },
      {
         id: 'canManageCourses',
         cell: (i) => (
            <StatusIndicator isGood={i.canManageCourses} isExpired={getIsExpired(i.expiresOn)} />
         ),
         centerCell: true,
         centerHeader: true,
         header: 'Manage Courses',
      },
      {
         id: 'canManageContentLibrary',
         cell: (i) => (
            <StatusIndicator
               isGood={i.canManageContentLibrary}
               isExpired={getIsExpired(i.expiresOn)}
            />
         ),
         centerCell: true,
         centerHeader: true,
         header: 'Content Library',
      },
      {
         id: 'canViewGlobalLibrary',
         cell: (i) => (
            <StatusIndicator
               isGood={i.canViewGlobalLibrary}
               isExpired={getIsExpired(i.expiresOn)}
            />
         ),
         centerCell: true,
         centerHeader: true,
         header: 'Global Library',
      },
   ];

   return (
      <SectionCard
         title={`${props.panelTitle}`}
         toolTipContent='This panel displays the licenses that are associated with this instructor. This will determine what features the instructor has access to.'
         headerButton={
            !props.disableUpdates ? (
               <AddButton onClick={openModal} aria-label='Add Instructor License' />
            ) : undefined
         }
      >
         {props.value.length > 0 ? (
            <div className='row margin-top-s'>
               <div className='col-xs-12'>
                  <Table
                     columns={instructorLicenseColumns}
                     rows={props.value}
                     rowKey='id'
                     defaultSortBy={[{ id: 'id', desc: true }]}
                  />
               </div>
            </div>
         ) : (
            <EmptyState
               className='padding-top-m padding-bottom-s'
               description=''
               heading='Nothing to Show'
               icon={<IconBinoculars className='large' aria-hidden />}
            />
         )}

         {(isAddModalOpen || currentInstructorLicense) && (
            <InstructorLicenseModal
               closeModal={closeModal}
               instructorId={props.instructorId}
               instructorLicense={currentInstructorLicense}
               onSave={onSave}
            />
         )}
      </SectionCard>
   );
};

export default InstructorLicensePanel;
