import * as _ from 'lodash';
import * as React from 'react';

import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import MapboxService from '@services/MapboxService';
import { ActionMeta, GroupBase, OnChangeValue, StylesConfig, Theme } from 'react-select';

import AsyncSelect from 'react-select/async';

export type PartialSearchBoxSuggestion = Pick<SearchBoxSuggestion, 'name' | 'place_formatted'>;
export type LocationSuggestion = SearchBoxSuggestion | PartialSearchBoxSuggestion;
export type LocationOptionsCallback = (options: LocationSuggestion[]) => void;

type Props = {
   selectedSuggestion: LocationSuggestion | undefined;
   setSelectedSuggestion(option: LocationSuggestion | undefined): void;
};

const LocationSearch: React.FC<Props> = (props) => {
   const loadOptions = (newInputValue: string, callback: LocationOptionsCallback): void => {
      if (newInputValue.length < 1) {
         callback([]);
         return;
      }

      MapboxService.suggest(newInputValue).then((suggestions) => callback(suggestions));
   };

   const debouncedLoadOptions = React.useCallback(_.debounce(loadOptions, 500), []);

   const handleChange = (
      option: OnChangeValue<LocationSuggestion, false>,
      actionMeta: ActionMeta<LocationSuggestion>,
   ): void => {
      if (props.setSelectedSuggestion) {
         if (actionMeta.action === 'select-option' || actionMeta.action === 'clear') {
            props.setSelectedSuggestion(option || undefined);
         }
      }
   };

   const customStyles: StylesConfig<LocationSuggestion, false, GroupBase<SearchBoxSuggestion>> = {
      control: (styles) => ({
         ...styles,
         borderRadius: '3px',
      }),
      menu: (provided) => ({
         ...provided,
         zIndex: '2', // Needed for the activity builder. Part of the menu was not out front.
      }),
      menuPortal: (provided) => ({
         ...provided,
         zIndex: '9999',
      }),
      placeholder: (styles) => ({
         ...styles,
         fontWeight: 400,
         fontFamily: 'Karla',
         color: '#807F86',
      }),
      option: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
      }),
      singleValue: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
         color: '#413f48',
      }),
   };

   const customTheme: (theme: Theme) => Theme = (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
         ...theme.colors,
         primary: '#007cbb',
         neutral20: '#e7e7e7',
      },
   });

   const formatOptionLabel = (suggestion: LocationSuggestion): React.ReactNode => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
         <strong>{suggestion.name}</strong>
         <label>{suggestion.place_formatted}</label>
      </div>
   );

   return (
      <AsyncSelect
         className='react-select'
         defaultOptions
         formatOptionLabel={formatOptionLabel}
         isClearable
         isMulti={false}
         loadOptions={debouncedLoadOptions}
         menuPortalTarget={document.body}
         menuPosition='fixed'
         menuShouldScrollIntoView={false}
         onChange={handleChange}
         placeholder='Enter location'
         styles={customStyles}
         theme={customTheme}
         value={props.selectedSuggestion || null} // Undefined does not reset the display value. Null does.
      />
   );
};

export default LocationSearch;
