import * as React from 'react';

import { isSameDay } from '@helpers/DateUtils';
import IconMicrophone from '@icons/general/icon-microphone.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import IconNavigationShowMoreVertical from '@icons/nova-solid/17-Navigation/navigation-show-more-vertical.svg';
import { AudioAnnotation } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import DateTime from '@services/DateTimeService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import Textarea from 'react-textarea-autosize';
import { Recorder } from 'vmsg';

import { AppStateContext } from '../../../../AppState';
import Button from '@components/Common/Button';
import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import Avatar from '@components/Core/Avatar';
import Droplist from '@components/Core/Droplist';

export type NewAudioAnnotation = Omit<AudioAnnotation, 'id'> & { id: null | string };

interface AnnotationProps {
   annotation: AudioAnnotation | NewAudioAnnotation;
   canEdit: boolean;
   isCreating: boolean;
   left: number;
   getAuthor(createdBy?: number): Maybe<BasicUserProfile>;
   onDelete(): void;
   onSave(annotation: AudioAnnotation | NewAudioAnnotation): void;
   onCancel(): void;
   onClearAnnotation?(): void;
}

const Annotation: React.FC<AnnotationProps> = ({
   annotation: { comments: origComments, createdBy, createdOn, audioUrl, id },
   annotation,
   canEdit,
   isCreating,
   left,
   getAuthor,
   onSave,
   onDelete,
   onCancel,
   onClearAnnotation,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!createdBy) {
      return null;
   }

   const elementRef = React.useRef<HTMLDivElement>(null);
   const textareaRef = React.useRef<HTMLTextAreaElement>(null);
   const [isEditing, setIsEditing] = React.useState<boolean>(false);
   const [isRecording, setIsRecording] = React.useState<boolean>(false);
   const [comments, setComments] = React.useState<string>('');
   const [audioSrc, setAudioSrc] = React.useState<string>('');
   const [blob, setBlob] = React.useState<Maybe<Blob>>(null);
   const recorder = React.useRef<Recorder>(
      new Recorder({
         wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
      }),
   );

   React.useEffect(() => {
      setAudioSrc(audioUrl);
   }, [audioUrl]);

   React.useEffect(() => {
      if (comments !== origComments && !isEditing) {
         setComments(origComments);
      }
   }, [comments, origComments, isCreating, isEditing, id]);

   React.useEffect(() => {
      document.addEventListener('click', handleDocumentClick, false);
      return () => {
         document.removeEventListener('click', handleDocumentClick);
      };
   }, []);

   React.useEffect(() => {
      if (isCreating) {
         setIsEditing(true);
      } else if (id) {
         setIsEditing(false);
      }
   }, [id, isCreating]);

   React.useEffect(() => {
      if (isEditing) {
         textareaRef.current?.focus();
      }
   }, [isEditing]);

   const clearAudio = (): void => {
      setAudioSrc('');
      setBlob(null);
   };

   const handleDocumentClick = (event: MouseEvent): void => {
      // Check if the click is inside the Wavesurfer container, if so ignore it
      if ((event.target as Element)?.closest('.wavesurfer-container')) {
         return;
      }
      if (!elementRef.current || !elementRef.current.contains(event.target as Element)) {
         onClearAnnotation?.();
      }
   };

   const handleElementClick = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.nativeEvent.stopImmediatePropagation();
   };

   const handleEdit = (): void => {
      if (canEdit) {
         setIsEditing(true);
      }
   };

   const handleSave = (): void => {
      setIsEditing(false);
      const updatedAnnotation = { ...annotation, comments, blob };
      if (!audioSrc && !blob) {
         updatedAnnotation.storedAudioFilename = '';
         updatedAnnotation.audioUrl = '';
      }
      onSave(updatedAnnotation);
   };

   const handleCancel = (): void => {
      if (isCreating) {
         onCancel();
      } else {
         setIsEditing(false);
      }
   };

   const getFormattedDate = (): string => {
      const date = createdOn;
      if (!date) {
         return '';
      }
      const timeStr = date.toLocaleTimeString([], {
         hour: 'numeric',
         minute: 'numeric',
      });
      let mmddyyStr = date.toLocaleString([], {
         month: 'numeric',
         day: 'numeric',
         year: '2-digit',
      });
      if (isSameDay(date, DateTime.now())) {
         mmddyyStr = 'Today';
      } else {
         const yesterday = DateTime.now();
         yesterday.setDate(yesterday.getDate() - 1);
         if (isSameDay(date, yesterday)) {
            mmddyyStr = 'Yesterday';
         }
      }
      return `${timeStr} ${mmddyyStr}`;
   };

   const initializeRecorder = async (): Promise<void> =>
      recorder.current.initAudio().then(() => recorder.current.initWorker());

   const renderRecorder = (): React.ReactNode => {
      const recorderElement = (
         <div className={classnames({ recording: isRecording })} onClick={toggleRecording}>
            <IconMicrophone />
         </div>
      );
      if (isRecording) {
         return recorderElement;
      } else {
         return (
            <Tippy delay={[800, 0]} content='Record Feedback'>
               {recorderElement}
            </Tippy>
         );
      }
   };

   const stopRecording = async (): Promise<void> => {
      const newBlob = await recorder.current.stopRecording();
      setBlob(newBlob);
      setIsRecording(false);
      const blobURL = window.URL.createObjectURL(newBlob);
      setAudioSrc(blobURL);
   };

   const toggleRecording = (): void => {
      if (isRecording) {
         stopRecording();
      } else {
         initializeRecorder()
            .then(() => {
               recorder.current.startRecording();
               setIsRecording(true);
            })
            .catch(() => {
               alert(
                  'Unable to capture your microphone. Please ensure that microphone access is enabled.',
               );
            });
      }
   };

   const minLeft = 0;
   const maxLeft =
      elementRef?.current?.parentElement?.offsetWidth && elementRef.current.offsetWidth
         ? elementRef.current.parentElement.offsetWidth - elementRef.current.offsetWidth + 20
         : 0;

   const clampedLeft = maxLeft ? Math.min(Math.max(left, minLeft), maxLeft) : Math.max(0, left);
   const author = getAuthor(createdBy);
   if (!author) {
      return null;
   }
   const { firstName, lastName, profileImageUrl } = author;

   const renderDroplist = () => {
      const userIsAuthor = author.id === userProfile?.id;
      if (!isCreating && userIsAuthor && canEdit) {
         return (
            <div className='options-wrapper'>
               <Droplist
                  pullRight
                  items={[
                     { text: 'Edit', onClick: handleEdit },
                     { text: 'Delete', onClick: onDelete },
                  ]}
               >
                  <IconNavigationShowMoreVertical />
               </Droplist>
            </div>
         );
      }
   };

   return (
      <div
         className='card annotation'
         style={{ left: `${clampedLeft}px` }}
         onClick={handleElementClick}
         ref={elementRef}
      >
         <div className='annotation-info-wrapper'>
            <Avatar
               hashValue={userProfile?.id}
               firstName={firstName}
               lastName={lastName}
               src={profileImageUrl}
            />
            <div className='author-timestamp'>
               <div className='author'>{`${firstName} ${lastName}`}</div>
               {createdOn && <div className='timestamp'>{getFormattedDate()}</div>}
            </div>
            {renderDroplist()}
         </div>
         {isEditing ? (
            <>
               <Textarea
                  ref={textareaRef}
                  name='comments'
                  value={comments}
                  onChange={(event) => setComments(event.target.value)}
               />
               <div className='annotation-footer'>
                  <div className='buttons-wrapper'>
                     <Button onClick={handleSave} disabled={!comments.trim() && !audioSrc}>
                        {isCreating ? 'Comment' : 'Save'}
                     </Button>
                     <Button line onClick={handleCancel}>
                        Cancel
                     </Button>
                  </div>
                  <div className='recording-wrapper'>
                     {audioSrc ? (
                        <AudioPlayer size={AudioPlayerSize.minimal} src={audioSrc} />
                     ) : (
                        renderRecorder()
                     )}
                     {!!audioSrc && (
                        <Tippy content='Clear Recording' delay={[800, 0]}>
                           <span className='clear-recording'>
                              <IconClose onClick={clearAudio} />
                           </span>
                        </Tippy>
                     )}
                  </div>
               </div>
            </>
         ) : (
            <div className='annotation-body'>
               {origComments}
               {audioSrc && <AudioPlayer size={AudioPlayerSize.minimal} src={audioSrc} />}
            </div>
         )}
      </div>
   );
};

export default Annotation;
