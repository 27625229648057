import * as React from 'react';

import useMousetrap from '@hooks/use-mousetrap';
import { Maybe } from '@models/Core';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';

interface ActivityGraderFooterProps {
   activeIndex: number;
   nextStudentLink: Maybe<string>;
   nextUngradedIndex: number;
   previousUngradedIndex: number;
   responseCount: number;
   shouldShowNextStudent: boolean;
   setActive(index: number): void;
}

const ActivityGraderFooter: React.FC<ActivityGraderFooterProps> = ({
   activeIndex,
   nextStudentLink,
   nextUngradedIndex,
   previousUngradedIndex,
   responseCount,
   shouldShowNextStudent,
   setActive,
}) => {
   const nextResponse = (): void => {
      if (activeIndex !== responseCount - 1) {
         setActive(activeIndex + 1);
      }
   };

   const previousResponse = (): void => {
      if (activeIndex) {
         setActive(activeIndex - 1);
      }
   };

   useMousetrap('left', previousResponse);
   useMousetrap('right', nextResponse);

   return (
      <div className='grader-nav'>
         <div className='title'>
            Response
            <div className='question-number'>
               {activeIndex + 1} of {responseCount}
            </div>
         </div>
         <div className='button-container' data-tour='grader-footer-buttons'>
            <Button
               className='gray hide-xs'
               disabled={previousUngradedIndex === -1 || activeIndex === 0}
               onClick={() => setActive(previousUngradedIndex)}
            >
               Previous Ungraded
            </Button>
            <Button line disabled={activeIndex === 0} onClick={previousResponse}>
               Previous
            </Button>
            <Button
               line
               className='hide-xs'
               disabled={activeIndex + 1 === responseCount}
               onClick={nextResponse}
               data-test='next-question-button'
            >
               Next
            </Button>
            {shouldShowNextStudent ? (
               <Link className='btn green' to={nextStudentLink} disabled={!nextStudentLink}>
                  Next Student
               </Link>
            ) : (
               <Button
                  color='green'
                  data-test='button-next-ungraded'
                  disabled={nextUngradedIndex === -1 || activeIndex === responseCount - 1}
                  onClick={() => setActive(nextUngradedIndex)}
               >
                  Next Ungraded
               </Button>
            )}
         </div>
      </div>
   );
};

export default ActivityGraderFooter;
