import * as React from 'react';

import { Maybe } from '@models/Core';

import AudioPlayer from '@components/Core/AudioPlayer';

interface AudioContentProps {
   disablePausing: boolean;
   fileUrl: Maybe<string>;
   limitPlaybacks: boolean;
   playbacks?: Maybe<number>;
   recordUponCompletion: boolean;
   onReadyToRecord(): void;
}

const AudioContent: React.FC<AudioContentProps> = ({
   disablePausing,
   fileUrl,
   limitPlaybacks,
   playbacks,
   recordUponCompletion,
   onReadyToRecord,
}) => {
   const [playbacksCount, setPlaybacksCount] = React.useState<number>(0);

   const handleOnEnded = (): void => {
      setPlaybacksCount(playbacksCount + 1);
      if (recordUponCompletion) {
         onReadyToRecord();
      }
   };

   return fileUrl ? (
      <div className='activity-builder-row'>
         <div className='row'>
            <div className='col-xs-12'>
               <AudioPlayer
                  disabled={limitPlaybacks && playbacks === playbacksCount}
                  onEnded={handleOnEnded}
                  src={fileUrl}
                  disablePausing={disablePausing}
                  disableSeeking={limitPlaybacks}
               />
            </div>
         </div>
      </div>
   ) : null;
};

export default AudioContent;
