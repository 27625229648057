/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-strict-ignore
import * as React from 'react';

import classnames from 'classnames';

import Button from '@components/Common/Button';
import DateTimePicker from '@components/Core/DateTimePicker';
import {
   BOOLEAN_COMPARISON_OPTIONS,
   DATE_COMPARISON_OPTIONS,
   NUMERIC_COMPARISON_OPTIONS,
   STRING_COMPARISON_OPTIONS,
} from './ComparisonOptions';
import { DatePredicate, Predicate, PrimitiveType, StringComparison } from './Models';
import { PredicateEditorContext } from './PredicatesEditor';
import { isAbsoluteDate, isBooleanPredicate, isRelativeDate } from './Utils';

interface FilterBlockOptionsProps {
   predicate: Predicate;
   showErrors?: boolean;
   onClose(): void;
   onUpdate(predicate: Predicate): void;
}

const FilterBlockOptions: React.FC<FilterBlockOptionsProps> = ({
   predicate,
   showErrors,
   onClose,
   onUpdate,
}) => {
   const { attributes } = React.useContext<PredicateEditorContext>(PredicateEditorContext);

   const attribute = attributes.find((i) => i.identifier === predicate.attribute);

   const getValuePrefix = (value: string): string =>
      value.includes(':') ? value.substr(0, value.indexOf(':')) : value;

   const handleValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const updatedValue = getValuePrefix(event.target.value);
      onUpdate({ ...predicate, value: updatedValue });
   };

   const handleDateChange = (date: Date): void => {
      if (isAbsoluteDate(predicate)) {
         const updatedDate = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
         };
         onUpdate({ ...predicate, value: updatedDate });
      }
   };

   const handleComparisonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      const comparison = getValuePrefix(value) as any;
      // clear predicate value if unknown or any
      if (value.includes('known')) {
         onUpdate({
            ...predicate,
            value: null,
            comparison,
         });
      } else if (value.includes('absolute') && !isAbsoluteDate(predicate)) {
         const date = new Date();
         const updatedDate = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
         };
         onUpdate({
            ...predicate,
            value: updatedDate,
            comparison,
         } as DatePredicate);
      } else if (value.includes('relative') && !isRelativeDate(predicate)) {
         onUpdate({
            ...predicate,
            value: '',
            comparison,
         });
      } else if (value === null) {
         onUpdate({
            ...predicate,
            value: '',
            comparison,
         });
      } else {
         onUpdate({
            ...predicate,
            comparison,
         });
      }
   };

   const isChecked = (value: string): boolean => {
      if (isAbsoluteDate(predicate)) {
         return `${predicate.comparison}:absolute` === value;
      } else if (isRelativeDate(predicate)) {
         return `${predicate.comparison}:relative` === value;
      } else {
         return predicate.comparison === value;
      }
   };

   const shouldRenderInput = (value: string): boolean => {
      if (isBooleanPredicate(predicate)) {
         return false;
      } else if (value.includes('known')) {
         return false;
      } else {
         return isChecked(value);
      }
   };

   const renderActiveValueInput = (): React.ReactNode => {
      if (predicate.type === PrimitiveType.string) {
         if (
            attribute.options &&
            [StringComparison.equals, StringComparison.doesNotEqual].includes(predicate.comparison)
         ) {
            return (
               <select
                  className={classnames({
                     error: showErrors && !predicate.value,
                  })}
                  onChange={handleValueChange}
                  value={predicate.value}
               >
                  <option key='empty' value='' disabled>
                     Select
                  </option>
                  {attribute.options.map((i) => (
                     <option key={i.value} value={i.value}>
                        {i.label}
                     </option>
                  ))}
               </select>
            );
         }
         return (
            <input
               type='text'
               className={classnames({ error: showErrors && !predicate.value })}
               value={predicate.value}
               onChange={handleValueChange}
            />
         );
      } else if (predicate.type === PrimitiveType.integer) {
         return (
            <input
               min='0'
               className={classnames({ error: showErrors && !predicate.value })}
               onChange={handleValueChange}
               type='number'
               value={predicate.value}
            />
         );
      } else if (predicate.type === PrimitiveType.date) {
         if (isAbsoluteDate(predicate)) {
            const { month, day, year } = predicate.value;
            const date = new Date(year, month - 1, day);
            return <DateTimePicker inline onChange={handleDateChange} time={false} value={date} />;
         } else if (isRelativeDate(predicate)) {
            return (
               <div className='flex items-center'>
                  <div className='flex-auto'>
                     <input
                        type='number'
                        className={classnames('min-w-0', {
                           error: showErrors && !predicate.value,
                        })}
                        value={predicate.value}
                        onChange={handleValueChange}
                     />
                  </div>
                  <span className='margin-left-s'>
                     {predicate.value === '1' ? 'day' : 'days'} ago
                  </span>
               </div>
            );
         }
      }
      return null;
   };

   const renderComparisonOptions = (
      comparisons: readonly {
         label: string;
         value: string;
         type?: string;
      }[],
   ): React.ReactNode => (
      <div className='filter-block-comparison-options'>
         {comparisons.map((i) => (
            <React.Fragment key={i.value}>
               <div className='flex items-center comparison-option' key={i.value}>
                  <input
                     checked={isChecked(i.value)}
                     id={i.value}
                     onChange={handleComparisonChange}
                     type='radio'
                     value={i.value}
                  />
                  <label htmlFor={i.value}>{i.label}</label>
               </div>
               {shouldRenderInput(i.value) && (
                  <div className='active-value-input' key='active-value-input'>
                     {renderActiveValueInput()}
                  </div>
               )}
            </React.Fragment>
         ))}
      </div>
   );

   const renderValueEditor = (): React.ReactNode => {
      if (predicate.type === PrimitiveType.string) {
         return renderComparisonOptions(STRING_COMPARISON_OPTIONS);
      } else if (predicate.type === PrimitiveType.integer) {
         return renderComparisonOptions(NUMERIC_COMPARISON_OPTIONS);
      } else if (predicate.type === PrimitiveType.boolean) {
         return renderComparisonOptions(BOOLEAN_COMPARISON_OPTIONS);
      } else if (predicate.type === PrimitiveType.date) {
         const relativeComparisonOptions = DATE_COMPARISON_OPTIONS.filter(
            (i) => i.type === 'relative',
         );
         const absoluteComparisonOptions = DATE_COMPARISON_OPTIONS.filter(
            (i) => i.type === 'absolute',
         );
         const noInputComparisonsOptions = DATE_COMPARISON_OPTIONS.filter((i) =>
            i.value.includes('known'),
         );
         return (
            <div>
               <div className='title'>Relative</div>
               {renderComparisonOptions(relativeComparisonOptions)}
               <div className='title'>Absolute</div>
               {renderComparisonOptions(absoluteComparisonOptions)}
               {renderComparisonOptions(noInputComparisonsOptions)}
            </div>
         );
      }
      return null;
   };

   return (
      <div className='card filter-block-options flex-auto flex flex-col'>
         {renderValueEditor()}
         <Button onClick={onClose} fullWidth className='margin-top-s'>
            Done
         </Button>
      </div>
   );
};

export default FilterBlockOptions;
