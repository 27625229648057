import * as React from 'react';

import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import indexDeep from '@helpers/IndexDeep';
import { randomTempId } from '@helpers/RandomStringUtils';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconAddLarge from '@icons/nova-solid/27-Remove&Add/add-square-1.svg';
import {
   GroupingPromptCategory as GroupingPromptCategoryType,
   GroupingPromptItem as GroupingPromptItemType,
} from '@models/Activity';
import Tippy from '@tippyjs/react';
import {
   Draggable,
   DraggableProvided,
   DraggableStateSnapshot,
   Droppable,
   DroppableProvided,
} from 'react-beautiful-dnd';

interface GroupingPromptCategoryProps {
   category: GroupingPromptCategoryType;
   items: readonly GroupingPromptItemType[];
   index: number;
   editCategory(update: Partial<GroupingPromptCategoryType>): void;
   setItems(items: readonly GroupingPromptItemType[]): void;
   removeCategory(): void;
}

const GroupingPromptCategory: React.FC<GroupingPromptCategoryProps> = ({
   category: { id, name },
   index,
   items,
   setItems,
   editCategory,
   removeCategory,
}) => {
   const [editingName, setIsEditingName] = React.useState<boolean>(false);

   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const handleItemChange = (itemId: string | number, data: string): void => {
      const updatedItems = items.map((i) => (i.id === itemId ? { ...i, data } : i));
      setItems(updatedItems);
   };

   const handleAddItem = (): void => {
      const item = {
         id: randomTempId(),
         categoryId: id,
         data: '',
         index: items.length,
      };
      const updatedItems = [...items, item];
      indexDeep(updatedItems);
      setItems(updatedItems);
   };

   const removeItem = (itemId: string | number): void => {
      const updatedItems = items.filter((i) => i.id !== itemId);
      indexDeep(updatedItems);
      setItems(updatedItems);
   };

   const handleNameEdit = (event: React.ChangeEvent<HTMLInputElement>): void => {
      editCategory({ name: event.target.value });
   };

   const handleNameFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   const handleNameBlur = (): void => {
      setIsEditingName(false);
   };

   const handleHeaderClick = (): void => {
      setIsEditingName(true);
   };

   return (
      <Draggable draggableId={id.toString()} index={index} key={id}>
         {(columnDragProvided: DraggableProvided, columnDragSnapshot: DraggableStateSnapshot) => (
            <div
               ref={columnDragProvided.innerRef}
               className='dnd-column-container'
               {...columnDragProvided.draggableProps}
            >
               <div className='dnd-column-heading editable' {...columnDragProvided.dragHandleProps}>
                  {editingName ? (
                     <input
                        type='text'
                        value={name}
                        placeholder='Untitled Group'
                        autoFocus
                        onChange={handleNameEdit}
                        onFocus={handleNameFocus}
                        onBlur={handleNameBlur}
                     />
                  ) : (
                     <div className='title large' onClick={handleHeaderClick}>
                        {name}
                     </div>
                  )}
                  {!columnDragSnapshot.isDragging && !!removeCategory && (
                     <Tippy content='Delete Group'>
                        <div className='icon-action'>
                           <IconBin onClick={removeCategory} />
                        </div>
                     </Tippy>
                  )}
               </div>
               <Droppable droppableId={id.toString()} type='ITEM'>
                  {(itemDropProvided: DroppableProvided) => (
                     <div className='dnd-column-items-wrapper' {...itemDropProvided.droppableProps}>
                        <div
                           className='dnd-column-items grouping-column-items'
                           ref={itemDropProvided.innerRef}
                        >
                           {items.map((item, itemIndex) => (
                              <Draggable
                                 key={item.id}
                                 draggableId={item.id.toString()}
                                 index={itemIndex}
                              >
                                 {(itemDragProvided: DraggableProvided) => (
                                    <div
                                       className='dnd-item'
                                       ref={itemDragProvided.innerRef}
                                       {...itemDragProvided.draggableProps}
                                       {...itemDragProvided.dragHandleProps}
                                    >
                                       <Editor
                                          className='no-p-margins'
                                          language={language}
                                          onChange={(value) => handleItemChange(item.id, value)}
                                          toolbarAppearance={ToolbarAppearance.floating}
                                          config={{
                                             toolbar:
                                                'bold italic underline strikethrough forecolor language tooltip',
                                          }}
                                          value={item.data}
                                       />
                                       <div className='delete-row-icon'>
                                          <IconCloseSmall
                                             aria-label='Delete'
                                             className='delete'
                                             onClick={() => removeItem(item.id)}
                                          />
                                       </div>
                                    </div>
                                 )}
                              </Draggable>
                           ))}
                           {itemDropProvided.placeholder}
                        </div>
                        <div className='dnd-column-new-item-wrapper' onClick={handleAddItem}>
                           <IconAddLarge aria-hidden />
                           Add Item
                        </div>
                     </div>
                  )}
               </Droppable>
            </div>
         )}
      </Draggable>
   );
};

export default React.memo(GroupingPromptCategory);
