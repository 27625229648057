import * as React from 'react';

import { BasicLevelName } from '@models/Proficiency';
import classNames from 'classnames';

import LevelIndicator from './LevelIndicator';

interface Props {
   level: BasicLevelName;
}

const LevelTag: React.FC<Props> = ({ level }) => {
   const colors = {
      Novice: 'blue',
      Intermediate: 'green',
      Advanced: 'yellow',
   };

   return (
      <div className='margin-left-s flex'>
         <div className={classNames('justify-center strong-text text-xs', `${colors[level]}-text`)}>
            {level}
         </div>
         <LevelIndicator level={level} className='margin-left-5' />
      </div>
   );
};

export default LevelTag;
