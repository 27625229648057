import * as React from 'react';

import FilterDropDown, { Option } from '@components/Core/FilterDropDown';
import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import { ProficiencyLevelConstants, ProficiencyLevelName } from '@models/Proficiency';
import CourseService from '@services/CourseService';

interface ProficiencyReportHeaderProps {
   courseId?: number | string;
   reportName: string;
   courseSectionChanged?(selectedOptions: Option[]): void;
   proficiencyLevelChanged(selectedLevel: ProficiencyLevelName | undefined): void;
}

const ProficiencyReportHeader: React.FC<ProficiencyReportHeaderProps> = ({
   courseId,
   reportName,
   courseSectionChanged,
   proficiencyLevelChanged,
}) => {
   const [courseSections, setCourseSections] = React.useState<Option[]>([]);

   const fetchCourseSections = () => {
      if (!courseId) {
         return;
      }

      CourseService.getCourseSections(courseId).then((r) => {
         const courseSectionOptions: Option[] = r.courseSections.map((x) => ({
            ...x,
            isChecked: false,
            weight: 1,
         }));

         courseSectionOptions.unshift({
            id: -1,
            name: 'No Section',
            isChecked: false,
            weight: 0,
         });

         setCourseSections(courseSectionOptions);
      });
   };

   const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newLevelValue = (event.target.value as ProficiencyLevelName) || undefined;

      proficiencyLevelChanged(newLevelValue);
   };

   const handleSectionChange = (options: Option[]): void => {
      if (!courseSectionChanged) {
         return;
      }

      setCourseSections(options);
      courseSectionChanged(options.filter((i) => i.isChecked));
   };

   React.useEffect(() => {
      fetchCourseSections();
   }, [courseId]);

   return (
      <div className='space-between'>
         <h1>{reportName}</h1>
         <div className='flex flex-col justify-center col-sm-4'>
            <div className='flex full-width'>
               <select
                  className='no-margin-top'
                  data-test='proficiency-report-level-filter'
                  onChange={handleLevelChange}
               >
                  <option value={''}>All Levels</option>
                  {ProficiencyLevelConstants.map((x) => (
                     <option key={x} value={x}>
                        {x}
                     </option>
                  ))}
               </select>
               <FilterDropDown
                  className='proficiency-report-sections-filter'
                  icon={<IconContentFilter aria-hidden />}
                  onSelectionChange={handleSectionChange}
                  options={courseSections}
               />
            </div>
         </div>
      </div>
   );
};

export default ProficiencyReportHeader;
