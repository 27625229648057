/* eslint-disable complexity */
// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import { faker } from '@faker-js/faker';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import { randomShortId } from '@helpers/RandomStringUtils';
import useCommands from '@hooks/use-commands';
import EmailSend1 from '@icons/nova-line/06-Email/email-send-1.svg';
import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconCalendar2 from '@icons/nova-solid/05-Time/calendar-2.svg';
import IconEmailEnvelope from '@icons/nova-solid/06-Email/email-envelope.svg';
import IconUserAdd from '@icons/nova-solid/07-Users/user-add.svg';
import IconAdd1 from '@icons/nova-solid/27-Remove&Add/add-1.svg';
import IconRemove1 from '@icons/nova-solid/27-Remove&Add/remove-1.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { Breadcrumb } from '@models/Breadcrumbs';
import { IdName, Maybe, MessageResponse } from '@models/Core';
import {
   CourseEnrollment,
   CourseInvite,
   CourseLMSConnection,
   CourseSection,
   isEnrollment,
   isInvite,
   RosterRecord,
} from '@models/Course';
import CourseRosterEnrollmentService, { NationalExam } from '@services/CourseEnrollmentService';
import CourseService from '@services/CourseService';
import DateTime from '@services/DateTimeService';
import HttpService from '@services/HttpService';
import NationalExamService from '@services/NationalExamService';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import Axios from 'axios';
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import Avatar from '@components/Core/Avatar';
import DateTimePicker from '@components/Core/DateTimePicker';
import Droplist, { IMenuItem } from '@components/Core/Droplist';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import CourseStrings from '@components/Course/CourseStrings';
import AddToRosterModal from './AddToRosterModal';

type CourseRosterParams = {
   courseId: string;
};

const CourseRoster: React.FC = () => {
   const {
      routes: {
         courses: { dashboard: dashboardRoute, roster: rosterRoute },
         enrollments: enrollmentsRoute,
      },
   } = Constants;

   const {
      setBreadcrumbs,
      userProfile: { isAdmin },
   } = React.useContext<AppStateContext>(AppStateContext);

   const pageParams = useParams<CourseRosterParams>();

   const [canModifyRoster, setCanModifyRoster] = React.useState<boolean>(false);
   const [courseCode, setCourseCode] = React.useState<string>('');
   const [courseId, setCourseId] = React.useState<number>(parseInt(pageParams.courseId));
   const [courseName, setCourseName] = React.useState<string>('');
   const [changeSectionSelection, setChangeSectionSelection] = React.useState<readonly IdName[]>(
      [],
   );
   const [excludeDemoStudents, setExcludeDemoStudents] = React.useState<boolean>(true);
   const [filteredKeys, setFilteredKeys] = React.useState<readonly string[]>([]);
   const [instructorCourseCode, setInstructorCourseCode] = React.useState<string>('');
   const [isAddUsersModalOpen, setIsAddUsersModalOpen] = React.useState<boolean>(false);
   const [isArchived, setIsArchived] = React.useState<boolean>(false);
   const [isDemo, setIsDemo] = React.useState<boolean>(false);
   const [isExtendingTrials, setIsExtendingTrials] = React.useState<boolean>(false);
   const [isExtendTrialsModalOpen, setIsExtendTrialsModalOpen] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const [isRemoveUsersModalOpen, setIsRemoveUsersModalOpen] = React.useState<boolean>(false);
   const [isRemovingUsers, setIsRemovingUsers] = React.useState<boolean>(false);
   const [isResendingInvites, setIsResendingInvites] = React.useState<boolean>(false);
   const [isResendingAccountConfirmationEmails, setIsResendingAccountConfirmationEmails] =
      React.useState<boolean>(false);
   const [isSettingExamCategory, setIsSettingExamCategory] = React.useState<boolean>(false);
   const [lmsConnections, setLmsConnections] = React.useState<readonly CourseLMSConnection[]>([]);
   const [nationalExam, setNationalExam] = React.useState<NationalExam>({
      categories: [],
      seatsPurchased: 0,
      seatsUsed: 0,
      isPracticeExam: false,
      nameAbbr: null,
   });
   const [newTrialEndOn, setNewTrialEndOn] = React.useState<Maybe<Date>>(null);
   const [organizationId, setOrganizationId] = React.useState<Maybe<number>>(null);
   const [roleFilter, setRoleFilter] = React.useState<AccountType | ''>(AccountType.student);
   const [roster, setRoster] = React.useState<readonly RosterRecord[]>([]);
   const [schoolDomainRestricted, setSchoolDomainRestricted] = React.useState<boolean>(false);
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [sections, setSections] = React.useState<readonly CourseSection[]>([]);
   const [selectedKeys, setSelectedKeys] = React.useState<readonly string[]>([]);
   const [showNoCategories, setShowNoCategories] = React.useState<boolean>(false);
   const [showNoSection, setShowNoSection] = React.useState<boolean>(true);
   const [statusFilter, setStatusFilter] = React.useState<'enrolled' | 'invited' | ''>('');

   React.useEffect(() => {
      const courseIdTemp = parseInt(pageParams.courseId);
      setCourseId(courseIdTemp);
      if (courseIdTemp) {
         fetchRoster();
      }
   }, [pageParams]);

   React.useEffect(() => {
      if (!isFetching) {
         calculateFilteredKeys();
      }
   }, [
      excludeDemoStudents,
      isFetching,
      nationalExam,
      roleFilter,
      roster,
      searchQuery,
      sections,
      showNoCategories,
      showNoSection,
      statusFilter,
   ]);

   const openAddUsersModal = (): void => {
      if (canModifyRoster) {
         setIsAddUsersModalOpen(true);
      }
   };

   const openRemoveUsersModal = (): void => {
      if (canModifyRoster) {
         setIsRemoveUsersModalOpen(true);
      }
   };

   useCommands(
      [
         {
            id: 'add_invite',
            title: 'Add/Invite to Course',
            action: () => openAddUsersModal(),
            showIf: () => canModifyRoster,
         },
      ],
      [canModifyRoster],
   );

   const openExtendTrialsModal = (): void => {
      if (isAdmin) {
         setIsExtendTrialsModalOpen(true);
         setNewTrialEndOn(moment(DateTime.midnight()).add(1, 'week').toDate());
      }
   };

   const hasNonEnrolledStudentSelected = (): boolean => {
      const selectedStudents = _.chain(roster).keyBy('key').at(selectedKeys).value();

      return selectedStudents.some(isInvite);
   };

   const hasUnlicensedStudentsSelected = (): boolean => {
      const selectedStudents = _.chain(roster).keyBy('key').at(selectedKeys).value();
      return selectedStudents.some((i) => isEnrollment(i) && !!i.trialEndOn);
   };

   const hasUnauthenticatedUsersSelected = (): boolean => {
      const selectedStudents = _.chain(roster).keyBy('key').at(selectedKeys).value();
      return selectedStudents.some((i) => isEnrollment(i) && !i.authenticated);
   };

   const resendConfirmationEmails = async (): Promise<void> => {
      const selectedStudents = _.chain(roster).keyBy('key').at(selectedKeys).value();
      const selectedStudentIds = selectedStudents
         .filter(isEnrollment)
         .filter((x) => !x.authenticated)
         .map((x) => x.userId);

      setIsResendingAccountConfirmationEmails(true);
      await UserService.sendConfirmationEmails(selectedStudentIds);
      setIsResendingAccountConfirmationEmails(false);
   };

   const resendSelectedInvites = (): void => {
      if (!isResendingInvites) {
         setIsResendingInvites(true);
         const selectedStudents = _.chain(roster).keyBy('key').at(selectedKeys).value();

         const nonEnrolledStudentEmails = selectedStudents
            .filter(isInvite)
            .map(({ email }) => ({ email }));
         CourseService.sendInvites(courseId, nonEnrolledStudentEmails).then(() => {
            setIsResendingInvites(false);
            setSelectedKeys([]);
         });
      }
   };

   const extendSelectedTrials = (): void => {
      if (!isExtendingTrials) {
         setIsExtendingTrials(true);
         const selectedUserIds = _.chain(roster)
            .keyBy('key')
            .at(selectedKeys)
            .value()
            .filter((i) => isEnrollment(i) && !!i.trialEndOn)
            .map((i: CourseEnrollment) => i.userId);
         CourseService.updateTrialEndOn(courseId, selectedUserIds, newTrialEndOn).then(() => {
            setIsExtendingTrials(false);
            setIsExtendTrialsModalOpen(false);
            setRoster((prevRoster) =>
               prevRoster.map((i) =>
                  isEnrollment(i) && selectedUserIds.includes(i.userId)
                     ? { ...i, trialEndOn: newTrialEndOn }
                     : i,
               ),
            );
            setSelectedKeys([]);
         });
      }
   };

   const closeAddUsersModal = (): void => {
      setIsAddUsersModalOpen(false);
   };

   const closeRemoveUsersModal = (): void => {
      setIsRemoveUsersModalOpen(false);
   };

   const closeExtendTrialsModal = (): void => {
      setIsExtendTrialsModalOpen(false);
      setNewTrialEndOn(null);
   };

   const resetStudentCourseCode = (): Promise<void> =>
      CourseService.resetStudentCourseCode(courseId).then((updatedCourseCode) => {
         setCourseCode(updatedCourseCode);
      });

   const resetSectionCourseCode = (sectionId: string | number): Promise<void> =>
      CourseService.resetSectionCourseCode(courseId, sectionId).then((updatedCourseCode) => {
         setSections((prevSections) =>
            prevSections.map((i) =>
               i.id === sectionId ? { ...i, courseCode: updatedCourseCode } : i,
            ),
         );
      });

   const resetInstructorCourseCode = (): Promise<void> =>
      CourseService.resetInstructorCourseCode(courseId).then((updatedInstructorCourseCode) => {
         setInstructorCourseCode(updatedInstructorCourseCode);
      });

   const fetchRoster = (): void => {
      setIsFetching(true);
      setIsAddUsersModalOpen(false);
      setIsRemoveUsersModalOpen(false);
      CourseRosterEnrollmentService.getRoster(courseId).then(async (data) => {
         let patchedRoster = [
            ...data.invites.map((i) => ({ ...i, key: randomShortId() })),
            ...data.enrollments.map((i) => ({ ...i, key: randomShortId() })),
         ];
         const fakeNames = await UserService.checkFeature('fake_names');
         if (fakeNames) {
            patchedRoster = patchedRoster.map((i) => ({
               ...i,
               firstName: faker.name.firstName(),
               lastName: faker.name.lastName(),
               email: faker.internet.email(),
            }));
         }
         setCourseCode(data.courseCode);
         setCourseName(data.name);
         setCanModifyRoster(data.permissions.canModifyRoster);
         setInstructorCourseCode(data.instructorCourseCode);
         setIsArchived(data.archived);
         setIsDemo(data.demo);
         setLmsConnections(data.lmsConnections);
         setNationalExam(data.nationalExam);
         setRoster(
            _.sortBy(patchedRoster, [
               (i) => (i.firstName || '').toLowerCase(),
               (i) => (i.lastName || '').toLowerCase(),
               'email',
            ]),
         );
         setSchoolDomainRestricted(data.schoolDomainRestricted);
         setOrganizationId(data.organizationId);
         setSections(data.sections);
         setIsFetching(false);
         const breadcrumbs: readonly Breadcrumb[] = [
            {
               link: dashboardRoute.replace(':courseId', courseId.toString()),
               text: data.name,
               contextInfo: { courseId, courseArchived: data.archived },
            },
            {
               link: rosterRoute.replace(':courseId', courseId.toString()),
               text: 'Course Roster',
            },
         ];
         setBreadcrumbs({ breadcrumbs, next: null, prev: null });
      });
   };

   const updateRoster = (
      updatedInvites: readonly CourseInvite[],
      updatedEnrollments: readonly CourseEnrollment[],
   ): void => {
      setRoster(
         _.sortBy(
            [
               ...updatedInvites.map((i) => ({ ...i, key: randomShortId() })),
               ...updatedEnrollments.map((i) => ({
                  ...i,
                  key: randomShortId(),
               })),
            ],
            [
               (i) => (i.firstName || '').toLowerCase(),
               (i) => (i.lastName || '').toLowerCase(),
               'email',
            ],
         ),
      );
   };

   const handleNewTrialEndOnChange = (updatedNewTrialEndOn: Date): void => {
      setNewTrialEndOn(updatedNewTrialEndOn);
   };

   const toggleSelectAll = (): void => {
      setSelectedKeys((prevSelectedKeys) =>
         prevSelectedKeys.length === filteredKeys.length ? [] : [...filteredKeys],
      );
   };

   const toggleSelect = (key: string): void => {
      setSelectedKeys((prevSelectedKeys) =>
         prevSelectedKeys.includes(key)
            ? prevSelectedKeys.filter((k) => k !== key)
            : [...prevSelectedKeys, key],
      );
   };

   const setExamCategories = (examLevelCategoryId: number): Promise<void> => {
      const inviteOrEnrollmentEmails: string[] = [];
      const updatedRoster = [...roster];
      selectedKeys.forEach((key) => {
         const selectedUser = updatedRoster.find(({ key: k }) => k === key);
         selectedUser.examLevelCategoryId = examLevelCategoryId;
         inviteOrEnrollmentEmails.push(selectedUser.email);
      });

      setIsSettingExamCategory(true);

      return HttpService.putWithAuthToken<{
         msg: string;
         examSeatsUsed: number;
      }>(
         `/api/courses/${courseId}/assign_exam_category`,
         snakeCaseKeys({ inviteOrEnrollmentEmails, examLevelCategoryId }),
      ).then((response) => {
         const { examSeatsUsed: seatsUsed } = response.data;
         setRoster(updatedRoster);
         setIsSettingExamCategory(false);
         setNationalExam((prevNationalExam) => ({
            ...prevNationalExam,
            seatsUsed,
         }));
         setSelectedKeys([]);
      });
   };

   const removeSelected = (): Promise<void> => {
      const requests = [];
      setIsRemovingUsers(true);
      selectedKeys.forEach((key) => {
         const rosterRecord = roster.find(({ key: k }) => k === key);
         if (isEnrollment(rosterRecord) && rosterRecord.accountType === AccountType.instructor) {
            const instructorCount = roster.filter(
               (i) => isEnrollment(i) && i.accountType === AccountType.instructor,
            ).length;
            if (instructorCount < 2) {
               return;
            }
         }
         let url = '';
         if (isEnrollment(rosterRecord)) {
            url = `/api/courses/${courseId}/roster/${rosterRecord.enrollmentId}`;
         } else if (isInvite(rosterRecord) && rosterRecord.inviteId) {
            url = `/api/courses/${courseId}/invites/${rosterRecord.inviteId}`;
         }
         requests.push(HttpService.deleteWithAuthToken<MessageResponse>(url));
      });
      return Axios.all(requests).then(() => {
         setSelectedKeys([]);
         setFilteredKeys((prevFilteredKeys) =>
            prevFilteredKeys.filter((key) => !selectedKeys.includes(key)),
         );
         setIsRemovingUsers(false);
         setRoster((prevRoster) => prevRoster.filter(({ key }) => !selectedKeys.includes(key)));
         closeRemoveUsersModal();
      });
   };

   const changeSections = (changedSections: readonly IdName[]): Promise<void> => {
      const enrollmentIds: number[] = [];
      const inviteIds: number[] = [];
      const updatedRoster = [...roster];
      const filteredSections = changedSections.filter(
         (cs) => !isNaN(typeof cs.id === 'number' ? cs.id : parseInt(cs.id)),
      );
      const sectionIds = filteredSections.map((fs) => Number(fs.id));

      selectedKeys.forEach((key) => {
         const selectedUser = updatedRoster.find(({ key: k }) => k === key);
         selectedUser.sections = filteredSections;

         if (isEnrollment(selectedUser)) {
            enrollmentIds.push(selectedUser.enrollmentId);
         } else if (isInvite(selectedUser)) {
            inviteIds.push(selectedUser.inviteId);
         }
      });

      return CourseRosterEnrollmentService.assignSections(
         courseId,
         enrollmentIds,
         inviteIds,
         sectionIds,
      ).then(() => {
         setRoster(updatedRoster);
      });
   };

   const changeSectionsFilter = (show: boolean, sectionId: number, _sectionName: string): void => {
      if (isNaN(sectionId)) {
         setShowNoSection((prevShowNoSection) => !prevShowNoSection);
         return;
      }

      const url = `/api/courses/${courseId}/sections/${sectionId}`;
      HttpService.patchWithAuthToken<MessageResponse>(url, snakeCaseKeys({ show }));
      setSections((prevSections) =>
         prevSections.map((i) => (i.id === sectionId ? { ...i, show } : i)),
      );
   };

   const changeCategoriesFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.id === 'none') {
         setShowNoCategories((prevShowNoCategories) => !prevShowNoCategories);
         return;
      }
      const examLevelCategoryId = isInteger(event.target.id) ? Number(event.target.id) : null;
      const show = event.target.checked;
      setNationalExam((prevNationalExam) => ({
         ...prevNationalExam,
         categories: prevNationalExam.categories.map((i) =>
            i.id === examLevelCategoryId ? { ...i, show } : i,
         ),
      }));
   };

   const emptyStateFactory = (): Maybe<React.ReactNode> => {
      const studentCount = roster.filter(
         ({ accountType }) => accountType === AccountType.student,
      ).length;
      if (filteredKeys.length) {
         return null;
      }
      if (!studentCount && roleFilter === AccountType.student) {
         return (
            <EmptyState
               icon={<IconUserAdd aria-hidden />}
               heading='No students have been enrolled yet.'
               description={
                  <p className='gray small padding-bottom-s'>
                     Click the <strong>+ Add/Invite to Roster</strong> Button to invite students to
                     join your course.
                  </p>
               }
            />
         );
      }
      if (roster.length) {
         return (
            <EmptyState
               icon={<IconBinoculars aria-hidden />}
               heading='No Results'
               description='Try changing your filters.'
            />
         );
      }
      return null;
   };

   const calculateFilteredKeys = (): void => {
      const { categories } = nationalExam;
      const sectionIds = sections.filter((i) => i.show).map((i) => i.id);

      const examLevelCategoryId = categories.filter((i) => i.show).map((i) => i.id);
      if (showNoCategories) {
         examLevelCategoryId.push(null);
      }

      const filterExcludeDemoStudents = (i: RosterRecord): boolean =>
         !excludeDemoStudents || isInvite(i) || (isEnrollment(i) && !i.excludeFromCalc);
      const filterRole = (i: RosterRecord): boolean =>
         roleFilter === '' || roleFilter === i.accountType;
      const filterSections = (i: RosterRecord): boolean => {
         const noFilter = !sectionIds.length && !showNoSection;
         const noSection = !i.sections.length && showNoSection;

         if (noFilter || noSection) {
            return true;
         }

         return i.sections.filter((section) => sectionIds.includes(section.id)).length > 0;
      };
      const filterCategories = (i: RosterRecord): boolean =>
         !examLevelCategoryId.length || examLevelCategoryId.includes(i.examLevelCategoryId);
      const filterSearchQuery = (i: RosterRecord): boolean =>
         !searchQuery ||
         (i.firstName && i.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
         (i.lastName && i.lastName.toLowerCase().includes(searchQuery.toLowerCase())) ||
         (i.email && i.email.toLowerCase().includes(searchQuery.toLowerCase()));
      const filterStatus = (i: RosterRecord): boolean =>
         statusFilter === '' ||
         (statusFilter === 'enrolled' && isEnrollment(i)) ||
         (statusFilter === 'invited' && isInvite(i));

      const updatedFilteredKeys = roster
         .filter(
            (i) =>
               filterExcludeDemoStudents(i) &&
               filterRole(i) &&
               filterSearchQuery(i) &&
               filterSections(i) &&
               filterCategories(i) &&
               filterStatus(i),
         )
         .map(({ key }) => key);

      setFilteredKeys(updatedFilteredKeys);
   };

   const sectionDroplistItemFactory = (): readonly IMenuItem[] =>
      [{ id: null, name: 'None' }, ...sections].map(({ name, id }) => ({
         text: name,
         onClick: () => changeSections([{ id: id as number, name }]),
      }));

   const examCategoryDroplistItemFactory = (): readonly IMenuItem[] =>
      [{ id: null, levelCatName: 'None' }, ...nationalExam.categories].map(
         ({ levelCatName, id }) => ({
            text: levelCatName,
            onClick: () => setExamCategories(id as number),
         }),
      );

   const getSectionNames = (userSections: readonly IdName[]): string =>
      userSections.map((section) => section.name).join(', ');

   const getCategoryName = (examLevelCategoryId: number): string =>
      examLevelCategoryId
         ? nationalExam.categories.find(({ id }) => id === examLevelCategoryId).levelCatName
         : '';

   const removeRosterEntriesByEmail = (emails: readonly string[]): void =>
      setRoster((prevRoster) => prevRoster.filter(({ email }) => !emails.includes(email)));

   const handleFilterChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      if (name === 'searchQuery') {
         setSearchQuery(value);
      } else if (name === 'statusFilter') {
         setStatusFilter(value as 'enrolled' | 'invited' | '');
      } else if (name === 'roleFilter') {
         setRoleFilter(value as AccountType | '');
      } else if (name === 'excludeFilter') {
         setExcludeDemoStudents(value === 'exclude');
      } else {
         console.error(`Unexpected filter name ${name}`);
      }
   };

   const handleChangeSectionSelection = (checked: boolean, id: number, name: string): void => {
      const selectedIsNan = isNaN(id);
      let changedSections = changeSectionSelection.filter((sect) => {
         if (selectedIsNan) {
            return false;
         } else if (isNaN(typeof sect.id === 'number' ? sect.id : parseInt(sect.id))) {
            return false;
         }

         return sect.id !== id;
      });

      if (checked && !isNaN(id)) {
         changedSections = [...changedSections, { id, name }];
      } else if (checked && isNaN(id)) {
         changedSections = [{ id, name }];
      }

      setChangeSectionSelection(changedSections);
      changeSections(changedSections);
   };

   const renderSectionItems = (
      onChange: (checked: boolean, id: number, name: string) => void,
      checkedState: boolean | readonly IdName[] = false,
   ): readonly React.ReactElement[] => {
      const sects = [...sections];
      const orderedSections: (Omit<CourseSection, 'id'> & { id: number | string })[] = _.orderBy(
         sects,
         ['name'],
      );

      orderedSections.unshift({
         id: 'none',
         show: !checkedState ? showNoSection : false,
         name: 'No Section',
         courseCode: 'none',
      });

      return orderedSections.map(({ id, show, name }) => {
         let isChecked = show;

         if (Array.isArray(checkedState)) {
            isChecked = !!checkedState.find((cs) => cs.id === id);
         }

         return (
            <div
               className='show-module-entry change-sections'
               key={id}
               data-test={`drop-list-item-${name.toLowerCase()}`}
            >
               <input
                  checked={isChecked}
                  className='ignore-click'
                  id={id.toString()}
                  onChange={(e) => {
                     onChange(e.target.checked, id as number, name);
                  }}
                  type='checkbox'
               />
               <label className='ignore-click' htmlFor={id.toString()}>
                  {name}
               </label>
            </div>
         );
      });
   };

   const renderFilterCategoryItems = (): readonly React.ReactElement[] =>
      [
         { id: 'none', show: showNoCategories, levelCatName: 'Not set' },
         ...nationalExam.categories,
      ].map(({ id, show, levelCatName }) => (
         <div className='show-module-entry' key={id}>
            <input
               checked={!!show}
               className='ignore-click'
               id={id.toString()}
               onChange={changeCategoriesFilter}
               type='checkbox'
            />
            <label
               className='ignore-click'
               data-test={`course-roster-exam-category-option_${levelCatName}`}
               htmlFor={id.toString()}
            >
               {levelCatName}
            </label>
         </div>
      ));

   const renderNameCell = (row: RosterRecord): React.ReactNode => {
      if (row.firstName && row.lastName) {
         const enrollmentLink = isEnrollment(row)
            ? enrollmentsRoute.byId.replace(':enrollmentId', `${row.enrollmentId}?ref=roster`)
            : null;
         return (
            <div className='roster-name'>
               <Avatar
                  firstName={row.firstName}
                  lastName={row.lastName}
                  src={isEnrollment(row) ? row.profileImageUrl : ''}
                  hashValue={isEnrollment(row) ? row.userId : row.inviteId}
                  size='large'
               />
               {row.accountType === AccountType.student && isEnrollment(row) ? (
                  <Link to={enrollmentLink}>{`${row.firstName} ${row.lastName}`}</Link>
               ) : (
                  `${row.firstName} ${row.lastName}`
               )}
            </div>
         );
      }
      return <></>;
   };

   const renderCategoryCell = (row: RosterRecord): string => {
      if (!row.examLevelCategoryId) {
         return '';
      } else {
         return nationalExam.categories.find((x) => x.id === row.examLevelCategoryId).levelCatName;
      }
   };

   const renderSelect = (row: RosterRecord): React.ReactNode => (
      <input
         type='checkbox'
         data-test='course-roster-table-select'
         checked={selectedKeys.includes(row.key)}
         onChange={() => toggleSelect(row.key)}
      />
   );

   const renderSelectAll = (): React.ReactNode => {
      const checkSelectAll =
         roster.length && filteredKeys.length && selectedKeys.length === filteredKeys.length;
      return <input type='checkbox' onChange={toggleSelectAll} checked={checkSelectAll} />;
   };

   const renderDateCell = (date: Date): React.ReactNode | string => {
      if (!date) {
         return '';
      }
      return (
         <Tippy content={moment(date).format('lll')}>
            <span className={classnames({ past: date < DateTime.now() })}>
               {moment(date).fromNow()}
            </span>
         </Tippy>
      );
   };

   const renderStatusCell = (row: RosterRecord): React.ReactNode | string => {
      if (isEnrollment(row)) {
         return 'Enrolled';
      } else if (isInvite(row)) {
         if (row?.emailInvite) {
            if (row.emailInvite.bouncedOn) {
               return (
                  <Tippy content={row.emailInvite.bounceDescription}>
                     <span className='error'>Invite Bounced</span>
                  </Tippy>
               );
            } else if (row.emailInvite.openedOn) {
               return (
                  <Tippy content={moment(row.emailInvite.openedOn).format('lll')}>
                     <span>Invite Opened</span>
                  </Tippy>
               );
            } else if (row.emailInvite.deliveredOn) {
               return (
                  <Tippy content={moment(row.emailInvite.deliveredOn).format('lll')}>
                     <span>Invite Delivered</span>
                  </Tippy>
               );
            }
         }
         return 'Invite Sent';
      }
      return '';
   };

   if (isFetching) {
      return <Loader />;
   }

   const numSelectedNewSeats = selectedKeys.filter((key) => {
      const { examLevelCategoryId } = roster.find(({ key: k }) => k === key);
      return !examLevelCategoryId;
   }).length;

   const existingEmails = roster.map(({ email }) => email);
   const filteredRows = roster.filter((i) => filteredKeys.includes(i.key));
   const hasDemoStudents = roster.some((i) => isEnrollment(i) && i.excludeFromCalc);
   const hasSections = !!sections.length;
   const lmsName = lmsConnections.length ? lmsConnections[0].lmsName : null;
   const nonIdealState = emptyStateFactory();
   const disabledTooltipText =
      !canModifyRoster && CourseStrings.getDisabledRosterTooltip(isArchived, isDemo);
   const seatsRemaining =
      nationalExam.seatsPurchased - nationalExam.seatsUsed - numSelectedNewSeats;
   const seatWord = pluralize('seat', seatsRemaining);
   const seatsRemainingMsg =
      seatsRemaining >= 0
         ? `${seatsRemaining} ${seatWord} left`
         : `${Math.abs(seatsRemaining)} ${seatWord} over capacity`;
   const selectedUserCount = selectedKeys.length;
   const studentClassificationWord = NationalExamService.getStudentClassificationWord(
      nationalExam.nameAbbr,
   );

   const columns: readonly Column<RosterRecord>[] = _.compact([
      canModifyRoster && {
         id: 'select',
         header: renderSelectAll,
         cell: renderSelect,
      },
      {
         id: 'name',
         header: 'Name',
         cell: renderNameCell,
         canSort: true,
         sortFunc: (i) => (i.lastName || '').toLowerCase(),
      },
      {
         id: 'email',
         header: 'Email Address',
         cell: (i) => i.email,
         canSort: true,
      },
      {
         id: 'category',
         header: `${nationalExam.nameAbbr} ${studentClassificationWord}`,
         cell: renderCategoryCell,
         canSort: true,
         sortFunc: (i) => getCategoryName(i.examLevelCategoryId),
         show: !!nationalExam.isPracticeExam,
      },
      {
         id: 'section',
         header: 'Section',
         cell: (i) => getSectionNames(i.sections),
         canSort: true,
         sortFunc: (i) => getSectionNames(i.sections),
         show: hasSections,
      },
      {
         id: 'role',
         header: 'Role',
         cell: (i) => _.startCase(i.accountType),
         canSort: true,
      },
      { id: 'status', header: 'Status', cell: renderStatusCell, canSort: true },
      {
         id: 'lastRefreshedOn',
         header: 'Last Activity',
         cell: (i) => (isEnrollment(i) ? renderDateCell(i.lastRefreshedOn) : ''),
         canSort: true,
      },
      isAdmin && {
         id: 'trialEndOn',
         header: 'Trial Ends',
         cell: (i) => (isEnrollment(i) ? renderDateCell(i.trialEndOn) : ''),
         canSort: true,
         cellClassName: 'trial-end-on',
      },
   ]);

   return (
      <>
         <DocumentTitle>
            {isFetching ? 'Loading Course...' : `Roster - ${courseName}`}
         </DocumentTitle>
         <div className='content-main margin-right-m' data-test='course-roster-container'>
            <div className='card no-padding'>
               {selectedKeys.length ? (
                  <div className='table-header filled blue'>
                     <div className='selected-count'>{`${selectedKeys.length} Selected`}</div>
                     <div className='right-options-wrapper'>
                        {nationalExam?.isPracticeExam && (
                           <Droplist pullRight items={examCategoryDroplistItemFactory()}>
                              <Button
                                 color='green'
                                 loading={isSettingExamCategory}
                                 data-test='course-roster-set-category-button'
                                 disabled={seatsRemaining < 0 || !canModifyRoster}
                              >
                                 Set {nationalExam.nameAbbr} {studentClassificationWord} (
                                 {seatsRemainingMsg})
                              </Button>
                           </Droplist>
                        )}
                        {!!sections.length && (
                           <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                              <div>
                                 <Droplist
                                    pullRight
                                    items={
                                       roleFilter === AccountType.instructor
                                          ? renderSectionItems(
                                               handleChangeSectionSelection,
                                               changeSectionSelection,
                                            )
                                          : sectionDroplistItemFactory()
                                    }
                                    onChange={(open): void => {
                                       if (!open) {
                                          setSelectedKeys([]);
                                          setChangeSectionSelection([]);
                                       }
                                    }}
                                 >
                                    <Button
                                       line
                                       data-test='course-roster-change-section-button'
                                       disabled={!canModifyRoster}
                                    >
                                       Change Section
                                    </Button>
                                 </Droplist>
                              </div>
                           </Tippy>
                        )}
                        {hasNonEnrolledStudentSelected() && (
                           <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                              <div>
                                 <Button
                                    color='green'
                                    disabled={!canModifyRoster}
                                    loading={isResendingInvites}
                                    onClick={resendSelectedInvites}
                                    icon={<IconEmailEnvelope aria-hidden />}
                                 >
                                    Resend Invites
                                 </Button>
                              </div>
                           </Tippy>
                        )}
                        {isAdmin && hasUnlicensedStudentsSelected() && (
                           <Button
                              color='purple'
                              onClick={openExtendTrialsModal}
                              icon={<IconCalendar2 aria-hidden />}
                           >
                              Extend Trials
                           </Button>
                        )}
                        {isAdmin && hasUnauthenticatedUsersSelected() && (
                           <Button
                              color='purple'
                              onClick={resendConfirmationEmails}
                              icon={<EmailSend1 aria-hidden />}
                              loading={isResendingAccountConfirmationEmails}
                           >
                              Resend Account Confirmation Email
                           </Button>
                        )}
                        <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                           <div>
                              <Button
                                 color='red'
                                 disabled={!canModifyRoster}
                                 onClick={openRemoveUsersModal}
                                 icon={<IconRemove1 aria-hidden />}
                              >
                                 Remove from Course
                              </Button>
                           </div>
                        </Tippy>
                     </div>
                  </div>
               ) : (
                  <div className='card-title has-button'>
                     <div className='flex items-center'>
                        <div className='title'>{courseName}</div>
                        {isArchived && (
                           <div className='lozenge dark-red margin-left-s'>Archived</div>
                        )}
                        {!isArchived && isDemo && (
                           <div className='lozenge blue margin-left-s'>Demo</div>
                        )}
                        <input
                           type='search'
                           name='searchQuery'
                           value={searchQuery}
                           placeholder='Search Roster'
                           onChange={handleFilterChange}
                        />
                     </div>
                     <div className='right-options-wrapper'>
                        <select
                           name='statusFilter'
                           value={statusFilter}
                           onChange={handleFilterChange}
                        >
                           <option value=''>Filter Status</option>
                           <option value='enrolled'>Enrolled</option>
                           <option value='invited'>Invited</option>
                        </select>
                        {nationalExam.isPracticeExam && (
                           <Droplist pullRight items={renderFilterCategoryItems()}>
                              <Button
                                 icon={<IconContentFilter aria-hidden />}
                                 line
                                 data-test='course-roster-filter-category-button'
                              >
                                 Filter {studentClassificationWord}
                              </Button>
                           </Droplist>
                        )}
                        {!!sections.length && (
                           <Droplist pullRight items={renderSectionItems(changeSectionsFilter)}>
                              <Button
                                 data-test='course-roster-section-filter'
                                 icon={<IconContentFilter aria-hidden />}
                                 line
                              >
                                 Filter Sections
                              </Button>
                           </Droplist>
                        )}
                        <select
                           data-test='role-filter'
                           name='roleFilter'
                           value={roleFilter}
                           onChange={handleFilterChange}
                        >
                           <option value=''>Filter Role</option>
                           <option value={AccountType.instructor}>Instructor</option>
                           <option value={AccountType.student}>Student</option>
                        </select>
                        {hasDemoStudents && (
                           <select
                              name='excludeFilter'
                              value={excludeDemoStudents ? 'exclude' : ''}
                              onChange={handleFilterChange}
                           >
                              <option value=''>Filter Demo</option>
                              <option value='exclude'>Non Demo</option>
                           </select>
                        )}
                        <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                           <div>
                              <Button
                                 disabled={!canModifyRoster}
                                 data-test='button-course-roster-add-or-invite'
                                 onClick={openAddUsersModal}
                                 icon={<IconAdd1 aria-hidden />}
                              >
                                 Add/Invite to Course
                              </Button>
                           </div>
                        </Tippy>
                     </div>
                  </div>
               )}
               {nonIdealState ? (
                  nonIdealState
               ) : (
                  <Table<RosterRecord>
                     className='sticky card-table'
                     dataTest='course-roster'
                     columns={columns}
                     rowKey='key'
                     rows={filteredRows}
                  />
               )}
            </div>
         </div>
         {isAddUsersModalOpen && (
            <AddToRosterModal
               courseCode={courseCode}
               courseId={courseId}
               existingEmails={existingEmails}
               instructorCourseCode={instructorCourseCode}
               lmsName={lmsName}
               roster={roster}
               schoolDomainRestricted={schoolDomainRestricted}
               organizationId={organizationId}
               sections={sections}
               onClose={closeAddUsersModal}
               removeRosterEntriesByEmail={removeRosterEntriesByEmail}
               resetStudentCourseCode={resetStudentCourseCode}
               resetSectionCourseCode={resetSectionCourseCode}
               resetInstructorCourseCode={resetInstructorCourseCode}
               updateRoster={updateRoster}
            />
         )}
         {isRemoveUsersModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Remove Users'
               onClose={closeRemoveUsersModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  {
                     text: 'Remove',
                     onClick: removeSelected,
                     loading: isRemovingUsers,
                  },
                  { text: 'Cancel', onClick: closeRemoveUsersModal },
               ]}
            >
               <p>
                  Are you sure that you want to remove {selectedUserCount}{' '}
                  {pluralize('user', selectedUserCount)} from your course?
               </p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
         {isExtendTrialsModalOpen && (
            <ModalDialog
               appearance={Appearance.primary}
               heading='Extend Trials'
               onClose={closeExtendTrialsModal}
               animations={{ enter: 'animated bounceInDown' }}
               bodyClassName='extend-trial-modal'
               actions={[
                  {
                     text: 'Extend',
                     onClick: extendSelectedTrials,
                     loading: isExtendingTrials,
                  },
                  { text: 'Cancel', onClick: closeExtendTrialsModal },
               ]}
            >
               <div>
                  <label className='field-title'>New Trial End On</label>
                  <DateTimePicker
                     value={newTrialEndOn}
                     onChange={handleNewTrialEndOnChange}
                     minDate='today'
                  />
               </div>
            </ModalDialog>
         )}
      </>
   );
};

export default CourseRoster;
