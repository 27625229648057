import * as React from 'react';

import Appearance from '@models/Appearance';

import ModalDialog from '@components/Core/ModalDialog';

interface DeleteObjectConfirmationModalProps {
   onCancel(): void;
   onDelete(): void;
   isLoading: boolean;
   objectName: string;
}

const DeleteObjectConfirmationModal: React.FC<DeleteObjectConfirmationModalProps> = (props) => (
   <ModalDialog
      animations={{ enter: 'animated bounceInDown' }}
      appearance={Appearance.danger}
      heading='Delete User'
      onClose={props.onCancel}
      actions={[
         {
            text: 'Delete',
            onClick: props.onDelete,
            loading: props.isLoading,
         },
         { text: 'Cancel', onClick: props.onCancel },
      ]}
   >
      <p>Are you sure that you want to delete "{props.objectName}"?</p>
      <p>This action cannot be undone.</p>
   </ModalDialog>
);

export default DeleteObjectConfirmationModal;
