import tinymce from 'tinymce';

tinymce.PluginManager.add('casechange', (editor) => {
   const minorWords = [
      'at',
      'by',
      'in',
      'of',
      'on',
      'up',
      'to',
      'en',
      're',
      'vs',
      'but',
      'off',
      'out',
      'via',
      'bar',
      'mid',
      'per',
      'pro',
      'qua',
      'til',
      'from',
      'into',
      'unto',
      'with',
      'amid',
      'anit',
      'atop',
      'down',
      'less',
      'like',
      'near',
      'over',
      'past',
      'plus',
      'sans',
      'save',
      'than',
      'thru',
      'till',
      'upon',
      'for',
      'and',
      'nor',
      'but',
      'or',
      'yet',
      'so',
      'an',
      'a',
      'some',
      'the',
   ];

   const toTitleCase = (str: string): string =>
      str.replace(/\b\w+/g, (word, index) => {
         if (index > 0 && minorWords.includes(word.toLowerCase())) {
            return word.toLowerCase();
         }
         return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

   const isSelectedTextCase = (caseType: string): boolean => {
      const selectedText = editor.selection.getContent({ format: 'text' });
      const isUpperCase = selectedText === selectedText.toUpperCase();
      const isLowerCase = selectedText === selectedText.toLowerCase();
      const isTitleCase = selectedText === toTitleCase(selectedText);

      switch (caseType) {
         case 'uppercase':
            return isUpperCase && !isLowerCase && !isTitleCase;
         case 'lowercase':
            return isLowerCase && !isUpperCase && !isTitleCase;
         case 'titlecase':
            return isTitleCase && !isLowerCase && !isUpperCase;
         default:
            return false;
      }
   };

   const convertTextCase = (caseType: string) => {
      const selectedContent = editor.selection.getContent({ format: 'html' });
      const parser = new DOMParser();
      const doc = parser.parseFromString(selectedContent, 'text/html');

      const traverseNodes = (node: Node) => {
         if (node.nodeType === Node.TEXT_NODE && !!node.textContent) {
            let newText = '';
            switch (caseType) {
               case 'uppercase':
                  newText = node.textContent.toUpperCase();
                  break;
               case 'lowercase':
                  newText = node.textContent.toLowerCase();
                  break;
               case 'titlecase':
                  newText = toTitleCase(node.textContent);
                  break;
            }
            if (newText) {
               node.textContent = newText;
            }
         } else {
            for (const child of Array.from(node.childNodes)) {
               traverseNodes(child);
            }
         }
      };

      traverseNodes(doc.body);

      const serializer = new XMLSerializer();
      const fragment = document.createDocumentFragment();
      Array.from(doc.body.childNodes).forEach((child) => {
         fragment.appendChild(child.cloneNode(true));
      });

      const newContent = serializer.serializeToString(fragment);
      editor.selection.setContent(newContent);
   };

   editor.ui.registry.addContextMenu('casechange', {
      update: () =>
         !editor.selection.isCollapsed() ? ['uppercase', 'lowercase', 'titlecase'] : [],
   });

   editor.ui.registry.addToggleMenuItem('uppercase', {
      text: 'UPPERCASE',
      onSetup: (api) => {
         api.setActive(isSelectedTextCase('uppercase'));
      },
      onAction: () => {
         convertTextCase('uppercase');
      },
   });

   editor.ui.registry.addToggleMenuItem('lowercase', {
      text: 'lowercase',
      onSetup: (api) => {
         api.setActive(isSelectedTextCase('lowercase'));
      },
      onAction: () => {
         convertTextCase('lowercase');
      },
   });

   editor.ui.registry.addToggleMenuItem('titlecase', {
      text: 'Title Case',
      onSetup: (api) => {
         api.setActive(isSelectedTextCase('titlecase'));
      },
      onAction: () => {
         convertTextCase('titlecase');
      },
   });

   editor.ui.registry.addNestedMenuItem('casechange', {
      text: 'Capitalization',
      icon: 'change-case',
      getSubmenuItems: () => 'uppercase lowercase titlecase',
   });

   editor.ui.registry.addContextMenu('casechange', {
      update: (element) => {
         if (
            ['IMG', 'AUDIO', 'VIDEO', 'DIV', 'TABLE'].includes(element.tagName) ||
            editor.selection.isCollapsed()
         ) {
            return '';
         }
         return 'casechange';
      },
   });

   return {
      getMetadata: () => ({
         name: 'Case Change',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
