import * as React from 'react';

import DateTime from '@services/DateTimeService';
import moment from 'moment';

import VocabSessionsCompletedChart from '@components/Course/VocabStats/VocabSessionsCompletedChart';
import { IVocabStat } from './StudentVocabStats';

interface CompletedVocabSessionsChartProps {
   data: readonly IVocabStat[];
}

const CompletedVocabSessionsChart: React.FC<CompletedVocabSessionsChartProps> = ({ data }) => {
   const [dateRange, setDateRange] = React.useState<moment.DurationInputArg2>('week');

   const startRange = DateTime.momentNow().subtract(1, dateRange).toDate();

   return (
      <div className='card no-padding' style={{ height: '100%' }}>
         <div className='card-title'>
            <div className='title'>Completed Vocab Sessions</div>
            <div className='right-options-wrapper'>
               <select
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value as moment.DurationInputArg2)}
               >
                  <option value='week'>1 Week</option>
                  <option value='month'>1 Month</option>
                  <option value='year'>1 Year</option>
               </select>
            </div>
         </div>
         <div style={{ height: '300px' }}>
            <VocabSessionsCompletedChart data={data.filter((i) => i.day >= startRange)} />
         </div>
      </div>
   );
};

export default React.memo(CompletedVocabSessionsChart);
