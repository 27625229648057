import { uploadUserFile } from '@services/AssetService';
import tinymce from 'tinymce';

tinymce.PluginManager.add('uploadfile', (editor) => {
   const handleUploadFile = async (): Promise<void> => {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.style.display = 'none'; // hide the input element

      return new Promise((resolve, reject) => {
         fileInput.onchange = async (event) => {
            try {
               const file = (event.target as HTMLInputElement).files?.[0];
               if (!file) {
                  return;
               }
               const { filename, url } = await uploadUserFile(file);
               editor.execCommand(
                  'mceInsertContent',
                  false,
                  `<a className="link" target='_blank' rel="noreferrer" href="${url}">
                     ${filename}
                  </a>`,
               );
               // Remove the input element after using it
               document.body.removeChild(fileInput);
               resolve();
            } catch (e) {
               reject(e);
            }
         };

         // Add the input element to the body to be able to trigger the click event
         document.body.appendChild(fileInput);
         fileInput.click();
      });
   };

   editor.addCommand('mceUploadFile', handleUploadFile);

   editor.ui.registry.addButton('upload-file', {
      icon: 'paperclip-line',
      tooltip: 'Upload File',
      onAction: () => editor.execCommand('mceUploadFile'),
   });
   editor.ui.registry.addMenuItem('upload-file', {
      icon: 'paperclip-line',
      text: 'Upload File',
      onAction: () => editor.execCommand('mceUploadFile'),
   });

   return {
      getMetadata: () => ({
         name: 'Upload File',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
