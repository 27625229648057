export enum TileState {
   correct = 'correct',
   incorrect = 'incorrect',
   hidden = 'hidden',
   neutural = 'neutural',
}

export interface Tile {
   id: string;
   termId: number;
   position: {
      x: number;
      y: number;
   };
   height: number;
   width: number;
   text: string;
   state: TileState;
}
