import * as React from 'react';

import AccountType from '@models/AccountType';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import { featureWithAdminOption } from '@models/Feature';
import UserService from '@services/UserService';
import ErrorDisplay from './ErrorDisplay';
import { HelpLauncherSize } from './HelpLauncher';
import Master from './Wrappers/Master';

interface MasterRouteProps {
   admin?: boolean;
   districtAdmin?: boolean;
   children?: React.ReactNode;
   exact?: boolean;
   feature?: featureWithAdminOption;
   fullScreen?: boolean;
   helpLauncherSize?: HelpLauncherSize;
   instructor?: boolean;
   navbar?: boolean;
}

// eslint-disable-next-line complexity
const MasterRoute: React.FC<MasterRouteProps> = ({
   admin = false,
   districtAdmin = false,
   fullScreen = false,
   helpLauncherSize = null,
   instructor = false,
   navbar = true,
   ...rest
}) => {
   const navigate = useNavigate();
   const { setHelpLauncherSize, networkError, userProfile, loggedIn } =
      React.useContext<AppStateContext>(AppStateContext);
   const {
      routes: {
         auth: { login },
      },
   } = Constants;
   const showError = Number.isInteger(networkError?.status);
   const [userHasFeature, setUserHasFeature] = React.useState<boolean>(rest.feature === undefined);

   React.useEffect(() => {
      if (helpLauncherSize) {
         setHelpLauncherSize(helpLauncherSize);
      }
      return () => {
         setHelpLauncherSize(HelpLauncherSize.large);
      };
   }, [helpLauncherSize]);

   React.useEffect(() => {
      if (!loggedIn || !userProfile) {
         navigate(login);
      }
   }, [loggedIn, userProfile]);

   React.useEffect(() => {
      checkFeature();
   }, [rest.feature]);

   const checkFeature = async () => {
      if (rest.feature) {
         if (rest.feature.shouldAllowAdmin && isAdmin) {
            setUserHasFeature(true);
         } else {
            const hasFeature = await UserService.checkFeature(rest.feature.feature);
            setUserHasFeature(hasFeature);
         }
      }
   };

   if (!loggedIn || !userProfile) {
      return null;
   }

   const { isAdmin, isDistrictAdmin, accountType } = userProfile;
   const isInstructor = accountType === AccountType.instructor;

   const userHasInsufficientPermission =
      (instructor && !isInstructor) ||
      (admin && !isAdmin) ||
      (districtAdmin && !isDistrictAdmin) ||
      !userHasFeature;

   if (userHasInsufficientPermission) {
      return (
         <Master navbar={navbar}>
            <ErrorDisplay statusCode={403} />
         </Master>
      );
   }

   if (rest.children === undefined) {
      return <></>;
   }

   if (fullScreen) {
      return showError ? <ErrorDisplay /> : <>{rest.children}</>;
   }

   return <Master navbar={navbar}>{showError ? <ErrorDisplay /> : <>{rest.children}</>}</Master>;
};

export default MasterRoute;
