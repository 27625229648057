import * as React from 'react';

import Constants from '../../../Constants';
import {
   IOnboardingStep,
   IOnboardingWalkthrough,
   OnboardingStepType,
   OnboardingWalkthrough,
} from '@components/Onboarding/Models';

const {
   routes: {
      courses: { dashboard: courseDashboardRoute, modules: modulesRoute, newModule },
   },
} = Constants;

export enum AssignActivityStep {
   modulesLink = 'modules_link',
   moduleItems = 'module_items',
   moduleDescription = 'module_description',
   moduleContent = 'module_content',
   addModule = 'add_module',
   moduleName = 'module_name',
   createModule = 'create_module',
   addContent = 'add_content',
   moduleAddButton = 'module_add_button',
   assignModuleItem = 'assign_module_item',
   availabilityTab = 'availability_tab',
   gradingTab = 'grading_tab',
   moduleItemActions = 'module_item_actions',
   walkthroughCompletionPost = 'walkthrough_completion_post',
}

const steps: readonly IOnboardingStep[] = [
   {
      id: AssignActivityStep.modulesLink,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="modules-link"]',
      hideWhenRemoved: '[data-tour="modules-link"]',
      advanceWhenVisible: '.module-item-container',
      overlay: false,
      expectedLocationChanges: [modulesRoute, /^\/courses\/:courseId\/modules\/\d+$/],
      placement: 'left',
      primaryButton: false,
      content: (
         <p>
            To assign an activity, start by going to the <strong>modules</strong> which is where the
            content for a course exists.
         </p>
      ),
   },
   {
      id: AssignActivityStep.moduleItems,
      type: OnboardingStepType.pointer,
      selector: '.module-item-container',
      placement: 'right',
      content: (
         <p>
            <strong>Modules</strong> are used to organize and group course content. They can be by
            chapter, unit, week, theme, etc.
         </p>
      ),
   },
   {
      id: AssignActivityStep.moduleDescription,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="module-description"]',
      placement: 'bottom',
      content: (
         <p>
            The <strong>Module Description</strong> is a great place to add unit objectives,
            important dates, announcements, and any other useful information about the module.
         </p>
      ),
   },
   {
      id: AssignActivityStep.moduleContent,
      type: OnboardingStepType.pointer,
      selector: '.module-content-list',
      placement: 'top',
      disableInteraction: true,
      content: (
         <>
            <p>
               A module contains <strong>content</strong> from your library like activities,
               lessons, videos, and vocabulary sets.
            </p>
            <p>Links and files can be added too!</p>
         </>
      ),
   },
   {
      id: AssignActivityStep.addModule,
      type: OnboardingStepType.pointer,
      overlay: false,
      className: 'animated infinite pulse slow green-outline',
      selector: '[data-tour="add-module-button"]',
      expectedLocationChanges: [newModule],
      primaryButton: false,
      content: <p>Let's start by creating a new module to put our activity in!</p>,
      beforeUnmount: (): void => {
         const button = document.querySelector(
            '[data-tour="add-module-button"]',
         ) as HTMLButtonElement;
         button?.blur();
      },
      advanceWhenVisible: '[data-tour="module-name"]',
   },
   {
      id: AssignActivityStep.moduleName,
      type: OnboardingStepType.pointer,
      content: (
         <p>
            Now give your module a <strong>name</strong>. This can be a chapter, unit, theme, week,
            etc.
         </p>
      ),
      selector: '[data-tour="module-name"]',
      overlay: false,
      placement: 'left',
      onMount: (elem: HTMLInputElement): void => {
         elem.focus();
      },
   },
   {
      id: AssignActivityStep.createModule,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="module-content"]',
      className: 'animated infinite pulse slow green-outline',
      placement: 'left',
      overlay: false,
      content: (
         <>
            <p>
               Finally, you can add a <strong>description</strong>. This is where you put things
               like unit objectives or other helpful information.
            </p>
            <p>
               When you're ready, click the blue <strong>create</strong> button to continue.
            </p>
         </>
      ),
      expectedLocationChanges: [/^\/courses\/:courseId\/modules\/\d+$/],
      primaryButton: false,
      advanceWhenVisible: '[data-tour="add-content-button"]',
   },
   {
      id: AssignActivityStep.addContent,
      type: OnboardingStepType.pointer,
      placement: 'left',
      className: 'animated pulse infinite slow',
      selector: '[data-tour="add-content-button"]',
      hideWhenVisible: '[data-tour="add-module-content-modal"]',
      advanceWhenVisible: '.module-add-button',
      primaryButton: false,
      overlay: false,
      content: (
         <>
            <p>Now let's add an activity from the Content Library to our module!</p>
            <p>
               Click <strong>Add Content</strong> and choose the "From Content Library" option.
            </p>
         </>
      ),
   },
   {
      id: AssignActivityStep.moduleAddButton,
      type: OnboardingStepType.pointer,
      selector: '.module-add-item.activity .module-add-button',
      hideWhenRemoved: '[data-tour="add-module-content-modal"]',
      advanceWhenVisible: 'input[name="isAssigned"]',
      placement: 'right',
      primaryButton: false,
      overlay: false,
      content: (
         <>
            <p>
               Find an activity and click the blue <strong>+</strong> icon to add it to your module.
            </p>
            <p>
               Activities are designated by the{' '}
               <span style={{ fontWeight: 'bold', color: '#4A7D35' }}>green</span> building blocks
               icon.
            </p>
         </>
      ),
   },
   {
      id: AssignActivityStep.assignModuleItem,
      type: OnboardingStepType.pointer,
      selector: '.assign-switch-container',
      placement: 'left',
      primaryButton: false,
      overlay: false,
      advanceWhenVisible: '[data-tour="availability-tab"]',
      content: (
         <>
            <p>
               Next, we'll <strong>assign</strong> the activity which will add it to the gradebook.
            </p>
            <p>Your changes will automatically be saved.</p>
         </>
      ),
   },
   {
      id: AssignActivityStep.availabilityTab,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="availability-tab"]',
      placement: 'left',
      overlay: false,
      content: (
         <p>
            The <strong>availability</strong> panel is used for setting the start and end dates and
            other rules such as the time limit.
         </p>
      ),
   },
   {
      id: AssignActivityStep.gradingTab,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="grading-tab"]',
      placement: 'left',
      overlay: false,
      content: (
         <p>
            On the <strong>grading</strong> panel, you can enable automatic grading, set the number
            of attempts allowed, and optionally add it to a grading category.
         </p>
      ),
   },
   {
      id: AssignActivityStep.moduleItemActions,
      type: OnboardingStepType.pointer,
      selector: '.action-icon-bar',
      placement: 'left',
      overlay: true,
      content: (
         <p>
            The <strong>action buttons</strong> allow you to view your students' submissions,
            preview and try the activity from a student's perspective, edit the activity in the
            content library or remove it from the module.
         </p>
      ),
   },
   {
      id: AssignActivityStep.walkthroughCompletionPost,
      type: OnboardingStepType.post,
      confetti: true,
      content: (
         <>
            <h1>Congratulations! 🎉</h1>
            <p>
               You've assigned your first activity! Your students will now be able to see and
               complete it and you have all of the tools to build out your course!
            </p>
            <p>
               Feel free to add more content, modify the settings, explore the sample module, and
               create new ones. When you're ready, we'll learn how to grade an activity and provide
               feedback to your students.
            </p>
            <p>
               <em>A helpful hint:</em> when building your course, you can work ahead of your
               students by setting modules that you're still working on in draft mode which will
               prevent your students from seeing them while they're in progress.
            </p>
         </>
      ),
   },
];

export default {
   id: OnboardingWalkthrough.assignActivity,
   steps,
   startRoute: courseDashboardRoute,
} as IOnboardingWalkthrough;
