// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import { faker } from '@faker-js/faker';
import { formatDate } from '@helpers/FormatTime';
import { round } from '@helpers/MathUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { getQueryParameterByName } from '@helpers/QueryParameter';
import { randomShortId } from '@helpers/RandomStringUtils';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconCalendarTimeout from '@icons/nova-solid/05-Time/calendar-timeout.svg';
import IconUserAdd from '@icons/nova-solid/07-Users/user-add.svg';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { IUserExtension } from '@models/Extensions';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import { useLocation, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import { Tab, Tabs } from '@components/Core/Tabs';
import ExtensionModal from '@components/Course/Submissions/ExtensionModal';
import DocumentTitle from '@components/DocumentTitle';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';

interface VocabSetProgressEntry {
   enrollmentId: number;
   excludeFromCalc: boolean;
   firstName: string;
   lastName: string;
   lastSession: Maybe<Date>;
   sessionCounts: {
      learn: number;
      review: number;
      speedReview: number;
   };
   progress: number;
   score: number;
   userId: number;
}

interface IncorrectResponseEntry {
   response: string;
   count: number;
   key?: string;
}

interface VocabSetOverviewResponse {
   isAssigned: boolean;
   breadcrumbsInfo: {
      canEdit: boolean;
      courseId: number;
      courseName: string;
      endDate: Date;
      moduleId: number;
      moduleName: string;
      vocabSetId: number;
      vocabSetName: string;
   };
   terms: readonly OverviewVocabTerm[];
   progress: readonly VocabSetProgressEntry[];
   extensions: readonly IUserExtension[];
}

interface OverviewVocabTerm {
   id: number;
   term: string;
   definition: number;
   correct: number;
   total: number;
}

interface VocabSetOverviewState {
   extensions: readonly IUserExtension[];
   filteredIds: Set<number>;
   incorrectResponses: {
      termId: number;
      responses: readonly IncorrectResponseEntry[];
   };
   isAssigned: boolean;
   isFetching: boolean;
   progress: readonly VocabSetProgressEntry[];
   selectedIds: Set<number>;
   terms: readonly OverviewVocabTerm[];
   courseId: number;
   moduleId: number;
   moduleItemEndDate: Date;
   moduleItemId: number;
   vocabSetName: string;
}

const VocabSetOverview: React.FC = () => {
   const location = useLocation();

   const {
      routes: { courses, enrollments, vocabSets },
   } = Constants;

   const { moduleItemId } = useParams<{ moduleItemId: string }>();

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<VocabSetOverviewState>({
      courseId: null,
      extensions: [],
      filteredIds: new Set(),
      incorrectResponses: null,
      isAssigned: false,
      isFetching: false,
      moduleId: null,
      moduleItemEndDate: null,
      moduleItemId: null,
      progress: [],
      selectedIds: new Set(),
      terms: [],
      vocabSetName: '',
   });

   const [extensionModalOpen, setExtensionModalOpen] = React.useState<boolean>(false);
   const [nameFilter, setNameFilter] = React.useState<string>('');

   const someSelected = !!state.selectedIds.size;
   const noResults = !!state.progress.length && !state.filteredIds.size;
   const pointsPossible = state.terms.length;
   const checkSelectAll = state.progress.length === state.selectedIds.size;
   const rosterLink = state.courseId
      ? courses.roster.replace(':courseId', state.courseId.toString())
      : '';

   React.useEffect(() => fetchData(), []);

   React.useEffect(() => {
      if (state.progress) {
         setState((prevState) => {
            const updatedFilteredIds = new Set(
               prevState.progress
                  .filter(
                     (i) =>
                        !nameFilter.length ||
                        `${i.firstName} ${i.lastName}`
                           .toLowerCase()
                           .includes(nameFilter.toLowerCase()),
                  )
                  .map((i) => i.enrollmentId),
            );

            return {
               ...prevState,
               filteredIds: updatedFilteredIds,
            };
         });
      }
   }, [nameFilter, state.progress]);

   const filteredEnrollments = React.useMemo(
      () =>
         nameFilter.length
            ? state.progress.filter((i) => state.filteredIds.has(i.enrollmentId))
            : state.progress,
      [state.progress, state.filteredIds],
   );

   const fetchData = (): void => {
      setState((prevState) => ({ ...prevState, isFetching: true }));
      HttpService.getWithAuthToken<VocabSetOverviewResponse>(
         `/api/vocab_sets/${moduleItemId}`,
      ).then(async (response) => {
         const {
            breadcrumbsInfo: {
               canEdit,
               courseName,
               courseId,
               moduleName,
               moduleId,
               vocabSetName,
               vocabSetId,
               endDate,
            },
            terms,
            progress,
            extensions,
            isAssigned,
         } = response.data;
         const patchedProgress = (await UserService.checkFeature('fake_names'))
            ? progress.map((i) => ({
                 ...i,
                 firstName: faker.name.firstName(),
                 lastName: faker.name.lastName(),
              }))
            : progress;
         setState((prevState) => ({
            ...prevState,
            courseId,
            moduleId,
            moduleItemId: Number(moduleItemId),
            moduleItemEndDate: new Date(endDate),
            terms,
            extensions,
            progress: patchedProgress,
            isAssigned,
            isFetching: false,
            vocabSetName,
         }));
         const ref =
            getQueryParameterByName(location, 'ref') === 'gradebook'
               ? 'gradebook'
               : `modules/${moduleId}`;
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: courses.dashboard.replace(':courseId', courseId.toString()),
                  text: courseName,
                  contextInfo: { courseId, moduleId },
               },
               {
                  link: `${courses.root}/${courseId}/${ref}`,
                  text: ref === 'gradebook' ? 'Gradebook' : moduleName,
               },
               {
                  link: vocabSets.overview.replace(':moduleItemId', moduleItemId.toString()),
                  text: `${vocabSetName} Overview`,
                  contextInfo: {
                     contentType: ContentType.vocabSet,
                     contentId: vocabSetId,
                     canEditContent: canEdit,
                  },
               },
            ],
            next: null,
            prev: null,
         });
      });
   };

   const handleAccuracyCellClick = (termId: number): void => {
      const url = `/api/vocab_sets/${moduleItemId}/terms/${termId}/incorrect_responses`;
      HttpService.getWithAuthToken<{
         incorrectResponses: readonly IncorrectResponseEntry[];
      }>(url).then((response) => {
         const { incorrectResponses } = response.data;
         setState((prevState) => ({
            ...prevState,
            incorrectResponses: {
               termId,
               responses: incorrectResponses.map((i) => ({
                  ...i,
                  key: randomShortId(),
               })),
            },
         }));
      });
   };

   const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const updatedNameFilter = event.target.value;
      setNameFilter(updatedNameFilter);
   };

   const toggleChecked = (enrollmentId: number): void => {
      const selectedIds = new Set(state.selectedIds);
      if (selectedIds.has(enrollmentId)) {
         selectedIds.delete(enrollmentId);
      } else {
         selectedIds.add(enrollmentId);
      }
      setState((prevState) => ({ ...prevState, selectedIds }));
   };

   const toggleSelectAll = (): void => {
      if (state.selectedIds.size === state.filteredIds.size) {
         setState((prevState) => ({ ...prevState, selectedIds: new Set() }));
      } else {
         setState((prevState) => ({
            ...prevState,
            selectedIds: new Set(prevState.filteredIds),
         }));
      }
   };

   const extendAssignment = (endDate: Date): void => {
      const { courseId, moduleId } = state;
      const url = `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}/extensions/bulk_create`;
      const userIds = state.progress
         .filter((i) => state.selectedIds.has(i.enrollmentId))
         .map((i) => i.userId);
      HttpService.postWithAuthToken<{
         msg: string;
         extensions: readonly IUserExtension[];
      }>(url, snakeCaseKeys({ userIds, endDate })).then((response) => {
         const { extensions } = response.data;
         setState((prevState) => ({ ...prevState, extensions }));
         fetchData();
      });
   };

   const buildTooltipHeader =
      (header: string, tooltip: string): (() => React.ReactNode) =>
      // eslint-disable-next-line react/display-name
      (): React.ReactNode => (
         <>
            {header}
            {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
         </>
      );

   const renderNameCell = (cell: VocabSetProgressEntry): React.ReactNode => {
      const url = enrollments.byId
         .replace(':enrollmentId', cell.enrollmentId.toString())
         .concat('?ref=vocab_stats');
      return (
         <Link to={url}>
            {cell.firstName} {cell.lastName}
         </Link>
      );
   };

   const calculateAccuracy = (cell: OverviewVocabTerm): number =>
      round((cell.correct / cell.total) * 100, 1);

   const renderAccuracyCell = (cell: OverviewVocabTerm): React.ReactNode => {
      if (cell.total) {
         const accuracy = calculateAccuracy(cell);
         return (
            <span className='accuracy-cell' onClick={() => handleAccuracyCellClick(cell.id)}>
               {`${accuracy}%`}
            </span>
         );
      } else {
         return <span>-</span>;
      }
   };

   const renderSelectAll = (): React.ReactNode => (
      <input type='checkbox' onChange={toggleSelectAll} checked={checkSelectAll} />
   );

   const renderSelect = (row: VocabSetProgressEntry): React.ReactNode => (
      <input
         type='checkbox'
         checked={state.selectedIds.has(row.enrollmentId)}
         onChange={() => toggleChecked(row.enrollmentId)}
      />
   );

   const getExtension = (userId: number): IUserExtension => {
      const extension = state.extensions.find((i) => i.userId === userId);
      return extension === undefined ? null : extension;
   };

   const renderLastSession = (row: VocabSetProgressEntry): React.ReactNode => {
      const extension = getExtension(row.userId);
      return (
         <div className='last-submitted-wrapper'>
            {row.lastSession ? formatDate(row.lastSession) : '-'}
            {extension !== null && (
               <Tippy content={`Extended Until ${formatDate(extension.endDate)}`}>
                  <span className='extension-info'>
                     <IconCalendarTimeout />
                  </span>
               </Tippy>
            )}
         </div>
      );
   };

   const renderGradeHeader = buildTooltipHeader('Grade', 'Work completed up to end date');
   const renderProgressHeader = buildTooltipHeader(
      'Progress',
      state.isAssigned ? 'Total progress ignoring end date' : '',
   );

   const progressColumns: readonly Column<VocabSetProgressEntry>[] = [
      { id: 'select', header: renderSelectAll, cell: renderSelect },
      {
         id: 'name',
         header: 'Name',
         cell: renderNameCell,
         canSort: true,
         sortFunc: (i) => i.lastName,
      },
      {
         id: 'sessionCounts.learn',
         header: 'Learn',
         cell: (i) => i.sessionCounts.learn || '-',
         canSort: true,
      },
      {
         id: 'sessionCounts.review',
         header: 'Review',
         cell: (i) => i.sessionCounts.review || '-',
         canSort: true,
      },
      {
         id: 'sessionCounts.speedReview',
         header: 'Speed Review',
         cell: (i) => i.sessionCounts.speedReview || '-',
         canSort: true,
      },
      {
         id: 'total',
         header: 'Total Sessions',
         cell: (i) => _.sum(Object.values(i.sessionCounts)) || '-',
         canSort: true,
         sortFunc: (i) => _.sum(Object.values(i.sessionCounts)),
      },
      {
         id: 'lastSession',
         header: 'Last Session',
         cell: renderLastSession,
         canSort: true,
      },
      ...(state.isAssigned
         ? [
              {
                 id: 'score',
                 header: renderGradeHeader,
                 cell: (i: VocabSetProgressEntry) =>
                    `${Math.floor((100 * i.score) / pointsPossible)}%`,
                 canSort: true,
              },
           ]
         : []),
      {
         id: 'progress',
         header: renderProgressHeader,
         cell: (i) => `${Math.floor((100 * i.progress) / pointsPossible)}%`,
         canSort: true,
      },
   ];

   const termsColumns: readonly Column<OverviewVocabTerm>[] = [
      { id: 'term', header: 'Term', cell: (i) => i.term, canSort: true },
      {
         id: 'definition',
         header: 'Definition',
         cell: (i) => i.definition,
         canSort: true,
      },
      {
         id: 'correct',
         header: 'Correct',
         cell: (i) => i.correct,
         canSort: true,
      },
      { id: 'total', header: 'Total', cell: (i) => i.total, canSort: true },
      {
         id: 'accuracy',
         header: 'Accuracy',
         cell: renderAccuracyCell,
         canSort: true,
         sortFunc: (i) => calculateAccuracy(i),
      },
   ];

   const incorrectResponsesColumns: readonly Column<IncorrectResponseEntry>[] = [
      {
         id: 'response',
         header: 'Response',
         cell: (i) => (i.response ? i.response : 'No Response'),
         canSort: true,
      },
      { id: 'count', header: 'Count', cell: (i) => i.count, canSort: true },
   ];

   if (state.isFetching) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>
            {state.isFetching ? 'Loading Overview...' : `Overview - ${state.vocabSetName}`}
         </DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row'>
               <div className='col-xs-12'>
                  <div className='card no-padding'>
                     <Tabs className='table-toggle'>
                        <Tab id='progress' className='table-toggle-tab' heading='Progress'>
                           <div className='card-title submissions-header has-button'>
                              <div className='row'>
                                 <div className='col-xs-12 col-sm-6'>
                                    <input
                                       name='nameFilter'
                                       type='search'
                                       className='full-width no-margin'
                                       value={nameFilter}
                                       onChange={handleNameFilterChange}
                                    />
                                 </div>
                                 <div className='col-xs-12 col-sm-6'>
                                    <div className='submissions-header-options'>
                                       {someSelected && (
                                          <Button
                                             color='purple'
                                             className='grant-extensions-btn'
                                             onClick={() => setExtensionModalOpen(true)}
                                          >
                                             Grant Extension
                                          </Button>
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </div>

                           {state.progress.length ? (
                              <Table
                                 columns={progressColumns}
                                 defaultSortBy={[{ id: 'total', desc: true }]}
                                 rowKey='enrollmentId'
                                 rows={filteredEnrollments}
                              />
                           ) : (
                              <EmptyState
                                 icon={<IconUserAdd aria-hidden />}
                                 heading='No students have been enrolled yet.'
                                 description={
                                    <p>
                                       Visit the <Link to={rosterLink}>Roster</Link> to invite
                                       students to join your course.
                                    </p>
                                 }
                              />
                           )}

                           {noResults && (
                              <EmptyState
                                 icon={<IconBinoculars aria-hidden />}
                                 heading='No Results'
                                 description='Try changing your filters.'
                              />
                           )}
                        </Tab>
                        <Tab id='terms' className='table-toggle-tab' heading='Terms'>
                           <Table
                              columns={termsColumns}
                              defaultSortBy={[{ id: 'total', desc: true }]}
                              rowKey='id'
                              rows={state.terms}
                           />
                        </Tab>
                     </Tabs>
                  </div>
               </div>
            </div>
         </div>
         {!!state.incorrectResponses && (
            <ModalDialog
               bodyClassName='incorrect-responses-overview'
               heading='Incorrect Responses'
               onClose={() =>
                  setState((prevState) => ({
                     ...prevState,
                     incorrectResponses: null,
                  }))
               }
            >
               <Table
                  className='fixed_headers'
                  columns={incorrectResponsesColumns}
                  defaultSortBy={[
                     { id: 'count', desc: true },
                     { id: 'response', desc: false },
                  ]}
                  rowKey='key'
                  rows={state.incorrectResponses.responses}
               />
            </ModalDialog>
         )}
         {extensionModalOpen && (
            <ExtensionModal
               endDate={state.moduleItemEndDate}
               closeExtensionModal={() => setExtensionModalOpen(false)}
               extendAssignment={extendAssignment}
            />
         )}
      </>
   );
};

export default VocabSetOverview;
