import * as React from 'react';

import ErrorDisplay from '@components/ErrorDisplay';
import AccountType from '@models/AccountType';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { buildDistrictReportBreadcrumbs } from '@components/ProficiencyTracking/ProficiencyReportHelpers';
import StudentProficiencySummary from './';

type Params = {
   courseId: string;
   standardCourseId?: string;
   studentId?: string;
   instructorId?: string;
   organizationId?: string;
};

const StudentProficiencySummaryPage: React.FC = () => {
   const { userProfile, setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   const params = useParams<Params>();
   const currentUserId = userProfile?.id.toString() ?? '';
   const [reportName, setReportName] = React.useState<string>('');
   const [courseName, setCourseName] = React.useState<string>('');

   if (!params.courseId) {
      return null;
   }

   // An instructor must specify a student to view
   if (!params.studentId && userProfile?.accountType === AccountType.instructor) {
      return <ErrorDisplay />;
   }

   const buildBreadcrumbs = async (): Promise<void> => {
      if (!reportName) {
         return;
      }

      // Must be viewing from the district report level
      if (params.standardCourseId) {
         const breadcrumbs = await buildDistrictReportBreadcrumbs({
            courseId: params.courseId,
            instructorId: params.instructorId,
            reportName,
            organizationId: params.organizationId,
            standardCourseId: params.standardCourseId,
            studentId: params.studentId,
         });

         setBreadcrumbs({
            breadcrumbs,
            prev: null,
            next: null,
         });

         return;
      }

      if (!params.standardCourseId && params.studentId) {
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: Constants.routes.courses.dashboard.replace(
                     ':courseId',
                     params.courseId || '',
                  ),
                  text: courseName,
               },
               {
                  link: Constants.routes.courses.instructorSummary.replace(
                     ':courseId',
                     params.courseId || '',
                  ),
                  text: 'Proficiency Summary',
               },
               {
                  link: Constants.routes.courses.studentSummary.replace(
                     ':courseId',
                     params.courseId || '',
                  ),
                  text: reportName,
               },
            ],
            prev: null,
            next: null,
         });

         return;
      }

      // Must be student trying to view their proficiency
      setBreadcrumbs({
         breadcrumbs: [
            {
               link: Constants.routes.courses.dashboard.replace(':courseId', params.courseId || ''),
               text: courseName,
            },
            {
               link: Constants.routes.courses.studentSummary.replace(
                  ':courseId',
                  params.courseId || '',
               ),
               text: reportName,
            },
         ],
         prev: null,
         next: null,
      });
   };

   React.useEffect(() => {
      buildBreadcrumbs();
   }, [reportName, courseName]);

   return (
      <div className='content-main student-proficiency-summary-page padding-right-s'>
         <div className='card no-padding'>
            <div className='card-title'>
               <div className='title'>{reportName}</div>
            </div>
            <div>
               <StudentProficiencySummary
                  courseId={parseInt(params.courseId)}
                  userId={parseInt(params.studentId || currentUserId)}
                  setPageName={(updatedReportName: string, updatedCourseName: string) => {
                     setReportName(updatedReportName);
                     setCourseName(updatedCourseName);
                  }}
               />
            </div>
         </div>
      </div>
   );
};

export default StudentProficiencySummaryPage;
