import * as React from 'react';

import { formatDate } from '@helpers/FormatTime';
import { round } from '@helpers/MathUtils';
import IconCalendarTimeout from '@icons/nova-solid/05-Time/calendar-timeout.svg';
import IconChatBubbleCircle1 from '@icons/nova-solid/10-Messages&Chat/chat-bubble-circle-1.svg';
import AccountType from '@models/AccountType';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { ExtensionType } from '@models/Extensions';
import DateTime from '@services/DateTimeService';
import Tippy from '@tippyjs/react';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import { UserCoursework } from './EnrollmentOverview';

interface CourseworkTableProps {
   canGrade?: boolean;
   coursework: readonly UserCoursework[];
   requestor: AccountType;
   selectedIds?: readonly number[];
   setSelectedIds?(selectedIds: readonly number[]): void;
}

const CourseworkTable: React.FC<CourseworkTableProps> = ({
   coursework,
   requestor,
   selectedIds = [],
   canGrade = false,
   setSelectedIds,
}) => {
   const {
      routes: {
         activities: { completeSubmission, gradeSubmissionFromModuleId },
         content: { viewVocabSet },
      },
   } = Constants;

   const displayType: Record<ContentType, string> = {
      [ContentType.activity]: 'Activity',
      [ContentType.vocabSet]: 'Vocabulary Set',
      [ContentType.lesson]: 'Lesson',
      [ContentType.video]: 'Video',
   };

   const renderName = ({
      itemName,
      itemType,
      submissionId,
      moduleItemId,
      contentId,
      startDate,
   }: UserCoursework): React.ReactNode => {
      let link = '';
      if (startDate > DateTime.now()) {
         return <>{itemName}</>;
      }
      if (itemType === ContentType.activity && submissionId) {
         if (requestor === AccountType.student) {
            link = completeSubmission.replace(':submissionId', submissionId.toString());
         } else if (canGrade) {
            link = gradeSubmissionFromModuleId
               .replace(':moduleItemId', moduleItemId.toString())
               .replace(':submissionId', submissionId.toString());
         }
      } else if (itemType === ContentType.vocabSet) {
         link = viewVocabSet
            .replace(':vocabSetId', contentId.toString())
            .concat(`?moduleItemId=${moduleItemId}`);
      }
      return link ? <Link to={link}>{itemName}</Link> : <span>{itemName}</span>;
   };

   const renderScore = ({ progressScore, pointsPossible, itemType }: UserCoursework): string => {
      if (progressScore !== null && pointsPossible && itemType === 'activity') {
         return `${round(progressScore, 1)}/${round(pointsPossible, 1)}`;
      }
      return '-';
   };

   const renderFinalScore = ({ score, pointsPossible }: UserCoursework): string => {
      if (score !== null && pointsPossible) {
         return `${round(score, 1)}/${round(pointsPossible, 1)}`;
      }
      return '-';
   };

   const renderGrade = ({ score, pointsPossible }: UserCoursework): string => {
      if (score !== null && pointsPossible) {
         return ((100 * score) / pointsPossible).toFixed(1).concat('%');
      }
      return '-';
   };

   const renderInstructorFeedback = ({ hasFeedback }: UserCoursework): React.ReactNode => {
      if (hasFeedback) {
         return (
            <Tippy content='Instructor Feedback Left'>
               <span>
                  <IconChatBubbleCircle1 className='icon-gray' />
               </span>
            </Tippy>
         );
      }
      return null;
   };

   const renderSelectAll = (): React.ReactNode => {
      const checkSelectAll = coursework.length && selectedIds.length === coursework.length;
      return <input type='checkbox' onChange={toggleSelectAll} checked={!!checkSelectAll} />;
   };

   const renderSelect = (row: UserCoursework): React.ReactNode => (
      <input
         type='checkbox'
         checked={selectedIds.includes(row.moduleItemId)}
         onChange={() => toggleSelect(row.moduleItemId)}
      />
   );

   const toggleSelectAll = (): void => {
      const allSelected = coursework.length && selectedIds.length === coursework.length;
      if (allSelected) {
         setSelectedIds?.([]);
      } else {
         setSelectedIds?.(coursework.map((i) => i.moduleItemId));
      }
   };

   const toggleSelect = (id: number): void => {
      if (selectedIds.includes(id)) {
         setSelectedIds?.(selectedIds.filter((i) => i !== id));
      } else {
         setSelectedIds?.([...selectedIds, id]);
      }
   };

   const getUserExtensionEndDate = (row: UserCoursework): Maybe<Date> => {
      const extension = row.allDueDates.find((x) => x.extensionType === ExtensionType.user);
      return extension?.endDate;
   };

   const getOriginalEndDate = (row: UserCoursework): Maybe<Date> => {
      const extension = row.allDueDates.find((x) => x.extensionType === ExtensionType.section);
      const original = row.allDueDates.find((x) => x.extensionType === ExtensionType.none);
      return extension?.endDate ?? original?.endDate;
   };

   const renderEndDate = (row: UserCoursework): React.ReactNode => {
      const userExtensionEndDate = getUserExtensionEndDate(row);
      const originalEndDate = getOriginalEndDate(row);

      const newThing = (
         <div className='last-submitted-wrapper'>
            {row.endDate ? formatDate(row.endDate) : '-'}
            {!!userExtensionEndDate && originalEndDate && (
               <Tippy content={`Original due date: ${formatDate(originalEndDate)}`}>
                  <span className='extension-info'>
                     <IconCalendarTimeout />
                  </span>
               </Tippy>
            )}
         </div>
      );

      return newThing;
   };

   const columns: readonly Column<UserCoursework>[] = [
      {
         id: 'select',
         header: renderSelectAll,
         cell: renderSelect,
         show: canGrade,
      },
      { id: 'itemName', header: 'Name', cell: renderName, canSort: true },
      {
         id: 'itemType',
         header: 'Type',
         cell: (i) => displayType[i.itemType],
         canSort: true,
      },
      {
         id: 'lastUpdated',
         header: 'Last Updated',
         cell: (i) => (i.lastUpdated ? formatDate(i.lastUpdated) : 'No Submission'),
         canSort: true,
      },
      { id: 'endDate', header: 'Due Date', cell: renderEndDate, canSort: true },
      { id: 'score', header: 'Score', cell: renderScore, canSort: true },
      {
         id: 'finalScore',
         header: 'Final Score',
         cell: renderFinalScore,
         canSort: true,
      },
      { id: 'grade', header: 'Grade', cell: renderGrade, canSort: true },
      { id: 'instructorFeedback', header: '', cell: renderInstructorFeedback },
   ];

   return <Table<UserCoursework> columns={columns} rowKey='moduleItemId' rows={coursework} />;
};

export default CourseworkTable;
