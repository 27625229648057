import { ActivityBuilderMode, ActivityQuestion } from '@models/Activity';
import dagre from 'dagre';
import { Edge, Node, Position } from 'reactflow';

import { isGoToQuestionAction } from '@components/Activity/Utils';

export const getEdgesFromQuestions = (
   questions: readonly ActivityQuestion<ActivityBuilderMode>[],
): Edge[] => {
   const makeEdge = (source: string | number, target: string | number, label = ''): Edge => ({
      id: `e_${source}_${target}`,
      source: source.toString(),
      target: target.toString(),
      label,
      type: 'default',
      markerEnd: 'arrow',
   });

   return questions.flatMap(({ logic, id: questionId }, i) => {
      let questionEdges = [];
      if (logic) {
         questionEdges = logic.actions
            .filter(isGoToQuestionAction)
            .filter((j) => j.questionId !== undefined)
            .map((j) =>
               // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
               makeEdge(questionId, j.questionId!, j.executeIfTrue ? 'condition' : 'default'),
            );

         const executeIfTrue =
            logic.actions.some((j) => isGoToQuestionAction(j) && j.executeIfTrue) ||
            !logic.rules.length;
         const executeIfFalse = logic.actions.some(
            (j) => isGoToQuestionAction(j) && !j.executeIfTrue,
         );

         if (!(executeIfTrue && executeIfFalse) && !(i === questions.length - 1)) {
            const nextQuestionId = questions[i + 1];
            questionEdges.push(makeEdge(questionId, nextQuestionId.id));
         }
      } else if (i !== questions.length - 1) {
         // If the question has no logic and is not the last question,
         // we create an edge to the next question by index.
         const nextQuestionId = questions[i + 1];
         questionEdges.push(makeEdge(questionId, nextQuestionId.id));
      }

      return questionEdges;
   });
};

export const getNodesFromQuestions = (
   questions: readonly ActivityQuestion<ActivityBuilderMode>[],
): Node[] =>
   questions.map((question) => ({
      id: question.id.toString(),
      type:
         question.index === 0
            ? 'input'
            : question.index + 1 === questions.length
            ? 'output'
            : 'default',
      data: { label: question.title ? question.title : `Untitled Question ${question.index + 1}` },
      position: { x: 0, y: 0 },
   }));

export const getLayoutedElements = (
   nodes: Node[],
   edges: Edge[],
   direction = 'LR',
): { nodes: Node[]; edges: Edge[] } => {
   const nodeWidth = 172;
   const nodeHeight = 36;

   const dagreGraph = new dagre.graphlib.Graph();
   dagreGraph.setDefaultEdgeLabel(() => ({}));

   const isHorizontal = direction === 'LR';
   dagreGraph.setGraph({ rankdir: direction });

   nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
   });

   edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
   });

   dagre.layout(dagreGraph);

   nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? Position.Left : Position.Top;
      node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
         x: nodeWithPosition.x - nodeWidth / 2,
         y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
   });

   return { nodes, edges };
};
