import { ActivityBuilderState } from '@components/Activity/Builder/ActivityBuilder';
import { OnboardingTask } from '@components/Onboarding';
/* eslint-disable complexity */
import { defaultState, IApplicationState } from '@models/ApplicationState';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import { BasicCourseProfile } from '@models/Course';
import IUserInfo from '@models/IUserInfo';
import IUserProfile from '@models/IUserProfile';
import { NationalExamAbbreviation } from '@models/NationalExam';

import { actionTypes } from './Actions';

export type ApplicationStateReducerAction =
   | { type: 'APPEND_COURSE'; course: BasicCourseProfile }
   | { type: 'APPEND_FEATURE'; feature: string }
   | { type: 'APPEND_GOOGLE_SCOPES'; scopes: readonly string[] }
   | { type: 'CLEAR_POST_LOGIN_REDIRECT' }
   | { type: 'COMPLETE_ONBOARDING_TASK'; onboardingTask: OnboardingTask }
   | { type: 'CONFIRM_USER' }
   | { type: 'HIDE_DOWNTIME_BANNER'; payload?: boolean }
   | { type: 'HIDE_HELP_FAB'; payload?: boolean }
   | {
        type: 'IMPERSONATE_USER';
        userInfo: IUserInfo;
        currentCourses: readonly BasicCourseProfile[];
        archivedCourses: readonly BasicCourseProfile[];
     }
   | {
        type: 'LOGIN_USER';
        userInfo: IUserInfo;
        currentCourses: readonly BasicCourseProfile[];
        archivedCourses: readonly BasicCourseProfile[];
     }
   | { type: 'LOGOUT_USER' }
   | { type: 'REFRESH_ACCESS_TOKEN'; accessToken: string }
   | { type: 'SET_ACTIVITY_RECOVERY'; state: ActivityBuilderState; existing: boolean }
   | { type: 'SET_AVAILABLE_NATIONAL_EXAMS'; exams: readonly NationalExamAbbreviation[] }
   | {
        type: 'SET_COURSES';
        currentCourses: readonly BasicCourseProfile[];
        archivedCourses: readonly BasicCourseProfile[];
     }
   | { type: 'SET_AVAILABLE_NATIONAL_EXAMS'; exams: readonly NationalExamAbbreviation[] }
   | { type: 'SET_CONTENT_LIBRARY_LAYOUT'; contentLibraryLayout: ContentLibraryLayout }
   | { type: 'SET_NETWORK_ERROR'; networkError: boolean }
   | { type: 'SET_PAYMENT_MODAL_VISIBILITY'; visible: boolean }
   | { type: 'SET_POST_LOGIN_REDIRECT'; postLoginRedirect: string }
   | { type: 'SET_REPORT_TASK_RESPONSE'; reportTaskResponse: string }
   | { type: 'SET_USER_PROFILE'; userProfile: IUserProfile }
   | { type: 'UPDATE_ONBOARDING_PROGRESS'; progress: number }
   | { type: 'UPDATE_PAID_COURSE_TRIAL'; paidCourseTrial: boolean }
   | { type: 'UPDATE_USER_PROFILE'; userProfile: IUserProfile };

const applicationStateReducer = (
   state: IApplicationState = defaultState,
   action: ApplicationStateReducerAction,
): IApplicationState => {
   switch (action.type) {
      case actionTypes.APPEND_COURSE:
         return Object.assign({}, state, {
            currentCourses: [...state.currentCourses, action.course],
         });
      case actionTypes.APPEND_FEATURE: {
         const oldFeatures = state.user?.userProfile?.features || [];
         return Object.assign({}, state, {
            user: state.user
               ? {
                    ...state.user,
                    userProfile: {
                       ...state.user.userProfile,
                       features: [...oldFeatures, action.feature],
                    },
                 }
               : null,
         });
      }
      case actionTypes.COMPLETE_ONBOARDING_TASK: {
         const oldCompletedOnboardingTasks =
            state?.user?.userProfile?.completedOnboardingTasks ?? [];
         return Object.assign({}, state, {
            user: state.user
               ? {
                    ...state.user,
                    userProfile: {
                       ...state.user.userProfile,
                       completedOnboardingTasks: oldCompletedOnboardingTasks.includes(
                          action.onboardingTask,
                       )
                          ? oldCompletedOnboardingTasks
                          : [...oldCompletedOnboardingTasks, action.onboardingTask],
                    },
                 }
               : null,
         });
      }
      case actionTypes.CONFIRM_USER: {
         return Object.assign({}, state, {
            user: state.user
               ? {
                    ...state.user,
                    userProfile: {
                       ...state.user.userProfile,
                       authenticated: true,
                    },
                 }
               : null,
         });
      }
      case actionTypes.HIDE_DOWNTIME_BANNER: {
         return Object.assign({}, state, {
            hideDowntimeBanner:
               action.payload !== undefined ? action.payload : !state.hideDowntimeBanner,
         });
      }
      case actionTypes.APPEND_GOOGLE_SCOPES: {
         const oldGoogleScopes = state.user?.userProfile?.googleScopes || [];
         return Object.assign({}, state, {
            user: state.user
               ? {
                    ...state.user,
                    userProfile: {
                       ...state.user.userProfile,
                       googleScopes: [...oldGoogleScopes, ...action.scopes],
                    },
                 }
               : null,
         });
      }
      case actionTypes.HIDE_HELP_FAB: {
         return Object.assign({}, state, {
            hideHelpFab: action.payload !== undefined ? action.payload : !state.hideHelpFab,
         });
      }
      case actionTypes.IMPERSONATE_USER: {
         return Object.assign({}, state, {
            currentCourses: action.currentCourses,
            archivedCourses: action.archivedCourses,
            user: {
               accessToken: action.userInfo.accessToken,
               districtProfile: action.userInfo.districtProfile,
               impersonatorRefreshToken: state.user?.refreshToken,
               loggedIn: action.userInfo.loggedIn,
               refreshToken: action.userInfo.refreshToken,
               schoolProfile: action.userInfo.schoolProfile,
               userProfile: action.userInfo.userProfile,
            },
         });
      }
      case actionTypes.LOGIN_USER: {
         return Object.assign({}, state, {
            user: {
               accessToken: action.userInfo.accessToken,
               districtProfile: action.userInfo.districtProfile,
               loggedIn: action.userInfo.loggedIn,
               refreshToken: action.userInfo.refreshToken,
               schoolProfile: action.userInfo.schoolProfile,
               userProfile: action.userInfo.userProfile,
            },
         });
      }
      case actionTypes.LOGOUT_USER: {
         return Object.assign({}, state, {
            user: {
               accessToken: null,
               districtProfile: null,
               loggedIn: false,
               refreshToken: null,
               schoolProfile: null,
               userProfile: null,
            },
            currentCourses: [],
            archivedCourses: [],
         });
      }
      case actionTypes.REFRESH_ACCESS_TOKEN: {
         return Object.assign({}, state, {
            user: {
               ...state.user,
               accessToken: action.accessToken,
            },
         });
      }
      case actionTypes.SET_ACTIVITY_RECOVERY: {
         return Object.assign({}, state, {
            activityRecovery: {
               existing: action.existing
                  ? action.state
                  : state.activityRecovery?.existing
                  ? state.activityRecovery.existing
                  : null,
               new: !action.existing
                  ? action.state
                  : state.activityRecovery?.new
                  ? state.activityRecovery.new
                  : null,
            },
         });
      }
      case actionTypes.SET_CONTENT_LIBRARY_LAYOUT: {
         return Object.assign({}, state, {
            contentLibraryLayout: action.contentLibraryLayout,
         });
      }
      case actionTypes.SET_NETWORK_ERROR: {
         return Object.assign({}, state, {
            networkError: action.networkError,
         });
      }
      case actionTypes.SET_COURSES: {
         return Object.assign({}, state, {
            currentCourses: action.currentCourses ? action.currentCourses : [],
            archivedCourses: action.archivedCourses ? action.archivedCourses : [],
         });
      }
      case actionTypes.SET_AVAILABLE_NATIONAL_EXAMS: {
         return Object.assign({}, state, {
            availableNationalExams: action.exams ? action.exams : [],
         });
      }
      case actionTypes.UPDATE_USER_PROFILE: {
         return Object.assign({}, state, {
            user: Object.assign({}, state.user, {
               userProfile: action.userProfile,
            }),
         });
      }
      case actionTypes.SET_POST_LOGIN_REDIRECT: {
         return Object.assign({}, state, {
            postLoginRedirect: action.postLoginRedirect,
         });
      }
      case actionTypes.CLEAR_POST_LOGIN_REDIRECT: {
         return Object.assign({}, state, {
            postLoginRedirect: null,
         });
      }
      case actionTypes.SET_REPORT_TASK_RESPONSE: {
         return Object.assign({}, state, {
            reportTaskResponse: action.reportTaskResponse,
         });
      }
      default: {
         return state;
      }
   }
};

export default applicationStateReducer;
