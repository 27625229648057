import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowUp from '@icons/general/icon-arrow-up.svg';
import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { Maybe } from '@models/Core';
import { BasicCourseProfile, Module } from '@models/Course';
import HttpService from '@services/HttpService';
import classnames from 'classnames';

import { GetCourseModulesResponse } from '@components/Course/Modules/Modules';

interface ModuleSelectorProps {
   /** Courses to allow the user to choose from */
   courses: readonly BasicCourseProfile[];
   selectedModuleId: Maybe<number>;
   disabledCourseIds?: readonly number[];
   disabledModuleIds?: readonly number[];
   onSelectModule(module: Module): void;
}

const ModuleSelector: React.FC<ModuleSelectorProps> = ({
   courses = [],
   disabledCourseIds = [],
   disabledModuleIds = [],
   selectedModuleId = null,
   onSelectModule,
}) => {
   const [expandedCourseIds, setExpandedCourseIds] = React.useState<readonly number[]>([]);
   const [modules, setModules] = React.useState<Record<number, readonly Module[]>>({});
   const [fetchingModulesCourseIds, setFetchingModulesCourseIds] = React.useState<
      readonly number[]
   >([]);

   React.useEffect(() => {
      expandedCourseIds.forEach((courseId) => {
         if (!modules[courseId] && !fetchingModulesCourseIds.includes(courseId)) {
            setFetchingModulesCourseIds((prevFetchingModulesCourseIds) => [
               ...prevFetchingModulesCourseIds,
               courseId,
            ]);
            const url = `/api/courses/${courseId}/modules?assignment_defaults=true`;
            return HttpService.getWithAuthToken<GetCourseModulesResponse>(url).then((response) => {
               const { modules: dataModules } = response.data;
               setFetchingModulesCourseIds((prevFetchingModulesCourseIds) =>
                  prevFetchingModulesCourseIds.filter((i) => i !== courseId),
               );
               setModules((prevModules) => ({ ...prevModules, [courseId]: dataModules }));
            });
         }
      });
   }, [expandedCourseIds]);

   const toggleCourse = (courseId: number): void => {
      if (disabledCourseIds.includes(courseId)) {
         return;
      }
      setExpandedCourseIds((prevExpandedCourseIds) =>
         prevExpandedCourseIds.includes(courseId)
            ? prevExpandedCourseIds.filter((i) => i !== courseId)
            : [...prevExpandedCourseIds, courseId],
      );
   };

   const renderArrow = (isExpanded: boolean): React.ReactNode =>
      isExpanded ? <IconArrowUp /> : <IconArrowDown />;

   const renderModules = (courseId: number, courseModules: readonly Module[]): React.ReactNode => {
      if (fetchingModulesCourseIds.includes(courseId) || modules === undefined) {
         return <div className='module-message'>Loading modules...</div>;
      } else if (!courseModules?.length) {
         return <div className='module-message'>This course has no modules.</div>;
      } else {
         return (
            <div className='modules'>
               {courseModules.map((module) => (
                  <div
                     className={classnames('module-wrapper', {
                        active: selectedModuleId === module.id,
                        disabled: disabledModuleIds.includes(module.id),
                     })}
                     key={module.id}
                     onClick={() =>
                        !disabledModuleIds.includes(module.id) && onSelectModule(module)
                     }
                  >
                     <div className='nav-icon'>
                        <IconMenuLevel />
                     </div>
                     <div className='module-name'>{module.name}</div>
                  </div>
               ))}
            </div>
         );
      }
   };

   return (
      <div className='module-selector'>
         {courses.map(({ id: courseId, name: courseName }) => (
            <div
               className={classnames('course-wrapper', {
                  disabled: disabledCourseIds.includes(courseId),
               })}
               key={courseId}
            >
               <div className='course-title-wrapper' onClick={() => toggleCourse(courseId)}>
                  <div className='course-title'>{courseName}</div>
                  <div className='carrot'>{renderArrow(expandedCourseIds.includes(courseId))}</div>
               </div>
               {expandedCourseIds.includes(courseId) && renderModules(courseId, modules[courseId])}
            </div>
         ))}
      </div>
   );
};

export default ModuleSelector;
