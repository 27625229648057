// @ts-strict-ignore
import * as React from 'react';

import { Maybe } from '@models/Core';
import classnames from 'classnames';

import AttributePickerOpener from './AttributePickerOpener';
import FilterGroupEditor from './FilterGroupEditor';
import LogicModeSelect from './LogicModeSelect';
import { Attribute, OperatorType, Predicate, PredicateGroup } from './Models';
import { getFilterIds, getInvalidFilterIds, getNewPredicate } from './Utils';

interface PredicatesEditorProps {
   className?: string;
   attributes: readonly Attribute[];
   value: PredicateGroup;
   onChange(value: PredicateGroup): void;
   onFilterAdded?(attribute: string): void;
   onFilterRemoved?(predicate: Predicate): void;
   onEditorOpen?(): void;
   onEditorClose?(): void;
}

export interface PredicateEditorContext {
   newFilterId: Maybe<string>;
   attributes: readonly Attribute[];
   openedEditorId: Maybe<string>;
   invalidFilterIds: readonly string[];
   orLimit: number;
}

export const PredicateEditorContext = React.createContext<PredicateEditorContext>(null);
export const PredicateEditorProvider = PredicateEditorContext.Provider;
export const PredicateEditorConsumer = PredicateEditorContext.Consumer;

const PredicatesEditor: React.FC<PredicatesEditorProps> = ({
   attributes,
   className,
   value,
   onChange,
   onEditorClose,
   onEditorOpen,
}) => {
   const [connectionSwitcherHovered, setConnectionSwitcherHovered] = React.useState<boolean>(false);

   const [newFilterId, setNewFilterId] = React.useState<Maybe<string>>(null);
   const [openedEditorId, setOpenedEditorId] = React.useState<Maybe<string>>(null);
   const [invalidFilterIds, setInvalidFilterIds] = React.useState<readonly string[]>([]);

   const orLimit = 10;
   const cannotSwitchToOr = value.type === OperatorType.and && value.predicates.length > orLimit;

   React.useEffect(() => {
      if (!openedEditorId) {
         setInvalidFilterIds(getInvalidFilterIds(value).filter((i) => i !== newFilterId));
      }
   }, [openedEditorId, newFilterId]);

   const handleChange = (predicate: PredicateGroup): void => {
      const idsBefore = getFilterIds(value);
      const idsAfter = getFilterIds(predicate);
      const idsAdded = idsAfter.filter((i) => !idsBefore.includes(i));
      const idsRemoved = idsBefore.filter((i) => !idsAfter.includes(i));
      if (idsRemoved.includes(openedEditorId)) {
         setOpenedEditorId(null);
      }
      if (idsRemoved.includes(newFilterId)) {
         setNewFilterId(null);
      }
      if (idsAdded.length === 1) {
         setNewFilterId(idsAdded[0]);
      }
      setInvalidFilterIds((prevInvalidFilterIds) =>
         prevInvalidFilterIds.filter((i) => !idsRemoved.includes(i)),
      );
      onChange(predicate);
   };

   const addPredicate = (attribute: string): void => {
      handleChange({
         ...value,
         predicates: [...value.predicates, getNewPredicate(attribute, attributes)],
      });
   };

   const updatePredicate = (predicateId: string, predicate: Predicate | PredicateGroup): void => {
      handleChange({
         ...value,
         predicates: value.predicates.map((i) => (i.id === predicateId ? { ...predicate } : i)),
      });
   };

   const removePredicate = (predicateId: string): void => {
      handleChange({
         ...value,
         predicates: value.predicates.filter((i) => i.id !== predicateId),
      });
   };

   const switchLogicalMode = (mode: OperatorType): void => {
      handleChange({
         ...value,
         type: mode,
      });
   };

   const handleEditorOpen = (predicateId: string): void => {
      if (!openedEditorId) {
         setOpenedEditorId(predicateId);
         onEditorOpen?.();
      }
   };

   const handleEditorClose = (predicateId: string): void => {
      if (predicateId === openedEditorId) {
         setOpenedEditorId(null);
         onEditorClose?.();
      }
      if (predicateId === newFilterId) {
         setNewFilterId(null);
      }
   };

   const getAddAttributeTooltip = (): Maybe<string> => {
      if (invalidFilterIds.length > 0) {
         return 'One of your filters is missing a value. Finish editing it before adding another.';
      } else if (value.type === OperatorType.or && value.predicates.length === orLimit) {
         return `You can have up to ${orLimit} filters connected with 'or'. Remove a few, or switch to 'and'.`;
      }
      return null;
   };

   const getLogicModeSelectTooltip = (): string => {
      if (cannotSwitchToOr) {
         return `You have too many filters to switch to 'or' (the limit is ${orLimit}). Remove a few first.`;
      }
      return null;
   };

   return (
      <PredicateEditorProvider
         value={{
            newFilterId,
            openedEditorId,
            invalidFilterIds,
            orLimit,
            attributes,
         }}
      >
         <span className={classnames('predicate-editor', className)}>
            <div className='flex items-center flex-wrap'>
               {value.predicates.map((predicate, index) => (
                  <React.Fragment key={predicate.id}>
                     <FilterGroupEditor
                        onEditorClose={handleEditorClose}
                        onEditorOpen={handleEditorOpen}
                        onRemove={() => removePredicate(predicate.id)}
                        onUpdate={(update) => updatePredicate(predicate.id, update)}
                        predicate={predicate}
                     />
                     {index !== value.predicates.length - 1 && (
                        <LogicModeSelect
                           className={classnames({
                              'connection-switcher-hovered': connectionSwitcherHovered,
                           })}
                           disabled={cannotSwitchToOr}
                           onMouseEnter={() => setConnectionSwitcherHovered(true)}
                           onMouseLeave={() => setConnectionSwitcherHovered(false)}
                           onSelect={switchLogicalMode}
                           selected={value.type}
                           tooltip={getLogicModeSelectTooltip()}
                        />
                     )}
                  </React.Fragment>
               ))}
               {attributes.length > 0 && (
                  <AttributePickerOpener
                     className='margin-left-m flex items-center'
                     disabled={invalidFilterIds.length > 0}
                     inline={false}
                     onAdd={addPredicate}
                     tooltip={getAddAttributeTooltip()}
                  />
               )}
            </div>
         </span>
      </PredicateEditorProvider>
   );
};

export default PredicatesEditor;
