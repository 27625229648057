import * as React from 'react';

import IconSlider2 from '@icons/nova-line/03-Settings/slider-2.svg';

import Button from '@components/Common/Button';
import CoursePermissionsModal from './CoursePermissionsModal';

interface CoursePermissionsProps {
   courseId: number;
}
const CoursePermissionsCard: React.FC<CoursePermissionsProps> = (props) => {
   const [showModal, setShowModal] = React.useState<boolean>(false);

   return (
      <div className='card course-card'>
         <div className='card-title full-width'>
            <div className='title'>Permissions</div>
         </div>
         <div className='content-form'>
            <div className='row'>
               <div className='col-xs-9'>
                  <p className='course-option-description'>
                     These are the default permissions that apply to instructors in this course.
                     They might be overridden in some cases. Many of these are used in national exam
                     courses.
                  </p>
               </div>
               <div className='col-xs-3'>
                  <div className='flex-end margin-top-s'>
                     <Button className='btn' onClick={() => setShowModal(true)}>
                        <IconSlider2 aria-hidden />
                        Manage Permissions
                     </Button>
                  </div>
               </div>
            </div>
         </div>
         {showModal && (
            <CoursePermissionsModal
               courseId={props.courseId}
               closeModal={() => setShowModal(false)}
            />
         )}
      </div>
   );
};

export default CoursePermissionsCard;
