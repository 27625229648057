import * as React from 'react';

import ModalDialog from '@components/Core/ModalDialog';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconLink from '@icons/nova-line/01-Content-Edition/link.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import Appearance from '@models/Appearance';
import SchoolService from '@services/SchoolService';
import Tippy from '@tippyjs/react';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';
import ExtendedSchoolProfile from './ExtendedSchoolProfile';
import OrganizationLmsInfoModal from './OrganizationLmsInfoModal';

interface SchoolActionsCellProps {
   row: ExtendedSchoolProfile;
   onUpdate(row: ExtendedSchoolProfile): void;
   onDelete(): void;
}

type modals = 'delete' | 'lms';

const SchoolActionsCell: React.FC<SchoolActionsCellProps> = ({ row, onDelete }) => {
   const navigate = useNavigate();

   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isModalOpen, setIsModalOpen] = React.useState<modals>();

   const handleCancel = (): void => {
      setIsModalOpen(undefined);
   };

   const handleSchoolEdit = (): void => {
      navigate(Constants.routes.schools.schoolDetails.replace(':organizationId', `${row.id}`));
   };

   const handleDeleteSchool = (): Promise<void> => {
      setIsLoading(true);
      return SchoolService.deleteSchool(row.id).then(() => {
         handleCancel();
         onDelete();
      });
   };

   return (
      <>
         <div className='table-row-action'>
            <div className='icon-action-wrap'>
               <Tippy content='LMS Configuration' delay={[400, 0]}>
                  <div className='icon-action' onClick={() => setIsModalOpen('lms')}>
                     <IconLink />
                  </div>
               </Tippy>
               <Tippy content='Edit' delay={[400, 0]}>
                  <div className='icon-action' onClick={handleSchoolEdit}>
                     <IconHighlight />
                  </div>
               </Tippy>
               <Tippy content='Delete' delay={[400, 0]}>
                  <div className='icon-action' onClick={() => setIsModalOpen('delete')}>
                     <IconBin />
                  </div>
               </Tippy>
            </div>
         </div>
         {isModalOpen === 'delete' && (
            <ModalDialog
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               heading='Delete School'
               onClose={handleCancel}
               actions={[
                  {
                     text: 'Delete',
                     onClick: handleDeleteSchool,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: handleCancel },
               ]}
            >
               <p>Are you sure that you want to delete {row.name}?</p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
         {isModalOpen === 'lms' && (
            <OrganizationLmsInfoModal
               onCancel={handleCancel}
               organizationId={row.id}
               organizationName={row.name}
            />
         )}
      </>
   );
};

export default SchoolActionsCell;
