import * as React from 'react';

import autobind from '@helpers/autobind';
import { ContainerOptions, dropHandlers, smoothDnD, SmoothDnD } from 'smooth-dnd';

smoothDnD.dropHandler = dropHandlers.reactDropHandler().handler;
smoothDnD.wrapChild = false;

export interface ContainerProps extends ContainerOptions {
   id?: string;
   children?: React.ReactNode;
   className?: string;
   style?: React.CSSProperties;
   render?(rootRef: React.RefObject<unknown>): React.ReactElement;
}

class Container extends React.Component<ContainerProps> {
   public static defaultProps = {
      behaviour: 'move',
      orientation: 'vertical',
   };

   prevContainer?: HTMLElement;
   container?: SmoothDnD;
   containerRef: React.RefObject<HTMLDivElement> = React.createRef();

   constructor(props: ContainerProps) {
      super(props);
      autobind(this);
   }

   componentDidMount() {
      this.prevContainer = this.getContainer() || undefined;
      if (this.prevContainer) {
         this.container = smoothDnD(this.prevContainer, this.getContainerOptions());
      }
   }

   componentWillUnmount() {
      if (this.container) {
         this.container.dispose();
         this.container = undefined;
      }
   }

   componentDidUpdate(prevProps: ContainerProps) {
      const currentContainer = this.getContainer() || undefined;
      if (currentContainer) {
         if (this.prevContainer && this.prevContainer !== currentContainer) {
            if (this.container) {
               this.container.dispose();
            }
            this.container = smoothDnD(currentContainer, this.getContainerOptions());
            this.prevContainer = currentContainer;
            return;
         }

         if (this.isObjectTypePropsChanged(prevProps) && this.container) {
            this.container.setOptions(this.getContainerOptions());
         }
      }
   }

   isObjectTypePropsChanged(prevProps: ContainerProps): boolean {
      const { render, children, style, ...containerOptions } = this.props;

      for (const _key in containerOptions) {
         const key = _key as keyof ContainerOptions;
         if (Object.prototype.hasOwnProperty.call(containerOptions, key)) {
            const prop = containerOptions[key];

            if (typeof prop !== 'function' && prop !== prevProps[key]) {
               return true;
            }
         }
      }

      return false;
   }

   render() {
      if (this.props.render) {
         return this.props.render(this.containerRef);
      } else {
         return (
            <div className={this.props.className} style={this.props.style} ref={this.containerRef}>
               {this.props.children}
            </div>
         );
      }
   }
   getContainer(): HTMLElement | null {
      return this.containerRef.current;
   }

   getContainerOptions(): ContainerOptions {
      const options = {} as Record<string, unknown>;

      Object.keys(this.props).forEach((key: string) => {
         const optionName = key as keyof ContainerOptions;
         const prop = this.props[optionName];

         if (typeof prop === 'function') {
            options[optionName] = (...params: any[]) =>
               (this.props[optionName] as (...params: any[]) => unknown)(...params);
         } else {
            options[optionName] = prop;
         }
      });

      return options as ContainerOptions;
   }
}

export default Container;
