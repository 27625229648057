import * as React from 'react';

import IconNavigationFilterText from '@icons/nova-solid/17-Navigation/navigation-filter-text.svg';
import IconContentBook4 from '@icons/nova-solid/18-Content/content-book-4.svg';
import IconContentModules from '@icons/nova-solid/18-Content/content-modules.svg';

import Constants from '../../../Constants';
import {
   IOnboardingStep,
   IOnboardingWalkthrough,
   OnboardingStepType,
   OnboardingWalkthrough,
} from '@components/Onboarding/Models';

const {
   routes: {
      contentLibrary: { root: contentLibraryRoot },
   },
} = Constants;

const steps: readonly IOnboardingStep[] = [
   {
      id: 'welcome_post',
      type: OnboardingStepType.post,
      expectedLocationChanges: [`${contentLibraryRoot}/global`],
      disableInteraction: true,
      content: (
         <>
            <h1>Welcome to the Content Library! 📚</h1>
            <p>
               Here is where all of the content created on Lingco lives. This includes activities,
               lessons, videos, and vocabulary sets.
            </p>
            <h2 className='content-heading activities'>
               <IconContentModules />
               Activities
            </h2>
            <p>
               Activities are used to create interactive assignments. You can pair content like
               text, audio, and videos with prompts such as multiple choice, fill in the blanks, and
               recordings to name a few.
            </p>
            <h2 className='content-heading lessons'>
               <IconContentBook4 />
               Lessons
            </h2>
            <p>
               Lessons can be used to present content that is part of a course but not an
               assignment. This can include reference materials, readings, lectures, etc.
            </p>
            <h2 className='content-heading vocab-sets'>
               <IconNavigationFilterText />
               Vocabulary Sets
            </h2>
            <p>
               Lingco uses adaptive questioning to create personalized learning sessions for each
               student. You can easily create and assign vocabulary sets in a few clicks and track
               the progress of individual students or the class as a whole.
            </p>
            <p>Let's take a look at the different parts of the content library.</p>
         </>
      ),
   },
   {
      id: 'personal_library',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="content-library-select"]',
      placement: 'right',
      disableInteraction: true,
      expectedLocationChanges: [`${contentLibraryRoot}/personal`],
      content: (
         <>
            <p>
               <strong>Personal</strong> is your own library. It's home to all of the content that
               you create on Lingco.
            </p>
            <p>
               You can organize your content by grouping items into folders which you can also share
               with other instructors on Lingco.
            </p>
         </>
      ),
   },
   {
      id: 'school_library',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="content-library-select"]',
      placement: 'right',
      disableInteraction: true,
      expectedLocationChanges: [`${contentLibraryRoot}/school`],
      content: (
         <>
            <p>
               <strong>School Content</strong> is where you can share content for other instructors
               at your school to use.
            </p>
            <p>It's a great place to house a shared or common curriculum.</p>
         </>
      ),
   },
   {
      id: 'global_library',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="content-library-select"]',
      placement: 'right',
      disableInteraction: true,
      expectedLocationChanges: [`${contentLibraryRoot}/global`],
      content: (
         <>
            <p>
               <strong>Global Content</strong> is full of curated content contributed by instructors
               on Lingco like you!
            </p>
            <p>
               You can use items as is, or duplicate and use them as a basis to create something
               entirely your own.
            </p>
         </>
      ),
   },
   {
      id: 'shared_library',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="content-library-select"]',
      placement: 'right',
      disableInteraction: true,
      expectedLocationChanges: [`${contentLibraryRoot}/shared`],
      content: (
         <>
            <p>
               You can give other instructors on Lingco the ability to use, edit or duplicate your
               content.
            </p>
            <p>
               <strong>Shared Content</strong> from other instructors will appear here.
            </p>
         </>
      ),
   },
   {
      id: 'explore',
      type: OnboardingStepType.post,
      content: (
         <>
            <h1>Ready to Explore! 🧭</h1>
            <p>
               Take a look around the <strong>Global Content</strong> to get some ideas of what's
               possible with Lingco. Then when you're ready, we'll create an activity to add to your
               personal content library.
            </p>
         </>
      ),
   },
];

const walkthrough: IOnboardingWalkthrough = {
   id: OnboardingWalkthrough.contentLibraryIntro,
   showBackButton: true,
   steps,
   startRoute: `${contentLibraryRoot}/global`,
};

export default walkthrough;
