// @ts-strict-ignore
import * as _ from 'lodash';

import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';

import Constants from '../../Constants';
import { Tile } from './Models';

export const getFilteredTerms = (
   terms: readonly IVocabTerm[],
   progress: Record<number, IVocabTermProgress>,
   numTerms = 6,
): readonly IVocabTerm[] => {
   const { vocabLearnedLevel } = Constants;
   const learnedTerms = Object.values(progress).filter((i) => i.level >= vocabLearnedLevel);
   const unlearnedTerms = Object.values(progress).filter((i) => i.level < vocabLearnedLevel);
   if (terms.length <= numTerms) {
      return terms;
   } else if (learnedTerms.length >= numTerms) {
      const selectedIds = _.sampleSize(learnedTerms, numTerms).map((i) => i.id);
      return selectedIds.map((i) => terms.find((j) => j.id === i));
   } else if (unlearnedTerms.length >= numTerms) {
      const selectedIds = _.sampleSize(
         [...learnedTerms, ...unlearnedTerms.splice(0, numTerms)],
         numTerms,
      ).map((i) => i.id);
      return selectedIds.map((i) => terms.find((j) => j.id === i));
   } else {
      return _.sampleSize(terms, numTerms);
   }
};

/**
 * Returns a random position for the tile
 */
export const getRandomPosition = (
   windowHeight: number,
   windowWidth: number,
   tile: Tile,
): { x: number; y: number } => {
   const paddingToAccountForBoxShadow = 5;
   // The number below is the height of the header and the padding of the container
   const paddingTopAndBottom = 75 + paddingToAccountForBoxShadow;

   return {
      x: _.random(
         paddingToAccountForBoxShadow,
         windowWidth - (paddingToAccountForBoxShadow + tile.width),
         false,
      ),
      y: _.random(
         paddingToAccountForBoxShadow,
         windowHeight - (paddingTopAndBottom + tile.height),
         false,
      ),
   };
};

/**
 * Returns a random position that does not overlap any of the other tiles
 */
export const getRandomNonOverlappingPosition = (
   tile: Tile,
   tiles: readonly Tile[],
   windowHeight: number,
   windowWidth: number,
): { x: number; y: number } => {
   const PADDING = 5;
   const MAX_ITERATION_COUNT = 100;
   let interationCount = 0;
   let newPosition = getRandomPosition(windowHeight, windowWidth, tile);
   while (
      overlapsAnyTiles({ ...tile, position: newPosition }, tiles, PADDING) &&
      interationCount < MAX_ITERATION_COUNT
   ) {
      interationCount++;
      newPosition = getRandomPosition(windowHeight, windowWidth, tile);
   }
   return newPosition;
};

export const overlaps = (a: Tile, b: Tile): boolean =>
   a.position.x < b.position.x + b.width &&
   a.position.x + a.width > b.position.x &&
   a.position.y < b.position.y + b.height &&
   a.position.y + a.height > b.position.y;

export const overlapsAnyTiles = (
   tile: Tile,
   /** Tiles to check for overlap */
   tiles: readonly Tile[],
   /** Number of Pixels between each tile */
   padding = 0,
): boolean => {
   if (tile) {
      if (padding) {
         tile.height += padding;
         tile.width += padding;
      }
      return tiles.filter((i) => i.id !== tile.id).some((i) => overlaps(tile, i));
   }
   return false;
};

export const isTileWithinBoard = (tile: Tile, windowHeight: number, windowWidth: number): boolean =>
   tile.position.x >= 0 &&
   tile.position.y >= 0 &&
   tile.position.x + tile.width < windowWidth &&
   tile.position.y + tile.height < windowHeight;
