import * as _ from 'lodash';
import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import {
   ActivityLogicField,
   ActivityLogicGroup,
   ActivityLogicRule,
   ActivityVariableField,
   ActivityVariableType,
} from '@models/Activity';
import { Maybe } from '@models/Core';

import AccentTextbox from '@components/AccentTextbox';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';

interface LogicRuleProps {
   rule: ActivityLogicRule;
   fields: readonly ActivityLogicField[];
   onPropChange(
      ruleId: number | string,
      update: Partial<ActivityLogicRule | ActivityLogicGroup>,
   ): void;
   onRuleRemove(id: number | string, parentId: number | string): void;
}

const LogicRule: React.FC<LogicRuleProps> = ({
   fields,
   rule: { id, parentId, field, subfield, operator, value, unit },
   onPropChange,
   onRuleRemove,
}) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const fieldObj = fields.find((i) => i.value === field) || ({} as ActivityLogicField);
   const operators = _.isFunction(fieldObj?.operators)
      ? fieldObj.operators(subfield ?? '')
      : fieldObj?.operators ?? [];

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const { name, value: eventValue } = event.target;
      onPropChange(id, { [name]: eventValue });
   };

   const handleFieldChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newField = event.target.value as ActivityVariableField;
      const newFieldObj = fields.find((i) => i.value === newField) || ({} as ActivityLogicField);
      const newOperator = _.isArray(newFieldObj.operators) ? newFieldObj.operators[0].value : null;
      const newUnit = _.isArray(newFieldObj.units) ? newFieldObj.units[0].value : null;
      onPropChange(id, {
         field: newField,
         operator: newOperator,
         value: null,
         subfield: null,
         unit: newUnit,
      });
   };

   const handleValueChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value: unpatchedValue } = event.target;
      let patchedValue: Maybe<string | boolean | number> = unpatchedValue;
      if (fieldObj.type === ActivityVariableType.boolean) {
         patchedValue = null;
         if (event.target.value === 'true') {
            patchedValue = true;
         } else if (event.target.value === 'false') {
            patchedValue = false;
         }
      }
      onPropChange(id, { [name]: patchedValue });
   };

   const handleNumbericValueChange = (newValue: Maybe<number>): void => {
      if (newValue !== null && newValue !== undefined && !isNaN(newValue)) {
         onPropChange(id, { value: newValue.toString() });
      } else {
         onPropChange(id, { value: '' });
      }
   };

   const removeRule = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      onRuleRemove(id, parentId);
   };

   const renderSubfield = (): Maybe<React.ReactNode> => {
      if (fieldObj.subfield?.options) {
         return (
            <select value={subfield ?? ''} name='subfield' onChange={handleChange}>
               <option value=''>{fieldObj.subfield.placeholder ?? 'Select'}</option>
               {fieldObj.subfield.options.map((i) => (
                  <option key={i.id} value={i.id}>
                     {i.label}
                  </option>
               ))}
            </select>
         );
      } else {
         return <input value={subfield || ''} name='subfield' onChange={handleChange} />;
      }
   };

   const renderRuleValue = (): Maybe<React.ReactNode> => {
      const type: Maybe<ActivityVariableType> = _.isFunction(fieldObj.type)
         ? fieldObj.type(subfield ?? '')
         : fieldObj?.type;
      if (!type || !field) {
         return null;
      }
      if (type === ActivityVariableType.string) {
         return (
            <AccentTextbox
               value={value ?? ''}
               language={language}
               name='value'
               onChange={handleValueChange}
            />
         );
      } else if (type === ActivityVariableType.boolean) {
         return (
            <select name='value' onChange={handleValueChange} value={value?.toString() ?? ''}>
               <option value='' disabled>
                  Select
               </option>
               <option value='true'>True</option>
               <option value='false'>False</option>
            </select>
         );
      } else if ([ActivityVariableType.float, ActivityVariableType.integer].includes(type)) {
         return (
            <NumberInput
               name='value'
               onValueChange={handleNumbericValueChange}
               value={Number(value)}
               integer={type === ActivityVariableType.integer}
            />
         );
      }
      return null;
   };

   return (
      <div className='logic-rule'>
         <select value={field ?? ''} name='field' onChange={handleFieldChange}>
            <option value='' disabled>
               Select Variable
            </option>
            {fields.map((i) => (
               <option value={i.value} key={i.value}>
                  {i.label}
               </option>
            ))}
         </select>
         {fieldObj?.subfield && renderSubfield()}
         {operators.length > 0 && (
            <>
               <select value={operator ?? ''} name='operator' onChange={handleChange}>
                  <option value='' disabled>
                     Select Operator
                  </option>
                  {operators.map((i) => (
                     <option value={i.value} key={i.value}>
                        {i.label}
                     </option>
                  ))}
               </select>
               {renderRuleValue()}
               {fieldObj?.units && (
                  <select value={unit ?? ''} name='unit' onChange={handleChange}>
                     <option value='' disabled>
                        Select Unit
                     </option>
                     {fieldObj.units.map((i) => (
                        <option value={i.value} key={i.value}>
                           {i.label}
                        </option>
                     ))}
                  </select>
               )}
            </>
         )}
         <div className='icon-action-wrap'>
            <div className='icon-action' onClick={removeRule} aria-label='Remove Rule'>
               <IconBin />
            </div>
         </div>
      </div>
   );
};

export default LogicRule;
