import * as _ from 'lodash';
import * as React from 'react';

import AudioSlider from '@components/Core/AudioPlayer/AudioSlider';
import { formatSeconds } from '@helpers/FormatTime';
import IconVideoSliderPause from '@icons/nova-solid/36-Videos/video-control-pause.svg';
import IconVideoSliderPlay from '@icons/nova-solid/36-Videos/video-control-play.svg';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import { Subtitle } from '@models/Video';
import ReactPlayer from 'react-player';

import Overlay from './Overlay';
import { VideoSubtitleProps } from './SubtitlePopup';
import VideoSubtitles from './VideoSubtitles';

import { OnProgressProps } from 'react-player/base';

interface VideoPlayerProps {
   nativeLanguage?: Language;
   nativeSubtitles?: readonly Subtitle[];
   posterUrl?: Maybe<string>;
   preload?: string;
   showSubtitles?: boolean;
   targetLanguage?: Language;
   targetSubtitles?: readonly Subtitle[];
   showOverlay?: boolean;
   videoContainerClassname?: string;
   videoUrl: string;
   start?: number; // FIXME
   end?: number; // FIXME
   onCanPlayThrough?(): void;
}

const PRECISION = 100;

const VideoPlayer = React.forwardRef<ReactPlayer, VideoPlayerProps>(
   (
      {
         showOverlay = false,
         preload = 'auto',
         posterUrl = '',
         videoContainerClassname = 'subtitle-video-container',
         showSubtitles = true,
         videoUrl,
         targetSubtitles = [],
         nativeSubtitles = [],
         targetLanguage,
         nativeLanguage,
         onCanPlayThrough,
      },
      ref,
   ) => {
      const [duration, setDuration] = React.useState<number>(0.01); // Setting duration to .01 avoids a warning in the console.
      const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
      const [progress, setProgress] = React.useState<number>(0);
      const [shouldLoop, setShouldLoop] = React.useState<boolean>(false);
      const [showNativeSubtitles, setShowNativeSubtitles] = React.useState<boolean>(true);
      const [showTargetSubtitles, setShowTargetSubtitles] = React.useState<boolean>(true);
      const [speed, setSpeed] = React.useState<number>(1);
      const [volume, setVolume] = React.useState<number>(0.5);

      const playerRef = React.useRef<ReactPlayer>();

      const playVideo = (): void => {
         setIsPlaying(true);
      };

      const pauseVideo = (): void => {
         setIsPlaying(false);
      };

      const setPlayerRef = (player: ReactPlayer) => {
         // Set the ref value
         playerRef.current = player;

         // If the parent component has also provided a ref, update that as well
         if (typeof ref === 'function') {
            ref(player);
         } else if (ref) {
            (ref as React.MutableRefObject<ReactPlayer | null>).current = player;
         }
      };

      const toggleShouldLoop = (): void => {
         setShouldLoop((prevShouldLoop) => !prevShouldLoop);
      };

      const toggleIsPlaying = (): void => {
         setIsPlaying((prevIsPlaying) => !prevIsPlaying);
      };

      const handleProgress = (i: OnProgressProps) => {
         setProgress(_.round(i.playedSeconds, 1));
      };

      const handleEnded = (): void => {
         setIsPlaying(false);
      };

      const handleSliderChange = (values: readonly number[]): void => {
         const seconds = convertPrecisionToSeconds(values[0]);
         if (playerRef.current) {
            playerRef.current.seekTo(seconds);
            setProgress(seconds);
         }
      };

      const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
         const vol = parseFloat(event.target.value);
         setVolume(vol);
      };

      const handleSpeedChange = React.useCallback(
         (newSpeed: number): void => {
            setSpeed(newSpeed);
         },
         [setSpeed],
      );

      const rewindVideo = (event: React.MouseEvent): void => {
         event.stopPropagation();
         const newProgress = Math.max(progress - 10, 0);

         if (playerRef.current) {
            playerRef.current.seekTo(newProgress);
            setProgress(newProgress);
            if (!isPlaying) {
               playVideo();
            }
         }
      };

      const forwardVideo = (event: React.MouseEvent): void => {
         event.stopPropagation();
         const newProgress = Math.min(progress + 10, duration);

         if (playerRef.current) {
            playerRef.current.seekTo(newProgress);
            setProgress(newProgress);
         }
      };

      const convertToPrecision = (value: number): number => Math.round(value * PRECISION);

      const convertPrecisionToSeconds = (value: number): number =>
         value === 0 ? 0 : value / PRECISION;

      const onReady = (player: ReactPlayer): void => {
         setPlayerRef(player);
      };

      const handleCanPlayThrough = () => {
         onCanPlayThrough?.();
      };

      const targetLanguageSubtitleProps = (): VideoSubtitleProps | undefined => {
         if (!targetLanguage || targetSubtitles.length === 0) {
            return;
         } else {
            return {
               language: targetLanguage,
               showLanguage: showTargetSubtitles,
               setShowLanguage: setShowTargetSubtitles,
            };
         }
      };

      const nativeLanguageSubtitleProps = (): VideoSubtitleProps | undefined => {
         if (!nativeLanguage || nativeSubtitles.length === 0) {
            return;
         } else {
            return {
               language: nativeLanguage,
               showLanguage: showNativeSubtitles,
               setShowLanguage: setShowNativeSubtitles,
            };
         }
      };

      return (
         <div className='video-player'>
            <div className={videoContainerClassname} onClick={toggleIsPlaying}>
               {showOverlay && (
                  <Overlay
                     forwardVideo={forwardVideo}
                     toggleShouldLoop={toggleShouldLoop}
                     handleSpeedChange={handleSpeedChange}
                     handleVolumeChange={handleVolumeChange}
                     isPlaying={isPlaying}
                     nativeLanguageSubtitleProps={nativeLanguageSubtitleProps()}
                     pauseVideo={pauseVideo}
                     playVideo={playVideo}
                     rewindVideo={rewindVideo}
                     shouldLoop={shouldLoop}
                     speed={speed}
                     targetLanguageSubtitleProps={targetLanguageSubtitleProps()}
                     volume={volume}
                  />
               )}
               <ReactPlayer
                  config={{
                     file: {
                        attributes: {
                           disablePictureInPicture: true,
                           controlsList: 'nodownload disable',
                           preload,
                           poster: posterUrl,
                           onCanPlayThrough: handleCanPlayThrough,
                        },
                     },
                     youtube: {
                        playerVars: { showinfo: 1 },
                     },
                  }}
                  controls={false}
                  url={videoUrl}
                  height='100%'
                  width='100%'
                  onReady={onReady}
                  progressInterval={100}
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  onDuration={setDuration}
                  onEnded={handleEnded}
                  onPlay={playVideo}
                  onPause={pauseVideo}
                  ref={setPlayerRef}
                  onProgress={handleProgress}
                  onSeek={setProgress}
                  playing={isPlaying}
                  volume={volume}
                  playbackRate={speed}
               />
            </div>
            <div className='video-controls'>
               <div className='play-icon'>
                  {isPlaying ? (
                     <IconVideoSliderPause onClick={pauseVideo} />
                  ) : (
                     <IconVideoSliderPlay onClick={playVideo} />
                  )}
               </div>
               <div className='slider-container'>
                  <AudioSlider
                     domain={[0, convertToPrecision(duration)]}
                     values={[convertToPrecision(progress)]}
                     onChange={handleSliderChange}
                     onUpdate={handleSliderChange}
                  />
               </div>
               <div className='video-time-wrapper'>
                  <time>{formatSeconds(progress)}</time>
                  <span className='divider'>/</span>
                  <time>{formatSeconds(duration)}</time>
               </div>
            </div>
            {showSubtitles && (
               <VideoSubtitles
                  progress={progress}
                  showNativeSubtitles={showNativeSubtitles}
                  showTargetSubtitles={showTargetSubtitles}
                  nativeSubtitles={nativeSubtitles}
                  targetSubtitles={targetSubtitles}
                  seekTo={playerRef.current?.seekTo}
                  loop={shouldLoop}
               />
            )}
         </div>
      );
   },
);

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
