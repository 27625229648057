import * as React from 'react';

import tinymce from 'tinymce';

import { randomShortId } from '@helpers/RandomStringUtils';
import clickCallback from '@components/Core/Editor/clickCallback';
import InsertImageModal from '@components/Core/Editor/InsertImageModal';

tinymce.PluginManager.add('insertimage', (editor) => {
   editor.addCommand('mceInsertImage', (_ui, value) => {
      const placeholder = editor.dom.select(
         `div.image-placeholder[data-placeholder-id="${value.id}"]`,
      )[0];
      const img = editor.dom.create('img', { ...value.attributes, 'data-mce-id': '__mcenew' });
      editor.dom.replace(img, placeholder);
      editor.focus();
      const insertedElm = editor.dom.select('*[data-mce-id="__mcenew"]')[0];
      editor.dom.setAttrib(insertedElm, 'data-mce-id', null);
      editor.selection.select(insertedElm);
   });

   editor.addCommand('insertImagePlaceholder', (_ui, value) => {
      const id = value.id ?? randomShortId();
      const elm = document.createElement('div');
      editor.dom.setAttrib(elm, 'class', 'image-placeholder');
      editor.dom.setAttrib(elm, 'contenteditable', false);
      editor.dom.setAttrib(elm, 'data-placeholder-id', id);
      editor.focus();
      editor.selection.setContent(elm.outerHTML);
      if (value.open) {
         editor.execCommand('openInsertImageModal', false, { id });
      }
   });

   editor.addCommand('openInsertImageModal', (_ui, value) => {
      clickCallback(
         editor,
         document,
         <InsertImageModal id={value.id} />,
         'insert-image-modal-wrapper',
      );
   });

   editor.on('click', (e) => {
      if ((e.target as HTMLElement)?.classList?.contains('image-placeholder')) {
         const placeholderId = e.target.getAttribute('data-placeholder-id');
         if (placeholderId) {
            editor.execCommand('openInsertImageModal', false, { id: placeholderId });
         }
      }
   });

   return {
      getMetadata: () => ({
         name: 'Insert Image',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
