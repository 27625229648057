export enum ActivityMode {
   create = 'create',
   edit = 'edit',
   complete = 'complete',
   preview = 'preview',
   grade = 'grade',
}

export type ActivityBuilderMode = ActivityMode.create | ActivityMode.edit;

export type ActivityCompleterMode = ActivityMode.complete | ActivityMode.preview;
