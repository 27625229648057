import * as _ from 'lodash';
import * as React from 'react';

import LicenseService from '@services/LicenseService';
import Tippy from '@tippyjs/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Column } from '@components/Common/Table';
import { renderDateCell, renderTooltipCell } from '@components/Common/TableCellHelpers';
import FilterableTable from '@components/FilterableTable';
import SchoolDetailsPopover from '@components/SchoolDetailsPopover';
import { defaultPredicate } from './DefaultLicensePredicate';
import LicenseActionsCell from './LicenseActionsCell';
import attributes from './LicensePredicateAttributes';
import { License } from './Models';
import {
   isBookstoreCodeActivation,
   isCourseLicense,
   isProductLicense,
   isStripePayment,
} from './Utils';

const Licenses: React.FC = () => {
   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
   });

   const renderLicenseNameCell = (row: License): string | React.ReactNode => {
      if (isProductLicense(row)) {
         return row.productName;
      } else if (isCourseLicense(row)) {
         const MAX_LENGTH = 28;
         if (row.courseName.length < MAX_LENGTH) {
            return row.courseName;
         } else {
            const truncatedCourseName = _.truncate(row.courseName, {
               length: MAX_LENGTH,
               omission: '...',
            });
            return renderTooltipCell(truncatedCourseName, row.courseName);
         }
      }
      return '';
   };

   const renderSchoolCell = (row: License): React.ReactNode => (
      <SchoolDetailsPopover organizationId={row.school.id} schoolName={row.school.name} />
   );

   const renderUserNameCell = ({ user }: License): React.ReactNode =>
      renderTooltipCell(`${user.firstName} ${user.lastName}`, user.email);

   const renderPaymentTypeCell = (license: License): React.ReactNode => {
      let content = '';
      let text = '';
      if (isStripePayment(license.payment)) {
         content = license.payment.stripePaymentIntentId;
         text = 'Stripe';
      } else if (isBookstoreCodeActivation(license.payment)) {
         content = license.payment.bookstoreCode;
         text = 'Bookstore Code';
      }
      return (
         <Tippy content={content}>
            <span>
               <CopyToClipboard text={content}>
                  <span className='pointer'>{text}</span>
               </CopyToClipboard>
            </span>
         </Tippy>
      );
   };

   const renderPaymentAmountCell = (license: License): string => {
      if (isStripePayment(license.payment)) {
         return formatter.format(license.payment.amount);
      }
      return '-';
   };

   const renderLicenseActionsCell = (
      row: License,
      onUpdate: (updatedRow: License) => void,
      onDelete: () => void,
   ): React.ReactNode => <LicenseActionsCell row={row} onUpdate={onUpdate} onDelete={onDelete} />;

   const columns: readonly Column<License>[] = [
      { id: 'id', header: 'License id', cell: (i) => i.id, show: false },
      { id: 'user.id', header: 'User id', cell: (i) => i.user.id },
      {
         id: 'user.full_name',
         header: 'User name',
         cell: renderUserNameCell,
         canSort: true,
      },
      {
         id: 'school.name',
         header: 'School',
         cell: renderSchoolCell,
         canSort: true,
      },
      {
         id: 'license.type',
         header: 'License type',
         cell: (i) => i.type,
         canSort: true,
      },
      {
         id: 'license.name',
         header: 'License name',
         cell: renderLicenseNameCell,
         canSort: true,
      },
      {
         id: 'payment.payment_type',
         header: 'Payment type',
         cell: renderPaymentTypeCell,
         canSort: true,
      },
      {
         id: 'payment.amount',
         header: 'Amount',
         cell: renderPaymentAmountCell,
         canSort: true,
      },
      {
         id: 'license.activated_on',
         header: 'Activated',
         cell: (i) => renderDateCell(i.activatedOn),
         canSort: true,
      },
      { id: 'actions', header: 'Actions', cell: renderLicenseActionsCell },
   ];

   return (
      <FilterableTable<License>
         attributes={attributes}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'license.activated_on', desc: true }]}
         pageTitle='Licenses'
         searchInputPlaceholder='Search Licenses'
         rowsLoader={LicenseService.getLicenses}
      />
   );
};

export default Licenses;
