import { DropResult } from '@components/Core/DragAndDrop';
import { randomShortId, randomTempId } from '@helpers/RandomStringUtils';

import { isVideoContent } from './Utils';

const applyDrag = <T>(arr: readonly T[], dropResult: DropResult, key = true): readonly T[] => {
   const { removedIndex, addedIndex, payload } = dropResult;
   if (removedIndex === null && addedIndex === null) {
      return arr;
   }

   const result = [...arr];
   const itemToAdd = removedIndex === null ? payload : result.splice(removedIndex, 1)[0];

   if (isVideoContent(itemToAdd)) {
      itemToAdd.id = randomTempId();
   }

   if (addedIndex !== null) {
      result.splice(addedIndex, 0, key ? { ...itemToAdd, key: randomShortId() } : itemToAdd);
   }

   return result;
};

export { applyDrag };
