import IconLock2 from '@icons/nova-line/04-Login/lock-2.svg';
import IconCalendar1 from '@icons/nova-line/05-Time/calendar-1.svg';
import IconATSign from '@icons/nova-line/06-Email/at-sign.svg';
import IconContentBox1 from '@icons/nova-line/18-Content/content-box-1.svg';
import IconTag1 from '@icons/nova-line/30-Bookmarks&Tags/tag-1.svg';
import IconLocationGlobe from '@icons/nova-line/55-Locations/location-globe.svg';
import IconUser from '@icons/nova-solid/08-Users-Actions/user.svg';
import IconGraduationHat from '@icons/nova-solid/41-School&Science/graduation-hat.svg';
import IconLocationMapPin from '@icons/nova-solid/55-Locations/location-map-pin.svg';
import AccountType from '@models/AccountType';

import { LanguageLookup } from '@models/Language';
import { Attribute, PrimitiveType } from '@components/PredicateEditor/Models';

const ATTRIBUTES: readonly Attribute[] = [
   {
      icon: IconUser,
      label: 'User Id',
      identifier: 'user.id',
      type: PrimitiveType.integer,
   },
   {
      icon: IconUser,
      label: 'User name',
      identifier: 'user.full_name',
      type: PrimitiveType.string,
   },
   {
      icon: IconATSign,
      label: 'User email',
      identifier: 'user.email',
      type: PrimitiveType.string,
   },
   {
      icon: IconUser,
      label: 'User account type',
      identifier: 'user.account_type',
      type: PrimitiveType.string,
      options: [
         { value: AccountType.instructor, label: 'Instructor' },
         { value: AccountType.student, label: 'Student' },
      ],
   },
   {
      icon: IconUser,
      label: 'Demo user',
      identifier: 'user.demo',
      type: PrimitiveType.boolean,
   },
   {
      icon: IconLock2,
      label: 'User authenticated',
      identifier: 'user.authenticated',
      type: PrimitiveType.boolean,
   },
   {
      icon: IconGraduationHat,
      label: 'Course name',
      identifier: 'course.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationGlobe,
      label: 'Course language',
      identifier: 'course.language',
      type: PrimitiveType.string,
      options: Object.entries(LanguageLookup).map(([value, label]) => ({
         label,
         value,
      })),
   },
   {
      icon: IconContentBox1,
      label: 'Course archived',
      identifier: 'course.archived',
      type: PrimitiveType.boolean,
   },
   {
      icon: IconTag1,
      label: 'Demo course',
      identifier: 'course.demo',
      type: PrimitiveType.boolean,
   },
   {
      icon: IconTag1,
      label: 'Template course',
      identifier: 'course.demo',
      type: PrimitiveType.boolean,
   },
   {
      icon: IconCalendar1,
      label: 'Course created on',
      identifier: 'course.created_on',
      type: PrimitiveType.date,
   },
   {
      icon: IconCalendar1,
      label: 'Enrolled on',
      identifier: 'course_enrollment.created_on',
      type: PrimitiveType.date,
   },
   {
      icon: IconGraduationHat,
      label: 'School Id',
      identifier: 'school.id',
      type: PrimitiveType.integer,
   },
   {
      icon: IconGraduationHat,
      label: 'School name',
      identifier: 'school.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconGraduationHat,
      label: 'School district name',
      identifier: 'school_district.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School city',
      identifier: 'school.city',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School state',
      identifier: 'school.state',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School zip code',
      identifier: 'school.zip_code',
      type: PrimitiveType.string,
   },
];

export default ATTRIBUTES;
