import { Callout } from '@components/Core/Editor/types/Callout';

export const LESSON_CALLOUTS: readonly Callout[] = [
   {
      class: 'quick-start',
      name: 'Quick Start',
      header: '✨ Quick Start',
      description: 'Give a short overview or summary.',
      body: '<p>A direct object pronoun (un pronombre de objeto directo) replaces a direct object, which is a noun that directly receives the action of the verb in a sentence.</p>',
      icon: 'flash-line',
   },
   {
      class: 'notice',
      header: '⚠️ Notice',
      name: 'Notice',
      description: 'Point out something important.',
      body: "<p>When object pronouns are attached to a verb, it's often necessary to add an accent in order to preserve the natural stress of the verb.</p>",
      icon: 'interface-alert-triange-line',
   },
   {
      class: 'info',
      header: 'ℹ️ Info',
      name: 'Info',
      description: 'Provide an explanation.',
      body: `
            <h2>Estar and Ser</h2>
            <p>Wait, doesn't ser mean to be? Yes, it does. And estar also means to be. Spanish has two verbs that mean to be! In general:</p>
            <ul>
            <li>Ser is used for talking about permanent relationships or traits.</li>
            <li>Estar is used for talking about transient or momentary feelings or conditions.</li>
            </ul>`,
      icon: 'interface-information-line',
   },
   {
      class: 'tip',
      header: '💡 Tip',
      name: 'Tip',
      description: 'Give a helpful tip.',
      body: `
        <h2>Gender and Plurality</h2>
        <p>All the adjectives in the list above must change gender or number to match the noun they describe.</p>`,
      icon: 'lightbulb-4-line',
   },
   {
      class: 'mistake',
      name: 'Mistake',
      header: '🛑 Common Mistake',
      description: 'Show a common error.',
      body: `
        <h2>Soy Bien or Estoy Bien</h2>
        <p>Learners of Spanish will often make the mistake of using ser (to be) instead of estar (to be) when talking about how they are.</p>`,
      icon: 'delete-line',
   },
   {
      class: 'learn-more',
      name: 'Learn More',
      header: '🔗 More to Learn',
      description: 'Connect to further lessons.',
      body: `
            <h2>Want to learn more? Check out the following lessons!</h2>
            <ul>
            <li>&nbsp;</li>
            </ul>`,
      icon: 'link-line',
   },
];

export const BASIC_CALLOUTS: readonly Callout[] = [
   {
      class: 'primary',
      description: 'Make writing stand out.',
      name: 'Primary Callout',
   },
   {
      class: 'secondary',
      description: 'Make writing stand out.',
      name: 'Secondary Callout',
   },
   {
      class: 'warning',
      description: 'Make writing stand out.',
      name: 'Warning Callout',
   },
   {
      class: 'success',
      description: 'Make writing stand out.',
      name: 'Success Callout',
   },
   {
      class: 'danger',
      description: 'Make writing stand out.',
      name: 'Danger Callout',
   },
];
