import * as React from 'react';

import {
   ActivityMode,
   MultipleChoiceOption as MultipleChoiceOptionType,
   MultipleChoiceResponse,
} from '@models/Activity';

import MultipleChoiceOption from '@components/Activity/Completer/Prompts/MultipleChoiceOption';

interface MultipleChoiceProps {
   groupName: string;
   multiple: boolean;
   options: readonly MultipleChoiceOptionType<ActivityMode.grade>[];
   response: MultipleChoiceResponse;
}

const MultipleChoicePrompt: React.FC<MultipleChoiceProps> = ({
   response,
   options,
   multiple,
   groupName,
}) => {
   const selected = response ? Object.keys(response).map(Number) : [];

   return (
      <div>
         {options.map((option) => (
            <MultipleChoiceOption
               key={option.id}
               option={option}
               multiple={multiple}
               disabled
               checked={selected.includes(option.id)}
               responseEntry={response?.[option.id] ?? null}
               groupName={groupName}
               showMissed
               anyCorrect={!options.filter((i) => !!i.score).length}
            />
         ))}
      </div>
   );
};

export default MultipleChoicePrompt;
