import * as React from 'react';

import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { StringNumberUndefined } from '@models/Core';
import {
   CanDoStatementReportSummary,
   CanDoStatmentSummaryRow,
   ProficiencyLevelName,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';
import Tippy from '@tippyjs/react';

import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import { ProgressBar } from '@components/Core/ProgressBar';
import Loader from '@components/Loader';

interface CanDoStatementSummaryProps {
   standardCourseId?: StringNumberUndefined;
   courseId?: StringNumberUndefined;
   instructorId?: StringNumberUndefined;
   schoolId?: StringNumberUndefined;
   proficiencyLevelSelected?: ProficiencyLevelName;
}

const CanDoStatementSummary: React.FC<CanDoStatementSummaryProps> = (props) => {
   const [reportSummary, setReportSummary] = React.useState<
      CanDoStatementReportSummary | undefined
   >(undefined);

   const [filteredCanDoStatements, setFilteredCanDoStatements] = React.useState<
      CanDoStatmentSummaryRow[]
   >([]);

   React.useEffect(() => {
      ProficiencyReportService.getCanDoCompletionSummary(
         props.standardCourseId,
         props.schoolId ? [props.schoolId] : undefined,
         props.instructorId ? [props.instructorId] : undefined,
         props.courseId ? [props.courseId] : undefined,
      ).then((response) => {
         setReportSummary(response);
      });
   }, []);

   React.useEffect(() => {
      if (reportSummary) {
         setFilteredCanDoStatements(
            reportSummary.canDoStatementSummaries.filter(
               (x) =>
                  !props.proficiencyLevelSelected ||
                  x.performanceIndicatorLevel === props.proficiencyLevelSelected,
            ),
         );
      }
   }, [reportSummary]);

   if (!reportSummary) {
      return <Loader />;
   }

   if (filteredCanDoStatements.length === 0) {
      return (
         <div className='padding-top-s'>
            <EmptyState
               heading='Nothing to show'
               icon={<IconBinoculars className='large' aria-hidden />}
               description='No Can-Do statements to show for selected filters.'
            />
         </div>
      );
   }

   const renderProgressBar = (i: CanDoStatmentSummaryRow): React.ReactNode => {
      const percentCompleted = (i.totalNumberCompleted / reportSummary.studentCount) * 100;
      return (
         <Tippy
            content={`${i.totalNumberCompleted}/${reportSummary.studentCount} students`}
            key={i.canDoStatementId}
         >
            {/* For some reason the progress bar needs to be wrapped for tippy to work properly */}
            <span className='flex-center'>
               <ProgressBar color='blue' percentage={percentCompleted} />
            </span>
         </Tippy>
      );
   };

   const columns: readonly Column<CanDoStatmentSummaryRow>[] = [
      {
         id: 'canDoStatementName',
         header: 'Can-Do Statements',
         cell: (i) => i.canDoStatementName,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'progress',
         header: 'Progress',
         cell: (i) => renderProgressBar(i),
         canSort: true,
         sortFunc: (i) => i.performanceIndicatorMode.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'levelName',
         header: 'Level',
         cell: (i) => i.performanceIndicatorLevel,
         canSort: true,
         sortFunc: (i) => i.performanceIndicatorLevel.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'modeName',
         header: 'Mode',
         cell: (i) => i.performanceIndicatorMode,
         canSort: true,
         sortFunc: (i) => i.performanceIndicatorMode.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'skillName',
         header: 'Skill',
         cell: (i) => i.canDoStatementSkill,
         canSort: true,
         sortFunc: (i) => i.canDoStatementSkill.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
   ];

   return (
      <div style={{ backgroundColor: '#fff' }}>
         <Table<CanDoStatmentSummaryRow>
            columns={columns}
            rowKey='canDoStatementId'
            rows={filteredCanDoStatements}
         />
      </div>
   );
};

export default CanDoStatementSummary;
