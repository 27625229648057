import * as React from 'react';

import useMousetrap from '@hooks/use-mousetrap';
import useSubscription from '@hooks/use-subscription';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import { IAudioChoice } from '@components/VocabSession/Models';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';
import { MultipleChoiceEvaluation } from './Card';
import MultipleChoiceAudioOption from './MultipleChoiceAudioOption';

interface MultipleChoiceOptionsProps {
   choices: readonly IAudioChoice[];
   isAnswered: boolean;
   isReady: boolean;
   isSpeedSession: boolean;
   isTimedOut: boolean;
   check(id: Maybe<number>): MultipleChoiceEvaluation;
   playAudio(signal?: AbortSignal): Promise<Event>;
   onReadyToTransition(): void;
}

const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({
   choices,
   isAnswered,
   isReady,
   isSpeedSession,
   isTimedOut,
   check,
   onReadyToTransition,
   playAudio,
}) => {
   const numberKeys = Array.from('1234567890');

   const {
      emitter,
      state: { isMuted },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const [evaluation, setEvaluation] = React.useState<MultipleChoiceEvaluation>({
      correct: null,
      incorrect: null,
      missed: null,
   });

   const [selected, setSelected] = React.useState<Maybe<number>>(null);
   const [showMissed, setShowMissed] = React.useState<boolean>(false);

   const transitionTimeoutRef = React.useRef<Maybe<NodeJS.Timeout>>(null);
   const playAudioController = React.useRef<Maybe<AbortController>>(null);
   const showMissedTimeoutRef = React.useRef<Maybe<NodeJS.Timeout>>(null);

   const SHOW_MISSED_DELAY = 1000;
   const CORRECT_TRANSITION_DELAY = isMuted ? 1000 : 800;
   const INCORRECT_TRANSITION_DELAY = isMuted ? 2500 : 1000;

   React.useEffect(() => {
      if (!isAnswered) {
         setEvaluation({
            correct: null,
            incorrect: null,
            missed: null,
         });
         setShowMissed(false);
         setSelected(null);
      }
   }, [isAnswered]);

   React.useEffect(() => {
      if (isTimedOut) {
         checkAnswer(null);
      }
   }, [isTimedOut]);

   React.useEffect(() => {
      if (evaluation?.correct || showMissed) {
         playAudioController.current = new AbortController();
         playAudio(playAudioController.current.signal)
            .then(() => {
               if (!isTimedOut || isSpeedSession) {
                  transitionTimeoutRef.current = setTimeout(
                     () => onReadyToTransition(),
                     evaluation.correct ? CORRECT_TRANSITION_DELAY : INCORRECT_TRANSITION_DELAY,
                  );
               }
            })
            .catch((error) => {
               if (error.name === 'AbortError') {
                  return;
               }
            });
      } else if (evaluation.missed && !showMissed) {
         showMissedTimeoutRef.current = setTimeout(() => setShowMissed(true), SHOW_MISSED_DELAY);
      }

      return () => {
         showMissedTimeoutRef.current && clearTimeout(showMissedTimeoutRef.current);
         transitionTimeoutRef.current && clearTimeout(transitionTimeoutRef.current);
      };
   }, [evaluation, showMissed, isTimedOut]);

   const checkAnswer = (id: Maybe<number>): void => {
      if (!isAnswered && isReady) {
         setEvaluation(check(id));
      }
   };

   const handleClick = (id: number): void => {
      const choice = choices.find((i) => i.id === id);
      if (choice) {
         playChoiceAudio(choice);
         setSelected(id);
      }
   };

   const playChoiceAudio = (choice: IAudioChoice): void => {
      const audio = choice.audio;

      const audioPlayPromise = audio.play();
      if (!audioPlayPromise) {
         console.error('"audio.play()" did not return a promise');
      }
      if (audioPlayPromise !== undefined) {
         audioPlayPromise.catch((error) => {
            console.error('Failed to play audio (MultipleChoiceAudioOption.tsx)', {
               err: `${error?.name}: ${error?.message}`,
               currentSrc: audio.currentSrc,
            });
         });
      } else {
         console.error('"audio.play()" did not return a promise');
      }
   };

   const handleNextButtonClick = (): void => {
      if (isReady) {
         if (isAnswered) {
            if (playAudioController.current) {
               playAudioController.current.abort();
               if (transitionTimeoutRef.current) {
                  clearTimeout(transitionTimeoutRef.current);
                  transitionTimeoutRef.current = null;
               }
               onReadyToTransition();
            }
         } else {
            checkAnswer(selected);
         }
      }
   };

   const handleNumberKey = (event: KeyboardEvent): void => {
      const index = numberKeys.indexOf(event.key.toString());
      if (choices.length > index) {
         handleClick(choices[index].id);
      }
   };

   const handleSpace = (): void => {
      if (selected !== null) {
         const choice = choices.find((i) => i.id === selected);
         if (choice) {
            playChoiceAudio(choice);
         }
      }
   };

   useSubscription(emitter, 'NEXT_BUTTON_CLICK', handleNextButtonClick);
   useMousetrap('enter', handleNextButtonClick);
   useMousetrap('space', handleSpace);
   useMousetrap(numberKeys, handleNumberKey);

   const groupSize = 2;
   const rows = choices
      .map((_, i) => (i % groupSize ? null : choices.slice(i, i + groupSize)))
      .filter((i) => i);

   return (
      <div className='session-answer-choices audio'>
         {rows.map((cols, i) => (
            <div
               className={classnames('row', {
                  'margin-bottom-s': i < rows.length - 1,
               })}
               key={i}
            >
               {cols?.map(({ id: choiceId }) => (
                  <div className='session-audio-choice' key={choiceId}>
                     <MultipleChoiceAudioOption
                        onClick={() => handleClick(choiceId)}
                        correct={isAnswered && evaluation.correct === choiceId}
                        incorrect={isAnswered && evaluation.incorrect === choiceId}
                        missed={isAnswered && showMissed && evaluation.missed === choiceId}
                        selected={selected === choiceId}
                        disabled={isAnswered}
                     />
                  </div>
               ))}
            </div>
         ))}
      </div>
   );
};

export default React.memo(MultipleChoiceOptions);
