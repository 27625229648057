import * as React from 'react';

import Button from '@components/Common/Button';

type ThemeColor = 'green' | 'blue';

interface ButtonPanelProps {
   buttonText: string;
   dataTest: string;
   description: string;
   themeColor: ThemeColor;
   icon: React.SVGFactory;
   onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
   title: string;
}

const DarkColorLookup: Record<ThemeColor, string> = {
   green: '#4a7d35',
   blue: '#007cbb',
};

const BackgroundStyleLookup: Record<
   ThemeColor,
   Pick<React.CSSProperties, 'background' | 'border'>
> = {
   green: {
      background: '#f1f7ed',
      border: `1px solid ${DarkColorLookup.green}`,
   },
   blue: {
      background: '#eaf7ff',
      border: `1px solid ${DarkColorLookup.blue}`,
   },
};

const ButtonPanel: React.FC<ButtonPanelProps> = (props) => {
   const [isOver, setIsOver] = React.useState<boolean>(false);
   const [styles, setStyles] = React.useState<React.CSSProperties>({});
   const color = BackgroundStyleLookup[props.themeColor];

   React.useEffect(() => {
      if (isOver) {
         setStyles(color);
      } else {
         setStyles({});
      }
   }, [isOver]);

   return (
      <div
         className='button-panel'
         data-test={`btn-panel-${props.dataTest}`}
         style={styles}
         onMouseEnter={() => setIsOver(true)}
         onMouseLeave={() => setIsOver(false)}
      >
         <div className='body'>
            <props.icon style={{ fill: DarkColorLookup[props.themeColor] }} />
            <h3 className='margin-top-s'>{props.title}</h3>
            <p className='gray small'>{props.description}</p>
            {isOver && (
               <Button
                  data-test={`btn-${props.dataTest}`}
                  onClick={props.onClick}
                  color={props.themeColor}
               >
                  {props.buttonText}
               </Button>
            )}
         </div>
      </div>
   );
};

export default ButtonPanel;
