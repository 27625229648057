import * as React from 'react';

import { Maybe } from '@models/Core';
import SchoolProfile from '@models/SchoolProfile';
import {
   RegisteringUser,
   RegistrationError,
   RegistrationErrorName,
} from '@services/RegistrationService';
import SchoolService from '@services/SchoolService';

import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import SchoolSelect from '@components/SchoolSelect';
import RegisterError from './RegisterError';

interface SchoolSearchStepProps {
   isLoading: boolean;
   errors: readonly RegistrationError[];
   user: RegisteringUser;
   handleUserChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void;
   setSchool(school: Maybe<SchoolProfile>, submitRegistration?: boolean): void;
}

const SchoolSearchStep: React.FC<SchoolSearchStepProps> = ({
   errors,
   isLoading,
   user,
   handleUserChange,
   setSchool: updateSchool,
}) => {
   const apiError = !!errors.find((i) => i.name === RegistrationErrorName.apiError);
   const [school, setSchool] = React.useState<Maybe<SchoolProfile>>(null);
   const [noSchoolPrev, setNoSchoolPrev] = React.useState<Maybe<SchoolProfile>>(null);
   const [noSchoolFound, setNoSchoolFound] = React.useState<boolean>(false);

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      updateSchool(school, true);
   };

   const handleNoSchoolChange = (): void => {
      const newState = !noSchoolFound;
      setNoSchoolFound(newState);
      if (newState) {
         setNoSchoolPrev(school);
         setSchool(null);
         SchoolService.getNoSchoolFound().then((response) => {
            setSchool(response);
         });
      } else {
         setSchool(noSchoolPrev);
      }
   };

   return (
      <div className='card-content-onboarding card padding-bottom-m'>
         <div className='onboarding-title border-bottom text-center'>
            <DocumentTitle>School Verification</DocumentTitle>
            <h2 aria-live='polite' className='uppercase no-margin'>
               School Verification
            </h2>
         </div>
         <form className='content-form' onSubmit={handleSubmit}>
            <div className='row'>
               <div className='col-xs-12'>
                  <SchoolSelect
                     autoFocus
                     data-test='school-search'
                     isDisabled={noSchoolFound}
                     onChange={setSchool}
                     placeholder={noSchoolFound ? '' : "Type your school's name or postal code"}
                     value={school}
                  />
               </div>
            </div>
            <div className='row margin-top-s'>
               <div className='col-xs-12'>
                  <input
                     type='checkbox'
                     id='no-school-found'
                     data-test='no-school-found'
                     checked={noSchoolFound}
                     onChange={handleNoSchoolChange}
                  />
                  <label htmlFor='no-school-found' className='black-text'>
                     I'm unable to find my school
                  </label>
               </div>
            </div>
            {noSchoolFound && (
               <div className='row margin-top-s'>
                  <div className='col-xs-12'>
                     <label htmlFor='missing-school-url'>
                        Please enter your school's website address (ex: www.school.edu)
                     </label>
                     <input
                        autoFocus
                        data-test='missing-school-url'
                        id='missing-school-url'
                        className='margin-top-s'
                        name='missingSchoolUrl'
                        onChange={handleUserChange}
                        type='text'
                        value={user.missingSchoolUrl}
                     />
                  </div>
               </div>
            )}
            <Button
               type='submit'
               fullWidth
               data-test='continue'
               disabled={!school || (noSchoolFound && !user.missingSchoolUrl) || apiError}
               loading={isLoading}
               className='margin-top-m'
            >
               Continue
            </Button>
            <RegisterError errors={errors} />
         </form>
      </div>
   );
};

export default SchoolSearchStep;
