import * as React from 'react';

import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import AddContentModal from '@components/ContentLibrary/AddContentModal';
import IconVideoCall1 from '@icons/nova-solid/15-Video-Meeting/video-call-1.svg';
import {
   ActivityBuilderMode,
   VideoLibraryContent as VideoLibraryContentType,
   VideoSource,
} from '@models/Activity';
import { ContentType } from '@models/Content';
import ContentItemProfile from '@models/ContentItemProfile';

import { AppStateContext } from '../../../../../AppState';

interface SelectVideoLibraryContentProps {
   item: VideoLibraryContentType<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<VideoLibraryContentType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const SelectVideoLibraryContent: React.FC<SelectVideoLibraryContentProps> = ({
   item,
   handleItemUpdate,
}) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const [addContentModalOpen, setAddContentModalOpen] = React.useState<boolean>(false);
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }

   const handleAddLibraryItem = ({ itemId: videoId }: ContentItemProfile): void => {
      handleItemUpdate({
         ...item,
         source: VideoSource.library,
         videoId,
      });
      closeAddContentModal();
   };

   const openAddContentModal = (): void => {
      setAddContentModalOpen(true);
   };

   const closeAddContentModal = (): void => {
      setAddContentModalOpen(false);
   };

   return (
      <>
         {addContentModalOpen && (
            <AddContentModal
               addItem={handleAddLibraryItem}
               contentTypes={[ContentType.video]}
               heading='Add Video to Activity'
               addButtonTooltip='Add Video'
               addedItemIds={[item.videoId]}
               language={language}
               onClose={closeAddContentModal}
            />
         )}

         <div className='upload-dropzone' onClick={openAddContentModal}>
            <div className='icon-wrapper'>
               <IconVideoCall1 />
            </div>
            <span>Click here to choose a video</span>
         </div>
      </>
   );
};

export default SelectVideoLibraryContent;
