import * as React from 'react';

import IconVideoRecording from '@icons/nova-solid/15-Video-Meeting/video-call-1.svg';
import { VideoRecordingResponse } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';

import { AppStateContext } from '../../../../AppState';
import Button from '@components/Common/Button';
import OpentokRecorder from '@components/OpentokRecorder';
import AudioWaveform from '@components/Activity/Grader/Prompts/AudioWaveform';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface VideoPromptProps extends CommonPromptProps {
   isClosed: boolean;
   response: VideoRecordingResponse;
   setResponse(response: VideoRecordingResponse, callback?: () => void): void;
}

const VideoPrompt: React.FC<VideoPromptProps> = ({
   isClosed,
   response: { fileUrl, instructorAnnotations, annotationAuthors },
   setResponse,
   saveResponse,
}) => {
   const [showRecorder, setShowRecorder] = React.useState<boolean>(false);

   const videoElement = React.useRef<HTMLVideoElement>(null);

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { id: userId, firstName, lastName, profileImageUrl, email } = userProfile;

   const hasAnnotations = instructorAnnotations && !!instructorAnnotations.length;

   const clearSubmission = (): void => {
      setResponse(
         {
            fileUrl: null,
            sessionId: null,
            annotationAuthors: {},
            instructorAnnotations: [],
         },
         saveResponse,
      );
   };

   const handleRecordingComplete = (sessionId: number, url: string): void => {
      setResponse(
         {
            fileUrl: url,
            sessionId,
         },
         saveResponse,
      );
      setShowRecorder(false);
   };

   const getAuthor = (createdBy: number): Maybe<BasicUserProfile> => {
      if (createdBy === userId) {
         return { id: userId, firstName, lastName, profileImageUrl, email };
      } else if (annotationAuthors && createdBy) {
         return annotationAuthors[createdBy];
      }
   };

   if (fileUrl) {
      return (
         <>
            <div className='video-responsive standard'>
               <video
                  className='elem'
                  src={fileUrl}
                  controls={!hasAnnotations}
                  ref={videoElement}
               />
            </div>
            {hasAnnotations && videoElement.current && (
               <AudioWaveform
                  canEdit={false}
                  media={videoElement.current}
                  annotations={instructorAnnotations}
                  getAuthor={getAuthor}
               />
            )}
            {!isClosed && (
               <div className='justify-center align-items-center margin-top-s'>
                  <Button color='red' onClick={clearSubmission}>
                     Clear Submission
                  </Button>
               </div>
            )}
         </>
      );
   }

   if (!isClosed) {
      return showRecorder ? (
         <OpentokRecorder onRecordingComplete={handleRecordingComplete} />
      ) : (
         <Button
            color='red'
            onClick={() => setShowRecorder(true)}
            icon={<IconVideoRecording className='icon-white' aria-hidden />}
         >
            Record
         </Button>
      );
   }

   return null;
};

export default VideoPrompt;
