type NestedKey<T> = ((item: T) => string) | keyof T;

/**
 * `naturalSort` sorts an array of objects in natural (human-friendly) order based on the provided key.
 * The sorting is performed based on the current locale. The key can either be a property of T, or a function
 * that takes an item of type T and returns a string.
 *
 * @template T The type of items in the array.
 *
 * @param {readonly T[]} items - A read-only array of items of type T to be sorted.
 * @param {((item: T) => string) | keyof T} key - The key used for sorting the items.
 *        It can be a property of T, or a function that takes an item of type T and returns a string.
 *
 * @returns {readonly T[]} A new array of the same items sorted in natural order.
 */
const naturalSort = <T>(items: readonly T[], key?: NestedKey<T>): readonly T[] =>
   [...items].sort((a, b) => {
      let aValue: string;
      let bValue: string;

      if (typeof key === 'function') {
         aValue = key(a);
         bValue = key(b);
      } else if (key) {
         aValue = a[key] as unknown as string;
         bValue = b[key] as unknown as string;
      } else if (typeof a === 'string' && typeof b === 'string') {
         aValue = a;
         bValue = b;
      } else {
         throw Error('The key must be provided when sorting an array of objects.');
      }

      return aValue.localeCompare(bValue, navigator.languages[0] || navigator.language, {
         numeric: true,
         ignorePunctuation: true,
      });
   });

export default naturalSort;
