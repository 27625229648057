import * as React from 'react';

import { ProficiencyReportSkillData } from '@models/Proficiency';

interface ProficiencyReportProgressBarProps {
   skillData: ProficiencyReportSkillData;
   size: 'sm' | 'md' | 'lg' | 'xl' | 'full';
   progressBarClassName?: string;
}

const ProficiencyReportProgressBar: React.FC<ProficiencyReportProgressBarProps> = (props) => {
   const [percentFinished, setPercentFinished] = React.useState<number>(0);
   const [percentGoal, setPercentGoal] = React.useState<number>(0);

   // This allows us to force the css animation
   React.useEffect(() => {
      setTimeout(() => {
         setPercentFinished(
            (props.skillData.completedAverage / props.skillData.totalCount) * 100 || 0,
         );
         setPercentGoal((props.skillData.presentedCount / props.skillData.totalCount) * 100 || 0);
      }, 0);
   }, [props]);

   return (
      <div className={`proficiency-progress-bar-container ${props.size}`}>
         <div className={`proficiency-progress-bar ${props.progressBarClassName || ''}`}>
            <div
               className='proficiency-progress-bar-filled blue'
               style={{ width: `${percentFinished}%` }}
            />

            <div
               className='proficiency-progress-bar-goal'
               style={{ left: `calc(${percentGoal}% - 3px)` }}
            />
         </div>
      </div>
   );
};

export default ProficiencyReportProgressBar;
