import * as _ from 'lodash';
import * as React from 'react';

import useStateWithReset from '@hooks/use-state-with-reset';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import DateTimePicker from '@components/Core/DateTimePicker';
import InfoTooltip from '@components/InfoTooltip';
import { getAssignmentEndDateError, getAssignmentStartDateError } from './ModuleUtils';

export interface AssignmentDatePickerProvided {
   className: string;
   disabled: boolean;
   maxDate: Date;
   minDate: Date;
   onChange(value: Date): void;
   value: Date;
}

interface AssignmentDatePickerProps {
   allowNull?: boolean;
   courseEndDate: Date;
   courseStartDate: Date;
   disabled?: boolean;
   endDate: Date;
   endDateLabel?: string;
   endDateTooltip?: string;
   startDate: Date;
   startDateLabel?: string;
   startDateTooltip?: string;
   /** Variables to watch and reset on */
   resetOnChange?: readonly number[];
   /** Render Props */
   children?(
      startDateProvided: AssignmentDatePickerProvided,
      startDateError: Maybe<string>,
      endDateProvided: AssignmentDatePickerProvided,
      endDateError: Maybe<string>,
   ): JSX.Element;
   onInvalidDate(): void;
   onValidDateChange(startDate: Date, endDate: Date): void;
}

const AssignmentDatePicker: React.FC<AssignmentDatePickerProps> = ({
   allowNull = false,
   children,
   courseEndDate,
   courseStartDate,
   disabled = false,
   endDate: propsEndDate,
   endDateLabel = 'End Date',
   endDateTooltip = '',
   resetOnChange = [],
   startDate: propsStartDate,
   startDateLabel = 'Start Date',
   startDateTooltip = '',
   onValidDateChange,
   onInvalidDate,
}) => {
   const [startDatePickerValue, setStartDatePickerValue] = useStateWithReset<Date>(
      propsStartDate,
      resetOnChange,
   );
   const [endDatePickerValue, setEndDatePickerValue] = useStateWithReset<Date>(
      propsEndDate,
      resetOnChange,
   );
   const [startDateError, setStartDateError] = React.useState<Maybe<string>>(null);
   const [endDateError, setEndDateError] = React.useState<Maybe<string>>(null);

   React.useEffect(() => {
      const updatedStartDateError = getAssignmentStartDateError(
         startDatePickerValue,
         endDatePickerValue,
         courseStartDate,
         courseEndDate,
         allowNull,
      );
      const updatedEndDateError = endDateError
         ? getAssignmentEndDateError(
              startDatePickerValue,
              endDatePickerValue,
              courseStartDate,
              courseEndDate,
              allowNull,
           )
         : null;
      setStartDateError(updatedStartDateError);
      setEndDateError(updatedEndDateError);
      if (
         !updatedStartDateError &&
         !updatedEndDateError &&
         startDatePickerValue !== propsStartDate
      ) {
         onValidDateChange(startDatePickerValue, endDatePickerValue);
      } else if (updatedStartDateError) {
         onInvalidDate();
      }
   }, [startDatePickerValue]);

   React.useEffect(() => {
      const updatedStartDateError = startDateError
         ? getAssignmentStartDateError(
              startDatePickerValue,
              endDatePickerValue,
              courseStartDate,
              courseEndDate,
              allowNull,
           )
         : null;
      const updatedEndDateError = getAssignmentEndDateError(
         startDatePickerValue,
         endDatePickerValue,
         courseStartDate,
         courseEndDate,
         allowNull,
      );
      setStartDateError(updatedStartDateError);
      setEndDateError(updatedEndDateError);
      if (!updatedStartDateError && !updatedEndDateError && endDatePickerValue !== propsEndDate) {
         onValidDateChange(startDatePickerValue, endDatePickerValue);
      } else if (updatedEndDateError) {
         onInvalidDate();
      }
   }, [endDatePickerValue]);

   const startDateProvided: AssignmentDatePickerProvided = {
      className: classnames({ error: !!startDateError, disabled }),
      disabled,
      maxDate: courseEndDate,
      minDate: courseStartDate,
      onChange: setStartDatePickerValue,
      value: startDatePickerValue,
   };

   const endDateProvided: AssignmentDatePickerProvided = {
      className: classnames({ error: !!endDateError, disabled }),
      disabled,
      maxDate: courseEndDate,
      minDate: courseStartDate,
      onChange: setEndDatePickerValue,
      value: endDatePickerValue,
   };

   if (_.isFunction(children)) {
      return children(startDateProvided, startDateError, endDateProvided, endDateError);
   }

   return (
      <div className='row'>
         <div className='col-xs-12 col-sm-6'>
            <label className='field-title'>
               {startDateLabel}
               {startDateTooltip && <InfoTooltip>{startDateTooltip}</InfoTooltip>}
            </label>
            <DateTimePicker {...startDateProvided} />
            {startDateError && <p className='error text-sm'>{startDateError}</p>}
         </div>
         <div className='col-xs-12 col-sm-6'>
            <label className='field-title'>
               {endDateLabel}
               {endDateTooltip && <InfoTooltip>{endDateTooltip}</InfoTooltip>}
            </label>
            <DateTimePicker {...endDateProvided} />
            {endDateError && <p className='error text-sm'>{endDateError}</p>}
         </div>
      </div>
   );
};

export default AssignmentDatePicker;
