import * as React from 'react';

import AddButton from '@components/Common/AddButton';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import BasicUserProfile from '@models/BasicUserProfile';

import AddExamAdminModal from './AddExamAdminModal';
import RemoveAdmin from './RemoveAdmin';

type ExamAdminsPanelProps = {
   disableUpdates: boolean;
   examId: number;
   examAdmins: readonly BasicUserProfile[];
   setExamAdmins(admins: BasicUserProfile[]): void;
};

const ExamAdminsPanel: React.FC<ExamAdminsPanelProps> = (props) => {
   const [isAddExamAdminModalOpen, setIsAddExamAdminModalOpen] = React.useState<boolean>(false);

   const renderActions = (row: BasicUserProfile): React.ReactNode => (
      <RemoveAdmin
         adminUserId={row.id}
         examId={props.examId}
         isDisabled={props.disableUpdates}
         setExamAdmins={props.setExamAdmins}
      />
   );

   const adminColumns: readonly Column<BasicUserProfile>[] = [
      {
         id: 'id',
         header: 'Id',
         cell: (i) => i.id,
      },
      {
         id: 'name',
         header: 'Name',
         cell: (i) => `${i.firstName} ${i.lastName}`,
      },
      {
         id: 'email',
         header: 'Email',
         cell: (i) => i.email,
      },
      { id: 'remove', header: 'Actions', cell: renderActions },
   ];

   return (
      <SectionCard
         title='Exam Admins'
         headerButton={
            !props.disableUpdates ? (
               <AddButton
                  aria-label='Add Exam Admin'
                  onClick={() => setIsAddExamAdminModalOpen(true)}
               />
            ) : undefined
         }
      >
         <div className='row margin-top-s'>
            <div className='col-xs-12'>
               <Table
                  columns={adminColumns}
                  rows={props.examAdmins}
                  rowKey='id'
                  defaultSortBy={[{ id: 'id', desc: false }]}
               />
            </div>
         </div>
         {isAddExamAdminModalOpen && (
            <AddExamAdminModal
               examId={props.examId}
               setExamAdmins={props.setExamAdmins}
               closeModal={() => setIsAddExamAdminModalOpen(false)}
            />
         )}
      </SectionCard>
   );
};

export default ExamAdminsPanel;
