import * as React from 'react';

import { IOnboardingProps } from './Models';
import { OnboardingConsumer } from './OnboardingContext';

const withOnboarding = <P extends object>(Component: React.ComponentType<P & IOnboardingProps>) =>
   function OnboardingComponent(props: Partial<P & IOnboardingProps>) {
      return (
         <OnboardingConsumer>
            {(contexts) => <Component {...(props as P & IOnboardingProps)} {...contexts} />}
         </OnboardingConsumer>
      );
   };

export default withOnboarding;
