import 'react-loading-skeleton/dist/skeleton.css';

import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import IconPaperGradeA from '@icons/nova-solid/41-School&Science/paper-grade-a.svg';
import AccountType from '@models/AccountType';
import { CourseSectionSummary } from '@models/Course';
import CourseService from '@services/CourseService';
import TaskListService, { Task } from '@services/TaskListService';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';

import { AppStateContext } from '../../AppState';
import mixPanelActions from '../../Mixpanel';
import Link from '@components/Common/Link';
import EmptyState from '@components/Core/EmptyState';
import FilterDropDown, { Option } from '@components/Core/FilterDropDown';
import { getUngradedSubmissionsLink } from './Helpers';

interface DashboardTaskListProps {
   courseId: number;
}

const DashboardTaskList: React.FC<DashboardTaskListProps> = (props) => {
   const rowHeight = 50;

   const [tasks, setTasks] = React.useState<readonly Task[]>([]);
   const [options, setOptions] = React.useState<Option[]>([]);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [hasSections, setHasSections] = React.useState<boolean>(true);

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const isInstructor = userProfile?.accountType === AccountType.instructor;

   // The Dashboard is re-rendering multiple times when we go from one course to another.
   // In order to prevent multiple network calls on initial load I'm tracking the course
   // id in state as well. This should not be happening, but I think it is a result of
   // something with react router potentially.
   const [courseId, setCourseId] = React.useState<number>(props.courseId);
   React.useEffect(() => {
      if (courseId !== props.courseId) {
         setCourseId(props.courseId);
      }
   }, [props.courseId]);

   const getOptionsFromSections = (
      courseSections: readonly CourseSectionSummary[],
      instructorSections: readonly CourseSectionSummary[],
   ): Option[] => {
      const instructorSectionIds = instructorSections.map((x) => x.id);

      const courseSectionOptions: Option[] = courseSections.map((x) => ({
         ...x,
         isChecked: instructorSectionIds.includes(x.id),
      }));

      return courseSectionOptions;
   };

   const getTasks = (courseSectionIds: readonly number[]): Promise<void> => {
      setIsFetching(true);
      return TaskListService.getTasks(props.courseId, courseSectionIds).then((data) => {
         setTasks(data.tasks);
         setIsFetching(false);
      });
   };

   const getCheckedSectionIds = (): number[] => options.filter((x) => x.isChecked).map((x) => x.id);

   React.useEffect(() => {
      CourseService.getCourseSections(props.courseId).then((r) => {
         const initialOptions = getOptionsFromSections(r.courseSections, r.instructorSections);
         setOptions(initialOptions);
         setHasSections(initialOptions.length > 0);
      });
   }, [courseId]);

   React.useEffect(() => {
      if ((options.length > 0 && hasSections) || !hasSections) {
         getTasks(getCheckedSectionIds());
      }
   }, [options, hasSections]);

   const trackUsage = (moduleItemId: number): void => {
      if (isInstructor) {
         mixPanelActions.track(
            'Task List Used',
            snakeCaseKeys({
               courseId,
               moduleItemId,
            }),
         );
      }
   };

   const rowRenderer: ListRowRenderer = ({ key, index, style }): React.ReactNode => {
      const task = tasks[index];
      const component = (
         <div key={key} style={style} className='task-item'>
            <div className='title-icon-wrapper'>
               <div className='icon-module-item activity'>
                  <IconPaperGradeA />
               </div>
               <div className='content-title'>
                  <Link
                     data-test='link-task-assignment'
                     onClick={() => trackUsage(task.moduleItemId)}
                     to={getUngradedSubmissionsLink(task.moduleItemId)}
                  >
                     Grade {task.assignmentName}
                  </Link>
               </div>
            </div>
         </div>
      );
      return component;
   };

   return (
      <div className='card margin-top-m no-padding margin-bottom-m'>
         <div className='card-title'>
            <div className='title'>Tasks</div>
            <FilterDropDown
               options={options}
               onSelectionChange={setOptions}
               icon={<IconContentFilter aria-hidden />}
               sort
            />
         </div>
         <div
            className={classnames('task-list-container', {
               empty: !isFetching && tasks.length === 0,
            })}
         >
            {isFetching && <Skeleton height={rowHeight} count={3} />}
            {!isFetching && tasks.length === 0 && (
               <EmptyState icon={<IconPaperGradeA aria-hidden />} heading='All tasks complete!' />
            )}
            {!isFetching && tasks.length > 0 && (
               <AutoSizer>
                  {({ height, width }) => (
                     <List
                        width={width}
                        height={height}
                        rowHeight={rowHeight}
                        overscanRowCount={10}
                        rowCount={tasks.length}
                        rowRenderer={rowRenderer}
                     />
                  )}
               </AutoSizer>
            )}
         </div>
      </div>
   );
};

export default DashboardTaskList;
