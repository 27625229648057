import * as React from 'react';

import EmptyState from '@components/Core/EmptyState';
import IconCursorSelectArea2 from '@icons/nova-solid/16-Selection&Cursors/cursor-select-area-2.svg';
import { Maybe } from '@models/Core';
import { Assignment, CourseSection, GradingCategory, ModuleItem } from '@models/Course';
import LMSName from '@models/LMSName';
import pluralize from 'pluralize';

import ActionIconBar from './ActionIconBar';
import AssignmentDetails from './AssignmentDetails';
import FileDetails from './FileDetails';
import LinkDetails from './LinkDetails';
import { isAssignable, isFile, isLink, isVideo } from './ModuleUtils';
import VideoDetails from './VideoDetails';

interface ModuleItemDetailsProps {
   courseId: number;
   moduleId: number;
   activeItem: Maybe<ModuleItem>;
   canEditModule: boolean;
   canEditModuleItem: boolean;
   canEditSecretCode: boolean;
   canPreviewModuleItemContent: boolean;
   courseEndDate: Date;
   courseStartDate: Date;
   emptyAssignment: Assignment;
   gradingCategories: readonly GradingCategory[];
   isSaving: boolean;
   lmsName: Maybe<LMSName>;
   sections: readonly CourseSection[];
   selectedItemCount: number;
   showCheckboxes: boolean;
   createGradingCategory(label: string): Promise<number>;
   editItem(update: Partial<ModuleItem>): void;
   openRemoveItemModal(): void;
   postItemToLMS(): Promise<void>;
   syncGradesWithLMS(): Promise<void>;
}

const ModuleItemDetails: React.FC<ModuleItemDetailsProps> = ({
   courseId,
   moduleId,
   activeItem,
   canEditModule,
   canEditModuleItem,
   canEditSecretCode,
   canPreviewModuleItemContent,
   courseEndDate,
   courseStartDate,
   emptyAssignment,
   gradingCategories,
   isSaving,
   lmsName,
   sections,
   selectedItemCount,
   showCheckboxes,
   createGradingCategory,
   editItem,
   openRemoveItemModal,
   postItemToLMS,
   syncGradesWithLMS,
}) => {
   const [isInvalid, setIsInvalid] = React.useState<boolean>(false);

   React.useEffect(() => {
      setIsInvalid(false);
   }, [isSaving, activeItem?.id]);

   const multipleItemsSelected = selectedItemCount > 1;
   const noItemsSelected = selectedItemCount === 0 || activeItem === null;

   const getItemDetails = (): Maybe<React.ReactNode> => {
      if (!activeItem) {
         return null;
      } else if (isLink(activeItem)) {
         return (
            <LinkDetails canEditModule={canEditModuleItem} editItem={editItem} item={activeItem} />
         );
      } else if (isFile(activeItem)) {
         return (
            <FileDetails canEditModule={canEditModuleItem} editItem={editItem} item={activeItem} />
         );
      } else if (isVideo(activeItem)) {
         return (
            <VideoDetails canEditModule={canEditModuleItem} editItem={editItem} item={activeItem} />
         );
      } else if (isAssignable(activeItem) && activeItem.assignment) {
         return (
            <AssignmentDetails
               courseId={courseId}
               moduleId={moduleId}
               activeItem={activeItem}
               assignment={activeItem.assignment}
               canEditModule={canEditModuleItem}
               canEditSecretCode={canEditSecretCode}
               courseEndDate={courseEndDate}
               courseStartDate={courseStartDate}
               createGradingCategory={createGradingCategory}
               defaultAssignment={emptyAssignment}
               editItem={editItem}
               gradingCategories={gradingCategories}
               isAssigned={activeItem.isAssigned}
               itemId={activeItem.id}
               itemType={activeItem.itemType}
               lmsCourseworkIds={activeItem.lmsCourseworkIds}
               lmsName={lmsName}
               postItemToLMS={postItemToLMS}
               sections={sections}
               setIsInvalid={setIsInvalid}
               syncGradesWithLMS={syncGradesWithLMS}
            />
         );
      }
      return null;
   };

   if (noItemsSelected && !showCheckboxes) {
      return (
         <EmptyState
            description='Select an item to view its details'
            heading='Item Details'
            icon={<IconCursorSelectArea2 aria-hidden />}
         />
      );
   } else if (multipleItemsSelected || showCheckboxes) {
      const noItemsDescription =
         'Click the checkboxes of one or more items to make changes in bulk';
      const itemsSelectedDescription =
         'Click the bulk actions button at the top to make changes to all of the selected items at once.';
      return (
         <EmptyState
            description={noItemsSelected ? noItemsDescription : itemsSelectedDescription}
            heading={`${selectedItemCount} ${pluralize('Item', selectedItemCount)} Selected`}
            icon={<IconCursorSelectArea2 aria-hidden />}
         />
      );
   }

   if (!activeItem) {
      return null;
   }
   return (
      <>
         <ActionIconBar
            canEditModule={canEditModule}
            canPreviewContent={canPreviewModuleItemContent}
            isInvalid={isInvalid}
            isSaving={isSaving}
            item={activeItem}
            openRemoveModal={openRemoveItemModal}
         />
         {getItemDetails()}
      </>
   );
};

export default ModuleItemDetails;
