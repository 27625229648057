import * as _ from 'lodash';
import * as React from 'react';

import { Maybe } from '@models/Core';
import SchoolProfile from '@models/SchoolProfile';
import SchoolService from '@services/SchoolService';
import classnames from 'classnames';

import {
   AsyncSelect,
   CSSObjectWithLabel,
   Menu,
   MenuProps,
   Select,
   selectComponents,
   selectStyle,
   selectTheme,
} from './Core/Select';

export type SchoolOption = {
   value: SchoolProfile;
   label: string;
};

interface SchoolSelectProps {
   autoFocus?: boolean;
   isDisabled?: boolean;
   className?: string;
   name?: string;
   placeholder?: string;
   ssoOnly?: boolean;
   minCharacters?: number;
   value: Maybe<SchoolProfile>;
   options?: readonly SchoolProfile[];
   onChange(value: Maybe<SchoolProfile>): void;
}

const SchoolSelect: React.FC<SchoolSelectProps> = ({
   autoFocus = false,
   isDisabled = false,
   minCharacters = 3,
   className = '',
   name,
   placeholder,
   ssoOnly = false,
   value: school,
   options,
   onChange,
}) => {
   const [inputValue, setInputValue] = React.useState<string>('');

   const loadOptions = (
      query: string,
      callback: (newOptions: readonly SchoolOption[]) => void,
   ): void => {
      if (query.length < minCharacters) {
         callback([]);
      } else {
         SchoolService.schoolSearch({ query, ssoOnly }).then((results) => {
            callback(results.map((i) => ({ label: i.name, value: i })));
         });
      }
   };

   const handleChange = (newValue: Maybe<SchoolOption>): void => {
      onChange(newValue ? newValue.value : null);
   };

   const debouncedLoadOptions = React.useCallback(_.debounce(loadOptions, 500), []);

   const renderMenu: React.ComponentType<MenuProps<SchoolOption, false>> = ({
      children,
      ...rest
   }) => (inputValue ? <Menu {...rest}>{children}</Menu> : null);

   const formatOptionLabel = ({
      value: { name: schoolName, city, state, zipCode },
   }: SchoolOption): React.ReactNode => (
      <div>
         <div>{schoolName}</div>
         {(city || state || zipCode) && (
            <div className='details'>
               {city}, {state} {zipCode}
            </div>
         )}
      </div>
   );

   const errorStyle = {
      borderColor: '#CD1D1D',
      boxShadow: '0 0 0 1px #CD1D1D',
   };

   const commonProps = {
      'aria-label': placeholder,
      autoFocus,
      className: classnames('react-select', 'school-search', className),
      formatOptionLabel,
      inputValue,
      isClearable: true,
      isDisabled,
      name,
      onChange: handleChange,
      onInputChange: setInputValue,
      placeholder,
      theme: selectTheme,
      styles: {
         ...selectStyle,
         control: (styles: CSSObjectWithLabel) => ({
            ...styles,
            minHeight: '50px',
            ...(className.split(' ').includes('error') ? errorStyle : {}),
         }),
      },
      components: selectComponents,
   };

   return options ? (
      <Select<SchoolOption>
         {...commonProps}
         options={options?.map((i) => ({ label: i.name, value: i }))}
         noOptionsMessage={() => 'No Schools Found'}
      />
   ) : (
      <AsyncSelect<SchoolOption>
         {...commonProps}
         defaultValue={school ? { value: school, label: school.name } : null}
         loadOptions={debouncedLoadOptions}
         noOptionsMessage={(i) =>
            i.inputValue.length >= minCharacters ? 'No Schools Found' : 'Loading...'
         }
         components={{
            ...selectComponents,
            DropdownIndicator: () => null,
            Menu: renderMenu,
         }}
      />
   );
};

export default SchoolSelect;
