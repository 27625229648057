import * as React from 'react';

import AddButton from '@components/Common/AddButton';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import FeatureCheck from '@components/Core/FeatureCheck';
import AddCourseModal from '@components/Course/AddCourseModal';
import { NationalExamCourseSummary } from '@services/NationalExamAdminService';

import Constants from '../../Constants';
import DownloadResponses from './DownloadResponses';
import ExtendEndDatesModal from './ExtendEndDatesModal';

type ExamCoursesPanelProps = {
   disableUpdates: boolean;
   examId: number;
   examCourses: readonly NationalExamCourseSummary[];
   setExamCourses(courses: NationalExamCourseSummary[]): void;
};

const ExamCoursesPanel: React.FC<ExamCoursesPanelProps> = (props) => {
   const [isAddCourseModalOpen, setIsAddCourseModalOpen] = React.useState<boolean>(false);
   const [isExtendEndDatesModalOpen, setIsExtendEndDatesModalOpen] = React.useState<boolean>(false);

   const renderActions = (row: NationalExamCourseSummary): React.ReactNode => (
      <DownloadResponses examCourseId={row.courseId} />
   );

   const courseColumns: readonly Column<NationalExamCourseSummary>[] = [
      {
         id: 'id',
         header: 'Id',
         cell: (i) => i.id,
      },
      {
         id: 'levelName',
         header: 'Level Name',
         cell: (i) => i.levelName,
      },
      {
         id: 'levelSortOrder',
         header: 'Level Sort Order',
         cell: (i) => i.levelSortOrder,
      },
      {
         id: 'courseId',
         header: 'Course Id',
         cell: (i) => (
            <Link to={Constants.routes.courses.dashboard.replace(':courseId', String(i.courseId))}>
               {i.courseId}
            </Link>
         ),
      },
      { id: 'courseName', header: 'Course Name', cell: (i) => i.courseName },
      { id: 'downloadQuestionnaire', header: 'Actions', cell: renderActions },
   ];

   return (
      <SectionCard
         title='Exam Courses'
         headerButton={
            !props.disableUpdates ? (
               <AddButton
                  aria-label='Add Exam Course'
                  onClick={() => setIsAddCourseModalOpen(true)}
               />
            ) : undefined
         }
      >
         <div className='row margin-top-s'>
            <div className='col-xs-12'>
               <Table
                  columns={courseColumns}
                  rows={props.examCourses}
                  rowKey='id'
                  defaultSortBy={[{ id: 'id', desc: false }]}
               />
            </div>
         </div>
         <FeatureCheck feature='national_exam_extension'>
            <div className='row margin-top-s'>
               <div className='col-xs-12'>
                  <Button fullWidth onClick={() => setIsExtendEndDatesModalOpen(true)}>
                     Extend module and course end dates
                  </Button>
               </div>
            </div>
         </FeatureCheck>
         {isAddCourseModalOpen && (
            <AddCourseModal
               closeModal={() => setIsAddCourseModalOpen(false)}
               examId={props.examId}
               setExamCourses={props.setExamCourses}
            />
         )}
         {isExtendEndDatesModalOpen && (
            <ExtendEndDatesModal
               closeModal={() => setIsExtendEndDatesModalOpen(false)}
               examId={props.examId}
            />
         )}
      </SectionCard>
   );
};

export default ExamCoursesPanel;
