import * as React from 'react';

import { ActivityCompleterMode, ActivityQuestion } from '@models/Activity';
import Appearance from '@models/Appearance';
import classnames from 'classnames';

import ModalDialog from '@components/Core/ModalDialog';

interface UnansweredQuestionModalProps {
   allowGoingBack?: boolean;
   finalSubmitWarning: boolean;
   incompleteQuestions: readonly ActivityQuestion<ActivityCompleterMode>[];
   onCancel(): void;
   onClose(): void;
   onPrimaryClick(): void;
   setCurrentQuestionId(questionId: string | number): void;
}

const UnansweredQuestionModal: React.FC<UnansweredQuestionModalProps> = ({
   allowGoingBack,
   finalSubmitWarning,
   incompleteQuestions,
   onCancel,
   onClose,
   onPrimaryClick,
   setCurrentQuestionId,
}) => {
   const handleQuestionClick = (questionId: string | number): void => {
      if (allowGoingBack) {
         setCurrentQuestionId(questionId);
      }
   };

   return (
      <ModalDialog
         appearance={Appearance.warning}
         data-test='unanswered-question-continue'
         heading='Incomplete Question'
         onClose={onClose}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[
            {
               text: finalSubmitWarning ? 'Submit' : 'Continue',
               onClick: onPrimaryClick,
            },
            { text: 'Cancel', onClick: onCancel },
         ]}
      >
         {finalSubmitWarning ? (
            <div className='submit-warning'>
               <div>
                  <p>You have not completed all of the questions.</p>
                  <ul>
                     {incompleteQuestions.map((i, j) => (
                        <li
                           key={i.id}
                           onClick={() => handleQuestionClick(i.id)}
                           className={classnames({ pointer: allowGoingBack })}
                        >
                           {i.title || `Question ${j + 1}`}
                        </li>
                     ))}
                  </ul>
                  <p>Are you sure that you want to submit?</p>
               </div>
            </div>
         ) : (
            <div>
               <p>You have not completed all parts of this question.</p>
               <p>Are you sure that you want to proceed?</p>
            </div>
         )}
      </ModalDialog>
   );
};

export default UnansweredQuestionModal;
