import * as React from 'react';

import useMousetrap from '@hooks/use-mousetrap';
import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import { createPortal } from 'react-dom';

import { AppStateContext } from '../../AppState';
import Blanket from '@components/Core/Blanket';
import CommandPalette from './CommandPalette';
import BookstoreCodeSearch from './Commands/BookstoreCodeSearch';
import ImpersonateUser from './Commands/ImpersonateUser';

export enum CommandName {
   COMMAND_PALETTE = 'COMMAND_PALETTE',
   IMPERSONATE_USER = 'IMPERSONATE_USER',
   SEARCH_BOOKSTORE_CODES = 'SEARCH_BOOKSTORE_CODES',
}

export interface CommandProps {
   open(command: CommandName): void;
   close(): void;
}

const COMMANDS_INDEX = {
   [CommandName.COMMAND_PALETTE]: { component: CommandPalette },
   [CommandName.IMPERSONATE_USER]: { component: ImpersonateUser },
   [CommandName.SEARCH_BOOKSTORE_CODES]: { component: BookstoreCodeSearch },
};

const CommandController: React.FC = () => {
   const [command, setCommand] = React.useState<Maybe<CommandName>>(null);
   const { userProfile, loggedIn } = React.useContext<AppStateContext>(AppStateContext);

   useMousetrap('mod+k', () => {
      setCommand(CommandName.COMMAND_PALETTE);
   });

   useMousetrap('esc', () => {
      setCommand(null);
   });

   const close = (): void => setCommand(null);

   const open = (updatedCommandName: CommandName): void => {
      if (userProfile?.accountType === AccountType.instructor) {
         setCommand(updatedCommandName);
      }
   };

   const CommandComponent: React.FC<CommandProps> = command
      ? COMMANDS_INDEX[command].component
      : () => null;

   if (!command || !loggedIn || userProfile?.accountType !== AccountType.instructor) {
      return null;
   }

   const props = {
      open,
      close,
   };

   if (command) {
      return createPortal(
         <>
            <Blanket transparent onBlanketClicked={close} />
            <CommandComponent {...props} />
         </>,
         document.body,
      );
   }
   return null;
};

export default CommandController;
