import * as React from 'react';

import languageOptions from '@helpers/LanguageOptions';
import IconImageClose from '@icons/general/icon-image-close.svg';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import Language from '@models/Language';
import { Speaker, SpeakerAgeConstants, SpeakerGenderConstants } from '@models/Speaker';
import { uploadImage } from '@services/AssetService';
import MapboxService from '@services/MapboxService';
import SpeakerService from '@services/SpeakerService';

import Appearance from '@models/Appearance';
import ModalDialog from '@components/Core/ModalDialog';
import Loader from '@components/Loader';
import LocationSearch, { LocationSuggestion } from '@components/MapBox/LocationSearch';
import { getPartialLocationFromLocation } from '@components/Video/helpers';

interface Props {
   incomingSpeakerId?: number;
   onCancel(): void;
   onSave(): void;
}

const SpeakerEditorModal: React.FC<Props> = (props) => {
   const [imageFile, setImageFile] = React.useState<File>();
   const [localImageUrl, setLocalImageUrl] = React.useState<string>();
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [speaker, setSpeaker] = React.useState<Speaker<true>>({
      id: null,
      name: '',
      languages: 'es',
      location: null,
      imageFilename: undefined,
      imageUrl: undefined,
   });
   const [selectedLocation, setSelectedLocation] = React.useState<LocationSuggestion | undefined>();

   const getSpeaker = (id: number): void => {
      setIsLoading(true);
      SpeakerService.get(id).then((r) => {
         setSpeaker({ ...r });
         const partialSuggestion = getPartialLocationFromLocation(r.location);
         setSelectedLocation(partialSuggestion);
         setIsLoading(false);
      });
   };

   React.useEffect(() => {
      if (props.incomingSpeakerId) {
         setSelectedLocation(undefined); // Reset location upon reload
         getSpeaker(props.incomingSpeakerId);
      }
   }, []);

   const handleLocationChange = (suggestion: SearchBoxSuggestion | undefined): void => {
      setSelectedLocation(suggestion);

      if (suggestion) {
         MapboxService.getDetails(suggestion).then((mapProviderResponse) => {
            const firstFeature = mapProviderResponse.features[0];
            setSpeaker({
               ...speaker,
               location: {
                  name: firstFeature.properties.name,
                  placeFormatted: firstFeature.properties.place_formatted,
                  displayName: firstFeature.properties.place_formatted,
                  latitude: firstFeature.properties.coordinates.latitude,
                  longitude: firstFeature.properties.coordinates.longitude,
                  mapProviderId: firstFeature.properties.mapbox_id,
                  countryCode: firstFeature.properties.context.country?.country_code_alpha_3 ?? '',
                  mapProviderResponse,
               },
            });
         });
      } else {
         setSpeaker({ ...speaker, location: null });
      }
   };

   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (!file) {
         return;
      }
      setImageFile(file);
      setLocalImageUrl(URL.createObjectURL(file));
   };

   const handleInputOrSelectChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setSpeaker({ ...speaker, [name]: value });
   };

   const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newLang = event.target.value as Language;
      setSpeaker({ ...speaker, languages: newLang });
   };

   const removeSpeakerImage = (): void => {
      setSpeaker({
         ...speaker,
         imageFilename: '',
         imageUrl: '',
      });
      setLocalImageUrl(undefined);
   };

   const uploadImageOrReturnEmptyString = async (): Promise<string> => {
      if (!imageFile) return Promise.resolve('');

      return uploadImage(imageFile);
   };

   const handleSaveOrCreate = async (): Promise<void> => {
      setIsSaving(true);
      const fileName = await uploadImageOrReturnEmptyString();
      const newOrUpdatedSpeaker: Speaker<true> = {
         ...speaker,
         imageFilename: fileName,
      };

      if (props.incomingSpeakerId) {
         await SpeakerService.update({
            ...newOrUpdatedSpeaker,
            id: props.incomingSpeakerId,
         });
      } else {
         await SpeakerService.create(newOrUpdatedSpeaker);
      }
      setIsSaving(false);
      props.onSave();
   };

   const mapAgeOptions = (): JSX.Element[] => [
      <option key={0} value='' />,
      ...SpeakerAgeConstants.map((x) => (
         <option key={x} value={x}>
            {x}
         </option>
      )),
   ];
   const mapGenderOptions = (): JSX.Element[] => [
      <option key={0} value='' />,
      ...SpeakerGenderConstants.map((x) => (
         <option key={x} value={x}>
            {x}
         </option>
      )),
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='no-center padding-bottom-s'
         heading='Speaker Editor'
         onClose={props.onCancel}
         actions={[
            {
               text: 'Save',
               onClick: handleSaveOrCreate,
               loading: isSaving,
            },
            { text: 'Cancel', onClick: props.onCancel },
         ]}
      >
         {isLoading ? (
            <Loader />
         ) : (
            <form>
               <div className='row'>
                  <div className='col-xs-12 col-sm-8'>
                     <label className='field-title'>Name</label>
                     <input
                        name='name'
                        required
                        type='text'
                        value={speaker.name}
                        onChange={handleInputOrSelectChange}
                     />
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>Gender</label>
                           <select
                              name='gender'
                              value={speaker.gender || undefined}
                              onChange={handleInputOrSelectChange}
                           >
                              {mapGenderOptions()}
                           </select>
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>Age</label>
                           <select
                              name='age'
                              value={speaker.age || undefined}
                              onChange={handleInputOrSelectChange}
                           >
                              {mapAgeOptions()}
                           </select>
                        </div>
                     </div>
                  </div>
                  <div className='col-xs-12 col-sm-4'>
                     <label className='field-title'>Photo</label>
                     <div className='set-settings-image-wrap'>
                        {(speaker.imageUrl || localImageUrl) && (
                           <div
                              className='set-settings-image-close'
                              aria-label='Remove Speaker Image'
                              onClick={removeSpeakerImage}
                           >
                              <IconImageClose className='ignore-click' aria-hidden />
                           </div>
                        )}
                        <div className='set-settings-image'>
                           {!(speaker.imageUrl || localImageUrl) ? (
                              <input
                                 className='pointer ignore-click'
                                 type='file'
                                 accept='image/*'
                                 id='speakerPhoto'
                                 onChange={handleImageChange}
                              />
                           ) : (
                              <img
                                 alt='Speaker Image'
                                 className='ignore-click'
                                 src={speaker.imageUrl || localImageUrl}
                              />
                           )}
                        </div>
                     </div>
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Location</label>
                     <LocationSearch
                        selectedSuggestion={selectedLocation}
                        setSelectedSuggestion={handleLocationChange}
                     />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Language</label>
                     <select
                        name='language'
                        className='cs-select cs-select-style'
                        onChange={handleLanguageChange}
                        value={speaker.languages}
                     >
                        {languageOptions}
                     </select>
                  </div>
               </div>
            </form>
         )}
      </ModalDialog>
   );
};

export default SpeakerEditorModal;
