import IVocabTerm from '@models/IVocabTerm';

export enum VocabSessionType {
   audio = 'audio',
   learn = 'learn',
   review = 'review',
   speedReview = 'speed_review',
}

export enum VocabSessionCardType {
   typing = 'typing',
   multipleChoice = 'multiple_choice',
   speaking = 'speaking',
}

export interface IBaseVocabSessionCard {
   id: string;
   termId: number;
   level: number;
}

export enum PromptMedium {
   audio = 'audio',
   text = 'text',
   image = 'image',
}

export enum ResponseMedium {
   audio = 'audio',
   text = 'text',
   speech = 'speech',
   image = 'image',
}

export interface ITextChoice {
   id: number;
   text: string;
}

export interface IAudioChoice {
   id: number;
   audio: HTMLAudioElement;
}

export interface IImageChoice {
   id: number;
   image: HTMLImageElement;
}

export interface IVocabSessionCard extends IBaseVocabSessionCard {
   type: VocabSessionCardType;
   scheduled: boolean;
   promptWith: PromptMedium;
   promptInTarget: boolean;
   respondWith: ResponseMedium;
   respondInTarget: boolean;
   choices?: readonly (IAudioChoice | IImageChoice | ITextChoice)[];
}

export interface IVocabSessionTerm extends IVocabTerm {
   /** Term Ids that can be used as distractors in the target language */
   targetChoices: readonly number[];
   /** Terms Ids that can be used as distactors in the native language */
   nativeChoices: readonly number[];
   audio: HTMLAudioElement;
   image: HTMLImageElement;
}

export interface IVocabSessionStats {
   correct: number;
   incorrect: number;
   timeSpent: number;
   currentStreak: number;
}

export enum FeedbackType {
   correct = 'correct',
   incorrect = 'incorrect',
   almost = 'almost',
}

export interface IFeedback {
   class: FeedbackType;
   text: string | React.ReactNode;
}
