import * as React from 'react';

import {
   ActivityBuilderMode,
   VideoSource,
   YouTubeVideoContent as YoutubeVideoContentType,
} from '@models/Activity';
import classNames from 'classnames';

import Constants from '../../../../../Constants';

interface YouTubeURLInputProps {
   item: YoutubeVideoContentType<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<YoutubeVideoContentType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const YouTubeURLInput: React.FC<YouTubeURLInputProps> = ({ item, handleItemUpdate }) => {
   const [error, setError] = React.useState<string>('');

   const youTubeUrl = item.youtubeId ? `https://youtu.be/${item.youtubeId}` : '';

   const handleYouTubeUrlUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const match = event.target.value.match(Constants.regex.youtubeUrl);
      const newYoutubeId = match && match[2].length === 11 ? match[2] : '';
      if (newYoutubeId) {
         const update: Partial<YoutubeVideoContentType<ActivityBuilderMode>> = {
            ...item,
            youtubeId: newYoutubeId,
            source: VideoSource.youTube,
            isInteractiveVideo: false,
         };
         handleItemUpdate(update);
      } else {
         setError(`Invalid YouTube URL: ${event.target.value}`);
      }
   };

   if (!item.youtubeId) {
      return (
         <div className='video-method'>
            <input
               type='text'
               className={classNames('video-url', { error })}
               placeholder='Paste Youtube URL'
               value={youTubeUrl}
               onChange={handleYouTubeUrlUpdate}
            />
            {error && <p className='error'>{error}</p>}
         </div>
      );
   }
};

export default YouTubeURLInput;
