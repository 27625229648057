/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash';

import { randomShortId } from './RandomStringUtils';

const keyDeepHelper = (o: any): any => {
   for (const prop in o) {
      if (_.isArray(o[prop]) && _.every(o[prop], _.isObject)) {
         o[prop].forEach((i: any, j: any) => {
            o[prop][j].key = randomShortId();
            keyDeepHelper(o[prop][j]);
         });
      } else if (_.isObject(o[prop])) {
         keyDeepHelper(o[prop]);
      }
   }
};

/**
 * Recursively adds a unique key to each item in an array of objects.
 */
const keyDeep = (obj: any): any => {
   if (_.isArray(obj) && _.every(obj, _.isObject)) {
      obj.forEach((i, j) => {
         obj[j].key = randomShortId();
         keyDeepHelper(obj);
      });
   } else {
      keyDeepHelper(obj);
   }
};

export default keyDeep;
