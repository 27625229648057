import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import Button from '@components/Common/Button';
import Switch from '@components/Common/Switch';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconAddCircle1 from '@icons/nova-line/27-Remove&Add/add-circle-1.svg';
import { ActivityBuilderMode, ActivityVariable, ActivityVariableType } from '@models/Activity';
import { ContentExamType } from '@models/Content';
import { Maybe } from '@models/Core';
import Language from '@models/Language';

import { AppStateContext } from '../../../AppState';
import InfoTooltip from '@components/InfoTooltip';
import { ActivityBuilderState } from './ActivityBuilder';

interface ActivityBuilderSettingsFieldsProps {
   allowGoingBack: boolean;
   disableCopyPaste: boolean;
   disableSpellCheck: boolean;
   examType: Maybe<string>;
   language: Language;
   oneQuestionAtATime: boolean;
   reportPartName: Maybe<string>;
   showPointsPossible: boolean;
   showQuestionList: boolean;
   variables: readonly ActivityVariable<ActivityBuilderMode>[];
   onAddVariable(): void;
   onRemoveVariable(variableId: string | number): void;
   onVariableChange(
      variableId: string | number,
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void;
   updateActivity(update: Partial<ActivityBuilderState>): void;
}

const ActivityBuilderSettingsFields: React.FC<ActivityBuilderSettingsFieldsProps> = ({
   allowGoingBack,
   disableCopyPaste,
   disableSpellCheck,
   examType,
   language,
   oneQuestionAtATime,
   reportPartName,
   showPointsPossible,
   showQuestionList,
   variables,
   onAddVariable,
   onRemoveVariable,
   onVariableChange,
   updateActivity,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!userProfile) {
      return;
   }

   const { isAdmin, isNationalExamAdmin } = userProfile;

   const toggleShowQuestionList = (): void => {
      updateActivity({ showQuestionList: !showQuestionList });
   };

   const toggleDisableCopyPaste = (): void => {
      updateActivity({ disableCopyPaste: !disableCopyPaste });
   };

   const toggleDisableSpellCheck = (): void => {
      updateActivity({ disableSpellCheck: !disableSpellCheck });
   };

   const toggleOneQuestionAtATime = (): void => {
      updateActivity({ oneQuestionAtATime: !oneQuestionAtATime });
   };

   const toggleAllowGoingBack = (): void => {
      updateActivity({ allowGoingBack: !allowGoingBack });
   };

   const toggleShowPointsPossible = (): void => {
      updateActivity({ showPointsPossible: !showPointsPossible });
   };

   const examTypeOptions: {
      label: string;
      value: ContentExamType;
   }[] = [
      { label: 'Official Exam', value: 'official_exam' },
      { label: 'Practice', value: 'practice' },
      { label: 'Questionnaire', value: 'questionnaire' },
   ];

   return (
      <>
         {(isAdmin || isNationalExamAdmin) && (
            <>
               <label className='field-title' htmlFor='partName'>
                  Part Name
               </label>
               <AccentTextbox
                  id='reportPartName'
                  placeholder='Enter Report Part Name'
                  value={reportPartName || ''}
                  onChange={(event) => updateActivity({ reportPartName: event.target.value })}
                  language={language}
               />
               <label className='field-title' htmlFor='partName'>
                  Exam Type
               </label>
               <select
                  id='examType'
                  value={examType ?? ''}
                  onChange={(event) =>
                     updateActivity({ examType: event.target.value as ContentExamType })
                  }
               >
                  <option key='examType-empty' value='' />
                  {examTypeOptions.map((i) => (
                     <option key={`examType-${i.label}`} value={i.value}>
                        {i.label}
                     </option>
                  ))}
               </select>
            </>
         )}

         <div className='activity-options'>
            <div className='title gray-text small margin-bottom-xs'>Activity Options</div>
            <div className='row'>
               <div className='col-xs-6'>
                  <Switch checked={showQuestionList} onChange={toggleShowQuestionList}>
                     <label className='field-title'>
                        Show Question List
                        <InfoTooltip>
                           Students will be able to see a list of questions in the sidebar when
                           completing the activity.
                        </InfoTooltip>
                     </label>
                  </Switch>
               </div>
               <div className='col-xs-6'>
                  <Switch checked={disableCopyPaste} onChange={toggleDisableCopyPaste}>
                     <label className='field-title'>Disable Copy and Paste</label>
                  </Switch>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-6'>
                  <Switch checked={showPointsPossible} onChange={toggleShowPointsPossible}>
                     <label className='field-title'>
                        Show Points Possible
                        <InfoTooltip>
                           Students will be able to see the number of points possible next to each
                           question when completing the activity.
                        </InfoTooltip>
                     </label>
                  </Switch>
               </div>
               <div className='col-xs-6'>
                  <Switch checked={disableSpellCheck} onChange={toggleDisableSpellCheck}>
                     <label className='field-title'>Disable Spell Check</label>
                  </Switch>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-6'>
                  <Switch checked={oneQuestionAtATime} onChange={toggleOneQuestionAtATime}>
                     <label className='field-title'>Show One Question At a Time</label>
                  </Switch>
               </div>
               {oneQuestionAtATime && (
                  <div className='col-xs-6'>
                     <Switch
                        checked={allowGoingBack}
                        onChange={toggleAllowGoingBack}
                        disabled={!oneQuestionAtATime}
                     >
                        <label className='field-title'>
                           Allow Going Back
                           <InfoTooltip>
                              Students will be able to return to previous questions when completing
                              the activity.
                           </InfoTooltip>
                        </label>
                     </Switch>
                  </div>
               )}
            </div>
            <div className='margin-top-s'>
               <div className='title gray-text small'>
                  Activity Variables
                  <InfoTooltip>
                     Variables can be set during the completion of the activity based on a set of
                     rules.
                  </InfoTooltip>
               </div>
               {variables.length > 0 && (
                  <div className='row'>
                     <div className='col-xs-3'>
                        <label className='field-title'>Name</label>
                     </div>
                     <div className='col-xs-4'>
                        <label className='field-title'>Type</label>
                     </div>
                     <div className='col-xs-4'>
                        <label className='field-title'>Description</label>
                     </div>
                     <div className='col-xs-1' />
                  </div>
               )}
               {variables.map((i) => (
                  <div className='row' key={i.id}>
                     <div className='col-xs-3'>
                        <input
                           type='text'
                           name='name'
                           value={i.name}
                           onChange={(e) => onVariableChange(i.id, e)}
                        />
                     </div>
                     <div className='col-xs-4'>
                        <select
                           value={i.type}
                           name='type'
                           onChange={(e) => onVariableChange(i.id, e)}
                        >
                           <option value={ActivityVariableType.string}>Text</option>
                           <option value={ActivityVariableType.integer}>Number</option>
                           <option value={ActivityVariableType.float}>Decimal Number</option>
                           <option value={ActivityVariableType.boolean}>True or False</option>
                        </select>
                     </div>
                     <div className='col-xs-4'>
                        <AccentTextbox
                           language={language}
                           type='text'
                           name='description'
                           value={i.description}
                           onChange={(e) => onVariableChange(i.id, e)}
                        />
                     </div>
                     <div className='col-xs-1 align-self-center'>
                        <div className='icon-action-wrap'>
                           <div
                              className='icon-action'
                              onClick={() => onRemoveVariable(i.id)}
                              aria-label='Remove Variable'
                           >
                              <IconBin />
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
               <Button
                  subtle
                  className='margin-top-s margin-bottom-s'
                  onClick={onAddVariable}
                  icon={<IconAddCircle1 aria-hidden />}
               >
                  Add Variable
               </Button>
            </div>
         </div>
      </>
   );
};

export default ActivityBuilderSettingsFields;
