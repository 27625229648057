import * as React from 'react';

import partOfSpeechAbbreviation from '@helpers/PartOfSpeechAbbreviation';
import IconFemininePlural from '@icons/general/icon-session-feminine-plural.svg';
import IconFeminineSingular from '@icons/general/icon-session-feminine-singular.svg';
import IconMasculinePlural from '@icons/general/icon-session-masculine-plural.svg';
import IconMasculineSingular from '@icons/general/icon-session-masculine-singular.svg';
import { Maybe } from '@models/Core';
import { PartOfSpeech } from '@models/IVocabTerm';

interface TermAttributesProps {
   gender: string;
   number: string;
   partOfSpeech: PartOfSpeech;
}

const TermAttributes: React.FC<TermAttributesProps> = ({
   gender,
   number: termNumber,
   partOfSpeech,
}) => {
   const renderGenderNumber = (): Maybe<React.ReactNode> => {
      if (gender === 'masculine') {
         if (termNumber === 'singular') {
            return <IconMasculineSingular />;
         } else if (termNumber === 'plural') {
            return <IconMasculinePlural />;
         }
      } else if (gender === 'feminine') {
         if (termNumber === 'singular') {
            return <IconFeminineSingular />;
         } else if (termNumber === 'plural') {
            return <IconFemininePlural />;
         }
      }
      return null;
   };

   return (
      <div className='session-title-noun'>
         {renderGenderNumber()}
         <label>{partOfSpeechAbbreviation(partOfSpeech)}</label>
      </div>
   );
};

export default React.memo(TermAttributes);
