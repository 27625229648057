import * as _ from 'lodash';
import * as React from 'react';

import {
   CSSObjectWithLabel,
   Select,
   selectComponents,
   selectStyle,
   selectTheme,
} from '@components/Core/Select';
import { Maybe } from '@models/Core';
import LMSName from '@models/LMSName';
import classnames from 'classnames';

import { lmsDisplayName } from './';
import LMSIcon from './LMSIcon';

export type LMSOption = {
   value: LMSName;
   label: string;
};

interface SchoolSelectProps {
   autoFocus?: boolean;
   isDisabled?: boolean;
   className?: string;
   name?: string;
   placeholder?: string;
   value: Maybe<LMSName>;
   options?: readonly LMSName[];
   onChange(value: Maybe<LMSName>): void;
}

const LMSSelect: React.FC<SchoolSelectProps> = ({
   autoFocus = false,
   isDisabled = false,
   className = '',
   name,
   placeholder,
   value: lmsName,
   options: lmsNames,
   onChange,
}) => {
   const handleChange = (newValue: Maybe<LMSOption>): void => {
      onChange(newValue ? newValue.value : null);
   };

   const formatOptionLabel = ({ value, label }: LMSOption): React.ReactNode => (
      <div className='flex align-items-center'>
         <LMSIcon circle lmsName={value} className='margin-right-s' />
         {label}
      </div>
   );

   const errorStyle = {
      borderColor: '#CD1D1D',
      boxShadow: '0 0 0 1px #CD1D1D',
   };

   const options = Object.entries(lmsDisplayName)
      .map(([k, v]) => ({ value: k as LMSName, label: v }))
      .filter((i) => !lmsNames || lmsNames.includes(i.value));

   return (
      <Select<LMSOption>
         aria-label={placeholder}
         autoFocus={autoFocus}
         className={classnames('react-select margin-top-s', className)}
         formatOptionLabel={formatOptionLabel}
         isClearable
         isDisabled={isDisabled}
         name={name}
         onChange={handleChange}
         placeholder={placeholder}
         theme={selectTheme}
         styles={{
            ...selectStyle,
            control: (styles: CSSObjectWithLabel) => ({
               ...styles,
               minHeight: '40px',
               ...(className.split(' ').includes('error') ? errorStyle : {}),
            }),
         }}
         components={selectComponents}
         options={options}
         value={lmsName ? { value: lmsName, label: lmsDisplayName[lmsName] } : null}
         noOptionsMessage={() => 'No LMS Found'}
      />
   );
};

export default LMSSelect;
