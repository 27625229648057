import { DataLayerEvent, PageViewEvent } from '@models/GoogleAnalytics';

export const createPageViewEvent = (userId: number, pageUrl: string): PageViewEvent => ({
   event: 'pageview',
   userId,
   pageUrl,
});

export const trackEvent = (event: DataLayerEvent): void => {
   window.dataLayer = window.dataLayer || [];
   window.dataLayer.push(event);
};
