import * as React from 'react';

import IconCompleterDownload from '@icons/activities/icon-completer-download.svg';
import { FileResponse } from '@models/Activity';

import Link from '@components/Common/Link';

interface FilePromptProps {
   response: FileResponse;
}

const FilePrompt: React.FC<FilePromptProps> = ({ response: { fileUrl, originalFilename } }) => (
   <div className='row'>
      <div className='col-xs-12'>
         {fileUrl ? (
            <Link to={fileUrl} external>
               <div className='activity-download-file'>
                  <div className='activity-item-icon'>
                     <IconCompleterDownload width='23' height='23' fill='#FFF' />
                  </div>
                  <p>{originalFilename}</p>
               </div>
            </Link>
         ) : (
            <p>
               <i>No Dropbox Submission</i>
            </p>
         )}
      </div>
   </div>
);

export default FilePrompt;
