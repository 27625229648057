import * as React from 'react';

import Switch from '@components/Common/Switch';
import useOutsideAlerter from '@hooks/use-outside-alerter';
import Language, { LanguageLookup } from '@models/Language';

export interface VideoSubtitleProps {
   language: Language;
   showLanguage: boolean;
   setShowLanguage(show: boolean): void;
}

type SubtitlePopupProps = {
   isOpen: boolean;
   nativeLanguageSubtitleProps?: VideoSubtitleProps;
   targetLanguageSubtitleProps?: VideoSubtitleProps;
   setIsOpen(value: boolean): void;
};

const SubtitlePopup: React.FC<SubtitlePopupProps> = ({
   isOpen,
   nativeLanguageSubtitleProps,
   targetLanguageSubtitleProps,
   setIsOpen,
}) => {
   const handleToggleCaptions = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const language = e.target.name as Language;
      if (targetLanguageSubtitleProps?.language === language) {
         targetLanguageSubtitleProps.setShowLanguage(!targetLanguageSubtitleProps.showLanguage);
      }
      if (nativeLanguageSubtitleProps?.language === language) {
         nativeLanguageSubtitleProps.setShowLanguage(!nativeLanguageSubtitleProps.showLanguage);
      }
   };

   const handleOutsideClick = (event: MouseEvent): void => {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();
      setIsOpen(false);
   };

   const componentRef = React.useRef<HTMLDivElement | null>(null);
   useOutsideAlerter(handleOutsideClick, componentRef, isOpen);

   return (
      <div
         className='margin-top-s subtitles-popup'
         onClick={(e) => {
            e.stopPropagation();
         }}
         ref={componentRef}
      >
         <div className='border-bottom title padding-s'>Subtitles</div>
         <div className='padding-left-s padding-bottom-s'>
            {targetLanguageSubtitleProps && (
               <Switch
                  aria-label={`Toggle ${
                     LanguageLookup[targetLanguageSubtitleProps.language]
                  } subtitles`}
                  checked={targetLanguageSubtitleProps.showLanguage}
                  name={targetLanguageSubtitleProps.language}
                  onChange={handleToggleCaptions}
               >
                  {LanguageLookup[targetLanguageSubtitleProps.language]}
               </Switch>
            )}
            {nativeLanguageSubtitleProps && (
               <Switch
                  aria-label={`Toggle ${
                     LanguageLookup[nativeLanguageSubtitleProps.language]
                  } subtitles`}
                  checked={nativeLanguageSubtitleProps.showLanguage}
                  name={nativeLanguageSubtitleProps.language}
                  onChange={handleToggleCaptions}
               >
                  {LanguageLookup[nativeLanguageSubtitleProps.language]}
               </Switch>
            )}
         </div>
      </div>
   );
};

export default SubtitlePopup;
