import HttpService from '@services/HttpService';

export interface CssStyleSheet {
   id: number;
   name: string;
   css: string;
}

export interface StyleSheetIds {
   id: number;
   name: string;
}

export interface StyleSheets {
   styleSheets: [StyleSheetIds];
}

export const getStyleSheet = (styleId: number): Promise<CssStyleSheet> =>
   HttpService.getWithAuthToken<CssStyleSheet>('/api/styles/' + styleId).then(
      (response) => response.data,
   );

export const updateStyleSheet = (
   styleId: number,
   name: string,
   css: string,
): Promise<CssStyleSheet> =>
   HttpService.putWithAuthToken<CssStyleSheet>('/api/styles/' + styleId, {
      name,
      css,
   }).then((response) => response.data);

export const newStyleSheet = (name: string, css: string): Promise<CssStyleSheet> =>
   HttpService.postWithAuthToken<CssStyleSheet>('/api/styles', {
      name,
      css,
   }).then((response) => response.data);

export const getStyleSheetsIdentifiers = (): Promise<[StyleSheetIds]> =>
   HttpService.getWithAuthToken<StyleSheets>('/api/styles').then(
      (response) => response.data.styleSheets,
   );
