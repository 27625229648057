import AdFlag from '@icons/flags/ad.svg';
import AeFlag from '@icons/flags/ae.svg';
import AfFlag from '@icons/flags/af.svg';
import AgFlag from '@icons/flags/ag.svg';
import AiFlag from '@icons/flags/ai.svg';
import AlFlag from '@icons/flags/al.svg';
import AmFlag from '@icons/flags/am.svg';
import AoFlag from '@icons/flags/ao.svg';
import AqFlag from '@icons/flags/aq.svg';
import ArFlag from '@icons/flags/ar.svg';
import AsFlag from '@icons/flags/as.svg';
import AtFlag from '@icons/flags/at.svg';
import AuFlag from '@icons/flags/au.svg';
import AwFlag from '@icons/flags/aw.svg';
import AxFlag from '@icons/flags/ax.svg';
import AzFlag from '@icons/flags/az.svg';
import BaFlag from '@icons/flags/ba.svg';
import BbFlag from '@icons/flags/bb.svg';
import BdFlag from '@icons/flags/bd.svg';
import BeFlag from '@icons/flags/be.svg';
import BfFlag from '@icons/flags/bf.svg';
import BgFlag from '@icons/flags/bg.svg';
import BhFlag from '@icons/flags/bh.svg';
import BiFlag from '@icons/flags/bi.svg';
import BjFlag from '@icons/flags/bj.svg';
import BlFlag from '@icons/flags/bl.svg';
import BmFlag from '@icons/flags/bm.svg';
import BnFlag from '@icons/flags/bn.svg';
import BoFlag from '@icons/flags/bo.svg';
import BqFlag from '@icons/flags/bq.svg';
import BrFlag from '@icons/flags/br.svg';
import BsFlag from '@icons/flags/bs.svg';
import BtFlag from '@icons/flags/bt.svg';
import BvFlag from '@icons/flags/bv.svg';
import BwFlag from '@icons/flags/bw.svg';
import ByFlag from '@icons/flags/by.svg';
import BzFlag from '@icons/flags/bz.svg';
import CaFlag from '@icons/flags/ca.svg';
import CcFlag from '@icons/flags/cc.svg';
import CdFlag from '@icons/flags/cd.svg';
import CfFlag from '@icons/flags/cf.svg';
import CgFlag from '@icons/flags/cg.svg';
import ChFlag from '@icons/flags/ch.svg';
import CiFlag from '@icons/flags/ci.svg';
import CkFlag from '@icons/flags/ck.svg';
import ClFlag from '@icons/flags/cl.svg';
import CmFlag from '@icons/flags/cm.svg';
import CnFlag from '@icons/flags/cn.svg';
import CoFlag from '@icons/flags/co.svg';
import CrFlag from '@icons/flags/cr.svg';
import CuFlag from '@icons/flags/cu.svg';
import CvFlag from '@icons/flags/cv.svg';
import CwFlag from '@icons/flags/cw.svg';
import CxFlag from '@icons/flags/cx.svg';
import CyFlag from '@icons/flags/cy.svg';
import CzFlag from '@icons/flags/cz.svg';
import DeFlag from '@icons/flags/de.svg';
import DjFlag from '@icons/flags/dj.svg';
import DkFlag from '@icons/flags/dk.svg';
import DmFlag from '@icons/flags/dm.svg';
import DoFlag from '@icons/flags/do.svg';
import DzFlag from '@icons/flags/dz.svg';
import EcFlag from '@icons/flags/ec.svg';
import EeFlag from '@icons/flags/ee.svg';
import EgFlag from '@icons/flags/eg.svg';
import EhFlag from '@icons/flags/eh.svg';
import ErFlag from '@icons/flags/er.svg';
import EsFlag from '@icons/flags/es.svg';
import EtFlag from '@icons/flags/et.svg';
import FiFlag from '@icons/flags/fi.svg';
import FjFlag from '@icons/flags/fj.svg';
import FkFlag from '@icons/flags/fk.svg';
import FmFlag from '@icons/flags/fm.svg';
import FoFlag from '@icons/flags/fo.svg';
import FrFlag from '@icons/flags/fr.svg';
import GaFlag from '@icons/flags/ga.svg';
import GbFlag from '@icons/flags/gb.svg';
import GdFlag from '@icons/flags/gd.svg';
import GeFlag from '@icons/flags/ge.svg';
import GfFlag from '@icons/flags/gf.svg';
import GgFlag from '@icons/flags/gg.svg';
import GhFlag from '@icons/flags/gh.svg';
import GiFlag from '@icons/flags/gi.svg';
import GlFlag from '@icons/flags/gl.svg';
import GmFlag from '@icons/flags/gm.svg';
import GnFlag from '@icons/flags/gn.svg';
import GpFlag from '@icons/flags/gp.svg';
import GqFlag from '@icons/flags/gq.svg';
import GrFlag from '@icons/flags/gr.svg';
import GsFlag from '@icons/flags/gs.svg';
import GtFlag from '@icons/flags/gt.svg';
import GuFlag from '@icons/flags/gu.svg';
import GwFlag from '@icons/flags/gw.svg';
import GyFlag from '@icons/flags/gy.svg';
import HkFlag from '@icons/flags/hk.svg';
import HmFlag from '@icons/flags/hm.svg';
import HnFlag from '@icons/flags/hn.svg';
import HrFlag from '@icons/flags/hr.svg';
import HtFlag from '@icons/flags/ht.svg';
import HuFlag from '@icons/flags/hu.svg';
import IdFlag from '@icons/flags/id.svg';
import IeFlag from '@icons/flags/ie.svg';
import IlFlag from '@icons/flags/il.svg';
import ImFlag from '@icons/flags/im.svg';
import InFlag from '@icons/flags/in.svg';
import IoFlag from '@icons/flags/io.svg';
import IqFlag from '@icons/flags/iq.svg';
import IrFlag from '@icons/flags/ir.svg';
import IsFlag from '@icons/flags/is.svg';
import ItFlag from '@icons/flags/it.svg';
import JeFlag from '@icons/flags/je.svg';
import JmFlag from '@icons/flags/jm.svg';
import JoFlag from '@icons/flags/jo.svg';
import JpFlag from '@icons/flags/jp.svg';
import KeFlag from '@icons/flags/ke.svg';
import KgFlag from '@icons/flags/kg.svg';
import KhFlag from '@icons/flags/kh.svg';
import KiFlag from '@icons/flags/ki.svg';
import KmFlag from '@icons/flags/km.svg';
import KnFlag from '@icons/flags/kn.svg';
import KpFlag from '@icons/flags/kp.svg';
import KrFlag from '@icons/flags/kr.svg';
import KwFlag from '@icons/flags/kw.svg';
import KyFlag from '@icons/flags/ky.svg';
import KzFlag from '@icons/flags/kz.svg';
import LaFlag from '@icons/flags/la.svg';
import LbFlag from '@icons/flags/lb.svg';
import LcFlag from '@icons/flags/lc.svg';
import LiFlag from '@icons/flags/li.svg';
import LkFlag from '@icons/flags/lk.svg';
import LrFlag from '@icons/flags/lr.svg';
import LsFlag from '@icons/flags/ls.svg';
import LtFlag from '@icons/flags/lt.svg';
import LuFlag from '@icons/flags/lu.svg';
import LvFlag from '@icons/flags/lv.svg';
import LyFlag from '@icons/flags/ly.svg';
import MaFlag from '@icons/flags/ma.svg';
import McFlag from '@icons/flags/mc.svg';
import MdFlag from '@icons/flags/md.svg';
import MeFlag from '@icons/flags/me.svg';
import MfFlag from '@icons/flags/mf.svg';
import MgFlag from '@icons/flags/mg.svg';
import MhFlag from '@icons/flags/mh.svg';
import MkFlag from '@icons/flags/mk.svg';
import MlFlag from '@icons/flags/ml.svg';
import MmFlag from '@icons/flags/mm.svg';
import MnFlag from '@icons/flags/mn.svg';
import MoFlag from '@icons/flags/mo.svg';
import MpFlag from '@icons/flags/mp.svg';
import MqFlag from '@icons/flags/mq.svg';
import MrFlag from '@icons/flags/mr.svg';
import MsFlag from '@icons/flags/ms.svg';
import MtFlag from '@icons/flags/mt.svg';
import MuFlag from '@icons/flags/mu.svg';
import MvFlag from '@icons/flags/mv.svg';
import MwFlag from '@icons/flags/mw.svg';
import MxFlag from '@icons/flags/mx.svg';
import MyFlag from '@icons/flags/my.svg';
import MzFlag from '@icons/flags/mz.svg';
import NaFlag from '@icons/flags/na.svg';
import NcFlag from '@icons/flags/nc.svg';
import NeFlag from '@icons/flags/ne.svg';
import NfFlag from '@icons/flags/nf.svg';
import NgFlag from '@icons/flags/ng.svg';
import NiFlag from '@icons/flags/ni.svg';
import NlFlag from '@icons/flags/nl.svg';
import NoFlag from '@icons/flags/no.svg';
import NpFlag from '@icons/flags/np.svg';
import NrFlag from '@icons/flags/nr.svg';
import NuFlag from '@icons/flags/nu.svg';
import NzFlag from '@icons/flags/nz.svg';
import OmFlag from '@icons/flags/om.svg';
import PaFlag from '@icons/flags/pa.svg';
import PeFlag from '@icons/flags/pe.svg';
import PfFlag from '@icons/flags/pf.svg';
import PgFlag from '@icons/flags/pg.svg';
import PhFlag from '@icons/flags/ph.svg';
import PkFlag from '@icons/flags/pk.svg';
import PlFlag from '@icons/flags/pl.svg';
import PmFlag from '@icons/flags/pm.svg';
import PnFlag from '@icons/flags/pn.svg';
import PrFlag from '@icons/flags/pr.svg';
import PsFlag from '@icons/flags/ps.svg';
import PtFlag from '@icons/flags/pt.svg';
import PwFlag from '@icons/flags/pw.svg';
import PyFlag from '@icons/flags/py.svg';
import QaFlag from '@icons/flags/qa.svg';
import ReFlag from '@icons/flags/re.svg';
import RoFlag from '@icons/flags/ro.svg';
import RsFlag from '@icons/flags/rs.svg';
import RuFlag from '@icons/flags/ru.svg';
import RwFlag from '@icons/flags/rw.svg';
import SaFlag from '@icons/flags/sa.svg';
import SbFlag from '@icons/flags/sb.svg';
import ScFlag from '@icons/flags/sc.svg';
import SdFlag from '@icons/flags/sd.svg';
import SeFlag from '@icons/flags/se.svg';
import SgFlag from '@icons/flags/sg.svg';
import ShFlag from '@icons/flags/sh.svg';
import SiFlag from '@icons/flags/si.svg';
import SjFlag from '@icons/flags/sj.svg';
import SkFlag from '@icons/flags/sk.svg';
import SlFlag from '@icons/flags/sl.svg';
import SmFlag from '@icons/flags/sm.svg';
import SnFlag from '@icons/flags/sn.svg';
import SoFlag from '@icons/flags/so.svg';
import SrFlag from '@icons/flags/sr.svg';
import SsFlag from '@icons/flags/ss.svg';
import StFlag from '@icons/flags/st.svg';
import SvFlag from '@icons/flags/sv.svg';
import SxFlag from '@icons/flags/sx.svg';
import SyFlag from '@icons/flags/sy.svg';
import SzFlag from '@icons/flags/sz.svg';
import TcFlag from '@icons/flags/tc.svg';
import TdFlag from '@icons/flags/td.svg';
import TfFlag from '@icons/flags/tf.svg';
import TgFlag from '@icons/flags/tg.svg';
import ThFlag from '@icons/flags/th.svg';
import TjFlag from '@icons/flags/tj.svg';
import TkFlag from '@icons/flags/tk.svg';
import TlFlag from '@icons/flags/tl.svg';
import TmFlag from '@icons/flags/tm.svg';
import TnFlag from '@icons/flags/tn.svg';
import ToFlag from '@icons/flags/to.svg';
import TrFlag from '@icons/flags/tr.svg';
import TtFlag from '@icons/flags/tt.svg';
import TvFlag from '@icons/flags/tv.svg';
import TwFlag from '@icons/flags/tw.svg';
import TzFlag from '@icons/flags/tz.svg';
import UaFlag from '@icons/flags/ua.svg';
import UgFlag from '@icons/flags/ug.svg';
import UmFlag from '@icons/flags/um.svg';
import UsFlag from '@icons/flags/us.svg';
import UyFlag from '@icons/flags/uy.svg';
import UzFlag from '@icons/flags/uz.svg';
import VaFlag from '@icons/flags/va.svg';
import VcFlag from '@icons/flags/vc.svg';
import VeFlag from '@icons/flags/ve.svg';
import VgFlag from '@icons/flags/vg.svg';
import ViFlag from '@icons/flags/vi.svg';
import VnFlag from '@icons/flags/vn.svg';
import VuFlag from '@icons/flags/vu.svg';
import WfFlag from '@icons/flags/wf.svg';
import WsFlag from '@icons/flags/ws.svg';
import YeFlag from '@icons/flags/ye.svg';
import YtFlag from '@icons/flags/yt.svg';
import ZaFlag from '@icons/flags/za.svg';
import ZmFlag from '@icons/flags/zm.svg';
import ZwFlag from '@icons/flags/zw.svg';

export type FlagComponentType = React.FC<React.SVGProps<SVGSVGElement>>;

export const countryFlagMap: Record<string, FlagComponentType> = {
   al: AlFlag,
   dz: DzFlag,
   as: AsFlag,
   ad: AdFlag,
   ao: AoFlag,
   af: AfFlag,
   ai: AiFlag,
   aq: AqFlag,
   ag: AgFlag,
   ar: ArFlag,
   am: AmFlag,
   aw: AwFlag,
   au: AuFlag,
   at: AtFlag,
   az: AzFlag,
   bs: BsFlag,
   bh: BhFlag,
   bd: BdFlag,
   bb: BbFlag,
   by: ByFlag,
   be: BeFlag,
   bz: BzFlag,
   bj: BjFlag,
   bm: BmFlag,
   bt: BtFlag,
   bo: BoFlag,
   bq: BqFlag,
   ba: BaFlag,
   bw: BwFlag,
   bv: BvFlag,
   br: BrFlag,
   io: IoFlag,
   bn: BnFlag,
   bg: BgFlag,
   bf: BfFlag,
   bi: BiFlag,
   cv: CvFlag,
   kh: KhFlag,
   cm: CmFlag,
   ca: CaFlag,
   ky: KyFlag,
   cf: CfFlag,
   td: TdFlag,
   cl: ClFlag,
   cn: CnFlag,
   cx: CxFlag,
   cc: CcFlag,
   co: CoFlag,
   km: KmFlag,
   cd: CdFlag,
   cg: CgFlag,
   ck: CkFlag,
   cr: CrFlag,
   ci: CiFlag,
   hr: HrFlag,
   cu: CuFlag,
   cw: CwFlag,
   cy: CyFlag,
   cz: CzFlag,
   dk: DkFlag,
   dj: DjFlag,
   dm: DmFlag,
   do: DoFlag,
   ec: EcFlag,
   eg: EgFlag,
   sv: SvFlag,
   gq: GqFlag,
   er: ErFlag,
   ee: EeFlag,
   sz: SzFlag,
   et: EtFlag,
   fk: FkFlag,
   fo: FoFlag,
   fj: FjFlag,
   fi: FiFlag,
   fr: FrFlag,
   gf: GfFlag,
   pf: PfFlag,
   tf: TfFlag,
   ga: GaFlag,
   gm: GmFlag,
   ge: GeFlag,
   de: DeFlag,
   gh: GhFlag,
   gi: GiFlag,
   gr: GrFlag,
   gl: GlFlag,
   gd: GdFlag,
   gp: GpFlag,
   gu: GuFlag,
   gt: GtFlag,
   gg: GgFlag,
   gn: GnFlag,
   gw: GwFlag,
   gy: GyFlag,
   ht: HtFlag,
   hm: HmFlag,
   va: VaFlag,
   hn: HnFlag,
   hk: HkFlag,
   hu: HuFlag,
   is: IsFlag,
   in: InFlag,
   id: IdFlag,
   ir: IrFlag,
   iq: IqFlag,
   ie: IeFlag,
   im: ImFlag,
   il: IlFlag,
   it: ItFlag,
   jm: JmFlag,
   jp: JpFlag,
   je: JeFlag,
   jo: JoFlag,
   kz: KzFlag,
   ke: KeFlag,
   ki: KiFlag,
   kp: KpFlag,
   kr: KrFlag,
   kw: KwFlag,
   kg: KgFlag,
   la: LaFlag,
   lv: LvFlag,
   lb: LbFlag,
   ls: LsFlag,
   lr: LrFlag,
   ly: LyFlag,
   li: LiFlag,
   lt: LtFlag,
   lu: LuFlag,
   mo: MoFlag,
   mg: MgFlag,
   mw: MwFlag,
   my: MyFlag,
   mv: MvFlag,
   ml: MlFlag,
   mt: MtFlag,
   mh: MhFlag,
   mq: MqFlag,
   mr: MrFlag,
   mu: MuFlag,
   yt: YtFlag,
   mx: MxFlag,
   fm: FmFlag,
   md: MdFlag,
   mc: McFlag,
   mn: MnFlag,
   me: MeFlag,
   ms: MsFlag,
   ma: MaFlag,
   mz: MzFlag,
   mm: MmFlag,
   na: NaFlag,
   nr: NrFlag,
   np: NpFlag,
   nl: NlFlag,
   nc: NcFlag,
   nz: NzFlag,
   ni: NiFlag,
   ne: NeFlag,
   ng: NgFlag,
   nu: NuFlag,
   nf: NfFlag,
   mp: MpFlag,
   no: NoFlag,
   om: OmFlag,
   pk: PkFlag,
   pw: PwFlag,
   ps: PsFlag,
   pa: PaFlag,
   pg: PgFlag,
   py: PyFlag,
   pe: PeFlag,
   ph: PhFlag,
   pn: PnFlag,
   pl: PlFlag,
   pt: PtFlag,
   pr: PrFlag,
   qa: QaFlag,
   mk: MkFlag,
   ro: RoFlag,
   ru: RuFlag,
   rw: RwFlag,
   re: ReFlag,
   bl: BlFlag,
   sh: ShFlag,
   kn: KnFlag,
   lc: LcFlag,
   mf: MfFlag,
   pm: PmFlag,
   vc: VcFlag,
   ws: WsFlag,
   sm: SmFlag,
   st: StFlag,
   sa: SaFlag,
   sn: SnFlag,
   rs: RsFlag,
   sc: ScFlag,
   sl: SlFlag,
   sg: SgFlag,
   sx: SxFlag,
   sk: SkFlag,
   si: SiFlag,
   sb: SbFlag,
   so: SoFlag,
   za: ZaFlag,
   gs: GsFlag,
   ss: SsFlag,
   es: EsFlag,
   lk: LkFlag,
   sd: SdFlag,
   sr: SrFlag,
   sj: SjFlag,
   se: SeFlag,
   ch: ChFlag,
   sy: SyFlag,
   tw: TwFlag,
   tj: TjFlag,
   tz: TzFlag,
   th: ThFlag,
   tl: TlFlag,
   tg: TgFlag,
   tk: TkFlag,
   to: ToFlag,
   tt: TtFlag,
   tn: TnFlag,
   tr: TrFlag,
   tm: TmFlag,
   tc: TcFlag,
   tv: TvFlag,
   ug: UgFlag,
   ua: UaFlag,
   ae: AeFlag,
   gb: GbFlag,
   um: UmFlag,
   us: UsFlag,
   uy: UyFlag,
   uz: UzFlag,
   vu: VuFlag,
   ve: VeFlag,
   vn: VnFlag,
   vg: VgFlag,
   vi: ViFlag,
   wf: WfFlag,
   eh: EhFlag,
   ye: YeFlag,
   zm: ZmFlag,
   zw: ZwFlag,
   ax: AxFlag,
};
