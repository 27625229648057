import { Assignment } from '@models/Course';
import ISubmissionEvent, {
   SubmissionEventDescription,
   SubmissionEventLevel,
} from '@models/ISubmissionEvent';

import AxiosService from './AxiosService';

export const recordSubmissionEvent = (
   assignment: Assignment,
   submissionId: number | string,
   description: SubmissionEventDescription,
   level?: SubmissionEventLevel,
   image?: Blob | undefined,
): void => {
   const bodyData = new FormData();
   bodyData.append('description', description as string);

   if (level) {
      bodyData.append('level', level);
   }

   if (image) {
      bodyData.append('image', image);
   }

   if (assignment?.trackStudentEvents) {
      AxiosService.post<void>(`/api/activities/${submissionId}/event`, bodyData);
   }
};

export const getSubmissionEvents = (submissionId: number): Promise<readonly ISubmissionEvent[]> =>
   AxiosService.get<{
      events: readonly ISubmissionEvent[];
   }>(`/api/activities/${submissionId}/event`).then((response) => response.data?.events);
