import * as React from 'react';

import Button from '@components/Common/Button';
import CourseService, { SimpleCourse } from '@services/CourseService';
import classnames from 'classnames';

import DocumentTitle from '@components/DocumentTitle';
import CourseCreateSelectTemplateTag from './CourseCreateSelectTemplateTag';
import { Steps } from './types';

interface CourseCreateSelectTemplateProps {
   setCourseToCloneFromId(courseId: number): void;
   setStep(step: Steps): void;
}

const CourseCreateSelectTemplate: React.FC<CourseCreateSelectTemplateProps> = (props) => {
   const [rows, setRows] = React.useState<readonly SimpleCourse[]>([]);
   const [filteredRows, setFilteredRows] = React.useState<readonly SimpleCourse[]>([]);
   const [selectedRow, setSelectedRow] = React.useState<SimpleCourse | undefined>(undefined);

   React.useEffect(() => {
      CourseService.getCloneableCourses().then((courses) => {
         setRows(courses);
         setFilteredRows(courses);
      });
   }, []);

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value.toLowerCase();
      setFilteredRows(rows.filter((row) => row.name.toLowerCase().includes(value)));
   };

   const useSelectedTemplate = () => {
      if (!selectedRow) return;

      props.setCourseToCloneFromId(selectedRow.id);
      props.setStep('form');
   };

   return (
      <>
         <DocumentTitle>Create Course</DocumentTitle>
         <div className='content-main margin-right-m center course-create-select-template'>
            <div className='card bottom-padding no-padding'>
               <div className='card-title'>
                  <div className='title'>Create From Course Template</div>
               </div>
               <div className='margin-left-m margin-top-l margin-right-m margin-bottom-m'>
                  <input
                     type='search'
                     name='searchQuery'
                     autoFocus
                     placeholder='Search Courses'
                     onChange={handleSearchChange}
                  />
               </div>
               <div className='card-title'>
                  <div className='title'>Courses</div>
                  <div className='clone-list-course-type-header title'>Course Type</div>
               </div>
               <ul className='no-padding clone-list no-margin '>
                  {filteredRows.map((row) => (
                     <li
                        key={`clone-course-${row.id}`}
                        data-test={`clone-course-${row.id}`}
                        className={classnames(
                           'padding-left-m padding-top-s padding-right-m padding-bottom-s border-bottom flex between-xs flex-wrap',
                           { selected: selectedRow ? row.id === selectedRow.id : false },
                        )}
                        onClick={() => setSelectedRow(row)}
                     >
                        <span className='course-name small'>{row.name}</span>

                        <CourseCreateSelectTemplateTag row={row} />
                     </li>
                  ))}
               </ul>
               <div className='flex flex-center padding-top-l padding-bottom-l border-top'>
                  <Button line onClick={() => props.setStep('chose_template_or_new')}>
                     Cancel
                  </Button>
                  <Button
                     className='margin-left-m'
                     data-test='btn-use-template'
                     disabled={!selectedRow}
                     onClick={() => useSelectedTemplate()}
                  >
                     Use Template
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
};

export default CourseCreateSelectTemplate;
