import * as React from 'react';

import { Option } from '@components/Core/FilterDropDown';
import { Tab, Tabs } from '@components/Core/Tabs';
import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import {
   InstructorProficiencyReport,
   InstructorProficiencySummaryRow,
   ProficiencyLevelName,
   ProficiencyReportTileBreakdownBySkill,
   StudentInInstructorProficiencyCourse,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import CanDoStatementSummary from '@components/ProficiencyTracking/CanDoStatementSummary';
import ProficiencyReportHeader from '@components/ProficiencyTracking/ProficiencyReportHeader';
import {
   buildDistrictReportBreadcrumbs,
   districtReportLinkReplace,
   getProficiencyReportTileData,
} from '@components/ProficiencyTracking/ProficiencyReportHelpers';
import ProficiencyReportOverviewTiles from '@components/ProficiencyTracking/ProficiencyReportOverviewTiles';
import ProficiencyReportTable from '@components/ProficiencyTracking/ProficiencyReportTable';

type Params = {
   courseId: string;
   standardCourseId: string;
   instructorId: string;
   organizationId: string;
};

type TabNames = 'instructor-proficiency-summary-tab' | 'can-do-statement-summary';

const InstructorProficiencySummary: React.FC = () => {
   const { userProfile, setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const params = useParams<Params>();

   // Component State
   const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [hasError, setHasError] = React.useState<boolean>(false);
   const [currentTab, setCurrentTab] = React.useState<TabNames>(
      'instructor-proficiency-summary-tab',
   );

   // Filters
   const [selectedLevel, setSelectedLevel] = React.useState<ProficiencyLevelName | undefined>(
      undefined,
   );
   const [selectedCourseSections, setSelectedCourseSections] = React.useState<Option[]>([]);

   // Data
   const [instructorReport, setInstructorReport] = React.useState<
      InstructorProficiencyReport | undefined
   >(undefined);
   const [skillBreakdown, setSkillBreakdown] = React.useState<
      ProficiencyReportTileBreakdownBySkill | undefined
   >(undefined);

   const handleLocalError = () => {
      setHasError(true);
      setIsLoading(false);
   };

   const fetchInstructorSummary = async () => {
      if (!params.courseId && !params.standardCourseId) {
         return handleLocalError();
      }

      if (params.standardCourseId && (!params.instructorId || !params.organizationId)) {
         return handleLocalError();
      }

      const courseId = (params.standardCourseId || params.courseId) as string;

      try {
         setHasError(false);
         setIsLoading(true);

         const courseSectionIds = selectedCourseSections.map((i) => i.id);
         const currentUserId = userProfile.id.toString();
         const result = await ProficiencyReportService.getInstructorSummary(
            courseId,
            params.instructorId || currentUserId,
            courseSectionIds,
            selectedLevel,
         );

         setInstructorReport(result);
         setSkillBreakdown(
            getProficiencyReportTileData<InstructorProficiencySummaryRow>(
               result.courseCanDoStatementSummaries,
               result.courseEndDate,
               result.courseStartDate,
               result.instructorSummary,
            ),
         );

         if (isInitialLoad) {
            await buildBreadcrumbs(result.courseName);
         }
      } catch (error) {
         console.error(error);
         setHasError(true);
      } finally {
         setIsLoading(false);
         setIsInitialLoad(false);
      }
   };

   const buildBreadcrumbs = async (courseName: string) => {
      if (params.courseId && !params.standardCourseId) {
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: Constants.routes.courses.dashboard.replace(
                     ':courseId',
                     params.courseId || '',
                  ),
                  text: courseName,
               },
               {
                  link: Constants.routes.courses.instructorSummary.replace(
                     ':courseId',
                     params.courseId || '',
                  ),
                  text: 'Proficiency Summary',
               },
            ],
            prev: null,
            next: null,
         });

         return;
      }

      const breadcrumbs = await buildDistrictReportBreadcrumbs({
         standardCourseId: params.standardCourseId,
         organizationId: params.organizationId,
         instructorId: params.instructorId,
      });

      setBreadcrumbs({
         breadcrumbs,
         prev: null,
         next: null,
      });
   };

   const linkBuilder = (row: StudentInInstructorProficiencyCourse) => {
      if (params.courseId && !params.standardCourseId) {
         return Constants.routes.courses.instructorStudentSummary
            .replace(':courseId', params.courseId)
            .replace(':studentId', row.id.toString());
      }

      return districtReportLinkReplace(
         Constants.routes.proficiencyTracking.districtStudentSummary,
         {
            standardCourseId: params.standardCourseId,
            organizationId: params.organizationId,
            instructorId: params.instructorId,
            courseId: row.courseId.toString(),
            studentId: row.id.toString(),
         },
      );
   };

   React.useEffect(() => {
      fetchInstructorSummary();
   }, [selectedCourseSections, selectedLevel]);

   if (isInitialLoad) {
      return <Loader />;
   }

   if (hasError || instructorReport === undefined || skillBreakdown === undefined) {
      return <ErrorDisplay />;
   }

   return (
      <div className='padding-left-l padding-right-m'>
         <ProficiencyReportHeader
            reportName={instructorReport.reportName}
            courseId={params.courseId}
            courseSectionChanged={setSelectedCourseSections}
            proficiencyLevelChanged={setSelectedLevel}
         />
         {isLoading ? (
            <Loader />
         ) : (
            <>
               <Tabs
                  className='table-toggle'
                  defaultTab={currentTab}
                  onTabChange={(tabName) => setCurrentTab(tabName as TabNames)}
               >
                  <Tab
                     id='instructor-proficiency-summary-tab'
                     className='table-toggle-tab'
                     heading='Instructors'
                     key='instructor-proficiency-summary-tab'
                     data-test='instructor-proficiency-summary-tab'
                  >
                     <ProficiencyReportOverviewTiles skillBreakdown={skillBreakdown} />
                     <ProficiencyReportTable<
                        InstructorProficiencySummaryRow,
                        StudentInInstructorProficiencyCourse
                     >
                        data={instructorReport.instructorSummary}
                        data-test='instructor-proficiency-summary'
                        groupById='studentId'
                        linkBuilder={linkBuilder}
                        rows={instructorReport.studentInInstructorProficiencyCourses}
                        skillBreakdown={skillBreakdown}
                     />
                  </Tab>
                  <Tab
                     id='can-do-statement-summary'
                     className='table-toggle-tab'
                     heading='Can-Do Statements'
                     key='can-do-statement-summary'
                     data-test='can-do-statement-summary'
                  >
                     <CanDoStatementSummary
                        instructorId={params.instructorId}
                        proficiencyLevelSelected={selectedLevel}
                        schoolId={params.organizationId}
                        standardCourseId={params.standardCourseId}
                        courseId={params.courseId}
                     />
                  </Tab>
               </Tabs>
            </>
         )}
      </div>
   );
};

export default InstructorProficiencySummary;
