import * as React from 'react';

import { ActivityMode } from '@models/Activity';
import { RouteObject, useRoutes } from 'react-router-dom';

import { ActivityBuilderProps } from './components/Activity/Builder/ActivityBuilder';
import ActivityBuilderContainer from './components/Activity/Builder/ActivityBuilderContainer';
import ActivityCompleter from './components/Activity/Completer/ActivityCompleter';
import ActivityGrader from './components/Activity/Grader/ActivityGrader';
import BookstoreCodeGenerator from './components/Admin/BookstoreCodeGenerator';
import ImpersonateUser from './components/Admin/ImpersonateUser';
import InstructorInvitation from './components/Admin/InstructorInvitation';
import InterviewQuestionTable from './components/Admin/InterviewQuestions';
import StyleSheets from './components/Admin/StyleSheets';
import CourseEnrollments from './components/AdminCourseEnrollmentsTable/CourseEnrollments';
import Courses from './components/AdminCoursesTable/Courses';
import ExamOrdersTable from './components/AdminExamOrders/ExamOrdersTable';
import NationalExamOrderDetails from './components/AdminExamOrders/NationalExamOrderDetails';
import Licenses from './components/AdminLicensesTable';
import ExamsTable from './components/AdminNationalExams/ExamsTable';
import SchoolDetails from './components/AdminSchoolsTable/SchoolDetails/SchoolDetails';
import Schools from './components/AdminSchoolsTable/Schools';
import UserDetails from './components/AdminUsersTable/UserDetails/UserDetails';
import Users from './components/AdminUsersTable/Users';
import AuthRequestCallback from './components/Auth/AuthRequestCallback';
import Login from './components/Auth/Login';
import LoginSSO from './components/Auth/LoginSSO';
import Logout from './components/Auth/Logout';
import TokenLogin from './components/Auth/TokenLogin';
import TokenLoginFailure from './components/Auth/TokenLoginFailure';
import ConfirmEmail from './components/ConfirmEmail';
import ContentLibrary from './components/ContentLibrary/ContentLibrary';
import ClassComponentRouterWrapper from './components/Core/ClassComponentRouterWrapper';
import CloneCourse from './components/Course/CloneCourse';
import CourseCreateFromCanvas from './components/Course/CourseEditor/CourseCreateFromCanvas';
import CourseEditor from './components/Course/CourseEditor/CourseEditor';
import EnrollmentOverview from './components/Course/EnrollmentOverview';
import Gradebook from './components/Course/Gradebook/Gradebook';
import JoinCourse from './components/Course/JoinCourse';
import Modules from './components/Course/Modules/Modules';
import MyGrades from './components/Course/MyGrades';
import CourseProficiency from './components/Course/Proficiency';
import CourseRoster from './components/Course/Roster/CourseRoster';
import ActivitySubmissions from './components/Course/Submissions/ActivitySubmissions';
import VocabStats from './components/Course/VocabStats/VocabStats';
import CourseDashboard from './components/Dashboard/CourseDashboard';
import ErrorDisplay from './components/ErrorDisplay';
import ForgotPassword from './components/ForgotPassword';
import { HelpLauncherSize } from './components/HelpLauncher';
import Landing from './components/Landing/Landing';
import LessonBuilder from './components/Lesson/LessonBuilder';
import LessonViewer from './components/Lesson/LessonViewer';
import MasterRoute from './components/MasterRoute';
import InvoiceDownloadPage from './components/NationalExam/InvoiceDownloadPage';
import NationalExamDashboard from './components/NationalExam/NationalExamDashboard';
import NationalExamRegistration from './components/NationalExam/NationalExamRegistration';
import NationalExamSetup from './components/NationalExam/NationalExamSetup';
import NationalExams from './components/NationalExamAdmin/Exams';
import NationalExamCourseSearch from './components/NationalExamAdmin/NationalExamCourseSearch';
import NationalExamSummaryDetails from './components/NationalExamAdmin/NationalExamSummaryDetails';
import CanDoStatementsTable from './components/ProficiencyTracking/CanDoStatementsTable';
import DistrictProficiencySummary from './components/ProficiencyTracking/DistrictProficiencySummary';
import InstructorProficiencySummary from './components/ProficiencyTracking/InstructorProficiencySummary';
import ProgramProficiencySummary from './components/ProficiencyTracking/ProgramProficiencySummary';
import SchoolProficiencySummary from './components/ProficiencyTracking/SchoolProficiencySummary';
import StudentProficiencySummaryPage from './components/ProficiencyTracking/StudentProficiencySummary/Page';
import RegisterForm from './components/Register/RegisterForm';
import ResetPassword from './components/ResetPassword';
import StudentVocabStats from './components/StudentVocabStats/StudentVocabStats';
import TechCheck from './components/TechCheck/TechCheck';
import UserSettingsContainer from './components/UserSettingsContainer';
import BulkVideoUploader from './components/Video/BulkVideoUploader';
import VideoMap from './components/Video/Map';
import VideoSpeakerTable from './components/Video/SpeakerTable';
import VideoViewer from './components/Video/VideoViewer';
import ViewModuleItem from './components/ViewModuleItem';
import VocabBuilder from './components/VocabBuilder/VocabBuilder';
import VocabMatching from './components/VocabMatching/VocabMatching';
import VocabSession from './components/VocabSession/VocabSession';
import VocabSetOverview from './components/VocabSetOverview/VocabSetOverview';
import VocabViewer from './components/VocabViewer/VocabViewer';
import Constants from './Constants';

const AppRoutes: React.FC = () => {
   const {
      confirmEmail,
      courses,
      enrollments,
      forgotPassword,
      landing,
      licenses,
      proficiencyTracking,
      register,
      resetPassword,
      settings,
      studentVocabStats,
      techCheck,
      users,
   } = Constants.routes;
   const { viewUsers } = users;
   const { viewLicenses } = licenses;
   const { viewCourseEnrollments } = courses;

   const routesArray: RouteObject[] = [
      {
         path: '',
         element: (
            <MasterRoute>
               <CourseDashboard />
            </MasterRoute>
         ),
      },
      {
         path: '*',
         element: (
            <MasterRoute>
               <ErrorDisplay statusCode={404} />
            </MasterRoute>
         ),
      },
      { path: register, element: <RegisterForm /> },
      { path: forgotPassword, element: <ForgotPassword /> },
      { path: resetPassword, element: <ResetPassword /> },
      { path: confirmEmail, element: <ConfirmEmail /> },
      { path: techCheck, element: <TechCheck /> },
      { path: landing, element: <Landing /> },
      {
         path: viewLicenses,
         element: (
            <MasterRoute admin>
               <Licenses />
            </MasterRoute>
         ),
      },
      {
         path: proficiencyTracking.canDoStatements,
         element: (
            <MasterRoute>
               <CanDoStatementsTable />
            </MasterRoute>
         ),
      },
      {
         path: viewUsers,
         element: (
            <MasterRoute admin>
               <Users />
            </MasterRoute>
         ),
      },
      {
         path: studentVocabStats,
         element: (
            <MasterRoute>
               <StudentVocabStats />
            </MasterRoute>
         ),
      },
      {
         path: viewCourseEnrollments,
         element: (
            <MasterRoute admin>
               <CourseEnrollments />
            </MasterRoute>
         ),
      },
      {
         path: enrollments.byId,
         element: (
            <MasterRoute instructor>
               <EnrollmentOverview />
            </MasterRoute>
         ),
      },
      {
         path: settings,
         element: (
            <MasterRoute>
               <UserSettingsContainer />
            </MasterRoute>
         ),
      },
      {
         path: 'vocab_sets/:moduleItemId/overview',
         element: (
            <MasterRoute instructor>
               <VocabSetOverview />
            </MasterRoute>
         ),
      },
      {
         path: 'auth',
         children: [
            {
               path: 'login',
               children: [
                  { path: '', element: <Login /> },
                  { path: 'sso', element: <LoginSSO /> },
               ],
            },
            { path: 'logout', element: <Logout /> },
            {
               path: 'token_login',
               element: <TokenLogin />,
            },
            { path: 'token_login_failure', element: <TokenLoginFailure /> },
            { path: 'request_callback', element: <AuthRequestCallback /> },
         ],
      },
      {
         // FIXME: all admin routes should really be under this route.
         path: 'admin',
         children: [
            {
               path: 'instructor_invitations',
               element: (
                  <MasterRoute admin>
                     <InstructorInvitation />
                  </MasterRoute>
               ),
            },
            {
               path: 'style_sheets',
               element: (
                  <MasterRoute admin>
                     <StyleSheets />
                  </MasterRoute>
               ),
            },
            {
               path: 'impersonate_user',
               element: (
                  <MasterRoute admin>
                     <ImpersonateUser />
                  </MasterRoute>
               ),
            },
            {
               path: 'bookstore_code_generator',
               element: (
                  <MasterRoute admin>
                     <BookstoreCodeGenerator />
                  </MasterRoute>
               ),
            },
            {
               path: 'national_exams',
               children: [
                  {
                     path: '',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute admin>
                                 <ExamsTable />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
                  {
                     path: ':examIdOrNew',
                     element: (
                        <MasterRoute>
                           <NationalExamSummaryDetails />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'orders',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute admin>
                                 <ExamOrdersTable />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: ':orderId',
                           element: (
                              <MasterRoute admin>
                                 <NationalExamOrderDetails />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
            {
               path: 'users',
               children: [
                  {
                     path: ':userId',
                     element: (
                        <MasterRoute admin>
                           <UserDetails />
                        </MasterRoute>
                     ),
                  },
               ],
            },
         ],
      },
      {
         path: 'content',
         children: [
            {
               path: 'activities',
               children: [
                  {
                     path: 'new',
                     element: (
                        <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                           <ClassComponentRouterWrapper<ActivityBuilderProps>
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              component={ActivityBuilderContainer as any}
                              componentProps={{ mode: ActivityMode.create }}
                           />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: ':activityId',
                     children: [
                        {
                           path: 'preview',
                           element: (
                              <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                                 <ClassComponentRouterWrapper
                                    component={ActivityCompleter}
                                    componentProps={{
                                       mode: ActivityMode.preview,
                                    }}
                                 />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'edit',
                           element: (
                              <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                                 <ClassComponentRouterWrapper<ActivityBuilderProps>
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    component={ActivityBuilderContainer as any}
                                    componentProps={{ mode: ActivityMode.edit }}
                                 />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
            {
               path: 'lessons',
               children: [
                  {
                     path: 'new',
                     element: (
                        <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                           <LessonBuilder />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: ':lessonId',
                     children: [
                        {
                           path: 'edit',
                           element: (
                              <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                                 <LessonBuilder />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'view',
                           element: (
                              <MasterRoute helpLauncherSize={HelpLauncherSize.small}>
                                 <LessonViewer />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
            {
               path: 'videos',
               children: [
                  {
                     path: 'bulk_upload',
                     element: (
                        <MasterRoute
                           instructor
                           helpLauncherSize={HelpLauncherSize.small}
                           feature={{ feature: 'bulk_video', shouldAllowAdmin: true }}
                        >
                           <BulkVideoUploader />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: ':videoId',
                     children: [
                        {
                           path: 'view',
                           element: (
                              <MasterRoute helpLauncherSize={HelpLauncherSize.small}>
                                 <VideoViewer />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
                  {
                     path: 'map',
                     element: (
                        <MasterRoute helpLauncherSize={HelpLauncherSize.small}>
                           <VideoMap />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'interview_questions',
                     element: (
                        <MasterRoute
                           feature={{
                              feature: 'video_interview_questions',
                              shouldAllowAdmin: true,
                           }}
                        >
                           <InterviewQuestionTable />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'speakers',
                     element: (
                        <MasterRoute
                           helpLauncherSize={HelpLauncherSize.small}
                           feature={{ feature: 'speaker_editor', shouldAllowAdmin: true }}
                        >
                           <VideoSpeakerTable />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: 'vocab_sets',
               children: [
                  {
                     path: 'new',
                     element: (
                        <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                           <VocabBuilder />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: ':vocabSetId',
                     children: [
                        {
                           path: 'edit',
                           element: (
                              <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                                 <VocabBuilder />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'view',
                           element: (
                              <MasterRoute>
                                 <VocabViewer />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'session/:sessionType',
                           element: (
                              <MasterRoute fullScreen>
                                 <VocabSession />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'matching',
                           element: (
                              <MasterRoute fullScreen>
                                 <VocabMatching />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
         ],
      },
      {
         path: 'national_exams',
         children: [
            {
               path: 'admin',
               children: [
                  {
                     path: 'course_search',
                     element: (
                        <MasterRoute>
                           <NationalExamCourseSearch />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'exams',
                     element: (
                        <MasterRoute>
                           <NationalExams />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: ':examUrlSlug',
               children: [
                  {
                     path: '',
                     element: (
                        <MasterRoute>
                           <NationalExamDashboard />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'register',
                     element: (
                        <MasterRoute>
                           <NationalExamRegistration />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'setup',
                     element: (
                        <MasterRoute>
                           <NationalExamSetup />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: 'orders/:orderId',
               children: [
                  {
                     path: 'invoice',
                     element: (
                        <MasterRoute>
                           <InvoiceDownloadPage />
                        </MasterRoute>
                     ),
                  },
               ],
            },
         ],
      },
      {
         path: 'courses',
         children: [
            {
               path: '',
               element: (
                  <MasterRoute admin>
                     <Courses />
                  </MasterRoute>
               ),
            },
            {
               path: 'join',
               element: (
                  <MasterRoute>
                     <JoinCourse />
                  </MasterRoute>
               ),
            },
            {
               path: 'create',
               element: (
                  <MasterRoute instructor navbar>
                     <ClassComponentRouterWrapper
                        component={CourseEditor}
                        componentProps={{ mode: 'create' }}
                     />
                  </MasterRoute>
               ),
            },
            {
               path: 'create_from_canvas',
               element: (
                  <MasterRoute>
                     <CourseCreateFromCanvas />
                  </MasterRoute>
               ),
            },
            {
               path: 'clone',
               element: (
                  <MasterRoute>
                     <CloneCourse />
                  </MasterRoute>
               ),
            },
            {
               path: ':courseId',
               children: [
                  {
                     path: '',
                     element: (
                        <MasterRoute>
                           <CourseDashboard />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'edit',
                     element: (
                        <MasterRoute instructor>
                           <ClassComponentRouterWrapper
                              component={CourseEditor}
                              componentProps={{ mode: 'edit' }}
                           />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'roster',
                     element: (
                        <MasterRoute instructor>
                           <CourseRoster />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'grades',
                     element: (
                        <MasterRoute>
                           <MyGrades />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'gradebook',
                     element: (
                        <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                           <ClassComponentRouterWrapper component={Gradebook} />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'vocab_stats',
                     element: (
                        <MasterRoute instructor>
                           <VocabStats />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'proficiency',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute instructor>
                                 <InstructorProficiencySummary />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'can_do_statements',
                           element: (
                              <MasterRoute instructor>
                                 <CourseProficiency />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'student_summary',
                           children: [
                              {
                                 path: '',
                                 element: (
                                    <MasterRoute>
                                       <StudentProficiencySummaryPage />
                                    </MasterRoute>
                                 ),
                              },
                              {
                                 path: ':studentId',
                                 element: (
                                    <MasterRoute instructor>
                                       <StudentProficiencySummaryPage />
                                    </MasterRoute>
                                 ),
                              },
                           ],
                        },
                     ],
                  },
                  {
                     path: 'modules',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute>
                                 <Modules />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'new',
                           element: (
                              <MasterRoute>
                                 <Modules />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: ':moduleId',
                           children: [
                              {
                                 path: '',
                                 element: (
                                    <MasterRoute>
                                       <Modules />
                                    </MasterRoute>
                                 ),
                              },
                              {
                                 path: 'view',
                                 element: (
                                    <MasterRoute>
                                       <Modules />
                                    </MasterRoute>
                                 ),
                              },
                              {
                                 path: 'edit',
                                 element: (
                                    <MasterRoute>
                                       <Modules />
                                    </MasterRoute>
                                 ),
                              },
                           ],
                        },
                     ],
                  },
                  {
                     path: 'module_items',
                     children: [
                        {
                           path: ':moduleItemId',
                           element: (
                              <MasterRoute>
                                 <ViewModuleItem />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
         ],
      },
      {
         path: 'activities',
         children: [
            {
               path: 'submission/:submissionId',
               children: [
                  {
                     path: 'complete',
                     element: (
                        <MasterRoute helpLauncherSize={HelpLauncherSize.small}>
                           <ClassComponentRouterWrapper
                              component={ActivityCompleter}
                              componentProps={{ mode: ActivityMode.complete }}
                           />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: 'moduleItem/:moduleItemId',
               children: [
                  {
                     path: 'complete',
                     element: (
                        <MasterRoute helpLauncherSize={HelpLauncherSize.small}>
                           <ClassComponentRouterWrapper
                              component={ActivityCompleter}
                              componentProps={{ mode: ActivityMode.complete }}
                           />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: ':submissionId',
               children: [
                  {
                     path: 'grade',
                     element: (
                        <MasterRoute helpLauncherSize={HelpLauncherSize.small} navbar={false}>
                           <ClassComponentRouterWrapper component={ActivityGrader} />
                        </MasterRoute>
                     ),
                  },
               ],
            },
            {
               path: ':moduleItemId',
               children: [
                  {
                     path: 'responses/:promptId',
                     element: (
                        <MasterRoute helpLauncherSize={HelpLauncherSize.small} navbar={false}>
                           <ClassComponentRouterWrapper component={ActivityGrader} />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'submissions',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute instructor helpLauncherSize={HelpLauncherSize.small}>
                                 <ActivitySubmissions />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: ':submissionId',
                           element: (
                              <MasterRoute instructor navbar={false}>
                                 <ClassComponentRouterWrapper component={ActivityGrader} />
                              </MasterRoute>
                           ),
                        },
                     ],
                  },
               ],
            },
            {
               path: 'responses/:promptId',
               element: (
                  <MasterRoute helpLauncherSize={HelpLauncherSize.small} navbar={false}>
                     <ClassComponentRouterWrapper component={ActivityGrader} />
                  </MasterRoute>
               ),
            },
         ],
      },
      {
         // Might be able to use a map here
         path: 'content_library',
         children: [
            {
               path: '',
               element: (
                  <MasterRoute instructor>
                     <ContentLibrary />
                  </MasterRoute>
               ),
            },
            {
               path: ':libraryName',
               children: [
                  {
                     path: '',
                     element: (
                        <MasterRoute instructor>
                           <ContentLibrary />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: ':folderId',
                     element: (
                        <MasterRoute instructor>
                           <ContentLibrary />
                        </MasterRoute>
                     ),
                  },
               ],
            },
         ],
      },
      {
         path: 'schools',
         children: [
            {
               path: '',
               element: (
                  <MasterRoute admin>
                     <Schools />
                  </MasterRoute>
               ),
            },
            {
               path: 'new',
               element: (
                  <MasterRoute admin>
                     <SchoolDetails />
                  </MasterRoute>
               ),
            },
            {
               path: ':organizationId',
               element: (
                  <MasterRoute admin>
                     <SchoolDetails />
                  </MasterRoute>
               ),
            },
         ],
      },
      {
         path: 'proficiency',
         children: [
            {
               path: 'district_report',
               children: [
                  {
                     path: '',
                     element: (
                        <MasterRoute districtAdmin>
                           <DistrictProficiencySummary />
                        </MasterRoute>
                     ),
                  },
                  {
                     path: 'standard_course/:standardCourseId',
                     children: [
                        {
                           path: '',
                           element: (
                              <MasterRoute districtAdmin>
                                 <ProgramProficiencySummary />
                              </MasterRoute>
                           ),
                        },
                        {
                           path: 'school/:organizationId/',
                           children: [
                              {
                                 path: '',
                                 element: (
                                    <MasterRoute districtAdmin>
                                       <SchoolProficiencySummary />
                                    </MasterRoute>
                                 ),
                              },
                              {
                                 path: 'instructor/:instructorId',
                                 children: [
                                    {
                                       path: '',
                                       element: (
                                          <MasterRoute districtAdmin>
                                             <InstructorProficiencySummary />
                                          </MasterRoute>
                                       ),
                                    },
                                    {
                                       path: 'course/:courseId/student/:studentId',
                                       element: (
                                          <MasterRoute districtAdmin>
                                             <StudentProficiencySummaryPage />
                                          </MasterRoute>
                                       ),
                                    },
                                 ],
                              },
                           ],
                        },
                     ],
                  },
               ],
            },
         ],
      },
   ];
   const routes = useRoutes(routesArray);

   return <>{routes}</>;
};

export default AppRoutes;
