import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';

import Config from '../scripts/Config';
import Constants from '../scripts/Constants';

export const setupBrowserConfiguration = (): void => {
   // make sure browsers know we want to handle scroll-position restoration ourselves
   if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
   }
};

export const setupDatadog = (): void => {
   const { applicationId, clientToken, site, service, version, env } = Constants.dataDog;

   if (Config.environmentType !== 'production') {
      return;
   }

   // FIXME: Limit sessions to specific environments and users after our DD trial
   datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      version,
      env: env[Config.environmentType],
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [/https:\/\/.*\.lingco\.io/],
   });

   datadogRum.startSessionReplayRecording();
};

export const setupMixpanel = (): void => {
   mixpanel.init(Constants.mixPanel[Config.environmentType]);
};
