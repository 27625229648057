import React from 'react';

import {
   ActivityCompleterMode,
   ActivityPrompt,
   ActivityResponse,
   ActivityResponseEvaluation,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import { ActivityConsumer } from './ActivityCompleter';
import Prompt from './Prompt';
import { QuestionProvider } from './Question';

export type CompleterPromptProps = {
   evaluations: Record<number, ActivityResponseEvaluation<ActivityCompleterMode>>;
   prompt: ActivityPrompt<ActivityCompleterMode>;
   readyToRecord: Maybe<{
      delay: Maybe<number>;
      promptId: string | number;
   }>;
   responses: Record<number, ActivityResponse>;
   showMissed: boolean;
   postDiscussionBoardResponse(itemId: string | number, entry: string, parentId: number): void;
   setResponse(itemId: string | number, response: ActivityResponse, callback?: () => void): void;
   saveResponse(itemId: string | number): void;
   setDisableSubmit(disableSubmit: boolean): void;
};

const CompleterPrompt: React.FC<CompleterPromptProps> = (props) => {
   const { id } = props.prompt;

   return (
      <ActivityConsumer>
         {({ isClosed, mode, showPointsPossible }) => (
            <QuestionProvider value={{ readyToRecord: props.readyToRecord }}>
               <Prompt
                  evaluation={props.evaluations[id]}
                  isClosed={isClosed}
                  mode={mode}
                  prompt={props.prompt}
                  response={props.responses[id]}
                  showMissed={props.showMissed}
                  postDiscussionBoardResponse={(entry, parentId) => {
                     props.postDiscussionBoardResponse(id, entry, parentId);
                  }}
                  showPointsPossible={showPointsPossible}
                  saveResponse={() => {
                     props.saveResponse(id);
                  }}
                  setResponse={(r, f) => {
                     props.setResponse(id, r, f);
                  }}
                  setDisableSubmit={props.setDisableSubmit}
               />
            </QuestionProvider>
         )}
      </ActivityConsumer>
   );
};

export default CompleterPrompt;
