// @ts-strict-ignore
import { Maybe } from '@models/Core';
import {
   ProficiencyLevelName,
   StudentProficiencyCanDoStatementStatus,
   StudentProficiencySummaryRow,
} from '@models/Proficiency';
import moment from 'moment';

import {
   getWeekBreakdownOfDateRange,
   WeeksWithIndex,
} from '@components/ProficiencyTracking/ProficiencyReportHelpers';

export type StudentProficiencyBars = {
   numberOfNoviceLowCompleted: number;
   numberOfNoviceMidCompleted: number;
   numberOfNoviceHighCompleted: number;
   numberOfIntermediateLowCompleted: number;
   numberOfIntermediateMidCompleted: number;
   numberOfIntermediateHighCompleted: number;
   numberOfAdvancedLowCompleted: number;
   numberOfAdvancedMidCompleted: number;
   numberOfAdvancedHighCompleted: number;
};

type FlattenedStatusesRow = Omit<StudentProficiencySummaryRow, 'statuses'> & {
   dateFirstOffered: Date;
   dateSuccessfullyCompleted: Date;
};

export type StudentProficiencySummaryBarchartData = StudentProficiencyBars &
   WeeksWithIndex & {
      columnName: string;
      availableStatementsNotCompleted: number;
      totalAvailableStatements: number;
   };

type LevelToBarChartKey = Record<ProficiencyLevelName, keyof StudentProficiencyBars>;

const LevelNameToDataKeys: LevelToBarChartKey = {
   'Novice Low': 'numberOfNoviceLowCompleted',
   'Novice Mid': 'numberOfNoviceMidCompleted',
   'Novice High': 'numberOfNoviceHighCompleted',
   'Intermediate Low': 'numberOfIntermediateLowCompleted',
   'Intermediate Mid': 'numberOfIntermediateMidCompleted',
   'Intermediate High': 'numberOfIntermediateHighCompleted',
   'Advanced Low': 'numberOfAdvancedLowCompleted',
   'Advanced Mid': 'numberOfAdvancedMidCompleted',
   'Advanced High': 'numberOfAdvancedHighCompleted',
};

export const getDateFirstOffered = (x: StudentProficiencyCanDoStatementStatus[]): Date => {
   const firstTimeOffered = x.reduce((a, b) => (a.dateAvailable < b.dateAvailable ? a : b));
   return firstTimeOffered.dateAvailable;
};

export const getDateFirstSuccessfullyCompleted = (
   i: StudentProficiencyCanDoStatementStatus[],
): Maybe<Date> => {
   const successfulCompletions = i.filter((j) => j.completionStatus);

   if (successfulCompletions.length === 0) {
      return null;
   }

   const dateFirstSuccessfullyCompleted = successfulCompletions.reduce((a, b) =>
      a.dateCompleted < b.dateCompleted ? a : b,
   );
   return dateFirstSuccessfullyCompleted.dateCompleted;
};

export const getStudentProficiencySummaryDataFromRows = (
   courseStartDate: Date,
   courseEndDate: Date,
   rows: StudentProficiencySummaryRow[],
): StudentProficiencySummaryBarchartData[] => {
   const weeks = getWeekBreakdownOfDateRange(courseStartDate, courseEndDate);

   // Prepare the data - flatten onto row object week first offered and week completed
   const rowsWithFlattedDates = rows.map<FlattenedStatusesRow>((x) => ({
      ...x,
      dateFirstOffered: getDateFirstOffered(x.statuses),
      dateSuccessfullyCompleted: getDateFirstSuccessfullyCompleted(x.statuses),
   }));

   const result: StudentProficiencySummaryBarchartData[] =
      weeks.map<StudentProficiencySummaryBarchartData>((week) => {
         const statementsAvailableBeforeEndOfCurrentWeek = rowsWithFlattedDates.filter((row) =>
            moment(row.dateFirstOffered).isSameOrBefore(week.endDate),
         );

         const statementsCompletedBeforeEndOfCurrentWeek = rowsWithFlattedDates.filter(
            (row) =>
               row.dateSuccessfullyCompleted !== null &&
               moment(row.dateSuccessfullyCompleted).isSameOrBefore(week.endDate),
         );

         const availableStatementsNotCompleted =
            statementsAvailableBeforeEndOfCurrentWeek.length -
            statementsCompletedBeforeEndOfCurrentWeek.length;

         const summaryRow: StudentProficiencySummaryBarchartData = {
            ...week,
            columnName: week.weekIndex.toString(),
            availableStatementsNotCompleted,
            numberOfAdvancedHighCompleted: 0,
            numberOfAdvancedLowCompleted: 0,
            numberOfAdvancedMidCompleted: 0,
            numberOfIntermediateHighCompleted: 0,
            numberOfIntermediateLowCompleted: 0,
            numberOfIntermediateMidCompleted: 0,
            numberOfNoviceHighCompleted: 0,
            numberOfNoviceLowCompleted: 0,
            numberOfNoviceMidCompleted: 0,
            totalAvailableStatements: statementsAvailableBeforeEndOfCurrentWeek.length,
         };

         for (let i = 0; i < statementsCompletedBeforeEndOfCurrentWeek.length; i++) {
            summaryRow[LevelNameToDataKeys[statementsCompletedBeforeEndOfCurrentWeek[i].level]]++;
         }

         return summaryRow;
      });

   return result;
};
