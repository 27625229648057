import * as _ from 'lodash';
import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import Link from '@components/Common/Link';
import ContentIcon from '@components/ContentIcon';
import Breadcrumbs from '@components/Core/Breadcrumbs/Breadcrumbs';
import BreadcrumbsItem from '@components/Core/Breadcrumbs/BreadcrumbsItem';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import InfiniteScroll from '@components/InfiniteScroll';
import Loader from '@components/Loader';
import useDebounce from '@hooks/use-debounce';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconUserCircle from '@icons/nova-solid/07-Users/user-circle.svg';
import IconUserShare from '@icons/nova-solid/08-Users-Actions/user-share.svg';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import IconBooksApple from '@icons/nova-solid/41-School&Science/books-apple.svg';
import IconPlanetBook from '@icons/nova-solid/41-School&Science/planet-book.svg';
import IconFolders from '@icons/nova-solid/86-Files-Folders/folders.svg';
import { ContentType } from '@models/Content';
import ContentFolder from '@models/ContentFolder';
import ContentItemProfile, { GlobalContentItemProfile } from '@models/ContentItemProfile';
import { ContentLibrary as ContentLibraryType } from '@models/ContentLibrary';
import ContentLibraryFilters from '@models/ContentLibraryFilters';
import ContentLibraryName from '@models/ContentLibraryName';
import Language from '@models/Language';
import PagedResponse from '@models/PagedResponse';
import ContentService from '@services/ContentService';
import Tippy from '@tippyjs/react';
import naturalSort from '@utilities/NaturalSort';
import classnames from 'classnames';
import fuzzysort from 'fuzzysort';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';

interface AddContentModalProps {
   addButtonTooltip?: string;
   addedItemIds?: readonly number[];
   contentTypes?: readonly ContentType[];
   heading: string;
   language: Language;
   moduleId?: number;
   addItem(item: ContentItemProfile, library?: ContentLibraryName): void;
   onClose(event: MouseEvent | KeyboardEvent): void;
}

const INFINITE_SCROLL_THRESHOLD = 50 * 2; // height of two rows

const isGlobalContent = (
   c: ContentItemProfile | GlobalContentItemProfile,
): c is GlobalContentItemProfile => (c as GlobalContentItemProfile).isRecommended !== undefined;

const isPagedLibrary = (
   libraryName: ContentLibraryName,
   library: ContentLibraryType | PagedResponse<ContentItemProfile> | null,
): library is PagedResponse<ContentItemProfile> =>
   [ContentLibraryName.school, ContentLibraryName.global].includes(libraryName) && library !== null;

const isNonPagedLibrary = (
   libraryName: ContentLibraryName,
   library: ContentLibraryType | PagedResponse<ContentItemProfile> | null,
): library is ContentLibraryType =>
   [ContentLibraryName.personal, ContentLibraryName.shared].includes(libraryName) &&
   library !== null;

const mergePages = (
   prev: PagedResponse<ContentItemProfile>,
   next: PagedResponse<ContentItemProfile>,
): PagedResponse<ContentItemProfile> => ({
   ...prev,
   ...next,
   rows: [...prev.rows, ...next.rows],
});

const AddContentModal: React.FC<AddContentModalProps> = ({
   addedItemIds = [],
   contentTypes = [],
   heading = 'Add Content',
   addButtonTooltip = 'Add to Module',
   language,
   moduleId,
   addItem,
   onClose,
}) => {
   const {
      routes: {
         contentLibrary: { root: contentLibraryRoot },
      },
   } = Constants;

   const { schoolProfile, userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!schoolProfile) {
      return null;
   }

   const { id: organizationId } = schoolProfile;

   const [filters, setFilters] = React.useState<ContentLibraryFilters>({
      contentTypes,
      languages: [],
      moduleId,
      searchQuery: '',
      proficiencyLevels: [],
      proficiencySkills: [],
   });
   const [activeLibrary, setActiveLibrary] = React.useState<ContentLibraryName>(
      ContentLibraryName.personal,
   );
   const debouncedFilters = useDebounce(filters, 500);
   const [libraries, setLibraries] = React.useState<{
      [key in ContentLibraryName]:
         | ContentLibraryType
         | PagedResponse<ContentItemProfile>
         | PagedResponse<GlobalContentItemProfile>
         | null;
   }>({
      [ContentLibraryName.district]: null,
      [ContentLibraryName.global]: null,
      [ContentLibraryName.personal]: null,
      [ContentLibraryName.school]: null,
      [ContentLibraryName.shared]: null,
   });

   const filteredItems = React.useMemo(():
      | readonly (ContentItemProfile | GlobalContentItemProfile)[]
      | undefined => {
      const contentTypeFilter = (item: ContentItemProfile): boolean =>
         !filters.contentTypes?.length || filters.contentTypes.includes(item.itemType);

      const isSortedAndSearchedOnBackend = [
         ContentLibraryName.global,
         ContentLibraryName.school,
      ].includes(activeLibrary);

      const libaryHasNoRows = !libraries?.[activeLibrary]?.rows;

      if (isSortedAndSearchedOnBackend || libaryHasNoRows) {
         return libraries?.[activeLibrary]?.rows;
      }

      const items = naturalSort(
         libraries?.[activeLibrary]?.rows?.filter(contentTypeFilter) ?? [],
         'itemName',
      );

      if (!filters?.searchQuery.length) return items;

      const fuzzysortOptions = {
         threshold: -75, // Don't return matches worse than this (higher is faster)
         allowTypo: true, // Allwos a snigle transpoes (false is faster)
         key: 'itemName', // For when targets are objects (see its example usage)
         keys: ['itemName'], // For when targets are objects (see its example usage)
      };
      return fuzzysort
         .go<ContentItemProfile>(filters?.searchQuery, [...items], fuzzysortOptions)
         .map((i) => i.obj);
   }, [filters, activeLibrary, libraries]);

   const isFetchingActiveLibrary = !libraries?.[activeLibrary]?.rows;
   const isEmpty = isFetchingActiveLibrary || !filteredItems?.length;

   React.useEffect(() => {
      fetchContent();
   }, [debouncedFilters]);

   React.useEffect(() => {
      setFilters((prevFilters) => ({ ...prevFilters, languages: [language] }));
   }, [language]);

   const hasMore = (): boolean => {
      const library = libraries?.[activeLibrary];
      if (isPagedLibrary(activeLibrary, library)) {
         return (
            library.currentPageNumber !== null &&
            library.currentPageNumber < library.currentPageNumber
         );
      }
      return false;
   };

   const getContentFolders = (content: ContentItemProfile): readonly ContentFolder[] => {
      const result: ContentFolder[] = [];
      const library = libraries?.[activeLibrary];
      if (isNonPagedLibrary(activeLibrary, library) && content.folderId !== null) {
         const folders = library?.folders ?? [];
         let folder = folders.find((i) => i.id === content.folderId);
         while (folder !== undefined) {
            result.push(folder);
            folder = folders.find((i) => i.id === folder?.parentId);
         }
      }
      return result;
   };

   const fetchPersonalContent = (): Promise<void> =>
      ContentService.getPersonalContent().then((data) => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [ContentLibraryName.personal]: { ...data },
         }));
      });

   const fetchSchoolContent = (
      additionalFilters: Partial<ContentLibraryFilters> = {},
   ): Promise<void> => {
      const mergedFilters = { ...filters, ...additionalFilters };
      return ContentService.getSchoolContent(organizationId, mergedFilters).then((data) => {
         if (mergedFilters?.page !== undefined && mergedFilters.page > 1) {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.school]: mergePages(
                  prevLibraries[ContentLibraryName.school] as PagedResponse<ContentItemProfile>,
                  data,
               ),
            }));
         } else {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.school]: { ...data },
            }));
         }
      });
   };

   const fetchSharedContent = (): Promise<void> =>
      ContentService.getSharedContent().then((data) => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [ContentLibraryName.shared]: { ...data },
         }));
      });

   const fetchGlobalContent = async (
      additionalFilters: Partial<ContentLibraryFilters> = {},
   ): Promise<void> => {
      if (userProfile?.permissions.canViewGlobalLibrary) {
         const mergedFilters = { ...filters, ...additionalFilters };
         const globalContent = await ContentService.getGlobalContent(mergedFilters);
         if (mergedFilters?.page && mergedFilters.page > 1) {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.global]: mergePages(
                  prevLibraries[
                     ContentLibraryName.global
                  ] as PagedResponse<GlobalContentItemProfile>,
                  globalContent,
               ),
            }));
         } else {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.global]: { ...globalContent },
            }));
         }
      }
   };

   const fetchMoreContent = (): Promise<void> => {
      const library = libraries[activeLibrary];
      if (isPagedLibrary(activeLibrary, library)) {
         const additionalFilters = {
            page: library.currentPageNumber > 0 ? library.currentPageNumber + 1 : 1,
         };
         if (activeLibrary === ContentLibraryName.school) {
            return fetchSchoolContent(additionalFilters);
         } else if (activeLibrary === ContentLibraryName.global) {
            return fetchGlobalContent(additionalFilters);
         }
      }
      return Promise.resolve();
   };

   const fetchContent = (): Promise<readonly void[]> =>
      Promise.all([
         fetchPersonalContent(),
         fetchSchoolContent(),
         fetchSharedContent(),
         fetchGlobalContent(),
      ]);

   const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setFilters((prevFilters) => ({ ...prevFilters, searchQuery: value }));
   };

   const renderEmptyState = (): React.ReactNode => {
      const emptyIdealDescription =
         activeLibrary === ContentLibraryName.personal ? (
            <p>
               You can create content in the <Link to={contentLibraryRoot}>Content Library</Link>
            </p>
         ) : null;
      const icon = isEmpty ? (
         <IconContentBook2 className='icon-gray' aria-hidden />
      ) : (
         <IconBinoculars className='icon-gray' aria-hidden />
      );
      const emptyStateHeading = isEmpty ? 'No Content Yet!' : 'No Results';
      const description = isEmpty ? emptyIdealDescription : 'Try changing your search criteria.';

      return <EmptyState icon={icon} heading={emptyStateHeading} description={description} />;
   };

   const rowRenderer = ({ key, index, style }: Partial<ListRowProps>): React.ReactNode => {
      if (index === undefined || index === undefined || filteredItems === undefined) {
         return <></>;
      }

      const i = filteredItems[index];

      const isRecommended = isGlobalContent(i) ? i.isRecommended : false;
      return (
         <div className='module-add-item-wrapper' style={style} key={key}>
            <div
               className={classnames('module-add-item', i.itemType, {
                  'background-yellow': isRecommended,
               })}
            >
               <div className={classnames('icon-module-item', _.kebabCase(i.itemType))}>
                  <ContentIcon
                     itemType={i.itemType}
                     className='icon-white'
                     isRecommended={isRecommended}
                  />
               </div>
               <div className='content-title'>
                  <p>{i.itemName}</p>
                  <Breadcrumbs maxItems={4} itemsAfterCollapse={2} itemsBeforeCollapse={2}>
                     {getContentFolders(i).map((folder, folderIndex) => (
                        <BreadcrumbsItem
                           key={folder.id}
                           text={folder.name}
                           icon={folderIndex === 0 && <IconFolders className='folder-icon' />}
                        />
                     ))}
                  </Breadcrumbs>
               </div>
               {addedItemIds.includes(i.itemId) ? (
                  <div className='module-checked'>
                     <IconRadioTick className='icon-green' />
                  </div>
               ) : (
                  <Tippy delay={[400, 0]} content={addButtonTooltip}>
                     <div className='module-add-button' data-test={i.itemName}>
                        <IconAddSmall onClick={() => addItem(i, activeLibrary)} />
                     </div>
                  </Tippy>
               )}
            </div>
         </div>
      );
   };

   const libraryAttributes = [
      {
         label: 'My Content',
         library: ContentLibraryName.personal,
         icon: <IconUserCircle />,
      },
      {
         label: 'School Content',
         library: ContentLibraryName.school,
         icon: <IconBooksApple />,
      },

      {
         label: 'Shared with Me',
         library: ContentLibraryName.shared,
         icon: <IconUserShare />,
      },
   ];

   if (userProfile?.permissions.canViewGlobalLibrary) {
      libraryAttributes.splice(2, 0, {
         label: 'Global Content',
         library: ContentLibraryName.global,
         icon: <IconPlanetBook />,
      });
   }

   return (
      <ModalDialog
         header={
            <div className='card-title has-button' data-tour='add-module-content-modal'>
               <div className='title'>{heading}</div>
               <AccentTextbox
                  name='search'
                  type='search'
                  value={filters.searchQuery}
                  placeholder='Search'
                  onChange={handleSearchQueryChange}
                  language={language}
               />
            </div>
         }
         width='large'
         className='no-padding'
         bodyClassName='add-content-modal-body'
         footerClassName='add-content-modal-footer'
         onClose={onClose}
      >
         <div className='section-tab'>
            <div className='row'>
               {libraryAttributes.map(({ library, icon, label }) => (
                  <div
                     key={library}
                     className={classnames('col', {
                        active: library === activeLibrary,
                     })}
                     data-test={`tab-${label}`}
                     onClick={() => setActiveLibrary(library)}
                  >
                     {React.cloneElement(icon, {
                        className: library === activeLibrary ? 'icon-black' : 'icon-gray',
                     })}
                     <div className='title small'>{label}</div>
                  </div>
               ))}
            </div>
         </div>
         <div className='section-tab-mobile'>
            <select
               value={activeLibrary}
               onChange={(e) => setActiveLibrary(e.target.value as ContentLibraryName)}
            >
               {libraryAttributes.map(({ library, label }) => (
                  <option value={library} key={library}>
                     {label}
                  </option>
               ))}
            </select>
         </div>
         {isFetchingActiveLibrary ? (
            <Loader />
         ) : (
            <>
               {isEmpty && renderEmptyState()}
               {isPagedLibrary(activeLibrary, libraries[activeLibrary]) ? (
                  <InfiniteScroll
                     className='add-item-container'
                     hasMore={hasMore()}
                     onLoadMore={fetchMoreContent}
                     threshold={INFINITE_SCROLL_THRESHOLD}
                  >
                     {filteredItems?.map((i, index) =>
                        rowRenderer({
                           key: i.itemId.toString(),
                           index,
                           style: {},
                        }),
                     )}
                  </InfiniteScroll>
               ) : (
                  !isEmpty && (
                     <div className='add-item-container overflow'>
                        <AutoSizer>
                           {({ height, width }) => (
                              <List
                                 width={width}
                                 height={height}
                                 rowHeight={52}
                                 overscanRowCount={10}
                                 rowCount={filteredItems.length}
                                 rowRenderer={rowRenderer}
                              />
                           )}
                        </AutoSizer>
                     </div>
                  )
               )}
            </>
         )}
      </ModalDialog>
   );
};

export default AddContentModal;
