import * as React from 'react';

import { Maybe } from '@models/Core';
import LMSName from '@models/LMSName';

import Button from '@components/Common/Button';
import LMSButton from '@components/Core/LMS/LMSButton';

interface SubmissionsActionsProps {
   filteredIdsCount: number;
   isAutograding: boolean;
   isSyncingGrades: boolean;
   lmsName: Maybe<LMSName>;
   selectedIdsCount: number;
   submissionsCount: number;
   onAutograde(): void;
   onSyncGrades(): void;
   onGradeByQuestion(): void;
   onGradeBySubmission(): void;
}

const SubmissionsActions: React.FC<SubmissionsActionsProps> = ({
   filteredIdsCount,
   isAutograding,
   isSyncingGrades,
   lmsName,
   selectedIdsCount,
   submissionsCount,
   onAutograde,
   onSyncGrades,
   onGradeByQuestion,
   onGradeBySubmission,
}) => {
   const getSelectionTerm = (): string => {
      if (filteredIdsCount === submissionsCount && !selectedIdsCount) {
         return 'all';
      } else if (!selectedIdsCount) {
         return 'filtered';
      } else {
         return 'selected';
      }
   };

   return (
      <div className='card submission-progress-card' data-tour='grading-actions-card'>
         <div className='card-section'>
            <Button
               line
               fullWidth
               loading={isAutograding}
               onClick={onAutograde}
               disabled={!filteredIdsCount}
               data-test='button-auto-grade'
            >
               Auto grade {getSelectionTerm()}
            </Button>
            {!!lmsName && (
               <LMSButton
                  fullWidth
                  className='margin-top-s'
                  lmsName={lmsName}
                  loading={isSyncingGrades}
                  onClick={onSyncGrades}
                  disabled={!filteredIdsCount}
               >
                  Sync {getSelectionTerm()} Grades
               </LMSButton>
            )}
         </div>
         <div className='row grade-buttons'>
            <div className='col-xs-12'>
               <Button
                  data-test='button-grade-by-question'
                  color='green'
                  fullWidth
                  onClick={onGradeByQuestion}
                  disabled={!filteredIdsCount}
               >
                  Grade {getSelectionTerm()} by Question
               </Button>
               <Button
                  color='blue'
                  fullWidth
                  className='margin-top-s'
                  onClick={onGradeBySubmission}
                  disabled={!filteredIdsCount}
               >
                  Grade {getSelectionTerm()} by Student
               </Button>
            </div>
         </div>
      </div>
   );
};

export default SubmissionsActions;
