import * as React from 'react';

import UserService from '@services/UserService';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';

const Logout: React.FC = () => {
   const navigate = useNavigate();

   React.useEffect(() => {
      UserService.logout().then(() => navigate(Constants.routes.auth.login));
   }, []);

   return <p>Logging out...</p>;
};

export default Logout;
