import * as _ from 'lodash';
import * as React from 'react';

import classNames from 'classnames';

import Button from '@components/Common/Button';
import Droplist from './Droplist';

export type Option = {
   id: number;
   isChecked: boolean;
   name: string;
   weight?: number;
};

type FilterDropDownProps = {
   className?: string;
   icon?: React.ReactNode;
   onSelectionChange(options: Option[]): void;
   options: Option[];
   sort?: boolean;
};

const FilterDropDown: React.FC<FilterDropDownProps> = (props) => {
   if (props.options.length === 0) {
      return <></>;
   }

   const isFilteringResults = (): boolean => {
      const hasOptionNotSelected = props.options.filter((x) => !x.isChecked).length > 0;
      const hasAllUnchecked =
         props.options.filter((x) => !x.isChecked).length === props.options.length;
      return hasOptionNotSelected && !hasAllUnchecked;
   };

   const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const prevOption = props.options.find((x) => x.id === parseInt(event.target.id));
      if (!prevOption) {
         return;
      }
      const updatedOptions: Option[] = [
         ...props.options.filter((x) => x.id !== parseInt(event.target.id)),
         {
            ...prevOption,
            isChecked: event.target.checked,
         },
      ];

      props.onSelectionChange(updatedOptions);
   };

   const renderDropdown = (): readonly React.ReactElement[] => {
      const sortedOptions = _.sortBy<Option>(props.options, ['weight', 'name']);

      return sortedOptions.map(({ id, name, isChecked }) => (
         <div
            className='filter-entry'
            key={id}
            data-test={`section-filter-item-${name.toLowerCase()}`}
         >
            <input
               checked={isChecked}
               className='ignore-click'
               id={id.toString()}
               onChange={onChange}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));
   };

   return (
      <Droplist
         pullRight
         className={classNames('margin-left-s', props.className)}
         items={renderDropdown()}
      >
         <Button
            className={classNames({
               'active-filter': isFilteringResults(),
            })}
            data-test={'section-filter-button'}
            icon={props.icon}
            line
         >
            Sections
         </Button>
      </Droplist>
   );
};

export default FilterDropDown;
