import type { LayerProps } from 'react-map-gl';

const LayerIdConstants = [
   'clusters',
   'cluster-count',
   'unclustered-point',
   'selected-cluster',
   'selected-cluster-count',
] as const;
export type LayerIds = (typeof LayerIdConstants)[number];
export const LayerIdObject: Record<LayerIds, LayerIds> = {
   clusters: 'clusters',
   'cluster-count': 'cluster-count',
   'unclustered-point': 'unclustered-point',
   'selected-cluster': 'selected-cluster',
   'selected-cluster-count': 'selected-cluster-count',
};

type NonOptionalIDLayerProps = LayerProps & { id: LayerIds };

export const selectedClusterLayer: NonOptionalIDLayerProps = {
   id: 'selected-cluster',
   type: 'circle',
   source: 'selected-cluster',
   filter: ['has', 'point_count'],
   paint: {
      'circle-color': 'green',
      'circle-radius': ['step', ['get', 'point_count'], 10, 20, 15, 40, 20],
      'circle-opacity': 0.7,
   },
};

export const selectedClusterCountLayer: NonOptionalIDLayerProps = {
   id: 'selected-cluster-count',
   type: 'symbol',
   source: 'selected-cluster',
   filter: ['has', 'point_count'],
   layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
   },
   paint: {
      'text-color': 'white',
   },
};

export const clusterLayer: NonOptionalIDLayerProps = {
   id: 'clusters',
   type: 'circle',
   source: 'videos',
   filter: ['has', 'point_count'],
   paint: {
      'circle-color': '#CD1F1C',
      'circle-radius': ['step', ['get', 'point_count'], 10, 20, 15, 40, 20],
      'circle-opacity': 0.7,
   },
};

export const clusterCountLayer: NonOptionalIDLayerProps = {
   id: 'cluster-count',
   type: 'symbol',
   source: 'videos',
   filter: ['has', 'point_count'],
   layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
   },
   paint: {
      'text-color': 'white',
   },
};

export const unclusteredPointLayer: NonOptionalIDLayerProps = {
   id: 'unclustered-point',
   type: 'symbol',
   source: 'videos',
   filter: ['!', ['has', 'point_count']],
   layout: {
      // This icon was uploaded in Mapbox Studio. You can upload assets there and reference them on the map.
      'icon-image': 'red-map-pin',
      'icon-size': 0.8,
      'icon-anchor': 'bottom',
   },
   paint: {
      'icon-color': '#CD1F1C',
      'icon-opacity': 0.7,
   },
};
