import * as React from 'react';

import DownloadIcon from '@icons/nova-line/23-Data-Transfer/download-harddisk.svg';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { AppStateContext } from '../../AppState';
import { isTransientNationalExamReportTaskResponse } from '@models/NationalExam';
import { downloadExternalFile } from '@services/AxiosService';
import NationalExamAdminService from '@services/NationalExamAdminService';

type Props = {
   examCourseId: number;
};

const DownloadResponses: React.FC<Props> = (props) => {
   const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
   const { reportTaskResponse } = React.useContext<AppStateContext>(AppStateContext);
   const [messageId, setMessageId] = React.useState<string | undefined>(undefined);

   React.useEffect(() => {
      if (reportTaskResponse && isTransientNationalExamReportTaskResponse(reportTaskResponse)) {
         if (reportTaskResponse.messageId === messageId) {
            downloadExternalFile(
               reportTaskResponse.fileUrl,
               `course_${props.examCourseId}-${new Date().toLocaleString()}.xlsx`,
            );
            setMessageId(undefined);
         }
         setIsDisabled(false);
      }
   }, [reportTaskResponse]);

   const onClick = async () => {
      setIsDisabled(true);
      const response = await NationalExamAdminService.generateQuestionnaireForExamCourse(
         props.examCourseId,
      );
      setMessageId(response.messageId);
   };

   return (
      <Tippy content='Download Exam and Questionnaire Results' delay={[400, 0]}>
         <div
            className={classNames('icon-action', {
               disabled: isDisabled,
            })}
            onClick={onClick}
         >
            <DownloadIcon />
         </div>
      </Tippy>
   );
};

export default DownloadResponses;
