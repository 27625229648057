import { Maybe } from '@models/Core';

export enum AnnotationType {
   audio = 'audio',
}

export interface Annotation {
   id: number;
   /** User Id of author */
   createdBy: Maybe<number>;
   annotationType: AnnotationType;
   comments: string;
   createdOn: Maybe<Date>;
   audioUrl: string;
   storedAudioFilename: string;
}

export interface AudioAnnotation extends Annotation {
   /** Start time of annotation in seconds */
   start: number;
   /** End time of annotation in seconds */
   end: number;
   /** Local variable used while recording */
   blob?: Maybe<Blob>;
}
