import * as React from 'react';

import IconIncorrect from '@icons/general/icon-incorrect.svg';
import IconTickGreen from '@icons/general/icon-tick-green.svg';
import classnames from 'classnames';

interface MultipleChoiceImageOption {
   correct?: boolean;
   incorrect?: boolean;
   missed?: boolean;
   disabled?: boolean;
   children: React.ReactNode;
   onClick(): void;
}

const MultipleChoiceImageOption: React.FC<MultipleChoiceImageOption> = ({
   children,
   correct,
   disabled,
   incorrect,
   missed,
   onClick,
}) => {
   const renderIcon = (): React.ReactNode => {
      if (correct) {
         return <IconTickGreen />;
      } else if (incorrect) {
         return <IconIncorrect />;
      }
      return null;
   };

   const handleClick = (): void => {
      if (!disabled) {
         onClick();
      }
   };

   return (
      <div
         className={classnames('term-image-wrapper', {
            correct,
            incorrect,
            missed,
         })}
         onClick={handleClick}
      >
         {renderIcon()}
         {children}
      </div>
   );
};

export default React.memo(MultipleChoiceImageOption);
