import * as _ from 'lodash';
import * as React from 'react';

import { IdName, Maybe } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { OptionsCallback, StringOption } from '@models/ReactSelectHelperTypes';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { ActionMeta, GroupBase, OnChangeValue, StylesConfig, Theme } from 'react-select';

import AsyncSelect from 'react-select/async';

type TypeAheadRowLoader<T> = (filters: PagedSearchFilters) => Promise<PagedResponse<T>>;
type LabelContent<T> = (row: T) => string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectTypeAheadProps<T, TFieldValues extends FieldValues = FieldValues, TContext = any> = {
   autoFocus?: boolean;
   control?: Control<TFieldValues, TContext>;
   fieldName?: FieldPath<TFieldValues>;
   placeHolder: string;
   selectedOption?: Maybe<StringOption>;
   labelContent?: LabelContent<T & IdName>;
   rowLoader: TypeAheadRowLoader<T & IdName>;
   setSelectedOption?(option: Maybe<StringOption>): void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectTypeAhead: React.FC<SelectTypeAheadProps<any, any, any>> = (props) => {
   const loadOptions: (newInputValue: string, callback: OptionsCallback) => void = (
      newInputValue,
      callback,
   ) => {
      if (newInputValue.length < 1) {
         callback([]);
         return;
      }

      props
         .rowLoader({
            page: 1,
            predicate: null,
            query: newInputValue,
         })
         .then((response) => {
            callback(
               response.rows.map((x) => ({
                  value: x.id.toString(),
                  label: props.labelContent ? props.labelContent(x) : x.name,
               })),
            );
         });
   };

   const debouncedLoadOptions = React.useCallback(_.debounce(loadOptions, 500), []);

   const handleChange: (
      selectedOption: OnChangeValue<StringOption, false>,
      actionMeta: ActionMeta<StringOption>,
   ) => void = (selectedOption, actionMeta) => {
      if (props.setSelectedOption) {
         if (actionMeta.action === 'select-option' || actionMeta.action === 'clear') {
            props.setSelectedOption(selectedOption || undefined);
         }
      }
   };

   const customStyles: StylesConfig<StringOption, false, GroupBase<StringOption>> = {
      control: (styles) => ({
         ...styles,
         borderRadius: '3px',
      }),
      menu: (provided) => ({
         ...provided,
         zIndex: '2', // Needed for the activity builder. Part of the menu was not out front.
      }),
      menuPortal: (provided) => ({
         ...provided,
         zIndex: '9999',
      }),
      placeholder: (styles) => ({
         ...styles,
         fontWeight: 400,
         fontFamily: 'Karla',
         color: '#807F86',
      }),
      option: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
      }),
      singleValue: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
         color: '#413f48',
      }),
   };

   const customTheme: (theme: Theme) => Theme = (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
         ...theme.colors,
         primary: '#007cbb',
         neutral20: '#e7e7e7',
      },
   });

   return (
      <div>
         {props.control ? (
            <Controller
               name={props.fieldName as string}
               control={props.control}
               defaultValue={props.selectedOption?.value}
               render={({ field }) => (
                  <AsyncSelect
                     {...field}
                     autoFocus={props.autoFocus}
                     cacheOptions
                     className='react-select'
                     loadOptions={debouncedLoadOptions}
                     placeholder={props.placeHolder}
                     onChange={(newValue, metadata) => {
                        handleChange(newValue, metadata);
                        field.onChange(newValue?.value);
                     }}
                     defaultOptions
                     isClearable
                     isMulti={false}
                     menuShouldScrollIntoView={false}
                     styles={customStyles}
                     theme={customTheme}
                     value={props.selectedOption}
                     menuPortalTarget={document.body}
                     menuPosition='fixed'
                  />
               )}
            />
         ) : (
            <AsyncSelect
               autoFocus={props.autoFocus}
               cacheOptions
               className='react-select'
               defaultOptions
               isClearable
               isMulti={false}
               menuShouldScrollIntoView={false}
               menuPortalTarget={document.body}
               menuPosition='fixed'
               loadOptions={debouncedLoadOptions}
               onChange={handleChange}
               placeholder={props.placeHolder}
               styles={customStyles}
               theme={customTheme}
               value={props.selectedOption}
            />
         )}
      </div>
   );
};
export default SelectTypeAhead;
