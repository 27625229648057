/* eslint-disable complexity */
// @ts-strict-ignore
import * as React from 'react';

import parseHtml, { isTag, isText } from '@helpers/ParseHtml';
import { FillBlanksResponse, FillBlanksResponseEntry } from '@models/Activity';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import AutosizeInput from 'react-18-input-autosize';

import Constants from '../../../../Constants';
import ArabicTextbox from '@components/ArabicTextbox';
import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import InfoTooltip from '@components/InfoTooltip';

interface FillBlanksPromptProps {
   content: string;
   language: Language;
   response: FillBlanksResponse;
}

const FillBlanksPrompt: React.FC<FillBlanksPromptProps> = ({ content, language, response }) => {
   const getResponseEntry = (
      responses: FillBlanksResponse,
      index: number,
      blankId: string,
   ): FillBlanksResponseEntry => {
      const usesBlankIds = response.some((i) => !!i.blankId);
      return usesBlankIds
         ? responses.find((i) => i.blankId === blankId && blankId)
         : responses[index];
   };

   const renderBlanks = (): string | React.ReactNode | readonly React.ReactNode[] => {
      let currentIndex = 0;
      const arabicLetterPattern = new RegExp(Constants.regex.allArabicLetters);
      const noLeftJoinLetterPattern = new RegExp(Constants.regex.arabicLettersWithNoLeftJoin);
      return parseHtml(content, {
         replace: (tag) => {
            if (isTag(tag) && tag.attribs) {
               const child = tag.children[0];
               const text: Maybe<string> = !!child && isText(child) ? child.data : null;
               if (tag.name === 'span' && tag.attribs['data-blank']) {
                  const blankId = tag.attribs['data-id'] || null;
                  const index = currentIndex++;
                  const entry = getResponseEntry(response, index, blankId);
                  const responseStr = entry.response;
                  const { size, style, ...rest } = tag.attribs;

                  let className = '';
                  if (entry.modified === false && entry.correct !== null) {
                     className = entry.correct ? 'correct' : 'incorrect';
                  }
                  const shouldShowAnswer = !entry.correct;
                  // Text can be null?
                  const maxSize = Math.max((responseStr || '').length, responseStr.length || 4);

                  let element;
                  if (language === 'ar') {
                     // Handle Arabic Letter Joins
                     const prevStr = !!tag.prev && isText(tag.prev) ? tag.prev.data : '';
                     const nextStr = !!tag.next && isText(tag.next) ? tag.next.data : '';
                     const firstChar = responseStr.charAt(0);
                     const lastChar = responseStr.charAt(responseStr.length - 1);
                     const prevIsArabic = arabicLetterPattern.test(
                        prevStr.charAt(prevStr.length - 1),
                     );
                     const nextIsArabic = arabicLetterPattern.test(nextStr.charAt(0));
                     const joinRight =
                        prevIsArabic &&
                        !noLeftJoinLetterPattern.test(prevStr.charAt(prevStr.length - 1)) &&
                        arabicLetterPattern.test(firstChar);
                     const joinLeft =
                        nextIsArabic &&
                        !!lastChar &&
                        !noLeftJoinLetterPattern.test(lastChar) &&
                        arabicLetterPattern.test(lastChar);

                     element = (
                        <ArabicTextbox
                           {...rest}
                           className={className}
                           disabled
                           joinLeft={joinLeft}
                           joinRight={joinRight}
                           size={maxSize}
                           value={responseStr}
                        />
                     );
                  } else {
                     element = (
                        <AutosizeInput
                           {...rest}
                           type='text'
                           className={classnames(className, 'autosize')}
                           disabled
                           value={responseStr}
                        />
                     );
                  }
                  if (shouldShowAnswer) {
                     return (
                        <Tippy content={`Correct Answer: ${text}`}>
                           <span>{element}</span>
                        </Tippy>
                     );
                  }
                  return element;
               } else if (tag.attribs['data-hint']) {
                  if (tag.children.length === 1 && text) {
                     return <InfoTooltip>{text}</InfoTooltip>;
                  }
               } else if (tag.name === 'audio' && tag.attribs['data-player-size']) {
                  return (
                     <AudioPlayer
                        src={tag.attribs.src}
                        size={tag.attribs['data-player-size'] as AudioPlayerSize}
                        className={classnames(tag.attribs.class)}
                     />
                  );
               }
            }
            return null;
         },
      });
   };

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='fill-blanks-form'>{renderBlanks()}</div>
         </div>
      </div>
   );
};

export default FillBlanksPrompt;
