import 'reactflow/dist/style.css';

import { ActivityBuilderMode, ActivityQuestion } from '@models/Activity';
import React, { useCallback } from 'react';
import ReactFlow, {
   addEdge,
   Background,
   Connection,
   ConnectionLineType,
   Controls,
   useEdgesState,
   useNodesState,
} from 'reactflow';

import ModalDialog from '@components/Core/ModalDialog';
import { getEdgesFromQuestions, getLayoutedElements, getNodesFromQuestions } from './MapUtils';

interface LogicMapProps {
   questions: readonly ActivityQuestion<ActivityBuilderMode>[];
   closeLogicMap(): void;
}

const LogicMap: React.FC<LogicMapProps> = ({ questions, closeLogicMap }) => {
   const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements([], []);

   const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
   const [edges, setEdges] = useEdgesState(layoutedEdges);

   React.useEffect(() => {
      const unsetNodes = getNodesFromQuestions(questions);
      const unsetEdges = getEdgesFromQuestions(questions);
      const { nodes: updatedNodes, edges: updatedEdges } = getLayoutedElements(
         unsetNodes,
         unsetEdges,
      );
      setNodes([...updatedNodes]);
      setEdges([...updatedEdges]);
   }, [questions]);

   const onConnect = useCallback(
      (params: Connection) =>
         setEdges((eds) =>
            addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds),
         ),
      [],
   );

   return (
      <ModalDialog onClose={closeLogicMap} className='logic-map-modal' heading='Logic Map'>
         <ReactFlow
            nodes={nodes}
            edges={edges}
            snapToGrid
            selectionOnDrag
            selectNodesOnDrag
            nodesConnectable={false}
            edgesUpdatable={false}
            panOnDrag={false}
            onNodesChange={onNodesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            proOptions={{ hideAttribution: true }}
            fitView
         >
            <Background color='#d7d7d7' gap={8} />
            <Controls />
         </ReactFlow>
      </ModalDialog>
   );
};

export default LogicMap;
