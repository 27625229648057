import * as React from 'react';

import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';

import { RouteComponentProps } from '../../types/Routing';

// Use a generic type for the component props
interface ClassComponentRouterWrapperProps<T> {
   component: React.ComponentType<T>;
   componentProps?: Partial<Omit<T, keyof RouteComponentProps>>;
}

const ClassComponentRouterWrapper = <T extends RouteComponentProps>(
   props: ClassComponentRouterWrapperProps<T>,
) => {
   const params = useParams<Readonly<Params<string>>>();
   const location = useLocation();
   const navigate = useNavigate();

   const { component: Component, componentProps = {} } = props;

   // Merge componentProps with router props
   const mergedProps = { ...componentProps, params, location, navigate } as T;

   return <Component {...mergedProps} />;
};

export default ClassComponentRouterWrapper;
