import * as React from 'react';

import LMSButton from '@components/Core/LMS/LMSButton';
import ManageLMSConnectionsModal from '@components/Course/CourseEditor/ManageLMSConnectionsModal';
import { CanvasCourse } from '@components/Course/CourseEditor/types';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { CourseLMSConnection, CourseMode, CourseSection } from '@models/Course';
import ILMSCourse from '@models/ILMSCourse';
import LMSName from '@models/LMSName';
import CourseService from '@services/CourseService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import pluralize from 'pluralize';

import { AppStateContext } from '../../../../AppState';
import Constants from '../../../../Constants';

interface CanvasConnectProps {
   courseId: number;
   lmsConnections: readonly CourseLMSConnection[];
   lmsDisplayName: string;
   sections: readonly CourseSection<CourseMode.edit>[];
   shouldAllowLtiAppInstallation: boolean;
   setLMSConnections(lmsConnections: readonly CourseLMSConnection[]): void;
}

const CanvasConnect: React.FC<CanvasConnectProps> = ({
   courseId,
   lmsConnections: propsLMSConnections,
   lmsDisplayName,
   sections,
   shouldAllowLtiAppInstallation,
   setLMSConnections: propsSetLMSConnections,
}) => {
   const {
      statusCodes: { forbidden },
   } = Constants;

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { canvasAuthorized: initialCanvasAuthorized = false, id: userId } = userProfile;

   const [canvasAuthorized, setCanvasAuthorized] = React.useState<boolean>(false);
   const [manageModalOpen, setManageModalOpen] = React.useState<boolean>(false);

   const [loadingLmsCourses, setLoadingLmsCourses] = React.useState<boolean>(false);
   const [lmsCourses, setLMSCourses] = React.useState<readonly ILMSCourse[]>([]);
   const [lmsConnections, setLMSConnections] = React.useState<readonly CourseLMSConnection[]>([]);
   const [installingLtiToolIds, setInstallingLtiToolIds] = React.useState<readonly string[]>([]);

   const [existingLMSConnections, setExistingLMSConnections] = React.useState<
      readonly CourseLMSConnection[]
   >([]);

   const alreadyConnectedCanvasCourseIds = existingLMSConnections
      .filter((i) => i.lmsName === LMSName.canvas)
      .map((i) => i.lmsCourseId);

   React.useEffect(() => {
      CourseService.getLMSConnections().then(setExistingLMSConnections);
   }, []);

   React.useEffect(() => {
      setCanvasAuthorized(initialCanvasAuthorized);
   }, []);

   React.useEffect(() => {
      setLMSConnections(propsLMSConnections.filter((i) => i.lmsName === LMSName.canvas));
   }, [propsLMSConnections]);

   React.useEffect(() => {
      if (canvasAuthorized) {
         setLoadingLmsCourses(true);
         HttpService.getWithAuthToken<{ courses: readonly CanvasCourse[] }>(
            '/api/lms/canvas/courses',
            {
               handleForbidden: false,
            },
         )
            .then((response) => {
               const { courses } = response.data;
               setLMSCourses(
                  courses.map((i) => ({
                     endDate: i.endAt
                        ? new Date(i.endAt)
                        : i.term?.endAt
                        ? new Date(i.term.endAt)
                        : undefined,
                     lmsCourseId: i.id.toString(),
                     lmsCourseName: i.name,
                     lmsCourseTerm: i.term?.name,
                     lmsName: LMSName.canvas,
                     lmsSectionId: null,
                     startDate: i.startAt
                        ? new Date(i.startAt)
                        : i.term?.startAt
                        ? new Date(i.term.startAt)
                        : undefined,
                  })),
               );
               setLoadingLmsCourses(false);
            })
            .catch((error) => {
               if (error.response && error.response.status === forbidden) {
                  setCanvasAuthorized(false);
               }
            });
      }
   }, [canvasAuthorized]);

   const installLtiTool = (lmsConnection: CourseLMSConnection): Promise<void> => {
      const { lmsCourseId } = lmsConnection;
      setInstallingLtiToolIds((prevInstallingLtiTools) => [...prevInstallingLtiTools, lmsCourseId]);
      return HttpService.postWithAuthToken<{ msg: string }>(
         '/api/lms/canvas/install_lti_tool',
         snakeCaseKeys({
            ...lmsConnection,
            courseId,
         }),
      ).then(() => {
         setInstallingLtiToolIds((prevInstallingLtiToolIds) =>
            prevInstallingLtiToolIds.filter((i) => i !== lmsCourseId),
         );
         setLMSConnections((prevLMSConnections) =>
            prevLMSConnections.map((i) =>
               i.lmsCourseId === lmsCourseId ? { ...i, ltiConfigured: true } : i,
            ),
         );
      });
   };

   const isInstallingLtiTool = (lmsCourseId: string): boolean =>
      installingLtiToolIds.includes(lmsCourseId);

   const handleAuthorizeClick = (): void => {
      UserService.getLMSAuthorization(LMSName.canvas).then(() => {
         setCanvasAuthorized(true);
         setManageModalOpen(true);
      });
   };

   const addConnection = (lmsCourseId: string): void => {
      const lmsCourse = lmsCourses.find((i) => i.lmsCourseId === lmsCourseId);
      if (!lmsCourse) {
         return;
      }
      const { lmsCourseName } = lmsCourse;
      setLMSConnections((prevLMSConnections) => [
         ...prevLMSConnections,
         {
            id: null,
            lmsName: LMSName.canvas,
            lmsCourseId,
            lmsCourseName,
            sectionId: null,
            linkedBy: userId,
         },
      ]);
   };

   const removeConnection = (lmsCourseId: string): void => {
      setLMSConnections((prevLMSConnections) =>
         prevLMSConnections.filter((i) => i.lmsCourseId !== lmsCourseId),
      );
   };

   const updateConnectionSection = (lmsCourseId: string, sectionId: string | number): void => {
      setLMSConnections((prevLMSConnections) =>
         prevLMSConnections.map((i) => (i.lmsCourseId === lmsCourseId ? { ...i, sectionId } : i)),
      );
   };

   const renderCanvasButton = (): React.ReactNode => {
      if (!canvasAuthorized) {
         return (
            <LMSButton lmsName={LMSName.canvas} onClick={handleAuthorizeClick}>
               {`Authorize ${lmsDisplayName}`}
            </LMSButton>
         );
      }
      return (
         <LMSButton lmsName={LMSName.canvas} onClick={() => setManageModalOpen(true)}>
            {lmsConnections.length ? 'Manage Connected Classes' : `Connect ${lmsDisplayName}`}
         </LMSButton>
      );
   };

   const handleSaveLMSConnections = (): void => {
      setManageModalOpen(false);
      propsSetLMSConnections(lmsConnections);
   };

   return (
      <>
         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>Connect to a Learning Management System (LMS)</div>
            </div>
            <div className='content-form'>
               <div className='row'>
                  <div className='col-xs-8'>
                     <p className='course-option-description'>
                        Integrate Lingco Classroom with your institution&apos;s learning management
                        system to enable roster and grade syncing and content sharing.
                     </p>
                  </div>
                  <div className='col-xs-4'>
                     <div className='flex-end margin-top-s'>
                        <div>
                           {renderCanvasButton()}
                           {!!lmsConnections.length && (
                              <div className='classes-connected'>
                                 {`${lmsConnections.length} ${pluralize(
                                    'Class',
                                    lmsConnections.length,
                                 )} Connected`}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {manageModalOpen && (
            <ManageLMSConnectionsModal
               addConnection={addConnection}
               alreadyConnectedLMSCourseIds={alreadyConnectedCanvasCourseIds}
               shouldAllowLtiAppInstallation={shouldAllowLtiAppInstallation}
               installLtiTool={installLtiTool}
               isInstallingLtiTool={isInstallingLtiTool}
               lmsConnections={lmsConnections}
               lmsCourses={lmsCourses}
               lmsDisplayName={lmsDisplayName}
               loadingLmsCourses={loadingLmsCourses}
               lookupKey='lmsCourseId'
               onClose={() => setManageModalOpen(false)}
               onSave={handleSaveLMSConnections}
               removeConnection={removeConnection}
               sections={sections}
               updateConnectionSection={updateConnectionSection}
            />
         )}
      </>
   );
};

export default CanvasConnect;
