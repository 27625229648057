import * as React from 'react';

import IconCloseSmall from '@icons/general/icon-close-small.svg';
import { ActivityRubricItem, ActivityRubricScoringType } from '@models/Activity';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import ModalDialog from '@components/Core/ModalDialog';
import { getDraggableId } from './helpers';
import PointsInput from './PointsInput';
import RubricEntryDescription from './RubricEntryDescription';

interface RubricItemProps {
   displayOnly: boolean;
   dragIndex: Maybe<number>;
   isApplied: boolean;
   isDraggingDisabled?: boolean;
   isDraggingRubricEntry: boolean;
   item: ActivityRubricItem;
   scoringType: ActivityRubricScoringType;
   shortcutKey: string;
   deleteRubricItem(): void;
   toggleRubricItem(): void;
   updateRubricItem(update: Partial<ActivityRubricItem>): void;
}

const RubricItem: React.FC<RubricItemProps> = ({
   displayOnly,
   dragIndex,
   isApplied,
   isDraggingDisabled = false,
   isDraggingRubricEntry,
   item,
   scoringType,
   shortcutKey,
   deleteRubricItem,
   toggleRubricItem,
   updateRubricItem,
}) => {
   const itemRef = React.useRef<Maybe<HTMLDivElement>>(null);
   const { description, weight } = item;

   const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (item.isNew) {
         updateRubricItem({ isNew: false });
      }
   }, []);

   const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
      const target = event.target as HTMLElement;
      if (!displayOnly) {
         if (event.target && event.target === itemRef.current && target.parentNode) {
            target.parentNode
               .querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea')
               .forEach((i) => i.blur());
         }
      }
   };

   const handleDeleteItem = (): void => {
      deleteRubricItem();
      setConfirmDeleteModalOpen(false);
   };

   const closeConfirmDeleteModal = (): void => {
      setConfirmDeleteModalOpen(false);
   };

   const openConfirmDeleteModal = (): void => {
      setConfirmDeleteModalOpen(true);
   };

   if (dragIndex === null || dragIndex === undefined) {
      return null;
   }

   return (
      <>
         <Draggable
            draggableId={getDraggableId(item)}
            isDragDisabled={isDraggingDisabled || displayOnly}
            index={dragIndex}
         >
            {(provided, snapshot) => (
               <div
                  className={classnames('grader-sidebar-row', 'rubric-entry', 'rubric-item', {
                     'rubric-entry-is-dragging': snapshot.isDragging,
                     'display-only': displayOnly,
                     'rubric-item-is-applied': isApplied,
                  })}
                  ref={(ref) => (provided.innerRef(ref), (itemRef.current = ref))}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onMouseDown={handleMouseDown}
               >
                  {!displayOnly && (
                     <div
                        data-test={'div-rubric-shortcut-' + shortcutKey}
                        className='rubric-entry-key'
                        onClick={toggleRubricItem}
                     >
                        <div className='shortcut-key'>
                           <label>{shortcutKey}</label>
                        </div>
                     </div>
                  )}
                  <PointsInput
                     displayOnly={displayOnly}
                     weight={weight}
                     scoringType={scoringType}
                     updateRubricItem={updateRubricItem}
                  />
                  {!isDraggingRubricEntry && !displayOnly && (
                     <div className='rubric-entry-delete'>
                        <IconCloseSmall onClick={openConfirmDeleteModal} />
                     </div>
                  )}
                  <RubricEntryDescription
                     displayOnly={displayOnly}
                     description={description}
                     updateRubricEntry={updateRubricItem}
                     autoFocus={item.isNew}
                  />
               </div>
            )}
         </Draggable>
         {confirmDeleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Delete Rubric Item'
               onClose={closeConfirmDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: handleDeleteItem },
                  { text: 'Cancel', onClick: closeConfirmDeleteModal },
               ]}
            >
               <p>
                  Are you sure? Deleting this rubric item will affect submissions that have this
                  rubric item applied.
               </p>
            </ModalDialog>
         )}
      </>
   );
};

export default RubricItem;
