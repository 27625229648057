export enum ActivityRubricEntryType {
   rubric = 'rubric',
   item = 'rubric_item',
   group = 'rubric_item_group',
}

export interface ActivityRubricItem {
   id: number;
   type: ActivityRubricEntryType.item;
   description: string;
   groupId: number | null;
   /** Null when updating */
   index: number | null;
   weight: string;
   isNew?: boolean;
}

export interface ActivityRubricItemGroup {
   description: string;
   id: number;
   /** Null when updating */
   index: number | null;
   isNew?: boolean;
   mutuallyExclusive: boolean;
   type: ActivityRubricEntryType.group;
}

export type ActivityRubricEntry = ActivityRubricItem | ActivityRubricItemGroup;

export enum ActivityRubricScoringType {
   positive = 'positive',
   negative = 'negative',
}

export interface ActivityRubric {
   ceiling: boolean;
   floor: boolean;
   groups: readonly ActivityRubricItemGroup[];
   id: number;
   items: readonly ActivityRubricItem[];
   moduleItemId: number;
   promptId: number;
   scoringType: ActivityRubricScoringType;
   weight: string;
}

export type ActivityRubricItemGropWithItems = ActivityRubricItemGroup & {
   rubricItems: ActivityRubricItem[];
};

export type ActivityCollapsedRubricEntry = ActivityRubricItem | ActivityRubricItemGropWithItems;
