import * as React from 'react';

import { CourseLMSConnection, CourseMode, CourseSection } from '@models/Course';
import { GoogleClassroomCourseState, IGoogleClassroomCourse } from '@models/GoogleClassroom';
import ILMSCourse from '@models/ILMSCourse';
import LMSName from '@models/LMSName';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import pluralize from 'pluralize';

import { AppStateContext } from '../../../../AppState';
import Constants from '../../../../Constants';
import LMSButton from '@components/Core/LMS/LMSButton';
import ManageLMSConnectionsModal from '@components/Course/CourseEditor/ManageLMSConnectionsModal';

interface GoogleClassroomConnectProps {
   lmsConnections: readonly CourseLMSConnection[];
   lmsDisplayName: string;
   sections: readonly CourseSection<CourseMode.edit>[];
   setLMSConnections(lmsConnections: readonly CourseLMSConnection[]): void;
}

const GoogleClassroomConnect: React.FC<GoogleClassroomConnectProps> = ({
   lmsConnections: propsLMSConnections,
   lmsDisplayName,
   sections,
   setLMSConnections: propsSetLMSConnections,
}) => {
   const {
      statusCodes: { forbidden },
      googleScopes: {
         manageClassroomCoursework,
         viewClassromEmails,
         viewClassroomCourses,
         viewClassroomRosters,
      },
   } = Constants;
   const SCOPES = [
      manageClassroomCoursework,
      viewClassromEmails,
      viewClassroomCourses,
      viewClassroomRosters,
   ];

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [googleClassroomAuthorized, setGoogleClassroomAuthorized] = React.useState<boolean>(false);
   const [manageModalOpen, setManageModalOpen] = React.useState<boolean>(false);

   const [loadingLmsCourses, setLoadingLmsCourses] = React.useState<boolean>(false);
   const [lmsCourses, setLMSCourses] = React.useState<readonly ILMSCourse[]>([]);
   const [lmsConnections, setLMSConnections] = React.useState<readonly CourseLMSConnection[]>([]);

   React.useEffect(() => {
      const authorized = UserService.checkGoogleScopes(SCOPES);
      setGoogleClassroomAuthorized(authorized);
   }, []);

   React.useEffect(() => {
      setLMSConnections(propsLMSConnections.filter((i) => i.lmsName === LMSName.googleClassroom));
   }, [propsLMSConnections]);

   React.useEffect(() => {
      if (googleClassroomAuthorized) {
         setLoadingLmsCourses(true);
         HttpService.getWithAuthToken<{ courses: readonly IGoogleClassroomCourse[] }>(
            '/api/lms/google_classroom/courses',
            {
               handleForbidden: false,
            },
         )
            .then((response) => {
               const { courses } = response.data;
               setLMSCourses(
                  courses.map((i) => ({
                     lmsName: LMSName.googleClassroom,
                     lmsSectionId: null,
                     lmsCourseId: i.id,
                     lmsCourseName: i.section ? `${i.name} ${i.section}` : i.name,
                     active: i.courseState === GoogleClassroomCourseState.active,
                  })),
               );
               setLoadingLmsCourses(false);
            })
            .catch((error) => {
               if (error.response && error.response.status === forbidden) {
                  setGoogleClassroomAuthorized(false);
               }
            });
      }
   }, [googleClassroomAuthorized]);

   const handleAuthorizeClick = (): void => {
      UserService.getGoogleScopes(SCOPES).then(() => {
         setGoogleClassroomAuthorized(true);
         setManageModalOpen(true);
      });
   };

   const addConnection = (lmsCourseId: string): void => {
      const lmsCourse = lmsCourses.find((i) => i.lmsCourseId === lmsCourseId);
      if (!lmsCourse || !userProfile) {
         return;
      }
      const { lmsCourseName } = lmsCourse;
      setLMSConnections((prevLMSConnections) => [
         ...prevLMSConnections,
         {
            id: null,
            lmsName: LMSName.googleClassroom,
            lmsCourseId,
            lmsCourseName,
            sectionId: null,
            linkedBy: userProfile.id,
         },
      ]);
   };

   const removeConnection = (lmsCourseId: string): void => {
      setLMSConnections((prevLMSConnections) =>
         prevLMSConnections.filter((i) => i.lmsCourseId !== lmsCourseId),
      );
   };

   const updateConnectionSection = (lmsCourseId: string, sectionId: string | number): void => {
      setLMSConnections((prevLMSConnections) => {
         const updatedLMSConnections = [...prevLMSConnections];
         const index = updatedLMSConnections.findIndex((i) => i.lmsCourseId === lmsCourseId);
         updatedLMSConnections[index].sectionId = sectionId;
         return updatedLMSConnections;
      });
   };

   const renderGoogleClassroomButton = (): React.ReactNode => {
      if (!googleClassroomAuthorized) {
         return (
            <LMSButton lmsName={LMSName.googleClassroom} onClick={handleAuthorizeClick}>
               {`Authorize ${lmsDisplayName}`}
            </LMSButton>
         );
      }
      return (
         <LMSButton lmsName={LMSName.googleClassroom} onClick={() => setManageModalOpen(true)}>
            {lmsConnections.length ? 'Manage Connected Classes' : `Connect ${lmsDisplayName}`}
         </LMSButton>
      );
   };

   const handleSaveLMSConnections = (): void => {
      setManageModalOpen(false);
      propsSetLMSConnections(lmsConnections);
   };

   return (
      <>
         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>Connect to a Learning Management System (LMS)</div>
            </div>
            <div className='content-form'>
               <div className='row'>
                  <div className='col-xs-8'>
                     <p className='course-option-description'>
                        Integrate Lingco Classroom with your institution&apos;s learning management
                        system to enable roster and grade syncing and content sharing.
                     </p>
                  </div>
                  <div className='col-xs-4'>
                     <div className='flex-end margin-top-s'>
                        <div>
                           {renderGoogleClassroomButton()}
                           {!!lmsConnections.length && (
                              <div className='classes-connected'>
                                 {`${lmsConnections.length} ${pluralize(
                                    'Class',
                                    lmsConnections.length,
                                 )} Connected`}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {manageModalOpen && (
            <ManageLMSConnectionsModal
               addConnection={addConnection}
               lmsConnections={lmsConnections}
               lmsCourses={lmsCourses}
               lmsDisplayName={lmsDisplayName}
               loadingLmsCourses={loadingLmsCourses}
               lookupKey='lmsCourseId'
               onClose={() => setManageModalOpen(false)}
               onSave={handleSaveLMSConnections}
               removeConnection={removeConnection}
               sections={sections}
               updateConnectionSection={updateConnectionSection}
            />
         )}
      </>
   );
};

export default GoogleClassroomConnect;
