import IconCalendar1 from '@icons/nova-line/05-Time/calendar-1.svg';
import IconATSign from '@icons/nova-line/06-Email/at-sign.svg';
import IconTag1 from '@icons/nova-line/30-Bookmarks&Tags/tag-1.svg';
import IconLocationGlobe from '@icons/nova-line/55-Locations/location-globe.svg';
import IconUser from '@icons/nova-solid/08-Users-Actions/user.svg';
import IconGraduationHat from '@icons/nova-solid/41-School&Science/graduation-hat.svg';
import IconLocationMapPin from '@icons/nova-solid/55-Locations/location-map-pin.svg';
import { LanguageLookup } from '@models/Language';
import SchoolFundingSource from '@models/SchoolFundingSource';
import SchoolType from '@models/SchoolType';

import { Attribute, PrimitiveType } from '@components/PredicateEditor/Models';
import { LicenseType, PaymentType } from './Models';

const ATTRIBUTES: readonly Attribute[] = [
   {
      icon: IconUser,
      label: 'Name',
      identifier: 'user.full_name',
      type: PrimitiveType.string,
   },
   {
      icon: IconATSign,
      label: 'Email',
      identifier: 'user.email',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationGlobe,
      label: 'Language',
      identifier: 'user.language',
      type: PrimitiveType.string,
      options: Object.entries(LanguageLookup).map(([value, label]) => ({
         label,
         value,
      })),
   },
   {
      icon: IconCalendar1,
      label: 'Activated on',
      identifier: 'license.activated_on',
      type: PrimitiveType.date,
   },
   {
      icon: IconTag1,
      label: 'License name',
      identifier: 'license.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconTag1,
      label: 'License type',
      identifier: 'license.type',
      type: PrimitiveType.string,
      options: [
         { value: LicenseType.course, label: 'Course' },
         { value: LicenseType.product, label: 'Product' },
      ],
   },
   {
      icon: IconTag1,
      label: 'Payment type',
      identifier: 'payment.payment_type',
      type: PrimitiveType.string,
      options: [
         { value: PaymentType.stripe, label: 'Stripe' },
         { value: PaymentType.bookstoreCode, label: 'Bookstore Code' },
      ],
   },
   {
      icon: IconGraduationHat,
      label: 'School name',
      identifier: 'school.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconGraduationHat,
      label: 'School type',
      identifier: 'school.school_type',
      type: PrimitiveType.string,
      options: [
         { value: SchoolType.higher, label: 'Higher Ed' },
         { value: SchoolType.secondary, label: 'Secondary' },
      ],
   },
   {
      icon: IconGraduationHat,
      label: 'School funding source',
      identifier: 'school.funding_source',
      type: PrimitiveType.string,
      options: [
         { value: SchoolFundingSource.public, label: 'Public' },
         { value: SchoolFundingSource.private, label: 'Private' },
      ],
   },
   {
      icon: IconGraduationHat,
      label: 'School district name',
      identifier: 'school_district.name',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School city',
      identifier: 'school.city',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School state',
      identifier: 'school.state',
      type: PrimitiveType.string,
   },
   {
      icon: IconLocationMapPin,
      label: 'School zip code',
      identifier: 'school.zip_code',
      type: PrimitiveType.string,
   },
];

export default ATTRIBUTES;
