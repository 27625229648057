// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import {
   ActivityCompleterMode,
   ActivityMode,
   ActivityPrompt,
   ActivityResponse,
   ActivityResponseEvaluation,
   AudioRecordingResponse,
   DiscussionBoardResponse,
   FileResponse,
   FillBlanksResponse,
   GroupingResponse,
   ImageLabelingResponse,
   MarkTokensResponse,
   MultipleChoiceResponse,
   OrderingResponse,
   SpokenResponseResponse,
   TextResponse,
   VideoRecordingResponse,
} from '@models/Activity';

import {
   isAudioRecordingPrompt,
   isDiscussionBoardPrompt,
   isFilePrompt,
   isFillBlanksPrompt,
   isGroupingPrompt,
   isImageLabelingPrompt,
   isMarkTokensPrompt,
   isMultipleChoicePrompt,
   isOrderingPrompt,
   isSpokenResponsePrompt,
   isTextPrompt,
   isVideoRecordingPrompt,
} from '@components/Activity/Utils';
import { ActivityCompleterContext } from './ActivityCompleter';
import DiscussionBoardPrompt from './Prompts/DiscussionBoardPrompt';
import FilePrompt from './Prompts/FilePrompt';
import FillBlanksPrompt from './Prompts/FillBlanksPrompt';
import GroupingPrompt from './Prompts/GroupingPrompt';
import ImageLabelingPrompt from './Prompts/ImageLabelingPrompt';
import MarkTokensPrompt from './Prompts/MarkTokensPrompt';
import MultipleChoicePrompt from './Prompts/MultipleChoicePrompt';
import OrderingPrompt from './Prompts/OrderingPrompt';
import RecordingPrompt from './Prompts/RecordingPrompt';
import SpokenResponsePrompt from './Prompts/SpokenResponsePrompt';
import TextPrompt from './Prompts/TextPrompt';
import VideoPrompt from './Prompts/VideoPrompt';
import { QuestionConsumer } from './Question';

export interface CommonPromptProps {
   evaluation: ActivityResponseEvaluation<ActivityCompleterMode>;
   isClosed: boolean;
   key: string;
   mode: ActivityMode;
   promptId: number;
   weight: string;
   saveResponse(): void;
   setResponse(response: ActivityResponse, callback?: () => void): void;
}

interface PromptProps {
   evaluation: ActivityResponseEvaluation<ActivityCompleterMode>;
   isClosed: boolean;
   prompt: ActivityPrompt<ActivityCompleterMode>;
   mode?: ActivityMode;
   response: ActivityResponse;
   showMissed?: boolean;
   showPointsPossible?: boolean;
   postDiscussionBoardResponse(entry: string, parentId: number): void;
   setDisableSubmit(disableSubmit: boolean): void;
   setResponse(response: ActivityResponse, callback?: () => void): void;
   saveResponse(): void;
}

const Prompt: React.FC<PromptProps> = ({
   evaluation,
   isClosed,
   prompt,
   mode = ActivityMode.preview,
   response,
   showMissed = false,
   showPointsPossible = false,
   postDiscussionBoardResponse,
   setDisableSubmit,
   setResponse,
   saveResponse,
}) => {
   const { comments, graded, rubricComments, score } = evaluation;
   const formattedScore = _.isNumber(score)
      ? score.toLocaleString('en-US', { minimumFractionDigits: 1 })
      : '';
   const { disableSpellCheck, language, variables } =
      React.useContext<ActivityCompleterContext>(ActivityCompleterContext);

   const renderPromptItem = (): React.ReactNode => {
      const commonProps: CommonPromptProps = {
         key: prompt.key,
         mode,
         weight: prompt.weight,
         promptId: prompt.id,
         isClosed,
         evaluation,
         saveResponse,
         setResponse,
      };
      if (isAudioRecordingPrompt(prompt)) {
         return (
            <QuestionConsumer>
               {({ readyToRecord }) => (
                  <RecordingPrompt
                     {...commonProps}
                     response={response as AudioRecordingResponse}
                     allowUploading={prompt.allowUploading}
                     limitDuration={prompt.limitDuration}
                     duration={prompt.duration}
                     limitAttempts={prompt.limitAttempts}
                     attempts={prompt.attempts}
                     recordingDelay={
                        readyToRecord && readyToRecord.promptId === prompt.id
                           ? readyToRecord.delay
                           : undefined
                     }
                     setDisableSubmit={setDisableSubmit}
                  />
               )}
            </QuestionConsumer>
         );
      } else if (isFilePrompt(prompt)) {
         return <FilePrompt {...commonProps} response={response as FileResponse} />;
      } else if (isTextPrompt(prompt)) {
         return (
            <TextPrompt
               {...commonProps}
               disableSpellCheck={disableSpellCheck}
               language={language}
               length={prompt.length}
               response={response as TextResponse}
               showWordCount={prompt.showWordCount}
            />
         );
      } else if (isMarkTokensPrompt(prompt)) {
         return (
            <MarkTokensPrompt
               {...commonProps}
               content={prompt.content}
               language={language}
               response={response as MarkTokensResponse}
               showMissed={showMissed}
            />
         );
      } else if (isFillBlanksPrompt(prompt)) {
         return (
            <FillBlanksPrompt
               {...commonProps}
               content={prompt.content}
               draggable={prompt.draggable}
               language={language}
               response={response as FillBlanksResponse}
               showMissed={showMissed}
               showUniqueWords={prompt.showUniqueWords}
               wordBank={prompt.wordBank}
            />
         );
      } else if (isMultipleChoicePrompt(prompt)) {
         return (
            <MultipleChoicePrompt
               {...commonProps}
               groupName={prompt.key}
               multiple={prompt.multiple}
               options={prompt.options}
               showFeedback={prompt.showFeedback}
               response={response as MultipleChoiceResponse}
               showMissed={showMissed}
               shuffle={prompt.shuffle}
            />
         );
      } else if (isOrderingPrompt(prompt)) {
         return (
            <OrderingPrompt
               {...commonProps}
               response={response as OrderingResponse}
               options={prompt.options}
            />
         );
      } else if (isGroupingPrompt(prompt)) {
         return (
            <GroupingPrompt
               {...commonProps}
               response={response as GroupingResponse}
               showMissed={showMissed}
               categories={prompt.categories}
               items={prompt.items}
            />
         );
      } else if (isImageLabelingPrompt(prompt)) {
         return (
            <ImageLabelingPrompt
               {...commonProps}
               fileUrl={prompt.fileUrl}
               hotspots={prompt.hotspots}
               response={response as ImageLabelingResponse}
               showMissed={showMissed}
               wordBank={prompt.wordBank}
            />
         );
      } else if (isSpokenResponsePrompt(prompt) && language) {
         return (
            <SpokenResponsePrompt
               {...commonProps}
               acceptedResponses={prompt.acceptedResponses}
               language={language}
               response={response as SpokenResponseResponse}
               saveRecording={prompt.saveRecording}
            />
         );
      } else if (isDiscussionBoardPrompt(prompt)) {
         return (
            <DiscussionBoardPrompt
               {...commonProps}
               postDiscussionBoardResponse={postDiscussionBoardResponse}
               response={response as DiscussionBoardResponse}
            />
         );
      } else if (isVideoRecordingPrompt(prompt)) {
         return <VideoPrompt {...commonProps} response={response as VideoRecordingResponse} />;
      }
      return null;
   };

   return (
      <div className='activity-builder-row'>
         <div className='align-items-center'>
            <div className='flex-grow-1'>
               {prompt.description && parseHtml(prompt.description, undefined, variables)}
            </div>
            <div className='flex-end margin-left-s'>
               <span className='small-text gray-text' data-test='weight'>
                  {graded
                     ? `${formattedScore}/${prompt.weight} pts`
                     : showPointsPossible && `${prompt.weight} pts possible`}
               </span>
            </div>
         </div>
         {renderPromptItem()}
         {!!(comments || (rubricComments && rubricComments.length)) && (
            <div className='margin-top-m'>
               <label>Comments</label>
               <ul>
                  {rubricComments.map((comment, index) => (
                     <li key={index}>
                        <p>{comment}</p>
                     </li>
                  ))}
                  {comments && <li>{ensureElement(parseHtml(comments), 'p')}</li>}
               </ul>
            </div>
         )}
      </div>
   );
};

export default Prompt;
