import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { randomShortId } from '@helpers/RandomStringUtils';
import {
   ActivityMode,
   ActivityPrompt,
   ActivityResponse,
   AudioRecordingResponse,
   DiscussionBoardResponse,
   FileResponse,
   FillBlanksResponse,
   GradingMode,
   GroupingResponse,
   ImageLabelingResponse,
   MarkTokensResponse,
   MultipleChoiceResponse,
   OrderingResponse,
   SpokenResponseResponse,
   TextResponse,
   VideoRecordingResponse,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import classnames from 'classnames';

import {
   isAudioRecordingPrompt,
   isDiscussionBoardPrompt,
   isFilePrompt,
   isFillBlanksPrompt,
   isGroupingPrompt,
   isImageLabelingPrompt,
   isMarkTokensPrompt,
   isMultipleChoicePrompt,
   isOrderingPrompt,
   isSpokenResponsePrompt,
   isTextPrompt,
   isVideoRecordingPrompt,
} from '@components/Activity/Utils';
import DiscussionBoardPrompt from './Prompts/DiscussionBoardPrompt';
import FilePrompt from './Prompts/FilePrompt';
import FillBlanksPrompt from './Prompts/FillBlanksPrompt';
import GroupingPrompt from './Prompts/GroupingPrompt';
import ImageLabelingPrompt from './Prompts/ImageLabelingPrompt';
import MarkTokensPrompt from './Prompts/MarkTokensPrompt';
import MultipleChoicePrompt from './Prompts/MultipleChoicePrompt';
import OrderingPrompt from './Prompts/OrderingPrompt';
import RecordingPrompt from './Prompts/RecordingPrompt';
import SpokenResponsePrompt from './Prompts/SpokenResponsePrompt';
import TextPrompt from './Prompts/TextPrompt';
import VideoPrompt from './Prompts/VideoPrompt';

interface ResponseProps {
   gradingMode: GradingMode;
   ownerName: string;
   title: string;
   language: Language;
   isActive: boolean;
   id: number;
   response: ActivityResponse;
   prompt: Maybe<ActivityPrompt<ActivityMode.grade>>;
   setActive(): void;
   setRef(e: Maybe<HTMLDivElement>): void;
   updateResponse(id: number, update: Partial<ActivityResponse>): Promise<void>;
}

const Response: React.FC<ResponseProps> = ({
   id,
   gradingMode,
   isActive,
   language,
   ownerName,
   prompt,
   response,
   title,
   setActive,
   setRef,
   updateResponse,
}) => {
   const renderPromptItem = (): React.ReactNode => {
      const promptProps = { language, responseId: id, isActive, setActive };
      if (!prompt) {
         return null;
      }
      if (isAudioRecordingPrompt(prompt)) {
         return (
            <RecordingPrompt
               {...promptProps}
               response={response as AudioRecordingResponse}
               updateResponse={(update) => updateResponse(id, update)}
            />
         );
      } else if (isVideoRecordingPrompt(prompt)) {
         return (
            <VideoPrompt
               {...promptProps}
               response={response as VideoRecordingResponse}
               updateResponse={(update) => updateResponse(id, update)}
            />
         );
      } else if (isTextPrompt(prompt)) {
         return (
            <TextPrompt
               {...promptProps}
               response={response as TextResponse}
               showWordCount={prompt.showWordCount}
               updateResponse={(update) => updateResponse(id, update)}
            />
         );
      } else if (isFillBlanksPrompt(prompt)) {
         return (
            <FillBlanksPrompt
               {...promptProps}
               content={prompt.content}
               response={response as FillBlanksResponse}
            />
         );
      } else if (isMultipleChoicePrompt(prompt)) {
         return (
            <MultipleChoicePrompt
               {...promptProps}
               groupName={randomShortId()}
               multiple={prompt.multiple}
               options={prompt.options}
               response={response as MultipleChoiceResponse}
            />
         );
      } else if (isOrderingPrompt(prompt)) {
         return (
            <OrderingPrompt
               {...promptProps}
               options={prompt.options}
               response={response as OrderingResponse}
            />
         );
      } else if (isGroupingPrompt(prompt)) {
         return (
            <GroupingPrompt
               {...promptProps}
               categories={prompt.categories}
               items={prompt.items}
               response={response as GroupingResponse}
            />
         );
      } else if (isMarkTokensPrompt(prompt)) {
         return (
            <MarkTokensPrompt
               {...promptProps}
               content={prompt.content}
               response={response as MarkTokensResponse}
            />
         );
      } else if (isImageLabelingPrompt(prompt)) {
         return (
            <ImageLabelingPrompt
               {...promptProps}
               fileUrl={prompt.fileUrl}
               hotspots={prompt.hotspots}
               response={response as ImageLabelingResponse}
            />
         );
      } else if (isSpokenResponsePrompt(prompt)) {
         return (
            <SpokenResponsePrompt
               {...promptProps}
               acceptedResponses={prompt.acceptedResponses}
               response={response as SpokenResponseResponse}
            />
         );
      } else if (isDiscussionBoardPrompt(prompt)) {
         return (
            <DiscussionBoardPrompt
               {...promptProps}
               response={response as DiscussionBoardResponse}
            />
         );
      } else if (isFilePrompt(prompt)) {
         return <FilePrompt {...promptProps} response={response as FileResponse} />;
      }
      return null;
   };

   return (
      <div
         className={classnames('card', { active: isActive })}
         onClick={setActive}
         ref={(e) => setRef(e)}
      >
         {isActive && (
            <div className='current-grading'>
               <div className='title'>Grading</div>
            </div>
         )}
         <div className='card-title full-width activity-builder-title'>
            <div className='title'>{title}</div>
            {gradingMode === GradingMode.byPrompt && (
               <div className='student-info gray-text small-text'>{ownerName}</div>
            )}
         </div>
         <div className='activity-builder-container'>
            {prompt?.description && (
               <div className='activity-builder-row'>
                  <div className='row'>
                     <div className='col-xs-12'>{parseHtml(prompt.description)}</div>
                  </div>
               </div>
            )}
            <div className='activity-builder-row'>{renderPromptItem()}</div>
         </div>
      </div>
   );
};

export default Response;
