import * as React from 'react';

import { ActivityRubricEntry } from '@models/Activity';
import classnames from 'classnames';
import Textarea from 'react-textarea-autosize';

interface RubricEntryDescriptionProps {
   autoFocus?: boolean;
   description: string;
   displayOnly: boolean;
   updateRubricEntry(update: Partial<ActivityRubricEntry>): void;
}

const RubricEntryDescription: React.FC<RubricEntryDescriptionProps> = ({
   autoFocus = false,
   description,
   displayOnly,
   updateRubricEntry,
}) => {
   const descriptionInput = React.useRef<HTMLTextAreaElement>(null);
   const [update, setUpdate] = React.useState<string>(description);

   React.useEffect(() => {
      setUpdate(description);
   }, [description]);

   const descriptionPlaceholder = 'Click here to replace this description.';

   React.useEffect(() => {
      if (autoFocus) {
         descriptionInput.current?.focus();
      }
   }, []);

   const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
      event.target.placeholder = '';
   };

   const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
      event.target.placeholder = descriptionPlaceholder;
      if (update !== description) {
         updateRubricEntry({ description: update });
      }
   };

   return (
      <div
         className={classnames('rubric-entry-description', {
            'display-only': displayOnly,
         })}
      >
         {displayOnly ? (
            <span>{description || 'Untitled'}</span>
         ) : (
            <Textarea
               name='description'
               onBlur={handleBlur}
               onChange={(e) => setUpdate(e.target.value)}
               onFocus={handleFocus}
               ref={descriptionInput}
               value={update}
               placeholder={descriptionPlaceholder}
            />
         )}
      </div>
   );
};

export default RubricEntryDescription;
