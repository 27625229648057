import * as _ from 'lodash';
import * as React from 'react';

import Appearance from '@models/Appearance';
import {
   ProficiencyActivitySummary,
   ProficiencyCanDoStatementDetailedWithActivities,
} from '@models/Proficiency';
import classnames from 'classnames';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';

interface CanDoStatementActivitiesModalProps {
   onCancel(): void;
   selectedCanDoStatement: ProficiencyCanDoStatementDetailedWithActivities;
}

const CanDoStatementActivitiesModal: React.FC<CanDoStatementActivitiesModalProps> = ({
   selectedCanDoStatement,
   onCancel,
}) => {
   const {
      routes: {
         content: { previewActivity },
      },
   } = Constants;

   const renderLink = ({ activityId }: ProficiencyActivitySummary) => (
      <Link
         key={`link-${activityId}`}
         target='_blank'
         to={previewActivity.replace(':activityId', activityId.toString())}
      >
         {activityId}
      </Link>
   );

   // Activity table columns
   const columns: readonly Column<ProficiencyActivitySummary>[] = [
      {
         // Activity ID
         id: 'activityId',
         header: 'ID',
         cell: (i) => renderLink(i),
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         // Activity name
         id: 'activityName',
         header: 'Name',
         cell: (i) => i.activityName,
         canSort: true,
         sortFunc: (i) => i.activityName.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         // Activity Language
         id: 'activityLanguage',
         header: 'Language',
         cell: (i) => i.activityLanguage,
         canSort: true,
         sortFunc: (i) => i.activityLanguage.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading={selectedCanDoStatement.name}
         onClose={onCancel}
         className='no-padding'
         bodyClassName='modal-body'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         actions={[{ text: 'Close', onClick: onCancel }]}
      >
         <div className='modal-body-scroll'>
            <Table<ProficiencyActivitySummary>
               columns={columns}
               rowKey='activityId'
               rows={selectedCanDoStatement.activities}
            />
         </div>
      </ModalDialog>
   );
};

export default CanDoStatementActivitiesModal;
