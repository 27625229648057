import { Maybe } from '@models/Core';

import { ContentType } from './Content';

export enum NotificationType {
   assignmentGraded = 'assignment_graded',
   feedbackLeft = 'feedback_left',
}

interface BaseNotification {
   createdOn: Date;
   updatedOn: Maybe<Date>;
   id: number;
   notificationType: NotificationType;
   read: boolean;
   userId: number;
}

export interface AssignmentNotification extends BaseNotification {
   data: {
      contentName: string;
      contentId: number;
      contentType: ContentType;
      submissionId: number;
      moduleItemId: number;
   };
}

type Notification = AssignmentNotification;

export default Notification;
