import * as _ from 'lodash';
import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { getPathFromUrl } from '@helpers/QueryParameter';
import {
   bookstoreCodePattern,
   courseCodePattern,
   emailPattern,
   integerPattern,
} from '@helpers/RegexPatterns';
import { ContextInfo } from '@models/Breadcrumbs';
import { Maybe } from '@models/Core';
import { Features } from '@models/Feature';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import Autosuggest, { RenderSuggestion, SuggestionSelectedEventData } from 'react-autosuggest';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import { CommandProps } from './CommandController';
import {
   makeAdminCommands,
   makeContentNavigation,
   makeCourseNavigation,
   makeCurrentCourseRoutes,
   makeNationalExamAdminCommands,
   makeQueryCommands,
   makeRootNavigation,
   makeVideoCommands,
} from './Commands';
import { Command, CommandCategory } from './Models';
import sortSuggestions, { Suggestion } from './Suggestions';

const CommandPalette: React.FC<CommandProps> = ({ open, close }) => {
   const navigate = useNavigate();
   const location = useLocation();

   const maxDisplayed = 7;

   const appStateContext = React.useContext<AppStateContext>(AppStateContext);

   const {
      breadcrumbs,
      userProfile,
      currentCourses,
      isImpersonating,
      commands: appStateCommands,
   } = appStateContext;

   const { isAdmin = false, isNationalExamAdmin = false } = userProfile || {};

   const [inputValue, setInputValue] = React.useState<string>('');
   const [suggestions, setSuggestions] = React.useState<readonly Suggestion[]>([]);
   const [enabledFeatures, setEnabledFeatures] = React.useState<readonly Features[]>([]);

   const info: ContextInfo = {
      ..._.reduce(
         breadcrumbs.breadcrumbs.map((i) => i.contextInfo ?? {}),
         (prev, current) => ({ ...prev, ...current }),
         {},
      ),
      ...userProfile?.permissions,
      isAdmin,
      isImpersonating,
      isNationalExamAdmin,
   };

   const isValidCommand = (command: Maybe<Command>): boolean => {
      const isSameLink = getPathFromUrl(command?.link ?? '') === location.pathname;
      return !!command && !isSameLink && (command.showIf === undefined || command.showIf(info));
   };

   const removeOverrides = (unfilteredCommands: readonly Command[]): readonly Command[] => {
      const idsToRemove = _.chain(unfilteredCommands)
         .map((i) => i.overrides)
         .compact()
         .flatten()
         .uniq()
         .value();
      return unfilteredCommands.filter((i) => !idsToRemove.includes(i.id));
   };

   const commands = React.useMemo(
      () =>
         removeOverrides(
            [
               ...appStateCommands,
               ...makeRootNavigation(info),
               ...makeContentNavigation(info),
               ...makeCourseNavigation(info, location, appStateContext),
               ...makeCurrentCourseRoutes(info, currentCourses, location),
               ...makeAdminCommands(info, navigate, appStateContext, enabledFeatures),
               ...makeNationalExamAdminCommands(),
               ...makeVideoCommands(enabledFeatures),
            ].filter(isValidCommand),
         ),
      [breadcrumbs, enabledFeatures, appStateCommands],
   );

   React.useEffect(() => {
      setSuggestions(
         _.orderBy(
            commands.map((i) => ({ ...i, highlight: '' })),
            ['scale'],
            ['desc'],
         ),
      );
   }, [commands]);

   const fetchEnabledFeatures = (): void => {
      const featuresToCheck: Features[] = [
         'bulk_video',
         'can_do_statement_editor',
         'speaker_editor',
         'video_interview_questions',
      ];
      const features: Features[] = [];
      featuresToCheck.forEach((f) => {
         UserService.checkFeature(f).then((isEnabled) => {
            if (isEnabled) {
               features.push(f);
            }
         });
      });
      setEnabledFeatures(features);
   };

   React.useEffect(() => {
      fetchEnabledFeatures();
      setSuggestions(
         _.orderBy(
            commands.map((i) => ({ ...i, highlight: '' })),
            ['scale'],
            ['desc'],
         ),
      );
      setInputValue('');
   }, []);

   const onChange = (
      _event: React.FormEvent<HTMLElement>,
      { newValue }: { newValue: string },
   ): void => {
      setInputValue(newValue);
   };

   const onSuggestionSelected = (
      _event: React.FormEvent,
      { suggestion }: SuggestionSelectedEventData<Suggestion>,
   ): void => {
      if (_.isString(suggestion.link)) {
         close();
         navigate(suggestion.link);
      } else if (suggestion.commandName) {
         open(suggestion.commandName);
      } else if (_.isFunction(suggestion.action)) {
         if (suggestion.autoClose !== false) {
            const decoratedAction = after(() => {
               close();
            })(suggestion.action);
            decoratedAction();
         } else {
            suggestion.action();
         }
      }
   };

   const scoreFn = (keysResult: readonly Fuzzysort.KeyResult<Command>[]): number | null => {
      let max = -Number.MAX_SAFE_INTEGER;
      for (let i = keysResult.length - 1; i >= 0; --i) {
         const result = keysResult[i];
         if (result === null) {
            continue;
         }
         const scale = commands.find((j) => j.title === keysResult[i].target)?.scale ?? 1;
         const score = result.score * scale;
         if (score > max) {
            max = score;
         }
      }
      if (max === -Number.MAX_SAFE_INTEGER) {
         return null;
      }
      return max;
   };

   // Add new commands based on used input
   const fetchQueryCommands = async (query: string): Promise<readonly Command[]> => {
      const shouldSearch =
         info.isAdmin &&
         query.length > 0 &&
         [integerPattern, emailPattern, bookstoreCodePattern, courseCodePattern].some((i) =>
            i.test(query.trim()),
         );
      if (shouldSearch) {
         const queryParams = new URLSearchParams({ query });
         return HttpService.getWithAuthToken<{
            user: Maybe<{ id: number; firstName: string; lastName: string }>;
            course: Maybe<{ id: number; name: string }>;
         }>(`/api/admin/search?${queryParams}`).then((response) => {
            const { user, course } = response.data;
            return makeQueryCommands(user, course, info, navigate);
         });
      }
      return Promise.resolve([]);
   };

   // Autosuggest will call this function every time you need to update suggestions.
   const onSuggestionsFetchRequested = async ({ value }: { value: string }): Promise<void> => {
      const fuzzysortOptions = {
         threshold: -Infinity, // Don't return matches worse than this (higher is faster)
         limit: 7, // Don't return more results than this (lower is faster)
         allowTypo: true, // Allwos a snigle transpoes (false is faster)
         key: 'title', // For when targets are objects (see its example usage)
         keys: ['title'], // For when targets are objects (see its example usage)
         scoreFn, // For use with `keys` (see its example usage)
      };
      const queryCommands = await fetchQueryCommands(value);
      const uniqueCommands = _.uniqBy([...queryCommands, ...commands], 'id');
      setSuggestions(sortSuggestions(value, uniqueCommands, fuzzysortOptions));
   };

   const onSuggestionsClearRequested = (): boolean =>
      // when using the onSuggestionsClearRequested property, it overrides
      // alwaysRenderSuggestions which I think is counter intuitive, as always should mean
      // always, see: https://github.com/moroshko/react-autosuggest/issues/521
      // once this issue is resolved the suggestions should return an empty array, ex:
      // this.setState({
      //   suggestions: []
      // });
      true;
   const renderSuggestion: RenderSuggestion<Suggestion> = ({
      title,
      highlight,
      category = CommandCategory.command,
   }): React.ReactElement => (
      <div className='command-palette-suggestion'>
         <span className={classnames('command-category', category)}>{category}</span>
         <span className='command-text'>
            {highlight && _.isString(highlight) ? parseHtml(highlight) : title}
         </span>
         {/* <kbd className="command-shortcut">{shortcut}</kbd> */}
      </div>
   );

   return (
      <div
         className='modal command-palette-modal'
         role='dialog'
         aria-label='Command Palette'
         tabIndex={-1}
      >
         <div className='command-palette-header'>
            <div className='title'>Lingco Command Palette</div>
            <div className='shortcuts'>
               <span className='shortcut'>
                  <kbd>↑↓</kbd> to navigate
               </span>
               <span className='shortcut'>
                  <kbd>enter</kbd> to select
               </span>
               <span className='shortcut'>
                  <kbd>esc</kbd> to dismiss
               </span>
            </div>
         </div>
         <Autosuggest<Suggestion>
            alwaysRenderSuggestions
            getSuggestionValue={(suggestion) => suggestion.title}
            highlightFirstSuggestion
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            renderSuggestion={renderSuggestion}
            suggestions={suggestions.slice(0, maxDisplayed)}
            theme={theme}
            inputProps={{
               onChange,
               value: inputValue,
               type: 'search',
               className: 'command-input mousetrap',
               autoFocus: true,
               placeholder: 'What would you like to do?',
            }}
         />
      </div>
   );
};

export default CommandPalette;

const theme = {
   container: 'command-palette-container',
   containerOpen: 'command-container--open',
   input: 'command-input',
   inputOpen: 'command-input--open',
   inputFocused: 'command-input--focused',
   suggestionsContainer: 'command-suggestions-container',
   suggestionsContainerOpen: 'command-suggestions-container--open',
   suggestionsList: 'command-suggestions-list',
   suggestion: 'command-suggestion',
   suggestionFirst: 'command-suggestion--first',
   suggestionHighlighted: 'command-suggestion--highlighted',
   sectionContainer: 'command-palette-section-container',
   sectionContainerFirst: 'command-palette-section-container--first',
   sectionTitle: 'command-palette-section-title',
};

// Apply a functions that'll run after the command's function runs
// Monkey patching for the commands
// http://me.dt.in.th/page/JavaScript-override/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func<T = unknown> = (this: T, ...args: any[]) => any;

const after = function <T = unknown>(extraBehavior: Func<T>): Func<T> {
   return function (original: Func<T>): Func<T> {
      return function (this: T, ...args: any[]): unknown {
         const returnValue = original.apply(this, args);
         extraBehavior.apply(this, args);
         return returnValue;
      };
   };
};
