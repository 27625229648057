import * as _ from 'lodash';
import * as React from 'react';

import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { IdName } from '@models/Core';
import {
   ProficiencyReportSkillData,
   ProficiencyReportTileBreakdownBySkill,
   ProficiencySkillsPlusOverall,
} from '@models/Proficiency';

import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import Loader from '@components/Loader';
import ProficiencyReportProgressBar from './ProficiencyReportProgressBar';

type Props<T, K> = {
   data: T[];
   'data-test': string;
   groupById: keyof T;
   linkBuilder(row: K): string;
   rows: K[];
   skillBreakdown: ProficiencyReportTileBreakdownBySkill;
};

type GroupedData<T> = {
   [key in string | number]: T[];
};

type BaseTableData = {
   countOfCompletedCanDoStatements: number;
   skill: ProficiencySkillsPlusOverall;
};

const ProficiencyReportTable = <T extends BaseTableData, K extends IdName>(
   props: Props<T, K>,
): React.ReactElement => {
   const [groupedData, setGroupedData] = React.useState<GroupedData<T> | undefined>(undefined);

   React.useEffect(() => {
      setGroupedData(_.groupBy(props.data, props.groupById));
   }, [props]);

   if (!groupedData) {
      return <Loader />;
   }

   const renderGraph = (i: string | number, skill: ProficiencySkillsPlusOverall) => {
      const skillInfo = props.skillBreakdown[skill];
      const data = groupedData[i] || [];
      let completed = 0;

      if (skill === 'Overall') {
         completed = data.reduce((prev, row) => prev + row.countOfCompletedCanDoStatements, 0);
      } else {
         completed = data.find((r) => r.skill === skill)?.countOfCompletedCanDoStatements || 0;
      }

      const skillData: ProficiencyReportSkillData = {
         completedAverage: completed,
         presentedCount: skillInfo.presentedCount,
         totalCount: skillInfo.totalCount,
      };

      return (
         <div>
            <ProficiencyReportProgressBar
               skillData={skillData}
               size='sm'
               progressBarClassName='no-margin'
            />
         </div>
      );
   };

   const columns: readonly Column<K>[] = [
      {
         id: 'reportTableRowName',
         header: 'Name',
         cell: (i) => (
            <Link
               to={props.linkBuilder(i)}
               className='white-space-pre-wrap'
               data-test={`report-link-${i.id}`}
            >
               {i.name}
            </Link>
         ),
      },
      {
         id: 'reportTableRowOverall',
         header: 'Overall',
         cell: (i) => renderGraph(i.id, 'Overall'),
      },
      {
         id: 'reportTableRowReading',
         header: 'Reading',
         cell: (i) => renderGraph(i.id, 'Reading'),
      },
      {
         id: 'reportTableRowWriting',
         header: 'Writing',
         cell: (i) => renderGraph(i.id, 'Writing'),
      },
      {
         id: 'reportTableRowListening',
         header: 'Listening',
         cell: (i) => renderGraph(i.id, 'Listening'),
      },
      {
         id: 'reportTableRowSpeaking',
         header: 'Speaking',
         cell: (i) => renderGraph(i.id, 'Speaking'),
      },
   ];

   return (
      <div className='margin-right-s' style={{ backgroundColor: '#fff' }}>
         <Table<K>
            columns={columns}
            defaultSortBy={[{ id: 'reportTableRowName', desc: false }]}
            rowKey='id'
            rows={props.rows}
            dataTest={props['data-test']}
         />
         {!props.rows.length && (
            <EmptyState
               className='padding-top-m padding-bottom-s'
               heading='Nothing to show'
               icon={<IconBinoculars className='large' aria-hidden />}
               description='No results found'
            />
         )}
      </div>
   );
};

export default ProficiencyReportTable;
