import * as React from 'react';

import SchoolProfile from '@models/SchoolProfile';
import IntercomService from '@services/IntercomService';
import RegistrationService, {
   RegisteringUser,
   RegisteringUserWithSchoolProfile,
   RegistrationError,
   RegistrationStep,
} from '@services/RegistrationService';

import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import RegisterError from './RegisterError';

interface DomainRestrictedProps {
   displayMessage?: string;
   errors: readonly RegistrationError[];
   isLoading: boolean;
   user: RegisteringUserWithSchoolProfile;
   setStep(step: RegistrationStep): void;
   setEmail(email: string): void;
   submitRegistration(user?: RegisteringUser): void;
}

const DomainRestricted: React.FC<DomainRestrictedProps> = ({
   isLoading,
   user: { school },
   user,
   setStep,
   submitRegistration,
   setEmail,
   errors,
}) => {
   const [schoolEmail, setSchoolEmail] = React.useState<string>('');
   const [localError, setLocalError] = React.useState<string>('');
   const [isVerifying, setIsVerifying] = React.useState<boolean>(false);

   const validateForm = async (): Promise<boolean> => {
      let updatedLocalError = '';
      if (!schoolEmail.length) {
         updatedLocalError = 'Cannot be blank';
      } else {
         if (!(await RegistrationService.checkEmailDomainValid(schoolEmail, school?.id))) {
            updatedLocalError = 'Invalid email';
         } else {
            setIsVerifying(true);
            if (!(await RegistrationService.checkEmailUnique(schoolEmail))) {
               updatedLocalError = 'Email already taken';
               setIsVerifying(false);
            }
         }
      }
      setLocalError(updatedLocalError);
      return !updatedLocalError;
   };

   const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSchoolEmail(event.target.value);
   };

   const handleHavingTrouble = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      const message = `Hello! I am unable to register at ${
         (school as SchoolProfile).name
      }. Can you help?`;
      IntercomService.startChat(message);
   };

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (await validateForm()) {
         setEmail(schoolEmail);
         submitRegistration({ ...user, email: schoolEmail });
      }
   };

   return (
      <div className='card-content-onboarding card padding-bottom-m'>
         <div className='onboarding-title border-bottom text-center'>
            <DocumentTitle>Domain Restricted</DocumentTitle>
            <h2 aria-live='polite' className='uppercase no-margin'>
               Domain Restricted
            </h2>
         </div>
         <form className='content-form' onSubmit={handleSubmit}>
            <p>
               The school administrator at {school.name} has restricted account registrations to the
               school email domain
            </p>
            <div className='row'>
               <div className='col-xs-12'>
                  <p>{school.name} Email</p>
                  <input
                     name='schoolEmail'
                     type='email'
                     data-test='school-email'
                     spellCheck={false}
                     value={schoolEmail}
                     onChange={handleEmailChange}
                  />
                  {localError && <p className='error'>{localError}</p>}
                  <Button className='margin-top-s pointer' onClick={handleHavingTrouble} textLink>
                     Having trouble?
                  </Button>
               </div>
            </div>
            <div className='row margin-top-m'>
               <div className='col-xs-6'>
                  <Button
                     type='submit'
                     data-test='continue'
                     fullWidth
                     loading={isVerifying || isLoading}
                  >
                     Continue
                  </Button>
               </div>
               <div className='col-xs-6'>
                  <Button
                     line
                     fullWidth
                     disabled={isLoading}
                     onClick={() => setStep(RegistrationStep.schoolSearch)}
                  >
                     Back
                  </Button>
               </div>
               <RegisterError errors={errors} />
            </div>
         </form>
      </div>
   );
};

export default DomainRestricted;
