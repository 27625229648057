import { SortByEntry } from '@components/Common/Table';
import { OnboardingTask, OnboardingWalkthrough } from '@components/Onboarding';
import { PredicateGroup } from '@components/PredicateEditor/Models';
/* eslint-disable camelcase */
import { datadogRum } from '@datadog/browser-rum';
import { OrderBy, UserReportRowFragment, UserTypeaheadFragment } from '@generated/gql/graphql';
import { GraphqlClient } from '@graphql/client';
import { userReportQuery, userTypeaheadQuery } from '@graphql/queries';
import BrowserStorage from '@helpers/BrowserStorage';
import { camelCaseKeys, snakeCaseKeys } from '@helpers/ModifyKeys';
import AccountType from '@models/AccountType';
import { ID, Maybe, MessageResponse } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import { Features } from '@models/Feature';
import ISchool from '@models/ISchool';
import IUserInfo from '@models/IUserInfo';
import IUserProfile from '@models/IUserProfile';
import Language from '@models/Language';
import LMSName from '@models/LMSName';
import PagedResponse from '@models/PagedResponse';
import SchoolType from '@models/SchoolType';
import UserFeatureInfo from '@models/UserFeatureInfo';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import axios, { AxiosPromise } from 'axios';

import Config from '../Config';
import Constants from '../Constants';
import mixPanelActions from '../Mixpanel';
import AuthService from './AuthService';
import HttpService, { HttpServiceRequestOptions } from './HttpService';
import IntercomService from './IntercomService';

const loginRedirectPathKey = 'login-redirect-path';

export interface UserSearchFilters {
   query?: string;
   demo?: boolean;
   accountType?: AccountType;
   schoolType?: SchoolType;
   language?: Language;
   page?: number;
   predicate?: Maybe<PredicateGroup>;
}

interface SuccessfulLoginResponse {
   accessToken: string;
   archivedCourses: readonly BasicCourseProfile[];
   courseId?: number;
   currentCourses: readonly BasicCourseProfile[];
   refreshToken: string;
   school: ISchool;
   district?: ISchool;
   user: IUserProfile;
}

interface TempPasswordResponse {
   isTempPassword: boolean;
   resetToken: string;
}

export type LoginResponse = SuccessfulLoginResponse | TempPasswordResponse;

export const isTempPasswordResponse = (data: Maybe<LoginResponse>): data is TempPasswordResponse =>
   (data as TempPasswordResponse)?.isTempPassword === true;

export const isSuccessfulLoginResponse = (
   data: Maybe<LoginResponse>,
): data is SuccessfulLoginResponse => !!(data as SuccessfulLoginResponse)?.user;

const deleteUser = (userId: number): AxiosPromise<MessageResponse> =>
   HttpService.deleteWithAuthToken<MessageResponse>(`/api/users/${userId}`);

const editUser = (userId: number, update: Partial<IUserProfile>): Promise<IUserProfile> =>
   HttpService.patchWithAuthToken<{ msg: string; user: IUserProfile }>(
      `/api/users/${userId}`,
      snakeCaseKeys(update),
      { handleForbidden: false },
   ).then((response) => response.data.user);

/* #region Features */

const checkFeature = (feature: Features): Promise<boolean> => {
   const {
      user: { loggedIn, userProfile },
   } = store.getState();
   if (!loggedIn) {
      return Promise.resolve(false);
   }
   if (userProfile.features.includes(feature)) {
      return Promise.resolve(true);
   }
   return HttpService.getWithAuthToken<{ enabled: boolean }>(
      `/api/features/check?feature=${encodeURIComponent(feature)}`,
   ).then((response) => {
      const { enabled } = response.data;
      if (enabled) {
         store.dispatch(actionFunctions.appendFeature(feature));
      }
      return enabled;
   });
};

const getFeatures = (userId: number): Promise<readonly UserFeatureInfo[]> =>
   HttpService.getWithAuthToken<{ features: readonly UserFeatureInfo[] }>(
      `/api/users/${userId}/features`,
   ).then((response) => response.data.features);

const checkFeatureLocalStorage = (feature: Features): Promise<boolean> => {
   const {
      user: { loggedIn, userProfile },
   } = store.getState();
   if (!loggedIn) {
      return Promise.resolve(false);
   }
   if (userProfile.features.includes(feature)) {
      return Promise.resolve(true);
   }

   return Promise.resolve(false);
};

const addFeature = (userId: number, featureId: number): Promise<void> =>
   HttpService.postWithAuthToken<MessageResponse>(
      `/api/users/${userId}/features`,
      snakeCaseKeys({
         featureId,
      }),
   ).then();

const removeFeature = (userId: number, featureId: number): Promise<void> =>
   HttpService.deleteWithAuthToken<MessageResponse>(
      `/api/users/${userId}/features/${featureId}`,
   ).then();

/* #endregion */

const completeWalkthrough = (walkthrough: OnboardingWalkthrough): Promise<void> =>
   HttpService.postWithAuthToken<MessageResponse>(
      '/api/users/complete_walkthrough',
      snakeCaseKeys({ walkthrough }),
   ).then();

const completeOnboardingTask = (onboardingTask: OnboardingTask): void => {
   const {
      user: {
         userProfile: { completedOnboardingTasks = [] },
      },
   } = store.getState();
   if (!completedOnboardingTasks.includes(onboardingTask)) {
      HttpService.postWithAuthToken<MessageResponse>(
         '/api/users/complete_onboarding_task',
         snakeCaseKeys({ onboardingTask }),
      );
      store.dispatch(actionFunctions.completeOnboardingTask(onboardingTask));
   }
};

const checkGoogleScopes = (scopes: readonly string[]): boolean => {
   const {
      user: {
         userProfile: { googleScopes = [] },
      },
   } = store.getState();
   return scopes.every((i) => {
      const exactScope = googleScopes.includes(i);
      if (exactScope) {
         return true;
      } else if (i.endsWith('.readonly')) {
         const manageScope = i.substr(0, i.indexOf('.endswith'));
         return googleScopes.includes(manageScope);
      }
      return false;
   });
};

const getGoogleScopes = (scopes: readonly string[]): Promise<void> => {
   const {
      user: {
         userProfile: { googleScopes = [] },
      },
   } = store.getState();
   const SUCCESS_MESSAGE = 'google-request-success';
   return new Promise((resolve) => {
      if (scopes.every((i) => googleScopes.includes(i))) {
         resolve();
      } else {
         const neededScopes = scopes.filter((i) => !googleScopes.includes(i));
         const encodedScopes = encodeURIComponent(neededScopes.join(' '));

         const receiveMessage = (event: MessageEvent): void => {
            if (event.origin === window.location.origin && event.data === SUCCESS_MESSAGE) {
               window.removeEventListener('message', receiveMessage);
               store.dispatch(actionFunctions.appendGoogleScopes(neededScopes));
               resolve();
            }
         };

         window.removeEventListener('message', receiveMessage);
         const windowHeight =
            neededScopes.length >= 2 ? Math.min(850, 600 + (neededScopes.length - 2) * 125) : 600;
         let url = `/api/auth/google/scope_request_uri?scope=${encodedScopes}`;
         if (!googleScopes.length) {
            url += '&initial=true';
         }
         const newWindow = window.open(url, 'name', `height=${windowHeight},width=450`);
         newWindow?.focus();
         window.addEventListener('message', receiveMessage);
      }
   });
};

const getLMSAuthorization = (lms: LMSName): Promise<void> => {
   const {
      user: {
         userProfile: { id: userId },
      },
   } = store.getState();
   const SUCCESS_MESSAGE = `${lms}-request-success`;
   return new Promise((resolve) => {
      const receiveMessage = (event: MessageEvent): void => {
         if (event.origin === window.location.origin && event.data === SUCCESS_MESSAGE) {
            window.removeEventListener('message', receiveMessage);
            resolve();
         }
      };

      const newWindow = window.open(`/api/auth/${lms}/auth_request_uri?user_id=${userId}`, 'name');
      newWindow?.focus();
      window.addEventListener('message', receiveMessage);
   });
};

const impersonateUser = async (userId: ID): Promise<void> => {
   const response = await HttpService.postWithAuthToken<SuccessfulLoginResponse>(
      `/api/users/${userId}/impersonate`,
   );
   const {
      accessToken,
      archivedCourses,
      currentCourses,
      district: districtProfile,
      refreshToken,
      school: schoolProfile,
      user: userProfile,
   } = response.data;
   const user: IUserInfo = {
      accessToken,
      districtProfile,
      loggedIn: true,
      refreshToken,
      schoolProfile,
      userProfile: { ...userProfile, intercomHash: null },
   };
   identifyIntercom(userProfile, schoolProfile);
   document.body.classList.add('impersonating');
   store.dispatch(actionFunctions.impersonateUser(user, currentCourses, archivedCourses));
};

const cancelImpersonation = (): Promise<void> => {
   const {
      user: { impersonatorRefreshToken: refreshToken },
   } = store.getState();
   if (refreshToken) {
      return axios({
         method: 'get',
         url: '/api/users/me',
         headers: { Authorization: `Bearer ${refreshToken}` },
      }).then((response) => {
         document.body.classList.remove('impersonating');
         void loginHelper(camelCaseKeys({ ...response.data, refreshToken }));
      });
   }
   return Promise.reject('RefreshToken cannot be empty');
};

const sendConfirmationEmail = (userId: number): AxiosPromise<MessageResponse> =>
   HttpService.postWithAuthToken<MessageResponse>(`/api/users/${userId}/confirmation_email`);

const sendConfirmationEmails = (userIds: number[]): AxiosPromise<MessageResponse> =>
   HttpService.postWithAuthToken<MessageResponse>(
      '/api/users/confirmation_email',
      snakeCaseKeys({ userIds }),
   );

const identifyIntercom = (user: IUserProfile, school: Maybe<ISchool>): void => {
   if (user) {
      IntercomService.boot(
         snakeCaseKeys({
            ...IntercomService.baseConfig,
            userId: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            companyId: school?.id,
            role: user.accountType,
            userHash: user.intercomHash,
         }),
      );
   }
};

const identifyCanny = (user: IUserProfile, school: ISchool): void => {
   window.Canny?.('identify', {
      appID: Constants.canny,
      user: {
         avatarURL: user.profileImageUrl,
         companies: school
            ? [
                 {
                    id: school.id.toString(),
                    name: school.name,
                 },
              ]
            : [],
         // user.createdOn could be null or an empty string
         created: user?.createdOn ? new Date(user.createdOn).toISOString() : '',
         email: user.email,
         id: user.id.toString(),
         name: `${user.firstName} ${user.lastName}`,
      },
   });
};

const identifyDatadog = (user: IUserProfile, school: ISchool): void => {
   datadogRum.setUser(
      snakeCaseKeys({
         id: user.id.toString(),
         name: `${user.firstName} ${user.lastName}`,
         email: user.email,
         accountType: user.accountType,
         school: school.name,
      }),
   );
};

const identifyMixPanel = (user: IUserProfile): void => {
   mixPanelActions.identify(user.id.toString());
   mixPanelActions.people.setOnce({
      $email: user.email,
      $first_name: user.firstName,
      $last_name: user.lastName,
      account_type: user.accountType,
      language: user.language,
   });
};

const loadAndIdentifyUser = (user: IUserProfile, school: ISchool): void => {
   const isInstructor = user.accountType === AccountType.instructor;
   if (school && user) {
      identifyDatadog(user, school);
      if (isInstructor) {
         // Only identify students in Intercom when they message us
         identifyIntercom(user, school);
         identifyMixPanel(user);
      }
      if (isInstructor && Config.environmentType === 'production') {
         identifyCanny(user, school);
      }
   }
};

const logout = async (): Promise<void> => {
   await AuthService.logout();

   IntercomService.reboot();
   // Logout from MixPanel
   mixPanelActions.reset();
   return Promise.resolve();
};

const loginHelper = (data: LoginResponse): Promise<LoginResponse> => {
   if (isTempPasswordResponse(data)) {
      return Promise.resolve(data);
   } else {
      const {
         accessToken,
         archivedCourses,
         currentCourses,
         district: districtProfile,
         refreshToken,
         school: schoolProfile,
         user: userProfile,
      } = data;
      store.dispatch(
         actionFunctions.loginUser({
            loggedIn: true,
            districtProfile,
            userProfile,
            schoolProfile,
            accessToken,
            refreshToken,
         }),
      );
      store.dispatch(actionFunctions.setCourses(currentCourses, archivedCourses));
      loadAndIdentifyUser(userProfile, schoolProfile);
      return Promise.resolve(data);
   }
};

const tokenLogin = (token: string): Promise<number | undefined> =>
   HttpService.post<LoginResponse>('/api/auth/token_login', { token }).then((response) => {
      const { data } = response;
      return loginHelper(data).then(() =>
         isSuccessfulLoginResponse(data) && data.courseId ? data.courseId : undefined,
      );
   });

const login = (email: string, password: string): Promise<LoginResponse> =>
   HttpService.post<LoginResponse>('/api/auth/login', snakeCaseKeys({ email, password })).then(
      (response) => loginHelper(response.data),
   );

const saveLoginRedirectPath = (route: string): void => {
   const TEN_MINUTES_IN_SECONDS = 10 * 60;
   BrowserStorage.setString(loginRedirectPathKey, route, TEN_MINUTES_IN_SECONDS);
};

const getLoginRedirectPath = (): string => BrowserStorage.getString(loginRedirectPathKey) ?? '/';

const deleteLoginRedirectPath = (): void => {
   BrowserStorage.deleteString(loginRedirectPathKey);
};

const forgotPasswordRequest = (email: string): Promise<void> =>
   HttpService.post<{ msg: string }>('/api/users/forgot_password', { email }).then();

const getUsersTypeAhead = async (query: string): Promise<UserTypeaheadFragment[]> => {
   const result = await GraphqlClient.query(userTypeaheadQuery, {
      searchString: query,
   });

   if (!result.data) {
      throw new Error('No data returned from GraphQL query');
   }

   return result.data.userReport.rows;
};

const getUsers = async (
   { predicate, ...rest }: UserSearchFilters,
   sortOrder?: readonly SortByEntry[],
   _options?: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<UserReportRowFragment>> => {
   const orderBy: OrderBy[] = sortOrder
      ? sortOrder.map((i) => ({
           columnName: i.id,
           desc: i.desc,
        }))
      : [];

   const result = await GraphqlClient.query(userReportQuery, {
      currentPageNumber: rest.page,
      orderBy,
      pageSize: 50,
      searchString: rest.query,
   });

   if (!result.data) {
      throw new Error('No data returned from GraphQL query');
   }

   return {
      rows: result.data.userReport.rows,
      currentPageNumber: rest.page ?? 1,
      pageSize: 50,
      totalPageCount: result.data?.userReport?.queryResultTotalCount / 50,
      queryResultTotalCount: result.data?.userReport?.queryResultTotalCount,
   };
};

export default {
   addFeature,
   cancelImpersonation,
   checkFeature,
   checkFeatureLocalStorage,
   checkGoogleScopes,
   completeOnboardingTask,
   completeWalkthrough,
   deleteLoginRedirectPath,
   deleteUser,
   editUser,
   forgotPasswordRequest,
   getFeatures,
   getGoogleScopes,
   getLMSAuthorization,
   getLoginRedirectPath,
   getUsers,
   getUsersTypeAhead,
   identifyIntercom,
   impersonateUser,
   loadAndIdentifyUser,
   login,
   logout,
   removeFeature,
   saveLoginRedirectPath,
   sendConfirmationEmail,
   sendConfirmationEmails,
   tokenLogin,
};
