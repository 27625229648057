import * as _ from 'lodash';
import * as React from 'react';

import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import IconRoster from '@icons/general/icon-roster.svg';
import IconSettings1 from '@icons/nova-line/03-Settings/settings-1.svg';
import { Maybe } from '@models/Core';
import { Course } from '@models/Course';
import {
   NationalExam,
   NationalExamAbbreviation,
   NationalExamLevelSeatAllocation,
   NationalExamReportLinks,
} from '@models/NationalExam';
import HttpService from '@services/HttpService';
import NationalExamService from '@services/NationalExamService';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';

interface NationalExamStatus {
   setupHasStarted: boolean;
   setupIsComplete: boolean;
   exam: NationalExam;
   levels: readonly NationalExamLevelSeatAllocation[];
   clonedCourses: readonly Course[];
}

const NationalExamDashboard: React.FC = () => {
   const navigate = useNavigate();

   const appContext = React.useContext(AppStateContext);
   const { examUrlSlug } = useParams<{ examUrlSlug: string }>();

   const [nationalExam, setNationalExam] = React.useState<Maybe<NationalExam>>(null);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [setupHasStarted, setSetupHasStarted] = React.useState<boolean>();
   const [clonedCourses, setClonedCourses] = React.useState<readonly Course[]>([]);
   const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
   const [levels, setLevels] = React.useState<readonly NationalExamLevelSeatAllocation[]>([]);
   const [reportLinks, setReportLinks] = React.useState<readonly NationalExamReportLinks[]>([]);

   React.useEffect(() => {
      if (!examUrlSlug) {
         return;
      }
      setIsFetching(true);
      setIsInitialized(true);

      HttpService.getWithAuthToken<NationalExamStatus>(
         `/api/national_exams/${examUrlSlug}/status`,
      ).then((response) => {
         const data = response.data;
         setNationalExam(data.exam);
         setClonedCourses(data.clonedCourses);
         setSetupHasStarted(data.setupHasStarted);
         setLevels(data.levels);
      });

      NationalExamService.getReportLinks(examUrlSlug).then((links) => setReportLinks(links));
   }, [examUrlSlug]);

   // We'll add this exam to the list of available ones for the current user
   // the first time they visit the dashboard page
   React.useEffect(() => {
      if (setupHasStarted === undefined) {
         return;
      }

      if (!appContext.availableNationalExams || !examUrlSlug) {
         return;
      }

      // This code helps guard against if someone goes to https://class.lingco.io/national_exams/nge_2023
      // instead of https://class.lingco.io/national_exams/nge
      const examUrlSlugNoYear = examUrlSlug.replace(/_\d*/, '');

      if (
         !appContext.availableNationalExams.includes(examUrlSlug as NationalExamAbbreviation) &&
         !appContext.availableNationalExams.includes(examUrlSlugNoYear as NationalExamAbbreviation)
      ) {
         NationalExamService.makeExamAvailableToUser(examUrlSlug as NationalExamAbbreviation).then(
            (examAbbr) => {
               appContext.setAvailableNationalExams([
                  ...appContext.availableNationalExams,
                  examAbbr,
               ]);
            },
         );
      }

      if (!setupHasStarted) {
         navigate(`/national_exams/${examUrlSlug}/setup`);
      } else {
         setIsFetching(false);
      }
   }, [examUrlSlug, setupHasStarted, appContext.availableNationalExams]);

   React.useEffect(() => {
      if (!nationalExam) {
         return;
      }

      appContext.setBreadcrumbs({
         breadcrumbs: [
            {
               link: `/national_exams/${nationalExam.nameAbbr.toLowerCase()}`,
               text: `${nationalExam.nameAbbr} Dashboard`,
            },
         ],
         next: null,
         prev: null,
      });
   }, [nationalExam]);

   if (isFetching || !isInitialized || !nationalExam) {
      return <Loader />;
   }

   const renderActionLinks = (): readonly React.ReactNode[] => {
      const links = [];
      if (nationalExam.hasLingcoRegistration) {
         links.push({
            text: 'Order more seats',
            to: `/national_exams/${nationalExam.shortName}/register`,
            icon: <IconRoster />,
         });
      }
      links.push({
         text: 'Review setup steps',
         to: `/national_exams/${examUrlSlug}/setup`,
         icon: <IconSettings1 />,
      });
      return links
         .filter((i) => !!i)
         .map(({ to, text, icon }) => (
            <li key={to}>
               <Link to={to} data-test={text}>
                  {icon}
                  {text}
               </Link>
            </li>
         ));
   };

   const renderSpecificHelpArticles = (): readonly React.ReactNode[] => {
      const links: React.ReactNode[] = [];

      nationalExam.helpLinks?.forEach((x) => {
         links.push(
            <li key={x.link}>
               <Link external to={x.link}>
                  {x.name}
               </Link>
            </li>,
         );
      });

      return links;
   };

   const renderReportLinks = (): readonly React.ReactNode[] => {
      const links: React.ReactNode[] = [];

      // Links from the attachments table
      reportLinks.forEach((reportLink) => {
         links.push(
            <li>
               <Link external to={reportLink.link}>
                  {reportLink.typeDisplayName} (.{reportLink.fileType})
               </Link>
            </li>,
         );
      });

      nationalExam.reportLinks?.forEach((x) => {
         links.push(
            <li key={x.link}>
               <Link external to={x.link}>
                  {x.name}
               </Link>
            </li>,
         );
      });

      return links;
   };

   const seatAssignmentColumns: readonly Column<NationalExamLevelSeatAllocation>[] = [
      { id: 'levelName', header: 'Level', cell: (i) => i.levelName },
      {
         id: 'seatsPurchased',
         header: 'Seats Purchased',
         cell: (i) => i.seatsPurchased,
      },
      { id: 'seatsUsed', header: 'Seats Used', cell: (i) => i.seatsUsed },
      {
         id: 'seatsRemaining',
         header: 'Seats Remaining',
         cell: (i) => i.seatsPurchased - i.seatsUsed,
      },
   ];

   const totalSeatsPurchased = _.sumBy(levels, 'seatsPurchased');

   const dashboardName = isFetching ? '' : `${nationalExam.name} Dashboard`;
   return (
      <>
         <DocumentTitle>{isFetching ? 'Loading Course...' : dashboardName}</DocumentTitle>
         <div className='row center-xs national-exam-dashboard'>
            <div
               className={classnames('col-xs-12 col-md-6 padding-left-s')}
               data-test='content-container-section'
            >
               <div className='card margin-top-m padding-bottom-s' style={{ minHeight: 250 }}>
                  <div className='card-title has-button full-width'>
                     <div className='flex align-items-center'>
                        <div className='title'>
                           {nationalExam.name} {pluralize('Course', clonedCourses.length)}
                        </div>
                     </div>
                  </div>
                  <div className='margin-top-m'>
                     <ul className='small black-urls'>
                        {clonedCourses.map((c) => (
                           <li data-test={`courses-${c.id}`} key={c.id}>
                              <Link to={`/courses/${c.id}`}>{c.name}</Link>
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
            </div>

            <div
               className={classnames('col-xs-12 col-md-6 padding-left-s')}
               data-test='content-container-section'
            >
               <div className='card margin-top-m padding-bottom-s' style={{ minHeight: 250 }}>
                  <div className='card-title has-button full-width'>
                     <div className='flex align-items-center'>
                        <div className='title'>Actions</div>
                     </div>
                  </div>
                  <div className='section-action-links margin-top-s'>
                     <ul className='actions-nav'>{renderActionLinks()}</ul>
                  </div>
               </div>
            </div>

            <div
               className={classnames('col-xs-12 col-md-6 padding-left-s')}
               data-test='content-container-section'
            >
               <div className='card margin-top-m padding-bottom-s'>
                  <div className='card-title has-button full-width'>
                     <div className='flex align-items-center'>
                        <div className='title'>Related Help Guides</div>
                     </div>
                  </div>
                  <div className='margin-top-m'>
                     <ul className='small black-urls'>
                        {renderSpecificHelpArticles()}
                        <li>
                           <Link
                              external
                              to='https://help.lingco.io/en/articles/1897690-adding-users-to-a-course'
                           >
                              Inviting students to your courses
                           </Link>
                        </li>
                        <li>
                           <Link
                              external
                              to='https://help.lingco.io/en/articles/5604593-previewing-course-material'
                           >
                              Preview material
                           </Link>
                        </li>
                        <li>
                           <Link
                              external
                              to='https://help.lingco.io/en/articles/5339466-viewing-student-grades'
                           >
                              View your students&apos; progress
                           </Link>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <div
               className={classnames('col-xs-12 col-md-6 padding-left-s')}
               data-test='content-container-section'
            >
               {totalSeatsPurchased > 0 && (
                  <div className='card margin-top-m padding-bottom-s no-body-padding'>
                     <div className='card-title has-button'>
                        <div className='flex align-items-center'>
                           <div className='title'>Seat Assignment</div>
                        </div>
                     </div>
                     <Table
                        columns={seatAssignmentColumns}
                        rows={levels}
                        rowKey='levelId'
                        defaultSortBy={[{ id: 'levelName', desc: false }]}
                     />
                  </div>
               )}
            </div>

            {reportLinks.length > 0 && (
               <>
                  <div
                     className='col-xs-12 col-md-6 padding-left-s'
                     data-test='content-container-section'
                  >
                     <div className='card margin-top-m padding-bottom-s'>
                        <div className='card-title has-button full-width'>
                           <div className='flex align-items-center'>
                              <div className='title'>Results</div>
                           </div>
                        </div>
                        <div className='margin-top-m'>
                           <div className='help-guide-links2'>
                              <ul className='actions-nav'>{renderReportLinks()}</ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     className='col-xs-12 col-md-6 padding-left-s'
                     data-test='content-container-section'
                  />
               </>
            )}
         </div>
      </>
   );
};

export default NationalExamDashboard;
