import { AuthConfig, authExchange, AuthUtilities } from '@urql/exchange-auth';
import { cacheExchange, Client, CombinedError, fetchExchange, Operation } from 'urql';

import Constants from '../Constants';
import AuthService from '@services/AuthService';

const handleAuthExchange = async (utils: AuthUtilities): Promise<AuthConfig> =>
   Promise.resolve({
      addAuthToOperation(operation: Operation): Operation {
         const accessToken = AuthService.getAccessToken();
         if (!accessToken) return operation;
         return utils.appendHeaders(operation, {
            Authorization: `Bearer ${accessToken}`,
         });
      },
      didAuthError(error: CombinedError, _operation: Operation): boolean {
         return error.response.status === Constants.statusCodes.unauthorized;
      },
      async refreshAuth(): Promise<void> {
         await AuthService.refreshAccessToken();
         return;
      },
   });

export const GraphqlClient = new Client({
   url: '/graphql',
   exchanges: [cacheExchange, authExchange(handleAuthExchange), fetchExchange],
});
