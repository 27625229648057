import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Appearance from '@models/Appearance';
import { Maybe, MessageResponse } from '@models/Core';
import { CourseProfile } from '@models/Course';
import SchoolProfile from '@models/SchoolProfile';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { AppStateContext } from '../../AppState';
import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import SchoolSelect from '@components/SchoolSelect';
import SectionCard from './SectionCard';

interface InstructorInvitationForm {
   email: string;
   courses: readonly {
      id: number;
      name: string;
      value: boolean;
   }[];
   school: Maybe<SchoolProfile>;
}

const InstructorInvitation: React.FC = () => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const {
      control,
      register,
      setError,
      clearErrors,
      handleSubmit,
      reset: resetForm,
      formState: { errors },
   } = useForm<InstructorInvitationForm>();
   const courseFields = useFieldArray({ control, name: 'courses' });
   const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);

   React.useEffect(() => {
      HttpService.getWithAuthToken<{ courses: CourseProfile[] }>('api/courses/templates').then(
         (response) => {
            const { courses } = response.data;
            courses.map((x) => courseFields.append({ value: false, ...x }));
            setIsFetching(false);
         },
      );
   }, []);

   const handleFormSubmit = (event: React.FormEvent): void => {
      event.preventDefault();
      clearErrors('courses');
      handleSubmit(requestCourseInvites)();
   };

   const requestCourseInvites = (formData: InstructorInvitationForm): Promise<void> => {
      const selectedCourseIds = formData.courses.filter((x) => x.value === true).map((x) => x.id);

      if (!selectedCourseIds.length) {
         setError('courses', { message: 'Please pick one' }, { shouldFocus: true });
         return Promise.resolve();
      }

      setIsSubmitting(true);

      const data = {
         email: formData.email,
         courseIds: selectedCourseIds,
         organizationId: formData.school?.id,
      };
      const url = 'api/courses/invite_instructor';
      return HttpService.postWithAuthToken<MessageResponse>(url, snakeCaseKeys(data))
         .then(() => {
            resetForm();
            dispatchToast({
               title: 'Invite Sent',
               message: 'Instructor will receive an email with instructions.',
               appearance: Appearance.success,
            });
         })
         .finally(() => {
            setIsSubmitting(false);
         });
   };

   if (isFetching) {
      return <Loader />;
   }

   return (
      <div className='content-main equal-margin instructor-one-time-code'>
         <DocumentTitle>Instructor Invitations</DocumentTitle>
         <div className='row center-xs'>
            <SectionCard
               title='Invite Instructor To Courses'
               primary={
                  <Button
                     loading={isSubmitting}
                     disabled={false}
                     form='invite-instructor'
                     type='submit'
                  >
                     Invite
                  </Button>
               }
            >
               <form onSubmit={handleFormSubmit} id='invite-instructor'>
                  <div className='row'>
                     <div className='col-xs-12 col-sm-7'>
                        <div>
                           <label className='field-title'>Email</label>
                           <input
                              {...register('email', { required: true })}
                              className={classnames({ error: errors.email })}
                              type='email'
                              autoComplete='off'
                           />
                        </div>
                        <div>
                           <label className='field-title'>Institution</label>
                           <Controller
                              rules={{ required: true }}
                              control={control}
                              name='school'
                              render={({ field }) => (
                                 <SchoolSelect
                                    name={field.name}
                                    value={field.value}
                                    className={classnames({
                                       error: errors.school,
                                    })}
                                    onChange={field.onChange}
                                 />
                              )}
                           />
                        </div>
                     </div>
                     <div className='col-xs-12 col-sm-5'>
                        <label className='field-title'>Courses</label>
                        <fieldset
                           className={classnames('form-group', {
                              error: errors.courses,
                           })}
                        >
                           {courseFields.fields.map((field, index) => (
                              <div key={field.id} className='course-option'>
                                 <input
                                    {...register(`courses.${index}.value`)}
                                    type='checkbox'
                                    id={`courses[${index}].value`}
                                 />
                                 <label htmlFor={`courses[${index}].value`}>
                                    <span>{field.name}</span>
                                 </label>
                              </div>
                           ))}
                        </fieldset>
                     </div>
                  </div>
               </form>
            </SectionCard>
         </div>
      </div>
   );
};

export default InstructorInvitation;
