import * as _ from 'lodash';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconMenuHelp from '@icons/general/icon-menu-help.svg';
import IconQuestionMarkChatBubble from '@icons/general/icon-question-mark-chat-bubble.svg';
import IconUserChat2 from '@icons/nova-line/07-Users/user-chat-2.svg';
import IconChatBubbleSquare3 from '@icons/nova-line/10-Messages&Chat/chat-bubble-square-3.svg';
import IconContentBookmark3 from '@icons/nova-line/18-Content/content-bookmark-3.svg';
import IconShareMegaphone1 from '@icons/nova-line/21-Share/share-megaphone-1.svg';
import IconMinimize from '@icons/nova-line/50-Applications/window-minimize.svg';
import IconHealthHeartPulse from '@icons/nova-line/75-Health/health-heart-pulse.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import AccountType from '@models/AccountType';
import { IApplicationState } from '@models/ApplicationState';
import IntercomService, { HelpLauncherEventType } from '@services/IntercomService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import { actionFunctions } from '@redux/Actions';
import Link from './Common/Link';

export enum HelpLauncherSize {
   small = 'small',
   medium = 'medium',
   large = 'large',
}

interface IHelpLauncherProps {
   className?: string;
}

const HelpLauncher: React.FC<IHelpLauncherProps> = ({ className = '' }) => {
   const {
      siteLinks: { statusPage, feedback, helpDocs, changelog },
   } = Constants;

   const verticalPaddings = {
      [HelpLauncherSize.small]: 65,
      [HelpLauncherSize.medium]: 75,
      [HelpLauncherSize.large]: 95,
   };

   const {
      userProfile,
      schoolProfile,
      loggedIn,
      helpLauncherSize: size,
      isImpersonating,
   } = React.useContext<AppStateContext>(AppStateContext);
   const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
   const [isIntercomOpen, setIsIntercomOpen] = React.useState<boolean>(false);
   const [intercomUnreadCount, setIntercomUnreadCount] = React.useState<number>(0);
   const [isBooted, setIsBooted] = React.useState<boolean>(false);
   const [studentIntercomIdentified, setStudentIntercomIdentified] = React.useState<boolean>(false);
   const [minimized, setMinimized] = React.useState<string>('');

   const hideHelpFab = useSelector<IApplicationState, boolean>((state) => state.hideHelpFab);

   const dispatch = useDispatch();

   const isInstructor = userProfile?.accountType === AccountType.instructor;

   const handleClick = (): void => {
      setIsMenuOpen(false);
   };

   // since the IntercomService listeners are registered after
   // the initial boot happens, we'll catch that initial boot here
   React.useEffect(() => {
      if (IntercomService.isBooted()) {
         setIsBooted(true);
      }
   }, [IntercomService.isBooted()]);

   // When a user logs out and back in we reboot our chat tool. We'll
   // capture that reboot event here so we can reattach any relevant event
   // handlers for the 3rd-party chat tool
   React.useEffect(() => {
      IntercomService.addEventListener(HelpLauncherEventType.Started, () => setIsBooted(true));
      IntercomService.addEventListener(HelpLauncherEventType.Stopped, () => setIsBooted(false));
   }, []);

   // We'll close our custom launcher when users click away
   React.useEffect(() => {
      if (isMenuOpen) {
         document.addEventListener('click', handleClick);
      } else {
         document.removeEventListener('click', handleClick);
      }
      return () => {
         document.removeEventListener('click', handleClick);
      };
   }, [isMenuOpen]);

   React.useEffect(() => {
      if (!(isIntercomOpen && loggedIn)) {
         setIsMenuOpen(false);
      }
      if (isIntercomOpen && !loggedIn) {
         setIsIntercomOpen(false);
      }
   }, [isIntercomOpen, loggedIn]);

   React.useEffect(() => {
      window.Intercom('onShow', () => setIsIntercomOpen(true));
      window.Intercom('onHide', () => setIsIntercomOpen(false));
      window.Intercom('onUnreadCountChange', (count: number) => setIntercomUnreadCount(count));
      setMinimizedHelp();
   }, []);

   React.useEffect(() => {
      if (isBooted) {
         window.Intercom('onShow', () => {
            setIsIntercomOpen(true);
         });
         window.Intercom('onHide', () => {
            setIsIntercomOpen(false);
         });
         window.Intercom('onUnreadCountChange', (count: number) => setIntercomUnreadCount(count));
      }
   }, [isBooted]);

   React.useEffect(() => {
      if (size && isBooted && loggedIn) {
         window.Intercom?.(
            'update',
            snakeCaseKeys({
               customLauncherSelector: '#live-chat-launcher',
               hideDefaultLauncher: true,
               verticalPadding: verticalPaddings[size],
            }),
         );
      }
   }, [size, isBooted, loggedIn]);

   const handleWidgetClick = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      identifyIntercomStudent();
      if (loggedIn && !intercomUnreadCount) {
         setIsMenuOpen(true);
      } else {
         window.Intercom?.('show');
      }
   };

   const identifyIntercomStudent = (): void => {
      if (loggedIn && userProfile && !isInstructor && !studentIntercomIdentified) {
         UserService.identifyIntercom(userProfile, schoolProfile);
         setStudentIntercomIdentified(true);
      }
   };

   const closeMenu = (): void => {
      setIsMenuOpen(false);
   };

   React.useEffect(() => {
      setMinimizedHelp();
   }, [hideHelpFab]);

   const setMinimizedHelp = (): void => {
      setMinimized(hideHelpFab ? 'minimized' : '');
   };

   const chatString = isInstructor ? 'Chat with us' : 'Customer Support';

   if (isIntercomOpen || isImpersonating) {
      return null;
   }

   return createPortal(
      <div
         className={classnames('help-launcher-wrapper', className, size, !isMenuOpen && minimized)}
      >
         {isMenuOpen ? (
            <div className='card'>
               <div className='card-title'>
                  <div className='title'>
                     Chat and Help
                     <IconMenuHelp aria-hidden />
                  </div>
                  <div className='close' aria-label='Close Menu'>
                     <IconClose onClick={closeMenu} />
                  </div>
               </div>
               <div className='help-item' onClick={() => dispatch(actionFunctions.hideHelpFab())}>
                  <IconMinimize aria-hidden />
                  {minimized === '' ? 'Hide' : 'Show'} Help Menu
               </div>
               <Link external to={helpDocs}>
                  <div className='help-item'>
                     <IconContentBookmark3 aria-hidden />
                     Help & Support Guides
                  </div>
               </Link>
               <Link external to={statusPage}>
                  <div className='help-item'>
                     <IconHealthHeartPulse aria-hidden />
                     Application Status
                  </div>
               </Link>
               {userProfile?.accountType === AccountType.instructor && (
                  <>
                     <Link external to={changelog}>
                        <div className='help-item'>
                           <IconShareMegaphone1 aria-hidden />
                           What&apos;s New?
                        </div>
                     </Link>
                     <Link external to={feedback}>
                        <div className='help-item'>
                           <IconChatBubbleSquare3 aria-hidden />
                           Feedback
                        </div>
                     </Link>
                  </>
               )}
               <div
                  className={classnames('help-item', {
                     featured: isInstructor,
                  })}
                  id='live-chat-launcher'
               >
                  <IconUserChat2 aria-hidden />
                  {chatString}
               </div>
            </div>
         ) : (
            <div className='help-launcher' onClick={handleWidgetClick}>
               {!!intercomUnreadCount && <div className='badge'>{intercomUnreadCount}</div>}
               <IconQuestionMarkChatBubble aria-hidden />
            </div>
         )}
      </div>,
      document.body,
   );
};

export default HelpLauncher;
