import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Droplist from '@components/Core/Droplist';
import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import FeatureCheck from '@components/Core/FeatureCheck';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import { ProgressBar } from '@components/Core/ProgressBar';
import {
   createFilter,
   OnChangeValue,
   Select,
   selectComponents,
   selectTheme,
} from '@components/Core/Select';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import useCommands from '@hooks/use-commands';
import usePrevious from '@hooks/use-previous';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconSidebarClose from '@icons/general/icon-sidebar-close.svg';
import IconSettings from '@icons/general/settings.svg'; // Line Cog Box
import IconDataUpload8 from '@icons/nova-line/23-Data-Transfer/data-upload-8.svg';
import IconFolderAdd from '@icons/nova-line/86-Files-Folders/folder-add.svg';
import {
   ActivityCollapsedRubricEntry,
   ActivityMode,
   ActivityPrompt,
   ActivityResponseEvaluation,
   ActivityRubric,
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGroup,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { Editor as TinyMCEEditor } from 'tinymce';

import ImportRubricModal from './ImportRubricModal';
import RubricEntries from './RubricEntries';
import RubricSettingsModal from './RubricSettingsModal';
import SidebarProficiency from './SidebarProficiency';

interface CommentOption {
   value: string | null;
   label: React.ReactNode | null;
}

interface ActivityGraderSidebarProps {
   courseId: number;
   evaluation: ActivityResponseEvaluation<ActivityMode.grade>;
   evaluationsGraded: number;
   expandedRubricItemGroupId: Maybe<number>;
   language: Language;
   moduleItemId: number;
   previousComments: readonly string[];
   prompt: Maybe<ActivityPrompt<ActivityMode.grade>>;
   promptId: Maybe<number>;
   responsesCount: number;
   rubric: ActivityRubric;
   score: Maybe<string>;
   createRubricItem(data?: Partial<ActivityRubricItem>, focus?: boolean): Promise<number>;
   createRubricItemGroup(data?: Partial<ActivityRubricItemGroup>, focus?: boolean): Promise<number>;
   deleteRubricItem(itemId: number): void;
   deleteRubricItemGroup(groupId: number): void;
   renderPromptList(): React.ReactNode;
   toggleRubricItem(itemId: number): void;
   toggleRubricItemGroup(groupId: number): void;
   toggleSidebarOpen(): void;
   updateEvaluation(update: Partial<ActivityResponseEvaluation<ActivityMode.grade>>): void;
   updateRubric(update: Partial<ActivityRubric>, callback?: () => void): void;
   updateRubricEntries(updates: {
      [ActivityRubricEntryType.item]: Record<number, Partial<ActivityRubricItem>>;
      [ActivityRubricEntryType.group]: Record<number, Partial<ActivityRubricItemGroup>>;
   }): void;
   updateRubricEntryPositions(rubricEntries: readonly ActivityCollapsedRubricEntry[]): void;
   updateRubricItem(itemId: number, update: Partial<ActivityRubricItem>): void;
   updateRubricItemGroup(groupId: number, update: Partial<ActivityRubricItemGroup>): void;
}

const ActivtyGraderSidebar = (props: ActivityGraderSidebarProps) => {
   const [isImportModalOpen, setIsImportModalOpen] = React.useState<boolean>(false);
   const [isSettingsModalOpen, setIsSettingsModalOpen] = React.useState<boolean>(false);
   const [isSidebarCompact, setIsSidebarCompact] = React.useState<boolean>(false);

   useCommands(
      [
         {
            id: 'open_import_rubric_modal',
            title: 'Import Rubric',
            action: () => setIsImportModalOpen(true),
         },
         {
            id: 'open_rubric_settings_modal',
            title: 'Rubric Settings',
            action: () => setIsSettingsModalOpen(true),
         },
      ],
      [],
   );

   const editor = React.useRef<TinyMCEEditor | null>(null);
   const prevEvaluationId = usePrevious(props.evaluation.id);

   React.useEffect(() => {
      if (props.evaluation.id !== prevEvaluationId && editor.current) {
         editor.current.setContent(props.evaluation.comments);
      }
   }, [props.evaluation.id, prevEvaluationId]);

   const getPreviousCommentsOptions = (): readonly CommentOption[] =>
      props.previousComments.map((i) => ({
         value: i,
         label: ensureElement(parseHtml(i) as React.ReactNode, 'p'),
      }));

   const getRubricEntries = (rubric?: ActivityRubric): readonly ActivityCollapsedRubricEntry[] => {
      const { items, groups } = rubric || props.rubric;
      const groupedItems = _.groupBy(items, 'groupId');
      const result = _.orderBy(
         [
            ...(groupedItems.null || []),
            ...groups.map((i) => ({
               ...i,
               rubricItems: _.orderBy(groupedItems[i.id] || [], ['index'], ['asc']),
            })),
         ],
         ['index'],
         ['asc'],
      );
      return result;
   };

   const handleAddRubricItem = (): void => {
      const rubricEntries = getRubricEntries();
      props.createRubricItem({ index: rubricEntries.length });
   };

   const handleCreateGroup = (): void => {
      const rubricEntries = getRubricEntries();
      props.createRubricItemGroup({ index: rubricEntries.length }, true).then((groupId) => {
         props.createRubricItem({ index: 0, groupId }, false);
         props.toggleRubricItemGroup(groupId);
      });
   };

   const handlePointAdjustmentChange = (pointAdjustment: number): void => {
      props.updateEvaluation({ pointAdjustment: pointAdjustment || null });
   };

   const handlePreviousCommentsChange = (newValue: OnChangeValue<CommentOption, false>): void => {
      if (!newValue?.value) {
         return;
      }
      const { value } = newValue;
      const { comments } = props.evaluation;
      const updatedComments = comments ? `${comments}\n${value}` : value;
      props.updateEvaluation({ comments: updatedComments });
      editor.current?.setContent(updatedComments);
      editor.current?.focus();
      editor.current?.selection.select(editor.current?.getBody(), true);
      editor.current?.selection.collapse(false);
   };

   const handleRubricImport = (rubricId: number, callback?: () => void): void => {
      HttpService.postWithAuthToken<ActivityRubric>(
         `/api/activities/rubrics/${rubricId}/clone`,
         snakeCaseKeys({ recipientRubricId: props.rubric.id }),
      ).then((response) => {
         const rubric = response.data;
         const { ceiling, floor, scoringType } = rubric;
         const updatedRubricEntries = [...getRubricEntries(rubric)];
         props.updateRubricEntryPositions(updatedRubricEntries);
         props.updateRubric(
            {
               ceiling,
               floor,
               scoringType,
            },
            callback,
         );
      });
   };

   const openImportModal = (): void => {
      setIsImportModalOpen(true);
   };

   const closeImportModal = (): void => {
      setIsImportModalOpen(false);
   };

   const openSettingsModal = () => {
      setIsSettingsModalOpen(true);
   };

   const closeSettingsModal = () => {
      setIsSettingsModalOpen(false);
   };

   const toggleSidebarCompact = (): void => {
      setIsSidebarCompact((prevIsSidebarCompact) => !prevIsSidebarCompact);
   };

   const getPointAdjustmentClassName = (value: number): string =>
      value && value <= 0 ? 'deduction' : '';

   const proficiencyChange = (isProficient: Maybe<boolean>): void => {
      props.updateEvaluation({ isProficient });
   };

   const {
      courseId,
      language,
      evaluation,
      evaluationsGraded,
      expandedRubricItemGroupId,
      moduleItemId,
      prompt,
      promptId,
      responsesCount,
      rubric,
      score,
      createRubricItem,
      deleteRubricItem,
      deleteRubricItemGroup,
      renderPromptList,
      toggleRubricItem,
      toggleRubricItemGroup,
      toggleSidebarOpen,
      updateEvaluation,
      updateRubric,
      updateRubricEntries,
      updateRubricItem,
      updateRubricItemGroup,
   } = props;

   const { weight } = rubric;
   const { graded } = evaluation;
   const points = graded ? score : '—';

   const droplistItems = [
      <div onClick={handleCreateGroup} className='rubric-dropdown-button' key='create-group'>
         <IconFolderAdd />
         Create Group
      </div>,
      <div onClick={openImportModal} className='rubric-dropdown-button' key='import-rubric'>
         <IconDataUpload8 />
         Import Rubric
      </div>,
   ];

   return (
      <>
         <div className='right-sidebar grader-view' data-tour='grader-sidebar'>
            {!isSidebarCompact && (
               <>
                  <div className='card-title'>
                     <div className='sidebar-toggle-grader close' onClick={toggleSidebarOpen}>
                        <IconSidebarClose />
                     </div>
                     <div>{renderPromptList()}</div>
                  </div>
                  <div
                     className='grader-sidebar-row progress-bar-section'
                     data-tour='grader-progress'
                  >
                     <ProgressBar
                        percentage={Math.floor((evaluationsGraded / responsesCount) * 100)}
                        color='green'
                     />
                     <label>
                        {evaluationsGraded} of {responsesCount} Graded
                     </label>
                  </div>
               </>
            )}
            <div className='grader-sidebar-row grader-total-points'>
               <label>Total Points</label>
               <label
                  className={classnames('collapse-grader-view', { collapsed: isSidebarCompact })}
                  onClick={toggleSidebarCompact}
               >
                  {isSidebarCompact ? 'expand' : 'collapse'} view
               </label>
               <div className='rubric-settings-btn'>
                  <Button
                     subtle
                     icon={<IconSettings aria-label='Settings' />}
                     onClick={openSettingsModal}
                  >
                     {!isSidebarCompact && 'Settings'}
                  </Button>
               </div>
               <div className='points-wrap'>
                  <div className={classnames('student-point', { graded })}>{points}</div>
                  <div className='points-available'>/{weight}</div>
                  <p>pts</p>
               </div>
            </div>
            <div className='rubric-items-adjustment-container'>
               <RubricEntries
                  evaluation={evaluation}
                  expandedRubricItemGroupId={expandedRubricItemGroupId}
                  rubricEntries={getRubricEntries()}
                  scoringType={rubric.scoringType}
                  createRubricItem={createRubricItem}
                  deleteRubricItem={deleteRubricItem}
                  deleteRubricItemGroup={deleteRubricItemGroup}
                  handleRubricImport={handleRubricImport}
                  toggleRubricItem={toggleRubricItem}
                  toggleRubricItemGroup={toggleRubricItemGroup}
                  updateRubricEntries={updateRubricEntries}
                  updateRubricItem={updateRubricItem}
                  updateRubricItemGroup={updateRubricItemGroup}
               />
               <div
                  className='grader-sidebar-row rubric-buttons'
                  data-tour='grader-add-rubric-entry'
               >
                  <Button line onClick={handleAddRubricItem} className='add-rubric-button'>
                     <IconAddSmall className='icon-gray' />
                     Add Rubric Item
                  </Button>
                  <Droplist pullRight items={droplistItems}>
                     <Button line className='dropdown-button'>
                        <IconArrowDown />
                     </Button>
                  </Droplist>
               </div>
               {prompt?.canDoStatementName && (
                  <FeatureCheck feature='proficiency_tracking'>
                     <div className='grader-sidebar-row'>
                        <SidebarProficiency
                           evaluation={evaluation}
                           onChange={proficiencyChange}
                           prompt={prompt}
                        />
                     </div>
                  </FeatureCheck>
               )}
               <div
                  className='submission-adjustment grader-sidebar-row'
                  data-tour='submission-adjustments'
               >
                  <div className='point-adjustment'>
                     <div className='row'>
                        <div className='col-xs-12 col-lg-6'>
                           <label>Point Adjustment</label>
                        </div>
                        <div className='col-xs-12 col-lg-6'>
                           <NumberInput
                              name='pointAdjustment'
                              forceSign
                              value={evaluation.pointAdjustment}
                              className={getPointAdjustmentClassName}
                              title="Any adjustment that doesn't fit into the above rubric (can be signed, e.g. +5, -3.5)"
                              onValueChange={handlePointAdjustmentChange}
                           />
                        </div>
                     </div>
                  </div>
                  <div className='response-comments'>
                     <label>Response-Specific Comments</label>
                     <Editor
                        language={language}
                        config={{
                           contextmenu: 'upload-file',
                           toolbar:
                              'bold italic underline strikethrough | forecolor backcolor | quicklink',
                        }}
                        editorRef={(e) => (editor.current = e)}
                        onChange={(value) => updateEvaluation({ comments: value })}
                        toolbarAppearance={ToolbarAppearance.floating}
                        value={evaluation.comments}
                     />
                  </div>
                  <div className='previous-comment'>
                     <label>Apply Previously Used Comments</label>
                     <Select<CommentOption>
                        className='react-select'
                        components={selectComponents}
                        filterOption={createFilter({
                           ignoreCase: true,
                           stringify: ({ value }) => value.replace(/<[^>]+>/g, ''),
                        })}
                        noOptionsMessage={() => 'No results found'}
                        onChange={handlePreviousCommentsChange}
                        options={getPreviousCommentsOptions()}
                        placeholder=''
                        theme={selectTheme}
                        value={{ value: null, label: null }}
                     />
                  </div>
               </div>
            </div>
         </div>
         {isSettingsModalOpen && (
            <RubricSettingsModal
               rubric={rubric}
               closeSettingsModal={closeSettingsModal}
               updateRubric={updateRubric}
            />
         )}
         {isImportModalOpen && promptId && (
            <ImportRubricModal
               currentCourseId={courseId}
               currentModuleItemId={moduleItemId}
               currentPromptId={promptId}
               importRubric={handleRubricImport}
               closeImportModal={closeImportModal}
            />
         )}
      </>
   );
};

export default ActivtyGraderSidebar;
