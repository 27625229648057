import { Maybe } from '@models/Core';

export const guessMimeType = (url: string): string | undefined => {
   const extension = url.split('.').pop()?.toLowerCase();
   if (!extension) {
      console.warn(`Cannot guess mime type for URL ${url}: no file extension found`);
      return undefined;
   }

   const mimeTypes: Record<string, string> = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      svg: 'image/svg+xml',
      webp: 'image/webp',
      pdf: 'application/pdf',
      mp3: 'audio/mpeg',
      wav: 'audio/wav',
      ogg: 'audio/ogg',
      mp4: 'video/mp4',
      webm: 'video/webm',
   };

   const mimeType = mimeTypes[extension];
   if (!mimeType) {
      console.warn(`Unknown file extension ${extension} in URL ${url}`);
      return undefined;
   }

   return mimeType;
};

export const getVideoDimensions = (file: File): Promise<{ width: number; height: number }> => {
   const video = document.createElement('video');
   video.preload = 'metadata';
   video.src = URL.createObjectURL(file);

   return new Promise((resolve, reject) => {
      video.onloadedmetadata = () => {
         const dimensions = { width: video.videoWidth, height: video.videoHeight };
         URL.revokeObjectURL(video.src);
         resolve(dimensions);
      };
      video.onerror = () => {
         reject(new Error('Failed to load video file.'));
      };
   });
};

export const isYoutubeVideo = (url: string) => {
   // Regular expression to match YouTube video URLs
   const youtubeRegex =
      /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]+(&\S+)?|^https?:\/\/youtu\.be\/[\w-]+/;

   // Return true if the URL matches the regex, false otherwise
   return youtubeRegex.test(url);
};

export const extractYoutubeVideoId = (url: Maybe<string>): string | undefined => {
   // Regular expression to match YouTube video URLs
   const youtubeRegex =
      /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([\w-]+)(&\S+)?|^https?:\/\/youtu\.be\/([\w-]+)/;

   // Use the match() method to extract the video ID from the URL
   const match = url ? url.match(youtubeRegex) : null;

   // If there's a match, return the video ID
   if (match) {
      return match[3] || match[5];
   }

   // If there's no match, return undefined
   return undefined;
};
