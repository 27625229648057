import HttpService from '@services/HttpService';

import { LandingPageContent } from '@models/LandingPageContent';

const getLandingPageContent = (urlSlug: string): Promise<LandingPageContent> =>
   HttpService.get<LandingPageContent>(`/api/landing/${urlSlug}`).then((response) => response.data);

export default {
   getLandingPageContent,
};
