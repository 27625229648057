// @ts-strict-ignore
import * as React from 'react';

import autobind from '@helpers/autobind';
import { isInteger } from '@helpers/NumberUtils';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import { mergeRefs } from '@utilities/Refs';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import Textarea from 'react-textarea-autosize';
import { CSSTransition } from 'react-transition-group';

import Constants from '../Constants';
import Button from './Common/Button';

type ILanguageKeyMapping = Record<string, readonly string[]>;

interface AccentTextboxProps extends React.HTMLProps<HTMLInputElement> {
   /** Autosize if textarea */
   autoHeight?: boolean;
   language: Maybe<Language>;
   small?: boolean;
   tag?: 'input' | 'textarea';
   value: string;
   /** Selector for automated testing */
   'data-test'?: string;
   inputRef?(ref: HTMLInputElement): void;
   onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
   onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
}

interface AccentTextboxState {
   showPicker: boolean;
   characters: readonly string[];
   lastKeypress: string;
   buttonFocusIndex: number;
   scrollPositionOnOpen: number;
}

class AccentTextbox extends React.Component<AccentTextboxProps, AccentTextboxState> {
   static getCursorIndex(input: HTMLInputElement): number {
      return input.selectionStart || input.selectionStart.toString() === '0'
         ? input.selectionStart
         : 0;
   }

   static getMappings(): Record<string, ILanguageKeyMapping> {
      return {
         de: {
            a: ['ä'],
            e: ['é'],
            o: ['ö'],
            s: ['ß'],
            u: ['ü'],
         },
         es: {
            a: ['á'],
            e: ['é'],
            i: ['í'],
            o: ['ó'],
            n: ['ñ'],
            u: ['ú', 'ü'],
            '/': ['¿'],
         },
         fr: {
            a: ['à', 'â', 'æ'],
            c: ['ç'],
            e: ['è', 'é', 'ê', 'ë'],
            i: ['î', 'ï'],
            o: ['ô', 'œ'],
            u: ['û', 'ü', 'ù'],
         },
         it: {
            a: ['à'],
            e: ['è', 'é'],
            i: ['ì'],
            o: ['ò', 'ó'],
            u: ['ù'],
         },
         pt: {
            a: ['á', 'â', 'ã', 'à'],
            c: ['ç'],
            e: ['é', 'ê'],
            i: ['í'],
            o: ['ó', 'ô', 'õ'],
            u: ['ú'],
         },
      };
   }

   myInput: React.RefObject<HTMLInputElement>;
   containerRef: React.RefObject<HTMLDivElement>;
   timer: NodeJS.Timeout;

   constructor(props: AccentTextboxProps) {
      super(props);
      autobind(this);

      this.timer = null;
      this.myInput = React.createRef<HTMLInputElement>();
      this.containerRef = React.createRef<HTMLDivElement>();

      this.state = {
         buttonFocusIndex: 0,
         characters: [],
         lastKeypress: undefined,
         scrollPositionOnOpen: null,
         showPicker: false,
      };
   }

   getInputRef = () => this.myInput.current;

   componentDidMount(): void {
      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('keypress', this.handleKeyPress);
      document.addEventListener('keyup', this.reset);
      document.addEventListener('click', this.handleOnClickOutside);
      if (this.props.autoFocus) {
         this.myInput.current.focus();
      }
   }

   componentDidUpdate(prevProps: AccentTextboxProps): void {
      if (this.props.value !== prevProps.value) {
         this.myInput.current.value = this.props.value;
      }
   }

   componentWillUnmount(): void {
      document.removeEventListener('keydown', this.handleKeyDown);
      document.removeEventListener('keypress', this.handleKeyPress);
      document.removeEventListener('keyup', this.reset);
      document.removeEventListener('click', this.handleOnClickOutside);
   }

   // Workaround to ensure that onChange is called anytime the value changes
   fireFakeOnChange(): void {
      const fakeEvent = { target: { value: this.myInput.current.value } };
      this.triggerOnChangeIfChanged(fakeEvent);
   }

   getLanguageMapping(): ILanguageKeyMapping {
      return AccentTextbox.getMappings()[this.props.language];
   }

   // Is the user just trying to type a different character?
   isOtherKey(typed: string): boolean {
      return !(typed.toLowerCase() in this.getLanguageMapping());
   }

   triggerOnChangeIfChanged(event): void {
      const newValue = event.target.value;
      if (newValue !== this.props.value) {
         this.props.onChange(event);
      }
   }

   handleOnClickOutside(event: MouseEvent): void {
      const target = event.target as Element;
      if (this.state.showPicker && !this.containerRef?.current.contains(target)) {
         this.hidePicker();
      }
   }

   // keyPress event gives case-senstive mappings, but only keyDown
   // tracks things like escape and enter.
   handleKeyPress(event: KeyboardEvent): void {
      if (!this.state.showPicker) {
         return;
      }
      if (this.isOtherKey(event.key)) {
         this.hidePicker();
         this.insertCharacter(event.key, { insertBehind: false });
      }
   }

   handleKeyDown(event: KeyboardEvent): void {
      const { buttonFocusIndex, characters, showPicker } = this.state;
      const {
         keys: { escape, enter, left, right },
      } = Constants;
      if (!showPicker) {
         return;
      }
      if (isInteger(event.key)) {
         this.handleNumberKeys(event);
      }
      if (event.key === enter) {
         this.handleEnter(event);
      }
      if (event.key === escape) {
         this.hidePicker();
      }
      if (event.key === left && buttonFocusIndex > 0) {
         this.setState((prevState) => ({
            buttonFocusIndex: prevState.buttonFocusIndex - 1,
         }));
      }
      if (event.key === right && buttonFocusIndex < characters.length - 1) {
         this.setState((prevState) => ({
            buttonFocusIndex: prevState.buttonFocusIndex + 1,
         }));
      }
   }

   hidePicker(): void {
      this.setState({ showPicker: false });
   }

   ensureCharsEntered(event): void {
      if (event.target.value.length === 0) {
         this.hidePicker();
      }
      this.triggerOnChangeIfChanged(event);
   }

   reset(): void {
      this.timer = null;
      clearTimeout(this.timer);
      this.setState({
         lastKeypress: null,
      });
   }

   insertCharacter(char: string, opts): void {
      const input = this.myInput.current;
      const cursorPosition = AccentTextbox.getCursorIndex(input);
      const text = input.value.split('');
      // Default to inserting behind the cursor
      if (!opts) {
         text[cursorPosition - 1] = char;
      }
      input.value = text.join('');
      input.focus();
      input.setSelectionRange(cursorPosition, cursorPosition);
      this.hidePicker();
   }

   handleEnter(event: KeyboardEvent): void {
      event.preventDefault();
      const currentCharacter = this.state.characters[this.state.buttonFocusIndex];
      this.insertCharacter(currentCharacter, null);
   }

   handleNumberKeys(event: KeyboardEvent): void {
      const allNumberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
      const buttonIndex = allNumberKeys.indexOf(event.key);
      if (buttonIndex < this.state.characters.length) {
         event.preventDefault();

         this.insertCharacter(this.state.characters[buttonIndex], null);
      }
   }

   scrollBack(): void {
      window.scrollTo(0, this.state.scrollPositionOnOpen);
   }

   showPicker(event: React.KeyboardEvent): void {
      const {
         keys: { backspace, left, right },
      } = Constants;
      const { lastKeypress } = this.state;
      if (event.key === left) {
         this.setState((prevState) => ({
            buttonFocusIndex: prevState.buttonFocusIndex + 1,
         }));
         return;
      }
      if (event.key === right) {
         this.setState((prevState) => ({
            buttonFocusIndex: prevState.buttonFocusIndex - 1,
         }));
         return;
      }
      if (event.key === backspace) {
         this.hidePicker();
         this.reset();
         return;
      }
      const target = event.target as HTMLInputElement;
      if (event.key === lastKeypress) {
         event.preventDefault();
         if (!this.timer) {
            this.timer = setTimeout(() => {
               const typedChar = target.value.split('')[AccentTextbox.getCursorIndex(target) - 1];
               // Cannot read property 'toLowerCase' of undefined fix
               if (typedChar === undefined) {
                  this.hidePicker();
                  this.reset();
                  return;
               }
               const charCase = typedChar.toLowerCase() === typedChar ? 'lower' : 'upper';
               const caseFunction = charCase === 'lower' ? 'toLowerCase' : 'toUpperCase';
               const typedCharAsLower = typedChar.toLowerCase();
               if (this.getLanguageMapping()[typedCharAsLower]) {
                  const characters = this.getLanguageMapping()[typedCharAsLower].map((letter) =>
                     String.prototype[caseFunction].call(letter),
                  );
                  const scrollPos = window.scrollY;
                  this.setState({
                     showPicker: true,
                     characters,
                     buttonFocusIndex: 0,
                     scrollPositionOnOpen: scrollPos,
                  });
                  this.scrollBack();
               } else {
                  this.hidePicker();
               }
            }, 10);
         }
         return;
      }
      this.setState({ lastKeypress: event.key });
   }

   handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
      this.showPicker(event);
      if (this.props.onKeyDown) {
         event.persist();
         this.props.onKeyDown(event);
      }
   }

   handleCharClick(char: string, opts): void {
      this.insertCharacter(char, opts);
      this.fireFakeOnChange();
   }

   renderInputElement(ref: React.Ref<HTMLInputElement>, inputProps): React.ReactNode {
      const { tag = 'input', inputRef, autoHeight = true } = this.props;
      const sharedProps = {
         ...inputProps,
         ref: mergeRefs(ref, this.myInput, inputRef),
         onKeyDown: this.handleInputKeyDown,
         onKeyUp: this.ensureCharsEntered,
         onChange: this.triggerOnChangeIfChanged,
      };
      if (tag === 'textarea' && autoHeight) {
         return <Textarea {...sharedProps} minRows={5} />;
      } else if (tag === 'textarea' && !autoHeight) {
         return <textarea {...sharedProps} />;
      }
      return <input {...sharedProps} />;
   }

   render(): React.ReactNode {
      const {
         className,
         dir = 'auto',
         placeholder = '',
         language,
         type = 'text',
         tag,
         value,
         small,
         autoHeight,
         ...rest
      } = this.props;
      const { buttonFocusIndex, characters, showPicker } = this.state;

      const inputProps = {
         ...rest,
         type,
         className,
         defaultValue: value,
         placeholder,
         dir,
      };

      if (!(language in AccentTextbox.getMappings())) {
         return this.renderInputElement(null, inputProps);
      }

      return (
         <Manager>
            <Reference>{({ ref }) => this.renderInputElement(ref, inputProps)}</Reference>
            {createPortal(
               <CSSTransition
                  in={showPicker}
                  unmountOnExit
                  timeout={300}
                  classNames='accent-textbox-container'
               >
                  <Popper
                     placement='top'
                     modifiers={[
                        {
                           name: 'offset',
                           options: {
                              offset: small ? [0, 7] : [0, 20],
                           },
                        },
                     ]}
                  >
                     {({ ref, style, placement, arrowProps }) => (
                        <div
                           ref={mergeRefs(ref, this.containerRef)}
                           style={style}
                           className='accent-textbox-container'
                        >
                           <ul
                              className={classnames('accent-textbox-buttons', 'ignore-click', {
                                 small,
                              })}
                           >
                              {characters.map((char, index) => (
                                 <li key={char}>
                                    <Button
                                       className={`ignore-click ${
                                          buttonFocusIndex === index ? 'focused' : ''
                                       }`}
                                       autoFocus={index === 0}
                                       onClick={() => this.handleCharClick(char, null)}
                                    >
                                       <span className={classnames('ignore-click', { small })}>
                                          {char}
                                       </span>
                                       <span className={classnames('ignore-click', { small })}>
                                          {index + 1}
                                       </span>
                                    </Button>
                                 </li>
                              ))}
                           </ul>
                           <div
                              className='popover-arrow'
                              ref={arrowProps.ref}
                              style={arrowProps.style}
                              data-popper-placement={placement}
                           />
                        </div>
                     )}
                  </Popper>
               </CSSTransition>,
               document.body,
            )}
         </Manager>
      );
   }
}

export default AccentTextbox;
