// @ts-strict-ignore
import * as React from 'react';

import { Maybe } from '@models/Core';
import { createPortal } from 'react-dom';

interface INewWindowProps {
   center?: 'parent' | 'screen';
   children: React.ReactNode;
   copyStyles?: boolean;
   features?: React.CSSProperties;
   name?: string;
   title?: string;
   url: string;
   onBlock?(): void;
   onOpen?(window: Window): void;
   onUnload?(): void;
}

interface INewWindowState {
   mounted: boolean;
}

class NewWindow extends React.PureComponent<INewWindowProps, INewWindowState> {
   static defaultProps = {
      url: '',
      name: '',
      title: '',
      features: { width: '600px', height: '640px' },
      onBlock: null,
      onOpen: null,
      onUnload: null,
      center: 'parent',
      copyStyles: true,
   };

   container: Maybe<HTMLElement>;
   window: Maybe<Window>;
   windowCheckerInterval: Maybe<NodeJS.Timeout>;
   released: boolean;

   constructor(props: INewWindowProps) {
      super(props);
      this.container = null;
      this.window = null;
      this.windowCheckerInterval = null;
      this.released = false;
      this.state = {
         mounted: false,
      };
   }

   /**
    * Render the NewWindow component.
    */
   render() {
      if (!this.state.mounted || !this.props.children || !this.container) {
         return null;
      }
      return createPortal(this.props.children, this.container);
   }

   componentDidMount(): void {
      this.openChild();
      this.setState({ mounted: true });
   }

   positionWindow(): void {
      const { features, center } = this.props;
      if (!features || !window.top) {
         return;
      }
      if (
         typeof center === 'string' &&
         (features.width === undefined || features.height === undefined)
      ) {
         console.warn(
            'width and height window features must be present when a center prop is provided',
         );
      } else if (center === 'parent') {
         features.left = window.top.outerWidth / 2 + window.screenX - Number(features.width) / 2;
         features.top = window.top.outerHeight / 2 + window.screenY - Number(features.height) / 2;
      } else if (center === 'screen') {
         const screenLeft = window.screenX;
         const screenTop = window.screenY;
         const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width;
         const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;
         features.left = width / 2 - Number(features.width) / 2 + screenLeft;
         features.top = height / 2 - Number(features.height) / 2 + screenTop;
      }
   }

   openWindow(): void {
      const { url, title, name, features, onBlock, onOpen } = this.props;

      this.window = window.open(url, name, toWindowFeatures(features));
      if (this.window && title) {
         this.window.document.title = title;
         this.container = this.window.document.createElement('div');
         this.window.document.body.appendChild(this.container);

         // If specified, copy styles from parent window's document.
         if (this.props.copyStyles && this.window.document) {
            setTimeout(() => copyStyles(document, this.window.document), 0);
         }

         if (typeof onOpen === 'function') {
            onOpen(this.window);
         }

         // Release anything bound to this component before the new window unload.
         this.window.addEventListener('beforeunload', () => this.release());
      } else {
         // Handle error on opening of new window.
         if (typeof onBlock === 'function') {
            onBlock();
         } else {
            console.warn('A new window could not be opened. Maybe it was blocked.');
         }
      }
   }

   openChild(): void {
      this.positionWindow();
      this.openWindow();

      // When a new window use content from a cross-origin there's no way we can attach event
      // to it. Therefore, we need to detect in a interval when the new window was destroyed
      // or was closed.
      this.windowCheckerInterval = setInterval(() => {
         if (!this.window || this.window.closed) {
            this.release();
         }
      }, 50);
   }

   /**
    * Close the opened window (if any) when NewWindow will unmount.
    */
   componentWillUnmount(): void {
      this.window?.close();
   }

   /**
    * Release the new window and anything that was bound to it.
    */
   release(): void {
      // This method can be called once.
      if (this.released) {
         return;
      }
      this.released = true;

      // Remove checker interval.
      clearInterval(this.windowCheckerInterval);

      // Call any function bound to the `onUnload` prop.
      const { onUnload } = this.props;
      onUnload?.();
   }
}

const copyStyles = (source: Document, target: Document): void => {
   // Store style tags, avoid reflow in the loop
   const headFrag = target.createDocumentFragment();

   Array.from(source.styleSheets).forEach((styleSheet) => {
      // For <style> elements
      let rules;
      try {
         rules = styleSheet.cssRules;
      } catch (err) {
         console.error(err);
      }
      if (rules) {
         // IE11 is very slow for appendChild, so use plain string here
         const ruleText = [];

         // Write the text of each rule into the body of the style element
         Array.from(styleSheet.cssRules).forEach((cssRule) => {
            // Skip unknown rules
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (type === CSSRule.UNKNOWN_RULE) {
               return;
            }

            let returnText = '';

            if (cssRule instanceof CSSKeyframesRule) {
               // IE11 will throw error when trying to access cssText property, so we
               // need to assemble them
               returnText = getKeyFrameText(cssRule);
            } else if (cssRule instanceof CSSImportRule || cssRule instanceof CSSFontFaceRule) {
               // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5)
               // to handle local imports on a about:blank page
               // '/custom.css' turns to 'http://my-site.com/custom.css'
               returnText = fixUrlForRule(cssRule);
            } else {
               returnText = cssRule.cssText;
            }
            ruleText.push(returnText);
         });

         const newStyleEl = target.createElement('style');
         newStyleEl.textContent = ruleText.join('\n');
         headFrag.appendChild(newStyleEl);
      } else if (styleSheet.href) {
         // for <link> elements loading CSS from a URL
         const newLinkEl = target.createElement('link');

         newLinkEl.rel = 'stylesheet';
         newLinkEl.href = styleSheet.href;
         headFrag.appendChild(newLinkEl);
      }
   });

   target.head.appendChild(headFrag);
};

const getKeyFrameText = (cssRule: CSSKeyframesRule): string => {
   const tokens = ['@keyframes', cssRule.name, '{'];
   Array.from(cssRule.cssRules).forEach((rule: CSSKeyframeRule) => {
      // type === CSSRule.KEYFRAME_RULE should always be true
      tokens.push(rule.keyText, '{', rule.style.cssText, '}');
   });
   tokens.push('}');
   return tokens.join(' ');
};

const fixUrlForRule = (cssRule: CSSRule): string =>
   cssRule.cssText
      .split('url(')
      .map((line) => {
         if (line[1] === '/') {
            return `${line.slice(0, 1)}${window.location.origin}${line.slice(1)}`;
         }
         return line;
      })
      .join('url(');

const toWindowFeatures = (obj): string =>
   Object.keys(obj)
      .reduce((features, name) => {
         const value = obj[name];
         if (typeof value === 'boolean') {
            features.push(`${name}=${value ? 'yes' : 'no'}`);
         } else {
            features.push(`${name}=${value}`);
         }
         return features;
      }, [])
      .join(',');

export default NewWindow;
