import * as React from 'react';

import { isClientError } from '@helpers/HttpResponseUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import HttpService from '@services/HttpService';

import Constants from '../../../Constants';
import Button from '@components/Common/Button';
import ProductCode from './ProductCode';

interface BookstoreCodePaymentProps {
   courseId: number;
   codeLabel: string;
   hasProducts: boolean;
   onSuccess(): void;
   togglePaymentMethod(): void;
}

const BookstoreCodePayment: React.FC<BookstoreCodePaymentProps> = ({
   courseId,
   codeLabel,
   hasProducts,
   togglePaymentMethod,
   onSuccess,
}) => {
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [bookstoreCode, setBookstoreCode] = React.useState<string>('');
   const [error, setError] = React.useState<string>('');

   const handleSubmit = (): void => {
      setIsLoading(true);
      const {
         statusCodes: { ok },
      } = Constants;
      HttpService.postWithAuthToken<{ msg: string }>(
         `/api/courses/${courseId}/payments/bookstore_code`,
         snakeCaseKeys({ bookstoreCode }),
         { handleNotFound: false },
      )
         .then((response) => {
            if (response.status === ok) {
               onSuccess();
            }
         })
         .catch(({ response }) => {
            if (response && isClientError(response.status)) {
               setIsLoading(false);
               setError(response.data.msg);
            }
         });
   };

   const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9-]/g, '');

      setBookstoreCode(event.target.value);
   };

   return (
      <div>
         {hasProducts ? (
            <ProductCode setCode={setBookstoreCode} />
         ) : (
            <input
               type='text'
               aria-label={`${codeLabel} Code`}
               placeholder={`${codeLabel} Code`}
               value={bookstoreCode}
               onChange={handleCodeChange}
            />
         )}
         {error && (
            <div className='payment-error' role='alert'>
               {error}
            </div>
         )}
         <Button
            className='payment-btn'
            fullWidth
            disabled={!bookstoreCode}
            loading={isLoading}
            onClick={handleSubmit}
            data-test='submit-bookstore-code-btn'
         >
            Submit
         </Button>
         <hr />
         <div className='switch-payment-container'>
            <label>Don't have a textbook code?</label>
            <Button line onClick={togglePaymentMethod} data-test='purchase-access-btn'>
               Purchase Access
            </Button>
         </div>
      </div>
   );
};

export default BookstoreCodePayment;
