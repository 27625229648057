import * as React from 'react';

import CanaryIslandsFlag from '@icons/flags/canary-islands.svg';
import PuertoRicoFlag from '@icons/flags/puerto-rico.svg';
import QuebecFlag from '@icons/flags/quebec.svg';
import { Maybe } from '@models/Core';

import { getTwoLetterCode } from './CountryCodes';
import { countryFlagMap, FlagComponentType } from './CountryFlagMap';

export interface RegionalOverride {
   latitudeRange: [number, number];
   longitudeRange: [number, number];
   flagComponent: FlagComponentType;
}

interface CountryFlagProps extends React.SVGProps<SVGElement> {
   /** Two or three letter Country Code */
   countryCode: string;
   latitude?: number;
   longitude?: number;
   regionalOverrides?: RegionalOverride[];
   useRegionalOverrides?: boolean;
}

// Default regional overrides
const defaultOverrides = [
   {
      latitudeRange: [27.6, 29.5],
      longitudeRange: [-18.2, -13.3],
      flagComponent: CanaryIslandsFlag,
   },
   {
      latitudeRange: [45, 62],
      longitudeRange: [-79.8, -57],
      flagComponent: QuebecFlag,
   },
   {
      latitudeRange: [17.9, 18.6],
      longitudeRange: [-67.3, -65.2],
      flagComponent: PuertoRicoFlag,
   },
];

const CountryFlag: React.FC<CountryFlagProps> = ({
   countryCode,
   latitude = null,
   longitude = null,
   useRegionalOverrides = true,
   regionalOverrides,
   ref,
   ...props
}) => {
   const overrides = regionalOverrides || defaultOverrides;

   const getRegionFlag = (): Maybe<FlagComponentType> => {
      if (latitude === null || longitude === null) return null;

      for (const override of overrides) {
         const [latMin, latMax] = override.latitudeRange;
         const [longMin, longMax] = override.longitudeRange;
         if (
            latitude >= latMin &&
            latitude <= latMax &&
            longitude >= longMin &&
            longitude <= longMax
         ) {
            return override.flagComponent;
         }
      }
      return null;
   };

   let FlagComponent;
   if (useRegionalOverrides) {
      FlagComponent = getRegionFlag();
   }

   if (!FlagComponent) {
      const normalizedCountryCode =
         countryCode.trim().length === 3 ? getTwoLetterCode(countryCode) : countryCode;
      if (normalizedCountryCode) {
         FlagComponent = countryFlagMap[normalizedCountryCode.toLowerCase()];
      }
   }

   if (!FlagComponent) {
      return null;
   }

   return <FlagComponent {...props} />;
};

export default CountryFlag;
