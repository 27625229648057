import * as _ from 'lodash';
import * as React from 'react';

import IconInterfaceInformation from '@icons/nova-line/22-Interface-Feedback/interface-information.svg';
import IconCursorSelectArea2 from '@icons/nova-solid/16-Selection&Cursors/cursor-select-area-2.svg';
import {
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGroup,
} from '@models/Activity';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import HttpService from '@services/HttpService';

import { AppStateContext } from '../../../../AppState';
import EmptyState from '@components/Core/EmptyState/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import { isNumber, isRubricItem, isRubricItemGroup } from '@components/Activity/Utils';
import GetCourseRubricsResponse, {
   CourseProfile,
   ModuleItemProfile,
   ModuleProfile,
   PromptProfile,
   QuestionProfile,
} from './models';
import ModuleItemSelector from './ModuleItemSelector';
import PromptSelector from './PromptSelector';
import importRubricModalReducer, { ImportRubricReducerAction } from './reducer';
import RubricPreview from './RubricPreview';

export interface ImportRubricModalProps {
   currentCourseId: number;
   currentModuleItemId: number;
   currentPromptId: number;
   importRubric(rubricId: number, callback?: () => void): void;
   closeImportModal(): void;
}

export interface ImportRubricModalState {
   courses: Record<number, CourseProfile>;
   isImportingRubric: boolean;
   moduleItems: Record<number, ModuleItemProfile>;
   modules: Record<number, ModuleProfile>;
   prompts: Record<number, PromptProfile>;
   questions: Record<number, QuestionProfile>;
   rubricItemGroups: Record<number, ActivityRubricItemGroup>;
   rubricItems: Record<number, ActivityRubricItem>;
   selectedModuleItemId: Maybe<number>;
   selectedPromptId: Maybe<number>;
}

const ImportRubricModal: React.FC<ImportRubricModalProps> = ({
   currentCourseId,
   currentModuleItemId,
   currentPromptId,
   importRubric,
   closeImportModal,
}) => {
   const { archivedCourses, currentCourses } = React.useContext<AppStateContext>(AppStateContext);

   const mapCourse = (
      i: BasicCourseProfile,
      options: Partial<BasicCourseProfile> = {},
   ): CourseProfile => ({
      ...i,
      isArchived: false,
      isExpanded: false,
      isFetchingRubrics: false,
      ...options,
   });

   const [state, dispatch] = React.useReducer<
      React.Reducer<ImportRubricModalState, ImportRubricReducerAction>
   >(importRubricModalReducer, {
      courses: _.keyBy(
         [
            ...currentCourses.map((i) => mapCourse(i)),
            ...archivedCourses.map((i) => mapCourse(i, { archived: true })),
         ],
         'id',
      ),
      isImportingRubric: false,
      moduleItems: {},
      modules: {},
      prompts: {},
      questions: {},
      rubricItemGroups: {},
      rubricItems: {},
      selectedModuleItemId: null,
      selectedPromptId: null,
   });

   React.useEffect(() => {
      toggleCourse(currentCourseId).then(() => {
         selectModuleItem(currentModuleItemId);
         dispatch({ type: 'EXPAND_SELECTED_MODULE' });
      });
   }, []);

   const fetchCourse = (courseId: number): Promise<void> => {
      dispatch({ type: 'FETCH_COURSE_RUBRICS', courseId });
      return HttpService.getWithAuthToken<GetCourseRubricsResponse>(
         `/api/courses/${courseId}/rubrics`,
      )
         .then((response) => {
            const { modules, moduleItems, questions, prompts, rubricItems, rubricItemGroups } =
               response.data;
            dispatch({
               type: 'FETCH_COURSE_RUBRICS_SUCCESS',
               courseId,
               modules,
               moduleItems,
               questions,
               prompts,
               rubricItems,
               rubricItemGroups,
            });
         })
         .catch(() => {
            dispatch({ type: 'FETCH_COURSE_RUBRICS_FAILURE', courseId });
         });
   };

   const handleImportRubricClick = (): void => {
      if (canImportRubric && state.selectedPromptId) {
         dispatch({ type: 'IMPORT_RUBRIC' });
         const prompt = state.prompts[state.selectedPromptId];
         importRubric(prompt.rubricId, closeImportModal);
      }
   };

   const selectModuleItem = (moduleItemId: number): void => {
      dispatch({ type: 'SELECT_MODULE_ITEM', moduleItemId });
   };

   const selectPrompt = (promptId: number): void => {
      dispatch({ type: 'SELECT_PROMPT', promptId });
   };

   const toggleCourse = (courseId: number): Promise<void> => {
      dispatch({ type: 'TOGGLE_COURSE', courseId });
      if (!Object.prototype.hasOwnProperty.call(state.courses[courseId], 'modules')) {
         return fetchCourse(courseId);
      }
      return Promise.resolve();
   };

   const toggleModule = (moduleId: number): void => {
      dispatch({ type: 'TOGGLE_MODULE', moduleId });
   };

   const reduceModuleItem = (moduleItemId: number): Maybe<ModuleItemProfile> => {
      const moduleItem = state.moduleItems[moduleItemId];
      if (moduleItem) {
         return {
            ...moduleItem,
            questions: moduleItem.questions.filter(isNumber).map((questionId) => {
               const question = state.questions[questionId];
               return {
                  ...question,
                  prompts: question.prompts
                     .filter(isNumber)
                     .map((promptId) => state.prompts[promptId]),
               };
            }),
         };
      }
      return undefined;
   };

   const reducePrompt = (promptId: number): PromptProfile | null => {
      const prompt = state.prompts[promptId];
      if (prompt) {
         return {
            ...prompt,
            rubricItems: prompt.rubricItems.filter(isNumber).map((itemId) => ({
               ...state.rubricItems[itemId],
               type: ActivityRubricEntryType.item,
            })),
            rubricItemGroups: prompt.rubricItemGroups.filter(isNumber).map((groupId) => ({
               ...state.rubricItemGroups[groupId],
               type: ActivityRubricEntryType.group,
            })),
         };
      }
      return null;
   };

   const reducedCourses = _.orderBy(Object.values(state.courses), ['archived', 'name']).map(
      (course) => ({
         ...course,
         modules: _.sortBy(
            (course.modules || []).filter(isNumber).map((moduleId) => {
               const moduleObj = state.modules[moduleId];
               return {
                  ...moduleObj,
                  moduleItems: _.sortBy(
                     moduleObj.moduleItems
                        .filter(isNumber)
                        .map((moduleItemId) => state.moduleItems[moduleItemId]),
                     ['index'],
                  ),
               };
            }),
            ['index'],
         ),
      }),
   );

   const reducedModuleItem = state.selectedModuleItemId
      ? reduceModuleItem(state.selectedModuleItemId)
      : null;

   const reducedPrompt = state.selectedPromptId ? reducePrompt(state.selectedPromptId) : null;

   const canImportRubric = reducedPrompt && reducedPrompt.rubricItems.length > 0;

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='Import Rubric'
         bodyClassName='import-rubric-modal'
         footerClassName='card-footer'
         actions={[
            {
               text: 'Import Rubric',
               onClick: handleImportRubricClick,
               disabled: !canImportRubric,
               loading: state.isImportingRubric,
            },
            { text: 'Cancel', onClick: closeImportModal },
         ]}
         width='x-large'
      >
         <div className='row'>
            <div className='col-xs-12'>
               <div className='directions'>
                  <IconInterfaceInformation />
                  <span>Select a rubric from another prompt to import.</span>
               </div>
            </div>
         </div>
         <div className='row'>
            <div className='col-xs-4 import-rubric-column'>
               <ModuleItemSelector
                  courses={reducedCourses}
                  currentModuleItemId={currentModuleItemId}
                  selectedModuleItemId={state.selectedModuleItemId}
                  selectModuleItem={selectModuleItem}
                  toggleCourse={toggleCourse}
                  toggleModule={toggleModule}
               />
            </div>
            <div className='col-xs-4 import-rubric-column'>
               <PromptSelector
                  currentModuleItemId={currentModuleItemId}
                  currentPromptId={currentPromptId}
                  moduleItem={reducedModuleItem}
                  selectedModuleItemId={state.selectedModuleItemId}
                  selectedPromptId={state.selectedPromptId}
                  selectPrompt={selectPrompt}
               />
            </div>
            <div className='col-xs-4 import-rubric-column'>
               {reducedPrompt ? (
                  <RubricPreview
                     scoringType={reducedPrompt.scoringType}
                     rubricItems={reducedPrompt.rubricItems.filter(isRubricItem)}
                     rubricItemGroups={reducedPrompt.rubricItemGroups.filter(isRubricItemGroup)}
                  />
               ) : (
                  <EmptyState
                     icon={<IconCursorSelectArea2 aria-hidden />}
                     heading='Rubric Preview'
                     description='Select a prompt to preview the rubric'
                  />
               )}
            </div>
         </div>
      </ModalDialog>
   );
};

export default ImportRubricModal;
