import Prompt from '@components/Activity/Builder/Prompt';
import { getPromptName } from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import Pill from '@components/Common/Pill';
import DropOverlay from '@components/DropOverlay';
import TableOfContents, { TableOfContentsRow } from '@components/TableOfContents';
import {
   convertPrecisionToSeconds,
   getInteractiveVideoSeconds,
   getPromptsForInteractiveVideoFromQuestion,
} from '@components/Video/helpers';
import { useDndMonitor } from '@dnd-kit/core';
import { formatSeconds } from '@helpers/FormatTime';
import { randomShortId, randomTempId } from '@helpers/RandomStringUtils';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import ShrinkDiagnal1 from '@icons/nova-line/28-Resize&Move/shrink-diagonal-1.svg';
import IconCursorChoose from '@icons/nova-solid/02-Status/cursor-choose.svg';
import IconDataDownload8 from '@icons/nova-solid/23-Data-Transfer/data-download-8.svg';
import IconVideoControlPause from '@icons/nova-solid/36-Videos/video-control-pause.svg';
import IconVideoControlPlay from '@icons/nova-solid/36-Videos/video-control-play.svg';
import {
   ActivityBuilderMode,
   ActivityItem,
   ActivityPrompt,
   ActivityQuestion,
   itemTypeDetails,
   VideoContent,
} from '@models/Activity';
import { PromptType } from '@models/Activity/ActivityPrompt';
import { ID } from '@models/Core';
import Tippy from '@tippyjs/react';
import _ from 'lodash';
import React from 'react';
import { SliderItem } from 'react-compound-slider';
import ReactPlayer from 'react-player';

import InteractiveVideoBuilderSlider, { HideableTick } from './InteractiveVideoBuilderSlider';
import DropOverlayBadge from './OverlayBadge';

type Props = {
   isDraggingPrompt: boolean;
   url: string;
   interactiveVideoContent: VideoContent<ActivityBuilderMode>;
   question: ActivityQuestion<ActivityBuilderMode>;
   removeItem(keyToDelete: string): void;
   handleQuestionUpdate(
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   handleItemUpdate(
      itemKey: string,
      update: Partial<ActivityItem<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   handleVideoPromptSecondsUpdate(
      videoId: ID,
      promptId: ID,
      promptTimeInSeconds: number,
      promptType: PromptType,
   ): void;
};

type Progress = {
   played: number;
   playedSeconds: number;
   loaded: number;
   loadedSeconds: number;
};

type PromptIdToDisplay = {
   id?: ID;
   isDraggingSlider: boolean;
};

const PROGRESS_INTERVAL_IN_MILLISECONDS = 100; // .1 seconds
const TOOLTIP_DELAY_IN_MILLISECONDS = 500;

const InteractiveVideoBuilder: React.FC<Props> = (props) => {
   const playerRef = React.useRef<ReactPlayer>(null);
   const [duration, setDuration] = React.useState<number>(0);
   const [progress, setProgress] = React.useState<number>(0);
   const [currentPromptId, setCurrentPromptId] = React.useState<PromptIdToDisplay>({
      id: undefined,
      isDraggingSlider: false,
   });
   const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
   const [selectedTickId, setSelectedTickId] = React.useState<string>();

   const currentTimeFormatted = formatSeconds(progress);
   const promptTimes = props.interactiveVideoContent.promptTimes || [];
   const interactivePrompts = getPromptsForInteractiveVideoFromQuestion(
      props.question,
      props.interactiveVideoContent,
   );
   const currentPrompt = interactivePrompts.find((x) => x.id === currentPromptId.id);
   const ticksInSeconds: readonly HideableTick[] = promptTimes.map((x) => ({
      seconds: x.promptTimeInSeconds,
      hidden: currentPromptId.id === x.promptId,
   }));

   const tableOfContents = interactivePrompts
      .map((x) => ({
         // TODO: check into this a bit
         id: x.id || randomShortId(),
         rightHeading: itemTypeDetails[x.itemType].label || getPromptName(x.itemType),
         leftHeading: formatSeconds(
            getInteractiveVideoSeconds(x.id, props.interactiveVideoContent),
         ),
         isSelected: currentPromptId.id === x.id,
      }))
      .sort((a, b) => a.leftHeading.localeCompare(b.leftHeading));

   useDndMonitor({
      onDragStart() {
         pauseVideo();
      },
      // onDragMove(event) {},
      // onDragOver(event) {},
      onDragEnd(event) {
         if (
            event.active.data.current &&
            event.over &&
            props.interactiveVideoContent.id &&
            event.over.data.current?.id === props.interactiveVideoContent.id
         ) {
            const newItems = [...props.question.items];
            const newPrompt = event.active.data.current as ActivityPrompt<ActivityBuilderMode>;
            newPrompt.id = randomTempId();
            newItems.push(newPrompt);
            props.handleQuestionUpdate({ items: newItems });
            props.handleVideoPromptSecondsUpdate(
               props.interactiveVideoContent.id,
               newPrompt.id,
               progress,
               newPrompt.itemType,
            );
            setCurrentPromptId({ id: newPrompt.id, isDraggingSlider: false });
         }
      },
      // onDragCancel(event) {},
   });

   const resetCurrentlySelectedPrompt = () => {
      setSelectedTickId(undefined);
      setCurrentPromptId({ id: undefined, isDraggingSlider: false });
   };

   const toggleIsPlaying = (): void => {
      setIsPlaying(!isPlaying);
      resetCurrentlySelectedPrompt();
   };

   const playVideo = (): void => {
      setIsPlaying(true);
      resetCurrentlySelectedPrompt();
   };

   const pauseVideo = (): void => {
      setIsPlaying(false);
   };

   const handleSliderChange = (seconds: number): void => {
      const deltaProgress = progress - seconds;
      const isProgressJump = Math.abs(deltaProgress) > 2;
      // If the progress jump is too large, reset the currently selected prompt
      if (isProgressJump && currentPromptId.id && currentPromptId.isDraggingSlider === false) {
         resetCurrentlySelectedPrompt();
      }

      if (playerRef.current) {
         playerRef.current.seekTo(seconds, 'seconds');
      }
   };

   const onSlideStart = (seconds: number): void => {
      if (currentPrompt?.id && props.interactiveVideoContent.id) {
         setCurrentPromptId({ id: currentPrompt.id, isDraggingSlider: true });
         props.handleVideoPromptSecondsUpdate(
            props.interactiveVideoContent.id,
            currentPrompt.id,
            seconds,
            currentPrompt.itemType,
         );
      }
   };

   const onSlideEnd = (seconds: number): void => {
      if (currentPrompt?.id && props.interactiveVideoContent.id) {
         setCurrentPromptId({ id: currentPrompt.id, isDraggingSlider: false });
         props.handleVideoPromptSecondsUpdate(
            props.interactiveVideoContent.id,
            currentPrompt.id,
            seconds,
            currentPrompt.itemType,
         );
      }
   };

   const onProgress = (value: Progress): void => {
      setProgress(value.playedSeconds);
   };

   const onTickClick = (tick?: SliderItem): void => {
      if (tick === undefined) {
         setCurrentPromptId({ id: undefined, isDraggingSlider: false });
         return;
      }

      const tickSeconds = convertPrecisionToSeconds(tick.value);
      const clickedPromptId = props.interactiveVideoContent.promptTimes?.find(
         (x) => x.promptTimeInSeconds === tickSeconds,
      )?.promptId;
      setCurrentPromptId({ id: clickedPromptId, isDraggingSlider: false });
      pauseVideo();
      handleSliderChange(tickSeconds);
   };

   const onTableOfContentsRowClick = (row: TableOfContentsRow) => {
      setCurrentPromptId({ id: row.id, isDraggingSlider: false });
      pauseVideo();
      const clickedPrompt = props.interactiveVideoContent.promptTimes?.find(
         (x) => x.promptId === row.id,
      );
      handleSliderChange(clickedPrompt?.promptTimeInSeconds || 0);
   };

   const onRemove = () => {
      if (currentPrompt !== undefined) {
         props.removeItem(currentPrompt.key);
      }
   };

   if (!props.interactiveVideoContent.id) {
      return <></>;
   }

   return (
      <div>
         <div className='interactive-media-player builder no-drag'>
            {currentPrompt === undefined || currentPromptId.isDraggingSlider === true ? (
               <DropOverlay
                  actionDescription={
                     <div className='text-md'>
                        Add prompt at <strong>{currentTimeFormatted}</strong>
                     </div>
                  }
                  actionIcon={<IconDataDownload8 className='icon-md margin-right-xs' />}
                  badge={
                     <DropOverlayBadge
                        icon={<IconCursorChoose className='icon-xs margin-right-xs' />}
                        title='Interactive'
                     />
                  }
                  data={props.interactiveVideoContent}
                  defaultDescription={
                     <div className='text-md'>Drop prompts on video to add interactive content</div>
                  }
                  defaultIcon={<IconDataDownload8 className='icon-md margin-right-xs' />}
                  id={props.interactiveVideoContent.id}
                  isDraggingPrompt={props.isDraggingPrompt}
               />
            ) : (
               <div className={'interactive-prompt-container inline'}>
                  <div className='interactive-prompt'>
                     <div className='margin-m flex space-between align-items-center'>
                        <Pill>{currentTimeFormatted}</Pill>
                        <div className='align-items-center'>
                           <Tippy content='Delete Item' delay={[500, 0]}>
                              <div
                                 aria-label='Delete Item'
                                 className='icon-action'
                                 onClick={onRemove}
                                 onKeyDown={(e) => e.key === 'Enter' && onRemove()}
                                 role='button'
                                 tabIndex={0}
                              >
                                 <IconBin />
                              </div>
                           </Tippy>
                           <Button
                              className='margin-left-s'
                              icon={<ShrinkDiagnal1 />}
                              line
                              onClick={resetCurrentlySelectedPrompt}
                              tooltip='Minimize Item'
                           >
                              Minimize
                           </Button>
                        </div>
                     </div>
                     <div className='interactive-prompt-padding-bottom'>
                        <Prompt
                           prompt={currentPrompt}
                           key={currentPrompt.key}
                           onUpdate={(
                              update: Partial<ActivityPrompt<ActivityBuilderMode>>,
                              callback?: () => void,
                           ) => props.handleItemUpdate(currentPrompt.key, update, callback)}
                        />
                     </div>
                  </div>
               </div>
            )}

            <div className='video-player' onClick={toggleIsPlaying}>
               <ReactPlayer
                  ref={playerRef}
                  width={'100%'}
                  height={'100%'}
                  config={{
                     file: {
                        attributes: {
                           onContextMenu: (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) =>
                              e.preventDefault(),
                           controlsList: 'nodownload disable',
                           disablePictureInPicture: true,
                        },
                     },
                  }}
                  url={props.url}
                  controls={false}
                  onProgress={onProgress}
                  onDuration={setDuration}
                  playing={isPlaying}
                  onPlay={playVideo}
                  progressInterval={PROGRESS_INTERVAL_IN_MILLISECONDS}
               />
            </div>
            <div className='interactive-video-controls'>
               <Tippy
                  content={isPlaying ? 'Pause (space)' : 'Play (space)'}
                  delay={TOOLTIP_DELAY_IN_MILLISECONDS}
               >
                  <div className={'play-icon'}>
                     {isPlaying ? (
                        <IconVideoControlPause onClick={pauseVideo} />
                     ) : (
                        <IconVideoControlPlay onClick={playVideo} />
                     )}
                  </div>
               </Tippy>
               <div className='slider-container'>
                  <InteractiveVideoBuilderSlider
                     duration={duration}
                     onSlideEnd={onSlideEnd}
                     onSlideStart={onSlideStart}
                     onTickClick={onTickClick}
                     onUpdate={handleSliderChange}
                     progress={progress}
                     selectedTickId={selectedTickId}
                     setSelectedTickId={setSelectedTickId}
                     ticksInSeconds={ticksInSeconds}
                  />
               </div>
               <div className='video-time-wrapper'>
                  <time>{formatSeconds(progress)}</time>
                  <span className='divider'>/</span>
                  <time>{formatSeconds(duration)}</time>
               </div>
            </div>
         </div>
         <TableOfContents
            className='margin-top-m'
            icon={<IconCursorChoose />}
            onRowClick={onTableOfContentsRowClick}
            rows={tableOfContents}
            title='Table of Contents'
         />
      </div>
   );
};

export default InteractiveVideoBuilder;
