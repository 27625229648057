import * as React from 'react';

import pluralize from 'pluralize';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import { ProgressBar } from '@components/Core/ProgressBar';
import { VocabSessionType } from '@components/VocabSession/Models';
import { IVocabSetSummary } from './StudentVocabStats';

interface IVocabSetSummariesProps {
   summary: readonly IVocabSetSummary[];
}

const VocabSetSummaries: React.FC<IVocabSetSummariesProps> = ({ summary }) => {
   const {
      routes: {
         content: { viewVocabSet, vocabSession },
      },
   } = Constants;

   const getSessionLink = (vocabSetId: number, sessionType: VocabSessionType): string =>
      `${vocabSession
         .replace(':vocabSetId', vocabSetId.toString())
         .replace(':sessionType', sessionType)}`;

   const renderVocabSetButton = ({
      vocabSetId,
      readyToReview,
      learned,
      terms,
   }: IVocabSetSummary): React.ReactNode => {
      if (readyToReview) {
         return (
            <Link className='btn blue' to={getSessionLink(vocabSetId, VocabSessionType.review)}>
               Review
            </Link>
         );
      } else if (learned < terms) {
         return (
            <Link className='btn green' to={getSessionLink(vocabSetId, VocabSessionType.learn)}>
               Learn
            </Link>
         );
      } else {
         return (
            <Link className='btn red' to={getSessionLink(vocabSetId, VocabSessionType.speedReview)}>
               Speed Review
            </Link>
         );
      }
   };

   const renderVocabSetSubtitle = ({
      readyToReview,
      learned,
      terms,
   }: IVocabSetSummary): React.ReactNode => {
      if (readyToReview) {
         return (
            <div className='vocab-set-summary-subtitle'>{`${readyToReview} ${pluralize(
               'term',
               readyToReview,
            )} ready to review`}</div>
         );
      } else if (learned < terms) {
         const readyToLearn = terms - learned;
         return (
            <div className='vocab-set-summary-subtitle'>{`${readyToLearn} ${pluralize(
               'term',
               readyToLearn,
            )} ready to learn`}</div>
         );
      }
      return null;
   };

   return (
      <div className='card no-padding' style={{ height: '100%' }}>
         <div className='card-title'>
            <div className='title'>Vocab Sets</div>
         </div>
         <div className='vocab-sets-summaries'>
            {summary.slice(0, 3).map((i) => (
               <div className='vocab-set-summary' key={i.moduleItemId}>
                  <div className='vocab-set-summary-title'>
                     <div>
                        <Link to={viewVocabSet.replace(':vocabSetId', i.vocabSetId.toString())}>
                           {i.vocabSetName}
                        </Link>
                        {renderVocabSetSubtitle(i)}
                     </div>
                     {renderVocabSetButton(i)}
                  </div>
                  <ProgressBar percentage={(i.learned / i.terms) * 100} />
               </div>
            ))}
         </div>
      </div>
   );
};

export default React.memo(VocabSetSummaries);
