import * as React from 'react';

import { parseHtml } from '@helpers/ParseHtml';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import Content, { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import ContentService from '@services/ContentService';
import LessonService from '@services/LessonService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import StyleWrapper from '@components/Activity/StyleWrapper';
import AddContentToCourseModal from '@components/AddContentToCourseModal/AddContentToCourseModal';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import LessonHeader from './LessonHeader';

const LessonViewer: React.FC = () => {
   const {
      routes: {
         content: { editLesson: editLessonLink },
      },
   } = Constants;

   const navigate = useNavigate();
   const location = useLocation();

   const { lessonId } = useParams<{ lessonId: string }>();

   const { userProfile, setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }

   const [canEdit, setCanEdit] = React.useState<boolean>(false);
   const [content, setContent] = React.useState<string>('');
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [settings, setSettings] = React.useState<Maybe<Content>>(null);
   const [addToCourseModalOpen, setAddToCoursModalOpen] = React.useState<boolean>(false);

   const moduleItemIdFromQuery = getQueryParameterAsNumber(location, 'moduleItemId', null);

   React.useEffect(() => {
      if (lessonId === null) {
         return;
      }
      setIsFetching(true);
      LessonService.fetchLesson(Number(lessonId)).then(
         ({
            lesson: { content: responseContent, ...responseSettings },
            canEdit: responseCanEdit,
         }) => {
            setContent(responseContent);
            setSettings(responseSettings);
            setCanEdit(responseCanEdit);
            setIsFetching(false);

            ContentService.getBreadcrumbs(
               {
                  accountType: userProfile.accountType,
                  canEditContent: canEdit,
                  contentId: responseSettings.id,
                  contentName: responseSettings.name,
                  contentType: ContentType.lesson,
                  createdBy: responseSettings.createdBy,
                  folderId: responseSettings.folderId,
                  userId: userProfile.id,
               },
               location,
            ).then(setBreadcrumbs);
         },
      );
   }, [lessonId]);

   const editLesson = (): void => {
      let queryParams = '';
      const moduleItemId = getQueryParameterAsNumber(location, 'moduleItemId', null);
      if (moduleItemId) {
         queryParams = `?moduleItemId=${moduleItemId}`;
      }
      if (settings?.id) {
         navigate(editLessonLink.replace(':lessonId', settings.id.toString()).concat(queryParams));
      }
   };

   const closeAddToCourseModal = (): void => {
      setAddToCoursModalOpen(false);
   };

   const openAddToCourseModal = (): void => {
      setAddToCoursModalOpen(true);
   };

   const handleOwnershipTransfer = (newCreatorId: number): void => {
      setSettings((prevSettings) =>
         prevSettings ? { ...prevSettings, createdBy: newCreatorId } : prevSettings,
      );
   };

   if (isFetching || !settings?.id) {
      return <Loader />;
   }

   return (
      <>
         <div className='content-main lesson-wrapper'>
            <DocumentTitle>
               {isFetching && settings ? 'Loading Lesson...' : settings.name}
            </DocumentTitle>
            <div className='card no-padding'>
               <LessonHeader
                  canEdit={canEdit || settings.createdBy === userProfile.id}
                  isCreator={settings.createdBy === userProfile.id}
                  lessonId={settings.id}
                  moduleItemId={moduleItemIdFromQuery}
                  name={settings.name}
                  editLesson={editLesson}
                  onOwnershipTransfer={handleOwnershipTransfer}
                  openAddToCourseModal={openAddToCourseModal}
               />
               <StyleWrapper styleId={settings.styleSheetId} className='lesson-content'>
                  {parseHtml(content)}
               </StyleWrapper>
            </div>
         </div>
         {addToCourseModalOpen && settings.createdBy && (
            <AddContentToCourseModal
               contentItemProfile={{
                  createdBy: settings.createdBy,
                  folderId: settings.folderId,
                  imageUrl: settings.imageUrl,
                  itemId: Number(lessonId),
                  itemLanguage: settings.language,
                  itemName: settings.name,
                  itemType: ContentType.lesson,
               }}
               onClose={closeAddToCourseModal}
            />
         )}
      </>
   );
};

export default LessonViewer;
