import * as React from 'react';

import { TabsConsumer } from './Tabs';

interface ITabProps {
   children: React.ReactNode;
   className?: string;
   disabled?: boolean;
   heading?: React.ReactNode;
   id: string;
   name?: string;
}

const Tab: React.FC<ITabProps> = ({ children, id }) => (
   <TabsConsumer>{(active) => active === id && children}</TabsConsumer>
);

export default Tab;
