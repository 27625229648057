import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { getQueryParameterByName, removeQueryParameter } from '@helpers/QueryParameter';
import { getCurrentRelativeURL } from '@helpers/RouterHelper';
import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import { useLocation, useNavigate } from 'react-router-dom';

import Constants from '../Constants';
import Button from './Common/Button';
import DocumentTitle from './DocumentTitle';

const ResetPassword: React.FC = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const {
      minPasswordLength,
      routes: {
         auth: { login: loginRoute, tokenLogin: tokenLoginRoute },
      },
      statusCodes,
   } = Constants;

   const [token, setToken] = React.useState<Maybe<string>>(null);
   const [password, setPassword] = React.useState<string>('');
   const [confirmPassword, setConfirmPassword] = React.useState<string>('');
   const [wasTempPassword, setWasTempPassword] = React.useState<boolean>(false);
   const [errors, setErrors] = React.useState<{ password?: string; confirmPassword?: string }>({});
   const [initial, setInitial] = React.useState<boolean>(false);
   const [isInitializing, setIsInitializing] = React.useState<boolean>(true);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   React.useEffect(() => {
      const initialParam = getQueryParameterByName(location, 'initial') === 'true';
      const tokenParam = getQueryParameterByName(location, 'token');
      const wasTempPasswordParam = getQueryParameterByName(location, 'temp') === 'true';

      setInitial(initialParam);
      setToken(tokenParam);
      setWasTempPassword(wasTempPasswordParam);
      setIsInitializing(false);

      // Once we've pulled the token from the route, we should remove it from the browser history
      // to minimize the chances of it being used again
      let newUrlForHistory = removeQueryParameter(getCurrentRelativeURL(), 'token');
      newUrlForHistory = removeQueryParameter(newUrlForHistory, 'temp');
      navigate(newUrlForHistory, { replace: true });
   }, []);

   const validateForm = (): boolean => {
      const updatedErrors: Record<string, string> = {};
      if (password.length === 0) {
         updatedErrors.password = 'Cannot be blank';
      } else if (password.length < minPasswordLength) {
         updatedErrors.password = `Must be at least ${minPasswordLength} characters`;
      } else if (password !== confirmPassword) {
         updatedErrors.confirmPassword = 'Passwords must match';
      }
      if (confirmPassword.length === 0) {
         updatedErrors.confirmPassword = 'Cannot be blank';
      }
      setErrors(updatedErrors);
      return Object.keys(updatedErrors).length === 0;
   };

   const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setPassword(value);
   };

   const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setConfirmPassword(value);
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (!validateForm()) {
         return;
      }
      setIsLoading(true);
      const data = {
         token,
         password,
         loginAfter: wasTempPassword,
      };
      HttpService.post<{ token: string }>('/api/users/reset_password', snakeCaseKeys(data), {
         handleNotFound: false,
      })
         .then((response) => {
            const { token: loginToken } = response.data;
            navigate(loginToken ? `${tokenLoginRoute}?token=${loginToken}` : loginRoute);
         })
         .catch((error) => {
            if (error.response.status === statusCodes.notFound) {
               setErrors({ confirmPassword: error.response.data.msg });
            }
         });
   };

   const docTitle = wasTempPassword
      ? 'Password Reset Required'
      : initial
      ? 'Set Password'
      : 'Reset Password';
   const continueButtonText = wasTempPassword ? 'Reset and Login' : initial ? 'Set' : 'Reset';

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card padding-bottom-m'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>{docTitle}</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           {docTitle}
                        </h2>
                     </div>
                     <form className='content-form' onSubmit={handleSubmit}>
                        {wasTempPassword && (
                           <div className='alert alert-primary margin-top-m margin-bottom-xs'>
                              You&apos;ve just logged in using a temporary password. Please create a
                              new password now.
                           </div>
                        )}
                        {!isInitializing && !token && (
                           <div className='alert alert-danger margin-top-m margin-bottom-xs'>
                              Your password reset token is invalid.
                           </div>
                        )}
                        <div className='row'>
                           <div className='col-xs-12'>
                              <label htmlFor='password'>{`${initial ? '' : 'New '}Password`}</label>
                              <input
                                 type='password'
                                 id='password'
                                 data-test='password'
                                 value={password}
                                 onChange={handlePasswordChange}
                                 data-dd-privacy='input-ignored'
                              />
                              {errors.password && <p className='error'>{errors.password}</p>}
                           </div>
                           <div className='col-xs-12'>
                              <label htmlFor='confirm-password'>Confirm Password</label>
                              <input
                                 id='confirm-password'
                                 type='password'
                                 data-test='confirm-password'
                                 value={confirmPassword}
                                 onChange={handleConfirmPasswordChange}
                                 data-dd-privacy='input-ignored'
                              />
                              {errors.confirmPassword && (
                                 <p className='error'>{errors.confirmPassword}</p>
                              )}
                           </div>
                        </div>
                        <Button
                           type='submit'
                           fullWidth
                           loading={isLoading}
                           data-test='submit'
                           className='margin-top-m'
                           disabled={!token}
                        >
                           {continueButtonText}
                        </Button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ResetPassword;
