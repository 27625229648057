import * as React from 'react';

import { Maybe } from '@models/Core';
import { ProficiencyLevelHierarchy, ProficiencyLevelName } from '@models/Proficiency';

interface CourseProficiencyOptionRowProps {
   allOptions: readonly ProficiencyLevelOption[];
   rowLabel: string;
   selectedEntryLevel?: Maybe<ProficiencyLevelName>;
   selectedGoalLevel?: Maybe<ProficiencyLevelName>;
   onEntryLevelChange(event: React.ChangeEvent<HTMLSelectElement>): void;
   onGoalLevelChange(event: React.ChangeEvent<HTMLSelectElement>): void;
}

type ProficiencyLevelOption = {
   value: ProficiencyLevelName;
   label: string;
};

const CourseProficiencyOptionRow: React.FC<CourseProficiencyOptionRowProps> = ({
   allOptions,
   rowLabel,
   selectedEntryLevel,
   selectedGoalLevel,
   onEntryLevelChange,
   onGoalLevelChange,
}) => {
   const [goalOptions, setGoalOptions] = React.useState<readonly ProficiencyLevelOption[]>([]);

   const renderOptions = (options: readonly ProficiencyLevelOption[]): React.ReactNode[] => {
      const emptyOption = <option key={rowLabel + 'empty'} value={undefined} />;
      const optionsToRender: React.ReactNode[] = [emptyOption];

      optionsToRender.push(
         ...options.map((x) => (
            <option key={rowLabel + x.value} value={x.value}>
               {x.label}
            </option>
         )),
      );

      return optionsToRender;
   };

   React.useEffect(() => {
      const levelsGreaterThanEntryLevelSelected = allOptions.filter(
         (i) =>
            selectedEntryLevel &&
            ProficiencyLevelHierarchy[i.value] >= ProficiencyLevelHierarchy[selectedEntryLevel],
      );
      setGoalOptions(levelsGreaterThanEntryLevelSelected);
   }, [selectedEntryLevel, allOptions]);

   return (
      <div className='row col-xs-12 flex items-center margin-top-s'>
         <label className='col-xs-2 items-center'>{rowLabel}</label>
         <div className='col-xs-5'>
            <select
               data-test={rowLabel + '-entry'}
               onChange={onEntryLevelChange}
               value={selectedEntryLevel ?? ''}
            >
               {renderOptions(allOptions)}
            </select>
         </div>
         <div className='col-xs-5'>
            <select
               data-test={rowLabel + '-goal'}
               disabled={goalOptions.length === 0}
               onChange={onGoalLevelChange}
               value={selectedGoalLevel ?? ''}
            >
               {renderOptions(goalOptions)}
            </select>
         </div>
      </div>
   );
};

export default CourseProficiencyOptionRow;
