import * as _ from 'lodash';
import * as React from 'react';

import { ActivityItem, ActivityMode, ActivityQuestion } from '@models/Activity';

import { isPrompt } from '@components/Activity/Utils';
import InfoTooltip from '@components/InfoTooltip';
import PromptRow from './PromptRow';

interface QuestionsTableProps {
   questions: readonly ActivityQuestion<ActivityMode.grade>[];
   filteredIds: ReadonlySet<number>;
   getPromptLink(promptId: number): string;
}

const QuestionsTable: React.FC<QuestionsTableProps> = ({
   questions,
   filteredIds,
   getPromptLink,
}) => {
   const renderPromptRows = (
      items: readonly ActivityItem<ActivityMode.grade>[],
   ): readonly React.ReactNode[] => {
      const lastPromptId = _.reverse(items).find((i) => isPrompt(i))?.id;
      return _.compact(
         items.map((i) => {
            if (isPrompt<ActivityMode.grade>(i) && i.scores) {
               return (
                  <PromptRow
                     id={i.id}
                     itemType={i.itemType}
                     key={`p-${i.id}`}
                     lastPrompt={i.id === lastPromptId}
                     promptLink={getPromptLink(i.id)}
                     scores={i.scores.filter((j) => filteredIds.has(j.submissionId))}
                     weight={i.weight}
                  />
               );
            }
            return null;
         }),
      );
   };

   return (
      <table className='rwd-table questions-table'>
         <tbody>
            <tr className='head-row no-bg-color' key='h'>
               <th>Question</th>
               <th>Points</th>
               <th>
                  <div className='inline-flex'>
                     Average
                     <InfoTooltip>Excludes Ungraded</InfoTooltip>
                  </div>
               </th>
               <th>% Completed</th>
               <th>% Graded</th>
            </tr>
            {questions
               .filter((i) => i.items.some(isPrompt))
               .map(({ id: questionId, items, title }) => (
                  <React.Fragment key={`q-${questionId}`}>
                     <tr className='nested-tr' key={`q-${questionId}`}>
                        <td data-th='Question'>
                           <div className='title'>{title}</div>
                        </td>
                        <td />
                        <td />
                        <td />
                     </tr>
                     {renderPromptRows(items)}
                  </React.Fragment>
               ))}
         </tbody>
      </table>
   );
};

export default QuestionsTable;
