import * as React from 'react';

import {
   ProficiencyReportSkillData,
   ProficiencyReportTileBreakdownBySkill,
} from '@models/Proficiency';

import ProficiencyReportProgressBar from './ProficiencyReportProgressBar';

interface ProficiencyReportOverviewTilesProps {
   skillBreakdown: ProficiencyReportTileBreakdownBySkill;
}

const ProficiencyReportOverviewTiles: React.FC<ProficiencyReportOverviewTilesProps> = (props) => {
   const renderTile = (title: string, row: ProficiencyReportSkillData) => (
      <div
         className='proficiency-report-tile padding-s margin-s col-xs'
         key={`report-tile-${title}`}
         data-test={`overview-${title}`}
      >
         <div className='no-margin-bottom title'>{title}</div>

         {/* Chart Container */}
         <div className='margin-bottom-m'>
            <ProficiencyReportProgressBar skillData={row} size='md' />
         </div>

         {/* Count Container */}
         <div className='space-between margin-top-xs'>
            <div className='flex'>
               <div className='flex flex-col report-count padding-right-s'>
                  <span
                     className='report-count-number primary'
                     data-test={`proficiency-report-completed-count-${title}`}
                  >
                     {row.completedAverage}
                  </span>
                  <span className='report-count-title'>Achieved</span>
               </div>
               <div className='flex flex-col report-count padding-right-s'>
                  <span className='report-count-number warning'>{row.presentedCount}</span>
                  <span className='report-count-title'>Presented</span>
               </div>
            </div>
            <div className='flex flex-col report-count'>
               <span className='report-count-number text-right'>{row.totalCount}</span>
               <span className='report-count-title program-total'>Program Total</span>
            </div>
         </div>
      </div>
   );

   return (
      <div className='flex flex-wrap proficiency-report-tile-container'>
         {Object.entries(props.skillBreakdown).map(([key, row]) => renderTile(key, row))}
      </div>
   );
};

export default ProficiencyReportOverviewTiles;
