import * as React from 'react';

import IconBuilderAudio from '@icons/activities/icon-builder-audio.svg';
import IconBuilderChoice from '@icons/activities/icon-builder-choice.svg';
import IconBuilderDiscussionBoard from '@icons/activities/icon-builder-discussion-board.svg';
import IconBuilderDropbox from '@icons/activities/icon-builder-dropbox.svg';
import IconBuilderFillBlanks from '@icons/activities/icon-builder-fill-blank.svg';
import IconBuilderGrouping from '@icons/activities/icon-builder-grouping.svg';
import IconBuilderImageLabeling from '@icons/activities/icon-builder-image-labeling.svg';
import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';
import IconBuilderReorder from '@icons/activities/icon-builder-reorder.svg';
import IconBuilderShortAnswer from '@icons/activities/icon-builder-short-answer.svg';
import IconBuilderSpokenResponse from '@icons/activities/icon-builder-spoken-response.svg';
import IconBuilderText from '@icons/activities/icon-builder-text.svg';
import IconBuilderVideo from '@icons/activities/icon-builder-video.svg';
import IconCursorTap from '@icons/nova-line/16-Selection&Cursors/cursor-tap.svg';
import IconBuilderVocabSet from '@icons/nova-line/17-Navigation/navigation-filter-text.svg';
import IconBuilderLesson from '@icons/nova-line/18-Content/content-book-4.svg';

import { ActivityContent, ActivityContentType } from './ActivityContent';
import { ActivityMode } from './ActivityMode';
import { ActivityPrompt, PromptType } from './ActivityPrompt';

export const ActivityItemType = { ...ActivityContentType, ...PromptType };

export type ActivityItemType = ActivityContentType | PromptType;

export type ActivityItemId<Mode extends ActivityMode> = Mode extends ActivityMode.create
   ? null
   : Mode extends ActivityMode.edit
   ? number | null | string
   : Mode extends ActivityMode.complete
   ? number
   : Mode extends ActivityMode.preview
   ? number
   : Mode extends ActivityMode.grade
   ? number
   : null;

export type NullableOnCreateOrEdit<Mode extends ActivityMode, T> = Mode extends
   | ActivityMode.create
   | ActivityMode.edit
   ? T | null
   : T;

export interface BaseActivityItem<Mode extends ActivityMode> {
   duplicateAssets?: boolean;
   id: ActivityItemId<Mode>;
   index: NullableOnCreateOrEdit<Mode, number>;
   itemType: NullableOnCreateOrEdit<Mode, ActivityItemType>;
   key: string;
}

export type ActivityItem<Mode extends ActivityMode> = ActivityContent<Mode> | ActivityPrompt<Mode>;

export const itemTypeDetails: Record<
   ActivityItemType,
   { icon: React.SVGFactory; label?: string; width?: number; height?: number; className?: string }
> = {
   [ActivityContentType.textContent]: { icon: IconBuilderText, label: 'Text Content' },
   [ActivityContentType.lessonContent]: {
      icon: IconBuilderLesson,
      label: 'Lesson',
      className: 'activity-builder-icon',
      width: 19,
      height: 19,
   },
   [ActivityContentType.vocabSet]: {
      icon: IconBuilderVocabSet,
      label: 'Vocab Set',
      className: 'activity-builder-icon',
      width: 19,
      height: 19,
   },
   [ActivityContentType.audioContent]: { icon: IconBuilderAudio, label: 'Audio Content' },
   [ActivityContentType.videoContent]: { icon: IconBuilderVideo, label: 'Video Content' },
   [PromptType.audioRecordingPrompt]: { icon: IconBuilderRecording },
   [PromptType.videoRecordingPrompt]: { icon: IconBuilderVideo },
   [PromptType.textPrompt]: { icon: IconBuilderShortAnswer },
   [PromptType.markTokensPrompt]: { icon: IconCursorTap, className: 'activity-builder-icon' },
   [PromptType.multipleChoicePrompt]: { icon: IconBuilderChoice },
   [PromptType.orderingPrompt]: { icon: IconBuilderReorder },
   [PromptType.groupingPrompt]: { icon: IconBuilderGrouping },
   [PromptType.imageLabelingPrompt]: { icon: IconBuilderImageLabeling },
   [PromptType.fillBlanksPrompt]: { icon: IconBuilderFillBlanks },
   [PromptType.spokenResponsePrompt]: { icon: IconBuilderSpokenResponse },
   [PromptType.discussionBoardPrompt]: { icon: IconBuilderDiscussionBoard },
   [PromptType.filePrompt]: { icon: IconBuilderDropbox },
};
