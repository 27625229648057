import * as React from 'react';

import AccountType from '@models/AccountType';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import DateTime from '@services/DateTimeService';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import Link from '@components/Common/Link';
import { AssignmentProfile } from './CourseDashboard';

interface ICalendarItemProps {
   assignment: AssignmentProfile;
}

const CalendarItem: React.FC<ICalendarItemProps> = ({
   assignment: { endDate, itemType, moduleItemId, startDate, submissionId, contentId, contentName },
}) => {
   const {
      activities: { completeSubmission, viewAllSubmissions },
      content,
      vocabSets: { overview: vocabSetOverview },
   } = Constants.routes;

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const isInstructor = userProfile?.accountType === AccountType.instructor;
   const isStudent = userProfile?.accountType === AccountType.student;

   const getType = (): Maybe<string> => {
      switch (itemType) {
         case ContentType.activity:
            return 'Activity';
         case ContentType.vocabSet:
            return 'Vocabulary Set';
      }

      console.warn('Unknown calendar item type: ', itemType);
      return undefined;
   };

   const getLink = (): Maybe<string> => {
      if (itemType === ContentType.activity) {
         if (isInstructor) {
            return viewAllSubmissions.replace(':moduleItemId', moduleItemId.toString());
         } else if (isStudent && submissionId) {
            return completeSubmission.replace(':submissionId', submissionId.toString());
         }
      } else if (itemType === ContentType.vocabSet) {
         if (isInstructor) {
            return vocabSetOverview.replace(':moduleItemId', moduleItemId.toString());
         }
         return content.viewVocabSet
            .replace(':vocabSetId', contentId.toString())
            .concat(`?moduleItemId=${moduleItemId}`);
      }
      return null;
   };

   const isOpen = startDate <= DateTime.now() || isInstructor;
   const link = getLink();

   return (
      <div className='calendar-event-row'>
         <div className='calendar-date-wraper'>
            <div className='calendar-date'>
               <label>
                  {endDate.getMonth() + 1}/{endDate.getDate()}
               </label>
            </div>
         </div>
         <div className='calendar-event'>
            <p>
               {isOpen && link ? (
                  <Link className='black' to={link}>
                     {contentName}
                  </Link>
               ) : (
                  contentName
               )}
            </p>
            <label>{getType()}</label>
         </div>
      </div>
   );
};

export default CalendarItem;
