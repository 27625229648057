import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconTick from '@icons/general/icon-tick.svg';
import { Course } from '@models/Course';
import { NationalExam } from '@models/NationalExam';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';

interface NationalExamSetupStatus {
   steps: readonly string[];
   completedSteps: readonly string[];
   practiceCourseTemplates: readonly Course[];
   exam: NationalExam;
   clonedCourses: readonly Course[];
}

const NationalExamSetup: React.FC = () => {
   const appContext = React.useContext(AppStateContext);

   const { examUrlSlug } = useParams<{ examUrlSlug: string }>();

   const [nationalExam, setNationalExam] = React.useState<NationalExam>();
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

   const [steps, setSteps] = React.useState<readonly string[]>([]);
   const [completedSteps, setCompletedSteps] = React.useState<readonly string[]>([]);
   const [currentStep, setCurrentStep] = React.useState<string>('');
   const [practiceCourseTemplates, setPracticeCourseTemplates] = React.useState<readonly Course[]>(
      [],
   );
   const [clonedCourses, setClonedCourses] = React.useState<readonly Course[]>([]);
   const [cloneInProgressCourses, setCloneInProgressCourses] = React.useState<readonly Course[]>(
      [],
   );

   const isLastStep = steps.length && currentStep === steps[steps.length - 1];
   const hasRegistered = completedSteps.includes('Register');

   React.useEffect(() => {
      setIsFetching(true);
      setIsInitialized(true);

      HttpService.getWithAuthToken<NationalExamSetupStatus>(
         `/api/national_exams/${examUrlSlug}/setup_status`,
      ).then((response) => {
         const data = response.data;
         setNationalExam(data.exam);
         setClonedCourses(data.clonedCourses);
         setSteps(data.steps);
         setCompletedSteps(data.completedSteps);
         setPracticeCourseTemplates(data.practiceCourseTemplates);
         const currentStepIndex = data.completedSteps.length ? data.completedSteps.length - 1 : 0;
         setCurrentStep(data.steps[currentStepIndex]);

         setIsFetching(false);
      });
   }, [examUrlSlug]);

   React.useEffect(() => {
      if (!nationalExam) {
         return;
      }

      const examAbbr = nationalExam.nameAbbr.toLowerCase();
      appContext.setBreadcrumbs({
         breadcrumbs: [
            {
               link: `/national_exams/${examAbbr}`,
               text: `${nationalExam.nameAbbr} Dashboard`,
            },
            {
               link: `/national_exams/${examAbbr}/setup`,
               text: `${nationalExam.nameAbbr.toString().replace('_', ' ')} Setup`,
            },
         ],
         next: null,
         prev: null,
      });
   }, [nationalExam]);

   if (isFetching || !isInitialized || !nationalExam) {
      return <Loader />;
   }

   const cloneCourse = (courseCode: string): void => {
      const course = practiceCourseTemplates.find((i) => i.courseCode === courseCode);
      if (!course) {
         return;
      }
      const postData = {
         notifyUser: false,
         async: false,
         allowDuplicate: true,
         assignmentSettings: {
            isAssigned: true,
            gradeAutomatically: true,
            attemptsAllowed: 3,
            endDate: course.endDate,
         },
      };

      setCloneInProgressCourses([...new Set([...cloneInProgressCourses, course])]);
      HttpService.postWithAuthToken<{ msg: string; id: number; name: string }>(
         `/api/courses/${courseCode}/clone`,
         snakeCaseKeys(postData),
         { handleNotFound: false },
      )
         .then((response) => {
            const { id, name } = response.data;
            if (!appContext.currentCourses.find((x) => x.id === id)) {
               appContext.appendCourse({
                  id,
                  name,
                  demo: false,
                  needsToPurchaseLicense: false,
                  trialEndOn: null,
               });
            }
            setCompletedSteps([...new Set([...completedSteps, 'Select Courses'])]);

            setCloneInProgressCourses((prev) => prev.filter((c) => c !== course));
            const newClonedCourse = {
               id,
               name,
               clonedFromId: course.id,
            } as Course;
            setClonedCourses((prev) => [...prev, newClonedCourse]);
         })
         .catch((err) => {
            throw err;
         });
   };

   let canAdvanceStep = false;
   if (currentStep === 'Welcome') {
      canAdvanceStep = true;
   }

   const advanceStep = (): void => {
      const currentStepIndex = steps.indexOf(currentStep);
      if (currentStepIndex === steps.length - 1) {
         return;
      }
      const nextStep = steps[currentStepIndex + 1];

      setCompletedSteps([...new Set([...completedSteps, currentStep])]);
      setCurrentStep(nextStep);
   };

   const stepContent: Record<string, () => React.ReactElement> = {};

   // TODO: Find the right class to use here to get the intended style
   stepContent['Welcome'] = () => {
      if (nationalExam.hasPublicPracticeCourses) {
         return (
            <>
               <p className='text'>
                  Welcome to the {nationalExam.name}, hosted on Lingco! Over the next few minutes
                  we'll get you set up with review material you can use to prepare your class for
                  the {nationalExam.nameAbbr.toString().toUpperCase().replace('_PREP', '')}.
               </p>
               <p className='text'>We'll walk you through a few key steps:</p>
               <ol>
                  <li>
                     Create your own copy of the review course (or courses). It will already have
                     review material added to it.
                  </li>
                  <li>Invite your students and colleagues to access these courses.</li>
                  <li>
                     Learn how to preview the included material and track your students' progress.
                  </li>
               </ol>
               <br />
               <p className='text'>
                  Click the blue "Next" button in the upper right to get started.
               </p>
            </>
         );
      } else if (nationalExam.hasLingcoRegistration) {
         return (
            <>
               <p className='text'>
                  Welcome to the {nationalExam.name}, hosted on Lingco! Over the next few minutes
                  we'll get you set up with the material you can use to prepare your students for
                  the {nationalExam.nameAbbr.toString().toUpperCase()}.
               </p>
               <p className='text'>We'll walk you through a few key steps:</p>
               <ol>
                  <li>
                     Register for the {nationalExam.name}. As part of this process, you'll specify
                     the levels your students are in, and we'll generate an exam course (with
                     pre-built material) for each of those levels.
                  </li>
                  <li>Invite your students and colleagues to access these exam courses.</li>
                  <li>
                     Learn how to preview the included material and track your students' progress.
                  </li>
               </ol>
               <br />
               <p className='text'>
                  Click the blue "Next" button in the upper right corner to get started.
               </p>
            </>
         );
      } else {
         throw Error('Unexpected exam configuration');
      }
   };

   const renderCloneAction = (course: Course): React.ReactNode => {
      const alreadyCloned = !!clonedCourses.find((c) => c.clonedFromId === course.id);
      const cloneInProgress = !!cloneInProgressCourses.find((c) => c.id === course.id);
      const buttonText = alreadyCloned ? 'Course Created' : 'Create Course';

      return (
         <Button
            loading={cloneInProgress}
            disabled={alreadyCloned}
            onClick={() => (course.courseCode ? cloneCourse(course.courseCode) : undefined)}
         >
            {buttonText}
         </Button>
      );
   };

   const renderViewAction = (course: Course): React.ReactNode => {
      const clonedCourse = clonedCourses.find((c) => c.clonedFromId === course.id);
      return clonedCourse ? (
         <Link className='btn green' to={`/courses/${clonedCourse.id}`}>
            Go to course
         </Link>
      ) : null;
   };

   const columns: readonly Column<Course>[] = [
      { id: 'Id', header: 'Type', cell: (i) => i.id, show: false },
      { id: 'Course', header: 'Course Name', cell: (i) => i.name },
      { id: 'Action', header: '', cell: renderCloneAction },
      { id: 'View', header: '', cell: renderViewAction },
      { id: 'Empty', header: '', cell: () => '' },
   ];

   stepContent['Register'] = () => (
      <div>
         <p className='text'>
            To get started, you'll need to complete our simple, one-page registration form. We'll
            gather some info about you, your school, and your students, and then we'll create exam
            courses with material for your students.{' '}
         </p>
         <p className='text'>
            Payment won't be made directly on Lingco - we'll email you a registration confirmation,
            and you (or someone else at your school) can follow the instructions on the registration
            confirmation to submit payment.
         </p>
         <Link
            data-test='button-start-registration'
            className='btn green'
            disabled={hasRegistered}
            to={`/national_exams/${nationalExam.shortName}/register`}
         >
            Start registration
         </Link>

         {hasRegistered && (
            <div className='alert alert-success'>
               <p className='text'>
                  You've successfully registered, but you can always order more seats
               </p>
               <p>
                  <Link
                     className='btn green'
                     to={`/national_exams/${nationalExam.shortName}/register`}
                  >
                     Order more seats
                  </Link>
               </p>
            </div>
         )}
      </div>
   );

   stepContent['Select Courses'] = () => {
      const cloneInstructions =
         "The following courses are available as review material - no registration required.  Click the 'Create Course' button next to the courses you want to use, and we'll generate them for you. It'll take about 10 seconds per course.";
      const successMessage = `
         Great! Now you can invite your students, review the material, and get acquainted with Lingco.
         We have a few help articles to get you started, but if you have any other questions,
         you can reach out to us on chat using the purple question mark in the bottom-right corner of your screen.
      `;
      const orderedCourses = _.sortBy(practiceCourseTemplates, (x) => x.name.toLowerCase());
      return (
         <div>
            <p className='text'>{cloneInstructions}</p>
            <Table<Course> columns={columns} rowKey='id' rows={orderedCourses} />
            {!!clonedCourses.length && (
               <div className='alert alert-success'>
                  <p className='text'>{successMessage}</p>
                  <p className='text'>
                     You can get to your courses by using the &quot;Courses&quot; or{' '}
                     {nationalExam.nameAbbr} links in the left sidebar.
                  </p>
                  <div className='title'>Learn how to:</div>
                  <ul>
                     <li>
                        <Link
                           className='alert-link'
                           external
                           to='https://help.lingco.io/en/articles/1897690-adding-users-to-a-course'
                        >
                           Invite your students to a course
                        </Link>
                     </li>
                     <li>
                        <Link
                           className='alert-link'
                           external
                           to='https://help.lingco.io/en/articles/5604593-previewing-course-material'
                        >
                           Preview the material in your course
                        </Link>
                     </li>
                     <li>
                        <Link
                           className='alert-link'
                           external
                           to='https://help.lingco.io/en/articles/5339466-viewing-student-grades'
                        >
                           View your students&apos; progress
                        </Link>
                     </li>
                  </ul>
                  <p className='text'>
                     Feel free to browse the{' '}
                     <Link className='alert-link' external to='https://help.lingco.io/en/'>
                        rest of our help articles
                     </Link>{' '}
                     too!
                  </p>
               </div>
            )}
         </div>
      );
   };

   const renderStepsList = (): React.ReactNode => (
      <div>
         {steps.map((step) => {
            const extraClasses = step === currentStep ? 'active' : '';
            const isCompleted = completedSteps.includes(step);
            return (
               <div
                  key={step}
                  className={classnames('national-exam-dashboard-step pointer', extraClasses)}
                  onClick={() => setCurrentStep(step)}
               >
                  <div className='title'>{step}</div>
                  <div className='step-completed'>
                     <div className='completed-icon'>
                        <IconTick fill={isCompleted ? '#4A7D35' : '#C5C5C5'} />
                     </div>
                  </div>
               </div>
            );
         })}
      </div>
   );

   return (
      <>
         {nationalExam && <DocumentTitle>{`${nationalExam.name} Dashboard`}</DocumentTitle>}
         <div className='content-main margin-right-m'>
            <div className='card no-padding national-exam-dashboard-container'>
               <div className='row'>
                  <div className='col-xs-12 col-md-3'>
                     <div className='national-exam-setup-sidebar-title title medium'>
                        {nationalExam.nameAbbr.replace('_', ' ')} Setup
                     </div>
                     {renderStepsList()}
                  </div>
                  <div className='col-xs-12 col-md-9 national-exam-setup-content'>
                     <div className='card-title full-width module-content-course-title has-button'>
                        <div className='flex flex-1 items-center'>
                           <div className='flex-1 title'>{currentStep}</div>
                           <div>
                              {!isLastStep && (
                                 <Button
                                    disabled={!canAdvanceStep}
                                    data-test='button-next'
                                    onClick={advanceStep}
                                 >
                                    Next
                                 </Button>
                              )}
                           </div>
                        </div>
                     </div>
                     <div>{stepContent[currentStep]()}</div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default NationalExamSetup;
