import * as React from 'react';

import Link from '@components/Common/Link';

import Logo from '../../../../assets/logo.svg';

const NavbarMobile: React.FC = () => (
   <div className='navbar-mobile'>
      <div className='row'>
         <div className='col-xs-12'>
            <div className='logo'>
               <Link to='/'>
                  <Logo className='logo' />
               </Link>
            </div>
         </div>
      </div>
   </div>
);

export default NavbarMobile;
