import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import { AdminNationalExam } from '@models/NationalExam';
import AdminNationalExamService from '@services/AdminNationalExamService';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import { Column } from '@components/Common/Table';
import { renderDateCell } from '@components/Common/TableCellHelpers';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';

const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [],
};

const ExamsTable: React.FC = () => {
   const navigate = useNavigate();
   const columns: readonly Column<AdminNationalExam>[] = [
      { id: 'id', header: 'Exam id', canSort: true, cell: (i) => orderLink(i.id) },
      { id: 'name', header: 'Exam Name', canSort: true, cell: (i) => i.name },
      {
         id: 'exam_year',
         header: 'Exam Year',
         canSort: true,
         cell: (i) => i.examYear,
      },
      {
         id: 'reg_start_date',
         header: 'Reg. Start Date',
         canSort: true,
         cell: (i) => renderDateCell(i.regStartDate),
      },
      {
         id: 'reg_end_date',
         header: 'Reg. End Date',
         canSort: true,
         cell: (i) => renderDateCell(i.regEndDate),
      },
      {
         id: 'late_reg_date',
         header: 'Late Reg. Date',
         canSort: true,
         cell: (i) => renderDateCell(i.lateRegDate),
      },
   ];

   const orderLink = (id: number): React.ReactNode => {
      const linkToExam = Constants.routes.admin.nationalExam.examSummaryDetails.replace(
         ':examId',
         `${id}`,
      );
      return <Link to={linkToExam}>{`${id}`}</Link>;
   };

   const onAddRowClick = (): void => {
      navigate(Constants.routes.admin.nationalExam.newExam);
   };

   return (
      <FilterableTable<AdminNationalExam>
         attributes={[]}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'id', desc: true }]}
         pageTitle='Exams'
         searchInputPlaceholder='Search Exams'
         rowsLoader={AdminNationalExamService.getExams}
         onAddRowClick={onAddRowClick}
      />
   );
};

export default ExamsTable;
