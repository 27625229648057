import { Maybe } from '@models/Core';
import { ProficiencyLevelName } from '@models/Proficiency';

import { BaseActivityItem, NullableOnCreateOrEdit } from './ActivityItem';
import { ActivityBuilderMode, ActivityCompleterMode, ActivityMode } from './ActivityMode';

export enum PromptType {
   discussionBoardPrompt = 'discussion_board_prompt',
   filePrompt = 'file_prompt',
   fillBlanksPrompt = 'fill_blanks_prompt',
   groupingPrompt = 'grouping_prompt',
   imageLabelingPrompt = 'image_labeling_prompt',
   markTokensPrompt = 'mark_tokens_prompt',
   multipleChoicePrompt = 'multiple_choice_prompt',
   orderingPrompt = 'ordering_prompt',
   audioRecordingPrompt = 'recording_prompt',
   spokenResponsePrompt = 'spoken_response_prompt',
   textPrompt = 'text_prompt',
   videoRecordingPrompt = 'video_prompt',
}

export interface ActivityPromptScore {
   submissionId: number;
   responseId: number;
   completed: boolean;
   graded: boolean;
   score: number;
}

export interface BaseActivityPrompt<Mode extends ActivityMode> extends BaseActivityItem<Mode> {
   itemType: NullableOnCreateOrEdit<Mode, PromptType>;
   /** Html string of prompt directions */
   description: string;
   /** Prompt weight (stored in db as float) */
   weight: string;
   /** Not presently used */
   hint: string;
   /** Not presently used */
   feedback?: string;
   /** Only in grader mode */
   scores: Mode extends ActivityMode.grade ? readonly ActivityPromptScore[] : undefined;
   /** Only in grader mode */
   percentageGraded: Mode extends ActivityMode.grade ? number : undefined;
   canDoStatementName: Maybe<string>;
   canDoStatementId: Maybe<number>;
   canDoStatementSkill: Maybe<string>;
   canDoStatementLevel: Maybe<ProficiencyLevelName>;
}

export enum TextPromptLength {
   short = 'short',
   long = 'long',
}

export interface TextPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.textPrompt;
   length: TextPromptLength;
   showWordCount: boolean;
}

export enum MultipleChoiceScoringMethod {
   /** Full points if user selects all of the correct answers and none of the incorrect answers, otherwise 0. */
   allOrNothing = 'all_or_nothing',
   /** Total number of correct answers selected minus total number of incorrect answers */
   correctMinusIncorrect = 'correct_minus_incorrect',
   /** Total number of correct answers and ignores incorrect answers  */
   correct = 'correct',
}

export interface MultipleChoiceOption<Mode extends ActivityMode> {
   /** Potentially null in the builder mode */
   id: Mode extends ActivityBuilderMode ? Maybe<number> : number;
   /** Position of option within the list. Not shown to student if shuffled */
   index?: number;
   /** Html string */
   data: string;
   /** 1 if correct and 0 if incorrect. Only present when answers are shown */
   score?: number;
   /** Feedback to show student if option is selected. Only present when answers are shown */
   feedback?: string;
   /** Local only */
   key?: string;
}

export interface MultipleChoicePrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.multipleChoicePrompt;
   /** Multiple correct answers. Checkboxes are shown instead of radio buttons */
   multiple: boolean;
   /** Order of options is randomized for student */
   shuffle: boolean;
   showFeedback: boolean;
   scoringMethod: Maybe<MultipleChoiceScoringMethod>;
   options: readonly MultipleChoiceOption<Mode>[];
}

export interface OrderingOption<Mode extends ActivityMode> {
   id: Mode extends ActivityBuilderMode ? Maybe<number> : number;
   /** Only present when answers are shown */
   index?: number;
   /** Only present when answers are shown */
   feedback?: string;
   /** Html string */
   data: string;
   /** Local only */
   key: Mode extends ActivityBuilderMode ? string : undefined;
}

export interface OrderingPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.orderingPrompt;
   options: readonly OrderingOption<Mode>[];
}

export interface GroupingPromptItem {
   id: string | number;
   /** Only present when answers are shown */
   index?: number;
   /** Html string */
   data: string;
   /** Only present when answers are shown */
   categoryId?: string | number;
}

export interface GroupingPromptCategory {
   id: string | number;
   index: number;
   name: string;
}

export interface GroupingPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.groupingPrompt;
   categories: readonly GroupingPromptCategory[];
   items: readonly GroupingPromptItem[];
}

export enum FillBlanksScoringMethod {
   caseInsensitive = 'case_insensitive',
   caseSensitive = 'case_sensitive',
   regex = 'regex',
}

export enum FillBlanksSize {
   /** Fit size of blank to content (plus 0-30%) */
   fitToContent = 'fit_to_content',
   /** Make all blanks the same size, based on the largest blank */
   fitToLargestBlank = 'fit_to_largest_blank',
}

export interface FillBlanksPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.fillBlanksPrompt;
   /** Html string of prompt contents */
   content: string;
   scoringMethod: FillBlanksScoringMethod;
   /** Enables student to drag words from a word bank */
   draggable: boolean;
   /** List of possible options */
   wordBank: Maybe<readonly string[]>;
   blankType: FillBlanksSize;
   /** In enabled, no duplicated in the wordbank - otherwise duplicates will appear multiple times in the word bank */
   showUniqueWords: boolean;
}

export interface MarkTokensPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.markTokensPrompt;
   /** Html string of prompt contents */
   content: string;
}

export interface ImageLabelingHotspot<Mode extends ActivityMode> {
   id: Mode extends ActivityBuilderMode ? string | number : number;
   /** Float of x position of hotspot */
   x: number;
   /** Float of y position of hotspot */
   y: number;
   /** Only present when answers are shown */
   content?: string;
}

export interface ImageLabelingPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.imageLabelingPrompt;
   storedFilename: string;
   hotspots: readonly ImageLabelingHotspot<Mode>[];
   /** List of possible options */
   wordBank: Mode extends ActivityCompleterMode ? readonly string[] : undefined;
   fileUrl: string | null;
   file?: File;
}

export interface AudioRecordingPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.audioRecordingPrompt;
   /** Allow student to upload a file instead of recording their response directly on Lingco */
   allowUploading: boolean;
   limitAttempts: boolean;
   attempts: number | null;
   limitDuration: boolean;
   duration: number | null;
}

export interface VideoRecordingPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.videoRecordingPrompt;
}

export interface SpokenResponsePrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.spokenResponsePrompt;
   acceptedResponses: readonly string[];
   saveRecording: boolean;
}

export interface DiscussionBoardPrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.discussionBoardPrompt;
   requirePostBeforeSeeingReplies: boolean;
}

export interface FilePrompt<Mode extends ActivityMode> extends BaseActivityPrompt<Mode> {
   itemType: PromptType.filePrompt;
}

export type ActivityPrompt<Mode extends ActivityMode> =
   | TextPrompt<Mode>
   | MultipleChoicePrompt<Mode>
   | OrderingPrompt<Mode>
   | GroupingPrompt<Mode>
   | FillBlanksPrompt<Mode>
   | MarkTokensPrompt<Mode>
   | ImageLabelingPrompt<Mode>
   | AudioRecordingPrompt<Mode>
   | VideoRecordingPrompt<Mode>
   | SpokenResponsePrompt<Mode>
   | DiscussionBoardPrompt<Mode>
   | FilePrompt<Mode>;
