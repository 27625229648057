import { Course, CourseMode, ProficiencyGoals } from '@models/Course';
import { ProficiencyLevelName } from '@models/Proficiency';
import DateTime from '@services/DateTimeService';

const proficiencyUndefinedProperties: Record<
   keyof ProficiencyGoals,
   ProficiencyLevelName | undefined
> = {
   listeningEntryLevel: undefined,
   listeningGoalLevel: undefined,
   readingEntryLevel: undefined,
   readingGoalLevel: undefined,
   speakingEntryLevel: undefined,
   speakingGoalLevel: undefined,
   writingEntryLevel: undefined,
   writingGoalLevel: undefined,
};

const createDefaultCourse = (): Course<CourseMode.create> => {
   const startDate = DateTime.now();
   startDate.setHours(0);
   startDate.setMinutes(0);
   startDate.setSeconds(0);

   const endDate = DateTime.now();
   endDate.setMonth(endDate.getMonth() + 4);
   endDate.setHours(0);
   endDate.setMinutes(0);
   endDate.setSeconds(0);

   const defaultDueTime = new Date(0);
   defaultDueTime.setHours(23);
   defaultDueTime.setMinutes(59);
   defaultDueTime.setSeconds(0);

   const course: Course<CourseMode.create> = {
      archived: false,
      courseCode: null,
      canEdit: true,
      defaultDueTime,
      demo: false,
      description: '',
      endDate,
      id: null,
      isClonedCourse: false,
      isNationalExamPractice: false,
      isOrganizationStandard: false,
      isSharedWithOrganization: false,
      isProtected: false,
      language: null,
      lmsConnections: [],
      name: '',
      proficiencyGoals: {
         ...proficiencyUndefinedProperties,
      },
      sections: [],
      shouldHideInstructorGradebook: false,
      shouldHideStudentGradebook: false,
      showVocabStats: false,
      startDate,
   };
   return course;
};

export { createDefaultCourse, proficiencyUndefinedProperties };
