import * as _ from 'lodash';

import { isGoToQuestionAction, isSetVariableAction } from '@components/Activity/Utils';
import { randomTempId } from '@helpers/RandomStringUtils';
import {
   ActivityLogicAction,
   ActivityLogicGroup,
   ActivityLogicRule,
   ActivityVariableField,
} from '@models/Activity';
import { Maybe } from '@models/Core';

export const isRule = (i: unknown): i is ActivityLogicRule =>
   _.isObject(i) &&
   ['id', 'parentId', 'index', 'field', 'subfield', 'operator', 'value', 'unit'].every((j) =>
      Object.prototype.hasOwnProperty.call(i, j),
   );

export const isRuleGroup = (i: unknown): i is ActivityLogicGroup =>
   _.isObject(i) && Object.prototype.hasOwnProperty.call(i, 'combinator');

export const removeMissingTagsFromGroup = (
   initalGroup: ActivityLogicGroup,
   tags: readonly string[],
): ActivityLogicGroup => {
   const removeMissingTagsFromRule = (rule: ActivityLogicRule): ActivityLogicRule => {
      if (
         rule.field === ActivityVariableField.tagScore &&
         !tags.includes(rule.subfield as string)
      ) {
         return {
            ...rule,
            subfield: null,
            operator: null,
            value: null,
            unit: null,
         };
      }
      return rule;
   };
   const removeMissingTagsFromGroupHelper = (group: ActivityLogicGroup): ActivityLogicGroup => ({
      ...group,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rules: group.rules.map((i) =>
         isRuleGroup(i) ? removeMissingTagsFromGroupHelper(i) : removeMissingTagsFromRule(i),
      ),
   });
   return removeMissingTagsFromGroupHelper(initalGroup);
};

export const removeVariableFromGroup = (
   initialGroup: ActivityLogicGroup,
   variableId: string | number,
): ActivityLogicGroup => {
   const removeVariableFromRule = (rule: ActivityLogicRule): ActivityLogicRule => {
      if (rule.field === ActivityVariableField.variable && rule.subfield === variableId) {
         return {
            ...rule,
            subfield: null,
            operator: null,
            value: null,
            unit: null,
         };
      }
      return rule;
   };
   const removeVariableFromAction = (action: ActivityLogicAction): ActivityLogicAction => {
      if (isSetVariableAction(action) && action.variableId === variableId) {
         return { ...action, variableId: null, variableValue: null };
      }
      return action;
   };
   const removeVariableFromGroupHelper = (group: ActivityLogicGroup): ActivityLogicGroup => ({
      ...group,
      actions: group.actions.map(removeVariableFromAction),
      rules: group.rules.map((i) =>
         isRuleGroup(i) ? removeVariableFromGroupHelper(i) : removeVariableFromRule(i),
      ),
   });
   return removeVariableFromGroupHelper(initialGroup);
};

export const resetVariableInGroup = (
   initialGroup: ActivityLogicGroup,
   variableId: string | number,
): ActivityLogicGroup => {
   const resetVariableInRule = (rule: ActivityLogicRule): ActivityLogicRule => {
      if (rule.field === ActivityVariableField.variable && rule.subfield === variableId) {
         return { ...rule, operator: null, value: null, unit: null };
      }
      return rule;
   };
   const resetVariableInAction = (action: ActivityLogicAction): ActivityLogicAction => {
      if (isSetVariableAction(action) && action.variableId === variableId) {
         return { ...action, variableValue: null };
      }
      return action;
   };
   const resetVariableInGroupHelper = (group: ActivityLogicGroup): ActivityLogicGroup => ({
      ...group,
      actions: group.actions.map(resetVariableInAction),
      rules: group.rules.map((i) =>
         isRuleGroup(i) ? resetVariableInGroupHelper(i) : resetVariableInRule(i),
      ),
   });
   return resetVariableInGroupHelper(initialGroup);
};

export const removeQuestionFromGroup = (
   initialGroup: ActivityLogicGroup,
   questionId: string | number,
): ActivityLogicGroup => {
   const removeQuestionFromAction = (action: ActivityLogicAction): ActivityLogicAction => {
      if (isGoToQuestionAction(action) && action.questionId === questionId) {
         return { ...action, questionId: null };
      }
      return action;
   };
   const removeQuestionFromGroupHelper = (group: ActivityLogicGroup): ActivityLogicGroup => ({
      ...group,
      actions: group.actions.map(removeQuestionFromAction),
      rules: group.rules.map((i) => (isRuleGroup(i) ? removeQuestionFromGroupHelper(i) : i)),
   });
   return removeQuestionFromGroupHelper(initialGroup);
};

export const cloneGroup = (initialGroup: Maybe<ActivityLogicGroup>): Maybe<ActivityLogicGroup> => {
   if (!isRuleGroup(initialGroup)) {
      return null;
   }
   const replaceId = <T extends ActivityLogicRule | ActivityLogicAction>(i: T): T => ({
      ...i,
      id: randomTempId(),
   });
   const fixGroupIds = (group: ActivityLogicGroup): ActivityLogicGroup => ({
      ...group,
      id: randomTempId(),
      actions: group.actions.map(replaceId),
      rules: group.rules.map((i) => (isRuleGroup(i) ? fixGroupIds(i) : replaceId(i))),
   });
   return fixGroupIds(_.cloneDeep(initialGroup));
};
