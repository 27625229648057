import { IApplicationState } from '@models/ApplicationState';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';

import { actionFunctions } from '@redux/Actions';
import ActivityBuilder, { ActivityBuilderProps, ActivityBuilderState } from './ActivityBuilder';

const mapStateToProps = (
   state: IApplicationState,
   ownProps: Partial<ActivityBuilderProps>,
): Partial<ActivityBuilderProps> => ({
   activityRecovery: state.activityRecovery,
   params: ownProps.params,
   navigate: ownProps.navigate,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): Partial<ActivityBuilderProps> => ({
   setActivityRecovery: (state: ActivityBuilderState, existing: boolean) => {
      dispatch(actionFunctions.setActivityRecovery(state, existing));
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityBuilder);
