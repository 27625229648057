/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash';

/**
 * Recursively converts object keys to camelCase
 */
export const camelCaseKeys = (object: any): any => {
   let camelCaseObject = _.cloneDeep(object);

   if (_.isArray(camelCaseObject)) {
      return _.map(camelCaseObject, camelCaseKeys);
   }
   if (_.isString(camelCaseObject)) {
      return camelCaseObject;
   }
   camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => _.camelCase(key));

   return _.mapValues(camelCaseObject, (value) => {
      if (_.isPlainObject(value)) {
         return camelCaseKeys(value);
      } else if (_.isArray(value) && _.every(value, _.isObject)) {
         return _.map(value, camelCaseKeys);
      }
      return value;
   });
};

/**
 * Recursively converts object keys to snake_case
 */
export const snakeCaseKeys = (object: any): any => {
   let snakeCaseObject = _.cloneDeep(object);

   if (_.isArray(snakeCaseObject)) {
      return _.map(snakeCaseObject, snakeCaseKeys);
   }
   if (_.isString(snakeCaseObject)) {
      return snakeCaseObject;
   }
   snakeCaseObject = _.mapKeys(snakeCaseObject, (value, key) => _.snakeCase(key));

   return _.mapValues(snakeCaseObject, (value) => {
      if (_.isPlainObject(value)) {
         return snakeCaseKeys(value);
      } else if (_.isArray(value) && _.every(value, _.isObject)) {
         return _.map(value, snakeCaseKeys);
      }
      return value;
   });
};
