import * as React from 'react';

import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import { Course, CourseMode } from '@models/Course';

import Button from '@components/Common/Button';
import CloneCourseModal from './CloneCourseModal';

interface CloneCourseProps {
   course: Course<CourseMode.edit>;
}

const CloneCourse: React.FC<CloneCourseProps> = ({ course: { id, name, language } }) => {
   const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

   return (
      <>
         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>Clone Course</div>
            </div>
            <div className='content-form'>
               <div className='row'>
                  <div className='col-xs-9'>
                     <p className='course-option-description'>
                        Create a new independent copy of this course and all of its modules,
                        content, and rubrics with new due dates. Student enrollments and submissions
                        will not be carried over.
                     </p>
                  </div>
                  <div className='col-xs-3'>
                     <div className='flex-end margin-top-s'>
                        <Button
                           data-test='clone-course'
                           icon={<IconFileCopy className='icon-white' aria-hidden />}
                           onClick={() => setIsModalOpen(true)}
                        >
                           Clone Course
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {isModalOpen && (
            <CloneCourseModal
               courseId={id}
               courseLanguage={language}
               courseName={name}
               closeModal={() => setIsModalOpen(false)}
            />
         )}
      </>
   );
};

export default React.memo(CloneCourse);
