import { Maybe } from '@models/Core';
import Language from '@models/Language';

import { Callout } from './types/Callout';
import { Message } from './types/Conversation';
import { Example, Examples } from './types/Example';

export const createMessage = (message: Message): string => `
        <div class="message ${message.direction}" contenteditable="false">
            <div class="person" contenteditable="true">${message.person}</div>
            <p contenteditable="true" lang="es">${message.message}</p>
        </div>
    `;

export const createConversation = (messages: readonly Message[]): string => `
        <div class="conversation component">
            ${messages.map(createMessage).join('')}
        </div>
    `;

export const createCallout = (
   callout: Callout,
   body: string,
): string => `<div class="callout component ${callout.class}" contenteditable="false">
        <div class="callout-header">
            ${callout.header}
        </div>
        <div class="callout-body" contenteditable="true">
         ${body}
        </div>
    </div>`;

export const createExample = (example: Example, language: Maybe<Language>): string => {
   const PLACEHODER_IMAGE = 'https://via.placeholder.com/120x90';
   return `
        <div class="example">
            <div class="example-image" contenteditable="true">
                <img src="${example.imgSrc || PLACEHODER_IMAGE}" />
            </div>
            <div class="example-body" contenteditable="false">
                <div class="primary-text" contenteditable="true" ${
                   language ? `lang="${language}"` : ''
                }>
                    ${example.primaryText || '<br data-mce-bogus="1">'}
                </div>
                <div class="secondary-text" contenteditable="true" lang="en">
                    ${example.secondaryText || '<br data-mce-bogus="1">'}
                </div>
            </div>
        </div>
    `;
};

export const createExamples = (examples: Examples): string => `
        <div class="example-container component ${examples.layout}" contenteditable="false">
            <div class="example-header">
                Examples
            </div>
            <div class="examples">
                ${examples.examples.map((i) => createExample(i, examples.language)).join('')}
            </div>
        </div>
    `;

export const createInlineAudio = (text: string, src: string): string => `
    <span
      class="inline-audio"
      contenteditable="false"
    >
      ${text}
      <audio src="${src}"></audio>
    </span>
  `;
