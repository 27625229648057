/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';
import * as workerTimers from 'worker-timers';

import LogicDebugger from '@components/Activity/Builder/Logic/LogicDebugger';
import StyleWrapper from '@components/Activity/StyleWrapper';
import {
   isAudioRecordingResponse,
   isDiscussionBoardResponse,
   isFileResponse,
   isFillBlanksResponse,
   isGroupingResponse,
   isImageLabelingResponse,
   isMarkTokensResponse,
   isMultipleChoiceResponse,
   isOrderingResponse,
   isPrompt,
   isSpokenResponseResponse,
   isTextResponse,
   isVideoRecordingResponse,
} from '@components/Activity/Utils';
import AddContentToCourseModal from '@components/AddContentToCourseModal/AddContentToCourseModal';
import Button from '@components/Common/Button';
import ConfirmModal from '@components/Core/ConfirmModal';
import EmptyState from '@components/Core/EmptyState';
import NavigationPrompt from '@components/Core/NavigationPrompt';
import DataTestLoader from '@components/DataTestLoader';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import NewWindow from '@components/NewWindow';
import { datadogRum } from '@datadog/browser-rum';
import autobind from '@helpers/autobind';
import { BrowserActivity } from '@helpers/BrowserActivity';
import { isNetworkConnectionError } from '@helpers/Error';
import { relativeTime } from '@helpers/FormatTime';
import keyDeep from '@helpers/KeyDeep';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import omitDeep from '@helpers/OmitDeep';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import { randomShortId } from '@helpers/RandomStringUtils';
import { takeScreenshot } from '@helpers/ScreenShot';
import { TranslationObserver } from '@helpers/TranslationTracking';
import { sleep } from '@helpers/utils';
import IconContentBookLock from '@icons/nova-solid/18-Content/content-book-lock.svg';
import {
   Activity,
   ActivityCompleterMode,
   ActivityItemType,
   ActivityMode,
   ActivityPrompt,
   ActivityQuestion,
   ActivityResponse,
   ActivitySubmission,
   ActivityVariable,
   CurrentQuestionIdResponse,
   DiscussionBoardResponseEntry,
   PromptType,
} from '@models/Activity';
import Appearance from '@models/Appearance';
import BasicUserProfile from '@models/BasicUserProfile';
import { ContextInfo } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import ContentItemProfile from '@models/ContentItemProfile';
import { Maybe } from '@models/Core';
import { Assignment } from '@models/Course';
import { SubmissionEventDescription, SubmissionEventLevel } from '@models/ISubmissionEvent';
import Language from '@models/Language';
import { updateCurrentQuestionId } from '@services/ActivityService';
import { uploadAudio, uploadFile } from '@services/AssetService';
import ContentService from '@services/ContentService';
import DateTime from '@services/DateTimeService';
import HttpService from '@services/HttpService';
import { recordSubmissionEvent } from '@services/StudentEventService';
import UserService from '@services/UserService';
import AutoExecutingQueue from '@utilities/AutoExecutingQueue';
import { AxiosError } from 'axios';
import Mousetrap from 'mousetrap';
import { Subscription } from 'rxjs';

import { AppStateContext } from '../../../AppState';
import { isProd } from '../../../Config';
import Constants from '../../../Constants';
import mixPanelActions from '../../../Mixpanel';
import { RouteComponentProps } from '../../../types/Routing';
import ActivityHeader from './ActivityHeader';
import ActivitySidebar from './ActivitySidebar';
import DiscussionBoardListener from './DiscussionBoardListener';
import Question from './Question';
import TimeLimitScreen from './TimeLimitScreen';
import UnansweredQuestionModal from './UnansweredQuestionModal';

export interface GetActivityResponse {
   activity: Activity<ActivityCompleterMode>;
   submission: ActivitySubmission<ActivityCompleterMode>;
   canEdit: boolean;
   assignment?: Assignment;
   hasSubmissions: boolean;
   breadcrumbsInfo?: {
      moduleId: number;
      moduleItemId: number;
   };
}

export interface AutogradePreviewResponse {
   activity: Activity<ActivityMode.preview>;
   submission: ActivitySubmission<ActivityMode.preview>;
}

export interface ActivityCompleterContext {
   canEdit: boolean;
   disableSpellCheck: boolean;
   showResponseDistributions: boolean;
   isClosed: boolean;
   language: Language;
   mode: ActivityMode;
   showPointsPossible: boolean;
   variables: Record<string, boolean | string | number>;
}

export const ActivityCompleterContext = React.createContext<ActivityCompleterContext>({
   canEdit: false,
   disableSpellCheck: true,
   showResponseDistributions: false,
   isClosed: true,
   language: 'en',
   mode: ActivityMode.complete,
   showPointsPossible: false,
   variables: {},
});
export const ActivityProvider = ActivityCompleterContext.Provider;
export const ActivityConsumer = ActivityCompleterContext.Consumer;

export interface ActivityCompleterProps extends RouteComponentProps {
   mode: ActivityMode;
}

interface ActivityCompleterState {
   activityId: Maybe<number>;
   addToCourseModalOpen: boolean;
   allowGoingBack: boolean;
   assignment: Maybe<Assignment>;
   canEdit: boolean;
   canShowResponseDistributions: boolean;
   createdBy: Maybe<number>;
   description: string;
   disableCopyPaste: boolean;
   disableSpellCheck: boolean;
   disableSubmit: boolean;
   folderId: Maybe<number>;
   hasSubmittedSecretCode: boolean;
   isAutograding: boolean;
   isClosed: boolean;
   isFetching: boolean;
   isLate: boolean;
   isLoadingNextQuestion: boolean;
   isLocked: boolean;
   isSaving: boolean;
   isSettingQuestionId: boolean;
   isSubmitting: boolean;
   isTrackingEvents: boolean;
   language: Language;
   logicDebuggerOpen: boolean;
   moduleItemId: Maybe<number>;
   name: string;
   oneQuestionAtATime: boolean;
   pointsPossible: Maybe<number>;
   promptIdToCheck: Maybe<number>;
   questions: readonly ActivityQuestion<ActivityCompleterMode>[];
   relativeDue: string;
   secretCode: string;
   showPointsPossible: boolean;
   showQuestionList: boolean;
   showResponseDistributions: boolean;
   styleSheetId: Maybe<number>;
   submission: ActivitySubmission<ActivityCompleterMode>;
   submitUnfinishedModalOpen: boolean;
   submitSingleAttemptAndSingleQuestionModalOpen: boolean;
   variables: readonly ActivityVariable<ActivityCompleterMode>[];
}

class ActivityCompleter extends React.Component<ActivityCompleterProps, ActivityCompleterState> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   private browserActivitySubscription: Maybe<Subscription>;
   private readonly questionCardContainerRef: React.RefObject<HTMLDivElement>;
   private readonly secretKeyInput: React.RefObject<HTMLInputElement>;
   private readonly orderedAsynchronousQueue: AutoExecutingQueue;
   private updateIntervalId: Maybe<number>;
   private translationObserver: Maybe<TranslationObserver>;

   constructor(props: ActivityCompleterProps) {
      super(props);
      autobind(this);

      this.orderedAsynchronousQueue = new AutoExecutingQueue(500, (isProcessing: boolean) => {
         this.setState({ isSaving: isProcessing });
      });

      this.secretKeyInput = React.createRef<HTMLInputElement>();
      this.questionCardContainerRef = React.createRef<HTMLDivElement>();

      this.state = {
         activityId: null,
         addToCourseModalOpen: false,
         allowGoingBack: true,
         assignment: null,
         canEdit: false,
         canShowResponseDistributions: false,
         createdBy: null,
         description: '',
         disableCopyPaste: false,
         disableSpellCheck: false,
         disableSubmit: false,
         folderId: null,
         hasSubmittedSecretCode: false,
         isAutograding: false,
         isClosed: false,
         isFetching: true,
         isLate: false,
         isLoadingNextQuestion: false,
         isLocked: false,
         isSaving: false,
         isSettingQuestionId: false,
         isSubmitting: false,
         language: 'en',
         logicDebuggerOpen: false,
         moduleItemId: null,
         name: '',
         oneQuestionAtATime: false,
         pointsPossible: 0,
         questions: [],
         secretCode: '',
         showPointsPossible: true,
         showQuestionList: true,
         showResponseDistributions: false,
         styleSheetId: null,
         submission: {
            id: null,
            answeredPrompts: [],
            attempts: 0,
            currentQuestionId: null,
            evaluations: {},
            lastSubmittedOn: null,
            modifiedOn: null,
            responses: {},
            score: null,
            startedOn: null,
            variables: {},
         },
         isTrackingEvents: false,
         relativeDue: '',
         submitUnfinishedModalOpen: false,
         submitSingleAttemptAndSingleQuestionModalOpen: false,
         variables: [],
         promptIdToCheck: null,
      };

      this.translationObserver;
   }

   async translationObserverCallback(isTranslated: boolean, isAlert: boolean): Promise<void> {
      if (!isTranslated) {
         return;
      }
      const { assignment } = this.state;
      let imageBlob: Blob | undefined;

      try {
         await sleep(1000);
         imageBlob = await takeScreenshot();
      } catch (error) {
         // silent error on prod
         if (!isProd) {
            console.error(error);
            return;
         }

         datadogRum.addError(error);
      }

      const {
         submission: { id: submissionId },
      } = this.state;
      if (!submissionId) return;

      const eventDescription = isAlert
         ? SubmissionEventDescription.TranslatedPage
         : SubmissionEventDescription.PossibleTranslatedPage;

      const eventLevel = isAlert ? SubmissionEventLevel.alert : SubmissionEventLevel.warning;
      if (assignment) {
         recordSubmissionEvent(assignment, submissionId, eventDescription, eventLevel, imageBlob);
      }
   }

   componentDidMount(): void {
      this.fetchActivity();
   }

   componentWillUnmount(): void {
      const { assignment, submission } = this.state;
      if (this.updateIntervalId) {
         workerTimers.clearInterval(this.updateIntervalId);
         this.updateIntervalId = null;
      }
      this.browserActivitySubscription?.unsubscribe();
      this.translationObserver?.disconnect();
      if (submission.id && assignment) {
         recordSubmissionEvent(
            assignment,
            submission.id,
            SubmissionEventDescription.LeftAssignment,
         );
      }
   }

   componentDidUpdate(prevProps: ActivityCompleterProps): void {
      const {
         params: { activityId: prevActivityId, submissionId: prevSubmissionId },
      } = prevProps;
      const {
         params: { activityId, submissionId },
      } = this.props;

      if (prevActivityId !== activityId || prevSubmissionId !== submissionId) {
         this.fetchActivity();
      }

      if (!this.updateIntervalId && this.state.assignment && this.state.submission) {
         this.updateSubmissionStatus();

         // Using a worker to run the set interval timer avoids issues with the interval not working well
         // when the tab is put in the background
         this.updateIntervalId = workerTimers.setInterval(this.updateSubmissionStatus, 1000);
      }
   }

   updateSubmissionStatus(): void {
      this.setState((prevState) => {
         const {
            submission: { id: submissionId },
         } = prevState;
         const { isClosed, isLate, isLocked } = this.calculateNextStatus(
            prevState.assignment,
            prevState.submission,
         );
         const endDate = this.getEndDate(prevState.assignment, prevState.submission.startedOn);
         const relativeDue = endDate ? relativeTime(endDate) : '';

         if (!prevState.isClosed && isClosed) {
            this.translationObserver?.disconnect();
            if (prevState.assignment && submissionId) {
               recordSubmissionEvent(
                  prevState.assignment,
                  submissionId,
                  SubmissionEventDescription.AssignmentEndedWhileActive,
               );
            }

            if (prevState.assignment?.timeLimit) {
               this.handleSubmit(true);
            }
         }

         // Prevent re-rendering this component everytime our setInterval hits unless something changes
         if (
            prevState.isLate !== isLate ||
            prevState.isClosed !== isClosed ||
            prevState.relativeDue !== relativeDue ||
            prevState.isLocked !== isLocked
         ) {
            return { isLate, isClosed, relativeDue, isLocked } as Pick<
               ActivityCompleterState,
               'isLate' | 'isClosed' | 'relativeDue' | 'isLocked'
            >;
         }

         // Don't update the state
         return prevState;
      });
   }

   initializeBrowserEventTracking(): void {
      if (this.state.isTrackingEvents) {
         return;
      }

      const {
         params: { submissionId: strSubmissionId },
      } = this.props;
      const submissionId = Number(strSubmissionId);
      if (this.state.assignment) {
         recordSubmissionEvent(
            this.state.assignment,
            submissionId,
            SubmissionEventDescription.NavigatedToAssignment,
         );
      }

      this.browserActivitySubscription = BrowserActivity.subscribeToFocusChanges(
         (value: string) => {
            const { assignment, submission, isClosed } = this.state;
            if (value === 'Blurred') {
               let eventDescription: string = SubmissionEventDescription.LeftLingco;
               let isAlert = false;
               if (assignment?.timeLimit && submission.startedOn && !isClosed) {
                  eventDescription += ' during timed assignment';
                  isAlert = true;
               }
               if (assignment) {
                  recordSubmissionEvent(
                     assignment,
                     submissionId,
                     eventDescription as SubmissionEventDescription,
                     isAlert ? SubmissionEventLevel.alert : undefined,
                  );
               }
            } else if (value === 'Focused' && assignment) {
               recordSubmissionEvent(
                  assignment,
                  submissionId,
                  SubmissionEventDescription.ReturnedToLingco,
               );
            }
         },
      );

      this.setState({ isTrackingEvents: true });
   }

   getEndDate(assignment: Maybe<Assignment>, startedOn: Maybe<Date>): Maybe<Date> {
      if (!assignment) {
         return null;
      }
      const { timeLimit } = assignment;
      let endDate = assignment.endDate;
      if (!!timeLimit && timeLimit > 0 && startedOn && assignment.endDate) {
         endDate = new Date(
            Math.min(assignment.endDate.getTime(), startedOn.getTime() + timeLimit * 60 * 1000),
         );
      }
      return endDate;
   }

   attemptsMaxReached(
      assignment: Assignment,
      submission: ActivitySubmission<ActivityCompleterMode>,
      forLateWork = false,
   ): boolean {
      if (!assignment) {
         return false;
      }
      const { attemptsAllowed, gradeAutomatically } = assignment;
      const maxReached = submission.attempts === attemptsAllowed || false;

      if (
         !forLateWork &&
         attemptsAllowed !== undefined &&
         maxReached !== undefined &&
         gradeAutomatically !== undefined
      ) {
         return gradeAutomatically && maxReached;
      }

      return gradeAutomatically ? maxReached : !!submission.lastSubmittedOn;
   }

   isLate(assignment: Assignment, submission: ActivitySubmission<ActivityCompleterMode>): boolean {
      const now = DateTime.now();
      const endDate = this.getEndDate(assignment, submission.startedOn);
      if (!endDate) {
         return false;
      }

      if (!assignment.allowLateSubmission || now <= endDate) {
         return false;
      }

      const alreadySubmittedBeforeDueDate =
         submission.lastSubmittedOn && submission.lastSubmittedOn < endDate;
      if (alreadySubmittedBeforeDueDate) {
         return false;
      }

      const attemptsMaxReachedForLateWork = this.attemptsMaxReached(assignment, submission, true);
      return !attemptsMaxReachedForLateWork;
   }

   calculateNextStatus(
      assignment: Maybe<Assignment>,
      submission: ActivitySubmission<ActivityCompleterMode>,
   ): { isClosed: boolean; isLate: boolean; isLocked: boolean } {
      let isClosed = false;
      let isLate = false;
      let isLocked = false;

      if (assignment) {
         const assignmentIsOpen = DateTime.momentNow().isBetween(
            assignment.startDate,
            this.getEndDate(assignment, submission.startedOn),
         );
         isLate = this.isLate(assignment, submission);
         isClosed =
            !isLate && (!assignmentIsOpen || this.attemptsMaxReached(assignment, submission));
         isLocked =
            assignment.isLocked || (assignment.lockAfterClosed && !assignment.isLocked && isClosed);
      }

      return {
         isClosed,
         isLate,
         isLocked,
      };
   }

   setDisableSubmit(disableSubmit: boolean): void {
      this.setState({ disableSubmit });
   }

   async fetchActivity(): Promise<void> {
      const { userProfile, setBreadcrumbs } = this.context;
      if (!userProfile) {
         return;
      }
      const { accountType, id: userId } = userProfile;
      const {
         params: { activityId, submissionId },
         params,
         mode,
         location,
      } = this.props;
      let moduleItemId: Maybe<number> = Number(params.moduleItemId);
      const { secretCode } = this.state;
      this.setState({ isFetching: true });

      let url = '';
      if (mode === ActivityMode.preview) {
         url = `/api/content/activities/${activityId}?mode=${mode}`;
      } else if (mode === ActivityMode.complete) {
         if (submissionId) {
            url = `/api/activities/${submissionId}?mode=${mode}`;
         } else if (moduleItemId) {
            url = `/api/activities/${moduleItemId}/generate_submission?mode=${mode}`;
         }

         if (secretCode) {
            url += `&secret_code=${encodeURIComponent(secretCode)}`;
         }
      }

      return HttpService.getWithAuthToken<GetActivityResponse>(url).then((response) => {
         const {
            activity,
            assignment = null,
            breadcrumbsInfo,
            submission,
            canEdit,
         } = response.data;
         const {
            allowGoingBack,
            createdBy,
            description,
            disableCopyPaste,
            disableSpellCheck,
            folderId = null,
            styleSheetId,
            language,
            name,
            oneQuestionAtATime,
            pointsPossible,
            questions,
            showPointsPossible,
            showQuestionList,
            variables,
         } = activity;

         const queryParamModuleItemId = getQueryParameterAsNumber(location, 'moduleItemId', null);
         if (!moduleItemId) {
            if (breadcrumbsInfo?.moduleItemId) {
               moduleItemId = breadcrumbsInfo?.moduleItemId;
            } else if (isInteger(queryParamModuleItemId)) {
               moduleItemId = queryParamModuleItemId;
            } else {
               moduleItemId = null;
            }
         }

         const { isClosed, isLate, isLocked } = this.calculateNextStatus(assignment, submission);

         if (assignment && (assignment.isLocked || assignment.isTimedAndNotStarted)) {
            this.setState((prevState) => ({
               assignment,
               hasSubmittedSecretCode: prevState.isLocked,
               isClosed: true,
               isFetching: false,
               isLocked: assignment?.isLocked ?? false,
               name,
               pointsPossible,
               submission,
            }));
            this.secretKeyInput.current?.focus();
         } else {
            keyDeep(questions);
            this.setState({
               allowGoingBack,
               assignment,
               activityId: activity.id,
               canEdit,
               createdBy,
               description,
               disableCopyPaste,
               disableSpellCheck,
               folderId,
               styleSheetId,
               isClosed,
               isFetching: false,
               isLate,
               isLocked,
               language,
               moduleItemId,
               name,
               oneQuestionAtATime,
               pointsPossible,
               questions,
               showPointsPossible,
               showQuestionList,
               submission,
               variables,
            });
         }

         if (
            assignment?.trackStudentEvents &&
            !isLocked &&
            !isClosed &&
            !assignment.isTimedAndNotStarted
         ) {
            this.initializeBrowserEventTracking();

            if (!this.translationObserver) {
               this.translationObserver = new TranslationObserver(
                  this.translationObserverCallback,
                  true,
               ).connect();
            }
         }
         ContentService.getBreadcrumbs(
            {
               accountType,
               canEditContent: canEdit,
               contentId: activity.id,
               contentName: name,
               contentType: ContentType.activity,
               createdBy,
               folderId,
               userId,
               moduleItemId,
               submissionId: submission.id,
            },
            location,
         ).then(setBreadcrumbs);
         UserService.checkFeature('response_distributions').then((canShowResponseDistributions) => {
            this.setState({
               canShowResponseDistributions: canEdit && canShowResponseDistributions,
            });
         });
      });
   }

   editActivity(): void {
      const {
         routes: {
            content: { editActivity },
         },
      } = Constants;
      const {
         navigate,
         params: { activityId },
         mode,
      } = this.props;
      const { moduleItemId } = this.state;
      if (!activityId) {
         return;
      }
      let queryParams = '';
      if (moduleItemId && mode === ActivityMode.preview) {
         queryParams = `?moduleItemId=${moduleItemId}`;
      }
      navigate(editActivity.replace(':activityId', activityId.toString()).concat(queryParams));
   }

   renderQuestionItems(): readonly React.ReactNode[] {
      const { mode } = this.props;
      const {
         isSubmitting,
         isClosed,
         questions,
         oneQuestionAtATime,
         submission: { responses, evaluations, currentQuestionId },
      } = this.state;
      const showMissed =
         mode === ActivityMode.preview || (mode === ActivityMode.complete && isClosed);
      return questions
         .filter(
            (i) =>
               !!i.items.length &&
               (!oneQuestionAtATime || (oneQuestionAtATime && currentQuestionId === i.id)),
         )
         .map((question) => (
            <Question
               checkAnswers={this.handleCheckAnswers}
               evaluations={evaluations}
               isSubmitting={isSubmitting}
               key={question.id}
               postDiscussionBoardResponse={this.postDiscussionBoardResponse}
               question={question}
               responses={responses}
               saveResponse={this.saveAssetsAndResponse}
               setDisableSubmit={this.setDisableSubmit}
               setResponse={this.setResponse}
               showMissed={showMissed}
            />
         ));
   }

   appendDiscussionBoardResponse(
      promptId: number,
      entry: DiscussionBoardResponseEntry,
      authorProfile?: BasicUserProfile,
   ): void {
      this.setState((prevState) => {
         const response = prevState.submission.responses[promptId];
         if (!isDiscussionBoardResponse(promptId, prevState.questions, response)) {
            return null;
         }
         const authors = response.authors;
         if (response.entries.some((i) => i.id === entry.id)) {
            return null;
         }
         if (authorProfile) {
            authors[authorProfile.id] = authorProfile;
         }
         return {
            submission: {
               ...prevState.submission,
               responses: {
                  ...prevState.submission.responses,
                  [promptId]: {
                     ...response,
                     authors,
                     entries: [...response.entries, entry],
                  },
               },
            },
         };
      });
   }

   // We don't want to update all items, since that will cause each item component to rerender
   // and force certain content (like Interactive Videos) to reset
   buildQuestionsUpdate(
      prevQuestions: readonly ActivityQuestion<ActivityCompleterMode>[],
      gradedQuestions: readonly ActivityQuestion<ActivityCompleterMode>[],
      promptId?: number,
   ): readonly ActivityQuestion<ActivityCompleterMode>[] {
      const updatedQuestions = prevQuestions.map((question) => {
         const itemTypesWithNoAnswers: readonly ActivityItemType[] = [
            ActivityItemType.videoContent,
         ];
         const gradedQuestion = gradedQuestions.find((q) => q.id === question.id);
         if (!gradedQuestion) {
            console.warn('Could not find graded question in array.');
            return question;
         }
         question.items = gradedQuestion.items.map((gradedItem) => {
            // TODO: Fix underlying issue that is causing group prompts to be undefined in state (question.items)
            const originalItem = question.items.find((i) => i?.id === gradedItem.id);
            if (!originalItem) {
               return gradedItem;
            }

            const shouldUseGradedItem =
               gradedItem.id === promptId ||
               (!promptId && !itemTypesWithNoAnswers.includes(gradedItem.itemType));
            const itemToUse = shouldUseGradedItem ? gradedItem : originalItem;
            // we'll retain the original key to reduce re-rendering
            itemToUse.key = originalItem.key;
            return itemToUse;
         });
         return question;
      });
      return updatedQuestions;
   }

   buildSubmissionUpdate(
      prevSubmission: ActivitySubmission<ActivityCompleterMode>,
      gradedSubmission: ActivitySubmission<ActivityCompleterMode>,
      promptId?: number,
   ): ActivitySubmission<ActivityCompleterMode> {
      if (promptId) {
         const updatedSubmission = { ...prevSubmission };
         for (const [key, value] of Object.entries(updatedSubmission.responses)) {
            updatedSubmission.responses[parseInt(key)] =
               key === promptId.toString() ? gradedSubmission.responses[parseInt(key)] : value;
         }
         return updatedSubmission;
      } else {
         return { ...prevSubmission, ...gradedSubmission };
      }
   }

   async autoGradePreview(promptId?: number): Promise<void> {
      const {
         params: { activityId },
         mode,
      } = this.props;
      const { currentQuestionId, variables } = this.state.submission;
      const { name, language } = this.state;
      if (mode === ActivityMode.preview) {
         this.setState({ isAutograding: true });
         const responses = _.cloneDeep(this.state.submission.responses);
         omitDeep(responses, [
            'file',
            'blob',
            'fileUrl',
            'annotationAuthors',
            'instructorAnnotations',
            'authors',
         ]);
         const url = `/api/activities/${activityId}/preview/autograde`;
         return HttpService.postWithAuthToken<AutogradePreviewResponse>(
            url,
            snakeCaseKeys({ responses, currentQuestionId, variables }),
         ).then((response) => {
            const mergedContextInfo = this.getMergedContextInfo();
            mixPanelActions.track(
               'Activity Preview Submitted',
               _.omitBy(
                  snakeCaseKeys({
                     name,
                     language,
                     moduleItemId: mergedContextInfo?.moduleItemId,
                     courseId: mergedContextInfo?.courseId,
                     courseName: mergedContextInfo?.courseName,
                     moduleId: mergedContextInfo?.moduleId,
                     moduleName: mergedContextInfo?.moduleName,
                  }),
                  _.isNil,
               ),
            );

            const {
               activity: { questions },
               submission,
            } = response.data;
            keyDeep(questions);
            this.setState((prevState) => ({
               submission: this.buildSubmissionUpdate(prevState.submission, submission, promptId),
               questions: this.buildQuestionsUpdate(prevState.questions, questions, promptId),
               isAutograding: false,
            }));
         });
      }
      return Promise.resolve();
   }

   closeSubmitUnfinishedModal(): void {
      this.setState({ submitUnfinishedModalOpen: false });
   }

   closeSubmitSingleAttemptAndSingleQuestionModal(): void {
      this.setState({ submitSingleAttemptAndSingleQuestionModalOpen: false });
   }

   handleSubmitClick(): void {
      this.handleSubmit(false);
   }

   async handleSubmit(shouldPreventModal?: boolean): Promise<void> {
      const { mode } = this.props;
      const { allowGoingBack, isSubmitting } = this.state;
      if (mode === ActivityMode.preview) {
         this.autoGradePreview();
      } else {
         const incompleteQuestionIds = this.getIncompleteQuestionIds();
         if (incompleteQuestionIds.length && allowGoingBack && !shouldPreventModal) {
            this.setState({ submitUnfinishedModalOpen: true });
         } else if (
            !shouldPreventModal &&
            this.state.oneQuestionAtATime &&
            this.state.assignment?.attemptsAllowed === 1
         ) {
            this.setState({
               submitSingleAttemptAndSingleQuestionModalOpen: true,
            });
         } else if (!isSubmitting) {
            // Enqueuing the submission with the same queue used to save responses should ensure they
            // the proper ordering of saves.
            const executionQueueId = randomShortId();
            this.orderedAsynchronousQueue.enqueueAndExecute(this.submitActivity, executionQueueId);
         }
      }
      return Promise.resolve();
   }

   setStartedOn(): Promise<void> {
      const {
         submission: { id: submissionId },
      } = this.state;
      return HttpService.postWithAuthToken<{ startedOn: Date }>(
         `/api/activities/${submissionId}/started_on`,
      ).then((response) => {
         const { startedOn } = response.data;
         this.setState((prevState) => ({
            submission: {
               ...prevState.submission,
               startedOn,
            },
         }));
      });
   }

   handleAssignmentStart(): void {
      this.setState({ isFetching: true });
      this.setStartedOn().then(() => {
         // we need to wait until set started returns
         // or we create a race condition - because it updates the activity
         this.fetchActivity();
      });
   }

   handleCopyPaste(event: React.ClipboardEvent<HTMLDivElement>): void {
      if (this.state.disableCopyPaste) {
         event.preventDefault();
         // eslint-disable-next-line no-alert
         alert('Copying and Pasting is not enabled for this activity.');
      }
   }

   handleSecretCodeChange(event: React.ChangeEvent<HTMLInputElement>, callback?: () => void): void {
      const { value } = event.target;
      this.setState({ secretCode: value }, callback);
   }

   unlockWithSecretCode(event: React.SyntheticEvent | Mousetrap.ExtendedKeyboardEvent): void {
      event?.preventDefault();
      this.fetchActivity();
   }

   postDiscussionBoardResponse(itemId: number, entry: string, parentId: number): void {
      const { userProfile } = this.context;
      if (!userProfile) {
         return;
      }
      const { id: userId, firstName, lastName, profileImageUrl, email } = userProfile;
      const {
         submission: { id: submissionId },
      } = this.state;
      const { mode } = this.props;
      if (mode === ActivityMode.complete) {
         const url = `/api/activities/submissions/${submissionId}/responses/${itemId}/entries`;
         HttpService.postWithAuthToken<{ entryId: number }>(
            url,
            snakeCaseKeys({ entry, parentId }),
         );
      } else if (mode === ActivityMode.preview) {
         const authorProfile = {
            id: userId,
            firstName,
            lastName,
            profileImageUrl,
            email,
         };
         this.appendDiscussionBoardResponse(
            itemId,
            {
               id: randomShortId(),
               parentId,
               createdOn: DateTime.now(),
               entry,
               userId,
            },
            authorProfile,
         );
      }
   }

   getMergedContextInfo(): ContextInfo {
      const {
         breadcrumbs: { breadcrumbs },
      } = this.context;
      return Object.assign({}, ...breadcrumbs.map((i) => i.contextInfo ?? {}));
   }

   async submitActivity(): Promise<void> {
      const {
         assignment,
         submission: { id: submissionId },
      } = this.state;

      if (!submissionId) {
         return Promise.reject('No submission id');
      }

      if (this.state.isSubmitting) {
         return Promise.resolve();
      }

      this.closeSubmitUnfinishedModal();
      this.closeSubmitSingleAttemptAndSingleQuestionModal();

      this.setState({ isSubmitting: true });
      const url = `/api/activities/${submissionId}`;
      return HttpService.postWithAuthToken<{
         activity: Activity<ActivityCompleterMode>;
         submission: ActivitySubmission<ActivityCompleterMode>;
      }>(url).then((response) => {
         const {
            activity: { questions },
            submission,
         } = response.data;
         keyDeep(questions);
         const nextStatus = this.calculateNextStatus(assignment, submission);
         this.setState({
            questions,
            isSubmitting: false,
            submission,
            ...nextStatus,
         });
      });
   }

   async checkAnswers(promptId: number): Promise<void> {
      if (this.state.isSubmitting) {
         return Promise.resolve();
      }

      /*
         TODO: For simplicity sake, the MVP of this feature is hijacking
         some of the submission state variables. We may want to add separate
         state specifically for 'check answers' sometime in the future
      */
      this.setState({ isSubmitting: true });
      const {
         submission: { id: submissionId },
      } = this.state;
      const url = `/api/activities/${submissionId}/check_answers`;
      const body = {
         promptIds: [promptId],
      };
      return HttpService.postWithAuthToken<{
         responses: readonly ActivityResponse[];
      }>(url, snakeCaseKeys(body)).then((response) => {
         const { responses } = response.data;
         this.setState((prevState) => ({
            isSubmitting: false,
            promptIdToCheck: null,
            submission: {
               ...prevState.submission,
               responses: {
                  ...prevState.submission.responses,
                  ...responses,
               },
            },
         }));
      });
   }

   async handleCheckAnswers(promptId: number): Promise<void> {
      const { mode } = this.props;
      const { isSubmitting, isSaving } = this.state;
      if (mode === ActivityMode.preview) {
         this.autoGradePreview(promptId);
      } else {
         if (isSaving) {
            this.setState({ promptIdToCheck: promptId });
         } else if (!isSubmitting) {
            return this.checkAnswers(promptId);
         }
      }
      return Promise.resolve();
   }

   checkIfErrorIsTimeout(statusCode: number): boolean {
      return (
         statusCode === Constants.statusCodes.gatewayTimeout ||
         statusCode === Constants.statusCodes.requestTimeout
      );
   }

   async saveResponse(itemId: number, response: ActivityResponse, url: string): Promise<void> {
      omitDeep(response, ['file', 'blob', 'fileUrl']);
      const { assignment, submission } = this.state;

      await HttpService.putWithAuthToken<{ modifiedOn: Date }>(url, snakeCaseKeys({ response }))
         .then(({ data: { modifiedOn } }) => {
            this.setState(
               (prevState) => ({
                  ...this.calculateNextStatus(assignment, submission),
                  submission: {
                     ...prevState.submission,
                     modifiedOn,
                     answeredPrompts: [
                        ...new Set([...prevState.submission.answeredPrompts, itemId]),
                     ],
                  },
               }),
               () => {
                  if (this.orderedAsynchronousQueue.isEmpty && !this.state.isSubmitting) {
                     if (this.state.promptIdToCheck) {
                        this.checkAnswers(this.state.promptIdToCheck);
                     }
                  }
               },
            );
         })
         .catch((error) => {
            if (error.response && this.checkIfErrorIsTimeout(error.response.status)) {
               this.context.setNetworkError(error.response);
            }
         });
   }
   async saveAssetsAndResponse(itemId: number): Promise<void> {
      this.closeSubmitUnfinishedModal();
      const { assignment, submission } = this.state;
      const url = `/api/activities/submissions/${submission.id}/responses/${itemId}`;
      const { mode } = this.props;
      if (mode !== ActivityMode.complete) {
         return;
      }
      const nextStatus = this.calculateNextStatus(assignment, submission);

      if (nextStatus.isClosed) {
         this.setState({ ...nextStatus });
         return;
      }

      this.orderedAsynchronousQueue.enqueueAndExecute(async () => {
         const response = _.cloneDeep(this.state.submission.responses[itemId]);

         if (this.promptRequiresUpload(itemId, response)) {
            const responseWithAssetSaved = await this.uploadAsset(itemId, response);
            await this.saveResponse(
               responseWithAssetSaved.itemId,
               responseWithAssetSaved.response,
               url,
            );
         } else {
            await this.saveResponse(itemId, response, url);
         }
      }, url);
   }

   setResponse(promptId: string | number, response: ActivityResponse, callback?: () => void): void {
      this.setState(
         (prevState) => ({
            submission: {
               ...prevState.submission,
               responses: {
                  ...prevState.submission.responses,
                  [promptId]: response,
               },
            },
         }),
         () => {
            callback?.();
         },
      );
   }

   getIncompleteQuestionIds(): readonly number[] {
      const {
         submission: { responses },
         questions,
      } = this.state;

      return questions
         .filter(
            ({ items }) =>
               !items.every((item) => {
                  if (!isPrompt(item)) {
                     return true;
                  }
                  const response = responses[item.id];
                  return this.isResponseComplete(response, item);
               }),
         )
         .map((i) => i.id);
   }

   getCompleteResponseHandlers(): Record<
      PromptType,
      (response: ActivityResponse, item: ActivityPrompt<ActivityCompleterMode>) => boolean
   > {
      const { userProfile } = this.context;
      const {
         submission: { answeredPrompts },
         questions,
      } = this.state;

      return {
         [PromptType.textPrompt]: (response, item) =>
            isTextResponse(item.id, questions, response) && !!response.text.length,
         [PromptType.multipleChoicePrompt]: (response, item) =>
            isMultipleChoiceResponse(item.id, questions, response) &&
            Object.keys(response).length > 0,
         [PromptType.orderingPrompt]: (response, item) =>
            isOrderingResponse(item.id, questions, response) &&
            (answeredPrompts.includes(item.id) || response.length <= 2),
         [PromptType.groupingPrompt]: (response, item) =>
            isGroupingResponse(item.id, questions, response) &&
            response.every((i) => i.categoryId !== null),
         [PromptType.fillBlanksPrompt]: (response, item) =>
            isFillBlanksResponse(item.id, questions, response) &&
            response.every((i) => i.response.length),
         [PromptType.imageLabelingPrompt]: (response, item) =>
            isImageLabelingResponse(item.id, questions, response) &&
            Object.values(response).every((i) => i.response.length),
         [PromptType.audioRecordingPrompt]: (response, item) =>
            isAudioRecordingResponse(item.id, questions, response) &&
            (!!response.storedFilename || !!response.fileUrl || !!response.blob || !!response.file),
         [PromptType.videoRecordingPrompt]: (response, item) =>
            isVideoRecordingResponse(item.id, questions, response) && response.fileUrl !== null,
         [PromptType.filePrompt]: (response, item) =>
            isFileResponse(item.id, questions, response) && !!response.storedFilename,
         [PromptType.spokenResponsePrompt]: (response, item) =>
            isSpokenResponseResponse(item.id, questions, response) && response.attempts > 0,
         [PromptType.discussionBoardPrompt]: (response, item) =>
            isDiscussionBoardResponse(item.id, questions, response) &&
            response.entries.some((i) => i.userId === userProfile?.id),
         [PromptType.markTokensPrompt]: (response, item) =>
            isMarkTokensResponse(item.id, questions, response) && response.length > 0,
      };
   }

   isResponseComplete(response: ActivityResponse, prompt: ActivityPrompt<ActivityCompleterMode>) {
      const handlers = this.getCompleteResponseHandlers();
      const handler = handlers[prompt.itemType];
      return handler(response, prompt);
   }

   promptRequiresUpload(itemId: number, response: ActivityResponse): boolean {
      return (
         isAudioRecordingResponse(itemId, this.state.questions, response) ||
         isSpokenResponseResponse(itemId, this.state.questions, response) ||
         isFileResponse(itemId, this.state.questions, response)
      );
   }

   async uploadAsset(
      itemId: number,
      response: ActivityResponse,
   ): Promise<{ itemId: number; response: ActivityResponse }> {
      if (isAudioRecordingResponse(itemId, this.state.questions, response)) {
         const { blob, file } = response;
         if (file) {
            // There was a bug where a student can upload a file for a recording prompt and
            // it was being stored to the wrong bucket so I added this check in.
            if (file.type.includes('audio')) {
               response.storedFilename = (await uploadAudio(file)).filename;
            } else {
               response.storedFilename = await uploadFile(file);
            }
         } else if (blob) {
            response.storedFilename = (await uploadAudio(blob)).filename;
         }
      } else if (isSpokenResponseResponse(itemId, this.state.questions, response)) {
         const { blob } = response;
         if (blob) {
            response.storedFilename = (await uploadAudio(blob)).filename;
         }
      } else if (isFileResponse(itemId, this.state.questions, response)) {
         const { file } = response;
         if (file) {
            response.storedFilename = await uploadFile(file);
         }
      }
      return { itemId, response };
   }

   handleNextClick(): void {
      const {
         allowGoingBack,
         submission: { currentQuestionId },
      } = this.state;
      if (
         !allowGoingBack &&
         currentQuestionId &&
         this.getIncompleteQuestionIds().includes(currentQuestionId)
      ) {
         this.setState({ submitUnfinishedModalOpen: true });
      } else {
         this.goToNextQuestion();
      }
   }

   updateCurrentQuestionIdSuccessful(data: CurrentQuestionIdResponse): void {
      this.updateCurrentQuestionIdCallback(data.currentQuestionId);
      this.setState((prevState) => ({
         submission: { ...prevState.submission, variables: data.variables },
      }));
   }

   updateCurrentQuestionIdFailure(error: AxiosError): void {
      if (
         isNetworkConnectionError(error) ||
         (error.response && this.checkIfErrorIsTimeout(error.response.status))
      ) {
         const buttonSelected = this.state.isLoadingNextQuestion ? 'next' : 'previous';

         this.context.dispatchToast({
            title: 'Failed to move to next question',
            message: `Please try to click the ${buttonSelected} button again. If the issue persists please contact support.`,
            appearance: Appearance.danger,
            timeout: 0,
         });
      }

      this.setState({
         isSettingQuestionId: false,
         isLoadingNextQuestion: false,
      });
   }

   goToNextQuestion(): Promise<void> {
      const {
         params: { activityId },
         mode,
      } = this.props;
      const { questions, submission, isSettingQuestionId } = this.state;
      const currentIndex = questions.findIndex((i) => i.id === submission.currentQuestionId);
      this.closeSubmitUnfinishedModal();

      if (currentIndex === questions.length - 1 || isSettingQuestionId) {
         return Promise.resolve();
      }

      this.setState({ isLoadingNextQuestion: true, isSettingQuestionId: true });

      if (mode === ActivityMode.complete && submission.id) {
         const data = { evaluateLogic: true };

         updateCurrentQuestionId(submission.id, data)
            .then(this.updateCurrentQuestionIdSuccessful)
            .catch(this.updateCurrentQuestionIdFailure);
      } else if (mode === ActivityMode.preview && activityId) {
         const clonedResponses = _.cloneDeep(submission.responses);
         omitDeep(clonedResponses, [
            'file',
            'blob',
            'fileUrl',
            'annotationAuthors',
            'instructorAnnotations',
            'authors',
         ]);
         const data = {
            responses: clonedResponses,
            currentQuestionId: submission?.currentQuestionId,
            variables: submission.variables,
         };

         updateCurrentQuestionId(activityId, data, true)
            .then(this.updateCurrentQuestionIdSuccessful)
            .catch(this.updateCurrentQuestionIdFailure);
      }

      return Promise.resolve();
   }

   handlePreviousClick(): void {
      const { mode } = this.props;
      const {
         allowGoingBack,
         questions,
         submission: { currentQuestionId, id: submissionId },
         isSettingQuestionId,
      } = this.state;
      const currentIndex = questions.findIndex((i) => i.id === currentQuestionId);
      if (currentIndex < 1 || !allowGoingBack || isSettingQuestionId) {
         return;
      }
      this.setState({ isSettingQuestionId: true });
      const previousQuestion = questions[currentIndex - 1];
      if (mode === ActivityMode.complete && submissionId) {
         const data = { currentQuestionId: previousQuestion.id };
         updateCurrentQuestionId(submissionId, data)
            .then(() => {
               this.updateCurrentQuestionIdCallback(previousQuestion.id);
            })
            .catch(this.updateCurrentQuestionIdFailure);
      } else {
         this.updateCurrentQuestionIdCallback(previousQuestion.id);
      }
   }

   openLogicDebugger(): void {
      this.setState({ logicDebuggerOpen: true });
   }

   closeLogicDebugger(): void {
      this.setState({ logicDebuggerOpen: false });
   }

   openAddToCourseModal(): void {
      this.setState({ addToCourseModalOpen: true });
   }

   closeAddToCourseModal(): void {
      this.setState({ addToCourseModalOpen: false });
   }

   setCurrentQuestionId(currentQuestionId: number): void {
      const { isSettingQuestionId, questions, submission } = this.state;
      const { mode } = this.props;

      if (!isSettingQuestionId) {
         const currentIndex = questions.findIndex((i) => i.id === submission.currentQuestionId);
         const newIndex = questions.findIndex((i) => i.id === currentQuestionId);
         const movingForwardQuestions = newIndex > currentIndex;

         this.setState({
            isLoadingNextQuestion: movingForwardQuestions,
            isSettingQuestionId: true,
         });

         if (mode === ActivityMode.complete && submission.id) {
            updateCurrentQuestionId(submission.id)
               .then(() => {
                  this.updateCurrentQuestionIdCallback(currentQuestionId);
               })
               .catch(this.updateCurrentQuestionIdFailure);
         } else {
            this.updateCurrentQuestionIdCallback(currentQuestionId);
         }
      }
   }

   updateCurrentQuestionIdCallback(newCurrentQuestionId: number): void {
      this.setState(
         (prevState) => ({
            isLoadingNextQuestion: false,
            isSettingQuestionId: false,
            submitUnfinishedModalOpen: false,
            submission: {
               ...prevState.submission,
               currentQuestionId: newCurrentQuestionId,
            },
         }),
         () => {
            if (this.state.oneQuestionAtATime && this.questionCardContainerRef.current) {
               this.questionCardContainerRef.current.scrollTop = 0;
            }
         },
      );
   }

   renderAssignmentContent(): React.ReactNode {
      const { mode } = this.props;
      const {
         assignment,
         canEdit,
         disableSpellCheck,
         hasSubmittedSecretCode,
         isClosed,
         isFetching,
         isLocked,
         language,
         secretCode,
         showPointsPossible,
         showResponseDistributions,
         styleSheetId,
      } = this.state;
      const questionItems = this.renderQuestionItems();

      if (isLocked) {
         const lockedToDueClosed = isClosed && assignment?.lockAfterClosed;
         const description = lockedToDueClosed
            ? 'This assignment has been submitted and is now closed. All completed answers have been saved.'
            : 'Please enter the secret code provided by your instructor to unlock this assignment.';
         const primaryAction = !lockedToDueClosed && (
            <form onSubmit={this.unlockWithSecretCode}>
               <input
                  type='text'
                  placeholder='Enter your secret code'
                  autoComplete='off'
                  name='secretCode'
                  onChange={this.handleSecretCodeChange}
                  value={secretCode}
                  maxLength={32}
                  ref={this.secretKeyInput}
               />
               {hasSubmittedSecretCode && (
                  <p className='error'>The secret key you entered was incorrect.</p>
               )}
               <Button
                  onClick={this.unlockWithSecretCode}
                  loading={isFetching}
                  fullWidth
                  className='margin-top-m'
               >
                  Unlock
               </Button>
            </form>
         );
         return (
            <EmptyState
               className='activity-height'
               icon={<IconContentBookLock className='large' aria-hidden />}
               heading='Assignment is Locked'
               description={description}
               primaryAction={primaryAction}
            />
         );
      }

      const hasInvalidDateState = assignment && (!assignment?.endDate || !assignment?.startDate);
      if (hasInvalidDateState) {
         return (
            <EmptyState
               className='activity-height'
               icon={<IconContentBookLock className='large' aria-hidden />}
               heading='Invalid Start or End Date'
               description={
                  <p>Your instructor has not set a start or end date for this assignment.</p>
               }
            />
         );
      }

      if (assignment?.isTimedAndNotStarted && assignment?.timeLimit) {
         return (
            <TimeLimitScreen
               timeLimit={assignment.timeLimit}
               onStart={this.handleAssignmentStart}
            />
         );
      }

      return (
         <div
            className='question-card-container completer'
            onCopy={this.handleCopyPaste}
            onPaste={this.handleCopyPaste}
            ref={this.questionCardContainerRef}
            data-test='question-card-container-completer'
         >
            <StyleWrapper styleId={styleSheetId}>
               <ActivityProvider
                  value={{
                     canEdit,
                     disableSpellCheck,
                     isClosed,
                     language,
                     mode,
                     showPointsPossible,
                     showResponseDistributions,
                     variables: this.getMappedVariables(),
                  }}
               >
                  {questionItems}
               </ActivityProvider>
            </StyleWrapper>
         </div>
      );
   }

   getMappedVariables(): Record<string, boolean | string | number> {
      const {
         variables: activityVariables,
         submission: { variables: submissionVariables },
      } = this.state;
      if (!(activityVariables && submissionVariables)) {
         return {};
      }
      return Object.fromEntries(
         Object.entries(submissionVariables).map(([variableId, variableValue]) => {
            const { name: variableName } =
               activityVariables.find((i) => i.id === Number(variableId)) ?? {};
            return [variableName, variableValue];
         }),
      );
   }

   getContentItemProfile(): Maybe<ContentItemProfile> {
      const { activityId, createdBy, folderId, language, name } = this.state;
      if (activityId && language && createdBy) {
         return {
            createdBy,
            folderId,
            itemId: activityId,
            itemLanguage: language,
            itemName: name,
            itemType: ContentType.activity,
         };
      }
      return null;
   }

   toggleShowResponseDistributions(): void {
      this.setState((prevState) => ({
         showResponseDistributions: !prevState.showResponseDistributions,
      }));
   }

   showFinalSubmitWarning(): boolean {
      const {
         oneQuestionAtATime,
         submission: { currentQuestionId },
         questions,
      } = this.state;
      const questionIndex = oneQuestionAtATime
         ? questions.findIndex((i) => i.id === currentQuestionId)
         : null;
      const isLastQuestion = questionIndex !== null && questionIndex + 1 === questions.length;
      return isLastQuestion || !oneQuestionAtATime;
   }

   render() {
      const { userProfile } = this.context;
      const { mode } = this.props;

      const {
         addToCourseModalOpen,
         allowGoingBack,
         assignment,
         canEdit,
         canShowResponseDistributions,
         createdBy,
         description,
         disableSubmit,
         isAutograding,
         isClosed,
         isFetching,
         isLate,
         isLoadingNextQuestion,
         isLocked,
         isSaving,
         isSettingQuestionId,
         isSubmitting,
         logicDebuggerOpen,
         moduleItemId,
         name,
         oneQuestionAtATime,
         pointsPossible,
         questions,
         relativeDue,
         showQuestionList,
         showResponseDistributions,
         submission,
         submission: { currentQuestionId, modifiedOn, score, startedOn },
         submitSingleAttemptAndSingleQuestionModalOpen,
         submitUnfinishedModalOpen,
      } = this.state;

      if (isFetching && !isLocked) {
         return <Loader />;
      }

      const canDebugLogic = mode === ActivityMode.preview && questions.some((i) => !!i.logic);
      const questionIndex = oneQuestionAtATime
         ? questions.findIndex((i) => i.id === currentQuestionId)
         : null;
      const incompleteQuestionIds = this.getIncompleteQuestionIds();
      const finalSubmitWarning = this.showFinalSubmitWarning();
      const contentItemProfile = this.getContentItemProfile();

      return (
         <div className='content-main'>
            <DataTestLoader isLoading={isFetching || isSubmitting} />
            <DocumentTitle>{isFetching ? 'Loading Activity...' : name}</DocumentTitle>
            {moduleItemId && (
               <DiscussionBoardListener
                  appendDiscussionBoardResponse={this.appendDiscussionBoardResponse}
                  moduleItemId={moduleItemId}
               />
            )}
            <div className='card no-padding card-activity-builder complete'>
               <ActivityHeader
                  allowGoingBack={allowGoingBack}
                  assignment={assignment}
                  canDebugLogic={canDebugLogic}
                  canEdit={canEdit || createdBy === userProfile?.id}
                  canShowResponseDistributions={canShowResponseDistributions}
                  disableSubmit={disableSubmit}
                  isActivityOwner={createdBy === userProfile?.id}
                  isAutograding={isAutograding}
                  isClosed={isClosed}
                  isLoadingNextQuestion={isLoadingNextQuestion}
                  isLocked={isLocked}
                  isSaving={isSaving}
                  isSettingQuestionId={isSettingQuestionId}
                  isSubmitting={isSubmitting}
                  mode={mode}
                  moduleItemId={moduleItemId}
                  name={name}
                  oneQuestionAtATime={oneQuestionAtATime}
                  questionCount={questions.length}
                  questionIndex={questionIndex}
                  showResponseDistributions={showResponseDistributions}
                  startedOn={submission.startedOn}
                  editActivity={this.editActivity}
                  fetchActivity={this.fetchActivity}
                  onNextClick={this.handleNextClick}
                  onPreviousClick={this.handlePreviousClick}
                  onSubmitClick={this.handleSubmitClick}
                  openAddToCourseModal={this.openAddToCourseModal}
                  openLogicDebugger={this.openLogicDebugger}
                  toggleShowResponseDistributions={this.toggleShowResponseDistributions}
               />
               <div className='activity-container'>
                  <ActivitySidebar
                     allowGoingBack={allowGoingBack}
                     currentQuestionId={submission.currentQuestionId}
                     assignment={assignment}
                     attempts={submission.attempts}
                     description={description}
                     isClosed={isClosed}
                     isLate={isLate}
                     isLocked={isLocked}
                     isSaving={isSaving}
                     isSubmitting={isSubmitting}
                     lastSubmittedOn={submission.lastSubmittedOn}
                     mode={mode}
                     modifiedOn={modifiedOn}
                     oneQuestionAtATime={oneQuestionAtATime}
                     pointsPossible={pointsPossible}
                     questions={questions}
                     relativeDue={relativeDue}
                     score={score}
                     showQuestionList={showQuestionList}
                     startedOn={startedOn}
                     setCurrentQuestionId={this.setCurrentQuestionId}
                  />
                  {this.renderAssignmentContent()}
               </div>
               <NavigationPrompt
                  when={mode === ActivityMode.complete && !isClosed && isSaving}
                  message='Are you sure you want to quit? Changes you made may not be saved.'
               />
               {submitUnfinishedModalOpen && (
                  <UnansweredQuestionModal
                     allowGoingBack={allowGoingBack}
                     finalSubmitWarning={finalSubmitWarning}
                     incompleteQuestions={questions.filter((i) =>
                        incompleteQuestionIds.includes(i.id),
                     )}
                     onCancel={this.closeSubmitUnfinishedModal}
                     onClose={this.closeSubmitUnfinishedModal}
                     onPrimaryClick={
                        finalSubmitWarning ? this.submitActivity : this.goToNextQuestion
                     }
                     setCurrentQuestionId={this.setCurrentQuestionId}
                  />
               )}
               {submitSingleAttemptAndSingleQuestionModalOpen && (
                  <ConfirmModal
                     header='Submit Activity?'
                     message='Are you sure you are ready to submit?'
                     onClose={this.closeSubmitSingleAttemptAndSingleQuestionModal}
                     onPrimaryClick={this.submitActivity}
                     primaryClickText='Submit'
                     closeText='Cancel'
                  />
               )}
            </div>
            {logicDebuggerOpen && (
               <NewWindow copyStyles onUnload={this.closeLogicDebugger}>
                  <LogicDebugger variables={this.getMappedVariables()} />
               </NewWindow>
            )}
            {addToCourseModalOpen && contentItemProfile && (
               <AddContentToCourseModal
                  contentItemProfile={contentItemProfile}
                  onClose={this.closeAddToCourseModal}
               />
            )}
         </div>
      );
   }
}

export default ActivityCompleter;
