import * as React from 'react';

import Link from '@components/Common/Link';
import IconMenuHelp from '@icons/general/icon-menu-help.svg';
import Slider1 from '@icons/nova-line/03-Settings/slider-1.svg';
import IconChatBubbleSquare3 from '@icons/nova-line/10-Messages&Chat/chat-bubble-square-3.svg';
import IconContentBox2 from '@icons/nova-line/18-Content/content-box-2.svg';
import IconRewardsMedal1 from '@icons/nova-line/19-Votes&Rewards/rewards-medal-1.svg';
import IconWindowApplication3 from '@icons/nova-line/50-Applications/window-application-3.svg';
import IconFolderNetwork from '@icons/nova-line/86-Files-Folders/folder-network.svg';
import AccountType from '@models/AccountType';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants, { AbbreviationExamNameRecord } from '../../../Constants';
import CoursesDrawer from './CoursesDrawer';
import ExamsDrawer from './ExamsDrawer';

const Sidebar: React.FC = () => {
   const {
      routes: {
         contentLibrary: { library: contentLibraryRoute },
         dashboard: mainDashboard,
         courses: { dashboard },
         nationalExam,
         proficiencyTracking: { districtSummary },
         auth: { logout },
      },
      siteLinks: { helpDocs, feedback },
   } = Constants;

   const { currentCourses, availableNationalExams, userProfile } =
      React.useContext<AppStateContext>(AppStateContext);
   const navigate = useNavigate();

   if (!userProfile) {
      return;
   }
   const { accountType, isDistrictAdmin, permissions } = userProfile;

   // TODO: some period of time remove this logout code because this is a
   // temporary state for users already logged in when permissions were added
   if (permissions === undefined) {
      navigate(logout);
      return;
   }
   const canManageContentLibrary = permissions.canManageContentLibrary;

   const [courseDrawerOpen, setCourseDrawerOpen] = React.useState<boolean>(false);
   const [examDrawerOpen, setExamDrawerOpen] = React.useState<boolean>(false);

   const toggleCoursesDrawer = (): void =>
      setCourseDrawerOpen((prevCourseDrawerOpen) => !prevCourseDrawerOpen);
   const isInstructor = accountType === AccountType.instructor;

   const toggleExamsDrawer = (): void =>
      setExamDrawerOpen((prevExamDrawerOpen) => !prevExamDrawerOpen);

   return (
      <div className='product-side-nav-wrapper col-sm-1'>
         <div className='product-side-nav-container' role='navigation' aria-label='main navigation'>
            <CoursesDrawer open={courseDrawerOpen} onClose={() => setCourseDrawerOpen(false)} />
            <ExamsDrawer open={examDrawerOpen} onClose={() => setExamDrawerOpen(false)} />
            <div className='product-side-nav'>
               <ul>
                  <li>
                     <Link
                        data-test='dashboard'
                        to={
                           currentCourses.length
                              ? dashboard.replace(':courseId', currentCourses[0].id.toString())
                              : mainDashboard
                        }
                     >
                        <div className='nav-item-wrapper'>
                           <IconWindowApplication3 />
                           Dashboard
                        </div>
                     </Link>
                  </li>
                  <li>
                     <div
                        tabIndex={0}
                        role='button'
                        data-test='courses'
                        className='nav-item-wrapper'
                        onClick={toggleCoursesDrawer}
                        aria-controls='courses-drawer'
                        aria-expanded={courseDrawerOpen}
                     >
                        <IconFolderNetwork />
                        Courses
                     </div>
                  </li>
                  {isDistrictAdmin && (
                     <li>
                        <Link data-test='proficiency' to={districtSummary}>
                           <div className='nav-item-wrapper'>
                              <Slider1 />
                              Proficiency
                           </div>
                        </Link>
                     </li>
                  )}
                  {isInstructor && availableNationalExams?.length > 1 && (
                     <li>
                        <div
                           tabIndex={0}
                           role='button'
                           data-test='national-exam'
                           className='nav-item-wrapper text-center'
                           onClick={toggleExamsDrawer}
                           aria-controls='exams-drawer'
                           aria-expanded={examDrawerOpen}
                        >
                           <IconRewardsMedal1 />
                           National Exams
                        </div>
                     </li>
                  )}
                  {isInstructor && availableNationalExams?.length === 1 && (
                     <li>
                        <Link
                           to={nationalExam.dashboard.replace(
                              ':examUrlSlug',
                              availableNationalExams[0],
                           )}
                        >
                           <div
                              tabIndex={0}
                              role='button'
                              data-test='national-exam'
                              className='nav-item-wrapper text-center '
                           >
                              <IconRewardsMedal1 />
                              {AbbreviationExamNameRecord[availableNationalExams[0]]}
                           </div>
                        </Link>
                     </li>
                  )}
                  {isInstructor && canManageContentLibrary && (
                     <li>
                        <Link
                           data-test='library'
                           to={contentLibraryRoute.replace(':libraryName', 'personal')}
                        >
                           <div className='nav-item-wrapper'>
                              <IconContentBox2 />
                              Library
                           </div>
                        </Link>
                     </li>
                  )}
                  <li>
                     <Link external to={helpDocs}>
                        <div className='nav-item-wrapper'>
                           <IconMenuHelp />
                           Help
                        </div>
                     </Link>
                  </li>
                  {isInstructor && (
                     <li>
                        <Link external to={feedback}>
                           <div className='nav-item-wrapper'>
                              <IconChatBubbleSquare3 />
                              Feedback
                           </div>
                        </Link>
                     </li>
                  )}
               </ul>
            </div>
         </div>
      </div>
   );
};

export default Sidebar;
