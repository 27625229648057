import * as React from 'react';

import { Maybe } from '@models/Core';
import { Modifier, Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import Constants from '../../Constants';
import Beacon from './Beacon';
import Confetti from './Confetti';
import Cursor from './Cursor';
import {
   IOnboardingProps,
   IOnboardingStep,
   OnboardingStepType,
   OnboardingTaskState,
} from './Models';
import { OnboardingContext } from './OnboardingContext';
import Pointer from './Pointer';
import Post from './Post';
import VideoPointer from './VideoPointer';
import VideoPost from './VideoPost';

const Onboarding: React.FC = () => {
   const { onboardingAuthor } = Constants;

   const pointerSteps = [OnboardingStepType.pointer, OnboardingStepType.videoPointer];

   const {
      stepId,
      taskState,
      walkthrough,
      walkthroughVisible,
      clearCurrentTask,
      nextStep,
      previousStep,
      setChecklistOpen,
      setStepId,
   } = React.useContext<IOnboardingProps>(OnboardingContext);

   const steps = walkthrough ? walkthrough.steps : [];
   const index = stepId === null ? null : steps.findIndex((i) => i.id === stepId);

   const [referenceElement, setReferenceElement] = React.useState<Maybe<Element>>(null);
   const [onboardingElement, setOnboardingElement] = React.useState<Maybe<HTMLElement>>(null);
   const [arrowElement, setArrowElement] = React.useState<Maybe<Element>>(null);
   const [showConfetti, setShowConfetti] = React.useState<boolean>(false);
   const [showCursor, setShowCursor] = React.useState<boolean>(false);

   const calculateOffset = React.useMemo(
      () => ({
         name: 'offset',
         options: {
            offset: () => {
               if (stepId !== null && walkthrough !== null) {
                  const { type } = steps.find((i) => i.id === stepId) ?? {};
                  if (type === OnboardingStepType.pointer) {
                     return [0, 35];
                  } else if (type === OnboardingStepType.videoPointer) {
                     return [0, 20];
                  } else if (type === OnboardingStepType.beacon) {
                     return [0, -20];
                  }
               }
               return [0, 20];
            },
         },
      }),
      [walkthrough, stepId],
   );

   const getOptions = (): {
      placement: Placement;
      modifiers: Partial<Modifier<unknown, object>>[];
   } => {
      const options: {
         placement: Placement;
         modifiers: Partial<Modifier<unknown, object>>[];
      } = {
         placement: 'auto',
         modifiers: [],
      };
      if (stepId !== null && walkthrough !== null) {
         const { fallbackPlacements, placement, type } = steps.find((i) => i.id === stepId) ?? {};
         options.modifiers.push(calculateOffset, {
            name: 'computeStyles',
            options: { adaptive: false },
         });
         if (type && pointerSteps.includes(type)) {
            options.modifiers.push({
               name: 'arrow',
               options: { element: arrowElement },
            });
         }
         if (placement) {
            options.placement = placement;
         } else if (type === OnboardingStepType.beacon) {
            options.placement = 'right';
         }
         if (fallbackPlacements) {
            options.modifiers.push({
               name: 'flip',
               options: { fallbackPlacements },
            });
         }
      }
      return options;
   };

   const getSpotlightTarget = (): DOMRect | undefined =>
      referenceElement ? referenceElement.getBoundingClientRect() : undefined;

   const { styles, attributes } = usePopper(referenceElement, onboardingElement, getOptions());

   React.useEffect(() => {
      if (!steps) {
         return;
      }

      if (stepId !== null) {
         const {
            selector = null,
            confetti = false,
            cursor,
            onMount,
         } = steps.find((i) => i.id === stepId) ?? {};
         const element = selector ? document.querySelector(selector) : null;
         setReferenceElement(element);
         if (element) {
            scrollIntoView(element, {
               behavior: 'smooth',
               scrollMode: 'if-needed',
            });
            if (onMount) {
               onMount(element);
            }
         }
         setShowConfetti(!!confetti);
         setShowCursor(!!cursor);
      }
   }, [stepId]);

   React.useEffect(() => {
      if (walkthrough?.steps && stepId === null && taskState === OnboardingTaskState.started) {
         const firstStep = walkthrough.steps[0];
         setStepId(firstStep.id);
         setChecklistOpen(false);
      }
   }, [walkthrough, taskState]);

   if (
      stepId === null ||
      !walkthroughVisible ||
      [OnboardingTaskState.closed, OnboardingTaskState.initialized].includes(taskState)
   ) {
      return null;
   }

   const getStep = (): IOnboardingStep | undefined => {
      const stepResult = steps.find((i) => i.id === stepId);
      if (stepResult) {
         return {
            overlayOptions: {},
            author: { ...onboardingAuthor },
            ...stepResult,
         };
      }
   };

   const step = getStep();

   const handleCloseClick = (): void => {
      step?.beforeUnmount?.();

      if (step?.onCloseClick) {
         step?.onCloseClick();
      } else {
         clearCurrentTask();
      }
   };

   const handleBackClick = (): void => {
      if (index && index > 0) {
         previousStep();
      }
   };

   const handlePrimaryClick = (): void => {
      if (step?.onPrimaryClick) {
         step?.onPrimaryClick();
      } else {
         if (index !== null && index + 1 === steps.length) {
            clearCurrentTask();
         } else {
            nextStep();
         }
      }
   };

   const renderStepComponent = (): Maybe<React.ReactNode> => {
      if (index === null || !step) {
         return null;
      }
      switch (step?.type) {
         case OnboardingStepType.beacon:
            return (
               <Beacon
                  elementRef={setOnboardingElement}
                  onClick={handlePrimaryClick}
                  style={styles.popper}
                  {...styles.popper}
               />
            );
         case OnboardingStepType.post:
            return (
               <Post
                  index={index}
                  size={steps.length}
                  step={step}
                  showBackButton={walkthrough?.showBackButton}
                  onBackClick={handleBackClick}
                  onPrimaryClick={handlePrimaryClick}
                  onCloseClick={() => handleCloseClick()}
               />
            );
         case OnboardingStepType.videoPost:
            return <VideoPost step={step} onCloseClick={handleCloseClick} />;
         case OnboardingStepType.pointer:
            return (
               <Pointer
                  index={index}
                  size={steps.length}
                  step={step}
                  showBackButton={walkthrough?.showBackButton}
                  onBackClick={handleBackClick}
                  onPrimaryClick={handlePrimaryClick}
                  onCloseClick={handleCloseClick}
                  style={styles.popper}
                  arrowStyle={styles.arrow}
                  elementRef={setOnboardingElement}
                  arrowRef={setArrowElement}
                  overlayOptions={{
                     ...step.overlayOptions,
                     spotlightTarget: getSpotlightTarget(),
                  }}
                  {...attributes.popper}
               />
            );
         case OnboardingStepType.videoPointer:
            return (
               <VideoPointer
                  index={index}
                  size={steps.length}
                  step={step}
                  onPrimaryClick={handlePrimaryClick}
                  onCloseClick={handleCloseClick}
                  style={styles.popper}
                  arrowStyle={styles.arrow}
                  elementRef={setOnboardingElement}
                  arrowRef={setArrowElement}
                  {...attributes.popper}
               />
            );
         case OnboardingStepType.custom:
            return step.component;
         default:
            return null;
      }
   };

   return (
      <>
         {showCursor && step?.cursor && (
            <Cursor {...step.cursor} onCompletion={() => setShowCursor(false)} />
         )}
         {showConfetti && <Confetti onAnimationEnd={() => setShowConfetti(false)} />}
         {renderStepComponent()}
      </>
   );
};

export default React.memo(Onboarding);
