import * as React from 'react';

import ModalDialog from '@components/Core/ModalDialog';
import { getWeekBreakdownOfDateRange } from '@components/ProficiencyTracking/ProficiencyReportHelpers';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { CourseProfile } from '@models/Course';
import { StringOption } from '@models/ReactSelectHelperTypes';
import ProficiencyCourseCanDoStatementService from '@services/ProficiencyCourseCanDoStatementService';
import classnames from 'classnames';
import Select from 'react-select';

interface CourseCanDoStatementBulkEditorModalProps {
   closeModal(): void;
   course: CourseProfile;
   selectedCanDoStatementIds: readonly number[];
   updateCanDoStatement(weekIndex: Maybe<number>): void;
}

const CourseCanDoStatementBulkEditorModal: React.FC<CourseCanDoStatementBulkEditorModalProps> = (
   props,
) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const [weekIndex, setWeekIndex] = React.useState<string>('');

   const onSave = async () => {
      if (isSaving) {
         return;
      }
      setIsSaving(true);

      try {
         const nullableWeekIndex = weekIndex === '' ? null : parseInt(weekIndex);

         await ProficiencyCourseCanDoStatementService.update(
            props.course.id,
            props.selectedCanDoStatementIds,
            nullableWeekIndex,
         );

         props.updateCanDoStatement(nullableWeekIndex);
      } catch (error) {
         console.error('Failed to bulk edit course can-do statements: ', error);
      } finally {
         setIsSaving(false);
         props.closeModal();
      }
   };

   const createCompletionOptions = (): readonly StringOption[] => {
      const weeks = getWeekBreakdownOfDateRange(props.course.startDate, props.course.endDate);

      const weekOptions = weeks.map((week) => ({
         value: week.weekIndex.toString(),
         label: `Week ${week.weekIndex + 1}`,
      }));

      return weekOptions;
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         className='no-padding'
         heading='Bulk Edit Selected Items'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         onClose={props.closeModal}
         actions={[
            { text: 'Save', onClick: onSave, disabled: isSaving },
            { text: 'Cancel', onClick: props.closeModal },
         ]}
      >
         <div className='text-left margin-left-s margin-top-s margin-right-s margin-bottom-s'>
            <p>Completion Date</p>
            <Select
               className='react-select data-test-select-completion-date'
               classNamePrefix='completion-date'
               data-test='select-completion-date'
               isSearchable
               options={createCompletionOptions()}
               onChange={(opt) => setWeekIndex(opt?.value || '')}
            />
         </div>
      </ModalDialog>
   );
};

export default CourseCanDoStatementBulkEditorModal;
