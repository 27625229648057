import * as React from 'react';

import AddButton from '@components/Common/AddButton';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState/EmptyState';
import { ChildOrganizationFragment, OrganizationProfileFragment } from '@generated/gql/graphql';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { useNavigate } from 'react-router-dom';

import Constants from '../../../Constants';
import AddChildOrganizationModal from './AddChildOrganizationModal';

interface Props {
   refetch(): void;
   organization: OrganizationProfileFragment;
   value: ChildOrganizationFragment[];
}

const ChildOrganizationsPanel: React.FC<Props> = (props) => {
   const {
      routes: {
         schools: { schoolDetails },
      },
   } = Constants;
   const navigate = useNavigate();

   const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

   const childColumns: readonly Column<ChildOrganizationFragment>[] = [
      {
         id: 'id',
         header: 'Id',
         cell: (i) => (
            <a onClick={() => navigate(schoolDetails.replace(':organizationId', i.id))}>{i.id}</a>
         ),
      },
      {
         id: 'name',
         header: 'Organization Name',
         cell: (i) => i.name,
      },
   ];

   return (
      <SectionCard
         headerButton={
            <AddButton aria-label='Add Child Organization' onClick={() => setIsModalOpen(true)} />
         }
         title='Child Organizations'
      >
         {props.value.length > 0 ? (
            <div className='row margin-top-s'>
               <div className='col-xs-12'>
                  <Table
                     columns={childColumns}
                     rows={props.value}
                     rowKey='id'
                     defaultSortBy={[{ id: 'id', desc: true }]}
                  />
               </div>
            </div>
         ) : (
            <EmptyState
               className='padding-top-m padding-bottom-s'
               description=''
               heading='Nothing to Show'
               icon={<IconBinoculars className='large' aria-hidden />}
            />
         )}
         {isModalOpen && (
            <AddChildOrganizationModal
               organization={props.organization}
               refetch={props.refetch}
               setIsModalOpen={setIsModalOpen}
            />
         )}
      </SectionCard>
   );
};

export default ChildOrganizationsPanel;
