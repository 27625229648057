import * as _ from 'lodash';
import * as React from 'react';

import indexDeep from '@helpers/IndexDeep';
import {
   GroupingPromptCategory as GroupingPromptCategoryType,
   GroupingPromptItem,
   GroupingResponse,
   GroupingResponseEntry,
} from '@models/Activity';
import classnames from 'classnames';
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';
import GroupingPromptCategory from './GroupingPromptCategory';

interface GroupingPromptProps extends CommonPromptProps {
   categories: readonly GroupingPromptCategoryType[];
   items: readonly GroupingPromptItem[];
   response: GroupingResponse;
   showMissed: boolean;
}

const reorder = <T,>(list: readonly T[], startIndex: number, endIndex: number): readonly T[] => {
   const result = Array.from(list);
   const [removed] = result.splice(startIndex, 1);
   result.splice(endIndex, 0, removed);
   indexDeep(result);
   return result;
};

const GroupingPrompt: React.FC<GroupingPromptProps> = ({
   isClosed,
   items,
   categories,
   response,
   setResponse,
   saveResponse,
   showMissed,
}) => {
   const reorderEntries = (
      source: DraggableLocation,
      destination: DraggableLocation,
   ): GroupingResponse => {
      const entriesByCategory: Record<string, readonly GroupingResponseEntry[]> = [
         ...categories,
         { id: 'origin' },
      ].reduce(
         (ac, { id }) => ({
            ...ac,
            [id]: _.sortBy(
               response.filter((i) => (i.categoryId === null ? 'origin' : i.categoryId) === id),
               'index',
            ),
         }),
         {},
      );

      const current = [...entriesByCategory[source.droppableId]];
      const next = [...entriesByCategory[destination.droppableId]];
      const target = current[source.index];

      // moving to same list
      if (source.droppableId === destination.droppableId) {
         const reordered = reorder(current, source.index, destination.index);
         entriesByCategory[source.droppableId] = [...reordered];
      } else {
         // remove from original
         current.splice(source.index, 1);

         // insert into next
         next.splice(destination.index, 0, {
            ...target,
            categoryId: parseInt(destination.droppableId),
            modified: true,
         });

         entriesByCategory[source.droppableId] = [...current];
         entriesByCategory[destination.droppableId] = [...next];
      }

      entriesByCategory.origin = entriesByCategory.origin.map((i) => ({
         ...i,
         categoryId: null,
      }));
      Object.keys(entriesByCategory).forEach((i) => indexDeep(entriesByCategory[i]));
      return Object.values(entriesByCategory).flat();
   };

   const handleDragEnd = (result: DropResult): void => {
      if (!result.destination) {
         return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
         return;
      }
      const updatedEntries = reorderEntries(source, destination);

      setResponse(updatedEntries, saveResponse);
   };

   return (
      <div className='dnd-prompt-drag-context'>
         <DragDropContext onDragEnd={handleDragEnd}>
            <div
               className={classnames('dnd-prompt-container', {
                  closed: isClosed,
               })}
            >
               {categories.map((category) => (
                  <GroupingPromptCategory
                     key={category.id}
                     category={category}
                     entries={response.filter((i) => i.categoryId === category.id)}
                     items={items}
                     isClosed={isClosed}
                     showMissed={showMissed}
                  />
               ))}
               <GroupingPromptCategory
                  className='origin'
                  category={{
                     name: 'Choices',
                     id: 'origin',
                     index: categories.length,
                  }}
                  entries={response.filter((i) => i.categoryId === null)}
                  items={items}
                  isClosed={isClosed}
                  showMissed={showMissed}
               />
            </div>
         </DragDropContext>
      </div>
   );
};

export default GroupingPrompt;
