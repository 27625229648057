import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Emitter from '@utilities/Emitter';

import Config from '../Config';
import Constants from '../Constants';

export enum HelpLauncherEventType {
   Started = 'Started',
   Stopped = 'Stopped',
}

const { intercom } = Constants;
const { environmentType } = Config;
const eventEmitter = new Emitter();

export const baseConfig = snakeCaseKeys({
   appId: environmentType === 'production' ? intercom.prod : intercom.test,
   customLauncherSelector: '#live-chat-launcher',
   hideDefaultLauncher: true,
});

const boot = (userData = baseConfig): void => {
   if (window.Intercom?.booted) {
      window.Intercom('update', userData);
   } else {
      window.Intercom('boot', userData);
   }

   eventEmitter.emit(HelpLauncherEventType.Started);
};

const reboot = (userData = baseConfig): void => {
   window.Intercom('shutdown');

   // we need to make sure any state hooks related to 'boot' events have a
   // chance to process 'unbooted' first, so we make sure the 'boot' event
   // happens in the next tick (ie: state batching)
   eventEmitter.emit(HelpLauncherEventType.Stopped).then(() => {
      boot(userData);
   });
};

const startChat = (message = ''): void => {
   window.Intercom?.('showNewMessage', message);
};

const closeChat = (): void => {
   if (window.Intercom) {
      window.Intercom('hide');
   }
};

const addEventListener = (name: string, handler: () => void): void => {
   eventEmitter.listen(name, handler);
};

const isBooted = (): boolean => window?.Intercom.booted;

export default {
   baseConfig,
   boot,
   reboot,
   closeChat,
   startChat,
   addEventListener,
   isBooted,
};
