import * as React from 'react';

import IconVoteHeartBroken from '@icons/nova-solid/19-Vote&Rewards/vote-heart-broken.svg';
import IconVoteHeart from '@icons/nova-solid/19-Vote&Rewards/vote-heart.svg';

import {
   IVocabSessionContext,
   SPEED_NUM_LIVES,
   VocabSessionContext,
} from '@components/VocabSession/VocabSession';

enum LiveStatus {
   alive = 'alive',
   dying = 'dying',
   dead = 'dead',
}

const Lives: React.FC = () => {
   const {
      state: {
         stats: { incorrect },
      },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const [lives, setLives] = React.useState<readonly LiveStatus[]>(
      [...Array(SPEED_NUM_LIVES)].map(() => LiveStatus.alive),
   );

   React.useEffect(() => {
      if (incorrect > 0) {
         handleIncorrectAnswer();
      }
   }, [incorrect]);

   const getClassName = (index: number): string => {
      const status = lives[index];
      switch (status) {
         case LiveStatus.alive:
            return 'icon-dark-red';
         case LiveStatus.dying:
            return 'animated heartBeat fast';
         default:
            return '';
      }
   };

   const handleIncorrectAnswer = (): void => {
      const index = lives.findIndex((i) => i === LiveStatus.alive);
      if (index !== -1) {
         const updatedState = [...lives];
         updatedState[index] = LiveStatus.dying;
         setLives(updatedState);
      }
   };

   const handleAnimationEnd = (index: number): void => {
      const updatedState = [...lives];
      updatedState[index] = LiveStatus.dead;
      setLives(updatedState);
   };

   return (
      <div className='session-lives'>
         {lives.map((live, i) =>
            live === LiveStatus.dead ? (
               <IconVoteHeartBroken className='broken' key={i} />
            ) : (
               <IconVoteHeart
                  key={i}
                  onAnimationEnd={() => handleAnimationEnd(i)}
                  className={getClassName(i)}
               />
            ),
         )}
      </div>
   );
};

export default React.memo(Lives);
