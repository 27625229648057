import * as React from 'react';

import { parseHtml } from '@helpers/ParseHtml';
import AccountType from '@models/AccountType';
import { Course } from '@models/Course';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { AppStateContext } from '../../AppState';
import Button from '@components/Common/Button';
import CourseStrings from '@components/Course/CourseStrings';

interface CourseDescriptionProps {
   canModifyRoster?: boolean;
   course: Course;
   openCourseDetailsModal(): void;
}

const CourseDescription: React.FC<CourseDescriptionProps> = ({
   canModifyRoster = false,
   course: {
      archived = false,
      demo = false,
      description,
      name,
      isNationalExamPractice,
      isClonedCourse,
   },
   openCourseDetailsModal,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const isInstructor = userProfile?.accountType === AccountType.instructor;
   const disabledTooltipText =
      !canModifyRoster && CourseStrings.getDisabledRosterTooltip(archived, demo);

   const isEmpty = !description;

   return (
      <div
         className={classnames('card margin-top-m', {
            'padding-bottom-s': !isEmpty,
         })}
      >
         <div className={classnames('card-title has-button full-width', { collapsed: isEmpty })}>
            <div className='flex items-center'>
               <div className='title'>{name}</div>
               {archived && <div className='lozenge red margin-left-s'>Archived</div>}
               {!archived && demo && <div className='lozenge blue margin-left-s'>Demo</div>}
               {isNationalExamPractice && (
                  <div
                     className='lozenge blue margin-left-s'
                     title={isClonedCourse ? 'Cloned' : 'Original'}
                  >
                     National Exam
                  </div>
               )}
            </div>
            {isInstructor && canModifyRoster && (
               <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                  <div className='right-options-wrapper'>
                     <Button line onClick={openCourseDetailsModal} className='course-details-btn'>
                        Course Details
                     </Button>
                  </div>
               </Tippy>
            )}
         </div>
         {description && <div>{parseHtml(description)}</div>}
      </div>
   );
};

export default CourseDescription;
