import * as React from 'react';

import { Maybe } from '@models/Core';
import SchoolProfile from '@models/SchoolProfile';
import { RegistrationError, RegistrationStep } from '@services/RegistrationService';

import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import SchoolSelect from '@components/SchoolSelect';
import RegisterError from './RegisterError';

interface MultipleSchoolSuggestionProps {
   isLoading: boolean;
   errors: readonly RegistrationError[];
   suggestedSchools: readonly SchoolProfile[];
   setStep(step: RegistrationStep): void;
   setSchool(school: SchoolProfile, submitRegistration?: boolean): void;
}

const MultipleSchoolSuggestion: React.FC<MultipleSchoolSuggestionProps> = ({
   isLoading,
   errors,
   suggestedSchools,
   setStep,
   setSchool,
}) => {
   const [selectedSchool, setSelectedSchool] = React.useState<Maybe<SchoolProfile>>(null);
   const [error, setError] = React.useState<string>('');

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (selectedSchool) {
         setSchool(selectedSchool, true);
      } else {
         setError('Please select a school');
      }
   };

   const handleSchoolNotFound = (): void => {
      setStep(RegistrationStep.schoolSearch);
   };

   return (
      <div className='card-content-onboarding card padding-bottom-m'>
         <div className='onboarding-title border-bottom text-center'>
            <DocumentTitle>School Verification</DocumentTitle>
            <h2 aria-live='polite' className='uppercase no-margin'>
               School Verification
            </h2>
         </div>
         <form className='content-form' onSubmit={handleSubmit}>
            <div className='row'>
               <div className='col-xs-12'>
                  <SchoolSelect
                     onChange={setSelectedSchool}
                     options={suggestedSchools}
                     value={selectedSchool}
                  />
                  {error && <p className='error'>{error}</p>}
                  <Button textLink className='margin-top-s' onClick={handleSchoolNotFound}>
                     Can't find my school
                  </Button>
               </div>
            </div>
            <div className='row margin-top-m'>
               <div className='col-xs-6'>
                  <Button
                     type='submit'
                     fullWidth
                     data-test='continue'
                     disabled={!selectedSchool}
                     loading={isLoading}
                  >
                     Continue
                  </Button>
               </div>
               <div className='col-xs-6'>
                  <Button
                     line
                     fullWidth
                     disabled={isLoading}
                     onClick={() => setStep(RegistrationStep.schoolSearch)}
                  >
                     Back
                  </Button>
               </div>
               <RegisterError errors={errors} />
            </div>
         </form>
      </div>
   );
};

export default MultipleSchoolSuggestion;
