import * as React from 'react';

import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import Loader from '@components/Loader';
import Appearance from '@models/Appearance';
import { ProficiencyEvidenceItems, StudentProficiencySummaryRow } from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';
import classnames from 'classnames';

import Constants from '../../../../Constants';

interface EvidenceModalProps {
   canDoStatement: StudentProficiencySummaryRow;
   closeModal(): void;
   courseId: number;
   userId: number;
}

const EvidenceModal: React.FC<EvidenceModalProps> = (props) => {
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [evidenceItems, setEvidenceItems] = React.useState<readonly ProficiencyEvidenceItems[]>(
      [],
   );

   React.useEffect(() => {
      getEvidenceItems();
   }, []);

   const getEvidenceItems = async () => {
      setIsLoading(true);
      const items = await ProficiencyReportService.getEvidenceItems(
         props.canDoStatement.id,
         props.courseId,
         props.userId,
      );

      setEvidenceItems(items);
      setIsLoading(false);
   };

   const renderActivityLink = (row: ProficiencyEvidenceItems): React.ReactNode => {
      const submissionLink = Constants.routes.activities.gradeSubmissionFromModuleId
         .replace(':moduleItemId', row.moduleItemId.toString())
         .replace(':submissionId', row.submissionId.toString());

      return <Link to={submissionLink}>{row.activityName}</Link>;
   };

   const columns: readonly Column<ProficiencyEvidenceItems>[] = [
      {
         id: 'activity',
         header: 'Activity',
         cell: renderActivityLink,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'in-module',
         header: 'In Module',
         cell: (i) => i.moduleName,
         headerClassName: 'text-center',
         cellClassName: 'text-center',
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         className='no-padding'
         bodyClassName='modal-body'
         footerClassName='hide'
         heading='Evidence'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         onClose={props.closeModal}
      >
         {isLoading ? (
            <Loader />
         ) : (
            <>
               <div className='padding-left-m padding-right-m'>
                  <div>
                     <div className='flex space-between padding-top-s'>
                        <div className='title'>Statement</div>
                        <div className='flex proficiency-pill-container'>
                           <div className='proficiency-pill'>
                              <span className='proficiency-pill-label'>
                                 {props.canDoStatement.skill}
                              </span>
                           </div>
                           <div className='margin-left-s proficiency-pill'>
                              <span className='proficiency-pill-label'>
                                 {props.canDoStatement.level}
                              </span>
                           </div>
                        </div>
                     </div>

                     <p>{props.canDoStatement.name}</p>
                  </div>
               </div>
               <div className='modal-body-scroll'>
                  <Table<ProficiencyEvidenceItems>
                     columns={columns}
                     rowKey='moduleItemId'
                     rows={evidenceItems}
                  />
               </div>
            </>
         )}
      </ModalDialog>
   );
};

export default EvidenceModal;
