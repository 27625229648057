import * as React from 'react';
import autobind from '@helpers/autobind';

import { randomShortId } from '@helpers/RandomStringUtils';
import { Editor as TinyMCEReact } from '@tinymce/tinymce-react';
import classnames from 'classnames';
import { Editor as TinyMCEEditor, EditorEvent } from 'tinymce';
import { BASE_CONFIG } from './Config';
import { BaseEditorProps } from './types/Editor';
import { Maybe } from '@models/Core';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { BASIC_CALLOUTS } from './data/Callouts';
import { LanguageLookup } from '@models/Language';

export enum ToolbarAppearance {
   /** Standard toolbar fixed at the top and always visible. */
   fixed = 'fixed',
   /** Similar to fixed but hides when unfocused. */
   sleek = 'sleek',
   /** Floating toolbar over highlighted text. */
   floating = 'floating',
}

interface EditorProps extends BaseEditorProps {
   /** Display Right-to-left buttons */
   rtl?: boolean;
   /**
    * Toolbar display options
    * - fixed: Standard toolbar fixed at the top and always visible.
    * - sleek: Similar to fixed but hides when unfocused.
    * - floating: Floating toolbar over highlighted text.
    */
   toolbarAppearance?: ToolbarAppearance;
   /** Additional plugins beyond standard ones */
   extraPlugins?: readonly string[];
   /** Remove standard plugins */
   disablePlugins?: readonly string[];
   footer?: React.ReactNode;
   dataTest?: string;
}

interface EditorState {
   id: string;
   focused: boolean;
   loaded: boolean;
}

class Editor extends React.Component<EditorProps, EditorState> {
   static defaultProps: Partial<EditorProps> = {
      toolbarAppearance: ToolbarAppearance.fixed,
      extraPlugins: [],
   };

   editor: Maybe<TinyMCEEditor>;

   constructor(props: EditorProps) {
      super(props);
      autobind(this);
      this.state = {
         id: `rte-${randomShortId(6)}`,
         focused: false,
         loaded: false,
      };
      this.editor = null;
   }

   shouldComponentUpdate(nextProps: EditorProps, nextState: EditorState): boolean {
      // TinyMCE has a bug where it will sometimes swap out &nbsp; for regular spaces without triggering an onChange.
      const normalize = (html: string) => html.replace(/&nbsp;/g, ' ');
      return (
         nextProps.className !== this.props.className ||
         nextProps.language !== this.props.language ||
         normalize(nextProps.value) !== normalize(this.editor?.getContent() ?? '') ||
         nextState.focused !== this.state.focused ||
         nextProps.footer !== this.props.footer
      );
   }

   componentDidUpdate(prevProps: EditorProps): void {
      if (prevProps.language !== this.props.language) {
         const newLang = this.props.language ?? '';
         this.editor?.options.set('content_lang', newLang);
         this.editor?.fire('contentLangChange', { language: newLang });
      }
   }

   setupEditor(editor: TinyMCEEditor): void {
      const contentLang = this.props.language ?? '';
      editor.options.register('content_lang', {
         processor: 'string',
         default: 'en',
      });
      editor.on('init', (e) => {
         this.props.onInit?.(e);
         editor.options.set('content_lang', this.props.language ?? '');
         editor.fire('contentLangChange', { language: contentLang });
      });
      editor.addShortcut('meta+shift+s', 'Source code editor', () => {
         editor.execCommand('mceCodeEditor');
      });
      editor.addShortcut('meta+shift+v', 'Toggle visual blocks', () => {
         editor.execCommand('mceVisualBlocks');
      });
      editor.addShortcut('meta+shift+c', 'Inline CSS', () => {
         editor.execCommand('mceExport');
      });

      this.editor = editor;
      this.props.editorRef?.(editor);
   }

   handleBlur(
      event: EditorEvent<{ focusedEditor: TinyMCEEditor | null }>,
      editor: TinyMCEEditor,
   ): void {
      this.setState({ focused: false });
      this.props.onBlur?.(event, editor);
      if (editor) {
         this.props.onChange(editor.getContent());
      }
   }

   handleFocus(
      event: EditorEvent<{ blurredEditor: TinyMCEEditor | null }>,
      editor: TinyMCEEditor,
   ): void {
      this.setState({ focused: true });
      this.props.onFocus?.(event, editor);
   }

   handleChange(value: string): void {
      this.props.onChange(value);
   }

   render(): React.ReactNode {
      const {
         autoFocus = false,
         className,
         config = {},
         extraPlugins = [],
         disablePlugins = [],
         overrideConfig,
         rtl,
         toolbarAppearance,
         value,
         footer,
         dataTest,
      } = this.props;
      const { id, focused } = this.state;

      const languages = [
         { title: 'English', code: 'en' },
         { title: LanguageLookup[this.props.language], code: this.props.language },
      ];

      const baseConfig = snakeCaseKeys({
         ...BASE_CONFIG,
         contentLangs: languages,
         autoFocus: autoFocus ? id : '',
         contextmenu:
            'table image link tooltip custom-media callouts examples conversation custom-delete transform generateaudio casechange translate',
         fixedToolbarContainer: `#${id}-toolbar`,
         calloutList: BASIC_CALLOUTS,
         plugins: [
            'advlist',
            'code',
            'contentlink',
            'customicons',
            'customtable',
            'emoticons',
            'image',
            'insertimage',
            'inlineaudio',
            'link',
            'lists',
            'media',
            'help',
            'quickbars',
            'table',
            'tooltip',
            'translate',
            'uploadfile',
            'recordvideo',
            'visualblocks',
            'webbookmark',
            'generateaudio',
            'fixmenus',
            'fiximages',
            'bannerimages',
            ...(toolbarAppearance === ToolbarAppearance.floating
               ? []
               : ['quickinsert', 'callouts', 'inlinecss']),
            ...extraPlugins,
         ].filter((i) => !!i && !disablePlugins.includes(i)),
         // quickbarsImageToolbar: false,
         quickbarsSelectionToolbar: false,
         quickinsertOptions:
            'p h1 h2 h3 table ul ol blockquote hr link primary-callout secondary-callout success-callout warning-callout danger-callout img video block-audio file emoji inline-audio',
         toolbar: [
            'bold italic underline strikethrough',
            'fontfamily fontsize',
            'forecolor backcolor',
            'styles',
            'alignleft aligncenter alignright alignfull',
            'language',
            rtl ? 'ltr rtl' : '',
            'numlist bullist',
            'link tooltip image upload-file media table hr',
            toolbarAppearance === ToolbarAppearance.fixed ? 'visualblocks code' : '',
         ]
            .filter((i) => !!i)
            .join(' | '),
         setup: this.setupEditor,
      });

      const floatingConfig = snakeCaseKeys({
         toolbar: false,
         fixedToolbarContainer: null,
         quickbarsInsertToolbar: false,
         quickbarsSelectionToolbar:
            'bold italic underline strikethrough | forecolor backcolor | quicklink language tooltip inlineaudio',
         contextmenu: 'table insert-image link custom-media translate',
      });

      const mergedConfig =
         config && overrideConfig
            ? config
            : {
                 ...baseConfig,
                 ...(toolbarAppearance === ToolbarAppearance.floating ? floatingConfig : {}),
                 // Override toolbar to be quick selection
                 ...config,
                 ...(toolbarAppearance === ToolbarAppearance.floating &&
                 config?.toolbar &&
                 !config?.quickbars_selection_toolbar
                    ? snakeCaseKeys({
                         quickbarsSelectionToolbar: config.toolbar,
                         toolbar: false,
                      })
                    : {}),
              };

      return (
         <div className={classnames('mce-wrapper', { focused }, className, toolbarAppearance)}>
            <div className='mce-wrapper-toolbar' data-test={id} id={`${id}-toolbar`} />
            <TinyMCEReact
               id={dataTest}
               init={mergedConfig}
               initialValue={value}
               onBlur={this.handleBlur}
               onEditorChange={this.handleChange}
               onFocus={this.handleFocus}
               onInit={(evt, editor) => (this.editor = editor)}
            />
            {footer}
         </div>
      );
   }
}

export default Editor;
