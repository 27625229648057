import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { MessageResponse } from '@models/Core';
import Notification from '@models/Notification';

import HttpService from './HttpService';

const getNotifications = (userId: number): Promise<readonly Notification[]> =>
   HttpService.getWithAuthToken<{ notifications: readonly Notification[] }>(
      `/api/users/${userId}/notifications`,
   ).then((response) => response.data.notifications);

const markNotificationAsRead = (userId: number, notificationId: number): Promise<MessageResponse> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/users/${userId}/notifications/${notificationId}`,
      snakeCaseKeys({ read: true }),
   ).then((response) => response.data);

const markNotificationsAsRead = (
   userId: number,
   notificationIds: readonly number[],
): Promise<MessageResponse> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/users/${userId}/notifications`,
      snakeCaseKeys({ read: true, notificationIds }),
   ).then((response) => response.data);

export default {
   getNotifications,
   markNotificationAsRead,
   markNotificationsAsRead,
};
