import * as React from 'react';

import { ActivityConsumer } from '@components/Activity/Builder/ActivityBuilder';
import Switch from '@components/Common/Switch';
import InfoTooltip from '@components/InfoTooltip';
import { isInteger } from '@helpers/NumberUtils';
import useUpdateEffect from '@hooks/use-update-effect';
import { ActivityBuilderMode, ActivityItemVisibility, AudioContent } from '@models/Activity';
import classnames from 'classnames';

import ItemOptions from '@components/Activity/Builder/ItemOptions';

interface AudioContentOptionsProps {
   item: AudioContent<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<AudioContent<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const AudioContentOptions: React.FC<AudioContentOptionsProps> = ({
   handleItemUpdate,
   item: {
      disablePausing,
      limitPlaybacks,
      playbacks,
      recordUponCompletion,
      recordingDelay,
      sequenceEligible,
      storedFilename,
      show,
   },
}) => {
   const playbacksRef = React.useRef<HTMLInputElement>(null);
   const recordingDelayRef = React.useRef<HTMLInputElement>(null);

   const [seconds, setSeconds] = React.useState<string>('');

   React.useEffect(() => {
      setSeconds((recordingDelay || 0).toString().padStart(2, '0'));
   }, [recordingDelay]);

   useUpdateEffect(() => {
      if (recordUponCompletion) {
         recordingDelayRef.current?.focus();
      }
   }, [recordUponCompletion]);

   useUpdateEffect(() => {
      if (limitPlaybacks) {
         playbacksRef.current?.focus();
      }
   }, [limitPlaybacks]);

   const toggleLimitPlaybacks = (): void => {
      handleItemUpdate({
         limitPlaybacks: !limitPlaybacks,
         playbacks: limitPlaybacks ? null : 1,
      });
   };

   const toggleDisablePausing = (): void => {
      handleItemUpdate({ disablePausing: !disablePausing });
   };

   const toggleRecordUponCompletion = (): void => {
      handleItemUpdate({
         recordUponCompletion: !recordUponCompletion,
         recordingDelay: recordUponCompletion ? null : 5,
      });
   };

   const handlePlaybacksChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      handleItemUpdate({ playbacks: isInteger(value) ? Number(value) : null });
   };

   const handleStoredFilenameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      handleItemUpdate({ storedFilename: event.target.value });
   };

   const handleSecondsBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      const numericSeconds = parseInt(event.target.value, 10);
      if (isNaN(numericSeconds)) {
         setSeconds(numericSeconds.toString());
         return;
      }
      handleItemUpdate({ recordingDelay: numericSeconds });
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   return (
      <ItemOptions title='Audio Options'>
         <div className='row'>
            <div className='col-xs-12'>
               <div className='margin-top-s'>
                  <label className='field-title'>Show</label>
                  <select
                     name='show'
                     value={show}
                     onChange={(e) =>
                        handleItemUpdate({
                           show: e.target.value as ActivityItemVisibility,
                        })
                     }
                  >
                     <option value={ActivityItemVisibility.always}>Always</option>
                     <option value={ActivityItemVisibility.onceClosed}>Once Closed</option>
                     <option value={ActivityItemVisibility.onceGraded}>Once Graded</option>
                  </select>
               </div>
               <div className='margin-top-s'>
                  <Switch
                     name='disablePausing'
                     checked={disablePausing}
                     onChange={toggleDisablePausing}
                  >
                     Disable Pausing
                  </Switch>
               </div>
               <div className='margin-top-s'>
                  <Switch
                     name='limitAttempts'
                     checked={limitPlaybacks}
                     onChange={toggleLimitPlaybacks}
                  >
                     Limit Playbacks
                  </Switch>
                  {limitPlaybacks && (
                     <div className='margin-top-s'>
                        <label className='field-title'>Playbacks</label>
                        <input
                           type='number'
                           min='0'
                           value={playbacks ?? ''}
                           ref={playbacksRef}
                           onChange={handlePlaybacksChange}
                           onFocus={handleFocus}
                        />
                     </div>
                  )}
               </div>
               <div
                  className={classnames('margin-top-s', {
                     disabled: !sequenceEligible,
                  })}
               >
                  <div className='title small'>
                     Upon Completion
                     <InfoTooltip>
                        Requires a recording prompt to be placed immediately after
                     </InfoTooltip>
                  </div>
                  <div className='margin-top-s'>
                     <Switch
                        name='recordUponCompletion'
                        checked={recordUponCompletion}
                        onChange={toggleRecordUponCompletion}
                        disabled={!sequenceEligible}
                     >
                        Start Recording
                     </Switch>
                     {recordUponCompletion && (
                        <div className='margin-top-s'>
                           <label className='field-title'>Delay (Seconds)</label>
                           <input
                              type='text'
                              value={seconds}
                              ref={recordingDelayRef}
                              onChange={(e) => setSeconds(e.target.value.replace(/\s/g, ''))}
                              onBlur={handleSecondsBlur}
                              onFocus={handleFocus}
                           />
                        </div>
                     )}
                  </div>
               </div>
               <ActivityConsumer>
                  {({ showStoredFilenames }) =>
                     showStoredFilenames && (
                        <div className='margin-top-s'>
                           <label className='field-title'>Filename</label>
                           <input
                              type='text'
                              value={storedFilename ?? ''}
                              onChange={handleStoredFilenameChange}
                           />
                        </div>
                     )
                  }
               </ActivityConsumer>
            </div>
         </div>
      </ItemOptions>
   );
};

export default AudioContentOptions;
