import { snakeCaseKeys } from '@helpers/ModifyKeys';

import AxiosService from './AxiosService';

export interface Task {
   assignmentName: string;
   moduleItemId: number;
}

export interface GetTasksResponse {
   tasks: readonly Task[];
}

const getTasks = (courseId: number, sectionIds: readonly number[]): Promise<GetTasksResponse> =>
   AxiosService.get<GetTasksResponse>(`/api/tasks/${courseId}`, {
      params: snakeCaseKeys({ sectionId: sectionIds }),
   }).then((response) => response.data);

export default {
   getTasks,
};
