import * as React from 'react';

import Language from '@models/Language';

import AccentTextbox from '@components/AccentTextbox';
import ArabicTextbox from '@components/ArabicTextbox';

interface FillBlanksInputProps
   extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
   className: string;
   blankId: string;
   value: string;
   disabled: boolean;
   language: Language;
   joinLeft: boolean;
   joinRight: boolean;
   onValueChange(value: string): void;
}
const FillBlanksInput: React.FC<FillBlanksInputProps> = ({
   className,
   blankId,
   value,
   language,
   joinLeft,
   joinRight,
   onValueChange,
   size,
   style,
   ...rest
}) => {
   const responseLength = value.length;
   const calculatedSize =
      size && Number(size) > responseLength ? Number(size) : Math.max(responseLength, 4);

   const commonProps = {
      ...rest,
      autoCapitalize: 'off',
      autoCorrect: 'off',
      className,
      size: calculatedSize,
      spellCheck: false,
      value,
   };

   if (language === 'ar') {
      return (
         <ArabicTextbox
            {...commonProps}
            data-test={`arabic-textbox-${blankId}`}
            joinLeft={joinLeft}
            joinRight={joinRight}
            onValueChange={onValueChange}
         />
      );
   }
   return (
      <AccentTextbox
         {...commonProps}
         data-test={`accent-textbox-${blankId}`}
         language={language}
         onChange={(e) => onValueChange(e.target.value)}
         small
      />
   );
};

export default FillBlanksInput;
