import * as _ from 'lodash';
import * as React from 'react';

import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import {
   isSkill,
   ProficiencyCanDoStatement,
   ProficiencyCanDoStatementDetailed,
   ProficiencyLevelConstants,
   ProficiencyLevelName,
   Skill,
   SkillsConstants,
} from '@models/Proficiency';
import ProficiencyCanDoStatementService from '@services/ProficiencyCanDoStatementService';
import ProficiencyCourseCanDoStatementService from '@services/ProficiencyCourseCanDoStatementService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import Constants from '../../../Constants';
import ProficiencyLevelLabel from '@components/ProficiencyLevelLabel';

interface CanDoStatementModalEditorModalProps {
   closeModal(): void;
   courseId: number;
   setSelectedCanDoStatements(canDoStatement: ProficiencyCanDoStatement): void;
   selectedCanDoStatements?: ProficiencyCanDoStatement[];
}

const CanDoStatementModalEditorModal: React.FC<CanDoStatementModalEditorModalProps> = (props) => {
   const [canDoStatements, setCanDoStatements] = React.useState<
      readonly ProficiencyCanDoStatementDetailed[]
   >([]);
   const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(0);
   const [totalPages, setTotalPages] = React.useState<number>(0);
   const [searchKeywords, setSearchKeyWords] = React.useState<string>('');
   const [selectedSkill, setSelectedSkill] = React.useState<Skill | undefined>();
   const [selectedLevel, setSelectedLevel] = React.useState<ProficiencyLevelName | undefined>();

   React.useEffect(() => {
      fetchMore();
   }, []);

   const getCanDoStatements = async (
      nextPage: number,
      searchString: string,
      skill?: Skill,
      level?: ProficiencyLevelName,
      resetCanDoStatements = false,
   ): Promise<void> => {
      const data = await ProficiencyCanDoStatementService.search(
         {
            query: searchString,
            page: nextPage,
            predicate: undefined,
         },
         undefined,
         level,
         skill,
      );

      setCurrentPageNumber(data.currentPageNumber);
      setTotalPages(data.totalPageCount);
      if (resetCanDoStatements) {
         setCanDoStatements(data.rows);
      } else {
         setCanDoStatements([...canDoStatements, ...data.rows]);
      }
   };

   const debouncedGetCanDoStatements = React.useCallback(_.debounce(getCanDoStatements, 500), []);

   const fetchMore = (): Promise<void> => {
      const nextPage = currentPageNumber + 1;
      return getCanDoStatements(nextPage, searchKeywords, selectedSkill, selectedLevel);
   };

   const hasMore = (): boolean => currentPageNumber < totalPages;

   const onAddClick = async (canDoStatement: ProficiencyCanDoStatementDetailed): Promise<void> => {
      try {
         await ProficiencyCourseCanDoStatementService.create(props.courseId, canDoStatement.id);
         props.setSelectedCanDoStatements({
            id: canDoStatement.id,
            name: canDoStatement.name,
            level: canDoStatement.level,
            mode: canDoStatement.mode,
            skill: canDoStatement.skill,
            performanceIndicatorId: canDoStatement.performanceIndicatorId,
            themeId: canDoStatement.themeId,
         });
      } catch (error) {
         console.error(error);
      }
   };

   const handleQueryKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): Maybe<boolean> => {
      if (event.key === Constants.keys.enter) {
         getCanDoStatements(1, searchKeywords);
         return false;
      }
   };

   const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchKeyWords(event.target.value);
      debouncedGetCanDoStatements(1, event.target.value, selectedSkill, selectedLevel, true);
   };

   const handleSkillChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newSkillValue = isSkill(event.target.value) ? event.target.value : undefined;
      setSelectedSkill(newSkillValue);

      getCanDoStatements(1, searchKeywords, newSkillValue, selectedLevel, true);
   };

   const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newLevelValue = (event.target.value as ProficiencyLevelName) || undefined;
      setSelectedLevel(newLevelValue);
      getCanDoStatements(1, searchKeywords, selectedSkill, newLevelValue, true);
   };

   const renderAdd = (canDoStatement: ProficiencyCanDoStatementDetailed): React.ReactNode => {
      const selectedCanDoStatements = props.selectedCanDoStatements || [];
      const isAlreadyInCourse = selectedCanDoStatements.find((x) => x.id === canDoStatement.id);
      if (isAlreadyInCourse) {
         return <IconRadioTick className='icon-green' height='20px' width='20px' />;
      }

      return (
         <Tippy delay={[400, 0]} content='Add to Course'>
            <div className='module-add-button'>
               <IconAddSmall
                  data-test={`${canDoStatement.id}-add`}
                  onClick={() => onAddClick(canDoStatement)}
               />
            </div>
         </Tippy>
      );
   };

   const columns: readonly Column<ProficiencyCanDoStatementDetailed>[] = [
      {
         id: 'canDoName',
         header: 'Can-Do Statements',
         cell: (i) => i.name,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'levelName',
         header: 'Level',
         cell: (i) => <ProficiencyLevelLabel>{i.level}</ProficiencyLevelLabel>,
         canSort: true,
         sortFunc: (i) => i.level?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'skillName',
         header: 'Skill',
         cell: (i) => i.skill,
         canSort: true,
         sortFunc: (i) => i.skill.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'modeName',
         header: 'Mode',
         cell: (i) => i.mode,
         canSort: true,
         sortFunc: (i) => i.mode?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'Add',
         header: '',
         cell: renderAdd,
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         className='no-padding'
         bodyClassName='modal-body'
         footerClassName='hide'
         width='medium'
         heading='Import Can-Do Statements'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         onClose={props.closeModal}
      >
         <div className='flex items-center margin-s'>
            <input
               autoFocus
               className='mousetrap'
               data-test='search-keywords'
               name='searchKeywords'
               onChange={handleQueryChange}
               onKeyPress={handleQueryKeyPress}
               placeholder='Search by keyword'
               type='search'
               value={searchKeywords}
            />
         </div>
         <div className='flex margin-left-s margin-right-s margin-bottom-s'>
            <select
               className='col-sm-6'
               data-test='select-skill'
               value={selectedSkill}
               onChange={handleSkillChange}
            >
               <option value={''}>All Skills</option>
               {SkillsConstants.map((x) => (
                  <option key={x} value={x}>
                     {x}
                  </option>
               ))}
            </select>
            <select
               className='col-sm-6'
               data-test='select-level'
               value={selectedLevel}
               onChange={handleLevelChange}
            >
               <option value={''}>All Levels</option>
               {ProficiencyLevelConstants.map((x) => (
                  <option key={x} value={x}>
                     {x}
                  </option>
               ))}
            </select>
         </div>
         <Table<ProficiencyCanDoStatementDetailed>
            columns={columns}
            hasMore={hasMore()}
            infiniteScroll
            onLoadMore={fetchMore}
            rowKey='id'
            rows={canDoStatements}
         />
      </ModalDialog>
   );
};

export default CanDoStatementModalEditorModal;
