import * as React from 'react';

import IconSpeakerVolume from '@icons/nova-solid/37-Audio/speaker-volume-high.svg';
import classnames from 'classnames';

interface IMultipleChoiceAudioOption {
   correct?: boolean;
   disabled?: boolean;
   incorrect?: boolean;
   missed?: boolean;
   selected: boolean;
   onClick(): void;
}

const MultipleChoiceAudioOption: React.FC<IMultipleChoiceAudioOption> = ({
   correct,
   disabled,
   incorrect,
   missed,
   onClick,
   selected,
}) => (
   <div
      className={classnames('session-audio-button-new', {
         correct,
         incorrect,
         missed,
         selected,
         disabled,
      })}
      onClick={onClick}
   >
      <IconSpeakerVolume />
   </div>
);

export default React.memo(MultipleChoiceAudioOption);
