import * as _ from 'lodash';
import * as React from 'react';

import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import ToastType from '@models/Toast';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Constants from '../../../Constants';
import ToastActions from './ToastActions';

interface ToastProps extends ToastType {
   animations?: {
      enter: string;
      exit: string;
   };
   className?: string;
   onRequestHide(): void;
}

const Toast: React.FC<ToastProps> = ({
   animations: { enter: entranceAnimation, exit: exitAnimation } = { enter: '', exit: '' },
   className: propsClassName,
   appearance,
   action,
   title,
   message,
   data = {},
   timeout = 10000,
   onRequestHide,
}) => {
   const {
      routes: {
         courses: { dashboard, viewModule },
      },
   } = Constants;

   const navigate = useNavigate();
   const timeoutRef = React.useRef<Maybe<NodeJS.Timeout>>(null);
   const [isMounting, setIsMounting] = React.useState<boolean>(true);
   const [isUnmounting, setIsUnmounting] = React.useState<boolean>(false);
   const [isHovering, setIsHovering] = React.useState<boolean>(false);

   const toastAppearances: Record<ToastActions, Appearance> = {
      [ToastActions.CLONE_COURSE_FAILED]: Appearance.danger,
      [ToastActions.CLONE_COURSE_FINISHED]: Appearance.success,
      [ToastActions.CONTENT_ADDED_TO_MODULE]: Appearance.success,
      [ToastActions.REPORT_GENERATED]: Appearance.success,
      [ToastActions.CONTENT_OWNERSHIP_TRANSFERRED]: Appearance.success,
   };

   const toastTitles: Record<ToastActions, Maybe<string>> = {
      [ToastActions.CLONE_COURSE_FAILED]: 'Course Clone Failed',
      [ToastActions.CLONE_COURSE_FINISHED]: 'Course Clone Finished',
      [ToastActions.CONTENT_ADDED_TO_MODULE]: 'Added to Module',
      [ToastActions.REPORT_GENERATED]: 'Report Generated',
      [ToastActions.CONTENT_OWNERSHIP_TRANSFERRED]: 'Content Ownership Transferred',
   };

   const toastMessages: Record<ToastActions, Maybe<React.ReactNode>> = {
      [ToastActions.CLONE_COURSE_FAILED]: 'We were unable to copy your course',
      [ToastActions.CLONE_COURSE_FINISHED]: (
         <>
            Your course has been copied! <strong>Click here</strong> to view {data.courseName}.
         </>
      ),
      [ToastActions.CONTENT_ADDED_TO_MODULE]: `${data.contentName} added to ${data.moduleName} in ${data.courseName}.`,
      [ToastActions.REPORT_GENERATED]: _.isString(data.fileUrl) ? (
         <>
            Your report has been generated!{' '}
            <a href={data.fileUrl} download>
               Click here
            </a>{' '}
            to download.
         </>
      ) : null,
      [ToastActions.CONTENT_OWNERSHIP_TRANSFERRED]: (
         <>Ownership of content item {data.id} has been transferred to your account.</>
      ),
   };

   const courseIdStr = _.isNumber(data.courseId) ? data.courseId?.toString() : '';
   const moduleIdStr = _.isNumber(data.courseId) ? data.courseId?.toString() : '';

   const toastLink: Record<ToastActions, Maybe<string>> = {
      [ToastActions.CLONE_COURSE_FINISHED]: dashboard.replace(':courseId', courseIdStr),
      [ToastActions.CONTENT_ADDED_TO_MODULE]: viewModule
         .replace(':courseId', courseIdStr)
         .replace(':moduleId', moduleIdStr),
      [ToastActions.CLONE_COURSE_FAILED]: undefined,
      [ToastActions.REPORT_GENERATED]: undefined,
      [ToastActions.CONTENT_OWNERSHIP_TRANSFERRED]: undefined,
   };

   const requestHide = (): void => {
      setIsUnmounting(true);
   };

   React.useEffect(() => {
      if (isHovering && timeoutRef.current) {
         clearTimeout(timeoutRef.current);
      }
   }, [isHovering]);

   const handleClick = (): void => {
      if (action) {
         const link = toastLink[action];
         if (link) {
            onRequestHide();
            navigate(link);
         }
      }

      if (action && data && data.fileUrl) {
         // Create "a" tag
         const link = document.createElement('a');
         if (_.isString(data.fileUrl)) {
            link.href = data.fileUrl;
         }

         const filename = 'test';
         link.setAttribute('download', filename);

         document.body.appendChild(link);
         link.click();

         // clean up
         document.body.removeChild(link);
      }

      requestHide();
   };

   React.useEffect(() => {
      if (timeout) {
         timeoutRef.current = setTimeout(requestHide, timeout);
      }
      return () => {
         if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
         }
      };
   }, []);

   const handleAnimationEnd = (): void => {
      if (isMounting) {
         setIsMounting(false);
      } else if (isUnmounting) {
         if (onRequestHide) {
            onRequestHide();
         }
      }
   };

   const handleMouseEnter = (): void => {
      setIsHovering(true);
   };

   const handleMouseLeave = (): void => {
      setIsHovering(false);
      requestHide();
   };

   const renderAppearance = (): Appearance => {
      if (appearance) {
         return appearance;
      } else if (action) {
         const actionAppearance = toastAppearances[action];
         if (actionAppearance) {
            return actionAppearance;
         }
      }
      return Appearance.primary;
   };

   const renderMessage = (): Maybe<React.ReactNode> => {
      if (message) {
         return message;
      } else if (action) {
         return toastMessages[action];
      }
   };

   const renderTitle = (): Maybe<string | React.ReactNode> => {
      if (title) {
         return title;
      } else if (action) {
         return toastTitles[action];
      }
   };

   const className = classnames([propsClassName, 'toast', `toast-${renderAppearance()}`], {
      [entranceAnimation]: isMounting,
      [exitAnimation]: isUnmounting,
   });

   return (
      <div
         className={className}
         onClick={handleClick}
         onAnimationEnd={handleAnimationEnd}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
      >
         <div className='toast-message' role='alert'>
            <div className='toast-title'>{renderTitle()}</div>
            <div className='message'>{renderMessage()}</div>
         </div>
      </div>
   );
};

export default Toast;
