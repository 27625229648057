import * as _ from 'lodash';
import * as React from 'react';

import { ensureElement } from '@helpers/utils';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { DowntimeBanner, IApplicationState } from '@models/ApplicationState';
import CourseService from '@services/CourseService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { actionFunctions } from '@redux/Actions';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';

export enum BannerName {
   BROWSER_OFFLINE = 'BROWSER_OFFLINE',
   CONFIRM_EMAIL = 'CONFIRM_EMAIL',
   COULD_NOT_RECONNECT = 'COULD_NOT_RECONNECT',
   COURSE_TRIAL = 'COURSE_TRIAL',
   EXPIRED_COURSE_TRIAL = 'EXPIRED_COURSE_TRIAL',
   JOIN_COURSE = 'JOIN_COURSE',
   JOIN_COURSE_FAILURE = 'JOIN_COURSE_FAILURE',
   NEW_UPDATE_RELEASED = 'NEW_UPDATE_RELEASED',
   NONIDEAL_BROWSER = 'NONIDEAL_BROWSER',
   TRYING_TO_RECONNECT = 'TRYING_TO_RECONNECT',
   DOWNTIME_EVENT = 'DOWNTIME_EVENT',
}

const Banner: React.FC = () => {
   const {
      routes: {
         courses: { dashboard: courseDashboard },
      },
      statusCodes: { notFound, forbidden },
   } = Constants;
   const historyLocation = useLocation();

   const { banner, userProfile, setBanner, setPaidCourseId } =
      React.useContext<AppStateContext>(AppStateContext);

   const hideDowntimeBanner = useSelector<IApplicationState, DowntimeBanner>(
      (state) => state.hideDowntimeBanner,
   );

   const dispatch = useDispatch();

   if (!banner) {
      return null;
   }

   const {
      appearance = Appearance.primary,
      bannerName = null,
      body = null,
      className = '',
      color = null,
      data = {},
      dismissable = false,
      show = false,
      sticky = false,
      hideOnLocationChange = false,
   } = banner;

   const navigate = useNavigate();

   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [joinCourseSuccess, setJoinCourseSuccess] = React.useState<boolean>(false);

   const clearBanner = (): void => {
      if (bannerName === BannerName.DOWNTIME_EVENT) {
         dispatch(
            actionFunctions.setDowntimeBanner({
               ...hideDowntimeBanner,
               dismissed: true,
            }),
         );
      }
      setBanner({ body: '', show: false });
   };

   const clearAndRefresh = (): void => (clearBanner(), window.location.reload());

   const joinCourse = (inviteId: string): void => {
      if (!userProfile) {
         return;
      }
      setIsLoading(true);
      CourseService.joinCourseByInvite(inviteId)
         .then((courseId) => {
            setIsLoading(false);
            navigate(courseDashboard.replace(':courseId', courseId.toString()));
            setJoinCourseSuccess(true);
         })
         .catch((error) => {
            setIsLoading(false);
            setBanner({ body: '', show: false });
            if (error.response && error.response.status === notFound) {
               setBanner({
                  appearance: Appearance.danger,
                  dismissable: true,
                  show: true,
                  body: 'Invalid Invite: Invite not found.',
               });
            }
            if (error.response && error.response.status === forbidden) {
               const message =
                  userProfile.accountType === AccountType.student
                     ? 'Invalid Invite: You were invited as an instructor, please reach out to your instructor for a student invite or the course code.'
                     : 'Invalid Invite: You were invited as a student. Please reach out to the instructor of the course for an instructor invite.';
               setBanner({
                  appearance: Appearance.danger,
                  bannerName: BannerName.JOIN_COURSE_FAILURE,
                  dismissable: true,
                  show: true,
                  body: message,
               });
            }
         });
   };

   React.useEffect(() => {
      // race condition for bannerName in promise response.
      if (joinCourseSuccess && bannerName === BannerName.JOIN_COURSE) {
         clearBanner();
      }
      setJoinCourseSuccess(false);
   }, [joinCourseSuccess]);

   const sendConfirmationEmail = (): void => {
      if (!userProfile) {
         return;
      }
      setIsLoading(true);
      UserService.sendConfirmationEmail(userProfile.id).then(() => {
         setIsLoading(false);
      });
   };

   React.useEffect(() => {
      if (hideOnLocationChange) {
         clearBanner();
      }
   }, [historyLocation]);

   const renderPayNowButton = (courseId: number): React.ReactNode => (
      <Button
         line
         onClick={() => setPaidCourseId(courseId)}
         className='margin-left-m'
         data-test='pay-now-btn'
      >
         Pay Now
      </Button>
   );

   const renderBanner = (): React.ReactNode => {
      if (body) {
         return ensureElement(body, 'p');
      } else if (bannerName === BannerName.NEW_UPDATE_RELEASED) {
         return (
            <p>
               A new update to Lingco Classroom has been released! To refresh the page,
               <Button line onClick={clearAndRefresh} className='margin-left-s' loading={isLoading}>
                  Click here
               </Button>
            </p>
         );
      } else if (bannerName === BannerName.TRYING_TO_RECONNECT) {
         return (
            <p>
               Your connection is a bit spotty, some automatic updates may not come through. Trying
               to reconnect...
            </p>
         );
      } else if (bannerName === BannerName.COULD_NOT_RECONNECT) {
         return (
            <p>
               We could not reconnect to our auto-refresh server. To refresh the page and see
               updates,
               <Button line onClick={clearAndRefresh} className='margin-left-s' loading={isLoading}>
                  Click here
               </Button>
            </p>
         );
      } else if (bannerName === BannerName.COURSE_TRIAL && _.isNumber(data.courseId)) {
         return (
            <p>
               {`Your ${data.courseName} trial expires ${data.remainingTime}`}
               {renderPayNowButton(data.courseId)}
            </p>
         );
      } else if (bannerName === BannerName.EXPIRED_COURSE_TRIAL && _.isNumber(data.courseId)) {
         return (
            <p>
               {`Your ${data.courseName} trial has expired`}
               {renderPayNowButton(data.courseId)}
            </p>
         );
      } else if (bannerName === BannerName.JOIN_COURSE) {
         const inviteId = data.inviteId;
         if (_.isNumber(inviteId) || _.isString(inviteId)) {
            return (
               <p>
                  {`You have been invited to join ${data.courseName}.`}
                  <Button
                     line
                     onClick={() => joinCourse(inviteId.toString())}
                     className='margin-left-m'
                     loading={isLoading}
                  >
                     Join Now
                  </Button>
               </p>
            );
         }
      } else if (bannerName === BannerName.CONFIRM_EMAIL) {
         return (
            <p>
               Please confirm your email to access the full set of Lingco's features!
               <Button
                  line
                  onClick={sendConfirmationEmail}
                  className='margin-left-m'
                  loading={isLoading}
               >
                  Send Again
               </Button>
            </p>
         );
      } else if (bannerName === BannerName.NONIDEAL_BROWSER) {
         const { isDesktopOrLaptop } = data;
         if (isDesktopOrLaptop) {
            return (
               <p>
                  For the best experience, we recommend using Google Chrome.
                  <Link
                     external
                     to='https://www.google.com/chrome/browser/'
                     className='btn line margin-left-m'
                  >
                     Download
                  </Link>
               </p>
            );
         }
         return (
            <p>
               For the best experience, we recommend using Google Chrome on a laptop or desktop
               computer.
            </p>
         );
      }
      return null;
   };

   return (
      show && (
         <div
            className={classnames('banner', color, appearance, className, {
               sticky,
            })}
         >
            <div className='inner'>
               <div className={classnames('banner-body', { dismissable })}>{renderBanner()}</div>
               {dismissable && (
                  <div className='close'>
                     <IconClose onClick={clearBanner} className='close-icon' />
                  </div>
               )}
            </div>
         </div>
      )
   );
};

export default Banner;
