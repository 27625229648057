import { Subject } from 'rxjs';

const createName = (name: string) => `$ ${name}`;

/**
 * A class that allows emitting and listening to events.
 * It acts as a simple event emitter.
 */
class Emitter {
   subjects: Record<string, Subject<unknown>>;
   constructor() {
      /**
       * Storage for the subjects representing different events.
       *
       * @type {Record<string, Subject<unknown>>}
       */
      this.subjects = {};
   }

   /**
    * Emits an event with optional data.
    *
    * @param {string} name - The name of the event to emit.
    * @param {*} [data] - Optional data to pass along with the event.
    * @returns {Promise<void>} A promise that resolves when the event is emitted.
    */
   emit(name: string, data?: unknown) {
      const fnName = createName(name);
      // Create a new subject if it doesn't exist
      if (!Object.prototype.hasOwnProperty.call(this.subjects, fnName)) {
         this.subjects[fnName] = new Subject();
      }
      this.subjects[fnName].next(data);
      return Promise.resolve();
   }

   /**
    * Listens to a specific event and invokes the provided handler function when the event is emitted.
    *
    * @param {string} name - The name of the event to listen to.
    * @param {Function} handler - The function to be invoked when the event is emitted.
    * @returns {Subscription} A subscription representing the listener. Call `unsubscribe()` on it to stop listening.
    */
   listen(name: string, handler: (...args: any[]) => void) {
      const fnName = createName(name);
      // Create a new subject if it doesn't exist
      this.subjects[fnName] || (this.subjects[fnName] = new Subject());
      // Subscribe to the subject and return the subscription
      return this.subjects[fnName].subscribe(handler);
   }

   /**
    * Disposes all event listeners and clears the event storage.
    */
   dispose() {
      Object.keys(this.subjects).forEach((prop) => {
         if (Object.prototype.hasOwnProperty.call(this.subjects, prop)) {
            this.subjects[prop].unsubscribe(); // Unsubscribe from the subject
         }
      });
      this.subjects = {}; // Clear the subjects object
   }
}

export default Emitter;
