import * as React from 'react';

import VideoPlayer from '@components/Core/VideoPlayer';
import { Maybe } from '@models/Core';
import { VideoGetResponse } from '@models/Video';
import VideoService from '@services/VideoService';
import ReactPlayer from 'react-player';

interface Props {
   videoId: number;
   url?: string;
   useNativePlayer?: boolean;
}

const InlineVideoViewer: React.FC<Props> = (props) => {
   const [video, setVideo] = React.useState<Maybe<VideoGetResponse>>();
   const url = props.url || video?.url;

   React.useEffect(() => {
      if (props.videoId) {
         VideoService.get(props.videoId).then(setVideo);
      }
   }, [props.videoId]);

   if (!url) {
      return null;
   }

   if (props.useNativePlayer) {
      return (
         <div className='video-container'>
            <ReactPlayer
               config={{
                  file: {
                     attributes: {
                        disablePictureInPicture: true,
                        controlsList: 'nodownload',
                        preload: 'auto',
                     },
                  },
               }}
               controls
               height='100%'
               pip={false}
               style={{ position: 'absolute', top: 0, left: 0 }}
               url={url}
               width='100%'
            />
         </div>
      );
   }

   return (
      <VideoPlayer
         videoUrl={url}
         posterUrl={video?.imageUrl}
         videoContainerClassname='video-container'
      />
   );
};

export default InlineVideoViewer;
