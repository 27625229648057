import * as React from 'react';

import { parseHtml } from '@helpers/ParseHtml';

import { ActivityCompleterContext } from '@components/Activity/Completer/ActivityCompleter';

interface TextContentProps {
   text: string;
}

const TextContent: React.FC<TextContentProps> = ({ text }) => {
   const { variables } = React.useContext<ActivityCompleterContext>(ActivityCompleterContext);

   if (!text) {
      return null;
   }

   return <div className='activity-builder-row'>{parseHtml(text, undefined, variables)}</div>;
};

export default React.memo(TextContent);
