import { IdName } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { Speaker } from '@models/Speaker';
import AxiosService from './AxiosService';

const search = (filters: PagedSearchFilters): Promise<PagedResponse<IdName>> =>
   AxiosService.get<PagedResponse<IdName>>('/api/videos/speakers/search', {
      params: filters,
   }).then((response) => response.data);

const get = (id: number): Promise<Speaker> =>
   AxiosService.get<Speaker>(`/api/videos/speakers/${id}`).then((response) => response.data);

const update = async (speaker: Speaker): Promise<void> => {
   await AxiosService.patch<void>(`api/videos/speakers/${speaker.id}`, { speaker });
};

const create = (speaker: Speaker<true>): Promise<Speaker> =>
   AxiosService.post<Speaker>('api/videos/speakers', { speaker }).then((response) => response.data);

export default {
   search,
   get,
   update,
   create,
};
