import * as React from 'react';

const DropboxPrompt: React.FC = () => (
   <input
      type='text'
      placeholder='Student Uploads File Here'
      className='file-input-placeholder disabled'
      disabled
   />
);

export default DropboxPrompt;
