import { ContentType } from '@models/Content';
import Language from '@models/Language';

import { Assignment } from './Assignment';

export enum NonContentModuleItemType {
   file = 'file',
   link = 'link',
}

export const ModuleItemType = { ...ContentType, ...NonContentModuleItemType };

export type ModuleItemType = NonContentModuleItemType | ContentType;

export type AssignableModuleItemType = ContentType.activity | ContentType.vocabSet;

interface Permissions {
   canEdit: boolean;
   canPreviewContent: boolean;
}

export interface ModuleItemPrerequisite {
   id: number;
   prereqItemId: number;
   percentCompleted: number;
   itemName: string;
}

interface BaseModuleItem {
   id: number;
   index: number;
   itemName: string;
   itemType: ModuleItemType;
   permissions?: Permissions;
   prerequisites?: readonly ModuleItemPrerequisite[];
   prerequisitesLoaded?: boolean;
}

export interface ContentModuleItem extends BaseModuleItem {
   itemType: ContentType;
   itemLanguage: Language;
   itemId: number;
   canEdit?: boolean;
}

export interface ModuleItemLink extends BaseModuleItem {
   itemId?: number;
   itemType: NonContentModuleItemType.link;
   linkUrl?: string;
   name?: string;
}

export interface ModuleItemFile extends BaseModuleItem {
   itemId?: number;
   itemType: NonContentModuleItemType.file;
   path?: string;
   name: string;
   fileUrl?: string;
}

export interface AssignableModuleItem extends ContentModuleItem {
   assignment: Assignment;
   isAssigned: boolean;
   itemType: AssignableModuleItemType;
   lmsCourseworkIds: readonly number[];
   percentCompleted?: number;
}

export interface ModuleItemActivity extends AssignableModuleItem {
   itemType: ContentType.activity;
   submissionId?: number;
}

export interface ModuleItemVocabSet extends AssignableModuleItem {
   itemType: ContentType.vocabSet;
}

export interface ModuleItemLesson extends ContentModuleItem {
   itemType: ContentType.lesson;
}

export interface ModuleItemVideo extends ContentModuleItem {
   itemType: ContentType.video;
   enableTargetSubtitles: boolean;
   enableNativeSubtitles: boolean;
}

export type ModuleItem =
   | ModuleItemActivity
   | ModuleItemVocabSet
   | ModuleItemLesson
   | ModuleItemLink
   | ModuleItemFile
   | ModuleItemVideo
   | ContentModuleItem;
