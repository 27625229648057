import * as React from 'react';

import Emitter from '@utilities/Emitter';

/**
 * `useSubscription` is a custom hook that facilitates event subscription to an Emitter instance.
 * It registers a callback function to be invoked whenever the specified event is emitted.
 *
 * @param {Emitter} emitter The Emitter instance to subscribe to.
 * @param {string} event The name of the event to listen for.
 * @param {(...args: any[]) => void} callback The callback function to be invoked when the event is emitted.
 */
const useSubscription = (
   emitter: Emitter,
   event: string,
   callback: (...args: any[]) => void,
): void => {
   React.useEffect(() => {
      const subscription = emitter.listen(event, callback);
      return () => {
         subscription && subscription.unsubscribe();
      };
   }, [callback]);
};

export default useSubscription;
