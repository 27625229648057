import * as React from 'react';

export interface IOverlayProps {
   spotlightTarget?: DOMRect;
   padding?: number;
   paddingLeft?: number;
   paddingRight?: number;
   paddingTop?: number;
   paddingBottom?: number;
   rounded?: number;
   disableInteraction?: boolean;
   className?: string;
   onOverlayClick?(event: React.MouseEvent<HTMLDivElement>): void;
}

const Overlay: React.FC<IOverlayProps> = ({
   spotlightTarget,
   padding = 10,
   paddingTop: propsPaddingTop = null,
   paddingBottom: propsPaddingBottom = null,
   paddingLeft: propsPaddingLeft = null,
   paddingRight: propsPaddingRight = null,
   rounded = 5,
   disableInteraction = true,
   className,
   onOverlayClick,
}) => {
   const paddingTop = propsPaddingTop === null ? padding : propsPaddingTop;
   const paddingBottom = propsPaddingBottom === null ? padding : propsPaddingBottom;
   const paddingLeft = propsPaddingLeft === null ? padding : propsPaddingLeft;
   const paddingRight = propsPaddingRight === null ? padding : propsPaddingRight;

   const width = spotlightTarget
      ? Math.max(spotlightTarget.width + (paddingLeft + paddingRight), 0)
      : 0;
   const height = spotlightTarget
      ? Math.max(spotlightTarget.height + (paddingTop + paddingBottom), 0)
      : 0;
   const top = spotlightTarget ? Math.max(spotlightTarget.top - paddingTop, 0) : 0;
   const left = spotlightTarget ? Math.max(spotlightTarget.left - paddingLeft, 0) : 0;

   const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

   const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

   return (
      <div className='onboarding-overlay' onClick={onOverlayClick}>
         <svg
            width={windowWidth}
            height={windowHeight}
            xmlns='http://www.w3.org/2000/svg'
            pointerEvents={disableInteraction ? 'all' : 'none'}
            className={className}
         >
            {spotlightTarget && (
               <>
                  <defs>
                     <mask id='mask-main'>
                        <rect x={0} y={0} width={windowWidth} height={windowHeight} fill='white' />
                        <rect x={left} y={top} width={width} height={height} fill='black' />
                        {/* top left rounded corner */}
                        <rect
                           x={left - 1}
                           y={top - 1}
                           width={rounded}
                           height={rounded}
                           fill='white'
                        />
                        <circle cx={left + rounded} cy={top + rounded} r={rounded} fill='black' />
                        {/* top right rounded corner */}
                        <rect
                           x={left + width - rounded + 1}
                           y={top - 1}
                           width={rounded}
                           height={rounded}
                           fill='white'
                        />
                        <circle
                           cx={left + width - rounded}
                           cy={top + rounded}
                           r={rounded}
                           fill='black'
                        />
                        {/* bottom left rounded corner */}
                        <rect
                           x={left - 1}
                           y={top + height - rounded + 1}
                           width={rounded}
                           height={rounded}
                           fill='white'
                        />
                        <circle
                           cx={left + rounded}
                           cy={top + height - rounded}
                           r={rounded}
                           fill='black'
                        />
                        {/* bottom right rounded corner */}
                        <rect
                           x={left + width - rounded + 1}
                           y={top + height - rounded + 1}
                           width={rounded}
                           height={rounded}
                           fill='white'
                        />
                        <circle
                           cx={left + width - rounded}
                           cy={top + height - rounded}
                           r={rounded}
                           fill='black'
                        />
                     </mask>
                     <clipPath id='clip-path'>
                        {/* top */}
                        <rect x={0} y={0} width={windowWidth} height={top} />
                        {/* left */}
                        <rect x={0} y={top} width={left} height={height} />
                        {/* right */}
                        <rect
                           x={spotlightTarget.left + spotlightTarget.width + padding}
                           y={top}
                           width={Math.max(windowWidth - spotlightTarget.width - left, 0)}
                           height={height}
                        />
                        {/* bottom */}
                        <rect
                           x={0}
                           y={spotlightTarget.top + spotlightTarget.height + padding}
                           width={windowWidth}
                           height={Math.max(windowHeight - spotlightTarget.height - top, 0)}
                        />
                     </clipPath>
                  </defs>
                  <rect
                     x={0}
                     y={0}
                     width={windowWidth}
                     height={windowHeight}
                     fill='currentColor'
                     clipPath='url(#clip-path)'
                     pointerEvents='auto'
                  />
                  <rect
                     x={left}
                     y={top}
                     width={width}
                     height={height}
                     pointerEvents='auto'
                     fill='transparent'
                     display={disableInteraction ? 'block' : 'none'}
                  />
               </>
            )}
            <rect
               x={0}
               y={0}
               width={windowWidth}
               height={windowHeight}
               fill='currentColor'
               mask='url(#mask-main)'
            />
         </svg>
      </div>
   );
};

export default Overlay;
