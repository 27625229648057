import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { Maybe } from '@models/Core';
import { Product } from '@services/ProductsService';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementOptions, StripeError } from '@stripe/stripe-js';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import { PaymentIntentInfo } from './PaymentModal';

interface IStripeHandlerEvent {
   complete: boolean;
   brand: string;
   elementType: string;
   empty: boolean;
   error?: StripeError;
   value: Record<string, unknown>;
}

interface IStripeCheckoutFormProps {
   paymentIntentInfo: PaymentIntentInfo;
   product?: Product;
   price: number;
   codeLabel: string;
   showBack: boolean;
   onSuccess(): void;
   togglePaymentMethod(): void;
   onBack(): void;
}

const CARD_OPTIONS: StripeCardElementOptions = {
   iconStyle: 'solid',
   style: {
      base: {
         iconColor: '#413F48',
         color: '#413F48',
         fontWeight: '500',
         fontFamily: "'Karla', sans-serif",
         fontSize: '15px',
         fontSmoothing: 'antialiased',
         '::placeholder': {
            color: '#a3a3a3',
            fontWeight: '400',
         },
      },
      invalid: {
         iconColor: '#FF5B5B',
         color: '#FF5B5B',
      },
   },
};

const StripeCheckoutForm: React.FC<IStripeCheckoutFormProps> = ({
   showBack,
   paymentIntentInfo,
   price,
   codeLabel,
   onBack,
   togglePaymentMethod,
   onSuccess,
}) => {
   const [error, setError] = React.useState<Maybe<StripeError>>(null);
   const [isLoading, setLoading] = React.useState<boolean>(false);

   const stripe = useStripe();
   const elements = useElements();
   const initializingPayment = !paymentIntentInfo;

   const handleCardElementChange = (event: IStripeHandlerEvent): void => {
      setError(event.error);
   };

   const handleBackClick = (): void => {
      onBack();
   };

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      const { clientSecret, receiptEmail } = paymentIntentInfo;

      event.preventDefault();
      if (!stripe || !elements) {
         return;
      }
      setLoading(true);
      stripe
         .confirmCardPayment(
            clientSecret,
            snakeCaseKeys({
               paymentMethod: {
                  card: elements.getElement(CardElement),
               },
               receiptEmail,
            }),
         )
         .then((payload) => {
            setLoading(false);
            if (payload.error) {
               setError(payload.error);
            } else {
               onSuccess();
            }
         });
   };

   return (
      <form className='stripe-form' onSubmit={handleSubmit}>
         <fieldset className='stripe-form-group'>
            <CardElement options={CARD_OPTIONS} onChange={handleCardElementChange} />
         </fieldset>
         {error && (
            <div className='payment-error' role='alert'>
               {error.message}
            </div>
         )}
         {showBack ? (
            <Button
               className='submit-payment-back-btn payment-btn'
               line
               disabled={isLoading || !stripe || initializingPayment}
               onClick={handleBackClick}
            >
               Back
            </Button>
         ) : (
            <Button subtle onClick={togglePaymentMethod}>
               Use {codeLabel} Code
            </Button>
         )}
         <Button
            className={classnames('payment-btn', {
               'credit-card-pay-btn-full': !showBack,
               'credit-card-pay-btn-partial': showBack,
            })}
            type='submit'
            disabled={isLoading || !stripe || initializingPayment}
            loading={isLoading}
         >
            {price ? `Pay $${price}` : 'Loading...'}
         </Button>
      </form>
   );
};

export default React.memo(StripeCheckoutForm);
