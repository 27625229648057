import * as React from 'react';

import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { ButtonProps } from '@components/Common/Button';

interface SectionCardProps {
   children: React.ReactNode;
   className?: string;
   headerButton?: React.ReactElement<ButtonProps>;
   title?: string;
   toolTipContent?: string;
}

export const SectionCard: React.FC<SectionCardProps> = (props) => (
   <div className='col-xs-12 col-md-12 col-lg-8'>
      <div className={classnames('card course-card', props.className)}>
         <div className='content-form'>
            {props.title && (
               <div className='card-title has-button full-width'>
                  {props.toolTipContent ? (
                     <Tippy content={props.toolTipContent}>
                        <div className='title'>{props.title}</div>
                     </Tippy>
                  ) : (
                     <div className='title'>{props.title}</div>
                  )}
                  {props.headerButton && props.headerButton}
               </div>
            )}
            <div>{props.children}</div>
         </div>
      </div>
   </div>
);
