/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import CanvasErrorDisplay from '@components/CanvasErrorDisplay';
import Button from '@components/Common/Button';
import Switch from '@components/Common/Switch';
import DateTimePicker from '@components/Core/DateTimePicker';
import FeatureCheck from '@components/Core/FeatureCheck';
import DocumentTitle from '@components/DocumentTitle';
import ErrorDisplay from '@components/ErrorDisplay';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import SchoolSelect from '@components/SchoolSelect';
import autobind from '@helpers/autobind';
import languageOptions from '@helpers/LanguageOptions';
import { Breadcrumb } from '@models/Breadcrumbs';
import { ExternalAPIError } from '@models/Canvas';
import { Maybe } from '@models/Core';
import {
   BasicCourseProfile,
   Course,
   CourseLMSConnection,
   CourseMode,
   CourseSection,
   ProficiencyGoals,
} from '@models/Course';
import Language from '@models/Language';
import LMSName from '@models/LMSName';
import { OrganizationCourseLevel } from '@models/Organization';
import SchoolProfile from '@models/SchoolProfile';
import CourseService from '@services/CourseService';
import HttpService from '@services/HttpService';
import OrganizationService from '@services/OrganizationService';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { Values } from 'react-currency-format';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import mixPanelActions from '../../../Mixpanel';
import { RouteComponentProps } from '../../../types/Routing';
import ArchiveCourse from './ArchiveCourse';
import CloneCourse from './CloneCourse';
import CourseCanDoStatements from './CourseCanDoStatements';
import CourseCreateSelectTemplate from './CourseCreateSelectTemplate';
import CourseCreateTemplateOrNew from './CourseCreateTemplateOrNew';
import CourseDescription from './CourseDescription';
import CoursePaymentInfo from './CoursePaymentInfo';
import CoursePermissionsCard from './CoursePermissionsCard';
import CourseProficiencyGoals from './CourseProficiencyGoals';
import DeleteCourse from './DeleteCourse';
import { createDefaultCourse, proficiencyUndefinedProperties } from './Helpers';
import { CanvasConnect, GoogleClassroomConnect, SchoologyConnect } from './LMSConnections';
import SectionsTable from './SectionsTable';
import { Steps } from './types';

export interface CourseEditorProps extends RouteComponentProps {
   mode: 'create' | 'edit';
}

export interface ICurrencyFormatValues {
   formattedValues: string;
   value: string;
   floatValue: number;
}

interface FormErrors {
   name?: string;
   language?: string;
   proficiencyGoals?: string;
   levelId?: string;
}

interface CourseEditorState {
   course: Course<CourseMode.create | CourseMode.edit>;
   courseToCloneFromId: number | undefined;
   defaultPrice: Maybe<number>;
   enableCourseProficiency: boolean;
   errors: FormErrors;
   isDeletingCourse: boolean;
   isFetching: boolean;
   isLoading: boolean;
   organizationCourseLevels: OrganizationCourseLevel[];
   origCourse?: Course<CourseMode.edit>;
   sectionsEnabled: boolean;
   step: Steps;
   error?: ExternalAPIError;
}

class CourseEditor extends React.Component<CourseEditorProps, CourseEditorState> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   constructor(props: CourseEditorProps) {
      super(props);
      autobind(this);

      this.state = this.initialState;
   }

   get initialState(): CourseEditorState {
      return {
         course: createDefaultCourse(),
         courseToCloneFromId: undefined,
         defaultPrice: null,
         enableCourseProficiency: false,
         errors: {},
         isDeletingCourse: false,
         isFetching: false,
         isLoading: false,
         organizationCourseLevels: [],
         sectionsEnabled: false,
         step: this.props.mode === 'edit' ? 'form' : 'chose_template_or_new',
         error: undefined,
      };
   }

   getCourseIdFromParams(): Maybe<number> {
      if (this.props.params?.courseId) {
         return parseInt(this.props.params.courseId);
      }
      return null;
   }

   componentDidMount(): void {
      const {
         routes: {
            courses: { create },
         },
      } = Constants;
      const { userProfile } = this.context;
      this.fetchOrganizationCourseLevels();

      if (this.props.mode === 'create') {
         this.context.setBreadcrumbs({
            breadcrumbs: [{ link: create, text: 'Create Course' }],
            next: null,
            prev: null,
         });
         this.setState((prevState) => ({
            ...prevState,
            ...this.initialState,
            course: {
               ...prevState.course,
               language: userProfile?.language,
            },
         }));
      } else {
         const courseId = this.getCourseIdFromParams();
         if (courseId) {
            this.fetchCourse(courseId);
         }
      }
   }

   componentDidUpdate(prevProps: CourseEditorProps): void {
      const {
         routes: {
            courses: { create },
         },
      } = Constants;
      if (prevProps.mode !== 'create' && this.props.mode === 'create') {
         this.context.setBreadcrumbs({
            breadcrumbs: [{ link: create, text: 'Create Course' }],
            prev: null,
            next: null,
         });
      } else if (prevProps.params.courseId !== this.props.params.courseId) {
         const newCourseId = this.getCourseIdFromParams();
         if (newCourseId) {
            this.fetchCourse(newCourseId);
         }
      }
   }

   fetchOrganizationCourseLevels(): void {
      OrganizationService.getCourseLevels().then((organizationCourseLevels) => {
         this.setState(() => ({ organizationCourseLevels }));
      });
   }

   fetchCourse(courseId: number): void {
      const {
         routes: {
            courses: { dashboard, edit },
         },
      } = Constants;
      this.setState({ isFetching: true });
      HttpService.getWithAuthToken<Course<CourseMode.edit>>(
         `/api/courses/${courseId}?enrollment_count=true&lms_info=true&lti_configured=true`,
      )
         .then((response) => {
            const {
               archived,
               canEdit,
               canLeaveCourse,
               defaultDueTime,
               defaultPrice,
               demo,
               description,
               endDate,
               isOrganizationStandard,
               isProtected,
               isSharedWithOrganization,
               language,
               levelId,
               lmsConnections,
               lmsInfo,
               name,
               priceOverride,
               proficiencyGoals,
               school,
               sections,
               shouldHideStudentGradebook,
               showVocabStats,
               startDate,
            } = response.data;
            this.setState(
               {
                  course: {
                     ...this.initialState.course,
                     archived,
                     canEdit,
                     canLeaveCourse,
                     defaultDueTime,
                     demo,
                     description,
                     endDate,
                     isOrganizationStandard,
                     isSharedWithOrganization,
                     shouldHideStudentGradebook,
                     showVocabStats,
                     id: courseId,
                     language,
                     levelId,
                     lmsConnections,
                     lmsInfo,
                     name,
                     priceOverride: priceOverride !== null ? priceOverride : null,
                     proficiencyGoals: proficiencyGoals || {
                        ...proficiencyUndefinedProperties,
                     },
                     sections,
                     startDate,
                     school,
                     isProtected,
                  },
                  defaultPrice,
                  isFetching: false,
                  sectionsEnabled: !!sections?.length,
                  enableCourseProficiency: !!proficiencyGoals,
               },
               () => {
                  this.setState((prevState) => ({
                     origCourse: _.cloneDeep(prevState.course as Course<CourseMode.edit>),
                  }));
               },
            );

            if (!this.state.courseToCloneFromId) {
               const breadcrumbs: readonly Breadcrumb[] = [
                  {
                     link: dashboard.replace(':courseId', courseId.toString()),
                     text: name,
                     contextInfo: { courseId, courseArchived: archived },
                  },
                  {
                     link: edit.replace(':courseId', courseId.toString()),
                     text: 'Edit Course',
                  },
               ];
               this.context.setBreadcrumbs({ breadcrumbs, next: null, prev: null });
            }
         })
         .catch((err) => {
            this.setState(() => ({
               error: err?.response?.data,
               isFetching: false,
            }));

            console.error(err?.response?.data);
         });
   }

   getOrganizationCourseLevelOptions(): React.ReactNode[] {
      const options: React.ReactNode[] = [
         <option key={0} value=''>
            Select
         </option>,
      ];

      const courseLevelOptions = this.state.organizationCourseLevels.map((x) => (
         <option key={x.id} value={x.id}>
            {x.name}
         </option>
      ));

      options.push(...courseLevelOptions);

      return options;
   }

   async validateForm(): Promise<boolean> {
      const { course, origCourse, enableCourseProficiency } = this.state;
      const errors: FormErrors = {};

      if (!course.name.length) {
         errors.name = 'Cannot be blank';
      } else if (
         this.props.mode === CourseMode.create &&
         (await CourseService.checkIfCourseExistsByName(course.name))
      ) {
         errors.name = `'${course.name}' is already taken`;
      } else if (
         course.name.toLowerCase() !== (origCourse?.name || '').toLowerCase() &&
         course.id &&
         (await CourseService.checkIfCourseExistsByName(course.name, course.id))
      ) {
         errors.name = `'${course.name}' is already taken`;
      }

      if (enableCourseProficiency) {
         if (!course.proficiencyGoals) {
            errors.proficiencyGoals = 'Need to select all proficiency levels';
         } else {
            for (const key in course.proficiencyGoals) {
               if (!course.proficiencyGoals[key as keyof ProficiencyGoals]) {
                  errors.proficiencyGoals = 'Need to select all proficiency levels';
                  break;
               }
            }
         }

         if (!course.levelId) {
            errors.levelId = 'Level required if proficiency is enabled';
         }
      }

      this.setState({ errors });
      return Object.keys(errors).length === 0;
   }

   archiveCourse(): void {
      this.setCourseArchived(true);
   }

   unarchiveCourse(): void {
      this.setCourseArchived(false);
   }

   setCourseArchived(archived: boolean): void {
      const {
         routes: {
            courses: { dashboard },
         },
      } = Constants;
      const { navigate } = this.props;
      const { setCourses } = this.context;
      const { course } = this.state;
      if (!course.id) {
         return;
      }
      const courseId = course.id;
      this.setState({ isLoading: true });

      const updatedCourse: Partial<Course<CourseMode.edit>> = { archived };

      CourseService.patchCourse(course.id, updatedCourse).then((responseData) => {
         mixPanelActions.track('Course Archived', {
            id: course.id,
            name: course.name,
            language: course.language,
         });

         this.setState((prevState) => ({
            course: {
               ...prevState.course,
               archived,
            },
            isLoading: false,
         }));

         const {
            courses: { archived: archivedCourses, current: currentCourses },
         } = responseData;
         setCourses(currentCourses, archivedCourses);

         navigate(dashboard.replace(':courseId', courseId.toString()));
      });
   }

   deleteCourse(): void {
      const {
         routes: {
            courses: { dashboard, create },
         },
      } = Constants;
      const {
         course: { id, name, language },
      } = this.state;
      const { navigate } = this.props;
      if (!id) {
         return;
      }
      this.setState({ isDeletingCourse: true });
      CourseService.deleteCourse(id).then(({ courses: { current } }) => {
         mixPanelActions.track('Course Deleted', { id, name, language });

         const firstCourse = current.length ? current[0].id : null;
         const courseUrl =
            firstCourse !== null ? dashboard.replace(':courseId', firstCourse.toString()) : create;
         navigate(courseUrl);
      });
   }

   exitWithoutSaving(): void {
      const {
         routes: { courses },
      } = Constants;
      const { mode } = this.props;
      const {
         course: { id: courseId },
      } = this.state;
      if (mode === 'edit' && courseId) {
         this.props.navigate(courses.dashboard.replace(':courseId', courseId.toString()));
      } else {
         this.setState(() => this.initialState);
      }
   }

   async handleSubmit(): Promise<void> {
      const { mode } = this.props;
      const { course, origCourse, courseToCloneFromId } = this.state;
      if (this.state.enableCourseProficiency === false) {
         course.proficiencyGoals = undefined;
      }

      if (!(await this.validateForm())) {
         return;
      }

      this.setState({ isLoading: true });
      if (mode === CourseMode.create) {
         const { userProfile } = this.context;
         if (userProfile?.isDistrictAdmin) {
            course.isProtected = course.isOrganizationStandard;
            course.isSharedWithOrganization = course.isOrganizationStandard;
         } else {
            course.isProtected = false;
            course.isOrganizationStandard = false;
            course.isSharedWithOrganization = false;
         }

         if (course && courseToCloneFromId) {
            const newCourse = await CourseService.createCourseFromTemplate(
               courseToCloneFromId,
               course as Course<CourseMode.create>,
            );

            const courseData = await CourseService.getCourses();
            this.setCoursesAndRedirect(
               courseData.courses.archived,
               courseData.courses.current,
               newCourse.newCourseId,
            );
         } else {
            CourseService.createCourse(course).then(({ courses: { archived, current }, id }) => {
               mixPanelActions.track('Course Created', {
                  id,
                  name: course.name,
                  language: course.language,
                  sections: course.sections?.length || 0,
               });

               this.setCoursesAndRedirect(archived, current, id);
            });
         }
      } else if (origCourse) {
         const courseId = course.id;
         const updatedCourse: Partial<Course<CourseMode.edit>> = diff(origCourse, course);
         if ('sections' in updatedCourse) {
            updatedCourse.sections = course.sections;
         }
         if ('lmsConnections' in updatedCourse) {
            updatedCourse.lmsConnections = course.lmsConnections;
         }
         if (updatedCourse && courseId !== null) {
            CourseService.patchCourse(courseId, updatedCourse).then((responseData) => {
               const {
                  courses: { archived, current },
               } = responseData;
               this.setCoursesAndRedirect(archived, current, courseId);
            });
         }
      }
   }

   handleCourseChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
      const { name } = event.target;
      let value: Maybe<string> = event.target.value;
      if (name === 'levelId' && value === '') {
         value = null;
      }
      this.setState((prevState) => ({
         course: { ...prevState.course, [name]: value },
      }));
   }

   handleDescriptionChange(description: string): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, description },
      }));
   }

   setCoursesAndRedirect(
      archived: readonly BasicCourseProfile[],
      current: readonly BasicCourseProfile[],
      id: number,
   ): void {
      const { routes } = Constants;
      const { setCourses } = this.context;
      setCourses(current, archived);
      this.props.navigate(routes.courses.dashboard.replace(':courseId', id.toString()));
   }

   appendSection(section: CourseSection<CourseMode.create | CourseMode.edit>): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            sections: [...(prevState.course.sections || []), section],
         },
      }));
   }

   updateSection(sectionId: number, update: Partial<CourseSection>): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            sections: [...(prevState.course.sections || [])].map((i) =>
               i.id === sectionId ? { ...i, ...update } : i,
            ),
         },
      }));
   }

   deleteSection(sectionId: number): void {
      this.setState((prevState) => {
         const updatedSections = (prevState.course.sections || []).filter(
            (i) => i.id !== sectionId,
         );
         return {
            course: {
               ...prevState.course,
               sections: updatedSections,
            },
            sectionsEnabled: !!updatedSections.length,
         };
      });
   }

   toggleSections(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            sections: [],
         },
         sectionsEnabled: !prevState.sectionsEnabled,
      }));
   }

   setEnableCourseProficiency(isEnabled: boolean): void {
      this.setState(() => ({
         enableCourseProficiency: isEnabled,
      }));
   }

   toggleShouldHideStudentGradebook(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            shouldHideStudentGradebook: !prevState.course.shouldHideStudentGradebook,
         },
      }));
   }

   toggleCanLeaveCourse(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            canLeaveCourse: !prevState.course.canLeaveCourse,
         },
      }));
   }

   toggleShowVocabStats(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            showVocabStats: !prevState.course.showVocabStats,
         },
      }));
   }

   toggleIsSharedWithOrganization(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            isSharedWithOrganization: !prevState.course.isSharedWithOrganization,
         },
      }));
   }

   toggleIsStandard(): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            isOrganizationStandard: !prevState.course.isOrganizationStandard,
         },
      }));
   }

   setLMSConnections(lmsConnections: readonly CourseLMSConnection[]): void {
      this.setState((prevState) => ({
         course: {
            ...prevState.course,
            lmsConnections,
         },
      }));
   }

   toggleOverridePrice(): void {
      this.setState((prevState) => ({
         ...prevState,
         course: {
            ...prevState.course,
            priceOverride: prevState.course.priceOverride !== null ? null : prevState.defaultPrice,
         },
      }));
   }

   handleOverridePriceChange({ floatValue }: Values): void {
      this.setState((prevState) => ({
         ...prevState,
         course: {
            ...prevState.course,
            priceOverride: isNaN(floatValue) ? 0 : floatValue,
         },
      }));
   }

   handleDefaultDueTimeChange(defaultDueTime: Date): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, defaultDueTime },
      }));
   }

   handleStartDateChange(startDate: Date): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, startDate },
      }));
   }

   handleEndDateChange(endDate: Date): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, endDate },
      }));
   }

   handleSchoolChange(school: SchoolProfile): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, school },
      }));
   }

   handleProficiencyGoalsChange(proficiencyGoals?: ProficiencyGoals): void {
      this.setState((prevState) => ({
         course: { ...prevState.course, proficiencyGoals },
      }));
   }

   setCourseCloneFromId(courseToCloneFromId: number): void {
      this.setState(() => ({ courseToCloneFromId }));
      this.fetchCourse(courseToCloneFromId);
   }

   setStep(step: Steps): void {
      this.setState(() => ({ step }));
   }

   render(): React.ReactNode {
      const { currentCourses, userProfile } = this.context;
      const { mode } = this.props;
      const {
         course,
         defaultPrice,
         errors,
         isDeletingCourse,
         isFetching,
         isLoading,
         sectionsEnabled,
         error,
      } = this.state;
      const {
         canLeaveCourse,
         defaultDueTime,
         endDate,
         isOrganizationStandard,
         isSharedWithOrganization,
         language,
         levelId,
         lmsInfo,
         name,
         priceOverride,
         school,
         sections,
         shouldHideStudentGradebook,
         showVocabStats,
         startDate,
      } = course;
      const isNew = mode === CourseMode.create;
      const showSchool = !isNew && userProfile?.isAdmin;

      if (isFetching) {
         return <Loader />;
      }

      if (error && error.externalApiUrl) {
         return <CanvasErrorDisplay error={error} />;
      }

      if (isNew && this.state.step === 'chose_template_or_new') {
         return <CourseCreateTemplateOrNew setStep={this.setStep} />;
      }

      if (isNew && this.state.step === 'select_template') {
         return (
            <CourseCreateSelectTemplate
               setCourseToCloneFromId={this.setCourseCloneFromId}
               setStep={this.setStep}
            />
         );
      }

      if (!isNew && !course.canEdit && !userProfile?.isAdmin) {
         return <ErrorDisplay heading='Error' message='This course cannot be edited' />;
      }

      return (
         <>
            <DocumentTitle>{`${isNew ? 'Create' : 'Edit'} Course`}</DocumentTitle>
            <div className='content-main margin-right-m'>
               <div className='row center-xs'>
                  <div className='col-xs-12 col-md-12 col-lg-9'>
                     <div className='card course-card'>
                        <div className='content-form'>
                           <div className='card-title has-button full-width'>
                              <div className='title'>{isNew ? 'Create' : 'Edit'} Course</div>
                              <div className='action-buttons'>
                                 {!!currentCourses.length && (
                                    <Button
                                       line
                                       data-test='cancel-save-or-create-course-btn'
                                       onClick={this.exitWithoutSaving}
                                    >
                                       Cancel
                                    </Button>
                                 )}
                                 <Button
                                    data-test='save-or-create-course-btn'
                                    onClick={this.handleSubmit}
                                    loading={isLoading}
                                 >
                                    {isNew ? 'Create Course' : 'Save'}
                                 </Button>
                              </div>
                           </div>
                           <div className='row'>
                              <div className='col-xs-12'>
                                 <label className='field-title'>
                                    Course Name
                                    <InfoTooltip>Example: German 101 (Fall &apos;22)</InfoTooltip>
                                 </label>
                                 <AccentTextbox
                                    name='name'
                                    language={language}
                                    value={name}
                                    autoFocus={isNew}
                                    onChange={this.handleCourseChange}
                                    className={classNames({ error: errors.name })}
                                    data-test='course-name'
                                 />
                                 {errors.name && <p className='error small'>{errors.name}</p>}
                              </div>
                           </div>
                           <div className='flex flex-gap-s'>
                              <div className='flex-1'>
                                 <label className='field-title'>Language</label>
                                 <select
                                    name='language'
                                    value={language ?? ''}
                                    className={classNames({ error: errors.language })}
                                    onChange={this.handleCourseChange}
                                 >
                                    {languageOptions}
                                 </select>
                                 {errors.language && (
                                    <p className='error small'>{errors.language}</p>
                                 )}
                              </div>
                              <div className='flex-1'>
                                 <label className='field-title'>Level</label>
                                 <select
                                    name='levelId'
                                    value={levelId}
                                    onChange={this.handleCourseChange}
                                    className={classNames({ error: errors.levelId })}
                                    disabled={this.getOrganizationCourseLevelOptions().length === 1}
                                    data-test='level-select'
                                 >
                                    {this.getOrganizationCourseLevelOptions()}
                                 </select>
                                 {errors.levelId && <p className='error small'>{errors.levelId}</p>}
                              </div>
                           </div>
                           <div className='flex flex-gap-s'>
                              <div className='flex-1'>
                                 <label className='field-title'>Start Date</label>
                                 <DateTimePicker
                                    onChange={this.handleStartDateChange}
                                    value={startDate}
                                 />
                              </div>
                              <div className='flex-1'>
                                 <label className='field-title'>End Date</label>
                                 <DateTimePicker
                                    onChange={this.handleEndDateChange}
                                    value={endDate}
                                 />
                              </div>
                           </div>
                           <div className='flex'>
                              <div className='flex-1'>
                                 <label className='field-title'>Default Due Time</label>
                                 <DateTimePicker
                                    onChange={this.handleDefaultDueTimeChange}
                                    value={defaultDueTime}
                                    calendar={false}
                                 />
                              </div>
                           </div>
                           {showSchool && (
                              <div className='flex'>
                                 <div className='flex-1'>
                                    <label className='field-title'>School</label>
                                    <SchoolSelect
                                       value={school}
                                       onChange={this.handleSchoolChange}
                                    />
                                 </div>
                              </div>
                           )}
                           <div className='flex'>
                              <div className='flex-1'>
                                 <label className='field-title'>Enable Sections</label>
                                 <Switch
                                    checked={!!sections?.length}
                                    onChange={this.toggleSections}
                                 />
                              </div>
                              <div className='flex-1'>
                                 <label className='field-title'>Hide Gradebook From Students</label>
                                 <Switch
                                    checked={shouldHideStudentGradebook}
                                    onChange={this.toggleShouldHideStudentGradebook}
                                 />
                              </div>
                           </div>
                           <div className='flex'>
                              <div className='flex-1'>
                                 <label className='field-title'>
                                    Allow Students to Leave Course
                                 </label>
                                 <Switch
                                    checked={canLeaveCourse}
                                    onChange={this.toggleCanLeaveCourse}
                                 />
                              </div>
                              <div className='flex-1'>
                                 <label className='field-title'>Show vocab stats</label>
                                 <Switch
                                    checked={showVocabStats}
                                    onChange={this.toggleShowVocabStats}
                                 />
                              </div>
                           </div>
                           <div className='flex'>
                              {userProfile?.isDistrictAdmin && (
                                 <>
                                    <div className='flex-1'>
                                       <Tippy content='A standard outlines learning expectations in your organization.'>
                                          <label className='field-title'>Standard</label>
                                       </Tippy>
                                       <Switch
                                          checked={isOrganizationStandard}
                                          onChange={this.toggleIsStandard}
                                          data-test='is-organization-standard-select'
                                       />
                                    </div>
                                    <FeatureCheck feature='share_course_with_organization'>
                                       <div className='flex-1'>
                                          <Tippy content='Sharing with your organization allows other instructors in your organization to clone this course.'>
                                             <label className='field-title'>
                                                Share with Organization
                                             </label>
                                          </Tippy>
                                          <Switch
                                             checked={isSharedWithOrganization}
                                             onChange={this.toggleIsSharedWithOrganization}
                                             data-test='is-share-with-organization-select'
                                          />
                                       </div>
                                    </FeatureCheck>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
                  {sectionsEnabled && course.sections && course.defaultDueTime && (
                     <div className='col-xs-12 col-md-12 col-lg-9'>
                        <SectionsTable
                           courseDefaultDueTime={course.defaultDueTime}
                           language={course.language}
                           sections={course.sections}
                           updateSection={this.updateSection}
                           deleteSection={this.deleteSection}
                           appendSection={this.appendSection}
                           toggleSections={this.toggleSections}
                        />
                     </div>
                  )}
                  {userProfile?.isAdmin && course.id && mode === 'edit' && (
                     <div className='col-xs-12 col-md-12 col-lg-9'>
                        {defaultPrice && (
                           <CoursePaymentInfo
                              courseId={course.id}
                              onOverridePriceChange={this.handleOverridePriceChange}
                              toggleOverridePrice={this.toggleOverridePrice}
                              defaultPrice={defaultPrice}
                              priceOverride={priceOverride}
                           />
                        )}
                        {course.id && <CoursePermissionsCard courseId={course.id} />}
                     </div>
                  )}
                  <div className='col-xs-12 col-md-12 col-lg-9'>
                     <FeatureCheck feature='proficiency_tracking'>
                        {course.proficiencyGoals && (
                           <CourseProficiencyGoals
                              courseProficiencyGoals={course.proficiencyGoals}
                              formErrorMessage={this.state.errors.proficiencyGoals}
                              enableCourseProficiency={this.state.enableCourseProficiency}
                              setCourseProficiencyGoals={this.handleProficiencyGoalsChange}
                              setEnableCourseProficiency={this.setEnableCourseProficiency}
                           />
                        )}
                     </FeatureCheck>
                  </div>
                  {!isNew && (
                     <div className='col-xs-12 col-md-12 col-lg-9'>
                        <FeatureCheck feature='proficiency_tracking'>
                           <CourseCanDoStatements course={course} />
                        </FeatureCheck>
                     </div>
                  )}
                  {!!language && (
                     <div className='col-xs-12 col-md-12 col-lg-9'>
                        <CourseDescription
                           language={course.language as Language}
                           description={course.description}
                           handleDescriptionChange={this.handleDescriptionChange}
                        />
                     </div>
                  )}
                  {!isNew && (
                     <>
                        {lmsInfo?.lms === LMSName.canvas && course.id && (
                           <div className='col-xs-12 col-md-12 col-lg-9'>
                              <CanvasConnect
                                 courseId={course.id}
                                 shouldAllowLtiAppInstallation={
                                    course.lmsInfo
                                       ? course.lmsInfo.shouldAllowLtiAppInstallation
                                       : false
                                 }
                                 lmsConnections={course.lmsConnections}
                                 lmsDisplayName='Canvas'
                                 sections={sections}
                                 setLMSConnections={this.setLMSConnections}
                              />
                           </div>
                        )}
                        {lmsInfo?.lms === LMSName.googleClassroom && (
                           <div className='col-xs-12 col-md-12 col-lg-9'>
                              <GoogleClassroomConnect
                                 lmsDisplayName='Google Classroom'
                                 sections={sections}
                                 lmsConnections={course.lmsConnections}
                                 setLMSConnections={this.setLMSConnections}
                              />
                           </div>
                        )}
                        {lmsInfo?.lms === LMSName.schoology && (
                           <div className='col-xs-12 col-md-12 col-lg-9'>
                              <SchoologyConnect
                                 lmsDisplayName='Schoology'
                                 sections={sections}
                                 lmsConnections={course.lmsConnections}
                                 setLMSConnections={this.setLMSConnections}
                              />
                           </div>
                        )}
                        <div className='col-xs-12 col-md-12 col-lg-9'>
                           <CloneCourse course={course} />
                        </div>
                        <div className='col-xs-12 col-md-12 col-lg-9'>
                           <ArchiveCourse
                              courseName={course.name}
                              archived={course.archived}
                              archiveCourse={this.archiveCourse}
                              unarchiveCourse={this.unarchiveCourse}
                              isProtected={course.isProtected}
                           />
                        </div>
                        <div className='col-xs-12 col-md-12 col-lg-9'>
                           <DeleteCourse
                              courseName={course.name}
                              deleteCourse={this.deleteCourse}
                              isDeletingCourse={isDeletingCourse}
                              isDemo={course.demo}
                              isProtected={course.isProtected}
                           />
                        </div>
                     </>
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default CourseEditor;
