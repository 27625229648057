import * as _ from 'lodash';

import {
   ActivityCollapsedRubricEntry,
   ActivityContent,
   ActivityContentType,
   ActivityItem,
   ActivityLogicAction,
   ActivityLogicActionType,
   ActivityMode,
   ActivityPrompt,
   ActivityQuestion,
   ActivityResponse,
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGropWithItems,
   ActivityRubricItemGroup,
   AudioContent,
   AudioRecordingPrompt,
   AudioRecordingResponse,
   DiscussionBoardPrompt,
   DiscussionBoardResponse,
   FilePrompt,
   FileResponse,
   FillBlanksPrompt,
   FillBlanksResponse,
   GoToNextQuestionAction,
   GoToQuestionAction,
   GroupingPrompt,
   GroupingResponse,
   ImageLabelingPrompt,
   ImageLabelingResponse,
   LessonContent,
   MarkTokensPrompt,
   MarkTokensResponse,
   MultipleChoicePrompt,
   MultipleChoiceResponse,
   OpenTokVideoContent,
   OrderingPrompt,
   OrderingResponse,
   PromptType,
   SetVariableAction,
   SpokenResponsePrompt,
   SpokenResponseResponse,
   TextContent,
   TextPrompt,
   TextResponse,
   UploadedVideoContent,
   VideoContent,
   VideoLibraryContent,
   VideoRecordingPrompt,
   VideoRecordingResponse,
   VideoSource,
   VocabSetItem,
   YouTubeVideoContent,
} from '@models/Activity';
import { Maybe } from '@models/Core';

const autogradablePrompts = [
   PromptType.fillBlanksPrompt,
   PromptType.groupingPrompt,
   PromptType.imageLabelingPrompt,
   PromptType.markTokensPrompt,
   PromptType.multipleChoicePrompt,
   PromptType.orderingPrompt,
   PromptType.spokenResponsePrompt,
];

type ActivityResponseModes = ActivityMode.complete | ActivityMode.grade | ActivityMode.preview;

const getItemType = (
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   itemId: number,
): PromptType | ActivityContentType => {
   const item = _.flatten(questions.map((i) => i.items)).find((i) => i.id === itemId);
   if (item) {
      return item.itemType;
   }
   throw Error(`Item with id ${itemId} not found`);
};

export const isContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is ActivityContent<M> =>
   item !== undefined &&
   Object.values(ActivityContentType).includes(item.itemType as ActivityContentType);

export const isTextContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is TextContent<M> => item?.itemType === ActivityContentType.textContent;

export const isAudioContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is AudioContent<M> => item?.itemType === ActivityContentType.audioContent;

export const isVideoContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is VideoContent<M> => item?.itemType === ActivityContentType.videoContent;

export const isUploadedVideoContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is UploadedVideoContent<M> => isVideoContent(item) && item.source === VideoSource.upload;

export const isYouTubeVideoContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is YouTubeVideoContent<M> => isVideoContent(item) && item.source === VideoSource.youTube;

export const isOpenTokVideoContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is OpenTokVideoContent<M> => isVideoContent(item) && item.source === VideoSource.openTok;

export const isVideoLibraryContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is VideoLibraryContent<M> => isVideoContent(item) && item.source === VideoSource.library;

export const isLessonContent = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is LessonContent<M> =>
   item !== undefined && item.itemType === ActivityContentType.lessonContent;

export const isVocabSetItem = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is VocabSetItem<M> => item?.itemType === ActivityContentType.vocabSet;

export const isPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is ActivityPrompt<M> =>
   item !== undefined && Object.values(PromptType).includes(item.itemType as PromptType);

export const isAutogradable = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode> | ActivityPrompt<ActivityMode>,
): boolean => isPrompt<M>(item) && autogradablePrompts.includes(item.itemType);

export const isAudioRecordingPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is AudioRecordingPrompt<M> => item?.itemType === PromptType.audioRecordingPrompt;

export const isDiscussionBoardPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is DiscussionBoardPrompt<M> => item?.itemType === PromptType.discussionBoardPrompt;

export const isFilePrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is FilePrompt<M> => item?.itemType === PromptType.filePrompt;

export const isFillBlanksPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is FillBlanksPrompt<M> => item?.itemType === PromptType.fillBlanksPrompt;

export const isGroupingPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is GroupingPrompt<M> => item?.itemType === PromptType.groupingPrompt;

export const isImageLabelingPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is ImageLabelingPrompt<M> => item?.itemType === PromptType.imageLabelingPrompt;

export const isMarkTokensPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is MarkTokensPrompt<M> => item?.itemType === PromptType.markTokensPrompt;

export const isMultipleChoicePrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is MultipleChoicePrompt<M> => item?.itemType === PromptType.multipleChoicePrompt;

export const isOrderingPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is OrderingPrompt<M> => item?.itemType === PromptType.orderingPrompt;

export const isSpokenResponsePrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is SpokenResponsePrompt<M> => item?.itemType === PromptType.spokenResponsePrompt;

export const isTextPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is TextPrompt<M> => item?.itemType === PromptType.textPrompt;

export const isVideoRecordingPrompt = <M extends ActivityMode>(
   item?: ActivityItem<ActivityMode>,
): item is VideoRecordingPrompt<M> => item?.itemType === PromptType.videoRecordingPrompt;

export const isAudioRecordingResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is AudioRecordingResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.audioRecordingPrompt;
};

export const isDiscussionBoardResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is DiscussionBoardResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.discussionBoardPrompt;
};

export const isFileResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is FileResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.filePrompt;
};

export const isFillBlanksResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is FillBlanksResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.fillBlanksPrompt;
};

export const isGroupingResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is GroupingResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.groupingPrompt;
};

export const isImageLabelingResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is ImageLabelingResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.imageLabelingPrompt;
};

export const isMarkTokensResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is MarkTokensResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.markTokensPrompt;
};

export const isMultipleChoiceResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is MultipleChoiceResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.multipleChoicePrompt;
};

export const isOrderingResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is OrderingResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.orderingPrompt;
};

export const isSpokenResponseResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is SpokenResponseResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.spokenResponsePrompt;
};

export const isTextResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is TextResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.textPrompt;
};

export const isVideoRecordingResponse = (
   itemId: number,
   questions: readonly ActivityQuestion<ActivityResponseModes>[],
   response: ActivityResponse,
): response is VideoRecordingResponse => {
   const itemType = getItemType(questions, itemId);
   return itemType === PromptType.videoRecordingPrompt;
};

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isRubricItem = (
   rubricItem: ActivityRubricItem | ActivityRubricItemGroup | number,
): rubricItem is ActivityRubricItem =>
   !isNumber(rubricItem) && rubricItem.type === ActivityRubricEntryType.item;

export const isRubricItemGroup = (
   rubricItem: Maybe<ActivityRubricItem | ActivityRubricItemGroup> | number,
): rubricItem is ActivityRubricItemGroup =>
   !isNumber(rubricItem) && rubricItem?.type === ActivityRubricEntryType.group;

export const isRubricItemGropWithItems = (
   rubricItem: Maybe<ActivityCollapsedRubricEntry>,
): rubricItem is ActivityRubricItemGropWithItems =>
   rubricItem?.type === ActivityRubricEntryType.group && rubricItem?.rubricItems !== undefined;

export const isGoToNextQuestionAction = (
   action: ActivityLogicAction,
): action is GoToNextQuestionAction =>
   action.actionType === ActivityLogicActionType.goToNextQuestion;

export const isGoToQuestionAction = (action: ActivityLogicAction): action is GoToQuestionAction =>
   action.actionType === ActivityLogicActionType.goToQuestion;

export const isSetVariableAction = (action: ActivityLogicAction): action is SetVariableAction =>
   action.actionType === ActivityLogicActionType.setVariable;

export const getPromptName = (promptType: PromptType): string => {
   const names = {
      [PromptType.textPrompt]: 'Text Prompt',
      [PromptType.multipleChoicePrompt]: 'Multiple Choice',
      [PromptType.orderingPrompt]: 'Ordering',
      [PromptType.groupingPrompt]: 'Grouping',
      [PromptType.fillBlanksPrompt]: 'Fill Blanks',
      [PromptType.markTokensPrompt]: 'Mark Tokens',
      [PromptType.imageLabelingPrompt]: 'Image Labeling',
      [PromptType.audioRecordingPrompt]: 'Audio Recording',
      [PromptType.videoRecordingPrompt]: 'Video Recording',
      [PromptType.spokenResponsePrompt]: 'Spoken Response',
      [PromptType.discussionBoardPrompt]: 'Discussion Board',
      [PromptType.filePrompt]: 'File Upload',
   };
   return names[promptType];
};
