enum Color {
   blue = 'blue',
   green = 'green',
   red = 'red',
   darkRed = 'dark-red',
   orange = 'orange',
   yellow = 'yellow',
}

export default Color;
