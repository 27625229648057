import * as React from 'react';

import { convertPrecisionToSeconds, convertToPrecision } from '@components/Video/helpers';
import classNames from 'classnames';
import { Handles, Rail, Slider, SliderItem, Ticks, Tracks } from 'react-compound-slider';

export type HideableTick = {
   seconds: number;
   hidden: boolean;
};

type Props = {
   duration: number;
   progress: number;
   ticksInSeconds: readonly HideableTick[];
   onSlideEnd(seconds: number): void;
   onSlideStart(seconds: number): void;
   onTickClick(tick: SliderItem): void;
   onUpdate(seconds: number): void;
   selectedTickId?: string;
   setSelectedTickId(id: string): void;
};

const InteractiveVideoBuilderSlider: React.FC<Props> = (props) => {
   const domain = convertToPrecision(props.duration);
   const progress = convertToPrecision(props.progress);
   const tickValues = props.ticksInSeconds
      .filter((x) => x.hidden === false)
      .map((x) => x.seconds)
      .map(convertToPrecision);
   const isHandleActive = tickValues.length !== props.ticksInSeconds.length;

   const onUpdate = (values: readonly number[]): void => {
      const seconds = convertPrecisionToSeconds(values[0]);
      props.onUpdate(seconds);
   };

   const onSlideStart = (values: readonly number[]): void => {
      const seconds = convertPrecisionToSeconds(values[0]);
      props.onSlideStart(seconds);
   };

   const onSlideEnd = (values: readonly number[]): void => {
      const seconds = convertPrecisionToSeconds(values[0]);
      props.onSlideEnd(seconds);
   };

   const onTickClick = (tick: SliderItem): void => {
      props.setSelectedTickId(tick.id);
      props.onTickClick(tick);
   };

   return (
      <Slider
         mode={1}
         step={1}
         className='slider'
         domain={[0, domain || 1]}
         values={[progress]}
         onUpdate={onUpdate}
         onSlideEnd={onSlideEnd}
         onSlideStart={onSlideStart}
      >
         <Rail>{({ getRailProps }) => <div className='rail' {...getRailProps()} />}</Rail>
         <Handles>
            {({ handles, getHandleProps }) => (
               <>
                  {handles.map((handle) => (
                     <div
                        key={handle.id}
                        role='slider'
                        aria-valuenow={handle.value}
                        className={classNames('handle', {
                           active: isHandleActive,
                        })}
                        style={{ left: `${handle.percent}%` }}
                        {...getHandleProps(handle.id)}
                     />
                  ))}
               </>
            )}
         </Handles>
         <Tracks right={false}>
            {(trackProps) => (
               <>
                  {trackProps.tracks.map((track) => (
                     <div key={track.id}>
                        <div
                           className='main-track'
                           style={{
                              width: `${track.target.percent - track.source.percent}%`,
                           }}
                           {...trackProps.getTrackProps()}
                        />
                     </div>
                  ))}
               </>
            )}
         </Tracks>
         <Ticks values={tickValues}>
            {({ ticks }) => (
               <div className='slider-ticks'>
                  {ticks.map((tick) => (
                     <div
                        key={tick.id}
                        className={classNames('item-ticks', {
                           active: tick.id === props.selectedTickId,
                        })}
                        style={{
                           left: tick.percent + '%',
                        }}
                        onClick={() => onTickClick(tick)}
                     />
                  ))}
               </div>
            )}
         </Ticks>
      </Slider>
   );
};

export default React.memo(InteractiveVideoBuilderSlider);
