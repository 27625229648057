import * as React from 'react';

import { IOnboardingProps, OnboardingTaskState } from './Models';

export const OnboardingContext = React.createContext<IOnboardingProps>({
   checklistOpen: false,
   completedOnboardingTasks: [],
   observers: [],
   demoCourseId: null,
   stepId: null,
   task: null,
   taskState: OnboardingTaskState.closed,
   walkthrough: null,
   walkthroughVisible: false,
   clearCurrentTask: () => undefined,
   nextStep: () => undefined,
   onStateUpdate: () => undefined,
   previousStep: () => undefined,
   setChecklistOpen: () => undefined,
   setStepId: () => undefined,
   setTask: () => undefined,
   setWalkthroughVisible: () => undefined,
   startWalkthrough: () => undefined,
   walkthroughIsComplete: () => false,
});

export const OnboardingConsumer = OnboardingContext.Consumer;
