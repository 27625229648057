export const CoursePermissionNameConstants = [
   'canEdit',
   'canEditSecretCode',
   'canGrade',
   'canLeaveCourse',
   'canModifyRoster',
   'canViewRoster',
   'canPreviewContent',
   'canExtendTimeForStudents',
   'canImportModulesFrom',
   'canGrantExtension',
] as const;

export type CoursePermissionName = (typeof CoursePermissionNameConstants)[number];

export type CoursePermissions = Record<CoursePermissionName, boolean>;

export const CoursePermissionNameLookup: Record<CoursePermissionName, string> = {
   canEdit: 'Can Edit',
   canEditSecretCode: 'Can Edit Secret Code',
   canGrade: 'Can Grade',
   canLeaveCourse: 'Can Leave Course',
   canModifyRoster: 'Can Modify Roster',
   canViewRoster: 'Can View Roster',
   canPreviewContent: 'Can Preview Content',
   canExtendTimeForStudents: 'Can Extend Time for Students',
   canImportModulesFrom: 'Can Import Modules From',
   canGrantExtension: 'Can Grant Extension',
};

export const CoursePermissionDescriptions: Record<CoursePermissionName, string> = {
   canEdit: 'Instructors can edit course settings and course codes.',
   canEditSecretCode: 'Instructors can edit the secret code for a module item.',
   canGrade: "Instructors can view students' answers and grade them.",
   canLeaveCourse:
      'Instructors can leave the course. This permission is distinct from the "allow students to leave course" option.',
   canModifyRoster: 'Instructors can add/remove students and assign them categories.',
   canViewRoster: 'Instructors can view the course roster.',
   canPreviewContent: 'Instructors can preview content.',
   canExtendTimeForStudents:
      'Instructors can extend the time on a timed assignment for individual students.',
   canImportModulesFrom: 'Instructors can import modules from this course into other courses.',
   canGrantExtension:
      'Instructors can grant an extension (change the end date of an assignment) for individual students.',
};
