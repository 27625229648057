import * as _ from 'lodash';
import * as React from 'react';

import useOutsideAlerter from '@hooks/use-outside-alerter';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import IMenuItem from './IMenuItem';

const isMenuItem = (item: IMenuItem | React.ReactNode): item is IMenuItem =>
   _.isPlainObject(item) && Object.prototype.hasOwnProperty.call(item, 'text');

interface DroplistProps {
   className?: string;
   children?: React.ReactNode;
   component?: React.ReactNode;
   disabled?: boolean;
   items?: readonly IMenuItem[] | readonly React.ReactNode[];
   pullRight?: boolean;
   stopPropagation?: boolean;
   closeDroplist?(): void;
   onChange?(open: boolean): void;
}

const Droplist: React.FC<DroplistProps> = ({
   children,
   className,
   component,
   disabled,
   items,
   pullRight = false,
   stopPropagation = true,
   onChange,
}) => {
   const navigate = useNavigate();

   const [isOpen, setDroplistOpen] = React.useState<boolean>(false);

   const componentRef = React.useRef<Maybe<HTMLDivElement>>(null);

   const openDroplist = (event: React.MouseEvent | React.KeyboardEvent): void => {
      handleStopPropogation(event);
      if (!isOpen) {
         setDroplistOpen(true);
         if (onChange) {
            onChange(true);
         }
      }
   };

   const closeDroplist = (): void => {
      setDroplistOpen(false);
      if (onChange) {
         onChange(false);
      }
   };

   useOutsideAlerter(closeDroplist, componentRef, isOpen);

   const handleItemClick = (event: React.MouseEvent, item: IMenuItem): void => {
      const { external, file, onClick, to } = item;
      handleStopPropogation(event);
      closeDroplist();
      if (to) {
         if (external) {
            file ? window.open(to, '_self') : window.open(to, '_blank');
         } else {
            navigate(to);
         }
      } else if (onClick) {
         onClick(event);
      }
   };

   const handleStopPropogation = (event: React.MouseEvent | React.KeyboardEvent): void => {
      if (stopPropagation) {
         event.stopPropagation();
         event.nativeEvent.stopImmediatePropagation();
      }
   };

   const handleToggleKeyDown = (event: React.KeyboardEvent): void => {
      if (event.key === 'Enter') {
         if (isOpen) {
            closeDroplist();
         } else {
            openDroplist(event);
         }
      }
   };

   const renderDroplistItems = (): readonly React.ReactNode[] => {
      if (!items) return [];
      const dropListItems: readonly (IMenuItem | React.ReactNode)[] = items;
      if (dropListItems.every(React.isValidElement)) {
         return dropListItems.map((item) => <li key={item.key}>{item}</li>);
      } else if (dropListItems.every((i) => isMenuItem(i) && i.icon)) {
         return dropListItems.map((item) => {
            if (isMenuItem(item)) {
               return (
                  <li
                     key={item.text}
                     data-test={`drop-list-item-${item.text.toLowerCase()}`}
                     onClick={(event) => handleItemClick(event, item)}
                     className='icon-droplist-item'
                  >
                     {item.icon}
                     {item.text}
                  </li>
               );
            }
         });
      } else {
         return dropListItems.map((item) => {
            if (isMenuItem(item)) {
               return (
                  <li
                     key={item.text}
                     data-test={`drop-list-item-${item.text.toLowerCase()}`}
                     onClick={(event) => handleItemClick(event, item)}
                  >
                     {item.text}
                  </li>
               );
            }
         });
      }
   };

   return (
      <div
         ref={(r) => (componentRef.current = r)}
         className={classnames('dropdown', { open: isOpen }, className)}
      >
         <div
            aria-expanded={isOpen}
            aria-haspopup
            className='dropdown-toggle'
            data-toggle='dropdown'
            onClick={isOpen ? closeDroplist : openDroplist}
            onKeyDown={handleToggleKeyDown}
            role='button'
            tabIndex={0}
         >
            {children}
         </div>
         {isOpen &&
            !disabled &&
            (items ? (
               <ul
                  className={classnames('dropdown-menu', {
                     'pull-right': pullRight,
                  })}
               >
                  {renderDroplistItems()}
               </ul>
            ) : (
               <div onClick={handleStopPropogation}>{component}</div>
            ))}
      </div>
   );
};

export default Droplist;
