import * as React from 'react';

import Appearance from '@models/Appearance';
import classnames from 'classnames';

interface HeaderProps {
   /** Appearance of the primary action and header.  */
   appearance?: Appearance;
   /** Class name for the modal header */
   className?: string;
   /** Component to render the footer of the modal */
   component?: React.ReactElement;
   /** The modal title; rendered in the header. */
   heading: string;
}

const Header: React.FC<HeaderProps> = ({ appearance, className, component, heading }) => {
   if (!component && !heading) {
      return null;
   } else if (component && heading) {
      console.warn('You can provide `component` OR `heading`, not both.');
      return null;
   } else if (component) {
      return component;
   }

   return (
      <div className={className || classnames('card-title', 'full-width', appearance)}>
         <div className='title' id='modal-heading'>
            {heading}
         </div>
      </div>
   );
};

export default Header;
