import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import IVocabTerm, { PartOfSpeech, TermGender, TermNumber } from '@models/IVocabTerm';

import ModalDialog from '@components/Core/ModalDialog';
import { Delimiter, numberOptions, partOfSpeechOptions } from './VocabBuilderUtils';
import VocabTermPreview from './VocabTermPreview';

interface ImportModalProps {
   importTerms(terms: readonly IVocabTerm<true>[]): void;
   onClose(): void;
}

const ImportModal: React.FC<ImportModalProps> = ({ importTerms, onClose }) => {
   const [customAttributeDelimiter, setCustomAttributeDelimiter] = React.useState<string>('');
   const [customTermDelimiter, setCustomTermDelimiter] = React.useState<string>('');
   const [attributeDelimiter, setAttributeDelimiter] = React.useState<Delimiter>(Delimiter.tab);
   const [termDelimiter, setTermDelimiter] = React.useState<Delimiter>(Delimiter.newline);
   const [textareaContent, setTextareaContent] = React.useState<string>('');

   const handleCustomAttributeDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomAttributeDelimiter(event.target.value);
   };

   const handleCustomTermDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomTermDelimiter(event.target.value);
   };

   const handleAttributeDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setAttributeDelimiter(event.target.value as Delimiter);
   };

   const handleTermDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermDelimiter(event.target.value as Delimiter);
   };

   const handleTextareaContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextareaContent(event.target.value);
   };

   const isValid = ({ term, definition }: IVocabTerm<true>): boolean =>
      term.trim().length > 0 && definition.trim().length > 0;

   const convertTerms = React.useCallback((): IVocabTerm<true>[] => {
      const delimiterValues: Record<string, string | RegExp> = {
         [Delimiter.newline]: /\r?\n/,
         [Delimiter.semicolon]: ';',
         [Delimiter.custom]: customTermDelimiter,
         [Delimiter.tab]: /\t+/,
         [Delimiter.comma]: ',',
      };
      const genders = ['feminine', 'masculine'];
      const terms: readonly string[] = textareaContent.trim().split(delimiterValues[termDelimiter]);
      return terms
         .map((term, i) => {
            const fields = term.split(delimiterValues[attributeDelimiter]);
            return {
               key: randomShortId(),
               index: i,
               id: null,
               errors: {},
               term: fields[0]?.trim() ?? '',
               definition: fields[1]?.trim() ?? '',
               partOfSpeech:
                  fields[2] && partOfSpeechOptions.includes(fields[2].toLowerCase().trim())
                     ? (fields[2].toLowerCase().trim() as PartOfSpeech)
                     : '',
               gender:
                  fields[3] && genders.includes(fields[3].toLowerCase().trim())
                     ? (fields[3].toLowerCase().trim() as TermGender)
                     : '',
               number:
                  fields[4] && numberOptions.includes(fields[4].toLowerCase().trim())
                     ? (fields[4].toLowerCase().trim() as TermNumber)
                     : '',
               imageFilename: '',
               notes: '',
            };
         })
         .filter(isValid)
         .map((i, j) => ({ ...i, index: j }));
   }, [customTermDelimiter, attributeDelimiter, termDelimiter, textareaContent]);

   const importTermsHandler = React.useCallback(() => {
      importTerms(convertTerms());
   }, [importTerms, convertTerms]);

   const convertedTermItems = convertTerms().map((term) => (
      <VocabTermPreview {...term} key={`imported-term-${term.key}`} />
   ));

   return (
      <ModalDialog
         width='large'
         onClose={onClose}
         bodyClassName='content-row-container'
         footerClassName='card-footer'
         header={
            <div className='card-title full-width'>
               <div className='title'>Import Your Data</div>
               <p className='gray-text'>
                  Copy and paste your data here (from Word, Excel, Quizlet, etc.)
               </p>
            </div>
         }
         actions={[
            {
               text: 'Import Terms',
               onClick: importTermsHandler,
               keyboardShortcut: 'mod+enter',
               disabled: !convertedTermItems.length,
            },
            { text: 'Cancel', onClick: onClose },
         ]}
      >
         <div className='content-row'>
            <textarea
               autoFocus
               name='textareaContent'
               className='import-data-content mousetrap'
               value={textareaContent}
               onChange={handleTextareaContentChange}
               spellCheck={false}
               placeholder={'Term 1\tDefinition 1\nTerm 2\tDefinition 2\nTerm 3\tDefinition 3'}
            />
         </div>
         <div className='content-row no-padding'>
            <div className='import-data-options'>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <div className='gray margin-bottom-s title'>Between attributes</div>
                     <div className='flex'>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='attributeDelimiter'
                              type='radio'
                              defaultValue={Delimiter.tab}
                              checked={attributeDelimiter === Delimiter.tab}
                              onChange={handleAttributeDelimiterChange}
                           />
                           <span>Tab</span>
                        </div>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='attributeDelimiter'
                              type='radio'
                              defaultValue={Delimiter.comma}
                              checked={attributeDelimiter === Delimiter.comma}
                              onChange={handleAttributeDelimiterChange}
                           />
                           <span>Comma</span>
                        </div>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='attributeDelimiter'
                              type='radio'
                              defaultValue={Delimiter.custom}
                              checked={attributeDelimiter === Delimiter.custom}
                              onChange={handleAttributeDelimiterChange}
                           />
                           <input
                              name='customAttributeDelimiter'
                              type='text'
                              placeholder='Custom'
                              value={customAttributeDelimiter}
                              onChange={handleCustomAttributeDelimiterChange}
                           />
                        </div>
                     </div>
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <div className='gray margin-bottom-s title'>Between terms</div>
                     <div className='flex'>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='termDelimiter'
                              type='radio'
                              defaultValue={Delimiter.newline}
                              checked={termDelimiter === Delimiter.newline}
                              onChange={handleTermDelimiterChange}
                           />
                           <span>New Line</span>
                        </div>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='termDelimiter'
                              type='radio'
                              defaultValue={Delimiter.semicolon}
                              checked={termDelimiter === Delimiter.semicolon}
                              onChange={handleTermDelimiterChange}
                           />
                           <span>Semicolon</span>
                        </div>
                        <div className='flex items-center margin-right-m'>
                           <input
                              name='termDelimiter'
                              type='radio'
                              defaultValue={Delimiter.custom}
                              checked={termDelimiter === Delimiter.custom}
                              onChange={handleTermDelimiterChange}
                           />
                           <input
                              name='customTermDelimiter'
                              type='text'
                              placeholder='Custom'
                              value={customTermDelimiter}
                              onChange={handleCustomTermDelimiterChange}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='content-row'>
            <div className='import-terms-preview'>
               {convertedTermItems.length > 0 ? (
                  convertedTermItems
               ) : (
                  <p className='mid-gray-text'>Nothing to preview yet</p>
               )}
            </div>
         </div>
      </ModalDialog>
   );
};

export default ImportModal;
