const elementReady = (
   selector: string,
   createdCallback: (observer: MutationObserver) => void,
): Promise<Element> =>
   new Promise((resolve) => {
      const el = document.querySelector(selector);
      if (el) {
         resolve(el);
      }
      const newObserver = new MutationObserver((mutationRecords, observer) => {
         // Query for elements matching the specified selector
         Array.from(document.querySelectorAll(selector)).forEach((element) => {
            resolve(element);
            // Once we have resolved we don't need the observer anymore.
            observer.disconnect();
         });
      });

      if (createdCallback) {
         createdCallback(newObserver);
      }

      newObserver.observe(document.documentElement, {
         childList: true,
         subtree: true,
      });
   });

export default elementReady;
