import * as React from 'react';

import { Features } from '@models/Feature';
import UserService from '@services/UserService';

interface FeatureCheckProps {
   feature: Features;
   children: React.ReactNode;
}

const FeatureCheck: React.FC<FeatureCheckProps> = (props) => {
   const [isFeatureEnabled, setIsFeatureEnabled] = React.useState<boolean>(false);

   React.useEffect(() => {
      UserService.checkFeature(props.feature).then((r) => {
         setIsFeatureEnabled(r);
      });
   }, []);

   if (isFeatureEnabled) {
      return <>{props.children}</>;
   }

   return <></>;
};

export default FeatureCheck;
