import * as React from 'react';

import Button from '@components/Common/Button';

type Props = {
   onClick(): void;
};

export const AddButton: React.FC<Props> = (props) => (
   <Button className='margin-left-auto' onClick={props.onClick} style={{ fontSize: '20px' }}>
      +
   </Button>
);

export default AddButton;
