import * as React from 'react';

import { ActivityRubric, ActivityRubricScoringType } from '@models/Activity';

import ModalDialog from '@components/Core/ModalDialog';

interface RubricSettingsModalProps {
   rubric: ActivityRubric;
   closeSettingsModal(): void;
   updateRubric(update: Partial<ActivityRubric>, callback?: () => void): void;
}

interface RubricSettingsModalState {
   ceiling: boolean;
   floor: boolean;
   scoringType: ActivityRubricScoringType;
}

const RubricSettingsModal: React.FC<RubricSettingsModalProps> = ({
   rubric,
   closeSettingsModal,
   updateRubric,
}) => {
   const [state, setState] = React.useState<RubricSettingsModalState>({
      ceiling: rubric.ceiling,
      floor: rubric.floor,
      scoringType: rubric.scoringType,
   });

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { type, checked, value, name } = event.target;
      setState((prevState) => ({
         ...prevState,
         [name]: type === 'checkbox' ? checked : value,
      }));
   };

   const saveAndClose = (): void => updateRubric(state, closeSettingsModal);

   return (
      <ModalDialog
         heading='Rubric Settings'
         bodyClassName='content-row-container'
         footerClassName='card-footer'
         onClose={closeSettingsModal}
         actions={[
            { text: 'Save', onClick: saveAndClose },
            { text: 'Cancel', onClick: closeSettingsModal },
         ]}
      >
         <div className='margin-top-s'>
            <label className='field-title'>Rubric Type</label>
            <div className='margin-top-xs'>
               <input
                  name='scoringType'
                  type='radio'
                  value={ActivityRubricScoringType.negative}
                  checked={state.scoringType === ActivityRubricScoringType.negative}
                  onChange={handleChange}
               />
               <label className='black-text'>
                  Negative Scoring (points are subtracted from {rubric.weight})
               </label>
            </div>
            <div className='margin-top-s'>
               <input
                  name='scoringType'
                  type='radio'
                  value={ActivityRubricScoringType.positive}
                  checked={state.scoringType === ActivityRubricScoringType.positive}
                  onChange={handleChange}
               />
               <label className='black-text'>Positive Scoring (points are added to 0)</label>
            </div>
         </div>
         <div className='margin-top-m'>
            <label className='field-title'>Score Bounds</label>
            <div className='margin-top-xs'>
               <input
                  type='checkbox'
                  name='ceiling'
                  checked={state.ceiling}
                  onChange={handleChange}
               />
               <label className='black-text'>Ceiling (maximum score is {rubric.weight})</label>
            </div>
            <div className='margin-top-s'>
               <input type='checkbox' name='floor' checked={state.floor} onChange={handleChange} />
               <label className='black-text'>Floor (minimum score is 0)</label>
            </div>
         </div>
      </ModalDialog>
   );
};

export default RubricSettingsModal;
