// @ts-strict-ignore
import * as React from 'react';

import { Bar, BarChart, Legend, LegendType, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import {
   StudentProficiencyBars,
   StudentProficiencySummaryBarchartData,
} from '@components/ProficiencyTracking/StudentProficiencySummary/Helper';
import CircleLegendContent from './CircleLegendContent';
import TooltipContent from './TooltipContent';

// This type is meant to be a subset of BarProps from rechart,
// but with stronger typing around what we actually use.
type UsedBarProps = {
   dataKey: keyof StudentProficiencyBars;
   fill: string;
   legendType: LegendType;
   name: string;
   stackId: 'a';
};

interface StackedBarChartProps {
   data: StudentProficiencySummaryBarchartData[];
}

const levelBarSections: UsedBarProps[] = [
   {
      dataKey: 'numberOfNoviceLowCompleted',
      fill: '#B2D7EA',
      legendType: 'circle',
      name: 'Novice Low',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfNoviceMidCompleted',
      fill: '#66B0D6',
      legendType: 'circle',
      name: 'Novice Mid',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfNoviceHighCompleted',
      fill: '#007CBB',
      legendType: 'circle',
      name: 'Novice High',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfIntermediateLowCompleted',
      fill: '#B7CBAE',
      legendType: 'circle',
      name: 'Intermediate Low',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfIntermediateMidCompleted',
      fill: '#80A471',
      legendType: 'circle',
      name: 'Intermediate Mid',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfIntermediateHighCompleted',
      fill: '#4A7D35',
      legendType: 'circle',
      name: 'Intermediate High',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfAdvancedLowCompleted',
      fill: '#FFE8C5',
      legendType: 'circle',
      name: 'Advanced Low',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfAdvancedMidCompleted',
      fill: '#FFD38B',
      legendType: 'circle',
      name: 'Advanced Mid',
      stackId: 'a',
   },
   {
      dataKey: 'numberOfAdvancedHighCompleted',
      fill: '#FFB53E',
      legendType: 'circle',
      name: 'Advanced High',
      stackId: 'a',
   },
];

const StackedBarChart: React.FC<StackedBarChartProps> = (props) => {
   const renderBars = (): React.ReactNode[] =>
      levelBarSections.map((x) => <Bar key={x.name} {...x} />);

   return (
      <ResponsiveContainer width='100%' height='100%'>
         <BarChart className='student-proficiency-summary-stacked-bar-chart' data={props.data}>
            <XAxis
               label='Weeks'
               height={60}
               // In order to hide the label on the ticks only we are using a key that does not exist.
               dataKey={'hidelabel'}
               tickLine
            />
            <Tooltip content={TooltipContent} />
            <Legend
               content={CircleLegendContent}
               layout='vertical'
               verticalAlign='middle'
               align='right'
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-ignore - This height is set on the css property height which can take a string.
               height={'100%'}
            />
            {renderBars()}
            <Bar
               dataKey={'availableStatementsNotCompleted'}
               fill={'white'}
               legendType={'circle'}
               name={'Incomplete'}
               stackId={'a'}
            />
         </BarChart>
      </ResponsiveContainer>
   );
};

export default StackedBarChart;
