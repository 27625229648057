// @ts-strict-ignore
import * as _ from 'lodash';

import { randomShortId } from '@helpers/RandomStringUtils';
import Language from '@models/Language';

import {
   IAudioChoice,
   IBaseVocabSessionCard,
   ITextChoice,
   IVocabSessionCard,
   IVocabSessionTerm,
   PromptMedium,
   ResponseMedium,
   VocabSessionCardType,
   VocabSessionType,
} from './Models';
import { SPEED_CARD_DURATION } from './VocabSession';

export interface ICardFactoryParams {
   audioEnabled: boolean;
   imageResponsePossible: boolean;
   language: Language;
   sessionType: VocabSessionType;
   termHasImage: boolean;
   typingEnabled: boolean;
}

const cardFactory = (
   baseCard: IBaseVocabSessionCard,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   const card = {
      scheduled: true,
      ...baseCard,
   };

   if (params.sessionType === VocabSessionType.speedReview) {
      return makeNativeToTargetMultipleChoice(card, terms, params);
   }

   switch (card.level) {
      case 0:
         // Target to native multiple choice
         return makeLevel0(card, terms, params);
      case 1:
         // Native to target multiple choice
         return makeLevel1(card, terms, params);
      case 2:
         // Native to target audio multiple choice
         return makeLevel2(card, terms, params);
      case 3:
         // Target audio to native multiple choice
         return makeLevel3(card, terms, params);
      case 4:
         // Native to target typing
         return makeLevel4(card, terms, params);
   }

   console.warn('Unable to create card for:', card);
   return undefined;
};

const makeTargetToNativeMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: true,
      promptWith: PromptMedium.text,
      respondInTarget: false,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms);
   return card;
};

const makeTargetToNativeImageMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: true,
      promptWith: PromptMedium.text,
      respondInTarget: false,
      respondWith: ResponseMedium.image,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms, 3);
   return card;
};

const makeNativeToTargetMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.text,
      respondInTarget: true,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms);
   return card;
};

const makeNativeToTargetAudioMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.text,
      respondInTarget: true,
      respondWith: ResponseMedium.audio,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms, 3);
   return card;
};

const makeImageToTargetAudioMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.image,
      respondInTarget: true,
      respondWith: ResponseMedium.audio,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms, 3);
   return card;
};

const makeNativeToTargetImageMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.text,
      respondInTarget: true,
      respondWith: ResponseMedium.image,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms, 3);
   return card;
};

const makeTargetAudioToNativeMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: true,
      promptWith: PromptMedium.audio,
      respondInTarget: false,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms);
   return card;
};

const makeTargetImageToNativeMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: true,
      promptWith: PromptMedium.image,
      respondInTarget: false,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms);
   return card;
};

const makeImageToTargetMultipleChoice = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.image,
      respondInTarget: true,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.multipleChoice,
   });
   generateChoices(card, terms);
   return card;
};

const makeNativeToTargetTyping = (
   card,
   _terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   Object.assign(card, {
      promptInTarget: false,
      promptWith: PromptMedium.text,
      respondInTarget: true,
      respondWith: ResponseMedium.text,
      type: VocabSessionCardType.typing,
   });
   return card;
};

const makeLevel0 = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   if (params.termHasImage && params.language === 'asl') {
      return makeTargetImageToNativeMultipleChoice(card, terms, params);
   } else {
      return makeTargetToNativeMultipleChoice(card, terms, params);
   }
};

const makeLevel1 = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   if (params.imageResponsePossible && params.language === 'asl') {
      return makeNativeToTargetImageMultipleChoice(card, terms, params);
   } else if (params.termHasImage && Math.random() > 0.4) {
      return makeImageToTargetMultipleChoice(card, terms, params);
   } else {
      return makeNativeToTargetMultipleChoice(card, terms, params);
   }
};

const makeLevel2 = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   if (params.audioEnabled) {
      if (params.termHasImage && Math.random() > 0.4) {
         return makeImageToTargetAudioMultipleChoice(card, terms, params);
      } else {
         return makeNativeToTargetAudioMultipleChoice(card, terms, params);
      }
   } else {
      // No audio fallback
      return makeLevel1(card, terms, params);
   }
};

const makeLevel3 = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   if (params.audioEnabled) {
      return makeTargetAudioToNativeMultipleChoice(card, terms, params);
   } else {
      if (params.termHasImage && params.language === 'asl') {
         return makeTargetImageToNativeMultipleChoice(card, terms, params);
      } else if (params.imageResponsePossible) {
         return makeTargetToNativeImageMultipleChoice(card, terms, params);
      } else {
         return makeTargetToNativeMultipleChoice(card, terms, params);
      }
   }
};

const makeLevel4 = (
   card,
   terms: Record<number, IVocabSessionTerm>,
   params: ICardFactoryParams,
): IVocabSessionCard => {
   if (params.typingEnabled) {
      return makeNativeToTargetTyping(card, terms, params);
   } else if (params.imageResponsePossible && params.language === 'asl') {
      return makeNativeToTargetImageMultipleChoice(card, terms, params);
   }
   console.warn('Unable to make Level4 card: ', card);
   return undefined;
};

const generateChoices = (card, terms: Record<number, IVocabSessionTerm>, n = 4): void => {
   const term = terms[card.termId];
   let samplePopulation = card.respondInTarget ? term.targetChoices : term.nativeChoices;
   if (card.respondWith === ResponseMedium.image) {
      samplePopulation = samplePopulation.filter((i) => !!terms[i].imageUrl);
   }
   const choiceIds = _.sampleSize(samplePopulation, n - 1);
   const choices = [...choiceIds, card.termId].map((i) => {
      if (card.respondWith === ResponseMedium.audio) {
         return { id: i, audio: terms[i].audio };
      } else if (card.respondWith === ResponseMedium.image) {
         return { id: i, image: terms[i].image };
      } else if (card.respondWith === ResponseMedium.text) {
         if (card.respondInTarget) {
            return { id: i, text: terms[i].term };
         } else {
            return { id: i, text: terms[i].definition };
         }
      }
      console.warn('Unable to generate choices for card: ', card);
      return undefined;
   });
   card.choices = _.shuffle(choices);
};

const getTimerDuration = (
   card: IVocabSessionCard,
   { term, definition, audio }: Partial<IVocabSessionTerm>,
   sessionType: VocabSessionType,
   language: Language,
): number => {
   let recognitionTime = 0;
   let answerTime = 0;

   if (sessionType === VocabSessionType.speedReview) {
      return SPEED_CARD_DURATION;
   }

   if (card.promptWith === PromptMedium.text) {
      recognitionTime = Math.max(6, (card.promptInTarget ? term : definition).length * 0.1);
   } else if (card.promptWith === PromptMedium.audio) {
      recognitionTime = audio.duration * 1.5;
   } else if (card.promptWith === PromptMedium.image) {
      recognitionTime = 2;
   }

   if (card.type === VocabSessionCardType.typing && card.respondInTarget) {
      answerTime = 12 + term.length * 1.5;
   } else if (card.type === VocabSessionCardType.multipleChoice) {
      if (card.respondWith === ResponseMedium.text) {
         answerTime = _.sum(
            card.choices.map((i: ITextChoice) => Math.max(i.text.length * 0.1, 1.5)),
         );
      } else if (card.respondWith === ResponseMedium.audio) {
         answerTime = _.sum(card.choices.map((i: IAudioChoice) => i.audio.duration * 2));
      } else if (card.respondWith === ResponseMedium.image) {
         answerTime = _.sum(card.choices.map(() => 2));
         if (language === 'asl') {
            answerTime *= 1.5;
         }
      }
   }

   if (language === 'ar') {
      answerTime *= 2;
   }
   return recognitionTime + answerTime;
};

const insertRandomlyBeforeEnd = (
   card: IBaseVocabSessionCard,
   cards: readonly (IBaseVocabSessionCard | IVocabSessionCard)[],
   index: number,
): readonly (IBaseVocabSessionCard | IVocabSessionCard)[] => {
   const maxIndex = cards.length - 1;
   const startIndex = Math.min(index + 1, maxIndex);
   const updatedCards = [...cards];
   updatedCards.splice(_.random(startIndex, maxIndex), 0, card);
   return updatedCards;
};

const replaceSubsequentCards = (
   termId: number,
   cards: readonly (IBaseVocabSessionCard | IVocabSessionCard)[],
   index: number,
): readonly (IBaseVocabSessionCard | IVocabSessionCard)[] =>
   [...cards].map((card, i) =>
      i > index && card.termId === termId ? { ...card, level: card.level - 1 } : card,
   );

const replaceAudioCard = (
   card: IVocabSessionCard,
   terms: Record<number, IVocabSessionTerm>,
   _params: ICardFactoryParams,
): IVocabSessionCard => {
   if (card.promptWith !== PromptMedium.audio && card.respondWith !== ResponseMedium.audio) {
      return undefined;
   }
   const newCard = { ...card };
   if (newCard.promptWith === PromptMedium.audio) {
      newCard.promptWith = PromptMedium.text;
   }
   if (newCard.respondWith === ResponseMedium.audio) {
      newCard.respondWith = ResponseMedium.text;
      if (card.type === VocabSessionCardType.multipleChoice) {
         generateChoices(newCard, terms, 3);
      }
   }
   return newCard;
};

const updateCards = (
   cards: readonly (IBaseVocabSessionCard | IVocabSessionCard)[],
   sessionType: VocabSessionType,
   resequenceCardId?: string,
): readonly (IBaseVocabSessionCard | IVocabSessionCard)[] => {
   if (resequenceCardId) {
      const cardIndex = cards.findIndex((i) => i.id === resequenceCardId);
      const { termId, level } = cards.find((i) => i.id === resequenceCardId);
      const newCard = {
         id: randomShortId(),
         termId,
         level,
         scheduled: false,
      };
      return sessionType === VocabSessionType.learn
         ? replaceSubsequentCards(termId, cards, cardIndex)
         : insertRandomlyBeforeEnd(newCard, cards, cardIndex);
   } else {
      return cards;
   }
};

export {
   cardFactory,
   getTimerDuration,
   insertRandomlyBeforeEnd,
   replaceAudioCard,
   replaceSubsequentCards,
   updateCards,
};
