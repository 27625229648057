import { Maybe } from '@models/Core';
import tinymce from 'tinymce';

tinymce.PluginManager.add('fiximages', (editor) => {
   editor.on('init', () => {
      const isEditable = (node: Maybe<Node>) => editor.dom.isEditable(node);
      const isInEditableContext = (el: Element) => isEditable(el.parentElement);

      const isImage = (node: Element) =>
         (node.nodeName === 'IMG' ||
            (node.nodeName === 'FIGURE' && /image/i.test(node.className))) &&
         isInEditableContext(node);

      const isBanner = (node: Element) => isImage(node) && node.classList.contains('banner');

      // New predicate function
      const newIsImage = (elem: Element) =>
         isImage(elem) && !elem.parentElement?.className.includes('example-image');

      editor.ui.registry.addContextToolbar('imageselection', {
         predicate: (i) => newIsImage(i) && !isBanner(i),
         items: 'alignleft aligncenter alignright',
         position: 'node',
      });
   });

   editor.on('keydown', (e) => {
      // Allow delete or backspace key
      if (e.key === 'Backspace' || e.key === 'Enter') {
         return;
      }

      const selection = editor.selection.getNode();
      if (!selection) {
         return;
      }
      const parentDiv = editor.dom.getParent(selection, 'div.example-image');

      if (parentDiv) {
         e.preventDefault();
      }
   });

   editor.on('NodeChange', () => {
      const divsWithExampleImageClass = editor.dom.select('div.example-image');
      divsWithExampleImageClass.forEach((div) => {
         div.childNodes.forEach((child) => {
            if (
               child.nodeType === Node.TEXT_NODE ||
               (child.nodeName !== 'IMG' && child.nodeType === Node.ELEMENT_NODE)
            ) {
               div.removeChild(child);
            }
         });
      });
   });

   editor.ui.registry.addMenuItem('insert-image', {
      icon: 'image',
      text: 'Image',
      onAction(api) {
         if (api.isEnabled()) {
            editor.execCommand('mceImage');
         }
      },
   });

   return {
      getMetadata: () => ({
         name: 'Fix Images',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
