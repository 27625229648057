import * as _ from 'lodash';
import * as React from 'react';

import IconImageClose from '@icons/general/icon-image-close.svg';
import IconRowMover from '@icons/general/icon-row-mover.svg';
import { Maybe } from '@models/Core';
import IVocabTerm from '@models/IVocabTerm';
import Language, { LanguageLookup } from '@models/Language';
import TranslationService from '@services/TranslationService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import AccentTextbox from '@components/AccentTextbox';
import Modal from '@components/Core/ModalDialog';
import GifRecorder from './GifRecorder';
import { numberOptions, partOfSpeechOptions } from './VocabBuilderUtils';
import VocabTermActions from './VocabTermActions';

interface VocabTermEditProps {
   genderOptions: readonly string[];
   index: number;
   isDragDisabled?: boolean;
   isSelected: boolean;
   label: number;
   language: Maybe<Language>;
   term: IVocabTerm<true>;
   termsSelected: boolean;
   onChange(update: Partial<IVocabTerm<true>>): void;
   removeTerm(): void;
   toggleSelect(): void;
}

const VocabTermEdit: React.FC<VocabTermEditProps> = ({
   genderOptions,
   index,
   isDragDisabled,
   isSelected,
   label,
   language,
   term: {
      definition,
      errors = {},
      gender,
      partOfSpeech,
      number: termNumber,
      term,
      imageUrl,
      key,
      notes,
   },
   termsSelected,
   onChange,
   removeTerm,
   toggleSelect,
}) => {
   const [showGifModal, setShowGifModal] = React.useState<boolean>(false);
   const [imagesPopupOpen, setImagesPopupOpen] = React.useState<boolean>(false);
   const [notesPopupOpen, setNotesPopupOpen] = React.useState<boolean>(false);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const {
         target: { name, value },
      } = event;
      onChange({ [name]: value });
   };

   const translate = (): void => {
      if (!term.length && !definition.length) {
         return;
      }
      const text = term.length ? term : definition;
      const source = term.length ? language : 'en';
      const target = term.length ? 'en' : language;

      if (source && target) {
         TranslationService.translate(text, source, target).then((translation) => {
            onChange({ [term.length ? 'definition' : 'term']: translation });
         });
      }
   };

   const renderOptions = (options: readonly string[]): readonly React.ReactNode[] => [
      <option value='' key='none'>
         Select
      </option>,
      ...options.map((o) => (
         <option key={o} value={o}>
            {_.startCase(o)}
         </option>
      )),
   ];

   const removeImage = (): void => {
      onChange({
         imageUrl: undefined,
         imageFilename: null,
         file: null,
      });
   };

   const setImageByFile = (file: File): void => {
      const src = window.URL.createObjectURL(file);
      onChange({
         imageUrl: src,
         imageFilename: null,
         file,
      });
   };

   const setImageByUrl = (updatedImageUrl: string): void => {
      onChange({
         imageUrl: updatedImageUrl,
         imageFilename: null,
         file: null,
      });
   };

   const setNotes = (updatedNotes: string): void => {
      onChange({ notes: updatedNotes });
   };

   const handleGifClick = (): void => {
      if (!imageUrl) {
         setShowGifModal(true);
      }
   };

   const closeGifModal = (): void => {
      setShowGifModal(false);
   };

   const showGenderSelector =
      !!language && ['ar', 'fr', 'de', 'he', 'it', 'pt', 'ru', 'es'].includes(language);

   return (
      <>
         <Draggable
            isDragDisabled={isDragDisabled || imagesPopupOpen || notesPopupOpen}
            draggableId={key}
            index={index}
         >
            {(draggableProvided) => (
               <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
               >
                  <div className='vocab-set-row row' data-test={`vocab-set-row-${index}`}>
                     <div
                        className={classnames('row-mover', {
                           selected: termsSelected,
                        })}
                     >
                        <IconRowMover />
                     </div>
                     <div className='vocab-set-checkbox'>
                        <input type='checkbox' checked={isSelected} onChange={toggleSelect} />
                     </div>
                     <div className='vocab-set-row-number-wrap'>
                        <div className='vocab-set-row-number'>{label}</div>
                     </div>
                     <div className='vocab-set-row-content'>
                        <div className='col-xs-12'>
                           <div className='row bottom-xs'>
                              <div
                                 className={classnames(
                                    'col-xs-12',
                                    `col-md-${
                                       showGenderSelector ? '3' : language === 'asl' ? '2' : '4'
                                    }`,
                                 )}
                              >
                                 {language && (
                                    <label className='field-title'>
                                       {LanguageLookup[language]}
                                    </label>
                                 )}
                                 {language === 'asl' ? (
                                    <div className='term-image-wrap'>
                                       {imageUrl && (
                                          <div className='term-image-close' onClick={removeImage}>
                                             <IconImageClose />
                                          </div>
                                       )}
                                       <div className='term-image' onClick={handleGifClick}>
                                          {imageUrl && <img src={imageUrl} />}
                                       </div>
                                    </div>
                                 ) : (
                                    <Tippy content={errors.term} disabled={!errors.term}>
                                       <div>
                                          <AccentTextbox
                                             type='text'
                                             name='term'
                                             className={classnames({
                                                error: !!errors.term,
                                             })}
                                             placeholder='Enter Term'
                                             value={term}
                                             language={language}
                                             onChange={handleChange}
                                          />
                                       </div>
                                    </Tippy>
                                 )}
                              </div>
                              <div className={`col-xs-12 col-md-${showGenderSelector ? '3' : '4'}`}>
                                 <label className='field-title'>Definition</label>
                                 <Tippy content={errors.definition} disabled={!errors.definition}>
                                    <div>
                                       <input
                                          type='text'
                                          name='definition'
                                          className={classnames({
                                             error: !!errors.definition,
                                          })}
                                          placeholder='Enter Definition'
                                          value={definition}
                                          onChange={handleChange}
                                       />
                                    </div>
                                 </Tippy>
                              </div>
                              {language !== 'asl' && (
                                 <>
                                    <div className='col-xs-12 col-md-2'>
                                       <label className='field-title'>Part of Speech</label>
                                       <select
                                          name='partOfSpeech'
                                          className={classnames({
                                             error: !!errors.partOfSpeech,
                                          })}
                                          value={partOfSpeech}
                                          onChange={handleChange}
                                       >
                                          {renderOptions(partOfSpeechOptions)}
                                       </select>
                                    </div>
                                    {showGenderSelector && (
                                       <div className='col-xs-12 col-md-2'>
                                          <label className='field-title'>Gender</label>
                                          <select
                                             name='gender'
                                             className={classnames({
                                                error: !!errors.gender,
                                             })}
                                             value={gender}
                                             onChange={handleChange}
                                          >
                                             {renderOptions(genderOptions)}
                                          </select>
                                       </div>
                                    )}
                                    <div className='col-xs-12 col-md-2'>
                                       <label className='field-title'>Number</label>
                                       <select
                                          name='number'
                                          className={classnames({
                                             error: !!errors.number,
                                          })}
                                          value={termNumber}
                                          onChange={handleChange}
                                       >
                                          {renderOptions(numberOptions)}
                                       </select>
                                    </div>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                     <VocabTermActions
                        imagesPopupOpen={imagesPopupOpen}
                        imageUrl={imageUrl}
                        language={language}
                        notes={notes}
                        notesPopupOpen={notesPopupOpen}
                        removeImage={removeImage}
                        removeTerm={removeTerm}
                        setImageByFile={setImageByFile}
                        setImageByUrl={setImageByUrl}
                        setImagesPopupOpen={setImagesPopupOpen}
                        setNotes={setNotes}
                        setNotesPopupOpen={setNotesPopupOpen}
                        translate={translate}
                     />
                  </div>
               </div>
            )}
         </Draggable>
         {showGifModal && (
            <Modal heading='Add Image' onClose={closeGifModal}>
               <GifRecorder setImage={setImageByFile} closeModal={closeGifModal} />
            </Modal>
         )}
      </>
   );
};

export default React.memo(VocabTermEdit);
