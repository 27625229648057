import * as _ from 'lodash';
import * as React from 'react';

import ScrollOnDrag from '@helpers/ScrollOnDrag';
import useWindowSize from '@hooks/use-window-size';
import {
   ActivityCompleterMode,
   ImageLabelingHotspot,
   ImageLabelingResponse,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface ImageLabelingPropsProps extends CommonPromptProps {
   fileUrl: string | null;
   hotspots: readonly ImageLabelingHotspot<ActivityCompleterMode>[];
   wordBank: readonly string[];
   response: ImageLabelingResponse;
   showMissed: boolean;
   setResponse(response: ImageLabelingResponse, callback?: () => void): void;
}

const ImageLabelingProps: React.FC<ImageLabelingPropsProps> = ({
   fileUrl,
   hotspots,
   isClosed,
   response,
   showMissed,
   wordBank,
   saveResponse,
   setResponse,
}) => {
   const el = React.useRef<HTMLDivElement | null>(null);
   const scrollManagerRef = React.useRef<Maybe<ScrollOnDrag>>(null);
   const [windowWidth, windowHeight] = useWindowSize();
   const [filteredWordBank, setFilteredWordBank] = React.useState<readonly string[]>([]);
   const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

   React.useEffect(() => {
      scrollManagerRef.current = new ScrollOnDrag();
      return () => {
         scrollManagerRef.current?.resetDragging();
      };
   }, []);

   React.useEffect(() => {
      let updatedWordBank = [...wordBank];
      Object.values(response)
         .filter((i) => !!i)
         .forEach((i) => {
            const index = updatedWordBank.findIndex((j) => j === i.response);
            if (index !== -1) {
               updatedWordBank.splice(index, 1);
            }
         });
      updatedWordBank = _.orderBy(updatedWordBank, [(i) => i.toLowerCase()]);
      setFilteredWordBank(updatedWordBank);
   }, [wordBank, response]);

   React.useEffect(() => {
      if (imageLoaded && el.current) {
         scrollManagerRef.current?.findScrollableParent(el.current);
      }
   }, [windowWidth, windowHeight, imageLoaded]);

   const handleImageLoad = (): void => setImageLoaded(true);

   const handleDragStart = (
      hotspotId: number | null,
      event: React.DragEvent<HTMLSpanElement>,
   ): void => {
      scrollManagerRef.current?.handleDragStart(event);
      const node = event.target as HTMLElement;
      const textContent = node.textContent;
      if (textContent) {
         event.dataTransfer.setData('text', textContent);
      }
      if (hotspotId) {
         event.dataTransfer.setData('dragged-from', hotspotId.toString());
      }
   };

   const handleDragOver = (event: React.DragEvent<HTMLSpanElement>): void => {
      event.preventDefault();
   };

   const handleDrop = (hotspotId: number | null, event: React.DragEvent<HTMLSpanElement>): void => {
      event.preventDefault();
      const draggedFrom = Number(event.dataTransfer.getData('dragged-from'));
      const updatedResponse = { ...response };
      if (draggedFrom) {
         updatedResponse[draggedFrom].response = '';
         updatedResponse[draggedFrom].modified = true;
      }
      if (hotspotId) {
         const text = event.dataTransfer.getData('text');
         updatedResponse[hotspotId].response = text;
         updatedResponse[hotspotId].modified = true;
      }
      setResponse(updatedResponse, saveResponse);
      scrollManagerRef.current?.handleDragEnd();
   };

   const renderHotspot = (
      hotspot: ImageLabelingHotspot<ActivityCompleterMode>,
   ): React.ReactNode => {
      const responseEntry = response[hotspot.id as number];
      const responseStr = responseEntry.response;

      const unmodified = responseEntry.modified === false;
      const correct = unmodified && responseEntry.correct === true;
      const incorrect = unmodified && responseEntry.correct === false;

      let element = (
         <span
            className={classnames('draggable', { disabled: isClosed })}
            draggable={!isClosed}
            onDragStart={(e) => handleDragStart(Number(hotspot.id), e)}
         >
            {responseStr}
         </span>
      );
      if (showMissed && incorrect) {
         element = <Tippy content={`Correct Answer: ${hotspot.content}`}>{element}</Tippy>;
      }
      return (
         <div
            className={classnames('droppable', { correct, incorrect })}
            onDrop={(e) => handleDrop(Number(hotspot.id), e)}
            onDragOver={handleDragOver}
            style={{ top: `${hotspot.y}%`, left: `${hotspot.x}%` }}
            key={hotspot.id}
         >
            {element}
         </div>
      );
   };

   if (!fileUrl) {
      return null;
   }

   return (
      <div className='image-labeling-form' ref={el}>
         <div className='word-bank' onDrop={(e) => handleDrop(null, e)} onDragOver={handleDragOver}>
            {filteredWordBank.map((i) => (
               <span
                  className={classnames('draggable', {
                     disabled: isClosed,
                  })}
                  key={i}
                  draggable={!isClosed}
                  onDragStart={(e) => handleDragStart(null, e)}
               >
                  {i}
               </span>
            ))}
         </div>
         <div className='drag-parent'>
            <img src={fileUrl} onLoad={handleImageLoad} />
            {hotspots.map(renderHotspot)}
         </div>
      </div>
   );
};

export default ImageLabelingProps;
