import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconTick from '@icons/general/icon-tick.svg';
import { ActivityCompleterMode, OrderingOption, OrderingResponse } from '@models/Activity';
import classnames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface OrderingProps extends CommonPromptProps {
   options: readonly OrderingOption<ActivityCompleterMode>[];
   response: OrderingResponse;
   setResponse(response: OrderingResponse, callback?: () => void): void;
}

const OrderingPrompt: React.FC<OrderingProps> = ({
   isClosed,
   options,
   response,
   setResponse,
   saveResponse,
}) => {
   const handleDragEnd = (result: DropResult): void => {
      if (result.destination) {
         const updatedResponse = [...response];
         const [removed] = updatedResponse.splice(result.source.index, 1);
         updatedResponse.splice(result.destination.index, 0, removed);

         setResponse(
            updatedResponse.map((entry, i) =>
               response.findIndex((j) => entry.optionId === j.optionId) === i
                  ? entry
                  : { ...entry, modified: true },
            ),
            saveResponse,
         );
      }
   };

   const optionItems = response.map((entry, i) => {
      const option = options.find(({ id }) => id === entry.optionId);

      const unmodified = entry.modified === false;
      const correct = unmodified && entry.correct === true;
      const incorrect = unmodified && entry.correct === false;

      if (!option) {
         return;
      }

      return (
         <Draggable
            key={entry.optionId}
            draggableId={entry.optionId.toString()}
            index={i}
            isDragDisabled={isClosed}
         >
            {(draggableProvided) => (
               <div
                  className={classnames('dnd-item', { correct, incorrect })}
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
               >
                  <div className='dnd-item-inner'>
                     {(correct || incorrect) && (
                        <div className='dnd-item-icon'>
                           {correct ? <IconTick /> : <IconCloseSmall />}
                        </div>
                     )}
                     <div className='dnd-item-text'>
                        {ensureElement(parseHtml(option.data) as React.ReactNode, 'p')}
                     </div>
                  </div>
               </div>
            )}
         </Draggable>
      );
   });

   return (
      <div className='dnd-prompt-drag-context'>
         <DragDropContext onDragEnd={handleDragEnd}>
            <div className='dnd-prompt-container'>
               <div className='dnd-column-container'>
                  <Droppable droppableId='ordering-prompt'>
                     {(provided) => (
                        <div className='dnd-column-items-wrapper' {...provided.droppableProps}>
                           <div className='dnd-column-items' ref={provided.innerRef}>
                              {optionItems}
                              {provided.placeholder}
                           </div>
                        </div>
                     )}
                  </Droppable>
               </div>
            </div>
         </DragDropContext>
      </div>
   );
};

export default OrderingPrompt;
