import * as React from 'react';

import classnames from 'classnames';
import moment from 'moment';

import Color from '../../../types/Color';

interface NotificationProps {
   color?: Color;
   date: Date;
   icon: React.ReactNode;
   text: string;
   unread?: boolean;
   onClick?(event: React.MouseEvent<HTMLDivElement>): void;
}

const Notification: React.FC<NotificationProps> = ({
   color = Color.blue,
   icon,
   text,
   date,
   unread,
   onClick,
}) => (
   <div className='notification' onClick={onClick}>
      <div className={classnames('nav-notification-event', color)}>{icon}</div>
      <div className='content-title'>
         <p>{text}</p>
         <label>{moment(date).fromNow()}</label>
      </div>
      {unread && <div className='unread-dot' />}
   </div>
);

export default Notification;
