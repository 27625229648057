import * as React from 'react';

import Avatar from '@components/Core/Avatar';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import moment from 'moment';

const renderTooltipCell = (body: Maybe<string>, tooltip: Maybe<string>): React.ReactNode => {
   if (!body && tooltip) {
      return <></>;
   }
   return (
      <Tippy content={tooltip}>
         <span>{body}</span>
      </Tippy>
   );
};

const renderDateCell = (date: Date | null): React.ReactNode => {
   if (!date) {
      return null;
   }
   return renderTooltipCell(moment(date).fromNow(), moment(date).format('lll'));
};

const renderAvatarCell = (
   firstName: string,
   lastName: string,
   userId: number,
   profileImageUrl?: string,
   props?: string[],
): React.ReactNode => (
   <div className='flex-align-center'>
      <Avatar
         firstName={firstName}
         lastName={lastName}
         hashValue={userId}
         src={profileImageUrl}
         size='large'
      />
      {`${firstName} ${lastName}`}
      {props?.length ? ' (' + props.join(' ') + ')' : ''}
   </div>
);

export { renderDateCell, renderTooltipCell, renderAvatarCell };
