import * as React from 'react';

import ExamAdminsPanel from '@components/AdminNationalExams/ExamAdminsPanel';
import ExamCoursesPanel from '@components/AdminNationalExams/ExamCoursesPanel';
import ExamInformationPanel from '@components/AdminNationalExams/ExamInformationPanel';
import ExamProgressPanel from '@components/AdminNationalExams/ExamProgressPanel';
import ExamReportPanel from '@components/AdminNationalExams/ExamReportPanel';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { LandingPageContent } from '@models/LandingPageContent';
import { FullNationalExam } from '@models/NationalExam';
import NationalExamAdminService, {
   NationalExamCourseSummary,
} from '@services/NationalExamAdminService';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import BasicUserProfile from '@models/BasicUserProfile';

type Params = {
   examIdOrNew: string;
};

const NationalExamSummaryDetails: React.FC = () => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const disableUpdates = !userProfile?.isAdmin;

   const params = useParams<Params>();
   const parsedExamIdOrNew = parseInt(params.examIdOrNew || '');
   const examId = isNaN(parsedExamIdOrNew) ? undefined : parsedExamIdOrNew;

   const [exam, setExam] = React.useState<FullNationalExam>();
   const [landingPageContent, setLandingPageContent] = React.useState<LandingPageContent>();
   const [examCourses, setExamCourses] = React.useState<NationalExamCourseSummary[]>([]);
   const [examAdmins, setExamAdmins] = React.useState<BasicUserProfile[]>([]);

   const loadExam = async (id: number | string): Promise<void> => {
      const data = await NationalExamAdminService.getExam(id);
      setExam(data.exam);
      setLandingPageContent(data.landingPageContent);
      setExamCourses(data.examCourses);
      setExamAdmins(data.examAdmins);
   };

   React.useEffect(() => {
      if (examId) loadExam(examId);
   }, []);

   if (examId && !exam) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>
            {exam ? `${exam.nameAbbr.toUpperCase()} ${exam.examYear}` : 'Create Exam'}
         </DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row center-xs'>
               <ExamInformationPanel
                  disableUpdates={disableUpdates}
                  exam={exam}
                  setExam={setExam}
                  landingPageContent={landingPageContent}
                  setLandingPageContent={setLandingPageContent}
               />
               {exam && (
                  <>
                     <ExamCoursesPanel
                        disableUpdates={disableUpdates}
                        examId={exam.id}
                        examCourses={examCourses}
                        setExamCourses={setExamCourses}
                     />
                     <ExamAdminsPanel
                        disableUpdates={disableUpdates}
                        examId={exam.id}
                        examAdmins={examAdmins}
                        setExamAdmins={setExamAdmins}
                     />
                     <ExamProgressPanel
                        examId={exam.id}
                        hasCachedResults={exam.shouldCacheResults}
                     />
                     <ExamReportPanel examId={exam.id} hasCachedResults={exam.shouldCacheResults} />
                  </>
               )}
            </div>
         </div>
      </>
   );
};

export default NationalExamSummaryDetails;
