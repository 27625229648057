import { Maybe } from '@models/Core';
import tinymce from 'tinymce';

import { Callout } from '@components/Core/Editor/types/Callout';

tinymce.PluginManager.add('callouts', (editor) => {
   const registerOption = editor.options.register;

   registerOption('callout_list', {
      processor: 'object[]',
      default: [],
   });

   const calloutList: readonly Callout[] = editor.options.get('callout_list');

   const getCallout = (element?: Element): Maybe<Element> => {
      if (!element) {
         element = editor.selection.getNode();
      }
      return element.closest('.callout');
   };

   const setStyle = (callout: Callout) => {
      const element = getCallout();
      if (!element) {
         return;
      }
      const header = element.querySelector('.callout-header');
      if (header && callout.header) {
         header.innerHTML = callout.header;
      }
      const classesToRemove = calloutList.map((i) => i.class).filter((i) => i !== callout.class);
      classesToRemove.forEach((i) => element.classList.remove(i));
      element.classList.add(callout.class);
   };

   calloutList.forEach((callout) => {
      if (callout.class.includes(' ')) {
         throw new Error(`Callout class cannot contain spaces: ${callout.class}`);
      }

      editor.ui.registry.addToggleMenuItem(`callout-style-${callout.class}`, {
         text: callout.name,
         icon: callout.icon,
         onAction: () => {
            setStyle(callout);
         },
         onSetup: (api) => {
            const element = getCallout();
            api.setActive(element ? element.classList.contains(callout.class) : false);
         },
      });
   });

   editor.ui.registry.addNestedMenuItem('callout-style', {
      text: 'Style',
      icon: 'color-picker',
      getSubmenuItems: () => calloutList.map((i) => `callout-style-${i.class}`).join(' '),
   });

   const deleteCallout = () => {
      const element = getCallout();
      if (element) {
         const nextChild = element.nextSibling;
         element.remove();
         if (nextChild) {
            editor.selection.setCursorLocation(nextChild, 0);
            editor.focus();
         }
         if (editor.getContent().trim() === '') {
            editor.setContent('<p>&nbsp;</p>');
         }
      }
   };

   editor.ui.registry.addMenuItem('delete-callout-component', {
      text: 'Delete Component',
      icon: 'remove',
      onAction: () => deleteCallout(),
   });

   editor.ui.registry.addContextMenu('callouts', {
      update: (element) => {
         const withinCallout = !!getCallout(element);
         const withinExample = !!element.closest('.example-container');
         const withinImage = element.tagName === 'IMG';
         if (!withinCallout || withinExample || withinImage) {
            return '';
         }
         return 'callout-style delete-callout-component';
      },
   });

   return {
      getMetadata: () => ({
         name: 'Callouts',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
