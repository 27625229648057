import * as React from 'react';

import { ProficiencyReportSkillData } from '@models/Proficiency';

import Link from '@components/Common/Link';
import ProficiencyReportProgressBar from './ProficiencyReportProgressBar';

interface ProficiencyReportTileProps {
   'data-test': string;
   className?: string;
   link?: string;
   row: ProficiencyReportSkillData;
   title: string;
}

const ProficiencyReportTile: React.FC<ProficiencyReportTileProps> = ({
   row,
   title,
   className,
   link,
   ...rest
}) => (
   <div
      className={`proficiency-report-tile padding-s margin-s col-xs ${className || ''}`}
      key={`report-tile-${title}`}
      data-test={`overview-${title}`}
   >
      <Link to={link} data-test={rest['data-test']}>
         <div className='no-margin-bottom title large'>{title}</div>
      </Link>

      {/* Chart Container */}
      <div className='margin-bottom-m'>
         <ProficiencyReportProgressBar skillData={row} size='md' />
      </div>

      {/* Count Container */}
      <div className='space-between margin-top-xs'>
         <div className='flex'>
            <div className='flex flex-col report-count padding-right-s'>
               <span className='report-count-number primary'>{row.completedAverage}</span>
               <span className='report-count-title'>Achieved</span>
            </div>
            <div className='flex flex-col report-count padding-right-s'>
               <span className='report-count-number warning'>{row.presentedCount}</span>
               <span className='report-count-title'>Presented</span>
            </div>
         </div>
         <div className='flex flex-col report-count'>
            <span className='report-count-number text-right'>{row.totalCount}</span>
            <span className='report-count-title program-total'>Program Total</span>
         </div>
      </div>
   </div>
);

export default ProficiencyReportTile;
