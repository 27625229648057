import * as React from 'react';

import Button from '@components/Common/Button';
import IconVideoControlForward from '@icons/general/video-control-forward.svg';
import IconVideoControlPlay from '@icons/general/video-control-play-rounded.svg';
import IconVideoControlRepeat from '@icons/general/video-control-repeat.svg';
import IconVideoControlRewind from '@icons/general/video-control-rewind.svg';
import IconVideoControlPause from '@icons/nova-line/36-Videos/video-control-pause.svg';
import IconVideoControlSubtitles from '@icons/videos/subtitle-icon.svg';
import classNames from 'classnames';

import SpeedOptions from './SpeedOptions';
import SubtitlePopup, { VideoSubtitleProps } from './SubtitlePopup';
import VolumeSlider from './VolumeSlider';

type OverlayProps = {
   isPlaying: boolean;
   nativeLanguageSubtitleProps?: VideoSubtitleProps;
   shouldLoop: boolean;
   speed: number;
   targetLanguageSubtitleProps?: VideoSubtitleProps;
   volume: number;
   forwardVideo(event: React.MouseEvent): void;
   handleSpeedChange(newSpeed: number): void;
   handleVolumeChange(event: React.ChangeEvent<HTMLInputElement>): void;
   pauseVideo(): void;
   playVideo(): void;
   rewindVideo(event: React.MouseEvent): void;
   toggleShouldLoop(): void;
};

const Overlay: React.FC<OverlayProps> = ({
   isPlaying,
   nativeLanguageSubtitleProps,
   shouldLoop,
   speed,
   targetLanguageSubtitleProps,
   volume,
   forwardVideo,
   handleSpeedChange,
   handleVolumeChange,
   rewindVideo,
   toggleShouldLoop,
}) => {
   const [showSubtitlePopup, setShowSubtitlePopup] = React.useState<boolean>(false);
   const disableSubtitles = !targetLanguageSubtitleProps && !nativeLanguageSubtitleProps;

   const handleToggleShouldLoop = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      toggleShouldLoop();
      event.currentTarget.blur();
   };

   const handleToggleShowSubtitlePopup = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      setShowSubtitlePopup(!showSubtitlePopup);
   };

   const renderSpeed = React.useMemo(
      (): JSX.Element => <SpeedOptions handleSpeedChange={handleSpeedChange} speed={speed} />,
      [speed, handleSpeedChange],
   );

   const renderVolume = React.useMemo(
      (): JSX.Element => <VolumeSlider handleVolumeChange={handleVolumeChange} volume={volume} />,
      [volume, handleVolumeChange],
   );

   const handleClickRewind = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      rewindVideo(event);
      event.currentTarget.blur();
   };

   const handleClickForward = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      forwardVideo(event);
      event.currentTarget.blur();
   };

   return (
      <div className='video-overlay'>
         <div className='video-control-row-1'>
            <div className='video-control-box'>
               <Button
                  className='video-toggle'
                  onClick={handleClickRewind}
                  aria-label='Back 10 Seconds'
                  tooltip='Back 10 Seconds'
                  icon={<IconVideoControlRewind preserveAspectRatio='xMidYMid meet' />}
               />
            </div>
            <div className='video-control-box'>
               <Button
                  className='video-toggle'
                  aria-label={isPlaying ? 'Pause' : 'Play'}
                  icon={
                     isPlaying ? (
                        <IconVideoControlPause preserveAspectRatio='xMidYMid meet' aria-hidden />
                     ) : (
                        <IconVideoControlPlay preserveAspectRatio='xMidYMid meet' aria-hidden />
                     )
                  }
               />
            </div>
            <div className='video-control-box'>
               <Button
                  className='video-toggle'
                  onClick={handleClickForward}
                  aria-label='Forward 10 Seconds'
                  tooltip='Forward 10 Seconds'
                  icon={<IconVideoControlForward preserveAspectRatio='xMidYMid meet' aria-hidden />}
               />
            </div>
         </div>
         <div className='video-control-row-2'>
            <div>
               <Button
                  tooltip='Loop'
                  ariaLabel='Toggle video loop'
                  icon={<IconVideoControlRepeat preserveAspectRatio='xMidYMid meet' aria-hidden />}
                  color={shouldLoop ? 'blue' : 'white'}
                  onClick={handleToggleShouldLoop}
               />
            </div>
            {renderSpeed}
            {renderVolume}
            <div>
               {!disableSubtitles && (
                  <Button
                     ariaLabel='Toggle subtitle options'
                     tooltip='Subtitles'
                     className={classNames({ disabled: disableSubtitles })}
                     icon={
                        <IconVideoControlSubtitles
                           preserveAspectRatio='xMidYMid meet'
                           aria-hidden
                        />
                     }
                     color={showSubtitlePopup ? 'blue' : 'white'}
                     onClick={handleToggleShowSubtitlePopup}
                  />
               )}
               {showSubtitlePopup && (
                  <SubtitlePopup
                     targetLanguageSubtitleProps={targetLanguageSubtitleProps}
                     nativeLanguageSubtitleProps={nativeLanguageSubtitleProps}
                     isOpen={showSubtitlePopup}
                     setIsOpen={setShowSubtitlePopup}
                  />
               )}
            </div>
         </div>
      </div>
   );
};

export default Overlay;
