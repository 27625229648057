export enum SubmissionEventLevel {
   alert = 'alert',
   warning = 'warning',
}

export enum SubmissionEventDescription {
   AssignmentEndedWhileActive = 'Assignment ended while active',
   LeftAssignment = 'Navigated away from assignment',
   LeftLingco = 'Focus moved away from Lingco',
   NavigatedToAssignment = 'Navigated to assignment',
   PossibleTranslatedPage = 'Page was possibly translated',
   ReturnedToLingco = 'Focus returned to Lingco',
   TranslatedPage = 'Page was translated',
}

export default interface ISubmissionEvent {
   id: number;
   createdOn: Date;
   description: SubmissionEventDescription;
   level?: SubmissionEventLevel;
   imageFilename?: string;
   imageUrl?: string;
}
