import * as React from 'react';

import { faker } from '@faker-js/faker';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import VocabSessionsCompletedChart from './VocabSessionsCompletedChart';

export interface VocabStat {
   day: Date;
   learn: number;
   review: number;
   speedReview: number;
}

interface DailyUserVocabStat {
   enrollmentId: number;
   firstName: string;
   lastName: string;
   learn?: number;
   review?: number;
   speedReview?: number;
   total?: number;
}

interface IVocabStatsState {
   data: readonly VocabStat[];
   dayStats: {
      day: Date;
      data: readonly DailyUserVocabStat[];
   } | null;
   isFetching: boolean;
   courseId: number | null;
   courseName: string;
}

const VocabStats: React.FC = () => {
   const {
      routes: {
         courses: { dashboard, vocabStats },
         enrollments,
      },
   } = Constants;

   const { courseId } = useParams<{ courseId: string }>();

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<IVocabStatsState>({
      data: [],
      isFetching: false,
      courseId: null,
      courseName: '',
      dayStats: null,
   });

   React.useEffect(() => {
      if (courseId !== null) {
         fetchData();
      }
   }, [courseId]);

   const fetchData = (): void => {
      setState((prevState) => ({ ...prevState, isFetching: true }));
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      HttpService.getWithAuthToken<{ stats: readonly VocabStat[]; courseName: string }>(
         `/api/courses/${courseId}/vocab_stats?time_zone=${encodeURIComponent(timeZone)}`,
      ).then((response) => {
         const { stats: data, courseName } = response.data;
         setState((prevState) => ({
            ...prevState,
            courseName,
            data,
            isFetching: false,
         }));
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: courseId ? dashboard.replace(':courseId', courseId) : undefined,
                  text: courseName,
                  contextInfo: { courseId },
               },
               {
                  link: courseId ? vocabStats.replace(':courseId', courseId) : undefined,
                  text: 'Vocab Stats',
               },
            ],
            next: null,
            prev: null,
         });
      });
   };

   const handleBarClick = ({ day }: VocabStat, _index: number): void => {
      const isoDay = day.toISOString().substr(0, 10);
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const url = `/api/courses/${courseId}/vocab_stats/${isoDay}?time_zone=${encodeURIComponent(
         timeZone,
      )}`;
      HttpService.getWithAuthToken<{ stats: readonly DailyUserVocabStat[] }>(url).then(
         async (response) => {
            const { stats } = response.data;
            const patchedStats = (await UserService.checkFeature('fake_names'))
               ? stats.map((i) => ({
                    ...i,
                    firstName: faker.person.firstName(),
                    lastName: faker.person.lastName(),
                 }))
               : stats;
            setState((prevState) => ({
               ...prevState,
               dayStats: {
                  day,
                  data: patchedStats
                     .map(({ firstName, lastName, ...rest }) => ({
                        learn: 0,
                        review: 0,
                        speedReview: 0,
                        firstName,
                        lastName,
                        ...rest,
                     }))
                     .map((i) => ({
                        ...i,
                        total: i.learn + i.review + i.speedReview,
                     })),
               },
            }));
         },
      );
   };

   const renderNameCell = (cell: DailyUserVocabStat): React.ReactNode => {
      const url = enrollments.byId
         .replace(':enrollmentId', cell.enrollmentId.toString())
         .concat('?ref=vocab_stats');
      return (
         <Link to={url}>
            {cell.firstName} {cell.lastName}
         </Link>
      );
   };

   const dayStatsColumns: readonly Column<DailyUserVocabStat>[] = [
      { id: 'name', header: 'Name', cell: renderNameCell, canSort: true, sortValue: 'lastName' },
      {
         id: 'learn',
         header: 'Learn',
         cell: (i) => (i.learn ? i.learn : '-'),
         canSort: true,
      },
      {
         id: 'review',
         header: 'Review',
         cell: (i) => (i.review ? i.review : '-'),
         canSort: true,
      },
      {
         id: 'speedReview',
         header: 'Speed Review',
         cell: (i) => (i.speedReview ? i.speedReview : '-'),
         canSort: true,
      },
      { id: 'total', header: 'Total', cell: (i) => i.total, canSort: true },
   ];

   if (state.isFetching) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>
            {state.isFetching ? 'Loading Course...' : `Vocab Stats - ${state.courseName}`}
         </DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='card no-padding'>
               <div className='card-title'>
                  <div className='title'>Completed Vocab Sessions</div>
               </div>
               <div className='vocab-stats-wrapper'>
                  <VocabSessionsCompletedChart data={state.data} onBarClick={handleBarClick} />
               </div>
            </div>
         </div>
         {state.dayStats && (
            <ModalDialog
               bodyClassName='daily-stats'
               heading={`Daily Stats: ${moment(state.dayStats.day).format('dddd, MMMM Do YYYY')}`}
               onClose={() => setState((prevState) => ({ ...prevState, dayStats: null }))}
            >
               <Table<DailyUserVocabStat>
                  className='fixed_headers'
                  columns={dayStatsColumns}
                  defaultSortBy={[{ id: 'total', desc: true }]}
                  rowKey='enrollmentId'
                  rows={state.dayStats.data}
               />
            </ModalDialog>
         )}
      </>
   );
};

export default VocabStats;
