import { Maybe } from '@models/Core';
import Emitter from '@utilities/Emitter';
import { Subscription } from 'rxjs';

enum EventType {
   BrowserFocusChanged = 'BrowserFocusChanged',
}

const emitter = new Emitter();

const notifySubscribers = (msg: string): void => {
   emitter.emit(EventType.BrowserFocusChanged, msg);
};

const monitorVisibilityEvents = (): void => {
   let hidden: Maybe<keyof Document>;
   let visibilityChange: Maybe<string>;
   if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
   } else if (typeof window.document['msHidden'] !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
   } else if (typeof document['webkitHidden'] !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
   }

   // TODO: Add something here to tell the difference between a browser loosing focus and and a user switching tabs
   const handleVisibilityChange = (): void => {
      if (hidden) {
         if (document[hidden]) {
            // notifySubscribers('Hidden');
         } else {
            // notifySubscribers('Shown');
         }
      }
   };

   if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      console.warn('Page Visibility API is not supported by this browser');
   } else {
      document.addEventListener(
         visibilityChange as keyof DocumentEventMap,
         handleVisibilityChange,
         false,
      );
   }
};

const monitorFocusEvents = (): void => {
   window.addEventListener('blur', () => notifySubscribers('Blurred'), false);
   window.addEventListener('focus', () => notifySubscribers('Focused'), false);
};

const initialize = (): void => {
   monitorVisibilityEvents();
   monitorFocusEvents();
};

const subscribeToFocusChanges = (handler: (value: string) => void): Subscription =>
   emitter.listen(EventType.BrowserFocusChanged, handler);

initialize();

export const BrowserActivity = {
   subscribeToFocusChanges,
};
