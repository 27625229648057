import * as React from 'react';

import IconArrowNext from '@icons/general/icon-arrow-next.svg';

import { IVocabSessionContext, VocabSessionContext } from './VocabSession';

interface NextButtonProps {
   show: boolean;
   cardHeight: number;
}

const NextButton: React.FC<NextButtonProps> = ({ cardHeight, show }) => {
   const { emitter } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const handleClick = (): void => {
      emitter.emit('NEXT_BUTTON_CLICK');
   };

   if (show) {
      return (
         <div className='button-next-wrapper' style={{ maxHeight: cardHeight }}>
            <div className='button-next' onClick={handleClick}>
               <IconArrowNext />
            </div>
         </div>
      );
   }
   return <></>;
};

export default React.memo(NextButton);
