import tinymce from 'tinymce';

tinymce.PluginManager.add('customicons', (editor) => {
   const CONTENT_VIEW_LIST_SVG = `
   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
       height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <g id="Frame_-_24px">
      <rect y="0.003" style="fill:none;" width="24" height="24"/>
   </g>
   <g id="Line_Icons">
      <g>
         <g>
            <path d="M20,8h-9C9.896,8,9,7.103,9,6V4c0-1.103,0.896-2,2-2h9c1.104,0,2,0.897,2,2v2
               C22,7.103,21.104,8,20,8z M11,4v2h8.997L20,4H11z"/>
         </g>
         <g>
            <path d="M20,15h-9c-1.104,0-2-0.897-2-2v-2c0-1.103,0.896-2,2-2h9c1.104,0,2,0.897,2,2v2
               C22,14.103,21.104,15,20,15z M11,11v2h8.997L20,11H11z"/>
         </g>
         <g>
            <path d="M20,22h-9c-1.104,0-2-0.897-2-2v-2c0-1.103,0.896-2,2-2h9c1.104,0,2,0.897,2,2v2
               C22,21.103,21.104,22,20,22z M11,18v2h8.997L20,18H11z"/>
         </g>
         <g>
            <path d="M6,8H4C2.896,8,2,7.103,2,6V4c0-1.103,0.896-2,2-2h2c1.104,0,2,0.897,2,2v2C8,7.103,7.104,8,6,8z
                M4,4v2h1.997L6,4H4z"/>
         </g>
         <g>
            <path d="M6,15H4c-1.104,0-2-0.897-2-2v-2c0-1.103,0.896-2,2-2h2c1.104,0,2,0.897,2,2v2
               C8,14.103,7.104,15,6,15z M4,11v2h1.997L6,11H4z"/>
         </g>
         <g>
            <path d="M6,22H4c-1.104,0-2-0.897-2-2v-2c0-1.103,0.896-2,2-2h2c1.104,0,2,0.897,2,2v2
               C8,21.103,7.104,22,6,22z M4,18v2h1.997L6,18H4z"/>
         </g>
      </g>
   </g>
   </svg>
   `;
   const PAPERCLIP_LINE_SVG = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
         height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <g>
               <path d="M11,23c-2.897,0-5-2.313-5-5.5V9c0-3.991,2.794-7,6.5-7S19,5.009,19,9v7h-2V9
                  c0-2.897-1.893-5-4.5-5S8,6.103,8,9v8.5c0,1.743,0.928,3.5,3,3.5s3-1.757,3-3.5V10c0-0.602-0.146-2-1.5-2S11,9.398,11,10v7H9v-7
                  c0-2.393,1.407-4,3.5-4S16,7.607,16,10v7.5C16,20.687,13.897,23,11,23z"/>
            </g>
         </g>
      </g>
      </svg>
   `;
   const MICROPHONE_LINE_ICON_SVG = `
   <svg width="24" height="24" viewBox="0 0 96.000000 96.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" stroke="none">
         <path d="M409 833 c-55 -34 -64 -65 -64 -214 0 -133 1 -136 28 -172 55 -73 159 -73 214 0 27 36 28 39 28 172 0 150 -10 182 -65 215 -36 20 -107 20 -141 -1z m123 -60 c16 -14 18 -33 18 -149 0 -124 -1 -135 -22 -154 -28 -26 -68 -26 -96 0 -20 19 -22 30 -22 152 0 108 3 135 17 150 20 23 80 24 105 1z"/>
         <path d="M252 508 c-26 -26 -3 -110 46 -161 29 -31 81 -60 125 -71 24 -6 27 -11 27 -51 l0 -44 -62 -3 c-60 -3 -63 -4 -63 -28 l0 -25 155 0 155 0 0 25 c0 24 -3 25 -62 28 l-63 3 0 44 c0 40 3 45 28 51 75 18 139 71 166 136 20 47 20 82 1 98 -19 16 -45 -3 -45 -32 0 -34 -39 -95 -73 -117 -106 -65 -263 -11 -283 96 -10 57 -28 75 -52 51z"/>
      </g>
   </svg>
   `;

   const SPEAKER_VOLUME_HIGH_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <g id="Frame_-_24px">
      <rect style="fill:none;" width="24" height="24"/>
   </g>
   <g id="Line_Icons">
      <g>
         <path d="M11.764,3.152c-0.739-0.306-1.615-0.131-2.178,0.434L6.479,8H4c-1.103,0-2,0.898-2,2v4    c0,1.103,0.897,2,2,2h2.479l2.995,4.28C9.89,20.776,10.433,21,11,21c0.263,0,0.521-0.051,0.764-0.151    C12.515,20.54,13,19.813,13,19V5C13,4.187,12.515,3.462,11.764,3.152z M7.521,14H4v-4h3.521L11,5.027l0.001,13.948L7.521,14z"/>
         <path d="M15,4v2c2.757,0,5,2.243,5,5s-2.243,5-5,5v2c3.86,0,7-3.14,7-7C22,7.141,18.86,4,15,4z"/>
         <path d="M18,11c0-1.654-1.346-3-3-3v2c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1v2    C16.654,14,18,12.654,18,11z"/>
      </g>
   </g>
   </svg>
   `;

   const FLASH_LINE_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="-0.001" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <g>
               <path d="M11,22c-0.111,0-0.223-0.018-0.331-0.057C10.268,21.802,10,21.424,10,21v-7H5     c-0.384,0-0.735-0.221-0.901-0.567s-0.12-0.756,0.121-1.058l8-10c0.265-0.331,0.711-0.459,1.112-0.318     C13.732,2.197,14,2.575,14,3v7h5c0.384,0,0.735,0.221,0.901,0.567s0.12,0.757-0.121,1.058l-8,10C11.587,21.866,11.298,22,11,22z      M7.081,12H11c0.552,0,1,0.448,1,1v5.15L16.919,12H13c-0.552,0-1-0.448-1-1V5.85L7.081,12z"/>
            </g>
         </g>
      </g>
   </svg>
   `;

   const INTERFACE_ALERT_TRIANGLE_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <g>
               <path d="M21,22H3c-0.347,0-0.668-0.18-0.851-0.474c-0.183-0.296-0.198-0.663-0.044-0.974l9-18     c0.339-0.677,1.45-0.677,1.789,0l9,18c0.154,0.311,0.139,0.677-0.044,0.974C21.668,21.821,21.347,22,21,22z M4.618,20h14.764     L12,5.237L4.618,20z"/>
            </g>
            <g>
               <rect x="11" y="10" width="2" height="6"/>
            </g>
            <g>
               <circle cx="12" cy="18" r="1.25"/>
            </g>
         </g>
      </g>
   </svg>
   `;

   const INTERFACE_INFORMATION_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <g>
               <path d="M12,22C6.486,22,2,17.515,2,12C2,6.487,6.486,2,12,2s10,4.486,10,10C22,17.515,17.514,22,12,22z      M12,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,4,12,4z"/>
            </g>
            <path d="M13,15v-4c0-0.552-0.447-1-1-1h-2v2h1v3H9v2h6v-2H13z"/>
            <g>
               <circle cx="12" cy="8" r="1.25"/>
            </g>
         </g>
      </g>
   </svg>
   `;

   const LIGHTBULB_4_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect x="0.001" y="0.002" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <polygon points="9,23 11,23 11,24 13,24 13,23 15,23 15,21 9,21   "/>
            <path d="M12,5c-3.859,0-7,3.14-7,7c0,2.714,1.581,5.17,4,6.319V19c0,0.552,0.447,1,1,1h4    c0.553,0,1-0.448,1-1v-0.681c2.419-1.148,4-3.604,4-6.319C19,8.14,15.859,5,12,5z M13.666,16.709    C13.268,16.85,13,17.228,13,17.651V18h-2v-0.349c0-0.424-0.268-0.801-0.666-0.942C8.34,16.003,7,14.11,7,12c0-2.757,2.243-5,5-5    s5,2.243,5,5C17,14.11,15.66,16.003,13.666,16.709z"/>
            <rect x="11" width="2" height="4"/>
            <rect y="11" width="4" height="2"/>
            <rect x="20" y="11" width="4" height="2"/>
            
               <rect x="18.5" y="2.379" transform="matrix(0.7072 0.707 -0.707 0.7072 8.8905 -12.4687)" width="1.999" height="4.243"/>
            
               <rect x="2.379" y="3.5" transform="matrix(0.7071 0.7071 -0.7071 0.7071 4.5 -1.864)" width="4.243" height="1.999"/>
         </g>
      </g>
   </svg>
   `;

   const DELETE_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <path d="M19.077,4.929C17.188,3.04,14.676,2,12.005,2C9.334,2,6.823,3.04,4.934,4.929   c-1.889,1.889-2.93,4.4-2.93,7.07c0,2.671,1.041,5.183,2.93,7.073C6.823,20.96,9.334,22,12.005,22s5.182-1.04,7.071-2.927   C22.975,15.172,22.975,8.828,19.077,4.929z M18.319,16.902L13.419,12L18.32,7.1C20.553,9.974,20.553,14.026,18.319,16.902z    M16.907,5.686l-4.902,4.901L7.104,5.686C8.501,4.597,10.206,4,12.005,4C13.805,4,15.509,4.597,16.907,5.686z M4.004,12   c0-1.799,0.597-3.504,1.686-4.9L10.591,12L5.69,16.902C4.601,15.504,4.004,13.8,4.004,12z M7.104,18.315l4.9-4.901l4.9,4.901   C15.508,19.404,13.804,20,12.005,20S8.501,19.404,7.104,18.315z"/>
      </g>
   </svg>
   `;

   const LINK_ICON_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <g>
               <path d="M3.514,16.227l1.414-1.414l-0.707-0.707c-0.39-0.39-0.39-1.024,0-1.415l8.486-8.485     c0.188-0.189,0.439-0.293,0.707-0.293s0.518,0.104,0.708,0.293l0.707,0.707L16.242,3.5l-0.706-0.707     c-1.134-1.134-3.111-1.134-4.243,0l-8.485,8.484c-1.17,1.17-1.17,3.073,0,4.243L3.514,16.227z"/>
               <path d="M21.192,8.451l-0.708-0.707L19.07,9.158l0.708,0.707c0.189,0.189,0.292,0.439,0.292,0.707     c0,0.266-0.104,0.517-0.292,0.706l-8.485,8.486c-0.378,0.377-1.037,0.377-1.415,0l-0.707-0.707L7.757,20.47l0.707,0.707     c0.567,0.567,1.32,0.879,2.122,0.879c0.801,0,1.554-0.312,2.121-0.879l8.485-8.485c0.566-0.566,0.878-1.319,0.878-2.12     C22.07,9.771,21.758,9.017,21.192,8.451z"/>
            </g>
            <g>
               
                  <rect x="15.949" y="2.036" transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.9393 -9.9242)" width="2" height="10"/>
            </g>
            <g>
               
                  <rect x="6.05" y="11.934" transform="matrix(0.7072 0.707 -0.707 0.7072 14.0365 -0.0261)" width="1.999" height="10.001"/>
            </g>
         </g>
      </g>
      </svg>
   `;

   const TRANSLATION_LINE_SVG = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
         height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <path d="M23,10h-7v2h6v10H12v-6h-2v7c0,0.553,0.448,1,1,1h12c0.553,0,1-0.447,1-1V11
               C24,10.447,23.553,10,23,10z"/>
            <path d="M14.919,21.394L15.517,20h2.967l0.598,1.394l1.838-0.788l-3-7C17.762,13.238,17.4,13,17,13
               c-0.399,0-0.762,0.238-0.919,0.606l-3,7L14.919,21.394z M17.627,18h-1.253L17,16.538L17.627,18z"/>
            <polygon points="6,7.381 7,7.881 8,7.381 8,6 6,6 		"/>
            <path d="M14,0H0v14h14V0z M12,6h-2v2c0,0.379-0.214,0.726-0.553,0.894L9.236,9l2.211,1.105l-0.895,1.789
               L7,10.118l-3.553,1.776l-0.895-1.789L4.764,9L4.553,8.894C4.214,8.725,4,8.378,4,8V6H2V4h4V2h2v2h4V6z"/>
            <path d="M23,3h-3.586l1.293-1.293l-1.414-1.414L15.586,4l3.707,3.707l1.414-1.414L19.414,5H22v3h2V4
               C24,3.447,23.553,3,23,3z"/>
            <path d="M4.293,17.707L5.586,19H2v-3H0v4c0,0.553,0.448,1,1,1h4.586l-1.293,1.293l1.414,1.414L9.414,20
               l-3.707-3.707L4.293,17.707z"/>
         </g>
      </g>
      </svg>
   `;

   const HEADING_1_SVG = `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
         <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
            <g id="H1" transform="translate(0.717692, 0.500000)">
               <g id="H" fill="#413F48" fill-rule="nonzero">
                  <path id="Path" d="M-2.22044605e-16,10.215 C-2.22044605e-16,10.365 0.135,10.5 0.285,10.5 L1.68,10.5 C1.845,10.5 1.965,10.365 1.965,10.215 L1.965,6.075 L6.855,6.075 L6.855,10.215 C6.855,10.365 6.975,10.5 7.14,10.5 L8.52,10.5 C8.67,10.5 8.805,10.365 8.805,10.215 L8.805,0.285 C8.805,0.135 8.67,0 8.52,0 L7.14,0 C6.975,0 6.855,0.135 6.855,0.285 L6.855,4.26 L1.965,4.26 L1.965,0.285 C1.965,0.135 1.845,0 1.68,0 L0.285,0 C0.135,0 -2.22044605e-16,0.135 -2.22044605e-16,0.285 L-2.22044605e-16,10.215 Z"/>
               </g>
               <g id="1" fill="#413F48" fill-rule="nonzero" transform="translate(10.844888, 4.700000)">
                  <path id="Path" d="M1.14254834,6.61 C1.14254834,6.71 1.22254834,6.8 1.33254834,6.8 L2.19254834,6.8 C2.29254834,6.8 2.37254834,6.71 2.37254834,6.61 L2.37254834,0.19 C2.37254834,0.09 2.29254834,8.8817842e-16 2.19254834,8.8817842e-16 L1.49254834,8.8817842e-16 L0.05254834,1.21 C0.01254834,1.25 -0.00745166004,1.31 0.00254833996,1.39 L0.06254834,1.88 C0.07254834,1.97 0.17254834,2.03 0.26254834,2.01 L1.14254834,1.82 L1.14254834,6.61 Z"/>
               </g>
               <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 13.5 16.282 13.5"/>
               <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 15.5 13.282 15.5"/>
               <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 17.5 10.282 17.5"/>
            </g>
         </g>
      </svg>
   `;

   const HEADING_2_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
      <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
         <g id="H2" transform="translate(0.717692, 0.500000)">
            <g id="H" fill="#413F48" fill-rule="nonzero">
               <path id="Path" d="M-2.22044605e-16,10.215 C-2.22044605e-16,10.365 0.135,10.5 0.285,10.5 L1.68,10.5 C1.845,10.5 1.965,10.365 1.965,10.215 L1.965,6.075 L6.855,6.075 L6.855,10.215 C6.855,10.365 6.975,10.5 7.14,10.5 L8.52,10.5 C8.67,10.5 8.805,10.365 8.805,10.215 L8.805,0.285 C8.805,0.135 8.67,0 8.52,0 L7.14,0 C6.975,0 6.855,0.135 6.855,0.285 L6.855,4.26 L1.965,4.26 L1.965,0.285 C1.965,0.135 1.845,0 1.68,0 L0.285,0 C0.135,0 -2.22044605e-16,0.135 -2.22044605e-16,0.285 L-2.22044605e-16,10.215 Z"/>
            </g>
            <g id="2" fill="#413F48" fill-rule="nonzero" transform="translate(10.082436, 4.600000)">
               <path id="Path" d="M0,6.71 C0,6.81 0.08,6.9 0.19,6.9 L4.13,6.9 C4.23,6.9 4.32,6.81 4.32,6.71 L4.32,6.02 C4.32,5.92 4.23,5.83 4.13,5.83 L1.97,5.83 C2.5,5.16 3.26,4.22 3.69,3.61 C4.08,3.05 4.36,2.64 4.36,1.99 C4.36,0.91 3.56,0 2.13,0 C0.94,0 0.16,0.98 0.16,0.98 C0.09,1.06 0.11,1.18 0.17,1.24 L0.65,1.72 C0.73,1.8 0.84,1.8 0.92,1.72 C1.15,1.47 1.56,1.13 2.04,1.13 C2.76,1.13 3.1,1.59 3.1,2.04 C3.1,2.38 2.9,2.77 2.59,3.15 C1.92,4.04 0.66,5.68 0,6.48 L0,6.71 Z" transform="translate(0.000000, -0.000000)"/>
            </g>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 13.5 16.282 13.5"/>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 15.5 13.282 15.5"/>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 17.5 10.282 17.5"/>
         </g>
      </g>
   </svg>
   `;

   const HEADING_3_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
      <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
         <g id="H3" transform="translate(0.717692, 0.500000)">
            <g id="H" fill="#413F48" fill-rule="nonzero">
               <path id="Path" d="M-2.22044605e-16,10.215 C-2.22044605e-16,10.365 0.135,10.5 0.285,10.5 L1.68,10.5 C1.845,10.5 1.965,10.365 1.965,10.215 L1.965,6.075 L6.855,6.075 L6.855,10.215 C6.855,10.365 6.975,10.5 7.14,10.5 L8.52,10.5 C8.67,10.5 8.805,10.365 8.805,10.215 L8.805,0.285 C8.805,0.135 8.67,0 8.52,0 L7.14,0 C6.975,0 6.855,0.135 6.855,0.285 L6.855,4.26 L1.965,4.26 L1.965,0.285 C1.965,0.135 1.845,0 1.68,0 L0.285,0 C0.135,0 -2.22044605e-16,0.135 -2.22044605e-16,0.285 L-2.22044605e-16,10.215 Z"/>
            </g>
            <g id="3" fill="#413F48" fill-rule="nonzero" transform="translate(10.072125, 4.600000)">
               <path id="Path" d="M0.0403110681,6.11 C0.260311068,6.37 0.870311068,7 2.12031107,7 C3.50031107,7 4.42031107,6.18 4.42031107,5.05 C4.42031107,4.16 3.68031107,3.62 3.21031107,3.42 L3.21031107,3.39 C3.66031107,3.18 4.24031107,2.6 4.24031107,1.83 C4.24031107,0.71 3.27031107,0 2.11031107,0 C0.990311068,0 0.230311068,0.88 0.230311068,0.88 C0.160311068,0.95 0.150311068,1.07 0.230311068,1.14 L0.720311068,1.6 C0.810311068,1.68 0.920311068,1.67 0.990311068,1.59 C1.16031107,1.39 1.48031107,1.11 2.00031107,1.11 C2.51031107,1.11 2.97031107,1.48 2.97031107,2.03 C2.97031107,2.54 2.55031107,2.95 1.73031107,2.95 L1.17031107,2.95 C1.05031107,2.95 0.980311068,3.02 0.980311068,3.15 L0.980311068,3.78 C0.980311068,3.9 1.06031107,3.98 1.17031107,3.98 L1.73031107,3.98 C2.63031107,3.98 3.11031107,4.45 3.11031107,4.97 C3.11031107,5.52 2.59031107,5.91 2.05031107,5.91 C1.44031107,5.91 0.850311068,5.41 0.820311068,5.37 C0.740311068,5.29 0.650311068,5.29 0.560311068,5.37 L0.0503110681,5.86 C-0.00968893189,5.93 -0.0196889319,6.04 0.0403110681,6.11 Z"/>
            </g>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 13.5 16.282 13.5"/>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 15.5 13.282 15.5"/>
            <path id="Line" stroke="#E7E7E7" stroke-linecap="square" stroke-width=".5" d="M.282 17.5 10.282 17.5"/>
         </g>
      </g>
   </svg>
   `;

   const CUSTOM_TEXT_SVG = `
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g id="Aa.1"><g id="a" fill="#413F48" fill-rule="nonzero" transform="translate(15.620000, 9.800000)"><path id="Shape" d="M0,5.66 C0,7.36 1.1,8.4 2.7,8.4 C3.94,8.4 4.68,7.8 5.14,7.38 L5.38,7.92 C5.48,8.14 5.58,8.2 5.72,8.2 L6.44,8.2 C6.64,8.2 6.82,8.02 6.82,7.82 L6.82,3.48 C6.82,1.3 6.24,0 3.74,0 C2.46,0 1.18,0.32 1.02,0.38 C0.84,0.44 0.68,0.58 0.74,0.88 L0.92,1.92 C0.96,2.18 1.1,2.36 1.38,2.28 C1.84,2.14 2.88,1.98 3.62,1.98 C4.36,1.98 4.68,2.34 4.66,3.36 C4.66,3.36 4,3.14 3.2,3.14 C1.16,3.14 0,4.24 0,5.66 Z M2.2,5.66 C2.2,5.02 2.72,4.58 3.5,4.58 C4.08,4.58 4.62,4.82 4.62,4.82 L4.62,5.92 C4.5,6.16 3.88,6.7 3.24,6.7 C2.62,6.7 2.2,6.26 2.2,5.66 Z"/></g><g id="A" fill="#413F48" fill-rule="nonzero" transform="translate(1.699927, 3.800000)"><path id="Shape" d="M0.380073258,14.2 L2.12007326,14.2 C2.44007326,14.2 2.62007326,14 2.70007326,13.8 L3.68007326,11.64 L9.66007326,11.64 L10.6400733,13.8 C10.7800733,14.08 10.9200733,14.2 11.2200733,14.2 L12.9600733,14.2 C13.2600733,14.2 13.4200733,13.94 13.3000733,13.68 L7.14007326,0.22 C7.08007326,0.1 7.00007326,5.99749647e-25 6.80007326,5.99749647e-25 L6.60007326,5.99749647e-25 C6.42007326,5.99749647e-25 6.32007326,0.1 6.26007326,0.22 L0.0400732584,13.68 C-0.0799267416,13.94 0.0800732584,14.2 0.380073258,14.2 Z M4.66007326,9.42 L6.62007326,5.02 L6.68007326,5.02 L8.68007326,9.42 L4.66007326,9.42 Z"/></g><path id="Rectangle" d="M0 0H24V24H0z"/></g></g></svg>
   `;

   const USER_CHAT_1_SVG = `
   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
       height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <g id="Frame_-_24px">
      <rect style="fill:none;" width="24" height="24"/>
   </g>
   <g id="Line_Icons">
      <g>
         <g>
            <path d="M15.001,16c-0.152,0-0.306-0.035-0.447-0.105c-0.339-0.169-0.553-0.516-0.553-0.895v-2h-1v-2h2
               c0.552,0,1,0.448,1,1v1l2.4-1.8c0.173-0.13,0.384-0.2,0.6-0.2h3V4h-10v3h-2V4c0-1.104,0.897-2,2-2h10c1.103,0,2,0.896,2,2v7
               c0,1.103-0.897,2-2,2h-2.667l-3.733,2.8C15.424,15.932,15.213,16,15.001,16z"/>
         </g>
         <g>
            <path d="M8.001,16c-2.206,0-4-1.794-4-4c0-2.205,1.794-4,4-4s4,1.795,4,4
               C12.001,14.206,10.207,16,8.001,16z M8.001,10c-1.103,0-2,0.896-2,2c0,1.103,0.897,2,2,2s2-0.897,2-2
               C10.001,10.896,9.104,10,8.001,10z"/>
         </g>
         <g>
            <path d="M16.001,24h-16v-1c0-3.533,3.29-6,8-6s8,2.467,8,6V24z M2.16,22h11.683
               c-0.598-1.808-2.833-3-5.841-3S2.758,20.192,2.16,22z"/>
         </g>
      </g>
   </g>
   </svg>
   `;

   const GRID_LINE_SVG = `
   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
       height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <g id="Frame_-_24px">
      <rect style="fill:none;" width="24" height="24"/>
   </g>
   <g id="Line_Icons">
      <path d="M22,7V5h-3V2h-2v3h-4V2h-2v3H7V2H5v3H2v2h3v4H2v2h3v4H2v2h3v3h2v-3h4v3h2v-3h4v3h2v-3h3v-2h-3v-4h3
         v-2h-3V7H22z M7,7h4v4H7V7z M7,17v-4h4v4H7z M17,17h-4v-4h4V17z M17,11h-4V7h4V11z"/>
   </g>
   </svg>
   `;

   const EXPAND_HORIZONTAL_5_SVG = `
   <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
       height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
   <g id="Frame_-_24px">
      <rect y="0.002" style="fill:none;" width="24" height="24"/>
   </g>
   <g id="Line_Icons">
      <g>
         <polygon points="16.707,8.292 15.293,9.706 16.586,10.999 7.414,10.999 8.707,9.706 7.293,8.292 
            3.586,11.999 7.293,15.706 8.707,14.292 7.414,12.999 16.586,12.999 15.293,14.292 16.707,15.706 20.414,11.999 		"/>
         <rect y="3.999" width="2" height="16"/>
         <rect x="22" y="3.999" width="2" height="16"/>
      </g>
   </g>
   </svg>
   `;

   const WAVE_LINE_SVG = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
         height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect y="0" style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <polygon points="12.067,21.959 7.012,8.815 4.693,14.999 0,14.999 0,12.999 3.307,12.999 6.988,3.183 
               11.933,16.039 14.815,7.393 17.618,12.999 24,12.999 24,14.999 16.382,14.999 15.185,12.605 		"/>
         </g>
      </g>
      </svg>
   `;

   const CHAT_BUBBLE_SQUARE_1_LINE = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
         height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <path d="M5.998,22v-4h-2c-1.103,0-2-0.897-2-2V4c0-1.104,0.897-2,2-2h16c1.103,0,2,0.896,2,2v12
               c0,1.103-0.897,2-2,2h-8.667L5.998,22z M3.998,4v12h4v2l2.667-2h9.333V4H3.998z"/>
         </g>
      </g>
      </svg>
   `;

   const CHAT_BUBBLE_SQUARE_CLOSE_LINE = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px"
         height="24px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
      <g id="Frame_-_24px">
         <rect style="fill:none;" width="24" height="24"/>
      </g>
      <g id="Line_Icons">
         <g>
            <path d="M14.998,18H7.665l-2.667,2v-2h-3V9h9V7h-9c-1.103,0-2,0.896-2,2v9c0,1.103,0.897,2,2,2h1v4l5.333-4
               h6.667c1.103,0,2-0.897,2-2v-6h-2V18z"/>
            <polygon points="20.705,3.707 19.291,2.293 16.998,4.586 14.705,2.293 13.291,3.707 15.584,6 13.291,8.293 
               14.705,9.707 16.998,7.414 19.291,9.707 20.705,8.293 18.412,6 		"/>
         </g>
      </g>
      </svg>
   `;

   editor.ui.registry.addIcon('chat-bubble-square-1-line', CHAT_BUBBLE_SQUARE_1_LINE);
   editor.ui.registry.addIcon('chat-bubble-square-close-line', CHAT_BUBBLE_SQUARE_CLOSE_LINE);

   editor.ui.registry.addIcon('paperclip-line', PAPERCLIP_LINE_SVG);
   editor.ui.registry.addIcon('content-view-list-line', CONTENT_VIEW_LIST_SVG);
   editor.ui.registry.addIcon('delete-line', DELETE_ICON_SVG);
   editor.ui.registry.addIcon('expand-horizontal-5-line', EXPAND_HORIZONTAL_5_SVG);
   editor.ui.registry.addIcon('flash-line', FLASH_LINE_ICON_SVG);
   editor.ui.registry.addIcon('grid-line', GRID_LINE_SVG);
   editor.ui.registry.addIcon('interface-alert-triange-line', INTERFACE_ALERT_TRIANGLE_ICON_SVG);
   editor.ui.registry.addIcon('interface-information-line', INTERFACE_INFORMATION_ICON_SVG);
   editor.ui.registry.addIcon('lightbulb-4-line', LIGHTBULB_4_ICON_SVG);
   editor.ui.registry.addIcon('link-line', LINK_ICON_SVG);
   editor.ui.registry.addIcon('wave-line', WAVE_LINE_SVG);
   editor.ui.registry.addIcon('microphone-line', MICROPHONE_LINE_ICON_SVG);
   editor.ui.registry.addIcon('speaker-volume-high-line', SPEAKER_VOLUME_HIGH_SVG);
   editor.ui.registry.addIcon('translation-line', TRANSLATION_LINE_SVG);
   editor.ui.registry.addIcon('user-chat-1-line', USER_CHAT_1_SVG);

   editor.ui.registry.addIcon('heading-1', HEADING_1_SVG);
   editor.ui.registry.addIcon('heading-2', HEADING_2_SVG);
   editor.ui.registry.addIcon('heading-3', HEADING_3_SVG);
   editor.ui.registry.addIcon('custom-text', CUSTOM_TEXT_SVG);

   return {
      getMetadata: () => ({
         name: 'Custom Icons',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
