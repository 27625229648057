import * as _ from 'lodash';
import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import DateTime from '@services/DateTimeService';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import { Animal } from '@components/AnimalIcon';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import CompletedVocabSessionsChart from './CompletedVocabSessionsChart';
import DailyGoals from './DailyGoals';
import Leaderboard from './Leaderboard';
import QuestionsAnsweredHeatmap from './QuestionsAnsweredHeatmap';
import VocabSetSummaries from './VocabSetSummaries';

export interface IVocabStat {
   day: Date;
   questionsAnswered: number;
   seconds: number;
   learn: number;
   review: number;
   speedReview: number;
}

export interface IVocabSetSummary {
   endDate: Date;
   learned: number;
   readyToReview: number;
   terms: number;
   vocabSetId: number;
   vocabSetName: string;
   moduleItemId: number;
}

export interface ILeaderboardEntry {
   animal: Animal;
   color: string;
   questionsAnswered: number;
   rank: number;
   self?: boolean;
}

export interface IDailyGoal {
   action: 'learn' | 'review';
   completed: number;
   terms: number;
}

interface IStudentVocabStatsState {
   dailyGoals: readonly IDailyGoal[];
   data: readonly IVocabStat[];
   isFetching: boolean;
   leaderboard: readonly ILeaderboardEntry[];
   summary: readonly IVocabSetSummary[];
   vocabSessionDateRange: moment.DurationInputArg2;
}

const StudentVocabStats: React.FC = () => {
   const location = useLocation();
   const {
      routes: { studentVocabStats },
   } = Constants;

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<IStudentVocabStatsState>({
      data: [],
      isFetching: false,
      leaderboard: [],
      summary: [],
      vocabSessionDateRange: 'week',
      dailyGoals: [],
   });

   React.useEffect(() => {
      setState((prevState) => ({ ...prevState, isFetching: true }));
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let url = `/api/vocab_sets/vocab_stats?time_zone=${encodeURIComponent(timeZone)}`;
      const viewAs = getQueryParameterByName(location, 'view_as');
      if (viewAs !== null) {
         url = url.concat(`&view_as=${viewAs}`);
      }
      setBreadcrumbs({
         breadcrumbs: [
            {
               text: 'Vocab Stats',
               link: studentVocabStats,
            },
         ],
         prev: null,
         next: null,
      });
      HttpService.getWithAuthToken<{
         dailyGoals: readonly IDailyGoal[];
         dailyStats: readonly {
            day: Date;
            questionsAnswered: number;
            seconds: number;
            learn?: number;
            review?: number;
            speedReview?: number;
         }[];
         leaderboard: readonly ILeaderboardEntry[];
         summary: readonly IVocabSetSummary[];
      }>(url).then((response) => {
         const { dailyGoals, dailyStats, leaderboard, summary } = response.data;
         const today = DateTime.now();
         const mappedData = dailyStats
            .map((i) => ({
               learn: 0,
               review: 0,
               speedReview: 0,
               ...i,
            }))
            .filter((i) => i.day <= today);
         const sortedSummary = _.orderBy(
            summary,
            ['endDate', (i) => i.terms - i.learned, 'readyToReview'],
            ['desc', 'desc', 'desc'],
         );
         setState((prevState) => ({
            ...prevState,
            dailyGoals,
            data: mappedData,
            isFetching: false,
            leaderboard,
            summary: sortedSummary,
         }));
      });
   }, []);

   if (state.isFetching) {
      return <Loader />;
   }

   const hasLeaderboard = !!state.leaderboard.length;

   return (
      <div className='content-main margin-right-m student-vocab-stats'>
         <DocumentTitle>Vocab Stats</DocumentTitle>
         <div className='row'>
            <div
               className={classnames('col-xs-12', {
                  'col-sm-9': hasLeaderboard,
               })}
            >
               <div className='row'>
                  <div className='col-xs-12 col-sm-7 col-lg-8'>
                     <QuestionsAnsweredHeatmap data={state.data} />
                  </div>
                  <div className='col-xs-12 col-sm-5 col-lg-4'>
                     <DailyGoals dailyGoals={state.dailyGoals} />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-7 col-lg-8'>
                     <CompletedVocabSessionsChart data={state.data} />
                  </div>
                  <div className='col-xs-12 col-sm-5 col-lg-4'>
                     <VocabSetSummaries summary={state.summary} />
                  </div>
               </div>
            </div>
            {hasLeaderboard && (
               <div className='col-xs-12 col-sm-3'>
                  <Leaderboard leaderboard={state.leaderboard} />
               </div>
            )}
         </div>
      </div>
   );
};

export default StudentVocabStats;
