/* eslint-disable complexity */
import * as _ from 'lodash';
import * as React from 'react';

import { faker } from '@faker-js/faker';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { getQueryParameterByName } from '@helpers/QueryParameter';
import IconFileDownload2 from '@icons/nova-line/85-Files-Basic/file-download-2.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconUserAdd from '@icons/nova-solid/07-Users/user-add.svg';
import {
   Activity,
   ActivityMode,
   ActivityQuestion,
   ActivitySubmissionsResponse,
} from '@models/Activity';
import { ContextInfo } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import { IdName, Maybe, MessageResponse } from '@models/Core';
import { Assignment, CoursePermissions } from '@models/Course';
import { IUserExtension } from '@models/Extensions';
import ISubmissionEvent from '@models/ISubmissionEvent';
import LMSName from '@models/LMSName';
import ActivityService from '@services/ActivityService';
import HttpService from '@services/HttpService';
import { getSubmissionEvents } from '@services/StudentEventService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { isPrompt } from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { SortByEntry } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import { Tab, Tabs } from '@components/Core/Tabs';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { GradeActivityStep } from '@components/Onboarding/Walkthroughs/GradeActivity';
import ActivitySubmissionsFilters, {
   Filter,
   IActivitySubmissionsFilters,
} from './ActivitySubmissionsFilters';
import ExtensionModal from './ExtensionModal';
import OverallProgress from './OverallProgress';
import QuestionsTable from './QuestionsTable';
import SubmissionEventsModal from './SubmissionEventsModal';
import SubmissionsActions from './SubmissionsActions';
import SubmissionsTable from './SubmissionsTable';

enum GradingStatus {
   graded = 'graded',
   ungraded = 'ungraded',
}

enum CompletionStatus {
   notStarted = 'not-started',
   inProgress = 'in-progress',
   complete = 'complete',
}

export interface SubmissionRow {
   attemptsMade: number;
   excludedFromCalc: boolean;
   firstName: string;
   isLate: boolean;
   lastName: string;
   modifiedOn: Date;
   percentCompleted: number;
   percentGraded: number;
   progressScore: number;
   score: number;
   sections: readonly IdName[];
   submissionId: number;
   userId: number;
   variables: Record<number, string | number | boolean>;
}

export interface SubmissionEventSummary {
   submissionId: number;
   count: number;
   alertCount: number;
}

interface ActivitySubmissionsState {
   activity: Maybe<Activity<ActivityMode.grade>>;
   allEvents: readonly SubmissionEventSummary[];
   assignment: Maybe<Assignment>;
   columns: readonly Filter<string>[];
   courseId: Maybe<number>;
   courseName: string;
   currentEvents: readonly ISubmissionEvent[];
   eventsModalHeading: string;
   eventsModalOpen: boolean;
   extensionModalOpen: boolean;
   extensions: readonly IUserExtension[];
   filteredIds: Set<number>;
   filters: IActivitySubmissionsFilters;
   includeDemoStudents: boolean;
   isAutograding: boolean;
   isFetching: boolean;
   isGrantingExtension: boolean;
   isSyncingGrades: boolean;
   lmsName: Maybe<LMSName>;
   moduleId: Maybe<number>;
   permissions: Maybe<CoursePermissions>;
   pointsPossible: number;
   sections: readonly Filter<number>[];
   selectedIds: Set<number>;
   submissions: readonly SubmissionRow[];
   tableIsOrdered: boolean;
}

interface SubmissionsAutoGradeResponse {
   questions: readonly ActivityQuestion<ActivityMode.grade>[];
   submissions: readonly SubmissionRow[];
}

const ActivitySubmissions: React.FC = () => {
   const location = useLocation();
   const {
      routes: {
         activities: { gradeSubmissionFromModuleId, gradeResponses, viewAllSubmissions },
         courses: { dashboard, gradebook, roster, viewModule },
      },
   } = Constants;
   const NO_SECTION_ID = -1;

   const { moduleItemId } = useParams<{ moduleItemId: string }>();
   const navigate = useNavigate();

   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   const { getOnboardingClassName, stepId } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [state, setState] = React.useState<ActivitySubmissionsState>({
      activity: null,
      allEvents: [],
      assignment: null,
      columns: [],
      courseId: null,
      courseName: '',
      currentEvents: [],
      eventsModalHeading: '',
      eventsModalOpen: false,
      extensionModalOpen: false,
      extensions: [],
      filters: {
         gradingFilters: [],
         columnFilters: [],
         sectionFilters: [],
         completionFilters: [],
         nameFilter: '',
      },
      filteredIds: new Set(),
      includeDemoStudents: false,
      isAutograding: false,
      isFetching: true,
      isGrantingExtension: false,
      isSyncingGrades: false,
      lmsName: null,
      moduleId: null,
      permissions: null,
      pointsPossible: 0,
      sections: [],
      selectedIds: new Set(),
      submissions: [],
      tableIsOrdered: false,
   });

   const csvRef = React.useRef<CSVLink>(null);

   React.useEffect(() => {
      if (moduleItemId !== null) {
         fetchSubmissions();
      }
   }, [moduleItemId]);

   React.useEffect(() => {
      filterSubmissions();
   }, [state.includeDemoStudents, state.filters, state.submissions]);

   const updateFilters = (filters: IActivitySubmissionsFilters): void => {
      setState((prevState) => ({
         ...prevState,
         filters,
      }));
   };

   const filterSections = (i: SubmissionRow, sectionIds: readonly number[]): boolean => {
      const noFilter = !sectionIds.length;
      const noSection = sectionIds.includes(NO_SECTION_ID) && !i.sections.length;

      if (noFilter || noSection) {
         return true;
      }

      return (
         i.sections.filter((section) => {
            const sectionId = typeof section.id === 'number' ? section.id : parseInt(section.id);
            return sectionIds.includes(sectionId);
         }).length > 0
      );
   };

   const filterSubmissions = (): void => {
      if (state.submissions) {
         setState((prevState) => {
            const updatedFilteredIds = new Set(
               prevState.submissions
                  .filter((i) => {
                     const sectionIds = prevState.filters.sectionFilters
                        .filter((j) => j.show)
                        .map((j) => j.id);
                     const sectionFilter = filterSections(i, sectionIds);

                     const searchFilter =
                        !prevState.filters.nameFilter.length ||
                        i.firstName
                           .toLowerCase()
                           .includes(prevState.filters.nameFilter.toLowerCase()) ||
                        i.lastName
                           .toLowerCase()
                           .includes(prevState.filters.nameFilter.toLowerCase());

                     const demoFilter =
                        prevState.includeDemoStudents || i.excludedFromCalc === false;

                     const gradedFilter =
                        prevState.filters.gradingFilters.find((j) => j.id === GradingStatus.graded)
                           ?.show && i.percentGraded === 1;
                     const ungradedFilter =
                        prevState.filters.gradingFilters.find(
                           (j) => j.id === GradingStatus.ungraded,
                        )?.show && i.percentGraded < 1;

                     const notStartedFilter =
                        prevState.filters.completionFilters.find(
                           (j) => j.id === CompletionStatus.notStarted,
                        )?.show && i.percentCompleted === 0;
                     const inProgressFilter =
                        prevState.filters.completionFilters.find(
                           (j) => j.id === CompletionStatus.inProgress,
                        )?.show &&
                        i.percentCompleted > 0 &&
                        i.percentCompleted < 1;
                     const completeFilter =
                        prevState.filters.completionFilters.find(
                           (j) => j.id === CompletionStatus.complete,
                        )?.show && i.percentCompleted === 1;

                     const gradingStatusFilter =
                        prevState.filters.gradingFilters.length === 0 ||
                        gradedFilter ||
                        ungradedFilter;
                     const completionStatusFilter =
                        prevState.filters.completionFilters.length === 0 ||
                        notStartedFilter ||
                        inProgressFilter ||
                        completeFilter;

                     return (
                        sectionFilter &&
                        searchFilter &&
                        demoFilter &&
                        gradingStatusFilter &&
                        completionStatusFilter
                     );
                  })
                  .map((i) => i.submissionId),
            );
            const updatedSelectedIds = new Set(
               [...prevState.selectedIds].filter((i) => updatedFilteredIds.has(i)),
            );
            return {
               ...prevState,
               filteredIds: updatedFilteredIds,
               selectedIds: updatedSelectedIds,
            };
         });
      }
   };

   const filteredSubmissions = React.useMemo(
      () => state.submissions.filter((i) => state.filteredIds.has(i.submissionId)),
      [state.submissions, state.filteredIds],
   );

   const addRefToLink = (link: string): string => {
      let updatedLink = link;
      const ref = getQueryParameterByName(location, 'ref');
      if (ref) {
         updatedLink += link.includes('?') ? '&' : '?';
         updatedLink += `ref=${ref}`;
      }
      return updatedLink;
   };

   const getSubmissionLink = (
      submissionId: number,
      selectedSubmissionIds?: Set<number>,
   ): string => {
      if (!state.permissions?.canGrade || !moduleItemId) {
         return '';
      }

      const submissionIdsArray = getSubmissionIds(state.selectedIds, state.filteredIds);
      const hasSubmissionsSelected = selectedSubmissionIds && submissionIdsArray.length > 0;
      const firstSubmissionToNavigateTo = hasSubmissionsSelected
         ? submissionIdsArray[0]
         : submissionId;
      let link = gradeSubmissionFromModuleId
         .replace(':moduleItemId', moduleItemId.toString())
         .replace(':submissionId', firstSubmissionToNavigateTo.toString());

      if (submissionIdsArray.length > 0) {
         const selectedSubmissionIdsString = '?sid=' + submissionIdsArray.join('&sid=');
         link += selectedSubmissionIdsString;
      }

      link = addRefToLink(link);

      return link;
   };

   const getSubmissionIds = (
      selectedSubmissionIds?: Set<number>,
      filteredIds?: Set<number>,
   ): readonly number[] => {
      const selectedSubmissionIdsArray = selectedSubmissionIds ? [...selectedSubmissionIds] : [];
      const filteredSubmissionIdsArray = filteredIds ? [...filteredIds] : [];
      const submissionIdsArray =
         selectedSubmissionIdsArray.length > 0
            ? selectedSubmissionIdsArray
            : filteredSubmissionIdsArray;
      const allIdsAreSelected = submissionIdsArray.length === state.submissions.length;

      if (allIdsAreSelected) {
         return [];
      }

      return submissionIdsArray;
   };

   const getSelectedSubmissionIds = (): readonly number[] =>
      state.selectedIds ? [...state.selectedIds] : [];

   const getPromptLink = (promptId: number): string => {
      if (!state.permissions?.canGrade || !moduleItemId) {
         return '';
      }

      let link = gradeResponses
         .replace(':moduleItemId', moduleItemId.toString())
         .replace(':promptId', promptId.toString());

      const selectedSubmissionIds = getSubmissionIds(state.selectedIds, state.filteredIds);

      if (selectedSubmissionIds.length > 0) {
         const selectedSubmissionIdsString = '?sid=' + selectedSubmissionIds.join('&sid=');
         link += selectedSubmissionIdsString;
      }

      link = addRefToLink(link);

      return link;
   };

   const handleAutograde = async (): Promise<void> => {
      const { selectedIds } = state;
      setState((prevState) => ({ ...prevState, isAutograding: true }));
      const data: { submissionIds?: readonly number[] } = {};
      if (selectedIds.size && selectedIds.size !== state.submissions.length) {
         data.submissionIds = [...selectedIds];
      }
      return HttpService.postWithAuthToken<SubmissionsAutoGradeResponse>(
         `/api/activities/${moduleItemId}/submissions/autograde`,
         snakeCaseKeys(data),
      ).then((response) => {
         const { questions, submissions } = response.data;
         setState((prevState) => ({
            ...prevState,
            activity: prevState.activity
               ? {
                    ...prevState.activity,
                    questions,
                 }
               : prevState.activity,
            submissions: _.orderBy(submissions, ['lastName', 'firstName'], ['asc']),
            isAutograding: false,
         }));
      });
   };

   const handleSyncGrades = async (): Promise<void> => {
      const { courseId, moduleId, selectedIds } = state;
      setState((prevState) => ({ ...prevState, isSyncingGrades: true }));
      const data: { submissionIds?: readonly number[] } = {};
      if (selectedIds.size && selectedIds.size !== state.submissions.length) {
         data.submissionIds = [...selectedIds];
      }
      const url = `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}/lms_sync`;
      HttpService.postWithAuthToken<{ msg: string; failedUserIds: readonly number[] }>(
         url,
         snakeCaseKeys(data),
      ).then(() => {
         setState((prevState) => ({ ...prevState, isSyncingGrades: false }));
      });
   };

   const handleGradeByQuestion = (): void => {
      if (!moduleItemId || !state.activity) {
         return;
      }
      const flattenedPrompts = state.activity.questions
         .map(({ items }) => items.filter(isPrompt))
         .flat();
      const nextPrompt = flattenedPrompts.find(
         ({ percentageGraded }) => percentageGraded !== undefined && percentageGraded < 1.0,
      );
      const promptId = nextPrompt ? nextPrompt.id : flattenedPrompts[0].id;
      if (!promptId) {
         return;
      }

      const selectedSubmissionIds = getSubmissionIds(state.selectedIds, state.filteredIds);
      let link = gradeResponses
         .replace(':moduleItemId', moduleItemId.toString())
         .replace(':promptId', promptId.toString());

      if (selectedSubmissionIds.length > 0) {
         const selectedSubmissionIdsString = '?sid=' + selectedSubmissionIds.join('&sid=');
         link += selectedSubmissionIdsString;
      }

      link = addRefToLink(link);

      navigate(link);
   };

   const handleGradeBySubmission = (): void => {
      const nextSubmission = state.submissions.find(({ percentGraded }) => percentGraded < 1.0);
      const submissionId = nextSubmission
         ? nextSubmission.submissionId
         : state.submissions[0].submissionId;

      navigate(getSubmissionLink(submissionId, state.selectedIds));
   };

   const fetchSubmissions = async (): Promise<void> => {
      if (!moduleItemId) {
         return Promise.reject();
      }
      const response = await HttpService.getWithAuthToken<ActivitySubmissionsResponse>(
         `/api/activities/${moduleItemId}/submissions`,
      );
      const {
         activity,
         assignment,
         breadcrumbsInfo,
         extensions,
         lmsName,
         pointsPossible,
         sections,
         submissions,
         permissions,
         events,
      } = response.data;
      const { canEdit, courseName, courseId, moduleName, moduleId } = breadcrumbsInfo;

      const shouldUseFakeNames = await UserService.checkFeature('fake_names');
      const patchedSubmissions = shouldUseFakeNames
         ? submissions.map((i) => ({
              ...i,
              firstName: faker.name.firstName(),
              lastName: faker.name.lastName(),
           }))
         : submissions;
      const mappedSections = _.orderBy(sections, ['name']).map((i) => ({
         id: Number(i.id),
         show: i.show ?? true,
         name: i.name,
      }));

      if (mappedSections.length > 0) {
         mappedSections.unshift({
            id: NO_SECTION_ID,
            show: true,
            name: 'No Section',
         });
      }
      setState((prevState) => ({
         ...prevState,
         activity,
         allEvents: events,
         assignment,
         courseId,
         columns: _.compact([
            { id: 'name', name: 'Student Name', show: true },
            { id: 'modifiedOn', name: 'Last Submitted', show: true },
            assignment?.trackStudentEvents && {
               id: 'events',
               name: 'Tracked Events',
               show: true,
            },
            { id: 'percentCompleted', name: '% Completed', show: true },
            { id: 'percentGraded', name: '% Graded', show: true },
            { id: 'attemptsMade', name: 'Attempts', show: true },
            ...activity.variables.map((i) => ({
               id: `var-${i.id}`,
               name: i.name,
               show: true,
            })),
            { id: 'progressScore', name: 'Score', show: true },
            { id: 'score', name: 'Final Score', show: true },
            { id: 'grade', name: 'Grade', show: true },
         ]),
         courseName,
         extensions,
         isFetching: false,
         lmsName,
         moduleId,
         permissions,
         pointsPossible,
         sections: mappedSections,
         submissions: _.orderBy(patchedSubmissions, ['lastName', 'firstName'], ['asc']),
      }));
      const ref =
         getQueryParameterByName(location, 'ref') === 'gradebook'
            ? 'gradebook'
            : `modules/${moduleId}`;
      const activityContext: ContextInfo = {
         contentType: ContentType.activity,
         contentId: activity.id,
         canEditContent: canEdit,
         moduleItemId,
      };
      const courseRoute = (i: string): string => i.replace(':courseId', courseId.toString());
      const refLink =
         ref === 'gradebook'
            ? courseRoute(gradebook)
            : courseRoute(viewModule).replace(':moduleId', moduleId.toString());
      const refText = ref === 'gradebook' ? 'Gradebook' : moduleName;
      setBreadcrumbs({
         breadcrumbs: [
            {
               link: courseRoute(dashboard),
               text: courseName,
               contextInfo: { courseId },
            },
            { link: refLink, text: refText, contextInfo: { moduleId } },
            {
               link: viewAllSubmissions.replace(':moduleItemId', moduleItemId.toString()),
               text: `${activity.name} Submissions`,
               contextInfo: activityContext,
            },
         ],
         next: null,
         prev: null,
      });
   };

   const extendAssignment = (endDate: Date): void => {
      const { courseId, moduleId } = state;
      const url = `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}/extensions/bulk_create`;
      const userIds = state.submissions
         .filter((i) => state.selectedIds.has(i.submissionId))
         .map((i) => i.userId);
      setState((prevState) => ({ ...prevState, isGrantingExtension: true }));
      HttpService.postWithAuthToken<{
         extensions: readonly IUserExtension[];
         submissions: readonly SubmissionRow[];
      }>(url, snakeCaseKeys({ userIds, endDate })).then((response) => {
         const { extensions, submissions } = response.data;
         setState((prevState) => ({
            ...prevState,
            extensions,
            submissions: state.tableIsOrdered
               ? submissions
               : _.orderBy(submissions, ['lastName', 'firstName'], ['asc']),
            isGrantingExtension: false,
         }));
      });
   };

   const updateAttempts = (submissionId: number, attemptsMade: number): void => {
      setState((prevState) => ({
         ...prevState,
         submissions: prevState.submissions.map((i) =>
            i.submissionId === submissionId ? { ...i, attemptsMade } : i,
         ),
      }));
      const url = `/api/activities/${moduleItemId}/submissions/${submissionId}/attempts`;
      HttpService.putWithAuthToken<MessageResponse>(url, snakeCaseKeys({ attempts: attemptsMade }));
   };

   const toggleChecked = (submissionId: number): void => {
      const selectedIds = new Set(state.selectedIds);
      if (selectedIds.has(submissionId)) {
         selectedIds.delete(submissionId);
      } else {
         selectedIds.add(submissionId);
      }
      setState((prevState) => ({ ...prevState, selectedIds }));
   };

   const toggleIncludeDemoStudents = (): void => {
      setState((prevState) => ({
         ...prevState,
         includeDemoStudents: !prevState.includeDemoStudents,
      }));
   };

   const toggleSelectAll = (): void => {
      if (state.selectedIds.size === state.filteredIds.size) {
         setState((prevState) => ({ ...prevState, selectedIds: new Set() }));
      } else {
         setState((prevState) => ({
            ...prevState,
            selectedIds: new Set(prevState.filteredIds),
         }));
      }
   };

   const openExtensionModal = (): void => {
      setState((prevState) => ({ ...prevState, extensionModalOpen: true }));
   };

   const closeExtensionModal = (): void => {
      setState((prevState) => ({ ...prevState, extensionModalOpen: false }));
   };

   const toggleEventsModal = (): void => {
      setState((prevState) => ({
         ...prevState,
         eventsModalOpen: !prevState.eventsModalOpen,
      }));
   };

   const showEventsModal = (submissionId: number): void => {
      getSubmissionEvents(submissionId).then((events) => {
         const submission = filteredSubmissions.find((i) => i.submissionId === submissionId);
         if (!submission || !state.activity) {
            return;
         }
         const heading = `Events during "${state.activity.name}" for ${submission.firstName} ${submission.lastName}`;
         setState((prevState) => ({
            ...prevState,
            currentEvents: events,
            eventsModalHeading: heading,
         }));
      });
      toggleEventsModal();
   };

   const getExtension = (userId: number): Maybe<IUserExtension> =>
      state.extensions.find((i) => i.userId === userId);

   const handleSortChanged = (sortBy: readonly SortByEntry[]): void => {
      setState((prevState) => ({
         ...prevState,
         tableIsOrdered: !!sortBy.length,
      }));
   };

   const downloadCsv = (): void => {
      csvRef.current?.link?.click();
   };

   const downloadQuestionScoreReport = (): void => {
      if (moduleItemId) {
         ActivityService.getScoreReport(moduleItemId);
      }
   };

   const getPercentTotalCompleted = () =>
      filteredSubmissions.length
         ? _.sum(filteredSubmissions.map(({ percentCompleted }) => percentCompleted)) /
           filteredSubmissions.length
         : 0;

   const getPercentTotalGraded = () =>
      filteredSubmissions.length
         ? _.sum(filteredSubmissions.map(({ percentGraded }) => percentGraded)) /
           filteredSubmissions.length
         : 0;

   if (
      state.isFetching ||
      !state.activity ||
      !state.permissions ||
      !state.assignment ||
      !state.courseId
   ) {
      return <Loader />;
   }

   const hasDemoStudents = state.submissions.some((i) => i.excludedFromCalc);
   const noResults = !!state.submissions.length && !state.filteredIds.size;
   const someSelected = !!state.selectedIds.size;

   return (
      <>
         <DocumentTitle>
            {state.isFetching || !state.activity
               ? 'Loading Submissions...'
               : `Submissions - ${state.activity.name}`}
         </DocumentTitle>
         <div className='content-main margin-right-m' data-tour='submissions-page'>
            <div className='row'>
               <div className='col-xs-12 col-md-3'>
                  <OverallProgress
                     percentTotalCompleted={getPercentTotalCompleted()}
                     hasDemoStudents={hasDemoStudents}
                     includeDemoStudents={state.includeDemoStudents}
                     percentTotalGraded={getPercentTotalGraded()}
                     toggleIncludeDemoStudents={toggleIncludeDemoStudents}
                  />
                  {state.permissions?.canGrade && (
                     <SubmissionsActions
                        filteredIdsCount={state.filteredIds.size}
                        isAutograding={state.isAutograding}
                        isSyncingGrades={state.isSyncingGrades}
                        lmsName={state.lmsName}
                        selectedIdsCount={state.selectedIds.size}
                        submissionsCount={state.submissions.length}
                        onAutograde={handleAutograde}
                        onSyncGrades={handleSyncGrades}
                        onGradeByQuestion={handleGradeByQuestion}
                        onGradeBySubmission={handleGradeBySubmission}
                     />
                  )}
               </div>
               <div className='col-xs-12 col-md-9'>
                  {state.submissions.length ? (
                     <div className='card no-padding' data-tour='submission-table-container'>
                        <Tabs className='table-toggle'>
                           <Tab
                              id='submissions'
                              className='table-toggle-tab'
                              heading='Student Submissions'
                              disabled={stepId === GradeActivityStep.questionsSubmissionsTable}
                           >
                              <ActivitySubmissionsFilters
                                 sectionFilters={state.sections}
                                 columnFilters={state.columns}
                                 filter={updateFilters}
                              >
                                 <Button
                                    line
                                    icon={<IconFileDownload2 aria-hidden />}
                                    className='margin-left-s'
                                    onClick={downloadCsv}
                                 >
                                    Export
                                 </Button>
                                 {someSelected && state.assignment && (
                                    <Button
                                       color='purple'
                                       className='grant-extensions-btn'
                                       loading={state.isGrantingExtension}
                                       onClick={openExtensionModal}
                                       disabled={!state.permissions.canGrantExtension}
                                       tooltip={
                                          !state.permissions.canGrantExtension
                                             ? 'The ability to grant an extension for students has been disabled for this course.'
                                             : undefined
                                       }
                                    >
                                       Grant Extension
                                    </Button>
                                 )}
                              </ActivitySubmissionsFilters>
                              <SubmissionsTable
                                 columns={state.filters.columnFilters}
                                 submissions={filteredSubmissions}
                                 assignment={state.assignment}
                                 filteredIds={state.filteredIds}
                                 selectedIds={state.selectedIds}
                                 pointsPossible={state.pointsPossible}
                                 allEvents={state.allEvents}
                                 getExtension={getExtension}
                                 updateAttempts={updateAttempts}
                                 toggleChecked={toggleChecked}
                                 toggleSelectAll={toggleSelectAll}
                                 getSubmissionLink={getSubmissionLink}
                                 onSortChanged={handleSortChanged}
                                 showEvents={showEventsModal}
                                 variables={state.activity.variables}
                                 csvRef={csvRef}
                              />
                              {noResults && (
                                 <EmptyState
                                    icon={<IconBinoculars aria-hidden />}
                                    heading='No Results'
                                    description='Try changing your filters.'
                                 />
                              )}
                           </Tab>
                           <Tab
                              id='questions'
                              className='table-toggle-tab'
                              heading={
                                 <div
                                    className={classnames(
                                       getOnboardingClassName?.(
                                          GradeActivityStep.questionsSubmissionsTable,
                                       ),
                                       'title',
                                    )}
                                 >
                                    Questions
                                 </div>
                              }
                           >
                              <ActivitySubmissionsFilters
                                 sectionFilters={state.sections}
                                 columnFilters={[]}
                                 filter={updateFilters}
                              >
                                 <Button
                                    line
                                    icon={<IconFileDownload2 />}
                                    className='margin-left-s'
                                    onClick={downloadQuestionScoreReport}
                                 >
                                    Export
                                 </Button>
                              </ActivitySubmissionsFilters>
                              <QuestionsTable
                                 questions={state.activity.questions}
                                 filteredIds={state.filteredIds}
                                 getPromptLink={getPromptLink}
                              />
                           </Tab>
                        </Tabs>
                     </div>
                  ) : (
                     <EmptyState
                        icon={<IconUserAdd aria-hidden />}
                        heading='No students have been enrolled yet.'
                        description={
                           <p>
                              Visit the{' '}
                              <Link to={roster.replace(':courseId', state.courseId.toString())}>
                                 Roster
                              </Link>{' '}
                              to invite students to join your course.
                           </p>
                        }
                     />
                  )}
               </div>
            </div>
         </div>
         {state.extensionModalOpen && (
            <ExtensionModal
               submissionIds={getSelectedSubmissionIds()}
               endDate={state.assignment.endDate}
               maxAttempts={state.assignment.attemptsAllowed}
               closeExtensionModal={closeExtensionModal}
               extendAssignment={extendAssignment}
            />
         )}
         {state.eventsModalOpen && (
            <SubmissionEventsModal
               closeModal={toggleEventsModal}
               events={state.currentEvents}
               heading={state.eventsModalHeading}
            />
         )}
      </>
   );
};

export default ActivitySubmissions;
