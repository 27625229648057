import * as React from 'react';

import useOutsideAlerter from '@hooks/use-outside-alerter';
import IconVideoControlVolume from '@icons/nova-line/37-Audio/speaker-volume-high.svg';
import classNames from 'classnames';

import { Maybe } from '@models/Core';
import Button from '@components/Common/Button';

type Props = {
   handleVolumeChange(event: React.ChangeEvent<HTMLInputElement>): void;
   volume: number;
};

const VolumeSlider: React.FC<Props> = (props) => {
   const [isOpen, setIsOpen] = React.useState<boolean>(false);

   const toggleSliderIsOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();
      setIsOpen(!isOpen);
      event.currentTarget.blur();
   };

   const handleOutsideClick = (event: MouseEvent): void => {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.preventDefault();
      setIsOpen(!isOpen);
   };

   const handleSliderClick = (event: React.MouseEvent): void => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
   };

   const componentRef = React.useRef<Maybe<HTMLDivElement>>(null);
   useOutsideAlerter(handleOutsideClick, componentRef, isOpen);

   return (
      <div ref={(r) => (componentRef.current = r)}>
         {isOpen && (
            <div className='volume-wrapper' onClick={handleSliderClick}>
               <input
                  name='volume slider'
                  type='range'
                  min='0.0'
                  max='1.0'
                  step='0.1'
                  value={props.volume}
                  onChange={props.handleVolumeChange}
                  className='slider'
                  onDrag={handleSliderClick}
                  autoFocus
               />
            </div>
         )}
         <Button
            ariaLabel='Toggle volume slider'
            tooltip='Volume'
            icon={<IconVideoControlVolume preserveAspectRatio='xMidYMid meet' aria-hidden />}
            className={classNames('no-transition', {
               open: isOpen,
               'no-top-radius': isOpen,
            })}
            color={isOpen ? 'blue' : 'white'}
            onClick={toggleSliderIsOpen}
         />
      </div>
   );
};

export default VolumeSlider;
