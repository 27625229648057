import * as React from 'react';

import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { ID } from '@models/Core';
import classnames from 'classnames';

export type TableOfContentsRow = {
   id: ID;
   leftHeading: string;
   rightHeading: string;
   isSelected: boolean;
};

export type TableOfContents = {
   className?: string;
   icon?: React.ReactNode;
   rows: readonly TableOfContentsRow[];
   title: string;
   onRowClick(id: TableOfContentsRow): void;
};

const TableOfContents: React.FC<TableOfContents> = (props) => {
   const handleRowClick = (row: TableOfContentsRow) => {
      props.onRowClick(row);
   };

   return (
      <div className={classnames('table-of-contents', props.className)}>
         <div className='margin-bottom-s'>
            {props.icon}
            {props.title}
         </div>
         <ul>
            {props.rows?.map((x) => (
               <li key={x.id}>
                  <div className='nav-icon margin-right-xs'>
                     <IconMenuLevel />
                  </div>
                  <div
                     className={classnames('table-of-contents-item', { active: x.isSelected })}
                     onClick={() => handleRowClick(x)}
                  >
                     <strong className='padding-m tabular-numbers'>{x.leftHeading}</strong>{' '}
                     {x.rightHeading}
                  </div>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default TableOfContents;
