import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import { instructorCourseCodePattern, studentCourseCodePattern } from '@helpers/RegexPatterns';
import AccountType from '@models/AccountType';
import CourseService from '@services/CourseService';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';

interface JoinCourseState {
   courseCode: string;
   error: string;
   validCode: boolean;
   isLoading: boolean;
   submitAutomatically: boolean;
}

const JoinCourse: React.FC = () => {
   const location = useLocation();
   const navigate = useNavigate();

   const {
      routes: {
         courses: { dashboard: courseDashboard },
      },
      statusCodes: { badRequest, notFound, forbidden },
   } = Constants;

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const isInstructor = userProfile?.accountType === AccountType.instructor;

   const [state, setState] = React.useState<JoinCourseState>({
      courseCode: '',
      error: '',
      isLoading: false,
      validCode: false,
      submitAutomatically: false,
   });

   React.useEffect(() => {
      const courseCode = getQueryParameterByName(location, 'code', '');
      if (courseCode) {
         setState((prevState) => ({
            ...prevState,
            courseCode,
            submitAutomatically: true,
         }));
      }
   }, []);

   React.useEffect(() => {
      if (state.submitAutomatically) {
         handleSubmit();
      }
   }, [state.submitAutomatically]);

   const handleSubmit = (): void => {
      const { courseCode, isLoading } = state;
      if (courseCode && !isLoading) {
         setState((prevState) => ({ ...prevState, isLoading: true }));
         CourseService.joinCourseByCode(courseCode)
            .then((courseId) => navigate(courseDashboard.replace(':courseId', courseId.toString())))
            .catch((error) => {
               if (error.response && error.response.status === notFound) {
                  setState((prevState) => ({
                     ...prevState,
                     error: "We couldn't find that course. Are you sure you copied it down correctly?",
                     isLoading: false,
                  }));
               } else if (error.response && error.response.status === forbidden) {
                  setState((prevState) => ({
                     ...prevState,
                     error: 'The code you provided is a student code. You need an instructor course code or a direct invite to join the course.',
                     isLoading: false,
                  }));
               } else if (error.response && error.response.status === badRequest) {
                  setState((prevState) => ({
                     ...prevState,
                     error: error.response.data.msg,
                     isLoading: false,
                  }));
               }
            });
      }
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      let validCode = false;
      if (isInstructor) {
         validCode = instructorCourseCodePattern.test(value);
      } else {
         validCode = studentCourseCodePattern.test(value);
      }
      setState((prevState) => ({
         ...prevState,
         error: '',
         courseCode: value,
         validCode,
      }));
   };

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>Join Course</DocumentTitle>
         <div className='card center col-lg-6 padding-l'>
            <div className='title large center-text margin-bottom-m'>Join a Course</div>
            {isInstructor ? (
               <p className='small gray'>
                  Please enter the <strong>instructor course code</strong> that was given to you
                  below.
               </p>
            ) : (
               <p className='small gray'>
                  Please enter the course code that your instructor gave you below.
               </p>
            )}
            <div className='join-course-form'>
               <input
                  type='text'
                  value={state.courseCode}
                  onChange={handleChange}
                  disabled={state.isLoading}
                  autoFocus
                  placeholder={isInstructor ? 'Instructor Course Code' : 'Course Code'}
                  data-test='course-code-input'
               />
               <Button
                  className='center margin-s'
                  onClick={handleSubmit}
                  loading={state.isLoading}
                  disabled={!state.validCode}
                  data-test='course-code-btn'
               >
                  Submit
               </Button>
            </div>
            {state.error && <p className='small error'>{state.error}</p>}
            {!state.validCode && state.courseCode && (
               <p className='small error'>
                  Code must conform to{' '}
                  {isInstructor ? 'INS-XXXXXX or INS-XXXXXXX' : 'STU-XXXXXXX or XXXXXXX'}
               </p>
            )}
         </div>
      </div>
   );
};

export default JoinCourse;
