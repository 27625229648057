import * as _ from 'lodash';
import * as React from 'react';

import IconSlider1 from '@icons/nova-line/03-Settings/slider-1.svg';
import { Module as ModuleType } from '@models/Course';
import { ProficiencyCanDoStatementDetailed } from '@models/Proficiency';
import ModuleCanDoStatementService from '@services/ModuleCanDoStatementService';

import Button from '@components/Common/Button';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import DeleteAction from '@components/TableActions/DeleteAction';
import ModuleCanDoStatementModalEditorModal from '@components/Course/Proficiency/ModuleCanDoStatementImportModal';

interface ModuleCanDoStatementEditorProps {
   canEditModules: boolean;
   activeModule: ModuleType;
   courseCanDoStatements: readonly ProficiencyCanDoStatementDetailed[];
}

const ModuleCanDoStatementEditor: React.FC<ModuleCanDoStatementEditorProps> = (props) => {
   const [isCanDoModalOpen, setIsCanDoModalOpen] = React.useState<boolean>(false);
   const [moduleCanDoStatments, setModuleCanDoStatments] = React.useState<
      readonly ProficiencyCanDoStatementDetailed[]
   >([]);

   React.useEffect(() => {
      ModuleCanDoStatementService.get(props.activeModule.id).then((r) => {
         setModuleCanDoStatments(r);
      });
   }, [props.activeModule]);

   const shouldShowAttachModuleButton =
      props.canEditModules || props.activeModule?.courseOverrideAllowEdit;

   const openModal = (): void => {
      setIsCanDoModalOpen(true);
   };

   const closeModal = (): void => {
      setIsCanDoModalOpen(false);
   };

   const onAddClick = async (canDoStatement: ProficiencyCanDoStatementDetailed): Promise<void> => {
      try {
         await ModuleCanDoStatementService.create(props.activeModule.id, canDoStatement.id);
         setModuleCanDoStatments((prevState) => [...prevState, canDoStatement]);
      } catch (error) {
         console.error(error);
      }
   };

   const onDeleteClick = async (canDoStatementId: number): Promise<void> => {
      try {
         await ModuleCanDoStatementService.remove(props.activeModule.id, canDoStatementId);
         setModuleCanDoStatments((prevState) => prevState.filter((x) => x.id !== canDoStatementId));
      } catch (error) {
         console.error(error);
      }
   };

   const renderDelete = (row: ProficiencyCanDoStatementDetailed): React.ReactNode => (
      <DeleteAction
         dataTest={`${row.id}-delete`}
         onDelete={() => onDeleteClick(row.id)}
         objectName={row.name}
      />
   );

   const columns: readonly Column<ProficiencyCanDoStatementDetailed>[] = [
      {
         id: 'canDoName',
         cell: (i) => i.name,
         cellClassName: 'text-left',
         header: 'Statements',
         headerClassName: 'text-left',
      },
      {
         id: 'levelName',
         canSort: true,
         cell: (i) => i.level,
         cellClassName: 'text-bold text-uppercase',
         header: 'Level',
         sortFunc: (i) => i.level?.toLowerCase() ?? '',
      },
      {
         id: 'skillName',
         canSort: true,
         cell: (i) => i.skill,
         cellClassName: 'text-bold text-uppercase',
         header: 'Skill',
         sortFunc: (i) => i.skill.toLowerCase(),
      },
      {
         id: 'Delete',
         cell: renderDelete,
         header: '',
      },
   ];

   return (
      <div className='module-can-do-statement-editor'>
         <div className='card inner margin-bottom-m'>
            <div className='card-title has-button'>
               <div className='title'>Can-Do Statements</div>
               {shouldShowAttachModuleButton && (
                  <div className='right-options-wrapper'>
                     <Button
                        color='green'
                        data-test='btn-attach-can-do-statement'
                        onClick={openModal}
                     >
                        + Attach Can-Do
                     </Button>
                  </div>
               )}
            </div>
            {!moduleCanDoStatments.length ? (
               <EmptyState
                  icon={<IconSlider1 aria-hidden />}
                  heading='No Can-Do Statements Yet!'
                  description='Click "Attach Can-Do" to add can-do statements to your module.'
               />
            ) : (
               <Table<ProficiencyCanDoStatementDetailed>
                  columns={columns}
                  rowKey='id'
                  rows={moduleCanDoStatments}
               />
            )}
         </div>
         {isCanDoModalOpen && (
            <ModuleCanDoStatementModalEditorModal
               courseCanDoStatements={props.courseCanDoStatements}
               moduleCanDoStatements={moduleCanDoStatments}
               closeModal={closeModal}
               setModuleCanDoStatements={_.noop}
               onAddClick={onAddClick}
            />
         )}
      </div>
   );
};

export default ModuleCanDoStatementEditor;
