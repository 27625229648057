import * as _ from 'lodash';
import * as React from 'react';

import { formatDate } from '@helpers/FormatTime';
import IconRowMover from '@icons/general/icon-row-mover.svg';
import IconTick from '@icons/general/icon-tick.svg';
import IconLock from '@icons/nova-line/18-Content/content-book-lock.svg';
import AccountType from '@models/AccountType';
import DateTime from '@services/DateTimeService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import moment from 'moment';

import Constants from '../../../Constants';
import { ModuleItem } from '@models/Course/ModuleItem';
import Link from '@components/Common/Link';
import ContentIcon from '@components/ContentIcon';
import {
   isActivity,
   isAssignable,
   isFile,
   isLesson,
   isLink,
   isVideo,
   isVocabSet,
} from './ModuleUtils';

interface ModuleContentItemProps {
   canEdit: boolean;
   isSelected: boolean;
   item: ModuleItem;
   showCheckbox: boolean;
   accountType: AccountType;
   onCheckboxClick(event: React.MouseEvent<HTMLInputElement>): void;
}

const ModuleContentItem: React.FC<ModuleContentItemProps> = ({
   accountType,
   canEdit,
   isSelected,
   item,
   showCheckbox,
   onCheckboxClick,
}) => {
   const {
      routes: {
         activities: { completeSubmission, completeSubmissionNoSubmissionId },
         content: { viewLesson, viewVocabSet, viewVideo },
      },
   } = Constants;
   const isAssigned = isAssignable(item) && item.isAssigned;

   const isInstructor = accountType === AccountType.instructor;
   const isStudent = accountType === AccountType.student;

   const incompletePrerequs = item.prerequisites
      ? item.prerequisites.filter((i) => i.percentCompleted < 1)
      : [];

   const startDateInFuture = isAssigned && item.assignment.startDate > DateTime.now();
   const isLocked = startDateInFuture || incompletePrerequs.length > 0;

   const renderLink = (): React.ReactNode => {
      const dataTest = `content-link-${item.itemName}`;
      if (isInstructor || isLocked) {
         return <>{item.itemName}</>;
      } else if (isLink(item)) {
         return (
            <Link external to={item.linkUrl}>
               {item.itemName}
            </Link>
         );
      } else if (isFile(item)) {
         return (
            <Link external to={item.fileUrl}>
               {item.itemName}
            </Link>
         );
      }
      let url = null;
      if (isActivity(item) && item.submissionId) {
         url = completeSubmission.replace(':submissionId', item.submissionId.toString());
      } else if (isActivity(item) && !item.submissionId) {
         url = completeSubmissionNoSubmissionId.replace(':moduleItemId', item.id.toString());
      } else if (isLesson(item)) {
         url = viewLesson
            .replace(':lessonId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id}`);
      } else if (isVideo(item)) {
         url = viewVideo
            .replace(':videoId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id}`);
      } else if (isVocabSet(item)) {
         url = viewVocabSet
            .replace(':vocabSetId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id}`);
      }
      return url ? (
         <Link to={url} data-test={dataTest}>
            {item.itemName}
         </Link>
      ) : (
         <>{item.itemName}</>
      );
   };

   const lockedToolTip = (): string => {
      let tooltip = '';
      if (incompletePrerequs.length > 0) {
         tooltip = 'You need to complete: ' + incompletePrerequs.map((i) => i.itemName).join(', ');
      } else if (startDateInFuture) {
         tooltip = 'This assignment will be available on: ' + formatDate(item.assignment.startDate);
      }
      return tooltip;
   };

   const handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>): void => {
      event.stopPropagation();
      onCheckboxClick(event);
   };

   return (
      <div
         className={classnames('module-content-item', {
            'can-edit': canEdit,
            'view-only': !canEdit,
            'student-view': isStudent,
         })}
         data-test='module-content-item'
      >
         {canEdit && (
            <div className='row-mover'>
               <IconRowMover />
            </div>
         )}
         <div className='title-icon-wrapper'>
            {canEdit && showCheckbox ? (
               <input
                  checked={isSelected}
                  onChange={(e) => e.stopPropagation()}
                  onClick={handleCheckboxClick}
                  type='checkbox'
               />
            ) : (
               <div
                  className={classnames(
                     'icon-module-item',
                     _.snakeCase(item.itemType).replace('_', '-'),
                  )}
               >
                  <ContentIcon itemType={item.itemType} />
               </div>
            )}
            <div className='content-title'>{renderLink()}</div>
         </div>
         {isStudent && (
            <>
               {isAssigned && (
                  <div className='end-date'>{moment(item.assignment.endDate).format('lll')}</div>
               )}
               <div className='completed-icon'>
                  {isLocked ? (
                     <Tippy content={lockedToolTip()}>
                        <span className={classnames('info-tooltip')}>
                           <IconLock />
                        </span>
                     </Tippy>
                  ) : (
                     isAssignable(item) && (
                        <IconTick fill={item.percentCompleted === 1 ? '#4A7D35' : '#C5C5C5'} />
                     )
                  )}
               </div>
            </>
         )}
      </div>
   );
};

export default ModuleContentItem;
