import * as _ from 'lodash';
import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import IVocabTerm, { PartOfSpeech, TermGender, TermNumber } from '@models/IVocabTerm';
import Language from '@models/Language';
import classnames from 'classnames';
import pluralize from 'pluralize';

import ModalDialog from '@components/Core/ModalDialog';
import { numberOptions, partOfSpeechOptions } from './VocabBuilderUtils';

interface VocabBuilderBulkEditProps {
   bulkProperties: {
      gender: TermGender;
      number: TermNumber;
      partOfSpeech: PartOfSpeech;
   };
   genderOptions: readonly string[];
   language: Maybe<Language>;
   selectedTermCount: number;
   showOptions: boolean;
   termCount: number;
   onSelectAll(): void;
   handleBulkChange(property: keyof IVocabTerm, value: string): void;
   removeSelectedTerms(): void;
}

const MIN_TERMS = 3;

const VocabBuilderBulkEdit: React.FC<VocabBuilderBulkEditProps> = ({
   bulkProperties,
   genderOptions,
   language,
   selectedTermCount,
   showOptions,
   termCount,
   handleBulkChange,
   onSelectAll,
   removeSelectedTerms,
}) => {
   const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = React.useState<boolean>(false);

   const allSelected = termCount === selectedTermCount;
   const canDeleteTerms = termCount - selectedTermCount > MIN_TERMS;

   const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const { name, value } = event.target;
      handleBulkChange(name as keyof IVocabTerm, value);
   };

   const deleteTerms = (): void => {
      removeSelectedTerms();
      setIsBulkDeleteModalOpen(false);
   };

   const renderOptions = (options: readonly string[]): readonly React.ReactNode[] => [
      <option value='' key={0}>
         Select
      </option>,
      ...options.map((o) => (
         <option key={o} value={o}>
            {_.startCase(o)}
         </option>
      )),
   ];

   const closeBulkDeleteModal = (): void => setIsBulkDeleteModalOpen(false);

   const showGenderSelector =
      !!language && ['ar', 'fr', 'de', 'he', 'it', 'pt', 'ru', 'es'].includes(language);

   return (
      <div className='vocab-set-row row bulk-edit-row'>
         <div className='vocab-set-checkbox'>
            <input type='checkbox' checked={allSelected} onChange={onSelectAll} />
         </div>
         <div className='vocab-set-row-content'>
            <div className='col-xs-12'>
               <div className='row bottom-xs'>
                  {showOptions && language !== 'asl' && (
                     <>
                        <div className={`col-sm-${showGenderSelector ? '6' : '8'}`} />
                        <div className='col-xs-12 col-md-2'>
                           <label className='field-title'>Part of Speech</label>
                           <select
                              name='partOfSpeech'
                              value={bulkProperties.partOfSpeech}
                              onChange={handleChange}
                           >
                              {renderOptions(partOfSpeechOptions)}
                           </select>
                        </div>
                        {showGenderSelector && (
                           <div className='col-xs-12 col-md-2'>
                              <label className='field-title'>Gender</label>
                              <select
                                 name='gender'
                                 value={bulkProperties.gender}
                                 onChange={handleChange}
                              >
                                 {renderOptions(genderOptions)}
                              </select>
                           </div>
                        )}
                        <div className='col-xs-12 col-md-2'>
                           <label className='field-title'>Number</label>
                           <select
                              name='number'
                              value={bulkProperties.number}
                              onChange={handleChange}
                           >
                              {renderOptions(numberOptions)}
                           </select>
                        </div>
                     </>
                  )}
               </div>
            </div>
         </div>
         {showOptions && (
            <div className='vocab-set-row-action'>
               <div className='icon-action-wrap'>
                  <div
                     className={classnames('icon-action', {
                        disabled: !canDeleteTerms,
                     })}
                     onClick={() => canDeleteTerms && setIsBulkDeleteModalOpen(true)}
                  >
                     <IconBin />
                  </div>
               </div>
            </div>
         )}
         {isBulkDeleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Delete Vocab Terms'
               onClose={closeBulkDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: deleteTerms },
                  { text: 'Cancel', onClick: closeBulkDeleteModal },
               ]}
            >
               <p>
                  Are you sure that you want to delete {selectedTermCount}{' '}
                  {pluralize('term', selectedTermCount)}?
               </p>
            </ModalDialog>
         )}
      </div>
   );
};

export default VocabBuilderBulkEdit;
