import * as React from 'react';

/**
 * `useWindowSize` is a custom hook that tracks the current size of the browser window.
 * It returns the width and height of the window as a tuple.
 *
 * @returns {[number, number]} A tuple containing the current width and height of the browser window.
 */
const useWindowSize = (): [number, number] => {
   const [size, setSize] = React.useState<[width: number, height: number]>([0, 0]);

   React.useEffect(() => {
      // Function to update the size state with the current window dimensions.
      const updateSize = (): void => {
         setSize([window.innerWidth, window.innerHeight]);
      };

      // Add an event listener to update the size state whenever the window is resized.
      window.addEventListener('resize', updateSize);

      // Call the updateSize function immediately to set the initial window size.
      updateSize();

      // Clean up the event listener when the component unmounts or the dependency array changes.
      return () => window.removeEventListener('resize', updateSize);
   }, []);

   return size;
};

export default useWindowSize;
