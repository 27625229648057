import * as React from 'react';

import Link from '@components/Common/Link';
import NotificationsDropdown from './NotificationsDropdown';
import UserDropdown from './UserDropdown';

import Logo from '../../../../assets/logo.svg';

const Header: React.FC = () => (
   <div className='navbar'>
      <div className='row align-items-center no-margin'>
         <div className='col-xs-2'>
            <div className='nav-logo'>
               <Link to='/'>
                  <Logo className='logo' />
               </Link>
            </div>
         </div>
         <div className='col-xs-4 col-xs-offset-6 col-lg-3 col-lg-offset-7'>
            <div className='nav-dropdowns'>
               <NotificationsDropdown />
               <UserDropdown />
            </div>
         </div>
      </div>
   </div>
);

export default Header;
