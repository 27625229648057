import * as React from 'react';

import { OnChangeValue } from '@components/Core/Select';
import languageOptions from '@helpers/LanguageOptions';
import IconImageClose from '@icons/general/icon-image-close.svg';
import IconFolders from '@icons/nova-line/86-Files-Folders/folders.svg';
import Content, { ContentType } from '@models/Content';
import ContentFolder from '@models/ContentFolder';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import { NumericOption } from '@models/ReactSelectHelperTypes';
import { getStyleSheetsIdentifiers } from '@services/StylesService';
import classnames from 'classnames';

import { AppStateContext } from '../AppState';
import AccentTextbox from './AccentTextbox';
import { Select, selectComponents, selectStyle, selectTheme } from './Core/Select';
import TagsInput from './Core/TagsInput';
import SelectFolderModal from './SelectFolderModal';

interface ContentSettingsMenuProps {
   additionalFields?: React.ReactNode;
   className?: string;
   personalFolders: readonly ContentFolder[];
   settings: Content;
   showFolder: boolean;
   editSettings(update: Partial<Content>): void;
   removeContentImage(): void;
}

const ContentSettingsMenu: React.FC<ContentSettingsMenuProps> = ({
   additionalFields,
   className,
   personalFolders,
   settings: {
      description,
      imageUrl,
      language,
      tags,
      name,
      folderId,
      styleSheetId,
      type: contentType,
   },
   showFolder,
   editSettings,
   removeContentImage,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [tempName, setTempName] = React.useState<string>(name);
   const tempNameRef = React.useRef<Maybe<string>>(null);
   const [styleSheetsOptions, setStyleSheetsOptions] = React.useState<NumericOption[]>([]);
   const [fileInputFocused, setFileInputFocused] = React.useState<boolean>(false);
   const [selectFolderModalOpen, setSelectFolderModalOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      tempNameRef.current = tempName;
   }, [tempName]);

   React.useEffect(
      () => () => {
         editSettings({ name: tempNameRef.current ?? '' });
      },
      [],
   );

   React.useEffect(() => {
      getStyleSheetsIdentifiers().then((styleSheets) => {
         const options = styleSheets.map((x) => ({ value: x.id, label: x.name }));
         setStyleSheetsOptions(options);
      });
   }, [userProfile?.isAdmin]);

   const handleClick = (event: React.MouseEvent<HTMLDivElement>): void =>
      event.nativeEvent.stopImmediatePropagation();

   const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void =>
      editSettings({ description: event.target.value });

   const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void =>
      editSettings({ language: event.target.value as Language });

   const handleStyleChange = (newValue: OnChangeValue<NumericOption, false>): void => {
      let styleId = null;
      if (newValue) {
         styleId = newValue.value;
      }
      editSettings({ styleSheetId: styleId });
   };

   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setTempName(event.target.value);

   const handleTagsChange = (updatedTags: readonly string[]): void =>
      editSettings({ tags: updatedTags });

   const handleFileFocus = (): void => setFileInputFocused(true);

   const handleFileBlur = (): void => setFileInputFocused(false);

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onloadend = () => {
            editSettings({ imageUrl: reader.result as string, file });
         };
         reader.readAsDataURL(file);
      }
   };

   const openSelectFolderModal = (): void => setSelectFolderModalOpen(true);

   const closeSelectFolderModal = (): void => setSelectFolderModalOpen(false);

   const handleFolderSelect = (
      event: React.MouseEvent<HTMLButtonElement>,
      updatedFolderId: Maybe<number>,
   ): void => {
      event.stopPropagation();
      editSettings({ folderId: updatedFolderId });
      closeSelectFolderModal();
   };

   const folder =
      showFolder && folderId !== null ? personalFolders.find((i) => i.id === folderId) : null;

   return (
      <>
         <div
            className={classnames('dropdown-menu content-settings-popup', className)}
            onClick={handleClick}
         >
            <div className='card-title'>
               <div className='title'>Content Settings</div>
            </div>
            <div className='popup-content'>
               <div className='row first-row'>
                  <div className='col-xs-12 col-sm-9'>
                     <label className='field-title' htmlFor='name'>
                        Title
                     </label>
                     <AccentTextbox
                        id='name'
                        placeholder='Enter Title'
                        value={tempName}
                        onChange={handleNameChange}
                        language={language}
                     />
                     {showFolder && (
                        <>
                           <label className='field-title'>Folder</label>
                           <div className='margin-top-s'>
                              <div
                                 className='select-folder'
                                 onClick={openSelectFolderModal}
                                 onKeyDown={(e) => e.key === 'Enter' && openSelectFolderModal()}
                                 tabIndex={0}
                              >
                                 <IconFolders aria-hidden />
                                 <span className='folder-name'>
                                    {folder ? folder.name : 'Select'}
                                 </span>
                              </div>
                           </div>
                        </>
                     )}
                     <label className='field-title' htmlFor='language'>
                        Language
                     </label>
                     <select id='language' value={language} onChange={handleLanguageChange}>
                        {languageOptions}
                     </select>
                  </div>
                  <div className='col-xs-12 col-sm-3'>
                     <label className='field-title' htmlFor='image-input'>
                        Image
                     </label>
                     <div className='set-settings-image-wrap'>
                        {imageUrl && (
                           <div
                              className='set-settings-image-close'
                              onClick={removeContentImage}
                              aria-label='Remove Image'
                           >
                              <IconImageClose />
                           </div>
                        )}
                        <div
                           className={classnames('set-settings-image', {
                              focused: fileInputFocused,
                           })}
                        >
                           {imageUrl ? (
                              <img className='ignore-click' src={imageUrl} />
                           ) : (
                              <input
                                 type='file'
                                 id='image-input'
                                 className='pointer ignore-click'
                                 accept='image/*'
                                 onBlur={handleFileBlur}
                                 onChange={handleFileChange}
                                 onFocus={handleFileFocus}
                              />
                           )}
                        </div>
                     </div>
                  </div>
                  <div className='col-xs-12'>
                     <label className='field-title'>Tags</label>
                     <TagsInput
                        className='margin-top-xs'
                        value={tags}
                        tagProps={{
                           className: 'tag',
                           classNameRemove: 'remove ignore-click',
                        }}
                        onChange={handleTagsChange}
                     />
                     {userProfile?.isAdmin &&
                        styleSheetsOptions &&
                        [ContentType.activity, ContentType.lesson].includes(contentType) && (
                           <>
                              <label className='field-title' htmlFor='style-sheet'>
                                 Style Sheet
                              </label>
                              <Select<NumericOption>
                                 id='style-sheet-select'
                                 isSearchable={false}
                                 value={styleSheetsOptions.find((i) => i.value === styleSheetId)}
                                 placeholder='Select Style Sheet'
                                 components={selectComponents}
                                 isClearable
                                 options={styleSheetsOptions}
                                 theme={selectTheme}
                                 styles={{
                                    ...selectStyle,
                                    control: (styles) => ({
                                       ...styles,
                                       marginTop: '8px',
                                    }),
                                 }}
                                 onChange={handleStyleChange}
                              />
                           </>
                        )}
                  </div>
                  <div className='col-xs-12'>
                     <label className='field-title' htmlFor='description'>
                        Description
                     </label>
                     <textarea
                        id='description'
                        value={description}
                        onChange={handleDescriptionChange}
                     />
                  </div>
               </div>
               {additionalFields}
            </div>
         </div>
         {selectFolderModalOpen && (
            <SelectFolderModal
               currentFolderId={folderId}
               folders={personalFolders}
               heading='Select Folder'
               primaryBtnText='Select'
               onPrimaryClick={handleFolderSelect}
               closeModal={closeSelectFolderModal}
            />
         )}
      </>
   );
};

export default ContentSettingsMenu;
