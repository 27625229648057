import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import { MessageResponse } from '@models/Core';
import {
   Course,
   CourseLMSConnection,
   CoursePermissions,
   CourseSection,
   GradingCategory,
} from '@models/Course';
import Language from '@models/Language';
import CourseService from '@services/CourseService';
import DateTime from '@services/DateTimeService';
import HttpService from '@services/HttpService';
import moment from 'moment';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

import { AppStateContext } from '../../../AppState';
import mixPanelActions from '../../../Mixpanel';

interface ModuleItem {
   id: number;
   index: number;
   itemType: ContentType;
   prerequisites: readonly { itemId: number }[];
}

interface Module {
   id: number;
   name: string;
   selected: boolean;
   dirty: boolean;
   defaultAssignmentStartDate: Date;
   defaultAssignmentEndDate: Date;
   items: readonly ModuleItem[];
}

interface GetModulesWithItemsResponse {
   course: {
      id: number;
      archived: boolean;
      endDate: Date;
      startDate: Date;
      demo: boolean;
      defaultDueTime: Date;
      name: string;
      language: Language;
      gradingCategory: readonly GradingCategory[];
      sections: readonly CourseSection[];
      lmsConnections: readonly CourseLMSConnection[];
   };
   modules: readonly Module[];
   permission: CoursePermissions;
}

interface CloneCourseModalProps {
   courseId: number;
   courseName: string;
   courseLanguage: Language;
   closeModal(): void;
}

interface CloneCourseState {
   course: Pick<Course, 'name' | 'startDate' | 'endDate' | 'defaultDueTime' | 'language'>;
   modules: readonly Module[];
}

type FormErrorMessages = {
   name?: string;
};

const CloneCourseModal: React.FC<CloneCourseModalProps> = ({
   courseId,
   courseName,
   courseLanguage,
   closeModal,
}) => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<CloneCourseState>({
      course: {
         name: '',
         startDate: moment(DateTime.now())
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
         endDate: moment(DateTime.midnight()).add(3, 'months').toDate(),
         defaultDueTime: DateTime.midnight(),
         language: courseLanguage,
      },
      modules: [],
   });
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [formErrors, setFormErrors] = React.useState<FormErrorMessages>({});

   React.useEffect(() => {
      HttpService.getWithAuthToken<GetModulesWithItemsResponse>(
         `/api/courses/${courseId}/modules?all_items=true`,
      ).then((response) => {
         const { modules } = response.data;
         setState((prevState) => ({
            ...prevState,
            course: {
               ...prevState.course,
               name: '',
            },
            modules: modules.map((i) => ({
               id: i.id,
               name: i.name,
               selected: true,
               dirty: false,
               items: i.items,
               defaultAssignmentStartDate: prevState.course.startDate,
               defaultAssignmentEndDate: prevState.course.endDate,
            })),
         }));
         setIsFetching(false);
      });
   }, [courseId]);

   const cloneCourse = async (): Promise<void> => {
      if (!(await validateCourseName())) {
         return;
      }

      const data = {
         ...state.course,
         modules: state.modules
            .filter((i) => i.selected)
            .map(({ id, defaultAssignmentStartDate, defaultAssignmentEndDate, items }) => ({
               id,
               defaultAssignmentStartDate,
               defaultAssignmentEndDate,
               itemIds: items.map((i) => i.id),
            })),
      };
      HttpService.postWithAuthToken<MessageResponse>(
         `/api/courses/${courseId}/clone`,
         snakeCaseKeys(data),
      ).then(() => {
         mixPanelActions.track(
            'Course Cloned',
            snakeCaseKeys({
               sourceId: courseId,
               sourceName: courseName,
               name: state.course.name,
               language: state.course.language,
            }),
         );
         dispatchToast({
            title: 'Course Clone Started',
            message: "We'll notify you when we finish cloning your course",
         });
      });
      closeModal();
   };

   const validateCourseName = async (): Promise<boolean> => {
      const errors: FormErrorMessages = {};

      if (!state.course.name.length) {
         errors.name = 'Cannot be blank';
      } else if (
         state.course.name.toLowerCase() === courseName.toLowerCase() ||
         (await CourseService.checkIfCourseExistsByName(state.course.name))
      ) {
         errors.name = `'${state.course.name}' is already taken`;
      }

      setFormErrors(errors);

      const formHasNoErrors = Object.keys(errors).length === 0;
      return formHasNoErrors;
   };

   const handleCourseChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCourseAttributes({ [event.target.name]: event.target.value });
   };

   const setCourseAttributes = (update: Partial<Course>): void => {
      setState((prevState) => ({
         ...prevState,
         course: { ...prevState.course, ...update },
      }));
   };

   const setCourseStartDate = (date: Date): void => {
      setState((prevState) => {
         const updatedModules = [...prevState.modules];
         for (const mod of updatedModules) {
            if (mod.defaultAssignmentStartDate < date || !mod.dirty) {
               mod.defaultAssignmentStartDate = date;
            }
            if (date > mod.defaultAssignmentEndDate) {
               mod.defaultAssignmentEndDate = date;
            }
         }
         return {
            ...prevState,
            modules: updatedModules,
         };
      });
      setCourseAttributes({ startDate: date });
   };

   const setCourseEndDate = (date: Date): void => {
      if (state.course.startDate > date) {
         setCourseStartDate(date);
      }
      setCourseAttributes({ endDate: date });
      setState((prevState) => {
         const updatedModules = [...prevState.modules];
         for (const mod of updatedModules) {
            if (mod.defaultAssignmentEndDate > date || !mod.dirty) {
               mod.defaultAssignmentEndDate = date;
            }
            if (date < mod.defaultAssignmentStartDate) {
               mod.defaultAssignmentStartDate = date;
            }
         }
         return {
            ...prevState,
            modules: updatedModules,
         };
      });
   };

   const updateModuleEndTimes = (date: Date): void => {
      setState((prevState) => {
         const updatedModules = [...prevState.modules];
         for (const mod of updatedModules) {
            const newTime = DateTime.setTimeUsingDate(mod.defaultAssignmentEndDate, date);
            if (newTime > mod.defaultAssignmentStartDate) {
               mod.defaultAssignmentEndDate = newTime;
            }
         }
         return {
            ...prevState,
            modules: updatedModules,
         };
      });
   };

   const toggleModule = (moduleId: number): void => {
      const moduleObj = state.modules.find((i) => i.id === moduleId);
      if (moduleObj) {
         setModuleAttributes(moduleId, { selected: !moduleObj.selected });
      }
   };

   const setModuleAttributes = (moduleId: number, update: Partial<Module>): void => {
      setState((prevState) => ({
         ...prevState,
         modules: prevState.modules.map((i) => (i.id === moduleId ? { ...i, ...update } : i)),
      }));
   };

   const setModuleStartDate = (
      moduleId: number,
      newStartDate: Date,
      defaultAssignmentEndDate: Date,
   ): void => {
      if (state.course.endDate > newStartDate || defaultAssignmentEndDate > newStartDate) {
         return;
      }
      setModuleAttributes(moduleId, {
         defaultAssignmentStartDate: newStartDate,
         dirty: true,
      });
   };

   const setModuleEndDate = (
      moduleId: number,
      newEndDate: Date,
      defaultAssignmentStartDate: Date,
   ): void => {
      if (state.course.startDate > newEndDate || defaultAssignmentStartDate > newEndDate) {
         return;
      }
      setModuleAttributes(moduleId, {
         defaultAssignmentEndDate: newEndDate,
         dirty: true,
      });
   };

   const setDefaultDueTime = (date: Date): void => {
      const {
         course: { endDate },
      } = state;
      setCourseAttributes({ defaultDueTime: date });
      const newEndDate = DateTime.setTimeUsingDate(endDate, date);
      if (newEndDate < state.course.startDate) {
         setCourseStartDate(newEndDate);
      }
      setCourseEndDate(newEndDate);
      updateModuleEndTimes(date);
   };

   const rowRenderer = ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // When scrolling
      isVisible, // When prerendered virtualized
      style, // Style object to be applied to row (to position it)
   }: Partial<ListRowProps>): JSX.Element => {
      if (index === undefined) {
         return <></>;
      }
      const {
         name: moduleName,
         selected,
         items,
         id: moduleId,
         defaultAssignmentEndDate,
         defaultAssignmentStartDate,
      } = state.modules[index];
      return (
         <div className='row module-import-row' key={key} style={style}>
            <div className='col-xs-1'>
               <input type='checkbox' checked={selected} onChange={() => toggleModule(moduleId)} />
            </div>
            <div className='col-xs-4 module-name'>{moduleName}</div>
            <div className='col-xs-3'>
               {isScrolling || !isVisible ? (
                  <input
                     disabled
                     value={moment(defaultAssignmentStartDate).format('MM/D/YYYY h:mm A')}
                     type='text'
                     className='flatpickr-input active'
                     readOnly
                  />
               ) : (
                  <DateTimePicker
                     data-test={`module-start-date-${index}`}
                     onChange={(date) =>
                        setModuleStartDate(moduleId, date, defaultAssignmentEndDate)
                     }
                     value={defaultAssignmentStartDate}
                     minDate={state.course.startDate}
                     maxDate={defaultAssignmentEndDate}
                  />
               )}
            </div>
            <div className='col-xs-3'>
               {isScrolling || !isVisible ? (
                  <input
                     disabled
                     value={moment(defaultAssignmentEndDate).format('MM/D/YYYY h:mm A')}
                     type='text'
                     readOnly
                     className='flatpickr-input active'
                  />
               ) : (
                  <DateTimePicker
                     data-test={`module-end-date-${index}`}
                     onChange={(date) =>
                        setModuleEndDate(moduleId, date, defaultAssignmentStartDate)
                     }
                     value={defaultAssignmentEndDate}
                     minDate={defaultAssignmentStartDate}
                     maxDate={state.course.endDate}
                  />
               )}
            </div>
            <div className='col-xs-1'>
               <div className='module-item-number'>{items.length}</div>
            </div>
         </div>
      );
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         width='large'
         heading={`Clone ${courseName}`}
         bodyClassName='clone-course-modal'
         footerClassName='card-footer'
         onClose={closeModal}
         shouldCloseOnOverlayClick={false}
         actions={[
            { text: 'Clone', onClick: cloneCourse, disabled: isFetching },
            { text: 'Cancel', onClick: closeModal, disabled: isFetching },
         ]}
      >
         {isFetching ? (
            <Loader />
         ) : (
            <>
               <div className='row'>
                  <div className='col-xs-12'>
                     <label className='field-title'>
                        New Course Name
                        <InfoTooltip>Example: German 101 (Fall &apos;22)</InfoTooltip>
                     </label>
                     <AccentTextbox
                        autoFocus
                        data-test='new-course-name'
                        language={courseLanguage}
                        name='name'
                        onChange={handleCourseChange}
                        value={state.course.name}
                     />
                     {formErrors.name && <p className='error'>{formErrors.name}</p>}
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>New Start Date</label>
                     <DateTimePicker
                        data-test='new-start-date'
                        onChange={setCourseStartDate}
                        value={state.course.startDate}
                        maxDate={state.course.endDate}
                     />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>New End Date</label>
                     <DateTimePicker
                        data-test='new-end-date'
                        onChange={setCourseEndDate}
                        value={state.course.endDate}
                        minDate={state.course.startDate}
                     />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Default Due Time</label>
                     <DateTimePicker
                        onChange={setDefaultDueTime}
                        value={state.course.defaultDueTime}
                        calendar={false}
                     />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12'>
                     <div className='row margin-top-s'>
                        <div className='col-xs-1'>
                           <label className='field-title'>Import</label>
                        </div>
                        <div className='col-xs-4'>
                           <label className='field-title'>Module</label>
                        </div>
                        <div className='col-xs-3'>
                           <label className='field-title'>Assignment Start Date</label>
                        </div>
                        <div className='col-xs-3'>
                           <label className='field-title'>Assignment End Date</label>
                        </div>
                        <div className='col-xs-1'>
                           <label className='field-title'>Items</label>
                        </div>
                     </div>
                  </div>
                  {state.modules.length > 20 ? (
                     <div className='modules overflow'>
                        <AutoSizer>
                           {({ height }) => (
                              <List
                                 width={820}
                                 height={height}
                                 rowHeight={42}
                                 overscanRowCount={10}
                                 rowCount={state.modules.length}
                                 rowRenderer={rowRenderer}
                              />
                           )}
                        </AutoSizer>
                     </div>
                  ) : (
                     <div className='modules'>
                        {state.modules.map((module, index) =>
                           rowRenderer({
                              key: `module-${module.id}`,
                              index,
                              isScrolling: false,
                              isVisible: true,
                              style: {},
                           }),
                        )}
                     </div>
                  )}
               </div>
            </>
         )}
      </ModalDialog>
   );
};

export default CloneCourseModal;
