import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { AxiosProgressEvent } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import AxiosService from './AxiosService';
import HttpService from './HttpService';

export enum MediaType {
   audio = 'audio',
   video = 'video',
   image = 'image',
}

type PresignedURLAPIResponse = {
   fields: Record<string, string>;
   url: string;
   get_url: string;
};

export type PresignedURLResponse = {
   filename: string;
   getUrl: string;
   urlResponse: PresignedURLAPIResponse;
};

type PostUserFileResponse = {
   /** Filename in system */
   filename: string;
   /** Url to get file from */
   url: string;
   /** Path to get file from within bucket */
   path: string;
};

export const uploadAudio = async (
   file: Blob | File,
): Promise<{ filename: string; url: string }> => {
   const body = new FormData();
   body.append('audio', file);
   return HttpService.postWithAuthToken<{ filename: string; url: string }>(
      '/api/services/upload_audio',
      body,
   ).then((response) => Promise.resolve(response.data));
};

export const uploadImage = (file: File, shouldGenerateNewFilename = true): Promise<string> => {
   const body = new FormData();
   body.append('image', file, file.name);

   return HttpService.postWithAuthToken<{ filename: string }>(
      `/api/services/upload_image?should_generate_new_filename=${shouldGenerateNewFilename}`,
      body,
   ).then((response) => Promise.resolve(response.data.filename));
};

export const uploadFile = async (file: File): Promise<string> => {
   const body = new FormData();
   body.append('file', file, file.name);
   return HttpService.postWithAuthToken<{ filename: string }>(
      '/api/services/upload_file',
      body,
   ).then((response) => Promise.resolve(response.data.filename));
};

export const uploadUserFile = (
   file: Blob | File,
   onUploadProgress?: (progress: AxiosProgressEvent) => void,
): Promise<PostUserFileResponse> => {
   const body = new FormData();

   if (file instanceof File) {
      // file is of type File, so it has a name property
      body.append('file', file, file.name);
   } else {
      // file is of type Blob, so we create a default name
      body.append('file', file, 'unnamed-file');
   }

   return HttpService.postWithAuthToken<PostUserFileResponse>('/api/services/user_files', body, {
      onUploadProgress,
   }).then((response) => response.data);
};

export const duplicateAsset = async (
   storedFilename: string,
   mediaType: MediaType,
): Promise<string> => {
   const url = `/api/services/duplicate_asset?type=${mediaType}`;
   return HttpService.postWithAuthToken<{ filename: string }>(
      url,
      snakeCaseKeys({ storedFilename }),
   ).then((response) => Promise.resolve(response.data.filename));
};

const getPresignedPostURL = async (file: File): Promise<PresignedURLResponse> => {
   const url = `/api/services/signed_video_url?content_type=${encodeURIComponent(file.type)}`;
   const result = await HttpService.getWithAuthToken<PresignedURLAPIResponse>(url, {
      camelCaseKeys: false,
   });
   const filename = result.data.fields.key.substring(result.data.fields.key.lastIndexOf('/') + 1);
   return {
      filename,
      getUrl: result.data.get_url,
      urlResponse: result.data,
   };
};

const getBulkPresignedURLs = async (files: File[]): Promise<PresignedURLResponse[]> => {
   const url = '/api/services/bulk_signed_video_urls';
   // eslint-disable-next-line camelcase
   const result = await AxiosService.get<{ presigned_urls: PresignedURLAPIResponse[] }>(url, {
      params: {
         contentType: files[0].type,
         numberOfFiles: files.length,
      },
      transformResponseOptions: { camelCaseKeys: false },
   });

   const response = result.data.presigned_urls.map((presignedURL) => {
      const filename = presignedURL.fields.key.substring(
         presignedURL.fields.key.lastIndexOf('/') + 1,
      );

      return {
         filename,
         getUrl: presignedURL.get_url,
         urlResponse: presignedURL,
      };
   });

   return response;
};

const uploadWithProgress = (
   presignedURLResponse: PresignedURLResponse,
   file: File,
   id: string,
   progressCallback?: (progress: number, id: string) => void,
): Promise<XMLHttpRequest> =>
   new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const body = new FormData();
      [
         'key',
         'policy',
         'x-amz-algorithm',
         'x-amz-credential',
         'x-amz-date',
         'x-amz-signature',
      ].forEach((i) => body.append(i, presignedURLResponse.urlResponse.fields[i]));
      body.append('file', file);

      xhr.onreadystatechange = () => {
         if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status < 300) {
               resolve(xhr);
            } else {
               reject(xhr);
            }
         }
      };

      if (progressCallback) {
         xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
               const percentComplete = (e.loaded / file.size) * 100;
               progressCallback(percentComplete, id);
            }
         };
      }

      xhr.open('POST', presignedURLResponse.urlResponse.url);
      xhr.send(body);
   });

export const uploadS3Video = async (
   file: File,
   progressCallback?: (progress: number, id: string) => void,
): Promise<PresignedURLResponse> => {
   const urlResponse = await getPresignedPostURL(file);
   await uploadWithProgress(urlResponse, file, uuidv4(), progressCallback);
   return urlResponse;
};

export default {
   getBulkPresignedURLs,
   getPresignedPostURL,
   uploadAudio,
   uploadFile,
   uploadImage,
   uploadS3Video,
   uploadUserFile,
   uploadWithProgress,
};
