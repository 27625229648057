import { MessageResponse } from '@models/Core';
import HttpService from './HttpService';

const API = '/api/downtime';

const getDowntimeMessage = async (): Promise<string | null> => {
   const response = await HttpService.get<MessageResponse>(API);
   if (response.status === 204 || !response.data) {
      return null;
   } else {
      return response.data.msg;
   }
};

export default {
   getDowntimeMessage,
};
