import EncodingUtils from '@helpers/EncodingUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import {
   CourseEnrollment,
   CourseInvite,
   CourseLMSConnection,
   CoursePermissions,
   CourseSection,
   RosterRecord,
} from '@models/Course';
import { NationalExamAbbreviation } from '@models/NationalExam';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';

import { SortByEntry } from '@components/Common/Table';
import { encodePredicate } from '@components/PredicateEditor/Utils';
import ExtendedCourseEnrollmentProfile from '@models/Course/ExtendedCourseEnrollmentProfile';
import HttpService, { HttpServiceRequestOptions } from './HttpService';

export type NationalExamLevelCategory = {
   id: number;
   levelCatName: string;
   show: boolean;
   sortOrder: number;
};

export type NationalExam = {
   isPracticeExam: boolean;
   categories: readonly NationalExamLevelCategory[];
   nameAbbr: NationalExamAbbreviation;
   seatsPurchased: number;
   seatsUsed: number;
};

export type GetRosterResponse = {
   archived: boolean;
   courseCode: string;
   demo: boolean;
   enrollments: readonly CourseEnrollment[];
   id: number;
   instructorCourseCode: string;
   invites: readonly CourseInvite[];
   lmsConnections: readonly CourseLMSConnection[];
   name: string;
   nationalExam: NationalExam;
   permissions: CoursePermissions;
   schoolDomainRestricted: boolean;
   organizationId: number;
   sections: readonly CourseSection[];
};

export type SimpleMessage = {
   msg: string;
};

export type AssignSectionsResponse = SimpleMessage & {
   examSeatsUsed: number;
};

export const getRoster = (courseId: number): Promise<GetRosterResponse> =>
   HttpService.getWithAuthToken<GetRosterResponse>(`/api/courses/${courseId}/roster`).then(
      (response) => response.data,
   );

export const assignSections = (
   courseId: number,
   enrollmentIds: readonly number[],
   inviteIds: readonly number[],
   sectionIds: readonly number[],
): Promise<SimpleMessage> =>
   HttpService.putWithAuthToken<SimpleMessage>(
      `/api/courses/${courseId}/assign_sections`,
      snakeCaseKeys({ enrollmentIds, inviteIds, sectionIds }),
   ).then((r) => r.data);

export const getCourseEnrollments = (
   { predicate, ...rest }: PagedSearchFilters,
   sortOrder?: readonly SortByEntry[],
   options?: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<ExtendedCourseEnrollmentProfile>> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(rest));
   if (predicate) {
      encodedQuery.set('predicate', encodePredicate(predicate));
   }
   if (sortOrder) {
      const str = JSON.stringify(sortOrder);
      encodedQuery.set('order_by', EncodingUtils.stringToB64(str));
   }
   return HttpService.getWithAuthToken<PagedResponse<ExtendedCourseEnrollmentProfile>>(
      `/api/admin/course_enrollments?${encodedQuery}`,
      options,
   ).then((response) => response.data);
};

export const deleteEnrollment = (courseId: number, courseEnrollmentId: number): Promise<void> =>
   HttpService.deleteWithAuthToken(`/api/courses/${courseId}/roster/${courseEnrollmentId}`).then();

export const updateRoster = (
   courseId: number,
   updates: readonly Partial<RosterRecord>[],
): Promise<void> =>
   HttpService.patchWithAuthToken<void>(
      `/api/courses/${courseId}/roster`,
      snakeCaseKeys({ roster: updates }),
   ).then();

export default {
   getRoster,
   assignSections,
   deleteEnrollment,
   getCourseEnrollments,
   updateRoster,
};
