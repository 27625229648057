import * as _ from 'lodash';
import * as React from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import Appearance from '@models/Appearance';
import { AdminNationalExamLevel, AdminNationalExamLevelSeatAllocation } from '@models/NationalExam';
import AdminNationalExamService from '@services/AdminNationalExamOrderService';
import { updatedDiff } from 'deep-object-diff';
import { useForm } from 'react-hook-form';

import ModalDialog from '@components/Core/ModalDialog';

interface NationalExamSeatModalProps {
   examLevels: AdminNationalExamLevel[];
   orderId: number | string;
   selected?: AdminNationalExamLevelSeatAllocation | null;
   usedLevelIds: readonly number[];
   onCancel(): void;
   onSave(): void;
}

type NationalExamLevelSeatAllocationFormInput = Pick<
   AdminNationalExamLevelSeatAllocation,
   'levelId' | 'seatsPurchased'
>;

// All of the input coming out of the form are string values.
const createSeatOrderFormSchema = yup
   .object({
      levelId: yup.number().required(),
      seatsPurchased: yup.number().required(),
   })
   .required();

const EditUserModal: React.FC<NationalExamSeatModalProps> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);

   const {
      handleSubmit,
      register,
      formState: { isDirty },
   } = useForm<NationalExamLevelSeatAllocationFormInput>({
      resolver: yupResolver(createSeatOrderFormSchema),
      defaultValues: props.selected || undefined,
   });

   const createSeats = (data: NationalExamLevelSeatAllocationFormInput): void => {
      setIsSaving(true);

      AdminNationalExamService.createExamSeatOrder(props.orderId, data)
         .then(() => {
            setIsSaving(false);
            props.onSave();
         })
         .catch((error) => {
            setIsSaving(false);

            if (error.response.status === 400) {
               alert(error.response.data?.msg || 'Failed to save.');
            } else {
               alert('Failed to save.');
            }
         });
   };

   const updateSeats = (data: NationalExamLevelSeatAllocationFormInput): void => {
      const difference: Partial<AdminNationalExamLevelSeatAllocation> = updatedDiff(
         _.omit(props.selected, ['levelId', 'levelName']),
         data,
      );

      if (!_.isEmpty(difference)) {
         setIsSaving(true);
         difference.levelId = props.selected?.levelId;

         AdminNationalExamService.updateExamSeatOrder(props.orderId, difference)
            .then(() => {
               setIsSaving(false);
               props.onSave();
            })
            .catch((error) => {
               setIsSaving(false);

               if (error.response.status === 400) {
                  alert(error.response.data?.msg || 'Failed to save.');
               } else {
                  alert('Failed to save.');
               }
            });
      }
   };

   const onSubmit = (data: NationalExamLevelSeatAllocationFormInput): void => {
      const isUpdate = !!props.selected;
      if (isUpdate) {
         updateSeats(data);
      } else {
         createSeats(data);
      }
   };

   const getExamLevels = (): AdminNationalExamLevel[] => {
      if (props.selected) {
         return props.examLevels;
      }

      // When creating filter out any already used levels on this order
      return props.examLevels.filter((el) => props.usedLevelIds.indexOf(el.id) === -1);
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='no-center padding-bottom-s'
         heading={`${props.selected ? 'Update' : 'Create'} Seat Order`}
         onClose={props.onCancel}
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            { text: 'Cancel', onClick: props.onCancel },
         ]}
      >
         <form>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>Level</label>
                  <select
                     {...register('levelId', { required: true })}
                     disabled={!!props.selected}
                     className={`${!props.selected || 'disabled'}`}
                     value={props.selected?.levelId}
                  >
                     {getExamLevels().map((x) => (
                        <option key={x.id} value={x.id}>
                           {x.levelName}
                        </option>
                     ))}
                  </select>
               </div>
               <div className='col-xs-12'>
                  <label className='field-title'>Seats Purchased</label>
                  <input
                     {...register('seatsPurchased', { required: true })}
                     type='text'
                     defaultValue={props.selected?.seatsPurchased}
                  />
               </div>
            </div>
         </form>
      </ModalDialog>
   );
};

export default EditUserModal;
