import * as React from 'react';

import { GetHandleProps, SliderItem } from 'react-compound-slider';

interface HandleProps {
   domain: readonly number[];
   handle: SliderItem;
   getHandleProps(id: string): GetHandleProps;
}

const Handle: React.FC<HandleProps> = ({
   domain: [min, max],
   handle: { id, value, percent },
   getHandleProps,
}) => (
   <div
      role='slider'
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      className='handle'
      style={{ left: `${percent}%` }}
      {...getHandleProps(id)}
   />
);

export default React.memo(Handle);
