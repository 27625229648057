import * as _ from 'lodash';
import * as React from 'react';

import ContentIcon from '@components/ContentIcon';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import Loader from '@components/Loader';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { Module, ModuleItem } from '@models/Course';
import HttpService from '@services/HttpService';
import classnames from 'classnames';

export interface EditPrereqsModalProps {
   courseId: number;
   selectedItem: ModuleItem;
   selectedModuleId: number;
   addPrereq(prereqId: ModuleItem): void;
   onClose(): void;
}

const EditPrereqsModal: React.FC<EditPrereqsModalProps> = ({
   courseId,
   selectedModuleId,
   selectedItem,
   addPrereq,
   onClose,
}) => {
   const [modules, setModules] = React.useState<readonly Module[]>([]);
   const [activeModuleId, setActiveModuleId] = React.useState<Maybe<number>>(null);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);

   const getActiveModule = (): Maybe<Module> =>
      modules && activeModuleId ? modules.find((i) => i.id === activeModuleId) : null;

   const getPrereqModalItems = (): void => {
      HttpService.getWithAuthToken<{ modules: readonly Module[] }>(
         `/api/courses/${courseId}/modules?all_items=true`,
      ).then((response) => {
         const { modules: responseModules } = response.data;
         setModules(responseModules);
         setActiveModuleId(selectedModuleId);
         setIsFetching(false);
      });
   };

   React.useEffect(() => {
      getPrereqModalItems();
   }, []);

   const renderItemActionIcon = (item: ModuleItem): React.ReactNode => {
      const isSelectedItem = item.id === selectedItem.id;
      const alreadyAdded = selectedItem.prerequisites?.find((i) => i.prereqItemId === item.id);

      if (isSelectedItem) {
         return <div className='current-tag'>Current</div>;
      }

      return alreadyAdded ? (
         <div className='module-checked'>
            <IconRadioTick className='icon-green' />
         </div>
      ) : (
         <div className='module-add-button'>
            <IconAddSmall data-test='btn-add-prereq' onClick={() => addPrereq(item)} />
         </div>
      );
   };

   const contentItemsFactory = (): readonly React.ReactNode[] => {
      const activeModule = getActiveModule();
      const isEmpty =
         activeModule?.items?.filter((i) => i.itemType === ContentType.activity).length === 0;

      if (!activeModule?.items || isEmpty) {
         return [];
      }

      return activeModule.items
         .filter((i) => i.itemType === ContentType.activity)
         .map((item) => {
            const isSelectedItem = item.id === selectedItem.id;
            return (
               <div
                  className={classnames('module-content-item', {
                     current: isSelectedItem,
                  })}
                  key={item.id}
               >
                  <div className='title-icon-wrapper with-button'>
                     <div
                        className={classnames(
                           'icon-module-item',
                           _.snakeCase(item.itemType).replace('_', '-'),
                        )}
                     >
                        <ContentIcon itemType={item.itemType} />
                     </div>
                     <div className='content-title'>{item.itemName}</div>
                  </div>
                  {renderItemActionIcon(item)}
               </div>
            );
         });
   };

   const renderEmptyState = (): React.ReactNode => {
      const isEmpty = !modules.length;
      const icon = isEmpty ? (
         <IconContentBook2 className='icon-grey' aria-hidden />
      ) : (
         <IconBinoculars className='icon-grey' aria-hidden />
      );
      const heading = 'No content!';
      const description = 'Add some more content to this module!';

      return <EmptyState description={description} heading={heading} icon={icon} />;
   };

   const renderModules = (): React.ReactNode => (
      <div>
         {modules.map((module) => (
            <div
               className={classnames('prereq-item', 'pointer', {
                  'sub-module': module.parentModuleId !== null,
                  active: module.id === activeModuleId,
               })}
               key={module.id}
               onClick={() => setActiveModuleId(module.id)}
            >
               <div className='title'>{module.name}</div>
            </div>
         ))}
      </div>
   );

   const contentItems = contentItemsFactory();

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='edit-module-item-prereq-modal-body'
         className='no-padding'
         headerClassName='card-title primary'
         footerClassName='edit-module-item-prereq-modal-footer'
         heading={`Add Prerequisites for ${selectedItem.itemName}`}
         onClose={onClose}
         width='x-large'
      >
         {isFetching ? (
            <Loader />
         ) : (
            <div className='row'>
               <div className='col-xs-12 col-md-5 module-item-prereq-col module-item-prereq-modules'>
                  {renderModules()}
               </div>
               <div className='col-xs-12 col-md-7 module-item-prereq-col module-content-list'>
                  {contentItems.length ? contentItems : renderEmptyState()}
               </div>
            </div>
         )}
      </ModalDialog>
   );
};

export default EditPrereqsModal;
