import * as React from 'react';

import IconVideoControlNext from '@icons/nova-solid/36-Videos/video-control-next.svg';
import IconVideoControlPrevious from '@icons/nova-solid/36-Videos/video-control-previous.svg';
import { Maybe } from '@models/Core';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { ActivityCompleterMode, ActivityPrompt } from '@models/Activity';
import Button from '@components/Common/Button';
import CompleterPrompt, { CompleterPromptProps } from './CompleterPrompt';

type InteractivePromptWrapperProps = Omit<CompleterPromptProps, 'prompt'> & {
   checkAnswers(): void;
   isFullScreen: boolean;
   isSubmitting: boolean;
   currentPrompt: ActivityPrompt<ActivityCompleterMode>;
   replayVideoSegment(): void;
   setInteractivePromptIdToDisplay(promptId: Maybe<number>): void;
   setIsPlaying(isPlaying: boolean): void;
   showCheckAnswers: boolean;
};

const InteractivePromptWrapper: React.FC<InteractivePromptWrapperProps> = ({
   checkAnswers,
   evaluations,
   isFullScreen,
   isSubmitting,
   postDiscussionBoardResponse,
   currentPrompt,
   readyToRecord,
   replayVideoSegment,
   responses,
   saveResponse,
   setDisableSubmit,
   setInteractivePromptIdToDisplay,
   setIsPlaying,
   setResponse,
   showCheckAnswers,
   showMissed,
}) => {
   const showPrompt = currentPrompt !== null;
   const duration = 500;

   const onExit = (): void => {
      setInteractivePromptIdToDisplay(null);
      setIsPlaying(true);
   };

   const wrapWithTransition = (element: JSX.Element): JSX.Element => (
      <CSSTransition in={showPrompt} timeout={duration} classNames='fade' onExited={onExit}>
         {element}
      </CSSTransition>
   );

   const handleReplayVideoSegment = (): void => {
      replayVideoSegment();
   };

   const onContinueVideo = (): void => {
      setInteractivePromptIdToDisplay(null);
      setIsPlaying(true);
   };

   return (
      <>
         {wrapWithTransition(
            <div
               className={classNames('interactive-prompt-container', {
                  'full-screen': isFullScreen,
                  inline: !isFullScreen,
               })}
            >
               <div className='interactive-prompt'>
                  <div className='interactive-prompt-padding-bottom'>
                     <CompleterPrompt
                        prompt={currentPrompt}
                        evaluations={evaluations}
                        readyToRecord={readyToRecord}
                        responses={responses}
                        showMissed={showMissed}
                        postDiscussionBoardResponse={postDiscussionBoardResponse}
                        setResponse={setResponse}
                        saveResponse={saveResponse}
                        setDisableSubmit={setDisableSubmit}
                     />
                  </div>
               </div>
               <div className='interactive-buttons'>
                  <Button
                     line
                     onClick={handleReplayVideoSegment}
                     icon={<IconVideoControlPrevious aria-hidden />}
                  >
                     Watch Again
                  </Button>
                  {showCheckAnswers && (
                     <Button color='green' disabled={isSubmitting} onClick={checkAnswers}>
                        Check Answers
                     </Button>
                  )}
                  <Button
                     onClick={onContinueVideo}
                     iconPosition='right'
                     icon={<IconVideoControlNext aria-hidden />}
                  >
                     Continue Video
                  </Button>
               </div>
            </div>,
         )}
      </>
   );
};

export default InteractivePromptWrapper;
