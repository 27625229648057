// @ts-strict-ignore
import * as React from 'react';

import { randomDistinguishableCode } from '@helpers/RandomStringUtils';
import useStateWithReset from '@hooks/use-state-with-reset';
import IconCalendar1 from '@icons/nova-line/05-Time/calendar-1.svg';
import { ContentType } from '@models/Content';
import {
   AssignableModuleItem,
   AssignableModuleItemType,
   Assignment,
   CourseSection,
   ModuleItem,
} from '@models/Course';
import classnames from 'classnames';

import Switch from '@components/Common/Switch';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import InfoTooltip from '@components/InfoTooltip';
import AssignmentDatePicker from './AssignmentDatePicker';
import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';
import {
   getSecretCodeError,
   getTimeLimitError,
   MAX_SECRET_CODE_LENGTH,
   MIN_TIME_LIMIT,
} from './ModuleUtils';
import PrereqsSettings from './PrereqsSettings';

interface AvailabilityPanelProps {
   assignment: Assignment;
   activeItem: ModuleItem;
   courseId: number;
   moduleId: number;
   canEditModule: boolean;
   canEditSecretCode: boolean;
   courseEndDate: Date;
   courseStartDate: Date;
   itemType: AssignableModuleItemType;
   isOpen: boolean;
   sections: readonly CourseSection[];
   itemId: number;
   onAssignmentUpdate(update: Partial<Assignment>): void;
   toggleOpen(): void;
   setIsInvalid(isInvalid: boolean): void;
   editItem(update: Partial<AssignableModuleItem>): void;
}

const AvailabilityPanel: React.FC<AvailabilityPanelProps> = ({
   assignment,
   activeItem,
   courseId,
   moduleId,
   canEditModule,
   canEditSecretCode,
   courseEndDate,
   courseStartDate,
   isOpen,
   itemType,
   sections,
   itemId,
   onAssignmentUpdate,
   setIsInvalid,
   toggleOpen,
   editItem,
}) => {
   // Sync input values with assignment
   const [timeLimitInputValue, setTimeLimitInputValue] = useStateWithReset<number>(
      assignment.timeLimit,
      [itemId],
   );
   const [secretCodeInputValue, setSecretCodeInputValue] = useStateWithReset<string>(
      assignment.secretCode,
      [itemId],
   );

   const isActivity = itemType === ContentType.activity;
   const sectionDatesEnabled = !!Object.entries(assignment.sectionDates).length;
   const timeLimitEnabled = assignment.timeLimit !== -1;
   const showSecretCodeInput = !!assignment.secretCode;

   // Either update or reset back to valid number
   const handleTimeLimitBlur = (): void => {
      if (!timeLimitError) {
         onAssignmentUpdate({ timeLimit: timeLimitInputValue });
      } else {
         setTimeLimitInputValue(assignment.timeLimit);
      }
   };

   const handleSecretCodeBlur = (): void => {
      if (!secretCodeError) {
         onAssignmentUpdate({ secretCode: secretCodeInputValue });
      } else {
         setSecretCodeInputValue(assignment.secretCode);
      }
   };

   const handleSecretCodeInputValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSecretCodeInputValue(event.target.value);
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   const toggleSetDatesBySection = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked && sections.length) {
         const updatedSectionDates = Object.fromEntries(
            sections.map((section) => {
               const sectionEndDate = new Date(assignment.endDate);
               if (section.defaultDueTime) {
                  sectionEndDate.setHours(section.defaultDueTime.getHours());
                  sectionEndDate.setMinutes(section.defaultDueTime.getMinutes());
                  sectionEndDate.setSeconds(0);
               }
               return [section.id, { startDate: assignment.startDate, endDate: sectionEndDate }];
            }),
         );
         onAssignmentUpdate({ sectionDates: updatedSectionDates });
      } else {
         onAssignmentUpdate({ sectionDates: {} });
      }
   };

   const toggleAllowLateSubmission = (event: React.ChangeEvent<HTMLInputElement>): void => {
      onAssignmentUpdate({ allowLateSubmission: event.target.checked });
   };

   const toggleLockedAfterClosed = (event: React.ChangeEvent<HTMLInputElement>): void => {
      onAssignmentUpdate({ lockAfterClosed: event.target.checked });
   };

   const toggleTimeLimit = (event: React.ChangeEvent<HTMLInputElement>): void => {
      onAssignmentUpdate({ timeLimit: event.target.checked ? 60 : -1 });
   };

   const toggleSecretCode = (): void => {
      onAssignmentUpdate({
         secretCode: assignment.secretCode ? '' : randomDistinguishableCode(),
      });
   };

   const handleInvalidDate = (): void => {
      setIsInvalid(true);
   };

   const handleAssigmentDateUpdate = (startDate: Date, endDate: Date): void => {
      setIsInvalid(false);
      onAssignmentUpdate({ startDate, endDate });
   };

   const handleSectionDateUpdate =
      (sectionId: string | number) => (startDate: Date, endDate: Date) => {
         setIsInvalid(false);
         onAssignmentUpdate({
            sectionDates: {
               ...assignment.sectionDates,
               [sectionId]: { startDate, endDate },
            },
         });
      };

   const secretCodeError = getSecretCodeError(secretCodeInputValue);
   const timeLimitError = getTimeLimitError(timeLimitInputValue);

   return (
      <ModuleItemSettingsAccordion
         collapsible
         icon={<IconCalendar1 aria-hidden />}
         isOpen={isOpen}
         onToggle={toggleOpen}
         title='Availability'
         data-tour='availability-tab'
         data-test='availability-tab'
      >
         <AssignmentDatePicker
            courseEndDate={courseEndDate}
            courseStartDate={courseStartDate}
            disabled={!canEditModule}
            endDate={assignment.endDate}
            endDateTooltip='Students will not be able to complete the content after this date.'
            resetOnChange={[itemId]}
            onInvalidDate={handleInvalidDate}
            onValidDateChange={handleAssigmentDateUpdate}
            startDate={assignment.startDate}
            startDateTooltip='Students will not be able to access the content prior to this date.'
         />
         {sections.length > 0 && (
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <Switch
                     checked={sectionDatesEnabled}
                     disabled={!canEditModule || !assignment.endDate}
                     onChange={toggleSetDatesBySection}
                     title='Set dates by section'
                  />
               </div>
            </div>
         )}
         {sectionDatesEnabled &&
            sections.map((section) => (
               <div className='section-date' key={section.id}>
                  <div className='row'>
                     <div className='col-xs-6'>
                        <div className='title small'>{section.name}</div>
                     </div>
                  </div>
                  <AssignmentDatePicker
                     courseEndDate={courseEndDate}
                     courseStartDate={courseStartDate}
                     disabled={!canEditModule}
                     endDate={assignment.sectionDates[section.id].endDate}
                     endDateTooltip='Students in this section will not be able to complete the content after this date.'
                     resetOnChange={[itemId]}
                     onInvalidDate={handleInvalidDate}
                     onValidDateChange={handleSectionDateUpdate(section.id)}
                     startDate={assignment.sectionDates[section.id].startDate}
                     startDateTooltip='Students this section will not be able to access the content prior to this date.'
                  />
               </div>
            ))}
         {isActivity && (
            <>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <Switch
                        checked={assignment.allowLateSubmission}
                        disabled={!canEditModule}
                        onChange={toggleAllowLateSubmission}
                        title='Allow late submissions'
                        tooltip='Students can complete the activity after the end date but will receive a zero.'
                     />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <Switch
                        checked={assignment.lockAfterClosed}
                        disabled={!canEditModule}
                        onChange={toggleLockedAfterClosed}
                        title='Lock after close'
                        tooltip="Students will not be able to access the assignment after it's closed."
                     />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12 col-sm-6'>
                     <Switch
                        checked={timeLimitEnabled}
                        disabled={!canEditModule}
                        onChange={toggleTimeLimit}
                        title='Set time limit'
                        tooltip='Once started, students must complete the activity within the given number of minutes. Does not reset between attempts.'
                     />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <Switch
                        checked={!!assignment.secretCode}
                        disabled={!canEditModule}
                        onChange={toggleSecretCode}
                        title='Require secret code'
                        tooltip='Students will be required to enter the secret code to access the activity.'
                     />
                  </div>
               </div>
               <div className='row'>
                  {timeLimitEnabled && (
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title no-margin-top'>Time Limit (Min)</label>
                        <NumberInput
                           className={(value) => classnames({ error: value < MIN_TIME_LIMIT })}
                           disabled={!canEditModule}
                           integer
                           min={1}
                           name='timeLimit'
                           onBlur={handleTimeLimitBlur}
                           onFocus={handleFocus}
                           onValueChange={setTimeLimitInputValue}
                           value={timeLimitInputValue}
                        />
                        {timeLimitError && <p className='error'>{timeLimitError}</p>}
                     </div>
                  )}
                  {showSecretCodeInput && (
                     <div
                        className={classnames(
                           'col-xs-12 col-sm-6',
                           !timeLimitEnabled && 'col-sm-offset-6',
                        )}
                     >
                        <label className='field-title no-margin-top'>
                           Require Secret Code
                           <InfoTooltip>
                              Students will be required to enter the secret code to access the
                              activity.
                           </InfoTooltip>
                        </label>
                        <input
                           className={classnames({ error: !!secretCodeError })}
                           disabled={!canEditSecretCode}
                           maxLength={MAX_SECRET_CODE_LENGTH}
                           name='secretCode'
                           onBlur={handleSecretCodeBlur}
                           onChange={handleSecretCodeInputValueChange}
                           onFocus={handleFocus}
                           type='text'
                           value={secretCodeInputValue}
                        />
                        {secretCodeError && <p className='error'>{secretCodeError}</p>}
                     </div>
                  )}
               </div>
               {canEditModule && (
                  <PrereqsSettings
                     activeItem={activeItem}
                     canEditModule={canEditModule}
                     courseId={courseId}
                     moduleId={moduleId}
                     editItem={editItem}
                  />
               )}
            </>
         )}
      </ModuleItemSettingsAccordion>
   );
};

export default AvailabilityPanel;
