import * as _ from 'lodash';
import * as React from 'react';

import { formatDate } from '@helpers/FormatTime';
import { isInteger } from '@helpers/NumberUtils';
import { ActivityMode, ActivityStatus } from '@models/Activity';
import { Maybe } from '@models/Core';
import DateTime from '@services/DateTimeService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { ActivitySidebarProps } from './ActivitySidebar';

const ActivityDescription: React.FC<ActivitySidebarProps> = ({
   assignment,
   attempts,
   description,
   isClosed,
   isLate,
   isLocked,
   isSaving,
   isSubmitting,
   lastSubmittedOn,
   mode,
   modifiedOn,
   pointsPossible,
   relativeDue,
   score,
   startedOn,
}) => {
   const getStatus = (): ActivityStatus | ActivityMode => {
      if (isLocked) {
         return ActivityStatus.locked;
      } else if (assignment) {
         if (isLate) {
            return ActivityStatus.late;
         } else if (isClosed) {
            return ActivityStatus.closed;
         } else {
            return ActivityStatus.open;
         }
      } else if (mode === ActivityMode.preview) {
         return ActivityMode.preview;
      } else {
         return ActivityStatus.unassigned;
      }
   };

   const getEndDate = (): Maybe<Date> => {
      if (assignment && assignment?.timeLimit && assignment.timeLimit > 0 && startedOn) {
         return new Date(
            Math.min(
               assignment.endDate.getTime(),
               startedOn.getTime() + assignment.timeLimit * 60 * 1000,
            ),
         );
      }
      return assignment?.endDate;
   };

   const getModified = (): string => {
      if (isSaving) {
         return 'Saving...';
      }
      return modifiedOn ? formatDate(modifiedOn) : 'Never';
   };

   const getLastSubmitted = (): string => {
      if (isSubmitting) {
         return 'Submitting...';
      }
      return lastSubmittedOn ? formatDate(lastSubmittedOn) : 'Never';
   };

   const getDueDateBlock = (): Maybe<JSX.Element> => {
      const secondsRemaining = endDate
         ? (endDate.getTime() - DateTime.now().getTime()) / 1000
         : null;
      const pastDueDate = secondsRemaining !== null && secondsRemaining < 0;
      if (status === ActivityStatus.open || endDate) {
         return (
            <div
               className={classnames('row', {
                  'due-soon':
                     secondsRemaining !== null && secondsRemaining > 0 && secondsRemaining < 60,
               })}
            >
               <div className='col-xs-6'>
                  <label>{pastDueDate ? 'Due' : 'Due in'}</label>
               </div>
               {!!endDate && (
                  <div className='col-xs-6'>
                     <Tippy content={formatDate(endDate)} delay={[500, 0]}>
                        <label className='date'>{relativeDue}</label>
                     </Tippy>
                  </div>
               )}
            </div>
         );
      }
   };

   const getGradeBlock = () => {
      const shouldFormatAsInteger = isInteger(score) && isInteger(pointsPossible);
      const formattedTotalPoints = shouldFormatAsInteger
         ? score?.toString()
         : score?.toLocaleString('en-US', { minimumFractionDigits: 1 });
      const formattedPointsPossible = shouldFormatAsInteger
         ? pointsPossible?.toString()
         : pointsPossible?.toLocaleString('en-US', {
              minimumFractionDigits: 1,
           });
      if (_.isNumber(score) && _.isNumber(pointsPossible)) {
         return (
            <div className='row'>
               <div className='col-xs-6'>
                  <label>Grade</label>
               </div>
               <div className='col-xs-6'>
                  <label className='black'>
                     {formattedTotalPoints + '/' + formattedPointsPossible}
                  </label>
               </div>
            </div>
         );
      }
   };

   const getAttemptsRow = () => {
      const multipleAttempts =
         assignment?.gradeAutomatically &&
         assignment?.attemptsAllowed &&
         assignment?.attemptsAllowed > 1;
      if (multipleAttempts) {
         return (
            <div className='row'>
               <div className='col-xs-6'>
                  <label>Attempts</label>
               </div>
               <div className='col-xs-6'>
                  <label className='black'>{`${attempts}/${assignment?.attemptsAllowed}`}</label>
               </div>
            </div>
         );
      }
   };

   const endDate = getEndDate();
   const status = getStatus();

   return (
      <div className='activity-description'>
         <div className='title small light-gray margin-bottom-s'>Details</div>
         {description && <p>{description}</p>}
         <div className='activity-date'>
            <div className='row'>
               <div className='col-xs-6'>
                  <label>Status</label>
               </div>
               <div className='col-xs-6'>
                  <div
                     data-test={`activity-badge-${status}`}
                     className={`activity-${status} activity-badge`}
                  >
                     {status}
                  </div>
               </div>
            </div>
            {getDueDateBlock()}
            {status !== ActivityMode.preview && (
               <>
                  <div className='row'>
                     <div className='col-xs-6'>
                        <label>Last Updated</label>
                     </div>
                     <div className='col-xs-6'>
                        <label className='black'>{getModified()}</label>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-xs-6'>
                        <label>Last Submitted</label>
                     </div>
                     <div className='col-xs-6'>
                        <label className='black' data-test='last-submitted-date'>
                           {getLastSubmitted()}
                        </label>
                     </div>
                  </div>
                  {getAttemptsRow()}
               </>
            )}
            {getGradeBlock()}
         </div>
      </div>
   );
};

export default ActivityDescription;
