import { snakeCaseKeys } from '@helpers/ModifyKeys';
import AccountType from '@models/AccountType';
import { Maybe, MessageResponse } from '@models/Core';
import { PartialDeep } from 'type-fest';

import Constants from '../Constants';
import { ModuleItem, ModuleItemPrerequisite, ModuleItemType } from '@models/Course/ModuleItem';
import HttpService from './HttpService';

const {
   routes: {
      activities: { completeSubmission, viewAllSubmissions },
      content: { viewLesson, viewVocabSet, viewVideo },
      vocabSets: { overview: vocabSetOverview },
   },
} = Constants;

interface ModuleItemDetailsResponse {
   itemType: ModuleItemType;
   submissionId?: number;
   lessonId?: number;
   vocabSetId?: number;
   videoId?: number;
}

const createModuleItem = (
   courseId: number,
   moduleId: number,
   item: PartialDeep<ModuleItem>,
): Promise<ModuleItem> =>
   HttpService.postWithAuthToken<{ msg: string; moduleItem: ModuleItem }>(
      `/api/courses/${courseId}/modules/${moduleId}/items`,
      snakeCaseKeys(item),
   ).then((response) => response.data.moduleItem);

const updateModuleItems = (
   courseId: number,
   moduleId: number,
   items: readonly PartialDeep<ModuleItem>[],
): Promise<void> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/${moduleId}/items`,
      snakeCaseKeys({ items }),
   ).then();

const updateModuleItem = (
   courseId: number,
   moduleId: number,
   moduleItemId: number,
   item: PartialDeep<ModuleItem>,
): Promise<void> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}`,
      snakeCaseKeys(item),
   ).then();

const deleteModuleItem = (
   courseId: number,
   moduleId: number,
   moduleItemId: number,
): Promise<string> =>
   HttpService.deleteWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}`,
   ).then((response) => response.data.msg);

const getRedirectLink = (
   courseId: number,
   moduleItemId: number,
   accountType: AccountType,
): Promise<Maybe<string>> => {
   const isInstructor = accountType === AccountType.instructor;
   const isStudent = accountType === AccountType.student;
   if (!moduleItemId) {
      return Promise.resolve(null);
   }
   return HttpService.getWithAuthToken<ModuleItemDetailsResponse>(
      `/api/courses/${courseId}/module_items/${moduleItemId}/details`,
   ).then((response) => {
      const { itemType, submissionId, lessonId, vocabSetId, videoId } = response.data;
      if (itemType === ModuleItemType.activity) {
         if (isInstructor) {
            return viewAllSubmissions.replace(':moduleItemId', moduleItemId.toString());
         } else if (isStudent && submissionId) {
            return completeSubmission.replace(':submissionId', submissionId?.toString());
         }
      } else if (itemType === ModuleItemType.lesson && lessonId) {
         return viewLesson.replace(':lessonId', lessonId.toString());
      } else if (itemType === ModuleItemType.video && videoId) {
         return viewVideo.replace(':videoId', videoId?.toString());
      } else if (itemType === ModuleItemType.vocabSet) {
         if (isInstructor) {
            return vocabSetOverview.replace(':moduleItemId', moduleItemId.toString());
         } else if (isStudent && vocabSetId) {
            return viewVocabSet.replace(':vocabSetId', vocabSetId.toString());
         }
      }
      return null;
   });
};

const addPrereqToModuleItem = (
   courseId: number,
   moduleId: number,
   moduleItemId: number,
   prereqId: number,
): Promise<ModuleItemPrerequisite> =>
   HttpService.postWithAuthToken<{ prerequisite: ModuleItemPrerequisite }>(
      `/api/courses/${courseId}/modules/${moduleId}/items/${moduleItemId}/prerequisites/${prereqId}`,
   ).then((response) => response.data.prerequisite);

const deletePrereq = (courseId: number, prereqId: number): Promise<string> =>
   HttpService.deleteWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/prerequisites/${prereqId}`,
   ).then((response) => response.data.msg);

export default {
   createModuleItem,
   deleteModuleItem,
   updateModuleItem,
   updateModuleItems,
   addPrereqToModuleItem,
   deletePrereq,
   getRedirectLink,
};
