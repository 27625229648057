import * as React from 'react';

import IconOptionGrey from '@icons/general/icon-option-gray.svg';

import Button from '@components/Common/Button';

interface BreadcrumbsProps {
   /** If max items is exceeded, the number of items to show after the ellipsis */
   itemsAfterCollapse?: number;
   /** If max items is exceeded, the number of items to show before the ellipsis */
   itemsBeforeCollapse?: number;
   /** Set the maximum number of breadcrumbs to display. When there are more than the maximum number, only the first and last will be shown, with an ellipsis in between. */
   maxItems?: number;
   /** The items to be included inside the Breadcrumbs wrapper */
   children: readonly React.ReactElement[];
   /** Separator between breadcrumbs */
   separator?: React.ReactElement;
   /** A function to be called when you are in the collapsed view and click the ellipsis. */
   onExpand?(): void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
   children,
   itemsAfterCollapse = 1,
   itemsBeforeCollapse = 1,
   maxItems,
   separator = '>',
}) => {
   const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

   const toggleIsExpanded = (): void => setIsExpanded((prevIsExpanded) => !prevIsExpanded);

   const maxItemsExceeded = maxItems && children.length > maxItems;

   React.useEffect(() => {
      if (maxItemsExceeded) {
         setIsExpanded(false);
      }
   }, [maxItemsExceeded]);

   const itemsBefore = maxItemsExceeded ? children.slice(0, itemsBeforeCollapse) : [];
   const itemsAfter = maxItemsExceeded ? children.slice(itemsAfterCollapse * -1) : [];

   return (
      <div className='breadcrumbs'>
         {maxItemsExceeded && !isExpanded ? (
            <React.Fragment>
               {itemsBefore.map((child, index) => (
                  <React.Fragment key={index}>
                     {child}
                     <span className='separator'>{separator}</span>
                  </React.Fragment>
               ))}
               <Button textLink className='truncate' onClick={toggleIsExpanded}>
                  <IconOptionGrey />
               </Button>
               <span className='separator'>{separator}</span>
               {itemsAfter.map((child, index, arr) => (
                  <React.Fragment key={index}>
                     {child}
                     {index < arr.length - 1 && <span className='separator'>{separator}</span>}
                  </React.Fragment>
               ))}
            </React.Fragment>
         ) : (
            children.map((child, index, arr) => (
               <React.Fragment key={index}>
                  {child}
                  {index < arr.length - 1 && <span className='separator'>{separator}</span>}
               </React.Fragment>
            ))
         )}
      </div>
   );
};

export default Breadcrumbs;
