import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Loader from '@components/Loader';
import {
   ProficiencySkillsPlusOverall,
   StudentProficiencyReport,
   StudentProficiencySummaryRow,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';

import {
   getStudentProficiencySummaryDataFromRows,
   StudentProficiencySummaryBarchartData,
} from './Helper';
import StackedBarChart from './StackedBarChart';
import StatusTable from './StatusTable';

interface StudentProficiencySummaryProps {
   userId: number;
   courseId: number;
   setPageName?(reportName: string, courseName: string): void;
}

type FilterState = Record<ProficiencySkillsPlusOverall, boolean>;

const StudentProficiencySummary: React.FC<StudentProficiencySummaryProps> = (props) => {
   // Do not bother rendering until the props are defined. This is a product
   // of disabling strict null checking in TypeScript.
   if (!props.courseId || !props.userId) {
      return null;
   }

   const defaultFilterState: FilterState = {
      Listening: false,
      Overall: false,
      Reading: false,
      Speaking: false,
      Writing: false,
   };

   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [proficiencySummaryResult, setProficiencySummaryResult] = React.useState<
      StudentProficiencyReport | undefined
   >();
   const [filteredProficiencySummaryRows, setFilteredProficiencySummaryRows] = React.useState<
      StudentProficiencySummaryRow[]
   >([]);
   const [proficiencyBarChartData, setProficiencyBarChartData] = React.useState<
      StudentProficiencySummaryBarchartData[]
   >([]);
   const [filterState, setFilterState] = React.useState<FilterState>({
      ...defaultFilterState,
      Overall: true,
   });

   const setRowData = (proficiencySummary: StudentProficiencyReport): void => {
      setFilteredProficiencySummaryRows(proficiencySummary.studentSummary);
      setProficiencyBarChartData(
         getStudentProficiencySummaryDataFromRows(
            proficiencySummary.courseStartDate,
            proficiencySummary.courseEndDate,
            proficiencySummary.studentSummary,
         ),
      );
   };

   React.useEffect(() => {
      fetchCanDoStatements();
   }, [props.userId, props.courseId]);

   React.useEffect(() => {
      if (!proficiencySummaryResult) {
         return;
      }

      const selectedFilter = _.findKey(filterState, (x) => x);

      if (selectedFilter && selectedFilter !== 'Overall') {
         const filteredRows = proficiencySummaryResult.studentSummary.filter(
            (x) => x.skill === selectedFilter,
         );
         setRowData({ ...proficiencySummaryResult, studentSummary: filteredRows });
      } else {
         setRowData(proficiencySummaryResult);
      }
   }, [filterState]);

   const fetchCanDoStatements = (): void => {
      setIsFetching(true);

      ProficiencyReportService.getStudentSummary(props.courseId, props.userId).then((result) => {
         if (props.setPageName) {
            props.setPageName(result.reportName, result.courseName);
         }

         setProficiencySummaryResult(result);
         setRowData(result);
         setIsFetching(false);
      });
   };

   if (isFetching) {
      return <Loader />;
   }

   const toggleFilter = (filterName: ProficiencySkillsPlusOverall): void => {
      const newFilterState: FilterState = {
         ...defaultFilterState,
      };
      if (filterState[filterName]) {
         newFilterState['Overall'] = true;
      }
      newFilterState[filterName] = !filterState[filterName];
      setFilterState(newFilterState);
   };

   const isFilterSelected = (filterName: ProficiencySkillsPlusOverall) => filterState[filterName];

   return (
      <div className='student-proficiency-summary'>
         <div className='skill-filters'>
            <label className='field-title'>Skill</label>
            <Button onClick={() => toggleFilter('Overall')} line={!isFilterSelected('Overall')}>
               Overall
            </Button>
            <Button onClick={() => toggleFilter('Reading')} line={!isFilterSelected('Reading')}>
               Reading
            </Button>
            <Button onClick={() => toggleFilter('Writing')} line={!isFilterSelected('Writing')}>
               Writing
            </Button>
            <Button onClick={() => toggleFilter('Speaking')} line={!isFilterSelected('Speaking')}>
               Speaking
            </Button>
            <Button onClick={() => toggleFilter('Listening')} line={!isFilterSelected('Listening')}>
               Listening
            </Button>
         </div>
         <div className='student-proficiency-graph'>
            <StackedBarChart data={proficiencyBarChartData} />
         </div>
         <div className='card-title'>
            <div className='title'>Benchmark</div>
         </div>
         <div className='canDoStatementStatusTableWrapper'>
            <StatusTable
               courseId={props.courseId}
               studentProficiencySummaryRows={filteredProficiencySummaryRows}
               userId={props.userId}
            />
         </div>
      </div>
   );
};

export default StudentProficiencySummary;
