import * as _ from 'lodash';
import * as React from 'react';

import IconFileTasksChecked from '@icons/nova-line/88-Files-Tasks/file-tasks-checklist.svg';
import { IdName } from '@models/Core';
import { FileWithId, UploadingVideo, VideoFormModel } from '@models/Video';
import AssetService, { PresignedURLResponse } from '@services/AssetService';
import MediaTranscriptService from '@services/MediaTranscriptService';
import VideoService from '@services/VideoService';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Constants from '../../Constants';
import { SupportedSonixLanguageLookup, SupportedSonixLanguages } from '@models/MediaTranscript';
import Button from '@components/Common/Button';
import FileInputArea from '@components/Common/FileInputArea';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import BulkVideoEditModal, { BulkVideoEditModalForm } from './BulkEditVideoModal';
import BulkVideoForm from './BulkVideoForm';
import VideoLanguageSelect from './VideoLanguageSelect';

const DEBOUNCE_TIME = 3000;
type VideoSteps = 'selectLanguage' | 'dropFiles' | 'editVideos';

const BulkVideoUploader: React.FC = () => {
   const {
      routes: { contentLibrary },
   } = Constants;

   const [searchParams, setSearchParams] = useSearchParams();
   const videoIdSearchParams = searchParams.getAll('videoId');

   const [videos, setVideos] = React.useState<UploadingVideo[]>([]);
   const [language, setLanguage] = React.useState<SupportedSonixLanguages>();
   const [currentStep, setCurrentStep] = React.useState<VideoSteps>('selectLanguage');
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isVideoUploadingMap, setIsVideoUploadingMap] = React.useState<Record<string, boolean>>(
      {},
   );
   const [uploadProgressMap, setUploadProgressMap] = React.useState<Record<string, number>>({});
   const [selectedVideoIds, setSelectedVideoIds] = React.useState<readonly number[]>([]);
   const [showCheckboxes, setShowCheckboxes] = React.useState<boolean>(false);
   const [isBulkVideoEditModalOpen, setIsBulkVideoEditModalOpen] = React.useState<boolean>(false);

   const fetchVideos = React.useCallback(async () => {
      const videoResponse = await VideoService.getAll(videoIdSearchParams);
      setVideos(
         videoResponse.map((i) => ({
            ...i,
            speakers: i.speakers.map((j) => ({ id: j.id, name: j.name })),
         })),
      );
      setCurrentStep('editVideos');
   }, []);

   React.useEffect(() => {
      if (videoIdSearchParams.length > 0) {
         fetchVideos();
      }
   }, []);

   const selectAll = (): void => setSelectedVideoIds(videos.map((i) => i.id));
   const unselectAll = (): void => setSelectedVideoIds([]);

   const onProgress = (progress: number, id: string): void => {
      setUploadProgressMap((prev) => ({ ...prev, [id]: progress }));
   };

   const handleUpdate = (
      data: Pick<
         VideoFormModel,
         | 'duration'
         | 'id'
         | 'imageFilename'
         | 'imageUrl'
         | 'interviewQuestionId'
         | 'language'
         | 'level'
         | 'location'
         | 'name'
         | 'nativeLanguageTextTrack'
         | 'speakers'
         | 'tags'
         | 'targetLanguageTextTrack'
      >[],
   ) => {
      // Just grabbing the fields that are exposed in the form.
      // The text tracks should only be updated through the fetch now.
      const videoFormModels: Partial<VideoFormModel>[] = data.map((x) => ({
         duration: x.duration,
         id: x.id,
         imageFilename: x.imageFilename,
         imageUrl: x.imageUrl,
         interviewQuestionId: x.interviewQuestionId,
         language: x.language,
         level: x.level,
         location: x.location,
         name: x.name,
         nativeLanguageTextTrack: x.nativeLanguageTextTrack === null ? null : undefined,
         speakers: x.speakers,
         tags: x.tags,
         targetLanguageTextTrack: x.targetLanguageTextTrack === null ? null : undefined,
      }));
      VideoService.bulkUpdate(videoFormModels);
   };

   const debouncedUpdate = React.useCallback(_.debounce(handleUpdate, DEBOUNCE_TIME), []);

   const updateVideo = (video: Partial<VideoFormModel>, shouldCallServer = true): void => {
      setVideos((prevVideos) => {
         const prevVideoState = prevVideos.filter((x) => x.id === video.id)[0];
         const speakers: readonly IdName[] = video.speakers
            ? _.uniqBy([...video.speakers], (x) => x.id)
            : prevVideoState.speakers;

         const updatedVideo = {
            ...prevVideoState,
            ...video,
            speakers,
         };
         // Replace old object in array
         const index = prevVideos.indexOf(prevVideoState);
         prevVideos.splice(index, 1, updatedVideo);
         const updatedVideos = [...prevVideos];
         if (shouldCallServer) {
            debouncedUpdate(updatedVideos);
         }
         return updatedVideos;
      });
   };

   const uploadVideoFiles = async (files: FileWithId[]): Promise<void> => {
      if (language === undefined) throw new Error('Language must be defined.');

      setIsLoading(true);

      const presignedUrls = await AssetService.getBulkPresignedURLs(files.map((x) => x.file));

      const filesWithPresignedUrls: {
         fileWithId: FileWithId;
         presignedUrl: PresignedURLResponse;
      }[] = [];
      for (let i = 0; i < files.length; i++) {
         filesWithPresignedUrls.push({
            fileWithId: files[i],
            presignedUrl: presignedUrls[i],
         });
      }

      const bulkVideoResponse = await VideoService.bulkCreate(
         presignedUrls.map((x) => x.filename),
         language,
      );
      const videosWithExtraInfo = bulkVideoResponse.map((x) => {
         const fileWithPresignedUrl = filesWithPresignedUrls.find(
            (presignedUrlResponse) => presignedUrlResponse.presignedUrl.filename === x.fileName,
         );
         if (fileWithPresignedUrl === undefined) {
            throw new Error('Did not find file with presigned url');
         }
         const file = fileWithPresignedUrl.fileWithId.file;
         const localFileURL = URL.createObjectURL(file);
         return {
            ...x,
            localFileURL,
            url: fileWithPresignedUrl.presignedUrl.getUrl,
            fileId: fileWithPresignedUrl.fileWithId.id,
         };
      });
      const newParams = new URLSearchParams();
      videosWithExtraInfo.map((x) => newParams.append('videoId', x.id.toString()));
      setSearchParams(newParams);

      setVideos(videosWithExtraInfo);
      setCurrentStep('editVideos');
      setIsLoading(false);

      // Set intiial upload state
      const initialIsUploadingMap: Record<string, boolean> = {};
      const initialUploadProgressMap: Record<string, number> = {};
      for (let i = 0; i < filesWithPresignedUrls.length; i++) {
         const fileWithPresignedUrl = filesWithPresignedUrls[i];
         const fileWithId = fileWithPresignedUrl.fileWithId;

         initialIsUploadingMap[fileWithId.id] = true;
         initialUploadProgressMap[fileWithId.id] = 0;
      }
      setIsVideoUploadingMap(initialIsUploadingMap);
      setUploadProgressMap(initialUploadProgressMap);

      // Upload videos using presigned urls
      for (let i = 0; i < filesWithPresignedUrls.length; i++) {
         const { presignedUrl, fileWithId } = filesWithPresignedUrls[i];

         AssetService.uploadWithProgress(
            presignedUrl,
            fileWithId.file,
            fileWithId.id,
            onProgress,
         ).then(async () => {
            setIsVideoUploadingMap((prev) => ({ ...prev, [fileWithId.id]: false }));

            const videoToSubmit = videosWithExtraInfo.find((x) => x.fileId === fileWithId.id);

            if (videoToSubmit === undefined) {
               throw new Error('Did not find video to submit to Sonix');
            }

            const newVideo = await MediaTranscriptService.submitNewMedia({
               mediaId: videoToSubmit.id,
               mediaType: 'video',
            });

            updateVideo(newVideo);
         });
      }
   };

   const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.files) {
         return;
      }

      const fileList = event.target.files;

      const filesToUpload: FileWithId[] = [];
      for (let i = 0; i < fileList.length; i++) {
         const file = fileList.item(i);
         if (!file) {
            continue;
         }

         filesToUpload.push({ id: uuidv4(), file });
      }
      uploadVideoFiles(filesToUpload);
   };

   const toggleShowCheckboxes = (): void => {
      setShowCheckboxes((prevShowCheckboxes) => !prevShowCheckboxes);
   };

   const toggleVideoSelected = (videoId: number): void => {
      setSelectedVideoIds((prevSelectedVideoIds) => {
         const wasSelected = prevSelectedVideoIds.includes(videoId);

         if (wasSelected) {
            return prevSelectedVideoIds.filter((i) => i !== videoId);
         } else {
            return [...prevSelectedVideoIds, videoId];
         }
      });
   };

   const toggleSelectAll = (): void => {
      if (selectedVideoIds.length === videos.length) {
         unselectAll();
      } else {
         selectAll();
      }
   };

   const getIsVideoUploading = (fileId?: string): boolean => {
      if (fileId === undefined) {
         return false;
      }

      const foundKey = Object.keys(isVideoUploadingMap).find((x) => x === fileId);
      if (foundKey !== undefined) {
         return isVideoUploadingMap[fileId];
      } else {
         return false;
      }
   };

   const getUploadProgress = (fileId?: string): number => {
      if (fileId === undefined) {
         return 100;
      }

      const foundKey = Object.keys(uploadProgressMap).find((x) => x === fileId);
      if (foundKey !== undefined) {
         return uploadProgressMap[fileId];
      } else {
         return 100;
      }
   };

   if (isLoading) {
      return <Loader />;
   }

   const getSelectedVideos = (): readonly UploadingVideo[] =>
      videos.filter((x) => selectedVideoIds.includes(x.id));

   const onBulkEditModalSave = (videoForm: BulkVideoEditModalForm): void => {
      const selectedVideos = getSelectedVideos();
      const currentlySelectedVideoTags = _.uniq(
         selectedVideos.map((x) => x.tags).reduce((acc, cur) => [...acc, ...cur]),
      );
      const videoUpdate = selectedVideos.map((v) => {
         const tagsToRemove = _.difference(currentlySelectedVideoTags, videoForm.removeTags);
         const withTagsRemoved = _.difference(v.tags, tagsToRemove);
         const tags = _.uniq([...withTagsRemoved, ...videoForm.addTags]);
         const speakers = _.uniqBy([...v.speakers, ...videoForm.speakers], (x) => x.id);
         return {
            ...v,
            level: videoForm.level ? videoForm.level : v.level,
            location: videoForm.location ? videoForm.location : v.location,
            suggestion: videoForm.location?.suggestion,
            speakers,
            tags,
         };
      });

      videoUpdate.forEach((v) => updateVideo(v));
      setIsBulkVideoEditModalOpen(false);
   };

   const renderBody = (): React.ReactNode => {
      if (currentStep === 'selectLanguage')
         return (
            <VideoLanguageSelect
               language={language}
               setLanguage={setLanguage}
               onContinue={() => setCurrentStep('dropFiles')}
            />
         );

      if (currentStep === 'dropFiles') {
         return (
            <FileInputArea
               accept='video/mp4'
               multiple
               onChange={handleFileInputChange}
               onGoBack={() => setCurrentStep('selectLanguage')}
               titleComponent={
                  language ? (
                     <div className='title medium'>
                        Drop {SupportedSonixLanguageLookup[language]} files to upload
                     </div>
                  ) : undefined
               }
            />
         );
      }

      return videos.map((x) => (
         <div className='flex flex-center video-form-row-container' key={x.id}>
            {showCheckboxes && videos.length > 0 && (
               <input
                  checked={selectedVideoIds.includes(x.id)}
                  className='select-all'
                  style={{ marginLeft: '10px', padding: '7.5px 12px' }}
                  onChange={() => toggleVideoSelected(x.id)}
                  type='checkbox'
               />
            )}
            <BulkVideoForm
               isVideoUploading={getIsVideoUploading(x.fileId)}
               uploadProgress={getUploadProgress(x.fileId)}
               videoForm={x}
               setVideoForm={updateVideo}
            />
         </div>
      ));
   };

   if (isLoading || (videoIdSearchParams.length > 0 && videos.length === 0)) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>Bulk Video Uploader</DocumentTitle>
         {isBulkVideoEditModalOpen && (
            <BulkVideoEditModal
               onCancel={() => setIsBulkVideoEditModalOpen(false)}
               onSave={onBulkEditModalSave}
               videos={getSelectedVideos()}
            />
         )}

         <div className='content-main margin-right-m'>
            <div className='card no-padding'>
               <div className='card-title has-button'>
                  <div className='left-content'>
                     {showCheckboxes && videos.length > 0 && (
                        <input
                           checked={selectedVideoIds.length === videos.length}
                           className='select-all'
                           onChange={toggleSelectAll}
                           type='checkbox'
                        />
                     )}
                     <div className='title'>Bulk Video Uploader</div>
                  </div>
                  <div className='right-options-wrapper'>
                     {videos.length > 0 && (
                        <Button
                           icon={<IconFileTasksChecked />}
                           line
                           onClick={toggleShowCheckboxes}
                           tooltip='Show Checkboxes'
                        />
                     )}
                     {showCheckboxes && selectedVideoIds.length > 0 ? (
                        <Button color='purple' onClick={() => setIsBulkVideoEditModalOpen(true)}>
                           Bulk Edit Items ({selectedVideoIds.length})
                        </Button>
                     ) : (
                        <Link className='btn' to={contentLibrary.root}>
                           Done
                        </Link>
                     )}
                  </div>
               </div>
               {renderBody()}
            </div>
         </div>
      </>
   );
};

export default BulkVideoUploader;
