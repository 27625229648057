import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import { IdName } from '@models/Core';
import SpeakerService from '@services/SpeakerService';
import Button from '@components/Common/Button';
import { Column } from '@components/Common/Table';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';
import SpeakerEditorModal from './SpeakerEditorModal';

const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [],
};

const VideoSpeakerTable: React.FC = () => {
   const [isCreateModalOpen, setIsCreateModalOpen] = React.useState<boolean>(false);
   const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);
   const [selectedSpeakerId, setSelectedSpeakerId] = React.useState<number>();

   const openUpdateModal = (speakerId: number): void => {
      setSelectedSpeakerId(speakerId);
      setIsCreateModalOpen(true);
   };

   const renderId = (speaker: IdName): React.ReactNode => (
      <Button
         textLink
         className='black hover-blue'
         onClick={() => {
            openUpdateModal(speaker.id);
         }}
      >
         {speaker.id}
      </Button>
   );

   const columns: readonly Column<IdName>[] = [
      { id: 'id', header: 'Id', cell: renderId },
      { id: 'name', header: 'Name', cell: (i) => i.name },
   ];

   const onSave = (): void => {
      setShouldRefetch(true);
      setIsCreateModalOpen(false);
   };

   const onAddRowClick = (): void => {
      setSelectedSpeakerId(undefined);
      setIsCreateModalOpen(true);
   };

   const handleCancel = (): void => {
      setIsCreateModalOpen(false);
   };

   const refetchComplete = (): void => {
      setShouldRefetch(false);
   };

   return (
      <>
         {isCreateModalOpen && (
            <SpeakerEditorModal
               onSave={onSave}
               onCancel={handleCancel}
               incomingSpeakerId={selectedSpeakerId}
            />
         )}
         <FilterableTable<IdName>
            attributes={[]}
            columns={columns}
            defaultPredicate={defaultPredicate}
            defaultSortOrder={[]}
            pageTitle='Video Speakers'
            searchInputPlaceholder='Search Speakers'
            rowsLoader={SpeakerService.search}
            onAddRowClick={onAddRowClick}
            shouldRefetch={shouldRefetch}
            refetchComplete={refetchComplete}
         />
      </>
   );
};

export default VideoSpeakerTable;
