import * as _ from 'lodash';
import * as React from 'react';

import {
   ActivityMode,
   MultipleChoiceOption as MultipleChoiceOptionType,
   MultipleChoiceResponse,
} from '@models/Activity';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';
import MultipleChoiceOption from './MultipleChoiceOption';

interface MultipleChoiceProps extends CommonPromptProps {
   groupName: string;
   multiple: boolean;
   options: readonly MultipleChoiceOptionType<ActivityMode.complete>[];
   showMissed: boolean;
   showFeedback: boolean;
   shuffle: boolean;
   response: MultipleChoiceResponse;
   setResponse(response: MultipleChoiceResponse, callback?: () => void): void;
}

const MultipleChoicePrompt: React.FC<MultipleChoiceProps> = ({
   groupName,
   isClosed,
   multiple,
   options: unshuffledOptions,
   response,
   showMissed,
   showFeedback,
   shuffle,
   setResponse,
   saveResponse,
}) => {
   const [options, setOptions] = React.useState<
      readonly MultipleChoiceOptionType<ActivityMode.complete>[]
   >([]);

   const anyCorrect = response
      ? Object.values(response).some((entry) => entry && entry.anyCorrect)
      : false;

   React.useEffect(() => {
      setOptions(shuffle ? _.shuffle(unshuffledOptions) : unshuffledOptions);
   }, [unshuffledOptions]);

   const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, id: number): void => {
      let choices = { ...response };
      if (multiple) {
         if (id in choices) {
            delete choices[id];
         } else {
            choices[id] = {
               modified: true,
               correct: null,
            };
         }
      } else {
         choices = {
            [id]: {
               modified: true,
               correct: null,
            },
         };
      }
      setResponse(choices, saveResponse);
   };

   return (
      <>
         {options.map((i) => (
            <MultipleChoiceOption
               key={i.id}
               option={i}
               multiple={multiple}
               showMissed={showMissed}
               responseEntry={response?.[i.id] ?? null}
               disabled={isClosed}
               showFeedback={showFeedback}
               checked={response && i.id in response}
               onChange={(e) => handleChange(e, i.id)}
               groupName={groupName}
               anyCorrect={anyCorrect}
            />
         ))}
      </>
   );
};

export default MultipleChoicePrompt;
