import * as React from 'react';

import { formatSeconds } from '@helpers/FormatTime';
import useWindowSize from '@hooks/use-window-size';
import IconFullScreen from '@icons/nova-line/28-Resize&Move/expand-1.svg';
import IconExitFullScreen from '@icons/nova-line/28-Resize&Move/shrink-1.svg';
import IconVideoControlPause from '@icons/nova-solid/36-Videos/video-control-pause.svg';
import IconVideoControlPlay from '@icons/nova-solid/36-Videos/video-control-play.svg';
import {
   ActivityCompleterMode,
   ActivityPrompt,
   InteractiveVideoPromptTime,
} from '@models/Activity';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import { Maybe } from 'yup';

import { CompleterPromptProps } from '@components/Activity/Completer/CompleterPrompt';
import InteractivePromptWrapper from '@components/Activity/Completer/InteractivePromptWrapper';
import InteractiveVideoSlider from './InteractiveVideoSlider';

type InteractiveMediaContentProps = Omit<CompleterPromptProps, 'prompt'> & {
   checkAnswers(promptId: string | number): void;
   interactivePrompts: ActivityPrompt<ActivityCompleterMode>[];
   isSubmitting: boolean;
   promptTimes: readonly InteractiveVideoPromptTime<ActivityCompleterMode>[];
   url: string;
};

type Progress = {
   played: number;
   playedSeconds: number;
   loaded: number;
   loadedSeconds: number;
};

const PROGRESS_INTERVAL_IN_MILLISECONDS = 100; // .1 seconds
const TOOLTIP_DELAY_IN_MILLISECONDS = 500;

const InteractiveMediaContent: React.FC<InteractiveMediaContentProps> = (props) => {
   const playerRef = React.useRef<ReactPlayer>(null);

   const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);
   const [duration, setDuration] = React.useState<number>(0);
   const [progress, setProgress] = React.useState<number>(0);
   const [furthestProgressMade, setFurthestProgressMade] = React.useState<number>(0);
   const [shownPromptIds, setShownPromptIds] = React.useState<readonly number[]>([]);
   const [interactivePromptIdToDisplay, setInteractivePromptIdToDisplay] =
      React.useState<Maybe<number>>(null);
   const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

   const windowSize = useWindowSize();

   const getPrompt = (promptId: Maybe<number>): ActivityPrompt<ActivityCompleterMode> | undefined =>
      props.interactivePrompts.find((x) => x.id === promptId);
   const currentPrompt = getPrompt(interactivePromptIdToDisplay);
   const disableControls = currentPrompt !== undefined;

   const toggleIsPlaying = (): void => {
      if (!disableControls) setIsPlaying(!isPlaying);
   };

   const handleKeyPress = (event: KeyboardEvent): boolean => {
      if (!disableControls) {
         if (event.code === 'Space') {
            toggleIsPlaying();
            event.preventDefault(); // By default space bar scrolls down one page. This prevents that.
         }
      }

      if (event.code === 'Escape') {
         setIsFullScreen(false);
      }

      if (event.shiftKey) {
         switch (event.code) {
            case 'KeyF':
               setIsFullScreen(!isFullScreen);
         }
      }

      return true;
   };

   React.useEffect(() => {
      window.addEventListener('keydown', handleKeyPress);

      return () => {
         window.removeEventListener('keydown', handleKeyPress);
      };
   });

   const playVideo = (): void => {
      setInteractivePromptIdToDisplay(null);
      setIsPlaying(true);
   };

   const pauseVideo = (): void => {
      setIsPlaying(false);
   };

   const handleOnStart = (): void => {
      if (shownPromptIds.length !== 0) {
         setShownPromptIds([]);
      }
   };

   const handleEnded = (): void => setIsPlaying(false);

   const onSeek = (seconds: number): void => {
      // On seek is called if they hit the play button at the end of a video
      if (seconds < 0.5) {
         setShownPromptIds([]);
      }
   };

   const handleSliderChange = (seconds: number): void => {
      if (playerRef.current && seconds < furthestProgressMade) {
         const promptsAlreadyShownGivenNewVideoPosition = props.promptTimes.filter(
            (x) => x.promptTimeInSeconds <= seconds,
         );
         setShownPromptIds(promptsAlreadyShownGivenNewVideoPosition.map((x) => x.promptId));

         playerRef.current.seekTo(seconds, 'seconds');
         onSeek(seconds);
      }
   };

   const toggleFullScreen = (): void => {
      setIsFullScreen(!isFullScreen);
   };

   const onProgress = (value: Progress): void => {
      props.promptTimes.forEach((promptTime) => {
         const hasBeenShown = shownPromptIds.includes(promptTime.promptId);
         const shouldDisplayPrompt =
            !hasBeenShown && value.playedSeconds >= promptTime.promptTimeInSeconds;

         if (shouldDisplayPrompt) {
            setShownPromptIds([...shownPromptIds, promptTime.promptId]);
            setInteractivePromptIdToDisplay(promptTime.promptId);
            pauseVideo();
         }
      });

      setProgress(value.playedSeconds);

      if (furthestProgressMade < value.playedSeconds) {
         setFurthestProgressMade(value.playedSeconds);
      }
   };

   const getPlayerWidth = (): string => {
      if (isFullScreen && windowSize[0] > 2400) {
         return '90vw';
      }

      return '100%';
   };

   const replayVideoSegment = (): void => {
      const sortedPromptTimes = _.sortBy(props.promptTimes, (x) => x.promptTimeInSeconds);

      const currentPromptTimeIndex = sortedPromptTimes.findIndex(
         (x) => x.promptId === interactivePromptIdToDisplay,
      );

      const prevTime =
         currentPromptTimeIndex === 0
            ? 0
            : sortedPromptTimes[currentPromptTimeIndex - 1].promptTimeInSeconds;

      setInteractivePromptIdToDisplay(null);
      playerRef.current?.seekTo(prevTime);
      setShownPromptIds(shownPromptIds.filter((x) => x !== interactivePromptIdToDisplay));
      playVideo();
   };

   return (
      <>
         <div
            className={classnames({ 'background-overlay': isFullScreen })}
            onClick={toggleFullScreen}
         />

         <div
            className={classnames('interactive-media-player', {
               'interactive-video-full-screen': isFullScreen,
            })}
         >
            {currentPrompt && (
               <InteractivePromptWrapper
                  checkAnswers={() => {
                     props.checkAnswers(currentPrompt.id);
                  }}
                  currentPrompt={currentPrompt}
                  evaluations={props.evaluations}
                  isFullScreen={isFullScreen}
                  isSubmitting={props.isSubmitting}
                  key={currentPrompt.id}
                  postDiscussionBoardResponse={props.postDiscussionBoardResponse}
                  readyToRecord={props.readyToRecord}
                  replayVideoSegment={replayVideoSegment}
                  responses={props.responses}
                  saveResponse={props.saveResponse}
                  setDisableSubmit={props.setDisableSubmit}
                  setInteractivePromptIdToDisplay={setInteractivePromptIdToDisplay}
                  setIsPlaying={setIsPlaying}
                  setResponse={props.setResponse}
                  showCheckAnswers
                  showMissed={props.showMissed}
               />
            )}
            <div className='video-player' onClick={toggleIsPlaying}>
               <ReactPlayer
                  ref={playerRef}
                  width={getPlayerWidth()}
                  height={isFullScreen ? '90vh' : '100%'}
                  config={{
                     file: {
                        attributes: {
                           onContextMenu: (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) =>
                              e.preventDefault(),
                           controlsList: 'nodownload disable',
                           disablePictureInPicture: true,
                        },
                     },
                  }}
                  url={props.url}
                  onSeek={onSeek}
                  controls={false}
                  onProgress={onProgress}
                  onDuration={setDuration}
                  onStart={handleOnStart}
                  onEnded={handleEnded}
                  playing={isPlaying}
                  onPlay={playVideo}
                  progressInterval={PROGRESS_INTERVAL_IN_MILLISECONDS}
               />
            </div>
            <div className='interactive-video-controls'>
               <Tippy
                  content={isPlaying ? 'Pause (space)' : 'Play (space)'}
                  delay={TOOLTIP_DELAY_IN_MILLISECONDS}
               >
                  <div
                     className={classnames('play-icon', {
                        disabled: disableControls,
                     })}
                  >
                     {isPlaying ? (
                        <IconVideoControlPause onClick={pauseVideo} />
                     ) : (
                        <IconVideoControlPlay onClick={playVideo} />
                     )}
                  </div>
               </Tippy>
               <div className='slider-container'>
                  <InteractiveVideoSlider
                     disabled={disableControls}
                     duration={duration}
                     furthestProgressMade={furthestProgressMade}
                     progress={progress}
                     onUpdate={handleSliderChange}
                     ticksInSeconds={props.promptTimes.map((x) => x.promptTimeInSeconds)}
                  />
               </div>
               <div className='video-time-wrapper'>
                  <time>{formatSeconds(progress)}</time>
                  <span className='divider'>/</span>
                  <time>{formatSeconds(duration)}</time>
               </div>
               <Tippy
                  content={isFullScreen ? 'Exit full screen (f)' : 'Full screen (f)'}
                  delay={TOOLTIP_DELAY_IN_MILLISECONDS}
               >
                  <div className={'full-screen-toggle'}>
                     {isFullScreen ? (
                        <IconExitFullScreen onClick={toggleFullScreen} />
                     ) : (
                        <IconFullScreen onClick={toggleFullScreen} />
                     )}
                  </div>
               </Tippy>
            </div>
         </div>
      </>
   );
};

export default React.memo(InteractiveMediaContent);
