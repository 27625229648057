// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import autobind from '@helpers/autobind';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import classnames from 'classnames';
import AutosizeInput from 'react-18-input-autosize';

import Constants from '../../../Constants';

interface TagProps extends React.HTMLProps<HTMLSpanElement> {
   key?: string | number;
   tag?: string;
   classNameRemove?: string;
   getTagDisplayValue?(tag: string): string;
   onRemove?(key: number | string): void;
}

interface TagsInputProps {
   /**
    * Specify the wrapper className
    * @default react-tagsinput
    */
   className?: string;
   /**
    * Specify the class to add to the wrapper when the component is focused.
    * @default react-tagsinput-focused
    */
   focusedClassName?: string;
   /**
    * Array of keys that add a tag
    * @default [Tab,Enter]
    */
   addKeys?: readonly string[];
   /**
    * Add a tag if input blurs
    * @default false
    */
   addOnBlur?: boolean;
   /**
    * Add tags if HTML5 paste on input
    * @default false
    */
   addOnPaste?: boolean;
   /** String set to a value on the input */
   currentValue?: string;
   /**
    * Similar to current value but needed for controlling the input box
    * Only useful if used together with onChangeInput
    */
   inputValue?: string;
   /** Props passed down to input. */
   inputProps?: React.HTMLProps<HTMLInputElement>;
   /**
    * Array of keys that remove a tag
    * @default [Backspace]
    */
   removeKeys?: readonly string[];
   /** Props passed down to every tag component */
   tagProps?: TagProps;
   /**
    * Only allow unique tags
    * @default false
    */
   onlyUnique?: boolean;
   /** An array of tags. */
   value: readonly string[];
   /** Maximum number of tags allowed */
   maxTags?: number;
   /** Allow only tags that pass this regex to be added */
   validationRegex?: RegExp;
   /** Passes the disabled prop to renderInput and renderTag */
   disabled?: boolean;
   /**
    * The tags' property to be used when displaying/adding one
    * @default null - causes the tags to be an array of strings.
    */
   tagDisplayProp?: string;
   /**
    * Boolean to prevent the default submit event when adding an 'empty' tag.
    * @default true
    */
   preventSubmit?: boolean;
   /**
    * Callback when tags change
    * @param {readonly string[]} tags - new tag array
    * @param {readonly string[]} changed - array of changed tags
    * @param {readonly number[]} changedIndexes - array of changed indexes
    */
   onChange(
      tags: readonly string[],
      changed: readonly string[],
      changedIndexes: readonly number[],
   ): void;
   /**
    * Callback from the input box
    * @param {string} value - content of the input box
    */
   onChangeInput?(value: string): void;
   /** Callback when tags are rejected through validationRegex */
   onValidationReject?(rejectedTags: readonly string[]): void;
   /** Function that splits pasted text */
   pasteSplit?(data: string): readonly string[];
   /** Allow only tags that pass this validate function */
   validate?(tag: string): boolean;
}

interface TagsInputState {
   isFocused: boolean;
   tag: string;
}

class TagsInput extends React.Component<TagsInputProps, TagsInputState> {
   static defaultProps = {
      addKeys: [Constants.keys.enter, Constants.keys.tab],
      addOnBlur: false,
      addOnPaste: false,
      className: '',
      disabled: false,
      focusedClassName: 'focused',
      inputProps: {},
      maxTags: -1,
      onlyUnique: false,
      preventSubmit: true,
      removeKeys: [Constants.keys.backspace],
      tagDisplayProp: null,
      tagProps: { className: 'tag', classNameRemove: 'remove' },
      validationRegex: /.*/,
      pasteSplit: (data) => data.split(' ').map((d) => d.trim()),
      validate: () => true,
   };
   input: HTMLInputElement;
   div: HTMLDivElement;

   constructor(props: TagsInputProps) {
      super(props);
      autobind(this);
      this.state = {
         isFocused: false,
         tag: '',
      };
   }

   componentDidMount(): void {
      if (this.hasControlledInput()) {
         return;
      }
      this.setState({
         tag: this.inputValue(this.props),
      });
   }

   componentDidUpdate(prevProps: TagsInputProps): void {
      if (
         !this.hasControlledInput() &&
         this.inputValue(prevProps) !== this.inputValue(this.props)
      ) {
         this.setState({ tag: this.inputValue(this.props) });
      }
   }

   _getTagDisplayValue(tag): string {
      const { tagDisplayProp } = this.props;
      if (tagDisplayProp) {
         return tag[tagDisplayProp];
      }
      return tag;
   }

   _makeTag(tag: string): any {
      const { tagDisplayProp } = this.props;
      if (tagDisplayProp) {
         return {
            [tagDisplayProp]: tag,
         };
      }
      return tag;
   }

   _removeTag(index: number): void {
      const value = this.props.value.concat([]);
      if (index > -1 && index < value.length) {
         const changed = value.splice(index, 1);
         this.props.onChange(value, changed, [index]);
      }
   }

   _clearInput(): void {
      if (this.hasControlledInput()) {
         this.props.onChangeInput('');
      } else {
         this.setState({ tag: '' });
      }
   }

   _tag(): string {
      if (this.hasControlledInput()) {
         return this.props.inputValue;
      }
      return this.state.tag;
   }

   _addTags(tags: readonly string[]): boolean {
      /* eslint-disable no-param-reassign */
      const { onChange, onValidationReject, onlyUnique, maxTags, value } = this.props;
      if (onlyUnique) {
         tags = [...new Set(tags)];
         tags = tags.filter((tag) =>
            value.every(
               (currentTag) =>
                  this._getTagDisplayValue(currentTag) !== this._getTagDisplayValue(tag),
            ),
         );
      }

      const rejectedTags = tags.filter((tag) => !this._validate(this._getTagDisplayValue(tag)));
      tags = tags.filter((tag) => this._validate(this._getTagDisplayValue(tag)));
      tags = tags.filter((tag) => {
         const tagDisplayValue = this._getTagDisplayValue(tag);
         if (typeof tagDisplayValue.trim === 'function') {
            return tagDisplayValue.trim().length > 0;
         } else {
            return tagDisplayValue;
         }
      });

      if (maxTags >= 0) {
         const remainingLimit = Math.max(maxTags - value.length, 0);
         tags = tags.slice(0, remainingLimit);
      }

      if (rejectedTags.length > 0) {
         onValidationReject?.(rejectedTags);
      }

      if (tags.length > 0) {
         const newValue = value.concat(tags);
         const indexes = [];
         for (let i = 0; i < tags.length; i++) {
            indexes.push(value.length + i);
         }
         onChange(newValue, tags, indexes);
         this._clearInput();
         return true;
      }

      if (rejectedTags.length > 0) {
         return false;
      }

      this._clearInput();
      return false;
      /* eslint-enable no-param-reassign */
   }

   _validate(tag: string): boolean {
      const { validate, validationRegex } = this.props;
      return validate(tag) && validationRegex.test(tag);
   }

   _shouldPreventDefaultEventOnAdd(added: boolean, empty: boolean, key: string): boolean {
      if (added) {
         return true;
      }

      if (key === Constants.keys.enter) {
         return this.props.preventSubmit || (!this.props.preventSubmit && !empty);
      }

      return false;
   }

   focus(): void {
      this.input?.focus?.();
      this.handleOnFocus();
   }

   blur(): void {
      this.input?.blur?.();
      this.handleOnBlur();
   }

   accept(): boolean {
      let tag = this._tag();
      if (tag !== '') {
         tag = this._makeTag(tag);
         return this._addTags([tag]);
      }
      return false;
   }

   addTag(tag: string): boolean {
      return this._addTags([tag]);
   }

   clearInput(): void {
      this._clearInput();
   }

   handlePaste(e: React.ClipboardEvent): void {
      const { addOnPaste, pasteSplit } = this.props;

      if (!addOnPaste) {
         return;
      }

      e.preventDefault();
      let data = '';

      if (window.clipboardData) {
         data = window.clipboardData.getData('Text');
      } else if (e.clipboardData) {
         data = e.clipboardData.getData('text/plain');
      }

      const tags = pasteSplit(data).map((tag) => this._makeTag(tag));
      this._addTags(tags);
   }

   handleKeyDown(event: React.KeyboardEvent): void {
      if (event.defaultPrevented) {
         return;
      }

      const { value, removeKeys, addKeys } = this.props;
      const tag = this._tag();
      const empty = tag === '';
      const add = addKeys.indexOf(event.key) !== -1;
      const remove = removeKeys.indexOf(event.key) !== -1;

      if (add) {
         const added = this.accept();
         if (this._shouldPreventDefaultEventOnAdd(added, empty, event.key)) {
            event.preventDefault();
         }
      }

      if (remove && value.length > 0 && empty) {
         event.preventDefault();
         this._removeTag(value.length - 1);
      }
   }

   handleClick(e: React.MouseEvent): void {
      if (e.target === this.div) {
         this.focus();
      }
   }

   handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
      const {
         onChangeInput,
         inputProps: { onChange },
      } = this.props;
      const tag = event.target.value;

      onChange?.(event);

      if (this.hasControlledInput()) {
         onChangeInput(tag);
      } else {
         this.setState({ tag });
      }
   }

   handleOnFocus(event?: React.FocusEvent<HTMLInputElement>): void {
      const { onFocus } = this.props.inputProps;
      if (event === null) {
         return;
      }
      onFocus?.(event);
      this.setState({ isFocused: true });
   }

   handleOnBlur(event?: React.FocusEvent<HTMLInputElement>): void {
      const { onBlur } = this.props.inputProps;

      this.setState({ isFocused: false });

      if (event === null) {
         return;
      }

      onBlur?.(event);

      if (this.props.addOnBlur) {
         const tag = this._makeTag(event.target.value);
         this._addTags([tag]);
      }
   }

   handleRemove(tag): void {
      this._removeTag(tag);
   }

   inputProps(): React.HTMLProps<HTMLInputElement> {
      const { onChange, onFocus, onBlur, ...otherInputProps } = this.props.inputProps;

      const props = {
         placeholder: 'Add a tag',
         ...otherInputProps,
      };

      if (this.props.disabled) {
         props.disabled = true;
      }

      return props;
   }

   inputValue(props): string {
      return props.currentValue || props.inputValue || '';
   }

   hasControlledInput(): boolean {
      const { inputValue, onChangeInput } = this.props;

      return typeof onChangeInput === 'function' && typeof inputValue === 'string';
   }

   renderLayout(tagComponents, inputComponent): React.ReactNode {
      return (
         <span>
            {tagComponents}
            {inputComponent}
         </span>
      );
   }

   renderTag(props: TagProps): React.ReactNode {
      const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
      return (
         <span key={key} {...other}>
            {getTagDisplayValue(tag)}
            {!disabled && (
               <span className={classNameRemove} onClick={() => onRemove(key)}>
                  <IconClose />
               </span>
            )}
         </span>
      );
   }

   renderInput({ addTag, ...props }): React.ReactNode {
      const { onChange, value, ...other } = props;
      return <AutosizeInput type='text' onChange={onChange} value={value} {...other} />;
   }

   render(): React.ReactNode {
      const { className: propsClassName, disabled, focusedClassName, tagProps, value } = this.props;

      const { isFocused } = this.state;

      const className = classnames(propsClassName, 'tags-input', {
         [focusedClassName]: isFocused,
      });

      const tagComponents = value.map((tag, index) =>
         this.renderTag({
            key: index,
            tag,
            onRemove: this.handleRemove,
            disabled,
            getTagDisplayValue: this._getTagDisplayValue,
            ...tagProps,
         }),
      );

      const inputComponent = this.renderInput({
         ref: (ref) => (this.input = ref),
         value: this._tag(),
         onPaste: this.handlePaste,
         onKeyDown: this.handleKeyDown,
         onChange: this.handleChange,
         onFocus: this.handleOnFocus,
         onBlur: this.handleOnBlur,
         addTag: this.addTag,
         ...this.inputProps(),
      });

      return (
         <div ref={(ref) => (this.div = ref)} onClick={this.handleClick} className={className}>
            {this.renderLayout(tagComponents, inputComponent)}
         </div>
      );
   }
}

export default TagsInput;
