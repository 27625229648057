import * as React from 'react';

import IconInterfaceInformation from '@icons/nova-line/22-Interface-Feedback/interface-information.svg';
import IconFolderNetwork from '@icons/nova-line/86-Files-Folders/folder-network.svg';
import Appearance from '@models/Appearance';
import ContentItemProfile from '@models/ContentItemProfile';
import { Maybe } from '@models/Core';
import { Module } from '@models/Course';
import HttpService from '@services/HttpService';
import ModuleItemService from '@services/ModuleItemService';

import { AppStateContext } from '../../AppState';
import EmptyState from '@components/Core/EmptyState/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import { ToastActions } from '@components/Core/Toasts';
import ModuleSelector from './ModuleSelector';

interface GetContentCourseInstancesResponse {
   instances: readonly { courseId: number; moduleId: number; moduleItemId: number }[];
}

export interface AddContentToCourseModalProps {
   contentItemProfile: ContentItemProfile;
   onClose(): void;
}

const AddContentToCourseModal: React.FC<AddContentToCourseModalProps> = ({
   contentItemProfile,
   onClose,
}) => {
   const { currentCourses, dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const [selectedModule, setSelectedModule] = React.useState<Maybe<Module>>(null);
   const [disabledModuleIds, setDisabledModuleIds] = React.useState<readonly number[]>([]);
   const [isAddingToCourse, setIsAddingToCourse] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (contentItemProfile?.itemId) {
         HttpService.getWithAuthToken<GetContentCourseInstancesResponse>(
            `/api/content/${contentItemProfile.itemId}/course_instances`,
         ).then((response) => {
            const { instances } = response.data;
            setDisabledModuleIds(instances.map((i) => i.moduleId));
         });
      }
   }, [contentItemProfile?.itemId]);

   const isDisabled = !selectedModule?.id || currentCourses.length === 0;

   const handleModuleSelect = (module: Module): void => {
      setSelectedModule(module);
   };

   const handleAddToCourseClick = (): void => {
      setIsAddingToCourse(true);
      const course = currentCourses?.find((i) => i.id === selectedModule?.courseId);
      if (!course || !selectedModule) {
         return;
      }
      ModuleItemService.createModuleItem(
         selectedModule.courseId,
         selectedModule.id,
         contentItemProfile,
      ).then(() => {
         setIsAddingToCourse(false);
         dispatchToast({
            action: ToastActions.CONTENT_ADDED_TO_MODULE,
            data: {
               contentName: contentItemProfile.itemName,
               courseName: course.name,
               courseId: course.id,
               moduleName: selectedModule.name,
               moduleId: selectedModule.id,
            },
         });
         onClose();
      });
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading={`Add '${contentItemProfile.itemName}' to Course`}
         bodyClassName='add-content-to-course-modal'
         footerClassName='card-footer'
         onClose={onClose}
         actions={[
            {
               text: 'Add to Course',
               onClick: handleAddToCourseClick,
               loading: isAddingToCourse,
               disabled: isDisabled,
            },
            { text: 'Close', onClick: onClose },
         ]}
         width='medium'
      >
         {currentCourses.length ? (
            <>
               <div className='row'>
                  <div className='col-xs-12'>
                     <div className='directions'>
                        <IconInterfaceInformation />
                        <span>Select a course and module to add the content to.</span>
                     </div>
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-12'>
                     <ModuleSelector
                        courses={currentCourses}
                        selectedModuleId={selectedModule?.id ?? null}
                        disabledModuleIds={disabledModuleIds}
                        onSelectModule={handleModuleSelect}
                     />
                  </div>
               </div>
            </>
         ) : (
            <EmptyState
               icon={<IconFolderNetwork aria-hidden />}
               heading='No courses available'
               description='Create a new course and module in order to add content.'
            />
         )}
      </ModalDialog>
   );
};

export default AddContentToCourseModal;
