import * as React from 'react';

import LMSName from '@models/LMSName';

import Button, { ButtonProps } from '@components/Common/Button';
import { lmsDisplayName } from './index';
import LMSIcon from './LMSIcon';

interface LMSButtonProps extends ButtonProps {
   lmsName: LMSName;
   height?: string;
   width?: string;
}

const LMSButton: React.FC<LMSButtonProps> = ({
   children,
   line = true,
   lmsName,
   height = '20',
   width = '20',
   ...buttonProps
}) => (
   <Button
      line={line}
      {...buttonProps}
      icon={<LMSIcon line height={height} width={width} lmsName={lmsName} />}
   >
      {children ? children : lmsDisplayName[lmsName]}
   </Button>
);

export default LMSButton;
