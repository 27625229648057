import {
   BookstoreCodeActivation,
   CourseLicense,
   License,
   LicenseType,
   Payment,
   PaymentType,
   ProductLicense,
   StripePayment,
} from './Models';

const isProductLicense = (license: License): license is ProductLicense =>
   license.type === LicenseType.product;

const isCourseLicense = (license: License): license is CourseLicense =>
   license.type === LicenseType.course;

const isBookstoreCodeActivation = (payment: Payment): payment is BookstoreCodeActivation =>
   payment.type === PaymentType.bookstoreCode;

const isStripePayment = (payment: Payment): payment is StripePayment =>
   payment.type === PaymentType.stripe;

export { isProductLicense, isCourseLicense, isBookstoreCodeActivation, isStripePayment };
