import * as _ from 'lodash';

import tinymce from 'tinymce';

import {
   createCallout,
   createConversation,
   createExamples,
} from '@components/Core/Editor/renderers';
import { Callout } from '@components/Core/Editor/types/Callout';
import { Message, MessageDirection } from '@components/Core/Editor/types/Conversation';
import { Example, ExampleLayout } from '@components/Core/Editor/types/Example';
import { convertStringToNode, convertStringToNodes } from '@components/Core/Editor/utils/node';
import { splitOnFirstChar } from '@components/Core/Editor/utils/string';

tinymce.PluginManager.add('transform', (editor) => {
   const calloutList: readonly Callout[] = editor.options.get('callout_list');

   const singleNodeSelection = () => {
      const str = editor.selection.getContent();
      const selection = convertStringToNodes(str);
      return Array.from(selection).filter((i) => editor.dom.isBlock(i)).length <= 1;
   };

   const multiNodeSelection = (selection: NodeList) =>
      Array.from(selection).filter((i) => editor.dom.isBlock(i)).length > 1;

   const sameElementType = (selection: NodeList) => {
      // Filter out non-element nodes
      const elements = Array.from(selection).filter(
         (node) => node.nodeType === Node.ELEMENT_NODE,
      ) as Element[];

      // If there are non-element nodes in the original selection, return false
      if (elements.length !== selection.length) return false;

      const elementTypes = new Set(elements.map((el) => el.tagName));
      return elementTypes.size === 1;
   };

   const getMessagesFromSelection = (selection: NodeList): undefined | readonly Message[] => {
      if (multiNodeSelection(selection) && sameElementType(selection)) {
         const innerTexts = Array.from(selection, (el) => el.textContent).filter((i) =>
            _.isString(i),
         );
         return innerTexts.map((i, index) => {
            const [person, message] = i?.includes(': ') ? splitOnFirstChar(i, ':') : ['Person', i];
            return {
               person: person.trim(),
               message: message?.trim() || '',
               direction: index % 2 ? MessageDirection.sent : MessageDirection.received,
            };
         });
      }
   };

   const transformSelectionToTable = (selection: NodeList, columns = 2) => {
      if (multiNodeSelection(selection) && sameElementType(selection) && columns >= 1) {
         const innerTexts = Array.from(selection, (el) => el.textContent?.trim());
         const chunks = _.chunk(innerTexts, columns);
         const firstChunk = chunks.at(0);
         if (!firstChunk) {
            return;
         }
         const columnWidth = _.round(100 / columns, 3);
         const tableHtml = `
         <table class="table" style="width: 100%;">
          <colgroup>
            ${`<col style="width: ${columnWidth}%;">`.repeat(columns)}
          </colgroup>
          <thead>
            <tr>
               ${Array.from({ length: columns })
                  .map((i, j) => `<td>${firstChunk.at(j)}</td>`)
                  .join('')}
            </tr>
          </thead>
          <tbody>
            ${chunks
               .splice(1)
               .map((row) => `<tr>${row.map((cell) => `<td>${cell ?? ''}</td>`).join('')}</tr>`)
               .join('')}
          </tr>
          </tbody>
        </table>`;
         editor.selection.setContent(tableHtml);
      }
   };

   const transformSelectionToExamples = (selection: NodeList) => {
      if (multiNodeSelection(selection) && sameElementType(selection)) {
         const innerTexts = Array.from(selection, (el) => el.textContent);
         const examples: readonly Example[] = _.chunk(innerTexts, 2).map(([a, b]) => ({
            imgSrc: '',
            primaryText: a || '',
            secondaryText: b || '',
         }));
         editor.selection.setContent(
            createExamples({
               layout: ExampleLayout.vertical,
               examples,
               language: null,
            }),
         );
      }
   };

   const transformSelectionToConversation = () => {
      const str = editor.selection.getContent();
      const selection = convertStringToNodes(str);
      const messages = getMessagesFromSelection(selection);
      if (messages) {
         editor.selection.setContent(createConversation(messages));
      }
   };

   const transformSelectedBlockNode = (value: string) => {
      const node = editor.selection.getNode();
      editor.dom.rename(node, value);
   };

   const transformNodeToCallout = (callout: Callout) => {
      const node = editor.selection.getNode();
      const calloutNode = convertStringToNode(createCallout(callout, node.outerHTML));
      if (calloutNode) {
         node.replaceWith(calloutNode);
      }
   };

   editor.ui.registry.addNestedMenuItem('transform-options', {
      text: 'Transform',
      icon: 'reload',
      getSubmenuItems: () => {
         const str = editor.selection.getContent();
         const selection = convertStringToNodes(str);
         const singleNodeOptions = [
            {
               text: 'Text',
               icon: 'custom-text',
               type: 'menuitem' as const,
               onAction: () => transformSelectedBlockNode('p'),
            },
            {
               text: 'Heading 1',
               icon: 'heading-1',
               type: 'menuitem' as const,
               onAction: () => transformSelectedBlockNode('h1'),
            },
            {
               text: 'Heading 2',
               icon: 'heading-2',
               type: 'menuitem' as const,
               onAction: () => transformSelectedBlockNode('h2'),
            },
            {
               text: 'Heading 3',
               icon: 'heading-3',
               type: 'menuitem' as const,
               onAction: () => transformSelectedBlockNode('h3'),
            },
         ];
         const multiNodeOptions = [
            {
               text: 'Table',
               icon: 'grid-line',
               type: 'nestedmenuitem' as const,
               getSubmenuItems: () => [
                  {
                     text: '1 Column',
                     icon: 'grid-line',
                     type: 'menuitem' as const,
                     onAction: () => transformSelectionToTable(selection, 1),
                  },
                  {
                     text: '2 Columns',
                     icon: 'grid-line',
                     type: 'menuitem' as const,
                     onAction: () => transformSelectionToTable(selection, 2),
                  },
                  {
                     text: '3 Columns',
                     icon: 'grid-line',
                     type: 'menuitem' as const,
                     onAction: () => transformSelectionToTable(selection, 3),
                  },
                  {
                     text: '4 Columns',
                     icon: 'grid-line',
                     type: 'menuitem' as const,
                     onAction: () => transformSelectionToTable(selection, 4),
                  },
               ],
            },
            {
               text: 'Examples',
               icon: 'content-view-list-line',
               type: 'menuitem' as const,
               onAction: () => transformSelectionToExamples(selection),
            },
            {
               text: 'Conversation',
               icon: 'user-chat-1-line',
               type: 'menuitem' as const,
               onAction: () => transformSelectionToConversation(),
            },
         ];

         const calloutOptions = calloutList.map((callout) => ({
            text: callout.name,
            icon: callout.icon,
            type: 'menuitem' as const,
            onAction: () => transformNodeToCallout(callout),
         }));

         const isSingleNodeSelection = singleNodeSelection();
         const isMultiNodeSelection = multiNodeSelection(selection);

         return [
            ...calloutOptions,
            ...(isSingleNodeSelection ? singleNodeOptions : []),
            ...(isMultiNodeSelection ? multiNodeOptions : []),
         ];
      },
   });

   editor.ui.registry.addContextMenu('transform', {
      update: (element) => {
         if (
            ['IMG', 'AUDIO', 'VIDEO'].includes(element.tagName) ||
            element.closest('.callout') || // Use style option to change between callouts
            element.closest('.conversation') || // Disable transform for conversations
            element.closest('.example-container') || // Disable transform for examples
            editor.dom.isEmpty(editor.selection.getNode())
         ) {
            return '';
         }
         return 'transform-options';
      },
   });

   return {
      getMetadata: () => ({
         name: 'Transform',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
