import moment from 'moment';

import HttpService from './HttpService';
import { logger } from './LoggingService';

let serverToLocalDiffMs = 0;

type StatusResponse = {
   now: Date;
};

const getServerTime = (): Promise<moment.Moment> =>
   HttpService.get<StatusResponse>('/api/users/status').then((response) => {
      const serverTime = response.data.now;
      const momentServerTime = moment(serverTime);
      return Promise.resolve(momentServerTime);
   });

const resetTimeDiff = (): Promise<void> =>
   getServerTime().then((momentServerTime) => {
      const localTime = moment();
      serverToLocalDiffMs = momentServerTime.diff(localTime, 'milliseconds');
      logger.debug(`Server-to-local time diff: ${serverToLocalDiffMs}ms`);
   });

const momentNow = (): moment.Moment => moment().add(serverToLocalDiffMs, 'milliseconds');

const now = (): Date => momentNow().toDate();

const midnight = (): Date => {
   const date = now();
   date.setHours(23);
   date.setMinutes(59);
   date.setSeconds(59);
   return date;
};

const setTimeUsingDate = (dateToUpdate: Date, newTime: Date): Date => {
   const updatedDate = new Date(dateToUpdate);
   updatedDate.setHours(newTime.getHours());
   updatedDate.setMinutes(newTime.getMinutes());
   return updatedDate;
};

export default {
   resetTimeDiff,
   getServerTime,
   momentNow,
   now,
   midnight,
   setTimeUsingDate,
};
