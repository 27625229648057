import IUserProfile from '@models/IUserProfile';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { actionFunctions } from '@redux/Actions';
import UserSettings, { IUserSettingsProps } from './UserSettings';

const mapDispatchToProps = (dispatch: Dispatch<Action>): IUserSettingsProps => ({
   updateUserProfile: (userProfile: IUserProfile) => {
      dispatch(actionFunctions.updateUserProfile(userProfile));
   },
});

export default connect(null, mapDispatchToProps)(UserSettings);
