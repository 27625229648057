import * as React from 'react';

import useOutsideAlerter from '@hooks/use-outside-alerter';
import IconVideoControlSpeed from '@icons/general/video-control-speed.svg';
import { Maybe } from '@models/Core';
import { NumericOption } from '@models/ReactSelectHelperTypes';
import classNames from 'classnames';
import { SingleValue } from 'react-select';

import Button from '@components/Common/Button';
import { Select, selectTheme } from '@components/Core/Select';

type Props = {
   speed: number;
   handleSpeedChange(newSpeed: number): void;
};

const SpeedOptions: React.FC<Props> = ({ speed, handleSpeedChange }) => {
   const [isOpen, setIsOpen] = React.useState<boolean>(false);

   const options: readonly NumericOption[] = [
      { value: 0.5, label: '0.5' },
      { value: 0.75, label: '0.75' },
      { value: 1, label: 'Normal' },
      { value: 1.5, label: '1.5' },
      { value: 1.75, label: '1.75' },
      { value: 2, label: '2' },
   ];

   const handleSelectorClick = (event: React.MouseEvent): void => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
   };
   const handleOutsideClick = (): void => {
      setIsOpen(!isOpen);
   };

   const handleChange = (newSpeed: SingleValue<NumericOption>): void => {
      if (newSpeed) {
         handleSpeedChange(newSpeed.value);
      }
   };

   const componentRef = React.useRef<Maybe<HTMLDivElement>>(null);
   useOutsideAlerter(handleOutsideClick, componentRef, isOpen);

   const toggleIsOpen = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
   };

   const renderControl = React.useCallback(
      (): JSX.Element => (
         <Button
            tooltip='Playback Speed'
            icon={<IconVideoControlSpeed preserveAspectRatio='xMidYMid meet' aria-hidden />}
            onClick={toggleIsOpen}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
               if (e.key === ' ' || e.key === 'Enter') {
                  e.stopPropagation();
                  toggleIsOpen();
               }
            }}
            color={isOpen || speed !== 1 ? 'blue' : 'white'}
            className={classNames({ 'no-top-radius': isOpen, open: isOpen })}
            ariaLabel='Toggle video speed options'
         />
      ),
      [isOpen, setIsOpen],
   );

   return (
      <div
         ref={(r) => (componentRef.current = r)}
         onClick={handleSelectorClick}
         onMouseOver={handleSelectorClick}
      >
         <Select
            components={{
               DropdownIndicator: () => null,
               IndicatorSeparator: () => null,
               Control: renderControl,
            }}
            pageSize={options.length}
            options={options}
            menuIsOpen={isOpen}
            theme={selectTheme}
            styles={{
               option: (base) => ({ ...base, fontSize: 14, padding: '6px 8px;' }),
               menu: (base) => ({
                  ...base,
                  margin: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
               }),
            }}
            getOptionValue={(option) => option.value.toString()}
            menuPlacement='top'
            isSearchable={false}
            value={options.find((i) => i.value === speed)}
            onChange={handleChange}
         />
      </div>
   );
};

export default SpeedOptions;
