import * as _ from 'lodash';

import { LatLng, VideoFeature, VideoSummary } from '@models/Video';
import { GeoJsonProperties } from 'geojson';
import { MapboxGeoJSONFeature } from 'react-map-gl';

export const getCoordinatesFromFeature = (
   feature: MapboxGeoJSONFeature | VideoFeature,
): LatLng | undefined => {
   const featureGeometry = feature.geometry;
   if ('coordinates' in featureGeometry) {
      const longitude = featureGeometry.coordinates[0];
      const latitude = featureGeometry.coordinates[1];
      if (_.isNumber(longitude) && _.isNumber(latitude)) {
         return { latitude, longitude };
      }
   }

   return undefined;
};

export const isVideoSummary = (properties: GeoJsonProperties): properties is VideoSummary =>
   (properties as VideoSummary).videoId !== undefined;
