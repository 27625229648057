import { Maybe } from '@models/Core';
import { PartOfSpeech } from '@models/IVocabTerm';

const partOfSpeechAbbreviation = (partOfSpeech: PartOfSpeech): Maybe<string> => {
   const abbreviations: Record<PartOfSpeech, string> = {
      adjective: 'adj.',
      adverb: 'adv.',
      conjunction: 'conj.',
      noun: 'n.',
      preposition: 'prep.',
      pronoun: 'pron.',
      verb: 'v.',
      '': '',
   };
   return abbreviations[partOfSpeech] || '';
};

export default partOfSpeechAbbreviation;
