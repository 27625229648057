import * as React from 'react';

import { Maybe } from '@models/Core';
import classnames from 'classnames';

import Link from '@components/Common/Link';
import { GradebookSettings } from './Gradebook';
import { getGradeColor } from './Utils';

interface GradebookCellProps {
   className?: string;
   displayGradesAs: string;
   gradebookSettings: GradebookSettings;
   pointsPossible: number;
   score: Maybe<number>;
   style: React.CSSProperties;
   url?: Maybe<string>;
}

const GradebookCell: React.FC<GradebookCellProps> = ({
   className = '',
   displayGradesAs,
   gradebookSettings,
   pointsPossible,
   score,
   style,
   url,
}) => {
   let displayGrade = '-';
   const showScore = typeof score === 'number' && pointsPossible > 0;
   const gradeAsPercentage = !showScore ? null : Math.round((1000 * score) / pointsPossible) / 10;
   if (showScore) {
      if (displayGradesAs === 'percentage') {
         displayGrade = `${gradeAsPercentage}%`;
      } else if (displayGradesAs === 'points') {
         displayGrade = (Math.round((score + Number.EPSILON) * 100) / 100).toString();
      }
   }
   const createElement = (): JSX.Element => {
      const gradeColor =
         displayGrade === '-'
            ? 'gray'
            : gradeAsPercentage !== null
            ? getGradeColor(gradeAsPercentage, gradebookSettings)
            : '';
      return (
         <div
            style={style}
            className={classnames('grid-item', 'grade-cell', gradeColor, className)}
         >
            {displayGrade}
         </div>
      );
   };
   if (url) {
      return <Link to={url}>{createElement()}</Link>;
   }
   return createElement();
};

export default React.memo(GradebookCell);
