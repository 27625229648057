import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { constants } from 'smooth-dnd';

const { wrapperClass } = constants;

export interface DraggableProps {
   children?: React.ReactNode;
   className?: string;
   render?(): React.ReactElement;
}

class Draggable extends Component<DraggableProps> {
   public static propsTypes = {
      render: PropTypes.func,
      className: PropTypes.string,
   };

   render() {
      if (this.props.render) {
         return React.cloneElement(this.props.render(), { className: wrapperClass });
      }

      const clsName = `${this.props.className ? this.props.className + ' ' : ''}`;
      return (
         <div {...this.props} className={`${clsName}${wrapperClass}`}>
            {this.props.children}
         </div>
      );
   }
}

export default Draggable;
