import IconCheckMark from '@icons/general/icon-checkmark.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import Tippy from '@tippyjs/react';
import React from 'react';

type Props = {
   isGood: boolean;
   isExpired?: boolean;
};

const StatusIndicator: React.FC<Props> = (props) => {
   if (props.isExpired && props.isGood) {
      return (
         <Tippy content='Expired'>
            {/* Tippy Needs the div and does not work with a direct child of SVG */}
            <div>
               <IconCheckMark className='icon-gray' />
            </div>
         </Tippy>
      );
   }

   return props.isGood ? (
      <IconCheckMark className='icon-green' />
   ) : (
      <IconClose className='icon-gray' />
   );
};

export default StatusIndicator;
