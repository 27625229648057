import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import naturalSort from '@utilities/NaturalSort';
import classnames from 'classnames';

import Draggable from './Draggable';

interface WordBankProps {
   className?: string;
   draggable?: boolean;
   showUniqueWords: boolean;
   words: readonly string[];
   usedWords: readonly string[];
   onWordDragStart(event: React.DragEvent<HTMLSpanElement>, word: string): void;
   onWordDragEnd(event: React.DragEvent<HTMLSpanElement>): void;
   onWordDoubleClick(event: React.MouseEvent<HTMLSpanElement>, word: string): void;
   onDrop(event: React.DragEvent<HTMLElement>): void;
}

const WordBank: React.FC<WordBankProps> = ({
   className,
   words,
   usedWords,
   showUniqueWords,
   onDrop,
   onWordDoubleClick,
   onWordDragStart,
   onWordDragEnd,
}) => {
   const [wordBank, setWordBank] = React.useState<readonly { word: string; key: string }[]>([]);

   React.useEffect(() => {
      // Used words doesn't matter if we're showing unique words
      if (showUniqueWords) {
         setWordBank(
            naturalSort([...new Set(words)]).map((word) => ({ word, key: randomShortId() })),
         );
      } else {
         // Otherwise we need to remove the used words from the word bank
         const updatedWordBank = [...words];
         usedWords.forEach((val) => {
            const index = updatedWordBank.indexOf(val);
            if (index > -1) {
               updatedWordBank.splice(index, 1);
            }
         });
         setWordBank(naturalSort(updatedWordBank).map((word) => ({ word, key: randomShortId() })));
      }
   }, [words, usedWords, showUniqueWords]);

   const handleWordBankDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
      event.preventDefault();
   };

   return (
      <div
         className={classnames('word-bank', className)}
         onDrop={onDrop}
         onDragOver={handleWordBankDragOver}
      >
         {wordBank.map(({ word, key }) => (
            <Draggable
               key={key}
               onDragEnd={onWordDragEnd}
               onDragStart={(e) => onWordDragStart(e, word)}
               onDoubleClick={(e) => onWordDoubleClick(e, word)}
               value={word}
            >
               {() => word}
            </Draggable>
         ))}
      </div>
   );
};

export default WordBank;
