import * as React from 'react';

import { isInteger } from '@helpers/NumberUtils';
import useUpdateEffect from '@hooks/use-update-effect';
import { ActivityBuilderMode, AudioRecordingPrompt } from '@models/Activity';

import Switch from '@components/Common/Switch';
import { PromptOptionsProps } from '@components/Activity/Builder/Prompt';

const RecordingPromptOptions: React.FC<
   PromptOptionsProps<AudioRecordingPrompt<ActivityBuilderMode>>
> = ({
   prompt: { allowUploading, attempts, limitAttempts, limitDuration, duration },
   onUpdate,
}) => {
   const getMinutes = (): number => (duration ? Math.floor(duration / 60) : 0);

   const getSeconds = (): number => (duration ? Math.floor(duration - getMinutes() * 60) : 0);

   const attemptsRef = React.useRef<HTMLInputElement>(null);

   const [minutes, setMinutes] = React.useState<string>('');
   const [seconds, setSeconds] = React.useState<string>('');

   React.useEffect(() => {
      setMinutes(getMinutes().toString());
      setSeconds(getSeconds().toString().padStart(2, '0'));
   }, [duration]);

   useUpdateEffect(() => {
      if (limitAttempts) {
         attemptsRef.current?.focus();
      }
   }, [limitAttempts]);

   const toggleAllowUploads = (): void => onUpdate({ allowUploading: !allowUploading });

   const toggleLimitAttempts = (): void => {
      onUpdate({
         limitAttempts: !limitAttempts,
         attempts: limitAttempts ? null : 1,
      });
   };

   const toggleLimitDuration = (): void => onUpdate({ limitDuration: !limitDuration, duration: 0 });

   const handleAttemptsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      onUpdate({ attempts: isInteger(value) ? Number(value) : null });
   };

   const handleSecondsBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      const numericSeconds = parseInt(event.target.value, 10);
      if (isNaN(numericSeconds)) {
         setSeconds(getSeconds().toString());
         return;
      }
      onUpdate({ duration: getMinutes() * 60 + numericSeconds });
   };

   const handleMinutesBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      const numericMinutes = parseInt(event.target.value, 10);
      if (isNaN(numericMinutes)) {
         setMinutes(getMinutes().toString());
         return;
      }
      onUpdate({ duration: numericMinutes * 60 + getSeconds() });
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='margin-top-s'>
               <Switch name='allowUploading' checked={allowUploading} onChange={toggleAllowUploads}>
                  Allow Uploading
               </Switch>
            </div>
            <div className='margin-top-s'>
               <Switch name='limitAttempts' checked={limitAttempts} onChange={toggleLimitAttempts}>
                  Limit Attempts
               </Switch>
               {limitAttempts && (
                  <div className='margin-top-s'>
                     <label className='field-title'>Attempts</label>
                     <input
                        type='number'
                        min='0'
                        ref={attemptsRef}
                        value={attempts ?? ''}
                        onChange={handleAttemptsChange}
                        onFocus={handleFocus}
                     />
                  </div>
               )}
            </div>
            <div className='margin-top-s'>
               <Switch name='limitDuration' checked={limitDuration} onChange={toggleLimitDuration}>
                  Limit Duration
               </Switch>
               {limitDuration && (
                  <div className='item-option duration-option'>
                     <div>
                        <label className='field-title'>Minutes</label>
                        <input
                           type='text'
                           value={minutes}
                           onChange={(e) => setMinutes(e.target.value.replace(/\s/g, ''))}
                           onBlur={handleMinutesBlur}
                           onFocus={handleFocus}
                        />
                     </div>
                     <span className='separator'>:</span>
                     <div>
                        <label className='field-title'>Seconds</label>
                        <input
                           type='text'
                           value={seconds}
                           onChange={(e) => setSeconds(e.target.value.replace(/\s/g, ''))}
                           onBlur={handleSecondsBlur}
                           onFocus={handleFocus}
                        />
                     </div>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default RecordingPromptOptions;
