import { randomShortId } from '@helpers/RandomStringUtils';
import { Maybe } from '@models/Core';
import tinymce, { Editor, EditorEvent } from 'tinymce';

interface MenuItemInstanceApi {
   isEnabled(): boolean;
   setEnabled(state: boolean): void;
}

export enum MarkTokensMode {
   text = 'text',
   tokens = 'tokens',
   answers = 'answers',
}

const isWithinToken = (
   editor: Editor,
   node: Maybe<HTMLElement>,
   lastRightClickedElement?: Maybe<HTMLElement>,
) => {
   // If selection is collapsed and we have a right-clicked node, use it
   if (editor.selection.isCollapsed() && lastRightClickedElement) {
      node = lastRightClickedElement;
   }
   return !!node?.closest('span[data-token]');
};

/*
 * Returns all tokens within the current selection.
 * If the selection is collapsed, it could be within one and returns it using the .closest option.
 */
const getTokensInRange = (
   editor: Editor,
   lastRightClickedElement?: Maybe<HTMLElement>,
): Element[] => {
   const rng = editor.selection.getRng();
   const commonAncestorContainer = rng.commonAncestorContainer;

   // If selection is collapsed and we have a right-clicked node, use it
   if (rng.collapsed && lastRightClickedElement) {
      // Check if right-clicked node is within a token
      const token = lastRightClickedElement.closest('span[data-token]');
      return token ? [token] : [];
   } else if (rng.collapsed && commonAncestorContainer.nodeType === Node.ELEMENT_NODE) {
      // The selection is collapsed, check if it's within a token
      const token = (commonAncestorContainer as Element).closest('span[data-token]');
      return token ? [token] : [];
   } else if (commonAncestorContainer instanceof Element) {
      // The selection is not collapsed, return all tokens in range
      return Array.from(commonAncestorContainer.querySelectorAll('span[data-token]'));
   }

   return [];
};

/*
 * Returns all text nodes under the given element (including the element itself)
 */
const textNodesUnder = (el: Element): readonly Node[] => {
   let node;
   const nodes = [];
   const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null);
   while ((node = walk.nextNode())) {
      nodes.push(node);
   }
   return nodes;
};

/*
 * Splits the given text node into tokens, using space and punctuation characters as delimiters.
 */
const splitTextNodeToTokens = (editor: Editor, node: Node) => {
   const result = editor.dom.create('span');
   const trailingPunctPattern = RegExp(/[!?:;,.…\\-]*$/);
   if (!node.textContent?.trim().length || isWithinToken(editor, node.parentElement)) {
      return;
   }
   node.textContent.split(' ').forEach((token, index, arr) => {
      const trailingMatches = token.match(trailingPunctPattern);
      let trailing = trailingMatches ? trailingMatches[0] : '';
      const substr = token.substring(0, token.length - trailing.length);
      const substrElement = editor.dom.create('span', { 'data-token': randomShortId() }, substr);
      result.appendChild(substrElement);
      if (index !== arr.length - 1) {
         trailing += ' ';
      }
      if (trailing) {
         const fragment = editor.dom.createFragment(trailing);
         result.append(fragment);
      }
   });
   if (node.parentNode) {
      node.parentNode.replaceChild(result, node);
      result.replaceWith(...result.childNodes);
   }
};

/*
 * Merges all tokens within the selection into a single token.
 * Uses start of first token and end of last token as the new token's boundaries.
 */
const mergeTokens = (editor: Editor) => {
   const selection = editor.selection.getSel();
   if (!selection) return;

   // Get the current range
   const range = selection.getRangeAt(0);

   // Find the first and last token within the range
   let node: Maybe<Node> = range.startContainer;
   let firstToken = null,
      lastToken = null;

   while (node) {
      if (node.nodeName === 'SPAN' && (node as HTMLSpanElement).getAttribute('data-token')) {
         if (!firstToken) firstToken = node;
         lastToken = node;
      }
      node =
         node.parentElement?.closest('span[data-token]') ||
         node.nextSibling ||
         node.parentNode?.nextSibling;
   }

   // Check if both tokens were found
   if (!firstToken || !lastToken) return;

   // Create a new range between the first and last token
   const newRange = document.createRange();
   newRange.setStartBefore(firstToken);
   newRange.setEndAfter(lastToken);

   // Extract the text content of the new range
   const newContent = newRange.toString();

   // Create a new token with the merged content
   const mergedToken = editor.dom.create('span', { 'data-token': randomShortId() }, newContent);

   // Replace the old tokens with the new merged token
   newRange.deleteContents();
   newRange.insertNode(mergedToken);

   // Set the range to the new token
   range.selectNodeContents(mergedToken);
   editor.selection.setRng(range);
};

/**
 * Marks current selection as a token.
 */
const createToken = (editor: Editor) => {
   // get the currently selected text
   const content = editor.selection.getContent({ format: 'text' });

   // create a new span element with a data-token attribute and the selected content
   const span = editor.dom.create('span', { 'data-token': randomShortId() }, content);

   // replace the selected content with the new span element
   const newHtml = editor.dom.getOuterHTML(span);
   editor.execCommand('mceReplaceContent', false, newHtml);
};

/**
 * Removes token. If selection is collapsed. expands to the token and removes it.
 */
const removeToken = (editor: Editor, lastRightClickedElement: Maybe<HTMLElement>) => {
   // Right clicked on token.
   if (editor.selection.isCollapsed() && lastRightClickedElement) {
      const token = lastRightClickedElement.closest('span[data-token]');
      if (token) {
         editor.dom.remove(token, true);
      }
   } else {
      //
   }
};

/**
 * Removes all tokens from selection.
 */
const removeTokensFromSelection = (editor: Editor) => {
   // Create a new DOM element to parse selected content as HTML
   const container = document.createElement('div');
   container.innerHTML = editor.selection.getContent({ format: 'html' });

   // Query all tokens within the selection
   const tokens = Array.from(container.querySelectorAll('span[data-token]'));

   // If there are tokens, remove them
   if (tokens.length > 0) {
      tokens.forEach((token) => {
         const parent = token.parentElement;
         if (parent) {
            // Replace the token with its text content
            parent.replaceChild(document.createTextNode(token.textContent || ''), token);
         }
      });

      // Replace the selected content with the modified HTML
      editor.selection.setContent(container.innerHTML);
   }
};

/**
 * Splits all text nodes into token in the editor.
 */
const tokenizeText = (editor: Editor) => {
   textNodesUnder(editor.getBody()).forEach((i) => splitTextNodeToTokens(editor, i));
};

tinymce.PluginManager.add('marktokens', (editor: Editor) => {
   let markTokensMode = MarkTokensMode.text;
   let lastRightClickedElement: HTMLElement | null = null;

   /**
    * Disables the menu item if there are less than minTokenCount tokens in the selection.
    */
   const onSetupMenuItem = (api: MenuItemInstanceApi, minTokenCount: number) => {
      const nodeChangeHandler = () => {
         let tokensCount = 0;
         // If selection is collapsed and we have a right-clicked node, use it
         if (editor.selection.isCollapsed() && lastRightClickedElement) {
            tokensCount =
               lastRightClickedElement && isWithinToken(editor, lastRightClickedElement) ? 1 : 0;
         } else {
            tokensCount = getTokensInRange(editor, lastRightClickedElement).length;
         }
         api.setEnabled(!editor.readonly && tokensCount >= minTokenCount);
      };
      editor.on('nodechange', nodeChangeHandler);
      return () => editor.off('nodechange', nodeChangeHandler);
   };

   editor.ui.registry.addMenuItem('createtoken', {
      icon: 'gamma',
      text: 'Create Token',
      onAction: () => createToken(editor),
   });

   editor.ui.registry.addMenuItem('mergetokens', {
      icon: 'gamma',
      text: 'Merge Tokens',
      onAction: () => mergeTokens(editor),
      onSetup: (api) => onSetupMenuItem(api, 2),
   });

   editor.ui.registry.addMenuItem('removetoken', {
      icon: 'gamma',
      text: 'Remove Token',
      onAction: () => removeToken(editor, lastRightClickedElement),
      onSetup: (api) => onSetupMenuItem(api, 1),
   });

   editor.ui.registry.addMenuItem('removetokens', {
      icon: 'gamma',
      text: 'Remove Tokens',
      onAction: () => removeTokensFromSelection(editor),
      onSetup: (api) => onSetupMenuItem(api, 1),
   });

   editor.ui.registry.addContextMenu('marktokens', {
      update: () => {
         const isEditable = editor.dom.isEditable(editor.selection.getNode());
         if (!isEditable || markTokensMode !== MarkTokensMode.tokens) {
            return '';
         }

         const noToken = 'createtoken';
         const withinToken = 'removetoken';
         const withinTokens = 'mergetokens removetokens';

         const tokens = getTokensInRange(editor, lastRightClickedElement);
         const isSelectionCollapsed = editor.selection.isCollapsed();
         if (tokens.length === 0 && !isSelectionCollapsed) {
            return noToken;
         } else if (tokens.length === 1 && isWithinToken(editor, editor.selection.getNode())) {
            return withinToken;
         } else if (tokens.length > 1) {
            return withinTokens;
         } else {
            return '';
         }
      },
   });

   editor.on('contextmenu', (event) => {
      // Stores the element that was right clicked
      if (event.target instanceof HTMLElement) {
         lastRightClickedElement = event.target;
      } else {
         //
      }
   });

   editor.on('click', (event) => {
      lastRightClickedElement = null;
      if (!isWithinToken(editor, event.target) || markTokensMode !== MarkTokensMode.answers) {
         return;
      } else if (event.target.getAttribute('data-correct')) {
         event.target.removeAttribute('data-correct');
      } else {
         event.target.setAttribute('data-correct', 'true');
      }
   });

   editor.on('markTokensModeChange', (event: EditorEvent<{ value: MarkTokensMode }>) => {
      if (event.value === MarkTokensMode.text) {
         editor.mode.set('design');
      } else if (event.value === MarkTokensMode.tokens) {
         editor.mode.set('design');
      } else if (event.value === MarkTokensMode.answers) {
         editor.mode.set('design');
      }
   });

   editor.editorCommands.addCommands({ tokenizeText: () => tokenizeText(editor) });

   return {
      getMetadata: () => ({
         name: 'Mark Tokens',
         url: 'https://example.com/docs/customplugin',
      }),
      setMode: (newMode: MarkTokensMode) => {
         markTokensMode = newMode;
         editor.fire('markTokensModeChange', { value: newMode });
      },
      getMode: () => markTokensMode,
   };
});
