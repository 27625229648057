import * as _ from 'lodash';
import * as React from 'react';

import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import useCommands from '@hooks/use-commands';
import Content, { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';
import ContentService from '@services/ContentService';
import DateTime from '@services/DateTimeService';
import VocabSetService from '@services/VocabSetService';
import { CSVLink } from 'react-csv';
import { useLocation, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import AddContentToCourseModal from '@components/AddContentToCourseModal';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import VocabSetInfo from './VocabSetInfo';
import VocabTermCard from './VocabTermCard';

const VocabViewer: React.FC = () => {
   const location = useLocation();
   const {
      vocabLearnedLevel,
      routes: {
         content: { editVocabSet, vocabSession },
      },
   } = Constants;

   const { userProfile, setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { accountType, id: userId } = userProfile;

   const [addToCourseModalOpen, setAddToCoursModalOpen] = React.useState<boolean>(false);
   const [canEdit, setCanEdit] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [settings, setSettings] = React.useState<Maybe<Content>>(null);
   const [progress, setProgress] = React.useState<Record<number, IVocabTermProgress>>({});
   const [terms, setTerms] = React.useState<readonly IVocabTerm[]>([]);

   const csvRef = React.useRef<CSVLink>(null);
   const { vocabSetId: vocabSetIdStr } = useParams<{ vocabSetId: string }>();

   const vocabSetId = vocabSetIdStr ? parseInt(vocabSetIdStr, 10) : null;

   const moduleItemIdFromQuery = getQueryParameterAsNumber(location, 'moduleItemId', null);

   const learnedCount = Object.values(progress).filter((i) => i.level >= vocabLearnedLevel).length;

   const reviewCount = Object.values(progress).filter(
      (i) => i.halfLife && i.halfLife <= DateTime.now(),
   ).length;

   useCommands(
      [
         {
            id: 'edit_vocab_set',
            link: vocabSetId
               ? editVocabSet
                    .replace(':vocabSetId', vocabSetId.toString())
                    .concat(moduleItemIdFromQuery ? `?moduleItemId=${moduleItemIdFromQuery}` : '')
               : null,
            scale: 1.5,
            showIf: (i) =>
               !!i.contentId &&
               i.contentType === ContentType.vocabSet &&
               (!!i.isAdmin || !!i.canEditContent),
            title: 'Edit Vocab Set',
         },
         {
            id: 'learning_session',
            link: vocabSetId
               ? vocabSession
                    .replace(':vocabSetId', vocabSetId.toString())
                    .replace(':sessionType', 'learn')
               : undefined,
            title: 'Learn New Terms',
            showIf: () => learnedCount < terms.length,
            scale: 1.5,
         },
         {
            id: 'review_session',
            link: vocabSetId
               ? vocabSession
                    .replace(':vocabSetId', vocabSetId.toString())
                    .replace(':sessionType', 'review')
               : undefined,
            title: 'Review Learned Terms',
            scale: 1.5,
            showIf: () => reviewCount > 0,
         },
      ],
      [vocabSetId, learnedCount, reviewCount, moduleItemIdFromQuery, terms],
   );

   React.useEffect(() => {
      if (vocabSetId === null) {
         return;
      }
      VocabSetService.getWithProgress(vocabSetId).then((response) => {
         const {
            terms: responseTerms,
            canEdit: responseCanEdit,
            progress: responseProgress,
            ...responseSettings
         } = response;
         setTerms(_.sortBy(responseTerms, 'index'));
         setCanEdit(responseCanEdit);
         setIsFetching(false);
         setProgress(_.keyBy(responseProgress, 'id'));
         setSettings(responseSettings);
         ContentService.getBreadcrumbs(
            {
               accountType,
               canEditContent: canEdit,
               contentId: responseSettings.id,
               contentName: responseSettings.name,
               contentType: ContentType.vocabSet,
               createdBy: responseSettings.createdBy,
               folderId: responseSettings.folderId,
               userId,
            },
            location,
         ).then(setBreadcrumbs);
      });
   }, [vocabSetId]);

   const handleDownloadClick = (): void => {
      csvRef.current?.link.click();
   };

   const openAddToCourseModal = () => {
      setAddToCoursModalOpen(true);
   };

   const closeAddToCourseModal = () => {
      setAddToCoursModalOpen(false);
   };

   const handleOwnershipTransfer = (newCreatorId: number): void => {
      setSettings((prevSettings) =>
         prevSettings ? { ...prevSettings, createdBy: newCreatorId } : prevSettings,
      );
   };

   const vocabSetProgress =
      100 *
      (_.sum(
         Object.values(progress).map(
            (i) => Math.min(i.level, vocabLearnedLevel) / vocabLearnedLevel,
         ),
      ) /
         terms.length);

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>
            {isFetching ? 'Loading Vocab Set...' : settings?.name ?? 'Lingco Classroom'}
         </DocumentTitle>
         {isFetching || !settings ? (
            <Loader />
         ) : (
            <>
               <VocabSetInfo
                  canEdit={canEdit || settings.createdBy === userId}
                  isOwner={settings.createdBy === userId}
                  learnedCount={learnedCount}
                  moduleItemId={moduleItemIdFromQuery}
                  onDownloadClick={handleDownloadClick}
                  progress={vocabSetProgress}
                  reviewCount={reviewCount}
                  settings={settings}
                  termCount={Object.values(progress).length}
                  openAddToCourseModal={openAddToCourseModal}
                  onOwnershipTransfer={handleOwnershipTransfer}
               />
               <div className='vocab-set-term-container'>
                  <div className='row'>
                     {terms.map((term, i) => (
                        <VocabTermCard
                           key={term.id}
                           term={term}
                           hasImages={terms.some((j) => j.imageUrl)}
                           progress={progress[term.id]}
                           index={i}
                           language={settings.language}
                        />
                     ))}
                  </div>
               </div>
               <CSVLink
                  filename={`${settings.name}.csv`}
                  ref={csvRef}
                  style={{ display: 'none' }}
                  data={[...terms.map((i) => [i.term, i.definition])]}
               />
               {addToCourseModalOpen && settings.createdBy && (
                  <AddContentToCourseModal
                     contentItemProfile={{
                        createdBy: settings.createdBy,
                        folderId: settings.folderId,
                        imageUrl: settings.imageUrl,
                        itemId: Number(vocabSetId),
                        itemLanguage: settings.language,
                        itemName: settings.name,
                        itemType: ContentType.vocabSet,
                     }}
                     onClose={closeAddToCourseModal}
                  />
               )}
            </>
         )}
      </div>
   );
};
export default VocabViewer;
