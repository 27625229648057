import * as React from 'react';

import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import DeleteAction from '@components/TableActions/DeleteAction';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { CourseProficiencyCanDoStatement } from '@models/Proficiency';
import ProficiencyCourseCanDoStatementService from '@services/ProficiencyCourseCanDoStatementService';

import ProficiencyLevelLabel from '@components/ProficiencyLevelLabel';

interface CourseCanDoStatementsProps {
   courseId: number;
   rows: CourseProficiencyCanDoStatement[];
   isEditable: boolean;
   showCheckboxes: boolean;
   selectedCanDoStatementIds: readonly number[];
   removeRow(canDoStatementId: number): void;
   setSelectedCanDoStatementIds(ids: number[]): void;
}

const CourseCanDoStatements: React.FC<CourseCanDoStatementsProps> = ({
   courseId,
   rows,
   isEditable,
   showCheckboxes,
   selectedCanDoStatementIds,
   removeRow,
   setSelectedCanDoStatementIds,
}) => {
   // There are no can-do statements
   if (rows.length === 0) {
      return (
         <div className='padding-top-s'>
            <EmptyState
               heading='Nothing to show'
               icon={<IconBinoculars className='large' aria-hidden />}
               description='No can-do statements have been found for this course. Click the "Import" button to start adding can-do statements.'
            />
         </div>
      );
   }

   const onDelete = async (id: number) => {
      await ProficiencyCourseCanDoStatementService.remove(courseId, id);
      removeRow(id);
   };

   const renderDelete = (row: CourseProficiencyCanDoStatement): React.ReactNode => (
      <DeleteAction
         dataTest={`${row.id}-delete`}
         onDelete={() => onDelete(row.id)}
         objectName={row.name}
      />
   );

   const renderCompletionDate = (row: CourseProficiencyCanDoStatement): React.ReactNode => {
      if (
         row.weekIndexOfExpectedCompletion === null ||
         row.weekIndexOfExpectedCompletion === undefined
      )
         return <></>;

      return <>{`Week ${row.weekIndexOfExpectedCompletion + 1}`}</>;
   };

   const toggleSelect = (id: number): void => {
      if (selectedCanDoStatementIds.includes(id)) {
         return setSelectedCanDoStatementIds(selectedCanDoStatementIds.filter((k) => k !== id));
      }

      setSelectedCanDoStatementIds([...selectedCanDoStatementIds, id]);
   };

   const renderSelect = (row: CourseProficiencyCanDoStatement): React.ReactNode => (
      <input
         type='checkbox'
         data-test={`${row.id}-status-select`}
         onChange={() => toggleSelect(row.id)}
         checked={selectedCanDoStatementIds.includes(row.id)}
         disabled={!showCheckboxes}
      />
   );

   const renderSelectAll = (): React.ReactNode => {
      const checkedAll = !!rows.length && rows.length === selectedCanDoStatementIds.length;
      const canDoStatementIds = checkedAll ? [] : rows.map((i) => i.id);

      return (
         <input
            type='checkbox'
            onChange={() => setSelectedCanDoStatementIds(canDoStatementIds)}
            checked={checkedAll}
         />
      );
   };

   const columns: readonly Column<CourseProficiencyCanDoStatement>[] = [
      {
         id: 'statusOrCheckBox',
         header: showCheckboxes ? renderSelectAll : 'Status',
         cell: renderSelect,
         show: showCheckboxes,
      },
      {
         id: 'canDoName',
         header: 'Can-Do Statements',
         cell: (i) => i.name,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'levelName',
         header: 'Level',
         cell: (i) => <ProficiencyLevelLabel>{i.level}</ProficiencyLevelLabel>,
         canSort: true,
         sortFunc: (i) => i.level?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'skillName',
         header: 'Skill',
         cell: (i) => i.skill,
         canSort: true,
         sortFunc: (i) => i.skill.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'modeName',
         header: 'Mode',
         cell: (i) => i.mode,
         canSort: true,
         sortFunc: (i) => i.mode?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'completionDate',
         header: 'Completion Date',
         cell: renderCompletionDate,
         canSort: true,
         sortFunc: (i) => i.weekIndexOfExpectedCompletion || 0,
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'delete',
         header: 'Delete?',
         cell: renderDelete,
         cellClassName: 'text-bold text-uppercase',
         show: isEditable,
      },
   ];

   return (
      <Table<CourseProficiencyCanDoStatement>
         className='canDoStatementStatusTable show-overflow'
         columns={columns}
         rowKey='id'
         rows={rows}
      />
   );
};

export default CourseCanDoStatements;
