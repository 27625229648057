import * as bowser from 'bowser';
import * as React from 'react';

import classnames from 'classnames';

// We could scrape this data from http://omahaproxy.appspot.com/ and https://product-details.mozilla.org/1.0/firefox_versions.json and decrement by 3, but for the sake of time I am avoiding that right now.
const CHROME_MINIMUM_SUPPORTED_VERSION = 89;
const FIREFOX_MINIMUM_SUPPORTED_VERSION = 87;
const SAFARI_MINIMUM_SUPPORTED_VERSION = 11;

const TechCheck: React.FC = () => {
   const [hasMiniumSpeed, setHasMiniumSpeed] = React.useState<boolean>(false);

   const areCookiesEnabled = (): boolean => navigator.cookieEnabled;

   const isBrowserCompatable = (): boolean => {
      const browser = bowser.getParser(window.navigator.userAgent);
      const isValidBrowser =
         browser.satisfies({
            desktop: {
               chrome: `>${CHROME_MINIMUM_SUPPORTED_VERSION}`,
               firefox: `>${FIREFOX_MINIMUM_SUPPORTED_VERSION}`,
               safari: `>${SAFARI_MINIMUM_SUPPORTED_VERSION}`,
            },
         }) || false;
      return isValidBrowser;
   };

   const isDesktopOrLaptop = (): boolean => {
      const browser = bowser.parse(window.navigator.userAgent);
      return browser.platform.type === 'desktop';
   };

   const handleInternetSpeedCheckbox = (): void => {
      setHasMiniumSpeed(!hasMiniumSpeed);
   };

   return (
      <div className='flex-column tech-check'>
         <h1>Technology Check</h1>
         <table>
            <tbody>
               <tr>
                  <td
                     className={classnames({
                        'success-checkmark': isBrowserCompatable(),
                        'failure-checkmark': !isBrowserCompatable(),
                     })}
                  />
                  <td>Browser</td>
                  <td
                     className={classnames({
                        success: isBrowserCompatable(),
                        fail: !isBrowserCompatable(),
                     })}
                  >
                     {isBrowserCompatable()
                        ? 'Browser is compatible.'
                        : 'Please use a compatible browser.'}
                  </td>
               </tr>
               <tr>
                  <td
                     className={classnames({
                        'success-checkmark': isDesktopOrLaptop(),
                        'failure-checkmark': !isDesktopOrLaptop(),
                     })}
                  />
                  <td>Non-mobile device</td>
                  <td
                     className={classnames({
                        success: isDesktopOrLaptop(),
                        fail: !isDesktopOrLaptop(),
                     })}
                  >
                     {isDesktopOrLaptop()
                        ? 'Using a laptop or desktop'
                        : 'Please use a laptop or desktop computer'}
                  </td>
               </tr>

               <tr>
                  <td
                     className={classnames({
                        'success-checkmark': areCookiesEnabled(),
                        'failure-checkmark': !areCookiesEnabled(),
                     })}
                  />
                  <td>Cookies</td>
                  <td
                     className={classnames({
                        success: areCookiesEnabled(),
                        fail: !areCookiesEnabled(),
                     })}
                  >
                     {areCookiesEnabled()
                        ? 'Cookies are enabled.'
                        : 'Please enable cookies on your browser.'}
                  </td>
               </tr>
               <tr>
                  <td
                     className={classnames({
                        'success-checkmark': hasMiniumSpeed,
                        'failure-checkmark': !hasMiniumSpeed,
                     })}
                  />
                  <td>Internet Speed</td>
                  <td
                     className={classnames({
                        success: hasMiniumSpeed,
                        fail: !hasMiniumSpeed,
                     })}
                  >
                     <div className='speed-test-container'>
                        <div className='speed-test-question'>
                           <input
                              id='internet-speed'
                              type='checkbox'
                              onChange={handleInternetSpeedCheckbox}
                           />
                           <label htmlFor='internet-speed'>
                              Do you have internet with a minimum speed of 3 Mbps? We recommend a
                              minimum speed of 3 Mbps, but ideally you would have a speed greater
                              than 5 Mbps.
                           </label>
                        </div>
                        <div className='iframe-container'>
                           <iframe id='speed-test-iframe' src='https://fast.com/' />
                        </div>
                     </div>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   );
};

export default TechCheck;
