import * as React from 'react';

import { Maybe } from '@models/Core';
import classNames from 'classnames';
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';

import { Tile as TileType } from './Models';

interface TileProps {
   tile: TileType;
   isDraggedOver: boolean;
   onDragStart: DraggableEventHandler;
   onDrag: DraggableEventHandler;
   onDragStop: DraggableEventHandler;
   onDimensionChange(height: number, width: number): void;
   getIncrementedZIndex(): number;
}

const Tile: React.FC<TileProps> = ({
   tile,
   isDraggedOver,
   onDimensionChange,
   onDrag,
   onDragStart,
   onDragStop,
   getIncrementedZIndex,
}) => {
   const [zIndex, setZIndex] = React.useState<number>(1);
   const ref = React.useRef<Maybe<HTMLDivElement>>(null);

   React.useEffect(() => {
      if (ref.current?.clientHeight && ref.current?.clientWidth) {
         onDimensionChange(ref.current?.clientHeight, ref.current?.clientWidth);
      }
   }, [ref.current]);

   const handleDragStart: DraggableEventHandler = (
      event: DraggableEvent,
      data: DraggableData,
   ): void => {
      setZIndex(getIncrementedZIndex());
      onDragStart(event, data);
   };

   const handleDrag: DraggableEventHandler = (event: DraggableEvent, data: DraggableData): void => {
      onDrag(event, data);
   };

   const handleDragStop: DraggableEventHandler = (
      event: DraggableEvent,
      data: DraggableData,
   ): void => {
      onDragStop(event, data);
   };

   return (
      <Draggable
         key={tile.id}
         position={tile.position}
         defaultClassName='vocab-tile'
         defaultClassNameDragging='dragging'
         defaultClassNameDragged='dragged'
         bounds='.parent'
         onStop={handleDragStop}
         onStart={handleDragStart}
         onDrag={handleDrag}
      >
         <div
            style={{ zIndex }}
            className={classNames({ 'dragged-over': isDraggedOver }, tile.state)}
            ref={(r) => (ref.current = r)}
         >
            {tile.text}
         </div>
      </Draggable>
   );
};

export default Tile;
