import * as React from 'react';

import IconPause from '@icons/general/video-control-pause.svg';
import IconPlay from '@icons/general/video-control-play.svg';
import { Maybe } from '@models/Core';
import { uploadUserFile } from '@services/AssetService';
import tinymce, { AstNode, Editor, EditorEvent } from 'tinymce';

import { AudioPlayerSize } from '@components/Core/AudioPlayer';

import { renderToString } from 'react-dom/server';

const inlineAudioTemplate = (text: string, src: string) =>
   `<span class="inline-audio" contenteditable="false">${text}<audio src="${src}"></audio></span>`;

const convertAudioToInlinePlayer = (editor: Editor, node: AstNode) => {
   const playerSize = node.attr('data-player-size');
   const src = node.attr('src');
   const className = node.attr('class');
   const view = renderToString(
      <span
         style={{
            backgroundColor: '#007CBB',
            cursor: 'pointer',
            width: '32px',
            borderRadius: '50%',
            display: 'inline-flex',
            height: '32px',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '4px',
         }}
         contentEditable={false}
         className={className}
         data-audio-src={src}
         data-player-size={playerSize}
      >
         <IconPlay fill='white' />
         <audio src={src} style={{ display: 'none' }} />
      </span>,
   );
   const parser = tinymce.html.DomParser({ validate: false }, editor.schema);
   const rootNode = parser.parse(view);
   node.replace(rootNode);
};

// Legacy
const convertInlinePlayerToAudio = (editor: Editor, node: AstNode): void => {
   const view = renderToString(
      <audio
         src={node.attr('data-audio-src')}
         className={node.attr('class')}
         controls
         data-player-size={node.attr('data-player-size')}
      />,
   );
   const parser = tinymce.html.DomParser({ validate: false }, editor.schema);
   const rootNode = parser.parse(view);
   node.replace(rootNode);
};

const checkAudioClick = (event: EditorEvent<MouseEvent>): void => {
   let target = event.target;
   let audioSrc = target.getAttribute('data-audio-src');
   if (!audioSrc && ['path', 'g', 'svg'].includes(event.target.tagName)) {
      let parent = event.target.parentElement;
      while (parent) {
         if (parent.getAttribute('data-audio-src')) {
            audioSrc = parent.getAttribute('data-audio-src');
            target = parent;
            break;
         }
         parent = parent.parentElement;
      }
   }
   if (audioSrc) {
      const audio = new Audio(audioSrc);
      audio.onplaying = (): void => {
         target.innerHTML = renderToString(<IconPause fill='white' />);
      };
      audio.onended = (): void => {
         target.innerHTML = renderToString(<IconPlay fill='white' />);
      };
      audio.play();
   }
};

tinymce.PluginManager.add('inlineaudio', (editor) => {
   const isInInlineAudio = (node: Element) => !!node.closest('span.inline-audio');

   const getAudioElement = (): Maybe<HTMLAudioElement> => {
      const node = editor.selection.getNode();
      const results = editor.dom.select('audio', node);
      return results ? results[0] : null;
   };

   const setAudioSource = (src: string) => {
      const currentNode = editor.selection.getNode();
      if (isInInlineAudio(currentNode)) {
         const elem = getAudioElement();
         if (elem) {
            if (elem.parentElement?.classList.contains('mce-object-audio')) {
               elem.parentElement.setAttribute('data-mce-p-src', src);
            }
            elem.src = src;
            editor.fire('change');
         }
      } else {
         const selectedText = editor.selection
            ?.getContent({
               format: 'text',
            })
            ?.trim();
         editor.execCommand('mceReplaceContent', false, inlineAudioTemplate(selectedText, src));
      }
   };

   // Legacy
   editor.on('preInit', () => {
      /**
       * Remove and replace audio plugin filter with more strict parameters.
       */
      const initialAudioFilterNodeCallback = editor.parser
         .getNodeFilters()
         .find((i) => i.name === 'audio')?.callbacks[0];
      if (initialAudioFilterNodeCallback) {
         editor.parser.removeNodeFilter('audio');
         editor.parser.addNodeFilter('audio', (nodes) => {
            const filteredNodes = nodes.filter(
               (node) => node.attr('data-player-size') !== AudioPlayerSize.small,
            );
            initialAudioFilterNodeCallback(filteredNodes, 'audio', {});
         });
      }

      editor.parser.addNodeFilter('audio', (nodes) => {
         nodes.forEach((node) => {
            if (node.attr('data-player-size') === AudioPlayerSize.small) {
               convertAudioToInlinePlayer(editor, node);
            }
         });
      });
      editor.serializer.addNodeFilter('span', (nodes) => {
         nodes.forEach((node) => {
            if (
               node.attr('data-audio-src') &&
               node.attr('data-player-size') === AudioPlayerSize.small
            ) {
               convertInlinePlayerToAudio(editor, node);
            }
         });
      });
   });

   // Legacy
   editor.on('click', checkAudioClick);

   editor.on('dblclick', (event) => {
      const elem: Maybe<HTMLSpanElement> = event.target.closest('span.inline-audio');
      if (elem) {
         const audioElem = elem.querySelector('audio');
         if (audioElem && !!audioElem.getAttribute('src')) {
            if (audioElem.paused) {
               audioElem.play();
            } else {
               audioElem.pause();
            }
         }
      }
   });

   editor.ui.registry.addContextForm('inlineaudio', {
      launch: {
         type: 'contextformtogglebutton',
         icon: 'speaker-volume-high-line',
         tooltip: 'Audio',
      },
      label: 'Audio',
      scope: 'node',
      position: 'selection',
      predicate: (node) => isInInlineAudio(node),
      initValue: () => {
         const elem = getAudioElement();
         return elem?.getAttribute('src') ?? '';
      },
      commands: [
         {
            type: 'contextformtogglebutton',
            icon: 'checkmark',
            tooltip: 'Save',
            primary: true,
            onAction: (formApi) => {
               const newSrc = formApi.getValue();
               setAudioSource(newSrc);
               editor.selection.collapse(false);
               formApi.hide();
            },
         },
         {
            type: 'contextformtogglebutton',
            icon: 'remove',
            tooltip: 'Remove audio',
            active: false,
            onAction: (formApi) => {
               const currentNode = editor.selection.getNode();
               if (isInInlineAudio(currentNode)) {
                  const inlineAudio = currentNode.closest('span.inline-audio') as HTMLElement;
                  const text = inlineAudio.innerText;
                  inlineAudio.replaceWith(text);
               }
               formApi.hide();
            },
         },
         // {
         //   type: "contextformbutton",
         //   icon: "microphone",
         //   tooltip: "Record",
         //   onAction: (formApi) => {
         //     // Record audio
         //     formApi.hide();
         //   }
         // },
         {
            type: 'contextformbutton',
            icon: 'browse',
            tooltip: 'Upload',
            onAction: (formApi) => {
               const input = document.createElement('input');
               input.setAttribute('type', 'file');
               input.setAttribute('accept', 'audio/*');

               input.addEventListener('change', async (e) => {
                  const file = (e.target as HTMLInputElement).files?.[0];
                  if (!file) {
                     return;
                  }
                  const { url } = await uploadUserFile(file);

                  const reader = new FileReader();
                  reader.addEventListener('load', () => {
                     setAudioSource(url);
                     editor.selection.collapse(false);
                     formApi.hide();
                     input.remove();
                  });
                  reader.readAsDataURL(file);
               });

               input.click();
            },
         },
         // {
         //    type: 'contextformbutton',
         //    icon: 'wave-line',
         //    tooltip: 'Generate',
         //    onAction: (formApi) => {
         //       formApi.hide();
         //    },
         // },
      ],
   });

   return {
      getMetadata: () => ({
         name: 'Inline Audio',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
