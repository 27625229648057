import * as React from 'react';

import { Maybe } from '@models/Core';
import Timer from '@utilities/Timer';

interface SpeedCountdownProps {
   show: boolean;
   onCountdownFinish(): void;
}

const SPEED_COUNTDOWN_DURATION = 3;

const SpeedCountdown: React.FC<SpeedCountdownProps> = ({ onCountdownFinish, show }) => {
   const [seconds, setSeconds] = React.useState<Maybe<number>>(null);
   const timer = React.useRef(
      new Timer({
         duration: SPEED_COUNTDOWN_DURATION,
         callback: (i) => setSeconds(i),
         onCompletion: () => onCountdownFinish(),
      }),
   );

   React.useEffect(() => {
      if (show) {
         timer.current.start();
      }
   }, [show]);

   if (show) {
      return (
         <div className='session-countdown'>
            <div className='countdown-number-wrapper'>
               <span key={seconds} className='countdown-number animated zoomIn faster'>
                  {seconds}
               </span>
            </div>
         </div>
      );
   }
   return null;
};

export default React.memo(SpeedCountdown);
