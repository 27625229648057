import * as React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@components/Common/Button';

interface CourseCodeProps {
   code: string;
   name: string;
   resetCode(): void;
}

const CourseCode: React.FC<CourseCodeProps> = ({ code, name, resetCode }) => {
   const [codeCopied, setCodeCopied] = React.useState<boolean>(false);

   const handleCopy = (): void => setCodeCopied(true);
   const handleReset = (): void => resetCode();

   return (
      <div className='row bottom-xs'>
         <div className='col-xs-12 margin-top-s'>
            <label className='field-title no-padding'>{name} Course Code</label>
            <Button
               className='no-margin right'
               color='blue'
               data-test={`button-reset-${name}-code`}
               onClick={handleReset}
               subtle
            >
               {`Reset ${name} code`}
            </Button>
            <div className='copy-field'>
               <CopyToClipboard text={code} onCopy={handleCopy}>
                  <Button color='white' className='copy-button'>
                     {codeCopied ? 'Copied' : 'Copy'}
                  </Button>
               </CopyToClipboard>
               <input readOnly type='text' className='disabled margin-top-s' value={code} />
            </div>
         </div>
      </div>
   );
};

export default CourseCode;
