import * as React from 'react';

import { ExternalAPIError } from '@models/Canvas';

import ErrorDisplay from './ErrorDisplay';

interface CanvasErrorDisplayProps {
   error: ExternalAPIError;
}

const CanvasErrorDisplay: React.FC<CanvasErrorDisplayProps> = ({ error }) => (
   <ErrorDisplay
      heading='Canvas Error'
      message={
         <>
            <p>Reason: {error.message}</p>
            <br />
            <div className='title'>Remediation Steps:</div>

            <ul className='text-left'>
               <li>
                  Verify canvas scope:
                  <strong> {error.externalApiUrl}</strong>
               </li>
               {error.externalApiUrlParams && error.externalApiUrlParams.courseId ? (
                  <li>
                     Verify access to canvas course:
                     <strong> {String(error.externalApiUrlParams.courseId)}</strong>
                  </li>
               ) : null}
               {error.externalApiUrlParams && error.externalApiUrlParams.assignmentId ? (
                  <li>
                     Verify access to canvas assignment:
                     <strong> {String(error.externalApiUrlParams.assignmentId)}</strong>
                  </li>
               ) : null}
               <li>If the problem persists please contact support</li>
            </ul>
         </>
      }
   />
);

export default CanvasErrorDisplay;
