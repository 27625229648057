import * as React from 'react';

import { CompleterPromptProps } from '@components/Activity/Completer/CompleterPrompt';
import VideoPlayer from '@components/Core/VideoPlayer/VideoPlayer';
import { isInteger } from '@helpers/NumberUtils';
import {
   ActivityCompleterMode,
   ActivityPrompt,
   InteractiveVideoPromptTime,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import { VideoGetResponse } from '@models/Video';
import VideoService from '@services/VideoService';
import ReactPlayer from 'react-player';

import InteractiveMediaContent from './InteractiveMediaContent';

export interface VideoPlayerAction {
   command: string;
   to?: number;
}

interface YoutubeVideoConfig {
   showinfo: 1 | 0;
   start?: number;
   end?: number;
}

type VideoContentProps = Omit<CompleterPromptProps, 'prompt'> & {
   enableNativeSubtitles: boolean;
   enableTargetSubtitles: boolean;
   fileUrl: Maybe<string>;
   interactivePrompts: ActivityPrompt<ActivityCompleterMode>[];
   isInteractiveVideo: boolean;
   isSubmitting: boolean;
   playSnippet: boolean;
   promptTimes?: readonly InteractiveVideoPromptTime<ActivityCompleterMode>[];
   snippetEnd?: number;
   snippetStart?: number;
   videoId: number | null;
   youtubeId: string | null;
   checkAnswers(promptId: string | number): void;
};

const VideoContent: React.FC<VideoContentProps> = ({
   enableNativeSubtitles,
   enableTargetSubtitles,
   evaluations,
   fileUrl,
   interactivePrompts,
   isInteractiveVideo,
   isSubmitting,
   promptTimes,
   readyToRecord,
   responses,
   showMissed,
   snippetEnd,
   snippetStart,
   videoId,
   youtubeId,
   checkAnswers,
   postDiscussionBoardResponse,
   saveResponse,
   setDisableSubmit,
   setResponse,
}) => {
   const [video, setVideo] = React.useState<Maybe<VideoGetResponse>>();
   const url = fileUrl || video?.url;

   React.useEffect(() => {
      if (videoId) {
         VideoService.get(videoId).then(setVideo);
      }
   }, [videoId]);

   const youtubeConfig: YoutubeVideoConfig = {
      showinfo: 1,
   };

   if (isInteger(snippetStart) && isInteger(snippetEnd)) {
      youtubeConfig.start = snippetStart;
      youtubeConfig.end = snippetEnd;
   }

   // eslint-disable-next-line complexity
   const renderVideoPlayer = (): React.ReactNode => {
      if (youtubeId) {
         return (
            <div className='max-width-720'>
               <div className='video-container'>
                  <ReactPlayer
                     width='100%'
                     height='100%'
                     style={{ position: 'absolute', top: 0, left: 0 }}
                     config={{
                        youtube: {
                           playerVars: youtubeConfig,
                        },
                     }}
                     url={`https://youtu.be/${youtubeId}`}
                     controls
                  />
               </div>
            </div>
         );
      }

      if (isInteractiveVideo && (fileUrl || videoId)) {
         if (url === undefined) return null;

         return (
            <InteractiveMediaContent
               checkAnswers={checkAnswers}
               evaluations={evaluations}
               interactivePrompts={interactivePrompts}
               isSubmitting={isSubmitting}
               postDiscussionBoardResponse={postDiscussionBoardResponse}
               promptTimes={promptTimes || []}
               readyToRecord={readyToRecord}
               responses={responses}
               saveResponse={saveResponse}
               setDisableSubmit={setDisableSubmit}
               setResponse={setResponse}
               showMissed={showMissed}
               url={url}
            />
         );
      }

      if (fileUrl) {
         let videoFileUrl = fileUrl;
         if (snippetStart && snippetEnd) {
            videoFileUrl = `${videoFileUrl}#t=${snippetStart},${snippetEnd}`;
         }

         return (
            <div style={{ maxWidth: '720px' }}>
               <VideoPlayer
                  videoUrl={videoFileUrl}
                  start={snippetStart}
                  end={snippetEnd}
                  videoContainerClassname='video-container'
               />
            </div>
         );
      }

      if (video) {
         return (
            <div style={{ maxWidth: '720px' }}>
               <VideoPlayer
                  videoUrl={video.url}
                  posterUrl={video.imageUrl}
                  start={snippetStart}
                  end={snippetEnd}
                  videoContainerClassname='video-container'
                  nativeLanguage='en'
                  nativeSubtitles={
                     enableNativeSubtitles
                        ? video?.nativeLanguageCaptions // FIXME: Rename to Subtitles
                        : undefined
                  }
                  showSubtitles={
                     !!(video?.nativeLanguageCaptions && enableNativeSubtitles) ||
                     !!(video?.targetLanguageCaptions && enableTargetSubtitles)
                  }
                  targetLanguage={video?.language}
                  targetSubtitles={
                     enableTargetSubtitles
                        ? video?.targetLanguageCaptions // FIXME: Rename to Subtitles
                        : undefined
                  }
                  showOverlay={!!video}
               />
            </div>
         );
      }

      return null;
   };

   return <div className='activity-builder-row'>{renderVideoPlayer()}</div>;
};

export default React.memo(VideoContent);
