import {
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGroup,
   ActivityRubricScoringType,
} from '@models/Activity';

interface RubricRoot {
   type: ActivityRubricEntryType.rubric;
   id: null;
}

export const RUBRIC_ROOT: RubricRoot = {
   type: ActivityRubricEntryType.rubric,
   id: null,
};

export interface DraggedEntryInfo {
   id: number;
   type: ActivityRubricEntryType;
}

export interface RubricEntryLocation {
   droppableInfo: DraggedEntryInfo | RubricRoot;
   index: number;
}

const getDraggableId = (entry: ActivityRubricItem | ActivityRubricItemGroup): string =>
   `${entry.type}-${entry.id}`;

const getDraggedEntryInfo = (draggableId: string): DraggedEntryInfo => {
   const [type, id] = draggableId.split('-', 2);
   return {
      type: type as ActivityRubricEntryType,
      id: parseInt(id, 10),
   };
};

const formatPoints = (points: string, scoringType: ActivityRubricScoringType): string => {
   const parsedPts = parseFloat(points);
   if (isNaN(parsedPts)) {
      return '';
   }
   let result = points;
   let sign = '';
   if (scoringType === ActivityRubricScoringType.negative) {
      if (parsedPts >= 0) {
         sign = '-';
      } else {
         result = result.replace('-', '+');
         sign = '+';
      }
   } else if (parsedPts >= 0) {
      sign = '+';
   }
   return (
      sign +
      parseFloat(parseFloat(result).toPrecision(10))
         .toFixed(4)
         .replace(/0{0,3}$/, '')
   );
};

export { getDraggableId, getDraggedEntryInfo, formatPoints };
