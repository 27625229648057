import * as React from 'react';

import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import Table from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import { ActivityCompleterContext } from '@components/Activity/Completer/ActivityCompleter';

interface FillBlanksResponseDistributionProps {
   promptId: number;
   selectedBlankId: Maybe<string>;
   setSelectedBlankId(blankId: Maybe<string>): void;
}

interface FillBlanksResponseDistributionEntry {
   entry: string;
   frequency: number;
   correct: boolean;
}

interface FillBlanksResponseDistribution {
   blankId: string;
   entries: readonly FillBlanksResponseDistributionEntry[];
}

const FillBlanksResponseDistribution: React.FC<FillBlanksResponseDistributionProps> = ({
   promptId,
   selectedBlankId,
   setSelectedBlankId,
}) => {
   const { showResponseDistributions } = React.useContext(ActivityCompleterContext);

   const { activityId } = useParams<{ activityId: string }>();

   React.useEffect(() => {
      setSelectedBlankId(null);
      if (showResponseDistributions) {
         HttpService.getWithAuthToken<{
            distribution: readonly FillBlanksResponseDistribution[];
         }>(`/api/activities/${activityId}/prompts/${promptId}/response_distribution`).then(
            ({ data }) => {
               setResponseDistribution(data.distribution);
            },
         );
      }
   }, [showResponseDistributions]);

   const [responseDistribution, setResponseDistribution] = React.useState<
      readonly FillBlanksResponseDistribution[]
   >([]);

   const responseDistributionRows =
      selectedBlankId && responseDistribution
         ? responseDistribution.find((i) => i.blankId === selectedBlankId)?.entries
         : null;

   const renderResponseDistrbutionCell = (
      row: FillBlanksResponseDistributionEntry,
      property: keyof FillBlanksResponseDistributionEntry,
   ): React.ReactNode => (
      <span className={classnames('entry', row.correct ? 'correct' : 'incorrect')}>
         {row[property].toString()}
      </span>
   );

   const clearSelectedBlankId = (): void => {
      setSelectedBlankId(null);
   };

   if (!responseDistributionRows) {
      return null;
   }

   return (
      <ModalDialog onClose={clearSelectedBlankId} heading='Response Distribution'>
         <Table<FillBlanksResponseDistributionEntry>
            rows={responseDistributionRows}
            rowKey='entry'
            className='fill-blanks-response-distribution'
            columns={[
               {
                  id: 'entry',
                  header: 'Entry',
                  cell: (i) => renderResponseDistrbutionCell(i, 'entry'),
                  canSort: true,
               },
               {
                  id: 'frequency',
                  header: 'Frequency',
                  cell: (i) => renderResponseDistrbutionCell(i, 'frequency'),
                  canSort: true,
               },
               {
                  id: 'correct',
                  header: 'Correct',
                  cell: (i) => renderResponseDistrbutionCell(i, 'correct'),
                  canSort: true,
               },
            ]}
         />
      </ModalDialog>
   );
};

export default FillBlanksResponseDistribution;
