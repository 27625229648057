import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconTick from '@icons/general/icon-tick.svg';
import { ActivityMode, OrderingOption, OrderingResponse } from '@models/Activity';
import classnames from 'classnames';

interface OrderingProps {
   options: readonly OrderingOption<ActivityMode.grade>[];
   response: OrderingResponse;
}

const OrderingPrompt: React.FC<OrderingProps> = ({ options, response }) => (
   <div className='dnd-prompt-drag-context'>
      <div className='dnd-prompt-container closed'>
         <div className='dnd-column-container'>
            <div className='dnd-column-items-wrapper'>
               <div className='dnd-column-items'>
                  {response.map((entry, i) => {
                     const option = options.find(({ id }) => id === entry.optionId);
                     const isCorrect = entry.optionId === options[i].id;
                     if (option) {
                        return (
                           <div
                              key={entry.optionId}
                              className={classnames('dnd-item', {
                                 correct: isCorrect,
                                 incorrect: !isCorrect,
                              })}
                           >
                              <div className='dnd-item-inner'>
                                 <div className='dnd-item-icon'>
                                    {isCorrect ? <IconTick /> : <IconCloseSmall />}
                                 </div>
                                 <div className='dnd-item-text'>
                                    {ensureElement(parseHtml(option.data), 'p')}
                                 </div>
                              </div>
                           </div>
                        );
                     }
                  })}
               </div>
            </div>
         </div>
      </div>
   </div>
);

export default OrderingPrompt;
