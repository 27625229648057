import * as _ from 'lodash';
import * as Mousetrap from 'mousetrap';
import * as React from 'react';

import {
   ActivityCollapsedRubricEntry,
   ActivityMode,
   ActivityResponseEvaluation,
   ActivityRubricEntryType,
   ActivityRubricItem,
   ActivityRubricItemGropWithItems,
   ActivityRubricItemGroup,
   ActivityRubricScoringType,
} from '@models/Activity';
import {
   Combine,
   DragDropContext,
   DraggableLocation,
   DragStart,
   DragUpdate,
   Droppable,
   DropResult,
} from 'react-beautiful-dnd';

import { Maybe } from '@models/Core';
import { isRubricItemGropWithItems } from '@components/Activity/Utils';
import {
   DraggedEntryInfo,
   getDraggableId,
   getDraggedEntryInfo,
   RUBRIC_ROOT,
   RubricEntryLocation,
} from './helpers';
import RubricItem from './RubricItem';
import RubricItemGroup from './RubricItemGroup';

interface RubricEntriesProps {
   displayOnly?: boolean;
   evaluation?: ActivityResponseEvaluation<ActivityMode.grade>;
   expandedRubricItemGroupId?: Maybe<number>;
   rubricEntries: readonly ActivityCollapsedRubricEntry[];
   scoringType: ActivityRubricScoringType;
   updateRubricEntries?(update: {
      [ActivityRubricEntryType.item]: Record<number, Partial<ActivityRubricItem>>;
      [ActivityRubricEntryType.group]: Record<number, Partial<ActivityRubricItemGroup>>;
   }): void;
   createRubricItem?(data?: Partial<ActivityRubricItem>, focus?: boolean): Promise<number>;
   deleteRubricItem?(itemId: number): void;
   deleteRubricItemGroup?(groupId: number): void;
   handleRubricImport?(rubricId: number, callback?: () => void): void;
   toggleRubricItem?(itemId: number): void;
   toggleRubricItemGroup?(groupId: number): void;
   updateRubricItem?(itemId: number, update: Partial<ActivityRubricItem>): void;
   updateRubricItemGroup?(groupId: number, update: Partial<ActivityRubricItemGroup>): void;
}

interface RubricEntriesState {
   importModalOpen: boolean;
   isDraggingRubricEntry: boolean;
   currentLocation: Maybe<RubricEntryLocation>;
   draggedEntryInfo: Maybe<DraggedEntryInfo>;
   showDropdown: boolean;
}
const RubricEntries: React.FC<RubricEntriesProps> = ({
   displayOnly = false,
   evaluation,
   expandedRubricItemGroupId,
   rubricEntries,
   scoringType,
   createRubricItem,
   deleteRubricItem,
   deleteRubricItemGroup,
   toggleRubricItem,
   toggleRubricItemGroup,
   updateRubricEntries,
   updateRubricItem,
   updateRubricItemGroup,
}) => {
   const letterKeys = Array.from('qwertyuiop');
   const numberKeys = Array.from('1234567890');

   window['__react-beautiful-dnd-disable-dev-warnings'] = true;

   const getDragIndex = (
      rubricEntryType: ActivityRubricEntryType,
      entryId: number,
   ): Maybe<number> => {
      let index = 0;
      const dragIndices: {
         [ActivityRubricEntryType.rubric]: undefined;
         [ActivityRubricEntryType.item]: Record<number, number>;
         [ActivityRubricEntryType.group]: Record<number, number>;
      } = {
         [ActivityRubricEntryType.rubric]: undefined,
         [ActivityRubricEntryType.item]: {},
         [ActivityRubricEntryType.group]: {},
      };
      rubricEntries.forEach((i) => {
         if (i.type === ActivityRubricEntryType.item) {
            dragIndices[ActivityRubricEntryType.item][i.id] = index++;
         } else if (i.type === ActivityRubricEntryType.group) {
            dragIndices[ActivityRubricEntryType.group][i.id] = index++;
            if (i.id === expandedRubricItemGroupId) {
               i.rubricItems.forEach(
                  (j) => (dragIndices[ActivityRubricEntryType.item][j.id] = index++),
               );
               index += 1;
            }
         }
      });

      return dragIndices[rubricEntryType]?.[entryId];
   };

   const [state, setState] = React.useState<RubricEntriesState>({
      importModalOpen: false,
      isDraggingRubricEntry: false,
      currentLocation: null,
      draggedEntryInfo: null,
      showDropdown: false,
   });

   const handleLetterKeys = (event: Mousetrap.ExtendedKeyboardEvent): void => {
      if (expandedRubricItemGroupId !== null) {
         const group = rubricEntries
            .filter(isRubricItemGropWithItems)
            .find(
               (i) =>
                  i.type === ActivityRubricEntryType.group && i.id === expandedRubricItemGroupId,
            );
         if (!group) {
            return;
         }
         const index = letterKeys.indexOf(event.key);
         if (group.rubricItems.length > index) {
            const entry = group.rubricItems[index];
            handleToggleRubricItem(entry.id);
         }
      }
   };

   const handleNumberKeys = (event: Mousetrap.ExtendedKeyboardEvent): void => {
      const index = numberKeys.indexOf(event.key.toString());
      if (rubricEntries.length > index) {
         const entry = rubricEntries[index];
         if (entry.type === ActivityRubricEntryType.item) {
            handleToggleRubricItem(entry.id);
         } else if (entry.type === ActivityRubricEntryType.group) {
            handleToggleRubricItemGroup(entry.id);
         }
      }
   };

   React.useEffect(() => {
      Mousetrap.bind(letterKeys, handleLetterKeys);
      Mousetrap.bind(numberKeys, handleNumberKeys);
      return () => {
         Mousetrap.unbind(letterKeys);
         Mousetrap.unbind(numberKeys);
      };
   }, [expandedRubricItemGroupId, rubricEntries, state.isDraggingRubricEntry]);

   function topLevelRubricEntry(
      type: ActivityRubricEntryType.item,
      id: number,
   ): Maybe<ActivityRubricItem>;
   function topLevelRubricEntry(
      type: ActivityRubricEntryType.group,
      id: number,
   ): Maybe<ActivityRubricItemGropWithItems>;
   // eslint-disable-next-line func-style
   function topLevelRubricEntry(
      type: ActivityRubricEntryType,
      id: number,
   ): Maybe<ActivityCollapsedRubricEntry> {
      return rubricEntries.find((i) => i.type === type && i.id === id);
   }

   const rubricEntryLocation = (
      rubricEntryType: ActivityRubricEntryType,
      source: Maybe<DraggableLocation>,
      destination: Maybe<DraggableLocation>,
      combine?: Maybe<Combine>,
   ): Maybe<RubricEntryLocation> => {
      if (combine) {
         const { id: combineWithId, type: combineWithType } = getDraggedEntryInfo(
            combine.draggableId,
         );
         const entryCombinedWith = rubricEntries.find(
            (i) => i.type === combineWithType && i.id === combineWithId,
         );
         if (isRubricItemGropWithItems(entryCombinedWith)) {
            return {
               droppableInfo: {
                  type: combineWithType,
                  id: combineWithId,
               },
               index: entryCombinedWith.rubricItems.length,
            };
         }
      }
      if (!destination) {
         return null;
      }
      if (!expandedRubricItemGroupId) {
         return {
            droppableInfo: RUBRIC_ROOT,
            index: destination.index,
         };
      }
      if (rubricEntryType === ActivityRubricEntryType.group) {
         return null;
      }

      const expandedGroup = topLevelRubricEntry(
         ActivityRubricEntryType.group,
         expandedRubricItemGroupId,
      );
      if (!isRubricItemGropWithItems(expandedGroup)) {
         return null;
      }
      let numDraggablesInExpandedGroup = expandedGroup.rubricItems.length + 2;
      let expandedGroupStartIndex = expandedGroup.index;
      if (expandedGroupStartIndex === null) {
         return null;
      }
      let expandedGroupEndIndex = expandedGroupStartIndex + numDraggablesInExpandedGroup;

      if (!source) {
         return null;
      }

      if (source.index < expandedGroupStartIndex) {
         expandedGroupStartIndex -= 1;
         expandedGroupEndIndex -= 1;
      } else if (source.index < expandedGroupEndIndex) {
         expandedGroupEndIndex -= 1;
         numDraggablesInExpandedGroup -= 1;
      }

      if (destination.index <= expandedGroupStartIndex) {
         return {
            droppableInfo: RUBRIC_ROOT,
            index: destination.index,
         };
      } else if (destination.index < expandedGroupEndIndex) {
         return {
            droppableInfo: {
               type: ActivityRubricEntryType.group,
               id: expandedRubricItemGroupId,
            },
            index: destination.index - (expandedGroupStartIndex + 1),
         };
      } else {
         return {
            droppableInfo: RUBRIC_ROOT,
            index: destination.index - numDraggablesInExpandedGroup + 1,
         };
      }
   };

   const getSourceLocation = (
      draggedEntryInfo: DraggedEntryInfo,
      dragUpdate: DragUpdate | DragStart,
   ): Maybe<RubricEntryLocation> =>
      rubricEntryLocation(draggedEntryInfo.type, dragUpdate.source, dragUpdate.source);

   const getCurrentLocation = (draggedEntryInfo: DraggedEntryInfo, dragUpdate: DragUpdate) =>
      rubricEntryLocation(
         draggedEntryInfo.type,
         dragUpdate.source,
         dragUpdate.destination,
         dragUpdate.combine,
      );

   const canInsertIntoAdjacentGroup = (): boolean => {
      const { currentLocation, draggedEntryInfo } = state;
      if (!currentLocation || draggedEntryInfo?.type !== ActivityRubricEntryType.item) {
         return false;
      }
      const { droppableInfo } = currentLocation;
      if (droppableInfo.type === ActivityRubricEntryType.group) {
         return droppableInfo.id !== expandedRubricItemGroupId;
      }
      const otherTopLevelEntries = rubricEntries.filter(
         (i) => i.type !== draggedEntryInfo.type || i.id !== draggedEntryInfo.id,
      );
      const previousEntry = otherTopLevelEntries[currentLocation.index - 1];
      if (
         previousEntry &&
         previousEntry.type === ActivityRubricEntryType.group &&
         previousEntry.id !== expandedRubricItemGroupId
      ) {
         return true;
      }
      const nextEntry = otherTopLevelEntries[currentLocation.index];
      return !(
         !nextEntry ||
         nextEntry.type !== ActivityRubricEntryType.group ||
         nextEntry.id === expandedRubricItemGroupId
      );
   };

   const canToggleEntry = (): boolean => !state.isDraggingRubricEntry;

   const currentDropIsAllowed = (): boolean => {
      const { currentLocation, draggedEntryInfo } = state;
      if (!currentLocation || !draggedEntryInfo) {
         return false;
      }
      const { droppableInfo } = currentLocation;
      if (!currentLocation || droppableInfo.type === ActivityRubricEntryType.rubric) {
         return true;
      } else if (
         droppableInfo.type === ActivityRubricEntryType.group &&
         draggedEntryInfo.type === ActivityRubricEntryType.item
      ) {
         return true;
      } else {
         return false;
      }
   };

   const handleDragEnd = (result: DropResult): void => {
      let rubricItem;
      const updates: {
         [ActivityRubricEntryType.item]: Record<number, Partial<ActivityRubricItem>>;
         [ActivityRubricEntryType.group]: Record<number, Partial<ActivityRubricItemGroup>>;
      } = {
         [ActivityRubricEntryType.item]: {},
         [ActivityRubricEntryType.group]: {},
      };
      setState((prevState) => ({
         ...prevState,
         isDraggingRubricEntry: false,
         draggedEntryInfo: null,
         currentLocation: null,
      }));
      const draggedEntryInfo = getDraggedEntryInfo(result.draggableId);
      const sourceLocation = getSourceLocation(draggedEntryInfo, result);
      const destinationLocation = getCurrentLocation(draggedEntryInfo, result);
      if (
         destinationLocation &&
         sourceLocation &&
         !_.isEqual(sourceLocation, destinationLocation)
      ) {
         if (draggedEntryInfo.type === ActivityRubricEntryType.group) {
            if (destinationLocation.droppableInfo.type === ActivityRubricEntryType.group) {
               return;
            }
            const reorderedList = [...rubricEntries];
            const [rubricItemGroup] = reorderedList.splice(sourceLocation.index, 1);
            reorderedList.splice(destinationLocation.index, 0, rubricItemGroup);
            reorderedList.forEach((e, index) => (updates[e.type][e.id] = { index }));
         } else {
            const sourceList =
               sourceLocation.droppableInfo.type === ActivityRubricEntryType.rubric
                  ? rubricEntries
                  : topLevelRubricEntry(
                       ActivityRubricEntryType.group,
                       sourceLocation.droppableInfo.id,
                    )?.rubricItems;
            const destinationList =
               destinationLocation.droppableInfo.type === ActivityRubricEntryType.rubric
                  ? rubricEntries
                  : topLevelRubricEntry(
                       ActivityRubricEntryType.group,
                       destinationLocation.droppableInfo.id,
                    )?.rubricItems;
            if (!sourceList || !destinationList) {
               return;
            }
            if (sourceList === destinationList) {
               const reorderedList = [...sourceList];
               [rubricItem] = reorderedList.splice(sourceLocation.index, 1);
               reorderedList.splice(destinationLocation.index, 0, rubricItem);
               reorderedList.forEach((e, index) => (updates[e.type][e.id] = { index }));
            } else {
               const newSourceList = [...sourceList];
               [rubricItem] = newSourceList.splice(sourceLocation.index, 1);
               const newDestinationList = [...destinationList];
               newDestinationList.splice(destinationLocation.index, 0, rubricItem);
               newSourceList.forEach((e, index) => (updates[e.type][e.id] = { index }));
               newDestinationList.forEach((e, index) => (updates[e.type][e.id] = { index }));
               updates[ActivityRubricEntryType.item][rubricItem.id].groupId =
                  destinationLocation.droppableInfo.id;
            }
         }
         updateRubricEntries?.(updates);
      }
   };

   const handleDragStart = (start: DragStart): void => {
      const draggedEntryInfo = getDraggedEntryInfo(start.draggableId);
      if (draggedEntryInfo.type === ActivityRubricEntryType.group && expandedRubricItemGroupId) {
         toggleRubricItemGroup?.(expandedRubricItemGroupId);
      }
      setState((prevState) => ({
         ...prevState,
         isDraggingRubricEntry: true,
         draggedEntryInfo,
         currentLocation: getSourceLocation(draggedEntryInfo, start),
      }));
   };

   const handleDragUpdate = (update: DragUpdate): void => {
      const draggedEntryInfo = getDraggedEntryInfo(update.draggableId);
      setState((prevState) => ({
         ...prevState,
         draggedEntryInfo,
         isDraggingRubricEntry: true,
         currentLocation: getCurrentLocation(draggedEntryInfo, update),
      }));
   };

   const handleToggleRubricItem = (itemId: number): void => {
      if (canToggleEntry()) {
         toggleRubricItem?.(itemId);
      }
   };

   const handleToggleRubricItemGroup = (groupId: number): void => {
      if (canToggleEntry()) {
         toggleRubricItemGroup?.(groupId);
      }
   };

   const isApplied = (itemId: number): boolean =>
      !displayOnly && !!evaluation && evaluation.rubricItemIds.includes(itemId);

   return (
      <DragDropContext
         onDragStart={handleDragStart}
         onDragEnd={handleDragEnd}
         onDragUpdate={handleDragUpdate}
      >
         <Droppable
            isDropDisabled={displayOnly}
            droppableId='rubric'
            isCombineEnabled={canInsertIntoAdjacentGroup() && currentDropIsAllowed()}
         >
            {(provided) => (
               <div
                  className='rubric-entries-container'
                  ref={provided.innerRef}
                  data-tour='rubric-entries'
               >
                  {rubricEntries.map((entry, i) => {
                     if (entry.type === ActivityRubricEntryType.item) {
                        return (
                           <RubricItem
                              displayOnly={displayOnly}
                              dragIndex={getDragIndex(ActivityRubricEntryType.item, entry.id)}
                              isApplied={isApplied(entry.id)}
                              isDraggingRubricEntry={state.isDraggingRubricEntry}
                              item={entry}
                              key={getDraggableId(entry)}
                              scoringType={scoringType}
                              shortcutKey={i < numberKeys.length ? numberKeys[i] : ''}
                              deleteRubricItem={() => deleteRubricItem?.(entry.id)}
                              toggleRubricItem={() => handleToggleRubricItem?.(entry.id)}
                              updateRubricItem={(update) => updateRubricItem?.(entry.id, update)}
                           />
                        );
                     } else if (entry.type === ActivityRubricEntryType.group) {
                        const draggedEntryWithinGroup =
                           !!state.currentLocation &&
                           state.currentLocation.droppableInfo.id === entry.id;
                        return (
                           <RubricItemGroup
                              displayOnly={displayOnly}
                              draggedEntryWithinGroup={draggedEntryWithinGroup}
                              dragIndex={getDragIndex(ActivityRubricEntryType.group, entry.id)}
                              droppablePlaceholder={provided.placeholder}
                              evaluation={evaluation}
                              group={entry}
                              isExpanded={entry.id === expandedRubricItemGroupId}
                              isDraggingRubricEntry={state.isDraggingRubricEntry}
                              key={getDraggableId(entry)}
                              scoringType={scoringType}
                              shortcutKey={i < numberKeys.length ? numberKeys[i] : ''}
                              createRubricItem={(data) =>
                                 createRubricItem?.(data) ?? Promise.resolve(0)
                              }
                              deleteRubricItem={(itemId) => deleteRubricItem?.(itemId)}
                              deleteRubricItemGroup={() => deleteRubricItemGroup?.(entry.id)}
                              toggleActive={() => handleToggleRubricItemGroup(entry.id)}
                              toggleRubricItem={handleToggleRubricItem}
                              updateRubricItem={(itemId, update) =>
                                 updateRubricItem?.(itemId, update)
                              }
                              updateRubricItemGroup={(update) =>
                                 updateRubricItemGroup?.(entry.id, update)
                              }
                           />
                        );
                     }
                     return null;
                  })}
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
      </DragDropContext>
   );
};

export default React.memo(RubricEntries);
