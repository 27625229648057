import * as React from 'react';

import { Maybe } from '@models/Core';
import classnames from 'classnames';

export interface CursorProps {
   from: string;
   to: string;
   repeat?: number;
   delayBetweenIterations?: number;
   transitionDuration?: number;
   transitionDelay?: number;
   onCompletion?(): void;
}

enum CursorStep {
   inital = 'initial',
   dragging = 'dragging',
   complete = 'complete',
}

const Cursor: React.FC<CursorProps> = ({
   repeat = 1,
   delayBetweenIterations = 2,
   transitionDuration = 2,
   transitionDelay = 0,
   to,
   from,
   onCompletion,
}) => {
   const [style, setStyle] = React.useState<React.CSSProperties>({});
   const [step, setStep] = React.useState<Maybe<CursorStep>>(null);
   const [iterations, setIterations] = React.useState<number>(0);

   React.useEffect(() => {
      if (!from) {
         return;
      }

      if (step === null) {
         setInitialPosition();
      } else if (step === CursorStep.inital) {
         setFinalPosition();
      } else if (step === CursorStep.complete) {
         const newIterations = iterations + 1;
         setIterations(newIterations);
         if (newIterations < repeat || repeat === -1) {
            setTimeout(setInitialPosition, delayBetweenIterations * 1000);
         } else if (onCompletion) {
            onCompletion();
         }
      }
   }, [step]);

   const getFromBoundingRect = (): Maybe<DOMRect> => {
      if (typeof from === 'string') {
         const fromElement = document.querySelector(from);
         if (fromElement) {
            return fromElement.getBoundingClientRect();
         }
      }
      return undefined;
   };

   const getToBoundingRect = (): Maybe<DOMRect> => {
      if (typeof to === 'string') {
         const toElement = document.querySelector(to);
         if (toElement) {
            return toElement.getBoundingClientRect();
         }
      }
      return undefined;
   };

   const setInitialPosition = (): void => {
      const {
         top: fromTop,
         left: fromLeft,
         height: fromHeight,
         width: fromWidth,
      } = getFromBoundingRect() ?? {};
      if (!(fromTop && fromLeft && fromHeight && fromWidth)) {
         return;
      }
      setStyle({
         top: fromTop + fromHeight / 2,
         left: fromLeft + fromWidth / 4,
         transition: '',
      });
      setStep(CursorStep.inital);
   };

   const setFinalPosition = (): void => {
      setStep(CursorStep.dragging);
      const {
         top: toTop,
         left: toLeft,
         height: toHeight,
         width: toWidth,
      } = getToBoundingRect() ?? {};
      if (!(toTop && toLeft && toHeight && toWidth)) {
         return;
      }
      setStyle({
         top: toTop + toHeight / 2,
         left: toLeft + toWidth / 4,
         transition: `all ${transitionDuration}s linear ${transitionDelay}s`,
      });
   };

   const handleTransitionEnd = (): void => {
      if (step === CursorStep.dragging) {
         setStep(CursorStep.complete);
      }
   };

   if (step) {
      return (
         <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 28 28'
            className={classnames('onboarding-cursor', step)}
            onTransitionEnd={handleTransitionEnd}
            style={style}
         >
            <path fill='#FFF' d='M8.2 20.9v-16l11.6 11.6H13l-.4.1z' />
            <path fill='#FFF' d='M17.3 21.6l-3.6 1.5L9 12l3.7-1.5z' />
            <path d='M11.03 14.293l1.845-.774 3.096 7.376-1.844.775z' />
            <path d='M9.2 7.3v11.2l3-2.9.4-.1h4.8z' />
         </svg>
      );
   }
   return null;
};

export default React.memo(Cursor);
