import * as _ from 'lodash';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { oxfordComma } from '@helpers/StringFormatting';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconCursorSelectArea2 from '@icons/nova-solid/16-Selection&Cursors/cursor-select-area-2.svg';
import { Maybe } from '@models/Core';
import { CourseInfo } from '@models/Course/Course';
import ModuleService from '@services/ModuleService';
import ProductsService, { ProductPrice } from '@services/ProductsService';
import classnames from 'classnames';

import { AppStateContext } from '../../../AppState';
import mixPanelActions from '../../../Mixpanel';
import { ModuleSummary } from '@models/Course/Module';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import Loader from '@components/Loader';

interface ImportModulesModalProps {
   courseInfo: CourseInfo;
   importModule(moduleId: number): void;
   closeModal(): void;
}

const ImportModulesModal: React.FC<ImportModulesModalProps> = ({
   courseInfo,
   closeModal,
   importModule,
}) => {
   const { archivedCourses, currentCourses } = React.useContext<AppStateContext>(AppStateContext);
   const [currentCourseProductPrices, setCurrentCourseProductPrices] = React.useState<
      readonly ProductPrice[]
   >([]);
   const [hasConfirmedPotentialPriceChangeForCourse, setHasConfirmedPotentialPriceChangeForCourse] =
      React.useState<boolean>(false);
   const [importedModuleIds, setImportedModuleIds] = React.useState<readonly number[]>([]);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [modules, setModules] = React.useState<readonly ModuleSummary[]>([]);
   const [selectedCourseId, setSelectedCourseId] = React.useState<Maybe<number>>(null);

   React.useEffect(() => {
      if (selectedCourseId) {
         setIsLoading(true);
         setModules([]);
         Promise.all([
            ModuleService.getModulesSummary(selectedCourseId).then(setModules),
            ProductsService.getProducts(selectedCourseId).then((productResponse) => {
               setCurrentCourseProductPrices(productResponse.data.products);
            }),
         ]).then(() => {
            setHasConfirmedPotentialPriceChangeForCourse(false);
            setIsLoading(false);
         });
      }
   }, [selectedCourseId]);

   const handleCourseSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setSelectedCourseId(Number(event.target.value));
   };

   const formatProductsList = (productPrices: readonly ProductPrice[]): Maybe<string> => {
      const cheapestProductPrice = _.minBy(productPrices, (x) => x.totalPrice);
      if (cheapestProductPrice) {
         const productStrings = cheapestProductPrice.products.map((x) => `${x.name} ($${x.price})`);
         const result = oxfordComma(productStrings);
         return result;
      }
   };

   const getProductPriceWarning = (): string => {
      const formattedProducts =
         formatProductsList(currentCourseProductPrices) || 'additional content.';
      return `Using content from this course will require students to enter an access code or purchase access to ${formattedProducts}`;
   };

   const courseHasProducts = (): boolean => currentCourseProductPrices.length > 0;

   const renderProductPriceWarning = (): Maybe<React.ReactNode> => {
      if (courseHasProducts()) {
         return <div className='alert alert-danger'>{getProductPriceWarning()}</div>;
      }

      return null;
   };

   const handleAddModule = (moduleId: number): void => {
      if (courseHasProducts() && !hasConfirmedPotentialPriceChangeForCourse) {
         if (confirm(getProductPriceWarning())) {
            setHasConfirmedPotentialPriceChangeForCourse(true);
         } else {
            return;
         }
      }
      // Import module and sub modules
      const subModuleIds = modules.filter((i) => i.parentModuleId === moduleId).map((i) => i.id);
      setImportedModuleIds((prevImportedIds) => [...prevImportedIds, moduleId, ...subModuleIds]);
      importModule(moduleId);

      const sourceCourse = [...currentCourses, ...archivedCourses].find(
         (i) => i.id === selectedCourseId,
      );
      const sourceModule = modules.find((i) => i.id === moduleId);
      if (sourceModule && sourceCourse) {
         mixPanelActions.track(
            'Module Imported',
            snakeCaseKeys({
               courseId: courseInfo.id,
               courseName: courseInfo.name,
               id: moduleId,
               name: sourceModule.name,
               sourceCourseId: selectedCourseId,
               sourceCourseName: sourceCourse.name,
               submodules: subModuleIds.length,
            }),
         );
      }
   };

   const renderBody = (): React.ReactNode => {
      if (isLoading) {
         return <Loader />;
      }
      if (selectedCourseId === null) {
         return (
            <EmptyState
               description='Select a course to import its modules'
               heading='Select a Course'
               icon={<IconCursorSelectArea2 aria-hidden />}
            />
         );
      }
      if (modules.length === 0) {
         return (
            <EmptyState
               description='The selected course has no importable modules.'
               heading='No Modules'
               icon={<IconBinoculars aria-hidden />}
            />
         );
      }
      return (
         <>
            {renderProductPriceWarning()}
            <div className='modal-body-scroll'>
               {modules.map((i) => (
                  <div
                     key={i.id}
                     className={classnames('import-module', {
                        'sub-module': i.parentModuleId !== null,
                     })}
                  >
                     <div className='content-title'>{i.name}</div>
                     {importedModuleIds.includes(i.id) ? (
                        <div className='module-checked'>
                           <IconRadioTick className='icon-green' />
                        </div>
                     ) : (
                        <div className='module-add-button'>
                           <IconAddSmall onClick={() => handleAddModule(i.id)} />
                        </div>
                     )}
                  </div>
               ))}
            </div>
         </>
      );
   };

   return (
      <ModalDialog
         bodyClassName='modal-body'
         footerClassName='import-modules-modal-footer'
         heading='Import Modules'
         onClose={closeModal}
         width='medium'
      >
         <label className='field-title' htmlFor='select-course'>
            Course
         </label>
         <select onChange={handleCourseSelect} value={selectedCourseId || ''} id='select-course'>
            <option value='' disabled>
               Select a course
            </option>
            {currentCourses.length > 0 && (
               <optgroup label='Current'>
                  {currentCourses.map((i) => (
                     <option value={i.id} key={i.id}>
                        {i.name}
                     </option>
                  ))}
               </optgroup>
            )}
            {archivedCourses.length > 0 && (
               <optgroup label='Archived'>
                  {archivedCourses.map((i) => (
                     <option value={i.id} key={i.id}>
                        {i.name}
                     </option>
                  ))}
               </optgroup>
            )}
         </select>
         {renderBody()}
      </ModalDialog>
   );
};

export default ImportModulesModal;
