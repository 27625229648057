import * as React from 'react';

import { isInteger } from '@helpers/NumberUtils';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import { ActivityLogicAction, ActivityVariableType } from '@models/Activity';

import AccentTextbox from '@components/AccentTextbox';
import { NumberInput } from '@components/Core/Forms/NumberInput/NumberInput';
import { isGoToQuestionAction, isSetVariableAction } from '@components/Activity/Utils';
import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';

interface LogicActionProps {
   action: ActivityLogicAction;
   currentQuestionId?: string | number;
   onActionChange(update: Partial<ActivityLogicAction>): void;
   onActionRemove(): void;
}

const LogicAction: React.FC<LogicActionProps> = ({
   action: { actionType },
   action,
   currentQuestionId,
   onActionChange,
   onActionRemove,
}) => {
   const { logicActionOptions, variables, language } =
      React.useContext<ActivityContext>(ActivityContext);
   const selectedAction = logicActionOptions.find((i) => i.value === actionType);

   const type = isSetVariableAction(action)
      ? variables.find((i) => i.id === action.variableId)?.type
      : null;

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const { name, value } = event.target;
      onActionChange({ [name]: value });
   };

   const handleIntChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      onActionChange({ [name]: isInteger(value) ? Number(value) : value });
   };

   const renderVariable = (): React.ReactNode => {
      if (!isSetVariableAction(action)) {
         return null;
      }
      if (type === ActivityVariableType.string) {
         return (
            <AccentTextbox
               value={action.variableValue as string}
               language={language}
               name='variableValue'
               onChange={handleChange}
            />
         );
      } else if (type === ActivityVariableType.boolean) {
         return (
            <select
               name='variableValue'
               onChange={handleChange}
               value={action.variableValue?.toString() ?? ''}
            >
               <option value=''>Select</option>
               <option value='true'>True</option>
               <option value='false'>False</option>
            </select>
         );
      } else if (
         type &&
         [ActivityVariableType.float, ActivityVariableType.integer].includes(type)
      ) {
         return (
            <NumberInput
               name='variableValue'
               onValueChange={(value) => onActionChange({ variableValue: value })}
               value={action.variableValue as number}
               integer={type === ActivityVariableType.integer}
            />
         );
      }
      return null;
   };

   return (
      <div className='logic-action'>
         <select value={actionType ?? ''} name='actionType' onChange={handleChange}>
            <option value='' disabled>
               Select Action
            </option>
            {logicActionOptions.map((i) => (
               <option value={i.value} key={i.value}>
                  {i.label}
               </option>
            ))}
         </select>
         {isGoToQuestionAction(action) && selectedAction && selectedAction.options && (
            <select value={action.questionId ?? ''} name='questionId' onChange={handleChange}>
               <option value='' disabled>
                  {selectedAction.placeholder}
               </option>
               {selectedAction.options.map((i) => (
                  <option value={i.id} key={i.id} disabled={i.id === currentQuestionId}>
                     {i.label}
                  </option>
               ))}
            </select>
         )}
         {isSetVariableAction(action) && selectedAction && selectedAction.options && (
            <>
               <select value={action.variableId ?? ''} name='variableId' onChange={handleIntChange}>
                  <option value='' disabled>
                     {selectedAction.placeholder}
                  </option>
                  {selectedAction.options.map((i) => (
                     <option value={i.id} key={i.id}>
                        {i.label}
                     </option>
                  ))}
               </select>
               {type && <span className='to-separator'>to</span>}
               {renderVariable()}
            </>
         )}
         <div className='icon-action-wrap'>
            <div className='icon-action' onClick={onActionRemove}>
               <IconBin />
            </div>
         </div>
      </div>
   );
};

export default LogicAction;
