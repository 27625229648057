import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import AxiosService from '@services/AxiosService';

export type BookstoreCodeSummary = {
   id: number;
   code: string;
   type: string;
   productId?: number;
   productName?: number;
   bookstoreCodeActivationId?: number;
};

export type GetBookstoreCodeParams = {
   quantity: number;
   productId: number;
   distributedTo: string;
};

export type ProductSummary = {
   name: string;
   id: number;
   isbn: string;
};

const search = (filters: PagedSearchFilters): Promise<PagedResponse<BookstoreCodeSummary>> =>
   AxiosService.get<PagedResponse<BookstoreCodeSummary>>('/api/bookstore_codes/search', {
      params: {
         ...filters,
      },
   }).then((response) => response.data);

const generateCodes = (data: GetBookstoreCodeParams): Promise<Blob> =>
   AxiosService.post<Blob>(
      '/api/bookstore_codes/download',
      {
         quantity: data.quantity,
         product_id: data.productId,
         distributed_to: data.distributedTo,
      },
      { downloadFile: true },
   ).then((response) => response.data);

const getProductsWithCanHaveBookstoreCodes = (): Promise<ProductSummary[]> =>
   AxiosService.get<{ products: ProductSummary[] }>(
      '/api/bookstore_codes/get_products_with_can_have_bookstore_codes',
   ).then((response) => response.data.products);

export default {
   search,
   generateCodes,
   getProductsWithCanHaveBookstoreCodes,
};
