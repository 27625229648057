import * as React from 'react';

import parseHtml, { isTag, isText } from '@helpers/ParseHtml';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { ActivityMode, FillBlanksResponse, FillBlanksResponseEntry } from '@models/Activity';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { ChildNode } from 'domhandler';
import { DOMNode } from 'html-react-parser';

import Constants from '../../../../../Constants';
import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import InfoTooltip from '@components/InfoTooltip';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';
import { Draggable, WordBank } from './DraggableWords';
import { DraggableProps } from './DraggableWords/Draggable';
import FillBlanksDroppable from './FillBlanksDroppable';
import FillBlanksInput from './FillBlanksInput';
import FillBlanksResponseDistribution from './FillBlanksResponseDistribution';
import FillBlanksSelect from './FillBlanksSelect';

interface BlankElementProps {
   blankId: string;
   attribs: Record<string, string>;
   index: number;
   value: string;
   className: string;
   joinLeft: boolean;
   joinRight: boolean;
}

interface FillBlanksPromptProps extends CommonPromptProps {
   content: string;
   draggable: boolean;
   language: Language;
   mode: ActivityMode;
   response: FillBlanksResponse;
   showMissed: boolean;
   showUniqueWords: boolean;
   wordBank: Maybe<readonly string[]>;
   setResponse(response: FillBlanksResponse, callback?: () => void): void;
}

const FillBlanksPrompt: React.FC<FillBlanksPromptProps> = ({
   content,
   draggable,
   isClosed,
   language,
   promptId,
   response,
   showMissed,
   showUniqueWords,
   wordBank,
   saveResponse,
   setResponse,
}) => {
   /** blankId of rendered response distribution */
   const [selectedBlankId, setSelectedBlankId] = React.useState<Maybe<string>>(null);
   /** droppableId of starting point for currently dragged item */
   const [dragSourceId, setDragSourceId] = React.useState<string | null>(null);
   /**
    *  old activities need to use the index unless we somehow go through the database
    * parse and update them.*/
   const [dragSourceIndex, setDragSourceIndex] = React.useState<number | null>(null);
   const [draggedValue, setDraggedValue] = React.useState<string | null>(null);

   const usesBlankIds = response.some((i) => !!i.blankId);

   const handleDragStart = (sourceId: string, value: string, index?: number): void => {
      setDragSourceId(sourceId);
      setDragSourceIndex(index === undefined ? null : index);
      setDraggedValue(value);
   };

   const handleDragEnd = (): void => {
      setDragSourceId(null);
      setDragSourceIndex(null);
   };

   const handleDrop = (
      _event: React.DragEvent<HTMLElement>,
      dragTargetId: string,
      dragTargetIndex?: number,
   ): void => {
      if (!dragSourceId && dragSourceIndex === null) {
         console.warn(
            'There is no source in the drag context. DnD between prompts is not allowed.',
         );
         return;
      }
      if (!draggedValue) {
         console.warn('There is no dragged value.');
         return;
      }

      // if the source and target are the same thing, we shouldn't do anything
      if (dragSourceId === dragTargetId && dragSourceIndex === dragTargetIndex) {
         return;
      }
      const updatedEntries = response.map((i, index) => {
         if (
            (dragTargetId && i.blankId === dragTargetId) ||
            (dragTargetIndex !== undefined && index === dragTargetIndex)
         ) {
            return {
               ...i,
               response: draggedValue || '',
               modified: true,
            };
         } else if (
            (dragSourceId && i.blankId === dragSourceId) ||
            (dragSourceIndex !== undefined && index === dragSourceIndex)
         ) {
            return {
               ...i,
               response: '',
               modified: true,
            };
         }

         setDragSourceId(null);
         setDragSourceIndex(null);
         setDraggedValue(null);
         return i;
      });
      setResponse(updatedEntries, saveResponse);
   };

   const handleWordBankDoubleClick = (
      _event: React.MouseEvent<HTMLSpanElement>,
      word: string,
   ): void => {
      const nextEmptyIndex = response.findIndex((i) => !i.response);
      if (nextEmptyIndex === -1) {
         return;
      }
      const updatedEntries = response.map((i, index) =>
         index === nextEmptyIndex
            ? {
                 ...i,
                 response: word,
                 modified: true,
              }
            : i,
      );
      setResponse(updatedEntries, saveResponse);
   };

   const handleUpdateResponse = (value: string, index: number, blankId: string): void => {
      const updatedResponse = [...response];
      const entry = getResponseEntry(updatedResponse, index, blankId);
      if (entry) {
         entry.response = value;
         entry.modified = true;
         setResponse(updatedResponse, saveResponse);
      }
   };

   const getResponseEntry = (
      responses: FillBlanksResponse,
      index: number,
      blankId: string,
   ): Maybe<FillBlanksResponseEntry> =>
      usesBlankIds ? responses.find((x) => x.blankId === blankId && blankId) : responses[index];

   const renderDraggable = ({
      className,
      value,
      onDragStart,
      onDragEnd,
      onRemove,
   }: Omit<DraggableProps, 'children'> & { onRemove(): void }) => (
      <Draggable
         className={className}
         onDragStart={onDragStart}
         onDragEnd={onDragEnd}
         data-test={`fill-blanks-draggable-${value}`}
         disabled={isClosed}
         value={value}
      >
         {({ isDragging }) => (
            <>
               <span>{value}</span>
               {!isDragging && (
                  <Tippy content='Remove' delay={[500, 0]}>
                     <div className='draggable-remove-icon' onClick={onRemove}>
                        <IconClose />
                     </div>
                  </Tippy>
               )}
            </>
         )}
      </Draggable>
   );

   const clearEntry = (blankId: string, index: number) => {
      if (blankId) {
         setResponse(
            response.map((i) =>
               i.blankId === blankId ? { ...i, response: '', modified: true } : i,
            ),
         );
      } else {
         setResponse(
            response.map((i, idx) => (idx === index ? { ...i, response: '', modified: true } : i)),
         );
      }
   };

   const createElement = ({
      blankId,
      attribs,
      index,
      value,
      className,
      joinLeft,
      joinRight,
   }: BlankElementProps): JSX.Element => {
      const options = attribs['data-options']?.split('|').filter((i) => !!i) ?? null;
      const commonProps = {
         blankId,
         className,
         joinLeft,
         joinRight,
         language,
         value,
         disabled: isClosed,
         onValueChange: (newValue: string) => handleUpdateResponse(newValue, index, blankId),
      };

      if (draggable && wordBank) {
         const selectedResponses = response.map((x) => x.response);
         return (
            <FillBlanksDroppable
               {...commonProps}
               selectedResponses={selectedResponses}
               wordBank={wordBank}
               onDrop={(e) => handleDrop(e, blankId, index)}
               renderDraggable={() =>
                  renderDraggable({
                     className,
                     value,
                     onDragEnd: handleDragEnd,
                     onDragStart: () => handleDragStart(blankId, value, index),
                     onRemove: () => clearEntry(blankId, index),
                  })
               }
               selectProps={{ onBlur: saveResponse, options }}
            />
         );
      } else if (options) {
         return <FillBlanksSelect {...commonProps} onBlur={saveResponse} options={options} />;
      } else {
         return (
            <FillBlanksInput
               {...commonProps}
               onDoubleClick={() => setSelectedBlankId(blankId)}
               {...attribs}
            />
         );
      }
   };

   const checkAndRetrieveTagAttributes = (
      tag: DOMNode,
   ): Maybe<{
      text: Maybe<string>;
      attribs: Record<string, string>;
      name: string;
      children: ChildNode[];
   }> => {
      if (isTag(tag) && tag.attribs) {
         const child = tag.children[0];
         const text = !!child && isText(child) ? child.data : null;
         return { text, attribs: tag.attribs, name: tag.name, children: tag.children };
      }
      return null;
   };

   const buildClassName = (entry: FillBlanksResponseEntry, text: Maybe<string>): string => {
      const unmodified = entry.modified === false;
      const correctAnswer = text;
      const isCorrect = unmodified && entry.correct === true;
      const isIncorrect = unmodified && entry.correct === false;
      const isIncorrectAndAnswered = unmodified && entry.correct === false;
      const showAnswer = showMissed && isIncorrect && correctAnswer !== undefined;

      return classnames({
         correct: isCorrect,
         incorrect: isIncorrectAndAnswered || showAnswer,
      });
   };

   const handleArabicLetterJoins = (
      tag: DOMNode,
      responseStr: string,
   ): { joinRight: boolean; joinLeft: boolean } => {
      const arabicLetterPattern = new RegExp(Constants.regex.allArabicLetters);
      const noLeftJoinLetterPattern = new RegExp(Constants.regex.arabicLettersWithNoLeftJoin);

      const prevStr = !!tag.prev && isText(tag.prev) ? tag.prev.data : '';
      const nextStr = !!tag.next && isText(tag.next) ? tag.next.data : '';
      const firstChar = responseStr.charAt(0);
      const lastChar = responseStr.charAt(responseStr.length - 1);
      const prevIsArabic = arabicLetterPattern.test(prevStr.charAt(prevStr.length - 1));
      const nextIsArabic = arabicLetterPattern.test(nextStr.charAt(0));

      return {
         joinRight:
            prevIsArabic &&
            !noLeftJoinLetterPattern.test(prevStr.charAt(prevStr.length - 1)) &&
            arabicLetterPattern.test(firstChar),
         joinLeft:
            nextIsArabic &&
            !!lastChar &&
            !noLeftJoinLetterPattern.test(lastChar) &&
            arabicLetterPattern.test(lastChar),
      };
   };

   const renderBlanks = (): React.ReactNode | readonly React.ReactNode[] | string => {
      let currentIndex = 0;
      return parseHtml(content, {
         replace: (tag) => {
            const tagAttr = checkAndRetrieveTagAttributes(tag);
            if (tagAttr) {
               const { text, attribs, name, children } = tagAttr;
               if (attribs['data-blank']) {
                  const blankId = attribs['data-id'];
                  const index = currentIndex++;
                  const entry = getResponseEntry(response, index, blankId);
                  if (!entry) {
                     return;
                  }
                  const value = entry.response;
                  const unmodified = entry.modified === false;
                  const isIncorrect = unmodified && entry.correct === false;
                  const correctAnswer = text;
                  const showAnswer = showMissed && isIncorrect && correctAnswer !== undefined;
                  const className = buildClassName(entry, text);
                  const { joinLeft, joinRight } = handleArabicLetterJoins(tag, value);

                  const element = createElement({
                     blankId,
                     attribs,
                     index,
                     value,
                     className,
                     joinLeft,
                     joinRight,
                  });

                  if (showAnswer) {
                     return (
                        <Tippy content={`Correct Answer: ${correctAnswer}`}>
                           <span className='tippy-wrapper'>{element}</span>
                        </Tippy>
                     );
                  }
                  return element;
               } else if (attribs['data-hint'] && children.length === 1 && text) {
                  return <InfoTooltip>{text}</InfoTooltip>;
               } else if (name === 'audio' && attribs['data-player-size']) {
                  return (
                     <AudioPlayer
                        src={attribs.src}
                        size={attribs['data-player-size'] as AudioPlayerSize}
                        className={classnames(attribs.class)}
                     />
                  );
               }
            }
            return null;
         },
      });
   };

   return (
      <div className='fill-blanks-form'>
         {!!wordBank && (
            <WordBank
               className={language}
               words={wordBank}
               showUniqueWords={showUniqueWords}
               onWordDragStart={(_, value) => handleDragStart('WORD_BANK', value)}
               onWordDoubleClick={handleWordBankDoubleClick}
               onWordDragEnd={handleDragEnd}
               onDrop={(e) => handleDrop(e, 'WORD_BANK')}
               usedWords={response.map((i) => i.response).filter((i) => !!i)}
               draggable={draggable}
            />
         )}
         {renderBlanks()}
         <FillBlanksResponseDistribution
            selectedBlankId={selectedBlankId}
            promptId={promptId}
            setSelectedBlankId={setSelectedBlankId}
         />
      </div>
   );
};

export default FillBlanksPrompt;
