import * as React from 'react';

import { ID } from '@models/Core';
import classnames from 'classnames';

import Droppable from './Core/DragAndDropKit/Droppable';

interface Props<T> {
   actionDescription: React.ReactNode;
   actionIcon: React.ReactNode;
   badge: React.ReactNode;
   data: T;
   defaultDescription: React.ReactNode;
   defaultIcon: React.ReactNode;
   id: ID;
   isDraggingPrompt: boolean;
}

const DropOverlay = <T extends object>(props: Props<T>) => (
   <Droppable className='drop-overlay' data={props.data} id={props.id}>
      {props.badge}
      <div
         className={classnames('drop-instructions', {
            hover: props.isDraggingPrompt,
         })}
      >
         {props.isDraggingPrompt ? (
            <>
               <div className='flex animation-move-up-down'>{props.actionIcon}</div>
               {props.actionDescription}
            </>
         ) : (
            <>
               <div className='flex animation-move-up-down'>{props.defaultIcon}</div>
               {props.defaultDescription}
            </>
         )}
      </div>
   </Droppable>
);

export default DropOverlay;
