import * as _ from 'lodash';

import FileSize from '@helpers/FileSize';
import { getPathFromUrl } from '@helpers/QueryParameter';
import { Maybe } from '@models/Core';
import { matchPath } from 'react-router-dom';

import Constants from '../../../Constants';
import { SectionDates } from '@models/Course/Assignment';
import {
   AssignableModuleItem,
   ContentModuleItem,
   ModuleItem,
   ModuleItemActivity,
   ModuleItemFile,
   ModuleItemLesson,
   ModuleItemLink,
   ModuleItemType,
   ModuleItemVideo,
   ModuleItemVocabSet,
} from '@models/Course/ModuleItem';

export enum ModuleMode {
   edit = 'edit',
   create = 'create',
   view = 'view',
}

const MAX_SECRET_CODE_LENGTH = 32;
const MIN_ATTEMPTS_ALLOWED = 2;
const MIN_SECRET_CODE_LENGTH = 3;
const MIN_TIME_LIMIT = 1;
const MAX_NAME_LENGTH = 90;
const MAX_LINK_LENGTH = 1024;
const MAX_FILE_SIZE = FileSize.getNumBytesFromMegabytes(200);

const ACTIVITY_ASSIGNMENT_PROPERTIES = [
   'allowLateSubmission',
   'attemptsAllowed',
   'endDate',
   'gradeAutomatically',
   'gradingCategoryId',
   'lockAfterClosed',
   'secretCode',
   'sectionDates',
   'shortName',
   'startDate',
   'timeLimit',
   'trackStudentEvents',
];

const VOCAB_SET_ASSIGNMENT_PROPERTIES = [
   'endDate',
   'sectionDates',
   'shortName',
   'startDate',
   'shortName',
];

const getModuleMode = (pathname: string): Maybe<ModuleMode> => {
   const {
      routes: {
         courses: { newModule, editModule, viewModule },
      },
   } = Constants;
   const strippedPathname = getPathFromUrl(pathname);
   const matchesNew = matchPath(newModule, strippedPathname) !== null;
   const matchesEdit = matchPath(editModule, strippedPathname) !== null;
   const matchesView = matchPath(viewModule, strippedPathname) !== null;
   if (matchesNew) {
      return ModuleMode.create;
   } else if (matchesEdit) {
      return ModuleMode.edit;
   } else if (matchesView) {
      return ModuleMode.view;
   } else {
      return null;
   }
};

const isActivity = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemActivity =>
   item.itemType === ModuleItemType.activity;

const isLesson = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemLesson =>
   item.itemType === ModuleItemType.lesson;

const isFile = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemFile =>
   item.itemType === ModuleItemType.file;

const isLink = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemLink =>
   item.itemType === ModuleItemType.link;

const isVideo = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemVideo =>
   item.itemType === ModuleItemType.video;

const isVocabSet = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ModuleItemVocabSet =>
   item.itemType === ModuleItemType.vocabSet;

const isContent = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is ContentModuleItem =>
   isActivity(item) || isVideo(item) || isLesson(item) || isVocabSet(item);

const isAssignable = (item: ModuleItem | Omit<ModuleItem, 'id'>): item is AssignableModuleItem =>
   isActivity(item) || isVocabSet(item);

const isAssigned = (item: ModuleItem): boolean => isAssignable(item) && !!item.isAssigned;

const getNameError = (name: string): Maybe<string> => {
   if (name?.length === 0) {
      return 'Cannot be empty';
   } else if (name?.length >= MAX_NAME_LENGTH) {
      return `Maximum length is ${MAX_NAME_LENGTH} characters`;
   }
   return null;
};

const getLinkError = (link: string): Maybe<string> => {
   if (link?.length === MAX_LINK_LENGTH) {
      return `Maximum length is ${MAX_NAME_LENGTH} characters`;
   }
   return null;
};

const getAssignmentStartDateError = (
   startDate: Date,
   endDate: Date,
   courseStartDate: Date,
   courseEndDate: Date,
   allowNull = false,
): Maybe<string> => {
   if (!startDate) {
      return allowNull ? null : 'Cannot be empty';
   } else if (startDate < courseStartDate) {
      return 'Cannot be before course start date';
   } else if (endDate && startDate > endDate) {
      return 'Cannot be after assignment end date';
   } else if (startDate > courseEndDate) {
      return 'Cannot be after course end date';
   }
   return null;
};

const getAssignmentEndDateError = (
   startDate: Date,
   endDate: Date,
   courseStartDate: Date,
   courseEndDate: Date,
   allowNull = false,
): Maybe<string> => {
   if (!endDate) {
      return allowNull ? null : 'Cannot be empty';
   } else if (endDate < courseStartDate) {
      return 'Cannot be before course start date';
   } else if (endDate && startDate > endDate) {
      return 'Cannot be before assignment start date';
   } else if (endDate > courseEndDate) {
      return 'Cannot be after course end date';
   }
   return null;
};

const getSecretCodeError = (secretCode: string): Maybe<string> => {
   if (secretCode?.length === 0) {
      return 'Cannot be empty';
   } else if (secretCode?.length < MIN_SECRET_CODE_LENGTH) {
      return `Must be at least ${MIN_SECRET_CODE_LENGTH} characters`;
   } else if (secretCode?.length >= MAX_SECRET_CODE_LENGTH) {
      return `Maximum length is ${MAX_SECRET_CODE_LENGTH} characters`;
   }
   return null;
};

const getAttemptsAllowedError = (attemptsAllowed: number): Maybe<string> => {
   if (attemptsAllowed < MIN_ATTEMPTS_ALLOWED) {
      return `Must be at least ${MIN_ATTEMPTS_ALLOWED}`;
   }
   return null;
};

const getTimeLimitError = (timeLimit: number): Maybe<string> => {
   if (timeLimit < MIN_TIME_LIMIT) {
      return `Must be at least ${MIN_TIME_LIMIT}`;
   }
   return null;
};

const checkSectionDatesValid = (
   sectionDates: SectionDates,
   courseStartDate: Date,
   courseEndDate: Date,
): boolean =>
   _.flatten(
      Object.values(sectionDates).map((i) => [
         getAssignmentStartDateError(i.startDate, i.endDate, courseStartDate, courseEndDate),
         getAssignmentEndDateError(i.startDate, i.endDate, courseStartDate, courseEndDate),
      ]),
   ).every((i) => i === null);

export {
   ACTIVITY_ASSIGNMENT_PROPERTIES,
   checkSectionDatesValid,
   getAssignmentEndDateError,
   getAssignmentStartDateError,
   getAttemptsAllowedError,
   getLinkError,
   getModuleMode,
   getNameError,
   getSecretCodeError,
   getTimeLimitError,
   isActivity,
   isAssignable,
   isAssigned,
   isContent,
   isFile,
   isLesson,
   isLink,
   isVideo,
   isVocabSet,
   MAX_FILE_SIZE,
   MAX_LINK_LENGTH,
   MAX_NAME_LENGTH,
   MAX_SECRET_CODE_LENGTH,
   MIN_ATTEMPTS_ALLOWED,
   MIN_SECRET_CODE_LENGTH,
   MIN_TIME_LIMIT,
   VOCAB_SET_ASSIGNMENT_PROPERTIES,
};
