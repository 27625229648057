/**
 * Recursively removes keys
 * @param {any} obj - Object to target
 * @param {readonly string[]} keys - Array of keys to remove
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const omitDeep = (obj: any, keys: readonly string[]): void => {
   let index;
   for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
         switch (typeof obj[prop]) {
            case 'string':
               index = keys.indexOf(prop);
               if (index > -1) {
                  // tslint:disable-next-line:no-delete
                  delete obj[prop];
               }
               break;
            case 'object':
               index = keys.indexOf(prop);
               if (index > -1) {
                  delete obj[prop];
               } else {
                  omitDeep(obj[prop], keys);
               }
               break;
         }
      }
   }
};

export default omitDeep;
