import * as React from 'react';

import { formatDateConcise } from '@helpers/FormatTime';
import IconUserMagnify from '@icons/nova-line/07-Users/user-magnify.svg';
import Appearance from '@models/Appearance';
import ISubmissionEvent from '@models/ISubmissionEvent';
import Tippy from '@tippyjs/react';

import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';

interface SubmissionEventsModalProps {
   heading?: string;
   events: readonly ISubmissionEvent[];
   closeModal(): void;
}

const SubmissionEventsModal: React.FC<SubmissionEventsModalProps> = ({
   events,
   heading,
   closeModal,
}) => {
   const columns: readonly Column<ISubmissionEvent>[] = [
      { id: 'id', header: 'Id', cell: (i) => i.id, show: false },
      {
         id: 'createdOn',
         header: 'Created On',
         cell: (i) => formatDateConcise(i.createdOn),
         cellClassName: 'col-createdOn',
      },
      {
         id: 'description',
         header: 'Description',
         cell: (i) => (
            <div className='space-between'>
               <span className={i.level ? `event-level-${i.level}` : ''}>{i.description}</span>

               {!i.imageUrl ? null : (
                  <Tippy content='Evidence'>
                     <div>
                        <Link to={i.imageUrl} external>
                           <IconUserMagnify />
                        </Link>
                     </div>
                  </Tippy>
               )}
            </div>
         ),
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='extend-assignment-modal'
         heading={heading || 'Student Events'}
         onClose={closeModal}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[{ text: 'Cancel', onClick: closeModal }]}
      >
         {events?.length && (
            <Table<ISubmissionEvent>
               className='submission-event-table'
               columns={columns}
               defaultSortBy={[{ id: 'id', desc: true }]}
               rowKey='id'
               rows={events}
            />
         )}
      </ModalDialog>
   );
};

export default React.memo(SubmissionEventsModal);
