import * as _ from 'lodash';
import * as React from 'react';

import { createAudioElement } from '@helpers/Audio';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import { randomShortId } from '@helpers/RandomStringUtils';
import useWindowSize from '@hooks/use-window-size';
import IconClose from '@icons/nova-line/02-Status/close.svg';
import Appearance from '@models/Appearance';
import Content from '@models/Content';
import { Maybe } from '@models/Core';
import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';
import VocabSet from '@models/VocabSet';
import HttpService from '@services/HttpService';
import classNames from 'classnames';
import { DraggableData, DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { useNavigate, useParams } from 'react-router';

import Constants from '../../Constants';
import mixPanelActions from '../../Mixpanel';
import ModalDialog from '@components/Core/ModalDialog';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import Confetti from '@components/Onboarding/Confetti';
import Flag from '@components/VocabSession/Header/Flag';
import {
   getFilteredTerms,
   getRandomNonOverlappingPosition,
   isTileWithinBoard,
   overlaps,
   overlapsAnyTiles,
} from './Helpers';
import { Tile as TileType, TileState } from './Models';
import Tile from './Tile';

interface TermWithAudio extends IVocabTerm {
   audio: Maybe<HTMLAudioElement>;
}

const NUM_TERMS = 6;
const STATE_TIMEOUT = 500;

const VocabMatching: React.FC = () => {
   const { soundEffectsFolder } = Constants;

   const { vocabSetId: strVocabSetId } = useParams<{ vocabSetId: string }>();
   const navigate = useNavigate();
   const vocabSetId = isInteger(strVocabSetId) ? Number(strVocabSetId) : null;

   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [progress, setProgress] = React.useState<Maybe<Record<number, IVocabTermProgress>>>(null);
   const [terms, setTerms] = React.useState<readonly TermWithAudio[]>();
   const [settings, setSettings] = React.useState<Maybe<Content>>(null);
   const [showStartModal, setShowStartModal] = React.useState<boolean>(false);
   const [tiles, setTiles] = React.useState<readonly TileType[]>([]);
   const [zIndex, setZIndex] = React.useState<number>(1);
   const [sessionId, setSessionId] = React.useState<Maybe<number>>(null);
   const [showConfetti, setShowConfetti] = React.useState<boolean>(false);
   const [isGameOver, setIsGameOver] = React.useState<boolean>(false);
   const [totalMatches, setTotalMatches] = React.useState<number>(0);

   const [seconds, setSeconds] = React.useState<number>(0);

   const [windowWidth, windowHeight] = useWindowSize();

   const timerRef = React.useRef<Maybe<NodeJS.Timer>>(null);

   const navigateBack = () => navigate(-1);

   // Initial Fetch and Setup
   React.useEffect(() => {
      if (vocabSetId !== null && !tiles.length && windowHeight && windowWidth) {
         setIsFetching(true);
         Promise.all([
            HttpService.postWithAuthToken<{ id: number; msg: string }>(
               `/api/vocab_sets/${vocabSetId}/matching_sessions`,
            ).then((sessionsResponse) => setSessionId(sessionsResponse.data.id)),
            HttpService.getWithAuthToken<VocabSet<true>>(
               `/api/content/vocab_sets/${vocabSetId}?progress=true`,
            ).then(async (response) => {
               const {
                  terms: responseTerms,
                  canEdit,
                  progress: responseProgress,
                  ...responseSettings
               } = response.data;
               const keyedProgress = _.keyBy(responseProgress, 'id');
               setProgress(keyedProgress);
               setSettings(responseSettings);
               setTerms(
                  _.sortBy(responseTerms, 'index').map((i) => ({
                     ...i,
                     audio: i.audioUrl
                        ? createAudioElement(i.audioUrl, 'Setup vocab matching')
                        : null,
                  })),
               );
               const filteredTerms = getFilteredTerms(responseTerms, keyedProgress, NUM_TERMS);
               const makeDefault = (i: IVocabTerm): TileType => ({
                  termId: i.id,
                  position: { x: 0, y: 0 },
                  height: 0,
                  width: 0,
                  state: TileState.neutural,
                  id: randomShortId(),
                  text: '',
               });
               setTiles([
                  ...filteredTerms.map((i) => ({
                     ...makeDefault(i),
                     text: i.term,
                  })),
                  ...filteredTerms.map((i) => ({
                     ...makeDefault(i),
                     text: i.definition,
                  })),
               ]);
            }),
         ]).then(() => {
            setIsFetching(false);
            setShowStartModal(true);
         });
      }
   }, [vocabSetId, windowHeight, windowWidth]);

   // Handle Window Resize
   React.useEffect(() => {
      if (!tiles.length || !tiles.every((i) => i.position.x > 0 && i.position.y >= 0)) {
         return;
      }
      setTiles((prevTiles) => {
         const updatedTiles: TileType[] = [];
         prevTiles.forEach((tile) => {
            if (isTileWithinBoard(tile, windowHeight, windowWidth)) {
               updatedTiles.push(tile);
            } else {
               const newPosition = getRandomNonOverlappingPosition(
                  tile,
                  updatedTiles,
                  windowHeight,
                  windowWidth,
               );
               updatedTiles.push({ ...tile, position: newPosition });
            }
         });
         return updatedTiles;
      });
   }, [windowHeight, windowWidth]);

   // Handle Game Over
   React.useEffect(() => {
      if (
         tiles?.length &&
         tiles?.every((i) => i.state === TileState.hidden) &&
         !isGameOver &&
         settings
      ) {
         if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
         }
         setShowConfetti(true);
         setIsGameOver(true);
         const audio = new Audio(`${soundEffectsFolder}/game_over.mp3`);
         audio.play();
         mixPanelActions.track(
            'Vocab Matching Completed',
            snakeCaseKeys({
               id: sessionId,
               language: settings.language,
               name: settings.name,
               time: _.round(seconds, 1),
               accuracy: getAccuracy(),
               termCount: tiles.length / 2,
               vocabSetId,
            }),
         );
         HttpService.patchWithAuthToken(
            `/api/vocab_sets/${vocabSetId}/matching_sessions/${sessionId}`,
            snakeCaseKeys({
               accuracy: getAccuracy(),
               completed: true,
               time: _.round(seconds, 1),
            }),
         );
      }
   }, [tiles]);

   const setRandomPositions = (): void => {
      setTiles((prevTiles) => {
         const updatedTiles: TileType[] = [];
         prevTiles.forEach((tile) => {
            const newPosition = getRandomNonOverlappingPosition(
               tile,
               updatedTiles,
               windowHeight,
               windowWidth,
            );
            updatedTiles.push({ ...tile, position: newPosition });
         });
         return updatedTiles;
      });
   };

   const setRandomPosition = (tile: TileType): void => {
      setTiles((prevTiles) =>
         prevTiles.map((i) =>
            i.id === tile.id
               ? {
                    ...i,
                    position: getRandomNonOverlappingPosition(
                       tile,
                       prevTiles,
                       windowHeight,
                       windowWidth,
                    ),
                 }
               : i,
         ),
      );
   };

   const getOverlappingTile = (tileId: string): Maybe<TileType> => {
      const tile = tiles?.find((i) => i.id === tileId);
      if (tile) {
         return tiles.filter((i) => i.id !== tileId).find((i) => overlaps(tile, i)) ?? null;
      }
      return null;
   };

   const handleStartGame = (): void => {
      setShowStartModal(false);
      setRandomPositions();
      timerRef.current = setInterval(() => setSeconds((prevSeconds) => prevSeconds + 0.1), 100);
   };

   const updateTilePosition = (tileId: string, x: number, y: number): void => {
      setTiles((prevTiles) =>
         prevTiles.map((i) => (i.id === tileId ? { ...i, position: { x, y } } : i)),
      );
   };

   const updateTileState = (tileId: string, tileState: TileState): void => {
      setTiles((prevTiles) =>
         prevTiles.map((i) => (i.id === tileId ? { ...i, state: tileState } : i)),
      );
   };

   const handleStop =
      (tileId: string): DraggableEventHandler =>
      (): void => {
         const tile = tiles.find((i) => i.id === tileId);
         if (!tile || !terms) {
            return;
         }
         const overlappingTile = getOverlappingTile(tileId);
         if (overlappingTile) {
            // correct
            if (tile.termId === overlappingTile.termId) {
               const term = terms.find((i) => i.id === tile.termId);
               term?.audio?.play();
               updateTileState(tile.id, TileState.correct);
               updateTileState(overlappingTile.id, TileState.correct);
               window.setTimeout(() => {
                  updateTileState(tile.id, TileState.hidden);
                  updateTileState(overlappingTile.id, TileState.hidden);
               }, STATE_TIMEOUT);
            } else {
               // incorrect
               updateTileState(tile.id, TileState.incorrect);
               updateTileState(overlappingTile.id, TileState.incorrect);
               setRandomPosition(tile);
               window.setTimeout(() => {
                  updateTileState(tile.id, TileState.neutural);
                  updateTileState(overlappingTile.id, TileState.neutural);
               }, STATE_TIMEOUT);
            }
            setTotalMatches((prevTotalMatches) => prevTotalMatches + 1);
         }
      };

   const handleStart =
      (tileId: string): DraggableEventHandler =>
      (event: DraggableEvent, { x, y }: DraggableData): void => {
         updateTilePosition(tileId, x, y);
      };

   const handleDimensionChange =
      (tileId: string) =>
      (height: number, width: number): void => {
         setTiles((prevTiles) =>
            prevTiles.map((i) => (i.id === tileId ? { ...i, height, width } : i)),
         );
      };

   const handleDrag =
      (tileId: string): DraggableEventHandler =>
      (event: DraggableEvent, { x, y }: DraggableData): void => {
         updateTilePosition(tileId, x, y);
      };

   const getIncrementedZIndex = (): number => {
      const incrementedZIndex = zIndex + 1;
      setZIndex(incrementedZIndex);
      return incrementedZIndex;
   };

   const isDraggedOver = (tileId: string): boolean => {
      const tile = tiles.find((i) => i.id === tileId);
      if (tile) {
         return overlapsAnyTiles(tile, tiles);
      }
      return false;
   };

   const getAccuracy = (): number =>
      100 - _.round(((totalMatches - tiles.length / 2) / totalMatches) * 100, 1);

   if (isFetching || !settings || !terms?.length || !progress) {
      return <Loader />;
   }

   return (
      <div className='product-content-container'>
         <DocumentTitle>{`Matching - ${settings.name}`}</DocumentTitle>
         <div className='top-session-row'>
            <div className='row align-items-center'>
               <div className='col-xs-2 col-md-3'>
                  <Flag language={settings.language} />
               </div>
               <div className='col-xs-8 col-md-6 top-session-title '>
                  <div className='session-description'>
                     <p>Matching - {settings.name}</p>
                     <p>Time: {_.round(seconds, 1)}</p>
                  </div>
               </div>
               <div className='col-xs-2 col-md-3 top-session-close'>
                  <div className='session-close' onClick={navigateBack} role='button'>
                     <IconClose />
                  </div>
               </div>
            </div>
         </div>
         <div className='session-content-container matching'>
            {showStartModal && (
               <ModalDialog
                  heading='Make Everything Disappear!'
                  appearance={Appearance.success}
                  actions={[{ text: 'Start game', onClick: handleStartGame }]}
               >
                  <p>Drag corresponding items onto each other to make them disappear.</p>
               </ModalDialog>
            )}
            {isGameOver && (
               <ModalDialog
                  heading='Nice Work!'
                  appearance={Appearance.success}
                  actions={[
                     {
                        text: 'Finish',
                        onClick: () => navigate(-1),
                     },
                  ]}
               >
                  {showConfetti && <Confetti onAnimationEnd={() => setShowConfetti(false)} />}
                  <p>
                     You matched all of the terms in {_.round(seconds, 1)} seconds with{' '}
                     {_.round(getAccuracy())}% accuracy.
                  </p>
               </ModalDialog>
            )}
            <div
               className={classNames('vocab-tile-container parent', {
                  ready: !showStartModal,
               })}
            >
               {tiles
                  .filter((i) => i.state !== TileState.hidden)
                  .map((tile) => (
                     <Tile
                        key={tile.id}
                        tile={tile}
                        isDraggedOver={isDraggedOver(tile.id)}
                        onDrag={handleDrag(tile.id)}
                        onDragStart={handleStart(tile.id)}
                        onDragStop={handleStop(tile.id)}
                        getIncrementedZIndex={getIncrementedZIndex}
                        onDimensionChange={handleDimensionChange(tile.id)}
                     />
                  ))}
            </div>
         </div>
      </div>
   );
};

export default VocabMatching;
