import * as React from 'react';

import Button from '@components/Common/Button';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import LMSButton from '@components/Core/LMS/LMSButton';
import DocumentTitle from '@components/DocumentTitle';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { Course, CourseLMSConnection, CourseMode } from '@models/Course';
import ILMSCourse from '@models/ILMSCourse';
import LMSName from '@models/LMSName';
import CourseService, { SimpleCourse } from '@services/CourseService';
import DateTimeService from '@services/DateTimeService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { createDefaultCourse } from './Helpers';
import { CanvasCourse } from './types';

const CourseCreateFromCanvas: React.FC = () => {
   const {
      statusCodes: { forbidden },
   } = Constants;

   const {
      archivedCourses,
      currentCourses,
      schoolProfile,
      userProfile,
      dispatchToast,
      setCourses,
   } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { canvasAuthorized: initialCanvasAuthorized = false, id: userId, language } = userProfile;

   const currentCourseIds = currentCourses.map((i) => i.id);
   const archivedCourseIds = archivedCourses.map((i) => i.id);

   const [canvasAuthorized, setCanvasAuthorized] = React.useState<boolean>(false);

   const [loadingLmsCourses, setLoadingLmsCourses] = React.useState<boolean>(false);
   const [clonableCourses, setClonableCourses] = React.useState<readonly SimpleCourse[]>([]);
   const [coursesCreated, setCoursesCreated] = React.useState<boolean>(false);
   const [lmsCourses, setLMSCourses] = React.useState<readonly ILMSCourse[]>([]);
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [isCreating, setIsCreating] = React.useState<boolean>(false);
   const [canvasToLingcoCourseMapping, setCanvasToLingcoCourseMapping] = React.useState<
      Record<string, Maybe<number | string>>
   >({});

   const [existingLMSConnections, setExistingLMSConnections] = React.useState<
      readonly CourseLMSConnection[]
   >([]);

   const alreadyConnectedCanvasCourseIds = existingLMSConnections
      .filter((i) => i.lmsName === LMSName.canvas)
      .map((i) => i.lmsCourseId);

   React.useEffect(() => {
      setCanvasAuthorized(initialCanvasAuthorized);
   }, []);

   React.useEffect(() => {
      CourseService.getCloneableCourses().then(setClonableCourses);
   }, []);

   React.useEffect(() => {
      CourseService.getLMSConnections().then(setExistingLMSConnections);
   }, []);

   React.useEffect(() => {
      setCanvasToLingcoCourseMapping(
         Object.fromEntries(lmsCourses.map((i) => [i.lmsCourseId, null])),
      );
   }, [lmsCourses]);

   React.useEffect(() => {
      if (canvasAuthorized) {
         setLoadingLmsCourses(true);
         HttpService.getWithAuthToken<{ courses: readonly CanvasCourse[] }>(
            '/api/lms/canvas/courses',
            {
               handleForbidden: false,
            },
         )
            .then((response) => {
               const { courses } = response.data;
               setLMSCourses(
                  courses
                     .filter((i) => i?.blueprint !== true)
                     .map((i) => ({
                        lmsName: LMSName.canvas,
                        lmsCourseId: i.id.toString(),
                        lmsCourseName: i.name,
                        lmsCourseTerm: i.term ? i.term.name : null,
                        lmsSectionId: null,
                        startDate: i.startAt ? new Date(i.startAt) : undefined,
                        endDate: i.endAt ? new Date(i.endAt) : undefined,
                     })),
               );
               setLoadingLmsCourses(false);
            })
            .catch((error) => {
               if (error.response && error.response.status === forbidden) {
                  setCanvasAuthorized(false);
               }
            });
      }
   }, [canvasAuthorized]);

   const handleAuthorizeClick = (): void => {
      UserService.getLMSAuthorization(LMSName.canvas).then(() => {
         setCanvasAuthorized(true);
      });
   };

   const renderCanvasButton = (): React.ReactNode => (
      <LMSButton
         lmsName={LMSName.canvas}
         onClick={handleAuthorizeClick}
         disabled={canvasAuthorized}
      >
         {canvasAuthorized ? 'Authorized' : 'Authorize Canvas'}
      </LMSButton>
   );

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value);
   };

   const installLtiTool = async (lmsConnection: CourseLMSConnection): Promise<void> => {
      if (schoolProfile?.lmsInfo?.shouldAllowLtiAppInstallation === true) {
         await HttpService.postWithAuthToken<{ msg: string }>(
            '/api/lms/canvas/install_lti_tool',
            snakeCaseKeys(lmsConnection),
         );
      }
   };

   const createLmsConnection = (
      canvasCourseId: string | number,
      lingcoCourseId: number,
   ): Promise<CourseLMSConnection | void> => {
      const lmsCourse = lmsCourses.find((i) => i.lmsCourseId === canvasCourseId);
      if (!lmsCourse) {
         return Promise.resolve();
      }
      const { lmsCourseName } = lmsCourse;
      const lmsConnection: CourseLMSConnection = {
         id: null,
         linkedBy: userId,
         lmsCourseId: canvasCourseId.toString(),
         lmsCourseName,
         lmsName: LMSName.canvas,
         sectionId: null,
         courseId: lingcoCourseId,
      };
      return HttpService.postWithAuthToken<{ msg: string }>(
         `/api/courses/${lingcoCourseId}/lms_connections`,
         snakeCaseKeys(lmsConnection),
      ).then(() => lmsConnection);
   };

   const handleCreateClick = async (): Promise<void> => {
      setIsCreating(true);
      return Promise.all(
         Object.entries(canvasToLingcoCourseMapping).map(([canvasCourseId, lingcoCourseId]) => {
            const canvasCourse = lmsCourses.find((i) => i.lmsCourseId === canvasCourseId);
            if (!canvasCourse) {
               return;
            }
            if (lingcoCourseId === 'new') {
               const course: Course<CourseMode.create> = {
                  ...createDefaultCourse(),
                  name: canvasCourse.lmsCourseName,
                  language,
               };
               if (canvasCourse.startDate) {
                  course.startDate = canvasCourse.startDate;
               }
               if (canvasCourse.endDate) {
                  course.endDate = canvasCourse.endDate;
               }
               return CourseService.createCourse(course)
                  .then(({ id: newCourseId }) => createLmsConnection(canvasCourseId, newCourseId))
                  .then((connection) => {
                     if (connection) {
                        installLtiTool(connection);
                     }
                  });
            } else if (Number.isInteger(lingcoCourseId)) {
               const course: Partial<Course<CourseMode.create>> = {
                  name: canvasCourse.lmsCourseName,
                  lmsConnections: [],
               };
               return CourseService.createCourseFromTemplate(Number(lingcoCourseId), course)
                  .then(({ newCourseId }) => createLmsConnection(canvasCourseId, newCourseId))
                  .then((connection) => {
                     if (connection) {
                        installLtiTool(connection);
                     }
                  });
            }
         }),
      )
         .then(() =>
            CourseService.getCourses().then(({ courses: { current, archived } }) =>
               setCourses(current, archived),
            ),
         )
         .then(() => {
            setIsCreating(false);
            setCoursesCreated(true);
            dispatchToast({
               title: 'Courses Created',
               message: 'You can view your new courses on the left sidebar.',
               appearance: Appearance.success,
            });
         });
   };

   const createHandleSelectLingcoCourse =
      (canvasCourseId: string | number) =>
      (event: React.ChangeEvent<HTMLSelectElement>): void => {
         const { value } = event.target;
         const patchedValue = value === '' ? null : isInteger(value) ? Number(value) : value;
         setCanvasToLingcoCourseMapping((prevCanvasToLingcoCourseMapping) => ({
            ...prevCanvasToLingcoCourseMapping,
            [canvasCourseId]: patchedValue,
         }));
      };

   const renderLingcoTemplateCell = (i: ILMSCourse): React.ReactNode => {
      if (alreadyConnectedCanvasCourseIds.includes(i.lmsCourseId)) {
         return <span className='strong-text gray-text'>Already Connected</span>;
      }
      return (
         <select
            value={canvasToLingcoCourseMapping[i.lmsCourseId] as number}
            onChange={createHandleSelectLingcoCourse(i.lmsCourseId)}
            disabled={coursesCreated}
         >
            <option value=''>Select</option>
            {organizationCourses.length > 0 && (
               <optgroup label='Organization Courses'>
                  {organizationCourses.map((j) => (
                     <option value={j.id} key={j.id}>
                        {j.name}
                     </option>
                  ))}
               </optgroup>
            )}
            {currentClonableCourses.length > 0 && (
               <optgroup label='Current Courses'>
                  {currentClonableCourses.map((j) => (
                     <option value={j.id} key={j.id}>
                        {j.name}
                     </option>
                  ))}
               </optgroup>
            )}
            {archivedClonableCourses.length > 0 && (
               <optgroup label='Archived Courses'>
                  {archivedClonableCourses.map((j) => (
                     <option value={j.id} key={j.id}>
                        {j.name}
                     </option>
                  ))}
               </optgroup>
            )}
            <option value='new'>New</option>
         </select>
      );
   };

   const filteredLMSCourses = React.useMemo(
      () =>
         lmsCourses
            .filter((i) => i.lmsCourseName.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((i) => !i.endDate || i.endDate > DateTimeService.now()),
      [lmsCourses, searchQuery],
   );

   const organizationCourses = clonableCourses.filter((i) => i.isOrganizationStandard);
   const currentClonableCourses = clonableCourses.filter(
      (i) => currentCourseIds.includes(i.id) && !organizationCourses.includes(i),
   );
   const archivedClonableCourses = clonableCourses.filter(
      (i) => archivedCourseIds.includes(i.id) && !organizationCourses.includes(i),
   );

   const columns: readonly Column<ILMSCourse>[] = [
      {
         id: 'lmsCourseName',
         canSort: true,
         cell: (i) => i.lmsCourseName,
         header: 'Canvas Course',
      },
      {
         id: 'lmsCourseTerm',
         canSort: true,
         cell: (i) => i.lmsCourseTerm,
         header: 'Canvas Term',
      },
      {
         id: 'lingcoTemplate',
         canSort: true,
         cell: renderLingcoTemplateCell,
         header: 'Lingco Template',
         sortFunc: (i) => Number(alreadyConnectedCanvasCourseIds.includes(i.lmsCourseId)),
      },
   ];
   return (
      <>
         <DocumentTitle>Create Course</DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='card course-card'>
               <div className='card-title full-width'>
                  <div className='title'>Connect to Canvas</div>
               </div>
               <div className='content-form'>
                  <div className='row'>
                     <div className='col-xs-8'>
                        <p className='course-option-description'>
                           Integrate Lingco Classroom with your institution&apos;s learning
                           management system to enable roster and grade syncing and content sharing.
                        </p>
                     </div>
                     <div className='col-xs-4'>
                        <div className='flex-end margin-top-s'>
                           <div>{renderCanvasButton()}</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {lmsCourses.length > 0 && !loadingLmsCourses && (
               <div className='card course-card no-margin-bottom'>
                  <div className='card-title full-width'>
                     <div className='title'>Create Courses</div>
                     <Button
                        loading={isCreating}
                        onClick={handleCreateClick}
                        disabled={coursesCreated}
                     >
                        Create
                     </Button>
                  </div>
                  <div className='content-form'>
                     <div className='row margin-top-s'>
                        <div className='col-xs-8 header-wrapper flex-align-center'>
                           <div className='title'>Available Classes</div>
                        </div>
                        <div className='col-xs-4'>
                           <input
                              type='search'
                              value={searchQuery}
                              onChange={handleSearchChange}
                              disabled={coursesCreated}
                              placeholder='Search Canvas Courses'
                           />
                        </div>
                     </div>
                     {filteredLMSCourses.length ? (
                        <div className='margin-top-s'>
                           <Table<ILMSCourse>
                              columns={columns}
                              className='table small sticky create-from-canvas-table'
                              rows={filteredLMSCourses}
                              rowKey='lmsCourseId'
                              defaultSortBy={[{ id: 'lmsCourseName', desc: false }]}
                           />
                        </div>
                     ) : (
                        <EmptyState icon={<IconBinoculars aria-hidden />} heading='No Results' />
                     )}
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default CourseCreateFromCanvas;
