import { IOnboardingTask, OnboardingTask } from './Models';
import ActivityBuilderIntro from './Walkthroughs/ActivityBuilderIntro';
import AssignActivity from './Walkthroughs/AssignActivity';
import ContentLibraryIntro from './Walkthroughs/ContentLibraryIntro';
import GradeActivity from './Walkthroughs/GradeActivity';

const onboardingTasks: readonly IOnboardingTask[] = [
   {
      id: OnboardingTask.exploreContentLibrary,
      title: 'Explore the Content Library',
      walkthrough: ContentLibraryIntro,
      repeatable: true,
   },
   {
      id: OnboardingTask.createActivity,
      title: 'Create an Activity',
      walkthrough: ActivityBuilderIntro,
      repeatable: true,
   },
   {
      id: OnboardingTask.assignActivity,
      title: 'Assign an Activity',
      walkthrough: AssignActivity,
      repeatable: true,
   },
   {
      id: OnboardingTask.gradeActivity,
      title: 'Grade an Activity',
      walkthrough: GradeActivity,
      repeatable: true,
   },
];

export default onboardingTasks;
