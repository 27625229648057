import * as React from 'react';

import { TimerValueConsumer } from '@components/VocabSession/TimerWrapper';
import { SPEED_CARD_DURATION } from '@components/VocabSession/VocabSession';

interface SpeedBackgroundProps {
   children: React.ReactNode;
}

const SpeedBackground: React.FC<SpeedBackgroundProps> = ({ children }) => (
   <div className='speed-background-wrapper'>
      <div className='speed-background'>
         {children}
         <TimerValueConsumer>
            {(seconds) => (
               <div
                  className='speed-timer'
                  style={{
                     height: `${seconds === null ? 0 : 100 * (1 - seconds / SPEED_CARD_DURATION)}%`,
                  }}
               />
            )}
         </TimerValueConsumer>
      </div>
   </div>
);

export default React.memo(SpeedBackground);
