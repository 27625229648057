import * as React from 'react';

import { ActivityCompleterMode, ActivityQuestion } from '@models/Activity';
import { Maybe } from '@models/Core';
import { Assignment } from '@models/Course';
import classnames from 'classnames';

import ActivityDescription from './ActivityDescription';

export interface ActivitySidebarProps {
   allowGoingBack: boolean;
   assignment: Maybe<Assignment>;
   attempts: Maybe<number>;
   currentQuestionId: Maybe<number>;
   description: string;
   isClosed: boolean;
   isLate: boolean;
   isLocked: boolean;
   isSaving: boolean;
   isSubmitting: boolean;
   lastSubmittedOn: Maybe<Date>;
   mode: string;
   modifiedOn: Maybe<Date>;
   oneQuestionAtATime: boolean;
   pointsPossible: Maybe<number>;
   questions: readonly ActivityQuestion<ActivityCompleterMode>[];
   relativeDue: string;
   score: Maybe<number>;
   showQuestionList: boolean;
   startedOn: Maybe<Date>;
   setCurrentQuestionId(questionId: number): void;
}

const ActivitySidebar: React.FC<ActivitySidebarProps> = (props) => {
   const {
      allowGoingBack,
      currentQuestionId,
      oneQuestionAtATime,
      questions,
      showQuestionList,
      setCurrentQuestionId,
   } = props;

   const handleListItemClick = (questionId: number): void => {
      if (oneQuestionAtATime && allowGoingBack) {
         setCurrentQuestionId(questionId);
      }
   };

   return (
      <div className='activity-sidebar completer'>
         <ActivityDescription {...props} />
         {showQuestionList && (
            <div
               className={classnames('activity-question-container', {
                  selectable: allowGoingBack && oneQuestionAtATime,
               })}
            >
               {questions.map(({ id, title }, i) => (
                  <div
                     key={id}
                     className={classnames('activity-question-row', {
                        active: oneQuestionAtATime && currentQuestionId === id,
                     })}
                     onClick={() => handleListItemClick(id)}
                  >
                     <div className='title'>{title || `Question ${i + 1}`}</div>
                     {/* <div className="activity-question-completed">
                           <IconTickGrey/>
                        </div> */}
                  </div>
               ))}
            </div>
         )}
      </div>
   );
};

export default ActivitySidebar;
