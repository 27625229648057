import * as React from 'react';

import classnames from 'classnames';

import Constants from '../../../Constants';
import Link from '@components/Common/Link';
import Avatar from '@components/Core/Avatar';
import { GradebookProfile } from './Gradebook';
import { GradeColor } from './Utils';

interface IStudentNameProps {
   profile: GradebookProfile;
   gradeColor: GradeColor;
   style: React.CSSProperties;
}

const StudentName: React.FC<IStudentNameProps> = ({ gradeColor, profile, style }) => {
   const { enrollments } = Constants.routes;
   const { enrollmentId, grade, firstName, lastName, profileImageUrl, userId } = profile;
   const link = enrollments.byId
      .replace(':enrollmentId', enrollmentId.toString())
      .concat('?ref=gradebook');

   return (
      <Link style={style} to={link}>
         <div className='grid-item gradebook-student-row'>
            <Avatar
               firstName={firstName}
               lastName={lastName}
               src={profileImageUrl}
               hashValue={userId}
            />
            <div className='student-name-wrapper'>
               <div className='student-name'>{`${firstName} ${lastName}`}</div>
            </div>
            <div className={classnames('overall-grade', gradeColor)}>{grade}</div>
         </div>
      </Link>
   );
};

export default React.memo(StudentName);
