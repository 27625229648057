import * as React from 'react';

import IconArrow from '@icons/nova-solid/53-Keyboard/arrow-up-right.svg';
import { VideoSummary } from '@models/Video';
import { Feature, Geometry } from 'geojson';
import { MapGeoJSONFeature, Popup } from 'react-map-gl';

import Constants from '../../../Constants';
import Link from '@components/Common/Link';
import InlineVideoViewer from '@components/Video/InlineVideoViewer';
import { getCoordinatesFromFeature, isVideoSummary } from './helpers';

type Props = {
   feature: MapGeoJSONFeature | Feature<Geometry, VideoSummary>;
   onClose(): void;
};

const VideoPopup: React.FC<Props> = (props) => {
   if (!props.feature) return null;
   const {
      routes: {
         content: { viewVideo },
      },
   } = Constants;

   const coordinates = getCoordinatesFromFeature(props.feature);
   if (!coordinates) {
      return null;
   }

   const properties = props.feature.properties;
   if (!isVideoSummary(properties)) {
      return null;
   }

   const onClose = React.useCallback(() => {
      props.onClose();
   }, []);

   return (
      <Popup
         {...props}
         {...coordinates}
         className='video-popup'
         closeButton
         closeOnClick
         closeOnMove={false}
         key={props.feature.id}
         offset={35}
         onClose={onClose}
         style={{
            animation: 'ease-in-out',
            maxWidth: '380px',
         }}
         focusAfterOpen={false}
      >
         <Link
            className='btn launch-button'
            to={viewVideo.replace(':videoId', properties.videoId.toString())}
            target='_blank'
            rel='noopener noreferrer'
         >
            <IconArrow />
            Launch Player
         </Link>
         <InlineVideoViewer videoId={properties.videoId} useNativePlayer />
         {properties.interviewQuestion && (
            <div className='question'>{properties.interviewQuestion}</div>
         )}
      </Popup>
   );
};

export default VideoPopup;
