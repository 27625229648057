import * as React from 'react';

import CountryFlag from '@components/Core/CountryFlag/CountryFlag';
import { formatSeconds } from '@helpers/FormatTime';
import { Video } from '@models/Video';

import LevelTag from './LevelTag';

interface Props {
   video: Video;
   onClick(event: React.MouseEvent<HTMLDivElement>): void;
}
const VideoSearchResult: React.FC<Props> = ({ video, onClick }) => (
   <div className='video-result flex-col' onClick={onClick}>
      <div className='image-question'>
         <div className='preview-wrapper'>
            <img
               src={video.imageUrl || undefined}
               alt='Thumbnail preview'
               className='thumbnail-img'
            />
            {video.duration && <div className='duration'>{formatSeconds(video.duration)}</div>}
         </div>
         <div className='prompt'>{video.interviewQuestionName}</div>
      </div>
      <div className='space-between align-items-center border-top-grey-2 padding-top-s margin-top-s'>
         <div className='speaker-info flex align-items-center'>
            {video.location?.countryCode ? (
               <CountryFlag
                  countryCode={video.location.countryCode}
                  latitude={video.location.latitude}
                  longitude={video.location.longitude}
                  useRegionalOverrides
                  width='21'
                  height='16'
               />
            ) : (
               <div className='country-flag-placeholder' />
            )}
            <div className='margin-left-s'>
               <div className='bold-text gray-text body-font line-height-s text-xs'>
                  {video.speakers[0]?.name || 'No Speaker'}
               </div>
               <div className='gray-text body-font line-height-s text-xs'>
                  {video.location?.displayName || 'No Location'}
               </div>
            </div>
         </div>
         {video.level && <LevelTag level={video.level} />}
      </div>
   </div>
);

export default VideoSearchResult;
