import { useEffect, useState } from 'react';

type UseFetchResult<T> = {
   isLoading: boolean;
   data?: T;
   error: unknown;
};

const useFetch = <InputType, OutputType>(
   asyncFunction: (input?: InputType) => Promise<OutputType>,
   input?: InputType,
): UseFetchResult<OutputType> => {
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [data, setData] = useState<OutputType>();
   const [error, setServerError] = useState<unknown | null>(null);

   useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
         try {
            const result = await asyncFunction(input);
            setData(result);
            setIsLoading(false);
         } catch (e) {
            setServerError(e);
            setIsLoading(false);
         }
      };

      fetchData();
   }, [input]);

   return { isLoading, data, error };
};

export default useFetch;
