import { Maybe } from '@models/Core';
import Language from '@models/Language';
import SchoolProfile from '@models/SchoolProfile';

import HttpService from './HttpService';

export type ValidateCourseCodeResponse = {
   school: SchoolProfile;
   name: string;
   language: Language;
   sectionId: Maybe<string>;
};

const validateStudentCourseCode = (courseCode: string): Promise<ValidateCourseCodeResponse> =>
   HttpService.get<ValidateCourseCodeResponse>(`/api/courses/course_codes/${courseCode}`, {
      handleNotFound: false,
   })
      .then((response) => response.data)
      .catch((error) => {
         error.response.data.courseCode = courseCode;
         throw error;
      });

const validateInstructorCourseCode = (courseCode: string): Promise<ValidateCourseCodeResponse> =>
   HttpService.get<ValidateCourseCodeResponse>(
      `/api/courses/instructor_course_codes/${courseCode}`,
      { handleNotFound: false },
   )
      .then((response) => response.data)
      .catch((error) => {
         error.response.data.courseCode = courseCode;
         throw error;
      });

export default {
   validateInstructorCourseCode,
   validateStudentCourseCode,
};
