import AccountType from '@models/AccountType';
import { IdName, Maybe } from '@models/Core';

// FIXME: Doesn't match the CourseInviteModel
export interface CourseInvite {
   accountType: AccountType;
   email: string;
   emailInvite?: {
      deliveredOn: Maybe<Date>;
      openedOn: Maybe<Date>;
      bouncedOn: Maybe<Date>;
      bounceDescription: Maybe<string>;
   };
   examLevelCategoryId: Maybe<number>;
   firstName: string;
   inviteId: number;
   key?: string;
   lastName: string;
   sections?: readonly IdName[];
   studentId: Maybe<string>;
}

export type RosterRecord = CourseInvite | CourseEnrollment;

export interface CourseEnrollment {
   accountType: AccountType;
   authenticated: boolean;
   email: string;
   enrollmentId: number;
   examLevelCategoryId: Maybe<number>;
   excludeFromCalc: boolean;
   firstName: string;
   key?: string;
   lastName: string;
   lastRefreshedOn: Maybe<Date>;
   lmsConnectionId: Maybe<number>;
   lmsUserId: Maybe<string>;
   profileImageUrl: Maybe<string>;
   sections?: readonly IdName[];
   trialEndOn?: Date;
   userId: number;
}

export const isEnrollment = (record: RosterRecord): record is CourseEnrollment =>
   (record as CourseEnrollment)?.enrollmentId !== undefined;

export const isInvite = (record: RosterRecord): record is CourseInvite =>
   (record as CourseInvite)?.inviteId !== undefined;
