import * as React from 'react';

import ItemOptions from '@components/Activity/Builder/ItemOptions';
import { isVideoLibraryContent, isYouTubeVideoContent } from '@components/Activity/Utils';
import Link from '@components/Common/Link';
import Switch from '@components/Common/Switch';
import IconScissors from '@icons/nova-line/01-Content-Edition/scissors.svg';
import IconNavigationNext2 from '@icons/nova-line/17-Navigation/navigation-next-2.svg';
import IconCursorChoose from '@icons/nova-solid/02-Status/cursor-choose.svg';
import {
   ActivityBuilderMode,
   ActivityItemVisibility,
   VideoContent,
   VideoSource,
} from '@models/Activity';
import Language, { LanguageLookup } from '@models/Language';
import ReactPlayer from 'react-player';

import Constants from '../../../../../Constants';
import { defaultItem, sourceOptions } from './helpers';

interface VideoContentOptionsProps {
   item: VideoContent<ActivityBuilderMode>;
   isVideoLoaded: boolean;
   videoLanguage?: Language;
   videoRef: React.RefObject<ReactPlayer>;
   onUpdate(update: Partial<VideoContent<ActivityBuilderMode>>): void;
}

const VideoContentOptions: React.FC<VideoContentOptionsProps> = ({
   onUpdate,
   item,
   isVideoLoaded,
   videoLanguage,
   videoRef,
}) => {
   const {
      routes: {
         content: { viewVideo },
      },
   } = Constants;

   const handleSourceChange = (source: VideoSource): void => {
      onUpdate({ ...defaultItem, source });
   };

   const handlePlaySnippetChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      const updatedSnippetEnd = checked ? videoRef.current?.getDuration() : 0;
      const update: Partial<VideoContent<ActivityBuilderMode>> = {
         snippetEnd: updatedSnippetEnd,
         playSnippet: checked,
      };
      if (!updatedSnippetEnd) {
         update.snippetStart = 0;
      }
      onUpdate(update);
   };

   const getExternalVideoUrl = () => {
      if (isVideoLibraryContent(item) && item.videoId) {
         return viewVideo.replace(':videoId', item.videoId?.toString());
      } else if (isYouTubeVideoContent(item) && item.youtubeId) {
         return `https://youtu.be/${item.youtubeId}`;
      }
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const target = event.target as HTMLSelectElement & HTMLInputElement;
      const { name, type, value } = target;
      onUpdate({ [name]: type === 'checkbox' ? target.checked : value });
   };

   return (
      <ItemOptions title='Video Options'>
         <div className='row'>
            <div className='col-xs-12'>
               {isVideoLoaded && (
                  <div className='margin-top-xs'>
                     <label className='field-title'>Video Source</label>
                     <select
                        name='source'
                        value={item.source ?? ''}
                        onChange={(e) => handleSourceChange(e.target.value as VideoSource)}
                     >
                        {sourceOptions.map((i) => (
                           <option value={i.source} key={i.source}>
                              {i.text}
                           </option>
                        ))}
                     </select>
                  </div>
               )}
               {isYouTubeVideoContent(item) && item.youtubeId && (
                  <input
                     type='text'
                     className='video-url small'
                     value={`https://youtu.be/${item.youtubeId}`}
                     readOnly
                  />
               )}
               <label className='field-title margin-top-xs'>Video Options</label>
               <div className='margin-top-s'>
                  <Switch
                     name='playSnippet'
                     checked={item.playSnippet}
                     onChange={handlePlaySnippetChange}
                  >
                     <label className='field-title'>
                        Play Snippet <IconScissors className='icon-xs margin-left-xs' />
                     </label>
                  </Switch>
               </div>
               <div className='margin-top-s'>
                  <Switch
                     name='isInteractiveVideo'
                     disabled={isYouTubeVideoContent(item)}
                     checked={item.isInteractiveVideo}
                     onChange={handleChange}
                  >
                     <label className='field-title'>
                        Interactive <IconCursorChoose className='icon-xs margin-left-xs' />
                     </label>
                  </Switch>
               </div>
               {isVideoLibraryContent(item) && (
                  <div className='margin-top-xs'>
                     <label className='field-title margin-top-xs'>Enable Subtitles</label>
                     {videoLanguage && (
                        <div className='margin-top-s'>
                           <Switch
                              name='enableTargetSubtitles'
                              checked={item.enableTargetSubtitles}
                              onChange={handleChange}
                              title={LanguageLookup[videoLanguage]}
                           />
                        </div>
                     )}
                     <div className='margin-top-s'>
                        <Switch
                           name='enableNativeSubtitles'
                           checked={item.enableNativeSubtitles}
                           onChange={handleChange}
                           title={LanguageLookup['en']}
                        />
                     </div>
                  </div>
               )}
               <div className='margin-top-xs'>
                  <label className='field-title'>Show</label>
                  <select
                     name='show'
                     value={item.show}
                     onChange={(e) =>
                        onUpdate({
                           show: e.target.value as ActivityItemVisibility,
                        })
                     }
                  >
                     <option value={ActivityItemVisibility.always}>Always</option>
                     <option value={ActivityItemVisibility.onceClosed}>Once Closed</option>
                     <option value={ActivityItemVisibility.onceGraded}>Once Graded</option>
                  </select>
               </div>
               {getExternalVideoUrl() && (
                  <div className='margin-top-s align-items-center'>
                     <Link
                        to={getExternalVideoUrl()}
                        className='strong-text'
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        Go to Video
                        <IconNavigationNext2 height='12' width='17' className='icon-blue' />
                     </Link>
                  </div>
               )}
            </div>
         </div>
      </ItemOptions>
   );
};

export default VideoContentOptions;
