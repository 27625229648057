import Language from '@models/Language';
import { Translation, Translations } from '@models/Video';

export const convertTranslationsDictToTranslationsArray = (
   translations: Translations,
): Translation[] => [
   ...Object.entries(translations).map(([code, label]) => ({
      language: code as Language,
      translation: label,
   })),
];

export const convertTranslationsArrayToTranslationsDict = (
   translationsArray: Translation[],
): Translations => {
   // Initialize an empty dictionary (object)
   const newTranslations: Translations = {};

   // Loop through the array and populate the dictionary
   for (const item of translationsArray) {
      newTranslations[item.language] = item.translation;
   }

   return newTranslations;
};
