import * as React from 'react';

import useMousetrap from '@hooks/use-mousetrap';
import useSubscription from '@hooks/use-subscription';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import { AppStateContext } from '../../../AppState';
import AccentTextbox from '@components/AccentTextbox';
import Modal from '@components/Core/ModalDialog';
import { FeedbackType } from '@components/VocabSession/Models';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';
import { TypingEvalution } from './Card';

interface TypingInputProps {
   isAnswered: boolean;
   isReady: boolean;
   isSpeedSession: boolean;
   isTimedOut: boolean;
   keyboardChars: readonly string[];
   check(response: string): TypingEvalution;
   playAudio(signal?: AbortSignal): Promise<Event>;
   onReadyToTransition(): void;
}

const TypingInput: React.FC<TypingInputProps> = ({
   isAnswered,
   isReady,
   isTimedOut,
   keyboardChars,
   check,
   onReadyToTransition,
   playAudio,
}) => {
   const {
      emitter,
      state: {
         isMuted,
         settings: { language },
      },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const virtualKeyboard = userProfile?.preferences
      ? userProfile.preferences.virtualKeyboard
      : false;

   const [pasteModalOpen, setPasteModalOpen] = React.useState<boolean>(false);

   const [value, setValue] = React.useState<string>('');
   const [showDiff, setShowDiff] = React.useState<boolean>(false);
   const [diffAnimationClass, setDiffAnimationClass] = React.useState<string>('');
   const [evaluation, setEvaluation] = React.useState<Maybe<TypingEvalution>>(null);

   const inputRef = React.useRef<Maybe<HTMLInputElement>>(null);
   const playAudioController = React.useRef<Maybe<AbortController>>(null);
   const showHintTimeoutRef = React.useRef<Maybe<number>>(null);
   const transitionTimeoutRef = React.useRef<Maybe<number>>(null);

   const TRANSITION_DELAY = isMuted ? 1600 : 800;
   const SHOW_HINT_DELAY = 1600;

   React.useEffect(() => {
      if (!isAnswered) {
         setShowDiff(false);
         setDiffAnimationClass('');
         setEvaluation(null);
         setValue('');
      }
   }, [isAnswered]);

   React.useEffect(() => {
      if (isReady) {
         inputRef.current?.focus();
      }
   }, [isReady]);

   React.useEffect(() => {
      if (isTimedOut) {
         checkAnswer();
      }
   }, [isTimedOut]);

   React.useEffect(() => {
      if (evaluation) {
         if (evaluation.feedback === FeedbackType.correct) {
            playAudioController.current = new AbortController();
            playAudio(playAudioController.current.signal)
               .then(() => {
                  transitionTimeoutRef.current = setTimeout(
                     () => onReadyToTransition(),
                     TRANSITION_DELAY,
                  ) as unknown as number;
               })
               .catch((error) => {
                  if (error.name === 'AbortError') {
                     return;
                  }
               });
         } else {
            setValue('');
            setShowDiff(true);
            setDiffAnimationClass('animate-show-answer');
            inputRef.current?.focus();
         }
      }

      return () => {
         transitionTimeoutRef.current && clearTimeout(transitionTimeoutRef.current);
      };
   }, [evaluation]);

   const checkAnswer = (): void => {
      setEvaluation(check(value));
   };

   const setInputRef = (instance: HTMLInputElement): void => {
      if (instance) {
         inputRef.current = instance;
      }
   };
   const handleTransitionEnd = (): void => {
      if (diffAnimationClass === 'animate-show-answer') {
         setDiffAnimationClass('fade-answer');
      }
   };

   const handleAnimationEnd = (): void => {
      handleTransitionEnd();
   };

   const handleKeyClick = (ch: string): void => {
      setValue((prev) => prev + ch);
      onValueChange();
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(event.target.value);
      onValueChange();
   };

   const onValueChange = (): void => {
      if (showDiff) {
         // Set timeout to show hint, or hide diff
         if (diffAnimationClass === 'fade-out') {
            if (showHintTimeoutRef.current) {
               clearTimeout(showHintTimeoutRef.current);
            }
            showHintTimeoutRef.current = setTimeout(
               () => setDiffAnimationClass('fade-answer'),
               SHOW_HINT_DELAY,
            ) as unknown as number;
         } else if (diffAnimationClass !== 'animate-show-answer') {
            setDiffAnimationClass('fade-out');
         }
      }
   };

   const handleNextButtonClick = (
      event?: React.MouseEvent<HTMLButtonElement> | KeyboardEvent,
   ): void => {
      if (
         isReady &&
         !(event && (event.target as HTMLButtonElement).classList.contains('ignore-click'))
      ) {
         if (!(evaluation && evaluation.feedback === FeedbackType.correct)) {
            checkAnswer();
         } else if (evaluation && evaluation.feedback === FeedbackType.correct) {
            if (playAudioController.current) {
               playAudioController.current.abort();
               if (transitionTimeoutRef.current) {
                  clearTimeout(transitionTimeoutRef.current);
                  transitionTimeoutRef.current = null;
               }
               onReadyToTransition();
            }
         }
      }
   };

   const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
      // Allow accents and single characters to be pasted
      const allowedCopyPasteLanguages = ['cmn', 'ja', 'ru'];
      if (
         event.clipboardData.getData('Text').length > 1 &&
         language &&
         !allowedCopyPasteLanguages.includes(language)
      ) {
         event.preventDefault();
         setPasteModalOpen(true);
      }
   };

   useMousetrap('enter', handleNextButtonClick);
   useSubscription(emitter, 'NEXT_BUTTON_CLICK', handleNextButtonClick);

   return (
      <div className='session-typing'>
         <AccentTextbox
            autoCapitalize='off'
            autoCorrect='off'
            className={classnames(evaluation?.feedback, 'mousetrap')}
            disabled={!isReady || (isAnswered && evaluation?.feedback === FeedbackType.correct)}
            language={language}
            dir={language === 'ar' ? 'rtl' : 'ltr'}
            onChange={handleChange}
            inputRef={setInputRef}
            spellCheck={false}
            value={value}
            onPaste={handlePaste}
         />
         {showDiff && evaluation && (
            <span
               className={classnames(
                  'session-typing-diff',
                  evaluation.feedback,
                  diffAnimationClass,
               )}
               data-lang={language}
               onTransitionEnd={handleTransitionEnd}
               onAnimationEnd={handleAnimationEnd}
            >
               {evaluation.diff}
            </span>
         )}
         {virtualKeyboard && (
            <div className='virtual-keyboard'>
               {keyboardChars.map((ch) => (
                  <div
                     key={ch}
                     className='virtual-keyboard-button'
                     onClick={() => handleKeyClick(ch)}
                  >
                     {ch}
                  </div>
               ))}
            </div>
         )}
         {pasteModalOpen && (
            <Modal
               width='large'
               animations={{ enter: 'animated bounceInDown' }}
               onClose={() => setPasteModalOpen(false)}
            >
               <>
                  <img
                     alt='To learn you must type not copy and paste.'
                     style={{ width: '100%' }}
                     src='https://lingco-static.s3.us-east-2.amazonaws.com/copy-paste.png'
                  />
                  <p>Need an accent? Hold down the letter and a menu will pop up!</p>
               </>
            </Modal>
         )}
      </div>
   );
};

export default React.memo(TypingInput);
