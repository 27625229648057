import * as React from 'react';

import Constants from '../../../Constants';
import { ActivityGraderState } from '@components/Activity/Grader/ActivityGrader';
import {
   IOnboardingStep,
   IOnboardingWalkthrough,
   OnboardingStepType,
   OnboardingWalkthrough,
} from '@components/Onboarding/Models';

const {
   routes: {
      courses: { dashboard: courseDashboardRoute, modules: modulesRoute },
   },
} = Constants;

export enum GradeActivityStep {
   activityGraderIntro = 'activity_grader_intro',
   audioWaveform = 'audio_waveform',
   currentCard = 'current_card',
   graderFooterButtons = 'grader_footer_buttons',
   modulesLink = 'modules_link',
   overallProgress = 'overall_progress',
   pointAdjustment = 'point_adjustment',
   promptListDropdown = 'prompt_list_dropdown',
   questionsSubmissionsTable = 'questions_submissions_table',
   rubricEntriesConsistency = 'rubric_entries_consistency',
   rubricEntriesGrade = 'rubric_entries_grade',
   rubricItem = 'rubric_item',
   selectPrompt = 'select_prompt',
   studentSubmissionsTable = 'student_submissions_table',
   submissionLink = 'submission_link',
   submissionOverview = 'submission_overview',
   walkthroughCompletePost = 'walkthrough_complete_post',
}

const steps: readonly IOnboardingStep[] = [
   {
      id: GradeActivityStep.modulesLink,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="modules-link"]',
      hideWhenRemoved: '[data-tour="modules-link"]',
      advanceWhenVisible: '.action-icon-bar',
      overlay: false,
      expectedLocationChanges: [modulesRoute, /^\/courses\/:courseId\/modules\/\d+$/],
      placement: 'left',
      primaryButton: false,
      content: (
         <p>To grade an activity, start by going to the module where the assignment exists.</p>
      ),
   },
   {
      id: GradeActivityStep.submissionLink,
      type: OnboardingStepType.pointer,
      selector: '.action-icon-bar .action-icon',
      className: 'animated infinite pulse slow green',
      expectedLocationChanges: [/^\/activities\/\d+\/submissions$/],
      hideWhenRemoved: '.action-icon-bar .action-icon',
      advanceWhenVisible: '[data-tour="submissions-page"]',
      overlay: false,
      placement: 'left',
      content: <p>Now, click the submissions button to view the students' submissions.</p>,
      primaryButton: false,
   },
   {
      id: GradeActivityStep.submissionOverview,
      type: OnboardingStepType.post,
      expectedLocationChanges: [/^\/activities\/\d+\/submissions$/],
      overlay: true,
      placement: 'left',
      content: (
         <>
            <h1>Welcome to the Submissions Page!</h1>
            <p>
               Here you can see an overview of the students' work for the assignment. You can see
               their progress, averages by questions or by student, grant extensions, and/or
               additional attempts as needed.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.overallProgress,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="overall-progress-chart"]',
      overlay: true,
      placement: 'right',
      content: (
         <p>
            This panel provides a quick overview of the portion of the students who have completed
            the assignment and the portion that you've graded.
         </p>
      ),
   },
   {
      id: GradeActivityStep.studentSubmissionsTable,
      type: OnboardingStepType.pointer,
      className: 'animated infinite pulse slow green-text',
      selector: '[data-tour="submission-table-container"]',
      advanceWhenVisible: 'table.questions-table',
      overlayOptions: {
         disableInteraction: false,
      },
      placement: 'left',
      primaryButton: false,
      content: (
         <>
            <p>
               Activity data can be viewed both by <strong>student</strong> and by{' '}
               <strong>question</strong>.
            </p>
            <p>
               {' '}
               Click the <strong>Questions</strong> tab at the top-right corner of the page to
               continue.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.questionsSubmissionsTable,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="submission-table-container"]',
      className: 'animated infinite pulse slow green-child',
      advanceWhenVisible: '.grader-content-container',
      hideWhenRemoved: '[data-tour="submission-table-container"]',
      expectedLocationChanges: [
         /^\/activities\/\d+\/responses\/\d+$/,
         /^\/activities\/\d+\/submissions$/,
      ],
      overlayOptions: {
         disableInteraction: false,
      },
      placement: 'left',
      primaryButton: false,
      content: (
         <>
            <p>
               Here we can see each of the questions along with the number of points, the average,
               and the percent completed and graded.
            </p>
            <p>
               Click on the <strong>Audio Recording</strong> prompt to start grading!
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.activityGraderIntro,
      type: OnboardingStepType.post,
      content: (
         <>
            <h1>Welcome to the Activity Grader! 💯</h1>
            <p>
               The Activity Grader allows you to view and grade student work all in one place either
               using a simple point scale or a detailed rubric.
            </p>
            <p>We've designed it to be efficient and to reflect common grading workflows.</p>
         </>
      ),
   },
   {
      id: GradeActivityStep.currentCard,
      type: OnboardingStepType.pointer,
      selector: '.active',
      placement: 'right',
      overlay: true,
      overlayOptions: {
         paddingLeft: 28,
      },
      content: (
         <>
            <p>
               Here we're grading by <strong>response</strong> so you'll see each student's response
               to the prompt.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.audioWaveform,
      type: OnboardingStepType.pointer,
      selector: '.active .audio-waveform',
      placement: 'right',
      overlay: true,
      overlayOptions: {
         disableInteraction: false,
      },
      content: (
         <>
            <p>
               You can leave written 📝 or voice 🎤 comments on the recording by clicking and
               highlighting parts of it.
            </p>
            <p>Try leaving the student a comment!</p>
            <p>💡 To play the highlighted part you can double click it.</p>
         </>
      ),
   },
   {
      id: GradeActivityStep.rubricEntriesConsistency,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="rubric-entries"]',
      placement: 'left',
      overlay: true,
      content: (
         <p>
            The rubric helps you grade quickly and consistently by applying the same feedback to
            every response.
         </p>
      ),
   },
   {
      id: GradeActivityStep.rubricEntriesGrade,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="rubric-entries"]',
      placement: 'left',
      overlay: true,
      primaryButton: false,
      overlayOptions: {
         disableInteraction: false,
      },
      shouldAdvance: (state: ActivityGraderState, prevState: ActivityGraderState): boolean => {
         const prevRubricItemIds = prevState.evaluations[prevState.activeIndex].rubricItemIds;
         const newRubricItemIds = state.evaluations[state.activeIndex].rubricItemIds;
         return newRubricItemIds.length > prevRubricItemIds.length;
      },
      content: (
         <>
            <p>
               Grading a response is as simple as clicking a rubric item (the box with a number in
               it).
            </p>
            <p>Try clicking a rubric item that matches your assessment!</p>
            <p>
               💡 To grade faster, you can press the number on your keyboard that corresponds with
               it.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.rubricItem,
      type: OnboardingStepType.pointer,
      selector: '.rubric-item-is-applied',
      placement: 'left',
      overlay: true,
      overlayOptions: {
         disableInteraction: false,
      },
      onMount: (elem: HTMLDivElement): void => {
         const input = elem.querySelector<HTMLInputElement>('.points-wrapper input');
         input?.focus();
      },
      content: (
         <>
            <p>
               <strong>Great!</strong> When grading, you can change the rubric at any time. So if
               you took off or gave too many points for a rubric item, you can change it and Lingco
               will apply those changes to other students who received the same mark.
            </p>
            <p>Try changing the points or feedback for this rubric item!</p>
         </>
      ),
   },
   {
      id: GradeActivityStep.pointAdjustment,
      type: OnboardingStepType.pointer,
      selector: '.submission-adjustment',
      placement: 'left',
      overlay: true,
      overlayOptions: {
         paddingTop: 4,
         paddingLeft: 4,
         paddingBottom: 4,
      },
      content: (
         <>
            <p>
               Sometimes you want to give individual feedback that doesn't align with the rubric.
               Here you can add or subtract points as well as provide specific comments about the
               response.
            </p>
            <p>
               💡 If you use the same comments often, you can pick them from the previously used
               comments.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.graderFooterButtons,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="grader-footer-buttons"]',
      placement: 'top',
      overlay: true,
      overlayOptions: {
         paddingTop: 6,
      },
      content: (
         <>
            <p>
               Once you're done grading and giving feedback, you can navigate to the next response
               using these buttons.
            </p>
            <p>
               💡 To move even faster, you can use the <strong>left</strong> and{' '}
               <strong>right</strong> arrow keys.
            </p>
         </>
      ),
   },
   {
      id: GradeActivityStep.promptListDropdown,
      type: OnboardingStepType.pointer,
      selector: '.prompt-list-dropdown',
      overlay: true,
      overlayOptions: {
         paddingTop: 3,
         paddingLeft: 3,
         paddingBottom: 3,
         disableInteraction: false,
      },
      primaryButton: false,
      advanceWhenVisible: '[data-tour="prompt-list"]',
      content: (
         <p>
            Once all of the responses have been graded, you can move to another prompt by clicking
            here.
         </p>
      ),
   },
   {
      id: GradeActivityStep.selectPrompt,
      type: OnboardingStepType.pointer,
      selector: '[data-tour="prompt-list"]',
      advanceWhenHidden: '[data-tour="prompt-list"]',
      overlay: true,
      overlayOptions: {
         paddingTop: 2,
         paddingBottom: 2,
         paddingLeft: 2,
         paddingRight: 2,
      },
      content: <p>Here's a list of prompts that you can choose from.</p>,
   },
   {
      id: GradeActivityStep.walkthroughCompletePost,
      type: OnboardingStepType.post,
      confetti: true,
      content: (
         <>
            <h1>Congratulations! 🎉</h1>
            <p>
               You've now learned to create, assign, and grade activities with feedback on Lingco.
               You have all of the tools that you need to run a class successfully on Lingco and we
               look forward to seeing your students on the platform.
            </p>
            <p>
               As you're exploring Lingco, if you have any questions, feel free to reach out by
               using the purple chat bubble in the bottom-right corner.
            </p>
         </>
      ),
   },
];

export default {
   id: OnboardingWalkthrough.gradeActivity,
   steps,
   startRoute: courseDashboardRoute,
} as IOnboardingWalkthrough;
