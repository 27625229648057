import * as _ from 'lodash';
import * as React from 'react';

import autobind from '@helpers/autobind';
import { camelCaseKeys } from '@helpers/ModifyKeys';
import { randomNumberBetween } from '@helpers/NumberUtils';
import { DiscussionBoardUpdate } from '@models/Activity';
import { Maybe } from '@models/Core';
import Toast from '@models/Toast';
import AuthService from '@services/AuthService';
import { OnlineCheckService } from '@services/OnlineCheckService';

import { AppStateContext } from './AppState';
import { ToastActions } from './components/Core/Toasts';
import { BannerName } from './components/Wrappers/Layout/Banner';
import Config from './Config';
import Constants from './Constants';
import { store } from './redux/Store';
import Emitter from './utilities/Emitter';

import { io, ManagerOptions, Socket } from 'socket.io-client';

const socketUrl = process.env.IS_LOCAL
   ? Constants.socketUrls.local
   : Constants.socketUrls[Config.environmentType];

interface WebSocketConnectionProps {
   children: React.ReactElement;
}

class WebSocketConnection extends React.Component<WebSocketConnectionProps> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   socket: Maybe<Socket>;
   retryTimeout = null;
   shouldConnect = true;
   isConnecting = false;
   eventEmitter: Emitter;
   retrying = false;
   reconnectionAttempts = 0;

   constructor(props: WebSocketConnectionProps) {
      super(props);
      autobind(this);

      this.eventEmitter = new Emitter();
   }

   componentDidMount(): void {
      this.connectToWebSocketServer();
   }

   componentWillUnmount(): void {
      this.shouldConnect = false;
      this.socket?.disconnect();
   }

   shouldComponentUpdate(nextProps: WebSocketConnectionProps): boolean {
      const { loggedIn } = store.getState().user;

      if (this.socket?.connected && !loggedIn) {
         this.socket.disconnect();
      } else if (!this.socket?.connected && loggedIn) {
         this.connectToWebSocketServer();
      }

      return this.props !== nextProps;
   }

   clearBanner(): void {
      this.context.setBanner({ body: '', show: false });
   }

   sleep(): Promise<void> {
      return new Promise((resolve) => {
         // After 60 retry attempts keep retrying every minute
         const retryTime = this.reconnectionAttempts * 1000;
         setTimeout(resolve, retryTime + randomNumberBetween(500, 1000));
      });
   }

   async retry(): Promise<void> {
      this.retrying = true;
      const user = store.getState().user;

      // If we want to add a retry attempt limit we need to do it here
      if (!this.socket?.connected && user?.loggedIn) {
         OnlineCheckService.setWebsocketConnectionStatus(false);
         if (this.reconnectionAttempts < 60) {
            this.reconnectionAttempts++;
         }
         await this.sleep();
         await this.connectToWebSocketServer();
         await this.retry();
         return;
      }

      // Reconnection successful so reset the retry state
      this.reconnectionAttempts = 0;
      this.retrying = false;
      return;
   }

   async connectToWebSocketServer(): Promise<void> {
      if (this.isConnecting || this.socket?.connected || !this.shouldConnect) {
         return;
      }

      const user = store.getState().user;
      if (socketUrl && user?.loggedIn) {
         this.isConnecting = true;

         const token = user?.accessToken || '';

         try {
            const options = {
               auth: {
                  token,
               },
               reconnection: false,
               transports: ['websocket'],
            } as Partial<ManagerOptions>;

            this.socket?.off();
            this.socket?.offAny();
            this.socket = io(socketUrl, options);
            this.socket.on('connect', this.handleConnected);
            this.socket.on('toast', this.handleSocketToast);
            this.socket.on('new_update_released', this.handleSocketNewUpdateReleased);
            this.socket.on('discussion_board_update', this.emitDiscussionBoardUpdate);
            this.socket.on('disconnect', () => {
               console.warn('WebSocket disconnected from notification server.  Reconnecting.');
               if (!this.retrying) {
                  this.retry();
               }
            });
            this.socket.on('connect_error', async (error) => {
               if (error.message === 'JWT Token Expired') {
                  await AuthService.refreshAccessToken();
               }

               if (!this.retrying) {
                  console.warn(
                     'WebSocket failed to connect to notification server. Reconnecting.',
                     error,
                  );
                  this.retry();
                  return;
               }
            });
         } catch (error) {
            if (!this.retrying) {
               console.error(error);
               this.retry();
            }
         } finally {
            this.isConnecting = false;
         }
      }
   }

   emit(action: string, data: Record<string, unknown>): void {
      if (this.socket && this.socket.connected) {
         this.socket.emit(action, data);
      }
   }

   isSocketBanner(): boolean {
      const socketBanners: readonly BannerName[] = [
         BannerName.COULD_NOT_RECONNECT,
         BannerName.TRYING_TO_RECONNECT,
      ];
      const { banner } = this.context;
      return banner?.bannerName ? socketBanners.includes(banner.bannerName) : false;
   }

   emitDiscussionBoardUpdate(update: DiscussionBoardUpdate): void {
      this.eventEmitter.emit('DISCUSSION_BOARD_UPDATE', camelCaseKeys(update));
   }

   handleSocketNewUpdateReleased(): void {
      this.context.setBanner({
         bannerName: BannerName.NEW_UPDATE_RELEASED,
         show: true,
      });
   }

   handleSocketToast(toast: Toast): void {
      if (toast.data) {
         toast.data = camelCaseKeys(toast.data);
      }
      if (toast.action) {
         if (toast.action === ToastActions.CLONE_COURSE_FINISHED && toast.data) {
            const { courseId: id, courseName: name } = toast.data;
            toast.timeout = 10000;
            if (_.isNumber(id) && _.isString(name)) {
               this.context.appendCourse({
                  id,
                  name,
                  demo: false,
                  needsToPurchaseLicense: false,
                  trialEndOn: null,
               });
            }
         }
      }
      this.context.dispatchToast(toast);
   }

   handleConnected(): void {
      if (this.isSocketBanner()) {
         this.clearBanner();
      }

      OnlineCheckService.setWebsocketConnectionStatus(true);
   }

   render(): React.ReactElement {
      return React.cloneElement(React.Children.only(this.props.children), {
         emit: this.emit,
         socketEventEmitter: this.eventEmitter,
      });
   }
}

export default WebSocketConnection;
