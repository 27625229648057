import * as React from 'react';

import autobind from '@helpers/autobind';
import Appearance from '@models/Appearance';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import ReactFocusLock from 'react-focus-lock';

import Constants from '../../../Constants';
import Blanket from '@components/Core/Blanket';
import Footer from './Footer';
import Header from './Header';

type KeyboardOrMouseEvent<T> =
   | React.MouseEvent<T>
   | React.KeyboardEvent<T>
   | KeyboardEvent
   | MouseEvent;

export interface IAction<T = React.MouseEvent<HTMLButtonElement>> {
   text: string;
   disabled?: boolean;
   loading?: boolean;
   keyboardShortcut?: string;
   onClick(event: T): void;
}

export interface ModalProps {
   /** Buttons to render in the footer */
   actions?: readonly IAction[];
   /** Animation classes to be applied during the entrance and exit */
   animations?: { enter?: string; exit?: string };
   /** Appearance of the primary action and header.  */
   appearance?: Appearance;
   /** Boolean indicating whether to focus on the first tabbable element inside the focus lock. */
   autoFocus?: boolean; // react-focus-lock
   /** Content of the modal */
   children?: React.ReactNode | React.ReactElement[];
   /** Class name for the modal body */
   bodyClassName?: string;
   /** Class name for the modal */
   className?: string;
   /** Component to render the footer of the modal, replaces internal implementation. */
   footer?: React.ReactElement;
   /** Class name for the modal footer */
   footerClassName?: string;
   /** Component to render the header of the modal, replaces internal implementation. */
   header?: React.ReactElement;
   /** Class name for the modal header */
   headerClassName?: string;
   /** The modal title; rendered in the header. */
   heading: string;
   /** Boolean indicating if clicking the overlay should close the modal. */
   shouldCloseOnOverlayClick: boolean;
   /** Boolean indicating if pressing the esc key should close the modal. */
   shouldCloseOnEscapePress: boolean;
   /** Width of the modal. */
   width?: string | number | 'small' | 'medium' | 'large' | 'x-large';
   /** Function that will be called to initiate the exit transition. */
   onClose(event: KeyboardOrMouseEvent<HTMLDivElement>): void;
   /** Function that will be called when the exit transition is complete. */
   onCloseComplete?(event: KeyboardOrMouseEvent<HTMLDivElement>): void;
   /** Function that will be called when the enter transition is complete. */
   onOpenComplete?(event?: React.AnimationEvent<HTMLDivElement>): void;
}

interface ModalState {
   /** Name of animated class to apply for enterance  */
   animateClass: string;
}

class Modal extends React.Component<ModalProps, ModalState> {
   static defaultProps: Partial<ModalProps> = {
      autoFocus: false,
      heading: '',
      onClose: () => {
         // empty
      },
      shouldCloseOnEscapePress: true,
      shouldCloseOnOverlayClick: true,
      width: 'medium',
   };

   constructor(props: ModalProps) {
      super(props);
      autobind(this);

      this.state = {
         animateClass: props.animations?.enter ?? '',
      };
   }

   componentDidMount(): void {
      document.body.classList.add('modal-open');
      document.addEventListener('keydown', (e) => this.handleKeyDown(e), false);
      if (!this.props.animations) {
         this.props.onOpenComplete?.();
      }
   }

   componentWillUnmount(): void {
      document.removeEventListener('keydown', this.handleKeyDown, false);
      document.body.classList.remove('modal-open');
   }

   handleAnimationEnd(event: React.AnimationEvent<HTMLDivElement>): void {
      const { onOpenComplete } = this.props;
      this.setState({ animateClass: '' });
      onOpenComplete?.(event);
   }

   handleKeyDown(event: KeyboardEvent): void {
      const {
         keys: { escape },
      } = Constants;
      const { onClose, shouldCloseOnEscapePress } = this.props;
      if (event.key === escape && shouldCloseOnEscapePress) {
         onClose(event);
      }
   }

   handleOverlayClick(event: React.MouseEvent<HTMLDivElement>): void {
      const { onClose, shouldCloseOnOverlayClick } = this.props;
      if (shouldCloseOnOverlayClick) {
         onClose(event);
      }
   }

   render(): React.ReactNode {
      const {
         actions,
         appearance,
         autoFocus,
         bodyClassName,
         children,
         className,
         footer,
         footerClassName,
         header,
         headerClassName,
         heading,
         width,
      } = this.props;
      const { animateClass } = this.state;
      const modalClassName = classnames('card', 'modal', width, className, animateClass);

      return createPortal(
         <>
            <Blanket onBlanketClicked={this.handleOverlayClick} />
            <ReactFocusLock returnFocus autoFocus={autoFocus}>
               <div
                  className={modalClassName}
                  tabIndex={-1}
                  role='dialog'
                  aria-labelledby='modal-heading'
                  onAnimationEnd={this.handleAnimationEnd}
               >
                  <Header
                     appearance={appearance}
                     className={headerClassName}
                     component={header}
                     heading={heading}
                  />
                  {children && (
                     <div className={`${bodyClassName ? bodyClassName : 'content-row single'}`}>
                        {children}
                     </div>
                  )}
                  <Footer
                     actions={actions}
                     appearance={appearance}
                     className={footerClassName}
                     component={footer}
                  />
               </div>
            </ReactFocusLock>
         </>,
         document.body,
      );
   }
}

export default Modal;
