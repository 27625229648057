import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowUp from '@icons/general/icon-arrow-up.svg';
import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import { CourseProfile, ModuleItemProfile, ModuleProfile } from './models';

interface ModuleItemSelectorProps {
   courses: readonly CourseProfile[];
   currentModuleItemId: number;
   selectedModuleItemId: Maybe<number>;
   selectModuleItem(moduleItemId: number): void;
   toggleCourse(courseId: number): void;
   toggleModule(moduleId: number): void;
}

const ModuleItemSelector: React.FC<ModuleItemSelectorProps> = ({
   courses,
   selectedModuleItemId,
   selectModuleItem,
   toggleCourse,
   toggleModule,
}) => {
   const renderArrow = (isExpanded: boolean): React.ReactNode =>
      isExpanded ? <IconArrowUp /> : <IconArrowDown />;

   const renderModuleItems = (moduleItems: readonly ModuleItemProfile[]): React.ReactNode => (
      <div className='module-items'>
         {moduleItems.map(({ name: activityName, id }) => (
            <div
               className={classnames('module-item-wrapper', {
                  active: selectedModuleItemId === id,
               })}
               onClick={() => selectModuleItem(id)}
               key={id}
            >
               <div className='nav-icon'>
                  <IconMenuLevel />
               </div>
               <p className='module-item-name'>{activityName}</p>
            </div>
         ))}
      </div>
   );

   const renderModules = (
      isFetchingRubrics: boolean,
      modules: readonly ModuleProfile[],
   ): React.ReactNode => {
      if (isFetchingRubrics) {
         return <p>Loading assignments...</p>;
      } else if (!modules.length) {
         return <p>This course has no assignments.</p>;
      } else {
         return (
            <div className='modules'>
               {modules.map(({ name: moduleName, id: moduleId, isExpanded, moduleItems }) => (
                  <div className='module-wrapper' key={moduleId}>
                     <div className='module-title-wrapper' onClick={() => toggleModule(moduleId)}>
                        <div className='module-title title'>{moduleName}</div>
                        <div className='carrot'>{renderArrow(isExpanded)}</div>
                     </div>
                     {isExpanded && renderModuleItems(moduleItems as readonly ModuleItemProfile[])}
                  </div>
               ))}
            </div>
         );
      }
   };

   return (
      <div className='module-item-selector'>
         {courses.map(
            ({ id: courseId, name: courseName, modules, isExpanded, isFetchingRubrics }) => (
               <div className='course-wrapper' key={courseId}>
                  <div className='course-title-wrapper' onClick={() => toggleCourse(courseId)}>
                     <div className='course-title title'>{courseName}</div>
                     <div className='carrot'>{renderArrow(isExpanded)}</div>
                  </div>
                  {isExpanded &&
                     renderModules(isFetchingRubrics, modules as readonly ModuleProfile[])}
               </div>
            ),
         )}
      </div>
   );
};

export default React.memo(ModuleItemSelector);
