import * as React from 'react';

import Switch from '@components/Common/Switch';
import { Maybe } from '@models/Core';
import CourseService from '@services/CourseService';
import CurrencyFormat, { Values } from 'react-currency-format';

import Constants from '../../../Constants';

interface CoursePaymentInfoProps {
   courseId: number;
   defaultPrice: number;
   onOverridePriceChange(values: Values): void;
   priceOverride: Maybe<number>;
   toggleOverridePrice(): void;
}

const CoursePaymentInfo: React.FC<CoursePaymentInfoProps> = ({
   courseId,
   defaultPrice,
   priceOverride,
   onOverridePriceChange,
   toggleOverridePrice,
}) => {
   const [shouldStudentsPay, setShouldStudentsPay] = React.useState<boolean>(false);

   const fetchShouldStudentsPay = async () => {
      const result = await CourseService.shouldStudentsPay(courseId);
      setShouldStudentsPay(result);
   };

   React.useEffect(() => {
      fetchShouldStudentsPay();
   }, [courseId]);

   return (
      <div className='card course-card'>
         <div className='content-form'>
            <div className='card-title full-width'>
               <div className='title'>Payment Info</div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>Should Students Pay</label>
                  <p className='small'>
                     {shouldStudentsPay
                        ? 'Students are required to pay'
                        : 'Students are not required to pay'}
                  </p>
               </div>
               <div className='col-xs-12 col-sm-6'>
                  {shouldStudentsPay && (
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <Switch
                              name='priceOverride'
                              checked={priceOverride !== null}
                              onChange={toggleOverridePrice}
                           >
                              <label className='field-title'>Override Price</label>
                           </Switch>
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <CurrencyFormat
                              displayType={priceOverride === null ? 'text' : 'input'}
                              renderText={(formattedValue) => (
                                 <p className='currency-text'>{formattedValue}</p>
                              )}
                              value={priceOverride ?? defaultPrice}
                              thousandSeparator
                              prefix={Constants.currencies.USD}
                              onValueChange={onOverridePriceChange}
                              decimalScale={Constants.currencyDecimalScales.USD}
                              fixedDecimalScale
                           />
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default CoursePaymentInfo;
