import * as React from 'react';

import IconTransferFilesComputer from '@icons/nova-line/23-Data-Transfer/transfer-files-computer.svg';
import ContentService from '@services/ContentService';
import Tippy from '@tippyjs/react';

import { AppStateContext } from '../AppState';
import ConfirmModal from './Core/ConfirmModal';
import FeatureCheck from './Core/FeatureCheck';
import { ToastActions } from './Core/Toasts';

interface TransferOwnershipButtonProps {
   contentId: number;
   onOwnershipTransfer(newCreatorId: number): void;
   isContentOwner: boolean;
}

const TransferOwnershipButton: React.FC<TransferOwnershipButtonProps> = ({
   contentId,
   isContentOwner,
   onOwnershipTransfer,
}) => {
   const [modalOpen, setModalOpen] = React.useState<boolean>(false);
   const { dispatchToast, userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }

   const transferOwnership = async () => {
      await ContentService.transferOwnership(Number(contentId));
      onOwnershipTransfer(userProfile.id);
      setModalOpen(false);
      dispatchToast({
         action: ToastActions.CONTENT_OWNERSHIP_TRANSFERRED,
         data: {
            id: contentId,
         },
      });
   };

   return (
      <>
         {!isContentOwner && (
            <FeatureCheck feature='content_transfer_ownership'>
               <Tippy content='Transfer Ownership'>
                  <div className='icon-action' onClick={() => setModalOpen(true)}>
                     <IconTransferFilesComputer />
                  </div>
               </Tippy>
            </FeatureCheck>
         )}
         {modalOpen && (
            <ConfirmModal
               header='Transfer Ownership?'
               message='Are you sure you want to transfer ownership?'
               onClose={() => setModalOpen(false)}
               onPrimaryClick={transferOwnership}
               primaryClickText='Yes'
               closeText='No'
            />
         )}
      </>
   );
};

export default TransferOwnershipButton;
