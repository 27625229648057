import * as _ from 'lodash';
import * as React from 'react';

import useStateWithReset from '@hooks/use-state-with-reset';
import { ModuleItemLink } from '@models/Course/ModuleItem';
import classnames from 'classnames';

import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';
import { getLinkError, getNameError, MAX_LINK_LENGTH, MAX_NAME_LENGTH } from './ModuleUtils';

interface LinkDetailsProps {
   canEditModule: boolean;
   item: ModuleItemLink;
   editItem(update: Partial<ModuleItemLink>): void;
}

const LinkDetails: React.FC<LinkDetailsProps> = ({ canEditModule, item, editItem }) => {
   const [nameInputValue, setNameInputValue] = useStateWithReset<string>(item.name ?? '');
   const [linkInputValue, setLinkInputValue] = useStateWithReset<string>(item.linkUrl ?? '');

   const nameError = getNameError(nameInputValue);
   const linkError = getLinkError(linkInputValue);

   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setNameInputValue(event.target.value);

   const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setLinkInputValue(event.target.value);

   const handleNameBlur = (): void => {
      if (!canEditModule) {
         return;
      } else if (!nameError) {
         editItem({ name: nameInputValue, itemName: nameInputValue });
      } else if (_.isString(item.name)) {
         setNameInputValue(item.name);
      }
   };

   const handleLinkBlur = (): void => {
      if (!canEditModule) {
         return;
      } else if (linkInputValue) {
         const hasPrefix = /^https?:/.test(linkInputValue.toLowerCase());
         editItem({
            linkUrl: hasPrefix ? linkInputValue : `https://${linkInputValue}`,
         });
      } else {
         editItem({ linkUrl: linkInputValue });
      }
   };

   return (
      <ModuleItemSettingsAccordion title='Link Details'>
         <div className='row'>
            <div className='col-xs-12'>
               <label className='field-title'>Name</label>
               <input
                  className={classnames({ error: nameError })}
                  disabled={!canEditModule}
                  maxLength={MAX_NAME_LENGTH}
                  name='name'
                  onBlur={handleNameBlur}
                  onChange={handleNameChange}
                  type='text'
                  value={nameInputValue}
               />
               {nameError && <p className='error'>{nameError}</p>}
            </div>
            <div className='col-xs-12'>
               <label className='field-title'>URL</label>
               <input
                  className={classnames({ error: linkError })}
                  disabled={!canEditModule}
                  maxLength={MAX_LINK_LENGTH}
                  name='linkUrl'
                  onBlur={handleLinkBlur}
                  onChange={handleLinkChange}
                  type='text'
                  value={linkInputValue}
               />
               {linkError && <p className='error'>{linkError}</p>}
            </div>
         </div>
      </ModuleItemSettingsAccordion>
   );
};

export default LinkDetails;
