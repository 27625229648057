/* eslint-disable complexity */
import Content from '@models/Content';
import { IVocabTermProgress } from '@models/IVocabTerm';

import { cardFactory, ICardFactoryParams, updateCards } from './cardFunctions';
import {
   IBaseVocabSessionCard,
   IFeedback,
   IVocabSessionStats,
   IVocabSessionTerm,
   VocabSessionType,
} from './Models';
import { IVocabSessionState } from './VocabSession';

export enum VocabSessionActionType {
   advanceCard = 'ADVANCE_CARD',
   hideFeedback = 'HIDE_FEEDBACK',
   setNextButton = 'SET_NEXT_BUTTON',
   setSessionData = 'SET_SESSION_DATA',
   showFeedback = 'SHOW_FEEDBACK',
   showSpeedOverlay = 'SHOW_SPEED_OVERLAY',
   setShowAutoplayBlocked = 'SHOW_AUTOPLAY_BLOCKED',
   toggleMute = 'TOGGLE_MUTE',
   togglePause = 'TOGGLE_PAUSE',
   toggleSpeedCountdown = 'TOGGLE_SPEED_COUNTDOWN',
   updateLevel = 'UPDATE_LEVEL',
   updateProgress = 'UPDATE_PROGRESS',
}

export type VocabSessionAction =
   | { type: VocabSessionActionType.advanceCard; resequenceCardId?: string }
   | { type: VocabSessionActionType.setNextButton; show: boolean }
   | { type: VocabSessionActionType.showFeedback; feedback: IFeedback }
   | {
        type: VocabSessionActionType.setSessionData;
        cards: readonly IBaseVocabSessionCard[];
        id: number;
        progress: Record<number, IVocabTermProgress>;
        sessionType: VocabSessionType;
        settings: Content;
        terms: Record<number, IVocabSessionTerm>;
        disableVocabSetTimer: boolean;
     }
   | { type: VocabSessionActionType.showSpeedOverlay; success: boolean }
   | { type: VocabSessionActionType.setShowAutoplayBlocked; show: boolean }
   | { type: VocabSessionActionType.hideFeedback }
   | { type: VocabSessionActionType.toggleMute }
   | { type: VocabSessionActionType.togglePause }
   | { type: VocabSessionActionType.toggleSpeedCountdown }
   | {
        type: VocabSessionActionType.updateLevel;
        termId: number;
        newLevel: number;
     }
   | {
        type: VocabSessionActionType.updateProgress;
        termId: number;
        progress: IVocabTermProgress;
        stats: IVocabSessionStats;
     };

const vocabSessionReducer = (
   state: IVocabSessionState,
   action: VocabSessionAction,
): IVocabSessionState => {
   switch (action.type) {
      case VocabSessionActionType.advanceCard: {
         const showSummaryState = {
            ...state,
            isComplete: true,
            showCard: false,
            showFeedback: false,
            showNext: false,
            showProgressBar: false,
            showSpeedCountdown: false,
            showSpeedOverlay: false,
            showSummary: true,
            showToggleAudio: false,
         };
         if (state.showSpeedOverlay) {
            return showSummaryState;
         }
         const cards = [...updateCards(state.cards, state.sessionType, action.resequenceCardId)];
         const index = state.index === null ? 0 : state.index + 1;
         if (index === cards.length) {
            if (state.sessionType === VocabSessionType.speedReview) {
               return {
                  ...state,
                  showSpeedOverlay: true,
                  speedSessionSuccessful: true,
               };
            } else {
               return showSummaryState;
            }
         }
         const termHasImage = !!state.terms[cards[index].termId].image;
         const params: ICardFactoryParams = {
            audioEnabled: !(state.isMuted || !index) && state.settings.language !== 'asl',
            imageResponsePossible:
               Object.values(state.terms).filter((i) => i.image).length > 0 && termHasImage,
            language: state.settings.language,
            sessionType: state.sessionType,
            termHasImage,
            typingEnabled: state.settings.language !== 'asl',
         };
         cards[index] = cardFactory(cards[index], state.terms, params);
         return {
            ...state,
            cards,
            feedback: null,
            index,
            showCard: true,
            showFeedback: false,
            showProgressBar: state.sessionType !== VocabSessionType.speedReview,
            showSpeedCountdown: false,
            showToggleAudio: state.settings.language !== 'asl',
         };
      }
      case VocabSessionActionType.showFeedback:
         return {
            ...state,
            feedback: action.feedback,
            showFeedback: true,
         };
      case VocabSessionActionType.setNextButton:
         return {
            ...state,
            showNext: action.show,
         };
      case VocabSessionActionType.setShowAutoplayBlocked:
         return {
            ...state,
            showAutoplayBlocked: action.show,
         };
      case VocabSessionActionType.setSessionData:
         return {
            ...state,
            cards: action.cards,
            id: action.id,
            isFetching: false,
            isReady: true,
            progress: action.progress,
            sessionType: action.sessionType,
            isMuted: action.settings.language === 'asl',
            settings: action.settings,
            terms: action.terms,
            disableVocabSetTimer: action.disableVocabSetTimer,
         };
      case VocabSessionActionType.showSpeedOverlay:
         return {
            ...state,
            showSpeedOverlay: true,
            speedSessionSuccessful: action.success,
         };
      case VocabSessionActionType.hideFeedback:
         return {
            ...state,
            showFeedback: false,
         };
      case VocabSessionActionType.toggleMute:
         return {
            ...state,
            isMuted: !state.isMuted,
         };
      case VocabSessionActionType.togglePause:
         return {
            ...state,
            isPaused: !state.isPaused,
         };
      case VocabSessionActionType.toggleSpeedCountdown:
         return {
            ...state,
            showSpeedCountdown: !state.showSpeedCountdown,
         };
      case VocabSessionActionType.updateLevel:
         return {
            ...state,
            progress: {
               ...state.progress,
               [action.termId]: {
                  ...state.progress[action.termId],
                  level: action.newLevel,
               },
            },
         };
      case VocabSessionActionType.updateProgress: {
         const termProgress = state.progress[action.termId];
         return {
            ...state,
            progress: {
               ...state.progress,
               [action.termId]: {
                  ...termProgress,
                  ...action.progress,
               },
            },
            stats: action.stats,
         };
      }
      default:
         return state;
   }
};

export default vocabSessionReducer;
