import * as React from 'react';

import {
   ActivityBuilderMode,
   MultipleChoicePrompt,
   MultipleChoiceScoringMethod,
} from '@models/Activity';

import Switch from '@components/Common/Switch';
import { PromptOptionsProps } from '@components/Activity/Builder/Prompt';

const MultipleChoicePromptOptions: React.FC<
   PromptOptionsProps<MultipleChoicePrompt<ActivityBuilderMode>>
> = ({ onUpdate, prompt }) => {
   const toggleMultiple = (): void => {
      const multiple = !prompt.multiple;
      const options = multiple ? prompt.options : prompt.options.map((i) => ({ ...i, score: 0.0 }));
      const scoringMethod = multiple ? MultipleChoiceScoringMethod.correct : null;
      onUpdate({ multiple, options, scoringMethod });
   };

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='margin-top-s'>
               <Switch name='multiple' checked={prompt.multiple} onChange={toggleMultiple}>
                  Multi-Select
               </Switch>
               {prompt.multiple && prompt.scoringMethod && (
                  <div className='margin-top-s'>
                     <label className='field-title'>Scoring</label>
                     <select
                        name='scoringMethod'
                        value={prompt.scoringMethod}
                        onChange={(e) =>
                           onUpdate({
                              scoringMethod: e.target.value as MultipleChoiceScoringMethod,
                           })
                        }
                     >
                        <option value={MultipleChoiceScoringMethod.correct}>Correct</option>
                        <option value={MultipleChoiceScoringMethod.allOrNothing}>
                           All or Nothing
                        </option>
                        <option value={MultipleChoiceScoringMethod.correctMinusIncorrect}>
                           Correct minus Incorrect
                        </option>
                     </select>
                  </div>
               )}
            </div>
            <div className='margin-top-s'>
               <Switch
                  name='shuffle'
                  checked={prompt.shuffle}
                  onChange={() => onUpdate({ shuffle: !prompt.shuffle })}
               >
                  Shuffle Choices
               </Switch>
            </div>
            <div className='margin-top-s'>
               <Switch
                  name='showFeedback'
                  checked={prompt.showFeedback}
                  onChange={() => onUpdate({ showFeedback: !prompt.showFeedback })}
               >
                  Show Feedback
               </Switch>
            </div>
         </div>
      </div>
   );
};

export default MultipleChoicePromptOptions;
