import * as _ from 'lodash';
import * as React from 'react';

import { applyDrag } from '@components/Activity/DragUtils';
import Button from '@components/Common/Button';
import { Container, Draggable, DropResult } from '@components/Core/DragAndDrop';
import indexDeep from '@helpers/IndexDeep';
import { randomShortId } from '@helpers/RandomStringUtils';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import {
   ActivityBuilderMode,
   MultipleChoiceOption as MultipleChoiceOptionType,
   MultipleChoicePrompt as MultipleChoicePromptType,
} from '@models/Activity';

import Constants from '../../../../../Constants';
import MultipleChoiceOption from './MultipleChoiceOption';

const MIN_OPTION_COUNT = 2;
const DEFAULT_OPTION_COUNT = 3;

interface MultipleChoicePromptProps {
   options: readonly MultipleChoiceOptionType<ActivityBuilderMode>[];
   multiple: boolean;
   groupName?: string;
   showFeedback: boolean;
   onUpdate(update: Partial<MultipleChoicePromptType<ActivityBuilderMode>>): void;
}

const MultipleChoicePrompt: React.FC<MultipleChoicePromptProps> = ({
   groupName,
   multiple,
   options,
   showFeedback,
   onUpdate,
}) => {
   const { nonDragAreaSelector } = Constants;

   React.useEffect(() => {
      if (!options.length) {
         const defaultOptions = [];
         for (let i = 0; i < DEFAULT_OPTION_COUNT; i++) {
            defaultOptions.push(generateOption());
         }
         onUpdate({ options: defaultOptions });
      }
   }, []);

   const generateOption = (): MultipleChoiceOptionType<ActivityBuilderMode> => ({
      key: randomShortId(),
      data: '<p>&nbsp;</p>',
      score: 0,
      feedback: '',
      id: null,
   });

   const addOption = (): void => {
      onUpdate({ options: [...options, generateOption()] });
   };

   const onOptionUpdate = (
      optionKey: string,
      update: Partial<MultipleChoiceOptionType<ActivityBuilderMode>>,
   ): void => {
      onUpdate({
         options: options.map((i) => (i.key === optionKey ? { ...i, ...update } : i)),
      });
   };

   const removeOption = (optionKey: string): void => {
      const updatedOptions = [...options];
      if (updatedOptions.length > MIN_OPTION_COUNT) {
         const index = options.findIndex((i) => i.key === optionKey);
         updatedOptions.splice(index, 1);
         onUpdate({ options: updatedOptions });
      }
   };

   const handleChange = (key: string, event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      onUpdate({
         options: options.map((option) => {
            let score = 0;
            if (option.key === key) {
               if (multiple) {
                  score = checked ? 1.0 : 0;
               } else {
                  if (!option.score) {
                     score = 1.0;
                  }
               }
            } else if (multiple && option.score !== undefined) {
               score = option.score;
            }
            return { ...option, score };
         }),
      });
   };

   const handleDrop = (event: DropResult): void => {
      const updatedOptions = applyDrag(options, event);
      indexDeep(updatedOptions);
      onUpdate({ options: updatedOptions });
   };

   return (
      <div className='multiple-choice-options'>
         <Container
            groupName='options-list'
            getChildPayload={(i) => options[i]}
            nonDragAreaSelector={nonDragAreaSelector}
            onDrop={handleDrop}
         >
            {options.map((o) => (
               <Draggable
                  key={o.key}
                  data-no-dnd // Prevents dragging for DNDKit
               >
                  <MultipleChoiceOption
                     {...o}
                     checked={!!o.score}
                     groupName={multiple ? undefined : groupName}
                     multiple={multiple}
                     onOptionCheck={(e) => (o.key ? handleChange(o.key, e) : undefined)}
                     onOptionUpdate={(update) =>
                        o.key ? onOptionUpdate(o.key, update) : undefined
                     }
                     onRemoveOption={() => (o.key ? removeOption(o.key) : undefined)}
                     showFeedback={showFeedback}
                     showRemove={options.length > MIN_OPTION_COUNT}
                     optionKey={o.key}
                  />
               </Draggable>
            ))}
         </Container>
         <Button
            line
            className='margin-top-s half-width'
            onClick={addOption}
            icon={<IconAddSmall aria-hidden />}
            data-test='add-option-btn'
         >
            Add Option
         </Button>
      </div>
   );
};

export default MultipleChoicePrompt;
