import * as React from 'react';

import autobind from '@helpers/autobind';
import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';
import IconPause from '@icons/general/video-control-pause.svg';
import IconPlay from '@icons/general/video-control-play.svg';
import { SpokenResponseResponse } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import pluralize from 'pluralize';

interface SpokenResponsePromptProps {
   acceptedResponses: readonly string[];
   className?: string;
   response: SpokenResponseResponse;
}

interface SpokenResponseState {
   acceptedResponses: readonly string[];
   audio: Maybe<HTMLAudioElement>;
   isCorrect: boolean;
   isDisabled: boolean;
   isIncorrect: boolean;
   isPlaying: boolean;
   result: Maybe<string | readonly string[]>;
}

class SpokenResponsePrompt extends React.Component<SpokenResponsePromptProps, SpokenResponseState> {
   constructor(props: SpokenResponsePromptProps) {
      super(props);
      autobind(this);

      this.state = {
         acceptedResponses: [],
         audio: null,
         isCorrect: false,
         isDisabled: false,
         isIncorrect: false,
         isPlaying: false,
         result: null,
      };
   }

   componentDidMount(): void {
      const { acceptedResponses, response } = this.props;
      const { attempts, accepted } = response;
      this.checkDisabled();
      this.setState(
         {
            acceptedResponses: [...acceptedResponses],
            isCorrect: attempts > 0 && !!accepted,
            isIncorrect: attempts > 0 && !accepted,
         },
         this.initializeAudio,
      );
   }

   checkDisabled(): void {
      const { acceptedResponses, response } = this.props;
      const isDisabled = !acceptedResponses.filter((i) => i.length).length || !response.attempts;
      this.setState({ isDisabled });
   }

   initializeAudio(): void {
      const {
         response: { fileUrl },
      } = this.props;
      if (fileUrl) {
         const audio = new Audio(fileUrl);
         audio.onended = () => {
            this.setState({ isPlaying: false });
         };
         this.setState({ audio });
      }
   }

   renderIcon(): React.ReactNode {
      const {
         response: { attempts, fileUrl },
      } = this.props;
      const { audio, isCorrect, isIncorrect, isPlaying } = this.state;
      const isDisabled = !attempts && !fileUrl;
      const className = classnames('activity-item-icon', {
         correct: isCorrect && !isDisabled,
         disabled: isDisabled,
         incorrect: isIncorrect && !isDisabled,
         pointer: !!attempts && audio,
      });
      if (audio) {
         return (
            <div className={className} onClick={this.togglePlaying}>
               {isPlaying ? (
                  <IconPause className='icon-white' />
               ) : (
                  <IconPlay className='icon-white' />
               )}
            </div>
         );
      } else {
         return (
            <div className={className}>
               <IconBuilderRecording />
            </div>
         );
      }
   }

   renderMessage(): React.ReactNode {
      const {
         response: { attempts, fileUrl },
      } = this.props;
      const { isCorrect, isIncorrect } = this.state;
      const attemptString = `${attempts} ${pluralize('attempt', attempts)}`;
      if (isCorrect) {
         return <p className='correct'>Correct! {attemptString}</p>;
      } else if (isIncorrect) {
         return <p className='incorrect'>Incorrect. {attemptString}</p>;
      } else if (!attempts && !fileUrl) {
         return <p>No Attempts</p>;
      }
      return null;
   }

   togglePlaying(event: React.MouseEvent<HTMLDivElement>): void {
      const { audio, isPlaying } = this.state;
      if (!audio) {
         return;
      }
      const target = event.target as HTMLDivElement;
      if (target.tagName !== 'SPAN') {
         this.setState({ isPlaying: !isPlaying }, () => {
            this.state.isPlaying ? audio.play() : audio.pause();
         });
      }
   }

   render(): React.ReactNode {
      const { result, isIncorrect } = this.state;
      const icon = this.renderIcon();
      const message = this.renderMessage();
      return (
         <>
            <div className='activity-builder-audio'>
               {icon}
               {message}
            </div>
            {result && isIncorrect && (
               <div className='row padding-top-m'>
                  <div className='col-xs-12'>
                     <p className='error'>It sounds like you said: {result}</p>
                  </div>
               </div>
            )}
         </>
      );
   }
}

export default SpokenResponsePrompt;
