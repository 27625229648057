import * as React from 'react';

import sonixLanguageOptions from '@helpers/SonixLanguageOptions';
import FileVideoUpload from '@icons/nova-line/95-Files-Video/file-video-information.svg';

import { isSupportedSonixLanguage, SupportedSonixLanguages } from '@models/MediaTranscript';
import Button from '@components/Common/Button';

type Props = {
   language?: SupportedSonixLanguages;
   onContinue(): void;
   setLanguage(language: SupportedSonixLanguages): void;
};

const VideoLanguageSelect: React.FC<Props> = (props) => {
   const disableContinue = props.language === undefined;

   const handleLanguageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { value } = event.target;
      if (isSupportedSonixLanguage(value)) {
         props.setLanguage(value);
      } else {
         alert('Language selection is not supported.');
      }
   };

   return (
      <div className='full-main-body-area'>
         <div className='visual'>
            <FileVideoUpload />
         </div>
         <div className='title medium'>Video Language</div>
         <p className='margin-top-s margin-bottom-s text-center'>
            Set the target language of the videos to upload
         </p>
         <select
            className='cs-select cs-select-style max-third-width margin-bottom-m'
            name='language'
            onChange={handleLanguageChange}
            value={props.language}
            defaultValue={undefined}
         >
            <option />
            {sonixLanguageOptions}
         </select>
         <Button className='margin-bottom-m' onClick={props.onContinue} disabled={disableContinue}>
            Continue
         </Button>
      </div>
   );
};

export default VideoLanguageSelect;
