import * as React from 'react';

import IconSpeakerVolumeHigh from '@icons/nova-solid/37-Audio/speaker-volume-high.svg';
import IconSpeakerVolumeOff from '@icons/nova-solid/37-Audio/speaker-volume-off.svg';

interface IToggleAudioButtonProps {
   isMuted: boolean;
   showToggleAudio: boolean;
   toggleMute(): void;
}

const ToggleAudioButton: React.FC<IToggleAudioButtonProps> = ({
   isMuted,
   showToggleAudio,
   toggleMute,
}) => {
   if (showToggleAudio) {
      return (
         <div className='session-control-button toggle-audio' onClick={toggleMute}>
            {isMuted ? <IconSpeakerVolumeOff /> : <IconSpeakerVolumeHigh />}
         </div>
      );
   }
   return <></>;
};

export default React.memo(ToggleAudioButton);
