import * as React from 'react';

import Button from '@components/Common/Button';

interface EmbedLinkTabProps {
   onImageInsertion(src: string): void;
}

const EmbedLinkTab: React.FC<EmbedLinkTabProps> = ({ onImageInsertion }) => {
   const [link, setLink] = React.useState<string>('');

   return (
      <div className='padding-s'>
         <input
            type='text'
            autoFocus
            placeholder='Paste the image link...'
            value={link}
            onChange={(e) => setLink(e.target.value)}
         />
         <Button fullWidth onClick={() => onImageInsertion(link)} className='margin-top-s'>
            Embed Image
         </Button>
         <div className='flex justify-center gray-text small-text margin-top-s'>
            Works with any image from the web.
         </div>
      </div>
   );
};

export default EmbedLinkTab;
