import { IOnboardingWalkthrough, OnboardingWalkthrough } from './Models';
import ActivityBuilderIntro from './Walkthroughs/ActivityBuilderIntro';
import AssignActivity from './Walkthroughs/AssignActivity';
import ContentLibraryIntro from './Walkthroughs/ContentLibraryIntro';
import GradeActivity from './Walkthroughs/GradeActivity';

const walkthroughs: Record<string, IOnboardingWalkthrough> = {
   [OnboardingWalkthrough.contentLibraryIntro]: ContentLibraryIntro,
   [OnboardingWalkthrough.activityBuilderIntro]: ActivityBuilderIntro,
   [OnboardingWalkthrough.assignActivity]: AssignActivity,
   [OnboardingWalkthrough.gradeActivity]: GradeActivity,
   // [OnboardingWalkthrough.discussionActivity]: null,
   // [OnboardingWalkthrough.gradebookIntro]: null,
   // [OnboardingWalkthrough.lessonBuilderIntro]: null,
   // [OnboardingWalkthrough.recordingActivity]: RecordingActivity
   // [OnboardingWalkthrough.videoComprehensionActivity]: null,
   // [OnboardingWalkthrough.vocabBuilderIntro]: null
};

export default Object.freeze(walkthroughs);
