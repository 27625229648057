import * as React from 'react';

import { Maybe } from '@models/Core';
import { getStyleSheet } from '@services/StylesService';
import scope from 'scope-css';

interface StyleWrapperProps extends React.HTMLProps<HTMLDivElement> {
   children: React.ReactNode;
   styleId?: Maybe<number>;
}

const StyleWrapper: React.FC<StyleWrapperProps> = ({ children, styleId, ...props }) => {
   const scopeId = 'scope-' + Math.random().toString(16).slice(2);
   const [css, setCss] = React.useState<string>('');

   React.useEffect(() => {
      if (styleId) {
         getStyleSheet(styleId).then((response) => {
            setCss(response.css);
         });
      }
   }, [styleId]);

   return (
      <div id={scopeId} {...props}>
         <style>{scope(css, '#' + scopeId)}</style>
         {children}
      </div>
   );
};

export default React.memo(StyleWrapper);
