import * as React from 'react';

import IconUnsplash from '@icons/general/unsplash.svg';
import classNames from 'classnames';
import { usePopper } from 'react-popper';
import { Editor as TinyMCEEditor } from 'tinymce';
import { createApi } from 'unsplash-js';

import elementRemoved from '@helpers/ElementRemoved';
import useOutsideAlerter from '@hooks/use-outside-alerter';
import { Maybe } from '@models/Core';
import EmbedLinkTab from './EmbedLinkTab';
import UnsplashTab from './UnsplashTab';
import UploadTab from './UploadTab';

import { Basic } from 'unsplash-js/dist/methods/photos/types';

interface InsertImageModalProps {
   editor?: TinyMCEEditor;
   id: string;
   onDismiss?(): void;
}

const unsplash = createApi({
   apiUrl: `${window.location.origin}/api/services/unsplash-proxy`,
});

const InsertImageModal: React.FC<InsertImageModalProps> = ({ editor, id, onDismiss }) => {
   const [activeTab, setActiveTab] = React.useState('Upload');
   const [popperElement, setPopperElement] = React.useState<Maybe<HTMLDivElement>>(null);
   const dialogRef = React.useRef<HTMLDivElement>(null);
   const [defaultUnsplashImages, setDefaultUnsplashImages] = React.useState<Basic[]>([]);

   const placeholderSelector = `div.image-placeholder[data-placeholder-id="${id}"]`;

   const referenceElement = id ? document.querySelector<HTMLDivElement>(placeholderSelector) : null;

   const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [
         {
            name: 'offset',
            options: {
               offset: [0, 10],
            },
         },
         {
            name: 'computeStyles',
            options: {
               gpuAcceleration: false, // disables transform: translate3d
               adaptive: false, // prevents from adjusting popper's position on each update, reduces the jitter
            },
         },
      ],
   });

   const loadDefaultUnsplashImages = () => {
      unsplash.photos.getRandom({ query: 'travel', count: 30 }).then((result) => {
         if (Array.isArray(result.response)) {
            setDefaultUnsplashImages(result.response);
         }
      });
   };

   React.useEffect(() => {
      loadDefaultUnsplashImages();
   }, []);

   React.useEffect(() => {
      let observer: MutationObserver | null = null;

      const createdCallback = (newObserver: MutationObserver) => {
         observer = newObserver;
      };

      elementRemoved({ selector: placeholderSelector, createdCallback }).then(() => {
         onDismiss?.();
      });
      return () => {
         observer?.disconnect();
      };
   }, [placeholderSelector]);

   const handleOutsideClick = () => {
      onDismiss?.();
   };

   useOutsideAlerter(handleOutsideClick, dialogRef);

   const onImageInsertion = (src: string, imageAttributes?: Record<string, string>) => {
      if (editor) {
         editor.execCommand('mceInsertImage', false, {
            id,
            attributes: { src, ...imageAttributes },
         });
      }
      onDismiss?.();
   };

   const renderTab = (name: string, icon?: JSX.Element): React.ReactNode => (
      <div
         className={classNames('col', { active: name === activeTab })}
         data-test={`div-${name}-invite-tab`}
         onClick={() => setActiveTab(name)}
      >
         <div className='title small'>
            {icon} {name}
         </div>
      </div>
   );

   if (!referenceElement) {
      return null;
   }

   return (
      <div
         ref={setPopperElement}
         style={{
            ...styles.popper,
            zIndex: 10,
         }}
         {...attributes.popper}
      >
         <div className='image-dialog' ref={dialogRef} tabIndex={-1}>
            <div className='section-tab'>
               <div className='row'>
                  {renderTab('Upload')}
                  {renderTab('Embed Link')}
                  {renderTab('Unsplash', <IconUnsplash />)}
               </div>
            </div>
            <div className='section-panel'>
               {activeTab === 'Upload' && <UploadTab onImageInsertion={onImageInsertion} />}
               {activeTab === 'Embed Link' && <EmbedLinkTab onImageInsertion={onImageInsertion} />}
               {activeTab === 'Unsplash' && (
                  <UnsplashTab
                     onImageInsertion={onImageInsertion}
                     defaultImages={defaultUnsplashImages}
                  />
               )}
            </div>
         </div>
      </div>
   );
};

export default InsertImageModal;
