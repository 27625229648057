import * as React from 'react';

import IconSlider2 from '@icons/nova-line/03-Settings/slider-2.svg';
import { Course, CourseMode } from '@models/Course';

import Constants from '../../../Constants';
import Link from '@components/Common/Link';

interface CourseCanDoStatementsProps {
   course: Course<CourseMode.edit>;
}

const CourseCanDoStatements: React.FC<CourseCanDoStatementsProps> = ({ course }) => {
   const {
      routes: {
         courses: { canDoStatements },
      },
   } = Constants;

   return (
      <div className='card course-card'>
         <div className='card-title full-width'>
            <div className='title'>Course Can-Do Statements </div>
         </div>
         <div className='content-form'>
            <div className='row'>
               <div className='col-xs-9'>
                  <p className='course-option-description'>
                     Can-Do Statements set goals and track a learner&apos;s progress as they gain
                     proficiency in each communication mode (Interpersonal, Interpretive, and
                     Presentational).
                  </p>
               </div>
               <div className='col-xs-3'>
                  <div className='flex-end margin-top-s'>
                     <Link
                        className='btn'
                        to={canDoStatements.replace(':courseId', course.id.toString())}
                        data-test='btn-manage-can-do-statements'
                     >
                        <IconSlider2 aria-hidden />
                        Manage Statements
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(CourseCanDoStatements);
