// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import { faker } from '@faker-js/faker';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import { getQueryParameterAsNumber, getQueryParameterByName } from '@helpers/QueryParameter';
import IconContentFilter from '@icons/nova-line/18-Content/content-filter.svg';
import IconHide from '@icons/nova-solid/01-Content-Edition/hide.svg';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import { IdName, Maybe, MessageResponse } from '@models/Core';
import { CoursePermissions, CourseProfile, CourseSection } from '@models/Course';
import { ModuleItemDatePair } from '@models/Extensions';
import IUserProfile from '@models/IUserProfile';
import CourseEnrollmentService from '@services/CourseEnrollmentService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import { ModuleProfile } from '@models/Course/Module';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import Switch from '@components/Common/Switch';
import Table, { Column } from '@components/Common/Table';
import Avatar from '@components/Core/Avatar';
import Droplist, { IMenuItem } from '@components/Core/Droplist';
import ModalDialog from '@components/Core/ModalDialog';
import { Tab, Tabs } from '@components/Core/Tabs';
import DocumentTitle from '@components/DocumentTitle';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import StudentProficiencySummary from '@components/ProficiencyTracking/StudentProficiencySummary';
import CourseStrings from './CourseStrings';
import CourseworkTable from './CourseworkTable';
import { GradebookSettings } from './Gradebook/Gradebook';
import ExtensionModal from './Submissions/ExtensionModal';
import VocabSessionsCompletedChart from './VocabStats/VocabSessionsCompletedChart';
import { VocabStat } from './VocabStats/VocabStats';

export interface UserCoursework {
   allDueDates: readonly ModuleItemDatePair[];
   contentId: number;
   endDate: Date;
   hasFeedback: boolean;
   isLate: boolean;
   isUnlisted: boolean;
   itemName: string;
   itemType: ContentType;
   lastUpdated: Date;
   moduleId: number;
   moduleIndex: number;
   moduleItemId: number;
   moduleItemIndex: number;
   pointsPossible: number;
   progressScore: number;
   score: number;
   startDate: Date;
   submissionId: Maybe<number>;
}

interface DailyVocabSetStat {
   learn: number;
   moduleItemId?: number;
   name?: string;
   review: number;
   speedReview: number;
   total: number;
   vocabSetId?: number;
}

interface GetCourseEnrollmentResponse {
   course: CourseProfile;
   coursework: readonly UserCoursework[];
   disableVocabSetTimer: boolean;
   excludeFromCalc: boolean;
   extendedTimeMultiplier: number;
   grade: number;
   gradebookSettings: GradebookSettings;
   modules: readonly ModuleProfile[];
   permissions: CoursePermissions;
   school: { id: number; name: string };
   enrollmentSections: readonly IdName[];
   courseSections: readonly CourseSection[];
   user: IUserProfile; // FIXME: Shouldn't need to send back the entire profile with Intercom hash
   vocabStats: readonly VocabStat[];
}

const EnrollmentOverview: React.FC = () => {
   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const location = useLocation();

   const [permissions, setPermissions] = React.useState<Maybe<CoursePermissions>>(null);
   const [course, setCourse] = React.useState<Maybe<CourseProfile>>(null);
   const [coursework, setCoursework] = React.useState<readonly UserCoursework[]>([]);
   const [dayStats, setDayStats] = React.useState<
      Maybe<{
         day: Date;
         data: readonly DailyVocabSetStat[];
      }>
   >(null);
   const [defaultTab, setDefaultTab] = React.useState<string>('');
   const [disableVocabSetTimer, setDisableVocabSetTimer] = React.useState<boolean>(false);
   const [excludeFromCalc, setExcludeFromCalc] = React.useState<boolean>(false);
   const [extendedTimeMultiplier, setExtendedTimeMultiplier] =
      React.useState<Maybe<number | string>>(null);
   const [extensionModalOpen, setExtensionModalOpen] = React.useState<boolean>(false);
   const [filteredAssignmentIds, setFilteredAssignmentIds] = React.useState<readonly number[]>([]);
   const [visibleModuleIds, setVisibleModuleIds] = React.useState<readonly number[]>([]);
   const [grade, setGrade] = React.useState<Maybe<number>>(null);
   const [gradebookSettings, setGradebookSettings] = React.useState<Maybe<GradebookSettings>>(null);
   const [isDisenrollModalOpen, setIsDisenrollModalOpen] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = React.useState<boolean>(false);
   const [isSavingExtension, setIsSavingExtension] = React.useState<boolean>(false);
   const [newPassword, setNewPassword] = React.useState<string>('');
   const [passwordLengthError, setPasswordLengthError] = React.useState<boolean>(false);
   const [school, setSchool] = React.useState<Maybe<{ id: number; name: string }>>(null);
   const [searchQuery, setSearchQuery] = React.useState<string>('');
   const [enrollmentSectionId, setEnrollmentSectionId] =
      React.useState<Maybe<number | string>>(null);
   const [modules, setModules] = React.useState<readonly ModuleProfile[]>([]);
   const [sections, setSections] = React.useState<readonly CourseSection[]>([]);
   const [selectedAssignmentIds, setSelectedAssignmentIds] = React.useState<readonly number[]>([]);
   const [showExtendedTimeInput, setShowExtendedTimeInput] = React.useState<boolean>(false);
   // TODO: add the ability to show/hide password
   const [showPassword, setShowPassword] = React.useState<boolean>(false);
   const [user, setUser] = React.useState<Maybe<IUserProfile>>(null);
   const [vocabStats, setVocabStats] = React.useState<readonly VocabStat[]>([]);
   const [isFeatureEnabled, setIsFeatureEnabled] = React.useState<boolean>(false);

   React.useEffect(() => {
      UserService.checkFeature('proficiency_tracking').then((r) => {
         setIsFeatureEnabled(r);
      });
   }, []);

   const {
      minPasswordLength,
      routes: {
         courses: { dashboard, root: courseRoot, roster },
         enrollments,
         vocabSets: { overview: vocabSetOverview },
      },
   } = Constants;

   const { enrollmentId } = useParams<{ enrollmentId: string }>();
   const navigate = useNavigate();

   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   React.useEffect(() => {
      if (enrollmentId !== null) {
         fetchCoursework();
      }
   }, [enrollmentId]);

   React.useEffect(() => {
      if (!isFetching) {
         calculateFilteredAssignmentIds();
      }
   }, [isFetching, coursework, searchQuery, visibleModuleIds]);

   const fetchCoursework = (): void => {
      const ref = getQueryParameterByName(location, 'ref');
      const refOptions = snakeCaseKeys({
         vocabStats: 'vocab-stats',
         proficiency: 'proficiency-tracking',
      });
      const refTexts = snakeCaseKeys({
         vocabStats: 'Vocab Stats',
         roster: 'Course Roster',
         gradebook: 'Gradebook',
      });
      const moduleId = getQueryParameterAsNumber(location, 'moduleId', null);
      setIsFetching(true);
      HttpService.getWithAuthToken<GetCourseEnrollmentResponse>(
         `/api/enrollments/${enrollmentId}?time_zone=${encodeURIComponent(timeZone)}`,
      ).then(async (response) => {
         const { data } = response;
         const allModuleIds = data.modules.map((i) => i.id);
         const defaultRef = {
            link: roster.replace('courseId', data.course.id.toString()),
            text: refTexts.roster,
         };
         const patchedUser = (await UserService.checkFeature('fake_names'))
            ? {
                 ...data.user,
                 firstName: faker.name.firstName(),
                 lastName: faker.name.lastName(),
                 email: faker.internet.email(),
              }
            : data.user;
         setCourse(data.course);
         setCoursework(data.coursework);
         setFilteredAssignmentIds(data.coursework.map((i) => i.moduleItemId));
         setDefaultTab(refOptions[ref] || 'coursework');
         setDisableVocabSetTimer(data.disableVocabSetTimer);
         setExcludeFromCalc(data.excludeFromCalc);
         setExtendedTimeMultiplier(data.extendedTimeMultiplier);
         setPermissions({
            ...data.permissions,
            canModifyRoster:
               data.permissions.canModifyRoster && !data.course.archived && !data.course.demo,
         });
         setGrade(data.grade);
         setGradebookSettings(data.gradebookSettings);
         setSchool(data.school);

         // There should never be multiple sections for a student
         const section = _.first(data.enrollmentSections);

         setEnrollmentSectionId(section?.id);
         setSections(data.courseSections);
         setModules(data.modules);
         setVisibleModuleIds(
            moduleId && allModuleIds.includes(moduleId) ? [moduleId] : allModuleIds,
         );
         setUser(patchedUser);
         setShowExtendedTimeInput(data.extendedTimeMultiplier > 1.0);
         setVocabStats(data.vocabStats);
         setIsFetching(false);
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: dashboard.replace(':courseId', data.course.id.toString()),
                  text: data.course.name,
                  contextInfo: { courseId: data.course.id },
               },
               refTexts?.[ref]
                  ? {
                       link: `${courseRoot}/${data.course.id}/${ref}`,
                       text: refTexts[ref],
                    }
                  : defaultRef,
               {
                  link: enrollments.byId.replace(':enrollmentId', enrollmentId),
                  text: `${patchedUser.firstName} ${patchedUser.lastName}`,
               },
            ],
            next: null,
            prev: null,
         });
      });
   };

   const calculateFilteredAssignmentIds = (): void => {
      const filterSearchQuery = (i: UserCoursework): boolean =>
         !searchQuery || i.itemName.toLowerCase().includes(searchQuery.toLowerCase());

      const filterModuleId = (i: UserCoursework): boolean => visibleModuleIds.includes(i.moduleId);

      const updatedFilteredAssignmentIds = coursework
         .filter((i) => filterSearchQuery(i) && filterModuleId(i))
         .map(({ moduleItemId }) => moduleItemId);

      setFilteredAssignmentIds(updatedFilteredAssignmentIds);
   };

   const disenrollUser = async (): Promise<void> => {
      setIsDisenrollModalOpen(false);
      setIsLoading(true);
      return CourseEnrollmentService.deleteEnrollment(course.id, Number(enrollmentId)).then(() => {
         closeDisenrollModal();
         navigate(roster.replace(':courseId', course.id.toString()));
      });
   };

   const confirmDisenrollUser = (): void => setIsDisenrollModalOpen(true);

   const closeDisenrollModal = (): void => setIsDisenrollModalOpen(false);

   const openPasswordResetModal = (): void => setIsPasswordResetModalOpen(true);

   const closePasswordResetModal = (): void => {
      setIsPasswordResetModalOpen(false);
      setNewPassword('');
   };

   const handlePasswordResetChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setNewPassword(value);
   };

   const handleExtendedTimeMultiplierBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      const updatedExtendedTimeMultiplier = Math.max(+event.target.value, +event.target.min);
      setExtendedTimeMultiplier(updatedExtendedTimeMultiplier);
      setShowExtendedTimeInput(updatedExtendedTimeMultiplier > 1.0);
      const url = `/api/enrollments/${enrollmentId}`;
      HttpService.patchWithAuthToken<MessageResponse>(
         url,
         snakeCaseKeys({
            extendedTimeMultiplier: updatedExtendedTimeMultiplier,
         }),
      );
   };

   const toggleDemoStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setExcludeFromCalc(checked);
      const url = `/api/enrollments/${enrollmentId}`;
      HttpService.patchWithAuthToken<MessageResponse>(
         url,
         snakeCaseKeys({ excludeFromCalc: checked }),
      );
   };

   const toggleVocabSetTimer = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setDisableVocabSetTimer(checked);
      const url = `/api/users/${user.id}`;
      HttpService.patchWithAuthToken<MessageResponse>(
         url,
         snakeCaseKeys({ disableVocabSetTimer: checked }),
      );
   };

   const toggleExtendedTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      const updatedExtendedTimeMultiplier = checked ? 1.5 : 1.0;
      setExtendedTimeMultiplier(updatedExtendedTimeMultiplier);
      setShowExtendedTimeInput(updatedExtendedTimeMultiplier > 1.0);
      const url = `/api/enrollments/${enrollmentId}`;
      HttpService.patchWithAuthToken<MessageResponse>(
         url,
         snakeCaseKeys({
            extendedTimeMultiplier: updatedExtendedTimeMultiplier,
         }),
      );
   };

   // TODO:
   const getSectionName = (): string =>
      enrollmentSectionId ? sections.find(({ id }) => id === enrollmentSectionId).name : 'None';

   const handleExtendedTimeMultiplierChange = (
      event: React.ChangeEvent<HTMLInputElement>,
   ): void => {
      const { value } = event.target;
      setExtendedTimeMultiplier(Number(value));
   };

   const renderStudentProfile = (): React.ReactNode => {
      if (!(course && user)) {
         return null;
      }
      const { archived, demo } = course;
      const { email, firstName, lastName, profileImageUrl, id: userId } = user;

      const disabledTooltipText =
         !permissions.canModifyRoster && CourseStrings.getDisabledRosterTooltip(archived, demo);

      return (
         <div className='col-xs-12 col-md-3'>
            <div className='card roster-profile margin-bottom-m-md'>
               <div className='user-details'>
                  <Avatar
                     firstName={firstName}
                     lastName={lastName}
                     src={profileImageUrl}
                     hashValue={userId}
                     size='xlarge'
                  />
                  <div className='title medium margin-top-s center-text'>{`${firstName} ${lastName}`}</div>
                  <div className='gray-text margin-top-s'>{school.name}</div>
                  <div className='margin-top-s'>
                     <Link external to={`mailto:${email}`}>
                        {email}
                     </Link>
                  </div>
                  {!!sections.length && (
                     <Droplist pullRight items={sectionDroplistItemFactory()}>
                        <div className='black-text margin-top-s'>
                           Section: <span className='section'>{getSectionName()}</span>
                        </div>
                     </Droplist>
                  )}
               </div>
               <div className='card-footer'>
                  <div className='enrollment-options'>
                     <Switch
                        checked={excludeFromCalc}
                        disabled={!permissions.canModifyRoster}
                        onChange={toggleDemoStatus}
                        tooltip='Excludes student from class average calculations'
                     >
                        Demo Student
                     </Switch>
                     <Switch
                        checked={disableVocabSetTimer}
                        disabled={!permissions.canModifyRoster}
                        onChange={toggleVocabSetTimer}
                     >
                        Disable Timer on Vocab Sets
                     </Switch>
                     <Switch
                        checked={Number(extendedTimeMultiplier) > 1.0 || showExtendedTimeInput}
                        disabled={
                           !permissions.canModifyRoster || !permissions.canExtendTimeForStudents
                        }
                        onChange={toggleExtendedTime}
                        tooltip={
                           !permissions.canExtendTimeForStudents
                              ? 'The ability to extend time for students has been disabled for this course.'
                              : null
                        }
                     >
                        Allow Extended Time on Timed Assignments
                     </Switch>
                     {showExtendedTimeInput && (
                        <div className='extended-time-input'>
                           <input
                              value={extendedTimeMultiplier}
                              className={classnames({
                                 disabled: !permissions.canModifyRoster,
                              })}
                              disabled={!permissions.canModifyRoster}
                              onBlur={handleExtendedTimeMultiplierBlur}
                              onChange={handleExtendedTimeMultiplierChange}
                              type='text'
                              min='1.0'
                           />
                           <span className='additional'>times additional</span>
                           <InfoTooltip>
                              {`On a 60 minute assignment, your student will receive ${
                                 60 * Number(extendedTimeMultiplier)
                              } minutes`}
                           </InfoTooltip>
                        </div>
                     )}
                  </div>
                  <Button
                     line
                     fullWidth
                     onClick={openPasswordResetModal}
                     disabled={!permissions.canModifyRoster}
                  >
                     Reset Password
                  </Button>
                  <Tippy content={disabledTooltipText} disabled={!disabledTooltipText}>
                     <div>
                        <Button
                           color='dark-red'
                           fullWidth
                           disabled={!permissions.canModifyRoster}
                           onClick={confirmDisenrollUser}
                        >
                           Remove from Course
                        </Button>
                     </div>
                  </Tippy>
               </div>
            </div>
         </div>
      );
   };

   const handleModulesFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const moduleId = Number(event.target.id);
      setVisibleModuleIds((prevVisibleModuleIds) =>
         prevVisibleModuleIds.includes(moduleId)
            ? prevVisibleModuleIds.filter((i) => i !== moduleId)
            : [...prevVisibleModuleIds, moduleId],
      );
   };

   const renderFilterModulesItems = (): readonly React.ReactElement[] =>
      _.orderBy(modules, ['index']).map(({ id, name }) => (
         <div className='show-module-entry' key={id}>
            <input
               checked={visibleModuleIds.includes(id)}
               className='ignore-click'
               id={id.toString()}
               onChange={handleModulesFilterChange}
               type='checkbox'
            />
            <label className='ignore-click' htmlFor={id.toString()}>
               {name}
            </label>
         </div>
      ));

   const getGradeClass = (): string => {
      let color = '';
      if (gradebookSettings) {
         if (grade > gradebookSettings.green.min) {
            color = 'green';
         } else if (grade > gradebookSettings.yellow.min) {
            color = 'yellow';
         } else {
            color = 'red';
         }
      }
      return classnames('overall-grade', 'enrollment-overview', color);
   };

   const changeSections = (updatedSectionId: number): void => {
      if (updatedSectionId !== enrollmentSectionId) {
         CourseEnrollmentService.assignSections(
            course.id,
            [Number(enrollmentId)],
            [],
            [updatedSectionId],
         ).then(() => {
            setEnrollmentSectionId(updatedSectionId);
         });
      }
   };

   const sectionDroplistItemFactory = (): readonly IMenuItem[] =>
      [{ id: null, name: 'None' }, ...sections].map(({ name, id }) => ({
         text: name,
         onClick: () => changeSections(isInteger(id) ? Number(id) : null),
      }));

   const handleBarClick = ({ day }): void => {
      const isoDay = day.toISOString().substr(0, 10);
      const url = `/api/courses/${
         course.id
      }/vocab_stats/${isoDay}/enrollments/${enrollmentId}?time_zone=${encodeURIComponent(
         timeZone,
      )}`;
      HttpService.getWithAuthToken<{
         stats: readonly {
            enrollmentId: number;
            firstName: string;
            lastName: string;
            learn?: number;
            review?: number;
            speedReview?: number;
         }[];
      }>(url).then((response) => {
         const { stats } = response.data;
         setDayStats({
            day,
            data: stats
               .map((i) => ({
                  learn: 0,
                  review: 0,
                  speedReview: 0,
                  ...i,
               }))
               .map((i) => ({
                  ...i,
                  total: i.learn + i.review + i.speedReview,
               })),
         });
      });
   };

   const handleResetPassword = (): void => {
      if (newPassword.length < minPasswordLength) {
         setPasswordLengthError(true);
      } else {
         setIsLoading(true);
         HttpService.postWithAuthToken<MessageResponse>(
            `/api/users/${user.id}/instructor_reset`,
            snakeCaseKeys({ password: newPassword }),
         ).then(() => {
            setIsLoading(false);
            setNewPassword('');
            setPasswordLengthError(false);
            closePasswordResetModal();
         });
      }
   };

   const openExtensionModal = (): void => {
      setExtensionModalOpen(true);
   };

   const closeExtensionModal = (): void => {
      setExtensionModalOpen(false);
   };

   const extendAssignment = (endDate: Date): void => {
      setIsSavingExtension(true);
      const moduleItemIds = selectedAssignmentIds.filter((i) => filteredAssignmentIds.includes(i));
      const url = `/api/courses/${course.id}/users/${user.id}/extensions/bulk_create`;
      HttpService.postWithAuthToken<{
         msg: string;
         coursework: readonly UserCoursework[];
      }>(url, snakeCaseKeys({ moduleItemIds, endDate })).then((response) => {
         const { coursework: updatedCoursework } = response.data;
         if (updatedCoursework) {
            setIsSavingExtension(false);
            setCoursework(updatedCoursework);
         }
      });
   };

   const toggleShowPassword = (): void => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
   };

   const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value);
   };

   if (isFetching || !user) {
      return <Loader />;
   }

   const dayStatsColumns: readonly Column<DailyVocabSetStat>[] = [
      {
         id: 'name',
         header: 'Name',
         cell: (i) => (
            <Link to={vocabSetOverview.replace(':moduleItemId', i.moduleItemId.toString())}>
               {i.name}
            </Link>
         ),
         canSort: true,
      },
      {
         id: 'learn',
         header: 'Learn',
         cell: (i) => (i.learn ? i.learn : '-'),
         canSort: true,
      },
      {
         id: 'review',
         header: 'Review',
         cell: (i) => (i.review ? i.review : '-'),
         canSort: true,
      },
      {
         id: 'speedReview',
         header: 'Speed Review',
         cell: (i) => (i.speedReview ? i.speedReview : '-'),
         canSort: true,
      },
      { id: 'total', header: 'Total', cell: (i) => i.total, canSort: true },
   ];

   const numSelectedItems = selectedAssignmentIds.length;
   const selectedDates = coursework
      .filter((i) => selectedAssignmentIds.includes(i.moduleItemId))
      .map((i) => i.endDate);
   const extensionInitialDate = _.max(selectedDates);

   const filteredCoursework = coursework.filter((i) =>
      filteredAssignmentIds.includes(i.moduleItemId),
   );

   return (
      <>
         <DocumentTitle>{`${user.firstName} ${user.lastName}`}</DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row'>
               {renderStudentProfile()}
               <div className='col-xs-12 col-md-9'>
                  <div className='card no-padding enrollment-overview'>
                     <Tabs className='table-toggle' defaultTab={defaultTab}>
                        <Tab
                           id='coursework'
                           className='table-toggle-tab'
                           heading='Coursework'
                           key='coursework'
                           data-test='coursework-tab'
                        >
                           <div className='card-title has-button'>
                              <div className='flex items-center flex-grow-1'>
                                 <div className='title'>Coursework</div>
                                 <input
                                    name='search'
                                    type='search'
                                    value={searchQuery}
                                    onChange={handleSearchQueryChange}
                                 />
                                 <div className='gradebook-filters'>
                                    <Droplist items={renderFilterModulesItems()}>
                                       <Button line icon={<IconContentFilter aria-hidden />}>
                                          Filter Modules
                                       </Button>
                                    </Droplist>
                                 </div>
                              </div>
                              <div className='flex align-items-right'>
                                 <div className='margin-right-m'>
                                    {!!numSelectedItems && (
                                       <Button
                                          color='purple'
                                          className='grant-extensions-btn'
                                          onClick={openExtensionModal}
                                          loading={isSavingExtension}
                                          disabled={!permissions.canGrantExtension}
                                          tooltip={
                                             !permissions.canGrantExtension
                                                ? 'The ability to grant an extension for students has been disabled for this course.'
                                                : null
                                          }
                                       >
                                          Grant Extension
                                       </Button>
                                    )}
                                 </div>
                                 <div className='overall-grade-wrapper'>
                                    <Tippy content='Overall grade includes all assigned and graded content'>
                                       <span className={getGradeClass()}>
                                          {grade !== null ? `${grade.toFixed(1)}%` : '-'}
                                       </span>
                                    </Tippy>
                                 </div>
                              </div>
                           </div>
                           <CourseworkTable
                              canGrade={permissions.canGrade}
                              coursework={filteredCoursework}
                              requestor={AccountType.instructor}
                              selectedIds={selectedAssignmentIds}
                              setSelectedIds={setSelectedAssignmentIds}
                           />
                        </Tab>
                        <Tab
                           id='vocab-stats'
                           className='table-toggle-tab'
                           heading='Vocab Stats'
                           key='vocab-stats'
                           data-test='vocab-stats-tab'
                        >
                           <div className='card-title'>
                              <div className='title'>Completed Vocab Sessions</div>
                           </div>
                           <div className='enrollment-vocab-stats-wrapper'>
                              <VocabSessionsCompletedChart
                                 onBarClick={handleBarClick}
                                 data={vocabStats}
                              />
                           </div>
                        </Tab>
                        {isFeatureEnabled && (
                           <Tab
                              id='proficiency-tracking'
                              className='table-toggle-tab'
                              heading='Proficiency'
                              key='proficiency-tracking'
                              data-test='proficiency-tracking-tab'
                           >
                              <StudentProficiencySummary courseId={course.id} userId={user.id} />
                           </Tab>
                        )}
                     </Tabs>
                  </div>
               </div>
            </div>
         </div>
         {isDisenrollModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Disenroll User'
               onClose={closeDisenrollModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  {
                     text: 'Disenroll',
                     onClick: disenrollUser,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closeDisenrollModal },
               ]}
            >
               <p>
                  Are you sure that you want to disenroll {user.firstName} {user.lastName} from{' '}
                  {course.name}?
               </p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
         {isPasswordResetModalOpen && (
            <ModalDialog
               appearance={Appearance.primary}
               heading='Reset Student Password'
               onClose={closePasswordResetModal}
               animations={{ enter: 'animated bounceInDown' }}
               bodyClassName='content-row no-center'
               footerClassName='card-footer no-margin-top'
               actions={[
                  {
                     text: 'Reset',
                     onClick: handleResetPassword,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closePasswordResetModal },
               ]}
            >
               <form autoComplete='off'>
                  <label className='field-title'>New Temporary Password</label>
                  {/* We do not want the browser offering to save this password EVER */}
                  <input
                     autoFocus
                     type='text'
                     value={newPassword}
                     name='newPassword'
                     autoComplete='off'
                     style={{
                        fontFamily: showPassword ? 'inherit' : 'password',
                     }}
                     onChange={handlePasswordResetChange}
                  />
                  <Button
                     subtle
                     onClick={toggleShowPassword}
                     className='margin-top-s'
                     icon={<IconHide aria-hidden />}
                  >
                     {`${showPassword ? 'Hide' : 'Show'} password`}
                  </Button>
                  <p>
                     For security purposes, users will be prompted to set a new password after
                     logging in with this temporary password.
                  </p>
                  {passwordLengthError && (
                     <p className='error'>Must be at least {minPasswordLength} characters</p>
                  )}
               </form>
            </ModalDialog>
         )}
         {dayStats && (
            <ModalDialog
               bodyClassName='daily-stats'
               heading={`Daily Stats: ${moment(dayStats.day).format('dddd, MMMM Do YYYY')}`}
               onClose={() => setDayStats(null)}
            >
               {/* By student or by vocab set */}
               <Table<DailyVocabSetStat>
                  className='fixed_headers'
                  columns={dayStatsColumns}
                  defaultSortBy={[{ id: 'total', desc: true }]}
                  rowKey='vocabSetId'
                  rows={dayStats.data}
               />
               {/* {renderDayStatsFooter()} */}
            </ModalDialog>
         )}
         {extensionModalOpen && (
            <ExtensionModal
               heading={pluralize('Extend Assignment', numSelectedItems)}
               endDate={extensionInitialDate}
               closeExtensionModal={closeExtensionModal}
               extendAssignment={extendAssignment}
            />
         )}
      </>
   );
};

export default EnrollmentOverview;
