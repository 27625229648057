import * as React from 'react';

import { ProgressBar } from '@components/Core/ProgressBar';
import TransferOwnershipButton from '@components/TransferOwnershipButton';
import IconCalendarAdd from '@icons/nova-line/05-Time/calendar-add.svg';
import IconDataDownload8 from '@icons/nova-line/23-Data-Transfer/data-download-8.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import AccountType from '@models/AccountType';
import Content from '@models/Content';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import VocabSessionActions from './VocabSessionActions';

interface VocabSetInfoProps {
   canEdit: boolean;
   isOwner: boolean;
   learnedCount: number;
   moduleItemId: Maybe<number>;
   progress: number;
   reviewCount: number;
   settings: Content;
   termCount: number;
   onOwnershipTransfer(newCreatorId: number): void;
   openAddToCourseModal(): void;
   onDownloadClick(event: React.MouseEvent<HTMLDivElement>): void;
}

const VocabSetInfo: React.FC<VocabSetInfoProps> = ({
   canEdit,
   isOwner,
   learnedCount,
   progress,
   reviewCount,
   settings: { description, name, imageUrl, id: vocabSetId, language },
   termCount,
   moduleItemId,
   onOwnershipTransfer,
   openAddToCourseModal,
   onDownloadClick,
}) => {
   const {
      routes: {
         content: { editVocabSet },
      },
   } = Constants;

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const isInstructor = userProfile?.accountType === AccountType.instructor;

   const navigate = useNavigate();

   const editSet = (): void => {
      let queryParams = '';
      if (moduleItemId) {
         queryParams = `?moduleItemId=${moduleItemId}`;
      }
      if (vocabSetId) {
         navigate(editVocabSet.replace(':vocabSetId', vocabSetId?.toString()).concat(queryParams));
      }
   };

   return (
      <div className='card no-padding margin-bottom-m'>
         <div className='card-title has-button'>
            <div className='content-title-wrapper'>
               <div className='title'>Vocabulary Set</div>
               <div className='icon-action-wrap'>
                  {canEdit && (
                     <Tippy content='Edit'>
                        <div className='icon-action' onClick={editSet}>
                           <IconHighlight />
                        </div>
                     </Tippy>
                  )}
                  <Tippy content='Download CSV'>
                     <div className='icon-action' onClick={onDownloadClick}>
                        <IconDataDownload8 />
                     </div>
                  </Tippy>
                  <TransferOwnershipButton
                     contentId={Number(vocabSetId)}
                     isContentOwner={isOwner}
                     onOwnershipTransfer={onOwnershipTransfer}
                  />
                  {moduleItemId === null && isInstructor && (
                     <Tippy content='Add to Course Module'>
                        <div className='icon-action' onClick={openAddToCourseModal}>
                           <IconCalendarAdd />
                        </div>
                     </Tippy>
                  )}
               </div>
            </div>
            <div className='right'>
               <VocabSessionActions
                  language={language}
                  learnedCount={learnedCount}
                  reviewCount={reviewCount}
                  termCount={termCount}
                  vocabSetId={vocabSetId}
               />
            </div>
         </div>
         <div className='set-progress'>
            <div className='row'>
               <div className='col-xs-12'>
                  <ProgressBar percentage={progress} />
               </div>
            </div>
         </div>
         <div className='set-description'>
            <div className='row'>
               <div className='col-xs-12 col-sm-3'>
                  <div className='set-description-image'>
                     <img alt={name} src={imageUrl || 'https://picsum.photos/400/300/?random'} />
                  </div>
               </div>
               <div className='col-xs-12 col-sm-9'>
                  <h1>{name}</h1>
                  <p>{description}</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default VocabSetInfo;
