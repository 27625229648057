import { OrganizationCourseLevel, OrganizationLmsInfo } from '@models/Organization';

import { Maybe } from '@models/Core';
import LMSName from '@models/LMSName';
import AxiosService from './AxiosService';

export type PartialOrganizationLmsInfo = Omit<Partial<OrganizationLmsInfo>, 'lms'> & {
   lms?: Maybe<LMSName>;
};

type OrganizationCourseLevelsResponse = {
   courseLevels: OrganizationCourseLevel[];
};

const getCourseLevels = (): Promise<OrganizationCourseLevel[]> =>
   AxiosService.get<OrganizationCourseLevelsResponse>('/api/organizations/course_levels').then(
      (response) => response.data.courseLevels,
   );

const getLmsConnection = (id: number): Promise<OrganizationLmsInfo> =>
   AxiosService.get<OrganizationLmsInfo>(`/api/admin/organization/${id}/lms_info`).then(
      (r) => r.data,
   );

const upsertLmsConnection = (
   id: number,
   data: Partial<PartialOrganizationLmsInfo>,
): Promise<void> =>
   AxiosService.post<OrganizationLmsInfo>(`/api/admin/organization/${id}/lms_info`, data).then();

export default {
   getCourseLevels,
   getLmsConnection,
   upsertLmsConnection,
};
