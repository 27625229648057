import * as React from 'react';

import IconMenuLevel from '@icons/general/icon-menu-level.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import EmptyState from '@components/Core/EmptyState';
import { getPromptName } from '@components/Activity/Utils';
import { ModuleItemProfile, PromptProfile, QuestionProfile } from './models';

interface PromptSelectorProps {
   currentModuleItemId: number;
   currentPromptId: number;
   moduleItem: Maybe<ModuleItemProfile>;
   selectedModuleItemId: Maybe<number>;
   selectedPromptId: Maybe<number>;
   selectPrompt(promptId: number): void;
}

const PromptSelector: React.FC<PromptSelectorProps> = ({
   currentModuleItemId,
   currentPromptId,
   moduleItem,
   selectedModuleItemId,
   selectedPromptId,
   selectPrompt,
}) => {
   if (!moduleItem) {
      return <EmptyState description='Questions are loading...' />;
   }

   const isSamePrompt = (promptId: number): boolean =>
      currentPromptId === promptId && currentModuleItemId === selectedModuleItemId;

   const handlePromptClick = (promptId: number): void => {
      if (!isSamePrompt(promptId)) {
         selectPrompt(promptId);
      }
   };

   const renderPrompts = (prompts: readonly PromptProfile[]): readonly React.ReactNode[] =>
      prompts.map(({ promptType, id: promptId }) => (
         <div
            className={classnames('prompt-wrapper', {
               current: isSamePrompt(promptId),
               active: promptId === selectedPromptId,
            })}
            key={promptId}
            onClick={() => handlePromptClick(promptId)}
         >
            <div className='nav-icon'>
               <IconMenuLevel />
            </div>
            <p className='prompt-name'>
               {getPromptName(promptType)}
               {isSamePrompt(promptId) && <span className='current-tag'>Current</span>}
            </p>
         </div>
      ));

   return (
      <div className='prompt-selector'>
         {(moduleItem.questions as readonly QuestionProfile[]).map(({ title, prompts, id }, i) => (
            <div className='question-wrapper' key={id}>
               <div className='question-title'>{title || `Question ${i + 1}`}</div>
               {renderPrompts(prompts as readonly PromptProfile[])}
            </div>
         ))}
      </div>
   );
};

export default PromptSelector;
