import * as React from 'react';

import IconSettings1 from '@icons/nova-line/03-Settings/settings-1.svg';
import Tippy from '@tippyjs/react';

import Droplist from '@components/Core/Droplist';

interface ItemOptions {
   title: string;
   children: React.ReactNode | readonly React.ReactNode[];
   tooltip?: string;
   dataTest?: string;
}

const ItemOptions: React.FC<ItemOptions> = ({ title, children, tooltip, dataTest }) => {
   if (!children) {
      return null;
   }

   const component = (
      <div className='dropdown-menu no-drag item-options-popup'>
         <div className='card-title'>
            <div className='title'>{title}</div>
         </div>
         <div className='popup-content'>{children}</div>
      </div>
   );

   if (tooltip) {
      return (
         <Droplist component={component}>
            <Tippy content={tooltip} delay={[400, 0]}>
               <div data-test={dataTest} className='icon-action'>
                  <IconSettings1 />
               </div>
            </Tippy>
         </Droplist>
      );
   }

   return (
      <Droplist component={component}>
         <div data-test={dataTest} className='icon-action'>
            <IconSettings1 />
         </div>
      </Droplist>
   );
};

export default React.memo(ItemOptions);
