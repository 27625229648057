import * as React from 'react';

import {
   isOpenTokVideoContent,
   isUploadedVideoContent,
   isVideoLibraryContent,
   isYouTubeVideoContent,
} from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import Droplist from '@components/Core/Droplist';
import VideoPlayer from '@components/Core/VideoPlayer';
import { formatSeconds } from '@helpers/FormatTime';
import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import {
   ActivityBuilderMode,
   ActivityItem,
   ActivityQuestion,
   PromptType,
   VideoContent as VideoContentType,
   VideoSource,
} from '@models/Activity';
import { ID } from '@models/Core';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import { defaultItem, getVideoUrl, isVideoLoaded, sourceOptions, useFetchVideo } from './helpers';
import InteractiveVideoBuilder from './InteractiveVideoBuilder';
import RecordVideoContent from './RecordVideoContent';
import SelectVideoLibraryContent from './SelectVideoLibraryContent';
import UploadVideoContent from './UploadVideoContent';
import VideoContentOptions from './VideoContentOptions';
import YouTubeURLInput from './YouTubeURLInput';

type VideoContentProps = {
   item: VideoContentType<ActivityBuilderMode>;
   isDraggingPrompt: boolean;
   question: ActivityQuestion<ActivityBuilderMode>;
   handleVideoContentUpdate(update: Partial<ActivityItem<ActivityBuilderMode>>): void;
   handleItemUpdate(
      itemKey: string,
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   handleQuestionUpdate(
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
   handleVideoPromptSecondsUpdate(
      videoID: ID,
      promptId: ID,
      promptTimeInSeconds: number,
      promptType: PromptType,
   ): void;
   removeItem(keyToDelete: string): void;
};

const VideoContent: React.FC<VideoContentProps> = ({
   isDraggingPrompt,
   item,
   question,
   handleItemUpdate,
   handleQuestionUpdate,
   handleVideoContentUpdate,
   handleVideoPromptSecondsUpdate,
   removeItem,
}) => {
   const videoRef = React.useRef<ReactPlayer>(null);

   const video = useFetchVideo(item);

   const handleSourceChange = (source: VideoSource): void => {
      handleVideoContentUpdate({ ...defaultItem, source });
   };

   React.useEffect(() => {
      if (!item.snippetEnd && videoRef.current) {
         handleVideoContentUpdate({ snippetEnd: videoRef.current.getDuration() });
      }
   }, []);

   const getCurrentTime = (): number | null =>
      videoRef.current ? Math.floor(videoRef.current?.getCurrentTime()) : null;

   const markStart = (): void => {
      const updatedSnippetStart = getCurrentTime();
      if (updatedSnippetStart !== null && updatedSnippetStart <= item.snippetEnd) {
         handleVideoContentUpdate({ snippetStart: updatedSnippetStart });
      }
   };

   const markEnd = (): void => {
      const updatedSnippetEnd = getCurrentTime();
      if (updatedSnippetEnd !== null && item.snippetStart <= updatedSnippetEnd) {
         handleVideoContentUpdate({ snippetEnd: updatedSnippetEnd });
      }
   };

   const renderSelectVideo = (): React.ReactElement | undefined => {
      if (isYouTubeVideoContent(item)) {
         return <YouTubeURLInput item={item} handleItemUpdate={handleVideoContentUpdate} />;
      } else if (isOpenTokVideoContent(item)) {
         return <RecordVideoContent item={item} handleItemUpdate={handleVideoContentUpdate} />;
      } else if (isUploadedVideoContent(item)) {
         return <UploadVideoContent item={item} handleItemUpdate={handleVideoContentUpdate} />;
      } else if (isVideoLibraryContent(item)) {
         return (
            <SelectVideoLibraryContent item={item} handleItemUpdate={handleVideoContentUpdate} />
         );
      }
   };

   const renderSnippetControls = () => (
      <div className='margin-top-s'>
         <div className='flex'>
            <div className='snippet margin-right-m'>
               <label>Start</label>
               <input
                  name='snippetStart'
                  disabled
                  type='text'
                  className='snippet-input'
                  onChange={markStart}
                  value={formatSeconds(item.snippetStart)}
               />
               <Button line onClick={markStart}>
                  Mark
               </Button>
            </div>
            <div className='snippet'>
               <label>End</label>
               <input
                  name='snippetEnd'
                  type='text'
                  disabled
                  className='snippet-input'
                  onChange={markEnd}
                  value={formatSeconds(item.snippetEnd)}
               />
               <Button line onClick={markEnd}>
                  Mark
               </Button>
            </div>
         </div>
         <p className='small'>Move the video timeline to the start point and click mark.</p>
      </div>
   );

   const videoLoaded = isVideoLoaded(item);
   const videoUrl = getVideoUrl(item, video);
   const useStandard = isOpenTokVideoContent(item);
   const posterUrl = isVideoLibraryContent(item) ? video?.imageUrl : '';

   return (
      <div className='activity-builder-row'>
         <div className='align-items-center'>
            <div className='flex-grow-1'>
               <div className='align-items-center'>
                  {videoLoaded ? (
                     <div className='flex-1 max-width-720 cursor-initial'>
                        {item.isInteractiveVideo ? (
                           <InteractiveVideoBuilder
                              removeItem={removeItem}
                              handleItemUpdate={handleItemUpdate}
                              interactiveVideoContent={item}
                              handleQuestionUpdate={handleQuestionUpdate}
                              handleVideoPromptSecondsUpdate={handleVideoPromptSecondsUpdate}
                              isDraggingPrompt={isDraggingPrompt}
                              question={question}
                              url={videoUrl}
                           />
                        ) : (
                           <VideoPlayer
                              ref={videoRef}
                              videoUrl={videoUrl}
                              posterUrl={posterUrl}
                              start={item.playSnippet ? item.snippetStart : undefined}
                              end={item.playSnippet ? item.snippetEnd : undefined}
                              videoContainerClassname={classNames('video-container', {
                                 standard: useStandard,
                              })}
                              nativeLanguage='en'
                              nativeSubtitles={
                                 item.enableNativeSubtitles
                                    ? video?.nativeLanguageCaptions // FIXME: Rename to Subtitles
                                    : undefined
                              }
                              showSubtitles={
                                 !!(video?.nativeLanguageCaptions && item.enableNativeSubtitles) ||
                                 !!(video?.targetLanguageCaptions && item.enableTargetSubtitles)
                              }
                              targetLanguage={video?.language}
                              targetSubtitles={
                                 item.enableTargetSubtitles
                                    ? video?.targetLanguageCaptions // FIXME: Rename to Subtitles
                                    : undefined
                              }
                              showOverlay={!!video}
                           />
                        )}
                        {item.playSnippet && renderSnippetControls()}
                     </div>
                  ) : (
                     <>
                        {renderSelectVideo()}
                        <Droplist
                           pullRight
                           className='margin-left-s'
                           items={sourceOptions
                              .filter((i) => i.source !== item.source)
                              .map((i) => ({ ...i, onClick: () => handleSourceChange(i.source) }))}
                        >
                           <Button line className='align-items-center'>
                              More Sources
                              <IconArrowDown className='icon-xxs icon-gray margin-left-xs no-padding' />
                           </Button>
                        </Droplist>
                     </>
                  )}
               </div>
            </div>
            <div className='flex-end margin-left-s'>
               <div className='weight-actions-wrapper'>
                  <div className='icon-action-wrap'>
                     <VideoContentOptions
                        videoRef={videoRef}
                        isVideoLoaded={videoLoaded}
                        videoLanguage={video?.language}
                        item={item}
                        onUpdate={handleVideoContentUpdate}
                     />
                     <Tippy content='Delete Item' delay={[500, 0]}>
                        <div
                           aria-label='Delete Item'
                           className='icon-action'
                           onClick={() => removeItem(item.key)}
                           onKeyDown={(e) => e.key === 'Enter' && removeItem(item.key)}
                           role='button'
                           tabIndex={0}
                        >
                           <IconBin />
                        </div>
                     </Tippy>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default VideoContent;
