// @ts-strict-ignore
import * as React from 'react';
import * as RecordRTC from 'recordrtc';

import IconImageClose from '@icons/general/icon-image-close.svg';
import IconVideoCall1 from '@icons/nova-solid/15-Video-Meeting/video-call-1.svg';
import IconFileVideoUpload from '@icons/nova-solid/95-Files-Video/file-video-upload.svg';
import { Maybe } from '@models/Core';

import Button from '@components/Common/Button';

interface GifRecorderProps {
   setImage(blob: Blob): void;
   closeModal(): void;
}

const GifRecorder: React.FC<GifRecorderProps> = ({ closeModal, setImage }) => {
   const [isRecording, setIsRecording] = React.useState<boolean>(false);
   const [imgSrc, setImgSrc] = React.useState<string>('');
   const [blob, setBlob] = React.useState<Maybe<Blob>>(null);
   const recorder = React.useRef(null);
   const fileInput = React.useRef(null);

   const captureCamera = (callback: (camera: MediaStream) => void): void => {
      navigator.mediaDevices
         .getUserMedia({ video: true })
         .then((camera) => {
            callback(camera);
         })
         .catch(() => {
            alert('Unable to capture your camera.');
         });
   };

   const startRecording = (): void => {
      captureCamera((camera) => {
         recorder.current = RecordRTC(camera, {
            type: 'gif',
            frameRate: 1,
            quality: 10,
            width: 300,
            hidden: 240,
            onGifRecordingStarted: () => {
               setIsRecording(true);
            },
            onGifPreview: (gifURL) => {
               setImgSrc(gifURL);
            },
         });
         recorder.current.startRecording();
         // release camera on stopRecording
         recorder.current.camera = camera;
      });
   };

   const stopRecordingCallback = (): void => {
      setIsRecording(false);
      setBlob(recorder.current.getBlob());
      setImgSrc(window.URL.createObjectURL(recorder.current.getBlob()));
      recorder.current.camera.stop();
      recorder.current.destroy();
      recorder.current = null;
   };

   const stopRecording = (): void => {
      recorder.current.stopRecording(stopRecordingCallback);
   };

   const reset = (): void => {
      setImgSrc('');
      setBlob(null);
   };

   const save = (): void => {
      setImage(blob);
      closeModal();
   };

   const upload = (): void => {
      if (fileInput.current) {
         fileInput.current.click();
      }
   };

   const handleFileChange = (event): void => {
      event.preventDefault();
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = (): void => {
         setBlob(file);
         setImgSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
   };

   return (
      <div>
         {imgSrc && (
            <div className='term-image-recording-wrapper'>
               <div className='term-image-recording'>
                  {blob && !isRecording && (
                     <div className='term-image-close' onClick={reset}>
                        <IconImageClose aria-label='Reset' />
                     </div>
                  )}
                  <img src={imgSrc} />
               </div>
            </div>
         )}
         {!isRecording && !blob && (
            <div className='image-options'>
               <Button
                  icon={<IconVideoCall1 className='icon-white' aria-hidden />}
                  onClick={startRecording}
               >
                  Record
               </Button>
               <Button
                  icon={<IconFileVideoUpload className='icon-white' aria-hidden />}
                  onClick={upload}
               >
                  Upload
               </Button>
               <input
                  type='file'
                  accept='image/gif'
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  ref={fileInput}
               />
            </div>
         )}
         {isRecording && (
            <Button color='red' onClick={stopRecording}>
               Recording
            </Button>
         )}
         {blob && !isRecording && <Button onClick={save}>Save Gif</Button>}
      </div>
   );
};

export default React.memo(GifRecorder);
