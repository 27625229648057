export enum MessageDirection {
   sent = 'sent',
   received = 'received',
}

export interface Message {
   person: string;
   direction: MessageDirection;
   message: string;
}
