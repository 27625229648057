import * as React from 'react';

import classnames from 'classnames';

import Constants from '../Constants';
import ContentEditable from './ContentEditable';

import { renderToString } from 'react-dom/server';

interface ArabicTextboxProps extends Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
   joinLeft?: boolean;
   joinRight?: boolean;
   innerRef?: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement) => void);
   onValueChange?(value: string): void;
}

const ArabicTextbox: React.FC<ArabicTextboxProps> = ({
   joinLeft,
   joinRight,
   className,
   value,
   onBlur,
   onFocus,
   onValueChange,
   ref,
   size = 1,
   ...props
}) => {
   const {
      keys: { enter },
   } = Constants;

   const [isFocused, setIsFocused] = React.useState<boolean>(false);

   const handleBlur = (event: React.FocusEvent<HTMLDivElement>): void => {
      setIsFocused(false);
      onBlur?.(event);
   };

   const handleChange = (event: React.ChangeEvent<HTMLDivElement>): void => {
      onValueChange?.(event.target.innerText);
   };

   const handleFocus = (event: React.FocusEvent<HTMLDivElement>): void => {
      setIsFocused(true);
      onFocus?.(event);
   };

   const handleKeyDown = (event: React.KeyboardEvent): void => {
      if (event.key === enter) {
         event.preventDefault();
      }
   };

   return (
      <>
         {joinRight && !isFocused && <>&zwj;</>}
         <ContentEditable
            className={classnames(className, 'arabic-textbox', {
               empty: value === '',
            })}
            style={{ minWidth: size * 8.5 }}
            lang='ar'
            html={renderToString(
               <>
                  {!isFocused && joinRight && <>&zwj;</>}
                  {value}
                  {!isFocused && joinLeft && <>&zwj;</>}
               </>,
            ).replace('<!-- -->', '')}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            {...props}
         />
         {joinLeft && !isFocused && <>&zwj;</>}
      </>
   );
};

export default ArabicTextbox;
