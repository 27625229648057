import * as _ from 'lodash';

import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { CourseSection, GradingCategory, ModuleProfile } from '@models/Course';

import Constants from '../../../Constants';
import {
   EnrollmentProfile,
   GradebookAssignmentProfile,
   GradebookProfile,
   GradebookSettings,
} from './Gradebook';

export type GradeColor = 'grey' | 'green' | 'yellow' | 'red';

export interface GradeData {
   endOfModule: boolean;
   grades: readonly (number | null)[];
   heading: string;
   headingUrl: Maybe<string>;
   id: number;
   pointsPossible: number;
   pointsPossibleByStudent?: readonly number[];
}

const headingUrl = (assignment: GradebookAssignmentProfile): Maybe<string> => {
   const {
      activities: { viewAllSubmissions },
      vocabSets: { overview: vocabOverview },
   } = Constants.routes;
   switch (assignment.itemType) {
      case ContentType.activity:
         return viewAllSubmissions
            .replace(':moduleItemId', assignment.moduleItemId.toString())
            .concat('?ref=gradebook');
      case ContentType.vocabSet:
         return vocabOverview
            .replace(':moduleItemId', assignment.moduleItemId.toString())
            .concat('?ref=gradebook');
      default:
         console.warn(`Unexpected item type: ${assignment.itemType}`);
         return undefined;
   }
};

const getGradeDataByAssignment = (
   roster: readonly EnrollmentProfile[],
   assignments: readonly GradebookAssignmentProfile[],
): readonly GradeData[] =>
   assignments.map((i) => ({
      endOfModule: i.endOfModule,
      grades: roster.map(({ userId }) => i.submissions[userId]?.score),
      heading: i.shortName ? i.shortName : i.name,
      headingUrl: headingUrl(i),
      id: i.moduleItemId,
      pointsPossible: i.pointsPossible,
   }));

const getGradeDataByModule = (
   roster: readonly EnrollmentProfile[],
   assignments: readonly GradebookAssignmentProfile[],
   modules: Record<number, ModuleProfile>,
   courseId: number,
): readonly GradeData[] => {
   const {
      routes: {
         courses: { viewModule },
      },
   } = Constants;

   return Object.values(modules)
      .filter((i) => i.showInGradebook)
      .map((mod) => {
         const url = viewModule
            .replace(':courseId', courseId.toString())
            .replace(':moduleId', mod.id.toString())
            .concat('?ref=gradebook');

         const moduleAssignments = assignments.filter((j) => j.moduleId === Number(mod.id));
         return getSummedAssignmentGrades(mod.id, mod.name, url, roster, moduleAssignments);
      });
};

const getGradeDataByGradingCategory = (
   roster: readonly EnrollmentProfile[],
   assignments: readonly GradebookAssignmentProfile[],
   gradingCategories: readonly GradingCategory[],
): readonly GradeData[] =>
   gradingCategories
      .filter((i) => i.show)
      .map((gradeCategory) => {
         const gradingCategoryAssignments = assignments.filter(
            (j) => j.gradingCategoryId === gradeCategory.id,
         );
         return getSummedAssignmentGrades(
            gradeCategory.id,
            gradeCategory.name,
            null,
            roster,
            gradingCategoryAssignments,
         );
      });

const getSummedAssignmentGrades = (
   id: number,
   name: string,
   url: Maybe<string>,
   roster: readonly EnrollmentProfile[],
   assignments: readonly GradebookAssignmentProfile[],
): GradeData => {
   let defaultPointsPossible = 0;
   const pointsPossibleByStudent = new Array(roster.length).fill(0);
   assignments.forEach(({ pointsPossible }) => {
      defaultPointsPossible += pointsPossible;
   });
   const summedGrades = roster.map(({ userId }, studentIndex) => {
      const studentGrades = assignments.map(({ pointsPossible, submissions }) => {
         const score = userId in submissions ? submissions[userId].score : null;
         if (score !== null) {
            pointsPossibleByStudent[studentIndex] += pointsPossible;
         }
         return score;
      });
      return studentGrades.every((j) => j === null) ? null : _.sum(studentGrades);
   });

   return {
      endOfModule: false,
      grades: summedGrades,
      heading: name,
      headingUrl: url,
      id,
      pointsPossible: defaultPointsPossible,
      pointsPossibleByStudent,
   };
};

const buildGradebookCSV = (
   format: string,
   roster: readonly GradebookProfile[],
   sections: readonly CourseSection[],
   gradeData: readonly GradeData[],
): readonly (readonly string[])[] => {
   const assignmentsExists = gradeData.length > 0;
   const rosterExists = roster.length > 0;

   const renderStudentRow = (userId: number): readonly string[] => {
      const studentIndex = roster.findIndex((i) => i.userId === userId);
      return gradeData.map((data) => {
         const grade = data.grades[studentIndex];
         return !grade ? '0' : grade.toString();
      });
   };

   const buildStandardCSV = (): readonly (readonly string[])[] => {
      const coursePoints = assignmentsExists
         ? _.sum(gradeData.map((i) => i.pointsPossible)).toString()
         : 'No Total Points Yet';

      const header = ['First Name', 'Last Name', 'Email'];
      const header2 = [coursePoints, '', ''];
      if (sections.length) {
         header.push('Section');
         header2.push('');
      }
      if (assignmentsExists) {
         header.push(...gradeData.map((i) => i.heading));
         header2.push(...gradeData.map((i) => i.pointsPossible.toString()));
      } else {
         header.push('No Assignments Yet');
         header2.push('No Assignments Yet');
      }

      const sectionMapping = (i: GradebookProfile): readonly string[] => [
         i.firstName,
         i.lastName,
         i.email,
         i.sections ? i.sections.map((x) => x.name).toString() : '',
         ...renderStudentRow(i.userId),
      ];

      const standardMapping = (i: GradebookProfile): readonly string[] => [
         i.firstName,
         i.lastName,
         i.email,
         ...renderStudentRow(i.userId),
      ];

      const studentRows = rosterExists
         ? roster.map(sections.length ? sectionMapping : standardMapping)
         : [['No Students Yet']];

      return [header, header2, ...studentRows];
   };

   const buildBlackboardCSV = (): readonly (readonly string[])[] => {
      let header = [
         'Last Name',
         'First Name',
         'Username',
         'Student ID',
         'Last Access',
         'Availability',
      ];
      header = header.concat(
         ...[
            assignmentsExists
               ? gradeData.map((i) => `${i.heading} [Total Pts: ${i.pointsPossible}]`)
               : 'No Assignments Yet',
         ],
      );

      let studentRows = [];
      if (rosterExists) {
         studentRows = roster.map((r) => {
            const username = r.email.substr(0, r.email.indexOf('@'));
            return [r.lastName, r.firstName, username, '', '', '', ...renderStudentRow(r.userId)];
         });
      } else {
         studentRows = [['No Students yet']];
      }

      return [header, ...studentRows];
   };

   const buildCanvasCSV = (): readonly (readonly string[])[] => {
      const header = ['Student', 'ID', 'SIS User ID', 'Login ID', 'Section'].concat(
         ...[assignmentsExists ? gradeData.map((i) => i.heading) : 'No Assignments Yet'],
      );
      const header2 = ['Points Possible', '', '', '', ''].concat(
         ...[
            assignmentsExists
               ? gradeData.map((i) => i.pointsPossible.toString())
               : 'No Assignments Yet',
         ],
      );
      const studentRows = rosterExists
         ? roster.map((r) => [
              `${r.lastName}, ${r.firstName}`, // Name
              '', // ID
              r.canvas?.sisUserId ?? '',
              r.canvas?.loginId ?? '',
              '',
              ...renderStudentRow(r.userId),
           ])
         : [['No Students Yet']];
      return [header, header2, ...studentRows];
   };

   const buildD2LCSV = (): readonly (readonly string[])[] => {
      const header = ['OrgDefinedId']
         .concat(
            ...[
               assignmentsExists
                  ? gradeData.map((i) => `${i.heading} Points Grade `)
                  : 'No Assignments Yet',
            ],
         )
         .concat(...['End-of-Line Indicator']);
      const studentRows = rosterExists
         ? roster.map((r) => [
              r.email.slice(0, r.email.indexOf('@')),
              ...renderStudentRow(r.userId),
              '#',
           ])
         : [['', 'No Students Yet', '#']];

      return [header, ...studentRows];
   };

   switch (format) {
      case 'Blackboard':
         return buildBlackboardCSV();
      case 'Canvas':
         return buildCanvasCSV();
      case 'D2L':
         return buildD2LCSV();
      case 'Standard':
      default:
         return buildStandardCSV();
   }
};

const getGradeColor = (grade: number, gbs: GradebookSettings): GradeColor => {
   if (grade === null) {
      return 'grey';
   } else if (grade >= gbs.green.min) {
      return 'green';
   } else if (grade >= gbs.yellow.min) {
      return 'yellow';
   } else {
      return 'red';
   }
};

export {
   buildGradebookCSV,
   getGradeDataByAssignment,
   getGradeDataByModule,
   getGradeDataByGradingCategory,
   getGradeColor,
};
