import * as React from 'react';

import { ActivityRubricItem, ActivityRubricScoringType } from '@models/Activity';
import classnames from 'classnames';

import Constants from '../../../Constants';
import { formatPoints } from './helpers';

interface PointsInputProps {
   displayOnly: boolean;
   scoringType: ActivityRubricScoringType;
   weight: string;
   updateRubricItem(update: Partial<ActivityRubricItem>): void;
}

const PointsInput: React.FC<PointsInputProps> = ({
   displayOnly,
   scoringType,
   weight,
   updateRubricItem,
}) => {
   const pointsInput = React.useRef<HTMLInputElement>(null);
   const [points, setPoints] = React.useState<string>('');
   const [originalPoints, setOriginalPoints] = React.useState<string>(
      formatPoints(weight, scoringType),
   );

   React.useEffect(() => {
      setPoints(formatPoints(weight, scoringType) ?? '');
   }, [scoringType]);

   React.useEffect(() => {
      setOriginalPoints(formatPoints(weight, scoringType));
   }, [scoringType, weight]);

   const getNumericWeight = (): number => {
      const parsedPts = parseFloat(points);
      if (isNaN(parsedPts) || scoringType === ActivityRubricScoringType.positive) {
         return parsedPts;
      } else {
         return points.includes('-') || points.includes('+') ? -1 * parsedPts : parsedPts;
      }
   };

   const pointsClass = (): string => {
      const parsedWeight = parseFloat(weight);
      const deduction =
         (scoringType === ActivityRubricScoringType.positive && parsedWeight <= 0) ||
         (scoringType === ActivityRubricScoringType.negative && parsedWeight > 0);
      const invalid = isNaN(getNumericWeight());
      return classnames({ deduction: deduction && !invalid, invalid });
   };

   const updatePoints = (): void => {
      const numericWeight = getNumericWeight();
      if (isNaN(numericWeight)) {
         setPoints(formatPoints(weight, scoringType));
         return;
      }
      const newPoints = formatPoints(numericWeight.toString(), scoringType);
      setPoints(formatPoints(numericWeight.toString(), scoringType));
      if (newPoints !== originalPoints) {
         updateRubricItem({ weight: numericWeight.toString() });
      }
   };

   const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const { enter, escape } = Constants.keys;
      if ([enter, escape].includes(event.key)) {
         pointsInput.current?.blur();
      }
   };

   const handleBlur = (): void => {
      updatePoints();
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      event.target.select();
   };

   return (
      <div className='points-wrapper'>
         {displayOnly ? (
            <span className={pointsClass()}>{points}</span>
         ) : (
            <input
               type='text'
               className={pointsClass()}
               onBlur={handleBlur}
               onChange={(e) => setPoints(e.target.value.replace(/\s/g, ''))}
               onFocus={handleFocus}
               onKeyDown={handleKeyDown}
               ref={pointsInput}
               value={points}
            />
         )}
      </div>
   );
};

export default PointsInput;
