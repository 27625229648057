import { graphql } from '@generated/gql';

export const updateUserMutation = graphql(`
   mutation UpdateUser($userId: String!, $userInput: UserInput!) {
      user(userId: $userId, userInput: $userInput) {
         id
      }
   }
`);

export const upsertOrganizationMutation = graphql(`
   mutation UpsertOrganization($organizationId: String, $organizationInput: OrganizationInput!) {
      organization(organizationId: $organizationId, organizationInput: $organizationInput) {
         id
      }
   }
`);

export const updateUserPasswordMutation = graphql(`
   mutation UpdateUserPasswordMutation($userId: String!, $passwordInput: UserPasswordUpdateInput!) {
      userUpdatePassword(userId: $userId, passwordInput: $passwordInput) {
         ok
      }
   }
`);

export const upsertInstructorLicneseMutation = graphql(`
   mutation UpsertInstructorLicense(
      $instructorLicenseId: String
      $instructorLicenseInput: InstructorLicenseUpsertInput!
   ) {
      instructorLicense(
         instructorLicenseId: $instructorLicenseId
         instructorLicenseInput: $instructorLicenseInput
      ) {
         ok
      }
   }
`);

export const upsertOrganizationLicenseMutation = graphql(`
   mutation UpsertOrganizationLicense(
      $organizationLicenseId: String
      $organizationLicenseInput: OrganizationLicenseUpsertInput!
   ) {
      organizationLicense(
         organizationLicenseId: $organizationLicenseId
         organizationLicenseInput: $organizationLicenseInput
      ) {
         ok
      }
   }
`);

graphql(`
   fragment UserProfileFeature on Feature {
      id
      feature
   }
`);

graphql(`
   fragment OrganizationDomain on OrganizationEmailDomain {
      id
      domain
   }
`);

graphql(`
   fragment ParentOrganization on Organization {
      id
      name
      licenses {
         ...UserProfileOrganizationLicense
      }
   }
`);

graphql(`
   fragment ChildOrganization on Organization {
      id
      name
   }
`);

graphql(`
   fragment OrganizationProfile on Organization {
      id
      name
      city
      state
      zipCode
      shortName
      domains {
         ...OrganizationDomain
      }
      domainRestricted
      parent {
         ...ParentOrganization
      }
      children {
         ...ChildOrganization
      }
      licenses {
         ...UserProfileOrganizationLicense
      }
   }
`);

export const organizationProfileQuery = graphql(`
   query OrganizationProfile($organizationId: String!) {
      organization(organizationId: $organizationId) {
         ...OrganizationProfile
      }
   }
`);

export const insertChildOrganizationMutation = graphql(`
   mutation InsertChildOrganization($childId: String!, $parentId: String!) {
      childOrganization(childId: $childId, parentId: $parentId) {
         id
      }
   }
`);

graphql(`
   fragment UserProfileInstructorLicense on InstructorLicense {
      id
      canManageContentLibrary
      canManageCourses
      canViewGlobalLibrary
      createdBy
      createdOn
      expiresOn
      instructorId
      notes
      numberOfSeats
      quickbooksInvoiceId
   }
`);

graphql(`
   fragment UserProfileOrganizationLicense on OrganizationLicense {
      id
      canManageContentLibrary
      canManageCourses
      canViewGlobalLibrary
      createdBy
      createdOn
      expiresOn
      notes
      numberOfSeats
      organizationId
      quickbooksInvoiceId
      shouldStudentsPay
   }
`);

graphql(`
   fragment UserProfileOrganizationBase on Organization {
      id
      name
      city
      state
      zipCode
      shortName
      licenses {
         ...UserProfileOrganizationLicense
      }
   }
`);

graphql(`
   fragment UserProfileOrganization on Organization {
      ...UserProfileOrganizationBase
      parent {
         ...UserProfileOrganizationBase
      }
   }
`);

graphql(`
   fragment UserProfile on User {
      id
      firstName
      lastName
      fullName
      email
      language
      accountType
      profileImageFilename
      profileImageUrl
      createdOn
      authenticated
      authenticatedOn
      isAdmin
      isDistrictAdmin
      demo
      disabled
      disableVocabSetTimer
      stripeCustomerId
      missingSchoolUrl
      referralSourceId
      features {
         ...UserProfileFeature
      }
      organization {
         ...UserProfileOrganization
      }
      licenses {
         ...UserProfileInstructorLicense
      }
   }
`);

export const userProfileQuery = graphql(`
   query UserProfile($userId: String!) {
      user(userId: $userId) {
         ...UserProfile
      }
   }
`);

graphql(`
   fragment UserReportRowUserLogin on UserLogin {
      id
      platform
      browser
      browserVersion
      lastRefreshedOn
      impersonated
      impersonatedBy
   }
`);

graphql(`
   fragment UserReportRowOrganization on Organization {
      id
      name
   }
`);

graphql(`
   fragment UserReportRow on User {
      id
      firstName
      lastName
      email
      language
      accountType
      profileImageFilename
      createdOn
      authenticated
      authenticatedOn
      isAdmin
      isDistrictAdmin
      demo
      disabled
      disableVocabSetTimer
      stripeCustomerId
      missingSchoolUrl
      referralSourceId
      logins {
         ...UserReportRowUserLogin
      }
      organizations {
         ...UserReportRowOrganization
      }
   }
`);

export const userReportQuery = graphql(`
   query UserReport(
      $currentPageNumber: Int
      $orderBy: [OrderBy!]!
      $pageSize: Int
      $searchString: String
   ) {
      userReport {
         queryResultTotalCount(searchString: $searchString)
         rows(
            currentPageNumber: $currentPageNumber
            orderBy: $orderBy
            pageSize: $pageSize
            searchString: $searchString
         ) {
            ...UserReportRow
         }
      }
   }
`);

graphql(`
   fragment UserTypeahead on User {
      id
      firstName
      lastName
      email
      profileImageUrl
   }
`);

export const userTypeaheadQuery = graphql(`
   query UserTypeaheadQuery($searchString: String) {
      userReport {
         rows(searchString: $searchString) {
            ...UserTypeahead
         }
      }
   }
`);

graphql(`
   fragment OrganizationSearchResult on Organization {
      id
      name
      city
      state
      zipCode
   }
`);

export const organizationTypeaheadQuery = graphql(`
   query OrganizationTypeaheadQuery(
      $currentPageNumber: Int
      $orderBy: [OrderBy!]!
      $pageSize: Int
      $searchString: String
   ) {
      organizationSearch {
         queryResultTotalCount(searchString: $searchString)
         rows(
            currentPageNumber: $currentPageNumber
            orderBy: $orderBy
            pageSize: $pageSize
            searchString: $searchString
         ) {
            ...OrganizationSearchResult
         }
      }
   }
`);
