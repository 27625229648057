import * as React from 'react';

interface SectionCardProps {
   title?: string;
   primary?: React.ReactNode;
   children: React.ReactNode;
}

const SectionCard: React.FC<SectionCardProps> = ({ title, primary, children }) => (
   <div className='col-xs-12 col-md-12 col-lg-8'>
      <div className='card course-card'>
         <div className='content-form'>
            {!!title && (
               <div className='card-title has-button full-width'>
                  <div className='title'>{title}</div>
                  {primary}
               </div>
            )}
            <div>{children}</div>
         </div>
      </div>
   </div>
);

export default SectionCard;
