import * as _ from 'lodash';
import * as React from 'react';

import Link from '@components/Common/Link';
import { Column } from '@components/Common/Table';
import FilterableTable from '@components/FilterableTable';
import { SCHOOL_ATTRIBUTES } from '@components/PredicateEditor/CommonAttributes';
import SchoolService from '@services/SchoolService';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import Constants from '../../Constants';
import { defaultPredicate } from './DefaultSchoolPredicate';
import ExtendedSchoolProfile from './ExtendedSchoolProfile';
import SchoolActionsCell from './SchoolActionsCell';

const Schools: React.FC = () => {
   const {
      routes: {
         courses: { viewCourses },
         schools: { viewSchools, createSchool },
         users: { viewUsers },
      },
   } = Constants;
   const location = useLocation();

   const navigate = useNavigate();

   React.useEffect(() => {
      if (location.pathname === createSchool) {
         navigate(createSchool);
      }
   }, [location.pathname]);

   const renderSchoolActionsCell = (
      row: ExtendedSchoolProfile,
      onUpdate: (updatedRow: ExtendedSchoolProfile) => void,
      onDelete: () => void,
   ): React.ReactNode => <SchoolActionsCell row={row} onUpdate={onUpdate} onDelete={onDelete} />;

   const renderCourseCountCell = (row: ExtendedSchoolProfile): React.ReactNode => (
      <Link to={`${viewCourses}?course.organization_id=${row.id}`}>{row.courseCount}</Link>
   );

   const renderEnrollmentCountCell = (row: ExtendedSchoolProfile): React.ReactNode => (
      <Link to={`${viewUsers}?school.id=${row.id}`}>{row.enrollmentCount}</Link>
   );

   const renderSelfLink = (
      row: ExtendedSchoolProfile,
      property: keyof ExtendedSchoolProfile,
   ): React.ReactNode => (
      <Link to={`${viewSchools}?school.${_.snakeCase(property)}=${row[property]}`}>
         {row[property] as number | string}
      </Link>
   );

   const columns: readonly Column<ExtendedSchoolProfile>[] = [
      { id: 'school.id', header: 'Id', cell: (i) => i.id, canSort: true },
      { id: 'school.name', header: 'Name', cell: (i) => i.name, canSort: true },
      {
         id: 'school.city',
         header: 'City',
         cell: (i) => renderSelfLink(i, 'city'),
         canSort: true,
      },
      {
         id: 'school.state',
         header: 'State',
         cell: (i) => renderSelfLink(i, 'state'),
         canSort: true,
      },
      {
         id: 'school.zip_code',
         header: 'Zip code',
         cell: (i) => renderSelfLink(i, 'zipCode'),
         canSort: true,
      },
      {
         id: 'school.course_count',
         header: 'Courses',
         cell: renderCourseCountCell,
         canSort: true,
      },
      {
         id: 'school.enrollment_count',
         header: 'Users',
         cell: renderEnrollmentCountCell,
         canSort: true,
      },
      {
         id: 'school.created_on',
         header: 'Created',
         cell: (i) => moment(i.createdOn).format('MM/DD/YYYY'),
         canSort: true,
      },
      { id: 'actions', header: 'Actions', cell: renderSchoolActionsCell },
   ];

   return (
      <>
         <FilterableTable<ExtendedSchoolProfile>
            attributes={SCHOOL_ATTRIBUTES}
            columns={columns}
            defaultPredicate={defaultPredicate}
            defaultSortOrder={[{ id: 'school.enrollment_count', desc: true }]}
            pageTitle='Schools'
            searchInputPlaceholder='Search Schools'
            rowsLoader={SchoolService.getSchools}
            onAddRowClick={() => navigate(createSchool)}
         />
      </>
   );
};

export default Schools;
