import * as React from 'react';

import { SimpleCourse } from '@services/CourseService';

interface CourseCreateSelectTemplateTagProps {
   row: SimpleCourse;
}

const CourseCreateSelectTemplateTag: React.FC<CourseCreateSelectTemplateTagProps> = (props) => {
   if (props.row.isOrganizationStandard) {
      return <span className='course-tag primary'>ORG. STANDARD</span>;
   }

   return <span className='course-tag success'>MY COURSE</span>;
};

export default CourseCreateSelectTemplateTag;
