import * as React from 'react';

import UserService from '@services/UserService';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import Button from '@components/Common/Button';

interface ImpersonationBannerProps {
   children: React.ReactElement | readonly React.ReactElement[];
}

const ImpersonationBanner: React.FC<ImpersonationBannerProps> = ({ children }) => {
   const navigate = useNavigate();

   const {
      routes: {
         users: { viewUsers },
      },
   } = Constants;

   const { userProfile, isImpersonating } = React.useContext<AppStateContext>(AppStateContext);

   const handleClick = (): void => {
      UserService.cancelImpersonation().then(() => {
         navigate(viewUsers);
      });
   };

   if (userProfile && isImpersonating) {
      const { firstName, lastName } = userProfile;
      return (
         <div>
            {children}
            <div className='impersonation-banner'>
               {`You are currently acting as ${firstName} ${lastName}`}
               <Button line onClick={handleClick}>
                  Stop Acting as User
               </Button>
            </div>
         </div>
      );
   }
   return <>{children}</>;
};

export default ImpersonationBanner;
