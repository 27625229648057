import { nanoid } from 'nanoid';

// 82.65 billion combinations
export const randomShortId = (length = 6): string => nanoid(length);
export const randomTempId = (length = 6): string => `temp-${randomShortId(length)}`;

export const randomDistinguishableCode = (length = 6): string => {
   let code = nanoid(length);
   code = code.replaceAll('0', 'A').replaceAll('8', 'F').replaceAll('1', '3');
   return code;
};
