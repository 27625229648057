import * as _ from 'lodash';

/**
 * Format number to include commas
 */
const numberWithCommas = (i: number): string => i.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const randomNumberBetween = (min: number, max: number): number =>
   Math.floor(Math.random() * (max - min + 1) + min);

const isInteger = (input: unknown): boolean => {
   if (_.isString(input)) {
      return Number.isInteger(Number.parseFloat(input));
   } else if (_.isInteger(input)) {
      return true;
   } else {
      return false;
   }
};

const isFloat = (input: unknown): boolean => {
   if (_.isString(input)) {
      return !Number.isNaN(Number.parseFloat(input));
   }
   return false;
};

export { numberWithCommas, randomNumberBetween, isInteger, isFloat };
