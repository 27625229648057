import * as React from 'react';

import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import AddButton from '@components/NationalExam/AddButton';
import Appearance from '@models/Appearance';
import { IdName } from '@models/Core';
import { ExamOrderResponseAlreadyClonedCourses } from '@models/NationalExam';

interface CloneExamCourseModalProps {
   coursesAvailableForExam: readonly IdName[];
   coursesAlreadyClonedForUser: readonly ExamOrderResponseAlreadyClonedCourses[];
   onAddClick(courseId: number): Promise<IdName | void>;
   onCancel(): void;
}

const CloneExamCourseModal: React.FC<CloneExamCourseModalProps> = (props) => {
   const renderAdd = (course: IdName): React.ReactNode => {
      const coursesAlreadyClonedForUser = props.coursesAlreadyClonedForUser || [];
      const foundCourse = coursesAlreadyClonedForUser.find((x) => x.clonedFromId === course.id);
      const isAlreadyInCourse = foundCourse !== undefined;

      return (
         <AddButton
            isAlreadyAdded={isAlreadyInCourse}
            objectId={course.id}
            onAddClick={props.onAddClick}
         />
      );
   };
   const columns: readonly Column<IdName>[] = [
      { id: 'id', header: 'Id', cell: (i) => i.id },
      { id: 'name', header: 'Name', cell: (i) => i.name },
      {
         id: 'Add',
         header: '',
         cell: renderAdd,
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='Clone Exam Course For User'
         bodyClassName='no-center padding-bottom-s'
         onClose={props.onCancel}
         actions={[{ text: 'Cancel', onClick: props.onCancel }]}
      >
         <div className='modal-body-scroll'>
            <Table<IdName> columns={columns} rowKey='id' rows={props.coursesAvailableForExam} />
         </div>
      </ModalDialog>
   );
};

export default CloneExamCourseModal;
