import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import Appearance from '@models/Appearance';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';

interface IDeleteCourseProps {
   courseName: string;
   isDemo: boolean;
   isDeletingCourse: boolean;
   deleteCourse(): void;
   isProtected: boolean;
}

const DeleteCourse: React.FC<IDeleteCourseProps> = ({
   courseName,
   isDeletingCourse,
   isDemo,
   deleteCourse,
   isProtected,
}) => {
   const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);

   const confirmDelete = (): void => {
      setDeleteModalOpen(true);
   };

   const closeDeleteModal = (): void => {
      setDeleteModalOpen(false);
   };

   const handleDeleteCourse = (): void => {
      setDeleteModalOpen(true);
      deleteCourse();
   };

   return (
      <>
         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>Delete Course</div>
            </div>
            <div className='content-form'>
               <div className='row'>
                  <div className='col-xs-9'>
                     <p className='course-option-description'>
                        <strong>Caution: </strong>
                        Once you delete a course, all modules, course content, and student
                        submissions will be permanently deleted. This action cannot be undone.
                     </p>
                  </div>
                  <div className='col-xs-3'>
                     <div className='flex-end'>
                        <Button
                           icon={<IconBin className='icon-white' aria-hidden />}
                           appearance={Appearance.danger}
                           onClick={confirmDelete}
                           className='margin-top-s'
                           disabled={isProtected}
                           tooltip={
                              isProtected ? 'This is a protected course and cannot be deleted.' : ''
                           }
                        >
                           Delete Course
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {deleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Delete Course'
               onClose={closeDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: handleDeleteCourse, loading: isDeletingCourse },
                  { text: 'Cancel', onClick: closeDeleteModal },
               ]}
            >
               <p>
                  Are you sure that you want to delete <strong>{courseName}</strong> and all of its
                  associated coursework? This action cannot be undone.
               </p>
               {isDemo && (
                  <p>
                     <strong>Note:</strong> This is a demo course so all student user accounts will
                     be deleted as well.
                  </p>
               )}
            </ModalDialog>
         )}
      </>
   );
};

export default DeleteCourse;
