import * as React from 'react';

import { Maybe } from '@models/Core';

import ContentFolder from '@models/ContentFolder';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import ContentLibraryName from '@models/ContentLibraryName';

export interface ContentLibraryContext {
   activeFolderId: Maybe<number>;
   activeLibrary: Maybe<ContentLibraryName>;
   folders: readonly ContentFolder[];
   layoutView: Maybe<ContentLibraryLayout>;
}

export const ContentLibraryContext = React.createContext<ContentLibraryContext>({
   activeFolderId: null,
   activeLibrary: null,
   folders: [],
   layoutView: null,
});

export const ContentLibraryConsumer = ContentLibraryContext.Consumer;
export const ContentLibraryProvider = ContentLibraryContext.Provider;
