import * as React from 'react';

import IconIncorrect from '@icons/general/icon-incorrect.svg';
import IconTickGreen from '@icons/general/icon-tick-green.svg';
import classnames from 'classnames';

import Button from '@components/Common/Button';

interface IMultipleChoiceOption {
   children: React.ReactNode;
   correct?: boolean;
   disabled?: boolean;
   incorrect?: boolean;
   missed?: boolean;
   onClick(): void;
}

const MultipleChoiceOption: React.FC<IMultipleChoiceOption> = ({
   children,
   correct,
   disabled,
   incorrect,
   missed,
   onClick,
}) => {
   const renderIcon = (): React.ReactNode => {
      if (correct) {
         return <IconTickGreen />;
      } else if (incorrect) {
         return <IconIncorrect />;
      }
      return null;
   };

   return (
      <Button
         line
         fullWidth
         disabled={disabled}
         className={classnames({ correct, incorrect, missed })}
         icon={renderIcon()}
         onClick={onClick}
      >
         {children}
      </Button>
   );
};

export default React.memo(MultipleChoiceOption);
