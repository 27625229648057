import * as _ from 'lodash';
import * as React from 'react';
import * as yup from 'yup';

import Button from '@components/Common/Button';
import { SectionCard } from '@components/Common/SectionCard';
import DateTimePicker from '@components/Core/DateTimePicker';
import InfoTooltip from '@components/InfoTooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import { LandingPageContent } from '@models/LandingPageContent';
import { FullNationalExam, NationalExamAbbreviationConstants } from '@models/NationalExam';
import AdminNationalExamService, {
   NationalExamFormInput,
} from '@services/AdminNationalExamService';
import { isAxiosError } from 'axios';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';

interface ExamInformationPanelProps {
   disableUpdates: boolean;
   exam?: FullNationalExam;
   landingPageContent?: LandingPageContent;
   setExam(exam: FullNationalExam): void;
   setLandingPageContent(landingPageContent: LandingPageContent): void;
}

const NationalExamSchema = yup.object({
   examYear: yup.number().required(),
   handlingFeeDomesticLarge: yup.number().required(),
   handlingFeeDomesticSmall: yup.number().required(),
   handlingFeeInternational: yup.number().required(),
   hasLingcoRegistration: yup.boolean().required(),
   hasPublicPracticeCourses: yup.boolean().required(),
   invoiceTemplate: yup.string().required().nullable(),
   landingPageContentGreeting: yup.string().required(),
   landingPageContentTitle: yup.string().required(),
   lateFee: yup.number().required(),
   lateRegDate: yup.date().required().nullable(),
   memberPrice: yup.number().required(),
   membershipName: yup.string().required(),
   name: yup.string().required(),
   nameAbbr: yup.string().oneOf(NationalExamAbbreviationConstants).required(),
   nonMemberFee: yup.number().required().nullable(),
   orderSpreadsheet: yup.string().required().nullable(),
   orderWorksheet: yup.string().required().nullable(),
   paperRegEndDate: yup.date().required().nullable(),
   price: yup.number().required(),
   regEndDate: yup.date().required(),
   regStartDate: yup.date().required(),
   seatCutoff: yup.number().required(),
   shortName: yup.string().required(),
   shouldCacheResults: yup.boolean().required(),
});

const ExamInformationPanel: React.FC<ExamInformationPanelProps> = (props) => {
   const {
      control,
      formState: { errors, isDirty },
      handleSubmit,
      register,
      reset,
      setValue,
      watch,
   } = useForm<NationalExamFormInput>({
      resolver: yupResolver<NationalExamFormInput>(NationalExamSchema),
      defaultValues: {
         landingPageContentGreeting: props.landingPageContent?.greeting,
         landingPageContentTitle: props.landingPageContent?.title,
         lateFee: 0,
         lateRegDate: null,
         memberPrice: 0,
         paperRegEndDate: null,
         hasLingcoRegistration: true,
         hasPublicPracticeCourses: false,
         handlingFeeDomesticLarge: 0,
         handlingFeeDomesticSmall: 0,
         handlingFeeInternational: 0,
         seatCutoff: 0,
         shouldCacheResults: false,
         ...props.exam,
      },
   });

   const nameAbbrValue = watch('nameAbbr');
   const examYearValue = watch('examYear');

   const onSubmit = async (data: NationalExamFormInput): Promise<void> => {
      // Safeguard just in case something somehow submits an update.
      if (props.disableUpdates) {
         return;
      }

      try {
         if (!props.exam) {
            const response = await AdminNationalExamService.createNationalExam(data);
            props.setExam(response.exam);
            props.setLandingPageContent(response.landingPageContent);
         } else {
            const response = await AdminNationalExamService.updateNationalExam(props.exam.id, data);
            props.setExam(response.exam);
            reset(response.exam);
            props.setLandingPageContent(response.landingPageContent);
         }
      } catch (e) {
         console.error(e);
         if (isAxiosError(e)) {
            alert(e.response?.data.error);
         }
      }
   };

   React.useEffect(() => {
      if (nameAbbrValue && examYearValue) {
         setValue('shortName', `${nameAbbrValue}_${examYearValue}`);
      }
   }, [nameAbbrValue, examYearValue, setValue]);

   const handleEvent = async (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
   ): Promise<void> => {
      handleSubmit(onSubmit)(event);
   };

   return (
      <SectionCard
         title='Exam Information'
         headerButton={
            !props.disableUpdates ? (
               <Button
                  className='margin-left-auto'
                  disabled={!isDirty}
                  onClick={handleEvent}
                  type='submit'
               >
                  {props.exam ? 'Save' : 'Create'}
               </Button>
            ) : undefined
         }
      >
         <form>
            <div className='row'>
               <div className='col-xs-12 col-sm-8'>
                  <label className='field-title'>Name</label>
                  <input
                     {...register('name')}
                     type='text'
                     className={classnames({ error: !!errors.name })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>Exam Year</label>
                  <input
                     {...register('examYear')}
                     type='number'
                     className={classnames({ error: !!errors.examYear })}
                     disabled={props.disableUpdates || props.exam !== undefined} // Should not allow exam year updating after creation
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <div className='switch-wrapper'>
                     <input
                        type='checkbox'
                        className={classnames('react-style switch yellow', {
                           error: !!errors.hasLingcoRegistration,
                        })}
                        disabled={props.disableUpdates}
                        {...register('hasLingcoRegistration')}
                     />
                     <label className='field-title'>
                        Has Lingco registration?
                        <InfoTooltip>
                           Almost all exams have Lingco registration. NFC and various prep/practice
                           courses do not. They often then have public practice courses.
                        </InfoTooltip>
                     </label>
                  </div>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <div className='switch-wrapper'>
                     <input
                        {...register('hasPublicPracticeCourses')}
                        type='checkbox'
                        className={classnames('react-style switch yellow', {
                           error: !!errors.hasPublicPracticeCourses,
                        })}
                        disabled={props.disableUpdates}
                     />
                     <label className='field-title'>
                        Has public practice courses?
                        <InfoTooltip>
                           These are the courses that someone can clone without placing an order.
                           Typically done for the NFC and prep/practice only.
                        </InfoTooltip>
                     </label>
                  </div>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <div className='switch-wrapper'>
                     <input
                        {...register('shouldCacheResults')}
                        type='checkbox'
                        className={classnames('react-style switch yellow', {
                           error: !!errors.shouldCacheResults,
                        })}
                        disabled={props.disableUpdates}
                     />
                     <label className='field-title'>
                        Should cache results?
                        <InfoTooltip>
                           This will determine whether or not we cache the exams results for
                           reporting. When this is enabled the results will be cached the next day
                           early in the morning.
                        </InfoTooltip>
                     </label>
                  </div>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Name Abbreviation
                     <InfoTooltip>
                        If the name abbreviation does not appear in this list we'll need to add it
                        to Lingco. Talk to engineering.
                     </InfoTooltip>
                  </label>
                  <select
                     {...register('nameAbbr')}
                     className={classnames({ error: !!errors.nameAbbr })}
                     disabled={props.disableUpdates || props.exam !== undefined}
                  >
                     <option />
                     {NationalExamAbbreviationConstants.map((x) => (
                        <option key={x} value={x}>
                           {x}
                        </option>
                     ))}
                  </select>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Short Name
                     <InfoTooltip>
                        This field is always a concatenation of the name abbreviation and year. The
                        goto link is generated based on this link. E.g.
                        class.lingco.io/goto/nle_2024
                     </InfoTooltip>
                  </label>
                  <input
                     {...register('shortName')}
                     type='text'
                     className={classnames({ error: !!errors.shortName })}
                     disabled
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Membership Name
                     <InfoTooltip>
                        This field is really only used during registration for those who require a
                        membership and have chapters.
                     </InfoTooltip>
                  </label>
                  <select
                     {...register('membershipName')}
                     className={classnames({ error: !!errors.membershipName })}
                     disabled={props.disableUpdates}
                  >
                     <option />
                     <option value='USA'>USA</option>
                     <option value='AATF'>AATF</option>
                     <option value='AATG'>AATG</option>
                     <option value='AATI'>AATI</option>
                     <option value='AATSP'>AATSP</option>
                  </select>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>Registration Start Date</label>
                  <Controller
                     name='regStartDate'
                     control={control}
                     render={({ field }) => (
                        <DateTimePicker
                           {...{ ...field, ref: null }}
                           className={classnames({ error: !!errors.regStartDate })}
                           disabled={props.disableUpdates}
                        />
                     )}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>Registration End Date</label>
                  <Controller
                     name='regEndDate'
                     control={control}
                     render={({ field }) => (
                        <DateTimePicker
                           {...{ ...field, ref: null }}
                           className={classnames({ error: !!errors.regEndDate })}
                           disabled={props.disableUpdates}
                        />
                     )}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Paper Registration End Date
                     <InfoTooltip>
                        This field need only be filled out for exams that can have paper orders.
                     </InfoTooltip>
                  </label>
                  <Controller
                     name='paperRegEndDate'
                     control={control}
                     render={({ field }) => (
                        <DateTimePicker
                           {...{ ...field, ref: null }}
                           className={classnames({ error: !!errors.paperRegEndDate })}
                           disabled={props.disableUpdates}
                        />
                     )}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Price
                     <InfoTooltip>This is the price per exam.</InfoTooltip>
                  </label>
                  <input
                     {...register('price')}
                     type='number'
                     className={classnames({ error: !!errors.price })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Member Price
                     <InfoTooltip>This is the price per exam for a member.</InfoTooltip>
                  </label>
                  <input
                     {...register('memberPrice')}
                     type='number'
                     className={classnames({ error: !!errors.memberPrice })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <label className='field-title'>
                     Non-member Fee
                     <InfoTooltip>This is an order fee assessed for non-members.</InfoTooltip>
                  </label>
                  <input
                     {...register('nonMemberFee', {
                        setValueAs: (v) => (v === '' ? null : v),
                     })}
                     type='number'
                     className={classnames({ error: !!errors.nonMemberFee })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>
                     Small/Large Seat Cutoff
                     <InfoTooltip>
                        Used on the NLE form and potentially for those with paper exams to determine
                        when to charge a small vs large handling fee.
                     </InfoTooltip>
                  </label>
                  <input
                     {...register('seatCutoff')}
                     type='number'
                     className={classnames({ error: !!errors.seatCutoff })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>
                     Small Order Handling Fee
                     <InfoTooltip>The handling fee for small orders.</InfoTooltip>
                  </label>
                  <input
                     {...register('handlingFeeDomesticSmall')}
                     type='number'
                     className={classnames({ error: !!errors.handlingFeeDomesticSmall })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>
                     Large Order Handling Fee
                     <InfoTooltip>The handling fee for large orders.</InfoTooltip>
                  </label>
                  <input
                     {...register('handlingFeeDomesticLarge')}
                     type='number'
                     className={classnames({ error: !!errors.handlingFeeDomesticLarge })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-3'>
                  <label className='field-title'>
                     International Handling Fee
                     <InfoTooltip>
                        The interational handling fee. No distinction between small and large
                        orders.
                     </InfoTooltip>
                  </label>
                  <input
                     {...register('handlingFeeInternational')}
                     type='number'
                     className={classnames({ error: !!errors.handlingFeeInternational })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>
                     Late Registration Date
                     <InfoTooltip>
                        The late registration date is used to determine if the late fee needs to be
                        added.
                     </InfoTooltip>
                  </label>
                  <Controller
                     name='lateRegDate'
                     control={control}
                     render={({ field }) => (
                        <DateTimePicker
                           {...{ ...field, ref: null }}
                           className={classnames({ error: !!errors.lateRegDate })}
                           disabled={props.disableUpdates}
                        />
                     )}
                  />
               </div>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>
                     Late Fee
                     <InfoTooltip>
                        The late fee to be added if past the late registration date.
                     </InfoTooltip>
                  </label>
                  <input
                     {...register('lateFee')}
                     type='number'
                     className={classnames({ error: !!errors.lateFee })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>
                     Order Spreadsheet
                     <InfoTooltip>
                        The identifier for the order spreadsheet in Google if they want orders to
                        sync through. For exams with paper orders or registration using the 'NLE'
                        registration form (ACL and NLE) be sure to clone the NLE template. All other
                        exams use the other template.
                     </InfoTooltip>
                  </label>
                  <input
                     {...register('orderSpreadsheet', {
                        setValueAs: (v) => (v === '' ? null : v),
                     })}
                     type='text'
                     className={classnames({ error: !!errors.orderSpreadsheet })}
                     disabled={props.disableUpdates}
                  />
               </div>
               <div className='col-xs-12 col-sm-6'>
                  <label className='field-title'>
                     Order Worksheet
                     <InfoTooltip>The name of the worksheet to write rows to.</InfoTooltip>
                  </label>
                  <input
                     {...register('orderWorksheet', {
                        setValueAs: (v) => (v === '' ? null : v),
                     })}
                     type='text'
                     className={classnames({ error: !!errors.orderWorksheet })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>
                     Invoice Template (HTML)
                     <InfoTooltip>
                        This is the HTML that we use for creating invoices. If an exam has Lingco
                        registration you must fill this out.
                     </InfoTooltip>
                  </label>
                  <textarea
                     {...register('invoiceTemplate', {
                        setValueAs: (v) => (v === '' ? null : v),
                     })}
                     className={classnames({ error: !!errors.invoiceTemplate })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>
                     Landing Page Title (HTML)
                     <InfoTooltip>
                        This is the title of the landing page when a person is not logged in and
                        goes to a 'goto' link
                     </InfoTooltip>
                  </label>
                  <textarea
                     {...register('landingPageContentTitle')}
                     className={classnames({ error: !!errors.landingPageContentTitle })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>
                     Landing Page Greeting (HTML){' '}
                     <InfoTooltip>
                        This is the body of the landing page when a person is not logged in and goes
                        to a 'goto' link
                     </InfoTooltip>
                  </label>
                  <textarea
                     {...register('landingPageContentGreeting')}
                     className={classnames({ error: !!errors.landingPageContentGreeting })}
                     disabled={props.disableUpdates}
                  />
               </div>
            </div>
         </form>
      </SectionCard>
   );
};

export default ExamInformationPanel;
