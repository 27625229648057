import { uploadImage } from '@services/AssetService';
import { takeScreenshot } from './ScreenShot';

type generateThumbnailResponse = {
   filename: string;
   localUrl: string;
};

/**
 * @param selector Default: 'video'
 * @param filename Optional string
 * @returns imageDataUrl
 */
export const generateThumbnail = async (
   selector = 'video',
   filename?: string,
): Promise<generateThumbnailResponse> => {
   const imageBlob = await takeScreenshot(selector);
   const localUrl = URL.createObjectURL(imageBlob);

   const imageFilename = filename || 'thumbnail.jpg';
   const shouldGenerateNewFilename = !filename;

   const imageFile = new File([imageBlob], imageFilename);
   const uploadedFilename = await uploadImage(imageFile, shouldGenerateNewFilename);

   return { filename: uploadedFilename, localUrl };
};
