import * as _ from 'lodash';
import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowRight from '@icons/general/icon-arrow-right.svg';
import IconLevel from '@icons/general/icon-menu-level.svg';
import IconContentBox2 from '@icons/nova-line/18-Content/content-box-2.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import ContentFolder from '@models/ContentFolder';
import ModalDialog from './Core/ModalDialog';

interface SelectFolderModalProps {
   currentFolderId: Maybe<number>;
   folders: readonly ContentFolder[];
   heading: string;
   primaryBtnText: string;
   closeModal(): void;
   onPrimaryClick(event: React.MouseEvent<HTMLButtonElement>, folderId: Maybe<number>): void;
}

const SelectFolderModal: React.FC<SelectFolderModalProps> = ({
   currentFolderId,
   folders,
   heading,
   primaryBtnText = 'Select',
   closeModal,
   onPrimaryClick,
}) => {
   const [activeFolder, setActiveFolder] = React.useState<Maybe<number>>(null);
   const [openFolders, setOpenFolders] = React.useState<readonly number[]>([]);

   React.useEffect(() => {
      let currentFolder: Maybe<ContentFolder> =
         folders.find((folder) => folder.id === currentFolderId) || null;
      const path = [];

      while (currentFolder) {
         path.unshift(currentFolder.id);
         currentFolder = folders.find((folder) => folder.id === currentFolder?.parentId) || null;
      }

      setOpenFolders(path);
      setActiveFolder(currentFolderId);
   }, [currentFolderId, folders]);

   const renderArrow = (isExpanded: boolean, hasChildren: boolean): React.ReactNode => {
      if (!hasChildren) {
         return <IconLevel aria-hidden />;
      } else if (isExpanded) {
         return <IconArrowDown aria-hidden />;
      } else {
         return <IconArrowRight aria-hidden />;
      }
   };

   const handleFolderClick = (
      folderId: Maybe<number>,
      event: React.MouseEvent<HTMLDivElement>,
   ): void => {
      event.stopPropagation();
      if (!folderId) {
         return;
      }
      setActiveFolder(folderId);
      const hasChildren = folders.some((i) => i.parentId === folderId);
      if (hasChildren) {
         setOpenFolders((prevOpenFolders) => [...prevOpenFolders, folderId]);
      }
   };

   const handleFolderDoubleClick = (
      folderId: Maybe<number>,
      event: React.MouseEvent<HTMLDivElement>,
   ): void => {
      event.stopPropagation();
      const hasChildren = folders.some((i) => i.parentId === folderId);
      if (!hasChildren || !folderId) {
         return;
      }
      if (openFolders.includes(folderId)) {
         setOpenFolders((prevOpenFolders) => prevOpenFolders.filter((i) => i !== folderId));
      } else {
         setOpenFolders((prevOpenFolders) => [...prevOpenFolders, folderId]);
      }
   };

   const renderSubtree = (folderId: Maybe<number>): readonly React.ReactNode[] =>
      _.sortBy(
         folders.filter((i) => i.parentId === folderId),
         'name',
      ).map((i) => {
         const isExpanded = openFolders.includes(i.id);
         const hasChildren = folders.some((j) => j.parentId === i.id);
         return (
            <div className='folder-wrapper' key={i.id}>
               <div
                  className={classnames('folder-name-wrapper', {
                     active: activeFolder === i.id,
                     current: currentFolderId === i.id,
                  })}
                  onClick={(e) => handleFolderClick(i.id, e)}
                  onDoubleClick={(e) => handleFolderDoubleClick(i.id, e)}
               >
                  <div className='nav-icon'>{renderArrow(isExpanded, hasChildren)}</div>
                  <div className='folder-name'>
                     {i.name}
                     {currentFolderId === i.id && <span className='current-tag'>Current</span>}
                  </div>
               </div>
               {isExpanded && renderSubtree(i.id)}
            </div>
         );
      });

   return (
      <ModalDialog
         heading={heading}
         appearance={Appearance.primary}
         bodyClassName='move-modal'
         animations={{ enter: 'animated bounceInDown' }}
         width='small'
         actions={[
            {
               text: primaryBtnText,
               onClick: (e) => onPrimaryClick(e, activeFolder),
            },
            { text: 'Cancel', onClick: closeModal },
         ]}
      >
         <div>
            <div className='folder-wrapper'>
               <div
                  className={classnames('folder-name-wrapper', 'top-row', {
                     active: activeFolder === null,
                     current: currentFolderId === null,
                  })}
                  onClick={(e) => handleFolderClick(null, e)}
               >
                  <div className='nav-icon'>
                     <IconContentBox2 />
                  </div>
                  <div className='folder-name'>
                     Content Library
                     {currentFolderId === null && <span className='current-tag'>Current</span>}
                  </div>
               </div>
               {renderSubtree(null)}
            </div>
         </div>
      </ModalDialog>
   );
};

export default SelectFolderModal;
