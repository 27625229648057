import * as React from 'react';

import IconShield from '@icons/nova-line/02-Status/close-shield.svg';
import { Assignment } from '@models/Course';

import Switch from '@components/Common/Switch';
import InfoTooltip from '@components/InfoTooltip';
import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';

interface SecurityPanelProps {
   assignment: Assignment;
   canEditModule: boolean;
   isOpen: boolean;
   onAssignmentUpdate(update: Partial<Assignment>): void;
   toggleOpen(): void;
}

const SecurityPanel: React.FC<SecurityPanelProps> = ({
   assignment,
   canEditModule,
   isOpen,
   onAssignmentUpdate,
   toggleOpen,
}) => {
   const toggleTrackUserEvents = (): void => {
      onAssignmentUpdate({
         trackStudentEvents: !assignment.trackStudentEvents,
      });
   };

   return (
      <ModuleItemSettingsAccordion
         collapsible
         icon={<IconShield aria-hidden />}
         isOpen={isOpen}
         onToggle={toggleOpen}
         title='Security'
      >
         <div className='row'>
            <div className='col-xs-12 col-sm-6'>
               <Switch
                  checked={!!assignment.trackStudentEvents}
                  disabled={!canEditModule}
                  onChange={toggleTrackUserEvents}
               >
                  <label className='field-title'>
                     Track user events
                     <InfoTooltip>
                        Track when students leave Lingco during this assignment.
                     </InfoTooltip>
                  </label>
               </Switch>
            </div>
         </div>
      </ModuleItemSettingsAccordion>
   );
};

export default SecurityPanel;
