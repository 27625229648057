import * as React from 'react';

import Button from '@components/Common/Button';
import { SectionCard } from '@components/Common/SectionCard';
import FeatureCheck from '@components/Core/FeatureCheck';
import Loader from '@components/Loader';
import { isSameDay } from '@helpers/DateUtils';
import {
   isPersistedNationalExamReportTaskResponse,
   NationalExamReportLinks,
} from '@models/NationalExam';
import AdminNationalExamService from '@services/AdminNationalExamService';
import { downloadExternalFile } from '@services/AxiosService';
import NationalExamAdminService from '@services/NationalExamAdminService';

import { AppStateContext } from '../../AppState';

type ExamReportsInAttachmentTable = 'examScoreSummaries';

type ExamReports =
   | ExamReportsInAttachmentTable
   | 'examOrders'
   | 'cheaterReport'
   | 'binaryReport'
   | 'reliabilityCofficient'
   | 'instructorList';

type ExamReportAttachmentType = 'all_exam_student_score_summaries';

const examReportAttachmentTableMap: Record<ExamReportsInAttachmentTable, ExamReportAttachmentType> =
   {
      examScoreSummaries: 'all_exam_student_score_summaries',
   };

const FetchReport: Record<ExamReports, (examId: string | number) => Promise<Blob | void | string>> =
   {
      examOrders: NationalExamAdminService.getAllOrders,
      binaryReport: AdminNationalExamService.getBinaryReport,
      cheaterReport: NationalExamAdminService.getCheaterReport,
      examScoreSummaries: NationalExamAdminService.generateExamScoreSummariesReport,
      reliabilityCofficient: AdminNationalExamService.getExamReliabilityCoefficient,
      instructorList: NationalExamAdminService.getInstructorList,
   };

interface ExamReportPanelProps {
   examId: number | string;
   hasCachedResults: boolean;
}

const ExamReportPanel: React.FC<ExamReportPanelProps> = (props) => {
   const { reportTaskResponse } = React.useContext<AppStateContext>(AppStateContext);
   const [downloadingReport, setDownloadingReport] = React.useState<ExamReports | undefined>(
      undefined,
   );
   const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [reportLinks, setReportLinks] = React.useState<NationalExamReportLinks[]>([]);
   const [messageId, setMessageId] = React.useState<string | undefined>(undefined);

   React.useEffect(() => {
      NationalExamAdminService.getGeneratedReports(props.examId).then((data) => {
         setReportLinks(data);
         setIsLoading(false);
      });
   }, []);

   React.useEffect(() => {
      if (reportTaskResponse && isPersistedNationalExamReportTaskResponse(reportTaskResponse)) {
         setReportLinks(reportTaskResponse.reports);

         reportTaskResponse.reports.forEach((report) => {
            if (report.messageId === messageId) {
               downloadExternalFile(report.link, report.fileName);
               setMessageId(undefined);
            }
            setIsDisabled(false);
            setDownloadingReport(undefined);
         });
      }
   }, [reportTaskResponse]);

   const getExamReport = async (report: ExamReports) => {
      if (report === 'examScoreSummaries') {
         // If the report has been generated already today just download that.
         const today = new Date();
         const reportLink = reportLinks.find(
            (x) =>
               isSameDay(x.createdOn, today) && examReportAttachmentTableMap[report] === x.typeName,
         );

         if (reportLink) {
            downloadExternalFile(reportLink.link, reportLink.fileName);
            return;
         }
      }

      if (isDisabled) return;

      setIsDisabled(true);
      setDownloadingReport(report);

      FetchReport[report](props.examId).then((result) => {
         if (typeof result === 'string') {
            setMessageId(result);
         } else {
            setIsDisabled(false);
            setDownloadingReport(undefined);
         }
      });
   };

   return (
      <SectionCard title='Reports (Data Refreshes Daily, if a button is disabled results may not be cached)'>
         {isLoading ? (
            <Loader />
         ) : (
            <div className='flex flex-col'>
               <FeatureCheck feature='national_exam_reporting'>
                  <Button
                     className='margin-s'
                     onClick={() => getExamReport('binaryReport')}
                     loading={downloadingReport === 'binaryReport'}
                     disabled={isDisabled}
                  >
                     Download Binary Report
                  </Button>
                  <Button
                     className='margin-s'
                     onClick={() => getExamReport('reliabilityCofficient')}
                     loading={downloadingReport === 'reliabilityCofficient'}
                     disabled={isDisabled || !props.hasCachedResults}
                  >
                     Download Exam Reliability Coefficient
                  </Button>
               </FeatureCheck>
               <Button
                  className='margin-s'
                  onClick={() => getExamReport('examOrders')}
                  loading={downloadingReport === 'examOrders'}
                  disabled={isDisabled}
               >
                  Download Exam Orders
               </Button>
               <Button
                  className='margin-s'
                  onClick={() => getExamReport('instructorList')}
                  loading={downloadingReport === 'instructorList'}
                  disabled={isDisabled}
               >
                  Download Registered Instructors
               </Button>
               <Button
                  className='margin-s'
                  onClick={() => getExamReport('cheaterReport')}
                  loading={downloadingReport === 'cheaterReport'}
                  disabled={isDisabled}
               >
                  Download Cheater Report
               </Button>
               <Button
                  className='margin-s'
                  onClick={() => getExamReport('examScoreSummaries')}
                  loading={downloadingReport === 'examScoreSummaries'}
                  disabled={isDisabled || !props.hasCachedResults}
               >
                  Download Exam Score Summaries
               </Button>
            </div>
         )}
      </SectionCard>
   );
};

export default ExamReportPanel;
