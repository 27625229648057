// @ts-strict-ignore
import 'annyang';

import Language from '@models/Language';

// eslint-disable-next-line no-duplicate-imports
import type { Annyang, CommandOption } from 'annyang';

export interface SpeechEngineParams {
   inputLanguage: Language;
   acceptedResponses: readonly string[];
   onAnsweredCorrectly(): void;
   onAnsweredWrong(results: readonly string[]): void;
}

/**
 * Speech engine library
 * A hub for all the speech engine events.
 * Keeps track of:
 * - all speech engine commands
 * - all callbacks fired by speech engine
 */
class SpeechEngine {
   params: SpeechEngineParams;
   annyang: Annyang;
   listening: boolean;
   commands: CommandOption;

   /**
    * Initializes speech engine
    * All commands are generated and engine is prepared for execution
    * The speech engine will wait until it gets the 'start-listening' event
    * before listening for any user speech
    *
    * @param {SpeechEngineParams} params
    *  A central event store that all events are channeled through
    */
   constructor(params: SpeechEngineParams) {
      this.params = params;
      this.annyang = window.annyang;
      this.listening = false;
      this.commands = this.getCommands(params.acceptedResponses);
   }

   /**
    * Requests usage of microphone and initializes speech engine
    * commands and callbacks.
    */
   init(): void {
      const { inputLanguage } = this.params;
      if (inputLanguage) {
         const toIetf = {
            ar: 'ar-EG',
            cmn: 'cmn-Hans-CN',
            de: 'de-DE',
            en: 'en-US',
            es: 'es-US',
            asl: null,
            la: null,
            he: 'he',
            ja: 'ja',
            ko: 'ko',
            fr: 'fr-FR',
            it: 'it-IT',
            tr: 'tr',
            pt: 'pt-BR',
            ru: 'ru-RU',
            vi: 'vi-VN',
         };
         const ietfLang = toIetf[inputLanguage] ?? null;
         if (ietfLang) {
            this.annyang.setLanguage(ietfLang);
         }
      }
      this.listening = true;
      this.annyang.addCallback('resultNoMatch', this.answeredWrong.bind(this));
      this.annyang.addCommands(this.commands);
      this.annyang.start();
   }

   /**
    * Stops listening and turns of microphone.
    * Removes all commands and callbacks so speech engine can be re-initialized
    * with a different set of rules by a different task.
    */
   destroy(): void {
      this.listening = false;
      this.annyang.removeCallback('resultNoMatch', this.answeredWrong.bind(this));
      this.annyang.removeCommands();
      this.annyang.abort();
   }

   /**
    * Notify listeners that user has answered correctly.
    * Destroy speech engine
    */
   answeredCorrectly(): void {
      if (this.listening) {
         this.params.onAnsweredCorrectly();
         this.destroy();
      }
   }

   /**
    * Notify listeners that user has answered wrong.
    *
    * @param {readonly string[]} results
    *  User answers as interpreted by the speech engine
    */
   answeredWrong(results: readonly string[]): void {
      if (this.listening) {
         this.params.onAnsweredWrong(results);
         this.destroy();
      }
   }

   /**
    * Generate commands from author specified answers
    * If a user answers gives of the accepted answers it should be interpreted
    * as a correctly answered task
    *
    * @param {readonly string[]} acceptedResponses Author specified list of accepted answers
    * @return {CommandOption} Consumable commands for the speech engine
    */
   getCommands(acceptedResponses: readonly string[]): CommandOption {
      return acceptedResponses.reduce((prev, curr) => {
         prev[curr] = (): void => {
            this.answeredCorrectly();
         };
         return prev;
      }, {});
   }
}

export default SpeechEngine;
