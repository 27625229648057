import * as React from 'react';

import classnames from 'classnames';

const TabsContext = React.createContext<string | null>(null);

export const TabsProvider = TabsContext.Provider;
export const TabsConsumer = TabsContext.Consumer;

interface TabsProps {
   className?: string;
   children: React.ReactNode;
   defaultTab?: string;
   onTabChange?(tabName: string): void;
}

const Tabs: React.FC<TabsProps> = (props) => {
   const filteredChildren = React.Children.toArray(props.children).filter((child) =>
      React.isValidElement(child),
   );

   const getTabIds = (): readonly string[] =>
      filteredChildren
         .map((child) => {
            if (React.isValidElement(child) && child.props.id) {
               return child.props.id;
            }
            return null;
         })
         .filter((id) => id !== null);

   const [active, setActive] = React.useState<string>('');

   React.useEffect(() => {
      if (!active && props.defaultTab) {
         setActive(props.defaultTab);
      } else {
         const tabIds = getTabIds();
         if (!tabIds.includes(active)) {
            setActive(tabIds[0]);
         }
      }
   }, [props.children]);

   const onTabChange = (tabId: string): void => {
      setActive(tabId);
      props.onTabChange?.(tabId);
   };

   return (
      <>
         <div className={classnames(props.className)}>
            {filteredChildren.map((child) => {
               if (React.isValidElement(child)) {
                  const {
                     className: tabClassName,
                     id: tabId,
                     heading,
                     name,
                     disabled = false,
                     ...otherProps
                  } = child.props;
                  return (
                     <div
                        className={classnames(tabClassName, {
                           active: tabId === active,
                           disabled,
                        })}
                        onClick={() => !disabled && onTabChange(tabId)}
                        key={tabId}
                        {...otherProps}
                     >
                        {heading ? heading : <div className='title small'>{name}</div>}
                     </div>
                  );
               }
               return null;
            })}
         </div>
         <TabsProvider value={active}>{props.children}</TabsProvider>
      </>
   );
};

export default Tabs;
