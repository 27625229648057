import { BasicLevelName } from '@models/Proficiency';
import React from 'react';

const levelColors = {
   Novice: '#007CBB',
   Intermediate: '#4A7D35',
   Advanced: '#FFB53E',
};

interface LevelIndicatorProps extends React.SVGProps<SVGElement> {
   level: BasicLevelName;
}

const LevelIndicator: React.FC<LevelIndicatorProps> = ({
   level,
   width = 6,
   height = 20,
   ref,
   ...props
}) => {
   // Determine the color of each notch based on the level
   const colorTop = level === 'Advanced' ? levelColors.Advanced : '#D9D9D9';
   const colorMiddle =
      level === 'Intermediate' || level === 'Advanced' ? levelColors[level] : '#D9D9D9';
   const colorBottom = levelColors[level];

   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox='0 0 6 20'
         {...props}
      >
         {/* Top notch */}
         <path
            d='M0 2C0 0.89543 0.895431 0 2 0H4C5.10457 0 6 0.895431 6 2V6H0V2Z'
            fill={colorTop}
         />
         {/* Middle notch */}
         <rect y='7' width='6' height='6' fill={colorMiddle} />
         {/* Bottom notch */}
         <path
            d='M0 14H6V18C6 19.1046 5.10457 20 4 20H2C0.89543 20 0 19.1046 0 18V14Z'
            fill={colorBottom}
         />
      </svg>
   );
};

export default LevelIndicator;
