import * as React from 'react';

import autobind from '@helpers/autobind';
import { formatSeconds } from '@helpers/FormatTime';
import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';
import IconBuilderLoading from '@icons/general/icon-loading-white.svg';
import IconPause from '@icons/general/video-control-pause.svg';
import IconPlay from '@icons/general/video-control-play.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { AudioRecordingResponse } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import Timer from '@utilities/Timer';
import classnames from 'classnames';
import { Recorder } from 'vmsg';

import AudioWaveform from '@components/Activity/Grader/Prompts/AudioWaveform';
import { isNumber } from '@components/Activity/Utils';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface RecordingPromptProps extends CommonPromptProps {
   response: AudioRecordingResponse;
   allowUploading: boolean;
   limitDuration: boolean;
   duration: number | null;
   limitAttempts: boolean;
   attempts: number | null;
   recordingDelay?: Maybe<number>;
   setDisableSubmit(disableSubmit: boolean): void;
   setResponse(response: AudioRecordingResponse, callback?: () => void): void;
}

interface RecordingContentState {
   audioLoaded: boolean;
   isPlaying: boolean;
   isDisabled: boolean;
   isRecording: boolean;
   recordingTime: number;
   recordingDelay: Maybe<number>;
   recordingInitializing: boolean;
}

class RecordingPrompt extends React.Component<RecordingPromptProps, RecordingContentState> {
   audio: Maybe<HTMLAudioElement>;
   recorder: Recorder;
   timer: Maybe<NodeJS.Timeout>;
   fileInput: Maybe<HTMLInputElement>;
   recordingDelayInterval: Maybe<NodeJS.Timeout>;

   constructor(props: RecordingPromptProps) {
      super(props);
      autobind(this);

      this.state = {
         audioLoaded: false,
         isDisabled: false,
         isPlaying: false,
         isRecording: false,
         recordingTime: 0,
         recordingDelay: null,
         recordingInitializing: false,
      };
      this.recorder = new Recorder({
         wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
      });
   }

   componentDidMount(): void {
      const { blob, fileUrl } = this.props.response;
      if (fileUrl) {
         this.initializeAudio(fileUrl);
      } else if (blob) {
         const src = window.URL.createObjectURL(blob);
         this.initializeAudio(src);
      }
   }

   componentDidUpdate(prevProps: RecordingPromptProps): void {
      if (
         (prevProps.recordingDelay === null || prevProps.recordingDelay === undefined) &&
         isNumber(this.props.recordingDelay)
      ) {
         this.initializeCountdown(this.props.recordingDelay);
      }
   }

   clearAudio(): void {
      const {
         response: { attempts },
         limitAttempts,
         attempts: attemptsAllowed,
      } = this.props;
      if (this.audio && !(limitAttempts && attempts === attemptsAllowed)) {
         this.audio.pause();
         this.props.setResponse({
            ...this.props.response,
            file: undefined,
            blob: undefined,
            fileUrl: null,
            storedFilename: null,
         });
         this.audio = null;
         this.setState({
            audioLoaded: false,
            recordingTime: 0,
         });
         if (this.fileInput) {
            this.fileInput.value = '';
         }
      }
   }

   handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (file) {
         this.props.setResponse(
            {
               ...this.props.response,
               blob: undefined,
               fileUrl: undefined,
               storedFilename: null,
               file,
            },
            this.props.saveResponse,
         );
         this.audio = null;
         this.setState({
            audioLoaded: false,
            recordingTime: 0,
         });
         const src = window.URL.createObjectURL(file);
         this.initializeAudio(src);
      }
   }

   getAnnotationAuthor(userId: number): BasicUserProfile {
      return this.props.response.annotationAuthors[userId];
   }

   async initializeRecorder(): Promise<void> {
      await this.recorder.initAudio();
      await this.recorder.initWorker();
   }

   startRecording(): void {
      this.setState({ recordingInitializing: true });
      const { limitDuration, duration } = this.props;
      if (this.recordingDelayInterval) {
         clearInterval(this.recordingDelayInterval);
         this.recordingDelayInterval = null;
      }
      this.initializeRecorder()
         .then(async () => {
            await this.recorder.startRecording();
            this.setState({
               isRecording: true,
               recordingInitializing: false,
               recordingDelay: null,
            });
            this.props.setDisableSubmit(true);

            const startTime = Date.now();
            this.timer = setInterval(() => {
               let recordingTime = Date.now() - startTime;
               if (limitDuration && duration !== null) {
                  recordingTime = Math.min(recordingTime, duration * 1000);
               }
               this.setState({ recordingTime });
               if (limitDuration && duration !== null && recordingTime >= duration * 1000) {
                  this.stopRecording();
               }
            }, 100);
         })
         .catch((error) => {
            this.setState({ recordingInitializing: false });
            // eslint-disable-next-line no-alert
            alert(
               'Unable to capture your microphone. Please ensure that microphone access is enabled.',
            );
            throw error;
         });
   }

   async stopRecording(): Promise<void> {
      const blob = await this.recorder.stopRecording();
      this.setState({ isRecording: false });
      if (this.timer) {
         clearInterval(this.timer);
         this.timer = null;
      }
      this.props.setResponse(
         {
            ...this.props.response,
            attempts: this.props.response.attempts + 1,
            blob,
         },
         this.props.saveResponse,
      );
      const blobURL = window.URL.createObjectURL(blob);
      this.initializeAudio(blobURL);
      this.props.setDisableSubmit(false);
   }

   initializeAudio(source: string): void {
      this.audio = new Audio(source);
      this.audio.oncanplaythrough = () => {
         this.setState({ audioLoaded: true });
      };
      this.audio.onended = () => {
         this.setState({ isPlaying: false });
      };
   }

   initializeCountdown(recordingDelay: number): void {
      this.setState({ recordingDelay }, () => {
         if (recordingDelay) {
            const timer = new Timer({
               duration: recordingDelay,
               callback: (i) => this.setState({ recordingDelay: i }),
               onCompletion: () => {
                  if (!this.state.isRecording) {
                     this.startRecording();
                  }
               },
            });
            this.recordingDelayInterval = timer.start();
         }
      });
   }

   renderIcon(): React.ReactNode {
      const { audioLoaded, isPlaying, recordingInitializing } = this.state;
      if (audioLoaded) {
         return isPlaying ? (
            <IconPause className='icon-white' />
         ) : (
            <IconPlay className='icon-white' />
         );
      }
      if (recordingInitializing) {
         return <IconBuilderLoading />;
      }
      return <IconBuilderRecording />;
   }

   renderMessage(): React.ReactNode {
      const { isClosed, limitDuration, duration, allowUploading } = this.props;
      const { audioLoaded, isDisabled, isRecording, recordingTime, recordingDelay } = this.state;
      if (isRecording) {
         const durationStr =
            duration !== null && limitDuration ? ` / ${formatSeconds(duration)}` : '';
         const recordingMessage = `Recording (${formatSeconds(
            recordingTime / 1000,
         )}${durationStr})`;
         return <p className='recording'>{recordingMessage}</p>;
      } else if (audioLoaded) {
         return <p>Response Recorded</p>;
      } else if (!(isClosed || isDisabled)) {
         if (recordingDelay !== null) {
            return <p>{`Recording in ${recordingDelay}`}</p>;
         } else {
            const upload = (
               <span>
                  {' '}
                  or{' '}
                  <span className='pointer hover-blue-text' onClick={() => this.fileInput?.click()}>
                     Upload
                  </span>
               </span>
            );
            return <p>Record {allowUploading && upload} Your Response </p>;
         }
      }
      return null;
   }

   togglePlaying(event: React.MouseEvent<HTMLDivElement>): void {
      if ((event.target as HTMLElement).tagName === 'SPAN') {
         return;
      }
      this.setState(
         (prevState) => ({ isPlaying: !prevState.isPlaying }),
         () => {
            this.state.isPlaying ? this.audio?.play() : this.audio?.pause();
         },
      );
   }

   toggleRecording(): void {
      const { isClosed } = this.props;
      const { isDisabled, isRecording, recordingInitializing } = this.state;
      if (recordingInitializing) {
         return;
      }
      if (isRecording) {
         this.stopRecording();
      } else if (!(isClosed || isDisabled)) {
         this.startRecording();
      }
   }

   render(): React.ReactNode {
      const {
         isClosed,
         response: { attempts, instructorAnnotations, fileUrl },
         allowUploading,
         limitAttempts,
         attempts: attemptsAllowed,
      } = this.props;
      const { audioLoaded, isDisabled, isRecording } = this.state;
      const icon = this.renderIcon();
      const message = this.renderMessage();
      const className = classnames('activity-item-icon', {
         disabled: isDisabled || isClosed,
         recording: isRecording,
      });
      const hasAnnotations = instructorAnnotations && !!instructorAnnotations.length;
      const canModify =
         !(isClosed || isDisabled) && !(limitAttempts && attempts === attemptsAllowed);

      return hasAnnotations && fileUrl ? (
         <AudioWaveform
            canEdit={false}
            audioFileSource={fileUrl}
            annotations={instructorAnnotations}
            getAuthor={this.getAnnotationAuthor}
         />
      ) : (
         <div className='activity-builder-audio'>
            <div className='audio-icon-wrapper'>
               <div
                  className={className}
                  onClick={audioLoaded ? this.togglePlaying : this.toggleRecording}
               >
                  {icon}
               </div>
               {audioLoaded && canModify && (
                  <Tippy delay={[800, 0]} content='Clear Recording'>
                     <IconClose className='clear-recording' onClick={this.clearAudio} />
                  </Tippy>
               )}
            </div>
            {message}
            {canModify && allowUploading && !isRecording && (
               <input
                  type='file'
                  accept='audio/*'
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange}
                  ref={(f) => (this.fileInput = f)}
                  disabled={!canModify}
               />
            )}
         </div>
      );
   }
}

export default RecordingPrompt;
