import * as React from 'react';

import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import Content, { ContentType } from '@models/Content';
import ContentFolder from '@models/ContentFolder';
import { Maybe } from '@models/Core';
import ContentService from '@services/ContentService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import AutosizeInput from 'react-18-input-autosize';

import { AppStateContext } from '../AppState';
import ContentSettingsMenu from './ContentSettingsMenu';

interface ContentBuilderHeaderProps {
   additionalIconActions?: readonly {
      tooltip: string;
      onClick(): void;
      icon: React.ReactNode;
      key: string;
   }[];
   additionalSettingsFields?: React.ReactNode;
   settings: Content;
   editSettings(update: Partial<Content>): void;
   removeContentImage(): void;
}

const ContentBuilderHeader: React.FC<ContentBuilderHeaderProps> = ({
   additionalIconActions = [],
   additionalSettingsFields,
   settings,
   editSettings,
   removeContentImage,
}) => {
   const [settingsMenuOpen, setSettingsMenuOpen] = React.useState<boolean>(false);
   const [isTyping, setIsTyping] = React.useState<boolean>(false);
   const [personalFolders, setPersonalFolders] = React.useState<readonly ContentFolder[]>([]);
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   React.useEffect(() => {
      ContentService.getPersonalFolders().then((folders) => setPersonalFolders(folders));
   }, []);

   React.useEffect(() => {
      if (isTyping) {
         document.addEventListener('mousemove', handleMouseMove);
      } else {
         document.removeEventListener('mousemove', handleMouseMove);
      }
   }, [isTyping]);

   React.useEffect(
      () => () => {
         document.removeEventListener('click', handleClick);
         document.removeEventListener('mousemove', handleMouseMove);
      },
      [],
   );

   const closeSettingsMenu = (): void => {
      setSettingsMenuOpen(false);
      document.removeEventListener('click', handleClick);
   };

   const openSettingsMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      setSettingsMenuOpen(true);
      document.addEventListener('click', handleClick);
   };

   const handleClick = (event: MouseEvent): void => {
      const target = event.target as Element;
      if (
         ['ignore-click', 'blanket'].some((i) => target?.classList?.contains(i)) ||
         target.closest('.modal')
      ) {
         return;
      }
      closeSettingsMenu();
   };

   const getDefaultTitle = (): Maybe<string> => {
      switch (settings.type) {
         case ContentType.activity:
            return 'Untitled Activity';
         case ContentType.lesson:
            return 'Untitled Lesson';
         case ContentType.vocabSet:
            return 'Untitled Set';
         case ContentType.video:
            return 'Untitled Video';
         default:
            console.error(`Unexpected content type ${settings.type}`);
            return undefined;
      }
   };

   const handleKeyDown = (): void => {
      setIsTyping(true);
   };

   const handleMouseMove = (): void => {
      setIsTyping(false);
   };

   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      editSettings({ name: event.target.value });
   };

   const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (event.target.value === getDefaultTitle()) {
         event.target.select();
      }
   };

   const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (!event.target.value.trim().length) {
         const defaultTitle = getDefaultTitle();
         if (defaultTitle) {
            event.target.value = defaultTitle;
            handleNameChange(event);
         }
      }
      setIsTyping(false);
      document.removeEventListener('mousemove', handleMouseMove);
   };

   return (
      <div className='content-title-wrapper'>
         <AutosizeInput
            data-tour='content-name'
            data-test='content-name'
            className='title-input content-title'
            value={settings.name ?? ''}
            type='text'
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
         />
         <div className='icon-action-wrap title-dropdown'>
            <div className='dropdown'>
               <div
                  data-toggle='dropdown'
                  className='dropdown-toggle'
                  aria-expanded={settingsMenuOpen}
               >
                  <Tippy content='Settings' disabled={isTyping || settingsMenuOpen}>
                     <div
                        className={classnames('icon-action', {
                           open: settingsMenuOpen,
                        })}
                        aria-label='Settings'
                        onClick={settingsMenuOpen ? closeSettingsMenu : openSettingsMenu}
                     >
                        <IconHighlight />
                     </div>
                  </Tippy>
               </div>
               {settingsMenuOpen && (
                  <ContentSettingsMenu
                     additionalFields={additionalSettingsFields}
                     className={settings.type}
                     personalFolders={personalFolders}
                     settings={settings}
                     showFolder={userProfile?.id === settings.createdBy}
                     editSettings={editSettings}
                     removeContentImage={removeContentImage}
                  />
               )}
            </div>
            {additionalIconActions.map((i) => (
               <Tippy content={i.tooltip} key={i.key}>
                  <div className='icon-action' onClick={i.onClick}>
                     {i.icon}
                  </div>
               </Tippy>
            ))}
         </div>
      </div>
   );
};

export default ContentBuilderHeader;
