import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { LessonContentDisplay } from '@models/Activity';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import LessonService from '@services/LessonService';
import { useNavigate } from 'react-router-dom';

import Constants from '../../../../Constants';
import Link from '@components/Common/Link';
import ContentIcon from '@components/ContentIcon';
import ModalDialog from '@components/Core/ModalDialog';
import StyleWrapper from '@components/Activity/StyleWrapper';

interface LessonContentProps {
   lessonId: Maybe<number>;
   display: LessonContentDisplay;
}

const LessonContent: React.FC<LessonContentProps> = ({ lessonId, display }) => {
   const {
      routes: {
         content: { viewLesson },
      },
   } = Constants;

   const navigate = useNavigate();

   const [viewContentModalOpen, setViewContentModalOpen] = React.useState<boolean>(false);
   const [name, setName] = React.useState<string>('');
   const [content, setContent] = React.useState<string>('');
   const [customStylesId, setCustomStylesId] = React.useState<Maybe<number>>(null);

   if (!lessonId) {
      return null;
   }

   const launchLesson = (): void => {
      if (display === LessonContentDisplay.overlay) {
         setViewContentModalOpen((prevViewContentModalOpen) => !prevViewContentModalOpen);
      } else if (display === LessonContentDisplay.link) {
         navigate(viewLesson.replace(':lessonId', lessonId.toString()));
      }
   };

   React.useEffect(() => {
      if (lessonId) {
         LessonService.fetchLesson(lessonId).then(({ lesson }) => {
            setName(lesson.name);
            setContent(lesson.content);
            if (lesson.styleSheetId !== null) {
               setCustomStylesId(lesson.styleSheetId);
            }
         });
      } else {
         setName('');
         setContent('');
      }
   }, [lessonId]);

   if (!content) {
      return null;
   }

   return (
      <div className='activity-builder-row'>
         {display === LessonContentDisplay.inline ? (
            <StyleWrapper styleId={customStylesId}>{parseHtml(content)}</StyleWrapper>
         ) : (
            <>
               <div className='activity-builder-lesson'>
                  <div className='content-icon-wrapper'>
                     <div className='activity-item-icon' role='button' onClick={launchLesson}>
                        <ContentIcon itemType={ContentType.lesson} line />
                     </div>
                  </div>
                  {display === LessonContentDisplay.link && (
                     <Link
                        className='lesson-name'
                        to={viewLesson.replace(':lessonId', lessonId.toString())}
                     >
                        {name}
                     </Link>
                  )}
                  {display === LessonContentDisplay.overlay && (
                     <p onClick={launchLesson} className='lesson-name'>
                        {name}
                     </p>
                  )}
               </div>
               {viewContentModalOpen && (
                  <ModalDialog
                     appearance={Appearance.primary}
                     heading={name}
                     width='xl'
                     onClose={launchLesson}
                     bodyClassName='lesson-content-modal'
                  >
                     <StyleWrapper styleId={customStylesId}> {parseHtml(content)} </StyleWrapper>
                  </ModalDialog>
               )}
            </>
         )}
      </div>
   );
};

export default LessonContent;
