import * as _ from 'lodash';
import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import { IApplicationState, PostLoginRedirect } from '@models/ApplicationState';
import { Maybe } from '@models/Core';
import UserService from '@services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import { actionFunctions } from '@redux/Actions';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';

const TokenLogin: React.FC<Record<string, never>> = () => {
   const {
      routes: {
         courses: { dashboard: courseDashboard },
         auth: { tokenLoginFailure },
      },
   } = Constants;
   const { loggedIn } = React.useContext<AppStateContext>(AppStateContext);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const isInIFrame = window.location !== window.parent.location;
   const postLoginRedirect = useSelector<IApplicationState, Maybe<PostLoginRedirect>>(
      (state) => state.postLoginRedirect,
   );

   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();

   React.useEffect(() => {
      // If the user is already logged in redirect them to application root.
      if (loggedIn) {
         navigate('/');
      } else {
         const token = getQueryParameterByName(location, 'token');
         if (!token) {
            navigate(tokenLoginFailure, { replace: true });
            return;
         }
         // If this is in an iframe we just put the token url into the buttons link so they'll be logged in
         // after clicking the launch button in a new browser tab.
         if (!isInIFrame) {
            setIsLoading(true);
            UserService.tokenLogin(token)
               .then((responseCourseId) => {
                  setIsLoading(false);
                  navigate(getRedirectUrl(responseCourseId), { replace: true });
                  dispatch(actionFunctions.clearPostLoginRedirect());
               })
               .catch((tokenError) => {
                  const errorCode = tokenError.response.data.error;
                  const errorUrl = `${tokenLoginFailure}${errorCode ? `?error=${errorCode}` : ''}`;
                  navigate(errorUrl, { replace: true });
               });

            return () => UserService.deleteLoginRedirectPath();
         }
      }
   }, [history, location]);

   const getRedirectUrl = (redirectCourseId: Maybe<number>): string => {
      const now = new Date();
      const usePostLoginRedirect =
         postLoginRedirect &&
         typeof postLoginRedirect.expiration === 'string' &&
         now < new Date(postLoginRedirect.expiration);
      if (usePostLoginRedirect) {
         return postLoginRedirect.path;
      } else if (redirectCourseId) {
         return courseDashboard.replace(':courseId', redirectCourseId.toString());
      }

      return UserService.getLoginRedirectPath();
   };

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  {isLoading && <Loader />}
                  {isInIFrame && (
                     <div className='card-content-onboarding card padding-bottom-m'>
                        <div className='onboarding-title border-bottom text-center'>
                           <DocumentTitle>Launch Lingco</DocumentTitle>
                           <h2 aria-live='polite' className='uppercase no-margin'>
                              Launch Lingco
                           </h2>
                        </div>
                        <div className='text-center'>
                           {/* Please leave this 'a' tag this way unless you test Canvas and Schoology LTI apps. 
                           In Schoology something happens when the button is clicked and the route changes away
                           from this page and the Launch button is not longer present. When we use an 'a' tag 
                           directly this does not happen. */}
                           <Link
                              external
                              className='btn full-width'
                              to={window.location.toString()}
                              rel='noopener, noreferrer'
                              target='_blank'
                           >
                              Launch
                           </Link>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default TokenLogin;
