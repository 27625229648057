import * as _ from 'lodash';
import * as React from 'react';

import { IdName } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { OptionsCallback, StringOption } from '@models/ReactSelectHelperTypes';
import { FieldPath, FieldValues } from 'react-hook-form';
import { ActionMeta, GroupBase, StylesConfig, Theme } from 'react-select';

import AsyncSelect from 'react-select/async';

type TypeAheadRowLoader<T> = (filters: PagedSearchFilters) => Promise<PagedResponse<T>>;
type LabelContent<T> = (row: T) => string;

type SelectTypeAheadProps<T, TFieldValues extends FieldValues = FieldValues> = {
   autoFocus?: boolean;
   fieldName?: FieldPath<TFieldValues>;
   placeHolder: string;
   selectedOptions?: readonly StringOption[];
   labelContent?: LabelContent<T & IdName>;
   rowLoader: TypeAheadRowLoader<T & IdName>;
   setSelectedOptions?(option: readonly StringOption[]): void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiSelectTypeAhead: React.FC<SelectTypeAheadProps<any, any>> = (props) => {
   const loadOptions: (newInputValue: string, callback: OptionsCallback) => void = (
      newInputValue,
      callback,
   ) => {
      if (newInputValue.length < 1) {
         callback([]);
         return;
      }

      props
         .rowLoader({
            page: 1,
            predicate: null,
            query: newInputValue,
         })
         .then((response) => {
            callback(
               response.rows.map((x) => ({
                  value: x.id.toString(),
                  label: props.labelContent ? props.labelContent(x) : x.name,
               })),
            );
         });
   };

   const debouncedLoadOptions = React.useCallback(_.debounce(loadOptions, 500), []);

   const handleChange = (
      options: readonly StringOption[],
      actionMeta: ActionMeta<StringOption>,
   ): void => {
      if (props.setSelectedOptions) {
         if (
            actionMeta.action === 'select-option' ||
            actionMeta.action === 'clear' ||
            actionMeta.action === 'remove-value'
         ) {
            props.setSelectedOptions(options);
         }
      }
   };

   const customStyles: StylesConfig<StringOption, true, GroupBase<StringOption>> = {
      control: (styles) => ({
         ...styles,
         borderRadius: '3px',
      }),
      menu: (provided) => ({
         ...provided,
         zIndex: '2', // Needed for the activity builder. Part of the menu was not out front.
      }),
      menuPortal: (provided) => ({
         ...provided,
         zIndex: '9999',
      }),
      multiValue: (provided) => ({
         ...provided,
         borderBottomLeftRadius: '20px',
         borderBottomRightRadius: '20px',
         borderTopLeftRadius: '20px',
         borderTopRightRadius: '20px',
         padding: '4px 8px',
      }),
      multiValueRemove: (provided) => ({
         ...provided,
         color: '#807f86',
         ':hover': {
            background: 'none',
            color: 'white',
         },
      }),
      placeholder: (styles) => ({
         ...styles,
         fontWeight: 400,
         fontFamily: 'Karla',
         color: '#807F86',
      }),
      option: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
      }),
      singleValue: (styles) => ({
         ...styles,
         fontFamily: 'Karla',
         color: '#413f48',
      }),
   };

   const customTheme: (theme: Theme) => Theme = (theme) => ({
      ...theme,
      borderRadius: 0,
      colors: {
         ...theme.colors,
         primary: '#007cbb',
         neutral20: '#e7e7e7',
      },
   });

   return (
      <AsyncSelect
         autoFocus={props.autoFocus}
         cacheOptions
         className='react-select'
         defaultOptions
         isClearable
         isMulti
         loadOptions={debouncedLoadOptions}
         menuPortalTarget={document.body}
         menuPosition='fixed'
         menuShouldScrollIntoView={false}
         onChange={handleChange}
         placeholder={props.placeHolder}
         styles={customStyles}
         theme={customTheme}
         value={props.selectedOptions}
      />
   );
};

export default MultiSelectTypeAhead;
