import * as _ from 'lodash';

import ModalDialog from '@components/Core/ModalDialog';
import IconLeisureMask from '@icons/nova-line/80-Leisure/leisure-mask.svg';
import Appearance from '@models/Appearance';
import { ID } from '@models/Core';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';

type Props = {
   userId: ID;
   userFullName: string;
};

const ImpersonateUserButton: React.FC<Props> = (props) => {
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = React.useState<boolean>();
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const disabled = String(userProfile?.id) === String(props.userId);

   const [isImpersonateUserModalOpen, setIsImpersonateUserModalOpen] =
      React.useState<boolean>(false);

   const handleImpersonateUser = async (): Promise<void> => {
      setIsLoading(true);
      await UserService.impersonateUser(props.userId);
      setIsImpersonateUserModalOpen(false);
      navigate('/');
      setIsLoading(false);
   };

   const openImpersonateUserModal = (): void => {
      if (!disabled) {
         setIsImpersonateUserModalOpen(true);
      }
   };

   const closeImpersonateUserModal = (): void => {
      setIsImpersonateUserModalOpen(false);
   };

   return (
      <>
         <Tippy content='Impersonate' disabled={disabled} delay={[400, 0]}>
            <div
               className={classnames('icon-action', { disabled })}
               onClick={openImpersonateUserModal}
            >
               <IconLeisureMask />
            </div>
         </Tippy>
         {isImpersonateUserModalOpen && (
            <ModalDialog
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.warning}
               heading={`Impersonate ${props.userFullName}`}
               onClose={closeImpersonateUserModal}
               actions={[
                  {
                     text: 'Impersonate',
                     onClick: handleImpersonateUser,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closeImpersonateUserModal },
               ]}
            >
               <p>
                  "Impersonating" is essentially logging in as this user without a password. You
                  will be able to take any action as if you were this user, and from other users'
                  points of views, it will be as if this user performed them. However, audit logs
                  record that you were the one who performed the actions on behalf of this user.
               </p>
            </ModalDialog>
         )}
      </>
   );
};

export default ImpersonateUserButton;
