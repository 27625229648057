import * as React from 'react';

import IconInterfaceInformation from '@icons/nova-solid/22-Interface-Feedback/interface-information.svg';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

interface IInfoTooltipProps {
   animation?: 'shift-toward' | 'fade' | 'scale' | 'perspective' | 'shift-away';
   className?: string;
   children: React.ReactNode;
}

const InfoTooltip: React.FC<IInfoTooltipProps> = ({ animation = 'fade', children, className }) => (
   <Tippy content={children} animation={animation}>
      <span className={classnames('info-tooltip', className)}>
         <IconInterfaceInformation aria-label='Info' />
      </span>
   </Tippy>
);

export default InfoTooltip;
