import * as React from 'react';

import { UrlBuilder } from '@helpers/QueryParameter';
import IntercomService from '@services/IntercomService';
import { RegistrationError, RegistrationErrorName } from '@services/RegistrationService';

import Constants from '../../Constants';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';

interface RegisterErrorProps {
   errors: readonly RegistrationError[];
}

const RegisterError: React.FC<RegisterErrorProps> = ({ errors }) => {
   const {
      chatMessages: { registrationError },
   } = Constants;

   const error = errors.find((i) =>
      [
         RegistrationErrorName.apiError,
         RegistrationErrorName.expiredGoogleAuth,
         RegistrationErrorName.invalidCourseCode,
         RegistrationErrorName.invalidCourseCode,
      ].includes(i.name),
   );

   if (!error) {
      return null;
   }

   const openCustomerSupportMessage = (): void => {
      IntercomService.startChat(registrationError);
   };

   const renderCustomErrorMessage = (): React.ReactNode => {
      if (error?.name === RegistrationErrorName.expiredGoogleAuth) {
         const urlBuilder = new UrlBuilder(
            `${window.location.origin}/api/auth/google/auth_request_uri`,
         );
         urlBuilder.addParam('action', 'signup');
         return (
            <>
               <p className='error'>{error.message}</p>
               <Link className='btn full-width margin-top-m' to={urlBuilder.fullUrl}>
                  Try Again
               </Link>
            </>
         );
      } else {
         return <div className='alert alert-danger margin-top-m'>{error?.message}</div>;
      }
   };

   return (
      <>
         {error?.message ? (
            renderCustomErrorMessage()
         ) : (
            <div className='alert alert-danger margin-top-m'>
               We ran into a problem setting up your account.{' '}
               <Button textLink className='inline-flex' onClick={openCustomerSupportMessage}>
                  Click here
               </Button>{' '}
               to let our team know.
            </div>
         )}
      </>
   );
};

export default RegisterError;
