import * as React from 'react';

import Button from '@components/Common/Button';
import indexDeep from '@helpers/IndexDeep';
import { randomTempId } from '@helpers/RandomStringUtils';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import { ActivityBuilderMode } from '@models/Activity/ActivityMode';
import {
   GroupingPrompt as GroupingPromptType,
   GroupingPromptCategory,
   GroupingPromptItem,
} from '@models/Activity/ActivityPrompt';
import Language from '@models/Language';
import {
   DragDropContext,
   DraggableLocation,
   Droppable,
   DroppableProvided,
   DropResult,
} from 'react-beautiful-dnd';

import GroupingPromptCategoryComponent from './GroupingPromptCategory';

const MIN_CATEGORY_COUNT = 2;
const MAX_CATEGORY_COUNT = 5;
const DEFAULT_CATEGORY_COUNT = 2;

const reorder = <T,>(list: readonly T[], startIndex: number, endIndex: number): readonly T[] => {
   const result = Array.from(list);
   const [removed] = result.splice(startIndex, 1);
   result.splice(endIndex, 0, removed);
   return result;
};

interface GroupingPromptProps {
   language: Language;
   categories: readonly GroupingPromptCategory[];
   items: readonly GroupingPromptItem[];
   onUpdate(update: Partial<GroupingPromptType<ActivityBuilderMode>>): void;
}

const GroupingPrompt: React.FC<GroupingPromptProps> = ({
   categories = [],
   items = [],
   onUpdate,
}) => {
   const canDelete = categories.length > MIN_CATEGORY_COUNT;

   const generateCategory = (index: number): GroupingPromptCategory => ({
      id: randomTempId(),
      index,
      name: `Group ${index + 1}`,
   });

   React.useEffect(() => {
      if (!categories.length) {
         const defaultCategories = [...Array(DEFAULT_CATEGORY_COUNT).keys()].map(generateCategory);
         indexDeep(defaultCategories);
         onUpdate({ categories: defaultCategories });
      }
   }, []);

   const addCategory = (): void => {
      onUpdate({
         categories: [...categories, generateCategory(categories.length)],
      });
   };

   const removeCategory = (categoryId: string | number): void => {
      onUpdate({
         categories: categories.filter((i) => i.id !== categoryId),
         items: items.filter((i) => i.categoryId !== categoryId),
      });
   };

   const editCategory = (
      categoryId: string | number,
      update: Partial<GroupingPromptCategory>,
   ): void => {
      onUpdate({
         categories: categories.map((i) => (i.id === categoryId ? { ...i, ...update } : i)),
      });
   };

   const setItems = (
      categoryId: string | number,
      categoryItems: readonly GroupingPromptItem[] = [],
   ): void => {
      const updatedItems = [...items.filter((i) => i.categoryId !== categoryId), ...categoryItems];
      onUpdate({ items: updatedItems });
   };

   const reorderItems = (
      source: DraggableLocation,
      destination: DraggableLocation,
   ): readonly GroupingPromptItem[] => {
      const itemsByCategory: Record<string, readonly GroupingPromptItem[]> = categories.reduce(
         (ac, { id }) => ({
            ...ac,
            [id]: items.filter((i) => i.categoryId === id),
         }),
         {},
      );

      const current = [...itemsByCategory[source.droppableId]];
      const next = [...itemsByCategory[destination.droppableId]];
      const target = current[source.index];

      // moving to same list
      if (source.droppableId === destination.droppableId) {
         const reordered: readonly GroupingPromptItem[] = reorder(
            current,
            source.index,
            destination.index,
         );
         itemsByCategory[source.droppableId] = [...reordered];
         Object.keys(itemsByCategory).forEach((i) => indexDeep(itemsByCategory[i]));
         return Object.values(itemsByCategory).flat();
      }

      // moving to different list

      // remove from original
      current.splice(source.index, 1);

      // insert into next
      next.splice(destination.index, 0, {
         ...target,
         categoryId: destination.droppableId,
      });

      itemsByCategory[source.droppableId] = [...current];
      itemsByCategory[destination.droppableId] = [...next];
      Object.keys(itemsByCategory).forEach((i) => indexDeep(itemsByCategory[i]));
      return Object.values(itemsByCategory).flat();
   };

   const handleDragEnd = (result: DropResult): void => {
      if (!result.destination) {
         return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      // did not move anywhere - can bail early
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
         return;
      }

      // reordering column
      if (result.type === 'COLUMN') {
         const updatedCategories = reorder(categories, source.index, destination.index);
         onUpdate({ categories: updatedCategories });
      } else {
         const updatedItems = reorderItems(source, destination);
         onUpdate({ items: updatedItems });
      }
   };

   return (
      <div className='dnd-prompt-drag-context no-drag'>
         <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='board' type='COLUMN' direction='horizontal'>
               {(columnDropProvided: DroppableProvided) => (
                  <div
                     className='dnd-prompt-container'
                     ref={columnDropProvided.innerRef}
                     {...columnDropProvided.droppableProps}
                  >
                     {categories.map((category, index) => (
                        <GroupingPromptCategoryComponent
                           key={category.id}
                           category={category}
                           index={index}
                           items={items.filter((i) => i.categoryId === category.id)}
                           editCategory={(update) => editCategory(category.id, update)}
                           removeCategory={() => canDelete && removeCategory(category.id)}
                           setItems={(categoryItems) => setItems(category.id, categoryItems)}
                        />
                     ))}
                     {columnDropProvided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
         {categories.length < MAX_CATEGORY_COUNT && (
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <Button
                     line
                     fullWidth
                     className='margin-top-s'
                     onClick={addCategory}
                     icon={<IconAddSmall aria-hidden />}
                  >
                     Add Group
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};

export default GroupingPrompt;
