enum LMSName {
   blackboard = 'blackboard',
   brightspace = 'brightspace',
   canvas = 'canvas',
   googleClassroom = 'google-classroom',
   microsoftTeams = 'microsoft-teams',
   moodle = 'moodle',
   schoology = 'schoology',
}

export default LMSName;
