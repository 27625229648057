import { Maybe } from '@models/Core';
import Language from '@models/Language';

export enum ExampleLayout {
   horizontal = 'horizontal',
   vertical = 'vertical',
}

export interface Example {
   imgSrc: string;
   primaryText: string;
   secondaryText: string;
}

export interface Examples {
   language: Maybe<Language>;
   layout: ExampleLayout;
   examples: readonly Example[];
}
