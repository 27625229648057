import * as React from 'react';

import { BaseItemProps } from '@components/Activity/Builder/Question';
import Link from '@components/Common/Link';
import { formatSeconds } from '@helpers/FormatTime';
import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';
import IconPause from '@icons/general/video-control-pause.svg';
import IconPlay from '@icons/general/video-control-play.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconDataDownload8 from '@icons/nova-line/23-Data-Transfer/data-download-8.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { ActivityBuilderMode, AudioContent as AudioContentType } from '@models/Activity';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { Recorder } from 'vmsg';

import AudioContentOptions from './AudioContentOptions';

interface AudioContentProps extends BaseItemProps {
   item: AudioContentType<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<AudioContentType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const AudioContent: React.FC<AudioContentProps> = ({
   item,
   duplicateItem,
   handleItemUpdate,
   removeItem,
}) => {
   const [audioLoaded, setAudioLoaded] = React.useState(false);
   const [audioBlobUrl, setAudioBlobUrl] = React.useState('');
   const [isPlaying, setIsPlaying] = React.useState(false);
   const [isRecording, setIsRecording] = React.useState(false);
   const [recordingTime, setRecordingTime] = React.useState(0);
   const [fileName, setFileName] = React.useState<string>('');

   const audioRef = React.useRef<Maybe<HTMLAudioElement>>(null);
   const fileInputRef = React.useRef<Maybe<HTMLInputElement>>(null);
   const recorderRef = React.useRef<Recorder>(
      new Recorder({
         wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
      }),
   );
   const timerRef = React.useRef<NodeJS.Timeout | null>(null);

   React.useEffect(() => {
      if (item.fileUrl) {
         initializeAudio(item.fileUrl);
      } else if (item.blob && item.blob instanceof Blob) {
         const src = window.URL.createObjectURL(item.blob);
         initializeAudio(src);
      }
   }, [item]);

   const clearAudio = (): void => {
      if (audioRef.current) {
         audioRef.current.pause();
         handleItemUpdate({
            file: undefined,
            blob: undefined,
            fileUrl: null,
            storedFilename: null,
         });
         audioRef.current = null;
         setAudioLoaded(false);
         setAudioBlobUrl('');
         setRecordingTime(0);
         if (fileInputRef.current) {
            fileInputRef.current.value = '';
         }
      }
   };

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (file) {
         handleItemUpdate({
            blob: undefined,
            fileUrl: null,
            storedFilename: null,
            file,
         });
         audioRef.current = null;
         setFileName(file.name);
         setAudioLoaded(false);
         setRecordingTime(0);
         const src = window.URL.createObjectURL(file);
         setAudioBlobUrl(src);
         initializeAudio(src);
      }
   };

   const initializeRecorder = async (): Promise<void> => {
      await recorderRef.current.initAudio();
      await recorderRef.current.initWorker();
   };

   const stopRecording = async (): Promise<void> => {
      const blob = await recorderRef.current.stopRecording();
      setIsRecording(false);
      if (timerRef.current) {
         clearInterval(timerRef.current);
      }
      handleItemUpdate({ blob });
      const blobURL = window.URL.createObjectURL(blob);
      initializeAudio(blobURL);
   };

   const initializeAudio = (source: string): void => {
      audioRef.current = new Audio(source);
      audioRef.current.oncanplaythrough = () => {
         setAudioLoaded(true);
      };
      audioRef.current.onended = () => {
         setIsPlaying(false);
      };
      if (item.blob) {
         setAudioBlobUrl(source);
      } else if (item.fileUrl) {
         fetch(item.fileUrl)
            .then((i) => i.blob())
            .then((i) => {
               setAudioBlobUrl(URL.createObjectURL(i));
            });
      }
   };

   const togglePlaying = (event: React.MouseEvent<HTMLDivElement>): void => {
      if ((event.target as HTMLElement).tagName === 'SPAN') {
         return;
      }
      if (audioRef.current) {
         if (isPlaying) {
            audioRef.current.pause();
         } else {
            audioRef.current.play();
         }
         setIsPlaying((prevIsPlaying) => !prevIsPlaying);
      }
   };

   const toggleRecording = (): void => {
      if (isRecording) {
         stopRecording();
      } else {
         initializeRecorder()
            .then(async () => {
               await recorderRef.current.startRecording();
               setIsRecording(true);
               const startTime = Date.now();
               timerRef.current = setInterval(() => setRecordingTime(Date.now() - startTime), 100);
            })
            .catch((error) => {
               alert(
                  'Unable to capture your microphone. Please ensure that microphone access is enabled.',
               );
               throw error;
            });
      }
   };

   const getIcon = (): React.ReactNode => {
      if (audioLoaded) {
         return isPlaying ? (
            <IconPause className='icon-white' aria-label='Pause' />
         ) : (
            <IconPlay className='icon-white' aria-label='Play' />
         );
      }
      return <IconBuilderRecording />;
   };

   const getMessage = (): React.ReactNode => {
      if (isRecording) {
         const recordingMessage = `Recording (${formatSeconds(recordingTime / 1000)})`;
         return <span className='margin-left-s recording'>{recordingMessage}</span>;
      } else if (audioLoaded) {
         return <span className='margin-left-s'>{fileName ? fileName : 'Audio Recorded'}</span>;
      } else {
         const upload = (
            <span className='pointer hover-blue-text' onClick={() => fileInputRef.current?.click()}>
               Upload
            </span>
         );
         return <span className='margin-left-s'>Record or {upload}</span>;
      }
   };

   return (
      <div className='activity-builder-row'>
         <div className='align-items-center'>
            <div className='flex-grow-1'>
               <div className='activity-builder-audio no-padding-left'>
                  <div className='audio-icon-wrapper'>
                     <div
                        className={classnames('activity-item-icon', {
                           recording: isRecording,
                        })}
                        onClick={audioLoaded ? togglePlaying : toggleRecording}
                     >
                        {getIcon()}
                     </div>
                     {audioLoaded && (
                        <IconClose
                           onClick={clearAudio}
                           aria-label='Clear Audio'
                           className='clear-recording'
                        />
                     )}
                  </div>
                  {getMessage()}
                  {audioBlobUrl && !isRecording && (
                     <div className='audio-download'>
                        <Link external to={audioBlobUrl} download='audio.mp3'>
                           <Tippy delay={[1000, 0]} content='Download Audio'>
                              <span>
                                 <IconDataDownload8 aria-label='Download Audio' />
                              </span>
                           </Tippy>
                        </Link>
                     </div>
                  )}
                  {!isRecording && (
                     <input
                        type='file'
                        accept='audio/mp3'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={(f) => (fileInputRef.current = f)}
                     />
                  )}
               </div>
            </div>
            <div className='flex-end margin-left-s'>
               <div className='weight-actions-wrapper'>
                  <div className='icon-action-wrap'>
                     <AudioContentOptions item={item} handleItemUpdate={handleItemUpdate} />
                     <Tippy content='Duplicate Item' delay={[500, 0]}>
                        <div
                           aria-label='Duplicate Item'
                           className='icon-action'
                           onClick={() => duplicateItem()}
                           onKeyDown={(e) => e.key === 'Enter' && duplicateItem()}
                           role='button'
                           tabIndex={0}
                        >
                           <IconFileCopy />
                        </div>
                     </Tippy>
                     <Tippy content='Delete Item' delay={[500, 0]}>
                        <div
                           aria-label='Delete Item'
                           className='icon-action'
                           onClick={() => removeItem()}
                           onKeyDown={(e) => e.key === 'Enter' && removeItem()}
                           role='button'
                           tabIndex={0}
                        >
                           <IconBin aria-hidden />
                        </div>
                     </Tippy>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AudioContent;
