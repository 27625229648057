// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import ContentIcon from '@components/ContentIcon';
import Droplist from '@components/Core/Droplist';
import EmptyState from '@components/Core/EmptyState';
import DocumentTitle from '@components/DocumentTitle';
import InfiniteScroll from '@components/InfiniteScroll';
import Loader from '@components/Loader';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import useCommands from '@hooks/use-commands';
import useDebounce from '@hooks/use-debounce';
import IconFolders from '@icons/nova-line/86-Files-Folders/folders.svg';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import { Activity, ActivityMode } from '@models/Activity';
import Appearance from '@models/Appearance';
import { ContentType } from '@models/Content';
import ContentFolder from '@models/ContentFolder';
import ContentItemProfile, { GlobalContentItemProfile } from '@models/ContentItemProfile';
import { ContentLibrary as ContentLibraryType } from '@models/ContentLibrary';
import ContentLibraryFilters from '@models/ContentLibraryFilters';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import ContentLibraryName from '@models/ContentLibraryName';
import { IdMessageResponse, Maybe, MessageResponse } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import ContentService from '@services/ContentService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import naturalSort from '@utilities/NaturalSort';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import ContentFolderTile from './ContentFolderTile';
import { ContentLibraryProvider } from './ContentLibraryContext';
import ContentLibraryHeader from './ContentLibraryHeader';
import ContentLibrarySidebar from './ContentLibrarySidebar';
import ContentTile from './ContentTile';
import NewContentFolderModal from './NewContentFolderModal';

const INFINITE_SCROLL_THRESHOLD = 234 * 2; // height of two rows

const isPagedLibrary = (
   libraryName: ContentLibraryName,
   library: ContentLibraryType | PagedResponse<ContentItemProfile>,
): library is PagedResponse<ContentItemProfile> =>
   [ContentLibraryName.school, ContentLibraryName.global].includes(libraryName) && library !== null;

const isNonPagedLibrary = (
   libraryName: ContentLibraryName,
   library: ContentLibraryType | PagedResponse<ContentItemProfile>,
): library is ContentLibraryType =>
   [ContentLibraryName.personal, ContentLibraryName.shared].includes(libraryName) &&
   library !== null;

const ContentLibrary: React.FC = () => {
   const {
      routes: {
         content: { newActivity, newLesson, bulkVideoUpload, newVocabSet },
         contentLibrary: { folder: folderRoute, library: libraryRoute },
      },
   } = Constants;

   const navigate = useNavigate();
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const filterableContentTypes: ContentType[] = [
      ContentType.activity,
      ContentType.lesson,
      ContentType.vocabSet,
   ];

   const {
      contentLibraryLayout: layoutView = ContentLibraryLayout.grid,
      userProfile: { language, id: userId },
      schoolProfile: { id: organizationId },
      dispatchToast,
      setContentLibraryLayout,
      setBreadcrumbs: contextSetBreadcrumbs,
   } = React.useContext<AppStateContext>(AppStateContext);

   const { stepId, startWalkthrough, walkthrough, taskState } =
      React.useContext<IOnboardingProps>(OnboardingContext);

   const { libraryName: activeLibrary = null, folderId: strFolderId } = useParams<{
      libraryName: ContentLibraryName;
      folderId: string;
   }>();
   const activeFolderId = isInteger(strFolderId) ? Number(strFolderId) : null;

   const [canImportActivityJSON, setCanImportActivityJSON] = React.useState<boolean>(false);
   const [canCreateVideos, setCanCreateVideos] = React.useState<boolean>(false);
   const [isOnboarding, setIsOnboarding] = React.useState<boolean>(false);
   const [isImporting, setIsImporting] = React.useState<boolean>(false);
   const [isCreatingNewFolder, setIsCreatingNewFolder] = React.useState<boolean>(false);
   const [filters, setFilters] = React.useState<ContentLibraryFilters>({
      searchQuery: '',
      languages: [language],
      contentTypes: [],
      proficiencyLevels: [],
      proficiencySkills: [],
   });
   const debouncedFilters = useDebounce(filters, 500);
   const [libraries, setLibraries] = React.useState<
      Maybe<{
         [key in ContentLibraryName]:
            | ContentLibraryType
            | PagedResponse<ContentItemProfile>
            | PagedResponse<GlobalContentItemProfile>;
      }>
   >(null);

   const canCreate = activeLibrary === ContentLibraryName.personal;

   useCommands(
      [
         {
            id: 'create_folder',
            title: 'Create Folder',
            showIf: () => activeLibrary === ContentLibraryName.personal,
            action: () => setIsCreatingNewFolder(true),
         },
      ],
      [activeLibrary],
   );

   const filterLibraryItems = (data: ContentLibraryType) => {
      if (filters.searchQuery) {
         data.folders = data.folders.filter((item) =>
            item.name.toLowerCase().includes(filters.searchQuery.toLowerCase()),
         );

         data.rows = data.rows.filter((item) =>
            item.itemName.toLowerCase().includes(filters.searchQuery.toLowerCase()),
         );
      }

      return data;
   };

   const fetchPersonalContent = (): Promise<void> =>
      ContentService.getPersonalContent().then((data) => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [ContentLibraryName.personal]: { ...filterLibraryItems(data) },
         }));
      });

   const mergePages = (
      prev: PagedResponse<ContentItemProfile>,
      next: PagedResponse<ContentItemProfile>,
   ): PagedResponse<ContentItemProfile> => ({
      ...prev,
      ...next,
      rows: [...prev.rows, ...next.rows],
   });

   const fetchSchoolContent = (
      additionalFilters: Partial<ContentLibraryFilters> = {},
   ): Promise<void> => {
      const mergedFilters = {
         ...filters,
         ...additionalFilters,
         contentTypes:
            filters.contentTypes.length > 0
               ? filters.contentTypes.filter((x) => x !== ContentType.video)
               : filterableContentTypes,
      };
      return ContentService.getSchoolContent(organizationId, mergedFilters).then((data) => {
         if (mergedFilters?.page > 1) {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.school]: mergePages(
                  prevLibraries[ContentLibraryName.school] as PagedResponse<ContentItemProfile>,
                  data,
               ),
            }));
         } else {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [ContentLibraryName.school]: { ...data },
            }));
         }
      });
   };

   const fetchSharedContent = (): Promise<void> =>
      ContentService.getSharedContent().then((data) => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [ContentLibraryName.shared]: { ...filterLibraryItems(data) },
         }));
      });

   const fetchGlobalContent = (
      additionalFilters: Partial<ContentLibraryFilters> = {},
   ): Promise<void> => {
      if (userProfile.permissions.canViewGlobalLibrary) {
         const mergedFilters = {
            ...filters,
            ...additionalFilters,
            contentTypes:
               filters.contentTypes.length > 0
                  ? filters.contentTypes.filter((x) => x !== ContentType.video)
                  : filterableContentTypes,
         };
         return ContentService.getGlobalContent(mergedFilters).then((data) => {
            if (mergedFilters?.page > 1) {
               setLibraries((prevLibraries) => ({
                  ...prevLibraries,
                  [ContentLibraryName.global]: mergePages(
                     prevLibraries[
                        ContentLibraryName.global
                     ] as PagedResponse<GlobalContentItemProfile>,
                     data,
                  ),
               }));
            } else {
               setLibraries((prevLibraries) => ({
                  ...prevLibraries,
                  [ContentLibraryName.global]: { ...data },
               }));
            }
         });
      }
   };

   const fetchMoreContent = (): Promise<void> => {
      const library = libraries?.[activeLibrary];
      if (isPagedLibrary(activeLibrary, library)) {
         const additionalFilters = {
            page: library.currentPageNumber > 0 ? library.currentPageNumber + 1 : 1,
         };
         if (activeLibrary === ContentLibraryName.school) {
            return fetchSchoolContent({
               ...additionalFilters,
            });
         } else if (activeLibrary === ContentLibraryName.global) {
            return fetchGlobalContent({
               ...additionalFilters,
            });
         }
      }
      return Promise.resolve();
   };

   const fetchContent = (): Promise<readonly void[]> =>
      Promise.all([
         fetchPersonalContent(),
         fetchSchoolContent({}),
         fetchSharedContent(),
         fetchGlobalContent({}),
      ]);

   const setBreadcrumbs = (): void => {
      const breadcrumbs = [];
      let folder = folders.find((i) => i.id === activeFolderId);
      while (folder !== undefined) {
         const currentFolderId = folder.id;
         breadcrumbs.push({
            link: folderRoute
               .replace(':libraryName', activeLibrary)
               .replace(':folderId', currentFolderId.toString()),
            text: folder.name,
         });
         folder = folders.find((i) => i.id === folder.parentId);
      }
      breadcrumbs.push({
         link: libraryRoute.replace(':libraryName', activeLibrary),
         text: 'Content Library',
      });
      contextSetBreadcrumbs({
         breadcrumbs: breadcrumbs.reverse(),
         next: null,
         prev: null,
      });
   };

   const folders = React.useMemo(() => {
      const library = libraries?.[activeLibrary];
      if (isNonPagedLibrary(activeLibrary, library)) {
         return library?.folders ?? [];
      }
      return [];
   }, [libraries, activeLibrary]);

   React.useEffect(() => {
      if (activeLibrary) {
         fetchContent();
      }
   }, [debouncedFilters]);

   React.useEffect(() => {
      setBreadcrumbs();
   }, [activeFolderId, activeLibrary, folders]);

   React.useEffect(() => {
      if (!activeLibrary) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.personal), {
            replace: true,
         });
      }
   }, [activeLibrary]);

   React.useEffect(() => {
      if (walkthrough?.id !== OnboardingWalkthrough.contentLibraryIntro) {
         return;
      } else if (stepId === 'personal_library' && activeLibrary !== ContentLibraryName.personal) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.personal), {
            replace: true,
         });
      } else if (stepId === 'school_library' && activeLibrary !== ContentLibraryName.school) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.school), {
            replace: true,
         });
      } else if (stepId === 'global_library' && activeLibrary !== ContentLibraryName.global) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.global), {
            replace: true,
         });
      } else if (stepId === 'shared_library' && activeLibrary !== ContentLibraryName.shared) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.shared), {
            replace: true,
         });
      }
   }, [stepId]);

   React.useEffect(() => {
      if (walkthrough?.id !== OnboardingWalkthrough.contentLibraryIntro) {
         return;
      } else if (!walkthrough?.id) {
         setIsImporting(false);
      } else if (taskState === OnboardingTaskState.initialized) {
         startWalkthrough();
      } else if (taskState === OnboardingTaskState.started) {
         setIsOnboarding(true);
      } else if (taskState === OnboardingTaskState.completed) {
         navigate(libraryRoute.replace(':libraryName', ContentLibraryName.global), {
            replace: true,
         });
      }
   }, [taskState, walkthrough]);

   React.useEffect(() => {
      UserService.checkFeature('import_activity_json').then(setCanImportActivityJSON);
      UserService.checkFeature('create_videos').then(setCanCreateVideos);
   }, []);

   const hasMore = (): boolean => {
      const library = libraries?.[activeLibrary];
      if (isPagedLibrary(activeLibrary, library)) {
         return (
            library.currentPageNumber !== null && library.currentPageNumber < library.totalPageCount
         );
      }
      return false;
   };

   const openNewFolderModal = (): void => setIsCreatingNewFolder(true);

   const closeNewFolderModal = (): void => setIsCreatingNewFolder(false);

   const buildContentLink = (contentType: ContentType): string => {
      const params = new URLSearchParams();
      let link = '';
      if (contentType === ContentType.activity) {
         link = newActivity;
      } else if (contentType === ContentType.lesson) {
         link = newLesson;
      } else if (contentType === ContentType.vocabSet) {
         link = newVocabSet;
      } else if (contentType === ContentType.video) {
         link = bulkVideoUpload;
      }
      if (activeFolderId !== null) {
         params.append('folder', activeFolderId.toString());
      }
      return params ? `${link}?${params.toString()}` : link;
   };

   const getCreateNewDropdown = (): React.ReactNode => (
      <Droplist
         className='create-new-dropdown'
         pullRight
         items={[
            {
               to: buildContentLink(ContentType.activity),
               text: 'Activity',
               icon: <ContentIcon itemType={ContentType.activity} line />,
            },
            {
               to: buildContentLink(ContentType.lesson),
               text: 'Lesson',
               icon: <ContentIcon itemType={ContentType.lesson} line />,
            },
            {
               to: buildContentLink(ContentType.vocabSet),
               text: 'Vocabulary Set',
               icon: <ContentIcon itemType={ContentType.vocabSet} line />,
            },
            canCreateVideos
               ? {
                    to: buildContentLink(ContentType.video),
                    text: 'Video',
                    icon: <ContentIcon itemType={ContentType.video} line />,
                 }
               : null,
            isOnboarding
               ? null
               : {
                    onClick: openNewFolderModal,
                    text: 'Folder',
                    icon: <IconFolders />,
                 },
         ].filter((i) => !!i)}
      >
         <Button data-test='create-new-btn'>Create New</Button>
      </Droplist>
   );

   const renderEmptyState = (): Maybe<React.ReactNode> => {
      if (activeLibrary === ContentLibraryName.personal) {
         const createNewDropdown = getCreateNewDropdown();
         return (
            <EmptyState
               icon={<IconContentBook2 className='large' aria-hidden />}
               heading='No Content Yet!'
               primaryAction={createNewDropdown}
            />
         );
      } else if (activeLibrary === ContentLibraryName.school) {
         return (
            <EmptyState
               icon={<IconContentBook2 className='large' aria-hidden />}
               heading='No Content Yet!'
               description={
                  <p>
                     You can share content from your personal library with other instructors at your
                     school by adding it here.
                  </p>
               }
            />
         );
      } else if (activeLibrary === ContentLibraryName.global) {
         return (
            <EmptyState
               icon={<IconContentBook2 className='large' aria-hidden />}
               heading='No Content Yet!'
               description={
                  <p>
                     Check back soon - our subject matter experts are working on adding new content
                     every day.
                  </p>
               }
            />
         );
      } else if (activeLibrary === ContentLibraryName.shared) {
         return (
            <EmptyState
               icon={<IconContentBook2 className='large' aria-hidden />}
               heading='No Content Yet!'
               description='Content shared with you by other instructors will appear here.'
            />
         );
      }
      return null;
   };

   const isFetchingActiveLibrary = !libraries?.[activeLibrary]?.rows;
   const isEmpty =
      isFetchingActiveLibrary || (!libraries?.[activeLibrary].rows.length && !folders.length);

   const moveContent = (itemId: number, folderId: number): Promise<void> =>
      ContentService.patchContent(itemId, { folderId }).then(() => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [activeLibrary]: {
               ...prevLibraries[activeLibrary],
               rows: prevLibraries[activeLibrary].rows.map((i) =>
                  i.itemId === itemId ? { ...i, folderId } : i,
               ),
            },
         }));
      });

   const moveFolder = (
      folderId: number,
      parentId: number,
      library: ContentLibraryName,
   ): Promise<void> => {
      const index = folders.findIndex((i) => i.id === folderId);
      const newParent = index !== -1 && folders[index].parentId !== parentId;
      if (index !== -1 && newParent) {
         return HttpService.patchWithAuthToken<MessageResponse>(
            `/api/content/folders/${folderId}`,
            snakeCaseKeys({ parentId }),
         ).then(() => {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [library]: {
                  ...prevLibraries[library],
                  folders: (prevLibraries[library] as ContentLibraryType).folders.map((i) =>
                     i.id === folderId ? { ...i, parentId } : i,
                  ),
               },
            }));
         });
      }
      return Promise.resolve();
   };

   const deleteFolder = (folderId: number, library: ContentLibraryName): Promise<void> =>
      HttpService.deleteWithAuthToken<MessageResponse>(`/api/content/folders/${folderId}`).then(
         () => {
            setLibraries((prevLibraries) => ({
               ...prevLibraries,
               [library]: {
                  ...prevLibraries[library],
                  folders: (prevLibraries[library] as ContentLibraryType).folders.filter(
                     (i) => i.id !== folderId,
                  ),
               },
            }));
         },
      );

   const renameFolder = (
      folderId: number,
      name: string,
      library: ContentLibraryName,
   ): Promise<void> =>
      HttpService.patchWithAuthToken<MessageResponse>(
         `/api/content/folders/${folderId}`,
         snakeCaseKeys({ name }),
      ).then(() => {
         setLibraries((prevLibraries) => ({
            ...prevLibraries,
            [library]: {
               ...prevLibraries[library],
               folders: (prevLibraries[library] as ContentLibraryType).folders.map((i) =>
                  i.id === folderId ? { ...i, name } : i,
               ),
            },
         }));
      });

   const setFolderActive = (folderId: number): void =>
      navigate(
         folderRoute
            .replace(':libraryName', activeLibrary)
            .replace(':folderId', folderId.toString()),
      );

   const handleCreateNewFolder = (folder: ContentFolder): void => {
      setLibraries((prevLibraries) => ({
         ...prevLibraries,
         [ContentLibraryName.personal]: {
            ...prevLibraries[ContentLibraryName.personal],
            folders: _.orderBy(
               [
                  ...(prevLibraries[ContentLibraryName.personal] as ContentLibraryType).folders,
                  folder,
               ],
               ['itemName'],
               ['asc'],
            ),
         },
      }));
      closeNewFolderModal();
   };

   const duplicateItem = (contentId: number): Promise<void> => {
      const data: Partial<ContentItemProfile> = {};
      const item = libraries.personal.rows.find((i) => i.itemId === contentId);
      if (item !== undefined && activeFolderId === item.folderId) {
         data.folderId = item.folderId;
      }
      return ContentService.duplicateContent(contentId, data).then(
         ({ id: itemId, name: itemName }) => {
            if (item !== undefined && activeFolderId === item.folderId) {
               setLibraries((prevLibraries) => ({
                  ...prevLibraries,
                  [ContentLibraryName.personal]: {
                     ...prevLibraries[ContentLibraryName.personal],
                     rows: _.orderBy(
                        [
                           ...prevLibraries[ContentLibraryName.personal].rows,
                           {
                              ...item,
                              itemName: `${item.itemName} - Copy`,
                              itemId,
                           },
                        ],
                        ['itemName'],
                        ['asc'],
                     ),
                  },
               }));
            } else {
               fetchContent();
            }
            if (activeLibrary !== ContentLibraryName.personal) {
               dispatchToast({
                  title: 'Content Duplicated',
                  message: `You can view ${itemName} in your personal library`,
                  appearance: Appearance.success,
               });
            }
         },
      );
   };

   const modifyGlobalLibrary = (contentId: number, isGlobal: boolean): Promise<void> =>
      ContentService.modifyGlobalLibrary(contentId, isGlobal).then(() => fetchGlobalContent({}));

   const modifySchoolLibrary = (contentId: number, inSchool: boolean): Promise<void> =>
      ContentService.modifySchoolLibrary(contentId, organizationId, inSchool).then(() =>
         fetchSchoolContent({}),
      );

   const deleteItem = (contentId: number): Promise<void> =>
      ContentService.deleteContent(contentId).then(() => {
         setLibraries((prevLibraries) => {
            const updatedLibraries = _.cloneDeep(prevLibraries);
            Object.values(updatedLibraries).forEach((v) => {
               v.rows = v.rows.filter((i) => i.itemId !== contentId);
            });

            return updatedLibraries;
         });
      });

   const renameItem = (contentId: number, name: string): Promise<void> =>
      ContentService.patchContent(contentId, { name }).then(() => {
         setLibraries((prevLibraries) => {
            const updatedLibraries = _.cloneDeep(prevLibraries);
            Object.values(updatedLibraries).forEach((v) => {
               v.rows.forEach((row) => {
                  if (row.itemId === contentId) {
                     row.itemName = name;
                  }
               });
            });

            return updatedLibraries;
         });
      });

   const renderContentTiles = (): readonly React.ReactNode[] => {
      const items = libraries?.[activeLibrary]?.rows ?? [];
      const globalContentIds =
         libraries?.[ContentLibraryName.global]?.rows.map((i) => i.itemId) ?? [];
      const schoolContentIds =
         libraries?.[ContentLibraryName.school]?.rows.map((i) => i.itemId) ?? [];
      const filteredItems = items.filter((i) => !folders.length || i.folderId === activeFolderId);
      return naturalSort(filteredItems, 'itemName').map((item) => (
         <ContentTile
            key={`content-${item.itemId}`}
            item={item}
            moveItem={(newFolderId) => moveContent(item.itemId, newFolderId)}
            modifyGlobalLibrary={(isGlobal) => modifyGlobalLibrary(item.itemId, isGlobal)}
            modifySchoolLibrary={(inState) => modifySchoolLibrary(item.itemId, inState)}
            canClone={item.cloneable}
            canEdit={item.permission && item.permission === 'edit'}
            duplicateItem={() => duplicateItem(item.itemId)}
            deleteItem={() => deleteItem(item.itemId)}
            renameItem={(name) => renameItem(item.itemId, name)}
            inGlobalLibrary={globalContentIds.includes(item.itemId)}
            inSchoolLibrary={schoolContentIds.includes(item.itemId)}
            isCreator={item.createdBy === userId}
         />
      ));
   };

   const renderFolderTiles = (): readonly React.ReactNode[] => {
      const isOwner = activeLibrary === ContentLibraryName.personal;
      const content = libraries?.[activeLibrary]?.rows ?? [];
      const filteredFolders = folders.filter((i) => i.parentId === activeFolderId);
      return naturalSort(filteredFolders, 'name').map((folder) => (
         <ContentFolderTile
            key={`folder-${folder.id}`}
            isCreator={isOwner}
            canEdit={isOwner || folder.permission === 'edit'}
            moveFolder={
               isOwner ? (parentId) => moveFolder(folder.id, parentId, activeLibrary) : _.noop
            }
            addFolder={
               isOwner ? (childid) => moveFolder(childid, folder.id, activeLibrary) : _.noop
            }
            addItem={isOwner ? (itemId) => moveContent(itemId, folder.id) : _.noop}
            setFolderActive={() => setFolderActive(folder.id)}
            deleteFolder={isOwner ? () => deleteFolder(folder.id, activeLibrary) : _.noop}
            renameFolder={isOwner ? (name) => renameFolder(folder.id, name, activeLibrary) : _.noop}
            folder={folder}
            itemCount={
               folders.filter((j) => j.parentId === folder.id).length +
               content.filter((j) => j.folderId === folder.id).length
            }
         />
      ));
   };

   const toggleLayoutView = (): void => {
      const updatedView =
         layoutView === ContentLibraryLayout.grid
            ? ContentLibraryLayout.list
            : ContentLibraryLayout.grid;
      setContentLibraryLayout(updatedView);
   };

   const handleImportActivityJSON = async (
      event: React.ChangeEvent<HTMLInputElement>,
   ): Promise<void> => {
      if (isImporting) {
         return;
      }
      setIsImporting(true);
      Array.from(event.target.files).forEach((file) => {
         if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
               const parsed = JSON.parse(e.target.result as string);
               const data: Activity<ActivityMode.create> = {
                  ...parsed,
                  settings: {
                     ...parsed.settings,
                     folderId: activeFolderId,
                  },
               };
               await HttpService.postWithAuthToken<IdMessageResponse>(
                  '/api/content/activities',
                  snakeCaseKeys(data),
               );
            };
            reader.readAsText(file);
         }
      });
      setIsImporting(true);
   };

   return (
      <>
         <div className='content-main content-library-container'>
            <DocumentTitle>Content Library</DocumentTitle>
            {isFetchingActiveLibrary ? (
               <Loader />
            ) : (
               <ContentLibraryProvider
                  value={{
                     folders,
                     activeFolderId,
                     activeLibrary,
                     layoutView,
                  }}
               >
                  <div className='row'>
                     <ContentLibrarySidebar
                        activeLibrary={activeLibrary}
                        filters={filters}
                        setFilters={setFilters}
                     />
                     <div className='content-library col-xs-12 col-lg-10'>
                        <ContentLibraryHeader
                           canCreate={canCreate}
                           canImportActivityJSON={canImportActivityJSON}
                           createNewDropdown={getCreateNewDropdown()}
                           handleImportActivityJSON={handleImportActivityJSON}
                           hasContent={!isEmpty}
                           isImporting={isImporting}
                           layoutView={layoutView}
                           toggleLayoutView={toggleLayoutView}
                        />
                        <DndProvider backend={HTML5Backend}>
                           <div className={`${layoutView}-wrapper`}>
                              <InfiniteScroll
                                 className='tiles'
                                 hasMore={hasMore()}
                                 onLoadMore={fetchMoreContent}
                                 threshold={INFINITE_SCROLL_THRESHOLD}
                              >
                                 {isEmpty && (
                                    <div className='empty-state-wrapper'>{renderEmptyState()}</div>
                                 )}
                                 {renderFolderTiles()}
                                 {renderContentTiles()}
                              </InfiniteScroll>
                           </div>
                        </DndProvider>
                     </div>
                  </div>
               </ContentLibraryProvider>
            )}
         </div>
         {isCreatingNewFolder && (
            <NewContentFolderModal
               activeFolderId={activeFolderId}
               closeNewFolderModal={closeNewFolderModal}
               onCreateNewFolder={handleCreateNewFolder}
            />
         )}
      </>
   );
};

export default ContentLibrary;
