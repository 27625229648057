import * as React from 'react';

import { Maybe } from '@models/Core';
import mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';

type HandlerCallback = (event: ExtendedKeyboardEvent) => void;

/**
 * `useMousetrap` is a custom hook that allows you to bind keyboard shortcuts using the Mousetrap library.
 *
 * @param {string | string[]} handlerKey - The key or array of keys representing the keyboard shortcut(s).
 * @param {HandlerCallback} handlerCallback - The callback function to be invoked when the shortcut is triggered.
 *
 * @typedef {import('mousetrap').ExtendedKeyboardEvent} KeyboardEvent - KeyboardEvent type from Mousetrap library.
 * @typedef {(event: KeyboardEvent) => void} HandlerCallback - Function type for the handler callback.
 *
 * @see {@link https://craig.is/killing/mice Official Mousetrap Documentation}
 *
 * @example
 * useMousetrap('ctrl+s', handleSave); // Binds 'Ctrl + S' as a shortcut to invoke handleSave function.
 *
 * The hook internally uses the Mousetrap library to bind and unbind keyboard shortcuts. It takes a `handlerKey` parameter
 * which can be a single key or an array of keys representing the keyboard shortcut(s). The `handlerCallback` is the
 * function to be executed when the shortcut is triggered. When the shortcut is triggered, the callback is invoked, and
 * the event is prevented from its default behavior. The hook automatically unbinds the shortcut when the component is unmounted.
 */
const useMousetrap = (
   handlerKey: Maybe<string | string[]>,
   handlerCallback: HandlerCallback,
): void => {
   const actionRef = React.useRef<Maybe<HandlerCallback>>(null);
   actionRef.current = handlerCallback;

   React.useEffect(() => {
      if (!handlerKey) {
         return;
      }
      mousetrap.bind(handlerKey, (event) => {
         typeof actionRef.current === 'function' && actionRef.current(event);
         event.preventDefault();
         return false;
      });
      return () => {
         mousetrap.unbind(handlerKey);
      };
   }, [handlerKey]);
};

export default useMousetrap;
