import * as React from 'react';

import { ensureElement } from '@helpers/utils';
import classNames from 'classnames';

import InfoTooltip from '@components/InfoTooltip';

interface SwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
   color?: 'yellow' | 'green' | 'purple';
   children?: React.ReactNode;
   title?: string;
   tooltip?: string;
   wrapperClass?: string;
}

const Switch: React.FC<SwitchProps> = ({
   className: cn,
   style,
   color = 'yellow',
   children,
   title,
   tooltip,
   wrapperClass,
   ...props
}) => {
   const className = classNames(cn, 'reset-style', 'switch', color);

   return (
      <div className={classNames('switch-wrapper', wrapperClass)}>
         <input type='checkbox' className={className} {...props} />
         {children ? (
            ensureElement(children, 'label')
         ) : (
            <label className='field-title'>{title}</label>
         )}
         {tooltip && <InfoTooltip>{tooltip}</InfoTooltip>}
      </div>
   );
};

export default Switch;
