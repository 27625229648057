// @ts-strict-ignore
/* eslint-disable complexity */
import * as jsdiff from 'diff';
import * as _ from 'lodash';
import * as React from 'react';

import IconSessionVolume from '@icons/general/icon-session-volume.svg';
import IconSpeakerVolume from '@icons/nova-solid/37-Audio/speaker-volume-high.svg';
import classnames from 'classnames';

import Constants from '../../../Constants';
import { logger } from '@services/LoggingService';
import { getTimerDuration } from '@components/VocabSession/cardFunctions';
import ProgressDots from '@components/VocabSession/Header/ProgressDots';
import {
   FeedbackType,
   IAudioChoice,
   IFeedback,
   IImageChoice,
   ITextChoice,
   PromptMedium,
   ResponseMedium,
   VocabSessionCardType,
   VocabSessionType,
} from '@components/VocabSession/Models';
import { checkAccentError, scoreString } from '@components/VocabSession/stringFunctions';
import { TimerContext } from '@components/VocabSession/TimerWrapper';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';
import MultipleChoiceAudioOptions from './MultipleChoiceAudioOptions';
import MultipleChoiceImageOptions from './MultipleChoiceImageOptions';
import MultipleChoiceOptions from './MultipleChoiceOptions';
import SpeedBackground from './SpeedBackground';
import TermAttributes from './TermAttributes';
import TypingInput from './TypingInput';

export interface MultipleChoiceEvaluation {
   correct: number | null;
   incorrect: number | null;
   missed: number | null;
}

export interface TypingEvalution {
   feedback: FeedbackType;
   diff: JSX.Element;
}

interface ICardProps {
   ref?: React.Ref<HTMLDivElement>;
   advance(resequenceCardId?: string): void;
   hideFeedback(): void;
   registerResponse(cardId: string, response: string, correct: boolean, timeSpent: number): void;
   showFeedback(feedback: IFeedback): void;
   setNextButton(show: boolean): void;
   setAutoplayBlocked(show: boolean): void;
}

const ENTER_ANIMATION_CLASS = 'animated slideInRight faster';
const EXIT_ANIMATION_CLASS = 'animated fadeOut faster';
const MIN_RESPONSE_TIME = 1.5;

const Card: React.FC<ICardProps> = React.forwardRef(
   (
      { advance, hideFeedback, registerResponse, setNextButton, showFeedback, setAutoplayBlocked },
      ref: React.Ref<HTMLDivElement>,
   ) => {
      const {
         card,
         progress,
         state: {
            sessionType,
            settings: { language },
            stats,
            terms,
            isPaused,
            isMuted,
            showNext,
            disableVocabSetTimer,
         },
         term: {
            audio,
            image,
            term,
            definition,
            gender,
            number: termNumber,
            partOfSpeech,
            id: termId,
         },
      } = React.useContext<IVocabSessionContext>(VocabSessionContext);

      const { timer, setTimer } = React.useContext(TimerContext);

      const isSpeedSession = sessionType === VocabSessionType.speedReview;
      const isMultipleChoice = card.type === VocabSessionCardType.multipleChoice;
      const isTyping = card.type === VocabSessionCardType.typing;

      const [isAnswered, setIsAnswered] = React.useState<boolean>(false);
      const [isCorrect, setIsCorrect] = React.useState<boolean>(false);
      const [isEntering, setIsEntering] = React.useState<boolean>(false);
      const [isExiting, setIsExiting] = React.useState<boolean>(false);
      const [isTimedOut, setIsTimedOut] = React.useState<boolean>(false);
      const cssTransitionTimeoutRef = React.useRef(null);
      const [animationClass, setAnimationClass] = React.useState<string>(
         isSpeedSession ? null : ENTER_ANIMATION_CLASS,
      );
      const [keyboardChars, setKeyboardChars] = React.useState<readonly string[]>([]);

      const isReady = !isEntering && !isExiting;
      const ANIMATION_OVERRIDE_DELAY = 2000;

      React.useEffect(() => {
         if (card) {
            setIsTimedOut(false);
            setIsAnswered(false);
            setIsCorrect(false);
            if (!disableVocabSetTimer) {
               setTimer({
                  duration: getTimerDuration(
                     card,
                     { term, definition, audio },
                     sessionType,
                     language,
                  ),
                  interval: 100,
                  onCompletion: () => setIsTimedOut(true),
               });
            }
            if (isSpeedSession) {
               handleCardReady();
            } else {
               setIsEntering(true);
            }
         }
      }, [card]);

      React.useEffect(() => {
         if (isAnswered && !showNext && !isSpeedSession) {
            setNextButton(true);
         }
      }, [isAnswered]);

      // Prevents Scenario where CSS Animation fails to fire
      // Difficult to reproduce but happens on random user devices
      React.useEffect(() => {
         if (isEntering || isExiting) {
            if (!cssTransitionTimeoutRef.current) {
               cssTransitionTimeoutRef.current = setTimeout(() => {
                  logger.debug('Warning failed to fire animation event in time: ', {
                     ANIMATION_OVERRIDE_DELAY,
                  });
                  handleAnimationEnd();
                  clearTimeout(cssTransitionTimeoutRef.current);
                  cssTransitionTimeoutRef.current = null;
               }, ANIMATION_OVERRIDE_DELAY);
            }
         } else {
            if (cssTransitionTimeoutRef.current) {
               clearTimeout(cssTransitionTimeoutRef.current);
               cssTransitionTimeoutRef.current = null;
            }
         }
      }, [isEntering, isExiting]);

      React.useEffect(() => {
         setKeyboardChars(getKeyboardChars());
      }, [term]);

      const handleCardReady = (): void => {
         if (!isPaused) {
            if (!disableVocabSetTimer) {
               timer.current.start();
            }
            if (card.promptInTarget && card.promptWith === PromptMedium.audio) {
               playAudio();
            }
         }
         if (
            !isSpeedSession &&
            (card.type === VocabSessionCardType.typing || card.respondWith === ResponseMedium.audio)
         ) {
            setNextButton(true);
         }
      };

      const checkResponse = (
         rawResponse: number | string,
      ): MultipleChoiceEvaluation | TypingEvalution => {
         const answeredTooQuickly = timer.current.timeSpent < MIN_RESPONSE_TIME;
         const responseIsEmpty = rawResponse === null || rawResponse === '';
         if (responseIsEmpty && (answeredTooQuickly || disableVocabSetTimer)) {
            return;
         }

         if (timer.current.timerInterval && !disableVocabSetTimer) {
            timer.current.stop();
         }

         setIsAnswered(true);
         const shouldRegister = (isTyping && !isAnswered) || isMultipleChoice;
         const response =
            (isMultipleChoice && rawResponse
               ? card.respondInTarget
                  ? terms[rawResponse].term
                  : terms[rawResponse].definition
               : rawResponse) || '';

         let correct;
         let ratio;
         if (isMultipleChoice) {
            correct = rawResponse === termId;
         } else if (isTyping) {
            ratio = scoreString(response, term, language);
            correct = ratio === 1.0;
         }
         const [show, feedback] = getFeedback(correct, response, ratio);

         if (show) {
            showFeedback(feedback);
         } else {
            hideFeedback();
         }
         setIsCorrect(correct);

         if (shouldRegister) {
            const timeSpent = disableVocabSetTimer ? 0 : timer.current.timeSpent;
            registerResponse(card.id, response, correct, timeSpent);
         }

         if (isMultipleChoice) {
            const choiceId = rawResponse as number;
            return {
               correct: correct ? choiceId : null,
               incorrect: correct ? null : choiceId,
               missed: correct ? null : termId,
            };
         } else if (isTyping) {
            return {
               feedback: feedback.class,
               diff: ratio >= 0.7 ? getDiff(response) : <>{term}</>,
            };
         }
      };

      const getDiff = (response: string): JSX.Element => {
         const diff = jsdiff.diffChars(term, response);
         const result = diff.map((part, i) => {
            if (part.added || part.removed) {
               return React.createElement(part.added ? 'ins' : 'del', { key: i }, part.value);
            } else {
               return part.value;
            }
         });
         return <>{result}</>;
      };

      const getFeedback = (correct: boolean, response: string, ratio?): [boolean, IFeedback] => {
         let show = false;
         const feedback: IFeedback = {
            class: FeedbackType.incorrect,
            text: 'Try Again!',
         };
         if (correct) {
            feedback.class = FeedbackType.correct;
            feedback.text = 'Correct';
            if (stats.currentStreak + 1 > 0 && !((stats.currentStreak + 1) % 5)) {
               feedback.text = `🔥 ${stats.currentStreak + 1} in a row!`;
               show = true;
            }
         } else {
            if (isTyping) {
               const isAccentError = checkAccentError(response, term, language);
               const faultyTerm = Object.values(terms).find((i) => i.term === response);
               if (faultyTerm) {
                  feedback.text = (
                     <>
                        🚨 Confusion Alert - <strong>{response}</strong> means{' '}
                        <i>{faultyTerm.definition}</i>
                     </>
                  );
                  show = true;
               } else if (isAccentError) {
                  feedback.class = FeedbackType.almost;
                  feedback.text = 'Close! Check your accents.';
                  show = true;
               } else if (ratio >= 0.8) {
                  feedback.class = FeedbackType.almost;
                  feedback.text = 'Almost! Looks like you made a typo.';
                  show = true;
               }
            }
         }
         return [show, feedback];
      };

      const handleAnimationEnd = (): void => {
         logger.debug('handleAnimationEnd: ', { isEntering, isExiting });

         if (isEntering) {
            setIsEntering(false);
            setAnimationClass(null);
            handleCardReady();
         } else if (isExiting) {
            setIsExiting(false);
            setAnimationClass(isSpeedSession ? null : ENTER_ANIMATION_CLASS);
            advance(isCorrect ? null : card.id);
         }
      };

      const handleReadyToTransition = (): void => {
         if (isReady) {
            if (isSpeedSession) {
               advance(isCorrect ? null : card.id);
            } else {
               hideFeedback();
               setIsAnswered(false);
               setIsExiting(true);
               setNextButton(false);
               setAnimationClass(EXIT_ANIMATION_CLASS);
            }
         }
      };

      const playAudio = (signal?: AbortSignal): Promise<Event> =>
         new Promise((resolve, reject) => {
            if (signal && signal instanceof AbortSignal) {
               signal.addEventListener('abort', () => {
                  reject(new DOMException('Aborted', 'AbortError'));
               });
            }
            if (isMuted || !audio) {
               resolve(null);
            } else {
               audio.onerror = reject;
               audio.onended = resolve;
               const audioPlayPromise = audio.play();
               if (audioPlayPromise !== undefined) {
                  audioPlayPromise.catch((error) => {
                     if (error.name === 'NotAllowedError') {
                        setAutoplayBlocked(true);
                     } else {
                        console.error('Failed to play audio (Card.tsx)', {
                           err: `${error?.name}: ${error?.message}`,
                           currentSrc: audio.currentSrc,
                        });
                     }
                  });
               } else {
                  console.error('"audio.play()" did not return a promise');
               }
            }
         });

      const getKeyboardChars = (): readonly string[] => {
         const { alphabets } = Constants;
         const alphabet = Object.prototype.hasOwnProperty.call(alphabets, language)
            ? alphabets[language]
            : [];
         const termChars = new Set(term);
         const termCharsLower = new Set(term.toLowerCase());
         const numDistractors = _.min([Math.floor((alphabet.length - termCharsLower.size) / 5), 7]);
         const distractors = _.shuffle(alphabet.filter((ch) => !termCharsLower.has(ch))).slice(
            0,
            numDistractors,
         );
         return [...distractors, ...termChars].sort();
      };

      const showAudioButton = card.promptWith === PromptMedium.text && card.promptInTarget;

      const renderCard = (): JSX.Element => (
         <div
            className={classnames('card session-card padding-bottom-m', animationClass)}
            onAnimationEnd={handleAnimationEnd}
            ref={ref}
         >
            <div className='session-content-card-top'>
               {sessionType === VocabSessionType.learn && <ProgressDots level={progress.level} />}
               {(() => {
                  switch (card.promptWith) {
                     case PromptMedium.audio:
                        return (
                           <div className='session-prompt audio'>
                              <div className='session-audio-button-new' onClick={() => playAudio()}>
                                 <IconSpeakerVolume />
                              </div>
                           </div>
                        );
                     case PromptMedium.image:
                        return (
                           <div className='session-prompt image'>
                              <img src={image.src} />
                           </div>
                        );
                     case PromptMedium.text:
                        return (
                           <div className='session-title'>
                              <p lang={card.promptInTarget ? language : ''}>
                                 {card.promptInTarget ? term : definition}
                              </p>
                           </div>
                        );
                  }
                  return null;
               })()}
               {showAudioButton && (
                  <div
                     className={classnames('session-controls', {
                        hasAudioBtn: showAudioButton,
                     })}
                  >
                     <IconSessionVolume onClick={() => playAudio()} />
                  </div>
               )}
               <TermAttributes gender={gender} number={termNumber} partOfSpeech={partOfSpeech} />
               {(() => {
                  const common = {
                     isAnswered,
                     isReady,
                     isSpeedSession,
                     isTimedOut,
                     onReadyToTransition: handleReadyToTransition,
                     playAudio,
                  };
                  if (isMultipleChoice) {
                     if (card.respondWith === ResponseMedium.text) {
                        return (
                           <MultipleChoiceOptions
                              check={checkResponse as (i: number) => MultipleChoiceEvaluation}
                              choices={card.choices as readonly ITextChoice[]}
                              {...common}
                           />
                        );
                     } else if (card.respondWith === ResponseMedium.audio) {
                        return (
                           <MultipleChoiceAudioOptions
                              check={checkResponse as (i: number) => MultipleChoiceEvaluation}
                              choices={card.choices as readonly IAudioChoice[]}
                              {...common}
                           />
                        );
                     } else if (card.respondWith === ResponseMedium.image) {
                        return (
                           <MultipleChoiceImageOptions
                              check={checkResponse as (i: number) => MultipleChoiceEvaluation}
                              choices={card.choices as readonly IImageChoice[]}
                              {...common}
                           />
                        );
                     }
                  } else if (isTyping) {
                     return (
                        <TypingInput
                           check={checkResponse as (i: string) => TypingEvalution}
                           keyboardChars={keyboardChars}
                           {...common}
                        />
                     );
                  }
                  return undefined;
               })()}
            </div>
         </div>
      );

      if (isSpeedSession) {
         return <SpeedBackground>{renderCard()}</SpeedBackground>;
      } else {
         return renderCard();
      }
   },
);

Card.displayName = 'Card';
export default React.memo(Card);
