import * as React from 'react';

import IconVoteHeartBroken from '@icons/nova-solid/19-Vote&Rewards/vote-heart-broken.svg';
import IconVoteStarBanner from '@icons/nova-solid/19-Vote&Rewards/vote-star-banner.svg';

import Button from '@components/Common/Button';

interface ISpeedSessionOverlayProps {
   success: boolean;
   onContinueClick(): void;
}

const SpeedSessionOverlay: React.FC<ISpeedSessionOverlayProps> = ({ onContinueClick, success }) => (
   <div className='session-overlay speed'>
      <div className='session-overlay-content'>
         {success ? (
            <>
               <IconVoteStarBanner className='icon-green' />
               <h1>Session finished!</h1>
               <p>Learn new words to get a higher score next time!</p>
            </>
         ) : (
            <>
               <IconVoteHeartBroken className='icon-red' />
               <h1>Oh no!</h1>
               <p>You ran out of hearts!</p>
            </>
         )}
         <Button onClick={onContinueClick}>Continue</Button>
      </div>
   </div>
);

export default React.memo(SpeedSessionOverlay);
