import * as React from 'react';

import IconInterfaceAlertCircle from '@icons/nova-solid/22-Interface-Feedback/interface-alert-circle.svg';

import { AppStateContext } from '../AppState';
import Button from './Common/Button';
import EmptyState from './Core/EmptyState';
import DocumentTitle from './DocumentTitle';

const PaymentRequiredDisplay: React.FC = () => {
   const { currentCourses, archivedCourses, networkError, setPaidCourseId } =
      React.useContext<AppStateContext>(AppStateContext);

   const courseId = networkError?.data.courseId;

   const openPaymentModal = (): void => {
      setPaidCourseId(courseId);
   };

   const showPaymentButton =
      [...currentCourses, ...archivedCourses].findIndex((i) => i.id === courseId) !== -1;

   return (
      <div className='row margin-top-m'>
         <DocumentTitle>Payment Required</DocumentTitle>
         <div className='col-xs-12 col-md-12 col-lg-10'>
            <EmptyState
               icon={<IconInterfaceAlertCircle className='large' aria-hidden />}
               heading='Payment Required'
               description='Your course trial has expired. In order to access the course content and assignments, payment is required.'
               primaryAction={
                  showPaymentButton && <Button onClick={openPaymentModal}>Pay Now</Button>
               }
            />
         </div>
      </div>
   );
};

export default PaymentRequiredDisplay;
