import * as React from 'react';

import IconAquariumOctopus from '@icons/animals/aquarium-octopus.svg';
import IconBearFacingRight from '@icons/animals/bear-facing-right.svg';
import IconBeaverFacingRight from '@icons/animals/beaver-facing-right.svg';
import IconBigBug from '@icons/animals/big-bug.svg';
import IconBigFish from '@icons/animals/big-fish.svg';
import IconBigOwl from '@icons/animals/big-owl.svg';
import IconBigToucan from '@icons/animals/big-toucan.svg';
import IconBirdFacingRight from '@icons/animals/bird-facing-right.svg';
import IconBullFacingRight from '@icons/animals/bull-facing-right.svg';
import IconCamelFacingRight from '@icons/animals/camel-facing-right.svg';
import IconCatFacingRight from '@icons/animals/cat-facing-right.svg';
import IconChickenLookingRight from '@icons/animals/chicken-looking-right.svg';
import IconCougar from '@icons/animals/cougar.svg';
import IconCoyoteFacingRight from '@icons/animals/coyote-facing-right.svg';
import IconCrocodileFacingRight from '@icons/animals/crocodile-facing-right.svg';
import IconDeerFacingRight from '@icons/animals/deer-facing-right.svg';
import IconDogFacingRight from '@icons/animals/dog-facing-right.svg';
import IconDuckFacingRight from '@icons/animals/duck-facing-right.svg';
import IconElephantFacingRight from '@icons/animals/elephant-facing-right.svg';
import IconFoxSitting from '@icons/animals/fox-sitting.svg';
import IconFrogFacingRight from '@icons/animals/frog-facing-right.svg';
import IconGiraffeFacingRight from '@icons/animals/giraffe-facing-right.svg';
import IconGorillaFacingRight from '@icons/animals/gorilla-facing-right.svg';
import IconHamsterFacingRight from '@icons/animals/hamster-facing-right.svg';
import IconHippopotamusLookingRight from '@icons/animals/hippopotamus-looking-right.svg';
import IconHorseFacingRight from '@icons/animals/horse-facing-right.svg';
import IconKangarooLookingRight from '@icons/animals/kangaroo-looking-right.svg';
import IconLionFacingRight from '@icons/animals/lion-facing-right.svg';
import IconLizardFacingRight from '@icons/animals/lizard-facing-right.svg';
import IconMonkeyFacingRight from '@icons/animals/monkey-facing-right.svg';
import IconOstrichFacingRight from '@icons/animals/ostrich-facing-right.svg';
import IconOtterFacingRight from '@icons/animals/otter-facing-right.svg';
import IconPenguinFacingRight from '@icons/animals/penguin-facing-right.svg';
import IconPigFacingRight from '@icons/animals/pig-facing-right.svg';
import IconRabbitFacingRight from '@icons/animals/rabbit-facing-right.svg';
import IconRaccoonFacingRight from '@icons/animals/raccoon-facing-right.svg';
import IconRamFacingRight from '@icons/animals/ram-facing-right.svg';
import IconRatLookingRight from '@icons/animals/rat-looking-right.svg';
import IconRhinocerosFacingRight from '@icons/animals/rhinoceros-facing-right.svg';
import IconSeahorseFacingRight from '@icons/animals/seahorse-facing-right.svg';
import IconSharkFacingRight from '@icons/animals/shark-facing-right.svg';
import IconSheepLookingRight from '@icons/animals/sheep-looking-right.svg';
import IconShrimpFacingRight from '@icons/animals/shrimp-facing-right.svg';
import IconSnailFacingRight from '@icons/animals/snail-facing-right.svg';
import IconSnakeFacingRight from '@icons/animals/snake-facing-right.svg';
import IconSquirrelFacingRight from '@icons/animals/squirrel-facing-right.svg';
import IconSwanFacingRight from '@icons/animals/swan-facing-right.svg';
import IconTurkeyFacingRight from '@icons/animals/turkey-facing-right.svg';
import IconTurtleFacingRight from '@icons/animals/turtle-facing-right.svg';
import IconWhaleFacingRight from '@icons/animals/whale-facing-right.svg';

export type Animal =
   | 'octopus'
   | 'bear'
   | 'beaver'
   | 'bug'
   | 'fish'
   | 'owl'
   | 'toucan'
   | 'bird'
   | 'bull'
   | 'camel'
   | 'cat'
   | 'chicken'
   | 'cougar'
   | 'coyote'
   | 'crocodile'
   | 'deer'
   | 'dog'
   | 'duck'
   | 'elephant'
   | 'fox'
   | 'frog'
   | 'giraffe'
   | 'gorilla'
   | 'hamster'
   | 'hippopotamus'
   | 'horse'
   | 'kangaroo'
   | 'lion'
   | 'lizard'
   | 'monkey'
   | 'ostrich'
   | 'otter'
   | 'penguin'
   | 'pig'
   | 'rabbit'
   | 'raccoon'
   | 'ram'
   | 'rat'
   | 'rhinoceros'
   | 'seahorse'
   | 'shark'
   | 'sheep'
   | 'shrimp'
   | 'snail'
   | 'snake'
   | 'squirrel'
   | 'swan'
   | 'turkey'
   | 'turtle'
   | 'whale';

interface IAnimalIconProps {
   animal: Animal;
   color: string;
}

const AnimalIcon: React.FC<IAnimalIconProps> = ({ animal, color }) => {
   switch (animal) {
      case 'bear':
         return <IconBearFacingRight fill={color} />;
      case 'beaver':
         return <IconBeaverFacingRight fill={color} />;
      case 'bird':
         return <IconBirdFacingRight fill={color} />;
      case 'bug':
         return <IconBigBug fill={color} />;
      case 'bull':
         return <IconBullFacingRight fill={color} />;
      case 'camel':
         return <IconCamelFacingRight fill={color} />;
      case 'cat':
         return <IconCatFacingRight fill={color} />;
      case 'chicken':
         return <IconChickenLookingRight fill={color} />;
      case 'cougar':
         return <IconCougar fill={color} />;
      case 'coyote':
         return <IconCoyoteFacingRight fill={color} />;
      case 'crocodile':
         return <IconCrocodileFacingRight fill={color} />;
      case 'deer':
         return <IconDeerFacingRight fill={color} />;
      case 'dog':
         return <IconDogFacingRight fill={color} />;
      case 'duck':
         return <IconDuckFacingRight fill={color} />;
      case 'elephant':
         return <IconElephantFacingRight fill={color} />;
      case 'fish':
         return <IconBigFish fill={color} />;
      case 'fox':
         return <IconFoxSitting fill={color} />;
      case 'frog':
         return <IconFrogFacingRight fill={color} />;
      case 'giraffe':
         return <IconGiraffeFacingRight fill={color} />;
      case 'gorilla':
         return <IconGorillaFacingRight fill={color} />;
      case 'hamster':
         return <IconHamsterFacingRight fill={color} />;
      case 'hippopotamus':
         return <IconHippopotamusLookingRight fill={color} />;
      case 'horse':
         return <IconHorseFacingRight fill={color} />;
      case 'kangaroo':
         return <IconKangarooLookingRight fill={color} />;
      case 'lion':
         return <IconLionFacingRight fill={color} />;
      case 'lizard':
         return <IconLizardFacingRight fill={color} />;
      case 'monkey':
         return <IconMonkeyFacingRight fill={color} />;
      case 'octopus':
         return <IconAquariumOctopus fill={color} />;
      case 'ostrich':
         return <IconOstrichFacingRight fill={color} />;
      case 'otter':
         return <IconOtterFacingRight fill={color} />;
      case 'owl':
         return <IconBigOwl fill={color} />;
      case 'penguin':
         return <IconPenguinFacingRight fill={color} />;
      case 'pig':
         return <IconPigFacingRight fill={color} />;
      case 'rabbit':
         return <IconRabbitFacingRight fill={color} />;
      case 'raccoon':
         return <IconRaccoonFacingRight fill={color} />;
      case 'ram':
         return <IconRamFacingRight fill={color} />;
      case 'rat':
         return <IconRatLookingRight fill={color} />;
      case 'rhinoceros':
         return <IconRhinocerosFacingRight fill={color} />;
      case 'seahorse':
         return <IconSeahorseFacingRight fill={color} />;
      case 'shark':
         return <IconSharkFacingRight fill={color} />;
      case 'sheep':
         return <IconSheepLookingRight fill={color} />;
      case 'shrimp':
         return <IconShrimpFacingRight fill={color} />;
      case 'snail':
         return <IconSnailFacingRight fill={color} />;
      case 'snake':
         return <IconSnakeFacingRight fill={color} />;
      case 'squirrel':
         return <IconSquirrelFacingRight fill={color} />;
      case 'swan':
         return <IconSwanFacingRight fill={color} />;
      case 'toucan':
         return <IconBigToucan fill={color} />;
      case 'turkey':
         return <IconTurkeyFacingRight fill={color} />;
      case 'turtle':
         return <IconTurtleFacingRight fill={color} />;
      case 'whale':
         return <IconWhaleFacingRight fill={color} />;
   }
};

export default React.memo(AnimalIcon);
