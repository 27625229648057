import { Maybe } from '@models/Core';
import tinymce from 'tinymce';

import { createMessage } from '@components/Core/Editor/renderers';
import { MessageDirection } from '@components/Core/Editor/types/Conversation';
import { convertStringToNode, getDataAttribute } from '@components/Core/Editor/utils/node';

tinymce.PluginManager.add('conversations', (editor) => {
   // Escape conversation
   editor.on('keydown', (event) => {
      if (event.key === 'Enter') {
         const element = editor.selection.getNode();
         if (element.parentElement?.classList.contains('conversation')) {
            const { startContainer } = editor.selection.getRng();
            if (editor.selection.isCollapsed()) {
               event.preventDefault();
               const newLine = editor.dom.create('p');
               newLine.appendChild(editor.dom.create('br', { 'data-mce-bogus': '1' }));
               if (getDataAttribute(startContainer, 'data-mce-caret') === 'after') {
                  editor.dom.insertAfter(newLine, element.parentElement);
                  const firstChild = element.parentElement?.nextElementSibling?.firstChild;
                  if (firstChild) {
                     editor.selection.select(firstChild);
                  }
                  editor.selection.collapse(false);
               } else if (getDataAttribute(startContainer, 'data-mce-caret') === 'before') {
                  element.parentElement.insertAdjacentElement('beforebegin', newLine);
                  editor.selection.select(element.parentElement);
                  editor.selection.collapse(true);
               }
            }
         }
      }
   });

   const getConversationContainer = (element?: Element) => {
      if (!element) {
         element = editor.selection.getNode();
      }
      return element.closest('.conversation');
   };

   const deleteComponent = () => {
      const element = getConversationContainer();
      if (!element) {
         return;
      }
      const nextChild = element.nextSibling;
      element.remove();
      if (nextChild) {
         editor.selection.setCursorLocation(nextChild, 0);
         editor.focus();
      }
   };

   const getMessage = (element?: Element): Maybe<Element> => {
      if (!element) {
         element = editor.selection.getNode();
      }
      return element?.closest('.message') || null;
   };

   const addMessage = () => {
      const container = getConversationContainer();
      const messages = container?.querySelectorAll('.message');
      if (!messages) {
         return;
      }
      const newText = 'Esta es una situación muy interesante.';
      const newDirection =
         messages.length > 0 &&
         messages[messages.length - 1].classList.contains(MessageDirection.sent)
            ? MessageDirection.received
            : MessageDirection.sent;
      const previousSameNode = container?.querySelector(`.${newDirection}`);
      let newPerson = 'Carla';
      // Try to find previous message from same direction to determine person name
      if (previousSameNode) {
         const previousPersonNode = previousSameNode.querySelector('.person');
         if (previousPersonNode?.textContent) {
            newPerson = previousPersonNode.textContent;
         }
      }

      const newMessageNode = convertStringToNode(
         createMessage({
            direction: newDirection,
            person: newPerson,
            message: newText,
         }),
      );
      if (newMessageNode) {
         container?.appendChild(newMessageNode);
      }
   };

   const deleteMessage = () => {
      const element = getMessage();
      element?.remove();
   };

   const swapMessageSides = () => {
      const container = getConversationContainer();
      const messages = container?.querySelectorAll('.message') || [];
      messages.forEach((i) => {
         i.classList.toggle(MessageDirection.sent);
         i.classList.toggle(MessageDirection.received);
      });
   };

   editor.ui.registry.addMenuItem('swap-message-sides', {
      text: 'Swap Sides',
      icon: 'duplicate',
      onAction: () => swapMessageSides(),
   });

   editor.ui.registry.addMenuItem('add-message', {
      text: 'Add Message',
      icon: 'duplicate',
      onAction: () => addMessage(),
   });

   editor.ui.registry.addMenuItem('delete-message', {
      text: 'Delete Message',
      icon: 'remove',
      onAction: () => deleteMessage(),
   });

   editor.ui.registry.addMenuItem('delete-conversation-component', {
      text: 'Delete Component',
      icon: 'remove',
      onAction: () => deleteComponent(),
   });

   editor.ui.registry.addContextMenu('conversation', {
      update: (element) => {
         const withinConversation = !!getConversationContainer(element);
         if (!withinConversation) {
            return '';
         }
         const withinMessage = !!getMessage(element);
         return `add-message ${
            withinMessage ? 'delete-message' : ''
         } | delete-conversation-component`;
      },
   });

   return {
      getMetadata: () => ({
         name: 'Conversation',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
