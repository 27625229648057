import * as _ from 'lodash';
import * as React from 'react';

import { applyDrag } from '@components/Activity/DragUtils';
import {
   isAudioContent,
   isAudioRecordingPrompt,
   isImageLabelingPrompt,
   isMultipleChoicePrompt,
   isUploadedVideoContent,
} from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import ContentBuilderHeader from '@components/ContentBuilderHeader';
import ConfirmModal from '@components/Core/ConfirmModal';
import DNDContext from '@components/Core/DragAndDropKit/DNDContext';
import ModalDialog from '@components/Core/ModalDialog';
import NavigationPrompt from '@components/Core/NavigationPrompt';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import {
   IOnboardingProps,
   OnboardingTaskState,
   OnboardingWalkthrough,
   withOnboarding,
} from '@components/Onboarding';
import autobind from '@helpers/autobind';
import indexDeep from '@helpers/IndexDeep';
import keyDeep from '@helpers/KeyDeep';
import omitDeep from '@helpers/OmitDeep';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import { randomShortId, randomTempId } from '@helpers/RandomStringUtils';
import IconControlSplit from '@icons/nova-line/29-Controls/control-split.svg';
import {
   Activity,
   ActivityBuilderMode,
   ActivityItem,
   ActivityLogicActionOption,
   ActivityLogicActionType,
   ActivityMode,
   ActivityQuestion,
   ActivityQuestionId,
   ActivityVariable,
   ActivityVariableType,
   AudioContent,
} from '@models/Activity';
import Appearance from '@models/Appearance';
import Content, { ContentExamType, ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import { createActivity, updateActivity } from '@services/ActivityService';
import {
   duplicateAsset,
   MediaType,
   uploadAudio,
   uploadImage,
   uploadS3Video,
} from '@services/AssetService';
import ContentService from '@services/ContentService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import { DropResult } from 'smooth-dnd';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import { RouteComponentProps } from '../../../types/Routing';
import ActivityBuilderSettingsFields from './ActivityBuilderSettingsFields';
import ActivityBuilderSidebar from './ActivityBuilderSidebar';
import ActivityContent from './ActivityContent';
import { getDefaultContentOrPromptByItemType } from './ActivityItems';
import LogicMap from './Logic/LogicMap';
import {
   cloneGroup,
   removeMissingTagsFromGroup,
   removeQuestionFromGroup,
   removeVariableFromGroup,
   resetVariableInGroup,
} from './Logic/LogicUtils';

export interface ActivityContext {
   language: Language;
   logicActionOptions: readonly ActivityLogicActionOption[];
   questionTags: readonly string[];
   showStoredFilenames: boolean;
   variables: readonly ActivityVariable<ActivityBuilderMode>[];
}

export const ActivityContext = React.createContext<ActivityContext>({
   language: 'es',
   logicActionOptions: [],
   questionTags: [],
   showStoredFilenames: false,
   variables: [],
});
export const ActivityProvider = ActivityContext.Provider;
export const ActivityConsumer = ActivityContext.Consumer;

export interface ActivityBuilderProps
   extends IOnboardingProps<ActivityBuilderProps, ActivityBuilderState>,
      RouteComponentProps {
   mode: ActivityBuilderMode;
   activityRecovery: Maybe<ActivityRecovery>;
   setActivityRecovery(state: Maybe<ActivityBuilderState>, existing: boolean): void;
}
export interface ActivityRecovery {
   existing: ActivityBuilderState;
   new: ActivityBuilderState;
}

export interface ActivityBuilderState {
   activityRecoveryModalOpen: boolean;
   allowGoingBack: boolean;
   confirmSaveModalOpen: boolean;
   disableCopyPaste: boolean;
   disableSpellCheck: boolean;
   examType: Maybe<ContentExamType>;
   hasSubmissions: boolean;
   id?: number;
   isDirty: boolean;
   isDraggingPrompt: boolean;
   isFetching: boolean;
   isLoading: boolean;
   isOnboarding: boolean;
   isReadyToRedirect: boolean;
   logicMapOpen: boolean;
   onboardingStepId: Maybe<string>;
   oneQuestionAtATime: boolean;
   questions: readonly ActivityQuestion<ActivityBuilderMode>[];
   reportPartName: Maybe<string>;
   settings: Content;
   showPointsPossible: boolean;
   showQuestionList: boolean;
   showSettings: boolean;
   showStoredFilenames: boolean;
   variables: readonly ActivityVariable<ActivityBuilderMode>[];
}

class ActivityBuilder extends React.Component<ActivityBuilderProps, ActivityBuilderState> {
   static contextType = AppStateContext;
   context!: React.ContextType<typeof AppStateContext>;

   setSettings: React.RefObject<HTMLDivElement>;

   constructor(props: ActivityBuilderProps) {
      super(props);
      autobind(this);

      this.state = {
         activityRecoveryModalOpen: false,
         allowGoingBack: true,
         confirmSaveModalOpen: false,
         disableCopyPaste: false,
         disableSpellCheck: false,
         examType: null,
         hasSubmissions: false,
         isDirty: false,
         isDraggingPrompt: false,
         isFetching: false,
         isLoading: false,
         isOnboarding: false,
         isReadyToRedirect: false,
         logicMapOpen: false,
         onboardingStepId: null,
         oneQuestionAtATime: false,
         questions: [],
         reportPartName: '',
         settings: {
            createdBy: null,
            createdOn: null,
            description: '',
            folderId: null,
            id: null,
            imageFilename: null,
            language: 'en',
            name: '',
            styleSheetId: null,
            imageUrl: null,
            modifiedOn: null,
            tags: [],
            type: ContentType.activity,
         },
         showPointsPossible: true,
         showQuestionList: true,
         showSettings: false,
         showStoredFilenames: false,
         variables: [],
      };

      this.setSettings = React.createRef<HTMLDivElement>();
   }

   componentDidMount(): void {
      const { activityRecovery, location, mode } = this.props;
      if (mode === ActivityMode.create) {
         const folderId = getQueryParameterAsNumber(location, 'folder', null);
         const { userProfile } = this.context;
         if (!userProfile) {
            return;
         }
         const { language, id: createdBy } = userProfile;

         this.setState(
            (prevState) => ({
               settings: {
                  ...prevState.settings,
                  createdBy,
                  folderId,
                  language,
                  name: 'Untitled Activity',
               },
            }),
            this.setBreadcrumbs,
         );
         if (activityRecovery?.new) {
            this.setState({ activityRecoveryModalOpen: true });
         }
         this.handleOnboardingStateChange(null, null);
      } else if (mode === ActivityMode.edit) {
         if (activityRecovery?.existing?.settings?.id === Number(this.props.params.activityId)) {
            this.setState({ activityRecoveryModalOpen: true });
         } else {
            this.fetchActivity();
         }
      }
      UserService.checkFeature('show_stored_filenames').then((i) =>
         this.setState({ showStoredFilenames: i }),
      );
   }

   componentDidUpdate(prevProps: ActivityBuilderProps, prevState: ActivityBuilderState): void {
      this.handleOnboardingStateChange(prevProps, prevState);
      if (this.state.isReadyToRedirect && !this.state.isDirty && this.state.settings.id) {
         setTimeout(this.goToPreview, 0);
      }
   }

   handleOnboardingStateChange(
      prevProps: Maybe<ActivityBuilderProps>,
      prevState: Maybe<ActivityBuilderState>,
   ): void {
      const { onStateUpdate, startWalkthrough } = this.props;
      if (this.props.taskState === OnboardingTaskState.inProgress) {
         onStateUpdate(this.state, prevState);
      }

      if (this.props.walkthrough?.id !== OnboardingWalkthrough.activityBuilderIntro) {
         return;
      }

      if (prevProps?.taskState !== this.props.taskState) {
         switch (this.props.taskState) {
            case OnboardingTaskState.initialized:
               this.setState(
                  (i) => ({
                     questions: [],
                     settings: {
                        ...i.settings,
                        name: 'Untitled Activity',
                     },
                  }),
                  startWalkthrough,
               );
               break;
            case OnboardingTaskState.started:
               this.setState({ isOnboarding: true });
               break;
         }
      }
   }

   addQuestion(): Promise<void> {
      return new Promise((resolve) =>
         this.setState(
            (prevState) => ({
               questions: [
                  ...prevState.questions,
                  {
                     id: randomTempId(),
                     index: prevState.questions.length,
                     items: [],
                     logic: null,
                     tags: null,
                     title: '',
                  },
               ],
               isDirty: true,
            }),
            resolve,
         ),
      );
   }

   closeConfirmSaveModal(): void {
      this.setState({ confirmSaveModalOpen: false });
   }

   checkLogicRules(): Promise<void> {
      return new Promise((resolve) => {
         const { questions } = this.state;
         const questionsWithLogic = questions.filter((i) => !!i.logic);
         if (questionsWithLogic.length === 0) {
            resolve();
            return;
         }
         this.setState({ oneQuestionAtATime: true }, () => {
            resolve();
         });
      });
   }

   async commitThenRedirect(confirmedSave?: boolean): Promise<void> {
      if (this.state.hasSubmissions && !confirmedSave) {
         this.setState({ confirmSaveModalOpen: true });
         return;
      }
      this.setState({ isLoading: true });
      await this.checkLogicRules();
      try {
         await this.uploadAssets();
         const {
            allowGoingBack,
            disableCopyPaste,
            disableSpellCheck,
            examType,
            oneQuestionAtATime,
            reportPartName,
            settings,
            showPointsPossible,
            showQuestionList,
            variables,
         } = this.state;

         const questions = _.cloneDeep(this.state.questions);
         const keysToOmit = [
            'blob',
            'file',
            'key',
            'imageUrl',
            'fileUrl',
            'sequenceEligible',
            'pointsPossible',
         ];

         indexDeep(questions);

         const data = _.cloneDeep({
            ...settings,
            allowGoingBack,
            styleSheetId: settings.styleSheetId || null,
            questions,
            disableCopyPaste,
            disableSpellCheck,
            examType,
            oneQuestionAtATime,
            reportPartName,
            showQuestionList,
            showPointsPossible,
            variables,
         });

         omitDeep(data, keysToOmit);
         if (this.props.mode === ActivityMode.create) {
            const newActivityId = await createActivity({
               ...data,
               id: null,
               createdBy: null,
               createdOn: null,
               modifiedOn: null,
               type: ContentType.activity,
            });
            this.setState((prevState) => ({
               isDirty: false,
               isReadyToRedirect: true,
               settings: { ...prevState.settings, id: newActivityId },
            }));
         } else {
            const activityId = settings.id;
            if (activityId) {
               try {
                  await updateActivity(data);
                  this.setState({ isDirty: false, isReadyToRedirect: true });
               } catch {
                  this.props.setActivityRecovery(this.state, true);
               }
            }
         }
      } catch {
         this.props.setActivityRecovery(this.state, false);
      }
   }

   goToPreview(): void {
      const {
         routes: {
            content: { previewActivity },
         },
      } = Constants;
      const { location, navigate } = this.props;
      const {
         settings: { id },
      } = this.state;
      if (!id) {
         return;
      }
      const moduleItemId = getQueryParameterAsNumber(location, 'moduleItemId', null);
      const redirectRoute = previewActivity.replace(':activityId', id.toString());
      navigate(moduleItemId ? `${redirectRoute}?moduleItemId=${moduleItemId}` : redirectRoute);
   }

   loadActivityRecovery(): void {
      this.setState(
         (prevState, prevProps) =>
            prevProps.activityRecovery
               ? {
                    ...(prevProps.mode === ActivityMode.create
                       ? prevProps.activityRecovery.new
                       : prevProps.activityRecovery.existing),
                    isFetching: false,
                    isLoading: false,
                    showSettings: false,
                 }
               : prevState,
         this.clearActivityRecovery,
      );
   }

   clearActivityRecoveryAndFetch(): void {
      this.clearActivityRecovery();
      if (this.props.mode === ActivityMode.edit) {
         this.fetchActivity();
      }
   }

   closeActivityRecoveryModalAndFetch(): void {
      this.closeActivityRecoveryModal();
      if (this.props.mode === ActivityMode.edit) {
         this.fetchActivity();
      }
   }

   clearActivityRecovery(): void {
      this.props.setActivityRecovery(null, this.props.mode === ActivityMode.edit);
      this.closeActivityRecoveryModal();
   }

   closeActivityRecoveryModal(): void {
      this.setState({ activityRecoveryModalOpen: false });
   }

   closeLogicMap(): void {
      this.setState({ logicMapOpen: false });
   }

   editSettings(update: Partial<Content>): void {
      this.setState((prevState) => ({
         settings: { ...prevState.settings, ...update },
         isDirty: true,
      }));
   }

   fetchActivity(): void {
      const {
         params: { activityId },
         mode,
      } = this.props;
      this.setState({ isFetching: true });
      HttpService.getWithAuthToken<{
         activity: Activity<ActivityMode.edit>;
         hasSubmissions: boolean;
      }>(`/api/content/activities/${activityId}?mode=${mode}`).then((response) => {
         const {
            activity: {
               allowGoingBack,
               disableCopyPaste,
               disableSpellCheck,
               examType,
               oneQuestionAtATime,
               questions,
               reportPartName,
               showPointsPossible,
               showQuestionList,
               variables,
               ...settings
            },
            hasSubmissions,
         } = response.data;
         keyDeep(questions);
         this.setState(
            {
               allowGoingBack,
               disableCopyPaste,
               disableSpellCheck,
               examType,
               isFetching: false,
               oneQuestionAtATime,
               reportPartName,
               questions,
               settings,
               showPointsPossible,
               showQuestionList,
               variables,
               hasSubmissions,
            },
            () => {
               this.setBreadcrumbs();
               this.checkAudioContentItems();
            },
         );

         if (hasSubmissions) {
            this.context.setBanner({
               appearance: Appearance.danger,
               show: true,
               body: 'There are already submissions on this activity. Editing answers or questions could change submissions of students that have already worked on this activity.',
               sticky: true,
               hideOnLocationChange: true,
            });
         }
      });
   }

   handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
      this.setState((prevState) => ({
         settings: {
            ...prevState.settings,
            name: event.target.value,
         },
      }));
   }

   async appendPrompt(
      _event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
      tile: ActivityItem<ActivityBuilderMode>,
   ): Promise<void> {
      // FIXME: Potential race condition
      if (!this.state.questions.length) {
         await this.addQuestion();
      }
      const newTile = getDefaultContentOrPromptByItemType(tile.itemType);

      this.setState(
         (prevState) => ({
            questions: prevState.questions.map((i, j) =>
               j === prevState.questions.length - 1
                  ? {
                       ...i,
                       items: [
                          ...i.items,
                          {
                             ...newTile,
                          },
                       ],
                    }
                  : i,
            ),
            isDirty: true,
         }),
         this.checkAudioContentItems,
      );
   }

   handleQuestionDrop(event: DropResult): void {
      this.setState((prevState) => ({
         questions: applyDrag(prevState.questions, event).map((i, j) => ({
            ...i,
            index: j,
         })),
         isDirty: true,
      }));
   }

   handleQuestionUpdate(
      id: ActivityQuestionId<ActivityBuilderMode>,
      update: Partial<ActivityQuestion<ActivityBuilderMode>>,
      callback?: () => void,
   ): void {
      this.setState(
         (prevState) => {
            const updatedQuestions = prevState.questions.map((i) =>
               i.id === id ? { ...i, ...update } : i,
            );
            const updatedTags = [...new Set(updatedQuestions.map((i) => i.tags ?? []).flat())];
            return {
               questions: updatedQuestions.map((i) =>
                  i.logic
                     ? {
                          ...i,
                          logic: removeMissingTagsFromGroup(i.logic, updatedTags),
                       }
                     : i,
               ),
               isDirty: true,
            };
         },
         () => {
            this.checkAudioContentItems();
            callback?.();
         },
      );
   }

   openLogicMap(): void {
      this.setState({ logicMapOpen: true });
   }

   handleVariableChange(
      variableId: string | number,
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void {
      const { name, value } = event.target;
      const isTypeChange = name === 'type';
      this.setState((prevState) => ({
         variables: prevState.variables.map((i) =>
            i.id === variableId ? { ...i, [name]: value } : i,
         ),
         questions: prevState.questions.map((i) =>
            i.logic && isTypeChange
               ? { ...i, logic: resetVariableInGroup(i.logic, variableId) }
               : i,
         ),
      }));
   }

   handleAddVariable(): void {
      this.setState((prevState) => ({
         variables: [
            ...prevState.variables,
            {
               id: randomTempId(),
               name: '',
               type: ActivityVariableType.string,
               description: '',
            },
         ],
      }));
   }

   handleRemoveVariable(variableId: string | number): void {
      this.setState((prevState) => ({
         variables: prevState.variables.filter((i) => i.id !== variableId),
         questions: prevState.questions.map((i) => ({
            ...i,
            logic: i.logic ? removeVariableFromGroup(i.logic, variableId) : null,
         })),
      }));
   }

   removeContentImage(): void {
      this.setState((prevState) => ({
         settings: { ...prevState.settings, imageUrl: undefined },
      }));
   }

   removeQuestion(questionId: string | number): void {
      this.setState((prevState) => ({
         questions: prevState.questions
            .filter((i) => i.id !== questionId)
            .map((i, j) => ({
               ...i,
               index: j,
               logic: i.logic ? removeQuestionFromGroup(i.logic, questionId) : null,
            })),
      }));
   }

   async setBreadcrumbs(): Promise<void> {
      const { userProfile } = this.context;
      if (!userProfile) {
         return;
      }
      const { accountType, id: userId } = userProfile;
      const {
         settings: { createdBy = userId, folderId, name: contentName, id: contentId },
      } = this.state;
      ContentService.getBreadcrumbs(
         {
            accountType,
            canEditContent: true,
            contentId,
            contentName,
            contentType: ContentType.activity,
            createdBy,
            folderId,
            userId,
         },
         this.props.location,
      ).then(this.context.setBreadcrumbs);
   }

   duplicateQuestion(questionId: string | number): void {
      this.setState((prevState) => {
         const questions = [...prevState.questions];
         const index = questions.findIndex((i) => i.id === questionId);
         const question = {
            ..._.cloneDeep(questions[index]),
            title: questions[index].title.length ? `${questions[index].title} - Copy` : '',
            id: randomTempId(),
            logic: cloneGroup(questions[index].logic),
            items: questions[index].items.map((item) => ({
               ...item,
               id: null,
               key: randomShortId(),
               duplicateAssets: true,
            })),
         };
         questions.splice(index + 1, 0, question);
         return {
            questions: questions.map((i, j) => ({ ...i, index: j })),
            isDirty: true,
         };
      });
   }

   duplicateItem(itemKey: string, questionId: string | number): void {
      this.setState((prevState) => {
         const questions = [...prevState.questions];
         const question = questions.find((q) => q.id === questionId);
         if (question) {
            const questionItems = [...question.items];
            const itemToDuplicateIndex = questionItems.findIndex((i) => i.key === itemKey);
            const itemToDuplicate = questionItems[itemToDuplicateIndex];

            const newItem = { ...itemToDuplicate };

            if (isMultipleChoicePrompt(newItem)) {
               const newOptions = newItem.options.map((x) => ({
                  ...x,
                  key: randomShortId(),
               }));
               newItem.options = newOptions;
            }

            questionItems.splice(itemToDuplicateIndex + 1, 0, {
               ...newItem,
               id: null,
               key: randomShortId(),
               duplicateAssets: true,
            });

            question.items = questionItems;
         }
         return {
            questions,
            isDirty: true,
         };
      });
      return;
   }

   checkAudioContentItems(): void {
      this.setState((prevState) => ({
         questions: prevState.questions.map((question) => ({
            ...question,
            items: question.items.map((item, j) => {
               if (isAudioContent(item)) {
                  // Only sequence eligible when next item in question is audio recording prompt
                  const update: Partial<AudioContent<ActivityBuilderMode>> = {
                     sequenceEligible:
                        j < question.items.length - 1 &&
                        isAudioRecordingPrompt(question.items[j + 1]),
                  };
                  if (!update.sequenceEligible) {
                     update.recordUponCompletion = false;
                     update.recordingDelay = null;
                  }
                  return { ...item, ...update };
               } else {
                  return item;
               }
            }),
         })),
      }));
   }

   async uploadAssets(): Promise<void> {
      const { questions, settings } = this.state;
      if (settings.file) {
         await uploadImage(settings.file).then((imageFilename) => {
            this.setState((prevState) => ({
               settings: { ...prevState.settings, imageFilename },
            }));
         });
      }
      const itemUpdates: { originalFilename?: string; storedFilename?: string }[][] = questions.map(
         (i) => i.items.map(() => ({})),
      );
      for (let i = 0; i < questions.length; i++) {
         await Promise.all(
            questions[i].items.map((item, j) => {
               const { duplicateAssets } = item;
               if (isAudioContent(item)) {
                  if (duplicateAssets && item.storedFilename) {
                     return duplicateAsset(item.storedFilename, MediaType.audio).then(
                        (storedFilename) => {
                           itemUpdates[i][j].storedFilename = storedFilename;
                        },
                     );
                  } else {
                     if (item.file) {
                        return uploadAudio(item.file).then(({ filename }) => {
                           itemUpdates[i][j].originalFilename = item.file?.name;
                           itemUpdates[i][j].storedFilename = filename;
                        });
                     } else if (item.blob) {
                        return uploadAudio(item.blob).then(({ filename }) => {
                           itemUpdates[i][j].storedFilename = filename;
                        });
                     }
                  }
               } else if (isUploadedVideoContent(item)) {
                  if (duplicateAssets && item.storedFilename) {
                     return duplicateAsset(item.storedFilename, MediaType.video).then(
                        (storedFilename) => {
                           itemUpdates[i][j].storedFilename = storedFilename;
                        },
                     );
                  } else if (item.file) {
                     return uploadS3Video(item.file).then(({ filename }) => {
                        itemUpdates[i][j].originalFilename = item.file?.name;
                        itemUpdates[i][j].storedFilename = filename;
                     });
                  }
               } else if (isImageLabelingPrompt(item)) {
                  if (item.duplicateAssets && item.storedFilename) {
                     return duplicateAsset(item.storedFilename, MediaType.image).then(
                        (storedFilename) => {
                           itemUpdates[i][j].storedFilename = storedFilename;
                        },
                     );
                  } else if (item.file) {
                     return uploadImage(item.file).then((storedFilename) => {
                        itemUpdates[i][j].storedFilename = storedFilename;
                     });
                  }
               }
               return Promise.resolve('Nothing to upload for this item');
            }),
         );
      }

      return new Promise((resolve) =>
         this.setState(
            (prevState) => ({
               questions: prevState.questions.map((question, i) => ({
                  ...question,
                  items: question.items.map((item, j) => ({
                     ...item,
                     ...itemUpdates[i][j],
                  })),
               })),
            }),
            resolve,
         ),
      );
   }

   handlePrimaryButtonClick(): void {
      this.commitThenRedirect();
   }

   updateActivityState(update: Pick<ActivityBuilderState, keyof ActivityBuilderState>): void {
      this.setState(update);
   }

   onDragStart(): void {
      this.setState({ isDraggingPrompt: true });
   }

   onDragEnd(): void {
      this.setState({ isDraggingPrompt: false });
   }

   render(): React.ReactNode {
      const { activityRecovery, mode, getOnboardingClassName } = this.props;
      const {
         activityRecoveryModalOpen,
         allowGoingBack,
         confirmSaveModalOpen,
         disableCopyPaste,
         disableSpellCheck,
         examType,
         isDirty,
         isFetching,
         isLoading,
         isReadyToRedirect,
         logicMapOpen,
         oneQuestionAtATime,
         questions,
         reportPartName,
         settings,
         settings: { language },
         showPointsPossible,
         showQuestionList,
         showStoredFilenames,
         variables,
      } = this.state;
      const isNew = mode === ActivityMode.create;
      const primaryButtonText = `${isNew ? 'Create' : 'Save'} Activity`;
      const questionTags = [...new Set(questions.map((i) => i.tags ?? []).flat())];
      const logicActionOptions: readonly ActivityLogicActionOption[] = [
         {
            label: 'Go To Question',
            options: questions.map((i, j) => ({
               id: i.id,
               label: i.title ? i.title : `Untitled Question ${j + 1}`,
            })),
            placeholder: 'Select Question',
            value: ActivityLogicActionType.goToQuestion,
         },
         {
            label: 'Set Variable',
            options: variables.map((i) => ({ id: i.id, label: i.name })),
            placeholder: 'Select Variable',
            value: ActivityLogicActionType.setVariable,
         },
         {
            label: 'Go To Next Question',
            value: ActivityLogicActionType.goToNextQuestion,
         },
      ];

      if (isFetching) {
         return <Loader />;
      }

      return (
         <>
            <DocumentTitle>{`${isNew ? 'Create' : 'Edit'} Activity`}</DocumentTitle>
            <div className='content-main margin-right-m'>
               <div className='card no-padding card-activity-builder build'>
                  <div className='card-title has-button'>
                     <ContentBuilderHeader
                        additionalIconActions={[
                           {
                              icon: <IconControlSplit />,
                              onClick: this.openLogicMap,
                              key: 'logic-map',
                              tooltip: 'Logic Map',
                           },
                        ]}
                        additionalSettingsFields={
                           <ActivityBuilderSettingsFields
                              allowGoingBack={allowGoingBack}
                              disableCopyPaste={disableCopyPaste}
                              disableSpellCheck={disableSpellCheck}
                              language={language}
                              onAddVariable={this.handleAddVariable}
                              oneQuestionAtATime={oneQuestionAtATime}
                              onRemoveVariable={this.handleRemoveVariable}
                              onVariableChange={this.handleVariableChange}
                              showPointsPossible={showPointsPossible}
                              showQuestionList={showQuestionList}
                              updateActivity={this.updateActivityState}
                              examType={examType}
                              reportPartName={reportPartName}
                              variables={variables}
                           />
                        }
                        editSettings={this.editSettings}
                        removeContentImage={this.removeContentImage}
                        settings={settings}
                     />
                     <div className='right-options-wrapper'>
                        <Button
                           data-tour='primary-activity-builder-btn'
                           data-test='primary-activity-builder-btn'
                           className={classnames(getOnboardingClassName?.('create_activity'))}
                           onClick={this.handlePrimaryButtonClick}
                           loading={isLoading}
                        >
                           {primaryButtonText}
                        </Button>
                     </div>
                  </div>
                  <div className='activity-container'>
                     <DNDContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                        <ActivityBuilderSidebar
                           addQuestion={this.addQuestion}
                           handleDoubleClick={this.appendPrompt}
                           handleEnter={this.appendPrompt}
                        />
                        <ActivityProvider
                           value={{
                              language,
                              logicActionOptions,
                              questionTags,
                              showStoredFilenames,
                              variables,
                           }}
                        >
                           <ActivityContent
                              duplicateItem={this.duplicateItem}
                              duplicateQuestion={this.duplicateQuestion}
                              handleQuestionDrop={this.handleQuestionDrop}
                              handleQuestionUpdate={this.handleQuestionUpdate}
                              isDraggingPrompt={this.state.isDraggingPrompt}
                              questions={questions}
                              removeQuestion={this.removeQuestion}
                           />
                        </ActivityProvider>
                     </DNDContext>
                  </div>
               </div>
            </div>
            {isDirty && !isReadyToRedirect && (
               <NavigationPrompt
                  when={isDirty}
                  message={'Are you sure you want to leave? Changes you made will not be saved.'}
               />
            )}
            {activityRecoveryModalOpen && activityRecovery && (
               <ModalDialog
                  actions={[
                     { text: 'Recover', onClick: this.loadActivityRecovery },
                     {
                        text: 'Abandon',
                        onClick: this.clearActivityRecoveryAndFetch,
                     },
                  ]}
                  animations={{ enter: 'animated bounceInDown' }}
                  appearance={Appearance.primary}
                  heading='Recover Activity'
                  onClose={this.closeActivityRecoveryModalAndFetch}
               >
                  {isNew ? (
                     <p>
                        We ran into a problem while trying to save your last activity,{' '}
                        <strong>{activityRecovery.new.settings.name}</strong>.
                     </p>
                  ) : (
                     <p>
                        We ran into a problem while trying to save{' '}
                        <strong>{activityRecovery.existing.settings.name}</strong> last time.
                     </p>
                  )}
                  <p>Would you like us to attempt to recover it?</p>
               </ModalDialog>
            )}
            {logicMapOpen && <LogicMap questions={questions} closeLogicMap={this.closeLogicMap} />}
            {confirmSaveModalOpen && (
               <ConfirmModal
                  header='Save Activity?'
                  message='There are already submissions on this activity. Editing answers or questions could change submissions of students that have already worked on this activity. Are you sure you want to save this activity?'
                  onClose={this.closeConfirmSaveModal}
                  onPrimaryClick={() => this.commitThenRedirect(true)}
                  primaryClickText='Save Activity'
                  closeText='Cancel'
               />
            )}
         </>
      );
   }
}

export default withOnboarding(ActivityBuilder);
