import * as React from 'react';

import classnames from 'classnames';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';

import Handle from './Handle';
import Track from './Track';

interface AudioSliderProps {
   disabled?: boolean;
   domain: readonly number[];
   values: readonly number[];
   onChange(values: readonly number[]): void;
   onUpdate(values: readonly number[]): void;
   onSlideStart?(): void;
}

const AudioSlider: React.FC<AudioSliderProps> = ({
   disabled = false,
   domain,
   values,
   onChange,
   onUpdate,
   onSlideStart,
}) => (
   <Slider
      mode={1}
      step={1}
      className={classnames('slider', { disabled })}
      disabled={disabled}
      domain={domain}
      values={values}
      onChange={onChange}
      onSlideStart={onSlideStart}
      onUpdate={onUpdate}
   >
      <Rail>{({ getRailProps }) => <div className='rail' {...getRailProps()} />}</Rail>
      <Handles>
         {({ handles, getHandleProps }) => (
            <>
               {handles.map((handle) => (
                  <Handle
                     key={handle.id}
                     handle={handle}
                     domain={domain}
                     getHandleProps={getHandleProps}
                  />
               ))}
            </>
         )}
      </Handles>
      <Tracks right={false}>
         {({ tracks, getTrackProps }) => (
            <>
               {tracks.map(({ id, source, target }) => (
                  <Track
                     key={id}
                     disabled={disabled}
                     source={source}
                     target={target}
                     getTrackProps={getTrackProps}
                  />
               ))}
            </>
         )}
      </Tracks>
   </Slider>
);

export default React.memo(AudioSlider);
