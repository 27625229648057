import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import ModuleItemService from '@services/ModuleItemService';
import classnames from 'classnames';

import { AssignableModuleItem, ModuleItem } from '@models/Course/ModuleItem';
import Button from '@components/Common/Button';
import InfoTooltip from '@components/InfoTooltip';
import EditPrereqsModal from './EditPrereqsModal';

interface PrereqsSettingsProps {
   activeItem: ModuleItem;
   courseId: number;
   moduleId: number;
   canEditModule: boolean;
   editItem(update: Partial<AssignableModuleItem>): void;
}

const PrereqsSettings: React.FC<PrereqsSettingsProps> = ({
   activeItem,
   courseId,
   moduleId,
   canEditModule,
   editItem,
}) => {
   const prerequisites = activeItem?.prerequisites ?? [];
   const [isAddPrereqModalOpen, setIsAddPrereqModalOpen] = React.useState<boolean>(false);

   const openPrereqModal = (): void => {
      setIsAddPrereqModalOpen(true);
   };

   const closePrereqModal = (): void => {
      setIsAddPrereqModalOpen(false);
   };

   const addPrereq = (prereqItem: AssignableModuleItem): void => {
      ModuleItemService.addPrereqToModuleItem(
         courseId,
         moduleId,
         activeItem.id,
         prereqItem.id,
      ).then((newPreReq) => {
         editItem({ prerequisites: [...prerequisites, newPreReq] });
      });
   };

   const deletePrereq = (prereqId: number): void => {
      if (canEditModule) {
         ModuleItemService.deletePrereq(courseId, prereqId).then(() => {
            editItem({
               prerequisites: prerequisites.filter((i) => i.id !== prereqId),
            });
         });
      }
   };

   return (
      <div className={classnames('prereq')}>
         <label className='field-title no-margin-top'>
            Prerequisites
            <InfoTooltip>Choose which activities must be completed before this one</InfoTooltip>
         </label>
         {!!prerequisites.length && (
            <div className='prereq-content'>
               {prerequisites.map((p) => (
                  <div className='prereq-row' key={p.id}>
                     <div className='prereq-item-name'>{p.itemName}</div>
                     <div
                        className='icon-action pointer'
                        onClick={() => {
                           deletePrereq(p.id);
                        }}
                        aria-label='Delete Prerequisites'
                        role='button'
                     >
                        <IconBin />
                     </div>
                  </div>
               ))}
            </div>
         )}
         <Button
            onClick={openPrereqModal}
            disabled={!canEditModule}
            className='margin-top-s'
            subtle
            data-test='btn-add-prereqs'
         >
            + Add Prerequisites
         </Button>
         {isAddPrereqModalOpen && (
            <EditPrereqsModal
               courseId={courseId}
               onClose={closePrereqModal}
               addPrereq={addPrereq}
               selectedItem={activeItem}
               selectedModuleId={moduleId}
            />
         )}
      </div>
   );
};

export default PrereqsSettings;
