import * as React from 'react';

import tinymce from 'tinymce';

import clickCallback from '@components/Core/Editor/clickCallback';
import RecordVideoModal from './RecordVideoModal';

tinymce.PluginManager.add('recordvideo', (editor) => {
   editor.addCommand('mceRecordVideo', () => {
      clickCallback(editor, document, <RecordVideoModal />);
   });

   // Register buttons
   editor.ui.registry.addMenuItem('record-video', {
      icon: 'embed',
      text: 'Record Video',
      onAction(api) {
         if (api.isEnabled()) {
            editor.execCommand('mceRecordVideo');
         }
      },
   });
});
