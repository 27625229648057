import ModalDialog from '@components/Core/ModalDialog';
import React from 'react';

import { ModalProps } from '@components/Core/ModalDialog/Modal';

type Props = Pick<ModalProps, 'appearance' | 'heading'> & {
   nativeLanguageTextTrack: string;
   onOk(): void;
   targetLanguageTextTrack: string;
};

const TrancriptModal: React.FC<Props> = ({
   appearance,
   heading,
   nativeLanguageTextTrack,
   onOk,
   targetLanguageTextTrack,
}) => (
   <ModalDialog
      actions={[{ text: 'Ok', onClick: () => onOk() }]}
      animations={{ enter: 'animated bounceInDown' }}
      appearance={appearance}
      bodyClassName='modal-body'
      heading={heading}
      onClose={() => onOk()}
      shouldCloseOnEscapePress
      shouldCloseOnOverlayClick
      width={'three-quarter-width'}
   >
      <div className='flex-row modal-body-scroll' style={{ whiteSpace: 'pre-line' }}>
         <div className='text-left half-width'>
            <div className='title'>Target Language</div>
            <p>{targetLanguageTextTrack} </p>
         </div>
         <div className='text-left half-width'>
            <div className='title'>Native Language</div>
            <p>{nativeLanguageTextTrack}</p>
         </div>
      </div>
   </ModalDialog>
);

export default TrancriptModal;
