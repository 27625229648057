import * as _ from 'lodash';
import * as React from 'react';

import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import IconFolderNetwork from '@icons/nova-line/86-Files-Folders/folder-network.svg';
import { IdName } from '@models/Core';
import {
   DistrictProficiencyReport,
   DistrictProficiencySummaryRow,
   DistrictSummariesByCourse,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import EmptyState from '@components/Core/EmptyState';
import {
   buildDistrictReportBreadcrumbs,
   districtReportLinkReplace,
   getProficiencyReportTileData,
} from '@components/ProficiencyTracking/ProficiencyReportHelpers';
import ProficiencyReportTile from '@components/ProficiencyTracking/ProficiencyReportTile';

const DistrictProficiencySummary: React.FC = () => {
   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);

   const [districtReport, setDistrictReport] = React.useState<
      DistrictProficiencyReport | undefined
   >(undefined);
   const [filteredDistrictProficiencyCourses, setFilteredDistrictProficiencyCourses] =
      React.useState<IdName[]>([]);
   const [districtSummariesByCourse, setDistrictSummariesByCourse] = React.useState<
      DistrictSummariesByCourse | undefined
   >(undefined);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [hasError, setHasError] = React.useState<boolean>(false);
   const [searchString, setSearchString] = React.useState<string>('');

   const fetchDistrictSummary = async () => {
      try {
         setHasError(false);
         setIsLoading(true);

         const result = await ProficiencyReportService.getDistrictSummary();
         setDistrictReport(result);

         const summariesByCourse: DistrictSummariesByCourse = {};

         result.canDoStatementSummariesByCourse.forEach((summary) => {
            summariesByCourse[summary.courseId] =
               getProficiencyReportTileData<DistrictProficiencySummaryRow>(
                  summary.canDoStatements,
                  summary.courseEndDate,
                  summary.courseStartDate,
                  result.districtSummary,
               );
         });

         setDistrictSummariesByCourse(summariesByCourse);
         await buildBreadcrumbs();
      } catch (error) {
         console.error(error);
         setHasError(true);
      } finally {
         setIsLoading(false);
      }
   };

   const buildBreadcrumbs = async () => {
      const breadcrumbs = await buildDistrictReportBreadcrumbs();

      setBreadcrumbs({
         breadcrumbs,
         prev: null,
         next: null,
      });
   };

   const linkBuilder = (standardCourseId: number) =>
      districtReportLinkReplace(Constants.routes.proficiencyTracking.programSummary, {
         standardCourseId: standardCourseId.toString(),
      });

   const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchString(event.target.value);
   };

   React.useEffect(() => {
      fetchDistrictSummary();
   }, []);

   React.useEffect(() => {
      if (districtReport) {
         const sortedDistrictProficiencyCourses = _.sortBy(
            districtReport.districtProficiencyCourses,
            (x) => x.name,
         );
         setFilteredDistrictProficiencyCourses(
            sortedDistrictProficiencyCourses.filter((x) =>
               x.name.toLowerCase().includes(searchString.toLowerCase()),
            ),
         );
      }
   }, [districtReport, searchString]);

   if (isLoading) {
      return <Loader />;
   }

   if (hasError || districtReport === undefined || districtSummariesByCourse === undefined) {
      return <ErrorDisplay />;
   }

   return (
      <div className='district-report margin-top-m margin-left-s margin-right-s'>
         <div className='flex margin-bottom-s'>
            <input
               autoFocus
               data-test='search-name'
               name='searchName'
               onChange={handleSearchStringChange}
               placeholder='Search by Name'
               type='search'
            />
         </div>
         {filteredDistrictProficiencyCourses.length === 0 && (
            <div className='margin-l'>
               <EmptyState
                  icon={<IconFolderNetwork aria-hidden />}
                  heading='No courses found'
                  description={`No courses found for "${searchString}"`}
               />
            </div>
         )}
         <div className='flex flex-wrap proficiency-report-tile-container'>
            {filteredDistrictProficiencyCourses.map((row) => {
               const overallCourseStats = districtSummariesByCourse[row.id].Overall;
               return (
                  <ProficiencyReportTile
                     data-test={`report-link-${row.id}`}
                     className='district-report-tile'
                     link={linkBuilder(row.id)}
                     title={row.name}
                     row={overallCourseStats}
                     key={row.id}
                  />
               );
            })}
         </div>
      </div>
   );
};

export default DistrictProficiencySummary;
