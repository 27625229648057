import { IApplicationState } from '@models/ApplicationState';
import { createStore, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import Config from '../Config';
import reducers from './Reducers';

import storage from 'redux-persist/lib/storage';

const whitelist: (keyof IApplicationState)[] = [
   'activityRecovery',
   'archivedCourses',
   'contentLibraryLayout',
   'currentCourses',
   'postLoginRedirect',
   'user',
   'hideDowntimeBanner',
   'hideHelpFab',
];

const persistConfig = {
   key: 'root',
   storage,
   whitelist,
};

const { environmentType } = Config;
const allowReduxDevTools: boolean = environmentType && environmentType !== 'production';
const enhancer =
   window.__REDUX_DEVTOOLS_EXTENSION__ && allowReduxDevTools
      ? window.__REDUX_DEVTOOLS_EXTENSION__()(createStore)
      : createStore;
const persistedReducer = persistReducer(persistConfig, reducers);

export const store: Store = enhancer(persistedReducer);
export const persistor = persistStore(store);
