import * as React from 'react';

import IconNavigationNext2 from '@icons/nova-line/17-Navigation/navigation-next-2.svg';
import {
   ActivityBuilderMode,
   ActivityItemVisibility,
   VocabSetItem,
   VocabSetItemDisplay,
} from '@models/Activity';

import Constants from '../../../../Constants';
import Link from '@components/Common/Link';

interface VocabSetItemOptionsProps {
   item: VocabSetItem<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<VocabSetItem<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const VocabSetItemOptions: React.FC<VocabSetItemOptionsProps> = ({ item, handleItemUpdate }) => {
   const {
      routes: {
         content: { viewVocabSet },
      },
   } = Constants;

   return (
      <div>
         <div className='margin-top-s'>
            <label className='field-title'>Show</label>
            <select
               name='show'
               value={item.show}
               onChange={(e) =>
                  handleItemUpdate({
                     show: e.target.value as ActivityItemVisibility,
                  })
               }
            >
               <option value={ActivityItemVisibility.always}>Always</option>
               <option value={ActivityItemVisibility.onceClosed}>Once Closed</option>
               <option value={ActivityItemVisibility.onceGraded}>Once Graded</option>
            </select>
         </div>
         <div className='margin-top-s'>
            <label className='field-title'>Display</label>
            <select
               name='display'
               value={item.display}
               onChange={(e) =>
                  handleItemUpdate({
                     display: e.target.value as VocabSetItemDisplay,
                  })
               }
            >
               <option value={VocabSetItemDisplay.inline}>Inline</option>
               <option value={VocabSetItemDisplay.link}>Link</option>
               <option value={VocabSetItemDisplay.overlay}>Overlay</option>
            </select>
         </div>
         {item.vocabSetId && (
            <div className='margin-top-s align-items-center'>
               <Link
                  to={viewVocabSet.replace(':vocabSetId', item.vocabSetId?.toString())}
                  className='strong-text'
                  target='_blank'
                  rel='noopener noreferrer'
               >
                  Go to Vocab Set
                  <IconNavigationNext2 height='12' width='17' className='icon-blue' />
               </Link>
            </div>
         )}
      </div>
   );
};

export default VocabSetItemOptions;
