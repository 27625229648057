import * as React from 'react';

import { parseHtml } from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import {
   ActivityCompleterMode,
   ActivityMode,
   MultipleChoiceOption as MultipleChoiceOptionType,
   MultipleChoiceResponseEntry,
} from '@models/Activity';
import { Maybe } from '@models/Core';
import classNames from 'classnames';

interface MultipleChoiceOptionProps {
   anyCorrect: boolean;
   checked: boolean;
   disabled: boolean;
   groupName?: string;
   multiple: boolean;
   option: MultipleChoiceOptionType<ActivityCompleterMode | ActivityMode.grade>;
   responseEntry: Maybe<MultipleChoiceResponseEntry>;
   showMissed?: boolean;
   showFeedback?: boolean;
   onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

const MultipleChoiceOption: React.FC<MultipleChoiceOptionProps> = ({
   anyCorrect,
   checked,
   disabled,
   groupName,
   multiple,
   option,
   responseEntry,
   showMissed,
   showFeedback,
   onChange,
}) => {
   const inputClassName = (): string => {
      const isGraded = Object.prototype.hasOwnProperty.call(option, 'score');
      if (anyCorrect || !isGraded || responseEntry?.modified) {
         return '';
      } else if (responseEntry && checked) {
         return option.score || responseEntry?.correct ? 'correct' : 'incorrect';
      } else if (showMissed) {
         return option.score ? 'missed' : '';
      }
      return '';
   };

   return (
      <>
         <div className={classNames('align-items-center', { disabled })}>
            <input
               className={classNames(
                  inputClassName(),
                  { square: multiple },
                  'flex-fixed margin-left-right-s',
               )}
               disabled={disabled}
               onChange={onChange}
               defaultChecked={checked}
               name={multiple ? '' : groupName}
               type={multiple ? 'checkbox' : 'radio'}
            />
            {option.data ? ensureElement(parseHtml(option.data), 'p') : <p>&nbsp;</p>}
         </div>
         {showMissed && showFeedback && checked && !!option.feedback && (
            <div className='option-feedback'>{parseHtml(option.feedback)}</div>
         )}
      </>
   );
};

export default MultipleChoiceOption;
