import * as React from 'react';

import { altFormatSeconds } from '@helpers/FormatTime';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Constants from '../../Constants';
import { LanguageLookup } from '@models/Language';
import Button from '@components/Common/Button';
import { ProgressBar } from '@components/Core/ProgressBar';
import { IVocabSessionContext, VocabSessionContext } from './VocabSession';

const ENTER_ANIMATION_CLASS = 'animated slideInRight faster';

const Summary: React.FC = () => {
   const { vocabLearnedLevel } = Constants;

   const {
      sessionColor,
      state: {
         cards,
         index,
         progress,
         settings: { language },
         stats: { timeSpent, correct, incorrect },
         terms,
      },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const termsSeen = new Set(cards.slice(0, index + 1).map((i) => i.termId));

   const navigate = useNavigate();

   const navigateBack = () => navigate(-1);

   return (
      <div className={classnames('card session-summary', ENTER_ANIMATION_CLASS)}>
         <div className={classnames('card-title full-width has-button', sessionColor)}>
            <div className='title'>Session Complete!</div>
            <Button line onClick={navigateBack}>
               Next
            </Button>
         </div>
         <div className='summary-score'>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <div className='title'>Correct Answers</div>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <p>{correct}</p>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <div className='title'>Speed</div>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <p>{altFormatSeconds(timeSpent)}</p>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-12 col-sm-4'>
                  <div className='title'>Accuracy</div>
               </div>
               <div className='col-xs-12 col-sm-4'>
                  <p>{Math.floor((correct / (correct + incorrect)) * 100)}%</p>
               </div>
            </div>
         </div>
         <div className='summary-table'>
            <table className='rwd-table'>
               <tbody>
                  <tr className='head-row'>
                     <th>{LanguageLookup[language]}</th>
                     <th>English</th>
                     <th>Memory Strength</th>
                     <th>Accuracy</th>
                     <th>Current Streak</th>
                  </tr>
                  {Object.values(progress)
                     .filter((i) => termsSeen.has(i.id))
                     .map(({ streak, id, correct: termCorrect, attempts, level }) => {
                        const { term, definition, image } = terms[id];
                        return (
                           <tr key={id}>
                              <td data-th={LanguageLookup[language]}>
                                 {!!image && language === 'asl' && (
                                    <div className='term-image-wrapper'>
                                       <img alt={term} src={image.src} />
                                    </div>
                                 )}
                                 {!!term && term}
                              </td>
                              <td data-th='English'>{definition}</td>
                              <td data-th='Memory Strength'>
                                 <ProgressBar
                                    percentage={Math.min(1.0, level / vocabLearnedLevel) * 100}
                                    color={sessionColor}
                                 />
                              </td>
                              <td data-th='Accuracy'>
                                 {termCorrect}/{attempts}
                              </td>
                              <td data-th='Current Streak'>{streak}</td>
                           </tr>
                        );
                     })}
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default Summary;
