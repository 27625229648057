import html2canvas from 'html2canvas';

export type ScreenshotOptions = {
   imageType: 'webp' | 'png' | 'jpeg';
   quality: number;
};

/**
 * @param selector Default: 'body'
 * @param opts Default: { imageType: 'png', quality: 0.85 }
 * @returns imageDataUrl
 */
export const takeScreenshot = async (
   selector = 'body',
   opts: ScreenshotOptions = { imageType: 'png', quality: 0.85 },
): Promise<Blob> => {
   if (opts.quality < 0 || opts.quality > 1) {
      throw new Error('Quality must be between 0 <-> 1');
   }

   const element: HTMLElement | null = document.querySelector(selector);

   if (!element) {
      throw new Error("Couldn't find the selector");
   }

   const canvas = await html2canvas(element);

   const imageBlob: Blob | null = await new Promise((resolve) => {
      canvas.toBlob((blob) => resolve(blob), `image/${opts.imageType}`, opts.quality);
   });

   if (!imageBlob) {
      throw new Error('Failed to convert canvas to blob');
   }

   return imageBlob;
};
