import { randomShortId } from '@helpers/RandomStringUtils';

import {
   OperatorType,
   PrimitiveType,
   StringComparison,
   StringPredicate,
} from '@components/PredicateEditor/Models';

const notLingcoPredicate: StringPredicate = {
   attribute: 'school.name',
   comparison: StringComparison.doesNotEqual,
   id: randomShortId(),
   type: PrimitiveType.string,
   value: 'Lingco Language Labs',
};

export const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [notLingcoPredicate],
};
