import * as React from 'react';

import VideoPlayer from '@components/Core/VideoPlayer';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import { Maybe } from '@models/Core';
import { VideoGetResponse } from '@models/Video';
import VideoService from '@services/VideoService';
import { useParams } from 'react-router-dom';

const VideoViewer: React.FC = () => {
   const { videoId: strVideoId } = useParams<{ videoId: string }>();

   const videoId = strVideoId ? parseInt(strVideoId) : null;

   const [video, setVideo] = React.useState<Maybe<VideoGetResponse>>();

   React.useEffect(() => {
      if (videoId) {
         VideoService.get(videoId).then(setVideo);
      }
   }, [videoId]);

   if (!video) {
      return <Loader />;
   }

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>{video.name}</DocumentTitle>
         <div className='card bottom-padding no-padding'>
            <div className='card-title has-button'>
               <div className='title'>{video.name}</div>
            </div>
            <VideoPlayer
               videoUrl={video.url}
               posterUrl={video.imageUrl}
               nativeSubtitles={video.nativeLanguageCaptions}
               targetSubtitles={video.targetLanguageCaptions}
               nativeLanguage='en'
               targetLanguage={video.language}
               showOverlay
            />
         </div>
      </div>
   );
};

export default VideoViewer;
