import * as React from 'react';

import { ProgressBar } from '@components/Core/ProgressBar';
import { IVocabSessionContext, VocabSessionContext } from './VocabSession';

interface IFooterProps {
   show: boolean;
}

const Footer: React.FC<IFooterProps> = ({ show }) => {
   const {
      state: { cards, index },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   if (show) {
      return (
         <div className='progress-bar-wrap'>
            <ProgressBar animate percentage={(index / cards.length) * 100} />
         </div>
      );
   }

   return <></>;
};

export default React.memo(Footer);
