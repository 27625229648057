import { isPrompt, isVideoContent } from '@components/Activity/Utils';
import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import {
   ActivityBuilderMode,
   ActivityMode,
   ActivityPrompt,
   ActivityQuestion,
   VideoContent,
} from '@models/Activity';
import { ID, Maybe } from '@models/Core';
import WorldLocation from '@models/WorldLocation';
import _ from 'lodash';

export const PRECISION = 1000;
export const getPartialLocationFromLocation = (
   location: Maybe<WorldLocation>,
): Pick<SearchBoxSuggestion, 'name' | 'place_formatted'> | undefined =>
   location
      ? {
           name: location?.name,
           // eslint-disable-next-line camelcase
           place_formatted: location?.placeFormatted,
        }
      : undefined;

export const getAllInteractivePromptsForQuestion = <Mode extends ActivityMode>(
   question: ActivityQuestion<Mode>,
): ID[] => {
   const interactiveVideoItems = question.items.filter(
      (i) => isVideoContent(i) && i.isInteractiveVideo,
   ) as VideoContent<Mode>[];

   const definedPromptTimes = _.flatMap(interactiveVideoItems, (x) => x.promptTimes ?? []);

   return definedPromptTimes.map((x) => x.promptId);
};

export const getPromptsForInteractiveVideoFromQuestion = <Mode extends ActivityMode>(
   question: ActivityQuestion<Mode>,
   interactiveVideoItem: VideoContent<Mode>,
): ActivityPrompt<ActivityMode>[] => {
   const interactiveItems = question.items.filter(
      (x) => interactiveVideoItem?.promptTimes?.find((y) => y.promptId === x.id) !== undefined,
   );

   const interactivePrompts = interactiveItems.filter(isPrompt);
   return interactivePrompts;
};

export const getInteractiveVideoSeconds = (
   promptId: string | number | null,
   interactiveVideoItem: VideoContent<ActivityBuilderMode>,
): number => {
   const promptTime = interactiveVideoItem.promptTimes?.find((x) => x.promptId === promptId);
   return promptTime?.promptTimeInSeconds ?? 0;
};

export const convertPrecisionToSeconds = (value: number): number => value / PRECISION;

export const convertToPrecision = (value: number): number => {
   const result = Math.round(value * PRECISION);
   return result;
};
