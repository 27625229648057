import * as React from 'react';

import IconClose from '@icons/nova-solid/02-Status/close.svg';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import { IOnboardingStep } from './Models';
import Overlay, { IOverlayProps } from './Overlay';

interface PointerProps {
   arrowStyle?: React.CSSProperties;
   showBackButton?: boolean;
   index: number;
   step: IOnboardingStep;
   size?: number;
   overlayOptions?: IOverlayProps;
   style: React.CSSProperties;
   arrowRef(element: HTMLDivElement): void;
   elementRef?(element: HTMLDivElement): void;
   onBackClick?(): void;
   onCloseClick(): void;
   onPrimaryClick(): void;
}

const Pointer: React.FC<PointerProps> = ({
   arrowRef,
   arrowStyle,
   elementRef,
   index,
   size,
   showBackButton = false,
   step: {
      content,
      footer = true,
      overlay = true,
      backButton: stepBackButton = true,
      primaryButton = true,
      author = null,
   },
   overlayOptions = {},
   style = {},
   onBackClick,
   onCloseClick,
   onPrimaryClick,
   ...props
}) => {
   const [isScrolling, setIsScrolling] = React.useState<boolean>(false);
   const [buttonClassName, setButtonClassName] = React.useState<string>('');

   React.useEffect(() => {
      setButtonClassName('');
   }, [index]);

   const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      setIsScrolling(!!(event.target as HTMLDivElement).scrollTop);
   };

   const handleOverlayClick = (event: React.MouseEvent): void => {
      event.stopPropagation();
      if (overlayOptions?.disableInteraction !== false) {
         setButtonClassName('animated tada');
      }
   };

   const handleAnimationEnd = (): void => {
      setButtonClassName('');
   };

   const backButtonEnabled = showBackButton && stepBackButton !== false && index > 0 && onBackClick;

   return (
      <>
         {overlay && <Overlay {...overlayOptions} onOverlayClick={handleOverlayClick} />}
         <div className='onboarding-pointer-wrapper' style={style} ref={elementRef} {...props}>
            <div className='onboarding-pointer'>
               <div
                  className={classnames('onboarding-pointer-header', {
                     scrolling: isScrolling,
                  })}
               >
                  {!!author && (
                     <>
                        <div className='pointer-avatar'>
                           <img src={author.imageUrl} />
                        </div>
                        <div className='pointer-author-wrapper'>
                           <div className='pointer-author'>
                              <span className='name'>{author.name}</span>
                              <span className='company'>{` from ${author.company}`}</span>
                           </div>
                        </div>
                     </>
                  )}
                  <div className='close'>
                     <IconClose onClick={onCloseClick} />
                  </div>
               </div>
               <div className='onboarding-pointer-body' onScroll={handleScroll}>
                  {content}
               </div>
               {footer && (
                  <div
                     className={classnames('onboarding-pointer-footer', {
                        'back-enabled': backButtonEnabled,
                     })}
                  >
                     {backButtonEnabled && (
                        <div className='previous-button-wrapper'>
                           <Button line onClick={onBackClick}>
                              Back
                           </Button>
                        </div>
                     )}
                     <div className='step'>{`${index + 1} of ${size}`}</div>
                     {primaryButton && (
                        <div className='next-button-wrapper'>
                           <Button
                              onClick={onPrimaryClick}
                              className={buttonClassName}
                              onAnimationEnd={handleAnimationEnd}
                           >
                              {index + 1 === size ? 'Done' : 'Next'}
                           </Button>
                        </div>
                     )}
                  </div>
               )}
            </div>
            <div ref={arrowRef} style={arrowStyle} className='onboarding-pointer-arrow' />
         </div>
      </>
   );
};

export default React.memo(Pointer);
