/* eslint-disable complexity */
import * as React from 'react';

import { CommandCategory } from '@components/CommandPalette/Models';
import ModalDialog from '@components/Core/ModalDialog';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { isInteger } from '@helpers/NumberUtils';
import useCommands from '@hooks/use-commands';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { Breadcrumb } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { Course, CoursePermissions } from '@models/Course';
import CourseService from '@services/CourseService';
import HttpService from '@services/HttpService';
import UserService from '@services/UserService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import CourseDescription from './CourseDescription';
import CourseDetailsModal from './CourseDetailsModal';
import DashboardCalendar from './DashboardCalendar';
import DashboardTaskList from './DashboardTasksList';
import ManageCourseSidebar from './ManageCourseSidebar';

export interface AssignmentProfile {
   contentId: number;
   contentName: string;
   endDate: Date;
   itemType: ContentType;
   moduleId: number;
   moduleItemId: number;
   startDate: Date;
   submissionId?: number;
}

interface FetchCourseResponse extends Course {
   assignments: readonly AssignmentProfile[];
   permissions: CoursePermissions;
   isProficiencyCourse: boolean;
}

const CourseDashboard: React.FC = () => {
   const {
      routes: {
         courses: {
            dashboard,
            create,
            join,
            instructorSummary: courseProficiency,
            vocabStats: vocabStatsLink,
            canDoStatements,
         },
      },
   } = Constants;
   const navigate = useNavigate();
   const { pathname } = useLocation();
   const { courseId: strCourseId = null } = useParams<{ courseId: string }>();

   const courseId = isInteger(strCourseId) ? Number(strCourseId) : null;

   const {
      breadcrumbs: prevBreadcrumbs,
      currentCourses,
      userProfile,
      setBreadcrumbs,
   } = React.useContext<AppStateContext>(AppStateContext);

   const { walkthrough, taskState, startWalkthrough } =
      React.useContext<IOnboardingProps>(OnboardingContext);

   if (!userProfile) {
      return null;
   }

   const { accountType, isAdmin = false } = userProfile;

   const [assignments, setAssignments] = React.useState<readonly AssignmentProfile[]>([]);
   const [course, setCourse] = React.useState<Maybe<Course>>(null);
   const [courseDetailsModalOpen, setCourseDetailsModalOpen] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isLeaveCourseModalOpen, setIsLeaveCourseModalOpen] = React.useState<boolean>(false);
   const [permissions, setPermissions] = React.useState<Maybe<CoursePermissions>>(null);
   const [isProficiencyEnabled, setIsProficiencyEnabled] = React.useState<boolean>(false);
   const [isProficiencyCourse, setIsProficiencyCourse] = React.useState<boolean>(false);

   const showVocabStats = (course?.showVocabStats && !course?.template) ?? false;

   // 'isFetching' lags a tick behind route state (courseId) so we can't always use 'isFetching' to determine
   // if a course is currently loaded. Comparing the route courseId to the state course.id is more reliable
   const courseHasLoaded = courseId === course?.id;

   React.useEffect(() => {
      UserService.checkFeatureLocalStorage('proficiency_tracking').then(setIsProficiencyEnabled);
   }, []);

   React.useEffect(() => {
      if (courseId !== null) {
         fetchCourse();
      } else if (currentCourses.length > 0) {
         const firstCourseId = currentCourses[0].id;
         navigate(dashboard.replace(':courseId', firstCourseId.toString()), {
            replace: true,
         });
      } else {
         navigate(accountType === AccountType.instructor ? create : join, {
            replace: true,
         });
      }
   }, [courseId]);

   React.useEffect(() => {
      if (
         walkthrough &&
         [OnboardingWalkthrough.assignActivity, OnboardingWalkthrough.gradeActivity].includes(
            walkthrough?.id,
         ) &&
         taskState === OnboardingTaskState.initialized &&
         pathname === walkthrough.startRoute &&
         courseHasLoaded
      ) {
         startWalkthrough();
      }
   }, [pathname, taskState, walkthrough?.id, courseHasLoaded]);

   useCommands(
      [
         {
            id: 'course_details',
            action: () => openCourseDetailsModal(),
            title: 'Course Details',
         },
         {
            id: 'course_proficiency',
            link: courseId ? courseProficiency.replace(':courseId', courseId.toString()) : '',
            category: CommandCategory.navigation,
            title: 'Jump to Course Proficiency',
            showIf: () => isProficiencyEnabled && !!courseId,
         },
         {
            id: 'vocab_stats',
            link: courseId ? vocabStatsLink.replace(':courseId', courseId.toString()) : '',
            category: CommandCategory.navigation,
            title: 'Jump to Vocab Stats',
            showIf: () => showVocabStats,
         },
         {
            id: 'course_can_do_statements',
            link: courseId ? canDoStatements.replace(':courseId', courseId.toString()) : '',
            title: 'Manage Course Can-Do Statements',
            showIf: () => isProficiencyEnabled && !!courseId,
         },
      ],
      [isProficiencyEnabled, showVocabStats, courseId],
   );

   const fetchCourse = (): Promise<void> => {
      if (!courseId) {
         return Promise.resolve();
      }
      setIsFetching(true);
      setBreadcrumbs({ ...prevBreadcrumbs, next: null, prev: null });
      return HttpService.getWithAuthToken<FetchCourseResponse>(
         `/api/courses/${courseId}?assignments=true`,
      ).then((response) => {
         const {
            assignments: responseAssignments,
            permissions: responsePermissions,
            ...responseCourse
         } = response.data;
         setAssignments(responseAssignments);
         setCourse(responseCourse);
         setIsProficiencyCourse(responseCourse.isProficiencyCourse);
         setIsFetching(false);
         setPermissions(responsePermissions);
         const breadcrumbs: readonly Breadcrumb[] = [
            {
               link: dashboard.replace(':courseId', courseId.toString()),
               text: responseCourse.name,
               contextInfo: {
                  courseId,
                  courseArchived: responseCourse.archived,
                  courseIsEditable: responseCourse.canEdit,
                  courseIsProtected: responseCourse.isProtected,
               },
            },
         ];
         setBreadcrumbs({ breadcrumbs, prev: null, next: null });
      });
   };

   const resetStudentCourseCode = async (): Promise<void> => {
      if (courseId && permissions?.canEdit) {
         return CourseService.resetStudentCourseCode(courseId).then((courseCode) => {
            setCourse((prevCourse) => (prevCourse ? { ...prevCourse, courseCode } : prevCourse));
         });
      }
      return Promise.resolve();
   };

   const resetSectionCourseCode = (sectionId: number): Promise<void> => {
      if (courseId && permissions?.canEdit) {
         return CourseService.resetSectionCourseCode(courseId, sectionId).then((courseCode) => {
            setCourse((prevCourse) =>
               prevCourse
                  ? {
                       ...prevCourse,
                       sections: prevCourse.sections.map((i) =>
                          i.id === sectionId ? { ...i, courseCode } : i,
                       ),
                    }
                  : prevCourse,
            );
         });
      }
      return Promise.resolve();
   };

   const leaveCourse = (): Promise<void> => {
      if (courseId) {
         CourseService.leaveCourse(courseId).then(({ courses: { current } }) => {
            closeLeaveCourseModal();
            if (current.length > 0) {
               const firstCourseId = current[0].id;
               navigate(dashboard.replace(':courseId', firstCourseId.toString()), {
                  replace: true,
               });
            } else {
               navigate(accountType === AccountType.instructor ? create : join);
            }
         });
      }
      return Promise.resolve();
   };

   const closeLeaveCourseModal = (): void => setIsLeaveCourseModalOpen(false);

   const openLeaveCourseModal = (): void => setIsLeaveCourseModalOpen(true);

   const openCourseDetailsModal = (): void => setCourseDetailsModalOpen(true);

   const closeCourseDetailsModal = (): void => setCourseDetailsModalOpen(false);

   if (isFetching || !course || !courseId) {
      return <Loader />;
   }

   const shouldShowCloneCourseOnSidebar = () => course.template && !isAdmin;

   const shouldShowEditCourseOnSidebar = () =>
      (permissions?.canEdit && !course.template) || isAdmin;

   return (
      <>
         <DocumentTitle>{isFetching ? 'Loading Course...' : course?.name}</DocumentTitle>
         <div className='row'>
            <div
               className='col-xs-12 col-md-8 content-container-sidebar'
               data-test='content-container-sidebar'
            >
               <CourseDescription
                  course={course}
                  canModifyRoster={permissions?.canModifyRoster}
                  openCourseDetailsModal={openCourseDetailsModal}
               />
               {!course.isNationalExamPractice && accountType === AccountType.instructor && (
                  <DashboardTaskList courseId={courseId} />
               )}
               <DashboardCalendar assignments={assignments} />
            </div>
            <div
               className='col-xs-12 col-md-4 right-sidebar-wrapper'
               data-test='course-dashboard-right-sidebar'
            >
               <ManageCourseSidebar
                  accountType={accountType}
                  canLeaveCourse={permissions?.canLeaveCourse}
                  canViewRoster={permissions?.canViewRoster}
                  courseId={courseId}
                  courseLanguage={course.language}
                  courseName={course.name}
                  isProficiencyCourse={isProficiencyCourse}
                  leaveCourse={openLeaveCourseModal}
                  shouldHideStudentGradebook={course.shouldHideStudentGradebook}
                  shouldHideInstructorGradebook={course.shouldHideInstructorGradebook}
                  showCloneCourse={shouldShowCloneCourseOnSidebar()}
                  showEditCourse={shouldShowEditCourseOnSidebar()}
                  showProficiency={isProficiencyEnabled}
                  showVocabStats={showVocabStats}
               />
            </div>
         </div>
         {courseDetailsModalOpen && (
            <CourseDetailsModal
               course={course}
               onClose={closeCourseDetailsModal}
               resetSectionCourseCode={resetSectionCourseCode}
               resetStudentCourseCode={resetStudentCourseCode}
            />
         )}
         {isLeaveCourseModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Leave Course'
               onClose={closeLeaveCourseModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Leave', onClick: leaveCourse },
                  { text: 'Cancel', onClick: closeLeaveCourseModal },
               ]}
            >
               <p>Are you sure that you want to leave {course.name}?</p>
               <p>
                  This action cannot be undone. This will delete ALL of your course data including
                  submissions.
               </p>
            </ModalDialog>
         )}
      </>
   );
};

export default CourseDashboard;
