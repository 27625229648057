import { getMedian } from '@helpers/MathUtils';
import { Breadcrumb } from '@models/Breadcrumbs';
import {
   CourseCanDoStatementSummary,
   isSkill,
   ProficiencyReportTileBreakdownBySkill,
   ProficiencySkillsPlusOverall,
   ProficiencySummaryRow,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';
import moment from 'moment';

import Constants from '../../Constants';

export type WeeksWithIndex = {
   weekIndex: number;
   endDate: string;
   startDate: string;
};

export const getWeekBreakdownOfDateRange = (startDate: Date, endDate: Date): WeeksWithIndex[] => {
   const startMoment = moment(startDate).startOf('isoWeek');
   const endMoment = moment(endDate).endOf('isoWeek');

   const diff = moment.duration(endMoment.diff(startMoment));
   const numberOfWeeks = Math.ceil(diff.asWeeks());

   const weeks: WeeksWithIndex[] = [];
   for (let i = 0; i < numberOfWeeks; i++) {
      const newStartOfWeek = moment(startMoment).add(i, 'week');
      const newEndOfWeek = moment(newStartOfWeek).endOf('isoWeek');
      const newWeek: WeeksWithIndex = {
         weekIndex: i,
         startDate: newStartOfWeek.format('L'),
         endDate: newEndOfWeek.format('L'),
      };
      weeks.push(newWeek);
   }

   return weeks;
};

export const getProficiencyReportTileData = <T extends ProficiencySummaryRow>(
   courseCanDoStatementSummaries: CourseCanDoStatementSummary[],
   courseEndDate: Date,
   courseStartDate: Date,
   summary: T[],
): ProficiencyReportTileBreakdownBySkill => {
   const skillBreakdown = getReportTileBreakdownBySkill(
      courseStartDate,
      courseEndDate,
      courseCanDoStatementSummaries,
   );

   // getReportTileAverageBySkill(numberOfStudents, skillBreakdown, summary);
   getReportTileMedianBySkill(skillBreakdown, summary);

   return skillBreakdown;
};

type completedCanDosBySkill = {
   [key in ProficiencySkillsPlusOverall]: number[];
};

export const getReportTileMedianBySkill = <T extends ProficiencySummaryRow>(
   skillBreakdown: ProficiencyReportTileBreakdownBySkill,
   summary: T[],
): void => {
   if (!summary.length) return;

   const completedCanDosBySkill: completedCanDosBySkill = [...summary].reduce(
      (prev, row) => {
         prev.Overall.push(row.countOfCompletedCanDoStatements);
         prev[row.skill].push(row.countOfCompletedCanDoStatements);
         return prev;
      },
      {
         Overall: [],
         Reading: [],
         Writing: [],
         Listening: [],
         Speaking: [],
      } as completedCanDosBySkill,
   );

   Object.entries(completedCanDosBySkill).forEach(([key, val]) => {
      if (isSkill(key) || key === 'Overall') {
         skillBreakdown[key].completedAverage = Math.round(getMedian(val) || 0);
      }
   });
};

export const getReportTileAverageBySkill = <T extends ProficiencySummaryRow>(
   numberOfStudents: number,
   skillBreakdown: ProficiencyReportTileBreakdownBySkill,
   summary: T[],
): void => {
   summary.forEach((row) => {
      row.skill;
      row.countOfCompletedCanDoStatements;

      skillBreakdown[row.skill].completedAverage = row.countOfCompletedCanDoStatements;
      skillBreakdown.Overall.completedAverage = row.countOfCompletedCanDoStatements;
   });

   Object.values(skillBreakdown).map((row) => {
      if (row.completedAverage > 0) {
         row.completedAverage = Math.round(row.completedAverage / numberOfStudents);
      }
   });
};

export const getReportTileBreakdownBySkill = (
   courseStartDate: Date,
   courseEndDate: Date,
   courseCanDoStatementSummaries: CourseCanDoStatementSummary[],
): ProficiencyReportTileBreakdownBySkill => {
   const weeks = getWeekBreakdownOfDateRange(courseStartDate, courseEndDate);
   const currentEndOfWeek = moment().endOf('isoWeek');
   const skillBreakdown: ProficiencyReportTileBreakdownBySkill = {
      Overall: {
         completedAverage: 0,
         presentedCount: 0,
         totalCount: 0,
      },
      Reading: {
         completedAverage: 0,
         presentedCount: 0,
         totalCount: 0,
      },
      Writing: {
         completedAverage: 0,
         presentedCount: 0,
         totalCount: 0,
      },
      Listening: {
         completedAverage: 0,
         presentedCount: 0,
         totalCount: 0,
      },
      Speaking: {
         completedAverage: 0,
         presentedCount: 0,
         totalCount: 0,
      },
   };

   courseCanDoStatementSummaries.forEach((row) => {
      const weekData = weeks.find((d) => d.weekIndex === row.weekIndexOfExpectedCompletion);

      if (
         !weekData ||
         (weekData && moment(weekData.endDate).isSameOrBefore(currentEndOfWeek, 'day'))
      ) {
         skillBreakdown[row.canDoStatementSkill].presentedCount += 1;
         skillBreakdown.Overall.presentedCount += 1;
      }

      skillBreakdown[row.canDoStatementSkill].totalCount += 1;
      skillBreakdown.Overall.totalCount += 1;
   });

   return skillBreakdown;
};

export const districtReportLinkReplace = (
   link: string,
   params: buildDistrictReportBreadcrumbsParams,
): string => {
   if (params.standardCourseId) {
      link = link.replace(':standardCourseId', params.standardCourseId);
   }

   if (params.organizationId) {
      link = link.replace(':organizationId', params.organizationId);
   }

   if (params.instructorId) {
      link = link.replace(':instructorId', params.instructorId);
   }

   if (params.studentId) {
      link = link.replace(':studentId', params.studentId);
   }

   if (params.courseId) {
      link = link.replace(':courseId', params.courseId);
   }

   return link;
};

type buildDistrictReportBreadcrumbsParams = {
   courseId?: string;
   instructorId?: string;
   reportName?: string;
   organizationId?: string;
   standardCourseId?: string;
   studentId?: string;
};

export const buildDistrictReportBreadcrumbs = async (
   params?: buildDistrictReportBreadcrumbsParams,
): Promise<Breadcrumb[]> => {
   // If there is a school id in params it must be a district admin viewing the page
   const breadcrumbs: Breadcrumb[] = [
      {
         link: Constants.routes.proficiencyTracking.districtSummary,
         text: 'District Summary',
      },
   ];

   if (!params || !params?.standardCourseId) {
      return breadcrumbs;
   }

   const result = await ProficiencyReportService.getDistrictReportBreadcrumbs(
      params.standardCourseId,
      params.organizationId,
      params.instructorId,
   );

   if (params.standardCourseId) {
      breadcrumbs.push({
         link: districtReportLinkReplace(
            Constants.routes.proficiencyTracking.programSummary,
            params,
         ),
         text: result.programReportName,
      });
   }

   if (params.standardCourseId && params.organizationId) {
      breadcrumbs.push({
         link: districtReportLinkReplace(
            Constants.routes.proficiencyTracking.schoolSummary,
            params,
         ),
         text: result.schoolReportName,
      });
   }

   if (params.standardCourseId && params.instructorId) {
      breadcrumbs.push({
         link: districtReportLinkReplace(
            Constants.routes.proficiencyTracking.instructorSummary,
            params,
         ),
         text: result.instructorReportName,
      });
   }

   if (params.standardCourseId && params.courseId && params.studentId) {
      breadcrumbs.push({
         link: districtReportLinkReplace(
            Constants.routes.proficiencyTracking.districtStudentSummary,
            params,
         ),
         text: params.reportName || '',
      });
   }

   return breadcrumbs;
};
