import { Maybe } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import { NationalExamReportTaskResponse } from '@models/NationalExam';
import { AxiosResponse } from 'axios';

import { ActivityRecovery } from '@components/Activity/Builder/ActivityBuilder';
import ContentLibraryLayout from './ContentLibraryLayout';
import IUserInfo from './IUserInfo';
import { NationalExamAbbreviation } from './NationalExam';

export interface PostLoginRedirect {
   expiration: string | Date;
   path: string;
}

export interface DowntimeBanner {
   messageStore?: string;
   dismissed: boolean;
}

export interface IApplicationState {
   activityRecovery: Maybe<ActivityRecovery>;
   archivedCourses: readonly BasicCourseProfile[];
   availableNationalExams: readonly NationalExamAbbreviation[];
   contentLibraryLayout: ContentLibraryLayout;
   currentCourses: readonly BasicCourseProfile[];
   hideDowntimeBanner: DowntimeBanner;
   hideHelpFab: boolean;
   networkError: Maybe<AxiosResponse>;
   postLoginRedirect: Maybe<PostLoginRedirect>;
   reportTaskResponse: Maybe<NationalExamReportTaskResponse>;
   user: Maybe<IUserInfo>;
}

export const defaultState: IApplicationState = {
   activityRecovery: null,
   archivedCourses: [],
   availableNationalExams: [],
   contentLibraryLayout: ContentLibraryLayout.grid,
   currentCourses: [],
   hideDowntimeBanner: { messageStore: undefined, dismissed: false },
   hideHelpFab: false,
   networkError: null,
   postLoginRedirect: null,
   reportTaskResponse: null,
   user: null,
};
