import * as React from 'react';

import autobind from '@helpers/autobind';
import { Maybe } from '@models/Core';

import { Color } from '@components/Common/Color';

interface ProgressBarProps {
   color?: Color;
   className?: string;
   animate?: boolean;
   percentage: number;
}

interface ProgressBarState {
   percentage: number;
}

class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
   static defaultProps = {
      className: '',
      animate: false,
   };
   initialTimeout: Maybe<NodeJS.Timeout>;
   requestAnimationFrame: Maybe<number>;

   constructor(props: ProgressBarProps) {
      super(props);
      autobind(this);

      this.state = {
         percentage: 0,
      };
   }

   componentDidMount(): void {
      this.initializeProgressBar();
   }

   componentDidUpdate(prevProps: ProgressBarProps): void {
      if (prevProps.percentage !== this.props.percentage) {
         this.initializeProgressBar();
      }
   }

   componentWillUnmount(): void {
      if (this.initialTimeout) {
         clearTimeout(this.initialTimeout);
      }
      if (this.requestAnimationFrame) {
         window.cancelAnimationFrame(this.requestAnimationFrame);
      }
   }

   initializeProgressBar(): void {
      const { percentage, animate } = this.props;
      if (animate) {
         this.initialTimeout = setTimeout(() => {
            this.requestAnimationFrame = window.requestAnimationFrame(() => {
               this.setState({ percentage });
            });
         }, 0);
      } else {
         this.setState({ percentage });
      }
   }

   render(): React.ReactNode {
      const { className, color } = this.props;
      const { percentage } = this.state;

      return (
         <div className={`progress-bar ${className ? className : ''}`}>
            <div
               className={`progress-bar-filled ${color ? color : ''}`}
               style={{ width: `${Math.min(percentage, 100)}%` }}
            />
         </div>
      );
   }
}

export default ProgressBar;
