import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import { ActivityBuilderMode, TextContent as TextContentType } from '@models/Activity';
import Tippy from '@tippyjs/react';

import { ActivityConsumer } from '@components/Activity/Builder/ActivityBuilder';
import ItemOptions from '@components/Activity/Builder/ItemOptions';
import { BaseItemProps } from '@components/Activity/Builder/Question';
import TextContentOptions from './TextContentOptions';

interface TextContentProps extends BaseItemProps {
   item: TextContentType<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<TextContentType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const TextContent: React.FC<TextContentProps> = ({
   item,
   duplicateItem,
   handleItemUpdate,
   removeItem,
}) => (
   <div className='activity-builder-row'>
      <div className='align-items-center'>
         <div className='flex-grow-1'>
            <ActivityConsumer>
               {({ language }) => (
                  <Editor
                     className='small-p-margins'
                     language={language}
                     onChange={(text) => handleItemUpdate({ text })}
                     rtl={language === 'ar'}
                     toolbarAppearance={ToolbarAppearance.sleek}
                     value={item.text}
                  />
               )}
            </ActivityConsumer>
         </div>
         <div className='flex-end margin-left-s'>
            <div className='weight-actions-wrapper'>
               <div className='icon-action-wrap'>
                  <ItemOptions title='Text Options'>
                     <TextContentOptions item={item} handleItemUpdate={handleItemUpdate} />
                  </ItemOptions>
                  <Tippy content='Duplicate Item' delay={[500, 0]}>
                     <div
                        aria-label='Duplicate Item'
                        className='icon-action'
                        onClick={() => duplicateItem()}
                        onKeyDown={(e) => e.key === 'Enter' && duplicateItem()}
                        role='button'
                        tabIndex={0}
                     >
                        <IconFileCopy />
                     </div>
                  </Tippy>
                  <Tippy content='Delete Item' delay={[500, 0]}>
                     <div
                        aria-label='Delete Item'
                        className='icon-action'
                        onClick={() => removeItem()}
                        onKeyDown={(e) => e.key === 'Enter' && removeItem()}
                        role='button'
                        tabIndex={0}
                     >
                        <IconBin />
                     </div>
                  </Tippy>
               </div>
            </div>
         </div>
      </div>
   </div>
);

export default TextContent;
