import * as React from 'react';

/**
 * `useDebounce` is a custom hook that returns a debounced version of the provided value.
 * The new value is only returned after the specified delay.
 * This is useful, for instance, when you want to implement functionality that should happen
 * some time after an event, like a search bar autocomplete, where you want to wait until
 * the user stops typing to perform an action.
 *
 * @template T - The type of the value that's going to be debounced.
 *
 * @param {T} value - The value that should be debounced.
 * @param {number} delay - The amount of delay in milliseconds before the debounced value should update.
 *
 * @returns {T} - The debounced value.
 *
 * @example
 * const debouncedSearchTerm = useDebounce(searchTerm, 500);
 */
const useDebounce = <T>(value: T, delay: number): T => {
   // State and setters for debounced value
   const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
   React.useEffect(
      () => {
         // Update debounced value after delay
         const handler = setTimeout(() => {
            setDebouncedValue(value);
         }, delay);
         // Cancel the timeout if value changes (also on delay change or unmount)
         // This is how we prevent debounced value from updating if value is changed ...
         // .. within the delay period. Timeout gets cleared and restarted.
         return () => {
            clearTimeout(handler);
         };
      },
      [value, delay], // Only re-call effect if value or delay changes
   );
   return debouncedValue;
};

export default useDebounce;
