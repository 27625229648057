import * as _ from 'lodash';
import * as React from 'react';

import CardBody from '@components/Common/CardBody';
import Link from '@components/Common/Link';
import Table, { Column } from '@components/Common/Table';
import EmptyState from '@components/Core/EmptyState';
import Loader from '@components/Loader';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import { IdName } from '@models/Core';
import NationalExamAdminService from '@services/NationalExamAdminService';

import Constants from '../../Constants';

const Exams: React.FC = () => {
   const [nationalExams, setNationalExams] = React.useState<readonly IdName[]>([]);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);

   React.useEffect(() => {
      NationalExamAdminService.getNationalExams().then((exams) => {
         const orderedExams = _.orderBy(exams, (x) => x.id, 'desc');
         setNationalExams(orderedExams);
         setIsLoading(false);
      });
   }, []);

   const columns: readonly Column<IdName>[] = [
      {
         id: 'id',
         cell: (i) => (
            <Link
               to={Constants.routes.admin.nationalExam.examSummaryDetails.replace(
                  ':examId',
                  i.id.toString(),
               )}
            >
               {i.id}
            </Link>
         ),
         header: 'Exam Id',
      },
      {
         id: 'name',
         cell: (i) => i.name,
         header: 'Exam Name',
      },
   ];

   if (isLoading) {
      return <Loader />;
   }

   return (
      <CardBody>
         {nationalExams.length === 0 ? (
            <EmptyState
               heading='Nothing to show.'
               icon={<IconBinoculars className='large' aria-hidden />}
            />
         ) : (
            <Table<IdName> columns={columns} rowKey='id' rows={nationalExams} />
         )}
      </CardBody>
   );
};

export default Exams;
