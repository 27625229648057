import * as React from 'react';

/**
 * `useInterval` is a custom hook that sets up an interval and clears it after unmounting.
 *
 * @param callback Function to be executed each interval.
 * @param delay Delay in milliseconds for the interval.
 * @param active If true, the interval is active. Default is true.
 *
 * @see {@link https://overreacted.io/making-setinterval-declarative-with-react-hooks/ Source}
 */
const useInterval = (callback: () => void, delay: number, active = true): void => {
   const savedCallback = React.useRef<() => void>();

   // Remember the latest callback.
   React.useEffect(() => {
      savedCallback.current = callback;
   }, [callback]);

   // Set up the interval.
   React.useEffect(() => {
      const tick = (): void => {
         savedCallback.current?.();
      };
      if (delay !== null && active) {
         const id = setInterval(tick, delay);
         return () => clearInterval(id);
      }
   }, [delay, active]);
};

export default useInterval;
