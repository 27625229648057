import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconAlarmClock from '@icons/nova-line/05-Time/alarm-clock.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import CourseEnrollmentService from '@services/CourseEnrollmentService';
import CourseService from '@services/CourseService';
import DateTime from '@services/DateTimeService';
import Tippy from '@tippyjs/react';

import ExtendedCourseEnrollmentProfile from '@models/Course/ExtendedCourseEnrollmentProfile';
import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';

interface CourseEnrollmentActionsCellProps {
   row: ExtendedCourseEnrollmentProfile;
   onUpdate(row: ExtendedCourseEnrollmentProfile): void;
   onDelete(): void;
}

const CourseEnrollmentActionsCell: React.FC<CourseEnrollmentActionsCellProps> = ({
   row,
   onDelete,
   onUpdate,
}) => {
   const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
   const [isExtendTrialModalOpen, setIsExtendTrialModalOpen] = React.useState<boolean>(false);
   const [trialEndOn, setTrialEndOn] = React.useState<Maybe<Date>>(null);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   React.useEffect(() => {
      const updatedTrialEndOn = DateTime.midnight();
      updatedTrialEndOn.setDate(updatedTrialEndOn.getDate() + 7);
      setTrialEndOn(updatedTrialEndOn);
   }, []);

   const closeDeleteModal = (): void => {
      setIsDeleteModalOpen(false);
   };

   const openDeleteModal = (): void => {
      setIsDeleteModalOpen(true);
   };

   const closeExtendTrialModal = (): void => {
      setIsExtendTrialModalOpen(false);
   };

   const openExtendTrialModal = (): void => {
      setIsExtendTrialModalOpen(true);
   };

   const handleExtendTrial = (): Promise<void> => {
      setIsLoading(true);
      if (!trialEndOn) {
         return Promise.resolve();
      }
      return CourseService.updateTrialEndOn(row.course.id, row.user.id, trialEndOn).then(() => {
         setIsLoading(false);
         setIsExtendTrialModalOpen(false);
         onUpdate({ ...row, trialEndOn });
      });
   };

   const handleDeleteCourseEnrollment = (): Promise<void> => {
      setIsLoading(true);
      return CourseEnrollmentService.deleteEnrollment(row.course.id, row.id).then(() => {
         setIsLoading(false);
         setIsDeleteModalOpen(false);
         onDelete();
      });
   };

   return (
      <>
         <div className='table-row-action'>
            <div className='icon-action-wrap'>
               <Tippy disabled={!row.trialEndOn} content='Extend Trial' delay={[400, 0]}>
                  <div className='icon-action' onClick={openExtendTrialModal}>
                     <IconAlarmClock />
                  </div>
               </Tippy>
               <Tippy content='Delete' delay={[400, 0]}>
                  <div className='icon-action' onClick={openDeleteModal}>
                     <IconBin />
                  </div>
               </Tippy>
            </div>
         </div>
         {isDeleteModalOpen && (
            <ModalDialog
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.danger}
               heading='Remove from course'
               onClose={closeDeleteModal}
               actions={[
                  {
                     text: 'Remove',
                     onClick: handleDeleteCourseEnrollment,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closeDeleteModal },
               ]}
            >
               <p>
                  Are you sure that you want to remove {row.user.firstName} {row.user.lastName} from{' '}
                  {row.course.name}?
               </p>
               <p>This action cannot be undone.</p>
            </ModalDialog>
         )}
         {isExtendTrialModalOpen && (
            <ModalDialog
               animations={{ enter: 'animated bounceInDown' }}
               appearance={Appearance.primary}
               bodyClassName='extend-trial-modal'
               heading='Extend Trial'
               onClose={closeExtendTrialModal}
               actions={[
                  {
                     text: 'Extend',
                     onClick: handleExtendTrial,
                     loading: isLoading,
                  },
                  { text: 'Cancel', onClick: closeExtendTrialModal },
               ]}
            >
               <div>
                  <label className='field-title'>New Trial End On</label>
                  <DateTimePicker value={trialEndOn} onChange={setTrialEndOn} minDate='today' />
               </div>
            </ModalDialog>
         )}
      </>
   );
};

export default CourseEnrollmentActionsCell;
