import * as React from 'react';

import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { WistiaVideo } from '@models/Wistia';

import { AppStateContext } from '../../AppState';
import { IOnboardingStep } from './Models';

interface VideoPostProps {
   step: IOnboardingStep;
   onCloseClick(): void;
}

const VideoPost: React.FC<VideoPostProps> = ({
   step: { wistiaId, heading = null, author = null, onEnd = null, onPlay = null, onPause = null },
   onCloseClick,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   React.useEffect(() => {
      if (window._wq === undefined) {
         const script = document.createElement('script');
         script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
         script.async = true;
         document.body.appendChild(script);
      }
      window._wq = window._wq || [];
      if (wistiaId) {
         window._wq.push({
            id: wistiaId,
            onReady: (video: WistiaVideo) => {
               if (userProfile) {
                  video.email(userProfile.email);
               }
               onEnd && video.bind('end', onEnd);
               onPlay && video.bind('play', onPlay);
               onPause && video.bind('pause', onPause);
            },
         });
      }
   }, []);

   return (
      <div className='onboarding-post-wrapper video-post'>
         <div className='onboarding-post'>
            <div className='onboarding-post-header'>
               {!!author && !heading && (
                  <>
                     <div className='post-avatar'>
                        <img src={author.imageUrl} />
                     </div>
                     <div className='post-author'>
                        <span className='name'>{author.name}</span>
                        <span className='company'>{` from ${author.company}`}</span>
                     </div>
                  </>
               )}
               {heading && <h2>{heading}</h2>}
               <div className='close'>
                  <IconClose onClick={onCloseClick} />
               </div>
            </div>
            <div className='onboarding-post-body'>
               <div
                  className='wistia_responsive_padding'
                  style={{ padding: '56.25% 0 0 0', position: 'relative' }}
               >
                  <div
                     className='wistia_responsive_wrapper'
                     style={{
                        height: '100%',
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                     }}
                  >
                     <iframe
                        src={`https://fast.wistia.net/embed/iframe/${wistiaId}?videoFoam=true`}
                        frameBorder={0}
                        className='wistia_embed'
                        allowFullScreen
                        width='100%'
                        height='100%'
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(VideoPost);
