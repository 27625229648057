import * as React from 'react';

import IconOptionGrey from '@icons/general/icon-option-gray.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconFileShare1 from '@icons/nova-line/85-Files-Basic/file-share-1.svg';
import IconFileSwap from '@icons/nova-line/85-Files-Basic/file-swap.svg';
import IconTextInput1 from '@icons/nova-solid/52-Text/text-input-1.svg';
import IconFolders from '@icons/nova-solid/86-Files-Folders/folders.svg';
import Appearance from '@models/Appearance';
import ContentFolder from '@models/ContentFolder';
import DraggableType from '@models/DraggableType';
import pluralize from 'pluralize';
import { useDrag, useDrop } from 'react-dnd';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import AccentTextbox from '@components/AccentTextbox';
import Link from '@components/Common/Link';
import Droplist, { IMenuItem } from '@components/Core/Droplist';
import ModalDialog from '@components/Core/ModalDialog';
import SelectFolderModal from '@components/SelectFolderModal';
import { ContentLibraryContext } from './ContentLibraryContext';
import ShareContentFolderModal from './ShareContentFolderModal';

interface ContentFolderTileProps {
   canEdit: boolean;
   folder: ContentFolder;
   isCreator: boolean;
   itemCount: number;
   addFolder?(childFolderId: number): void;
   addItem?(itemId: number): void;
   deleteFolder?(): void;
   moveFolder?(parentFolderId: number): void;
   renameFolder?(name: string): void;
   setFolderActive(): void;
}

const ContentFolderTile: React.FC<ContentFolderTileProps> = ({
   canEdit,
   folder: { id, name },
   isCreator,
   itemCount,
   addFolder,
   addItem,
   deleteFolder,
   moveFolder,
   renameFolder,
   setFolderActive,
}) => {
   const {
      routes: {
         contentLibrary: { folder: folderRoute },
      },
   } = Constants;
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const { folders, activeLibrary } =
      React.useContext<ContentLibraryContext>(ContentLibraryContext);
   const link = activeLibrary
      ? folderRoute.replace(':libraryName', activeLibrary).replace(':folderId', id.toString())
      : null;

   const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
   const [isShareModalOpen, setIsShareModalOpen] = React.useState<boolean>(false);
   const [isRenameModalOpen, setIsRenameModalOpen] = React.useState<boolean>(false);
   const [isSelectFolderModalOpen, setIsSelectFolderModelOpen] = React.useState<boolean>(false);
   const [isOver, setIsOver] = React.useState<boolean>(false);
   const [updatedName, setUpdatedName] = React.useState<string>('');

   const closeSelectFolderModal = (): void => {
      setIsSelectFolderModelOpen(false);
   };

   const closeRenameModal = (): void => {
      setIsRenameModalOpen(false);
      setUpdatedName('');
   };

   const droplistItemFactory = (): readonly IMenuItem[] => {
      const items: IMenuItem[] = [];
      if (isCreator || canEdit) {
         items.push(
            ...[
               {
                  text: 'Rename',
                  onClick: openRenameModal,
                  icon: <IconTextInput1 className='icon-gray' />,
               },
            ],
         );
      }
      if (isCreator) {
         items.push(
            ...[
               {
                  text: 'Delete',
                  onClick: toggleDeleteModal,
                  icon: <IconBin className='icon-gray' />,
               },
               {
                  text: 'Share',
                  onClick: toggleShareModal,
                  icon: <IconFileShare1 className='icon-gray' />,
               },
               {
                  text: 'Move',
                  onClick: openSelectFolderModal,
                  icon: <IconFileSwap className='icon-gray' />,
               },
            ],
         );
      }
      return items;
   };

   const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      event.preventDefault();
   };

   const handleDeleteFolder = (): void => {
      deleteFolder?.();
      toggleDeleteModal();
   };

   const handleFolderSelect = (
      _event: React.MouseEvent<HTMLButtonElement>,
      updatedFolderId: number,
   ): void => {
      moveFolder?.(updatedFolderId);
      closeSelectFolderModal();
   };

   const handleRenameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setUpdatedName(event.target.value);
   };

   const handleRenameFolder = (): void => {
      renameFolder?.(updatedName);
      closeRenameModal();
   };

   const openSelectFolderModal = (): void => {
      setIsSelectFolderModelOpen(true);
   };

   const openRenameModal = (): void => {
      setUpdatedName(name);
      setIsRenameModalOpen(true);
   };

   const toggleDeleteModal = (): void => {
      setIsDeleteModalOpen((prevIsDeleteModalOpen) => !prevIsDeleteModalOpen);
   };

   const toggleShareModal = (): void => {
      setIsShareModalOpen((prevIsShareModalOpen) => !prevIsShareModalOpen);
   };

   const handleDrop = (item: { id: number; type: DraggableType }): void => {
      if (item.type === DraggableType.CONTENT_FILE) {
         addItem?.(item['id']);
      } else if (item.type === DraggableType.CONTENT_FOLDER) {
         if (item['id'] !== id) {
            addFolder?.(item['id']);
         }
      }
   };

   const subtitle = itemCount !== undefined ? `${itemCount} ${pluralize('item', itemCount)}` : '';
   const droplistItems = droplistItemFactory();

   const [, drop] = useDrop<{ id: number; type: DraggableType }>({
      accept: [DraggableType.CONTENT_FILE, DraggableType.CONTENT_FOLDER],
      drop: (item) => handleDrop(item),
      collect: (monitor) => {
         if (isOver !== monitor.isOver()) {
            setIsOver(monitor.isOver());
         }
      },
   });

   const [{ opacity }, drag] = useDrag(() => ({
      type: DraggableType.CONTENT_FOLDER,
      item: () => ({ id }),
      collect: (monitor) => ({
         opacity: monitor.isDragging() ? 0.5 : 1,
      }),
   }));

   return (
      <>
         <div className='tile' ref={drop} style={{ opacity }} onDoubleClick={setFolderActive}>
            <div
               className={isOver ? 'card content-library card-hover' : 'card content-library'}
               ref={drag}
            >
               <Link to={link} onClick={handleClick}>
                  <div className='content-tile-folder'>
                     <IconFolders className='icon-gray' height='100%' width='100%' />
                  </div>
               </Link>
               <div className='content-tile-title'>
                  <Link to={link} onClick={handleClick}>
                     <div className='title'>{name}</div>
                  </Link>
               </div>
               <div className='content-tile-subtitle'>
                  <label>{subtitle}</label>
                  {!!droplistItems.length && (
                     <Droplist items={droplistItems} pullRight>
                        <IconOptionGrey />
                     </Droplist>
                  )}
               </div>
            </div>
         </div>
         {isDeleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading={`Delete ${name}`}
               onClose={toggleDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: handleDeleteFolder },
                  { text: 'Cancel', onClick: toggleDeleteModal },
               ]}
            >
               <p>
                  Are you sure that you want to delete <strong>{name}</strong> and all of its
                  contents?
               </p>
            </ModalDialog>
         )}
         {isRenameModalOpen && (
            <ModalDialog
               appearance={Appearance.primary}
               heading={`Rename ${name}`}
               onClose={closeRenameModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Rename', onClick: handleRenameFolder },
                  { text: 'Cancel', onClick: closeRenameModal },
               ]}
            >
               <AccentTextbox
                  autoFocus
                  language={userProfile?.language}
                  value={updatedName}
                  onChange={handleRenameChange}
               />
            </ModalDialog>
         )}
         {isShareModalOpen && (
            <ShareContentFolderModal
               folderName={name}
               folderId={id}
               closeShareModal={toggleShareModal}
            />
         )}
         {isSelectFolderModalOpen && (
            <SelectFolderModal
               currentFolderId={id}
               folders={folders}
               heading={`Move ${name}`}
               primaryBtnText='Move'
               closeModal={closeSelectFolderModal}
               onPrimaryClick={handleFolderSelect}
            />
         )}
      </>
   );
};

export default ContentFolderTile;
