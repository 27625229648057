import * as React from 'react';

import Link from '@components/Common/Link';
import CloneCourseModal from '@components/Course/CourseEditor/CloneCourseModal';
import IconDownwardLineTriangle from '@icons/activities/downward-line-triangle.svg';
import IconGrade from '@icons/general/icon-grade.svg';
import IconRoster from '@icons/general/icon-roster.svg';
import IconSettings1 from '@icons/nova-line/03-Settings/settings-1.svg';
import IconSlider1 from '@icons/nova-line/03-Settings/slider-1.svg';
import IconContentViewModule2 from '@icons/nova-line/18-Content/content-view-module-2.svg';
import IconBusinessGraphBarVertical from '@icons/nova-line/44-Business/business-graph-bar-vertical.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import Language from '@models/Language';

import Constants from '../../Constants';

interface SidebarLink {
   text: string;
   to?: Maybe<string>;
   index: number;
   'data-test'?: string;
   'data-tour'?: string;
   onClick?(): void;
   icon: React.ReactNode;
}

interface ManageCourseSidebarProps {
   accountType: AccountType;
   canLeaveCourse?: boolean;
   canViewRoster?: boolean;
   courseId: number;
   courseLanguage: Language;
   courseName: string;
   isProficiencyCourse: boolean;
   shouldHideInstructorGradebook?: boolean;
   shouldHideStudentGradebook?: boolean;
   showCloneCourse?: boolean;
   showEditCourse?: boolean;
   showProficiency: boolean;
   showVocabStats?: boolean;
   leaveCourse(): void;
}

const ManageCourseSidebar: React.FC<ManageCourseSidebarProps> = (props) => {
   const {
      accountType,
      canLeaveCourse = true,
      canViewRoster = false,
      courseId,
      isProficiencyCourse = false,
      shouldHideInstructorGradebook = false,
      shouldHideStudentGradebook = false,
      showCloneCourse = false,
      showEditCourse = false,
      showProficiency = false,
      showVocabStats = false,
      leaveCourse,
   } = props;

   const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

   const {
      routes: {
         courses: {
            edit,
            gradebook,
            modules,
            myGrades,
            studentSummary,
            instructorSummary,
            roster,
            vocabStats: vocabStatsLink,
         },
      },
   } = Constants;

   const isInstructor = accountType === AccountType.instructor;

   const renderCourseSidebarLink = ({
      text,
      index,
      icon,
      to = null,
      onClick = undefined,
      ...i
   }: SidebarLink): React.ReactNode => {
      if (to) {
         return (
            <li key={index} {...i}>
               <Link
                  style={{
                     display: 'flex',
                     alignItems: 'center',
                     width: '100%',
                     height: '100%',
                  }}
                  to={to}
                  data-test={text}
               >
                  {icon}
                  {text}
               </Link>
            </li>
         );
      }
      return (
         <li key={index} onClick={onClick} {...i}>
            {icon}
            {text}
         </li>
      );
   };

   const makeCourseLink = (link: string): string => link.replace(':courseId', courseId.toString());

   const renderInstructorLinks = (): readonly React.ReactNode[] =>
      [
         {
            text: 'Modules',
            to: makeCourseLink(modules),
            icon: <IconContentViewModule2 />,
            'data-tour': 'modules-link',
         },
         {
            text: 'Roster',
            to: canViewRoster ? makeCourseLink(roster) : undefined,
            className: canViewRoster ? '' : 'disabled',
            icon: <IconRoster />,
         },
         shouldHideInstructorGradebook
            ? undefined
            : {
                 text: 'Gradebook',
                 to: makeCourseLink(gradebook),
                 icon: <IconGrade />,
              },
         showProficiency
            ? {
                 text: 'Proficiency',
                 to: makeCourseLink(instructorSummary),
                 icon: <IconDownwardLineTriangle />,
              }
            : undefined,
         showVocabStats
            ? {
                 text: 'Vocab Stats',
                 to: makeCourseLink(vocabStatsLink),
                 icon: <IconBusinessGraphBarVertical />,
              }
            : undefined,
         showEditCourse
            ? {
                 text: 'Edit Course',
                 to: makeCourseLink(edit),
                 icon: <IconSettings1 />,
              }
            : undefined,
         showCloneCourse
            ? {
                 text: 'Clone Course',
                 onClick: () => setIsModalOpen(true),
                 icon: <IconFileCopy />,
                 'data-test': 'clone-course-link',
              }
            : undefined,
      ]
         .filter((i) => !!i)
         .map((i, index) => renderCourseSidebarLink({ ...i, index } as SidebarLink));

   const renderStudentLinks = (): readonly React.ReactNode[] =>
      [
         {
            text: 'Modules',
            to: makeCourseLink(modules),
            icon: <IconContentViewModule2 />,
            'data-tour': 'modules-link',
         },
         shouldHideStudentGradebook
            ? undefined
            : {
                 text: 'My Grades',
                 to: makeCourseLink(myGrades),
                 icon: <IconGrade />,
              },
         isProficiencyCourse
            ? {
                 text: 'My Proficiency',
                 to: makeCourseLink(studentSummary),
                 icon: <IconSlider1 />,
              }
            : undefined,
         canLeaveCourse
            ? {
                 text: 'Leave Course',
                 onClick: leaveCourse,
                 icon: <IconSettings1 />,
              }
            : undefined,
      ]
         .filter((i) => !!i)
         .map((i, index) => renderCourseSidebarLink({ ...i, index } as SidebarLink));

   return (
      <div className='right-sidebar' role='navigation' aria-labelledby='course-detail-label'>
         <div className='card-title'>
            <div id='course-detail-label' className='title'>
               {isInstructor ? 'Manage Course' : 'Course Details'}
            </div>
         </div>
         <ul className='actions-nav'>
            {isInstructor ? renderInstructorLinks() : renderStudentLinks()}
         </ul>
         {isModalOpen && showCloneCourse && (
            <CloneCourseModal {...props} closeModal={() => setIsModalOpen(false)} />
         )}
      </div>
   );
};

export default ManageCourseSidebar;
