import * as _ from 'lodash';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { isInteger } from '@helpers/NumberUtils';
import useCommands from '@hooks/use-commands';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import { CourseInfo, CoursePermissions, Module as ModuleType } from '@models/Course';
import Language from '@models/Language';
import LMSName from '@models/LMSName';
import CourseService from '@services/CourseService';
import HttpService from '@services/HttpService';
import ModuleService from '@services/ModuleService';
import { BeforeCapture, DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import mixPanelActions from '../../../Mixpanel';
import EmptyState from '@components/Core/EmptyState';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { GradeActivityStep } from '@components/Onboarding/Walkthroughs/GradeActivity';
import ImportModulesModal from './ImportModulesModal';
import Module from './Module';
import { ModuleDndEmitter as emitter, ModuleDroppableId } from './ModuleDndEmitter';
import ModuleList from './ModuleList';
import { getModuleMode, ModuleMode } from './ModuleUtils';

export type GetCourseModulesResponse = {
   course: CourseInfo;
   initialModuleId: number;
   modules: readonly ModuleType[];
   permissions: CoursePermissions;
};

const LanguageContext = React.createContext<Maybe<Language>>(null);

export const LanguageProvider = LanguageContext.Provider;
export const LanguageConsumer = LanguageContext.Consumer;

const Modules: React.FC = () => {
   const {
      routes: {
         courses: { dashboard, viewModule, editModule, newModule, modules: modulesRoute },
      },
   } = Constants;

   const navigate = useNavigate();
   const { pathname } = useLocation();
   const { courseId: paramsCourseId, moduleId: paramsModuleId = null } = useParams<{
      courseId: string;
      moduleId: string;
   }>();

   const {
      setBreadcrumbs,
      userProfile,
      breadcrumbs: prevBreadcrumbs,
   } = React.useContext<AppStateContext>(AppStateContext);
   const { walkthrough, taskState, stepId } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [activeModuleId, setActiveModuleId] = React.useState<Maybe<number>>(null);
   const [courseInfo, setCourseInfo] = React.useState<Maybe<CourseInfo>>(null);
   const [draggingItemIds, setDraggingItemIds] = React.useState<readonly number[]>([]);
   const [importModulesModalOpen, setImportModulesModalOpen] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const [modules, setModules] = React.useState<Maybe<readonly ModuleType[]>>(null);
   const [permissions, setPermissions] = React.useState<Maybe<CoursePermissions>>(null);

   const mode = getModuleMode(pathname);
   const courseId = Number(paramsCourseId);

   const canEditModules = (permissions?.canEdit && !courseInfo?.archived) ?? false;
   const canEditSecretCode = (permissions?.canEditSecretCode && !courseInfo?.archived) ?? false;

   const courseRoute = (route: string): string => route.replace(':courseId', courseId.toString());

   const viewModuleRoute = (moduleId: number): string =>
      courseRoute(viewModule).replace(':moduleId', moduleId.toString());

   const editModuleRoute = (moduleId: number): string =>
      courseRoute(editModule).replace(':moduleId', moduleId.toString());

   React.useEffect(() => {
      // modules not loaded yet for course id
      if (modules === null) {
         if (!isFetching) {
            loadModulesForCourse();
         }
         // already started loading modules
         return;
      }
      // modules already loaded for course id
      const moduleId = isInteger(paramsModuleId) ? Number(paramsModuleId) : null;
      // Same module id
      if (moduleId === activeModuleId && activeModuleId !== null) {
         return;
         // is on /new page
      } else if (mode === ModuleMode.create) {
         setActiveModuleId(null);
         // fetch then switch to module in address bar
      } else if (moduleId !== null) {
         fetchModule(moduleId).then(() => {
            setActiveModuleId(moduleId);
         });
         // find first module and redirect to it
      } else if (modules && modules.length > 0) {
         const firstModuleId = modules[0].id;
         navigate(viewModuleRoute(firstModuleId), { replace: true });
         // redirect to /new page
      } else if (canEditModules) {
         navigate(courseRoute(newModule), { replace: true });
      }
      // at this point, they can't create so we can't do anything
   }, [courseId, paramsModuleId, mode]);

   const closeImportModulesModal = (): void => {
      setImportModulesModalOpen(false);
   };

   const openImportModulesModal = (): void => {
      setImportModulesModalOpen(true);
   };

   useCommands(
      [
         {
            id: 'create_module',
            link: courseRoute(newModule),
            scale: 1.2,
            title: 'Create Module',
            showIf: () => canEditModules && mode !== ModuleMode.create,
         },
         {
            id: 'import_modules',
            scale: 1.2,
            title: 'Import Modules',
            action: openImportModulesModal,
            showIf: () => canEditModules,
         },
         {
            id: 'edit_module',
            scale: 1.5,
            title: 'Edit Module',
            link: activeModuleId ? editModuleRoute(activeModuleId) : null,
            showIf: () => canEditModules && activeModuleId !== null,
         },
      ],
      [courseId, mode, activeModuleId, canEditModules],
   );

   // Go to first module if in grading Activity Onboarding Event
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.gradeActivity &&
         taskState === OnboardingTaskState.inProgress &&
         stepId === GradeActivityStep.modulesLink
      ) {
         if (modules && modules.length > 0) {
            const firstModuleId = modules[0].id;
            navigate(viewModuleRoute(firstModuleId), { replace: true });
         }
      }
   }, [modules, stepId]);

   React.useEffect(() => {
      const moduleId = isInteger(paramsModuleId) ? Number(paramsModuleId) : null;
      if (moduleId !== null) {
         ModuleService.setLastVisitedModule(courseId, moduleId);
      }
      setDraggingItemIds([]);
   }, [paramsModuleId]);

   const fetchModule = (moduleId: number): Promise<void> => {
      if (moduleId === null) {
         return Promise.reject('No module id to fetch');
      } else if (!modules) {
         return Promise.reject('Modules not loaded');
      }
      const index = modules.findIndex((i) => i.id === moduleId);
      if (index < 0) {
         return Promise.reject(`Could not find module id ${moduleId} in modules`);
      }
      // If a module isn't loaded yet, items will be undefined
      if (modules[index].items === undefined) {
         return ModuleService.getModule(courseId, moduleId).then((module) => {
            setModules((prevModules) =>
               prevModules ? prevModules.map((i) => (i.id === moduleId ? module : i)) : prevModules,
            );
         });
      }
      return Promise.resolve();
   };

   const appendNewModule = (module: ModuleType, redirect = true): void => {
      setModules((prevModules) =>
         prevModules
            ? [...prevModules, { ...module, index: prevModules.length, courseId }]
            : prevModules,
      );
      if (redirect) {
         navigate(viewModuleRoute(module.id), { replace: true });
      }
   };

   const loadModulesForCourse = (): Promise<void> => {
      setBreadcrumbs({ ...prevBreadcrumbs, next: null, prev: null });
      setModules(null);
      setActiveModuleId(null);
      setIsFetching(true);
      const additionalParams = paramsModuleId ? `&initial_module=${paramsModuleId}` : '';
      const url = `/api/courses/${courseId}/modules?assignment_defaults=true${additionalParams}`;
      return HttpService.getWithAuthToken<GetCourseModulesResponse>(url).then((response) => {
         const {
            course: dataCourseInfo,
            permissions: dataPermissions,
            modules: dataModules,
            initialModuleId: dataInitialModuleId,
         } = response.data;
         setCourseInfo(dataCourseInfo);
         setIsFetching(false);
         setModules(dataModules);
         setPermissions(dataPermissions);
         setBreadcrumbs({
            breadcrumbs: [
               {
                  link: courseRoute(dashboard),
                  text: dataCourseInfo.name,
                  contextInfo: { courseId, courseArchived: dataCourseInfo.archived },
               },
               { link: '#', text: 'Modules' },
            ],
            next: null,
            prev: null,
         });
         const initialModuleId = dataModules.some((i) => i.id === dataInitialModuleId)
            ? dataInitialModuleId
            : null;
         const moduleId = isInteger(paramsModuleId) ? Number(paramsModuleId) : null;
         const canCreateNewModule = dataPermissions.canEdit && !dataCourseInfo.archived;

         // initial module is same as one in the params
         if (initialModuleId !== null && initialModuleId && moduleId) {
            setActiveModuleId(moduleId);
            // no initial module but can create
         } else if (
            (initialModuleId === null || mode === ModuleMode.create) &&
            canCreateNewModule
         ) {
            navigate(courseRoute(newModule), { replace: true });
            // in edit mode already
         } else if (mode === ModuleMode.edit && initialModuleId) {
            navigate(editModuleRoute(initialModuleId), { replace: true });
            // has initial module and needs to redirect to view route
         } else if (initialModuleId !== null) {
            navigate(viewModuleRoute(initialModuleId), { replace: true });
         }
      });
   };

   const createGradingCategory = async (name: string): Promise<number> =>
      CourseService.createGradingCategory(courseId, name).then((id) => {
         setCourseInfo((prevCourseInfo) =>
            prevCourseInfo
               ? {
                    ...prevCourseInfo,
                    gradingCategories: _.orderBy(
                       [...prevCourseInfo.gradingCategories, { name, id }],
                       ['label'],
                       ['asc'],
                    ),
                 }
               : prevCourseInfo,
         );
         return Promise.resolve(id);
      });

   const deleteModule = (moduleId: number): Promise<void> => {
      if (!modules) {
         return Promise.reject();
      }
      setActiveModuleId(null);
      const lastModule = modules.filter((i) => i.id !== moduleId).length === 1;
      const moduleToDelete = modules.find((i) => i.id === moduleId);
      return ModuleService.deleteModule(courseId, moduleId).then(() => {
         if (moduleToDelete && courseInfo) {
            mixPanelActions.track(
               'Module Deleted',
               snakeCaseKeys({
                  id: moduleId,
                  name: moduleToDelete.name,
                  courseId: courseInfo.id,
                  courseName: courseInfo.name,
               }),
            );
         }
         setModules((prevModules) =>
            prevModules ? prevModules.filter((i) => i.id !== moduleId) : null,
         );
         const path = lastModule ? newModule : modulesRoute;
         navigate(path.replace(':courseId', courseId.toString()), {
            replace: true,
         });
      });
   };

   const updateModuleProperties = (update: Partial<ModuleType>): void => {
      setModules((prevModules) =>
         prevModules
            ? prevModules.map((i) => (i.id === activeModuleId ? { ...i, ...update } : i))
            : null,
      );
   };

   const handleDragEnd = (result: DropResult): void => {
      const { source, destination, combine } = result;
      if (
         (destination?.droppableId === ModuleDroppableId.ModuleList &&
            source?.droppableId === ModuleDroppableId.ModuleList) ||
         combine?.droppableId === ModuleDroppableId.ModuleList
      ) {
         emitter.emit.moduleListOnDropEnd(result);
      } else if (
         destination?.droppableId === ModuleDroppableId.ModuleContent &&
         source?.droppableId === ModuleDroppableId.ModuleContent
      ) {
         emitter.emit.moduleItemOnDropEnd(result);
      }
   };

   const handleDragStart = (start: DragStart): void => {
      emitter.emit.onDropStart(start);
   };

   const handleBeforeDragCapture = (before: BeforeCapture): void => {
      emitter.emit.onBeforeDragCapture(before);
   };

   const importModule = (moduleId: number): Promise<void> =>
      ModuleService.importModule(courseId, moduleId).then((addedModules) => {
         setModules((prevModules) => (prevModules ? [...prevModules, ...addedModules] : null));
      });

   const getLmsName = (): Maybe<LMSName> => {
      if (courseInfo?.lmsConnections.length) {
         return courseInfo.lmsConnections[0].lmsName;
      }
      return null;
   };

   if (isFetching || !modules || !courseInfo || !permissions || !userProfile) {
      return <Loader />;
   }

   const renderCourseTitle = () => (
      <div className='flex items-center'>
         <div className='title'>{courseInfo.name}</div>
         {courseInfo.archived && <div className='lozenge dark-red margin-left-s'>Archived</div>}
         {courseInfo.demo && !courseInfo.archived && (
            <div className='lozenge blue margin-left-s'>Demo</div>
         )}
      </div>
   );

   const shouldShowNoContentMessage = () =>
      userProfile?.accountType === AccountType.student && !modules?.length;

   const activeModule = modules.find(({ id }) => id === activeModuleId) ?? null;

   return (
      <DragDropContext
         onBeforeCapture={handleBeforeDragCapture}
         onDragEnd={handleDragEnd}
         onDragStart={handleDragStart}
      >
         <div className='content-main margin-right-m'>
            <DocumentTitle>{`Modules - ${courseInfo.name}`}</DocumentTitle>
            <div className='card no-padding module-container'>
               <div className='row'>
                  <div className='col-xs-12 col-md-3'>
                     <ModuleList
                        activeModuleId={activeModuleId}
                        canEditModules={canEditModules}
                        courseId={courseId}
                        draggingItemIds={draggingItemIds}
                        modules={modules}
                        openImportModulesModal={openImportModulesModal}
                        setModules={setModules}
                     />
                  </div>
                  <div className='col-xs-12 col-md-9 module-content-container'>
                     <div className='card-title full-width module-content-course-title has-button'>
                        {renderCourseTitle()}
                     </div>
                     {shouldShowNoContentMessage() ? (
                        <div className='no-module-empty-state'>
                           <EmptyState
                              description='This course has no content available yet.'
                              heading='No content!'
                              icon={<IconContentBook2 className='icon-gray' aria-hidden />}
                           />
                        </div>
                     ) : (
                        <LanguageProvider value={courseInfo.language}>
                           <Module
                              accountType={userProfile.accountType}
                              activeModule={activeModule}
                              appendNewModule={appendNewModule}
                              canEditModules={canEditModules}
                              canEditSecretCode={canEditSecretCode}
                              canPreviewContent={permissions.canPreviewContent}
                              courseInfo={courseInfo}
                              createGradingCategory={createGradingCategory}
                              deleteModule={() =>
                                 activeModuleId ? deleteModule(activeModuleId) : Promise.reject()
                              }
                              lmsName={getLmsName()}
                              moduleCount={modules.length}
                              setDraggingItemIds={setDraggingItemIds}
                              updateModuleProperties={updateModuleProperties}
                           />
                        </LanguageProvider>
                     )}
                  </div>
               </div>
            </div>
         </div>
         {importModulesModalOpen && (
            <ImportModulesModal
               closeModal={closeImportModulesModal}
               courseInfo={courseInfo}
               importModule={importModule}
            />
         )}
      </DragDropContext>
   );
};

export default Modules;
