const formElements = [
   'INPUT',
   'LABEL',
   'SELECT',
   'TEXTAREA',
   'BUTTON',
   'FIELDSET',
   'LEGEND',
   'DATALIST',
   'OUTPUT',
   'OPTION',
   'OPTGROUP',
];

export type TranslationObserverCallback = (
   isPageTranslated: boolean,
   isAlert: boolean,
) => Promise<void>;

export class TranslationObserver {
   observer?: MutationObserver;
   isPageTranslated: boolean;
   observeChildren: boolean;
   callback: TranslationObserverCallback;

   /**
    *
    * @param callback - Only triggers if there is a change in state
    * @param observeChildren - (Experimental) Watch if any question text was translated
    */
   constructor(callback: TranslationObserverCallback, observeChildren = false) {
      this.observer = undefined;
      this.isPageTranslated = false;
      this.observeChildren = observeChildren;
      this.callback = callback;
   }

   /**
    * @param callback -
    */
   connect(): TranslationObserver {
      this.observer = new MutationObserver((events) => this.mutationObserverCallback(events));

      this.observer.observe(document.documentElement, {
         attributes: true,
         attributeFilter: ['class'],
         childList: true,
         characterData: true,
         subtree: true,
      });

      return this;
   }

   disconnect(): TranslationObserver {
      if (!this.observer) {
         return this;
      }

      this.observer.disconnect();
      this.observer = undefined;

      return this;
   }

   private async mutationObserverCallback(events: MutationRecord[]) {
      // Check if the page overall was translated by the most common translation tools
      const isTranslated = !!document.querySelector(
         'html.translated-ltr, html.translated-rtl, ya-tr-span, *[_msttexthash], *[x-bergamot-translated]',
      );

      // Guard clause for multiple events
      if (isTranslated && this.isPageTranslated) {
         return;
      }

      if (isTranslated) {
         this.isPageTranslated = true;
         await this.callback(true, true);
         return;
      } else if (!isTranslated && this.isPageTranslated) {
         this.isPageTranslated = false;
         await this.callback(false, true);
         return;
      }

      // Observe children allows us to watch for any changes on the page, and then see if they matter
      if (!this.observeChildren) {
         return;
      }

      // Observe children if any text on the page was translated.
      const eventsLength = events.length;

      for (let idx = 0; idx < eventsLength; idx++) {
         const event = events[idx];

         // Skip if the record isn't an an element
         if (event.type !== 'childList' || !event.target || !(event.target instanceof Element)) {
            continue;
         }

         // Ignore form elements, style tags, non text nodes, if the node is new, and the target is not under a ".lingco-question"
         if (
            event.addedNodes[0] instanceof Text &&
            event.removedNodes.length !== 0 &&
            !formElements.includes(event.target.tagName) &&
            event.target.tagName !== 'STYLE' &&
            event.target.closest('.lingco-question')
         ) {
            await this.callback(true, false);

            // We only need to see one event
            break;
         }
      }
   }
}

// TODO: test one question at a time
