import * as React from 'react';

import AddButton from '@components/Common/AddButton';
import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import { SectionCard } from '@components/Common/SectionCard';
import Table, { Column } from '@components/Common/Table';
import ConfirmModal from '@components/Core/ConfirmModal';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import CloneExamCourseModal from '@components/NationalExam/CloneExamCourseModal';
import NationalExamSeatTransferModal from '@components/NationalExam/NationalExamSeatOrderModal';
import DeleteAction from '@components/TableActions/DeleteAction';
import UserAutoSuggest from '@components/UserAutoSuggest';
import Appearance from '@models/Appearance';
import { ID, IdName } from '@models/Core';
import {
   AdminExamOrder,
   AdminNationalExamLevel,
   AdminNationalExamLevelSeatAllocation,
   ExamOrderResponseAlreadyClonedCourses,
} from '@models/NationalExam';
import AdminNationalExamOrderService from '@services/AdminNationalExamOrderService';
import { useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';

const NationalExamOrderDetails: React.FC = () => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const navigate = useNavigate();

   const params = useParams<{ orderId: string }>();

   if (!params.orderId) {
      return null;
   }

   const [orderId, setOrderId] = React.useState<number | string>(params.orderId);
   const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
   const [isDeleted, setIsDeleted] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [order, setOrder] = React.useState<AdminExamOrder | null>(null);
   const [isResendingInvoice, setIsResendingInvoice] = React.useState<boolean>(false);
   const [coursesAlreadyClonedForUser, setCoursesAlreadyClonedForUser] = React.useState<
      ExamOrderResponseAlreadyClonedCourses[]
   >([]);
   const [coursesAvailableForExam, setCoursesAvailableForExam] = React.useState<IdName[]>([]);
   const [hasConnectedAccount, setHasConnectedAccount] = React.useState<boolean>(false);
   const [newUserId, setNewUserId] = React.useState<ID>();
   const [attemptingToConnectAccount, setAttemptingToConnectAccount] =
      React.useState<boolean>(false);
   const [levels, setLevels] = React.useState<AdminNationalExamLevelSeatAllocation[]>([]);
   const [examLevels, setExamLevels] = React.useState<AdminNationalExamLevel[]>([]);
   const [editLevel, setEditLevel] = React.useState<AdminNationalExamLevelSeatAllocation | null>(
      null,
   );
   const [showCloneExamCourseModal, setShowCloneExamCourseModal] = React.useState<boolean>(false);
   const [showSeatOrderModal, setShowSeatOrderModal] = React.useState<boolean>(false);
   const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

   const levelNameLink = (level: AdminNationalExamLevelSeatAllocation): React.ReactNode => (
      <a onClick={() => handleSeatOrderModal(true, level)}>{level.levelName}</a>
   );

   const handleSeatOrderModal = (
      show: boolean,
      level: AdminNationalExamLevelSeatAllocation | null = null,
   ): void => {
      setEditLevel(level);
      setShowSeatOrderModal(show);
   };

   const cloneExamCourseForInstructor = (courseId: number): Promise<IdName | void> => {
      if (order && order.instructorId) {
         return AdminNationalExamOrderService.cloneCourseForUser(order.instructorId, courseId).then(
            (newCourse) => {
               setCoursesAlreadyClonedForUser([...coursesAlreadyClonedForUser, newCourse]);
            },
         );
      } else {
         alert('Order must be linked with an instructor to clone a course for them.');
         return new Promise((resolve) => {
            resolve();
         });
      }
   };

   const seatAssignmentColumns: readonly Column<AdminNationalExamLevelSeatAllocation>[] = [
      { id: 'levelName', header: 'Level', cell: (i) => levelNameLink(i) },
      {
         id: 'seatsPurchased',
         header: 'Seats Purchased',
         cell: (i) => i.seatsPurchased,
      },
      {
         id: 'delete',
         header: 'Delete',
         cell: (i) => rendersSeatOrderActions(i),
      },
   ];

   const courseColumns: readonly Column<ExamOrderResponseAlreadyClonedCourses>[] = [
      {
         id: 'id',
         header: 'Id',
         cell: (i) => (
            <Link to={Constants.routes.courses.dashboard.replace(':courseId', String(i.id))}>
               {i.id}
            </Link>
         ),
      },
      { id: 'name', header: 'Name', cell: (i) => i.name },
      { id: 'clonedFromId', header: 'Cloned From Course Id', cell: (i) => i.clonedFromId },
   ];

   const connectAccount = (): void => {
      if (!orderId || !newUserId || !order) {
         return;
      }

      setAttemptingToConnectAccount(true);

      if (hasConnectedAccount) {
         AdminNationalExamOrderService.transferOrderToUser(order.id, newUserId)
            .then((examOrder) => {
               setHasConnectedAccount(true);
               setAttemptingToConnectAccount(false);
               setOrder(examOrder);
            })
            .catch(() => setAttemptingToConnectAccount(false));
      } else {
         AdminNationalExamOrderService.connectExamOrderToUser(order.id, newUserId)
            .then((examOrder) => {
               setHasConnectedAccount(true);
               setAttemptingToConnectAccount(false);
               setOrder(examOrder);
            })
            .catch(() => setAttemptingToConnectAccount(false));
      }
   };

   const connectAccountTooltip = (): string => {
      if (hasConnectedAccount) {
         return 'Update the account the order is connected to. This will transfer the cloned courses if the instructor being transferred to does not have those exam courses yet.';
      } else if (attemptingToConnectAccount) {
         return 'Attempting to connect the order to the account.';
      } else if (!newUserId) {
         return 'Please select a user to connect to this order';
      }

      return '';
   };

   const savedSeatOrder = (): void => {
      handleSeatOrderModal(false);
      loadSeatOrder(orderId);
   };

   const createSeatOrder = (): void => {
      handleSeatOrderModal(true);
   };

   const onSeatOrderDelete = (seatOrderLevel: number): void => {
      AdminNationalExamOrderService.removeExamSeatOrder(orderId, seatOrderLevel)
         .then(() => {
            loadSeatOrder(orderId);
         })
         .catch((error) => {
            if (error.response.status === 400) {
               alert(error.response.data?.msg || 'Failed to save.');
            } else {
               alert('Failed to save.');
            }
         });
   };

   const rendersSeatOrderActions = (
      seatOrder: AdminNationalExamLevelSeatAllocation,
   ): React.ReactNode => (
      <DeleteAction
         onDelete={() => onSeatOrderDelete(seatOrder.levelId)}
         objectName={seatOrder.levelName}
      />
   );

   const loadSeatOrder = (id: number | string): void => {
      AdminNationalExamOrderService.getExamOrder(id).then((exam) => {
         setOrder(exam.order);
         setCoursesAlreadyClonedForUser(exam.coursesAlreadyClonedForUser);
         setCoursesAvailableForExam(exam.coursesAvailableForExam);
         setHasConnectedAccount(!!exam.order.instructorId);
         setLevels(exam.levels);
         setExamLevels(exam.examLevels);
         setIsLoading(false);
      });
   };

   React.useEffect(() => {
      setOrderId(Number(params.orderId));

      loadSeatOrder(Number(params.orderId));
   }, []);

   const handleDeleteClick = (): void => {
      setIsDeleting(true);
      AdminNationalExamOrderService.deleteExamOrder(Number(params.orderId)).then(() => {
         setIsDeleting(false);
         setIsDeleted(true);
         dispatchToast({
            title: 'Order Deleted',
            message: `Order ${params.orderId} has been successfully deleted.`,
            appearance: Appearance.danger,
         });
         navigate(Constants.routes.admin.nationalExam.viewExamOrders);
      });
   };

   const handleResendInvoiceClick = (): void => {
      if (!params.orderId) {
         return;
      }

      setIsResendingInvoice(true);
      AdminNationalExamOrderService.resendOrderInvoice(params.orderId).then(() => {
         setIsResendingInvoice(false);

         dispatchToast({
            title: 'Resend Invoice',
            message: 'Invoice has been successfully sent.',
            appearance: Appearance.success,
         });
      });
   };

   const renderSeatOrderInformation = (): React.ReactNode => {
      const toolTipContent = `We display seat order information here when an exam has per level seating
         or is the NLE. 

         Per level seating means that when the user places an order we will save the number 
         of seats for each level they ordered. The user then gets the number of seats at each level. 
         Most exams have this disabled.
         
         The NLE has per level seating disabled, but has a separate form that sends the seats in to be
         saved.`;
      if (order?.hasPerLevelSeats || levels.length > 0) {
         return (
            <SectionCard
               title='Seat Order Information'
               headerButton={<AddButton onClick={createSeatOrder} />}
               toolTipContent={toolTipContent}
            >
               <div className='row margin-top-s'>
                  <div className='col-xs-12'>
                     <Table
                        columns={seatAssignmentColumns}
                        rows={levels}
                        rowKey='levelId'
                        defaultSortBy={[{ id: 'levelName', desc: false }]}
                     />
                  </div>
               </div>
            </SectionCard>
         );
      }

      return (
         <SectionCard title='Seat Order Information' toolTipContent={toolTipContent}>
            <div className='title extra-large'>Per Level Seats Disabled On Exam</div>
         </SectionCard>
      );
   };

   if (isLoading || !order || !coursesAlreadyClonedForUser || !coursesAvailableForExam) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>{`Order ${orderId}`}</DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row center-xs'>
               <SectionCard title='Order Information'>
                  <>
                     <div className='row'>
                        <div className='col-xs-12'>
                           <label className='field-title'>Exam Name</label>
                           <input
                              className='disabled'
                              disabled
                              value={order.examName}
                              type='text'
                              name='examName'
                           />
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>First Name</label>
                           <input
                              className='disabled'
                              disabled
                              value={order.instructorFirstName}
                              type='text'
                              name='firstName'
                           />
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>Last Name</label>
                           <input
                              className='disabled'
                              disabled
                              value={order.instructorLastName}
                              type='text'
                              name='lastName'
                           />
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>Order Email</label>
                           <input
                              className='disabled'
                              disabled
                              value={order.instructorEmail}
                              type='email'
                              name='email'
                           />
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title'>User Id</label>
                           <input
                              className='disabled'
                              disabled
                              value={order.instructorId}
                              type='number'
                              name='id'
                           />
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-8'>
                           <label className='field-title'>Link Order To User</label>
                           <UserAutoSuggest
                              maxDisplayed={5}
                              onUserSelection={(event, { suggestion }) => {
                                 setNewUserId(suggestion.id);
                              }}
                           />
                        </div>
                        <div className='col-xs-4 flex items-end' style={{ paddingBottom: 2 }}>
                           <Button
                              disabled={!newUserId}
                              loading={attemptingToConnectAccount}
                              onClick={connectAccount}
                              tooltip={connectAccountTooltip()}
                           >
                              Connect to Account
                           </Button>
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12'>
                           <div>
                              <label className='field-title'>Actions</label>
                              <hr className='margin-top-xs margin-bottom-s' />
                           </div>
                           <Button onClick={handleResendInvoiceClick} loading={isResendingInvoice}>
                              Resend Invoice
                           </Button>
                           <Button
                              className='margin-left-m'
                              appearance={Appearance.danger}
                              onClick={() => setShowDeleteModal(true)}
                              loading={isDeleting}
                              disabled={isDeleted}
                           >
                              Delete Order
                           </Button>
                        </div>
                     </div>
                  </>
               </SectionCard>

               <SectionCard title='School Information'>
                  <div className='row margin-top-s'>
                     <div className='col-xs-12'>
                        <label className='field-title'>School Name</label>
                        <input
                           className='disabled'
                           disabled
                           defaultValue={order.schoolName}
                           type='text'
                           name='schoolName'
                        />
                     </div>
                  </div>
               </SectionCard>

               <SectionCard
                  title='Exam Courses Instructor Has Cloned'
                  headerButton={<AddButton onClick={() => setShowCloneExamCourseModal(true)} />}
               >
                  <div className='row margin-top-s'>
                     <div className='col-xs-12'>
                        <Table
                           columns={courseColumns}
                           rows={coursesAlreadyClonedForUser}
                           rowKey='id'
                           defaultSortBy={[{ id: 'id', desc: false }]}
                        />
                     </div>
                  </div>
               </SectionCard>

               {renderSeatOrderInformation()}
            </div>
         </div>

         {showSeatOrderModal && (
            <NationalExamSeatTransferModal
               orderId={orderId}
               selected={editLevel}
               onCancel={() => handleSeatOrderModal(false)}
               onSave={() => savedSeatOrder()}
               examLevels={examLevels}
               usedLevelIds={levels.map((l) => l.levelId)}
            />
         )}
         {showCloneExamCourseModal && (
            <CloneExamCourseModal
               coursesAlreadyClonedForUser={coursesAlreadyClonedForUser}
               coursesAvailableForExam={coursesAvailableForExam}
               onCancel={() => setShowCloneExamCourseModal(false)}
               onAddClick={cloneExamCourseForInstructor}
            />
         )}
         {showDeleteModal && (
            <ConfirmModal
               header='Delete Order?'
               message='Are you sure you want to delete this order?'
               onClose={() => setShowDeleteModal(false)}
               onPrimaryClick={handleDeleteClick}
            />
         )}
      </>
   );
};

export default NationalExamOrderDetails;
