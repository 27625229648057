import * as _ from 'lodash';
import * as React from 'react';

import { formatDate } from '@helpers/FormatTime';
import IconCalendarTimeout from '@icons/nova-solid/05-Time/calendar-timeout.svg';
import IconAlertTriangle from '@icons/nova-solid/22-Interface-Feedback/interface-alert-triangle.svg';
import { ActivityMode, ActivityVariable } from '@models/Activity';
import { Maybe } from '@models/Core';
import { Assignment } from '@models/Course';
import { IUserExtension } from '@models/Extensions';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';

import AttemptsInput from '@components/AttemptsInput';
import Link from '@components/Common/Link';
import Table, { Column, SortByEntry } from '@components/Common/Table';
import { ProgressBar } from '@components/Core/ProgressBar';
import { SubmissionEventSummary, SubmissionRow } from './ActivitySubmissions';
import { Filter } from './ActivitySubmissionsFilters';

interface SubmissionsTableProps {
   allEvents: readonly SubmissionEventSummary[];
   assignment: Assignment;
   columns: readonly Filter<string>[];
   filteredIds: ReadonlySet<number>;
   pointsPossible: number;
   selectedIds: ReadonlySet<number>;
   submissions: readonly SubmissionRow[];
   variables: readonly ActivityVariable<ActivityMode.grade>[];
   csvRef: React.RefObject<CSVLink>;
   getExtension(userId: number): Maybe<IUserExtension>;
   getSubmissionLink(submissionId: number): string;
   showEvents(submissionId: number): void;
   onSortChanged(sortBy: readonly SortByEntry[]): void;
   toggleChecked(submissionId: number): void;
   toggleSelectAll(): void;
   updateAttempts(submissionId: number, updatedAttempts: number): void;
}

const SubmissionsTable: React.FC<SubmissionsTableProps> = ({
   allEvents,
   assignment,
   columns: propsColumns,
   filteredIds,
   pointsPossible,
   selectedIds,
   submissions,
   variables,
   csvRef,
   getExtension,
   getSubmissionLink,
   showEvents,
   onSortChanged,
   toggleChecked,
   toggleSelectAll,
   updateAttempts,
}) => {
   const checkSelectAll =
      submissions.length > 0 && filteredIds.size > 0 && selectedIds.size === filteredIds.size;

   const renderSelectAll = (): React.ReactNode => (
      <input
         data-test='select-all'
         type='checkbox'
         onChange={toggleSelectAll}
         checked={checkSelectAll}
      />
   );
   const renderSelect = (row: SubmissionRow): React.ReactNode => (
      <input
         type='checkbox'
         checked={selectedIds.has(row.submissionId)}
         onChange={() => toggleChecked(row.submissionId)}
      />
   );
   const renderStudentName = (row: SubmissionRow): React.ReactNode => {
      const link = getSubmissionLink(row.submissionId);
      const fullName = `${row.firstName} ${row.lastName}`;
      return link ? <Link to={link}>{fullName}</Link> : <span>{fullName}</span>;
   };

   const renderLastSubmitted = (row: SubmissionRow): React.ReactNode => {
      const extension = getExtension(row.userId);
      return (
         <div className='last-submitted-wrapper'>
            {row.modifiedOn ? formatDate(row.modifiedOn) : '-'}
            {!!extension && (
               <Tippy content={`Extended Until ${formatDate(extension.endDate)}`}>
                  <span className='extension-info'>
                     <IconCalendarTimeout />
                  </span>
               </Tippy>
            )}
         </div>
      );
   };

   const getEventSummary = (submissionId: number): Maybe<SubmissionEventSummary> =>
      allEvents.find((i) => i.submissionId === submissionId);

   const renderSubmissionEvents = (row: SubmissionRow): React.ReactNode => {
      const event = getEventSummary(row.submissionId);
      const alertIcon = event?.alertCount ? (
         <IconAlertTriangle className='event-level event-level-alert' />
      ) : null;
      return (
         <span
            className={classnames('pointer', { 'is-alert': event?.alertCount })}
            onClick={() => showEvents(row.submissionId)}
         >
            {event?.count}
            {alertIcon}
         </span>
      );
   };

   const renderPercentCompleted = (row: SubmissionRow): React.ReactNode => (
      <>
         <ProgressBar animate percentage={row.percentCompleted * 100} color='blue' />
         {row.percentCompleted ? Math.floor(row.percentCompleted * 100) : 0}%
      </>
   );
   const renderPercentGraded = (row: SubmissionRow): React.ReactNode => (
      <>
         <ProgressBar animate percentage={row.percentGraded * 100} color='green' />
         {row.percentGraded ? Math.floor(row.percentGraded * 100) : 0}%
      </>
   );
   const renderAttempts = (row: SubmissionRow): React.ReactNode => (
      <AttemptsInput
         firstName={row.firstName}
         lastName={row.lastName}
         originalAttempts={row.attemptsMade}
         maxAttempts={assignment.attemptsAllowed}
         updateAttempts={(attempts) => updateAttempts(row.submissionId, attempts)}
      />
   );
   const renderGrade = (row: SubmissionRow): string => {
      if (row.percentGraded && pointsPossible) {
         return `${Math.floor((100 * row.score) / pointsPossible)}%`;
      } else {
         return '-';
      }
   };

   const gradeSortValue = (row: SubmissionRow): number => {
      if (row.percentGraded && pointsPossible) {
         return row.score / pointsPossible;
      } else {
         return 0;
      }
   };

   const renderFinalGrade = (row: SubmissionRow): string => `${row.score}/${pointsPossible}`;

   const renderProgressScore = (row: SubmissionRow): string =>
      `${row.progressScore}/${pointsPossible}`;

   const renderVariable = (variableId: number, row: SubmissionRow): string =>
      row.variables[variableId]?.toString();

   const columns: readonly Column<SubmissionRow>[] = [
      { id: 'select', header: renderSelectAll, cell: renderSelect },
      {
         id: 'name',
         header: 'Student Name',
         cell: renderStudentName,
         str: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
         id: 'modifiedOn',
         header: 'Last Submitted',
         cellClassName: 'last-submitted',
         cell: renderLastSubmitted,
         canSort: true,
         str: (row) => (row.modifiedOn ? formatDate(row.modifiedOn) : '-'),
      },
      {
         id: 'events',
         header: 'Tracked Events',
         cell: renderSubmissionEvents,
         canSort: true,
         str: (row) => getEventSummary(row.submissionId)?.count.toString() ?? '',
      },
      {
         id: 'percentCompleted',
         header: '% Completed',
         cellClassName: 'completed-percent',
         cell: renderPercentCompleted,
         canSort: true,
         str: (row) => `${row.percentCompleted ? Math.floor(row.percentCompleted * 100) : 0}%`,
      },
      {
         id: 'percentGraded',
         header: '% Graded',
         cellClassName: 'graded-percent',
         cell: renderPercentGraded,
         canSort: true,
         str: (row) => `${row.percentGraded ? Math.floor(row.percentGraded * 100) : 0}%`,
      },
      {
         id: 'attemptsMade',
         header: 'Attempts',
         cell: renderAttempts,
         canSort: true,
         str: (row) => row.attemptsMade.toString(),
      },
      ...variables.map((i) => ({
         id: `var-${i.id}`,
         header: i.name,
         cell: (row: SubmissionRow) => renderVariable(i.id, row),
         str: (row: SubmissionRow) => renderVariable(i.id, row),
      })),
      {
         id: 'progressScore',
         header: 'Score',
         cell: renderProgressScore,
         canSort: true,
      },
      {
         id: 'score',
         header: 'Final Score',
         cell: renderFinalGrade,
         canSort: true,
         str: renderFinalGrade,
      },
      {
         id: 'grade',
         header: 'Grade',
         cell: renderGrade,
         canSort: true,
         str: renderGrade,
      },
   ];

   const filteredColumns = propsColumns
      .filter((i) => i.show)
      .map((i) => columns.find((j) => j.id === i.id))
      .filter((column): column is Column<SubmissionRow> => column !== undefined);

   const select = columns.find((i) => i.id === 'select');

   if (!select) {
      return null;
   }

   const getData = (): readonly string[][] => {
      const header = filteredColumns.map((i) => (_.isString(i.header) ? i.header : ''));
      const data = submissions.map((row) => filteredColumns.map((col) => col?.str?.(row) ?? ''));
      return [header, ...data];
   };

   // In order for the table to sort properly you need to ensure a data attribute lines up with a column name.
   // Additionally, for numbers use the number value not the string value to get the expected behavior.
   const tableData = submissions.map((row) => ({
      ...row,
      grade: gradeSortValue(row),
   }));

   return (
      <>
         <Table<SubmissionRow>
            className='submissions-table'
            columns={[select, ...filteredColumns]}
            onSortChanged={onSortChanged}
            rowKey='submissionId'
            rows={tableData}
            dataTest='activity-submissions'
         />
         <CSVLink data={getData()} filename='export.csv' style={{ display: 'none' }} ref={csvRef}>
            Export
         </CSVLink>
      </>
   );
};

export default SubmissionsTable;
