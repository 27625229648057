import * as React from 'react';

import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import DateTime from '@services/DateTimeService';
import Calendar from 'react-calendar';

import EmptyState from '@components/Core/EmptyState';
import CalendarItem from './CalendarItem';
import { AssignmentProfile } from './CourseDashboard';

type View = 'century' | 'decade' | 'year' | 'month';
interface DashboardCalendarProps {
   assignments: readonly AssignmentProfile[];
}

const DashboardCalendar: React.FC<DashboardCalendarProps> = ({ assignments }) => {
   const [selectedDate, setSelectedDate] = React.useState<Date>(DateTime.now());

   const formatCardTitle = (): string => {
      const months: Record<number, string> = {
         0: 'January',
         1: 'February',
         2: 'March',
         3: 'April',
         4: 'May',
         5: 'June',
         6: 'July',
         7: 'August',
         8: 'September',
         9: 'October',
         10: 'November',
         11: 'December',
      };
      return `${months[selectedDate.getMonth()]} ${selectedDate.getDate()}`;
   };

   const isSameDay = (a: Date, b: Date): boolean =>
      a.getDate() === b.getDate() &&
      a.getMonth() === b.getMonth() &&
      a.getFullYear() === b.getFullYear();

   const renderAssignmentDot = ({
      date,
   }: {
      activeStartDate: Date;
      date: Date;
      view: View;
   }): React.ReactNode => {
      const hasAssignments = assignments.some(({ endDate }) => isSameDay(endDate, date));
      return hasAssignments ? <span className='assignment-dot'>•</span> : null;
   };

   const activeAssignments = assignments.filter(({ endDate }) =>
      isSameDay(new Date(endDate), selectedDate),
   );

   const formatShortWeekday = (_locale: string | undefined, date: Date): string =>
      date.toDateString().slice(0, 2).toUpperCase();

   return (
      <div className='card margin-top-m no-padding margin-bottom-m'>
         <div className='card-title'>
            <div className='title'>Calendar</div>
         </div>
         <div className='calendar-container'>
            <div className='row'>
               <div className='col-xs-12 col-sm-6'>
                  <div className='calendar-wrapper'>
                     <Calendar
                        calendarType='gregory'
                        minDetail='month'
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date as Date)}
                        tileContent={renderAssignmentDot}
                        formatShortWeekday={formatShortWeekday}
                        nextAriaLabel='next month'
                        next2AriaLabel='next year'
                        prevAriaLabel='previous month'
                        prev2AriaLabel='previous year'
                     />
                  </div>
               </div>
               <div className='col-xs-12 col-sm-6'>
                  <div className='card-title'>
                     <div className='title'>{formatCardTitle()}</div>
                  </div>
                  <div className='calendar-event-container'>
                     {activeAssignments.length ? (
                        <div className='assignment-list'>
                           {activeAssignments.map((assignment) => (
                              <CalendarItem key={assignment.moduleItemId} assignment={assignment} />
                           ))}
                        </div>
                     ) : (
                        <EmptyState
                           icon={<IconContentBook2 aria-hidden />}
                           heading='No Assignments Due!'
                        />
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default DashboardCalendar;
