import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import Appearance from '@models/Appearance';
import Language, { LanguageLookup } from '@models/Language';
import { InterviewQuestion, Translation } from '@models/Video';
import InterviewQuestionService, { InterviewQuestionFields } from '@services/InterviewQuestionService';
import { v4 as uuidv4 } from 'uuid';

import {
   convertTranslationsArrayToTranslationsDict,
   convertTranslationsDictToTranslationsArray,
} from './helpers';

interface Props {
   selectedInterviewQuestion?: InterviewQuestion;
   onCancel(): void;
   onSave(): void;
}

type LanguageOrEmpty = '' | Language;

type TranslationWithEmpty = {
   language: LanguageOrEmpty;
   translation: string;
   key: string;
};

const InterviewQuestionEditorModal: React.FC<Props> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);

   const [question, setQuestion] = React.useState<string>(
      props.selectedInterviewQuestion?.name || '',
   );
   const [translations, setTranslations] = React.useState<TranslationWithEmpty[]>(
      props.selectedInterviewQuestion
         ? convertTranslationsDictToTranslationsArray(
              props.selectedInterviewQuestion.translations,
           ).map((x) => ({ ...x, key: uuidv4() }))
         : [],
   );

   const isLanguageUsed = React.useCallback(
      (language: LanguageOrEmpty): boolean =>
         translations.find((x) => x.language === language) !== undefined,
      [translations],
   );

   const hasEmptyAlready = React.useCallback(
      (): boolean => translations.find((x) => x.language === '') !== undefined,
      [translations],
   );

   const createInterviewQuestion = React.useCallback((data: InterviewQuestionFields): void => {
      setIsSaving(true);
      InterviewQuestionService.create({
         ...data,
      })
         .then(() => {
            setIsSaving(false);
            props.onSave();
         })
         .catch(() => {
            setIsSaving(false);
            alert('Failed to save.');
         });
   }, []);

   const updateInterviewQuestion = React.useCallback((data: InterviewQuestion): void => {
      setIsSaving(true);
      InterviewQuestionService.update(data)
         .then(() => {
            setIsSaving(false);
            props.onSave();
         })
         .catch(() => {
            setIsSaving(false);
            alert('Failed to save.');
         });
   }, []);

   const onSubmit = React.useCallback((): void => {
      const newTranslationsWithoutEmpties = translations.filter(
         (x) => x.language !== '',
      ) as Translation[];
      const newTranslations = convertTranslationsArrayToTranslationsDict(
         newTranslationsWithoutEmpties,
      );

      if (props.selectedInterviewQuestion !== undefined) {
         const interviewQuestion: InterviewQuestion = {
            id: props.selectedInterviewQuestion.id,
            name: question,
            translations: newTranslations,
         };
         updateInterviewQuestion(interviewQuestion);
      } else {
         const interviewQuestion: InterviewQuestionFields = {
            name: question,
            translations: newTranslations,
         };
         createInterviewQuestion(interviewQuestion);
      }
   }, [translations, question]);

   const deleteTranslation = React.useCallback((key: string): void => {
      setTranslations((prev) => [...prev.filter((x) => x.key !== key)]);
   }, []);

   const onQuestionUpdate = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>): void => {
      e.target.value;
      setQuestion(e.target.value);
   }, []);

   const onTranslationUpdate = React.useCallback(
      (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, key: string): void => {
         setTranslations((prevTranslations) => {
            const prevTranslationState = prevTranslations.find((x) => x.key === key);

            const { name, value } = e.target;
            // If it is not found return
            if (prevTranslationState === undefined) return { ...prevTranslations };

            const updatedTranslation: TranslationWithEmpty = {
               ...prevTranslationState,
               [name]: value as LanguageOrEmpty,
            };
            // Replace old object in array
            const index = prevTranslations.indexOf(prevTranslationState);
            prevTranslations.splice(index, 1, updatedTranslation);
            return [...prevTranslations];
         });
      },
      [],
   );

   const renderTranslation = (translationWithEmpty: TranslationWithEmpty): React.ReactNode => (
      <div className='flex space-between' key={translationWithEmpty.key}>
         <div className='col-xs-3'>
            <label className='field-title'>Language</label>
            <select
               name='language'
               onChange={(e) => onTranslationUpdate(e, translationWithEmpty.key)}
               value={translationWithEmpty.language}
            >
               {[
                  <option key={0} disabled value=''>
                     Select
                  </option>,
                  ...Object.entries(LanguageLookup).map(([code, label]) => (
                     <option
                        key={code}
                        value={code}
                        disabled={isLanguageUsed(code as LanguageOrEmpty)}
                     >
                        {label}
                     </option>
                  )),
               ]}
            </select>
         </div>
         <div className='col-xs-8'>
            <label className='field-title'>Translation</label>
            <input
               defaultValue={translationWithEmpty.translation}
               name='translation'
               onChange={(e) => onTranslationUpdate(e, translationWithEmpty.key)}
               type='text'
            />
         </div>
         <div className='col-xs-1'>
            <div className='flex flex-bottom'>
               <div
                  className='icon-action'
                  onClick={() => deleteTranslation(translationWithEmpty.key)}
               >
                  <IconBin />
               </div>
            </div>
         </div>
      </div>
   );

   const renderTranslations = (): React.ReactNode => [
      ...translations.map((i) => renderTranslation(i)),
   ];

   const addTranslation = (): void => {
      setTranslations((prev) => [
         ...prev,
         {
            language: '',
            translation: '',
            key: uuidv4(),
         },
      ]);
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='no-center padding-bottom-s'
         heading='Create Interview Question'
         onClose={props.onCancel}
         actions={[
            {
               text: 'Save',
               onClick: onSubmit,
               loading: isSaving,
            },
            { text: 'Cancel', onClick: props.onCancel },
         ]}
      >
         <form>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>Question (English)</label>
                  <textarea autoFocus onChange={onQuestionUpdate} value={question} />
               </div>
            </div>
            {renderTranslations()}
            <div className='col-xs-12 center'>
               <Button
                  disabled={hasEmptyAlready()}
                  line
                  fullWidth
                  className='margin-top-s'
                  onClick={addTranslation}
                  icon={<IconAddSmall aria-hidden />}
               >
                  Add Translation
               </Button>
            </div>
         </form>
      </ModalDialog>
   );
};

export default InterviewQuestionEditorModal;
