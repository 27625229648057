import * as React from 'react';

import Avatar from '@components/Core/Avatar';
import Droplist from '@components/Core/Droplist';
import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowUp from '@icons/general/icon-arrow-up.svg';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';

const UserDropdown: React.FC = () => {
   const {
      routes: {
         auth: { logout },
         settings,
      },
   } = Constants;

   const [isOpen, setOpen] = React.useState<boolean>(false);
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
   if (!userProfile) {
      return;
   }
   const { id: userId, firstName, lastName, profileImageUrl } = userProfile;

   return (
      <div className='nav-user-info'>
         <div className='user-dropdown'>
            <Avatar
               hashValue={userId}
               firstName={firstName}
               lastName={lastName}
               size='large'
               src={profileImageUrl}
            />
            <Droplist
               pullRight
               items={[
                  { text: 'Settings', to: settings },
                  { text: 'Logout', to: logout },
               ].filter((i) => !!i)}
               onChange={(open) => setOpen(open)}
            >
               <div className='user-name-wrapper' data-test='user-name-container'>
                  <div className='user-name' data-test='header-user-name'>
                     {`${firstName} ${lastName}`}
                  </div>
                  <div className='carrot'>{isOpen ? <IconArrowUp /> : <IconArrowDown />}</div>
               </div>
            </Droplist>
         </div>
      </div>
   );
};

export default UserDropdown;
