import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconContentNote from '@icons/nova-line/18-Content/content-note.svg';
import IconTranslate from '@icons/nova-line/52-Text/translate.svg';
import IconFileLandscapeImage from '@icons/nova-line/85-Files-Basic/file-landscape-image.svg';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import ImagePopup from './ImagePopup';
import NotesPopup from './NotesPopup';

interface VocabTermActionsProps {
   imagesPopupOpen: boolean;
   imageUrl: Maybe<string>;
   language: Maybe<Language>;
   notes: string;
   notesPopupOpen: boolean;
   removeImage(): void;
   removeTerm(): void;
   setImageByFile(file: File): void;
   setImageByUrl(imageUrl: string): void;
   setImagesPopupOpen(open?: boolean | ((open: boolean) => boolean)): void;
   setNotes(notes: string): void;
   setNotesPopupOpen(open?: boolean | ((open: boolean) => boolean)): void;
   translate(): void;
}

const VocabTermActions: React.FC<VocabTermActionsProps> = ({
   imagesPopupOpen,
   imageUrl,
   notes,
   language,
   notesPopupOpen,
   removeImage,
   removeTerm,
   setImageByFile,
   setImageByUrl,
   setImagesPopupOpen,
   setNotesPopupOpen,
   setNotes,
   translate,
}) => {
   const imageDropdownRef = React.useRef<HTMLDivElement>(null);
   const notesDropdownRef = React.useRef<HTMLDivElement>(null);

   React.useEffect(
      () => () => {
         document.removeEventListener('click', handleClick);
      },
      [],
   );

   const closeImagesPopup = (): void => {
      setImagesPopupOpen(false);
      document.removeEventListener('click', handleClick);
   };

   const openImagesPopup = (): void => {
      setImagesPopupOpen(true);
      setNotesPopupOpen(false);
      document.addEventListener('click', handleClick);
   };

   const closeNotesPopup = (): void => {
      setNotesPopupOpen(false);
      document.removeEventListener('click', handleClick);
   };

   const openNotesPopup = (): void => {
      setNotesPopupOpen(true);
      setImagesPopupOpen(false);
      document.addEventListener('click', handleClick);
   };

   const handleClick = (event: MouseEvent): void => {
      const target = event.target as Element;
      if (target?.classList.contains('ignore-click')) {
         return;
      } else if (imageDropdownRef.current && !imageDropdownRef.current.contains(target)) {
         closeImagesPopup();
      } else if (notesDropdownRef.current && !notesDropdownRef.current.contains(target)) {
         closeNotesPopup();
      }
   };

   return (
      <div className='vocab-set-row-action'>
         <label className='field-title'>Actions</label>
         <div className='icon-action-wrap'>
            {language !== 'asl' && (
               <div className='dropdown' ref={imageDropdownRef}>
                  <div
                     data-toggle='dropdown'
                     className='dropdown-toggle'
                     aria-expanded={imagesPopupOpen}
                  >
                     <Tippy content='Images'>
                        <div
                           className={classnames('icon-action', {
                              open: imagesPopupOpen,
                           })}
                           aria-label='Images'
                           data-test='vocab-term-actions-images'
                           onClick={imagesPopupOpen ? closeImagesPopup : openImagesPopup}
                        >
                           {imageUrl && (
                              <div className='icon-action-count'>
                                 <span>1</span>
                              </div>
                           )}
                           <IconFileLandscapeImage />
                        </div>
                     </Tippy>
                  </div>
                  <ImagePopup
                     imageUrl={imageUrl}
                     show={imagesPopupOpen}
                     removeImage={removeImage}
                     setImageByUrl={setImageByUrl}
                     setImageByFile={setImageByFile}
                  />
               </div>
            )}
            <div className='dropdown' ref={notesDropdownRef}>
               <div
                  data-toggle='dropdown'
                  className='dropdown-toggle'
                  aria-expanded={notesPopupOpen}
               >
                  <Tippy content='Notes'>
                     <div
                        className={classnames('icon-action', { open: notesPopupOpen })}
                        data-test='vocab-term-actions-notes'
                        aria-label='Notes'
                        onClick={notesPopupOpen ? closeNotesPopup : openNotesPopup}
                     >
                        {!!notes && (
                           <div className='icon-action-count'>
                              <span>1</span>
                           </div>
                        )}
                        <IconContentNote />
                     </div>
                  </Tippy>
               </div>
               {notesPopupOpen && <NotesPopup notes={notes} setNotes={setNotes} />}
            </div>
            {language !== 'asl' && (
               <Tippy content='Translate'>
                  <div
                     aria-label='Translate'
                     className='icon-action'
                     data-test='vocab-term-actions-translate'
                     onClick={translate}
                     onKeyDown={(e) => e.key === 'Enter' && translate()}
                     role='button'
                     tabIndex={0}
                  >
                     <IconTranslate height='14px' />
                  </div>
               </Tippy>
            )}
            <Tippy content='Delete'>
               <div
                  aria-label='Delete'
                  className='icon-action'
                  data-test='vocab-term-actions-delete'
                  onClick={removeTerm}
                  onKeyDown={(e) => e.key === 'Enter' && removeTerm()}
                  role='button'
                  tabIndex={0}
               >
                  <IconBin />
               </div>
            </Tippy>
         </div>
      </div>
   );
};

export default React.memo(VocabTermActions);
