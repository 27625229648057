import * as React from 'react';

import { ActivityBuilderMode, SpokenResponsePrompt } from '@models/Activity';

import Switch from '@components/Common/Switch';
import { PromptOptionsProps } from '@components/Activity/Builder/Prompt';

const SpokenResponsePromptOptions: React.FC<
   PromptOptionsProps<SpokenResponsePrompt<ActivityBuilderMode>>
> = ({ prompt, onUpdate }) => (
   <div className='row'>
      <div className='col-xs-12'>
         <div className='margin-top-s'>
            <Switch
               name='saveRecording'
               checked={prompt.saveRecording}
               onChange={() => onUpdate({ saveRecording: !prompt.saveRecording })}
            >
               Save Recording
            </Switch>
         </div>
      </div>
   </div>
);

export default SpokenResponsePromptOptions;
