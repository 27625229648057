import * as React from 'react';

import Switch from '@components/Common/Switch';
import ModalDialog from '@components/Core/ModalDialog';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import Appearance from '@models/Appearance';
import {
   CoursePermissionDescriptions,
   CoursePermissionName,
   CoursePermissionNameConstants,
   CoursePermissionNameLookup,
   CoursePermissions,
} from '@models/Course';
import CourseService from '@services/CourseService';

interface Props {
   courseId: number;
   closeModal(): void;
}
const CoursePermissionsModal: React.FC<Props> = (props) => {
   const [permissions, setPermissions] = React.useState<CoursePermissions>();
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   const fetchPermissions = async (): Promise<void> => {
      setIsLoading(true);
      const curentPermissions = await CourseService.getPermissions(props.courseId);
      setPermissions(curentPermissions);
      setIsLoading(false);
   };

   React.useEffect(() => {
      fetchPermissions();
   }, []);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (permissions === undefined) return;

      const permissionToUpdate = e.target.name as CoursePermissionName;
      setPermissions({ ...permissions, [permissionToUpdate]: e.target.checked });
   };

   const updatePermissions = async (): Promise<void> => {
      if (permissions === undefined) return;

      setIsLoading(true);
      const newPermissions = await CourseService.updatePermissions(props.courseId, permissions);
      setPermissions(newPermissions);
      setIsLoading(false);
      props.closeModal();
   };

   const renderSwitch = (permissionName: CoursePermissionName): React.ReactNode => {
      if (!permissions || permissions[permissionName] === undefined) {
         return;
      }

      return (
         <Switch
            name={permissionName}
            checked={permissions[permissionName]}
            onChange={handleChange}
         >
            <label className='field-title'>
               {CoursePermissionNameLookup[permissionName]}
               <InfoTooltip>{CoursePermissionDescriptions[permissionName]}</InfoTooltip>
            </label>
         </Switch>
      );
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='Permissions'
         onClose={props.closeModal}
         shouldCloseOnOverlayClick={false}
         footerClassName='card-footer'
         actions={[
            { text: 'Save', onClick: updatePermissions },
            { text: 'Cancel', onClick: props.closeModal },
         ]}
      >
         {!permissions || isLoading ? (
            <Loader />
         ) : (
            <div className='row'>
               <div className='col-xs-12'>
                  {CoursePermissionNameConstants.map((x) => renderSwitch(x))}
               </div>
            </div>
         )}
      </ModalDialog>
   );
};

export default CoursePermissionsModal;
