import * as React from 'react';

import parseHtml, { isTag, isText } from '@helpers/ParseHtml';
import { MarkTokensResponse } from '@models/Activity';
import Language from '@models/Language';
import classnames from 'classnames';

import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import InfoTooltip from '@components/InfoTooltip';

interface MarkTokensPromptProps {
   content: string;
   language: Language;
   response: MarkTokensResponse;
}

const MarkTokensPrompt: React.FC<MarkTokensPromptProps> = ({ content, response }) => {
   const renderTokens = (): React.ReactNode | readonly React.ReactNode[] | string =>
      parseHtml(content, {
         replace: (tag) => {
            if (isTag(tag) && tag.attribs) {
               const child = tag.children[0];
               const text = !!child && isText(child) ? child.data : null;
               if (tag.attribs['data-token']) {
                  const entry = response.find((i) => i.tokenId === tag.attribs['data-token']);
                  const selected = !!entry;
                  const unmodified = entry?.modified === false;
                  const correct = unmodified && entry?.correct === true;
                  const incorrect = unmodified && entry?.correct === false;
                  const missed = !selected && !!tag.attribs['data-correct'];
                  const className = classnames({
                     correct,
                     incorrect,
                     selected,
                     missed,
                  });

                  return (
                     <span {...tag.attribs} className={className}>
                        {text}
                     </span>
                  );
               } else if (tag.attribs['data-hint']) {
                  if (tag.children.length === 1 && text) {
                     return <InfoTooltip>{text}</InfoTooltip>;
                  }
               } else if (tag.name === 'audio' && tag.attribs['data-player-size']) {
                  return (
                     <AudioPlayer
                        src={tag.attribs.src}
                        size={tag.attribs['data-player-size'] as AudioPlayerSize}
                        className={classnames(tag.attribs.class)}
                     />
                  );
               }
            }
            return null;
         },
      });

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='mark-tokens-form grader'>{renderTokens()}</div>
         </div>
      </div>
   );
};

export default MarkTokensPrompt;
