import fuzzysort from 'fuzzysort';

import { Command } from './Models';

export interface Suggestion extends Command {
   highlight: string | readonly string[];
}

const getSuggestionHighlights = (
   suggestion: Fuzzysort.KeyResult<Command>,
): string | readonly string[] => {
   // if there's more than one suggestion, retun an array of
   // highlighted results. ex: ["first *result*", "second *result*"]
   if (Array.isArray(suggestion) && suggestion.length >= 2) {
      return suggestion
         .map((result) => fuzzysort.highlight(result))
         .filter((i): i is string => i !== null);
   }
   // otherwise return the single suggestion as a string. ex:
   // "only *result*"
   return fuzzysort.highlight(suggestion) || '';
};

// Teach Autosuggest how to calculate suggestions for any given input value.
const sortSuggestions = (
   search: string,
   allCommands: readonly Command[],
   options: Fuzzysort.KeyOptions,
): readonly Suggestion[] => {
   // If the user specified an autosuggest term
   // search for close matches
   const suggestionResults = fuzzysort.go<Command>(search, [...allCommands], options);

   // if the user didnt suggest a specific term or there's a search term
   // but no matches were found return all the commands
   if (!search || !suggestionResults.length) {
      return allCommands.map((i) => ({ ...i, highlight: '' }));
   }

   // Otherwise return the search results
   // format the output to include a code higlight for innerHTML
   // and the command to invoke
   return suggestionResults.map((suggestion) => ({
      highlight: getSuggestionHighlights(suggestion),
      ...suggestion.obj,
   }));
};

export default sortSuggestions;
