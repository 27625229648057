import * as React from 'react';

import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import { IdName } from '@models/Core';
import Tippy from '@tippyjs/react';

interface AddButtonProps {
   objectId: number;
   isAlreadyAdded: boolean;
   onAddClick(objectId: number): Promise<IdName | void>;
}

const AddButton: React.FC<AddButtonProps> = (props) => {
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   const onAddClick = (): void => {
      setIsLoading(true);
      props.onAddClick(props.objectId).then(() => {
         setIsLoading(false);
      });
   };

   if (props.isAlreadyAdded) {
      return (
         <button disabled style={{ border: 'None', background: 'None' }}>
            <IconRadioTick className='icon-green' height='20px' width='20px' />
         </button>
      );
   }

   return (
      <Tippy delay={[400, 0]} content='Clone Course For Instructor'>
         <button
            disabled={isLoading}
            style={{ border: 'None', background: 'None', cursor: 'pointer' }}
         >
            <IconAddSmall onClick={() => onAddClick()} />
         </button>
      </Tippy>
   );
};

export default AddButton;
