import * as React from 'react';

import Link from '@components/Common/Link';
import CheckMarkSSeal from '@icons/general/checkmark-seal.svg';

const PaymentModalFooter: React.FC = () => (
   <div className='flex-center'>
      <span style={{ margin: '10px' }}>
         <CheckMarkSSeal style={{ color: 'green' }} />
      </span>
      <label>
         14-day no risk
         <Link to='https://lingco.io/terms_of_service/' external>
            <strong> refund policy</strong>
         </Link>
      </label>
   </div>
);

export default PaymentModalFooter;
