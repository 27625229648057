import * as React from 'react';

import useStateWithReset from '@hooks/use-state-with-reset';
import { ModuleItemFile } from '@models/Course/ModuleItem';
import { uploadUserFile } from '@services/AssetService';
import classnames from 'classnames';

import ModuleItemSettingsAccordion from './ModuleItemSettingsAccordion';
import { getNameError, MAX_FILE_SIZE, MAX_NAME_LENGTH } from './ModuleUtils';

interface FileDetailsProps {
   canEditModule: boolean;
   item: ModuleItemFile;
   editItem(update: Partial<ModuleItemFile>): void;
}

const FileDetails: React.FC<FileDetailsProps> = ({ canEditModule, item, editItem }) => {
   const [nameInputValue, setNameInputValue] = useStateWithReset<string>(item.name);
   const [fileSizeError, setFileSizeError] = React.useState<boolean>(false);

   const nameError = getNameError(nameInputValue);

   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setNameInputValue(event.target.value);

   const handleNameBlur = (): void => {
      if (!canEditModule) {
         return;
      } else if (!nameError) {
         editItem({ name: nameInputValue, itemName: nameInputValue });
      } else {
         setNameInputValue(item.name);
      }
   };

   const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      setFileSizeError(false);
      if (event.target.files) {
         const file: File = event.target.files[0];
         const reader = new FileReader();
         reader.onloadend = () => {
            if (file.size > MAX_FILE_SIZE) {
               setFileSizeError(true);
               return;
            } else {
               const fileName = file.name ? file.name : 'New File';
               uploadUserFile(file).then(({ path, url }) => {
                  editItem({
                     path,
                     name: fileName,
                     itemName: fileName,
                     fileUrl: url,
                  });
               });
            }
         };
         reader.readAsDataURL(file);
      }
   };

   return (
      <ModuleItemSettingsAccordion title='File Details'>
         <div className='col-xs-12'>
            <label className='field-title'>Name</label>
            <input
               className={classnames({
                  error: nameError,
                  disabled: !canEditModule,
               })}
               disabled={!canEditModule}
               maxLength={MAX_NAME_LENGTH}
               name='name'
               onBlur={handleNameBlur}
               onChange={handleNameChange}
               type='text'
               value={nameInputValue}
            />
            {nameError && <p className='error'>{nameError}</p>}
         </div>
         {!item.fileUrl && canEditModule && (
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>File</label>
                  <input
                     className='file-content-input'
                     disabled={!canEditModule}
                     onChange={handleFileChange}
                     type='file'
                  />
                  {fileSizeError && <p className='error'>Max. size 200MB</p>}
               </div>
            </div>
         )}
      </ModuleItemSettingsAccordion>
   );
};

export default FileDetails;
