import * as React from 'react';

import { ProficiencyGoals } from '@models/Course/Course';
import { ProficiencyLevelConstants, ProficiencyLevelName } from '@models/Proficiency';
import classNames from 'classnames';

import Switch from '@components/Common/Switch';
import CourseProficiencyOptionRow from './CourseProficiencyOptionRow';

interface CourseProficiencyGoalsProps {
   courseProficiencyGoals: ProficiencyGoals;
   formErrorMessage?: string;
   enableCourseProficiency: boolean;
   setCourseProficiencyGoals(courseProficiencyGoals?: ProficiencyGoals): void;
   setEnableCourseProficiency(isEnabled: boolean): void;
}

const CourseProficiencyGoals: React.FC<CourseProficiencyGoalsProps> = ({
   courseProficiencyGoals,
   formErrorMessage,
   enableCourseProficiency,
   setCourseProficiencyGoals,
   setEnableCourseProficiency,
}) => {
   const allOptions = ProficiencyLevelConstants.map((level) => ({
      label: level,
      value: level as ProficiencyLevelName,
   }));

   const toggleCourseProficiency = (): void => {
      setEnableCourseProficiency(!enableCourseProficiency);
   };

   const onReadingEntryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         readingEntryLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onReadingGoalChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         readingGoalLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onWritingEntryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         writingEntryLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onWritingGoalChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         writingGoalLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onSpeakingEntryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         speakingEntryLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onSpeakingGoalChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         speakingGoalLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onListeningEntryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         listeningEntryLevel: event.target.value as ProficiencyLevelName,
      });
   };

   const onListeningGoalChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      setCourseProficiencyGoals({
         ...courseProficiencyGoals,
         listeningGoalLevel: event.target.value as ProficiencyLevelName,
      });
   };

   return (
      <div className='card course-card'>
         <div
            className={classNames('card-title full-width', { collapsed: !enableCourseProficiency })}
         >
            <div className='title'>Course Proficiency Goals</div>
            <Switch
               className='margin-left-m'
               data-test='enable-course-proficiency'
               checked={enableCourseProficiency}
               onChange={toggleCourseProficiency}
            />
         </div>
         {enableCourseProficiency && (
            <div className='content-form'>
               <div className='row flex-end'>
                  <div className='col-xs-5 space-around'>
                     <label className='field-title'>Start</label>
                  </div>
                  <div className='col-xs-5 space-around'>
                     <label className='field-title'>Goal</label>
                  </div>
               </div>
               <CourseProficiencyOptionRow
                  allOptions={allOptions}
                  rowLabel='Reading'
                  onEntryLevelChange={onReadingEntryChange}
                  onGoalLevelChange={onReadingGoalChange}
                  selectedEntryLevel={courseProficiencyGoals?.readingEntryLevel}
                  selectedGoalLevel={courseProficiencyGoals?.readingGoalLevel}
               />
               <CourseProficiencyOptionRow
                  allOptions={allOptions}
                  rowLabel='Writing'
                  onEntryLevelChange={onWritingEntryChange}
                  onGoalLevelChange={onWritingGoalChange}
                  selectedEntryLevel={courseProficiencyGoals?.writingEntryLevel}
                  selectedGoalLevel={courseProficiencyGoals?.writingGoalLevel}
               />
               <CourseProficiencyOptionRow
                  allOptions={allOptions}
                  rowLabel='Speaking'
                  onEntryLevelChange={onSpeakingEntryChange}
                  onGoalLevelChange={onSpeakingGoalChange}
                  selectedEntryLevel={courseProficiencyGoals?.speakingEntryLevel}
                  selectedGoalLevel={courseProficiencyGoals?.speakingGoalLevel}
               />
               <CourseProficiencyOptionRow
                  allOptions={allOptions}
                  rowLabel='Listening'
                  onEntryLevelChange={onListeningEntryChange}
                  onGoalLevelChange={onListeningGoalChange}
                  selectedEntryLevel={courseProficiencyGoals?.listeningEntryLevel}
                  selectedGoalLevel={courseProficiencyGoals?.listeningGoalLevel}
               />
               {formErrorMessage && <p className='error'>{formErrorMessage}</p>}
            </div>
         )}
      </div>
   );
};

export default CourseProficiencyGoals;
