import FileVideoUpload from '@icons/nova-line/95-Files-Video/file-video-upload.svg';
import React from 'react';

import Button from './Button';

type Props = React.DetailedHTMLProps<
   React.InputHTMLAttributes<HTMLInputElement>,
   HTMLInputElement
> & {
   onGoBack?(): void;
   titleComponent?: React.ReactNode;
};

const FileInputArea: React.FC<Props> = (props) => {
   const title = props.titleComponent || <div className='title medium'>Drop files to upload</div>;

   return (
      <div className='full-main-body-area'>
         <div className='visual'>
            <FileVideoUpload />
         </div>
         {title}
         <p className='margin-top-s margin-bottom-s text-center'>
            <strong>OR</strong>
         </p>
         <Button className='margin-bottom-m'>Select Files</Button>
         <input {...props} className='file-upload-input' type='file' />
         {props.onGoBack && (
            <Button line className='go-back margin-bottom-m' onClick={props.onGoBack}>
               Go Back
            </Button>
         )}
      </div>
   );
};

export default FileInputArea;
