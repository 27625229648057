import * as React from 'react';

import IconContentBox1 from '@icons/nova-line/18-Content/content-box-1.svg';
import Appearance from '@models/Appearance';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';

interface IArchiveCourseProps {
   archived: boolean;
   courseName: string;
   archiveCourse(): void;
   unarchiveCourse(): void;
   isProtected: boolean;
}

const ArchiveCourse: React.FC<IArchiveCourseProps> = ({
   archived,
   courseName,
   archiveCourse,
   unarchiveCourse,
   isProtected,
}) => {
   const [archiveModalOpen, setArchiveModalOpen] = React.useState<boolean>(false);

   const confirmArchive = (): void => {
      setArchiveModalOpen(true);
   };

   const closeArchiveModal = (): void => {
      setArchiveModalOpen(false);
   };

   const handleArchiveCourse = (): void => {
      setArchiveModalOpen(false);
      archiveCourse();
   };

   return (
      <>
         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>{`${archived ? 'Unarchive' : 'Archive'} Course`}</div>
            </div>
            <div className='content-form'>
               <div className='row'>
                  <div className='col-xs-9'>
                     {archived ? (
                        <p>
                           Unarchive this course to restore activity. You will be able to create new
                           assignments and students will be able to join the course.
                        </p>
                     ) : (
                        <p className='course-option-description'>
                           Once a course is archived, it will be deactivated but{' '}
                           <strong>nothing will be deleted</strong>. You won't be able to create new
                           assignments or add new students. Students will not be able to submit
                           assignments. Classrooms <strong>can</strong> be unarchived.
                        </p>
                     )}
                  </div>
                  <div className='col-xs-3'>
                     <div className='flex-end margin-top-s'>
                        {archived ? (
                           <Button appearance={Appearance.danger} onClick={unarchiveCourse}>
                              Unarchive Course
                           </Button>
                        ) : (
                           <Button
                              icon={<IconContentBox1 className='icon-white' aria-hidden />}
                              onClick={confirmArchive}
                              disabled={isProtected}
                              tooltip={
                                 isProtected
                                    ? 'This is a protected course and cannot be archived.'
                                    : ''
                              }
                           >
                              Archive Course
                           </Button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {archiveModalOpen && (
            <ModalDialog
               appearance={Appearance.warning}
               heading='Archive Course'
               onClose={closeArchiveModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Archive', onClick: handleArchiveCourse },
                  { text: 'Cancel', onClick: closeArchiveModal },
               ]}
            >
               <p>
                  Are you sure that you want to archive "{courseName}"? New assignments cannot be
                  created and students cannot join a course while it is archived.
               </p>
               <p>You can unarchive your course at any point.</p>
            </ModalDialog>
         )}
      </>
   );
};

export default React.memo(ArchiveCourse);
