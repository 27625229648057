import * as React from 'react';

import CommandController from '@components/CommandPalette/CommandController';
import { IMenuItem } from '@components/Core/Droplist';
import Toasts from '@components/Core/Toasts';
import NavBreadcrumbs from '@components/NavBreadcrumbs';
import { OnboardingChecklist } from '@components/Onboarding';
import AccountType from '@models/AccountType';
import {
   isPersistedNationalExamReportTaskResponse,
   isTransientNationalExamReportTaskResponse,
   NationalExamAbbreviation,
} from '@models/NationalExam';
import classnames from 'classnames';

import { AppStateContext } from '../../AppState';
import Constants, { AbbreviationExamNameRecord } from '../../Constants';
import Header from './Layout/Header';
import ImpersonationBanner from './Layout/ImpersonationBanner';
import NavbarMobile from './Layout/NavbarMobile';
import NavbarTablet from './Layout/NavbarTablet';
import Sidebar from './Layout/Sidebar';
import WarningModal from './Layout/WarningModal';
import PaymentModal from './Payment/PaymentModal';

interface MasterProps {
   children: React.ReactNode | React.ReactNodeArray;
   navbar?: boolean;
}

export interface NavigationItem extends IMenuItem {
   sublist?: readonly NavigationItem[];
}

const Master: React.FC<MasterProps> = ({ children, navbar = false }) => {
   const {
      routes: {
         contentLibrary: { root: contentLibraryRoot },
         courses: { create: createCourse, dashboard, join: joinCourse },
         nationalExam: { dashboard: examDashboard },
      },
      siteLinks,
   } = Constants;

   const {
      archivedCourses,
      currentCourses,
      toasts,
      userProfile,
      dismissToast,
      setReportTaskResponse,
      availableNationalExams,
   } = React.useContext<AppStateContext>(AppStateContext);
   const isInstructor = userProfile?.accountType === AccountType.instructor;

   const [onboardingChecklistEnabled, setOnboardingChecklistEnabled] =
      React.useState<boolean>(false);

   React.useEffect(() => {
      const demoCourse = currentCourses.find((i) => i.demo && /Lingco.*Demo/i.test(i.name));
      setOnboardingChecklistEnabled(demoCourse !== undefined && isInstructor);
   }, [userProfile?.accountType, currentCourses]);

   React.useEffect(() => {
      toasts.forEach((toast) => {
         if (toast.data && isPersistedNationalExamReportTaskResponse(toast.data)) {
            setReportTaskResponse(toast.data);
         }

         if (toast.data && isTransientNationalExamReportTaskResponse(toast.data)) {
            setReportTaskResponse(toast.data);
         }
      });
   }, [toasts]);

   const navItems = React.useMemo((): readonly NavigationItem[] => {
      const result: NavigationItem[] = [
         {
            text: 'Dashboard',
            to: currentCourses.length
               ? dashboard.replace(':courseId', currentCourses[0].id.toString())
               : dashboard,
         },
      ];

      if (isInstructor) {
         result.push({
            text: 'Content Library',
            to: contentLibraryRoot,
         });
      }

      const currentCoursesSublist = currentCourses.map(({ name, id }) => ({
         text: name,
         to: dashboard.replace(':courseId', id.toString()),
      }));

      if (isInstructor) {
         currentCoursesSublist.push({
            text: 'Create Course',
            to: createCourse,
         });
      }

      currentCoursesSublist.push({
         text: 'Join Course',
         to: joinCourse,
      });

      result.push({
         text: 'Current Courses',
         sublist: currentCoursesSublist,
      });

      if (archivedCourses.length) {
         result.push({
            text: 'Archived Courses',
            sublist: archivedCourses.map(({ name, id }) => ({
               text: name,
               to: dashboard.replace(':courseId', id.toString()),
            })),
         });
      }

      if (availableNationalExams?.length) {
         const examsAvailableToUser = [...availableNationalExams];
         examsAvailableToUser.sort();

         const examsList = examsAvailableToUser.map((examAbbr: NationalExamAbbreviation) => ({
            text: AbbreviationExamNameRecord[examAbbr],
            to: examDashboard.replace(':examUrlSlug', examAbbr),
         }));

         result.push({
            text: 'National Exams',
            sublist: examsList,
         });
      }

      result.push({
         text: 'Help & Support Guides',
         to: siteLinks.helpDocs,
         external: true,
      });

      if (isInstructor) {
         result.push({
            text: 'Feedback',
            to: siteLinks.feedback,
            external: true,
         });
      }

      return result;
   }, [archivedCourses, currentCourses]);

   return (
      <ImpersonationBanner>
         <Toasts toasts={toasts} onToastDismiss={dismissToast} />
         <PaymentModal />
         <WarningModal />
         <header>
            <div className='container-fluid'>
               <Header />
               {navbar && <NavbarTablet navItems={navItems} />}
               <NavbarMobile />
            </div>
         </header>
         <div className='container-fluid master-body'>
            <div className='row no-margin'>
               <NavBreadcrumbs />
               {navbar && <Sidebar />}
               <div
                  className={classnames('col-xs-12 no-padding', navbar && 'col-md-12 col-lg-11')}
                  role='main'
               >
                  {children}
               </div>
            </div>
            {onboardingChecklistEnabled && <OnboardingChecklist />}
         </div>
         <CommandController />
      </ImpersonationBanner>
   );
};

export default Master;
