import * as _ from 'lodash';
import * as React from 'react';

import { SearchBoxSuggestion } from '@mapbox/search-js-core';
import Appearance from '@models/Appearance';
import { IdName } from '@models/Core';
import { BasicLevelName } from '@models/Proficiency';
import { StringOption } from '@models/ReactSelectHelperTypes';
import { UploadingVideo } from '@models/Video';
import WorldLocation from '@models/WorldLocation';
import MapboxService from '@services/MapboxService';
import SpeakerService from '@services/SpeakerService';

import ModalDialog from '@components/Core/ModalDialog';
import TagsInput from '@components/Core/TagsInput';
import LocationSearch from '@components/MapBox/LocationSearch';
import MultiSelectTypeAhead from '@components/MultiSelectTypeAhead';

interface Props {
   videos: readonly UploadingVideo[];
   onCancel(): void;
   onSave(modalVideoForm: BulkVideoEditModalForm): void;
}

export type BulkVideoEditModalForm = {
   addTags: readonly string[];
   level?: BasicLevelName;
   location: (WorldLocation & { suggestion: SearchBoxSuggestion }) | null;
   removeTags: readonly string[];
   speakers: readonly IdName[];
};

const BulkVideoEditModal: React.FC<Props> = ({ videos, onCancel, onSave }) => {
   const allCurrentVideoTags = _.uniq(
      videos.map((x) => x.tags).reduce((acc, cur) => [...acc, ...cur]),
   );
   const [videoForm, setVideoForm] = React.useState<BulkVideoEditModalForm>({
      addTags: [],
      location: null,
      removeTags: [...allCurrentVideoTags],
      speakers: [],
   });
   const [selectedLocation, setSelectedLocation] = React.useState<SearchBoxSuggestion>();
   const [selectedSpeakers, setSelectedSpeakers] = React.useState<StringOption[]>();

   const handleInputOrSelectChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setVideoForm((prev) => ({ ...prev, [name]: value }));
   };

   const handleSpeakerChange = (options: readonly StringOption[]): void => {
      setSelectedSpeakers([...options]);
      const speakers: IdName[] = options.map((x) => ({
         id: parseInt(x.value),
         name: x.label,
      }));
      setVideoForm((prev) => ({ ...prev, speakers }));
   };

   const handleLocationChange = (suggestion: SearchBoxSuggestion | undefined): void => {
      setSelectedLocation(suggestion);

      if (suggestion) {
         MapboxService.getDetails(suggestion).then((mapProviderResponse) => {
            const firstFeature = mapProviderResponse.features[0];
            setVideoForm((prev) => ({
               ...prev,
               location: {
                  name: firstFeature.properties.name,
                  placeFormatted: firstFeature.properties.place_formatted,
                  displayName: firstFeature.properties.place_formatted,
                  latitude: firstFeature.properties.coordinates.latitude,
                  longitude: firstFeature.properties.coordinates.longitude,
                  countryCode: firstFeature.properties.context.country?.country_code_alpha_3 ?? '',
                  mapProviderId: firstFeature.properties.mapbox_id,
                  mapProviderResponse,
                  suggestion,
               },
            }));
         });
      } else {
         setVideoForm((prev) => ({ ...prev, location: null }));
      }
   };

   const handleAddTagChange = (tags: readonly string[]) => {
      setVideoForm((prev) => ({ ...prev, addTags: tags }));
   };

   const handleRemoveTagChange = (tags: readonly string[]) => {
      setVideoForm((prev) => ({ ...prev, removeTags: tags }));
   };

   const handleSave = (): void => {
      onSave(videoForm);
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='no-center padding-bottom-s'
         heading='Bulk Edit Videos'
         onClose={onCancel}
         actions={[
            {
               text: 'Save',
               onClick: handleSave,
            },
            { text: 'Cancel', onClick: onCancel },
         ]}
      >
         <form className='flex flex-col'>
            <label className='field-title'>Level</label>
            <select className='no-margin' name='level' onChange={handleInputOrSelectChange}>
               <option value={undefined} />
               <option value='Novice'>Novice</option>
               <option value='Intermediate'>Intermediate</option>
               <option value='Advanced'>Advanced</option>
            </select>
            <label className='field-title'>Add Speakers</label>
            <MultiSelectTypeAhead
               placeHolder='Start typing a speaker name.'
               rowLoader={SpeakerService.search}
               selectedOptions={selectedSpeakers}
               setSelectedOptions={handleSpeakerChange}
            />
            <label className='field-title'>Add Tags</label>
            <TagsInput
               inputProps={{ placeholder: '+ Add Tag' }}
               onChange={handleAddTagChange}
               value={videoForm.addTags}
            />
            <label className='field-title'>Remove Tags</label>
            <TagsInput
               disabled={videoForm.removeTags.length === 0}
               inputProps={{ placeholder: '- Remove Tag' }}
               onChange={handleRemoveTagChange}
               value={videoForm.removeTags}
            />
            <label className='field-title'>Location</label>
            <LocationSearch
               selectedSuggestion={selectedLocation}
               setSelectedSuggestion={handleLocationChange}
            />
         </form>
      </ModalDialog>
   );
};

export default BulkVideoEditModal;
