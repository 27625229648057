import * as React from 'react';

import { NationalExamAbbreviation } from '@models/NationalExam';
import { useParams } from 'react-router-dom';

import NationalExamOnlineOrPaperRegistrationForm from './Registration/NationalExamOnlineOrPaperRegistrationForm';
import NationalExamRegistrationForm from './Registration/NationalExamRegistationForm';

const ExamsWithOnlineOrPaperForm: NationalExamAbbreviation[] = [
   'nle',
   'nrce',
   'pme',
   'mme',
   'ele',
   'ncee',
   'ngre',
   'nlve',
   'nhce',
];

const NationalExamRegistration: React.FC = () => {
   const { examUrlSlug } = useParams<{ examUrlSlug: string }>();

   for (let i = 0; i < ExamsWithOnlineOrPaperForm.length; i++) {
      if (examUrlSlug?.includes(ExamsWithOnlineOrPaperForm[i])) {
         return <NationalExamOnlineOrPaperRegistrationForm examUrlSlug={examUrlSlug} />;
      }
   }

   return <NationalExamRegistrationForm examUrlSlug={examUrlSlug} />;
};

export default NationalExamRegistration;
