import * as _ from 'lodash';

import { parseDates } from '@helpers/DateUtils';
import { isNetworkConnectionError } from '@helpers/Error';
import history from '@helpers/History';
import { camelCaseKeys, snakeCaseKeys } from '@helpers/ModifyKeys';
import { MessageResponse } from '@models/Core';
import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'qs';

import Constants from '../Constants';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import { IgnorableHttpError } from '../types/IgnorableHttpError';
import AuthService from './AuthService';

type TransformResponseOptions = {
   camelCaseKeys?: boolean;
   parseDates?: boolean;
};

type RequestData = Record<string, unknown> | FormData;

type ResponseData = Record<string, unknown>;

type TransformRequestOptions = {
   /** Transform keys to camelCase or snake_keyss */
   caseKeyStyle?: 'camelCase' | 'snakeCase';
};

interface AxiosErrorHandlerOverrides {
   handleConflict?: boolean;
   handleForbidden?: boolean;
   handleNetworkConnectionError?: boolean;
   handleNotAllowed?: boolean;
   handleNotFound?: boolean;
   handlePaymentRequired?: boolean;
   handleServerError?: boolean;
   handleUnauthorized?: boolean;
}

declare module 'axios' {
   export interface AxiosRequestConfig {
      transformResponseOptions?: TransformResponseOptions;
      transformRequestOptions?: TransformRequestOptions;
      /** Opt out of AxiosService error handling */
      errorHandler?: boolean;
      errorHandlerOverrides?: AxiosErrorHandlerOverrides;
      downloadFile?: boolean;
      /** Allow us to track if the request is a retry caused by auth */
      _retry?: boolean;
   }
}

const regexForEndpointsToRetry = [
   RegExp('^/api/activities/[0-9]+$'),
   RegExp('^/api/activities/[0-9]+/started_on'),
   RegExp('^/api/activities/[0-9]+/current_question_id'),
   RegExp('^/api/activities/submissions/[0-9]+/responses/[0-9]+'),
   RegExp('^/api/activities/submissions/[0-9]+/responses/[0-9]+/entries'),
];

export const isCriticalActivityUrl = (url: unknown): url is string => {
   if (!_.isString(url)) {
      return false;
   }
   for (const regex of regexForEndpointsToRetry) {
      if (regex.test(url)) {
         return true;
      }
   }

   return false;
};

const transformRequest = (
   data: RequestData,
   options: TransformRequestOptions = {},
): RequestData | FormData => {
   // Check if data is an instance of FormData
   if (data instanceof FormData) {
      const formDataResult = new FormData();
      for (const pair of data.entries()) {
         switch (options.caseKeyStyle) {
            case 'camelCase':
               formDataResult.append(_.camelCase(pair[0]), pair[1]);
               break;
            default:
               formDataResult.append(_.snakeCase(pair[0]), pair[1]);
               break;
         }
      }
      return formDataResult;
   }

   // If data is not FormData, handle as before
   let result = { ...data };
   switch (options.caseKeyStyle) {
      case 'camelCase':
         result = camelCaseKeys(result);
         break;
      default:
         result = snakeCaseKeys(result);
   }
   return result;
};

const transformResponse = (
   data: ResponseData,
   options: TransformResponseOptions = {},
): ResponseData => {
   let result = { ...data };
   const config: TransformResponseOptions = {
      camelCaseKeys: true,
      parseDates: true,
      ...options,
   };

   if (config.camelCaseKeys) {
      result = camelCaseKeys(result);
   }
   if (config.parseDates) {
      result = parseDates(result);
   }

   return result;
};

const {
   statusCodes: {
      badRequest,
      conflict,
      forbidden,
      notFound,
      paymentRequired,
      serverError,
      unauthorized,
      unprocessableEntity,
   },
} = Constants;

const axiosErrorHandler = (error: AxiosError<MessageResponse, unknown>): Promise<never> => {
   if (error.config?.errorHandler === false) {
      return Promise.reject(error);
   }

   const errorOverrides: AxiosErrorHandlerOverrides = {
      handleServerError: true,
      handleNotFound: true,
      handleForbidden: true,
      handlePaymentRequired: true,
      handleNetworkConnectionError: true,
      handleUnauthorized: true,
      handleConflict: true,
      ...(error.config?.errorHandlerOverrides || {}),
   };

   const registeredHttpErrors = new Map<number, boolean>([
      // HTTP_STATUS_CODE, SHOULD_BE_HANDLED_BY_CALLER
      [conflict, !errorOverrides.handleConflict],
      [forbidden, !errorOverrides.handleForbidden],
      [notFound, !errorOverrides.handleNotFound],
      [paymentRequired, !errorOverrides.handlePaymentRequired],
      [serverError, !errorOverrides.handleServerError],
      [badRequest, true], // TODO: we shouldn't assume the caller will always handle BAD_REQUESTs
   ]);

   // if we've gotten this far then there must have been a non-401 error
   // we'll handle it appropriately depending on the environment
   let errorHandled = false;
   if (error.response) {
      const isRegisteredError = registeredHttpErrors.has(error.response.status);
      const errorIsHandledByCaller = registeredHttpErrors.get(error.response.status);
      if (isRegisteredError) {
         if (!errorIsHandledByCaller) {
            errorHandled = true;
            store.dispatch(actionFunctions.setNetworkError(error.response));
         }
      } else if (error.response.status === unauthorized) {
         // we're logging the user out in 'refreshToken' too, but that's called from
         errorHandled = true;
         AuthService.logoutRedirect();
      } else {
         errorHandled = true;
         store.dispatch(actionFunctions.setNetworkError(error.response));
      }
   } else if (isNetworkConnectionError(error) && isCriticalActivityUrl(error.config?.url)) {
      console.error(
         `Network connection error when calling ${String(
            error.config?.method,
         ).toUpperCase()} - "${error.config?.url}"`,
      );
      if (errorOverrides.handleNetworkConnectionError) {
         errorHandled = true;
         // Using a custom HTTP Status code for now to avoid refactoring 'network error' process
         // TODO: Refactor Network Error process to handle non-HTTP-Status errors better
         store.dispatch(actionFunctions.setNetworkError({ status: 900 } as AxiosResponse));
      }
   }

   const passThroughError = errorHandled ? new IgnorableHttpError(error.message) : error;
   return Promise.reject(passThroughError);
};

/**
 * Axios Instance
 * @example
 * // Basic Example
 * AxiosService.patch('/my-endpoint', {data: 'here'});
 * @example
 * // Pass query string params (Default: "snake case")
 * AxiosService.get('/my-endpoint', {params: {queryParam: 'option', queryParam2: 'option2'}});
 * AxiosService.post('/my-endpoint', {data: 'here'}, {params: {queryParam: 'option', queryParam2: 'option2'}});
 * // Using an array will result in "?query_param=1&query_param=2&query_param=3"
 * AxiosService.get('/my-endpoint', {params: {queryParam: [1,2,3]}});
 * @example
 * // Example of passing transform request options (Default: "snake case")
 * AxiosService.post('/my-endpoint', {data: 'here'}, {transformRequestOptions: {caseKeyStyle: "snakeCase"}});
 * AxiosService.post('/my-endpoint', {data: 'here'});
 * @example
 * // Example of passing transform response options (Default: "camel case")
 * AxiosService.get('/my-endpoint', {transformResponseOptions: {camelCaseKeys: false}});
 * @example
 * // Example of overriding global error handlers
 * AxiosService.post('/my-endpoint', {data: 'here'}, {errorHandlerOverrides: {handleBadRequest: false}});
 */
const AxiosService = axios.create({
   paramsSerializer: (params) => {
      const snakeCaseParams = snakeCaseKeys(params);
      return qs.stringify(snakeCaseParams, {
         arrayFormat: 'repeat',
         encodeValuesOnly: true,
      });
   },
});

// Bearer token handler
AxiosService.interceptors.request.use(
   async (config) => {
      const token = AuthService.getAccessToken();
      if (token) {
         config.headers['Authorization'] = 'Bearer ' + token;
      }

      if (config.downloadFile) {
         config.responseType = 'blob';
      }

      config.data = transformRequest(config.data, config.transformRequestOptions);

      return config;
   },
   (error) => {
      Promise.reject(error);
   },
);

// Refresh token handler
AxiosService.interceptors.response.use(
   (response) => {
      const redirectTo = response.data?.redirectTo || response.data?.redirect_to;

      if (redirectTo) {
         // Redirect user
         history.push(redirectTo);

         // we don't want the caller to actually get a response of any sort
         // so we'll just delay the response until the redirect (above) causes
         // the page to reload
         return new Promise((resolve) => setTimeout(resolve, 10000));
      }

      // Download file
      if (response.config.downloadFile) {
         // create file in memory
         const href = window.URL.createObjectURL(response.data);

         // Create "a" tag
         const link = document.createElement('a');
         link.href = href;

         const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0]; // this won't work with filenames that contain a semicolon
         link.setAttribute('download', filename);

         document.body.appendChild(link);
         link.click();

         // clean up
         document.body.removeChild(link);
         URL.revokeObjectURL(href);

         return response;
      }

      // Format the response
      response.data = transformResponse(response.data, response.config.transformResponseOptions);

      return response;
   },
   async (callError: AxiosError<MessageResponse>) => {
      if (callError.config?.errorHandler === false) {
         return Promise.reject(callError);
      }

      const originalRequest = callError.config;
      const statusCode = callError.response?.status;
      const errorMessage = callError.response?.data?.msg;
      const isAuthError =
         statusCode === unauthorized ||
         (statusCode === unprocessableEntity && errorMessage === 'Signature verification failed');

      // If this is an auth error and has not already been retried
      if (isAuthError && originalRequest && !originalRequest?._retry) {
         originalRequest._retry = true;
         const token = await AuthService.refreshAccessToken();

         if (!token) {
            // if we couldn't refresh the token, we'll pass this 401 error through
            console.error('Refresh token did not return access token (in makeCallWithAuthToken)');
            return Promise.reject(callError);
         }

         axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
         return AxiosService(originalRequest);
      }

      // Global Error handler
      return axiosErrorHandler(callError);
   },
);

axiosRetry(axios, {
   retries: 3,
   retryDelay: axiosRetry.exponentialDelay,
   retryCondition: (error) => {
      if (error.config?.errorHandler === false) {
         return false;
      }
      if (isNetworkConnectionError(error) && isCriticalActivityUrl(error?.config?.url)) {
         console.warn(
            `Retrying API call due to network connection error for ${String(
               error.config?.method,
            ).toUpperCase()} - "${error.config?.url}"`,
         );
         return true;
      }
      return false;
   },
});

export const downloadExternalFile = (url: string, fileName: string): void => {
   /*
      This function has been explicitly created to download files that do not require our API 
      authentication. If you try downloading a file from S3 with our auth headers you will get
      a bad request.
   */
   axios({
      url,
      method: 'GET',
      responseType: 'blob',
   }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
   });
};

export default AxiosService;
