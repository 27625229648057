import * as React from 'react';

import { DiscussionBoardResponse } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import DateTime from '@services/DateTimeService';
import moment from 'moment';

import DiscussionBoardResponseEntry from '@components/Activity/Completer/Prompts/DiscussionBoardResponseEntry';

interface DiscussionBoardPromptProps {
   response: DiscussionBoardResponse;
}

const DiscussionBoardPrompt: React.FC<DiscussionBoardPromptProps> = ({
   response: { authors, entries },
}) => {
   const getAuthorProfile = (userId: number): BasicUserProfile => authors[userId];

   const renderDateDivider = (index: number): React.ReactNode => {
      let dateStr = 'Today';
      const currentCreatedOn = entries[index].createdOn;
      const sameDay =
         index === 0 ? false : moment(entries[index - 1].createdOn).isSame(currentCreatedOn, 'day');
      if (sameDay) {
         return null;
      } else {
         const yesterday = DateTime.now();
         yesterday.setDate(yesterday.getDate() - 1);
         if (moment(currentCreatedOn).isSame(yesterday, 'day')) {
            dateStr = 'Yesterday';
         } else if (!moment(currentCreatedOn).isSame(DateTime.now(), 'day')) {
            dateStr = moment(currentCreatedOn).format('MMMM Do, YYYY');
         }
      }
      return (
         <div key={`divider-${index}`} className='date-divider'>
            <span>{dateStr}</span>
         </div>
      );
   };

   return (
      <div className='discussion-board-prompt'>
         <div>
            {!entries.length && (
               <p>
                  <i>No Posts</i>
               </p>
            )}
            {entries.map((i, j) => (
               <React.Fragment key={i.id}>
                  {renderDateDivider(j)}
                  <DiscussionBoardResponseEntry
                     entry={i}
                     allowReplies={false}
                     getAuthorProfile={getAuthorProfile}
                  />
               </React.Fragment>
            ))}
         </div>
      </div>
   );
};

export default DiscussionBoardPrompt;
