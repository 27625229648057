export enum ExtensionType {
   user = 'user',
   section = 'section',
   none = 'none',
}

export interface IUserExtension {
   id: number;
   userId: number;
   endDate: Date;
   extensionType: ExtensionType.user;
   moduleItemId: number;
}

export interface ModuleItemDatePair {
   startDate: Date;
   endDate: Date;
   extensionType: string;
   level: number;
}
