import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import { DiscussionBoardResponseEntry as DiscussionBoardResponseEntryType } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import moment from 'moment';
import pluralize from 'pluralize';
import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';
import Avatar from '@components/Core/Avatar';
import { ActivityCompleterContext } from '@components/Activity/Completer/ActivityCompleter';

interface DiscussionBoardResponseEntryProps {
   entry: DiscussionBoardResponseEntryType;
   allowReplies?: boolean;
   replies?: readonly DiscussionBoardResponseEntryType[];
   getAuthorProfile(userId: number): BasicUserProfile;
   onReply?(entry: string): void;
}

const DiscussionBoardResponseEntry: React.FC<DiscussionBoardResponseEntryProps> = ({
   entry: { entry, userId, createdOn },
   allowReplies = false,
   replies = [],
   getAuthorProfile,
   onReply,
}) => {
   const canReply = allowReplies && !!onReply;
   const editorRef = React.useRef<Maybe<TinyMCEEditor>>(null);
   const [showReplies, setShowReplies] = React.useState<boolean>(false);
   const [replyDraftText, setReplyDraftText] = React.useState<string>('');
   const [authorProfile, setAuthorProfile] = React.useState<Maybe<BasicUserProfile>>(null);
   const { language } = React.useContext<ActivityCompleterContext>(ActivityCompleterContext);
   React.useEffect(() => {
      setAuthorProfile(getAuthorProfile(userId));
   }, [userId]);

   const toggleShowReplies = (): void => {
      setShowReplies((prevShowReplies) => !prevShowReplies);
   };

   const handleSubmitReply = (): void => {
      onReply?.(replyDraftText);
      setReplyDraftText('');
      editorRef.current?.resetContent();
   };

   const renderReplyButtonText = (): string => {
      if (showReplies) {
         if (replies.length) {
            return `Hide ${pluralize('reply', replies.length)}`;
         }
         return 'Hide';
      } else if (replies.length) {
         return `${replies.length} ${pluralize('reply', replies.length)}`;
      }
      return 'Reply';
   };

   if (!authorProfile) {
      return null;
   }

   return (
      <div className='discussion-board-entry-wrapper'>
         <div className='comment'>
            <div className='author-avatar-wrapper'>
               <Avatar
                  hashValue={userId}
                  firstName={authorProfile.firstName}
                  lastName={authorProfile.lastName}
                  src={authorProfile.profileImageUrl}
               />
            </div>
            <div className='entry-body'>
               <div className='entry-header'>
                  <div className='title'>{`${authorProfile.firstName} ${authorProfile.lastName}`}</div>
                  <small className='entry-timestamp'>{moment(createdOn).format('hh:mm A')}</small>
               </div>
               <div className='entry-text'>
                  {ensureElement(parseHtml(entry) as React.ReactNode, 'p')}
               </div>
            </div>
         </div>
         <div className='comment-replies-wrapper'>
            {(canReply || !!replies.length) && (
               <Button subtle className='show-replies-button' onClick={toggleShowReplies}>
                  {renderReplyButtonText()}
               </Button>
            )}
            {showReplies && (
               <>
                  {replies.map((reply) => (
                     <DiscussionBoardResponseEntry
                        key={reply.id}
                        entry={reply}
                        allowReplies={allowReplies}
                        getAuthorProfile={getAuthorProfile}
                     />
                  ))}
                  {canReply && (
                     <div className='padding-top-s align-items-center'>
                        <div className='flex-1'>
                           <Editor
                              config={{
                                 placeholder: 'Write a reply...',
                                 contextmenu: 'upload-file insert-image record-video',
                                 toolbar:
                                    'bold italic underline strikethrough | forecolor backcolor | quicklink',
                              }}
                              editorRef={(editor) => (editorRef.current = editor)}
                              language={language}
                              onChange={(text) => setReplyDraftText(text)}
                              toolbarAppearance={ToolbarAppearance.floating}
                              value={replyDraftText}
                           />
                        </div>
                        <Button
                           onClick={handleSubmitReply}
                           disabled={!replyDraftText}
                           className='margin-left-s'
                        >
                           Reply
                        </Button>
                     </div>
                  )}
               </>
            )}
         </div>
      </div>
   );
};

export default DiscussionBoardResponseEntry;
