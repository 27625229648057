import { ContextInfo } from '@models/Breadcrumbs';
import { Maybe } from '@models/Core';

import { CommandName } from './CommandController';

export enum CommandCategory {
   navigation = 'Navigation',
   command = 'Command',
   system = 'System',
   admin = 'Admin',
   nationalExamAdmin = 'NationalExamAdmin',
   video = 'Video',
}

export interface Command {
   /** unique ID for the command. */
   id: string;
   /** title, as shown in the command palette */
   title: string;
   /** Should close on completion? */
   autoClose?: boolean;
   /** array of command ids to override if shown */
   overrides?: readonly string[];
   /** category of command */
   category?: CommandCategory;
   /** link redirected to if command is selected */
   link?: Maybe<string>;
   /* Multiplied to the score given by the query. Allows us to lower or raise something in the list. */
   scale?: number;
   /** function called when the command is selected. */
   action?(): void;
   /** function that can hide a command at runtime. The command is shown if this is undefined. */
   showIf?(info: ContextInfo): boolean;
   /** command name to switch to */
   commandName?: CommandName;
}
