import * as React from 'react';

import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconUserAdd from '@icons/nova-solid/07-Users/user-add.svg';
import IconContentBook2 from '@icons/nova-solid/18-Content/content-book-2.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { AutoSizer, GridCellProps, MultiGrid } from 'react-virtualized';

import Constants from '../../../Constants';
import Link from '@components/Common/Link';
import EmptyState from '@components/Core/EmptyState';
import {
   GradebookAssignmentProfile,
   GradebookGrouping,
   GradebookProfile,
   GradebookSettings,
} from './Gradebook';
import GradebookCell from './GradebookCell';
import GradeRanges from './GradeRanges';
import StudentName from './StudentName';
import { getGradeColor, GradeData } from './Utils';

interface GradebookGridProps {
   assignments: readonly GradebookAssignmentProfile[];
   courseName: string;
   courseId: number;
   displayGradesAs: 'points' | 'percentage';
   gradebookSettings: GradebookSettings;
   gradeData: readonly GradeData[];
   groupGradesBy: GradebookGrouping;
   roster: readonly GradebookProfile[];
   showNeedsGrading: boolean;
   totalAssignments: number;
   totalRoster: number;
}

const GradebookGrid: React.FC<GradebookGridProps> = (props) => {
   const {
      routes: {
         activities: { gradeSubmissionFromModuleId },
         enrollments,
      },
   } = Constants;

   const assignmentText = `${props.assignments.length} ${pluralize(
      'Assignment',
      props.assignments.length,
   )}`;
   const moduleCount = new Set(props.assignments.map((i) => i.moduleId)).size;

   const getAssignmentByModuleItemId = (
      moduleItemId: number,
   ): GradebookAssignmentProfile | undefined =>
      props.assignments.find((i) => i.moduleItemId === moduleItemId);

   const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps): React.ReactNode => {
      if (columnIndex === 0 && rowIndex === 0) {
         return renderCourseInfo(key, style);
      } else if (columnIndex === 0) {
         return renderNameCell(key, rowIndex, style);
      } else if (rowIndex === 0) {
         return renderHeaderCell(columnIndex, key, style);
      } else {
         return renderGradeCell(columnIndex, key, rowIndex, style);
      }
   };

   const renderCourseInfo = (key: string, style: React.CSSProperties): React.ReactNode => (
      <div key={key} style={style} className='grid-item grid-item--header gradebook-details'>
         <div className='gradebook-course-detail-top'>
            <ul>
               <li>{props.courseName}</li>
               <li>{assignmentText}</li>
               <li>{props.roster.length} Enrolled</li>
            </ul>
         </div>
         <GradeRanges gradebookSettings={props.gradebookSettings} />
      </div>
   );

   const renderNameCell = (
      key: string,
      rowIndex: number,
      style: React.CSSProperties,
   ): React.ReactNode => {
      const profile = props.roster[rowIndex - 1];
      props.roster[rowIndex];
      if (props.gradeData.length === 0) {
         return (
            <div key={key} style={style} className='grid-item gray'>
               -
            </div>
         );
      }
      return (
         <StudentName
            key={key}
            style={style}
            profile={profile}
            gradeColor={getGradeColor(profile.grade, props.gradebookSettings)}
         />
      );
   };

   const renderHeaderCell = (
      columnIndex: number,
      key: string,
      style: React.CSSProperties,
   ): React.ReactNode => {
      const { heading, headingUrl, endOfModule } = props.gradeData[columnIndex - 1];
      const showEndOfModule =
         (props.groupGradesBy === GradebookGrouping.assignment && endOfModule) ||
         props.groupGradesBy === GradebookGrouping.module;
      const element = (
         <div
            key={key}
            style={style}
            className={classnames('grid-item', 'grid-item--header', 'column-header', {
               'end-of-module': showEndOfModule,
               'single-module': moduleCount === 1,
            })}
         >
            {heading}
         </div>
      );
      if (headingUrl) {
         return (
            <Link key={key} to={headingUrl}>
               {element}
            </Link>
         );
      }
      return element;
   };

   const renderGradeCell = (
      gridColumnIndex: number,
      key: string,
      gridRowIndex: number,
      style: React.CSSProperties,
   ): React.ReactNode => {
      const columnIndex = gridColumnIndex - 1;
      const rowIndex = gridRowIndex - 1;

      const columnGradeData = props.gradeData[columnIndex];
      const profile = props.roster[rowIndex];
      const showEndOfModule =
         (props.groupGradesBy === GradebookGrouping.assignment && columnGradeData.endOfModule) ||
         props.groupGradesBy === GradebookGrouping.module;

      let url = null;
      let score: Maybe<number> = null;
      let percentGraded: Maybe<number> = null;
      const pointsPossible = columnGradeData.pointsPossibleByStudent
         ? columnGradeData.pointsPossibleByStudent[rowIndex]
         : columnGradeData.pointsPossible;

      if (props.groupGradesBy === GradebookGrouping.assignment) {
         const assignment = getAssignmentByModuleItemId(columnGradeData.id);
         if (!assignment) {
            return <></>;
         }
         const { submissions, itemType } = assignment;
         if (!Object.prototype.hasOwnProperty.call(submissions, profile.userId)) {
            return (
               <div
                  key={key}
                  style={style}
                  className={classnames('grid-item gray', {
                     'end-of-module': showEndOfModule,
                     'single-module': moduleCount === 1,
                  })}
               >
                  -
               </div>
            );
         }
         const {
            score: submissionScore,
            submissionId,
            percentGraded: submissionPercentGraded,
         } = submissions[profile.userId];
         percentGraded = submissionPercentGraded;
         score = submissionScore;
         if (itemType === 'activity' && submissionId) {
            url = gradeSubmissionFromModuleId
               .replace(':moduleItemId', columnGradeData.id.toString())
               .replace(':submissionId', submissionId.toString())
               .concat('?ref=gradebook');
         }
      } else {
         score = columnGradeData.grades[rowIndex];
         if (props.groupGradesBy === GradebookGrouping.module) {
            url = enrollments.byId
               .replace(':enrollmentId', profile.enrollmentId.toString())
               .concat(`?moduleId=${columnGradeData.id}&ref=gradebook`);
         }
      }

      const needsGrading = props.showNeedsGrading && !!percentGraded && percentGraded < 1;

      return (
         <GradebookCell
            key={key}
            className={classnames({
               'end-of-module': showEndOfModule,
               'single-module': moduleCount === 1,
               'needs-grading': needsGrading,
            })}
            displayGradesAs={props.displayGradesAs}
            gradebookSettings={props.gradebookSettings}
            pointsPossible={pointsPossible}
            score={score}
            style={style}
            url={url}
         />
      );
   };

   const renderEmptyInfo = (): React.ReactNode => {
      const { totalAssignments, totalRoster, assignments, roster } = props;

      if (isEmptyGrid()) {
         if ((totalAssignments && !assignments.length) || (totalRoster && !roster.length)) {
            return (
               <EmptyState
                  icon={<IconBinoculars className='large' aria-hidden />}
                  heading='No Results'
                  description='Try changing your filters.'
               />
            );
         } else if (!totalAssignments) {
            return (
               <EmptyState
                  icon={<IconContentBook2 className='large' aria-hidden />}
                  heading='No Grades Yet!'
                  description='Grades will appear as soon as assignments are created.'
               />
            );
         } else if (!totalRoster) {
            return (
               <EmptyState
                  icon={<IconUserAdd className='large' aria-hidden />}
                  heading='No Students Yet!'
                  description='Grades will appear as soon as students are added.'
               />
            );
         }
      }
      return <></>;
   };

   const getColumnWidth = ({ index }: { index: number }): number => {
      switch (index) {
         case 0:
            return 230;
         default:
            return 40;
      }
   };

   const getRowHeight = ({ index }: { index: number }): number => {
      switch (index) {
         case 0:
            return 195;
         default:
            return 40;
      }
   };

   const isEmptyGrid = (): boolean => {
      const { assignments, roster } = props;
      return !(assignments.length && roster.length);
   };

   return (
      <div className='grid-container'>
         <AutoSizer>
            {({ width, height }) => (
               <MultiGrid
                  className='grid'
                  cellRenderer={cellRenderer}
                  columnWidth={getColumnWidth}
                  columnCount={isEmptyGrid() ? 1 : props.gradeData.length + 1}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  height={height}
                  rowHeight={getRowHeight}
                  rowCount={isEmptyGrid() ? 1 : props.roster.length + 1}
                  width={width}
                  overscanRowCount={10}
               />
            )}
         </AutoSizer>
         {renderEmptyInfo()}
      </div>
   );
};

export default React.memo(GradebookGrid);
