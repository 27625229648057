import * as _ from 'lodash';
import * as React from 'react';

import parseHtml from '@helpers/ParseHtml';
import { ensureElement } from '@helpers/utils';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import IconTick from '@icons/general/icon-tick.svg';
import {
   GroupingPromptCategory as GroupingPromptCategoryType,
   GroupingPromptItem,
   GroupingResponse,
} from '@models/Activity';
import classnames from 'classnames';

interface GroupingPromptCategoryProps {
   category: GroupingPromptCategoryType;
   items: readonly GroupingPromptItem[];
   entries: GroupingResponse;
}

const GroupingPromptCategory: React.FC<GroupingPromptCategoryProps> = ({
   category: { name },
   items,
   entries,
}) => (
   <div className='dnd-column-container'>
      <div className='dnd-column-heading'>
         <div className='title large'>{name}</div>
      </div>
      <div className='dnd-column-items-wrapper'>
         <div className='dnd-column-items grouping-column-items'>
            {_.sortBy(entries, 'index').map((entry) => {
               const item = items.find((i) => i.id === entry.itemId);
               if (!item) {
                  return null;
               }
               const unmodified = entry.modified === false;
               const correct = unmodified && entry.correct === true;
               const incorrect = unmodified && entry.correct === false;

               return (
                  <div
                     className={classnames('dnd-item', {
                        correct,
                        incorrect,
                     })}
                     key={entry.itemId}
                  >
                     <div className='dnd-item-inner'>
                        {(correct || incorrect) && (
                           <div className='dnd-item-icon'>
                              {correct ? <IconTick /> : <IconCloseSmall />}
                           </div>
                        )}
                        <div className='dnd-item-text'>
                           {ensureElement(parseHtml(item.data), 'p')}
                        </div>
                     </div>
                  </div>
               );
            })}
         </div>
      </div>
   </div>
);

export default GroupingPromptCategory;
