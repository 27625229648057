import * as React from 'react';

import classnames from 'classnames';

interface ContentLibraryFilterProps<ValueType = string> {
   defaultExpanded?: boolean;
   heading: string;
   icon: React.ReactNode;
   options: readonly { value: ValueType; label: string }[];
   value: readonly ValueType[];
   onChange(updatedValue: ValueType[]): void;
}

const ContentLibraryFilter = <ValueType,>({
   defaultExpanded = true,
   heading,
   icon,
   options,
   value,
   onChange,
}: ContentLibraryFilterProps<ValueType>) => {
   const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const name = event.target.name as ValueType;
      onChange(value.includes(name) ? value.filter((i) => i !== name) : [...value, name]);
   };

   const toggleExpanded = (): void => setExpanded((prevExpanded) => !prevExpanded);

   return (
      <div className='filter-section'>
         <div className='toggle-wrap'>
            <div className='toggle-title row' onClick={toggleExpanded}>
               {icon}
               <div className='title'>{heading}</div>
            </div>
            <div className={classnames('toggle-content', { expanded })}>
               <ul className='list-sub-level'>
                  {options.map(({ label, value: optionValue }) => (
                     <li key={optionValue as string}>
                        <div className='checkbox'>
                           <label className='pointer'>
                              <input
                                 name={optionValue as string}
                                 type='checkbox'
                                 checked={value.includes(optionValue)}
                                 onChange={handleChange}
                              />
                              {label}
                           </label>
                        </div>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </div>
   );
};

export default ContentLibraryFilter;
