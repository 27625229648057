import * as _ from 'lodash';
import * as React from 'react';

import DeleteAction from '@components/TableActions/DeleteAction';
import { User } from '@generated/gql/graphql';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import ImpersonateUserButton from './ImpersonateUserButton';

interface UserActionsCellProps {
   row: User;
   onUpdate(row: User): void;
   onDelete(): void;
}

const UserActionsCell: React.FC<UserActionsCellProps> = ({ row, onDelete }) => {
   const navigate = useNavigate();

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const disabled = userProfile?.id === Number(row.id);
   const fullName = `${row.firstName} ${row.lastName}`;

   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   const handleUserEdit = (): void => {
      if (!disabled) {
         navigate(Constants.routes.admin.user.userDetails.replace(':userId', `${row.id}`));
      }
   };

   const handleDeleteUser = (): Promise<void> => {
      setIsLoading(true);
      return UserService.deleteUser(Number(row.id)).then(() => {
         setIsLoading(false);
         onDelete();
      });
   };

   return (
      <>
         <div className='table-row-action'>
            <div className='icon-action-wrap'>
               <Tippy content='Edit' disabled={disabled} delay={[400, 0]}>
                  <div className={classnames('icon-action', { disabled })} onClick={handleUserEdit}>
                     <IconHighlight />
                  </div>
               </Tippy>
               <ImpersonateUserButton userFullName={row.fullName} userId={row.id} />
               <DeleteAction
                  disabled={disabled}
                  isLoading={isLoading}
                  objectName={fullName}
                  onDelete={handleDeleteUser}
               />
            </div>
         </div>
      </>
   );
};

export default UserActionsCell;
