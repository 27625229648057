import * as React from 'react';

import { ActivityContentType, PromptType } from '@models/Activity';

import Constants from '../../../Constants';
import { ActivityBuilderState } from '@components/Activity/Builder/ActivityBuilder';
import {
   IOnboardingStep,
   IOnboardingWalkthrough,
   OnboardingStepType,
   OnboardingWalkthrough,
} from '@components/Onboarding/Models';

const {
   routes: {
      content: { newActivity },
   },
} = Constants;

const steps: readonly IOnboardingStep[] = [
   {
      id: 'welcome_post',
      type: OnboardingStepType.post,
      content: (
         <>
            <h1>Welcome to the Activity Builder! 🎉</h1>
            <p>
               Here, you can create rich learning experiences for your students. You can use
               activities for presenting new topics, practice and review, or assessments.
            </p>
            <p>
               Easily create questions that can be automatically graded with instant feedback such
               as multiple choice, fill in the blanks, or matching. Enhance your activities by
               bringing in rich media like videos, audio, and images.
            </p>
            <p>
               The possibilities for activities are endless. Ask comprehension questions about what
               happened in a story, have students put events in order, group items into categories,
               fill in sentences, or give their opinion in a free response text or recording prompt.
               The activity builder is your blank canvas to create something incredible.
            </p>
            <p>
               To get started, we'll go over the two categories of item types and then build a
               simple recording activity where you ask the student a question and they respond with
               a recording of their own.
            </p>
         </>
      ),
   },
   {
      id: 'content_items_pointer',
      type: OnboardingStepType.pointer,
      content: (
         <p>
            <strong>Content items</strong> are what you give to your students, or the stimuli. This
            could be the story, introduction, recording to listen to, video to watch, etc.
         </p>
      ),
      selector: '[data-tour="activity-content-items"]',
      placement: 'right',
      overlayOptions: {
         paddingBottom: -1,
         paddingTop: 8,
      },
   },
   {
      id: 'prompt_items_pointer',
      type: OnboardingStepType.pointer,
      content: (
         <p>
            <strong>Prompt items</strong> are how your students respond and interact with the
            content. These can be assigned point values and graded.
         </p>
      ),
      selector: '[data-tour="activity-prompt-items"]',
      placement: 'right',
      overlayOptions: {
         paddingBottom: -1,
         paddingTop: 8,
      },
   },
   {
      id: 'add_question_section',
      type: OnboardingStepType.pointer,
      overlay: false,
      content: (
         <p>
            To build your activity, start by clicking the <strong>Add Question Section</strong>{' '}
            button!
         </p>
      ),
      selector: '[data-tour="add-question-section"]',
      className: 'animated infinite pulse slow green-outline',
      placement: 'bottom',
      primaryButton: false,
      beforeUnmount: (): void => {
         const button = document.querySelector(
            '[data-tour="add-question-section"]',
         ) as HTMLButtonElement;
         button?.blur();
      },
      shouldAdvance: (state: ActivityBuilderState, prevState: ActivityBuilderState): boolean =>
         prevState.questions.length === 0 && state.questions.length === 1,
   },
   {
      id: 'add_audio_content_pointer',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="audio_content"]',
      content: (
         <p>
            <strong>Great!</strong> We'll start by adding some audio content. Grab the "audio
            content" item and <strong>drag</strong> it on to the question section.
         </p>
      ),
      cursor: {
         from: '[data-tour="audio_content"]',
         to: '.activity-builder-container',
         repeat: -1,
         transitionDelay: 1,
      },
      overlay: false,
      placement: 'left',
      fallbackPlacements: ['bottom'],
      primaryButton: false,
      shouldAdvance: (state: ActivityBuilderState, prevState: ActivityBuilderState): boolean =>
         prevState.questions[0].items.length === 0 &&
         state.questions[0].items.length === 1 &&
         state.questions[0].items[0].itemType === ActivityContentType.audioContent,
   },
   {
      id: 'record_audio_content_pointer',
      type: OnboardingStepType.pointer,
      selector: '.audio-icon-wrapper',
      content: (
         <p>
            Next, click the blue dot to record yourself. When you're finished, click it again to
            stop. Try asking your students a question! 🗣️
         </p>
      ),
      overlay: false,
      placement: 'left',
   },
   {
      id: 'add_recording_prompt_pointer',
      type: OnboardingStepType.pointer,
      selector: '[data-tour="recording_prompt"]',
      content: (
         <>
            <p>Now, we want your students to be able to respond to your question.</p>
            <p>
               Let's <strong>drag</strong> an audio recording prompt onto the question section below
               the audio content.
            </p>
         </>
      ),
      cursor: {
         from: '[data-tour="recording_prompt"]',
         to: '.activity-builder-container',
         repeat: -1,
         transitionDelay: 1,
      },
      overlay: false,
      placement: 'left',
      fallbackPlacements: ['right'],
      primaryButton: false,
      shouldAdvance: (state: ActivityBuilderState, prevState: ActivityBuilderState): boolean =>
         prevState.questions[0].items.length === 1 &&
         state.questions[0].items.length === 2 &&
         state.questions[0].items[0].itemType === ActivityContentType.audioContent &&
         state.questions[0].items[1].itemType === PromptType.audioRecordingPrompt,
      advanceWhenVisible: '.mce-content-body',
   },
   {
      id: 'add_prompt_description_pointer',
      type: OnboardingStepType.pointer,
      className: 'show-shadow',
      selector: '.mce-content-body',
      content: (
         <p>
            Great! Now let's add some directions to the prompt for your students{' '}
            <i>(i.e. respond to the prompt)</i>. Each prompt comes with its own text box for
            directions.
         </p>
      ),
      overlay: false,
      placement: 'left',
   },
   {
      id: 'add_prompt_weight_pointer',
      type: OnboardingStepType.pointer,
      selector: 'input[name="weight"]',
      content: (
         <>
            <p>
               Finally, let's pick the number of points. By default it's <strong>1.0</strong>, but
               you can make it worth any number.
            </p>
            <p>
               You'll learn later how you can use rubrics to break down the grade into specific
               parts.
            </p>
         </>
      ),
      overlay: false,
      placement: 'left',
      onMount: (elem: HTMLInputElement): void => {
         elem.select();
      },
   },
   {
      id: 'question_section_title',
      type: OnboardingStepType.pointer,
      selector: 'input[name="question-section-title"]',
      content: (
         <>
            <p>
               Activities can have many <strong>sections</strong>. To keep the activities organized,
               you can give sections titles like "Part 1", "Recording", etc.
            </p>
            <p>If you'd like, give your section a title.</p>
         </>
      ),
      overlay: false,
      placement: 'left',
      onMount: (elem: HTMLInputElement): void => {
         elem.select();
      },
   },
   {
      id: 'activity_name',
      type: OnboardingStepType.pointer,
      content: <p>The last step is to give your new activity a name!</p>,
      selector: '[data-tour="content-name"]',
      overlay: false,
      placement: 'bottom',
      onMount: (elem: HTMLInputElement): void => {
         elem.focus();
      },
   },
   {
      id: 'create_activity',
      type: OnboardingStepType.pointer,
      overlay: false,
      content: <p>Now you're ready to create your activity! Click the blue button.</p>,
      className: 'animated infinite pulse slow green-outline',
      selector: '[data-tour="primary-activity-builder-btn"]',
      hideWhenRemoved: '[data-tour="primary-activity-builder-btn"]',
      expectedLocationChanges: [/^\/content\/activities\/\d+\/preview$/],
      placement: 'left',
      primaryButton: false,
      advanceWhenVisible: '.completer',
   },
   {
      id: 'walkthrough_complete_post',
      type: OnboardingStepType.post,
      confetti: true,
      content: (
         <>
            <h1>Congratulations! 🎉</h1>
            <p>
               You've finished creating your first of many activities on Lingco! Now you can preview
               it from the student's perspective. If you'd like you can enhance it by adding some
               additional prompts or you can visit the <strong>Content Library</strong> and take a
               look at some of the activities that others on Lingco have made.
            </p>
            <p>
               With the Activity Builder, there's unlimited potential! We can't wait to see what you
               create with it.
            </p>
         </>
      ),
   },
   // {
   //    id: 'getting_started',
   //    type: OnboardingStepType.post,
   //    content: (
   //       <>
   //       <p>Let's start by using some of these elements to create an activity!</p>
   //       <ChooseActivityTemplateModal />
   //       </>
   //    ),
   //    primaryButton: false
   //    // advanceWhenVisible: '.mce-content-body'
   // }
];

export default {
   id: OnboardingWalkthrough.activityBuilderIntro,
   steps,
   startRoute: newActivity,
} as IOnboardingWalkthrough;
