import Lesson from '@models/Lesson';
import HttpService from '@services/HttpService';

const fetchLesson = (lessonId: number): Promise<{ lesson: Lesson; canEdit: boolean }> =>
   HttpService.getWithAuthToken<{ lesson: Lesson; canEdit: boolean }>(
      `/api/content/lessons/${lessonId}`,
   ).then((response) => response.data);

export default {
   fetchLesson,
};
