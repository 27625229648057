import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Appearance from '@models/Appearance';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import { LoadOptions, NumericOption, OptionsCallback } from '@models/ReactSelectHelperTypes';
import HttpService from '@services/HttpService';

import { AppStateContext } from '../../AppState';
import Avatar from '@components/Core/Avatar';
import ModalDialog from '@components/Core/ModalDialog';
import { IAction } from '@components/Core/ModalDialog/Modal';
import {
   AsyncSelect,
   Menu,
   MenuProps,
   selectComponents,
   selectStyle,
   selectTheme,
} from '@components/Core/Select';
import { UserPartial } from './ShareContentModal';
import ShareModalRow from './ShareModalRow';

interface ShareFolderModalProps {
   folderName: string;
   folderId: number;
   closeShareModal(): void;
}

interface ShareFolderModalState {
   createdBy: Maybe<UserPartial>;
   inputValue: string;
   selectedDefaultOption: string;
   selectedUsers: readonly NumericOption[];
   sharedWith: readonly UserPartial[];
   permissionsChanged: boolean;
}

const ShareFolderModal: React.FC<ShareFolderModalProps> = ({
   folderName,
   folderId,
   closeShareModal,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<ShareFolderModalState>({
      createdBy: null,
      inputValue: '',
      selectedDefaultOption: 'view',
      selectedUsers: [],
      sharedWith: [],
      permissionsChanged: false,
   });

   React.useEffect(() => {
      HttpService.getWithAuthToken<{ createdBy: UserPartial; sharedWith: readonly UserPartial[] }>(
         `/api/content/folders/${folderId}`,
      ).then((response) => {
         const { createdBy, sharedWith } = response.data;
         setState((prevState) => ({ ...prevState, createdBy, sharedWith }));
      });
   }, []);

   const handlePeopleChange = (newSelectedUsers: readonly NumericOption[]): void => {
      const selectedUsers = newSelectedUsers === null ? [] : newSelectedUsers;
      setState((prevState) => ({ ...prevState, selectedUsers, inputValue: '' }));
   };

   const handleDefaultChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = event.target;
      setState((prevState) => ({ ...prevState, selectedDefaultOption: value }));
   };

   const handlePermissionChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const { name, value } = event.target;
      const contentPermissionId = parseInt(name, 10);
      setState((prevState) => ({
         ...prevState,
         sharedWith: prevState.sharedWith.map((i) =>
            i.contentPermissionId === contentPermissionId ? { ...i, permission: value } : i,
         ),
         permissionsChanged: true,
      }));
   };

   const handleCloneableChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = event.target;
      const contentPermissionId = parseInt(name, 10);
      setState((prevState) => ({
         ...prevState,
         sharedWith: prevState.sharedWith.map((i) =>
            i.contentPermissionId === contentPermissionId ? { ...i, cloneable: checked } : i,
         ),
         permissionsChanged: true,
      }));
   };

   const handleRemoveAccess = (contentPermissionId: Maybe<number>): void => {
      if (contentPermissionId) {
         setState((prevState) => ({
            ...prevState,
            sharedWith: prevState.sharedWith.filter(
               (i) => i.contentPermissionId !== contentPermissionId,
            ),
            permissionsChanged: true,
         }));
      }
   };

   const userToValue = (user: BasicUserProfile): NumericOption => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName} <${user.email}>`,
   });

   const loadOptions: LoadOptions<NumericOption> = (
      inputValue: string,
      callback: OptionsCallback<NumericOption>,
   ): void => {
      if (inputValue && state.createdBy) {
         const excludeIds = [...state.sharedWith.map((i) => i.id), state.createdBy.id];
         HttpService.getWithAuthToken<{ users: readonly BasicUserProfile[] }>(
            `/api/users/search?q=${inputValue}`,
         ).then((response) => {
            const { users } = response.data;
            callback(users.filter((i) => !excludeIds.includes(i.id)).map(userToValue));
         });
      } else {
         callback([]);
      }
   };

   const handleInputChange = (inputValue: string): void => {
      setState((prevState) => ({ ...prevState, inputValue }));
   };

   const handleSaveChanges = (): void => {
      const permissions = [
         ...state.sharedWith.map(({ contentPermissionId, cloneable, permission }) => ({
            id: contentPermissionId,
            cloneable,
            permission,
         })),
         ...state.selectedUsers.map(({ value }) => ({
            id: null,
            userId: value,
            permission: state.selectedDefaultOption,
            cloneable: null,
         })),
      ];
      HttpService.putWithAuthToken<{ msg: string; sharedWith: readonly UserPartial[] }>(
         `/api/content/folders/${folderId}/permissions`,
         snakeCaseKeys({ permissions }),
      ).then((response) => {
         const { sharedWith: updatedSharedwith } = response.data;
         setState((prevState) => ({
            ...prevState,
            sharedWith: updatedSharedwith,
            selectedUsers: [],
            permissionsChanged: false,
         }));
      });
   };

   const actionButtons = (): readonly IAction[] => {
      if (state.permissionsChanged || state.selectedUsers.length) {
         return [
            { text: 'Save', onClick: handleSaveChanges },
            { text: 'Cancel', onClick: closeShareModal },
         ];
      } else {
         return [{ text: 'Close', onClick: closeShareModal }];
      }
   };

   const renderUserIcon = ({
      firstName,
      lastName,
      id,
      profileImageUrl,
   }: BasicUserProfile): React.ReactNode => (
      <Avatar
         firstName={firstName}
         lastName={lastName}
         hashValue={id}
         src={profileImageUrl}
         size='large'
      />
   );

   const renderMenu = (props: MenuProps<NumericOption, true>): JSX.Element =>
      state.inputValue ? <Menu {...props}>{props.children}</Menu> : <></>;

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='share-content-modal'
         heading={`Sharing Settings: ${folderName}`}
         onClose={closeShareModal}
         animations={{ enter: 'animated bounceInDown' }}
         footerClassName='card-footer'
         actions={actionButtons()}
      >
         <div className='access-rows'>
            {state.createdBy && (
               <ShareModalRow
                  rowType='owner'
                  name={`${state.createdBy.firstName} ${state.createdBy.lastName} ${
                     state.createdBy.id === userProfile?.id ? '(you)' : '(creator)'
                  }`}
                  icon={renderUserIcon(state.createdBy)}
               />
            )}
            <div className='shared-with'>
               {!!state.sharedWith.length &&
                  state.sharedWith.map(
                     ({
                        schoolName,
                        permission,
                        contentPermissionId,
                        cloneable,
                        ...userDetails
                     }) => (
                        <ShareModalRow
                           rowType='user'
                           key={userDetails.id}
                           name={`${userDetails.firstName} ${userDetails.lastName}`}
                           icon={renderUserIcon(userDetails)}
                           switchValue={cloneable}
                           permissionId={contentPermissionId}
                           permissionValue={permission}
                           schoolName={schoolName}
                           handleSwitchChange={handleCloneableChange}
                           handleUserDelete={() => handleRemoveAccess(contentPermissionId)}
                           handlePermissionChange={handlePermissionChange}
                        />
                     ),
                  )}
            </div>
         </div>
         <div className='invite-people'>
            <label className='field-title'>Invite People</label>
            <div className='row'>
               <div className='col-xs-9'>
                  <AsyncSelect<NumericOption, true>
                     isMulti
                     isSearchable
                     placeholder='Enter names or email addresses...'
                     loadOptions={loadOptions}
                     onChange={handlePeopleChange}
                     inputValue={state.inputValue}
                     onInputChange={handleInputChange}
                     value={state.selectedUsers}
                     className='react-select'
                     theme={selectTheme}
                     components={{
                        ...selectComponents,
                        DropdownIndicator: () => null,
                        Menu: renderMenu,
                     }}
                     noOptionsMessage={() => 'No Users Found'}
                     styles={selectStyle}
                  />
               </div>
               <div className='col-xs-3'>
                  <div className='default-permission'>
                     <select value={state.selectedDefaultOption} onChange={handleDefaultChange}>
                        <option value='view'>View</option>
                        <option value='edit'>Edit</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </ModalDialog>
   );
};

export default ShareFolderModal;
