import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import { useLocation } from 'react-router-dom';

import DocumentTitle from '@components/DocumentTitle';

const AuthRequestCallback: React.FC = () => {
   const location = useLocation();

   React.useEffect(() => {
      const message = getQueryParameterByName(location, 'message');
      if (window.opener && message) {
         window.opener.postMessage(message);
         window.close();
      }
   }, []);

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card padding-bottom-m'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>Authorization Successful</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           Success
                        </h2>
                     </div>
                     <p>
                        Credentials have been successfully obtained. This window should close
                        automatically momentairly.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AuthRequestCallback;
