import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Appearance from '@models/Appearance';
import ContentFolder from '@models/ContentFolder';
import { IdMessageResponse } from '@models/Core';
import HttpService from '@services/HttpService';

import { AppStateContext } from '../../AppState';
import AccentTextbox from '@components/AccentTextbox';
import ModalDialog from '@components/Core/ModalDialog';

interface NewContentFolderModalProps {
   activeFolderId: number;
   onCreateNewFolder(folder: ContentFolder): void;
   closeNewFolderModal(): void;
}

const NewContentFolderModal: React.FC<NewContentFolderModalProps> = ({
   activeFolderId,
   closeNewFolderModal,
   onCreateNewFolder,
}) => {
   const [newFolderName, setNewFolderName] = React.useState<string>('');
   const [isCreatingNewFolder, setIsCreatingNewFolder] = React.useState<boolean>(false);

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const handleNewFolderNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setNewFolderName(event.target.value);
   };

   const createNewFolder = (): Promise<void> => {
      const newFolder = {
         name: newFolderName,
         parentId: activeFolderId,
      };
      setIsCreatingNewFolder(true);
      return HttpService.postWithAuthToken<IdMessageResponse>(
         '/api/content/folders',
         snakeCaseKeys(newFolder),
      ).then((response) => {
         const { id } = response.data;
         onCreateNewFolder({
            ...newFolder,
            createdBy: userProfile?.id,
            id,
         });
      });
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='New Folder'
         onClose={closeNewFolderModal}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[
            {
               text: 'Create',
               onClick: createNewFolder,
               loading: isCreatingNewFolder,
            },
            { text: 'Cancel', onClick: closeNewFolderModal },
         ]}
      >
         <AccentTextbox
            language={userProfile?.language}
            autoFocus
            name='newFolderName'
            value={newFolderName}
            onChange={handleNewFolderNameChange}
         />
      </ModalDialog>
   );
};

export default NewContentFolderModal;
