import * as _ from 'lodash';
import * as React from 'react';

import ToastType from '@models/Toast';
import classnames from 'classnames';

import Toast from './Toast';

export interface ToastsProps {
   toasts: readonly ToastType[];
   onToastDismiss(toastId: string | number): void;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, onToastDismiss }) => {
   const className = classnames('toast-container', {
      'toast-container-empty': !toasts.length,
   });
   return (
      <div className={className}>
         {toasts.map((toast) => (
            <Toast
               animations={{
                  enter: 'animated fadeInRight',
                  exit: 'animated fadeOut',
               }}
               key={toast.id}
               {...toast}
               onRequestHide={() => (toast.id ? onToastDismiss(toast.id) : _.noop())}
            />
         ))}
      </div>
   );
};

export default Toasts;
