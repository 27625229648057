import { randomShortId } from '@helpers/RandomStringUtils';
import IVocabTerm from '@models/IVocabTerm';

export enum Delimiter {
   comma = 'comma',
   custom = 'custom',
   newline = 'newline',
   semicolon = 'semicolon',
   tab = 'tab',
}

export const partOfSpeechOptions: readonly string[] = [
   'adjective',
   'adverb',
   'article',
   'conjunction',
   'noun',
   'preposition',
   'pronoun',
   'verb',
];

export const numberOptions: readonly string[] = ['singular', 'plural'];

export const MIN_TERMS = 3;
// Number of terms to start with
export const BASE_TERMS = 5;
// Max length of term or definition
export const MAX_LEN = 64;

export const isEmpty = ({ term, definition, partOfSpeech, gender }: IVocabTerm<true>): boolean =>
   [term, definition, partOfSpeech, gender].every((i) => i.length === 0);

export const generateTerms = (num = 1): readonly IVocabTerm<true>[] =>
   [...Array(num).keys()].map(() => ({
      definition: '',
      errors: {},
      gender: '',
      id: null,
      index: null,
      imageFilename: '',
      key: randomShortId(),
      notes: '',
      number: '',
      partOfSpeech: '',
      term: '',
   }));
