import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { getQueryParameterByName } from '@helpers/QueryParameter';
import HttpService from '@services/HttpService';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';

interface CloneCourseState {
   courseCode: string;
   allowDuplicate: boolean;
   error: string;
   isLoading: boolean;
   submitAutomatically: boolean;
}

const CloneCourse: React.FC = () => {
   const location = useLocation();
   const navigate = useNavigate();

   const {
      routes: {
         courses: { dashboard: courseDashboard },
      },
   } = Constants;
   const appContext = React.useContext(AppStateContext);

   const [state, setState] = React.useState<CloneCourseState>({
      allowDuplicate: false,
      courseCode: '',
      error: '',
      isLoading: false,
      submitAutomatically: false,
   });

   React.useEffect(() => {
      const courseCode = getQueryParameterByName(location, 'code', '');
      const allowDuplicate =
         getQueryParameterByName(location, 'force', '').toLowerCase() === 'true';
      if (courseCode) {
         setState((prevState) => ({
            ...prevState,
            courseCode,
            allowDuplicate,
            submitAutomatically: true,
         }));
      }
   }, []);

   React.useEffect(() => {
      if (state.submitAutomatically) {
         handleSubmit();
      }
   }, [state.submitAutomatically]);

   const handleSubmit = (): void => {
      const { courseCode, isLoading, allowDuplicate } = state;
      if (courseCode && !isLoading) {
         setState((prevState) => ({ ...prevState, isLoading: true }));
         HttpService.postWithAuthToken<{ msg: string; id: number; name: string }>(
            `/api/courses/${courseCode}/clone`,
            snakeCaseKeys({ notifyUser: false, async: false, allowDuplicate }),
            { handleNotFound: false },
         )
            .then((response) => {
               const { id, name } = response.data;
               setState((prevState) => ({
                  ...prevState,
                  error: '',
                  isLoading: false,
               }));
               navigate(courseDashboard.replace(':courseId', id.toString()));
               if (!appContext.currentCourses.find((x) => x.id === id)) {
                  appContext.appendCourse({
                     id,
                     name,
                     demo: false,
                     needsToPurchaseLicense: false,
                     trialEndOn: null,
                  });
               }
            })
            .catch((err) => {
               setState((prevState) => ({
                  ...prevState,
                  error: "We couldn't find that course. Are you sure you copied it down correctly?",
                  isLoading: false,
               }));
               throw err;
            });
      }
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setState((prevState) => ({ ...prevState, courseCode: value }));
   };

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>Clone Course</DocumentTitle>
         <div className='card center col-lg-6 padding-l'>
            <div className='text-center title large'>Clone a Course</div>
            <p>Please enter the course code given to you below.</p>
            <div className='join-course-form'>
               <input
                  type='text'
                  value={state.courseCode}
                  onChange={handleChange}
                  disabled={state.isLoading}
                  placeholder='Course Code'
               />
               <Button className='center margin-s' onClick={handleSubmit} loading={state.isLoading}>
                  Submit
               </Button>
            </div>
            {state.error && <p className='error'>{state.error}</p>}
         </div>
      </div>
   );
};

export default CloneCourse;
