import * as _ from 'lodash';
import * as React from 'react';

import {
   isAudioRecordingPrompt,
   isDiscussionBoardPrompt,
   isFilePrompt,
   isFillBlanksPrompt,
   isGroupingPrompt,
   isImageLabelingPrompt,
   isMarkTokensPrompt,
   isMultipleChoicePrompt,
   isOrderingPrompt,
   isSpokenResponsePrompt,
   isTextPrompt,
   isVideoRecordingPrompt,
} from '@components/Activity/Utils';
import WeightInput from '@components/Activity/WeightInput';
import Button from '@components/Common/Button';
import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import SelectTypeAhead from '@components/SelectTypeAhead';
import IconDownwardLineTriangle from '@icons/activities/downward-line-triangle.svg';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconFileCopy from '@icons/nova-line/85-Files-Basic/file-copy.svg';
import { ActivityBuilderMode, ActivityPrompt } from '@models/Activity';
import { Maybe } from '@models/Core';
import { ProficiencyCanDoStatementDetailed } from '@models/Proficiency';
import { StringOption } from '@models/ReactSelectHelperTypes';
import ProficiencyCanDoStatementService from '@services/ProficiencyCanDoStatementService';
import UserService from '@services/UserService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { AppStateContext } from '../../../AppState';
import { ActivityContext } from './ActivityBuilder';
import ItemOptions from './ItemOptions';
import DiscussionBoardPrompt from './Prompts/DiscussionBoardPrompt';
import DropboxPrompt from './Prompts/DropboxPrompt';
import FillBlanksPrompt, { FillBlanksPromptOptions } from './Prompts/FillBlanksPrompt';
import GroupingPrompt from './Prompts/GroupingPrompt';
import ImageLabelingPrompt from './Prompts/ImageLabelingPrompt';
import MarkTokensPrompt from './Prompts/MarkTokensPrompt';
import MultipleChoicePrompt, { MultipleChoicePromptOptions } from './Prompts/MultipleChoicePrompt';
import OrderingPrompt from './Prompts/OrderingPrompt';
import RecordingPrompt, { RecordingPromptOptions } from './Prompts/RecordingPrompt';
import SpokenResponsePrompt from './Prompts/SpokenResponsePrompt/SpokenResponsePrompt';
import SpokenResponsePromptOptions from './Prompts/SpokenResponsePrompt/SpokenResponsePromptOptions';
import TextPrompt from './Prompts/TextPrompt';
import TextPromptOptions from './Prompts/TextPrompt/TextPromptOptions';
import VideoPrompt from './Prompts/VideoPrompt';

export interface PromptProps {
   descriptionClassName?: string;
   prompt: ActivityPrompt<ActivityBuilderMode>;
   duplicateItem?(): void;
   onRemove?(): void;
   onUpdate(update: Partial<ActivityPrompt<ActivityBuilderMode>>, callback?: () => void): void;
}

export interface PromptOptionsProps<T> {
   prompt: T;
   onUpdate(update: Partial<T>): void;
}

const Prompt: React.FC<PromptProps> = ({
   descriptionClassName,
   prompt,
   duplicateItem,
   onUpdate,
   onRemove,
}) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);
   const appStateContext = React.useContext<AppStateContext>(AppStateContext);

   const [showCanDoSearch, setShowCanDoSearch] = React.useState<boolean>(false);
   const [canDoStatementFeatureEnabled, setCanDoStatementFeatureEnabled] =
      React.useState<boolean>(false);

   React.useEffect(() => {
      UserService.checkFeatureLocalStorage('can_do_statement_editor').then(
         setCanDoStatementFeatureEnabled,
      );
   }, []);

   React.useEffect(() => {
      const hasCanDoStatement = !!prompt.canDoStatementId;
      setShowCanDoSearch(hasCanDoStatement);
   }, [prompt]);

   const initialCanDoStatementOption: Maybe<StringOption> =
      prompt.canDoStatementId && prompt.canDoStatementName
         ? {
              label: prompt.canDoStatementName,
              value: prompt.canDoStatementId?.toString(),
           }
         : null;

   const [selectedCanDoStatementOption, setSelectedCanDoStatementOption] = React.useState<
      Maybe<StringOption>
   >(initialCanDoStatementOption);

   const rtl = language === 'ar';

   const onCanDoStatementSelected = (option: Maybe<StringOption>): void => {
      const selectedCanDoStatementId = option ? parseInt(option.value) : null;
      setSelectedCanDoStatementOption(option);
      onUpdate({ canDoStatementId: selectedCanDoStatementId });
   };

   const renderPopupContent = (): Maybe<React.ReactNode> => {
      if (isTextPrompt(prompt)) {
         return <TextPromptOptions onUpdate={onUpdate} prompt={prompt} />;
      } else if (isMultipleChoicePrompt(prompt)) {
         return <MultipleChoicePromptOptions onUpdate={onUpdate} prompt={prompt} />;
      } else if (isFillBlanksPrompt(prompt)) {
         return <FillBlanksPromptOptions onUpdate={onUpdate} prompt={prompt} />;
      } else if (isAudioRecordingPrompt(prompt)) {
         return <RecordingPromptOptions onUpdate={onUpdate} prompt={prompt} />;
      } else if (isSpokenResponsePrompt(prompt)) {
         return <SpokenResponsePromptOptions onUpdate={onUpdate} prompt={prompt} />;
      } else {
         return null;
      }
   };

   const renderPromptRow = (): Maybe<React.ReactNode> => {
      if (isTextPrompt(prompt)) {
         return <TextPrompt length={prompt.length} showWordCount={prompt.showWordCount} />;
      } else if (isMultipleChoicePrompt(prompt)) {
         return (
            <MultipleChoicePrompt
               groupName={prompt.key}
               multiple={prompt.multiple}
               options={prompt.options}
               showFeedback={prompt.showFeedback}
               onUpdate={onUpdate}
            />
         );
      } else if (isGroupingPrompt(prompt)) {
         return (
            <GroupingPrompt
               categories={prompt.categories}
               items={prompt.items}
               language={language}
               onUpdate={onUpdate}
            />
         );
      } else if (isOrderingPrompt(prompt)) {
         return <OrderingPrompt options={prompt.options} language={language} onUpdate={onUpdate} />;
      } else if (isFillBlanksPrompt(prompt)) {
         return <FillBlanksPrompt rtl={rtl} content={prompt.content} onUpdate={onUpdate} />;
      } else if (isMarkTokensPrompt(prompt)) {
         return <MarkTokensPrompt rtl={rtl} content={prompt.content} onUpdate={onUpdate} />;
      } else if (isImageLabelingPrompt(prompt)) {
         return (
            <ImageLabelingPrompt
               fileUrl={prompt.fileUrl}
               hotspots={prompt.hotspots}
               onUpdate={onUpdate}
            />
         );
      } else if (isAudioRecordingPrompt(prompt)) {
         return <RecordingPrompt />;
      } else if (isVideoRecordingPrompt(prompt)) {
         return <VideoPrompt />;
      } else if (isSpokenResponsePrompt(prompt)) {
         return (
            <SpokenResponsePrompt
               acceptedResponses={prompt.acceptedResponses}
               language={language}
               onUpdate={onUpdate}
            />
         );
      } else if (isDiscussionBoardPrompt(prompt)) {
         return <DiscussionBoardPrompt />;
      } else if (isFilePrompt(prompt)) {
         return <DropboxPrompt />;
      }
      return null;
   };

   const handleDescriptionUpdate = (description: string): void => {
      onUpdate({ description });
   };

   const renderLabelContent = (x: ProficiencyCanDoStatementDetailed): string =>
      `${x.name} (${x.level}, ${x.mode}, ${x.skill})`;

   return (
      <div
         className='activity-builder-row'
         data-test={`activity-builder-row-${prompt.itemType}-${prompt.key}`}
         data-testid={`${prompt.key}`}
      >
         <div className='align-items-center'>
            <div className='flex-grow-1' data-test={`prompt-header-${prompt.key}`}>
               <Editor
                  className={classnames(descriptionClassName, 'small-p-margins')}
                  language={language}
                  onChange={handleDescriptionUpdate}
                  toolbarAppearance={ToolbarAppearance.sleek}
                  value={prompt.description}
               />
            </div>
            <div className='flex-end margin-left-s' data-test='prompt-weight'>
               <div className='weight-actions-wrapper'>
                  <WeightInput
                     weight={prompt.weight}
                     onUpdate={onUpdate}
                     dataTest={`weight-${prompt.key}`}
                  />
                  <div className='icon-action-wrap'>
                     <ItemOptions
                        title='Prompt Options'
                        tooltip='Prompt Options'
                        dataTest={`item-options-${prompt.key}`}
                     >
                        {appStateContext.userProfile?.isAdmin && (
                           <div className='margin-top-xs'>
                              <label className='field-title'>Item Id</label>
                              <input
                                 className='disabled'
                                 disabled
                                 readOnly
                                 type='text'
                                 value={prompt.id?.toString()}
                              />
                           </div>
                        )}
                        {renderPopupContent()}
                     </ItemOptions>
                     {duplicateItem && (
                        <Tippy content='Duplicate Item' delay={[500, 0]}>
                           <div
                              aria-label='Duplicate Item'
                              className='icon-action'
                              onClick={duplicateItem}
                              onKeyDown={(e) => e.key === 'Enter' && duplicateItem()}
                              role='button'
                              tabIndex={0}
                           >
                              <IconFileCopy />
                           </div>
                        </Tippy>
                     )}
                     {onRemove && (
                        <Tippy content='Delete Item' delay={[500, 0]}>
                           <div
                              aria-label='Delete Item'
                              className='icon-action'
                              onClick={onRemove}
                              onKeyDown={(e) => e.key === 'Enter' && onRemove()}
                              role='button'
                              tabIndex={0}
                           >
                              <IconBin />
                           </div>
                        </Tippy>
                     )}
                  </div>
               </div>
            </div>
         </div>
         {renderPromptRow()}
         {canDoStatementFeatureEnabled && (
            <div className='margin-top-s'>
               {!showCanDoSearch ? (
                  <Button
                     line
                     icon={<IconDownwardLineTriangle aria-hidden />}
                     onClick={() => setShowCanDoSearch(!showCanDoSearch)}
                     data-test={`attach-can-do-btn-${prompt.key}`}
                  >
                     Attach Can-Do
                  </Button>
               ) : (
                  <SelectTypeAhead
                     setSelectedOption={onCanDoStatementSelected}
                     selectedOption={selectedCanDoStatementOption}
                     placeHolder='Start typing a can-do statement'
                     labelContent={renderLabelContent}
                     rowLoader={ProficiencyCanDoStatementService.search}
                     data-test='can-do-typeahead'
                  />
               )}
            </div>
         )}
      </div>
   );
};

export default Prompt;
