import { Maybe } from '@models/Core';
import Language from '@models/Language';

export enum ContentType {
   activity = 'activity',
   lesson = 'lesson',
   vocabSet = 'vocab_set',
   video = 'video',
}

export type ContentExamType = 'official_exam' | 'questionnaire' | 'practice';

interface Content {
   // null before saved to database
   id: number | null;
   name: string;
   description: string;
   type: ContentType;
   language: Language;
   tags: readonly string[];
   file?: File;
   imageFilename?: Maybe<string>;
   imageUrl?: Maybe<string>;
   createdBy: Maybe<number>;
   createdOn: Maybe<Date>;
   modifiedOn: Maybe<Date>;
   folderId: number | null;
   styleSheetId?: number | null;
}

export default Content;
