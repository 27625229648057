import * as React from 'react';

import { Maybe } from '@models/Core';

/**
 * `useOutsideAlerter` is a custom hook that triggers a callback when a click event occurs outside of a specified component.
 *
 * @param {Function} clickedOutsideCallback - The callback function to be invoked when a click event occurs outside the component.
 * @param {React.MutableRefObject<HTMLDivElement>} ref - Reference to the component's DOM element.
 * @param {boolean} isComponentVisible - Optional. Determines if the component is visible and active. Default is true.
 *
 * @see {@link https://reactjs.org/docs/hooks-reference.html#useref Official useRef API}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event/target Official Event.target documentation}
 *
 * @example
 * const componentRef = React.useRef(null);
 * const handleClickOutside = (event) => {
 *    // Code to be executed when a click event occurs outside the component
 * };
 * useOutsideAlerter(handleClickOutside, componentRef, true);
 *
 * The hook sets up an event listener on the document for the 'mousedown' event. When a mousedown event occurs, it checks
 * if the event target is outside the specified component by comparing the target with the component's DOM element. If the
 * target is outside, the provided callback function `clickedOutsideCallback` is invoked. The event listener is bound and
 * unbound based on the `isComponentVisible` flag. When the component is unmounted, the event listener is automatically
 * unbound to avoid memory leaks.
 */
const useOutsideAlerter = (
   clickedOutsideCallback: (event: MouseEvent) => void,
   ref: React.MutableRefObject<Maybe<HTMLElement>>,
   isComponentVisible = true,
): void => {
   React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: MouseEvent): void => {
         if (
            ref &&
            ref.current &&
            event.target instanceof HTMLDivElement &&
            !ref.current.contains(event.target)
         ) {
            clickedOutsideCallback(event);
         }
      };

      // Bind the event listener
      if (isComponentVisible) {
         document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
         // Unbind the event listener on clean up
         if (isComponentVisible) {
            document.removeEventListener('mousedown', handleClickOutside);
         }
      };
   }, [ref, isComponentVisible]);
};

export default useOutsideAlerter;
