import { DowntimeBanner, PostLoginRedirect } from '@models/ApplicationState';
import ContentLibraryLayout from '@models/ContentLibraryLayout';
import { BasicCourseProfile } from '@models/Course';
import IUserInfo from '@models/IUserInfo';
import IUserProfile from '@models/IUserProfile';
import { NationalExamAbbreviation, NationalExamReportTaskResponse } from '@models/NationalExam';
import { AxiosResponse } from 'axios';

import { ActivityBuilderState } from '@components/Activity/Builder/ActivityBuilder';
import { OnboardingTask } from '@components/Onboarding';

export const actionTypes = Object.freeze({
   APPEND_COURSE: 'APPEND_COURSE',
   APPEND_FEATURE: 'APPEND_FEATURE',
   APPEND_GOOGLE_SCOPES: 'APPEND_GOOGLE_SCOPES',
   CLEAR_POST_LOGIN_REDIRECT: 'CLEAR_POST_LOGIN_REDIRECT',
   COMPLETE_ONBOARDING_TASK: 'COMPLETE_ONBOARDING_TASK',
   CONFIRM_USER: 'CONFIRM_USER',
   HIDE_DOWNTIME_BANNER: 'HIDE_DOWNTIME_BANNER',
   HIDE_HELP_FAB: 'HIDE_HELP_FAB',
   IMPERSONATE_USER: 'IMPERSONATE_USER',
   LOGIN_USER: 'LOGIN_USER',
   LOGOUT_USER: 'LOGOUT_USER',
   REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
   SET_ACTIVITY_RECOVERY: 'SET_ACTIVITY_RECOVERY',
   SET_CONTENT_LIBRARY_LAYOUT: 'SET_CONTENT_LIBRARY_LAYOUT',
   SET_COURSES: 'SET_COURSES',
   SET_AVAILABLE_NATIONAL_EXAMS: 'SET_AVAILABLE_NATIONAL_EXAMS',
   SET_REPORT_TASK_RESPONSE: 'SET_REPORT_TASK_RESPONSE',
   SET_NETWORK_ERROR: 'SET_NETWORK_ERROR',
   SET_POST_LOGIN_REDIRECT: 'SET_POST_LOGIN_REDIRECT',
   SET_PAYMENT_MODAL_VISIBILITY: 'SET_PAYMENT_MODAL_VISIBILITY',
   UPDATE_ONBOARDING_PROGRESS: 'UPDATE_ONBOARDING_PROGRESS',
   UPDATE_PAID_COURSE_TRIAL: 'UPDATE_PAID_COURSE_TRIAL',
   UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
});

export const actionFunctions = Object.freeze({
   appendCourse: (course: BasicCourseProfile) => ({
      type: actionTypes.APPEND_COURSE,
      course,
   }),
   appendFeature: (feature: string) => ({
      type: actionTypes.APPEND_FEATURE,
      feature,
   }),
   appendGoogleScopes: (scopes: readonly string[]) => ({
      type: actionTypes.APPEND_GOOGLE_SCOPES,
      scopes,
   }),
   clearPostLoginRedirect: () => ({
      type: actionTypes.CLEAR_POST_LOGIN_REDIRECT,
   }),
   completeOnboardingTask: (onboardingTask: OnboardingTask) => ({
      type: actionTypes.COMPLETE_ONBOARDING_TASK,
      onboardingTask,
   }),
   confirmUser: () => ({ type: actionTypes.CONFIRM_USER }),
   setDowntimeBanner: (payload: DowntimeBanner) => ({
      type: actionTypes.HIDE_DOWNTIME_BANNER,
      payload,
   }),
   hideHelpFab: (hidden?: boolean) => ({ type: actionTypes.HIDE_HELP_FAB, payload: hidden }),
   impersonateUser: (
      userInfo: IUserInfo,
      currentCourses: readonly BasicCourseProfile[],
      archivedCourses: readonly BasicCourseProfile[],
   ) => ({
      type: actionTypes.IMPERSONATE_USER,
      userInfo,
      currentCourses,
      archivedCourses,
   }),
   loginUser: (userInfo: IUserInfo) => ({
      type: actionTypes.LOGIN_USER,
      userInfo,
   }),
   logoutUser: () => ({ type: actionTypes.LOGOUT_USER }),
   refreshAccessToken: (accessToken: string) => ({
      type: actionTypes.REFRESH_ACCESS_TOKEN,
      accessToken,
   }),
   setActivityRecovery: (state: ActivityBuilderState, existing: boolean) => ({
      type: actionTypes.SET_ACTIVITY_RECOVERY,
      state,
      existing,
   }),
   setCourses: (
      currentCourses: readonly BasicCourseProfile[],
      archivedCourses: readonly BasicCourseProfile[],
   ) => ({ type: actionTypes.SET_COURSES, currentCourses, archivedCourses }),
   setAvailableNationalExams: (exams: readonly NationalExamAbbreviation[]) => ({
      type: actionTypes.SET_AVAILABLE_NATIONAL_EXAMS,
      exams,
   }),
   setContentLibraryLayout: (contentLibraryLayout: ContentLibraryLayout) => ({
      type: actionTypes.SET_CONTENT_LIBRARY_LAYOUT,
      contentLibraryLayout,
   }),
   setNetworkError: (networkError: AxiosResponse | null) => ({
      type: actionTypes.SET_NETWORK_ERROR,
      networkError,
   }),
   setPostLoginRedirect: (postLoginRedirect: PostLoginRedirect) => ({
      type: actionTypes.SET_POST_LOGIN_REDIRECT,
      postLoginRedirect,
   }),
   updateUserProfile: (userProfile: IUserProfile) => ({
      type: actionTypes.UPDATE_USER_PROFILE,
      userProfile,
   }),
   setReportTaskResponse: (reportTaskResponse: NationalExamReportTaskResponse) => ({
      type: actionTypes.SET_REPORT_TASK_RESPONSE,
      reportTaskResponse,
   }),
});
