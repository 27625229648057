import * as _ from 'lodash';
import * as React from 'react';

import IconArrowDown from '@icons/general/icon-arrow-down.svg';
import IconArrowUp from '@icons/general/icon-arrow-up.svg';
import classnames from 'classnames';

export interface Column<T> {
   id: string;
   cellClassName?: string;
   headerClassName?: string;
   header: (() => React.ReactNode) | string | number;
   cell(
      row: T,
      onUpdateRow: (updatedRow: T) => void,
      onDeleteRow: () => void,
   ): React.ReactNode | string | number;
   // String representation of a cell
   str?(row: T): string;
   canSort?: boolean;
   centerHeader?: boolean;
   centerCell?: boolean;
   show?: boolean;
   sortFunc?(row: T): string | number | Date;
   sortValue?: string;
}

export interface SortByEntry {
   id: string;
   desc: boolean;
}

interface TableProps<T> {
   className?: string;
   columns: readonly Column<T>[];
   rows: readonly T[];
   defaultSortBy?: readonly SortByEntry[];
   hasMore?: boolean;
   rowClassName?: string;
   rowKey: keyof T;
   infiniteScroll?: boolean;
   dataTest?: string;
   onLoadMore?(): Promise<void>;
   onRowDelete?(row: T): void;
   onRowUpdate?(row: T): void;
   onSortChanged?(sortBy: readonly SortByEntry[]): void;
}

const Table = <T extends object>({
   className,
   columns,
   defaultSortBy = [],
   hasMore = false,
   infiniteScroll,
   rowClassName,
   rowKey,
   rows,
   dataTest,
   onLoadMore,
   onRowDelete,
   onRowUpdate,
   onSortChanged,
}: TableProps<T>): JSX.Element => {
   const [sortBy, setSortBy] = React.useState<readonly SortByEntry[]>(defaultSortBy);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const visibleColumns = columns.filter((i) => i.show !== false);

   React.useEffect(() => {
      onSortChanged?.(sortBy);
   }, [sortBy]);

   const sortFunc = (entry: SortByEntry): ((row: T) => string | number | Date) | string => {
      const column = columns.find((i) => i.id === entry.id);
      if (column === undefined) {
         console.error(`Column for entry ${entry.id} not found`);
      }
      if (column?.sortFunc && _.isFunction(column?.sortFunc)) {
         return column.sortFunc;
      } else {
         return entry.id;
      }
   };

   const renderSortArrow = (columnId: string): React.ReactNode => {
      const column = sortBy.find((i) => i.id === columnId);
      if (column === undefined) {
         return null;
      } else if (column.desc) {
         return <IconArrowDown />;
      } else {
         return <IconArrowUp />;
      }
   };

   const toggleSortBy = (event: React.MouseEvent<HTMLDivElement>, columnId: string): void => {
      event.persist();
      const column = sortBy.find((i) => i.id === columnId);
      const isMulti = event.shiftKey;
      if (column !== undefined) {
         setSortBy((prevSortBy) => {
            const updatedSortBy = [...prevSortBy];
            const index = updatedSortBy.findIndex((i) => i.id === columnId);
            if (updatedSortBy[index].desc) {
               updatedSortBy.splice(index, 1);
            } else {
               updatedSortBy[index].desc = true;
            }
            return updatedSortBy;
         });
      } else {
         if (isMulti) {
            setSortBy((prevSortBy) => [...prevSortBy, { id: columnId, desc: false }]);
         } else {
            setSortBy([{ id: columnId, desc: false }]);
         }
      }
   };

   const headerRow = (
      <tr className='head-row'>
         {visibleColumns.map((i) => {
            const header = _.isFunction(i.header) ? i.header() : i.header;
            const handleClick = (e: React.MouseEvent<HTMLTableCellElement>): void =>
               toggleSortBy(e, i.id);
            return (
               <th
                  className={classnames(i.headerClassName)}
                  style={{ textAlign: i.centerHeader ? 'center' : 'left' }}
                  onClick={i.canSort ? handleClick : undefined}
                  key={i.id}
               >
                  {i.canSort ? (
                     <div className='sortable-column'>
                        {header}
                        {renderSortArrow(i.id)}
                     </div>
                  ) : (
                     header
                  )}
               </th>
            );
         })}
      </tr>
   );

   const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      const threshold = 30;
      // Assert event.target as HTMLDivElement to access specific properties
      const target = event.target as HTMLDivElement;
      const { scrollTop, clientHeight, scrollHeight } = target;

      if (scrollTop + clientHeight + threshold >= scrollHeight) {
         if (!isLoading && hasMore) {
            setIsLoading(true);
            onLoadMore?.().then(() => setIsLoading(false));
         }
      }
   };

   const sortedRows = React.useMemo(
      () =>
         _.orderBy(
            rows,
            sortBy.map(sortFunc),
            sortBy.map((i) => (i.desc ? 'desc' : 'asc')),
         ),
      [rows, sortBy],
   );

   return (
      <div
         className={classnames('rwd-table-wrapper', className, {
            sticky: infiniteScroll,
         })}
         onScroll={handleScroll}
      >
         <table
            className={classnames('rwd-table', className)}
            data-test={dataTest ? `table-${dataTest}` : undefined}
         >
            <thead>{headerRow}</thead>
            <tbody>
               {sortedRows.map((row) => (
                  <tr key={`row-${row[rowKey]}`} className={rowClassName}>
                     {visibleColumns.map((col) => (
                        <td
                           key={`${col.id}-${row[rowKey]}`}
                           className={classnames(col.cellClassName)}
                           style={{ textAlign: col.centerCell ? 'center' : 'left' }}
                           data-th={_.isString(col.header) ? col.header : ''}
                        >
                           {col.cell(
                              row,
                              (updatedRow) => onRowUpdate?.(updatedRow),
                              () => onRowDelete?.(row),
                           )}
                        </td>
                     ))}
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};

export default Table;
