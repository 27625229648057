import history from '@helpers/History';
import { Maybe } from '@models/Core';
import axios, { AxiosResponse } from 'axios';

import Constants from '../Constants';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';

class AuthHandler {
   refreshTokenPromise: Maybe<Promise<string>>;

   constructor() {
      this.refreshTokenPromise = null;
   }

   private postWithRefreshToken<T>(
      url: string,
      requestBody?: Record<string, unknown>,
   ): Promise<AxiosResponse<T>> {
      const { refreshToken } = store.getState().user;

      return axios.post<T>(url, requestBody, {
         headers: { Authorization: `Bearer ${refreshToken}` },
      });
   }

   getAccessToken(): string {
      const { accessToken } = store.getState().user;

      if (!accessToken) {
         this.logoutRedirect();
      }

      return accessToken;
   }

   refreshAccessToken(): Promise<string> {
      if (this.refreshTokenPromise) {
         return this.refreshTokenPromise;
      }

      this.refreshTokenPromise = this.postWithRefreshToken<{
         access_token: string;
      }>('/api/users/refresh')
         .then((response) => {
            const newToken = response.data.access_token;
            store.dispatch(actionFunctions.refreshAccessToken(newToken));
            return Promise.resolve(newToken);
         })
         .catch((error) => {
            console.warn(`Failed to refresh token: ${error}`);
            this.logoutRedirect();
            return Promise.resolve('');
         })
         .finally(() => {
            this.refreshTokenPromise = null;
         });

      return this.refreshTokenPromise;
   }

   async logout(): Promise<void> {
      const { refreshToken } = store.getState().user;
      if (refreshToken) {
         await this.postWithRefreshToken('/api/auth/logout').catch((error) => {
            console.error(`AuthService Logout Error: ${error}`);
         });
      }
      store.dispatch(actionFunctions.logoutUser());
   }

   logoutRedirect(): void {
      const authRoutes = [Constants.routes.auth.logout, Constants.routes.auth.login];
      if (!authRoutes.includes(history.location.pathname)) {
         history.push(Constants.routes.auth.logout);
      }
   }
}

const AuthService = new AuthHandler();
export default AuthService;
