import * as _ from 'lodash';

import { Maybe } from '@models/Core';

import { ErrorAudioElementEvent } from '../types/DOMErrors';

const isErrorAudioElementEvent = (i: ErrorEvent): i is ErrorAudioElementEvent =>
   _.isObject(i) && Object.prototype.hasOwnProperty.call(i, 'path');

const createAudioElement = (url: string, origin: string): Maybe<HTMLAudioElement> => {
   if (!url) {
      return null;
   }

   /*
      TODO: We really want to take advantage of the browser cache, but for now
      we're going to skip it since it may be causing some issues with loading audio files
   */
   const urlBuilder = new URL(url);
   urlBuilder.searchParams.set('cacheBuster', new Date().getTime().toString());

   const audioElement = new Audio();
   audioElement.addEventListener('error', (err: ErrorEvent) => {
      if (!isErrorAudioElementEvent(err)) {
         return;
      }
      const errorDetails = err.path?.length
         ? err.path[0]
         : {
              currentSrc: url,
              error: { code: -1, message: 'Unknown error type' },
           };
      const { code, message } = errorDetails.error;
      console.error('Audio element error occurred:', {
         url: errorDetails.currentSrc,
         errorType: { code, message },
         fullError: err,
         origin,
      });
   });

   // we're setting the src after hooking up the handlers above to make sure we capture
   // all the events, before the audio is auto-loaded
   audioElement.crossOrigin = 'anonymous';
   audioElement.src = urlBuilder.href;

   return audioElement;
};

export { createAudioElement };
