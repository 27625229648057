/* eslint-disable camelcase */
import { randomShortId } from '@helpers/RandomStringUtils';
import { Maybe } from '@models/Core';
import tinymce from 'tinymce';

/**
 *
 * TODO:
 * [ ] If you press the right arrow and the blank is the last item in the container, insert a blank space and go to it.
 * The user needs to be able to exit the blank
 * [ ] Pressing enter in a blank should insert a paragraph but not with the node.
 * [ ] Clicking mark blank in an activate blank should un-blank it.
 * [ ] Pasting a blank should assign it a new blank id.
 * [ ] Blanks should only be able to have plain text.
 */

export enum FillBlanksDataAttr {
   blank = 'data-blank',
   grading = 'data-grading',
   hint = 'data-hint',
   id = 'data-id',
   options = 'data-options',
}

tinymce.PluginManager.add('fillblanks', (editor) => {
   let lastFocusedNode: Maybe<HTMLElement> = null;
   const existingBlankIds: string[] = []; // List of existing blankIds

   const getBlank = (el: HTMLElement): Element | null => el.closest('span[data-blank');

   const toggleBlankSection = (): void => {
      if (editor.selection.isCollapsed()) {
         if (lastFocusedNode) {
            const lastBlank = lastFocusedNode.closest('span[data-blank]');
            if (lastBlank) {
               editor.formatter.remove('blank', {}, lastBlank);
            }
         }
      } else {
         editor.formatter.toggle('blank', { value: randomShortId() });
      }
   };

   editor.on('init', () => {
      // Register blank and hint as formatter option
      editor.formatter.register('blank', {
         inline: 'span',
         clear_child_styles: true,
         block_expand: false,
         split: false,
         attributes: {
            'data-blank': 'true',
            'data-id': '%value',
         },
      });

      editor.formatter.register('hint', {
         inline: 'span',
         attributes: { 'data-hint': 'true' },
      });

      // Append text pattern shortcut to editor options
      const patterns = editor.options.get('text_patterns');
      patterns.push({
         start: '_',
         end: '_',
         cmd: 'toggleBlank',
         type: 'inline-command',
      });

      patterns.push({
         type: 'inline-format',
         start: '~',
         end: '~',
         format: ['hint'],
      });
      editor.options.set('text_patterns', patterns);

      // set filter to remove extra attributes
   });

   editor.editorCommands.addCommands({
      toggleBlank: toggleBlankSection,
   });

   editor.on('PostProcess', () => {
      // Find all spans with the attribute data-blank
      const spans = editor.dom.select('span[data-blank]');
      spans.forEach((span: HTMLSpanElement) => {
         // Check if the span has a data-id attribute
         /** FIXME: Refactor to data-id="xxx" and remove data-blank */
         let blankId: Maybe<string> = span.getAttribute('data-id');
         if (!blankId) {
            // Generate a unique blankId using a do...while loop
            do {
               blankId = randomShortId().toString();
            } while (existingBlankIds.includes(blankId));

            // Add the generated blankId to the list of existing IDs
            existingBlankIds.push(blankId);

            span.setAttribute('data-id', blankId);
         }

         // Remove any other attributes that are not data attributes
         Array.from(span.attributes).forEach((attr: Attr) => {
            if (!attr.name.startsWith('data-')) {
               span.removeAttribute(attr.name);
            }
         });

         // Ensure that the span has only text content
         Array.from(span.childNodes).forEach((child: Node) => {
            if (child.nodeType === Node.ELEMENT_NODE) {
               // If the child is an element node, replace it with raw text
               const text = child.textContent;
               if (text !== null) {
                  span.replaceChild(document.createTextNode(text), child);
               }
            }
         });
      });
   });

   editor.on('dblclick', (e) => {
      const blank = getBlank(e.target);
      if (blank) {
         editor.dispatch('BlankActive', { node: blank });
      } else {
         editor.dispatch('BlankInactive');
      }
   });

   editor.on('focus', () => {
      // Store the currently focused node
      lastFocusedNode = editor.selection.getNode();
   });

   return {
      getMetadata: () => ({
         name: 'Fill Blanks',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
