import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { randomShortId } from '@helpers/RandomStringUtils';
import { Editor as TinyMCEReact } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { LanguageLookup } from '@models/Language';
import { BASE_CONFIG } from './Config';
import { LESSON_CALLOUTS } from './data/Callouts';
import { BaseEditorProps } from './types/Editor';

type PageEditorProps = Omit<BaseEditorProps & { initialValue?: string }, 'value'>;

const PageEditor: React.FC<PageEditorProps> = ({
   autoFocus = false,
   initialValue,
   language,
   editorRef,
   onChange,
}) => {
   const [id, _setId] = React.useState(`rte-${randomShortId(6)}`);
   const editor = React.useRef<TinyMCEEditor | null>(null);

   React.useEffect(() => {
      editor.current?.fire('contentLangChange', { language });
   }, [language]);

   const setupEditor = (newEditor: TinyMCEEditor): void => {
      newEditor.addShortcut('meta+shift+s', 'Source code editor', () => {
         newEditor.execCommand('mceCodeEditor');
      });
      newEditor.addShortcut('meta+shift+v', 'Toggle visual blocks', () => {
         newEditor.execCommand('mceVisualBlocks');
      });
      newEditor.addShortcut('meta+shift+c', 'Inline CSS', () => {
         newEditor.execCommand('mceExport');
      });
      newEditor.on('init', () => {
         newEditor.options.set('content_lang', language);
         newEditor.fire('contentLangChange', { language });
      });
      editor.current = newEditor;
      editor.current?.fire('contentLangChange', { language });
      editorRef?.(newEditor);
   };

   const config = snakeCaseKeys({
      ...BASE_CONFIG,
      contentLangs: [
         { title: 'English', code: 'en' },
         { title: LanguageLookup[language], code: language },
      ],
      autoFocus: autoFocus ? id : '',
      calloutList: LESSON_CALLOUTS,
      contextmenu:
         'table image link custom-media callouts examples conversation custom-delete transform generateaudio casechange translate',
      formats: { tooltip: { inline: 'span', attributes: { 'data-tooltip': '%value' } } },
      plugins: [
         'advlist',
         'code',
         'emoticons',
         'image',
         'link',
         'lists',
         'media',
         'quickbars',
         'table',
         'visualblocks',
         'customicons',
         'callouts',
         'conversations',
         'examples',
         'help',
         'inlineaudio',
         'quickinsert',
         'insertimage',
         'tooltip',
         'transform',
         'webbookmark',
         'casechange',
         'translate',
         'inlinecss',
         'customtable',
         'contentlink',
         'generateaudio',
         'fiximages',
         'fixmenus',
         'bannerimages',
         'fixcontextmenus',
         'fixlang',
      ],
      quickbarsInsertToolbar: false,
      quickbarsSelectionToolbar:
         'bold italic underline strikethrough | forecolor backcolor | language | quicklink tooltip inlineaudio',
      quickinsertOptions:
         'p h1 h2 h3 table ul ol hr blockquote link quick-start-callout examples conjugation-table notice-callout info-callout mistake-callout conversation learn-more-callout img video block-audio file emoji inline-audio reveal',
      setup: setupEditor,
      toolbar: false,
   });

   return (
      <TinyMCEReact id={id} onEditorChange={onChange} initialValue={initialValue} init={config} />
   );
};

export default PageEditor;
