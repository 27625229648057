import * as _ from 'lodash';
import * as React from 'react';

import IconWindowSearch from '@icons/nova-solid/50-Applications/window-search.svg';
import {
   ActivityCollapsedRubricEntry,
   ActivityRubricItem,
   ActivityRubricItemGroup,
   ActivityRubricScoringType,
} from '@models/Activity';

import EmptyState from '@components/Core/EmptyState';
import RubricEntries from '@components/Activity/Grader/RubricEntries';

interface RubricPreviewProps {
   scoringType: ActivityRubricScoringType;
   rubricItems: readonly ActivityRubricItem[];
   rubricItemGroups: readonly ActivityRubricItemGroup[];
}

const RubricPreview: React.FC<RubricPreviewProps> = ({
   rubricItems,
   rubricItemGroups,
   scoringType,
}) => {
   const getRubricEntries = (): readonly ActivityCollapsedRubricEntry[] => {
      // Converts to ActivityCollapsedRubricEntry by adding rubricItems to ActivityRubricItemGroup
      const groupedItems = _.groupBy(rubricItems, 'groupId');
      const result = _.orderBy(
         [
            ...(groupedItems.null || []),
            ...rubricItemGroups.map((i) => ({
               ...i,
               rubricItems: _.orderBy(groupedItems[i.id] || [], ['index'], ['asc']),
            })),
         ],
         ['index'],
         ['asc'],
      );
      return result;
   };

   if (!rubricItems.length) {
      return (
         <EmptyState
            icon={<IconWindowSearch aria-hidden />}
            heading='No Items'
            description='Rubric has no items.'
         />
      );
   }

   return (
      <div className='rubric-preview'>
         <RubricEntries displayOnly rubricEntries={getRubricEntries()} scoringType={scoringType} />
      </div>
   );
};

export default RubricPreview;
