import * as React from 'react';

import { ActivityMode, ActivityPrompt } from '@models/Activity';
import { isSkill } from '@models/Proficiency';
import Tippy from '@tippyjs/react';

import { ProficiencyLevelSkillGraderDescriptions } from '../../../Constants';

interface SidebarProficiencyPillsProps {
   prompt: ActivityPrompt<ActivityMode.grade>;
}

const SidebarProficiencyPills: React.FC<SidebarProficiencyPillsProps> = ({ prompt }) => {
   const getProficiencyLevelAndSkillDescription = (): string[] => {
      if (!prompt.canDoStatementLevel || !prompt.canDoStatementSkill) {
         return [];
      }
      const levelObj = ProficiencyLevelSkillGraderDescriptions[prompt.canDoStatementLevel];
      if (!levelObj) {
         return [];
      }

      return isSkill(prompt.canDoStatementSkill) ? levelObj[prompt.canDoStatementSkill] || [] : [];
   };

   const renderTooltip = (): React.ReactNode => (
      <div className='small-text'>
         <ul>
            {getProficiencyLevelAndSkillDescription().map((str) => (
               <li key={str}>{str}</li>
            ))}
         </ul>
      </div>
   );

   return (
      <Tippy content={renderTooltip()} className='proficiency-tooltip' placement='bottom'>
         <div className='flex proficiency-pill-container'>
            <div className='proficiency-pill left'>
               <span className='proficiency-pill-label'>{prompt.canDoStatementSkill}</span>
            </div>
            <div className='proficiency-pill right'>
               <span className='proficiency-pill-label'>{prompt.canDoStatementLevel}</span>
            </div>
         </div>
      </Tippy>
   );
};

export default SidebarProficiencyPills;
