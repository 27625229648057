import * as _ from 'lodash';
import * as React from 'react';

import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';
import {
   isGoToNextQuestionAction,
   isGoToQuestionAction,
   isSetVariableAction,
} from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import Droplist from '@components/Core/Droplist';
import { randomTempId } from '@helpers/RandomStringUtils';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconCursorChoose from '@icons/nova-line/02-Status/cursor-choose.svg';
import IconContentLayersShow from '@icons/nova-line/18-Content/content-layers-show.svg';
import IconAddCircle1 from '@icons/nova-line/27-Remove&Add/add-circle-1.svg';
import {
   ActivityLogicAction,
   ActivityLogicActionType,
   ActivityLogicCombinator,
   ActivityLogicField,
   ActivityLogicGroup,
   ActivityLogicRule,
} from '@models/Activity';
import Tippy from '@tippyjs/react';

import LogicAction from './LogicAction';
import LogicRule from './LogicRule';
import { isRuleGroup } from './LogicUtils';

interface LogicGroupProps {
   group: ActivityLogicGroup;
   combinators: readonly {
      value: ActivityLogicCombinator;
      label: string;
   }[];
   currentQuestionId: string | number;
   fields: readonly ActivityLogicField[];
   createRule(): Omit<ActivityLogicRule, 'parentId'>;
   createRuleGroup(): Omit<ActivityLogicGroup, 'parentId'>;
   onRuleAdd(newRule: Omit<ActivityLogicRule, 'parentId'>, parentId: number | string): void;
   onGroupAdd(newGroup: Omit<ActivityLogicGroup, 'parentId'>, parentId: number | string): void;
   onRuleRemove(ruleId: number | string, parentId: number | string): void;
   onGroupRemove(id: number | string, parentId: number | string): void;
   onPropChange(
      ruleId: number | string,
      update: Partial<ActivityLogicRule | ActivityLogicGroup>,
   ): void;
}

const LogicGroup: React.FC<LogicGroupProps> = ({
   combinators,
   currentQuestionId,
   fields,
   group: { actions, combinator, id, parentId, rules },
   createRule,
   createRuleGroup,
   onGroupAdd,
   onGroupRemove,
   onPropChange,
   onRuleAdd,
   onRuleRemove,
}) => {
   const canDelete = parentId !== null;

   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const onCombinatorChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      onPropChange(id, {
         combinator: event.target.value as ActivityLogicCombinator,
      });
   };

   const addRule = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();

      const newRule = createRule();
      onRuleAdd(newRule, id);
   };

   const addGroup = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();

      const newGroup = createRuleGroup();
      onGroupAdd(newGroup, id);
   };

   const removeGroup = (event: React.MouseEvent<HTMLDivElement>): void => {
      event.preventDefault();
      event.stopPropagation();
      if (parentId) {
         onGroupRemove(id, parentId);
      }
   };

   const updateAction = (
      action: ActivityLogicAction,
      update: Partial<ActivityLogicAction>,
   ): ActivityLogicAction => {
      const combined = { ...action, ...update } as ActivityLogicAction;
      if (update.actionType !== undefined && action.actionType !== update.actionType) {
         if (isGoToNextQuestionAction(combined)) {
            return {
               id: combined.id,
               groupId: combined.groupId,
               actionType: ActivityLogicActionType.goToNextQuestion,
               index: combined.index,
               executeIfTrue: combined.executeIfTrue,
            };
         } else if (isGoToQuestionAction(combined)) {
            return {
               id: combined.id,
               groupId: combined.groupId,
               actionType: ActivityLogicActionType.goToQuestion,
               index: combined.index,
               questionId: combined.questionId ? Number(combined.questionId) : null,
               executeIfTrue: combined.executeIfTrue,
            };
         } else if (isSetVariableAction(combined)) {
            return {
               id: combined.id,
               groupId: combined.groupId,
               actionType: ActivityLogicActionType.setVariable,
               index: combined.index,
               variableId: combined.variableId ?? null,
               variableValue: combined.variableValue ?? null,
               executeIfTrue: combined.executeIfTrue,
            };
         }
      }
      return combined;
   };

   const handleActionChange = (
      actionId: string | number,
      update: Partial<ActivityLogicAction>,
   ): void => {
      onPropChange(id, {
         actions: actions.map((i) => (i.id === actionId ? updateAction(i, update) : i)),
      });
   };

   const handleActionRemove = (actionId: string | number): void => {
      const index = actions.findIndex((i) => i.id === actionId);
      onPropChange(id, {
         actions: actions
            .filter((i) => i.id !== actionId)
            .map((i, j) => (j > index ? { ...i, index: i.index - 1 } : i)),
      });
   };

   const handleActionAdd = (executeIfTrue: boolean): void => {
      const maxAction = _.maxBy(actions, 'index');
      const newAction: ActivityLogicAction = {
         id: randomTempId(),
         index: maxAction ? maxAction.index + 1 : 0,
         groupId: id,
         executeIfTrue,
         actionType: null,
      };
      onPropChange(id, { actions: [...actions, newAction] });
   };

   const renderAction = (action: ActivityLogicAction): React.ReactNode => (
      <LogicAction
         action={action}
         key={action.id}
         currentQuestionId={currentQuestionId}
         onActionChange={(update) => handleActionChange(action.id, update)}
         onActionRemove={() => handleActionRemove(action.id)}
      />
   );

   const sharedProps = {
      fields,
      language,
      parentId: id,
      onPropChange,
      onRuleRemove,
   };

   return (
      <div className='logic-rule-group'>
         <div className='logic-rule-group-header'>
            <select value={combinator || ''} onChange={onCombinatorChange}>
               {combinators.map((i) => (
                  <option value={i.value} key={i.value}>
                     {i.label}
                  </option>
               ))}
            </select>
            {canDelete && (
               <div className='icon-action-wrap'>
                  <Tippy content='Delete Group' delay={[500, 0]}>
                     <div className='icon-action' onClick={removeGroup}>
                        <IconBin />
                     </div>
                  </Tippy>
               </div>
            )}
         </div>
         {!parentId && <div className='condition'>If</div>}
         {rules.map((rule) => (
            <React.Fragment key={rule.id}>
               {isRuleGroup(rule) ? (
                  <LogicGroup
                     {...sharedProps}
                     combinators={combinators}
                     createRule={createRule}
                     createRuleGroup={createRuleGroup}
                     currentQuestionId={currentQuestionId}
                     group={rule}
                     onGroupAdd={onGroupAdd}
                     onGroupRemove={onGroupRemove}
                     onRuleAdd={onRuleAdd}
                  />
               ) : (
                  <LogicRule {...sharedProps} rule={rule} />
               )}
            </React.Fragment>
         ))}
         <div className='margin-top-s'>
            <Droplist
               items={[
                  {
                     text: 'Rule',
                     onClick: addRule,
                     icon: <IconCursorChoose />,
                  },
                  {
                     text: 'Rule Group',
                     onClick: addGroup,
                     icon: <IconContentLayersShow />,
                  },
               ]}
            >
               <Button subtle icon={<IconAddCircle1 aria-hidden />}>
                  Add Condition
               </Button>
            </Droplist>
         </div>
         {!parentId && (
            <>
               <div>
                  <div className='condition'>Then:</div>
                  {actions.filter((i) => i.executeIfTrue).map(renderAction)}
                  <div className='margin-top-s'>
                     <Button
                        subtle
                        icon={<IconAddCircle1 aria-hidden />}
                        onClick={() => handleActionAdd(true)}
                     >
                        Add Action
                     </Button>
                  </div>
               </div>
               <div>
                  <div className='condition'>In all other cases:</div>
                  {actions.filter((i) => !i.executeIfTrue).map(renderAction)}
                  <div className='margin-top-s'>
                     <Button
                        subtle
                        icon={<IconAddCircle1 aria-hidden />}
                        onClick={() => handleActionAdd(false)}
                     >
                        Add Action
                     </Button>
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

export default LogicGroup;
