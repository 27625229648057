// @ts-strict-ignore
import * as React from 'react';

import { shiftDate } from '@helpers/DateUtils';
import DateTime from '@services/DateTimeService';
import memoizeOne from 'memoize-one';

const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;
const DAYS_IN_WEEK = 7;

const SQUARE_SIZE = 10;
const MONTH_LABEL_GUTTER_SIZE = 5;
const WEEK_LABEL_GUTTER_SIZE = 7;
const CSS_PSEDUO_NAMESPACE = 'calendar-heatmap-';

interface CalendarHeatmapProps<T> {
   /** property with date of value */
   dateProperty?: string;
   /** array of objects with date and arbitrary metadata */
   values: readonly T[];
   /** start of date range **/
   startDate?: number | string | Date;
   /** end of date range **/
   endDate?: number | string | Date;
   /** size of space between squares */
   gutterSize?: number;
   /** whether to orient horizontally or vertically */
   horizontal?: boolean;
   /** whether to show month labels */
   showMonthLabels?: boolean;
   /** whether to show weekday labels */
   showWeekdayLabels?: boolean;
   /** whether to render squares for extra days in week after endDate, and before start date */
   showOutOfRangeDays?: boolean;
   /** Data attributes to add to square for setting 3rd party tooltips, e.g. { 'data-toggle': 'tooltip' } for bootstrap tooltips */
   tooltipDataAttrs?: ((value: T) => Record<string, string>) | Record<string, string>;
   /** An array with 12 strings representing the text from janurary to december */
   monthLabels?: readonly string[];
   /** An array with 7 strings representing the text from Sun to Sat */
   weekdayLabels?: readonly string[];
   /* function which returns title text for value */
   titleForValue?(value: T | null): string;
   /** function which returns html class for value */
   classForValue?(value: T | null): string;
   /** callback function when a square is clicked */
   onClick?(event: React.MouseEvent<SVGRectElement>, value: T): void;
   /** callback function when mouse pointer is over a square */
   onMouseOver?(event: React.MouseEvent<SVGRectElement>, value: T): void;
   /** callback function when mouse pointer is left a square */
   onMouseLeave?(event: React.MouseEvent<SVGRectElement>, value: T): void;
   /** function to further transform the svg element for a single day */
   transformDayElement?(element: React.ReactNode, value: T, index: number): React.ReactNode;
}

class CalendarHeatmap<T> extends React.Component<CalendarHeatmapProps<T>> {
   static defaultProps: Partial<CalendarHeatmapProps<unknown>> = {
      dateProperty: 'date',
      // Show last year by default
      startDate: shiftDate(DateTime.now(), -365),
      endDate: DateTime.now(),
      gutterSize: 1,
      horizontal: true,
      showMonthLabels: true,
      showWeekdayLabels: false,
      showOutOfRangeDays: false,
      titleForValue: null,
      classForValue: (value) => (value ? 'color-filled' : 'color-empty'),
      monthLabels: [
         'Jan',
         'Feb',
         'Mar',
         'Apr',
         'May',
         'Jun',
         'Jul',
         'Aug',
         'Sep',
         'Oct',
         'Nov',
         'Dec',
      ],
      weekdayLabels: ['', 'Mon', '', 'Wed', '', 'Fri', ''],
      onClick: null,
      onMouseOver: null,
      onMouseLeave: null,
      transformDayElement: null,
   };

   getValueCache = memoizeOne((props) =>
      props.values.reduce((memo, value) => {
         const date = new Date(value[this.props.dateProperty]);
         const index = Math.floor(
            (+date - +this.getStartDateWithEmptyDays()) / MILLISECONDS_IN_ONE_DAY,
         );
         memo[index] = {
            value,
            className: this.props.classForValue?.(value),
            title: this.props.titleForValue ? this.props.titleForValue(value) : null,
            tooltipDataAttrs: this.getTooltipDataAttrsForValue(value),
         };
         return memo;
      }, {}),
   );

   valueCache: Record<
      string | number,
      {
         value?: T | null;
         className?: string;
         tooltipDataAttrs?: Record<string, string>;
         title?: string;
      }
   >;

   getDateDifferenceInDays(): number {
      const { startDate } = this.props;
      const timeDiff = +this.getEndDate() - +new Date(startDate);
      return Math.ceil(timeDiff / MILLISECONDS_IN_ONE_DAY);
   }

   getSquareSizeWithGutter(): number {
      return SQUARE_SIZE + this.props.gutterSize;
   }

   getMonthLabelSize(): number {
      if (!this.props.showMonthLabels) {
         return 0;
      }
      if (this.props.horizontal) {
         return SQUARE_SIZE + MONTH_LABEL_GUTTER_SIZE;
      }
      return 2 * (SQUARE_SIZE + MONTH_LABEL_GUTTER_SIZE);
   }

   getWeekdayLabelSize(): number {
      if (!this.props.showWeekdayLabels) {
         return 0;
      }
      if (this.props.horizontal) {
         return 20 + WEEK_LABEL_GUTTER_SIZE;
      }
      return SQUARE_SIZE * 1.5;
   }

   getStartDate(): Date {
      // +1 because endDate is inclusive
      return shiftDate(this.getEndDate(), -this.getDateDifferenceInDays() + 1);
   }

   getEndDate(): Date {
      const date = new Date(this.props.endDate);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
   }

   getStartDateWithEmptyDays(): Date {
      return shiftDate(this.getStartDate(), -this.getNumEmptyDaysAtStart());
   }

   getNumEmptyDaysAtStart(): number {
      return this.getStartDate().getDay();
   }

   getNumEmptyDaysAtEnd(): number {
      return DAYS_IN_WEEK - 1 - this.getEndDate().getDay();
   }

   getWeekCount(): number {
      const numDaysRoundedToWeek =
         this.getDateDifferenceInDays() +
         this.getNumEmptyDaysAtStart() +
         this.getNumEmptyDaysAtEnd();
      return Math.ceil(numDaysRoundedToWeek / DAYS_IN_WEEK);
   }

   getWeekWidth(): number {
      return DAYS_IN_WEEK * this.getSquareSizeWithGutter();
   }

   getWidth(): number {
      return (
         this.getWeekCount() * this.getSquareSizeWithGutter() -
         (this.props.gutterSize - this.getWeekdayLabelSize())
      );
   }

   getHeight(): number {
      return this.getWeekWidth() + (this.getMonthLabelSize() - this.props.gutterSize);
   }

   getValueForIndex(index: number): T | null {
      if (this.valueCache[index]) {
         return this.valueCache[index].value;
      }
      return null;
   }

   getClassNameForIndex(index: number): string {
      if (this.valueCache[index]) {
         return this.valueCache[index].className;
      }
      return this.props.classForValue(null);
   }

   getTitleForIndex(index: number): string {
      if (this.valueCache[index]) {
         return this.valueCache[index].title;
      }
      return this.props.titleForValue ? this.props.titleForValue(null) : null;
   }

   getTooltipDataAttrsForIndex(index: number): Record<string, string> {
      if (this.valueCache[index]) {
         return this.valueCache[index].tooltipDataAttrs;
      }
      return this.getTooltipDataAttrsForValue({ date: null, count: null });
   }

   getTooltipDataAttrsForValue(value): Record<string, string> {
      const { tooltipDataAttrs } = this.props;
      if (typeof tooltipDataAttrs === 'function') {
         return tooltipDataAttrs(value);
      }
      return tooltipDataAttrs;
   }

   getTransformForWeek(weekIndex): string {
      if (this.props.horizontal) {
         return `translate(${weekIndex * this.getSquareSizeWithGutter()}, 0)`;
      }
      return `translate(0, ${weekIndex * this.getSquareSizeWithGutter()})`;
   }

   getTransformForWeekdayLabels(): string | undefined {
      if (this.props.horizontal) {
         return `translate(0, ${this.getMonthLabelSize()})`;
      }
      return undefined;
   }

   getTransformForMonthLabels(): string {
      if (this.props.horizontal) {
         return `translate(${this.getWeekdayLabelSize()}, 0)`;
      }
      return `translate(${
         this.getWeekWidth() + MONTH_LABEL_GUTTER_SIZE
      }, ${this.getWeekdayLabelSize()})`;
   }

   getTransformForAllWeeks(): string {
      if (this.props.horizontal) {
         return `translate(${this.getWeekdayLabelSize()}, ${this.getMonthLabelSize()})`;
      }
      return `translate(0, ${this.getWeekdayLabelSize()})`;
   }

   getViewBox(): string {
      if (this.props.horizontal) {
         return `0 0 ${this.getWidth()} ${this.getHeight()}`;
      }
      return `0 0 ${this.getHeight()} ${this.getWidth()}`;
   }

   getSquareCoordinates(dayIndex): [number, number] {
      if (this.props.horizontal) {
         return [0, dayIndex * this.getSquareSizeWithGutter()];
      }
      return [dayIndex * this.getSquareSizeWithGutter(), 0];
   }

   getWeekdayLabelCoordinates(dayIndex): [number, number] {
      if (this.props.horizontal) {
         return [0, (dayIndex + 1) * SQUARE_SIZE + dayIndex * this.props.gutterSize];
      }
      return [dayIndex * SQUARE_SIZE + dayIndex * this.props.gutterSize, SQUARE_SIZE];
   }

   getMonthLabelCoordinates(weekIndex): [number, number] {
      if (this.props.horizontal) {
         return [
            weekIndex * this.getSquareSizeWithGutter(),
            this.getMonthLabelSize() - MONTH_LABEL_GUTTER_SIZE,
         ];
      }
      const verticalOffset = -2;
      return [0, (weekIndex + 1) * this.getSquareSizeWithGutter() + verticalOffset];
   }

   handleClick(event: React.MouseEvent<SVGRectElement>, value): void {
      this.props.onClick?.(event, value);
   }

   handleMouseOver(event: React.MouseEvent<SVGRectElement>, value): void {
      this.props.onMouseOver?.(event, value);
   }

   handleMouseLeave(event: React.MouseEvent<SVGRectElement>, value): void {
      this.props.onMouseLeave?.(event, value);
   }

   renderSquare(dayIndex, index): React.ReactNode {
      const { transformDayElement } = this.props;
      const indexOutOfRange =
         index < this.getNumEmptyDaysAtStart() ||
         index >= this.getNumEmptyDaysAtStart() + this.getDateDifferenceInDays();
      if (indexOutOfRange && !this.props.showOutOfRangeDays) {
         return null;
      }
      const [x, y] = this.getSquareCoordinates(dayIndex);
      const value = this.getValueForIndex(index) as T;
      const rect = (
         <rect
            key={index}
            width={SQUARE_SIZE}
            height={SQUARE_SIZE}
            x={x}
            y={y}
            className={this.getClassNameForIndex(index)}
            onClick={(e) => this.handleClick(e, value)}
            onMouseOver={(e) => this.handleMouseOver(e, value)}
            onMouseLeave={(e) => this.handleMouseLeave(e, value)}
            {...this.getTooltipDataAttrsForIndex(index)}
         >
            <title>{this.getTitleForIndex(index)}</title>
         </rect>
      );
      return transformDayElement ? transformDayElement(rect, value, index) : rect;
   }

   renderWeek(weekIndex): React.ReactNode {
      return (
         <g
            key={weekIndex}
            transform={this.getTransformForWeek(weekIndex)}
            className={`${CSS_PSEDUO_NAMESPACE}week`}
         >
            {[...Array(DAYS_IN_WEEK).keys()].map((dayIndex) =>
               this.renderSquare(dayIndex, weekIndex * DAYS_IN_WEEK + dayIndex),
            )}
         </g>
      );
   }

   renderAllWeeks(): readonly React.ReactNode[] {
      return [...Array(this.getWeekCount()).keys()].map((weekIndex) => this.renderWeek(weekIndex));
   }

   renderMonthLabels(): null | readonly React.ReactNode[] {
      if (!this.props.showMonthLabels) {
         return null;
      }
      // don't render for last week, because label will be cut off
      const weekRange = [...Array(this.getWeekCount() - 1).keys()];
      return weekRange.map((weekIndex) => {
         const endOfWeek = shiftDate(
            this.getStartDateWithEmptyDays(),
            (weekIndex + 1) * DAYS_IN_WEEK,
         );
         const [x, y] = this.getMonthLabelCoordinates(weekIndex);
         return endOfWeek.getDate() >= 1 && endOfWeek.getDate() <= DAYS_IN_WEEK ? (
            <text key={weekIndex} x={x} y={y} className={`${CSS_PSEDUO_NAMESPACE}month-label`}>
               {this.props.monthLabels[endOfWeek.getMonth()]}
            </text>
         ) : null;
      });
   }

   renderWeekdayLabels(): readonly React.ReactNode[] {
      if (!this.props.showWeekdayLabels) {
         return null;
      }
      return this.props.weekdayLabels.map((weekdayLabel, dayIndex) => {
         const [x, y] = this.getWeekdayLabelCoordinates(dayIndex);
         const cssClasses = `${
            this.props.horizontal ? '' : `${CSS_PSEDUO_NAMESPACE}small-text`
         } ${CSS_PSEDUO_NAMESPACE}weekday-label`;
         return dayIndex & 1 ? (
            <text key={`${x}${y}`} x={x} y={y} className={cssClasses}>
               {weekdayLabel}
            </text>
         ) : null;
      });
   }

   render(): React.ReactNode {
      this.valueCache = this.getValueCache(this.props);
      return (
         <svg className='calendar-heatmap' viewBox={this.getViewBox()}>
            <g
               transform={this.getTransformForMonthLabels()}
               className={`${CSS_PSEDUO_NAMESPACE}month-labels`}
            >
               {this.renderMonthLabels()}
            </g>
            <g
               transform={this.getTransformForAllWeeks()}
               className={`${CSS_PSEDUO_NAMESPACE}all-weeks`}
            >
               {this.renderAllWeeks()}
            </g>
            <g
               transform={this.getTransformForWeekdayLabels()}
               className={`${CSS_PSEDUO_NAMESPACE}weekday-labels`}
            >
               {this.renderWeekdayLabels()}
            </g>
         </svg>
      );
   }
}

export default CalendarHeatmap;
