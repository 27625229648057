import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { AccountType } from '@models/AccountType';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import SchoolProfile from '@models/SchoolProfile';

import Constants from '../Constants';
import HttpService from './HttpService';
import SchoolService, { isSchoolProfile } from './SchoolService';

const { minPasswordLength, maxPasswordLength, statusCodes } = Constants;

interface CourseInvite {
   email: string;
   language: Language;
   courseCode: string;
   organizationId: number;
   sections: readonly { id: number; name: string }[];
   accountType: AccountType;
   /** First Name; if set by invite source */
   firstName?: string;
   /** Last Name; if set by invite source */
   lastName?: string;
}
export interface RegisteringUser {
   firstName: string;
   lastName: string;
   school: SchoolProfile | { id: number } | null;
   email: string;
   confirmEmail: string;
   accountType: AccountType;
   language: Language | '';
   instructorCode?: string;
   password: string;
   confirmPassword: string;
   termsOfService: boolean;
   missingSchoolUrl?: string;
}

export interface RegisteringUserWithSchoolProfile extends RegisteringUser {
   school: SchoolProfile;
}

/**
 * Information that we already know about the user currently registering based on their token or courseCode.
 * Cannot be edited by the registering user.
 */
export interface PrefilledUserInfo {
   /** Set when there's an instructor code or course code in the query params */
   accountType?: AccountType;
   /** Set when there's a personalized instructorCode or token */
   email?: string;
   confirmEmail?: string;
   /** Set where there's a token  */
   firstName?: string;
   /** Set from instructorCode query parameter */
   instructorCode?: string;
   /** Set when prefilled info was gathered from an SSO sign up. */
   isSsoOnly?: boolean;
   /** Language teaching or learning. Gather from language query param or course language */
   language?: Language;
   /** Gathered when there's an LTI invite or SSO sign up */
   lastName?: string;
   /** Set based on courseCode or invite */
   school?: SchoolProfile | { id: number } | null;
   /** Token from query parameters used to gather info */
   token?: string;
   /** Confirm Password */
   confirmPassword?: string;
}

export interface ApiError {
   msg?: string;
   code?: string;
   displayMsg?: string;
}

export type ErrorField = keyof Omit<RegisteringUser, 'school'>;

export interface SuccessfulRegistrationResponse {
   msg: string;
   loginToken?: string;
}

export enum RegistrationErrorName {
   missingField = 'missing_field',
   mismatchedField = 'mispatched_field',
   invalidPassword = 'invalid_password',
   invalidInstructorCode = 'invalid_instructor_code',
   existingEmail = 'existing_email',
   restrictedEmailDomain = 'restricted_email_domain',
   expiredGoogleAuth = 'expired_google_auth',
   expiredLtiToken = 'expired_lti_token',
   apiError = 'api_error',
   invalidToken = 'invalid_token',
   invalidCourseCode = 'invalid_course_code',
}

export interface RegistrationError {
   /** Type of registation error */
   name: RegistrationErrorName;
   /** Name of input field affected */
   field?: keyof RegisteringUser;
   /** Error message to display to user */
   message?: string;
}

export enum RegistrationStep {
   initial = 'initial',
   schoolSuggestion = 'school_suggestion',
   schoolSearch = 'school_search',
   domainRestricted = 'domain_restricted',
   success = 'success',
}

export const isRegistrationError = (response: unknown): response is RegistrationError =>
   Object.values(RegistrationErrorName)?.includes((response as RegistrationError)?.name);

export const isRegisteringUserWithSchoolProfile = (
   user: RegisteringUser,
): user is RegisteringUserWithSchoolProfile => isSchoolProfile(user.school);

const checkEmailDomainValid = (email: string, schoolId?: number): Promise<boolean> => {
   if (schoolId) {
      return SchoolService.isEmailValid(schoolId, email);
   } else {
      return Promise.resolve(false);
   }
};

const checkEmailUnique = (email: string): Promise<boolean> =>
   HttpService.post<{ msg: string }>('/api/users/email_unique', { email })
      .then((response) => response.status === statusCodes.ok)
      .catch((error) => {
         if (error.response.status === statusCodes.conflict) {
            return false;
         }
         throw error;
      });

const validateInstructorCode = (
   instructorCode: string,
): Promise<{ msg: string; email: string } | RegistrationError> =>
   HttpService.post<{ msg: string; email: string }>(
      '/api/users/instructor_code_valid',
      snakeCaseKeys({
         instructorCode,
      }),
   )
      .then((response) => response.data)
      .catch(() => ({
         field: 'instructorCode' as keyof RegisteringUser,
         name: RegistrationErrorName.invalidInstructorCode,
         message: 'Invalid instructor code',
      }));

const verifyNotBlank = (
   userInfo: RegisteringUser,
   prefilledInfo: Maybe<PrefilledUserInfo>,
   field: keyof Pick<
      RegisteringUser,
      'firstName' | 'lastName' | 'language' | 'confirmEmail' | 'confirmPassword'
   >,
): Maybe<RegistrationError> => {
   if (!(userInfo[field] || prefilledInfo?.[field])) {
      return {
         field,
         name: RegistrationErrorName.missingField,
         message: 'Cannot be blank',
      };
   }
};

const validateAccountType = async (
   accountType: AccountType,
   instructorCode: Maybe<string>,
): Promise<{
   accountType?: RegistrationError;
   instructorCode?: RegistrationError;
}> => {
   if (!accountType) {
      return {
         accountType: {
            field: 'accountType',
            name: RegistrationErrorName.missingField,
            message: 'Cannot be blank',
         },
      };
   } else if (accountType === AccountType.instructor) {
      if (!instructorCode) {
         return {
            instructorCode: {
               field: 'instructorCode',
               name: RegistrationErrorName.missingField,
               message: 'Cannot be blank',
            },
         };
      }
      const instructorCodeError = await validateInstructorCode(instructorCode);
      if (isRegistrationError(instructorCodeError)) {
         return { instructorCode: instructorCodeError };
      }
   }
   return {};
};

const validateEmail = async (
   email: string,
   confirmEmail: string,
   prefilledEmail?: string,
): Promise<Maybe<RegistrationError>> => {
   let emailToValidate = email;
   if (!email) {
      if (prefilledEmail) {
         emailToValidate = prefilledEmail;
      } else {
         return {
            field: 'email',
            name: RegistrationErrorName.missingField,
            message: 'Cannot be blank',
         };
      }
   }

   if (!prefilledEmail && email !== confirmEmail) {
      return {
         field: 'email',
         name: RegistrationErrorName.mismatchedField,
         message: 'Email must match',
      };
   }
   const emailIsUnique = await checkEmailUnique(emailToValidate);
   if (!emailIsUnique) {
      return {
         field: 'email',
         name: RegistrationErrorName.existingEmail,
         message: 'Email already taken',
      };
   }
};

const validatePassword = (password: string, confirmPassword: string): Maybe<RegistrationError> => {
   if (!password) {
      return {
         field: 'password',
         name: RegistrationErrorName.missingField,
         message: 'Cannot be blank',
      };
   } else if (password.length < minPasswordLength) {
      return {
         field: 'password',
         name: RegistrationErrorName.invalidPassword,
         message: `Must be at least ${minPasswordLength} characters`,
      };
   } else if (password.length > maxPasswordLength) {
      return {
         field: 'password',
         name: RegistrationErrorName.invalidPassword,
         message: `Must be no more than ${maxPasswordLength} characters`,
      };
   } else if (confirmPassword !== password) {
      return {
         field: 'password',
         name: RegistrationErrorName.mismatchedField,
         message: 'Passwords must match',
      };
   }
   return null;
};

const validateTermsOfService = (termsOfService: boolean): Maybe<RegistrationError> => {
   if (!termsOfService) {
      return {
         field: 'termsOfService',
         name: RegistrationErrorName.missingField,
         message: 'Must accept Terms of Service',
      };
   }
};

const validateRegistration = async (
   user: RegisteringUser,
   prefilledUserInfo?: Maybe<PrefilledUserInfo>,
): Promise<readonly RegistrationError[]> => {
   const errors: Partial<Record<keyof RegisteringUser, Maybe<RegistrationError>>> = {};

   errors.firstName = verifyNotBlank(user, prefilledUserInfo, 'firstName');
   errors.lastName = verifyNotBlank(user, prefilledUserInfo, 'lastName');
   errors.language = verifyNotBlank(user, prefilledUserInfo, 'language');
   const accountTypeErrors = await validateAccountType(user.accountType, user?.instructorCode);
   Object.assign(errors, accountTypeErrors);
   errors.email = await validateEmail(user.email, user.confirmEmail, prefilledUserInfo?.email);
   errors.confirmEmail = verifyNotBlank(user, prefilledUserInfo, 'confirmEmail');
   errors.password = validatePassword(user.password, user.confirmPassword);
   errors.confirmPassword = verifyNotBlank(user, prefilledUserInfo, 'confirmPassword');
   errors.termsOfService = validateTermsOfService(user.termsOfService);
   const filteredErrors = Object.values(errors).filter((i: Maybe<RegistrationError>) =>
      isRegistrationError(i),
   ) as RegistrationError[];
   return filteredErrors;
};

const register = async (
   user: RegisteringUser,
   prefilledUserInfo: Maybe<PrefilledUserInfo>,
   courseCode: Maybe<string>,
): Promise<SuccessfulRegistrationResponse | RegistrationError> => {
   const emailToCheck = prefilledUserInfo?.email || user.email;
   const schoolId = user.school?.id;
   const emailValid = await checkEmailDomainValid(emailToCheck, schoolId);
   if (!emailValid) {
      return {
         name: RegistrationErrorName.restrictedEmailDomain,
         message: "Email must match school's domain",
      };
   }

   const { token, isSsoOnly } = prefilledUserInfo || {};
   const { confirmPassword, school, termsOfService, ...otherParams } = user;
   const data = {
      ...otherParams,
      organizationId: school?.id,
      invitationToken: !isSsoOnly ? token : '',
      registrationToken: isSsoOnly ? token : '',
      courseCode,
   };
   return HttpService.post<SuccessfulRegistrationResponse>(
      '/api/users/register',
      snakeCaseKeys(data),
   ).then((response) => response.data);
};

const getSsoAttributesFromToken = (
   token: string,
): Promise<{ firstName: string; lastName: string; email: string }> =>
   HttpService.get<{ firstName: string; lastName: string; email: string }>(
      `/api/users/sso/attributes?token=${token}`,
   ).then((response) => response.data);

const getCourseInviteFromToken = (token: string) =>
   HttpService.get<CourseInvite>(`/api/users/course_invitations/${token}`).then(
      (response) => response.data,
   );

export default {
   validateRegistration,
   validateInstructorCode,
   checkEmailUnique,
   checkEmailDomainValid,
   getSsoAttributesFromToken,
   getCourseInviteFromToken,
   register,
};
