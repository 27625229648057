// @ts-strict-ignore
import * as React from 'react';

import useIsMount from '@hooks/use-is-mount';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import Tippy from '@tippyjs/react';
import { mergeRefs } from '@utilities/Refs';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import FilterBlockOptions from './FilterBlockOptions';
import { Predicate } from './Models';
import { PredicateEditorContext } from './PredicatesEditor';
import { getDetails } from './Utils';

interface FilterBlockEditorProps {
   predicate: Predicate;
   onUpdate(predicate: Predicate);
   onRemove(): void;
   onEditorOpen(): void;
   onEditorClose(): void;
}

const FilterBlockEditor: React.FC<FilterBlockEditorProps> = ({
   predicate,
   onRemove,
   onUpdate,
   onEditorClose,
   onEditorOpen,
}) => {
   const { attributes, newFilterId, invalidFilterIds } =
      React.useContext<PredicateEditorContext>(PredicateEditorContext);
   const attribute = attributes.find((i) => i.identifier === predicate.attribute);

   const [isEditorVisible, setIsEditorVisibile] = React.useState<boolean>(false);
   const isInvalid = invalidFilterIds.includes(predicate.id);

   const isMount = useIsMount();

   const details = isInvalid ? 'is missing value' : getDetails(predicate);

   const referenceRef = React.useRef(null);
   const containerRef = React.useRef(null);

   React.useEffect(() => {
      if (newFilterId === predicate.id && !isEditorVisible) {
         setIsEditorVisibile(true);
      }
   }, [newFilterId]);

   React.useEffect(() => {
      if (!isMount) {
         if (isEditorVisible) {
            onEditorOpen();
         } else {
            onEditorClose();
         }
      }
   }, [isEditorVisible]);

   React.useEffect(() => {
      document.addEventListener('mousedown', handleDocumentClick);
      return () => {
         document.removeEventListener('mousedown', handleDocumentClick);
      };
   }, [isEditorVisible]);

   const handleDocumentClick = (event: MouseEvent): void => {
      if (isEditorVisible) {
         if (
            containerRef.current.contains(event.target) ||
            referenceRef.current.contains(event.target)
         ) {
            return;
         } else {
            setIsEditorVisibile(false);
         }
      }
   };

   const handleBlockClick = (): void => {
      setIsEditorVisibile((prevVisible) => !prevVisible);
   };

   const handleRemoveClick = (): void => {
      onRemove();
   };

   const completeEditingFilterBlock = (): void => {
      setIsEditorVisibile(false);
   };

   const getIcon = (): React.ReactNode => {
      const Icon = attribute.icon;
      return <Icon />;
   };

   const renderFilterBlock = (ref: React.Ref<HTMLDivElement>): React.ReactNode => (
      <div
         className={classnames('filter-group-item filter-block', {
            active: isEditorVisible,
            invalid: isInvalid,
         })}
         onClick={handleBlockClick}
         ref={mergeRefs(ref, referenceRef)}
      >
         <div className='filter-block-name'>
            <span className='filter-block-icon'>{getIcon()}</span>
            {attribute.label}
         </div>
         <p className='filter-block-details flex-auto'>&nbsp; {details}</p>
         <Tippy content='Remove this filter'>
            <div className='filter-block-delete' onClick={handleRemoveClick}>
               <IconClose />
            </div>
         </Tippy>
      </div>
   );

   if (attribute === undefined) {
      console.error(`Cannot find ${predicate.attribute} in provided attributes`);
      return null;
   }

   return (
      <Manager>
         <Reference>{({ ref }) => renderFilterBlock(ref)}</Reference>
         {createPortal(
            <CSSTransition
               in={isEditorVisible}
               unmountOnExit
               timeout={100}
               classNames='filter-block-options-container'
            >
               <Popper placement='bottom-start'>
                  {({ ref, style }) => (
                     <div
                        ref={mergeRefs(ref, containerRef)}
                        style={style}
                        className='filter-block-options-container'
                     >
                        <FilterBlockOptions
                           predicate={predicate}
                           showErrors={isInvalid}
                           onClose={completeEditingFilterBlock}
                           onUpdate={onUpdate}
                        />
                     </div>
                  )}
               </Popper>
            </CSSTransition>,
            document.body,
         )}
      </Manager>
   );
};

export default FilterBlockEditor;
