import 'mapbox-gl/dist/mapbox-gl.css';

import * as _ from 'lodash';
import * as React from 'react';

import DocumentTitle from '@components/DocumentTitle';
import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import VideoSearchBar, { StringSelection } from '@components/Video/VideoSearch';
import { randomShortId } from '@helpers/RandomStringUtils';
import useFetch from '@hooks/use-fetch';
import Language from '@models/Language';
import { BasicLevelName } from '@models/Proficiency';
import {
   InterviewQuestionSummary,
   Video,
   VideoFeature,
   VideoFeatureCollection,
   VideoSummary,
} from '@models/Video';
import WorldLocation from '@models/WorldLocation';
import VideoService from '@services/VideoService';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import {
   FullscreenControl,
   GeoJSONSource,
   Layer,
   Map,
   MapGeoJSONFeature,
   MapLayerMouseEvent,
   MapRef,
   NavigationControl,
   Source,
} from 'react-map-gl';

import Config from '../../../Config';
import {
   clusterCountLayer,
   clusterLayer,
   LayerIdObject,
   selectedClusterCountLayer,
   selectedClusterLayer,
   unclusteredPointLayer,
} from './layers';
import VideoPopup from './VideoPopup';

import { FuseResult } from 'fuse.js';

const defaultCenter = {
   latitude: 20,
   longitude: 0,
   zoom: 1.5,
};
const maxZoom = 6;

const VideoMap: React.FC = () => {
   const { error, data: allVideoGeoJson, isLoading } = useFetch(VideoService.getAllVideoGeoJSON);
   const [cursor, setCursor] = React.useState<string>('');
   const [isLoadingResults, setIsLoadingResults] = React.useState<boolean>(false);

   // Selected pin
   const [selectedFeature, setSelectedFeature] = React.useState<
      MapGeoJSONFeature | Feature<Geometry, VideoSummary>
   >();

   // The data used to drive the primary source of the map
   const [mapFeatureCollection, setMapFeatureCollection] = React.useState<VideoFeatureCollection>();

   // Interview Result State
   const [interviewQuestionResults, setInterviewQuestionResults] = React.useState<
      FuseResult<InterviewQuestionSummary>[]
   >([]);

   // Video Search Result State
   const [videoSearchResults, setVideoSearchResults] = React.useState<Video[]>([]);

   // Filter State
   const [selectedClustersVideoFeatures, setSelectedClustersVideoFeatures] =
      React.useState<VideoFeature[]>();
   const [selectedCluster, setSelectedCluster] = React.useState<FeatureCollection>();
   const [searchString, setSearchString] = React.useState<StringSelection>({
      isSelection: false,
      value: '',
   });
   const [selectedLanguage, setSelectedLanguage] = React.useState<Language>();
   const [selectedLevel, setSelectedLevel] = React.useState<BasicLevelName>();

   const mapRef = React.useRef<MapRef | null>(null);

   React.useEffect(() => {
      setMapFeatureCollection(allVideoGeoJson);
   }, [allVideoGeoJson]);

   const onSetVideoSearchResults = (videos: readonly Video[]) => {
      setVideoSearchResults(videos.map((i) => ({ ...i, location: getLocationFromGeoJSON(i.id) })));
   };

   const easeTo = (
      feature: MapGeoJSONFeature | Feature<Geometry, VideoSummary>,
      zoom = 6,
   ): void => {
      const featureGeometry = feature.geometry;
      if ('coordinates' in featureGeometry) {
         const longitude = featureGeometry.coordinates[0];
         const latitude = featureGeometry.coordinates[1];

         if (_.isNumber(longitude) && _.isNumber(latitude)) {
            mapRef.current?.easeTo({
               center: {
                  lat: latitude,
                  lng: longitude,
               },
               zoom: Math.min(zoom, maxZoom), // check here
               duration: 500,
            });
         }
      }
   };

   const handleClusterClick = (feature: MapGeoJSONFeature) => {
      if (feature.properties) {
         const clusterId = feature.properties.cluster_id;

         const mapboxSource = mapRef.current?.getSource('videos') as GeoJSONSource;

         mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) {
               return;
            }

            easeTo(feature, zoom); //
         });

         mapboxSource.getClusterChildren(clusterId, (err, aFeatures) => {
            const cluster = aFeatures?.find((f) => f.properties?.cluster);
            // If we find a child that has a "cluster" property set to true then it means that
            // there are still clusters under the cluster and we are not at the bottom yet.
            // If cluster is undefined then it means we found no clusters under this cluster
            // and we should only have leaf nodes.
            if (cluster === undefined) {
               // The types on getClusterChildren do not seem to allow for our specific type of feature
               const videoFeatures = aFeatures as VideoFeature[];

               // If there are no results when we select a cluster clear the search string.
               if (interviewQuestionResults !== undefined) {
                  // setSearchString({ isSelection: false, value: '' });
                  setInterviewQuestionResults([]);
               }
               setSelectedClustersVideoFeatures(videoFeatures);
               setSelectedCluster({
                  type: 'FeatureCollection',
                  features: [feature],
               });
               setMapFeatureCollection((prev) => {
                  if (prev === undefined) return;
                  const clusterVideoIds = videoFeatures.map((x) => x.properties?.videoId);
                  const nextFeatures = prev.features.filter(
                     (x) => !clusterVideoIds.find((y) => x.properties?.videoId === y),
                  );
                  return {
                     type: 'FeatureCollection',
                     features: nextFeatures,
                  };
               });
            }
         });
      }
   };

   const handlePointClick = (feature: MapGeoJSONFeature) => {
      // When the user clicks a point when the popup is already open
      // The pop up close fires setting the feature to be undefined
      // Then the feature is reset in here by the click handler
      // In order to render properly the id needs to be different for some reason
      const f = _.cloneDeep(feature);
      f.id = randomShortId();
      setSelectedFeature(_.cloneDeep(f));
   };

   // This function is more for the video geo json on the map.
   const getFilteredVideoSummaryMapResults = (
      interviewQuestion?: StringSelection,
      language?: Language,
      level?: BasicLevelName,
   ): Feature<Geometry, VideoSummary>[] => {
      if (allVideoGeoJson === undefined) return [];

      const filteredResults = allVideoGeoJson?.features.filter(
         (x) =>
            (interviewQuestion?.isSelection
               ? x.properties.interviewQuestion === interviewQuestion.value
               : true) &&
            (language ? x.properties.language === language : true) &&
            (level ? x.properties.level === level : true),
      );
      return filteredResults;
   };

   const getLocationFromGeoJSON = (videoId: number): WorldLocation | null => {
      if (!allVideoGeoJson) {
         return null;
      }
      const geoJsonResult = allVideoGeoJson.features.find(
         (item) => item.properties.videoId === videoId,
      );

      if (!geoJsonResult) {
         return null;
      }

      return {
         countryCode: geoJsonResult.properties.countryCode,
         longitude: geoJsonResult.properties.longitude,
         latitude: geoJsonResult.properties.latitude,
         name: '', // FIXME
         placeFormatted: '', // FIXME
         displayName: '', // FIXME
         mapProviderId: '', // FIXME
      };
   };

   const clearClusterAndResetResultsToCurrentFilters = async () => {
      if (selectedCluster !== undefined) {
         const filteredResults = getFilteredVideoSummaryMapResults(
            searchString,
            selectedLanguage,
            selectedLevel,
         );

         if (searchString?.isSelection) {
            const videoIds = filteredResults.map((x) => x.properties.videoId);
            setIsLoadingResults(true);
            const videos = await VideoService.getAllSummaries(videoIds);
            setIsLoadingResults(false);
            onSetVideoSearchResults(videos);
         } else {
            onSetVideoSearchResults([]);
         }

         setSelectedCluster(undefined);
         setSelectedClustersVideoFeatures(undefined);
         setMapFeatureCollection({
            type: 'FeatureCollection',
            features: filteredResults,
         });
      }
   };

   const onClick = (e: MapLayerMouseEvent) => {
      if (e === undefined) return;

      if (selectedFeature) return;

      if (e.features && e.features[0] && mapRef.current) {
         const feature = e.features[0];

         // When a cluster is clicked
         if (feature.layer.id === LayerIdObject['clusters']) {
            handleClusterClick(feature);
         } else if (feature.layer.id === LayerIdObject['unclustered-point']) {
            handlePointClick(feature);
         }
      } else {
         clearClusterAndResetResultsToCurrentFilters();
      }
   };

   const onVideoResultClick = (video: Video): void => {
      const feature = allVideoGeoJson?.features.find((x) => x.properties.videoId === video.id);

      if (feature) {
         // Do not change zoom and easement if they are clicking a feature in the current selected cluster
         const doesSelectedClusterContainVideo = selectedClustersVideoFeatures?.find(
            (x) => x.properties.videoId === feature.properties.videoId,
         );
         if (!doesSelectedClusterContainVideo) {
            easeTo(feature, 2);
         }

         setSelectedFeature(feature);
      }
   };

   const onSearchStringChange = (newSearchString: StringSelection): void => {
      setSearchString(newSearchString);
      recenter();
   };

   // Map interactions
   const recenter = (): void => {
      mapRef.current?.easeTo({
         center: {
            lat: defaultCenter.latitude,
            lng: defaultCenter.longitude,
         },
         zoom: defaultCenter.zoom,
         duration: 500,
      });
   };
   const onMouseEnter = React.useCallback(() => setCursor('pointer'), []);
   const onMouseLeave = React.useCallback(() => setCursor(''), []);
   const onDrag = () => {
      clearClusterAndResetResultsToCurrentFilters();
   };

   const onRender = () => {
      // The map does not seem to like to resize to the container when using vh or percentages. This fixes that.
      mapRef.current?.resize();
   };

   const onPopupClose = (): void => {
      setSelectedFeature(undefined);
   };

   if (error) return <ErrorDisplay heading='Error Fetching Videos' />;
   if (isLoading || allVideoGeoJson === undefined) return <Loader />;

   return (
      <>
         <DocumentTitle>Video Map</DocumentTitle>
         <Map
            cursor={cursor}
            initialViewState={defaultCenter}
            // If you add draft to the end there isn't any caching when iterating with the map in mapbox studio this is helpful.
            mapStyle='mapbox://styles/jameslingco/clnknhiai001a01p360rzbiac'
            // mapStyle='mapbox://styles/jameslingco/clnknhiai001a01p360rzbiac/draft'
            mapboxAccessToken={Config.mapBoxAccessToken}
            interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
            onClick={onClick}
            attributionControl
            ref={mapRef}
            style={{
               height: 'calc(100vh - 140px)',
               margin: '20px 0 0 0',
               width: 'calc(100% - 20px',
               borderRadius: '7px',
            }}
            onDrag={onDrag}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onRender={onRender}
            // onZoomStart={onZoomStart}
            maxZoom={maxZoom}
         >
            <VideoSearchBar
               getFilteredVideoSummaryMapResults={getFilteredVideoSummaryMapResults}
               interviewQuestionResults={interviewQuestionResults}
               isLoadingResults={isLoadingResults}
               mapFeatureCollection={allVideoGeoJson}
               onSearchStringChange={onSearchStringChange}
               onVideoResultClick={onVideoResultClick}
               searchString={searchString}
               selectedClusterVideoFeatures={selectedClustersVideoFeatures}
               selectedLanguage={selectedLanguage}
               selectedLevel={selectedLevel}
               setInterviewQuestionResults={setInterviewQuestionResults}
               setIsLoadingResults={setIsLoadingResults}
               setMapFeatureCollection={setMapFeatureCollection}
               setSelectedLanguage={setSelectedLanguage}
               setSelectedLevel={setSelectedLevel}
               setVideoSearchResults={onSetVideoSearchResults}
               unselectCluster={clearClusterAndResetResultsToCurrentFilters}
               videoSearchResults={videoSearchResults}
            />
            <FullscreenControl position='bottom-right' />
            <NavigationControl position='bottom-right' />

            <Source
               id='videos'
               type='geojson'
               data={mapFeatureCollection}
               cluster
               clusterMaxZoom={maxZoom}
               clusterRadius={15}
            >
               <Layer {...clusterLayer} />
               <Layer {...clusterCountLayer} />
               <Layer {...unclusteredPointLayer} />
            </Source>

            {selectedCluster && (
               <Source
                  id='selected-cluster'
                  type='geojson'
                  data={selectedCluster}
                  cluster
                  clusterMaxZoom={10}
                  clusterRadius={10} // Play with this in order to adjust cluster sizes as we get more data.
               >
                  <Layer {...selectedClusterLayer} />
                  <Layer {...selectedClusterCountLayer} />
               </Source>
            )}

            {selectedFeature && <VideoPopup feature={selectedFeature} onClose={onPopupClose} />}
         </Map>
      </>
   );
};

export default VideoMap;
