import * as React from 'react';

import Banner from '@components/Wrappers/Layout/Banner';
import flattenObject from '@helpers/FlattenObject';
import { Maybe } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import IUserInfo from '@models/IUserInfo';
import { createPageViewEvent, trackEvent } from '@services/GoogleAnalyticsService';
import IntercomService from '@services/IntercomService';
import UserService from '@services/UserService';
import { diff } from 'deep-object-diff';
import { useLocation } from 'react-router-dom';
import { Provider as GraphQLProvider } from 'urql';

import AppRoutes from './AppRoutes';
import HelpLauncher from './components/HelpLauncher';
import Onboarding from './components/Onboarding';
import Config from './Config';
import { GraphqlClient } from './graphql/client';
import usePrevious from './hooks/use-previous';
import Mixpanel from './Mixpanel';
import { actionFunctions } from './redux/Actions';
import { store } from './redux/Store';
import Emitter from './utilities/Emitter';

export const AppContext = React.createContext<{ socketEventEmitter: Maybe<Emitter> }>({
   socketEventEmitter: null,
});
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export interface IAppProps {
   currentCourses: readonly BasicCourseProfile[];
   socketEventEmitter: Emitter;
   user: Maybe<IUserInfo>;
   emit(action: string, data: Record<string, unknown>): void;
}

const App: React.FC<IAppProps> = React.memo(
   (props: IAppProps) => {
      const location = useLocation();
      const prevLocation = usePrevious(location);

      const resetNetworkError = (): void => {
         store.dispatch(actionFunctions.setNetworkError(null));
      };

      React.useEffect(() => {
         resetNetworkError();

         if (Config.environmentType === 'production' && props.user?.userProfile?.id) {
            trackEvent(createPageViewEvent(props.user.userProfile.id, location.pathname));
         }
         window.Intercom?.('update');

         if (location.pathname !== '/' && location.pathname !== prevLocation?.pathname) {
            Mixpanel.trackPageView(props.user);
         }
      }, [location, prevLocation]);

      React.useEffect(() => {
         if (props.user?.loggedIn) {
            const {
               user: { userProfile, schoolProfile, impersonatorRefreshToken },
            } = props;
            if (!!userProfile && !!schoolProfile) {
               UserService.loadAndIdentifyUser(userProfile, schoolProfile);
               if (impersonatorRefreshToken) {
                  document.body.classList.add('impersonating');
               }
            }
         } else {
            IntercomService.boot();
         }
      }, []);

      return (
         <AppProvider
            value={{
               socketEventEmitter: props.socketEventEmitter,
            }}
         >
            <GraphQLProvider value={GraphqlClient}>
               <Banner />
               <AppRoutes />
               <Onboarding />
               <HelpLauncher />
            </GraphQLProvider>
         </AppProvider>
      );
   },
   (prevProps, nextProps) => {
      const changedKeys = Object.keys(flattenObject(diff(prevProps, nextProps)));
      const exemptKeys = [
         'contentLibraryLayout',
         'user.accessToken',
         'user.userProfile.completedOnboardingTasks',
         'user.userProfile.googleScopes',
      ];

      return !changedKeys.every((i) => exemptKeys.includes(i));
   },
);

App.displayName = 'App';

export default App;
