import * as React from 'react';

import SessionResumeButton from '@icons/general/session-resume-button.svg';

interface PauseOverlayProps {
   togglePause(): void;
}

const PauseOverlay: React.FC<PauseOverlayProps> = ({ togglePause }) => (
   <div className='session-overlay pause'>
      <SessionResumeButton onClick={togglePause} />
   </div>
);

export default React.memo(PauseOverlay);
