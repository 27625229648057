import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import DeleteObjectConfirmationModal from './DeleteObjectModal';

interface DeleteActionProps {
   dataTest?: string;
   disabled?: boolean;
   isLoading?: boolean;
   objectName: string;
   onCancel?(): void;
   onDelete(): void;
}

const DeleteAction: React.FC<DeleteActionProps> = ({
   isLoading = false,
   objectName,
   disabled,
   dataTest,
   onDelete,
   onCancel,
}) => {
   const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

   const openDeleteModal = (): void => {
      if (!disabled) {
         setIsDeleteModalOpen(true);
      }
   };

   const handleCancel = (): void => {
      setIsDeleteModalOpen(false);
      onCancel?.();
   };

   return (
      <>
         {isDeleteModalOpen && (
            <DeleteObjectConfirmationModal
               onCancel={handleCancel}
               onDelete={onDelete}
               isLoading={isLoading}
               objectName={objectName}
            />
         )}
         <Tippy content='Delete' disabled={disabled} delay={[400, 0]}>
            <div
               className={classNames('icon-action', {
                  disabled,
               })}
               data-test={dataTest}
               onClick={openDeleteModal}
            >
               <IconBin />
            </div>
         </Tippy>
      </>
   );
};

export default DeleteAction;
