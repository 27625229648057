import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import Button from '@components/Common/Button';
import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';
import { randomTempId } from '@helpers/RandomStringUtils';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { CourseMode, CourseSection } from '@models/Course';
import Language from '@models/Language';

interface SectionsTable {
   language: Maybe<Language>;
   sections: readonly CourseSection<CourseMode.create | CourseMode.edit>[];
   courseDefaultDueTime: Date;
   appendSection(section: CourseSection<CourseMode.create | CourseMode.edit>): void;
   toggleSections(): void;
   updateSection(
      sectionId: string | number,
      update: Partial<CourseSection<CourseMode.create | CourseMode.edit>>,
   ): void;
   deleteSection(sectionId: string | number): void;
}

const SectionsTable: React.FC<SectionsTable> = ({
   language,
   sections,
   courseDefaultDueTime,
   appendSection,
   updateSection,
   deleteSection,
   toggleSections,
}) => {
   const [editingSection, setEditingSection] =
      React.useState<Maybe<CourseSection<CourseMode.create | CourseMode.edit>>>(null);
   const [sectionModalOpen, setSectionModalOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (!sections.length && !sectionModalOpen) {
         setEditingSection({
            name: 'Section 1',
            id: randomTempId(),
            defaultDueTime: courseDefaultDueTime,
         });
         setSectionModalOpen(true);
      }
   }, [sections, sectionModalOpen]);

   const getSectionName = (id: string | number): string => {
      const section = sections.find((i) => i.id === id);
      return section ? section.name : '';
   };

   const getSectionDefaultDueTime = (id: string | number): Maybe<Date> => {
      const section = sections.find((i) => i.id === id);
      return section ? section.defaultDueTime : courseDefaultDueTime;
   };

   const handleEditSection = (id: string | number): void => {
      setEditingSection({
         id,
         name: getSectionName(id),
         defaultDueTime: getSectionDefaultDueTime(id),
      });
      setSectionModalOpen(true);
   };

   const handleNewSection = (): void => {
      setSectionModalOpen(true);
      setEditingSection((prevEditingSection) => {
         if (prevEditingSection) {
            return {
               ...prevEditingSection,
               name: `Section ${sections.length + 1}`,
               defaultDueTime: courseDefaultDueTime,
            };
         } else {
            return {
               name: 'Section 1',
               id: randomTempId(),
               defaultDueTime: courseDefaultDueTime,
            };
         }
      });
   };

   const handleSectionModalCancel = (): void => {
      if (!sections.length) {
         toggleSections();
      }
      setSectionModalOpen(false);
      setEditingSection(null);
   };

   const handleSectionModalSave = (): void => {
      const existingIds = sections.map((i) => i.id);
      if (!editingSection) {
         return;
      }
      if (existingIds.includes(editingSection.id)) {
         updateSection(editingSection.id, {
            name: editingSection.name,
            defaultDueTime: editingSection.defaultDueTime,
         });
      } else {
         appendSection(editingSection);
      }
      setSectionModalOpen(false);
      setEditingSection(null);
   };

   const handleSectionNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setEditingSection((prevEditingSection) =>
         prevEditingSection
            ? {
                 ...prevEditingSection,
                 name: value,
              }
            : prevEditingSection,
      );
   };

   const handleSectionDefaultDueTimeChange = (defaultDueTime: Date): void => {
      setEditingSection((prevEditingSection) =>
         prevEditingSection
            ? {
                 ...prevEditingSection,
                 defaultDueTime,
              }
            : prevEditingSection,
      );
   };

   return (
      <>
         <div className='card course-card'>
            <div className='card-title has-button full-width'>
               <div className='title'>Sections</div>
               <div>
                  <div className='action-buttons'>
                     <Button line onClick={handleNewSection}>
                        + Add New Section
                     </Button>
                  </div>
               </div>
            </div>
            <div className='content-form sections-table'>
               <div className='row'>
                  <div className='col-xs-12'>
                     <table className='rwd-table'>
                        <tbody>
                           <tr className='head-row'>
                              <th>Name</th>
                              <th>Enrolled</th>
                              <th>Actions</th>
                           </tr>
                           {sections.map(({ enrolledCount, id }) => (
                              <tr key={id}>
                                 <td data-th='Name'>{getSectionName(id)}</td>
                                 <td data-th='Enrolled'>{enrolledCount}</td>
                                 <td data-th='Actions'>
                                    <div
                                       className='icon-action'
                                       onClick={() => handleEditSection(id)}
                                    >
                                       <IconHighlight />
                                    </div>
                                    <div className='icon-action' onClick={() => deleteSection(id)}>
                                       <IconBin />
                                    </div>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         {sectionModalOpen && editingSection && (
            <ModalDialog
               appearance={Appearance.primary}
               heading={`${editingSection.id ? 'Edit ' : 'New'} Section`}
               onClose={handleSectionModalCancel}
               animations={{ enter: 'animated bounceInDown' }}
               bodyClassName='edit-sections-modal'
               actions={[
                  { text: 'Save', onClick: handleSectionModalSave },
                  { text: 'Cancel', onClick: handleSectionModalCancel },
               ]}
            >
               <div className='row margin-bottom-m'>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Name</label>
                     <AccentTextbox
                        autoFocus
                        name='sectionName'
                        language={language}
                        value={editingSection.name}
                        onChange={handleSectionNameChange}
                     />
                  </div>
                  <div className='col-xs-12 col-sm-6'>
                     <label className='field-title'>Default Due Time</label>
                     <DateTimePicker
                        onChange={handleSectionDefaultDueTimeChange}
                        value={editingSection.defaultDueTime}
                        calendar={false}
                     />
                  </div>
               </div>
            </ModalDialog>
         )}
      </>
   );
};

export default React.memo(SectionsTable);
