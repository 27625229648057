import { ActivityMode } from './ActivityMode';

export enum ActivityVariableType {
   float = 'float',
   string = 'string',
   boolean = 'boolean',
   integer = 'integer',
}

export type ActivityVariableValue = string | boolean | number;

export type ActivityVariableId<Mode extends ActivityMode> = Mode extends ActivityMode.create
   ? string
   : Mode extends ActivityMode.edit
   ? string | number
   : Mode extends ActivityMode.complete | ActivityMode.grade
   ? number
   : Mode extends ActivityMode.preview
   ? number
   : null;

export interface ActivityVariable<Mode extends ActivityMode> {
   /** Prefixed with temp- if new, otherwise an integer */
   id: ActivityVariableId<Mode>;
   description: string;
   name: string;
   type: ActivityVariableType;
}
