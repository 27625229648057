import * as React from 'react';

import CourseService from '@services/CourseService';
import moment from 'moment';

import Constants from '../../Constants';
import { LanguageLookup } from '@models/Language';
import Link from '@components/Common/Link';
import { Column } from '@components/Common/Table';
import { renderTooltipCell } from '@components/Common/TableCellHelpers';
import FilterableTable from '@components/FilterableTable';
import { COURSE_ATTRIBUTES, SCHOOL_ATTRIBUTES } from '@components/PredicateEditor/CommonAttributes';
import CourseActionsCell from './CourseActionsCell';
import { defaultPredicate } from './DefaultCoursesPredicate';
import ExtendedCourseProfile from './ExtendedCourseProfile';

const Courses: React.FC = () => {
   const {
      routes: {
         courses: { dashboard, viewCourses, viewCourseEnrollments, modules },
      },
   } = Constants;

   const attributes = [...COURSE_ATTRIBUTES, ...SCHOOL_ATTRIBUTES];

   const renderCourseNameCell = (row: ExtendedCourseProfile): React.ReactNode => (
      <Link to={dashboard.replace(':courseId', row.id.toString())}>{row.name}</Link>
   );

   const renderLanguageCell = (row: ExtendedCourseProfile): React.ReactNode => (
      <Link to={`${viewCourses}?course.language=${row.language}`}>
         {LanguageLookup[row.language]}
      </Link>
   );

   const renderInstructorCell = ({
      instructor: { id, firstName, lastName },
   }: ExtendedCourseProfile): React.ReactNode => (
      <Link to={`${viewCourses}?course.created_by=${id}`}>{`${firstName} ${lastName}`}</Link>
   );

   const renderSchoolCell = (row: ExtendedCourseProfile): React.ReactNode => (
      <Link to={`${viewCourses}?school.id=${row.school.id}`}>{row.school.name}</Link>
   );

   const renderModuleCountCell = (row: ExtendedCourseProfile): React.ReactNode => (
      <Link to={modules.replace(':courseId', row.id.toString())}>{row.moduleCount}</Link>
   );

   const renderEnrolledCountCell = (row: ExtendedCourseProfile): React.ReactNode => (
      <Link to={`${viewCourseEnrollments}?course.id=${row.id}`}>{row.enrollmentCount}</Link>
   );

   const renderCreatedOnCell = (row: ExtendedCourseProfile): React.ReactNode =>
      renderTooltipCell(
         moment(row.createdOn).format('MM/DD/YYYY'),
         moment(row.createdOn).fromNow(),
      );

   const renderCourseActionsCell = (
      row: ExtendedCourseProfile,
      onUpdate: (updatedRow: ExtendedCourseProfile) => void,
      onDelete: () => void,
   ): React.ReactNode => <CourseActionsCell row={row} onUpdate={onUpdate} onDelete={onDelete} />;

   const columns: readonly Column<ExtendedCourseProfile>[] = [
      { id: 'course.id', header: 'Id', cell: (i) => i.id, canSort: true },
      {
         id: 'course.name',
         header: 'Name',
         cell: renderCourseNameCell,
         canSort: true,
      },
      {
         id: 'course.language',
         header: 'Language',
         cell: renderLanguageCell,
         canSort: true,
      },
      {
         id: 'course.instructor_name',
         header: 'Instructor',
         cell: renderInstructorCell,
      },
      {
         id: 'school.name',
         header: 'School',
         cell: renderSchoolCell,
         canSort: true,
      },
      {
         id: 'course.module_count',
         header: 'Modules',
         cell: renderModuleCountCell,
         canSort: true,
      },
      {
         id: 'course.module_item_count',
         header: 'Module Items',
         cell: (i) => i.moduleItemCount,
         canSort: true,
      },
      {
         id: 'course.enrollment_count',
         header: 'Enrolled',
         cell: renderEnrolledCountCell,
         canSort: true,
      },
      {
         id: 'course.invited_count',
         header: 'Invited',
         cell: (i) => i.invitedCount,
         canSort: true,
      },
      {
         id: 'course.created_on',
         header: 'Created On',
         cell: renderCreatedOnCell,
         canSort: true,
      },
      { id: 'actions', header: 'Actions', cell: renderCourseActionsCell },
   ];

   return (
      <FilterableTable<ExtendedCourseProfile>
         attributes={attributes}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'course.created_on', desc: true }]}
         pageTitle='Courses'
         searchInputPlaceholder='Search Courses'
         rowsLoader={CourseService.searchCourses}
      />
   );
};

export default Courses;
