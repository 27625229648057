import * as React from 'react';

const mergeRefs =
   <T>(...refs: (React.MutableRefObject<T> | React.LegacyRef<T>)[]): React.RefCallback<T> =>
   (value) => {
      refs.forEach((ref) => {
         if (typeof ref === 'function') {
            ref(value);
            // eslint-disable-next-line eqeqeq
         } else if (ref != null) {
            (ref as React.MutableRefObject<T | null>).current = value;
         }
      });
   };

export { mergeRefs };
