import { BooleanComparison, StringComparison } from './Models';

const BOOLEAN_COMPARISON_OPTIONS = [
   {
      label: 'is true',
      value: BooleanComparison.true,
   },
   {
      label: 'is false',
      value: BooleanComparison.false,
   },
   {
      label: 'is unknown',
      value: BooleanComparison.isUnknown,
   },
   {
      label: 'has any value',
      value: BooleanComparison.hasAnyValue,
   },
];
const STRING_COMPARISON_OPTIONS = [
   {
      label: 'is',
      value: StringComparison.equals,
   },
   {
      label: 'is not',
      value: StringComparison.doesNotEqual,
   },
   {
      label: 'starts with',
      value: StringComparison.startsWith,
   },
   {
      label: 'ends with',
      value: StringComparison.endsWith,
   },
   {
      label: 'contains',
      value: StringComparison.contains,
   },
   {
      label: 'does not contain',
      value: StringComparison.doesNotContain,
   },
   {
      label: 'is unknown',
      value: StringComparison.isUnknown,
   },
   {
      label: 'has any value',
      value: StringComparison.hasAnyValue,
   },
];
const DATE_COMPARISON_OPTIONS = [
   {
      label: 'more than',
      value: 'lt:relative',
      type: 'relative',
   },
   {
      label: 'exactly',
      value: 'eq:relative',
      type: 'relative',
   },
   {
      label: 'less than',
      value: 'gt:relative',
      type: 'relative',
   },
   {
      label: 'after',
      value: 'gt:absolute',
      type: 'absolute',
   },
   {
      label: 'on',
      value: 'eq:absolute',
      type: 'absolute',
   },
   {
      label: 'before',
      value: 'lt:absolute',
      type: 'absolute',
   },
   {
      label: 'is unknown',
      value: 'unknown',
   },
   {
      label: 'has any value',
      value: 'known',
   },
];
const DATETIME_COMPARISON_OPTIONS = [
   {
      label: 'more than',
      value: 'lt:relative',
      type: 'relative',
      direction: 'past',
   },
   {
      label: 'less than',
      value: 'gt:relative',
      type: 'relative',
      direction: 'past',
   },
   {
      label: 'less than',
      value: 'lt:relative',
      type: 'relative',
      direction: 'future',
   },
   {
      label: 'more than',
      value: 'gt:relative',
      type: 'relative',
      direction: 'future',
   },
];
const NUMERIC_COMPARISON_OPTIONS = [
   {
      label: 'greater than',
      value: 'gt',
   },
   {
      label: 'less than',
      value: 'lt',
   },
   {
      label: 'is',
      value: 'eq',
   },
   {
      label: 'is not',
      value: 'ne',
   },
   {
      label: 'is unknown',
      value: 'unknown',
   },
   {
      label: 'has any value',
      value: 'known',
   },
];

export {
   BOOLEAN_COMPARISON_OPTIONS,
   DATE_COMPARISON_OPTIONS,
   DATETIME_COMPARISON_OPTIONS,
   STRING_COMPARISON_OPTIONS,
   NUMERIC_COMPARISON_OPTIONS,
};
