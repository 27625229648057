import * as React from 'react';

interface DocumentTitleProps {
   children: string;
}

const DocumentTitle: React.FC<DocumentTitleProps> = ({ children }) => {
   const initialRender = React.useRef<boolean>(true);
   React.useEffect(() => {
      if (initialRender.current) {
         document.title = `${children} | Lingco Classroom`;
         initialRender.current = false;
      } else {
         const nextTitle = children;
         if (nextTitle !== document.title) {
            document.title = `${nextTitle} ${nextTitle ? '|' : ''} Lingco Classroom`;
         }
      }
      return () => {
         document.title = 'Lingco Classroom';
      };
   }, [children]);

   return null;
};

export default DocumentTitle;
