import {
   NationalExam,
   NationalExamAbbreviation,
   NationalExamLevel,
   NationalExamRegistrationOrder,
   NationalExamReportLinks,
} from '@models/NationalExam';

import {
   NationalExamStudentClassificationTypes,
   NationalExamStudentClassificationWord,
} from '../Constants';
import { Maybe } from '@models/Core';
import AxiosService from './AxiosService';
import HttpService from './HttpService';

interface MakeExamAvailableToUserResponse {
   examAdded: NationalExamAbbreviation;
}

type getExamRegistrationInformationResponse = {
   levels: readonly NationalExamLevel[];
   orderHistory: readonly NationalExamRegistrationOrder[];
   exam: NationalExam;
};

const getStudentClassificationWord = (
   examAbbreviation: NationalExamAbbreviation,
): NationalExamStudentClassificationTypes => {
   if (examAbbreviation) {
      const result =
         NationalExamStudentClassificationWord[
            examAbbreviation.toLowerCase() as NationalExamAbbreviation
         ];
      return result;
   }

   return 'Category';
};

const makeExamAvailableToUser = (
   examNameAbbr: NationalExamAbbreviation,
): Promise<NationalExamAbbreviation> =>
   HttpService.postWithAuthToken<MakeExamAvailableToUserResponse>(
      `/api/national_exams/${examNameAbbr}/make_available`,
   ).then((response) => response.data.examAdded);

const getReportLinks = (examNameAbbr: string): Promise<readonly NationalExamReportLinks[]> =>
   AxiosService.get(`/api/national_exams/${examNameAbbr}/report_links`).then(
      (response) => response.data.reportLinks,
   );

const getExamRegistrationInformation = (
   examUrlSlug: string,
): Promise<getExamRegistrationInformationResponse> =>
   AxiosService.get<getExamRegistrationInformationResponse>(
      `/api/national_exams/${examUrlSlug}`,
   ).then((resp) => resp.data);

const downloadInvoice = (orderId: Maybe<number>): Promise<Blob> =>
   AxiosService.get<Blob>(`/api/national_exams/orders/${orderId}/invoice`, {
      downloadFile: true,
   }).then((response) => response.data);

export default {
   getStudentClassificationWord,
   makeExamAvailableToUser,
   getReportLinks,
   getExamRegistrationInformation,
   downloadInvoice,
};
