import * as _ from 'lodash';
import * as React from 'react';

import Language from '@models/Language';
import { StylesConfig } from 'react-select';

import { Droppable } from './DraggableWords';
import FillBlanksSelect, { DropdownOption, FillBlanksSelectProps } from './FillBlanksSelect';

interface FillBlanksDroppableProps {
   blankId: string;
   className: string;
   disabled?: boolean;
   joinLeft: boolean;
   joinRight: boolean;
   language: Language;
   selectProps: Partial<FillBlanksSelectProps>;
   value: string;
   wordBank: readonly string[];
   selectedResponses: readonly string[];
   renderDraggable(): React.ReactNode;
   onDrop(event: React.DragEvent<HTMLDivElement>): void;
   onValueChange(value: string): void;
}

const FillBlanksDroppable: React.FC<FillBlanksDroppableProps> = ({
   blankId,
   value,
   className,
   joinLeft,
   joinRight,
   disabled,
   language,
   wordBank,
   selectedResponses,
   selectProps,
   renderDraggable,
   onDrop,
   onValueChange,
}) => {
   const fillBlankOptions = [...wordBank];
   // If a response has been selected remove the first instance of it from the array.
   for (let i = 0; i < selectedResponses.length; i++) {
      const elementIndex = fillBlankOptions.indexOf(selectedResponses[i]);

      if (elementIndex !== -1) {
         fillBlankOptions.splice(elementIndex, 1);
      }
   }

   return (
      <Droppable
         className={className}
         disabled={disabled}
         droppableId={blankId}
         value={value}
         data-test={`fill-blanks-droppable-${blankId}`}
         onChange={onValueChange}
         onDrop={onDrop}
      >
         {value ? (
            renderDraggable()
         ) : (
            <FillBlanksSelect
               styles={dropableSelectStyle}
               className={className}
               joinLeft={joinLeft}
               blankId={blankId}
               joinRight={joinRight}
               language={language}
               data-test={`fill-blanks-select-${blankId}`}
               {...selectProps}
               onValueChange={onValueChange}
               options={_.uniq(fillBlankOptions)}
               value={value}
            />
         )}
      </Droppable>
   );
};

export default FillBlanksDroppable;

const dropableSelectStyle: StylesConfig<DropdownOption, false> = {
   container: (styles) => ({
      ...styles,
      minWidth: '60px',
   }),
   control: (styles) => ({
      ...styles,
      minHeight: 'initial',
      border: 'none',
   }),
   indicatorsContainer: (styles) => ({
      ...styles,
   }),
   dropdownIndicator: (styles) => ({
      ...styles,
      padding: '4px',
   }),
   menu: (styles) => ({ ...styles, width: 'auto' }),
   placeholder: (styles) => ({ ...styles }),
   singleValue: (styles) => ({ ...styles }),
   valueContainer: (styles) => ({
      ...styles,
      padding: 'auto',
   }),
};
