import * as _ from 'lodash';

import moment from 'moment';

/**
 * Confirms that a value is a date
 */
const isValidDate = (date: unknown): boolean => moment.isDate(date);

/**
 * Checks if two dates are on the same day
 */
const isSameDay = (a: Date | string, b: Date | string): boolean => {
   const dateA = new Date(a);
   const dateB = new Date(b);

   return (
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
   );
};

/**
 * Recursively converts object values to Date objects
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseDates = (object: any): any => {
   let parsedObject = _.cloneDeep(object);

   if (_.isArray(parsedObject)) {
      return _.map(parsedObject, parseDates);
   }
   if (_.isString(parsedObject)) {
      return parsedObject;
   }
   const ISORegex = /^(\d{4}-\d\d-\d\d([tT][\d:.]*)?)([zZ]|([+-])(\d\d):?(\d\d))?$/;
   parsedObject = _.mapValues(parsedObject, (value) =>
      ISORegex.test(value as string) ? new Date(value as string) : value,
   );

   return _.mapValues(parsedObject, (value) => {
      if (_.isPlainObject(value)) {
         return parseDates(value);
      } else if (_.isArray(value) && _.every(value, _.isObject)) {
         return _.map(value, parseDates);
      }
      return value;
   });
};

/**
 * Recursively converts Date objects to ISO string in an object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const serializeDates = (object: any): any => {
   const serializedObject = _.cloneDeep(object);

   return _.mapValues(serializedObject, (value) => {
      if (_.isDate(value)) {
         return value.toISOString();
      } else if (_.isPlainObject(value)) {
         return serializeDates(value);
      } else if (_.isArray(value)) {
         return value.map(serializeDates);
      }
      return value;
   });
};

/**
 * Shifts date n days
 */
const shiftDate = (date: Date | string | number, numDays: number): Date => {
   const newDate = new Date(date);
   newDate.setDate(newDate.getDate() + numDays);
   return newDate;
};

/**
 * Zeros out hours, minutes, and seconds
 */
const atMidnight = (date: Date): Date => {
   const updatedDate = new Date(date);
   updatedDate.setHours(0);
   updatedDate.setMinutes(0);
   updatedDate.setSeconds(0);

   return updatedDate;
};

export { atMidnight, isSameDay, isValidDate, parseDates, serializeDates, shiftDate };
