/*
   AutoExecutingQueue will ensure that the enqueued functions always run sequentially, never in parallel.
   It has one public method: 'enqueueAndExecute'.  You should pass a function to this method that you
   want 'AutoExecutingQueue' to execute.  If the function performs any async work then it should return
   a promise so we can appropriately wait for it to complete.
*/
type ParameterlessFunction = () => void;
type ParameterlessFunctionReturningPromise = () => Promise<unknown>;
type QueueProcessChangeCallback = (isProcessing: boolean) => void;

export default class AutoExecutingQueue {
   private readonly queue: (ParameterlessFunction | ParameterlessFunctionReturningPromise)[] = [];
   private isExecuting = false;
   private debounceIntervalId: number | null;
   private currentActionId: string | null;
   private readonly debounceDuration: number;
   private readonly queueProcessingChange: QueueProcessChangeCallback;

   constructor(debounceDuration = 0, queueProcessingChange: QueueProcessChangeCallback) {
      this.debounceDuration = debounceDuration;
      this.queueProcessingChange = queueProcessingChange;
      this.debounceIntervalId = null;
      this.currentActionId = null;
   }

   get length(): number {
      let length = this.queue.length;
      if (this.debounceIntervalId) {
         length++;
      }

      return length;
   }

   get isEmpty(): boolean {
      return this.length === 0;
   }

   get isProcessing(): boolean {
      return this.length > 0 || this.isExecuting;
   }

   /**
    * Adds the specified function to the queue for execution
    * and kicks off an execution cycle. Input will be debounced
    * using the duration specified in the constructor.
    * @param  {function} toQueue  work to be performed
    */
   enqueueAndExecute(toQueue: ParameterlessFunction, actionId: string): void {
      this.queueProcessingChange(true);

      // the same queue is used for multiple prompts in a question, so we need to make
      // sure we only debounce for the exact same action.  Once we switch actions, we need
      // to let the last action complete (we shouldn't clear the interval for that action)
      if (actionId === this.currentActionId) {
         if (this.debounceIntervalId) {
            window.clearInterval(this.debounceIntervalId);
         }
      } else {
         this.currentActionId = actionId;
      }

      this.debounceIntervalId = window.setTimeout(() => {
         this.debounceIntervalId = null;

         this.queue.push(toQueue);
         this.executeNext();
      }, this.debounceDuration);
   }

   private executeNext(): void {
      if (this.queue.length === 0 || this.isExecuting) {
         return;
      }

      const toExecute = this.queue.shift();

      this.isExecuting = true;
      this.queueProcessingChange(this.isProcessing);

      const executionResult = toExecute?.();

      // it's a promise we'll wait for it to finish
      if (this.isPromise(executionResult)) {
         executionResult.finally(() => {
            this.executionFinished();
         });
         // otherwise we can be sure it's done by the time we get here
      } else {
         this.executionFinished();
      }
   }

   private isPromise(func: unknown): func is Promise<unknown> {
      return !!func && typeof func === 'object' && 'then' in func && 'finally' in func;
   }

   private executionFinished(): void {
      this.isExecuting = false;
      this.queueProcessingChange(this.isProcessing);
      this.executeNext();
   }
}
