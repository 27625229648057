/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n   mutation UpdateUser($userId: String!, $userInput: UserInput!) {\n      user(userId: $userId, userInput: $userInput) {\n         id\n      }\n   }\n": types.UpdateUserDocument,
    "\n   mutation UpsertOrganization($organizationId: String, $organizationInput: OrganizationInput!) {\n      organization(organizationId: $organizationId, organizationInput: $organizationInput) {\n         id\n      }\n   }\n": types.UpsertOrganizationDocument,
    "\n   mutation UpdateUserPasswordMutation($userId: String!, $passwordInput: UserPasswordUpdateInput!) {\n      userUpdatePassword(userId: $userId, passwordInput: $passwordInput) {\n         ok\n      }\n   }\n": types.UpdateUserPasswordMutationDocument,
    "\n   mutation UpsertInstructorLicense(\n      $instructorLicenseId: String\n      $instructorLicenseInput: InstructorLicenseUpsertInput!\n   ) {\n      instructorLicense(\n         instructorLicenseId: $instructorLicenseId\n         instructorLicenseInput: $instructorLicenseInput\n      ) {\n         ok\n      }\n   }\n": types.UpsertInstructorLicenseDocument,
    "\n   mutation UpsertOrganizationLicense(\n      $organizationLicenseId: String\n      $organizationLicenseInput: OrganizationLicenseUpsertInput!\n   ) {\n      organizationLicense(\n         organizationLicenseId: $organizationLicenseId\n         organizationLicenseInput: $organizationLicenseInput\n      ) {\n         ok\n      }\n   }\n": types.UpsertOrganizationLicenseDocument,
    "\n   fragment UserProfileFeature on Feature {\n      id\n      feature\n   }\n": types.UserProfileFeatureFragmentDoc,
    "\n   fragment OrganizationDomain on OrganizationEmailDomain {\n      id\n      domain\n   }\n": types.OrganizationDomainFragmentDoc,
    "\n   fragment ParentOrganization on Organization {\n      id\n      name\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n": types.ParentOrganizationFragmentDoc,
    "\n   fragment ChildOrganization on Organization {\n      id\n      name\n   }\n": types.ChildOrganizationFragmentDoc,
    "\n   fragment OrganizationProfile on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      domains {\n         ...OrganizationDomain\n      }\n      domainRestricted\n      parent {\n         ...ParentOrganization\n      }\n      children {\n         ...ChildOrganization\n      }\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n": types.OrganizationProfileFragmentDoc,
    "\n   query OrganizationProfile($organizationId: String!) {\n      organization(organizationId: $organizationId) {\n         ...OrganizationProfile\n      }\n   }\n": types.OrganizationProfileDocument,
    "\n   mutation InsertChildOrganization($childId: String!, $parentId: String!) {\n      childOrganization(childId: $childId, parentId: $parentId) {\n         id\n      }\n   }\n": types.InsertChildOrganizationDocument,
    "\n   fragment UserProfileInstructorLicense on InstructorLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      instructorId\n      notes\n      numberOfSeats\n      quickbooksInvoiceId\n   }\n": types.UserProfileInstructorLicenseFragmentDoc,
    "\n   fragment UserProfileOrganizationLicense on OrganizationLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      notes\n      numberOfSeats\n      organizationId\n      quickbooksInvoiceId\n      shouldStudentsPay\n   }\n": types.UserProfileOrganizationLicenseFragmentDoc,
    "\n   fragment UserProfileOrganizationBase on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n": types.UserProfileOrganizationBaseFragmentDoc,
    "\n   fragment UserProfileOrganization on Organization {\n      ...UserProfileOrganizationBase\n      parent {\n         ...UserProfileOrganizationBase\n      }\n   }\n": types.UserProfileOrganizationFragmentDoc,
    "\n   fragment UserProfile on User {\n      id\n      firstName\n      lastName\n      fullName\n      email\n      language\n      accountType\n      profileImageFilename\n      profileImageUrl\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      features {\n         ...UserProfileFeature\n      }\n      organization {\n         ...UserProfileOrganization\n      }\n      licenses {\n         ...UserProfileInstructorLicense\n      }\n   }\n": types.UserProfileFragmentDoc,
    "\n   query UserProfile($userId: String!) {\n      user(userId: $userId) {\n         ...UserProfile\n      }\n   }\n": types.UserProfileDocument,
    "\n   fragment UserReportRowUserLogin on UserLogin {\n      id\n      platform\n      browser\n      browserVersion\n      lastRefreshedOn\n      impersonated\n      impersonatedBy\n   }\n": types.UserReportRowUserLoginFragmentDoc,
    "\n   fragment UserReportRowOrganization on Organization {\n      id\n      name\n   }\n": types.UserReportRowOrganizationFragmentDoc,
    "\n   fragment UserReportRow on User {\n      id\n      firstName\n      lastName\n      email\n      language\n      accountType\n      profileImageFilename\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      logins {\n         ...UserReportRowUserLogin\n      }\n      organizations {\n         ...UserReportRowOrganization\n      }\n   }\n": types.UserReportRowFragmentDoc,
    "\n   query UserReport(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      userReport {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...UserReportRow\n         }\n      }\n   }\n": types.UserReportDocument,
    "\n   fragment UserTypeahead on User {\n      id\n      firstName\n      lastName\n      email\n      profileImageUrl\n   }\n": types.UserTypeaheadFragmentDoc,
    "\n   query UserTypeaheadQuery($searchString: String) {\n      userReport {\n         rows(searchString: $searchString) {\n            ...UserTypeahead\n         }\n      }\n   }\n": types.UserTypeaheadQueryDocument,
    "\n   fragment OrganizationSearchResult on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n   }\n": types.OrganizationSearchResultFragmentDoc,
    "\n   query OrganizationTypeaheadQuery(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      organizationSearch {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...OrganizationSearchResult\n         }\n      }\n   }\n": types.OrganizationTypeaheadQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation UpdateUser($userId: String!, $userInput: UserInput!) {\n      user(userId: $userId, userInput: $userInput) {\n         id\n      }\n   }\n"): (typeof documents)["\n   mutation UpdateUser($userId: String!, $userInput: UserInput!) {\n      user(userId: $userId, userInput: $userInput) {\n         id\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation UpsertOrganization($organizationId: String, $organizationInput: OrganizationInput!) {\n      organization(organizationId: $organizationId, organizationInput: $organizationInput) {\n         id\n      }\n   }\n"): (typeof documents)["\n   mutation UpsertOrganization($organizationId: String, $organizationInput: OrganizationInput!) {\n      organization(organizationId: $organizationId, organizationInput: $organizationInput) {\n         id\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation UpdateUserPasswordMutation($userId: String!, $passwordInput: UserPasswordUpdateInput!) {\n      userUpdatePassword(userId: $userId, passwordInput: $passwordInput) {\n         ok\n      }\n   }\n"): (typeof documents)["\n   mutation UpdateUserPasswordMutation($userId: String!, $passwordInput: UserPasswordUpdateInput!) {\n      userUpdatePassword(userId: $userId, passwordInput: $passwordInput) {\n         ok\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation UpsertInstructorLicense(\n      $instructorLicenseId: String\n      $instructorLicenseInput: InstructorLicenseUpsertInput!\n   ) {\n      instructorLicense(\n         instructorLicenseId: $instructorLicenseId\n         instructorLicenseInput: $instructorLicenseInput\n      ) {\n         ok\n      }\n   }\n"): (typeof documents)["\n   mutation UpsertInstructorLicense(\n      $instructorLicenseId: String\n      $instructorLicenseInput: InstructorLicenseUpsertInput!\n   ) {\n      instructorLicense(\n         instructorLicenseId: $instructorLicenseId\n         instructorLicenseInput: $instructorLicenseInput\n      ) {\n         ok\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation UpsertOrganizationLicense(\n      $organizationLicenseId: String\n      $organizationLicenseInput: OrganizationLicenseUpsertInput!\n   ) {\n      organizationLicense(\n         organizationLicenseId: $organizationLicenseId\n         organizationLicenseInput: $organizationLicenseInput\n      ) {\n         ok\n      }\n   }\n"): (typeof documents)["\n   mutation UpsertOrganizationLicense(\n      $organizationLicenseId: String\n      $organizationLicenseInput: OrganizationLicenseUpsertInput!\n   ) {\n      organizationLicense(\n         organizationLicenseId: $organizationLicenseId\n         organizationLicenseInput: $organizationLicenseInput\n      ) {\n         ok\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfileFeature on Feature {\n      id\n      feature\n   }\n"): (typeof documents)["\n   fragment UserProfileFeature on Feature {\n      id\n      feature\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment OrganizationDomain on OrganizationEmailDomain {\n      id\n      domain\n   }\n"): (typeof documents)["\n   fragment OrganizationDomain on OrganizationEmailDomain {\n      id\n      domain\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment ParentOrganization on Organization {\n      id\n      name\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"): (typeof documents)["\n   fragment ParentOrganization on Organization {\n      id\n      name\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment ChildOrganization on Organization {\n      id\n      name\n   }\n"): (typeof documents)["\n   fragment ChildOrganization on Organization {\n      id\n      name\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment OrganizationProfile on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      domains {\n         ...OrganizationDomain\n      }\n      domainRestricted\n      parent {\n         ...ParentOrganization\n      }\n      children {\n         ...ChildOrganization\n      }\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"): (typeof documents)["\n   fragment OrganizationProfile on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      domains {\n         ...OrganizationDomain\n      }\n      domainRestricted\n      parent {\n         ...ParentOrganization\n      }\n      children {\n         ...ChildOrganization\n      }\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   query OrganizationProfile($organizationId: String!) {\n      organization(organizationId: $organizationId) {\n         ...OrganizationProfile\n      }\n   }\n"): (typeof documents)["\n   query OrganizationProfile($organizationId: String!) {\n      organization(organizationId: $organizationId) {\n         ...OrganizationProfile\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation InsertChildOrganization($childId: String!, $parentId: String!) {\n      childOrganization(childId: $childId, parentId: $parentId) {\n         id\n      }\n   }\n"): (typeof documents)["\n   mutation InsertChildOrganization($childId: String!, $parentId: String!) {\n      childOrganization(childId: $childId, parentId: $parentId) {\n         id\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfileInstructorLicense on InstructorLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      instructorId\n      notes\n      numberOfSeats\n      quickbooksInvoiceId\n   }\n"): (typeof documents)["\n   fragment UserProfileInstructorLicense on InstructorLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      instructorId\n      notes\n      numberOfSeats\n      quickbooksInvoiceId\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfileOrganizationLicense on OrganizationLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      notes\n      numberOfSeats\n      organizationId\n      quickbooksInvoiceId\n      shouldStudentsPay\n   }\n"): (typeof documents)["\n   fragment UserProfileOrganizationLicense on OrganizationLicense {\n      id\n      canManageContentLibrary\n      canManageCourses\n      canViewGlobalLibrary\n      createdBy\n      createdOn\n      expiresOn\n      notes\n      numberOfSeats\n      organizationId\n      quickbooksInvoiceId\n      shouldStudentsPay\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfileOrganizationBase on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"): (typeof documents)["\n   fragment UserProfileOrganizationBase on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n      shortName\n      licenses {\n         ...UserProfileOrganizationLicense\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfileOrganization on Organization {\n      ...UserProfileOrganizationBase\n      parent {\n         ...UserProfileOrganizationBase\n      }\n   }\n"): (typeof documents)["\n   fragment UserProfileOrganization on Organization {\n      ...UserProfileOrganizationBase\n      parent {\n         ...UserProfileOrganizationBase\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserProfile on User {\n      id\n      firstName\n      lastName\n      fullName\n      email\n      language\n      accountType\n      profileImageFilename\n      profileImageUrl\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      features {\n         ...UserProfileFeature\n      }\n      organization {\n         ...UserProfileOrganization\n      }\n      licenses {\n         ...UserProfileInstructorLicense\n      }\n   }\n"): (typeof documents)["\n   fragment UserProfile on User {\n      id\n      firstName\n      lastName\n      fullName\n      email\n      language\n      accountType\n      profileImageFilename\n      profileImageUrl\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      features {\n         ...UserProfileFeature\n      }\n      organization {\n         ...UserProfileOrganization\n      }\n      licenses {\n         ...UserProfileInstructorLicense\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   query UserProfile($userId: String!) {\n      user(userId: $userId) {\n         ...UserProfile\n      }\n   }\n"): (typeof documents)["\n   query UserProfile($userId: String!) {\n      user(userId: $userId) {\n         ...UserProfile\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserReportRowUserLogin on UserLogin {\n      id\n      platform\n      browser\n      browserVersion\n      lastRefreshedOn\n      impersonated\n      impersonatedBy\n   }\n"): (typeof documents)["\n   fragment UserReportRowUserLogin on UserLogin {\n      id\n      platform\n      browser\n      browserVersion\n      lastRefreshedOn\n      impersonated\n      impersonatedBy\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserReportRowOrganization on Organization {\n      id\n      name\n   }\n"): (typeof documents)["\n   fragment UserReportRowOrganization on Organization {\n      id\n      name\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserReportRow on User {\n      id\n      firstName\n      lastName\n      email\n      language\n      accountType\n      profileImageFilename\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      logins {\n         ...UserReportRowUserLogin\n      }\n      organizations {\n         ...UserReportRowOrganization\n      }\n   }\n"): (typeof documents)["\n   fragment UserReportRow on User {\n      id\n      firstName\n      lastName\n      email\n      language\n      accountType\n      profileImageFilename\n      createdOn\n      authenticated\n      authenticatedOn\n      isAdmin\n      isDistrictAdmin\n      demo\n      disabled\n      disableVocabSetTimer\n      stripeCustomerId\n      missingSchoolUrl\n      referralSourceId\n      logins {\n         ...UserReportRowUserLogin\n      }\n      organizations {\n         ...UserReportRowOrganization\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   query UserReport(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      userReport {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...UserReportRow\n         }\n      }\n   }\n"): (typeof documents)["\n   query UserReport(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      userReport {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...UserReportRow\n         }\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment UserTypeahead on User {\n      id\n      firstName\n      lastName\n      email\n      profileImageUrl\n   }\n"): (typeof documents)["\n   fragment UserTypeahead on User {\n      id\n      firstName\n      lastName\n      email\n      profileImageUrl\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   query UserTypeaheadQuery($searchString: String) {\n      userReport {\n         rows(searchString: $searchString) {\n            ...UserTypeahead\n         }\n      }\n   }\n"): (typeof documents)["\n   query UserTypeaheadQuery($searchString: String) {\n      userReport {\n         rows(searchString: $searchString) {\n            ...UserTypeahead\n         }\n      }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   fragment OrganizationSearchResult on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n   }\n"): (typeof documents)["\n   fragment OrganizationSearchResult on Organization {\n      id\n      name\n      city\n      state\n      zipCode\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   query OrganizationTypeaheadQuery(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      organizationSearch {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...OrganizationSearchResult\n         }\n      }\n   }\n"): (typeof documents)["\n   query OrganizationTypeaheadQuery(\n      $currentPageNumber: Int\n      $orderBy: [OrderBy!]!\n      $pageSize: Int\n      $searchString: String\n   ) {\n      organizationSearch {\n         queryResultTotalCount(searchString: $searchString)\n         rows(\n            currentPageNumber: $currentPageNumber\n            orderBy: $orderBy\n            pageSize: $pageSize\n            searchString: $searchString\n         ) {\n            ...OrganizationSearchResult\n         }\n      }\n   }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;