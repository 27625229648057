import * as Bowser from 'bowser';

const bowser = Bowser.getParser(window.navigator.userAgent);
const mobileMaxWidthInPx = 950;

const isMobileSafariOrUIWebView = (): boolean =>
   /(iPhone|iPod|iPad).*AppleWebKit/i.test(window.navigator.userAgent);

const isChrome = (): boolean => {
   // please note,
   // that IE11 now returns undefined again for window.chrome
   // and new Opera 30 outputs true for window.chrome
   // but needs to check if window.opr is not undefined
   // and new IE Edge outputs to true now for window.chrome
   // and if not iOS Chrome check
   // so use the below updated condition
   const isChromium = window.chrome;

   if (isIOSChrome()) {
      // is Google Chrome on IOS
      return true;
   } else if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      window.navigator.vendor === 'Google Inc.' &&
      isOpera() === false &&
      isEdge() === false
   ) {
      // is Google Chrome
      return true;
   }
   return false;
};

const isChromeOSOnDesktopOrLaptop = (): boolean =>
   bowser.getOSName() === 'Chrome OS' && !['mobile', 'tablet'].includes(bowser.getPlatformType());

const isIOSChrome = (): boolean => !!window.navigator.userAgent.match('CriOS');

const isEdge = (): boolean => window.navigator.userAgent.indexOf('Edge') > -1;

const isOpera = (): boolean => typeof window.opr !== 'undefined';

const isSafari = (): boolean =>
   !isChrome() && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isMobileView = (windowWidth: number): boolean => mobileMaxWidthInPx > windowWidth;

const maxLandscapeWidthInPx = 950;
const maxLandscapeHeightInPx = 414;
const isLandscape = (): boolean =>
   window.innerWidth > window.innerHeight &&
   window.innerWidth <= maxLandscapeWidthInPx &&
   window.innerHeight <= maxLandscapeHeightInPx;

const isInternetExplorer = (): boolean =>
   navigator.appName === 'Microsoft Internet Explorer' ||
   !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/));

// this method of determining if something is a touch device is very accurate but expensive
// so we'll cache the result.
let _isTouchDevice: boolean | null = null;
const isTouchDevice = (): boolean => {
   if (_isTouchDevice === null) {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

      const mq = (query: string): boolean => window.matchMedia(query).matches;

      if ('ontouchstart' in window) {
         _isTouchDevice = true;
      } else {
         // include the 'heartz' as a way to have a non matching MQ to help terminate the join
         // https://git.io/vznFH
         const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
         _isTouchDevice = mq(query);
      }
   }
   return _isTouchDevice;
};

// iPad Pros in particular seem to do this by default
const isIOSDeviceConfiguredAsDesktop = (): boolean =>
   bowser.getOSName() === 'macOS' && isTouchDevice();

let _isDesktopOrLaptop: boolean | null = null;
const isDesktopOrLaptop = (): boolean => {
   if (_isDesktopOrLaptop === null) {
      if (isIOSDeviceConfiguredAsDesktop()) {
         _isDesktopOrLaptop = false;
      } else if (isChromeOSOnDesktopOrLaptop()) {
         _isDesktopOrLaptop = true;
      } else {
         _isDesktopOrLaptop = bowser.getPlatformType() === 'desktop';
      }
   }
   return _isDesktopOrLaptop;
};

export {
   isMobileSafariOrUIWebView,
   isSafari,
   isMobileView,
   isLandscape,
   isInternetExplorer,
   isChrome,
   isOpera,
   isDesktopOrLaptop,
};
