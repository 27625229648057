import * as React from 'react';

import { secondsToHHMMSSString } from '@helpers/FormatTime';
import IconVideoControlPause from '@icons/nova-solid/36-Videos/video-control-pause.svg';
import IconVideoControlPlay from '@icons/nova-solid/36-Videos/video-control-play.svg';
import { AnnotationType, AudioAnnotation } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import DateTimeService from '@services/DateTimeService';

import { AppStateContext } from '../../../../AppState';
import Annotation, { NewAudioAnnotation } from './Annotation';

import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';

export enum PlayerState {
   loading = 'loading',
   ready = 'ready',
   paused = 'paused',
   playing = 'playing',
}

interface AudioWaveformProps {
   /** Annotations to render as regions */
   annotations: readonly AudioAnnotation[];
   /** Url of the audio file or the audio elemenbt with the audio */
   audioFileSource?: string;
   /** Can add and edit annotations */
   canEdit: boolean;
   /** Media Element */
   media?: HTMLMediaElement;
   /** Should Waveform be loaded automatically. */
   loadWaveformOnMount?: boolean;
   /** Retrieves annotation author information */
   getAuthor(createdBy: number): Maybe<BasicUserProfile>;
   /** Callback for deleting an exiting annotation */
   onDeleteAnnotation?(id: number): Promise<void>;
   /** Callback for saving or updating an annotation */
   onSaveAnnotation?(
      annotation: NewAudioAnnotation | AudioAnnotation,
   ): Promise<{ id: number } | void>;
   /** Callback for rendering the waveform */
   onWaveformRendered?(): void;
}

export interface AudioWaveformHandles {
   playPause(): void;
   loadWaveform(): void;
}

const REGION_COLOR = 'rgba(0, 124, 187, 0.1)';
const NEW_REGION_PREFIX = 'region-';

const AudioWaveform = React.forwardRef<AudioWaveformHandles, AudioWaveformProps>(
   (
      {
         audioFileSource,
         annotations,
         canEdit,
         media,
         loadWaveformOnMount = true,
         getAuthor,
         onDeleteAnnotation,
         onSaveAnnotation,
         onWaveformRendered,
      }: AudioWaveformProps,
      ref,
   ) => {
      const [activeAnnotationId, setActiveAnnotationId] = React.useState<string | number | null>(
         null,
      );
      const [annotationLeft, setAnnotationLeft] = React.useState<number | null>(null);
      const [position, setPosition] = React.useState<number>(0);
      const [durationInSeconds, setDurationInSeconds] = React.useState<number>(0);
      const [playerState, setPlayerState] = React.useState<Maybe<PlayerState>>(null);
      const [isWaveformRendered, setIsWaveformRendered] = React.useState<boolean>(false);
      const waveSurferRef = React.useRef<Maybe<WaveSurfer>>(null);
      const wsRegionsRef = React.useRef<Maybe<RegionsPlugin>>(null);
      const audioWaveformElement = React.useRef<Maybe<HTMLDivElement>>(null);
      const timelineElement = React.useRef<Maybe<HTMLDivElement>>(null);
      const { userProfile } = React.useContext<AppStateContext>(AppStateContext);
      const [newAnnotation, setNewAnnotation] = React.useState<Maybe<NewAudioAnnotation>>(null);

      const playPause = (): void => {
         waveSurferRef.current?.playPause();
      };

      const clearAnnotation = (): void => {
         setNewAnnotation(null);
         setActiveAnnotationId(null);
         setAnnotationLeft(null);
      };

      const handleRegionPlay = (region: Region): void => {
         const wavesurfer = waveSurferRef.current;

         if (!wavesurfer) return;

         const checkRegionEnd = () => {
            if (wavesurfer.getCurrentTime() >= region.end) {
               wavesurfer.pause();
               wavesurfer?.setTime(region.start);
               wavesurfer.un('audioprocess', checkRegionEnd); // Remove the listener
            }
         };

         // Attach the listener to check the playback position
         wavesurfer.on('audioprocess', checkRegionEnd);
      };

      const loadWaveform = () => {
         if (
            (audioFileSource || media) &&
            audioWaveformElement.current &&
            timelineElement.current &&
            playerState !== PlayerState.loading
         ) {
            const waveSurfer = WaveSurfer.create({
               autoCenter: true,
               barWidth: 3,
               container: audioWaveformElement.current,
               cursorColor: '#007CBB',
               progressColor: '#007CBB',
               waveColor: '#E7E7E7',
               backend: 'MediaElement',
               media,
               plugins: [
                  TimelinePlugin.create({
                     container: timelineElement.current,
                  }),
               ],
            });
            if (audioFileSource && !media) {
               waveSurfer.load(audioFileSource);
            }
            const wsRegions: RegionsPlugin = waveSurfer.registerPlugin(RegionsPlugin.create());
            if (canEdit) {
               wsRegions.enableDragSelection({
                  color: REGION_COLOR,
               });
            }
            waveSurfer.on('play', () => {
               setPlayerState(PlayerState.playing);
            });
            waveSurfer.on('pause', () => {
               setPlayerState(PlayerState.paused);
            });
            waveSurfer.on('load', () => {
               setPlayerState(PlayerState.loading);
            });
            waveSurfer.on('ready', () => {
               setDurationInSeconds(waveSurfer.getDuration());
               setIsWaveformRendered(true);
               onWaveformRendered?.();
            });
            waveSurfer.on('timeupdate', (updatedPosition: number) => {
               setPosition(updatedPosition);
            });
            waveSurfer.on('interaction', (updatedPosition: number) => {
               setPosition(updatedPosition);
            });
            waveSurfer.on('finish', () => {
               setPlayerState(PlayerState.ready);
            });
            waveSurfer.on('click', () => {
               clearAnnotation();
            });
            wsRegions.on('region-created', (region: Region) => {
               // Only care about regions created by the user
               if (region.id.startsWith(NEW_REGION_PREFIX)) {
                  setNewAnnotation({
                     id: region.id,
                     start: region.start,
                     end: region.end,
                     createdBy: userProfile?.id,
                     annotationType: AnnotationType.audio,
                     comments: '',
                     createdOn: DateTimeService.now(),
                     audioUrl: '',
                     storedAudioFilename: '',
                  });
                  setAnnotationLeft(
                     region.element.offsetLeft - (region.element?.parentElement?.scrollLeft ?? 0),
                  );
                  wsRegions.getRegions().forEach((i) => {
                     if (i.id !== region.id && i.id.startsWith(NEW_REGION_PREFIX)) {
                        i.remove();
                     }
                  });
               }
               region.on('play', () => handleRegionPlay(region));
            });
            wsRegions.on('region-updated', (region: Region) => {
               if (!region.id.startsWith(NEW_REGION_PREFIX)) {
                  const annotation = annotations.find((i) => i.id === Number(region.id));
                  if (annotation) {
                     onSaveAnnotation?.({
                        ...annotation,
                        start: Number(region.start.toFixed(3)),
                        end: Number(region.end.toFixed(3)),
                     });
                  }
               }
               setAnnotationLeft(
                  region.element.offsetLeft - (region.element?.parentElement?.scrollLeft ?? 0),
               );
            });
            wsRegions.on('region-clicked', (region, event) => {
               event.stopPropagation();
               if (!region.id.startsWith(NEW_REGION_PREFIX)) {
                  setActiveAnnotationId(region.id);
               }
            });
            wsRegions.on('region-double-clicked', (region) => {
               region.play();
            });

            waveSurferRef.current = waveSurfer;
            wsRegionsRef.current = wsRegions;
         }
      };

      React.useImperativeHandle(ref, () => ({
         playPause,
         loadWaveform,
      }));

      React.useEffect(() => {
         if (!newAnnotation) {
            wsRegionsRef.current?.getRegions().forEach((i) => {
               if (i.id.startsWith(NEW_REGION_PREFIX)) {
                  i.remove();
               }
            });
         }
      }, [newAnnotation]);

      const clearNewAnnotation = () => {
         setNewAnnotation(null);
      };

      React.useEffect(() => {
         if (loadWaveformOnMount) {
            loadWaveform();
         }
      }, [audioFileSource, media, canEdit, loadWaveformOnMount]);

      React.useEffect(() => {
         if (!wsRegionsRef.current) {
            return;
         }
         if (activeAnnotationId) {
            const region = wsRegionsRef.current
               .getRegions()
               .find((i) => i.id === activeAnnotationId);
            if (region) {
               setAnnotationLeft(
                  region.element.offsetLeft - (region.element?.parentElement?.scrollLeft ?? 0),
               );
            }
         } else {
            setAnnotationLeft(null);
         }
      }, [activeAnnotationId]);

      React.useEffect(() => {
         if (!wsRegionsRef.current || !isWaveformRendered) {
            return;
         }
         const regions = wsRegionsRef.current.getRegions();
         const currentRegionIds = regions.map((i) => i.id);
         const annotationIds = annotations.map((a) => a.id.toString());

         // Add regions for annotations
         annotations.forEach((annotation) => {
            if (!currentRegionIds.includes(annotation.id.toString())) {
               wsRegionsRef.current?.addRegion({
                  id: annotation.id.toString(),
                  start: annotation.start,
                  end: annotation.end,
                  color: REGION_COLOR,
               });
            }
         });

         // Remove regions not present in annotations
         currentRegionIds.forEach((regionId) => {
            if (!annotationIds.includes(regionId) && !regionId.startsWith(NEW_REGION_PREFIX)) {
               const region = regions.find((i) => i.id === regionId);
               region?.remove();
            }
         });
      }, [annotations, isWaveformRendered]);

      const saveAnnotation = (annotation: AudioAnnotation) => {
         const oldRegion = wsRegionsRef.current
            ?.getRegions()
            .find((i) => i.id === annotation.id.toString());
         if (typeof annotation.id === 'number') {
            onSaveAnnotation?.(annotation);
         } else {
            onSaveAnnotation?.({ ...annotation, id: null }).then((response) => {
               if (oldRegion && waveSurferRef.current && response && response?.id) {
                  oldRegion.remove();
                  setActiveAnnotationId(response.id);
                  setNewAnnotation(null);
               }
            });
         }
      };

      const deleteAnnotation = (annotation: AudioAnnotation | NewAudioAnnotation) => {
         if (!waveSurferRef.current || typeof annotation.id !== 'number') {
            return;
         }
         // const region = waveSurferRef.current.regions.list[annotation.id];
         // region?.remove();
         onDeleteAnnotation?.(annotation.id);
         if (activeAnnotationId === annotation.id) {
            setActiveAnnotationId(null);
            setAnnotationLeft(null);
         }
      };

      const ControlIcon =
         playerState === PlayerState.playing ? IconVideoControlPause : IconVideoControlPlay;

      const activeAnnotation = newAnnotation
         ? newAnnotation
         : annotations.find((i) => i.id === Number(activeAnnotationId));

      return (
         <div className='audio-waveform'>
            <div className='player-header'>
               <div className='player-controls'>
                  <ControlIcon onClick={playPause} />
                  <span className='position'>{secondsToHHMMSSString(position)}</span>
                  <span className='divider'>/</span>
                  <span className='duration'>{secondsToHHMMSSString(durationInSeconds)}</span>
               </div>
            </div>
            <div
               className='timeline-container'
               ref={(c) => {
                  timelineElement.current = c;
               }}
            />
            <div
               className='wavesurfer-container'
               ref={(c) => {
                  audioWaveformElement.current = c;
               }}
            />
            {activeAnnotation && annotationLeft !== null && (
               <Annotation
                  annotation={activeAnnotation}
                  canEdit={canEdit}
                  isCreating={!!newAnnotation}
                  left={annotationLeft}
                  getAuthor={getAuthor}
                  onSave={saveAnnotation}
                  onCancel={() => clearNewAnnotation()}
                  onDelete={() => deleteAnnotation(activeAnnotation)}
                  onClearAnnotation={clearAnnotation}
               />
            )}
         </div>
      );
   },
);

export default AudioWaveform;
