import * as React from 'react';

import IconAlarmClock from '@icons/nova-solid/05-Time/alarm-clock.svg';
import Mousetrap from 'mousetrap';
import pluralize from 'pluralize';

import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';

interface TimeLimitScreenProps {
   timeLimit: number;
   onStart(event: React.MouseEvent | Mousetrap.ExtendedKeyboardEvent): void;
}

const TimeLimitScreen: React.FC<TimeLimitScreenProps> = ({ timeLimit, onStart }) => (
   <EmptyState
      className='activity-height'
      icon={<IconAlarmClock className='large' aria-hidden />}
      heading='Timed Assignment'
      description={
         <p>
            This Assignment has a time limit of{' '}
            <strong>
               {timeLimit} {pluralize('minute', timeLimit)}
            </strong>
            .
         </p>
      }
      primaryAction={<Button onClick={onStart}>Start</Button>}
   />
);

export default React.memo(TimeLimitScreen);
