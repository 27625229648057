import * as _ from 'lodash';
import * as React from 'react';

import ContentIcon from '@components/ContentIcon';
import { VocabSetItemDisplay } from '@models/Activity';
import Appearance from '@models/Appearance';
import Content, { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';
import VocabSetService from '@services/VocabSetService';
import { useNavigate } from 'react-router-dom';

import Constants from '../../../../Constants';
import Link from '@components/Common/Link';
import ModalDialog from '@components/Core/ModalDialog';
import InlineVocabSet from './InlineVocabSet';

interface VocabSetItemProps {
   vocabSetId: Maybe<number>;
   display: VocabSetItemDisplay;
}

const VocabSetItem: React.FC<VocabSetItemProps> = ({ vocabSetId, display }) => {
   const {
      routes: {
         content: { viewVocabSet },
      },
   } = Constants;

   const navigate = useNavigate();

   const [viewContentModalOpen, setViewContentModalOpen] = React.useState<boolean>(false);
   const [settings, setSettings] = React.useState<Maybe<Content>>(null);
   const [progress, setProgress] = React.useState<Record<number, IVocabTermProgress>>({});
   const [terms, setTerms] = React.useState<readonly IVocabTerm[]>([]);

   if (!vocabSetId) {
      return null;
   }

   const launchVocabSet = (): void => {
      if (display === VocabSetItemDisplay.overlay) {
         setViewContentModalOpen((prevViewContentModalOpen) => !prevViewContentModalOpen);
      } else if (display === VocabSetItemDisplay.link) {
         navigate(viewVocabSet.replace(':vocabSetId', vocabSetId.toString()));
      }
   };

   React.useEffect(() => {
      if (vocabSetId === null) {
         return;
      }
      VocabSetService.getWithProgress(vocabSetId).then((response) => {
         const {
            terms: responseTerms,
            canEdit: responseCanEdit,
            progress: responseProgress,
            ...responseSettings
         } = response;
         setTerms(_.sortBy(responseTerms, 'index'));
         setProgress(_.keyBy(responseProgress, 'id'));
         setSettings(responseSettings);
      });
   }, [vocabSetId]);

   if (!terms || !settings) {
      return null;
   }

   return (
      <div className='activity-builder-row'>
         {display === VocabSetItemDisplay.inline ? (
            <InlineVocabSet
               terms={terms}
               name={settings.name}
               language={settings.language}
               vocabSetId={settings.id}
               progress={progress}
            />
         ) : (
            <>
               <div className='activity-builder-lesson'>
                  <div className='content-icon-wrapper'>
                     <div className='activity-item-icon' role='button' onClick={launchVocabSet}>
                        <ContentIcon itemType={ContentType.vocabSet} line />
                     </div>
                  </div>
                  {display === VocabSetItemDisplay.link && (
                     <Link
                        className='vocab-set-name'
                        to={viewVocabSet.replace(':vocabSetId', vocabSetId.toString())}
                     >
                        {settings.name}
                     </Link>
                  )}
                  {display === VocabSetItemDisplay.overlay && (
                     <p onClick={launchVocabSet} className='vocab-set-name'>
                        {settings.name}
                     </p>
                  )}
               </div>
               {viewContentModalOpen && (
                  <ModalDialog
                     appearance={Appearance.primary}
                     heading={settings.name}
                     width='xl'
                     onClose={launchVocabSet}
                     bodyClassName='vocab-set-content-modal'
                  >
                     <InlineVocabSet
                        terms={terms}
                        language={settings.language}
                        progress={progress}
                        vocabSetId={settings.id}
                     />
                  </ModalDialog>
               )}
            </>
         )}
      </div>
   );
};

export default VocabSetItem;
