import * as React from 'react';

import { Tab, Tabs } from '@components/Core/Tabs';
import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import { IdName } from '@models/Core';
import {
   ProficiencyLevelName,
   ProficiencyReportTileBreakdownBySkill,
   SchoolProficiencyReport,
   SchoolProficiencySummaryRow,
} from '@models/Proficiency';
import ProficiencyReportService from '@services/ProficiencyReportService';
import { useParams } from 'react-router-dom';

import { AppStateContext } from '../../../AppState';
import Constants from '../../../Constants';
import CanDoStatementSummary from '@components/ProficiencyTracking/CanDoStatementSummary';
import ProficiencyReportHeader from '@components/ProficiencyTracking/ProficiencyReportHeader';
import {
   buildDistrictReportBreadcrumbs,
   districtReportLinkReplace,
   getProficiencyReportTileData,
} from '@components/ProficiencyTracking/ProficiencyReportHelpers';
import ProficiencyReportOverviewTiles from '@components/ProficiencyTracking/ProficiencyReportOverviewTiles';
import ProficiencyReportTable from '@components/ProficiencyTracking/ProficiencyReportTable';

type Params = {
   standardCourseId: string;
   organizationId: string;
};

type TabNames = 'school-proficiency-summary' | 'can-do-statement-summary';

const SchoolProficiencySummary: React.FC = () => {
   const { setBreadcrumbs } = React.useContext<AppStateContext>(AppStateContext);
   const params = useParams<Params>();
   if (!params.standardCourseId || !params.organizationId) {
      return null;
   }
   const standardCourseId = params.standardCourseId;
   const organizationId = params.organizationId;

   // Component State
   const [isInitialLoad, setIsInitialLoad] = React.useState<boolean>(true);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [hasError, setHasError] = React.useState<boolean>(false);
   const [currentTab, setCurrentTab] = React.useState<TabNames>('school-proficiency-summary');

   // Filters
   const [selectedLevel, setSelectedLevel] = React.useState<ProficiencyLevelName | undefined>(
      undefined,
   );

   // Data
   const [schoolReport, setSchoolReport] = React.useState<SchoolProficiencyReport | undefined>(
      undefined,
   );
   const [skillBreakdown, setSkillBreakdown] = React.useState<
      ProficiencyReportTileBreakdownBySkill | undefined
   >(undefined);

   const fetchSchoolSummary = async () => {
      try {
         setHasError(false);
         setIsLoading(true);

         const result = await ProficiencyReportService.getSchoolSummary(
            standardCourseId,
            organizationId,
            selectedLevel,
         );

         setSchoolReport(result);
         setSkillBreakdown(
            getProficiencyReportTileData<SchoolProficiencySummaryRow>(
               result.courseCanDoStatementSummaries,
               result.courseEndDate,
               result.courseStartDate,
               result.schoolSummary,
            ),
         );

         await buildBreadcrumbs();
      } catch (error) {
         console.error(error);
         setHasError(true);
      } finally {
         setIsLoading(false);
         setIsInitialLoad(false);
      }
   };

   const buildBreadcrumbs = async () => {
      const breadcrumbs = await buildDistrictReportBreadcrumbs({
         standardCourseId: params.standardCourseId,
         organizationId: params.organizationId,
      });

      setBreadcrumbs({
         breadcrumbs,
         prev: null,
         next: null,
      });
   };

   const linkBuilder = (row: IdName) =>
      districtReportLinkReplace(Constants.routes.proficiencyTracking.instructorSummary, {
         standardCourseId: params.standardCourseId,
         organizationId: params.organizationId,
         instructorId: row.id.toString(),
      });

   React.useEffect(() => {
      fetchSchoolSummary();
   }, [selectedLevel]);

   if (isInitialLoad) {
      return <Loader />;
   }

   if (hasError || schoolReport === undefined || skillBreakdown === undefined) {
      return <ErrorDisplay />;
   }

   return (
      <div className='padding-left-l padding-right-m'>
         <ProficiencyReportHeader
            reportName={schoolReport.reportName}
            proficiencyLevelChanged={setSelectedLevel}
         />
         {isLoading ? (
            <Loader />
         ) : (
            <>
               <Tabs
                  className='table-toggle'
                  defaultTab={currentTab}
                  onTabChange={(tabName) => setCurrentTab(tabName as TabNames)}
               >
                  <Tab
                     id='school-proficiency-summary'
                     className='table-toggle-tab'
                     heading='Instructors'
                     key='school-proficiency-summary'
                     data-test='school-proficiency-summary'
                  >
                     <ProficiencyReportOverviewTiles skillBreakdown={skillBreakdown} />
                     <ProficiencyReportTable<SchoolProficiencySummaryRow, IdName>
                        data={schoolReport.schoolSummary}
                        data-test='school-proficiency-summary'
                        groupById='instructorId'
                        linkBuilder={linkBuilder}
                        rows={schoolReport.instructorsInProficiencyCourse}
                        skillBreakdown={skillBreakdown}
                     />
                  </Tab>
                  <Tab
                     id='can-do-statement-summary'
                     className='table-toggle-tab'
                     heading='Can-Do Statements'
                     key='can-do-statement-summary'
                     data-test='can-do-statement-summary'
                  >
                     <CanDoStatementSummary
                        proficiencyLevelSelected={selectedLevel}
                        schoolId={organizationId}
                        standardCourseId={standardCourseId}
                     />
                  </Tab>
               </Tabs>
            </>
         )}
      </div>
   );
};

export default SchoolProficiencySummary;
