import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';
import ModalDialog from '@components/Core/ModalDialog';
import InfoTooltip from '@components/InfoTooltip';
import Loader from '@components/Loader';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { CourseLMSConnection, CourseMode, CourseSection } from '@models/Course';
import ILMSCourse from '@models/ILMSCourse';
import LMSName from '@models/LMSName';
import DateTimeService from '@services/DateTimeService';

import Color from '../../../types/Color';

interface ManageLMSConnectionsModalProps {
   alreadyConnectedLMSCourseIds?: readonly (string | number)[];
   lmsConnections: readonly CourseLMSConnection[];
   lmsCourses: readonly ILMSCourse[];
   lmsDisplayName: string;
   loadingLmsCourses: boolean;
   lookupKey: 'lmsCourseId' | 'lmsSectionId';
   sections: readonly CourseSection<CourseMode.edit>[];
   shouldAllowLtiAppInstallation?: boolean;
   addConnection(key: string): void;
   installLtiTool?(lmsConnection: CourseLMSConnection): Promise<void>;
   isInstallingLtiTool?(lmsCourseId: string): boolean;
   onClose(): void;
   onSave(): void;
   removeConnection(key: string): void;
   updateConnectionSection(key: string, sectionId: string | number): void;
}

const ManageLMSConnectionsModal: React.FC<ManageLMSConnectionsModalProps> = ({
   alreadyConnectedLMSCourseIds = [],
   lmsConnections,
   lmsCourses,
   lmsDisplayName,
   loadingLmsCourses,
   lookupKey,
   sections,
   shouldAllowLtiAppInstallation,
   addConnection,
   installLtiTool,
   isInstallingLtiTool,
   onClose,
   onSave,
   removeConnection,
   updateConnectionSection,
}) => {
   const sortedLMSCourses = _.orderBy(lmsCourses, ['endDate'], ['desc']);

   const connectedLmsIds = lmsConnections.map((i) => i[lookupKey]);
   const isCanvas = lmsConnections.every((i) => i.lmsName === LMSName.canvas);
   const connectedSectionIds = lmsConnections.map((i) => i.sectionId).filter((i) => !!i);
   const [searchQuery, setSearchQuery] = React.useState<string>('');

   const getSections = (
      sectionId: Maybe<string | number>,
   ): readonly CourseSection<CourseMode.edit>[] =>
      _.sortBy(
         sections.filter((i) => !connectedSectionIds.includes(i.id) || i.id === sectionId),
         ['id'],
      );

   const getTerm = (lmsCourseId: string): string => {
      const matchingCourses = sortedLMSCourses.filter((x) => x.lmsCourseId === lmsCourseId);

      if (matchingCourses.length > 0) {
         return matchingCourses[0].lmsCourseTerm || '';
      }

      return '';
   };

   const handleSectionChange = (key: string, event: React.ChangeEvent<HTMLSelectElement>): void => {
      const value = event.target.value;
      let sectionId: Maybe<string | number> = null;
      if (value) {
         sectionId = value.startsWith('temp-') ? value : Number(value);
      }
      if (sectionId) {
         updateConnectionSection(key, sectionId);
      }
   };

   const hasLtiPermissionError = (connection: CourseLMSConnection) =>
      connection.ltiErrors !== undefined;

   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchQuery(event.target.value);
   };

   const filteredLMSCourses = React.useMemo(
      () =>
         sortedLMSCourses
            .filter((i) => !connectedLmsIds.includes(i[lookupKey] as string))
            .filter((i) => i.lmsCourseName.toLowerCase().includes(searchQuery.toLowerCase()))
            .filter((i) => !i.endDate || i.endDate > DateTimeService.now()),
      [sortedLMSCourses, connectedLmsIds, searchQuery],
   );

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading='Manage Classes'
         bodyClassName='manage-lms-connections-modal'
         footerClassName='card-footer'
         actions={[
            { text: 'Save', onClick: onSave },
            { text: 'Cancel', onClick: onClose },
         ]}
         width='large'
      >
         {loadingLmsCourses ? (
            <Loader />
         ) : (
            <>
               <div className='row margin-top-s'>
                  <div className='col-xs-8 header-wrapper'>
                     <div className='title'>Available Classes to Add</div>
                  </div>
                  <div className='col-xs-4'>
                     <input
                        type='search'
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder={`Search ${lmsDisplayName} Courses`}
                     />
                  </div>
               </div>
               {filteredLMSCourses.length > 0 ? (
                  <>
                     <div className='row table-row head-row margin-top-s'>
                        <div className='col-xs-8'>{`${lmsDisplayName} Course (Term)`}</div>
                        <div className='col-xs-4'>Actions</div>
                     </div>
                     <div className='table-row-body'>
                        {filteredLMSCourses.map((i) => (
                           <div className='table-row row' key={i[lookupKey]}>
                              <div className='col-xs-8'>
                                 {i.lmsCourseName} ({i.lmsCourseTerm})
                              </div>
                              <div className='col-xs-4'>
                                 {alreadyConnectedLMSCourseIds.includes(i.lmsCourseId) ? (
                                    <span className='gray-text strong-text'>
                                       Connected in another course
                                    </span>
                                 ) : (
                                    <Button
                                       subtle
                                       className='connect'
                                       onClick={() => addConnection(i[lookupKey] as string)}
                                    >
                                       Connect
                                    </Button>
                                 )}
                              </div>
                           </div>
                        ))}
                     </div>
                  </>
               ) : (
                  <EmptyState icon={<IconBinoculars aria-hidden />} heading='No Results' />
               )}
               {!!connectedLmsIds.length && (
                  <div className='margin-top-m'>
                     <div className='header-wrapper margin-bottom-s'>
                        <div className='title'>Connected Classes</div>
                     </div>
                     <div className='row table-row head-row'>
                        <div className={`col-xs-${sections.length ? '4' : '8'}`}>
                           {`${lmsDisplayName} Course (Term)`}
                        </div>
                        {!!sections.length && (
                           <div className='col-xs-4'>
                              <div className='inline-flex'>
                                 Lingco Section
                                 <InfoTooltip>
                                    {`Students imported from ${lmsDisplayName} will be enrolled in this section.`}
                                 </InfoTooltip>
                              </div>
                           </div>
                        )}
                        {isCanvas && shouldAllowLtiAppInstallation && (
                           <div className='col-xs-2'>
                              <div className='flex'>
                                 LTI Tool
                                 <InfoTooltip>
                                    Adds Lingco Login button to course sidebar
                                 </InfoTooltip>
                              </div>
                           </div>
                        )}
                        <div className={isCanvas ? 'col-xs-2' : 'col-xs-4'}>Actions</div>
                     </div>
                     <div className='table-row-body'>
                        {lmsConnections.map((i) => (
                           <div className='table-row row' key={i[lookupKey]}>
                              <div
                                 className={`col-xs-${
                                    sections.length ? '4' : '8'
                                 } flex items-center`}
                              >
                                 {i.lmsCourseName} ({getTerm(i.lmsCourseId)})
                              </div>
                              {!!sections.length && (
                                 <div className='col-xs-4 flex items-center'>
                                    <select
                                       value={i.sectionId === null ? '' : i.sectionId}
                                       onChange={(e) =>
                                          handleSectionChange(i[lookupKey] as string, e)
                                       }
                                    >
                                       <option value=''>None</option>
                                       {getSections(i.sectionId).map((j) => (
                                          <option key={j.id} value={j.id}>
                                             {j.name}
                                          </option>
                                       ))}
                                    </select>
                                 </div>
                              )}
                              {isCanvas && shouldAllowLtiAppInstallation && (
                                 <div className='col-xs-2 flex items-center'>
                                    {i.ltiConfigured ? (
                                       <IconRadioTick className='icon-green' />
                                    ) : (
                                       installLtiTool &&
                                       isInstallingLtiTool && (
                                          <Button
                                             subtle
                                             color={Color.blue}
                                             disabled={hasLtiPermissionError(i)}
                                             tooltip={
                                                hasLtiPermissionError(i)
                                                   ? 'Insufficient permissions'
                                                   : undefined
                                             }
                                             onClick={() => installLtiTool(i)}
                                             loading={isInstallingLtiTool(i.lmsCourseId)}
                                          >
                                             Install
                                          </Button>
                                       )
                                    )}
                                 </div>
                              )}
                              <div
                                 className={`${
                                    isCanvas ? 'col-xs-2' : 'col-xs-4'
                                 } flex items-center`}
                              >
                                 <Button
                                    subtle
                                    className='disconnect'
                                    onClick={() => removeConnection(i[lookupKey] as string)}
                                 >
                                    Disconnect
                                 </Button>
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               )}
            </>
         )}
      </ModalDialog>
   );
};

export default ManageLMSConnectionsModal;
