import * as React from 'react';

import { Maybe } from '@models/Core';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
   to: Maybe<string>;
   className?: string;
   disabled?: boolean;
   external?: boolean;
   children?: React.ReactNode;
   target?: string;
   rel?: string;
}

const Link: React.FC<LinkProps> = ({
   disabled,
   className: propsClassName,
   children,
   to,
   external,
   download,
   ...attr
}) => {
   const className = classNames(propsClassName, { disabled });
   if (!to) {
      return <a className={className}>{children}</a>;
   }
   return external ? (
      <a href={disabled ? undefined : to} {...{ target: '_blank', ...attr }} className={className}>
         {children}
      </a>
   ) : (
      <RouterLink className={className} to={disabled ? '' : to} {...attr}>
         {children}
      </RouterLink>
   );
};

export default Link;
