import * as React from 'react';

import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { OperatorType } from './Models';

interface LogicModeSelectProps {
   className?: string;
   disabled?: boolean;
   selected: OperatorType;
   tooltip?: string;
   onMouseEnter?(event: React.MouseEvent<HTMLSpanElement>): void;
   onMouseLeave?(event: React.MouseEvent<HTMLSpanElement>): void;
   onSelect(mode: OperatorType): void;
}

const LogicModeSelect: React.FC<LogicModeSelectProps> = ({
   className,
   disabled = false,
   selected,
   tooltip,
   onMouseEnter,
   onMouseLeave,
   onSelect,
}) => {
   const options = [
      { text: 'and', value: OperatorType.and },
      { text: 'or', value: OperatorType.or },
   ];

   const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      onSelect(event.target.value as OperatorType);
   };

   return (
      <Tippy content={tooltip} disabled={!tooltip}>
         <select
            className={classnames('connection-switcher', className)}
            disabled={disabled}
            onChange={handleChange}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            value={selected}
         >
            {options.map((i) => (
               <option value={i.value} key={i.value}>
                  {i.text}
               </option>
            ))}
         </select>
      </Tippy>
   );
};

export default LogicModeSelect;
