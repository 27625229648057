import * as React from 'react';

import useMousetrap from '@hooks/use-mousetrap';
import useSubscription from '@hooks/use-subscription';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import { IImageChoice } from '@components/VocabSession/Models';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';
import { MultipleChoiceEvaluation } from './Card';
import MultipleChoiceImageOption from './MultipleChoiceImageOption';

interface MultipleChoiceImageOptionsProps {
   isAnswered: boolean;
   isReady: boolean;
   isTimedOut: boolean;
   isSpeedSession: boolean;
   choices: readonly IImageChoice[];
   check(id: Maybe<number>): MultipleChoiceEvaluation;
   playAudio(signal?: AbortSignal): Promise<Event>;
   onReadyToTransition(): void;
}

const MultipleChoiceImageOptions: React.FC<MultipleChoiceImageOptionsProps> = ({
   isAnswered,
   isReady,
   isSpeedSession,
   isTimedOut,
   check,
   choices,
   playAudio,
   onReadyToTransition,
}) => {
   const {
      emitter,
      state: { isMuted },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const [evaluation, setEvaluation] = React.useState<MultipleChoiceEvaluation>({
      correct: null,
      incorrect: null,
      missed: null,
   });

   const [showMissed, setShowMissed] = React.useState<boolean>(false);

   const transitionTimeoutRef = React.useRef<Maybe<NodeJS.Timeout>>(null);
   const playAudioController = React.useRef<Maybe<AbortController>>(null);
   const showMissedTimeoutRef = React.useRef<Maybe<NodeJS.Timeout>>(null);

   const SHOW_MISSED_DELAY = 1000;
   const TRANSITION_DELAY = Math.floor((isMuted ? 1600 : 800) * (isSpeedSession ? 0.75 : 1.0));

   React.useEffect(() => {
      if (!isAnswered) {
         setEvaluation({
            correct: null,
            incorrect: null,
            missed: null,
         });
         setShowMissed(false);
      }
   }, [isAnswered]);

   React.useEffect(() => {
      if (isTimedOut) {
         checkAnswer(null);
      }
   }, [isTimedOut]);

   React.useEffect(() => {
      if (isAnswered && (evaluation.correct || showMissed)) {
         playAudioController.current = new AbortController();
         playAudio(playAudioController.current.signal)
            .then(() => {
               if (!isTimedOut || isSpeedSession) {
                  transitionTimeoutRef.current = setTimeout(() => {
                     onReadyToTransition();
                  }, TRANSITION_DELAY);
               }
            })
            .catch((error) => {
               if (error.name === 'AbortError') {
                  return;
               }
            });
      } else if (evaluation.missed && !showMissed && isAnswered) {
         showMissedTimeoutRef.current = setTimeout(() => setShowMissed(true), SHOW_MISSED_DELAY);
      }

      return () => {
         showMissedTimeoutRef.current && clearTimeout(showMissedTimeoutRef.current);
         transitionTimeoutRef.current && clearTimeout(transitionTimeoutRef.current);
      };
   }, [evaluation, showMissed, isAnswered, isTimedOut]);

   const handleNextButtonClick = (): void => {
      if (isAnswered && isReady) {
         if (playAudioController.current) {
            playAudioController.current?.abort();
            if (transitionTimeoutRef.current) {
               clearTimeout(transitionTimeoutRef.current);
               transitionTimeoutRef.current = null;
            }
            onReadyToTransition();
         }
      }
   };

   const checkAnswer = (id: Maybe<number>): void => {
      if (!isAnswered && isReady) {
         setEvaluation(check(id));
      }
   };

   useSubscription(emitter, 'NEXT_BUTTON_CLICK', handleNextButtonClick);
   useMousetrap('enter', handleNextButtonClick);

   const groupSize = 2;

   const rows = choices
      .map((_, i) => (i % groupSize ? null : choices.slice(i, i + groupSize)))
      .filter((i): i is IImageChoice[] => i !== null);

   return (
      <div className='session-answer-choices image'>
         {rows.map((cols, i) => (
            <div
               className={classnames('row', {
                  'margin-bottom-s': i < rows.length - 1,
               })}
               key={i}
            >
               {cols.map(({ id: choiceId, image }) => (
                  <div className='session-image-choice' key={choiceId}>
                     <MultipleChoiceImageOption
                        onClick={() => checkAnswer(choiceId)}
                        correct={isAnswered && evaluation.correct === choiceId}
                        incorrect={isAnswered && evaluation.incorrect === choiceId}
                        missed={isAnswered && showMissed && evaluation.missed === choiceId}
                        disabled={isAnswered}
                     >
                        <img src={image.src} />
                     </MultipleChoiceImageOption>
                  </div>
               ))}
            </div>
         ))}
      </div>
   );
};

export default React.memo(MultipleChoiceImageOptions);
