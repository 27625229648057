// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import { numberWithCommas } from '@helpers/NumberUtils';
import classnames from 'classnames';

import AnimalIcon from '@components/AnimalIcon';
import { ILeaderboardEntry } from './StudentVocabStats';

interface LeaderboardProps {
   leaderboard: readonly ILeaderboardEntry[];
}

const Leaderboard: React.FC<LeaderboardProps> = ({ leaderboard }) => {
   const filteredLeaderboard = React.useMemo((): readonly ILeaderboardEntry[] => {
      const MAX_RECORDS = 10;
      if (!leaderboard.length) {
         return [];
      }
      const filtered = leaderboard.filter((i) => i.questionsAnswered || i.self);
      const myRecord = filtered.find((i) => i.self);
      if (!myRecord) {
         return [];
      }
      const myRank = myRecord.rank;
      const grouped = _.groupBy(filtered, 'rank');
      const ranks = _.orderBy([...new Set(filtered.map((i) => i.rank))]);
      const mapped = ranks.map((i) => {
         const group = grouped[i];
         if (i === myRank) {
            return group.find((j) => j.self);
         } else {
            return group[0];
         }
      });
      const result = mapped.slice(0, MAX_RECORDS);
      if (myRank > MAX_RECORDS + 1) {
         const myRankIndex = ranks.findIndex((i) => i === myRank);
         const prevRank = ranks[myRankIndex - 1];
         const prevRecord = grouped[prevRank][0];
         result.push(prevRecord);
      }
      if (myRank > MAX_RECORDS) {
         result.push(myRecord);
      }
      if (myRank > MAX_RECORDS - 1) {
         const myRankIndex = ranks.findIndex((i) => i === myRank);
         if (myRankIndex !== ranks.length - 1) {
            const nextRank = ranks[myRankIndex + 1];
            const nextRecord = grouped[nextRank][0];
            result.push(nextRecord);
         }
      }
      return result;
   }, [leaderboard]);

   return (
      <div className='card no-padding' style={{ height: '100%' }}>
         <div className='card-title'>
            <div className='title'>Weekly Leaderboard</div>
         </div>
         <div className='leaderboard'>
            {filteredLeaderboard.map(({ color, animal, questionsAnswered, rank, self }) => (
               <div key={rank} className={classnames('entry', { self })}>
                  <div className='number-wrap'>
                     <div className='number'>
                        <label>{rank}</label>
                     </div>
                  </div>
                  <div className={`avatar-wrapper small ${color}`}>
                     <div className='avatar'>
                        <AnimalIcon animal={animal} color='white' />
                     </div>
                  </div>
                  <div className='heading-wrapper'>
                     <div className='name-wrapper'>
                        <span className='name'>{`${_.startCase(color)} ${_.startCase(
                           animal,
                        )}`}</span>
                        {self && <span className='self-subtitle'>(You)</span>}
                     </div>
                     <div className='questions-answered'>{numberWithCommas(questionsAnswered)}</div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
};

export default React.memo(Leaderboard);
