import { Video, VideoFeatureCollection, VideoGetResponse } from '@models/Video';

import { IdName } from '@models/Core';
import { SupportedSonixLanguages } from '@models/MediaTranscript';
import AxiosService from './AxiosService';

const API = '/api/videos';

const getAllVideoGeoJSON = async (): Promise<VideoFeatureCollection> => {
   const response = await AxiosService.get<VideoFeatureCollection>(`${API}/geojson`);
   return response.data;
};

const getAll = async (videoIds: number[] | string[]): Promise<VideoGetResponse[]> => {
   const response = await AxiosService.get<{ videos: VideoGetResponse[] }>(API, {
      params: {
         videoIds,
      },
   });

   return response.data.videos;
};

const get = async (videoId: number): Promise<VideoGetResponse> => {
   const response = await AxiosService.get<{ videos: VideoGetResponse[] }>(API, {
      params: {
         videoIds: [videoId],
      },
   });

   return response.data.videos[0];
};

const getAllSummaries = async (videoIds: number[] | string[]): Promise<Video[]> => {
   const response = await AxiosService.get<{ videos: VideoGetResponse[] }>(`${API}/summaries`, {
      params: {
         videoIds,
      },
   });
   return response.data.videos;
};

const bulkCreate = async (
   fileNames: string[],
   language: SupportedSonixLanguages,
): Promise<Video[]> => {
   const response = await AxiosService.post<{ videos: Video[] }>(`${API}/bulk_create`, {
      fileNames,
      language,
   });
   return response.data.videos;
};

const create = async (fileName: string, language: SupportedSonixLanguages): Promise<Video> => {
   const response = await AxiosService.post<Video>(API, { fileName, language }, {});
   return response.data;
};

const bulkUpdate = async (
   partialVideos: Partial<
      Omit<Video, 'nativeLanguageTextTrack' | 'targetLanguageTextTrack' | 'speakers'> & {
         speakers: readonly IdName[];
      }
   >[],
): Promise<void> => {
   await AxiosService.patch<void>(
      '/api/videos/bulk_update',
      { videos: partialVideos },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   );
};

export default {
   bulkCreate,
   bulkUpdate,
   create,
   get,
   getAll,
   getAllSummaries,
   getAllVideoGeoJSON,
};
