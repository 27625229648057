import * as _ from 'lodash';
import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import { Maybe } from '@models/Core';
import {
   ModesConstant,
   ProficiencyCanDoStatementDetailedWithActivities,
   ProficiencyCanDoStatementOptions,
   ProficiencyLevelConstants,
   SkillsConstants,
} from '@models/Proficiency';
import ProficiencyCanDoStatementService from '@services/ProficiencyCanDoStatementService';

import Button from '@components/Common/Button';
import { Column } from '@components/Common/Table';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';
import ProficiencyLevelLabel from '@components/ProficiencyLevelLabel';
import DeleteAction from '@components/TableActions/DeleteAction';
import CanDoStatementActivitiesModal from './CanDoStatementActivitiesModal';
import CanDoStatementEditorModal from './CanDoStatementEditorModal';

const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [],
};

const CanDoStatementsTable: React.FC = () => {
   const [isCreateModalOpen, setIsCreateModalOpen] = React.useState<boolean>(false);
   const [isActivityModalOpen, setIsActivityModalOpen] = React.useState<boolean>(false);
   const [options, setOptions] = React.useState<Maybe<ProficiencyCanDoStatementOptions>>(null);
   const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);
   const [selectedCanDoStatement, setSelectedCanDoStatement] =
      React.useState<Maybe<ProficiencyCanDoStatementDetailedWithActivities>>(null);

   React.useEffect(() => {
      ProficiencyCanDoStatementService.getThemes().then((response) => {
         setOptions({
            themes: response.themes,
            levels: ProficiencyLevelConstants,
            skills: SkillsConstants,
            modes: ModesConstant,
         });
      });
   }, []);

   const openUpdateModal = (
      canDoStatement: ProficiencyCanDoStatementDetailedWithActivities,
   ): void => {
      setSelectedCanDoStatement({ ...canDoStatement });
      setIsCreateModalOpen(true);
   };

   const openActivityModal = (canDoStatement: ProficiencyCanDoStatementDetailedWithActivities) => {
      setSelectedCanDoStatement({ ...canDoStatement });
      setIsActivityModalOpen(true);
   };

   const renderId = (
      canDoStatement: ProficiencyCanDoStatementDetailedWithActivities,
   ): React.ReactNode => (
      <Button
         textLink
         className='black hover-blue'
         onClick={() => {
            openUpdateModal(canDoStatement);
         }}
      >
         {canDoStatement.id}
      </Button>
   );

   const onDelete = (canDoStatementId: number): void => {
      ProficiencyCanDoStatementService.remove(canDoStatementId).then(() => {
         setShouldRefetch(true);
      });
   };

   const renderActions = (
      canDoStatement: ProficiencyCanDoStatementDetailedWithActivities,
   ): React.ReactNode => (
      <DeleteAction
         onDelete={() => {
            onDelete(canDoStatement.id);
         }}
         objectName={canDoStatement.name}
      />
   );

   const renderActivities = (
      canDoStatement: ProficiencyCanDoStatementDetailedWithActivities,
   ): JSX.Element => {
      const activitiesGroupedByLanguage = _.groupBy(canDoStatement.activities, 'activityLanguage');
      return (
         <>
            {Object.entries(activitiesGroupedByLanguage).map(([language, activities]) => (
               <div key={language}>
                  <strong>{language.toUpperCase()}</strong>: {activities.length}
               </div>
            ))}
            {Object.keys(activitiesGroupedByLanguage).length > 0 && (
               <Button
                  textLink
                  className='margin-top-xs'
                  onClick={() => openActivityModal(canDoStatement)}
               >
                  Show
               </Button>
            )}
         </>
      );
   };

   const columns: readonly Column<ProficiencyCanDoStatementDetailedWithActivities>[] = [
      { id: 'id', header: 'Id', cell: renderId },
      { id: 'name', header: 'Statement', cell: (i) => i.name },
      {
         id: 'level_name',
         header: 'Level',
         cell: (i) => <ProficiencyLevelLabel>{i.level}</ProficiencyLevelLabel>,
      },
      { id: 'mode_name', header: 'Mode', cell: (i) => i.mode },
      { id: 'skill_name', header: 'Skill', cell: (i) => i.skill },
      {
         id: 'performance_indicator_name',
         header: 'Performance Indicator',
         cell: (i) => i.performanceIndicator,
      },
      {
         id: 'activities',
         header: 'Activities',
         cell: renderActivities,
      },
      { id: 'delete', header: 'Delete', cell: renderActions },
   ];

   const onSave = (): void => {
      setShouldRefetch(true);
      setIsCreateModalOpen(false);
   };

   const onAddRowClick = (): void => {
      setSelectedCanDoStatement(null);
      setIsCreateModalOpen(true);
   };

   const handleCancel = (): void => {
      setIsCreateModalOpen(false);
   };

   const handleActivityCancel = (): void => {
      setIsActivityModalOpen(false);
   };

   return (
      <>
         {isCreateModalOpen && options && (
            <CanDoStatementEditorModal
               onCancel={handleCancel}
               onSave={onSave}
               options={options}
               selectedCanDoStatement={selectedCanDoStatement}
            />
         )}
         {isActivityModalOpen && selectedCanDoStatement && (
            <CanDoStatementActivitiesModal
               selectedCanDoStatement={selectedCanDoStatement}
               onCancel={handleActivityCancel}
            />
         )}
         <FilterableTable<ProficiencyCanDoStatementDetailedWithActivities>
            attributes={[]}
            columns={columns}
            defaultPredicate={defaultPredicate}
            defaultSortOrder={[]}
            pageTitle='Can-Do Statements'
            searchInputPlaceholder='Search Statements'
            rowsLoader={ProficiencyCanDoStatementService.filterableTableSearch}
            onAddRowClick={onAddRowClick}
            shouldRefetch={shouldRefetch}
            refetchComplete={() => {
               setShouldRefetch(false);
            }}
         />
      </>
   );
};

export default CanDoStatementsTable;
