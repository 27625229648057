// credit: https://stackoverflow.com/a/16233919
const formatCurrency = (amount: number): string => {
   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   return formatter.format(isNaN(amount) ? 0 : amount);
};

export { formatCurrency };
