import * as React from 'react';

import { Maybe } from '@models/Core';
import LandingService from '@services/LandingService';
import Parser from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import { LandingPageContent } from '@models/LandingPageContent';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';

import Logo from '../../../assets/logo.svg';

type MatchParams = {
   urlSlug: string;
};

const Landing: React.FC = () => {
   const navigate = useNavigate();
   const paramaters = useParams<MatchParams>();

   const { loggedIn } = React.useContext<AppStateContext>(AppStateContext);

   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [landingPageContent, setLandingPageContent] =
      React.useState<Maybe<LandingPageContent>>(null);

   React.useEffect(() => {
      if (!paramaters.urlSlug) {
         return;
      }
      LandingService.getLandingPageContent(paramaters.urlSlug)
         .then((data) => {
            // if the user is already logged in, and this landing page
            // has a deep link, let's take them directly to it
            if (loggedIn && data?.postLoginDeeplink) {
               navigate(data.postLoginDeeplink, { replace: true });
               return;
            }

            setLandingPageContent(data);
            setIsLoading(false);

            const thirtyMinutes = 30 * 60 * 1000;
            store.dispatch(
               actionFunctions.setPostLoginRedirect({
                  expiration: new Date(new Date().getTime() + thirtyMinutes),
                  path: data.postLoginDeeplink,
               }),
            );
         })
         .catch((x) => {
            console.error('Failed when loading landing page: ', x);
            navigate(Constants.routes.auth.login, { replace: true });
         });
   }, [loggedIn, paramaters.urlSlug]);

   if (isLoading || !landingPageContent) {
      return <Loader />;
   }

   return (
      <div className='landing-page margin-top-xl'>
         <div className='col-xs-12 col-sm-8 col-md-6 center'>
            <div className='card-content-onboarding card padding-m'>
               <Logo className='logo center' />
               <div className='onboarding-title border-bottom text-center'>
                  <DocumentTitle>{landingPageContent.title}</DocumentTitle>
                  <h2 aria-live='polite' className='uppercase no-margin'>
                     {landingPageContent.title}
                  </h2>
               </div>
               <div className='margin-top-m margin-bottom-m'>
                  {Parser(landingPageContent.greeting)}
               </div>
               <p className='margin-top-s margin-bottom-s'>
                  <strong>Do you already have an account?</strong>
               </p>
               <Link className='btn full-width' to={Constants.routes.auth.login}>
                  Login
               </Link>
               <div className='hr-sect'>or</div>
               <Link
                  className='btn full-width line '
                  to={
                     Constants.routes.register +
                     '?instructorCode=' +
                     landingPageContent.instructorCode
                  }
               >
                  Sign up
               </Link>
            </div>
         </div>
      </div>
   );
};

export default Landing;
