import * as _ from 'lodash';
import * as React from 'react';

import { getQueryParameterByName, UrlBuilder } from '@helpers/QueryParameter';
import GoogleIcon from '@icons/general/google.svg';
import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import RegistrationService, {
   PrefilledUserInfo,
   RegisteringUser,
   RegistrationError,
} from '@services/RegistrationService';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Button from '@components/Common/Button';
import Constants from '../../Constants';
import { LanguageLookup } from '@models/Language';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import RegisterError from './RegisterError';

interface RegisterInitialProps {
   user: RegisteringUser;
   errors: readonly RegistrationError[];
   displayMessage?: string;
   prefilledUserInfo?: Maybe<PrefilledUserInfo>;
   handleInitialStepComplete(): void;
   handleUserChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void;
   setErrors(errors: readonly RegistrationError[]): void;
}

interface FieldAttributes extends React.HTMLProps<HTMLInputElement | HTMLSelectElement> {
   containerClassName?: string;
   label: string;
   name: keyof Omit<RegisteringUser, 'school'>;
   prefilledValue?: string;
   type?: string;
   options?: readonly { value: string; label: string }[];
}

const RegisterInitial: React.FC<RegisterInitialProps> = ({
   user,
   errors,
   prefilledUserInfo,
   handleUserChange,
   setErrors,
   handleInitialStepComplete,
}) => {
   const {
      routes: {
         auth: { login },
         courses: { join },
      },
      siteLinks: { privacyPolicy, termsOfService: termsOfServiceLink },
   } = Constants;

   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [courseCode, setCourseCode] = React.useState<Maybe<string>>(null);
   const location = useLocation();

   const {
      firstName: prefilledFirstName = '',
      lastName: prefilledLastName = '',
      email: prefilledEmail = '',
      language: prefilledLanguage = '',
      accountType: prefilledAccountType = null,
      instructorCode: prefilledInstructorCode = '',
   } = prefilledUserInfo || {};

   React.useEffect(() => {
      const queryParamCourseCode = getQueryParameterByName(location, 'courseCode');
      setCourseCode(queryParamCourseCode);
   }, [location]);

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      const validationErrors = await RegistrationService.validateRegistration(user, {
         ...prefilledUserInfo,
         confirmEmail: prefilledUserInfo?.email,
      });
      setErrors(validationErrors);
      if (_.isEmpty(validationErrors)) {
         setIsLoading(true);
         handleInitialStepComplete();
      }
   };

   const buildGoogleSsoUrl = (): string => {
      const extraParams = new URLSearchParams(window.location.search);
      extraParams.delete('courseCode');

      const urlBuilder = new UrlBuilder(
         `${window.location.origin}/api/auth/google/auth_request_uri`,
      );
      urlBuilder.addParam('action', 'signup');
      if (courseCode) {
         urlBuilder.addParam('courseCode', courseCode);
      }
      urlBuilder.addParam('extraParams', extraParams.toString());
      return urlBuilder.fullUrl;
   };

   const renderField = ({
      containerClassName = 'col-xs-12 col-sm-6',
      value,
      prefilledValue,
      label,
      name,
      disabled: propsDisabled,
      type = 'text',
      options = [],
      ...rest
   }: FieldAttributes): React.ReactNode => {
      const disabled = propsDisabled ? propsDisabled : !!prefilledValue;
      const error = errors.find((i) => i?.field === name);
      const common = {
         name,
         type,
         'data-test': _.kebabCase(name),
         className: classNames({ disabled, error: !!error }),
         onChange: handleUserChange,
         value: prefilledValue ? prefilledValue : ((user[name] ?? '') as string),
         'aria-invalid': !!error,
         disabled,
         'data-dd-privacy': type === 'password' ? 'input-ignored' : '',
      };
      return (
         <div className={containerClassName}>
            <label htmlFor={name} className={classNames('margin-top-s', { error })}>
               {label}
            </label>
            {type !== 'select' && (
               <input
                  spellCheck={false}
                  {...common}
                  {...(rest as React.HTMLProps<HTMLInputElement>)}
               />
            )}
            {type === 'select' && (
               <select {...common} {...(rest as React.HTMLProps<HTMLSelectElement>)}>
                  <option key={0} disabled value=''>
                     Select
                  </option>
                  {options.map((i) => (
                     <option value={i.value} key={i.value}>
                        {i.label}
                     </option>
                  ))}
               </select>
            )}
            {error?.message && (
               <div className='error text-sm padding-top-xs' aria-live='polite'>
                  {error.message}
               </div>
            )}
         </div>
      );
   };

   const renderTermsOfService = () => {
      const error = errors.find((i) => i.field === 'termsOfService');
      return (
         <div className='flex align-items-center'>
            <input
               name='termsOfService'
               type='checkbox'
               data-test='terms-of-service'
               aria-label='Terms of Service'
               className={classNames({ error })}
               checked={user.termsOfService}
               onChange={handleUserChange}
               aria-invalid={!!error}
            />
            <span className='gray-text text-sm no-margin'>
               I agree to the{' '}
               <Link to={termsOfServiceLink} rel='noreferrer' external>
                  Terms of Service
               </Link>
               &nbsp;and{' '}
               <Link to={privacyPolicy} rel='noreferrer' external>
                  Privacy Policy
               </Link>
               .
            </span>
            {error?.message && (
               <div className='error text-sm padding-top-xs' aria-live='polite'>
                  {error.message}
               </div>
            )}
         </div>
      );
   };

   const signupButtonText = prefilledEmail ? 'Continue registration' : 'Sign up';
   const continuingRegistration = !!prefilledEmail;
   const loginRoute = courseCode ? `${join}?code=${courseCode}` : login;
   const googleSsoUrl = buildGoogleSsoUrl();

   return (
      <div className='card-content-onboarding card'>
         <div className='onboarding-title border-bottom text-center margin-bottom-s'>
            <DocumentTitle>Account Registration</DocumentTitle>
            <h2 aria-live='polite' className='uppercase no-margin'>
               Account Registration
            </h2>
         </div>
         <form className='content-form' onSubmit={handleSubmit}>
            <div className='row'>
               {renderField({
                  label: 'First Name',
                  name: 'firstName',
                  prefilledValue: prefilledFirstName,
               })}
               {renderField({
                  label: 'Last Name',
                  name: 'lastName',
                  prefilledValue: prefilledLastName,
               })}
            </div>
            <div className='row'>
               {renderField({
                  label: 'School Email',
                  containerClassName: 'col-xs-12',
                  name: 'email',
                  type: 'email',
                  autoComplete: 'username',
                  prefilledValue: prefilledEmail,
               })}
            </div>
            {!prefilledEmail && (
               <div className='row'>
                  {renderField({
                     containerClassName: 'col-xs-12',
                     label: 'Re-enter School Email',
                     type: 'email',
                     name: 'confirmEmail',
                  })}
               </div>
            )}
            <div className='row'>
               {renderField({
                  label: 'Password',
                  type: 'password',
                  name: 'password',
               })}
               {renderField({
                  label: 'Re-enter Password',
                  type: 'password',
                  name: 'confirmPassword',
               })}
            </div>
            <div className='row'>
               {renderField({
                  containerClassName: 'col-xs-12',
                  label: 'Account Type',
                  type: 'select',
                  name: 'accountType',
                  prefilledValue: prefilledAccountType as string,
                  options: [
                     { value: AccountType.student, label: 'Student' },
                     { value: AccountType.instructor, label: 'Instructor' },
                  ],
               })}
            </div>
            {user.accountType === AccountType.instructor && (
               <div className='row'>
                  {renderField({
                     containerClassName: 'col-xs-12',
                     label: 'Instructor Code',
                     name: 'instructorCode',
                     prefilledValue: prefilledInstructorCode,
                  })}
               </div>
            )}
            <div className='row'>
               {renderField({
                  containerClassName: 'col-xs-12',
                  label: `Language ${
                     user.accountType === AccountType.instructor ? 'Teaching' : 'Learning'
                  }`,
                  type: 'select',
                  name: 'language',
                  prefilledValue: prefilledLanguage,
                  options: _.sortBy(
                     Object.entries(LanguageLookup).map(([key, value]) => ({
                        label: value,
                        value: key,
                     })),
                     (x) => x.label,
                  ),
               })}
            </div>
            <div className='signup-with-email margin-top-m'>
               {renderTermsOfService()}
               <Button
                  type='submit'
                  fullWidth
                  loading={isLoading}
                  data-test='sign-up-button'
                  className='margin-top-m'
               >
                  {signupButtonText}
               </Button>
               <RegisterError errors={errors} />
            </div>
         </form>
         {continuingRegistration ? (
            <div className='padding-top-m' />
         ) : (
            <>
               <div className='hr-sect'>or</div>
               <div className='row'>
                  <div className='col-xs-12'>
                     <Link
                        className='btn line full-width margin-top-s'
                        external
                        target='_self'
                        to={googleSsoUrl}
                     >
                        <GoogleIcon aria-hidden />
                        Google
                     </Link>
                  </div>
               </div>
               <div className='border-top margin-top-m padding-top-s space-between align-items-center'>
                  <span className='gray-text text-sm'>Already have an account?</span>
                  <Link className='btn line small' to={loginRoute}>
                     Login
                  </Link>
               </div>
            </>
         )}
      </div>
   );
};

export default RegisterInitial;
