import * as _ from 'lodash';
import * as React from 'react';

import { getQueryParameterByName, removeQueryParameter } from '@helpers/QueryParameter';
import { getCurrentRelativeURL } from '@helpers/RouterHelper';
import AccountType from '@models/AccountType';
import Appearance from '@models/Appearance';
import { IApplicationState } from '@models/ApplicationState';
import { Maybe } from '@models/Core';
import Language, { LanguageLookup } from '@models/Language';
import SchoolProfile from '@models/SchoolProfile';
import CourseCodeService, { ValidateCourseCodeResponse } from '@services/CourseCodeService';
import CourseService from '@services/CourseService';
import RegistrationService, {
   ApiError,
   isRegisteringUserWithSchoolProfile,
   isRegistrationError,
   PrefilledUserInfo,
   RegisteringUser,
   RegistrationError,
   RegistrationErrorName,
   RegistrationStep,
} from '@services/RegistrationService';
import SchoolService from '@services/SchoolService';
import { AxiosError, isAxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import IUserInfo from '@models/IUserInfo';
import DocumentTitle from '@components/DocumentTitle';
import { BannerName } from '@components/Wrappers/Layout/Banner';
import DomainRestricted from './DomainRestricted';
import MultipleSchoolSuggestion from './MultipleSchoolSuggestion';
import RegisterInitial from './RegisterInitial';
import RegisterSuccess from './RegisterSuccess';
import SchoolSearchStep from './SchoolSearchStep';
import SingleSchoolSuggestion from './SingleSchoolSuggestion';

const RegisterForm: React.FC<Record<string, never>> = () => {
   const {
      routes: {
         courses,
         dashboard,
         auth: { tokenLogin },
      },
      statusCodes: { notFound },
      statusCodes,
   } = Constants;

   const stateUser = useSelector<IApplicationState, Maybe<IUserInfo>>((state) => state.user);

   const loggedIn = stateUser?.loggedIn || false;

   const { setBanner } = React.useContext<AppStateContext>(AppStateContext);

   const navigate = useNavigate();
   const location = useLocation();

   const [courseCode, setCourseCode] = React.useState<Maybe<string>>(null);
   const [errors, setErrors] = React.useState<readonly RegistrationError[]>([]);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [prefilledUserInfo, setPrefilledUserInfo] = React.useState<PrefilledUserInfo>({});
   const [step, setStep] = React.useState<RegistrationStep>(RegistrationStep.initial);
   const [suggestedSchools, setSuggestedSchools] = React.useState<readonly SchoolProfile[]>([]);
   const [user, setUser] = React.useState<RegisteringUser>({
      firstName: '',
      lastName: '',
      language: '',
      school: null,
      email: '',
      confirmEmail: '',
      accountType: AccountType.student,
      instructorCode: '',
      password: '',
      confirmPassword: '',
      termsOfService: false,
   });

   React.useEffect(() => {
      const token = getQueryParameterByName(location, 'token');
      let paramCourseCode = getQueryParameterByName(location, 'courseCode');
      if (!paramCourseCode) {
         paramCourseCode = getQueryParameterByName(location, 'accessCode');
      }
      const signupUsingSSO = getQueryParameterByName(location, 'sso') === 'true';
      const instructorCode = getQueryParameterByName(location, 'instructorCode', '');
      const queryParamLang = getQueryParameterByName(location, 'language') as Language;

      setCourseCode(paramCourseCode);

      if (loggedIn) {
         if (paramCourseCode) {
            CourseService.joinCourseByCode(paramCourseCode)
               .then((courseId) => {
                  navigate(courses.dashboard.replace(':courseId', courseId.toString()));
               })
               .catch((error) => {
                  if (error.response && error.response.status === notFound) {
                     setBanner({
                        bannerName: BannerName.JOIN_COURSE_FAILURE,
                        show: true,
                        dismissable: true,
                        appearance: Appearance.danger,
                        body: 'Invalid Course Code: Course not found.',
                     });
                  }
                  navigate(dashboard);
               });
         } else {
            navigate(dashboard);
         }
      } else if (paramCourseCode) {
         if (instructorCode) {
            CourseCodeService.validateInstructorCourseCode(paramCourseCode)
               .then(handleValidCourseCode)
               .catch(handleInvalidCourseCode);
         } else {
            CourseCodeService.validateStudentCourseCode(paramCourseCode)
               .then(handleValidCourseCode)
               .catch(handleInvalidCourseCode);
         }
      }
      if (instructorCode) {
         RegistrationService.validateInstructorCode(instructorCode).then((response) => {
            if (isRegistrationError(response)) {
               setUser((prevUser) => ({
                  ...prevUser,
                  // Setting to User so so that user can edit it
                  instructorCode,
                  accountType: AccountType.instructor,
               }));
               setErrors((prevErrors) => [...prevErrors, response]);
               const newUrlForHistory = removeQueryParameter(
                  getCurrentRelativeURL(),
                  'instructorCode',
               );
               navigate(newUrlForHistory, { replace: true });
            } else {
               const { email: responseEmail } = response;
               setUser((prevUser) => ({
                  ...prevUser,
                  email: responseEmail ? responseEmail : prevUser.email,
                  instructorCode,
                  accountType: AccountType.instructor,
               }));
               setPrefilledUserInfo((prevPrefilledUserInfo) => ({
                  ...prevPrefilledUserInfo,
                  email: responseEmail,
                  instructorCode,
                  accountType: AccountType.instructor,
               }));
            }
         });
      }
      if (token) {
         if (signupUsingSSO) {
            RegistrationService.getSsoAttributesFromToken(token)
               .then(({ firstName, lastName, email }) => {
                  setPrefilledUserInfo((prevPrefilledUserInfo) => ({
                     ...prevPrefilledUserInfo,
                     firstName,
                     lastName,
                     email,
                     token,
                     isSsoOnly: true,
                  }));
                  setUser((prevUser) => ({ ...prevUser, firstName, lastName, email }));
               })
               .catch(handleInvalidToken);
         } else {
            RegistrationService.getCourseInviteFromToken(token)
               .then(
                  ({
                     email,
                     organizationId,
                     firstName = '',
                     lastName = '',
                     language,
                     accountType,
                  }) => {
                     const school = { id: organizationId };
                     setPrefilledUserInfo((prevPrefilledUserInfo) => ({
                        ...prevPrefilledUserInfo,
                        email,
                        language,
                        accountType,
                        token,
                        school,
                     }));
                     setUser((prevUser) => ({
                        ...prevUser,
                        firstName,
                        lastName,
                        language,
                        accountType,
                        email,
                        school,
                     }));
                  },
               )
               .catch(handleInvalidToken);
         }
      }
      if (queryParamLang && Object.keys(LanguageLookup).includes(queryParamLang)) {
         setUser((prevUser) => ({
            ...prevUser,
            instructorCode,
            language: queryParamLang,
         }));
      }
   }, [loggedIn, location]);

   const handleValidCourseCode = ({
      school,
      language: courseLanguage,
   }: ValidateCourseCodeResponse): void => {
      setPrefilledUserInfo((prevPrefilledUserInfo) => ({
         ...prevPrefilledUserInfo,
         school,
      }));
      // Not pre-filling them because we want them to be able to be changed.
      const accountType = courseCode?.startsWith('INS-')
         ? AccountType.instructor
         : AccountType.student;
      setUser((prevUser) => ({
         ...prevUser,
         school,
         accountType,
         language: courseLanguage,
      }));
   };

   const handleInvalidCourseCode = (error: AxiosError<{ courseCode: string }>): void => {
      if (error.response?.status === statusCodes.notFound) {
         const invalidCourseCode = error.response.data.courseCode;
         setErrors((prevErrors) => [
            ...prevErrors,
            {
               name: RegistrationErrorName.invalidCourseCode,
               message: `The course code in your link is invalid: ${invalidCourseCode}. Please reach out to your instructor or course coordinator for a new link.`,
            },
         ]);
         setCourseCode(null);
         const newUrlForHistory = removeQueryParameter(getCurrentRelativeURL(), 'courseCode');
         navigate(newUrlForHistory, { replace: true });
      }
   };

   const handleInvalidToken = (error: AxiosError): void => {
      if (error.response?.status === statusCodes.notFound) {
         setErrors((prevErrors) => [
            ...prevErrors,
            {
               name: RegistrationErrorName.invalidToken,
               message: 'The token in your link is invalid.',
            },
         ]);
      }
   };

   const handleDomainRestrictedEmailChange = (): void => {
      setPrefilledUserInfo((prevPrefilledUserInfo) => ({
         ...prevPrefilledUserInfo,
         // If restricted domain and email doesn't match - can't use google auth so clear it.
         token: prevPrefilledUserInfo?.isSsoOnly ? undefined : prevPrefilledUserInfo?.token,
         // need to remove prefilled email when domain restricted and fails
         email: undefined,
      }));
   };

   const suggestSchools = (): Promise<readonly SchoolProfile[]> => {
      const { email } = user;
      const emailDomain = email.split('@')[1];
      return SchoolService.schoolSearch({ domain: emailDomain });
   };

   const handleInitialStepComplete = async (): Promise<void> => {
      if (prefilledUserInfo?.school && (courseCode || prefilledUserInfo.token)) {
         submitRegistration();
      } else {
         suggestSchools().then((results) => {
            setSuggestedSchools(results);
            setStep(
               results.length ? RegistrationStep.schoolSuggestion : RegistrationStep.schoolSearch,
            );
         });
      }
   };

   const submitRegistration = async (localUser?: RegisteringUser): Promise<void> => {
      setIsLoading(true);
      RegistrationService.register(localUser ?? user, prefilledUserInfo, courseCode)
         .then((response) => {
            if (isRegistrationError(response)) {
               if (response.name === RegistrationErrorName.restrictedEmailDomain) {
                  setStep(RegistrationStep.domainRestricted);
               } else {
                  // Handle other RegistrationError cases
                  setErrors((prevErrors) => [...prevErrors, response]);
               }
            } else {
               // Handle SuccessfulRegistrationResponse
               const { loginToken } = response;
               if (loginToken) {
                  navigate(`${tokenLogin}?token=${loginToken}`);
               } else {
                  setStep(RegistrationStep.success);
               }
            }
         })
         .catch((error) => {
            if (isAxiosError(error)) {
               const apiError: ApiError = error.response?.data;
               setIsLoading(false);
               if (apiError) {
                  setErrors((prevErrors) => ({ ...prevErrors, apiError }));
               }
            }
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const handleUserChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const target = event.target as HTMLSelectElement & HTMLInputElement;
      const { name, type, value } = target;
      const inputValue: string | boolean = type === 'checkbox' ? target.checked : value?.trim();
      setUser((prevUser) => ({ ...prevUser, [name]: inputValue }));
   };

   const setSchool = (school: SchoolProfile, submitRegistrationAfter?: boolean): void => {
      if (submitRegistrationAfter) {
         submitRegistration({ ...user, school });
      }
      setUser((prevUser) => ({ ...prevUser, school }));
   };

   return (
      <div className='focused-form-container'>
         <DocumentTitle>Register</DocumentTitle>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  {step === RegistrationStep.initial && (
                     <RegisterInitial
                        errors={errors}
                        handleInitialStepComplete={handleInitialStepComplete}
                        handleUserChange={handleUserChange}
                        prefilledUserInfo={prefilledUserInfo}
                        setErrors={setErrors}
                        user={user}
                     />
                  )}
                  {step === RegistrationStep.schoolSuggestion && suggestedSchools.length === 1 && (
                     <SingleSchoolSuggestion
                        errors={errors}
                        isLoading={isLoading}
                        setSchool={setSchool}
                        setStep={setStep}
                        suggestedSchool={suggestedSchools[0]}
                     />
                  )}
                  {step === RegistrationStep.schoolSuggestion && suggestedSchools.length > 1 && (
                     <MultipleSchoolSuggestion
                        errors={errors}
                        isLoading={isLoading}
                        setSchool={setSchool}
                        setStep={setStep}
                        suggestedSchools={suggestedSchools}
                     />
                  )}
                  {step === RegistrationStep.schoolSearch && (
                     <SchoolSearchStep
                        errors={errors}
                        handleUserChange={handleUserChange}
                        isLoading={isLoading}
                        setSchool={setSchool}
                        user={user}
                     />
                  )}
                  {step === RegistrationStep.domainRestricted &&
                     isRegisteringUserWithSchoolProfile(user) && (
                        <DomainRestricted
                           errors={errors}
                           isLoading={isLoading}
                           setEmail={handleDomainRestrictedEmailChange}
                           setStep={setStep}
                           submitRegistration={submitRegistration}
                           user={user}
                        />
                     )}
                  {step === RegistrationStep.success && (
                     <RegisterSuccess email={(user.email || prefilledUserInfo?.email) as string} />
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default RegisterForm;
