// @ts-strict-ignore
import * as React from 'react';

import autobind from '@helpers/autobind';
import classnames from 'classnames';

import { Color } from '@components/Common/Color';

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;
const MAX_X = 100;
const MAX_Y = 100;
const FULL_RADIUS = 50;
const CENTER_X = 50;
const CENTER_Y = 50;

interface CircularProgressBarProps {
   animate?: boolean;
   background?: boolean;
   backgroundPadding?: number;
   children?: React.ReactNode;
   classes?: Record<string, string>;
   className?: string;
   color?: Color;
   counterClockwise?: boolean;
   percentage: number;
   strokeWidth?: number;
   styles?: Record<string, React.CSSProperties>;
   text?: string;
}

interface CircularProgressBarState {
   percentage: number;
}

class CircularProgressBar extends React.Component<
   CircularProgressBarProps,
   CircularProgressBarState
> {
   static defaultProps = {
      className: '',
      strokeWidth: 6,
      text: null,
      classes: {
         root: 'circular-progress-bar',
         trail: 'circular-progress-bar-trail',
         path: 'circular-progress-bar-path',
         text: 'circular-progress-bar-text',
         background: 'circular-progress-bar-background',
      },
      styles: {
         root: {},
         trail: {},
         path: {},
         text: {},
         background: {},
      },
      animate: false,
      background: false,
      backgroundPadding: null,
      counterClockwise: false,
   };
   initialTimeout: NodeJS.Timeout;
   requestAnimationFrame: number;

   constructor(props: CircularProgressBarProps) {
      super(props);
      autobind(this);

      this.state = {
         percentage: 0,
      };
   }

   componentDidMount(): void {
      this.initializeProgressBar();
   }

   componentDidUpdate(prevProps: CircularProgressBarProps): void {
      if (
         !isNaN(prevProps.percentage) &&
         !isNaN(this.props.percentage) &&
         prevProps.percentage !== this.props.percentage
      ) {
         this.initializeProgressBar();
      }
   }

   componentWillUnmount(): void {
      clearTimeout(this.initialTimeout);
      window.cancelAnimationFrame(this.requestAnimationFrame);
   }

   initializeProgressBar(): void {
      const { percentage, animate } = this.props;
      if (animate) {
         this.initialTimeout = setTimeout(() => {
            this.requestAnimationFrame = window.requestAnimationFrame(() => {
               this.setState({ percentage });
            });
         }, 0);
      } else {
         this.setState({ percentage });
      }
   }

   getBackgroundPadding(): number {
      if (this.props.background) {
         // default padding to be the same as strokeWidth
         // compare to null because 0 is falsy
         if (this.props.backgroundPadding === null) {
            return this.props.strokeWidth;
         }
         return this.props.backgroundPadding;
      }
      // don't add padding if not displaying background
      return 0;
   }

   getPathDescription(): string {
      const radius = this.getPathRadius();
      const rotation = this.props.counterClockwise ? 1 : 0;
      // Move to center of canvas
      // Relative move to top canvas
      // Relative arc to bottom of canvas
      // Relative arc to top of canvas
      return `
         M ${CENTER_X},${CENTER_Y}
         m 0,-${radius}
         a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
         a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
      `;
   }

   getPathStyles(): React.CSSProperties {
      const diameter = Math.PI * 2 * this.getPathRadius();
      const truncatedPercentage = Math.min(
         Math.max(this.state.percentage, MIN_PERCENTAGE),
         MAX_PERCENTAGE,
      );
      const dashoffset = ((100 - truncatedPercentage) / 100) * diameter;
      return {
         strokeDasharray: `${diameter}px ${diameter}px`,
         strokeDashoffset: `${this.props.counterClockwise ? -dashoffset : dashoffset}px`,
      };
   }

   getPathRadius(): number {
      // the radius of the path is defined to be in the middle, so in order for the path to
      // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
      return FULL_RADIUS - this.props.strokeWidth / 2 - this.getBackgroundPadding();
   }

   render(): React.ReactNode {
      const { children, classes, className, color, percentage, strokeWidth, styles, text } =
         this.props;
      const pathDescription = this.getPathDescription();
      const progressBar = (
         <svg
            className={`${classes.root} ${className}`}
            style={styles.root}
            viewBox={`0 0 ${MAX_X} ${MAX_Y}`}
         >
            {this.props.background ? (
               <circle
                  className={classes.background}
                  style={styles.background}
                  cx={CENTER_X}
                  cy={CENTER_Y}
                  r={FULL_RADIUS}
               />
            ) : null}
            <path
               className={classes.trail}
               style={styles.trail}
               d={pathDescription}
               strokeWidth={strokeWidth}
               fillOpacity={0}
            />
            <path
               className={classnames(classes.path, color)}
               d={pathDescription}
               strokeWidth={strokeWidth}
               fillOpacity={0}
               style={{ ...styles.path, ...this.getPathStyles() }}
            />
            {!children && (
               <text className={classes.text} style={styles.text} x={CENTER_X} y={CENTER_Y}>
                  {text ? (
                     text
                  ) : (
                     <>
                        {percentage}
                        <tspan className='percent'>%</tspan>
                     </>
                  )}
               </text>
            )}
         </svg>
      );

      if (children) {
         return (
            <div className='circular-progress-bar-wrapper'>
               {progressBar}
               <div className='circular-progress-bar-child'>{children}</div>
            </div>
         );
      }
      return progressBar;
   }
}

export default CircularProgressBar;
