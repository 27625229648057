import * as React from 'react';

import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import {
   AssignableModuleItem,
   AssignableModuleItemType,
   Assignment,
   CourseSection,
   GradingCategory,
   ModuleItem,
} from '@models/Course';
import LMSName from '@models/LMSName';
import UserService from '@services/UserService';

import Switch from '@components/Common/Switch';
import {
   IOnboardingProps,
   OnboardingContext,
   OnboardingTaskState,
   OnboardingWalkthrough,
} from '@components/Onboarding';
import { AssignActivityStep } from '@components/Onboarding/Walkthroughs/AssignActivity';
import AvailabilityPanel from './AvailabilityPanel';
import GradingPanel from './GradingPanel';
import SecurityPanel from './SecurityPanel';

interface AssignmentDetailsProps {
   courseId: number;
   moduleId: number;
   activeItem: ModuleItem;
   assignment: Assignment;
   canEditModule: boolean;
   canEditSecretCode: boolean;
   courseEndDate: Date;
   courseStartDate: Date;
   defaultAssignment: Assignment;
   gradingCategories: readonly GradingCategory[];
   isAssigned: boolean;
   itemId: number;
   itemType: AssignableModuleItemType;
   lmsCourseworkIds?: readonly number[];
   lmsName: Maybe<LMSName>;
   sections: readonly CourseSection[];
   createGradingCategory(label: string): Promise<number>;
   editItem(update: Partial<AssignableModuleItem>): void;
   postItemToLMS(): Promise<void>;
   syncGradesWithLMS(): Promise<void>;
   setIsInvalid(isInvalid: boolean): void;
}

const AssignmentDetails: React.FC<AssignmentDetailsProps> = ({
   courseId,
   activeItem,
   moduleId,
   assignment,
   canEditModule,
   canEditSecretCode,
   courseStartDate,
   courseEndDate,
   gradingCategories,
   isAssigned,
   itemId,
   itemType,
   lmsCourseworkIds,
   lmsName,
   sections,
   createGradingCategory,
   editItem,
   postItemToLMS,
   setIsInvalid,
   syncGradesWithLMS,
}) => {
   const { walkthrough, taskState, stepId } = React.useContext<IOnboardingProps>(OnboardingContext);

   const [isAvailabilityPanelOpen, setIsAvailabilityPanelOpen] = React.useState<boolean>(false);
   const [isGradingPanelOpen, setIsGradingPanelOpen] = React.useState<boolean>(false);
   const [isSecurityPanelOpen, setIsSecurityPanelOpen] = React.useState<boolean>(false);
   const [showSecurityPanel, setShowSecurityPanel] = React.useState<boolean>(false);

   const isActivity = itemType === ContentType.activity;

   React.useEffect(() => {
      UserService.checkFeature('show_security_panel').then(setShowSecurityPanel);
   }, []);

   // AssignActivity walkthrough actions
   React.useEffect(() => {
      if (
         walkthrough?.id === OnboardingWalkthrough.assignActivity ||
         taskState === OnboardingTaskState.inProgress
      ) {
         if (stepId === AssignActivityStep.availabilityTab) {
            setIsAvailabilityPanelOpen(true);
            setIsGradingPanelOpen(false);
         } else if (AssignActivityStep.gradingTab) {
            setIsAvailabilityPanelOpen(false);
            setIsGradingPanelOpen(true);
         }
      }
   }, [stepId]);

   const toggleAvailabilityPanel = (): void => {
      setIsAvailabilityPanelOpen((prevIsOpen) => !prevIsOpen);
   };

   const toggleGradingPanel = (): void => {
      setIsGradingPanelOpen((prevIsOpen) => !prevIsOpen);
   };

   const toggleSecurityPanel = (): void => {
      setIsSecurityPanelOpen((prevIsOpen) => !prevIsOpen);
   };

   const editAssignment = (update: Partial<Assignment>): void => {
      const isSecretCodeUpdate =
         canEditSecretCode && Object.keys(update).length === 1 && update.secretCode;

      if (canEditModule || isSecretCodeUpdate) {
         editItem({ assignment: { ...assignment, ...update } });
      }
   };

   const toggleIsAssigned = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      editItem({ isAssigned: checked });
   };

   return (
      <>
         <div className='assign-switch-container'>
            <Switch
               checked={isAssigned}
               disabled={!canEditModule}
               data-test='assign-switch'
               name='isAssigned'
               onChange={toggleIsAssigned}
               title='Assign to Class'
               tooltip='Item will be assigned a grade in the gradebook'
            />
         </div>
         {isAssigned && (
            <>
               <AvailabilityPanel
                  assignment={assignment}
                  activeItem={activeItem}
                  courseId={courseId}
                  moduleId={moduleId}
                  canEditModule={canEditModule}
                  canEditSecretCode={canEditSecretCode}
                  courseEndDate={courseEndDate}
                  courseStartDate={courseStartDate}
                  isOpen={isAvailabilityPanelOpen}
                  itemId={itemId}
                  itemType={itemType}
                  onAssignmentUpdate={editAssignment}
                  sections={sections}
                  setIsInvalid={setIsInvalid}
                  toggleOpen={toggleAvailabilityPanel}
                  editItem={editItem}
               />
               <GradingPanel
                  assignment={assignment}
                  canEditModule={canEditModule}
                  createGradingCategory={createGradingCategory}
                  gradingCategories={gradingCategories}
                  isOpen={isGradingPanelOpen}
                  itemType={itemType}
                  lmsCourseworkIds={lmsCourseworkIds}
                  lmsName={lmsName}
                  onAssignmentUpdate={editAssignment}
                  postItemToLMS={postItemToLMS}
                  setIsInvalid={setIsInvalid}
                  syncGradesWithLMS={syncGradesWithLMS}
                  toggleOpen={toggleGradingPanel}
               />
               {showSecurityPanel && isActivity && (
                  <SecurityPanel
                     assignment={assignment}
                     canEditModule={canEditModule}
                     isOpen={isSecurityPanelOpen}
                     onAssignmentUpdate={editAssignment}
                     toggleOpen={toggleSecurityPanel}
                  />
               )}
            </>
         )}
      </>
   );
};

export default AssignmentDetails;
