import { Maybe } from './Core';

export const LanguageConstants = [
   'ar',
   'asl',
   'cmn',
   'de',
   'en',
   'es',
   'fr',
   'he',
   'it',
   'ja',
   'ko',
   'la',
   'pt',
   'ru',
   'tr',
   'vi',
   'el',
] as const;

export type Language = (typeof LanguageConstants)[number];

export const LanguageLookup: Record<Language, string> = {
   ar: 'Arabic',
   asl: 'American Sign Language',
   cmn: 'Chinese (Mandarin)',
   de: 'German',
   en: 'English',
   es: 'Spanish',
   fr: 'French',
   la: 'Latin',
   he: 'Hebrew',
   it: 'Italian',
   ja: 'Japanese',
   ko: 'Korean',
   pt: 'Portuguese',
   ru: 'Russian',
   tr: 'Turkish',
   vi: 'Vietnamese',
   el: 'Greek',
};

export const isLanguage = (maybeLanguage: Maybe<string>): maybeLanguage is Language =>
   !!maybeLanguage && LanguageConstants.includes(maybeLanguage as Language);

export default Language;
