import * as React from 'react';

import IconPrinter from '@icons/nova-line/01-Content-Edition/printer.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import Appearance from '@models/Appearance';
import {
   CssStyleSheet,
   getStyleSheet,
   getStyleSheetsIdentifiers,
   newStyleSheet,
   StyleSheetIds,
   updateStyleSheet,
} from '@services/StylesService';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';

// Thoughts for later:
// We could use https://github.com/react-syntax-highlighter/react-syntax-highlighter if we wanted in browser highlighted css
// My only qualm with this is - I think we should break up the javascript bundle and only load it if the user is using a page that requires it.
// It is 1.84 mb if you install the whole package - we could narrow it down a bit though

const StyleSheets: React.FC = () => {
   const [isFetching, setIsFetching] = React.useState<boolean>(true);
   const [isFetchingFile, setIsFetchingFile] = React.useState<boolean>(false);
   const [styleSheetsOptions, setStyleSheetsOptions] = React.useState<StyleSheetIds[]>([]);
   const [editingStyle, setEditingStyle] = React.useState<Partial<CssStyleSheet>>({});
   const [styleModalOpen, setStyleModalOpen] = React.useState<boolean>(false);
   const fileInput = React.useRef<HTMLInputElement>(null);
   const reader = new FileReader();

   reader.onload = async (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result;
      setEditingStyle((prevEditingStyle) => ({
         ...prevEditingStyle,
         css: text as string,
      }));
      setIsFetchingFile(false);
   };

   reader.onerror = async () => {
      alert('Failed to upload file');
      setIsFetchingFile(false);
   };

   React.useEffect(() => {
      getStyleSheets();
   }, []);

   const getStyleSheets = (): void => {
      getStyleSheetsIdentifiers().then((styleSheets) => {
         setStyleSheetsOptions(styleSheets);
         setIsFetching(false);
      });
   };

   const handleNewStyleSheet = (): void => {
      setStyleModalOpen(true);
      setEditingStyle((prevEditingStyle) => ({
         ...prevEditingStyle,
         name: '',
         defaultDueTime: null,
      }));
   };

   const handleStyleModalCancel = (): void => {
      setStyleModalOpen(false);
      setEditingStyle({});
   };

   const handleEditStyles = (id: number): void => {
      getStyleSheet(id).then((styleSheet) => {
         setEditingStyle({
            id: styleSheet.id,
            name: styleSheet.name,
            css: styleSheet.css,
         });
         setStyleModalOpen(true);
      });
   };

   const handleDownload = (id: number): void => {
      getStyleSheet(id).then((styleSheet) => {
         const element = document.createElement('a');
         element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(styleSheet.css),
         );
         element.setAttribute('download', styleSheet.name + '.css');

         element.style.display = 'none';
         document.body.appendChild(element);

         element.click();

         document.body.removeChild(element);
      });
   };

   const handleStyleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setEditingStyle((prevEditingStyle) => ({
         ...prevEditingStyle,
         name: value,
      }));
   };

   const handleStyleModalSave = (): void => {
      setStyleModalOpen(false);
      setIsFetching(true);
      if (editingStyle.id && editingStyle.name && editingStyle.css) {
         updateStyleSheet(editingStyle.id, editingStyle.name, editingStyle.css)
            .finally(() => getStyleSheets())
            .catch(() => alert('Failed to update style'));
      } else if (editingStyle.name && editingStyle.css) {
         newStyleSheet(editingStyle.name, editingStyle.css)
            .finally(() => getStyleSheets())
            .catch(() => alert('Failed to make new style'));
      }
      setEditingStyle({});
   };

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      setIsFetchingFile(true);
      const file = event.target.files?.[0];
      if (file) {
         reader.readAsText(file);
      } else {
         setIsFetchingFile(false);
      }
   };

   if (isFetching) {
      return <Loader />;
   }

   return (
      <div className='content-main equal-margin instructor-one-time-code'>
         <DocumentTitle>Style Sheets</DocumentTitle>

         <div className='card course-card'>
            <div className='card-title full-width'>
               <div className='title'>Sections</div>
               <div>
                  <div className='action-buttons'>
                     <Button line onClick={handleNewStyleSheet}>
                        + Add New stylesheet
                     </Button>
                  </div>
               </div>
            </div>
            <div className='content-form sections-table'>
               <div className='row'>
                  <div className='col-xs-12'>
                     <table className='rwd-table'>
                        <tbody>
                           <tr className='head-row'>
                              <th>Name</th>
                              <th>Actions</th>
                           </tr>
                           {styleSheetsOptions.map(({ id, name }) => (
                              <tr key={id}>
                                 <td data-th='Name'>{name}</td>
                                 <td data-th='Actions'>
                                    <div
                                       className='icon-action'
                                       onClick={() => handleEditStyles(id)}
                                    >
                                       <IconHighlight />
                                    </div>
                                    <div className='icon-action' onClick={() => handleDownload(id)}>
                                       <IconPrinter />
                                    </div>
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            {styleModalOpen && (
               <ModalDialog
                  appearance={Appearance.primary}
                  heading={`${editingStyle.id ? 'Edit ' : 'New'} Section`}
                  onClose={handleStyleModalCancel}
                  animations={{ enter: 'animated bounceInDown' }}
                  bodyClassName='edit-sections-modal'
                  actions={[
                     {
                        text: 'Save',
                        onClick: handleStyleModalSave,
                        disabled: isFetchingFile || editingStyle.name?.length === 0,
                        loading: isFetchingFile,
                     },
                     { text: 'Cancel', onClick: handleStyleModalCancel },
                  ]}
               >
                  <div className='row margin-bottom-m'>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Name</label>
                        <input
                           type='text'
                           autoFocus
                           name='styleName'
                           placeholder='Style Sheet Name'
                           value={editingStyle.name ?? 's'}
                           onChange={handleStyleNameChange}
                        />
                     </div>
                     <div className='col-xs-12 col-sm-6'>
                        <label className='field-title'>Upload File</label>
                        <span className='upload-style' onClick={() => fileInput.current?.click()}>
                           {fileInput.current?.files?.[0]
                              ? fileInput.current.files[0].name
                              : 'Upload Css File'}
                        </span>
                        <input
                           type='file'
                           accept='.css'
                           style={{ display: 'none' }}
                           onChange={handleFileChange}
                           ref={fileInput}
                        />
                     </div>
                  </div>
               </ModalDialog>
            )}
         </div>
      </div>
   );
};

export default StyleSheets;
