import * as React from 'react';

import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';

const RecordingPrompt: React.FC = () => (
   <div className='row'>
      <div className='col-xs-12'>
         <div className='activity-builder-audio no-padding-left'>
            <div className='activity-item-icon disabled'>
               <IconBuilderRecording aria-hidden />
            </div>
            <p>Student records their response here</p>
         </div>
      </div>
   </div>
);

export default RecordingPrompt;
