import * as React from 'react';

import { ProductPrice } from '@services/ProductsService';

import Button from '@components/Common/Button';

interface ChooseProductProps {
   productPrices: readonly ProductPrice[];
   selectedProduct: ProductPrice;
   onProductSelected(product: ProductPrice): void;
   onNext(): void;
   togglePaymentMethod(): void;
}

const ChooseProduct: React.FC<ChooseProductProps> = ({
   productPrices,
   selectedProduct,
   onProductSelected,
   togglePaymentMethod,
   onNext,
}) => {
   const handleNext = (): void => {
      onNext();
   };

   return (
      <div>
         <div className='product-options'>
            {productPrices.map((productPrice) => (
               <div className='row' key={productPrice.products.map((i) => i.id).join('.')}>
                  <div className='col-xs-12 product-option'>
                     <label>
                        <input
                           name='product'
                           type='radio'
                           checked={selectedProduct === productPrice}
                           onChange={() => onProductSelected(productPrice)}
                        />
                     </label>
                     <span className='total-price'>${productPrice.totalPrice}</span>
                     <div>
                        {productPrice.products.map((product) => (
                           <p key={product.id}>
                              <span>{product.name}:</span> {product.licenseDurationInMonths} - Month
                              Access (${product.price})
                           </p>
                        ))}
                     </div>
                  </div>
               </div>
            ))}
         </div>
         <Button className='payment-btn' onClick={handleNext} fullWidth disabled={!selectedProduct}>
            Next
         </Button>
         <hr />
         <div className='switch-payment-container'>
            <label>Have a textbook code?</label>
            <Button line onClick={togglePaymentMethod} data-test='purchase-access-btn'>
               Use Code
            </Button>
         </div>
      </div>
   );
};

export default ChooseProduct;
