import * as _ from 'lodash';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import AccountType from '@models/AccountType';
import Breadcrumbs, { BreadcrumbsNavigation, ContextInfo } from '@models/Breadcrumbs';
import Content, { ContentType } from '@models/Content';
import ContentFolder from '@models/ContentFolder';
import ContentItemProfile, { GlobalContentItemProfile } from '@models/ContentItemProfile';
import { ContentLibrary } from '@models/ContentLibrary';
import ContentLibraryFilters from '@models/ContentLibraryFilters';
import ContentLibraryName from '@models/ContentLibraryName';
import { Maybe, MessageResponse } from '@models/Core';
import PagedResponse from '@models/PagedResponse';
import { Location } from 'react-router-dom';

import Constants from '../Constants';
import AxiosService from './AxiosService';
import HttpService from './HttpService';

interface BreadcrumbsParameters {
   accountType: AccountType;
   canEditContent: boolean;
   contentId: Maybe<number>;
   contentName: string;
   contentType: ContentType;
   createdBy: Maybe<number>;
   folderId: Maybe<number>;
   moduleItemId?: Maybe<number>;
   submissionId?: Maybe<number>;
   userId: number;
}

interface BreadcrumbsResponse {
   courseName: string;
   courseId: number;
   moduleName: string;
   moduleId: number;
   prev: Maybe<BreadcrumbsNavigation>;
   next: Maybe<BreadcrumbsNavigation>;
}

const {
   routes: {
      content: {
         bulkVideoUpload,
         editActivity,
         editLesson,
         editVocabSet,
         previewActivity,
         viewLesson,
         viewVideo,
         viewVocabSet,
      },
   },
} = Constants;

const makeQueryString = (obj: ContentLibraryFilters): string => {
   const params = new URLSearchParams();
   Object.entries(obj).forEach(([k, v]) => {
      const key = _.snakeCase(k);
      if (_.isArray(v)) {
         v.forEach((i) => {
            params.append(key, i);
         });
      } else if (_.isString(v) && !!v) {
         params.append(key, v);
      } else if (_.isNumber(v)) {
         params.append(key, v.toString());
      }
   });

   return params.toString();
};

const modifySchoolLibrary = (
   contentId: number,
   organizationId: number,
   inSchool: boolean,
): Promise<void> => {
   if (inSchool) {
      return HttpService.postWithAuthToken<MessageResponse>(
         `/api/organizations/${organizationId}/content`,
         snakeCaseKeys({ contentId }),
      ).then();
   } else {
      return HttpService.deleteWithAuthToken<MessageResponse>(
         `/api/organizations/${organizationId}/content/${contentId}`,
      ).then();
   }
};

const modifyGlobalLibrary = (contentId: number, isGlobal: boolean): Promise<void> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      '/api/content/global',
      snakeCaseKeys({ contentId, isGlobal }),
   ).then();

const duplicateContent = (
   contentId: number,
   data = {},
): Promise<{ msg: string; id: number; name: string }> =>
   HttpService.postWithAuthToken<{ msg: string; id: number; name: string }>(
      `/api/content/${contentId}/duplicate`,
      snakeCaseKeys(data),
   ).then((response) => response.data);

const deleteContent = (contentId: number): Promise<void> =>
   HttpService.deleteWithAuthToken<MessageResponse>(`/api/content/${contentId}`).then(
      () => undefined,
   );

const patchContent = (contentId: number, update: Partial<Content>): Promise<void> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/content/${contentId}`,
      snakeCaseKeys(update),
   ).then();

const getPersonalContent = (): Promise<ContentLibrary> =>
   HttpService.getWithAuthToken<ContentLibrary>('/api/content/personal').then(
      (response) => response.data,
   );

const getSharedContent = (): Promise<ContentLibrary> =>
   HttpService.getWithAuthToken<ContentLibrary>('/api/content/shared').then(
      (response) => response.data,
   );

const getSchoolContent = (
   organizationId: number,
   filters: ContentLibraryFilters,
): Promise<PagedResponse<ContentItemProfile>> =>
   HttpService.getWithAuthToken<PagedResponse<ContentItemProfile>>(
      `/api/organizations/${organizationId}/content?${makeQueryString(filters)}`,
   ).then((response) => response.data);

const getGlobalContent = (
   filters: ContentLibraryFilters,
): Promise<PagedResponse<GlobalContentItemProfile>> =>
   HttpService.getWithAuthToken<PagedResponse<GlobalContentItemProfile>>(
      `/api/content/global?${makeQueryString(filters)}`,
   ).then((response) => response.data);

const getEditUrl = ({ itemType, itemId }: ContentItemProfile): string => {
   switch (itemType) {
      case ContentType.activity:
         return editActivity.replace(':activityId', itemId.toString());
      case ContentType.lesson:
         return editLesson.replace(':lessonId', itemId.toString());
      case ContentType.vocabSet:
         return editVocabSet.replace(':vocabSetId', itemId.toString());
      case ContentType.video:
         return `${bulkVideoUpload}?videoId=${itemId}`;
      default:
         console.warn(`Unexpected item type: ${itemType}`);
         return '';
   }
};

const getViewUrl = ({ itemType, itemId }: ContentItemProfile): string => {
   switch (itemType) {
      case ContentType.activity:
         return previewActivity.replace(':activityId', itemId.toString());
      case ContentType.lesson:
         return viewLesson.replace(':lessonId', itemId.toString());
      case ContentType.vocabSet:
         return viewVocabSet.replace(':vocabSetId', itemId.toString());
      case ContentType.video:
         return viewVideo.replace(':videoId', itemId.toString());
      default:
         console.warn(`Unexpected item type: ${itemType}`);
         return '';
   }
};

const getBreadcrumbs = async (
   {
      accountType,
      canEditContent = false,
      contentId,
      contentName,
      contentType,
      createdBy,
      folderId: folderIdParam,
      moduleItemId: moduleItemIdParam,
      submissionId,
      userId,
   }: BreadcrumbsParameters,
   location: Location,
): Promise<Breadcrumbs> => {
   const moduleItemId = moduleItemIdParam || getQueryParameterAsNumber(location, 'moduleItemId');
   const folderId = folderIdParam || getQueryParameterAsNumber(location, 'folder');
   const contentContext: ContextInfo = {
      contentId,
      contentType,
      canEditContent,
      moduleItemId,
   };
   const {
      routes: {
         courses: { dashboard, viewModule },
         contentLibrary: { root, folder },
      },
   } = Constants;
   if (moduleItemId) {
      const params: Record<string, number> = { moduleItemId: Number(moduleItemId) };
      if (submissionId) {
         params.submissionId = submissionId;
      }
      const encodedQuery = new URLSearchParams(snakeCaseKeys(params));
      return HttpService.getWithAuthToken<BreadcrumbsResponse>(`/api/breadcrumbs?${encodedQuery}`, {
         handleNotFound: false,
      })
         .then((profileResponse) => {
            const { courseName, courseId, moduleId, moduleName, prev, next } = profileResponse.data;
            return {
               breadcrumbs: [
                  {
                     link: dashboard.replace(':courseId', courseId.toString()),
                     text: courseName,
                     contextInfo: { courseId, courseName },
                  },
                  {
                     link: viewModule
                        .replace(':courseId', courseId.toString())
                        .replace(':moduleId', moduleId.toString()),
                     text: moduleName,
                     contextInfo: { moduleId, moduleName },
                  },
                  {
                     link: location.pathname,
                     text: contentName,
                     contextInfo: contentContext,
                  },
               ],
               next,
               prev,
            };
         })
         .catch(() => ({ breadcrumbs: [], prev: null, next: null }));
   } else if (accountType === AccountType.instructor) {
      if (createdBy === userId && folderId) {
         return HttpService.getWithAuthToken<{
            ancestors: readonly ContentFolder[];
         }>(`/api/content/folders/${folderId}/ancestors`, {
            handleNotFound: false,
         })
            .then(async (folderResponse) => {
               const { ancestors } = folderResponse.data;
               const breadcrumbs = {
                  breadcrumbs: [
                     { link: root, text: 'Content Library' },
                     ...ancestors.map((i) => ({
                        link: folder
                           .replace(':libraryName', ContentLibraryName.personal)
                           .replace(':folderId', i.id.toString()),
                        text: i.name,
                     })),
                     {
                        link: location.pathname,
                        text: contentName,
                        contextInfo: contentContext,
                     },
                  ],
                  prev: null,
                  next: null,
               };
               return breadcrumbs;
            })
            .catch(() => ({ breadcrumbs: [], prev: null, next: null }));
      } else {
         return {
            breadcrumbs: [
               { link: root, text: 'Content Library' },
               {
                  link: location.pathname,
                  text: contentName,
                  contextInfo: contentContext,
               },
            ],
            prev: null,
            next: null,
         };
      }
   } else {
      return {
         breadcrumbs: [
            {
               link: location.pathname,
               text: contentName,
               contextInfo: contentContext,
            },
         ],
         next: null,
         prev: null,
      };
   }
};

const getPersonalFolders = async (): Promise<readonly ContentFolder[]> =>
   HttpService.getWithAuthToken<{ folders: readonly ContentFolder[] }>('/api/content/folders').then(
      (response) => response.data.folders,
   );

const transferOwnership = (contentId: number): Promise<string> =>
   AxiosService.patch<{ msg: string }>('/api/content/transfer_ownership', {
      contentId,
   }).then((response) => response.data.msg);

export default {
   deleteContent,
   duplicateContent,
   getBreadcrumbs,
   getGlobalContent,
   getViewUrl,
   getEditUrl,
   getPersonalContent,
   getPersonalFolders,
   getSchoolContent,
   getSharedContent,
   modifyGlobalLibrary,
   modifySchoolLibrary,
   patchContent,
   transferOwnership,
};
