import * as _ from 'lodash';
import * as React from 'react';

import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';
import Language from '@models/Language';
import DateTime from '@services/DateTimeService';
import classNames from 'classnames';

import Constants from '../../../../Constants';
import VocabSessionActions from '@components/VocabViewer/VocabSessionActions';
import VocabTermCard from '@components/VocabViewer/VocabTermCard';

interface VocabSetItemProps {
   name?: string;
   vocabSetId: number | null;
   progress?: Record<number, IVocabTermProgress>;
   language: Language;
   terms: readonly IVocabTerm[];
}

const InlineVocabSet: React.FC<VocabSetItemProps> = ({
   name,
   progress,
   terms,
   language,
   vocabSetId,
}) => {
   if (!terms) {
      return null;
   }

   const { vocabLearnedLevel } = Constants;

   const learnedCount = progress
      ? Object.values(progress).filter((i) => i.level >= vocabLearnedLevel).length
      : 0;

   const reviewCount = progress
      ? Object.values(progress).filter((i) => i.halfLife && i.halfLife <= DateTime.now()).length
      : 0;

   return (
      <div className='vocab-set-term-container'>
         {progress && (
            <div
               className={classNames(
                  'align-items-center',
                  'padding-s',
                  name ? 'space-between' : 'flex-end',
               )}
            >
               {name && <div className='title'>{name}</div>}
               <VocabSessionActions
                  language={language}
                  learnedCount={learnedCount}
                  reviewCount={reviewCount}
                  termCount={Object.values(progress).length}
                  vocabSetId={vocabSetId}
               />
            </div>
         )}
         <div className='row'>
            {terms.map((term, i) => (
               <VocabTermCard
                  key={term.id}
                  term={term}
                  hasImages={terms.some((j) => j.imageUrl)}
                  progress={progress?.[term.id]}
                  index={i}
                  language={language}
               />
            ))}
         </div>
      </div>
   );
};

export default InlineVocabSet;
