import { Maybe } from '@models/Core';
import Language from '@models/Language';

import { Example, ExampleLayout, Examples } from './types/Example';

export const getPrimaryTextLanguageFromExamples = (element: Element): Maybe<Language> => {
   const primaryTextElements = element.querySelectorAll('.primary-text');
   if (primaryTextElements.length === 0) {
      return null;
   }

   const primaryLanguage = primaryTextElements[0].getAttribute('lang');
   for (const elem of primaryTextElements) {
      if (elem.getAttribute('lang') !== primaryLanguage) {
         return null;
      }
   }

   if (primaryLanguage) {
      return primaryLanguage as Language;
   }

   return null;
};

export const parseExample = (element: Element): Example => {
   const primaryTextElement = element.querySelector('.primary-text');
   const secondaryTextElement = element.querySelector('.secondary-text');
   const imgElement = element.querySelector('.example-image img');

   const primaryText = primaryTextElement?.textContent || '';
   const secondaryText = secondaryTextElement?.textContent || '';
   const imgSrc = imgElement?.getAttribute('src') || '';

   return { primaryText, secondaryText, imgSrc };
};

export const parseExampleContainer = (element: Element): Examples => {
   const examples = element.querySelectorAll('.example');
   const layout: ExampleLayout = element.classList.contains('horizontal')
      ? ExampleLayout.horizontal
      : ExampleLayout.vertical;
   return {
      layout,
      language: getPrimaryTextLanguageFromExamples(element),
      examples: [...examples].map(parseExample),
   };
};
