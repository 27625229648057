import * as React from 'react';

import IconVideoRecording from '@icons/nova-line/15-Video-Meeting/video-call-1.svg';

import EmptyState from '@components/Core/EmptyState';

const VideoPrompt: React.FC = () => (
   <div className='row'>
      <div className='col-xs-12'>
         <div className='activity-builder-video no-padding-left'>
            <EmptyState
               icon={<IconVideoRecording aria-hidden />}
               description='Student video recording will appear here'
            />
         </div>
      </div>
   </div>
);

export default VideoPrompt;
