import * as React from 'react';

import { isPrompt } from '@components/Activity/Utils';
import Button from '@components/Common/Button';
import { Container, Draggable } from '@components/Core/DragAndDrop';
import DNDKitDraggable from '@components/Core/DragAndDropKit/Draggable';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import { ActivityBuilderMode, ActivityItem } from '@models/Activity';
import classnames from 'classnames';

import Constants from '../../../Constants';
import { DEFAULT_CONTENT_ITEMS, DEFAULT_PROMPTS, getDefaultContentByIndex } from './ActivityItems';
import ActivityItemTile from './ActivityItemTile';

interface ActivityBuilderSidebarProps {
   addQuestion(): void;
   handleDoubleClick(
      event: React.MouseEvent<HTMLDivElement>,
      tile: ActivityItem<ActivityBuilderMode>,
   ): void;
   handleEnter(
      event: React.KeyboardEvent<HTMLDivElement>,
      tile: ActivityItem<ActivityBuilderMode>,
   ): void;
}

const ActivityBuilderSidebar: React.FC<ActivityBuilderSidebarProps> = ({
   addQuestion,
   handleDoubleClick,
   handleEnter,
}) => {
   const { nonDragAreaSelector } = Constants;

   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const tileFactory = (
      tiles: readonly ActivityItem<ActivityBuilderMode>[],
   ): readonly React.ReactNode[] =>
      tiles.map((tile) => (
         <Draggable key={tile.itemType}>
            {isPrompt(tile) ? (
               <DNDKitDraggable id={tile.itemType} data={tile}>
                  <ActivityItemTile
                     itemType={tile.itemType}
                     handleDoubleClick={(e) => handleDoubleClick(e, tile)}
                     handleEnter={(e) => handleEnter(e, tile)}
                  />
               </DNDKitDraggable>
            ) : (
               <ActivityItemTile
                  itemType={tile.itemType}
                  handleDoubleClick={(e) => handleDoubleClick(e, tile)}
                  handleEnter={(e) => handleEnter(e, tile)}
               />
            )}
         </Draggable>
      ));

   return (
      <div className='activity-sidebar builder'>
         <div className='add-question'>
            <Button
               fullWidth
               line
               onClick={addQuestion}
               className={classnames(getOnboardingClassName?.('add_question_section'))}
               data-tour='add-question-section'
            >
               <IconAddSmall aria-hidden />
               Add Question Section
            </Button>
         </div>
         <div className='activity-items'>
            <div data-tour='activity-content-items'>
               <div className='title small gray-text margin-bottom-xs margin-left-s'>Content</div>
               <Container
                  behaviour='copy'
                  dragClass='is-dragging'
                  getChildPayload={(i) => getDefaultContentByIndex(i)}
                  groupName='builder'
                  nonDragAreaSelector={nonDragAreaSelector}
               >
                  {tileFactory(DEFAULT_CONTENT_ITEMS)}
               </Container>
            </div>
            <div data-tour='activity-prompt-items'>
               <div className='title small gray-text margin-top-s margin-bottom-xs margin-left-s'>
                  Prompts
               </div>
               <Container
                  behaviour='copy'
                  dragClass='is-dragging'
                  getChildPayload={(i) => DEFAULT_PROMPTS[i]}
                  groupName='builder'
                  nonDragAreaSelector={nonDragAreaSelector}
               >
                  {tileFactory(DEFAULT_PROMPTS)}
               </Container>
            </div>
         </div>
      </div>
   );
};

export default React.memo(ActivityBuilderSidebar);
