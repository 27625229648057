import { SortByEntry } from '@components/Common/Table';
import EncodingUtils from '@helpers/EncodingUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import BasicUserProfile from '@models/BasicUserProfile';
import { LandingPageContent } from '@models/LandingPageContent';
import { AdminNationalExam, FullNationalExam } from '@models/NationalExam';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';

import AxiosService from './AxiosService';
import HttpService, { HttpServiceRequestOptions } from './HttpService';
import { NationalExamCourseSummary } from './NationalExamAdminService';

const BASE_API = '/api/admin/national_exams';

export type NationalExamFormInput = Pick<
   FullNationalExam,
   | 'examYear'
   | 'hasLingcoRegistration'
   | 'hasPublicPracticeCourses'
   | 'invoiceTemplate'
   | 'lateFee'
   | 'lateRegDate'
   | 'memberPrice'
   | 'membershipName'
   | 'name'
   | 'nameAbbr'
   | 'nonMemberFee'
   | 'orderSpreadsheet'
   | 'orderWorksheet'
   | 'paperRegEndDate'
   | 'price'
   | 'regEndDate'
   | 'regStartDate'
   | 'shortName'
   | 'handlingFeeDomesticLarge'
   | 'handlingFeeDomesticSmall'
   | 'handlingFeeInternational'
   | 'seatCutoff'
   | 'shouldCacheResults'
> & {
   landingPageContentGreeting: string;
   landingPageContentTitle: string;
};
type PostExamResponse = {
   exam: FullNationalExam;
   landingPageContent: LandingPageContent;
};

type PostExamCourseResponse = {
   nationalExamCourseSummaries: NationalExamCourseSummary[];
};

export type NationalExamCourseInput = {
   courseId: number;
   examModuleIds: string[];
   practiceModuleIds: string[];
   categories: string[];
   isPractice: boolean;
   levelName?: string | null;
   levelSortOrder?: number | null;
   requiresRegistration: boolean;
   shouldEnforceCategoryAssignment: boolean;
   shouldGetDefaultExamCoursePermissions: boolean;
};

export type ExtendEndDatesInput = {
   examId: number;
   newEndDate: Date;
};

export type NationalExamAdminInput = {
   examId: number;
   userId: number;
};

type UpdateExamAdminsResponse = {
   updatedAdmins: BasicUserProfile[];
};

const getExams = (
   { predicate, ...rest }: PagedSearchFilters,
   sortOrder?: readonly SortByEntry[],
   options?: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<AdminNationalExam>> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(rest));
   if (sortOrder) {
      const str = JSON.stringify(sortOrder);
      encodedQuery.set('order_by', EncodingUtils.stringToB64(str));
   }
   return HttpService.getWithAuthToken<PagedResponse<AdminNationalExam>>(
      `${BASE_API}?${encodedQuery}`,
      options,
   ).then((response) => response.data);
};

const getCheaterReport = (examId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/${examId}/cheater_report`, {
      downloadFile: true,
   }).then((response) => response.data);

const getBinaryReport = (examId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/${examId}/binary_report`).then((response) => response.data);

const getExamReliabilityCoefficient = (examId: number | string): Promise<void> =>
   AxiosService.post(`${BASE_API}/${examId}/reliability_coefficient_report`).then();

const createNationalExam = async ({
   landingPageContentGreeting,
   landingPageContentTitle,
   ...nationalExam
}: NationalExamFormInput): Promise<PostExamResponse> =>
   AxiosService.post<PostExamResponse>(
      `${BASE_API}`,
      {
         nationalExam: { ...nationalExam },
         landingPageContentTitle,
         landingPageContentGreeting,
      },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then((response) => response.data);

const updateNationalExam = async (
   examId: number | string,
   { landingPageContentGreeting, landingPageContentTitle, ...nationalExam }: NationalExamFormInput,
): Promise<PostExamResponse> =>
   AxiosService.put<PostExamResponse>(
      `${BASE_API}/${examId}`,
      {
         nationalExam: { ...nationalExam },
         landingPageContentTitle,
         landingPageContentGreeting,
      },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then((response) => response.data);

const extendExamEndDate = async (input: ExtendEndDatesInput): Promise<void> =>
   AxiosService.put(`${BASE_API}/${input.examId}/end_date`, {
      endDate: input.newEndDate,
   });

const connectCourseToNationalExam = async (
   examId: number | string,
   input: NationalExamCourseInput,
): Promise<PostExamCourseResponse> =>
   AxiosService.post<PostExamCourseResponse>(
      `${BASE_API}/${examId}/national_exam_courses`,
      {
         nationalExamCourse: { ...input },
      },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then((response) => response.data);

const addExamAdmin = async (input: NationalExamAdminInput): Promise<UpdateExamAdminsResponse> =>
   AxiosService.post(`${BASE_API}/${input.examId}/exam_admin`, {
      nationalExamAdminUserId: input.userId,
   }).then((response) => response.data);

const removeExamAdmin = async (input: NationalExamAdminInput): Promise<UpdateExamAdminsResponse> =>
   AxiosService.delete(`${BASE_API}/${input.examId}/exam_admin/${input.userId}`).then(
      (response) => response.data,
   );

// const updateNationalExamCourse

export default {
   addExamAdmin,
   connectCourseToNationalExam,
   createNationalExam,
   extendExamEndDate,
   getExams,
   getBinaryReport,
   getCheaterReport,
   getExamReliabilityCoefficient,
   removeExamAdmin,
   updateNationalExam,
};
