import { Maybe } from '@models/Core';
import tinymce, { Editor } from 'tinymce';

const findNearestTag = (editor: Editor, element: Element, nodeName: string): Maybe<Node> => {
   // Create a TreeWalker that starts at the given element and walks up the tree
   const treeWalker = new tinymce.dom.TreeWalker(element, editor.getBody());

   // Keep walking up the tree until you find an h1 element or reach the editor's body
   let currentNode: Maybe<Node> = null;
   while ((currentNode = treeWalker.prev())) {
      if (currentNode.nodeName.toLowerCase() === nodeName) {
         return currentNode;
      }
   }

   return null; // Return null if no h1 element is found
};

export { findNearestTag };
