// @ts-strict-ignore
import * as _ from 'lodash';

import EncodingUtils from '@helpers/EncodingUtils';
import { randomShortId } from '@helpers/RandomStringUtils';
import { PartialDeep } from 'type-fest';

import {
   BOOLEAN_COMPARISON_OPTIONS,
   DATE_COMPARISON_OPTIONS,
   NUMERIC_COMPARISON_OPTIONS,
   STRING_COMPARISON_OPTIONS,
} from './ComparisonOptions';
import {
   AbsoluteDatePredicate,
   Attribute,
   BooleanComparison,
   BooleanPredicate,
   OperatorType,
   Predicate,
   PredicateGroup,
   PrimitiveType,
   RelativeComparison,
   RelativeDatePredicate,
   StringComparison,
} from './Models';

const MONTH_ABBREVIATIONS = [
   'Jan',
   'Feb',
   'Mar',
   'Apr',
   'May',
   'Jun',
   'Jul',
   'Aug',
   'Sep',
   'Oct',
   'Nov',
   'Dec',
];

const isPredicateGroup = (predicate: Predicate | PredicateGroup): predicate is PredicateGroup =>
   Object.values(OperatorType).includes(predicate.type as OperatorType);

const isBooleanPredicate = (predicate: Predicate): predicate is BooleanPredicate =>
   predicate.type === 'boolean';

const isAbsoluteDate = (predicate: Predicate): predicate is AbsoluteDatePredicate =>
   predicate.type === 'date' && _.isObject(predicate.value);

const isRelativeDate = (predicate: Predicate): predicate is RelativeDatePredicate =>
   predicate.type === 'date' && _.isString(predicate.value);

const getInvalidFilterIds = (predicate: Predicate | PredicateGroup): readonly string[] => {
   const results = [];
   if (isPredicateGroup(predicate)) {
      results.push(..._.flatten(predicate.predicates.map((i) => getInvalidFilterIds(i))));
   } else {
      // pass on null, not null, true, or false checks
      const shouldIgnore =
         predicate.comparison.includes('known') || predicate.type === PrimitiveType.boolean;
      if (!shouldIgnore && (predicate.value === null || predicate.value === '')) {
         results.push(predicate.id);
      }
   }
   return results;
};

const getFilterIds = (predicate: Predicate | PredicateGroup): readonly string[] => {
   const results = [];
   if (isPredicateGroup(predicate)) {
      results.push(..._.flatten(predicate.predicates.map((i) => getFilterIds(i))));
   } else {
      results.push(predicate.id);
   }
   return results;
};

const flattenFilters = (predicate: Predicate | PredicateGroup): readonly Predicate[] => {
   const results = [];
   if (isPredicateGroup(predicate)) {
      results.push(..._.flatten(predicate.predicates.map((i) => flattenFilters(i))));
   } else {
      results.push(predicate);
   }
   return results;
};

const getDetails = (predicate: Predicate): string => {
   if (predicate.type === 'string') {
      const { label } = STRING_COMPARISON_OPTIONS.find((i) => i.value === predicate.comparison);
      return `${label} ${predicate.value}`;
   } else if (predicate.type === 'integer') {
      const { label } = NUMERIC_COMPARISON_OPTIONS.find((i) => i.value === predicate.comparison);
      return `${label} ${predicate.value}`;
   } else if (predicate.type === 'boolean') {
      const { label } = BOOLEAN_COMPARISON_OPTIONS.find((i) => i.value === predicate.comparison);
      return label;
   } else if (predicate.type === 'date') {
      if (predicate.comparison.includes('known') && predicate.value === null) {
         const { label } = DATE_COMPARISON_OPTIONS.find((i) => i.value === predicate.comparison);
         return label;
      } else {
         const dateType = isAbsoluteDate(predicate) ? 'absolute' : 'relative';
         const value = `${predicate.comparison}:${dateType}`;
         const { label } = DATE_COMPARISON_OPTIONS.find((i) => i.value === value);
         if (isAbsoluteDate(predicate)) {
            const { month, day, year } = predicate.value;
            const monthName = MONTH_ABBREVIATIONS[month - 1];
            return `${label} ${monthName} ${day}, ${year}`;
         } else if (isRelativeDate(predicate)) {
            const singular = parseInt(predicate.value, 10) === 1;
            return `${label} ${predicate.value} ${singular ? 'day' : 'days'} ago`;
         }
      }
   }
   return null;
};

const getNewPredicate = (attribute: string, attributes: readonly Attribute[]): Predicate => {
   const attributeObj = attributes.find((i) => i.identifier === attribute);
   if (!attributeObj) {
      console.error(`Attribute ${attribute} not in provided attributes`);
      return null;
   }
   const { type } = attributeObj;
   if (type === PrimitiveType.string) {
      return {
         attribute,
         id: randomShortId(),
         type,
         value: '',
         comparison: StringComparison.equals,
      };
   } else if (type === PrimitiveType.boolean) {
      return {
         attribute,
         id: randomShortId(),
         type,
         value: '',
         comparison: BooleanComparison.true,
      };
   } else if (type === PrimitiveType.date) {
      return {
         attribute,
         id: randomShortId(),
         type,
         value: '',
         comparison: RelativeComparison.is,
      };
   } else if (type === PrimitiveType.integer || type === PrimitiveType.float) {
      return {
         attribute,
         id: randomShortId(),
         type,
         value: '',
         comparison: RelativeComparison.is,
      };
   }
   return null;
};

const removeFilterIds = (
   predicate: Predicate | PredicateGroup,
): PartialDeep<Predicate | PredicateGroup> => {
   if (isPredicateGroup(predicate)) {
      const { id, ...rest } = predicate;
      return {
         ...rest,
         predicates: predicate.predicates.map((i) => removeFilterIds(i)),
      };
   } else {
      const { id, ...rest } = predicate;
      return rest;
   }
};

const encodePredicate = (predicate: PredicateGroup): string => {
   const str = JSON.stringify(removeFilterIds(predicate));
   return EncodingUtils.stringToB64(str);
};

const decodePredicateStr = (predicateStr: string): PredicateGroup =>
   JSON.parse(EncodingUtils.b64ToString(predicateStr)) as PredicateGroup;

export {
   decodePredicateStr,
   encodePredicate,
   flattenFilters,
   getDetails,
   getFilterIds,
   getInvalidFilterIds,
   getNewPredicate,
   isAbsoluteDate,
   isBooleanPredicate,
   isPredicateGroup,
   isRelativeDate,
};
