import VideoContent from '@components/Activity/Completer/Content/VideoContent';
import { Maybe } from '@models/Core';

import { BaseActivityItem, NullableOnCreateOrEdit } from './ActivityItem';
import { ActivityMode } from './ActivityMode';
import { PromptType } from './ActivityPrompt';

export enum ActivityContentType {
   textContent = 'text_content',
   audioContent = 'audio_content',
   videoContent = 'video_content',
   lessonContent = 'lesson_content',
   vocabSet = 'vocab_set',
}

export enum ActivityItemVisibility {
   always = 'always',
   onceClosed = 'once_closed',
   onceGraded = 'once_graded',
}

export interface BaseActivityContent<Mode extends ActivityMode> extends BaseActivityItem<Mode> {
   itemType: NullableOnCreateOrEdit<Mode, ActivityContentType>;
   show: ActivityItemVisibility;
}

export interface TextContent<Mode extends ActivityMode> extends BaseActivityContent<Mode> {
   itemType: ActivityContentType.textContent;
   text: string;
}

export interface AudioContent<Mode extends ActivityMode> extends BaseActivityContent<Mode> {
   itemType: ActivityContentType.audioContent;
   originalFilename: Maybe<string>;
   storedFilename: Maybe<string>;
   fileUrl?: Maybe<string>;
   file?: File;
   blob?: Blob;
   limitPlaybacks: boolean;
   disablePausing: boolean;
   playbacks: Maybe<number>;
   recordUponCompletion: boolean;
   recordingDelay: Maybe<number>;
   /** Local variable to determine if recordingUponCompletion can be enabled. True when followed immediately by a AudioRecordingPrompt */
   sequenceEligible?: boolean;
}

export enum VideoSource {
   youTube = 'youtube',
   upload = 'upload',
   openTok = 'opentok',
   library = 'library',
}

export interface InteractiveVideoPromptTime<Mode extends ActivityMode> {
   videoId: Mode extends ActivityMode.create | ActivityMode.edit ? string | number : number;
   promptId: Mode extends ActivityMode.create | ActivityMode.edit ? string | number : number;
   promptTimeInSeconds: number;
   promptType: PromptType;
}

export interface VideoContent<Mode extends ActivityMode> extends BaseActivityContent<Mode> {
   itemType: ActivityContentType.videoContent;
   source: Maybe<VideoSource>;
   playSnippet: boolean;
   enableTargetSubtitles: boolean;
   enableNativeSubtitles: boolean;
   snippetStart: number;
   snippetEnd: number;
   isInteractiveVideo: boolean;
   promptTimes?: readonly InteractiveVideoPromptTime<Mode>[];
}

export interface YouTubeVideoContent<Mode extends ActivityMode> extends VideoContent<Mode> {
   source: VideoSource.youTube;
   youtubeId: string;
   fileUrl: string;
}

export interface OpenTokVideoContent<Mode extends ActivityMode> extends VideoContent<Mode> {
   source: VideoSource.openTok;
   sessionId: number;
   fileUrl: string;
}

export interface UploadedVideoContent<Mode extends ActivityMode> extends VideoContent<Mode> {
   source: VideoSource.upload;
   storedFilename: string;
   fileUrl?: string;
   file?: File;
}

export interface VideoLibraryContent<Mode extends ActivityMode> extends VideoContent<Mode> {
   itemType: ActivityContentType.videoContent;
   videoId: number;
}

export enum LessonContentDisplay {
   inline = 'inline',
   link = 'link',
   overlay = 'overlay',
}

export enum VocabSetItemDisplay {
   inline = 'inline',
   link = 'link',
   overlay = 'overlay',
}

export interface LessonContent<Mode extends ActivityMode> extends BaseActivityContent<Mode> {
   itemType: ActivityContentType.lessonContent;
   lessonId: Maybe<number>;
   display: LessonContentDisplay;
}

export interface VocabSetItem<Mode extends ActivityMode> extends BaseActivityContent<Mode> {
   itemType: ActivityContentType.vocabSet;
   vocabSetId: Maybe<number>;
   display: VocabSetItemDisplay;
}

type ActivityContent<Mode extends ActivityMode> =
   | TextContent<Mode>
   | AudioContent<Mode>
   | VideoContent<Mode>
   | LessonContent<Mode>
   | VocabSetItem<Mode>;

export { ActivityContent };
