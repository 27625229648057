import * as React from 'react';

import Link from '@components/Common/Link';
import Droplist from '@components/Core/Droplist';
import { NavigationItem } from '@components/Wrappers/Master';

interface NavbarTabletProps {
   navItems: readonly NavigationItem[];
}

const NavbarTablet: React.FC<NavbarTabletProps> = ({ navItems }) => {
   const renderItem = (item: NavigationItem): React.ReactNode | string => {
      const { external, sublist, text, to } = item;
      if (sublist) {
         return (
            <Droplist items={sublist.map((i) => ({ text: i.text, to: i.to }))}>{text}</Droplist>
         );
      } else if (to) {
         return (
            <Link external={external} to={to}>
               {text}
            </Link>
         );
      }
      return text;
   };

   return (
      <div className='navbar-tablet' role='navigation' aria-label='main navigation'>
         <ul>
            {navItems.map((item, i) => (
               <li className='menu-item' key={i}>
                  {renderItem(item)}
               </li>
            ))}
         </ul>
      </div>
   );
};

export default NavbarTablet;
