import { Maybe } from './Core';
import Language from './Language';
import WorldLocation from './WorldLocation';

export interface Speaker<EditMode extends boolean = false> {
   id: EditMode extends true ? Maybe<number> : number;
   name: string;
   languages: Language;
   gender?: SpeakerGender;
   age?: SpeakerAge;
   location: WorldLocation | null;
   imageFilename: Maybe<string>;
   imageUrl: Maybe<string>;
}

export const SpeakerAgeConstants = ['Child', 'Teenager', '20-30', '40-50', '60+'] as const;
export type SpeakerAge = (typeof SpeakerAgeConstants)[number];

export const SpeakerGenderConstants = ['Male', 'Female', 'Other'] as const;
export type SpeakerGender = (typeof SpeakerGenderConstants)[number];
