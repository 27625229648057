import * as React from 'react';

import Avatar from '@components/Core/Avatar';
import ModalDialog from '@components/Core/ModalDialog';
import { IAction } from '@components/Core/ModalDialog/Modal';
import {
   AsyncSelect,
   Menu,
   MenuProps,
   selectComponents,
   selectStyle,
   selectTheme,
} from '@components/Core/Select';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import IconBooksApple from '@icons/nova-solid/41-School&Science/books-apple.svg';
import IconPlanetBook from '@icons/nova-solid/41-School&Science/planet-book.svg';
import Appearance from '@models/Appearance';
import BasicUserProfile from '@models/BasicUserProfile';
import ContentLibraryName from '@models/ContentLibraryName';
import { Maybe } from '@models/Core';
import { LoadOptions, NumericOption, OptionsCallback } from '@models/ReactSelectHelperTypes';
import HttpService from '@services/HttpService';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import ShareModalRow from './ShareModalRow';

export interface UserPartial extends BasicUserProfile {
   permission?: string;
   schoolName?: string;
   contentPermissionId?: number;
   cloneable?: boolean;
}

export interface ShareContentModalProps {
   inGlobalLibrary: boolean;
   inSchoolLibrary: boolean;
   itemName: string;
   itemId: number;
   closeShareModal(): void;
   modifyGlobalLibrary(isGlobal: boolean): void;
   modifySchoolLibrary(inSchool: boolean): void;
}

interface ShareContentModalState {
   createdBy: Maybe<UserPartial>;
   inputValue: string;
   permissionsChanged: boolean;
   selectedDefaultOption: string;
   selectedUsers: readonly NumericOption[];
   sharedWith: readonly UserPartial[];
   updatedInGlobalLibrary: boolean;
   updatedInSchoolLibrary: boolean;
}

const ShareContentModal: React.FC<ShareContentModalProps> = ({
   inGlobalLibrary,
   inSchoolLibrary,
   itemName,
   itemId,
   closeShareModal,
   modifyGlobalLibrary,
   modifySchoolLibrary,
}) => {
   const { schoolProfile, districtProfile, userProfile } =
      React.useContext<AppStateContext>(AppStateContext);

   const [state, setState] = React.useState<ShareContentModalState>({
      createdBy: null,
      inputValue: '',
      selectedDefaultOption: 'view',
      selectedUsers: [],
      sharedWith: [],
      permissionsChanged: false,
      updatedInGlobalLibrary: inGlobalLibrary,
      updatedInSchoolLibrary: inSchoolLibrary,
   });
   const globalLibraryEnabled =
      userProfile?.features.includes(Constants.features.addToGlobalLibrary) ?? false;

   React.useEffect(() => {
      HttpService.getWithAuthToken<{ createdBy: UserPartial; sharedWith: readonly UserPartial[] }>(
         `/api/content/${itemId}`,
      ).then((response) => {
         const { createdBy, sharedWith } = response.data;
         setState((prevState) => ({ ...prevState, createdBy, sharedWith }));
      });
   }, []);

   const handlePeopleChange = (newSelectedUsers: readonly NumericOption[]): void => {
      setState((prevState) => ({
         ...prevState,
         selectedUsers: newSelectedUsers === null ? [] : newSelectedUsers,
         inputValue: '',
      }));
   };

   const handleDefaultChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = event.target;
      setState((prevState) => ({ ...prevState, selectedDefaultOption: value }));
   };

   const handleGlobalLibraryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setState((prevState) => ({
         ...prevState,
         updatedInGlobalLibrary: checked,
         permissionsChanged: checked !== inGlobalLibrary ? true : prevState.permissionsChanged,
      }));
   };

   const handleSchoolLibraryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setState((prevState) => ({
         ...prevState,
         updatedInSchoolLibrary: checked,
         permissionsChanged: checked !== inSchoolLibrary ? true : prevState.permissionsChanged,
      }));
   };

   const handlePermissionChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const { name, value: permission } = event.target;
      const contentPermissionId = parseInt(name, 10);
      setState((prevState) => ({
         ...prevState,
         sharedWith: prevState.sharedWith.map((i) =>
            i.contentPermissionId === contentPermissionId ? { ...i, permission } : i,
         ),
         permissionsChanged: true,
      }));
   };

   const handleCloneableChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked: cloneable } = event.target;
      const contentPermissionId = parseInt(name, 10);
      setState((prevState) => ({
         ...prevState,
         sharedWith: prevState.sharedWith.map((i) =>
            i.contentPermissionId === contentPermissionId ? { ...i, cloneable } : i,
         ),
         permissionsChanged: true,
      }));
   };

   const handleRemoveAccess = (contentPermissionId: Maybe<number>): void => {
      if (contentPermissionId) {
         setState((prevState) => ({
            ...prevState,
            sharedWith: prevState.sharedWith.filter(
               (i) => i.contentPermissionId !== contentPermissionId,
            ),
            permissionsChanged: true,
         }));
      }
   };

   const userToValue = (user: BasicUserProfile): NumericOption => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName} <${user.email}>`,
   });

   const loadOptions: LoadOptions<NumericOption> = (
      inputValue: string,
      callback: OptionsCallback<NumericOption>,
   ): void => {
      if (inputValue) {
         const excludeIds = state.sharedWith.map((i) => i.id);
         if (state.createdBy) {
            excludeIds.push(state.createdBy.id);
         }
         HttpService.getWithAuthToken<{ users: readonly BasicUserProfile[] }>(
            `/api/users/search?q=${inputValue}`,
         ).then((response) => {
            callback(
               response.data.users.filter((i) => !excludeIds.includes(i.id)).map(userToValue),
            );
         });
      } else {
         callback([]);
      }
   };

   const handleInputChange = (inputValue: string): void => {
      setState((prevState) => ({ ...prevState, inputValue }));
   };

   const handleSaveChanges = (): void => {
      const { updatedInGlobalLibrary, updatedInSchoolLibrary } = state;
      const permissions = [
         ...state.sharedWith.map(({ contentPermissionId, cloneable, permission }) => ({
            id: contentPermissionId,
            cloneable,
            permission,
         })),
         ...state.selectedUsers.map(({ value: userId }) => ({
            id: null,
            userId,
            permission: state.selectedDefaultOption,
            cloneable: null,
         })),
      ];
      if (updatedInGlobalLibrary !== inGlobalLibrary) {
         modifyGlobalLibrary(updatedInGlobalLibrary);
      }
      if (updatedInSchoolLibrary !== inSchoolLibrary) {
         modifySchoolLibrary(updatedInSchoolLibrary);
      }
      HttpService.putWithAuthToken<{ sharedWith: readonly UserPartial[] }>(
         `/api/content/${itemId}/permissions`,
         snakeCaseKeys({ permissions }),
      ).then((response) => {
         const { sharedWith: updatedSharedwith } = response.data;
         setState((prevState) => ({
            ...prevState,
            sharedWith: updatedSharedwith,
            selectedUsers: [],
            permissionsChanged: false,
         }));
      });
   };

   const actionButtons = (): readonly IAction[] => {
      if (state.permissionsChanged || state.selectedUsers.length) {
         return [
            { text: 'Save', onClick: handleSaveChanges },
            { text: 'Cancel', onClick: closeShareModal },
         ];
      } else {
         return [{ text: 'Close', onClick: closeShareModal }];
      }
   };

   const renderUserIcon = ({
      firstName,
      lastName,
      id,
      profileImageUrl,
   }: BasicUserProfile): React.ReactNode => (
      <Avatar
         firstName={firstName}
         lastName={lastName}
         hashValue={id}
         src={profileImageUrl}
         size='large'
      />
   );

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='share-content-modal'
         heading={`Sharing Settings: ${itemName}`}
         onClose={closeShareModal}
         animations={{ enter: 'animated bounceInDown' }}
         footerClassName='card-footer'
         actions={actionButtons()}
      >
         <div className='static-library-rows'>
            {globalLibraryEnabled && (
               <ShareModalRow
                  rowType='library'
                  name='Global'
                  libraryType={ContentLibraryName.global}
                  icon={<IconPlanetBook className='icon-gray' aria-hidden />}
                  switchValue={state.updatedInGlobalLibrary}
                  handleSwitchChange={handleGlobalLibraryChange}
               />
            )}
            {schoolProfile && (
               <ShareModalRow
                  rowType='library'
                  libraryType={
                     districtProfile ? ContentLibraryName.district : ContentLibraryName.school
                  }
                  name={districtProfile?.name || schoolProfile.name}
                  icon={<IconBooksApple className='icon-gray' aria-hidden />}
                  switchValue={state.updatedInSchoolLibrary}
                  handleSwitchChange={handleSchoolLibraryChange}
               />
            )}
         </div>
         <div className='access-rows'>
            {state.createdBy && (
               <ShareModalRow
                  rowType='owner'
                  name={`${state.createdBy.firstName} ${state.createdBy.lastName} ${
                     state.createdBy.id === userProfile?.id ? '(you)' : '(creator)'
                  }`}
                  icon={renderUserIcon(state.createdBy)}
               />
            )}
            <div className='shared-with'>
               {!!state.sharedWith.length &&
                  state.sharedWith.map(
                     ({
                        schoolName,
                        permission,
                        contentPermissionId,
                        cloneable,
                        ...userDetails
                     }) => (
                        <ShareModalRow
                           rowType='user'
                           key={userDetails.id}
                           name={`${userDetails.firstName} ${userDetails.lastName}`}
                           icon={renderUserIcon(userDetails)}
                           switchValue={cloneable}
                           permissionId={contentPermissionId}
                           permissionValue={permission}
                           schoolName={schoolName}
                           handleSwitchChange={handleCloneableChange}
                           handleUserDelete={() => handleRemoveAccess(contentPermissionId)}
                           handlePermissionChange={handlePermissionChange}
                        />
                     ),
                  )}
            </div>
         </div>
         <div className='invite-people'>
            <label className='field-title'>Invite people</label>
            <div className='row'>
               <div className='col-xs-9'>
                  <AsyncSelect<NumericOption, true>
                     isMulti
                     isSearchable
                     placeholder='Enter names or email addresses...'
                     loadOptions={loadOptions}
                     onChange={handlePeopleChange}
                     inputValue={state.inputValue}
                     onInputChange={handleInputChange}
                     value={state.selectedUsers}
                     className='react-select'
                     theme={selectTheme}
                     components={{
                        ...selectComponents,
                        DropdownIndicator: () => null,
                        Menu: (props: MenuProps<NumericOption, true>) =>
                           state.inputValue ? <Menu {...props}>{props.children}</Menu> : <></>,
                     }}
                     noOptionsMessage={() => 'No Users Found'}
                     styles={selectStyle}
                  />
               </div>
               <div className='col-xs-3'>
                  <div className='default-permission'>
                     <select value={state.selectedDefaultOption} onChange={handleDefaultChange}>
                        <option value='view'>View</option>
                        <option value='edit'>Edit</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </ModalDialog>
   );
};

export default ShareContentModal;
