import * as React from 'react';

import Button from '@components/Common/Button';
import useMousetrap from '@hooks/use-mousetrap';
import IconDownwardLineTriangle from '@icons/activities/downward-line-triangle.svg';
import { ActivityMode, ActivityPrompt, ActivityResponseEvaluation } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import { isAutogradable } from '@components/Activity/Utils';
import SidebarProficiencyPills from './SidebarProficiencyPills';

interface SidebarProficiencyProps {
   evaluation: Partial<ActivityResponseEvaluation<ActivityMode.grade>>;
   prompt: ActivityPrompt<ActivityMode.grade>;
   onChange(isProficient: Maybe<boolean>): void;
}

const SidebarProficiency: React.FC<SidebarProficiencyProps> = ({
   evaluation,
   onChange,
   prompt,
}) => {
   const handleProficiencyChange = (isProficient: Maybe<boolean>): void => {
      let isProficientValue = isProficient;

      // Allows the user to unset the proficiency
      if (isProficientValue === evaluation.isProficient) {
         isProficientValue = null;
      }

      onChange(isProficientValue);
   };

   useMousetrap('y', () => {
      onChange(true);
   });

   useMousetrap('n', () => {
      onChange(false);
   });

   return (
      <div className='proficiency-container'>
         <div className='flex items-center between-xs'>
            <div className='flex items-center'>
               <IconDownwardLineTriangle fill='#9C9BA1' width='16px' height='16px' />
               <span className='proficiency-container-header'>CAN-DO</span>
            </div>
            <SidebarProficiencyPills prompt={prompt} />
         </div>

         <div className='can-do-statement padding-top-s padding-bottom-s title small'>
            {prompt.canDoStatementName}
         </div>

         {!isAutogradable(prompt) && (
            <>
               <div className='flex items-center'>
                  <Button
                     className={classnames('proficiency-yes-no-btn', {
                        active: evaluation.isProficient,
                     })}
                     onClick={() => handleProficiencyChange(true)}
                     data-test='proficiency-yes-btn'
                  >
                     Y
                  </Button>
                  <span className='proficiency-yes-no-label'>Yes, meets expectation</span>
               </div>
               <div className='flex items-center padding-top-s'>
                  {/*
               Needs to be a falsy check, because we use null as the third state to know if it
               has been set or not.
            */}
                  <Button
                     className={classnames('proficiency-yes-no-btn', {
                        active: evaluation.isProficient === false,
                     })}
                     onClick={() => handleProficiencyChange(false)}
                     data-test='proficiency-no-btn'
                  >
                     N
                  </Button>
                  <span className='proficiency-yes-no-label'>Does not yet meet expectation</span>
               </div>
            </>
         )}
      </div>
   );
};

export default SidebarProficiency;
