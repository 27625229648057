import * as React from 'react';

import IconGlobe from '@icons/general/icon-globe.svg'; // Custom
import IconLineNavigationFilterText from '@icons/nova-line/17-Navigation/navigation-filter-text.svg';
import IconLineContentBook4 from '@icons/nova-line/18-Content/content-book-4.svg';
import IconLineContentModules from '@icons/nova-line/18-Content/content-modules.svg';
import IconLineDataDownload4 from '@icons/nova-line/23-Data-Transfer/data-download-4.svg';
import IconLineVideoClip3 from '@icons/nova-line/36-Videos/video-clip-3.svg';
import IconSolidNavigationFilterText from '@icons/nova-solid/17-Navigation/navigation-filter-text.svg';
import IconSolidContentBook4 from '@icons/nova-solid/18-Content/content-book-4.svg';
import IconSolidContentModules from '@icons/nova-solid/18-Content/content-modules.svg';
import IconStarBox from '@icons/nova-solid/19-Vote&Rewards/vote-star-box.svg';
import IconSolidDataDownload4 from '@icons/nova-solid/23-Data-Transfer/data-download-4.svg';
import IconSolidVideoClip3 from '@icons/nova-solid/36-Videos/video-clip-3.svg';

import { ModuleItemType } from '@models/Course/ModuleItem';

interface ContentIconProps extends React.SVGProps<SVGElement> {
   itemType: ModuleItemType;
   line?: boolean;
   isRecommended?: boolean;
}

type ModuleIcons = Record<ModuleItemType, Record<'line' | 'solid', React.SVGFactory>>;

const ModuleIconsMap: ModuleIcons = {
   [ModuleItemType.activity]: {
      line: IconLineContentModules,
      solid: IconSolidContentModules,
   },
   [ModuleItemType.file]: {
      line: IconLineDataDownload4,
      solid: IconSolidDataDownload4,
   },
   [ModuleItemType.lesson]: {
      line: IconLineContentBook4,
      solid: IconSolidContentBook4,
   },
   [ModuleItemType.link]: {
      line: IconGlobe,
      solid: IconGlobe,
   },
   [ModuleItemType.video]: {
      line: IconLineVideoClip3,
      solid: IconSolidVideoClip3,
   },
   [ModuleItemType.vocabSet]: {
      line: IconLineNavigationFilterText,
      solid: IconSolidNavigationFilterText,
   },
};

const labelsMap: Record<ModuleItemType, string> = {
   [ModuleItemType.activity]: 'Activity',
   [ModuleItemType.file]: 'File',
   [ModuleItemType.lesson]: 'Lesson',
   [ModuleItemType.link]: 'Link',
   [ModuleItemType.video]: 'Video',
   [ModuleItemType.vocabSet]: 'Vocab Set',
};

const ContentIcon: React.FC<ContentIconProps> = ({
   itemType,
   isRecommended = false,
   line = false,
   ...rest
}) => {
   const lineOrSolid = line ? 'line' : 'solid';
   const Icon = ModuleIconsMap[itemType][lineOrSolid];
   const label = labelsMap[itemType];

   if (Icon && isRecommended) {
      return (
         <div className='position-relative'>
            <Icon {...rest} role='img' aria-label={label} />
            <IconStarBox
               viewBox='5 3 14 18'
               className='content-star-bottom-right'
               role='img'
               aria-label='Recommended'
            />
         </div>
      );
   }

   return <Icon {...rest} aria-label={label} />;
};

export default ContentIcon;
