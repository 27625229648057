import * as React from 'react';

import IconCalendarAdd from '@icons/nova-line/05-Time/calendar-add.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import AccountType from '@models/AccountType';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';

import { AppStateContext } from '../../AppState';
import TransferOwnershipButton from '@components/TransferOwnershipButton';

interface LessonHeaderProps {
   canEdit: boolean;
   isCreator: boolean;
   lessonId: number;
   moduleItemId: Maybe<number>;
   name: string;
   editLesson(): void;
   openAddToCourseModal(): void;
   onOwnershipTransfer(newCreatorId: number): void;
}

const LessonHeader: React.FC<LessonHeaderProps> = ({
   canEdit,
   isCreator,
   moduleItemId,
   name,
   lessonId,
   editLesson,
   openAddToCourseModal,
   onOwnershipTransfer,
}) => {
   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   const isInstructor = userProfile?.accountType === AccountType.instructor;

   return (
      <div className='card-title has-button'>
         <div className='content-title-wrapper'>
            <div className='title'>{name}</div>
            <div className='icon-action-wrap'>
               {canEdit && (
                  <Tippy content='Edit'>
                     <div className='icon-action' onClick={editLesson}>
                        <IconHighlight />
                     </div>
                  </Tippy>
               )}
               <TransferOwnershipButton
                  contentId={lessonId}
                  onOwnershipTransfer={onOwnershipTransfer}
                  isContentOwner={isCreator}
               />
               {moduleItemId === null && isInstructor && (
                  <Tippy content='Add to Course Module'>
                     <div className='icon-action' onClick={openAddToCourseModal}>
                        <IconCalendarAdd />
                     </div>
                  </Tippy>
               )}
            </div>
         </div>
      </div>
   );
};

export default LessonHeader;
