// @ts-strict-ignore
import { connect } from 'react-redux';

import App, { IAppProps } from './App';
import { IApplicationState } from './Models/ApplicationState';

const mapStateToProps = (state: IApplicationState, ownProps: Partial<IAppProps>): IAppProps => ({
   currentCourses: state.currentCourses,
   emit: ownProps.emit,
   socketEventEmitter: ownProps.socketEventEmitter,
   user: state.user,
});

export default connect<IAppProps>(mapStateToProps)(App);
