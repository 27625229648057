import { Maybe } from '@models/Core';
import Emitter from '@utilities/Emitter';
import axios, { AxiosResponse } from 'axios';
import { Subscription } from 'rxjs';

import Config from '../Config';
import Constants from '../Constants';
import HttpService from './HttpService';

const ONLINE_EVENT = 'lingco-online';
type SubscriptionCallback = (result: boolean) => void;
const eventEmitter = new Emitter();
let websocketConnected = false;
let interval: Maybe<NodeJS.Timeout> = null;

const setWebsocketConnectionStatus = (value: boolean): void => {
   websocketConnected = value;
};

const emitOnlineEvent = (value: boolean): void => {
   eventEmitter.emit(ONLINE_EVENT, value);
};

const navigatorCheck = (): void => {
   if (!navigator?.onLine) {
      emitOnlineEvent(false);
   }
};

const handleHealthResponse = (promise: Promise<AxiosResponse<unknown>>): void => {
   promise
      .then((resp) => {
         emitOnlineEvent(resp.status >= 200 && resp.status < 300);
      })
      .catch(() => {
         emitOnlineEvent(false);
      });
};

const checkOnlineStatus = (): void => {
   if (!websocketConnected) {
      // If on local dev let's keep using the health endpoint.
      // This way we don't have to enable COR's stuff in AWS for our other health endpoint.
      if (Config.isLocalDev) {
         const url = '/api/health';
         handleHealthResponse(HttpService.get(url));
      } else {
         // We don't want this health check to ever be cached, so we're going old-school
         const urlBuilder = new URL(Constants.awsHealthCheck);
         urlBuilder.searchParams.set('cacheBuster', new Date().getTime().toString());
         handleHealthResponse(axios.get(urlBuilder.href));
      }
   } else {
      emitOnlineEvent(true);
   }
};

const startEmitter = (): void => {
   stopEmitter();
   interval = setInterval(async () => {
      checkOnlineStatus();
   }, 15000);
   window.addEventListener('online', navigatorCheck);
   window.addEventListener('offline', navigatorCheck);
};

const stopEmitter = (): void => {
   if (interval) {
      clearInterval(interval);
      interval = null;
   }
   window.removeEventListener('online', navigatorCheck);
   window.removeEventListener('offline', navigatorCheck);
};

export const OnlineCheckService = {
   ONLINE_EVENT,
   start: startEmitter,
   stop: stopEmitter,
   setWebsocketConnectionStatus,
   subscribeTo: {
      online: (handler: SubscriptionCallback): Subscription =>
         eventEmitter.listen(ONLINE_EVENT, handler),
   },
};
