// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import IconCheckCircle2 from '@icons/nova-solid/02-Status/check-circle-2.svg';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import pluralize from 'pluralize';

import { ProgressBar } from '@components/Core/ProgressBar';
import { IDailyGoal } from './StudentVocabStats';

interface DailyGoalsProps {
   dailyGoals: readonly IDailyGoal[];
}

const DailyGoals: React.FC<DailyGoalsProps> = ({ dailyGoals }) => {
   const renderDailyGoal = (
      { action, completed, terms }: IDailyGoal,
      index: number,
   ): React.ReactNode => {
      const color = action === 'learn' ? 'green' : 'blue';
      const complete = completed >= terms;
      const percentage = (completed / terms) * 100;
      const goal = `${_.startCase(action)} ${terms} ${pluralize('Term', terms)}`;
      return (
         <div className='daily-goal' key={index}>
            <div className='header'>
               <div className={classnames('goal-text', { complete })}>{goal}</div>
               {complete && <IconCheckCircle2 className='icon-green' />}
            </div>
            <Tippy theme='lingco-yellow' content={`${Math.floor(percentage)}% Complete`}>
               <div>
                  <ProgressBar percentage={percentage} color={color} />
               </div>
            </Tippy>
         </div>
      );
   };

   return (
      <div className='card no-padding' style={{ height: '100%' }}>
         <div className='card-title'>
            <div className='title'>Daily Goals</div>
         </div>
         <div className='daily-goals'>{dailyGoals.map((goal, i) => renderDailyGoal(goal, i))}</div>
      </div>
   );
};

export default React.memo(DailyGoals);
