import * as React from 'react';

import { ID, Maybe } from '@models/Core';
import SchoolDetails from '@models/SchoolDetails';
import SchoolService from '@services/SchoolService';
import { mergeRefs } from '@utilities/Refs';
import { createPortal } from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

import Constants from '../Constants';
import Link from './Common/Link';
import { renderDateCell } from './Common/TableCellHelpers';

interface SchoolDetailsPopoverProps {
   organizationId: ID;
   schoolName: string;
}

const SchoolDetailsPopover: React.FC<SchoolDetailsPopoverProps> = ({
   organizationId,
   schoolName,
}) => {
   const {
      routes: {
         courses: { viewCourses },
         users: { viewUsers },
      },
   } = Constants;

   const [schoolDetails, setSchoolDetails] = React.useState<Maybe<SchoolDetails>>(null);
   const [showDetails, setShowDetails] = React.useState<boolean>(false);
   const [timeoutId, setTimeoutId] = React.useState<Maybe<NodeJS.Timeout>>(null);

   const referenceRef = React.useRef(null);
   const containerRef = React.useRef(null);

   React.useEffect(() => {
      if (showDetails && !schoolDetails) {
         SchoolService.getSchoolDetails(organizationId).then(setSchoolDetails);
      }
      if (timeoutId) {
         setTimeoutId(null);
      }
   }, [showDetails]);

   const handleMouseEnter = (): void => {
      setTimeoutId(
         setTimeout(() => {
            setShowDetails(true);
         }, 400),
      );
   };

   const handleMouseLeave = (): void => {
      setShowDetails(false);
      if (timeoutId) {
         clearTimeout(timeoutId);
      }
   };

   return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
         <Manager>
            <Reference>
               {({ ref }) => (
                  <span className='pointer' ref={mergeRefs(ref, referenceRef)}>
                     {schoolName}
                  </span>
               )}
            </Reference>
            {createPortal(
               <CSSTransition
                  in={showDetails}
                  unmountOnExit
                  timeout={300}
                  classNames='school-details-container'
               >
                  <Popper placement='bottom'>
                     {({ ref, style, placement, arrowProps }) => (
                        <div
                           ref={mergeRefs(ref, containerRef)}
                           style={style}
                           className='school-details-container'
                        >
                           {schoolDetails && showDetails && (
                              <>
                                 <div className='card school-details-card' ref={ref}>
                                    <div className='card-title'>
                                       <div className='title'>{schoolDetails.name}</div>
                                    </div>
                                    <div className='card-body'>
                                       {schoolDetails.city && schoolDetails.state && (
                                          <div className='text-sm gray-text'>
                                             {schoolDetails.city}, {schoolDetails.state}
                                          </div>
                                       )}
                                       <div className='flex'>
                                          <div className='flex-1'>
                                             <label className='field-title'>First Seen</label>
                                             <div className='text-sm black-text'>
                                                {renderDateCell(schoolDetails.firstSeen)}
                                             </div>
                                          </div>
                                          <div className='flex-1'>
                                             <label className='field-title'>Last Seen</label>
                                             <div className='text-sm black-text'>
                                                {renderDateCell(schoolDetails.lastSeen)}
                                             </div>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <div className='flex-1'>
                                             <label className='field-title'>Courses</label>
                                             <div className='text-sm'>
                                                <Link
                                                   to={`${viewCourses}?school.id=${schoolDetails.id}`}
                                                >
                                                   {schoolDetails.courseCount}
                                                </Link>
                                             </div>
                                          </div>
                                          <div className='flex-1'>
                                             <label className='field-title'>Users</label>
                                             <div className='text-sm'>
                                                <Link
                                                   to={`${viewUsers}?school.id=${schoolDetails.id}`}
                                                >
                                                   {schoolDetails.enrolledCount}
                                                </Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div
                                    className='popover-arrow'
                                    ref={arrowProps.ref}
                                    data-popper-placement={placement}
                                 />
                              </>
                           )}
                        </div>
                     )}
                  </Popper>
               </CSSTransition>,
               document.body,
            )}
         </Manager>
      </div>
   );
};

export default SchoolDetailsPopover;
