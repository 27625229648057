import * as React from 'react';

import IconCloseSmall from '@icons/general/icon-close-small.svg';
import {
   ActivityMode,
   ActivityResponseEvaluation,
   ActivityRubricItem,
   ActivityRubricItemGroup,
   ActivityRubricScoringType,
} from '@models/Activity';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';
import { formatPoints, getDraggableId } from './helpers';
import RubricEntryDescription from './RubricEntryDescription';
import RubricItem from './RubricItem';

export interface RubricItemGroupEntry extends ActivityRubricItemGroup {
   rubricItems: readonly ActivityRubricItem[];
}

interface RubricItemGroupProps {
   displayOnly: boolean;
   draggedEntryWithinGroup: boolean;
   dragIndex: Maybe<number>;
   droppablePlaceholder: React.ReactNode;
   evaluation: Maybe<ActivityResponseEvaluation<ActivityMode.grade>>;
   group: RubricItemGroupEntry;
   isDraggingDisabled?: boolean;
   isDraggingRubricEntry: boolean;
   isExpanded: boolean;
   scoringType: ActivityRubricScoringType;
   shortcutKey: string;
   createRubricItem?(data?: Partial<ActivityRubricItem>, focus?: boolean): Promise<number>;
   deleteRubricItem(itemId: number): void;
   deleteRubricItemGroup(): void;
   toggleActive(): void;
   toggleRubricItem(itemId: number): void;
   updateRubricItem(itemId: number, update: Partial<ActivityRubricItem>): void;
   updateRubricItemGroup(update: Partial<ActivityRubricItemGroup>): void;
}

const RubricItemGroup: React.FC<RubricItemGroupProps> = ({
   displayOnly,
   draggedEntryWithinGroup,
   dragIndex,
   droppablePlaceholder,
   evaluation,
   group,
   isExpanded,
   isDraggingDisabled = false,
   isDraggingRubricEntry,
   scoringType,
   shortcutKey,
   createRubricItem,
   deleteRubricItem,
   deleteRubricItemGroup,
   toggleActive,
   toggleRubricItem,
   updateRubricItem,
   updateRubricItemGroup,
}) => {
   const letterKeys = Array.from('QWERTYUIOP');
   const groupRef = React.useRef<Maybe<HTMLDivElement>>(null);
   const { description, rubricItems, isNew = false } = group;

   const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = React.useState<boolean>(false);

   React.useEffect(() => {
      if (group.isNew) {
         updateRubricItemGroup({ isNew: false });
      }
   }, []);

   const handleAddRubricItem = (): void => {
      createRubricItem?.({ index: rubricItems.length, groupId: group.id });
   };

   const onMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
      if (!displayOnly) {
         if (event.target && event.target === groupRef.current) {
            (event.target as HTMLDivElement)?.parentNode
               ?.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea')
               .forEach((i) => i.blur());
         }
      }
   };

   const handleDeleteGroup = (): void => {
      deleteRubricItemGroup();
      setConfirmDeleteModalOpen(false);
   };

   const closeConfirmDeleteModal = (): void => {
      setConfirmDeleteModalOpen(false);
   };

   const openConfirmDeleteModal = (): void => {
      setConfirmDeleteModalOpen(true);
   };

   const isApplied = (itemId: number): boolean =>
      !displayOnly && (evaluation?.rubricItemIds.includes(itemId) ?? false);

   const appliedItems = displayOnly
      ? []
      : rubricItems.filter((i) => evaluation?.rubricItemIds.includes(i.id)) ?? [];

   if (dragIndex === undefined || dragIndex === null) {
      return null;
   }

   return (
      <>
         <Draggable
            draggableId={getDraggableId(group)}
            isDragDisabled={isDraggingDisabled || displayOnly}
            index={dragIndex}
         >
            {(provided, snapshot) => (
               <div
                  className={classnames('grader-sidebar-row', 'rubric-entry', {
                     'rubric-entry-is-dragging': snapshot.isDragging,
                     'rubric-item-group-is-dropzone': !!snapshot.combineTargetFor,
                     'rubric-item-group-is-applied': !!appliedItems.length,
                     'display-only': displayOnly,
                  })}
                  ref={(ref) => (provided.innerRef(ref), (groupRef.current = ref))}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  onMouseDown={onMouseDown}
               >
                  {!displayOnly && (
                     <div
                        className={classnames('rubric-entry-key', {
                           'rubric-item-group-is-expanded': isExpanded,
                        })}
                        onClick={toggleActive}
                     >
                        <div className='shortcut-key'>
                           <label>{shortcutKey}</label>
                        </div>
                     </div>
                  )}
                  {!displayOnly && (
                     <div className='points-wrapper'>
                        <span>—</span>
                     </div>
                  )}
                  {!isDraggingRubricEntry && !displayOnly && (
                     <div className='rubric-entry-delete'>
                        <IconCloseSmall onClick={openConfirmDeleteModal} />
                     </div>
                  )}
                  <RubricEntryDescription
                     description={description}
                     displayOnly={displayOnly}
                     updateRubricEntry={updateRubricItemGroup}
                     autoFocus={isNew}
                  />
                  {!isExpanded && !displayOnly && (
                     <div className='rubric-item-group-summary'>
                        {appliedItems.length
                           ? appliedItems.map((item) => (
                                <div key={item.id} className='rubric-item-group-summary-item'>
                                   <div className='summary-item-points-and-description'>
                                      <span className='summary-item-points'>
                                         {formatPoints(item.weight, scoringType)}
                                      </span>
                                      <span>{item.description}</span>
                                   </div>
                                </div>
                             ))
                           : 'No rubric item applied'}
                     </div>
                  )}
               </div>
            )}
         </Draggable>
         {!!(isExpanded || displayOnly) && (
            <div className='group-items-container'>
               <div className='group-items'>
                  {rubricItems.map((item, i) => (
                     <RubricItem
                        displayOnly={displayOnly}
                        dragIndex={dragIndex + i + 1}
                        isApplied={isApplied(item.id)}
                        isDraggingRubricEntry={isDraggingRubricEntry}
                        item={item}
                        key={getDraggableId(item)}
                        scoringType={scoringType}
                        shortcutKey={i < letterKeys.length ? letterKeys[i] : ''}
                        deleteRubricItem={() => deleteRubricItem(item.id)}
                        toggleRubricItem={() => toggleRubricItem(item.id)}
                        updateRubricItem={(update) => updateRubricItem(item.id, update)}
                     />
                  ))}
                  {displayOnly && !rubricItems.length && (
                     <p className='no-rubric-items'>No rubric items in this group</p>
                  )}
               </div>
               {!displayOnly && (
                  <>
                     {draggedEntryWithinGroup && (
                        <div className='group-items-placeholder'>{droppablePlaceholder}</div>
                     )}
                     <Draggable
                        draggableId={`group-footer-${group.id}`}
                        index={dragIndex + group.rubricItems.length + 1}
                     >
                        {(provided) => (
                           <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='group-items-footer'
                           >
                              <Button onClick={handleAddRubricItem} line>
                                 Add Rubric Item To Group
                              </Button>
                           </div>
                        )}
                     </Draggable>
                  </>
               )}
            </div>
         )}
         {confirmDeleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Delete Rubric Item Group'
               onClose={closeConfirmDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: handleDeleteGroup },
                  { text: 'Cancel', onClick: closeConfirmDeleteModal },
               ]}
            >
               <p>
                  Are you sure? Deleting this rubric item group will affect submissions that have
                  rubric items applied in this group.
               </p>
            </ModalDialog>
         )}
      </>
   );
};

export default React.memo(RubricItemGroup);
