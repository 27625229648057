import * as _ from 'lodash';

import tinymce from 'tinymce';

tinymce.PluginManager.add('customtable', (editor) => {
   const evenlyDistributeColumnWidths = () => {
      const selectedTable = editor.dom.getParent(editor.selection.getNode(), 'table');
      if (!selectedTable) {
         return;
      }

      const columns = selectedTable.querySelectorAll('colgroup col');
      if (!columns) {
         return;
      }
      const columnWidth = _.round(100 / columns.length, 3);

      columns.forEach((column) => {
         (column as HTMLElement).style.width = columnWidth + '%';
         column.setAttribute('data-mce-style', column.getAttribute('style') ?? '');
      });
   };

   editor.ui.registry.addButton('tableevencolumns', {
      icon: 'expand-horizontal-5-line',
      tooltip: 'Evenly Distribute Columns',
      onAction: () => {
         evenlyDistributeColumnWidths();
      },
   });

   return {
      getMetadata: () => ({
         name: 'Custom Tables',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
