import * as React from 'react';

import OrganizationLicensePanel from '@components/AdminUsersTable/UserDetails/OrganizationLicensePanel';
import EmptyState from '@components/Core/EmptyState';
import DocumentTitle from '@components/DocumentTitle';
import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import { organizationProfileQuery } from '@graphql/queries';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { AppStateContext } from '../../../AppState';
import ChildOrganizationsPanel from './ChildOrganizationsPanel';
import SchoolInformationPanel from './SchoolInformationPanel';

type Params = {
   organizationId: string;
};

const SchoolDetails: React.FC = () => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const params = useParams<Params>();
   const parsedOrganizationId = params.organizationId || '';

   if (parsedOrganizationId === '') {
      return (
         <>
            <DocumentTitle>New Organization</DocumentTitle>
            <div className='content-main margin-right-m'>
               <div className='row center-xs'>
                  <SchoolInformationPanel dispatchToast={dispatchToast} />
               </div>
            </div>
         </>
      );
   }

   const [{ data, error, fetching }, reExecuteQuery] = useQuery({
      query: organizationProfileQuery,
      variables: { organizationId: parsedOrganizationId },
   });

   const refetchQuery = () => {
      reExecuteQuery({ requestPolicy: 'network-only' });
   };

   if (fetching) return <Loader />;
   if (error) {
      return <ErrorDisplay />;
   }
   if (!data?.organization) return <EmptyState />;

   return (
      <>
         <DocumentTitle>{data.organization.name}</DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row center-xs'>
               <SchoolInformationPanel
                  organization={data.organization}
                  dispatchToast={dispatchToast}
               />
               <OrganizationLicensePanel
                  organizationId={data.organization.id}
                  disableUpdates={false} // TODO
                  value={data.organization.licenses}
                  refetch={refetchQuery}
                  panelTitle={`${data.organization.name} Organization Licenses`}
               />
               {data.organization.parent && (
                  <OrganizationLicensePanel
                     organizationId={data.organization.parent.id}
                     disableUpdates={false} // TODO
                     value={data.organization.parent?.licenses}
                     refetch={refetchQuery}
                     panelTitle={`${data.organization.parent?.name} District Licenses`}
                  />
               )}
               {data.organization.parent === null && (
                  <ChildOrganizationsPanel
                     organization={data.organization}
                     value={data.organization.children}
                     refetch={refetchQuery}
                  />
               )}
            </div>
         </div>
      </>
   );
};

export default SchoolDetails;
