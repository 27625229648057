import { NewMediaRequestInput } from '@models/MediaTranscript';
import { Video } from '@models/Video';

import AxiosService from './AxiosService';

const API = '/api/media_transcripts';

const deleteMedia = async (mediaId: string): Promise<void> =>
   await AxiosService.delete(`${API}/${mediaId}/delete_media`, {
      errorHandlerOverrides: {
         handleNotFound: false,
         handleConflict: false,
      },
   });

const submitNewMedia = async (media: NewMediaRequestInput): Promise<Video> =>
   (await AxiosService.post<Video>(`${API}/submit_new_media`, media)).data;

const updateTextTrackFromSonix = async (mediaId: string): Promise<Video> =>
   (
      await AxiosService.post<Video>(
         `${API}/${mediaId}/update_text_track_from_sonix`,
         {},
         {
            errorHandlerOverrides: {
               handleNotFound: false,
            },
         },
      )
   ).data;

export default {
   deleteMedia,
   submitNewMedia,
   updateTextTrackFromSonix,
};
