import * as _ from 'lodash';
import * as React from 'react';

import CourseEnrollmentService from '@services/CourseEnrollmentService';

import Constants from '../../Constants';
import ExtendedCourseEnrollmentProfile from '@models/Course/ExtendedCourseEnrollmentProfile';
import { LanguageLookup } from '@models/Language';
import Link from '@components/Common/Link';
import { Column } from '@components/Common/Table';
import { renderAvatarCell, renderDateCell } from '@components/Common/TableCellHelpers';
import FilterableTable from '@components/FilterableTable';
import SchoolDetailsPopover from '@components/SchoolDetailsPopover';
import CourseEnrollmentActionsCell from './CourseEnrollmentActionsCell';
import attributes from './CourseEnrollmentPredicateAttributes';
import { defaultPredicate } from './DefaultCourseEnrollmentsPredicate';

const CourseEnrollments: React.FC = () => {
   const {
      routes: {
         courses: { viewCourseEnrollments },
      },
   } = Constants;

   const renderNameCell = ({ user }: ExtendedCourseEnrollmentProfile): React.ReactNode =>
      renderAvatarCell(user.firstName, user.lastName, user.id, user.profileImageUrl);

   const renderAccountTypeCell = ({ user }: ExtendedCourseEnrollmentProfile): React.ReactNode => (
      <Link to={`${viewCourseEnrollments}?user.account_type=${user.accountType}`}>
         {_.startCase(user.accountType)}
      </Link>
   );

   const renderLanguageCell = ({ course }: ExtendedCourseEnrollmentProfile): React.ReactNode => (
      <Link to={`${viewCourseEnrollments}?course.language=${course.language}`}>
         {LanguageLookup[course.language]}
      </Link>
   );

   const renderSchoolCell = (row: ExtendedCourseEnrollmentProfile): React.ReactNode => (
      <SchoolDetailsPopover organizationId={row.school.id} schoolName={row.school.name} />
   );

   const renderCourseEnrollmentActionsCell = (
      row: ExtendedCourseEnrollmentProfile,
      onUpdate: (updatedRow: ExtendedCourseEnrollmentProfile) => void,
      onDelete: () => void,
   ): React.ReactNode => (
      <CourseEnrollmentActionsCell row={row} onUpdate={onUpdate} onDelete={onDelete} />
   );

   const columns: readonly Column<ExtendedCourseEnrollmentProfile>[] = [
      {
         id: 'user.full_name',
         header: 'User',
         cell: renderNameCell,
         canSort: true,
      },
      {
         id: 'user.id',
         header: 'User id',
         cell: (i) => i.user.id.toString(),
         canSort: true,
      },
      {
         id: 'user.email',
         header: 'Email address',
         cell: (i) => i.user.email,
         canSort: true,
      },
      {
         id: 'user.account_type',
         header: 'Account type',
         cell: renderAccountTypeCell,
         canSort: true,
      },
      {
         id: 'course.name',
         header: 'Course name',
         cell: (i) => i.course.name,
         canSort: true,
      },
      {
         id: 'course.id',
         header: 'Course id',
         cell: (i) => i.course.id,
         canSort: true,
      },
      {
         id: 'course.language',
         header: 'Language',
         cell: renderLanguageCell,
         canSort: true,
      },
      {
         id: 'school.name',
         header: 'School',
         cell: renderSchoolCell,
         canSort: true,
      },
      {
         id: 'course.created_on',
         header: 'Course created on',
         cell: (i) => renderDateCell(i.course.createdOn),
         show: false,
      },
      {
         id: 'course_enrollment.created_on',
         header: 'Joined',
         cell: (i) => renderDateCell(i.createdOn),
         canSort: true,
      },
      {
         id: 'course_enrollment.trial_end_on',
         header: 'Trial ends',
         cell: (i) => (i.trialEndOn ? renderDateCell(i.trialEndOn) : null),
         canSort: true,
      },
      {
         id: 'actions',
         header: 'Actions',
         cell: renderCourseEnrollmentActionsCell,
      },
   ];

   return (
      <FilterableTable<ExtendedCourseEnrollmentProfile>
         attributes={attributes}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'course_enrollment.created_on', desc: true }]}
         pageTitle='Course Enrollments'
         searchInputPlaceholder='Search Enrollments'
         rowsLoader={CourseEnrollmentService.getCourseEnrollments}
      />
   );
};

export default CourseEnrollments;
