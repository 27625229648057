import * as _ from 'lodash';

import { ActivityRubricItem, ActivityRubricItemGroup } from '@models/Activity';

import { ImportRubricModalState } from './ImportRubricModal';
import { ModuleItemProfile, ModuleProfile, PromptProfile, QuestionProfile } from './models';

export type ImportRubricReducerAction =
   | { type: 'EXPAND_SELECTED_MODULE' }
   | { type: 'FETCH_COURSE_RUBRICS'; courseId: number }
   | {
        type: 'FETCH_COURSE_RUBRICS_SUCCESS';
        courseId: number;
        modules: readonly ModuleProfile[];
        moduleItems: readonly ModuleItemProfile[];
        questions: readonly QuestionProfile[];
        prompts: readonly PromptProfile[];
        rubricItems: readonly ActivityRubricItem[];
        rubricItemGroups: readonly ActivityRubricItemGroup[];
     }
   | { type: 'FETCH_COURSE_RUBRICS_FAILURE'; courseId: number }
   | { type: 'IMPORT_RUBRIC' }
   | { type: 'SELECT_MODULE_ITEM'; moduleItemId: number }
   | { type: 'SELECT_PROMPT'; promptId: number }
   | { type: 'TOGGLE_COURSE'; courseId: number }
   | { type: 'TOGGLE_MODULE'; moduleId: number };

const importRubricModalReducer = (
   state: ImportRubricModalState,
   action: ImportRubricReducerAction,
): ImportRubricModalState => {
   switch (action.type) {
      case 'EXPAND_SELECTED_MODULE': {
         const moduleObj = Object.values(state.modules).find(
            (i) => state.selectedModuleItemId && i.moduleItems.includes(state.selectedModuleItemId),
         );
         if (moduleObj) {
            return Object.assign({}, state, {
               modules: {
                  ...state.modules,
                  [moduleObj.id]: {
                     ...state.modules[moduleObj.id],
                     isExpanded: true,
                  },
               },
            });
         } else {
            return state;
         }
      }
      case 'FETCH_COURSE_RUBRICS':
         return Object.assign({}, state, {
            courses: {
               ...state.courses,
               [action.courseId]: {
                  ...state.courses[action.courseId],
                  isFetchingRubrics: true,
               },
            },
         });
      case 'FETCH_COURSE_RUBRICS_SUCCESS':
         return Object.assign({}, state, {
            courses: {
               ...state.courses,
               [action.courseId]: {
                  ...state.courses[action.courseId],
                  modules: action.modules.map((i) => i.id),
                  isFetchingRubrics: false,
                  rubricsLoaded: true,
               },
            },
            modules: {
               ...state.modules,
               ..._.keyBy(
                  action.modules.map((i) => ({
                     ...i,
                     isExpanded: false,
                  })),
                  'id',
               ),
            },
            moduleItems: {
               ...state.moduleItems,
               ..._.keyBy(action.moduleItems, 'id'),
            },
            questions: {
               ...state.questions,
               ..._.keyBy(action.questions, 'id'),
            },
            prompts: {
               ...state.prompts,
               ..._.keyBy(action.prompts, 'id'),
            },
            rubricItems: {
               ...state.rubricItems,
               ..._.keyBy(action.rubricItems, 'id'),
            },
            rubricItemGroups: {
               ...state.rubricItemGroups,
               ..._.keyBy(action.rubricItemGroups, 'id'),
            },
         });
      case 'FETCH_COURSE_RUBRICS_FAILURE':
         return Object.assign({}, state, {
            courses: {
               ...state.courses,
               [action.courseId]: {
                  ...state.courses[action.courseId],
                  isFetchingRubrics: false,
                  rubricsLoaded: false,
               },
            },
         });
      case 'IMPORT_RUBRIC':
         return Object.assign({}, state, { isImportingRubric: true });
      case 'SELECT_MODULE_ITEM':
         return Object.assign({}, state, {
            selectedModuleItemId: action.moduleItemId,
            selectedPromptId: null,
         });
      case 'SELECT_PROMPT':
         return Object.assign({}, state, {
            selectedPromptId: action.promptId,
         });
      case 'TOGGLE_COURSE':
         return Object.assign({}, state, {
            courses: {
               ...state.courses,
               [action.courseId]: {
                  ...state.courses[action.courseId],
                  isExpanded: !state.courses[action.courseId].isExpanded,
               },
            },
         });
      case 'TOGGLE_MODULE':
         return Object.assign({}, state, {
            modules: {
               ...state.modules,
               [action.moduleId]: {
                  ...state.modules[action.moduleId],
                  isExpanded: !state.modules[action.moduleId].isExpanded,
               },
            },
         });
      default:
         return state;
   }
};

export default importRubricModalReducer;
