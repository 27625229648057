import {
   CanDoStatementReportSummary,
   DistrictProficiencyReport,
   InstructorProficiencyReport,
   ProficiencyDistrictReportBreadcrumbs,
   ProficiencyEvidenceItems,
   ProficiencyLevelName,
   ProgramProficiencyReport,
   SchoolProficiencyReport,
   StudentProficiencyReport,
} from '@models/Proficiency';

import AxiosService from './AxiosService';

const getStudentSummary = (courseId: number, userId: number): Promise<StudentProficiencyReport> =>
   AxiosService.get<StudentProficiencyReport>(
      `/api/proficiency_reports/student_summary/${courseId}`,
      {
         params: { userId },
      },
   ).then((response) => response.data);

const getInstructorSummary = (
   courseId: number | string,
   instructorId: number | string,
   courseSectionIds?: number[],
   proficiencyLevel?: ProficiencyLevelName,
): Promise<InstructorProficiencyReport> =>
   AxiosService.get<InstructorProficiencyReport>(
      `/api/proficiency_reports/instructor_summary/${instructorId}`,
      {
         params: { courseId, courseSectionIds, proficiencyLevel },
      },
   ).then((response) => response.data);

const getSchoolSummary = (
   standardCourseId: number | string,
   organizationId: number | string,
   proficiencyLevel?: ProficiencyLevelName,
): Promise<SchoolProficiencyReport> =>
   AxiosService.get<SchoolProficiencyReport>(
      `/api/proficiency_reports/school_summary/${organizationId}`,
      {
         params: { standardCourseId, proficiencyLevel },
      },
   ).then((response) => response.data);

const getProgramSummary = (
   standardCourseId: number | string,
   proficiencyLevel?: ProficiencyLevelName,
): Promise<ProgramProficiencyReport> =>
   AxiosService.get<ProgramProficiencyReport>(
      `/api/proficiency_reports/program_summary/${standardCourseId}`,
      { params: { proficiencyLevel } },
   ).then((response) => response.data);

const getCanDoCompletionSummary = (
   standardCourseId: number | string | undefined,
   schoolIds?: (number | string)[],
   instructorIds?: (number | string)[],
   courseIds?: (number | string)[],
): Promise<CanDoStatementReportSummary> =>
   AxiosService.get<CanDoStatementReportSummary>(
      '/api/proficiency_reports/can_do_completion_summary',
      {
         params: {
            courseIds,
            instructorIds,
            schoolIds,
            standardCourseId,
         },
      },
   ).then((response) => response.data);

const getDistrictSummary = (): Promise<DistrictProficiencyReport> =>
   AxiosService.get<DistrictProficiencyReport>('/api/proficiency_reports/district_summary').then(
      (response) => response.data,
   );

const getDistrictReportBreadcrumbs = (
   standardCourseId: number | string,
   organizationId?: number | string,
   instructorId?: number | string,
): Promise<ProficiencyDistrictReportBreadcrumbs> =>
   AxiosService.get<ProficiencyDistrictReportBreadcrumbs>(
      `/api/proficiency_reports/district_report_breadcrumbs/${standardCourseId}`,
      {
         params: { organizationId, instructorId },
      },
   ).then((response) => response.data);

type ProficiencyEvidenceItemsResponse = {
   evidenceItems: ProficiencyEvidenceItems[];
};

const getEvidenceItems = (
   canDoStatementId: number,
   courseId: number,
   userId: number,
): Promise<ProficiencyEvidenceItems[]> =>
   AxiosService.get<ProficiencyEvidenceItemsResponse>(
      `/api/proficiency_reports/evidence_items/${courseId}`,
      {
         params: { canDoStatementId, userId },
      },
   ).then((response) => response.data.evidenceItems);

export default {
   getDistrictReportBreadcrumbs,
   getDistrictSummary,
   getEvidenceItems,
   getCanDoCompletionSummary,
   getInstructorSummary,
   getProgramSummary,
   getSchoolSummary,
   getStudentSummary,
};
