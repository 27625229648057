import * as React from 'react';

import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import IconFileTasksChecked from '@icons/nova-line/88-Files-Tasks/file-tasks-checklist.svg';
import IconBinoculars from '@icons/nova-solid/01-Content-Edition/binoculars.svg';
import IconAdd1 from '@icons/nova-solid/27-Remove&Add/add-1.svg';
import { Maybe } from '@models/Core';
import { CourseProfile } from '@models/Course';
import { CourseProficiencyCanDoStatement, ProficiencyCanDoStatement } from '@models/Proficiency';
import ProficiencyCourseCanDoStatementService from '@services/ProficiencyCourseCanDoStatementService';
import { useParams } from 'react-router-dom';

import CourseCanDoStatementBulkEditorModal from './CourseCanDoStatementBulkEditorModal';
import CourseCanDoStatementsImportModal from './CourseCanDoStatementsImportModal';
import CourseCanDoStatementsTable from './CourseCanDoStatementsTable';

const CourseProficiency: React.FC = () => {
   const { courseId } = useParams<{ courseId: string }>();
   const [courseCanDoStatements, setCourseCanDoStatements] = React.useState<
      CourseProficiencyCanDoStatement[]
   >([]);
   const [isUsingOrganizationStandard, setIsUsingOrganizationStandard] =
      React.useState<boolean>(false);
   const [isImportModalOpen, setIsImportModalOpen] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(true);
   const [showCheckboxes, setShowCheckboxes] = React.useState<boolean>(false);
   const [isBulkEditModalOpen, setIsBulkEditModalOpen] = React.useState<boolean>(false);
   const [selectedCanDoStatementIds, setSelectedCanDoStatementIds] = React.useState<
      readonly number[]
   >([]);
   const [course, setCourse] = React.useState<CourseProfile>();

   React.useEffect(() => {
      getCourseCanDoStatements();
   }, [courseId]);

   const getCourseCanDoStatements = async () => {
      if (!courseId) {
         setIsLoading(false);
         return;
      }

      try {
         setIsLoading(true);
         const data = await ProficiencyCourseCanDoStatementService.get(parseInt(courseId));
         setCourseCanDoStatements(data.canDoStatements);
         setIsUsingOrganizationStandard(data.isUsingOrganizationStandard);
         setCourse(data.course);
         setIsLoading(false);
      } catch (error) {
         console.error(error);
         setCourseCanDoStatements([]);
         setIsLoading(false);
      }
   };

   const addSelectedCanDoStatements = (canDoStatement: ProficiencyCanDoStatement) => {
      setCourseCanDoStatements([...courseCanDoStatements, canDoStatement]);
   };

   const removeCanDoStatement = (canDoStatementId: number) => {
      const cleanedRows = courseCanDoStatements.filter((row) => row.id !== canDoStatementId);
      setCourseCanDoStatements(cleanedRows);
   };

   const updateCanDoStatement = (weekIndex: Maybe<number>) => {
      courseCanDoStatements.find((i) => {
         if (selectedCanDoStatementIds.includes(i.id)) {
            i.weekIndexOfExpectedCompletion = weekIndex;
         }
      });

      setSelectedCanDoStatementIds([]);
   };

   const toggleShowCheckboxes = () => {
      setShowCheckboxes((prevState) => !prevState);
      setSelectedCanDoStatementIds([]);
   };

   if (isLoading) {
      return (
         <>
            <DocumentTitle>Course Proficiency</DocumentTitle>
            <Loader />
         </>
      );
   }

   if (!courseId || !course) {
      return (
         <>
            <DocumentTitle>Course Proficiency</DocumentTitle>
            <div className='padding-top-s'>
               <EmptyState
                  heading='Nothing to show'
                  icon={<IconBinoculars className='large' aria-hidden />}
                  description='No can-do statements have been found for this course.'
               />
            </div>
         </>
      );
   }

   return (
      <>
         <DocumentTitle>Course Proficiency</DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='card bottom-padding no-padding'>
               <div className='card-title'>
                  <div className='title'>Course Can-Do Statements</div>
                  {!isUsingOrganizationStandard && (
                     <div>
                        <Button
                           data-test='btn-bulk-select'
                           icon={<IconFileTasksChecked aria-hidden />}
                           line
                           onClick={toggleShowCheckboxes}
                           tooltip='Show Checkboxes'
                        >
                           {/* {showCheckboxes ? 'Selected' : 'Select'} */}
                        </Button>
                        {showCheckboxes && (
                           <Button
                              className='margin-left-s'
                              onClick={() => setIsBulkEditModalOpen(true)}
                              data-test='btn-bulk-edit-items'
                              disabled={!selectedCanDoStatementIds.length}
                           >
                              Bulk Edit Items
                           </Button>
                        )}
                        {!showCheckboxes && (
                           <Button
                              className='margin-left-s'
                              data-test='import-btn'
                              icon={<IconAdd1 aria-hidden />}
                              onClick={() => setIsImportModalOpen(true)}
                           >
                              Import
                           </Button>
                        )}
                     </div>
                  )}
               </div>
               <div>
                  <CourseCanDoStatementsTable
                     courseId={parseInt(courseId)}
                     rows={courseCanDoStatements}
                     removeRow={removeCanDoStatement}
                     isEditable={!isUsingOrganizationStandard}
                     showCheckboxes={showCheckboxes}
                     selectedCanDoStatementIds={selectedCanDoStatementIds}
                     setSelectedCanDoStatementIds={(ids: number[]) =>
                        setSelectedCanDoStatementIds(ids)
                     }
                  />
               </div>
            </div>
         </div>
         {isImportModalOpen && (
            <CourseCanDoStatementsImportModal
               courseId={parseInt(courseId)}
               setSelectedCanDoStatements={addSelectedCanDoStatements}
               closeModal={() => setIsImportModalOpen(false)}
               selectedCanDoStatements={courseCanDoStatements}
            />
         )}
         {isBulkEditModalOpen && (
            <CourseCanDoStatementBulkEditorModal
               // courseId={parseInt(courseId)}
               closeModal={() => setIsBulkEditModalOpen(false)}
               selectedCanDoStatementIds={selectedCanDoStatementIds}
               updateCanDoStatement={updateCanDoStatement}
               course={course}
            />
         )}
      </>
   );
};

export default CourseProficiency;
