import * as React from 'react';

import IconInterfaceAlertCircle from '@icons/nova-solid/22-Interface-Feedback/interface-alert-circle.svg';
import { Maybe } from '@models/Core';
import IntercomService from '@services/IntercomService';

import { AppStateContext } from '../AppState';
import constants from '../Constants';
import Button from './Common/Button';
import EmptyState from './Core/EmptyState';
import DocumentTitle from './DocumentTitle';
import ExamMissingDivisionDisplay from './ExamMissingCategoryDisplay';
import PaymentRequiredDisplay from './PaymentRequiredDisplay';

const errorHeadings: Record<number, string> = {
   403: 'Access Denied',
   404: 'Page Not Found!',
   408: 'Something Went Wrong!',
   500: 'Something Went Wrong!',
   504: 'Something Went Wrong!',
   900: 'Unstable Internet',
};

const errorMessage: Record<number, string> = {
   403: "You don't have permission to view this page. If you need help, let us know and our team will take a look.",
   404: "Sorry, we can't find that page. If you need help, let us know and our team will take a look.",
   408: 'An unexpected error has occurred. Please try returning to the previous page, or let us know if the problem persists.',
   500: 'An unexpected error has occurred. Please try returning to the previous page, or let us know if the problem persists.',
   504: 'An unexpected error has occurred. Please try returning to the previous page, or let us know if the problem persists.',
   900: 'Your internet connection is unstable. When you are ready to continue please refresh your page.',
};

interface ErrorDisplay {
   statusCode?: number;
   heading?: string;
   message?: string | React.ReactNode;
}

const ErrorDisplay: React.FC<ErrorDisplay> = ({ statusCode, heading, message }) => {
   const { networkError } = React.useContext(AppStateContext);
   const errorStatusCode = networkError?.status || statusCode || 500;
   const errorType = networkError?.data?.errorType;

   const contactSupport = (): void => {
      IntercomService.startChat(`${constants.chatMessages.error}: ${message}`);
   };

   const renderHeading = (): string =>
      heading || errorHeadings[errorStatusCode] || errorHeadings[500];

   const getMessage = (): string => {
      const serverMessage = networkError?.data?.message;
      return message || serverMessage || errorMessage[errorStatusCode] || errorMessage[500];
   };

   const renderPrimaryAction = (): Maybe<React.ReactNode> => {
      if (![900].includes(errorStatusCode)) {
         return <Button onClick={contactSupport}>Contact Support</Button>;
      }

      return null;
   };

   if (errorStatusCode === 402) {
      return <PaymentRequiredDisplay />;
   }

   if (errorType === constants.errorTypes.missingNationalExamCategory) {
      return <ExamMissingDivisionDisplay />;
   }

   return (
      <div className='row margin-top-m'>
         <DocumentTitle>{errorStatusCode === 404 ? 'Not Found' : 'Error'}</DocumentTitle>
         <EmptyState
            icon={<IconInterfaceAlertCircle className='large' aria-hidden='true' />}
            heading={renderHeading()}
            description={getMessage()}
            primaryAction={renderPrimaryAction()}
         />
      </div>
   );
};

export default ErrorDisplay;
