import * as React from 'react';

import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import ContentLibraryName from '@models/ContentLibraryName';

import Switch from '@components/Common/Switch';

export interface ShareModalRowProps {
   rowType: 'library' | 'owner' | 'user';
   icon: React.ReactNode;
   libraryType?: ContentLibraryName;
   switchValue?: boolean;
   name: string;
   permissionId?: number;
   permissionValue?: string;
   schoolName?: string;
   handleSwitchChange?(event: React.ChangeEvent<HTMLInputElement>): void;
   handleUserDelete?(event: React.MouseEvent<HTMLDivElement>): void;
   handlePermissionChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
}

const ShareModalRow: React.FC<ShareModalRowProps> = ({
   rowType,
   icon,
   libraryType,
   switchValue,
   name,
   schoolName,
   permissionId,
   permissionValue,
   handleSwitchChange,
   handleUserDelete,
   handlePermissionChange,
}) => {
   const renderPermissionColumns = (): React.ReactNode => (
      <div className='col-xs-6'>
         <div className='permission-wrapper'>
            <div className='flex-1'>
               <label className='field-title'>Permissions</label>
               <select
                  name={permissionId?.toString()}
                  value={permissionValue}
                  onChange={handlePermissionChange}
               >
                  <option value='view'>View</option>
                  <option value='edit'>Edit</option>
               </select>
            </div>
            <div className='padding-left-s padding-right-s'>
               <label className='field-title'>Can Duplicate</label>
               <Switch
                  name={permissionId?.toString()}
                  checked={switchValue}
                  onChange={handleSwitchChange}
               />
            </div>
            <div className='delete-column'>
               <div className='icon-action' onClick={handleUserDelete}>
                  <IconBin />
               </div>
            </div>
         </div>
      </div>
   );

   const renderLibraryColumn = (): React.ReactNode => (
      <div className='col-xs-6'>
         <div className={`${rowType}-column`}>
            {rowType === 'owner' ? (
               <p className='is-owner'>Is owner</p>
            ) : (
               <Switch checked={switchValue} onChange={handleSwitchChange}>
                  <p className='field-title'>
                     {switchValue
                        ? `Added to ${libraryType} library`
                        : `Add to ${libraryType} library`}
                  </p>
               </Switch>
            )}
         </div>
      </div>
   );

   return (
      <div className={`shared-with-${rowType}`}>
         <div className='row'>
            <div className='col-xs-2'>
               <div className={`${rowType}-column ${rowType}-icon-column`}>{icon}</div>
            </div>
            <div className='col-xs-4'>
               <div className={`${rowType}-column ${rowType}-details-column`}>
                  <div className='details-wrapper'>
                     <div className='name'>{name}</div>
                     <div className='school'>{schoolName}</div>
                  </div>
               </div>
            </div>
            {rowType === 'user' ? renderPermissionColumns() : renderLibraryColumn()}
         </div>
      </div>
   );
};

export default ShareModalRow;
