import * as React from 'react';

import classnames from 'classnames';

import Link from '@components/Common/Link';

interface IAvatarProps {
   /** Background color */
   backgroundColor?: string;
   /** Class name for the avatar */
   className?: string;
   /** First name for calculating initials */
   firstName?: string;
   /** Last name for calculating initials */
   lastName?: string;
   /** Provides a url for avatars being used as a link. */
   href?: string;
   /** Defines the size of the avatar */
   size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
   /** A url to load an image from (this can also be a base64 encoded image). */
   src?: string;
   /** Initials used as a fallback and for calculating the background color */
   initials?: string;
   /** Integer used as a fallback for calculating the background color */
   hashValue?: number;
   /** Indicates a user's online status by showing a small icon on the avatar. */
   presence?: 'online' | 'offline' | 'busy';
   /** Presence color */
   presenceColor?: string;
}

const Avatar: React.FC<IAvatarProps> = ({
   backgroundColor,
   className,
   firstName,
   hashValue,
   href,
   initials = '',
   lastName,
   presence,
   presenceColor,
   size = 'medium',
   src,
}) => {
   const [useFallback, setUseFallback] = React.useState<boolean>(false);

   const defaultColors: Record<number, string> = {
      0: '#CFE2E8',
      1: '#FFE5D7',
      2: '#DCE6B7',
      3: '#D0DFDA',
      4: '#FADDDD',
      5: '#DACAEC',
      6: '#EBD3E0',
      7: '#C6D3EF',
      8: '#FBEDCE',
      9: '#CFCAD4',
      10: '#B3A5AC',
      11: '#CADCD0',
      99: '#DCE6B7',
   };

   const getStyles = (): React.CSSProperties => {
      if (src && !useFallback) {
         return {};
      } else if (backgroundColor) {
         return { backgroundColor };
      } else {
         return {
            backgroundColor: defaultColors[hashValue ? hashValue % 12 : 99],
         };
      }
   };

   const getText = (): string => {
      if (initials) {
         return initials;
      } else if (firstName && lastName) {
         return firstName.charAt(0) + lastName.charAt(0);
      } else {
         return '';
      }
   };

   const component = (
      <div className={classnames('avatar-wrapper', className, size)}>
         <div className='avatar' style={getStyles()}>
            {src && !useFallback ? (
               <img src={src} onError={() => setUseFallback(true)} />
            ) : (
               <div className='initials'>{getText()}</div>
            )}
         </div>
         {!!(presence || presenceColor) && (
            <div className='presence-wrapper'>
               <div className='presence-outer'>
                  <div className='presence-inner'>
                     <svg
                        height='100%'
                        version='1.1'
                        viewBox='0 0 8 8'
                        width='100%'
                        xmlns='http://www.w3.org/2000/svg'
                        className={classnames(presence)}
                     >
                        <circle
                           cx='4'
                           cy='4'
                           r='4'
                           {...(presenceColor ? { fill: presenceColor } : {})}
                        />
                     </svg>
                  </div>
               </div>
            </div>
         )}
      </div>
   );

   if (href) {
      return (
         <Link to={href} external={href.startsWith('http')}>
            {component}
         </Link>
      );
   }

   return component;
};

export default Avatar;
