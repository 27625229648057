import * as React from 'react';

import KeyboardShortcut from '@components/Core/KeyboardShortcut';
import useMousetrap from '@hooks/use-mousetrap';
import IconLoading from '@icons/general/icon-loading.svg';
import Appearance from '@models/Appearance';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { Color } from './Color';

export const CLASSES: Record<string, string> = {
   BUTTON: 'btn',
   LINE: 'line',
   LARGE: 'large',
   SMALL: 'small',
   LOADING: 'loading',
   DISABLED: 'disabled',
   FULL_WIDTH: 'full-width',
   SUBTLE: 'subtle',
   ICON_ONLY: 'icon-only',
   TEXT_LINK: 'text-link',
   ICON_LEFT: 'icon-left',
   ICON_RIGHT: 'icon-right',
};

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
   appearance?: Appearance;
   ariaLabel?: string;
   children?: React.ReactNode;
   className?: string;
   color?: Color;
   disabled?: boolean;
   fullWidth?: boolean;
   icon?: React.ReactNode;
   iconPosition?: 'left' | 'right';
   line?: boolean;
   loading?: boolean;
   subtle?: boolean;
   tabIndex?: number;
   textLink?: boolean;
   tooltip?: string;
   type?: 'button' | 'submit' | 'reset';
   keyboardShortcut?: string;
   onClick?(event: React.MouseEvent<HTMLButtonElement> | Mousetrap.ExtendedKeyboardEvent): void;
   onDoubleClick?(event: React.MouseEvent<HTMLButtonElement>): void;
   'data-test'?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
   const handleOnClick = React.useCallback(
      (
         buttonEvent: React.MouseEvent<HTMLButtonElement> | Mousetrap.ExtendedKeyboardEvent,
      ): void => {
         if (disabled || loading) {
            return;
         } else {
            props.onClick?.(buttonEvent);
         }
      },
      [props.disabled, props.loading, props.onClick],
   );

   useMousetrap(props.keyboardShortcut, handleOnClick);

   const {
      appearance,
      ariaLabel,
      children,
      className = '',
      color,
      disabled,
      fullWidth = false,
      icon,
      iconPosition = 'left',
      line = false,
      loading = false,
      textLink = false,
      subtle = false,
      tabIndex,
      tooltip = null,
      type = 'button',
      keyboardShortcut,
      ref,
      ...otherProps
   } = props;

   const iconLeft = iconPosition === 'left';
   const iconRight = iconPosition === 'right';

   const sharedProps = {
      disabled,
      tabIndex,
      onClick: handleOnClick,
      'aria-label': ariaLabel,
      className: classnames(className, 'btn', color, appearance, {
         [CLASSES.LINE]: line,
         [CLASSES.SUBTLE]: subtle,
         [CLASSES.LOADING]: loading,
         [CLASSES.FULL_WIDTH]: fullWidth,
         [CLASSES.DISABLED]: disabled,
         [CLASSES.ICON_ONLY]: !!icon && !children,
         [CLASSES.TEXT_LINK]: textLink,
         [CLASSES.ICON_LEFT]: !!icon && iconLeft,
         [CLASSES.ICON_RIGHT]: !!icon && iconRight,
      }),
   };

   const button = (
      // eslint-disable-next-line react/forbid-elements
      <button type={type} {...otherProps} {...sharedProps}>
         {loading ? (
            <IconLoading className='icon-loading' />
         ) : (
            <>
               {iconLeft && icon}
               {children}
               {keyboardShortcut && <KeyboardShortcut>{keyboardShortcut}</KeyboardShortcut>}
               {iconRight && icon}
            </>
         )}
      </button>
   );
   if (tooltip) {
      return (
         <Tippy content={tooltip} delay={[800, 0]}>
            <div>{button}</div>
         </Tippy>
      );
   } else {
      return button;
   }
};

export default React.memo(Button);
