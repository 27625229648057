import LMSName from '@models/LMSName';

export const lmsDisplayName: Record<LMSName, string> = {
   [LMSName.blackboard]: 'Blackboard',
   [LMSName.brightspace]: 'Brightspace',
   [LMSName.canvas]: 'Canvas',
   [LMSName.moodle]: 'Moodle',
   [LMSName.microsoftTeams]: 'Microsoft Teams',
   [LMSName.googleClassroom]: 'Google Classroom',
   [LMSName.schoology]: 'Schoology',
};
