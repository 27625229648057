import * as React from 'react';

import { AppStateContext } from '../AppState';
import { Command } from '@components/CommandPalette/Models';

const useCommands = (commands: readonly Command[], dependencies: readonly unknown[]): void => {
   const { registerCommands, unregisterCommands } =
      React.useContext<AppStateContext>(AppStateContext);

   React.useEffect(() => {
      registerCommands(commands);
      return () => {
         unregisterCommands(commands);
      };
   }, dependencies);
};

export default useCommands;
