import * as React from 'react';

import AccentTextbox from '@components/AccentTextbox';
import {
   DropdownIndicator,
   Option,
   OptionProps,
   Select,
   selectTheme,
   SingleValue,
   SingleValueProps,
} from '@components/Core/Select';
import DownwardLineTriangle from '@icons/activities/downward-line-triangle.svg';
import IconNavigationFilterVideo from '@icons/nova-line/17-Navigation/navigation-filter-video.svg';
import IconNetworkUsers from '@icons/nova-line/51-Network/network-users.svg';
import IconUserCircle from '@icons/nova-solid/07-Users/user-circle.svg';
import IconUserShare from '@icons/nova-solid/08-Users-Actions/user-share.svg';
import IconBookshelf from '@icons/nova-solid/34-Office/book-shelf.svg';
import IconBooksApple from '@icons/nova-solid/41-School&Science/books-apple.svg';
import IconPlanetBook from '@icons/nova-solid/41-School&Science/planet-book.svg';
import { ContentType } from '@models/Content';
import ContentLibraryFilters from '@models/ContentLibraryFilters';
import ContentLibraryName from '@models/ContentLibraryName';
import Language, { LanguageLookup } from '@models/Language';
import { ProficiencyLevelConstants, SkillsConstants } from '@models/Proficiency';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { SingleValue as SingleValueType } from 'react-select';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import ContentLibraryFilter from './ContentLibraryFilter';

interface ContentLibrarySidebarProps {
   activeLibrary: ContentLibraryName;
   filters: ContentLibraryFilters;
   setFilters(filters: ContentLibraryFilters): void;
}

interface ContentLibraryOption {
   value: ContentLibraryName;
   label: string;
}

const ContentLibrarySidebar: React.FC<ContentLibrarySidebarProps> = ({
   activeLibrary,
   filters,
   setFilters,
}) => {
   const {
      routes: {
         contentLibrary: { library: contentLibraryRoute },
      },
   } = Constants;

   const navigate = useNavigate();

   const { userProfile, districtProfile } = React.useContext<AppStateContext>(AppStateContext);

   const setLibrary = (library: ContentLibraryName): void => {
      navigate(contentLibraryRoute.replace(':libraryName', library), {
         replace: true,
      });
   };

   const handleFilterChange = (update: Partial<ContentLibraryFilters>): void => {
      setFilters({ ...filters, ...update });
   };

   const libraries = [
      { value: ContentLibraryName.personal, label: 'Personal', index: 1 },
      { value: ContentLibraryName.shared, label: 'Shared', index: 4 },
   ];

   if (userProfile?.permissions.canViewGlobalLibrary) {
      libraries.push({ value: ContentLibraryName.global, label: 'Global', index: 3 });
   }

   if (districtProfile) {
      libraries.push({ value: ContentLibraryName.district, label: 'District', index: 2 });
   } else {
      libraries.push({ value: ContentLibraryName.school, label: 'School', index: 2 });
   }

   const icons = {
      [ContentLibraryName.district]: IconBooksApple,
      [ContentLibraryName.global]: IconPlanetBook,
      [ContentLibraryName.personal]: IconUserCircle,
      [ContentLibraryName.school]: IconBooksApple,
      [ContentLibraryName.shared]: IconUserShare,
   };

   const selectComponents = {
      IndicatorSeparator: () => null,
      LoadingIndicator: () => null,
      Placeholder: () => null,
      DropdownIndicator,
      Option: ({
         children,
         data,
         isSelected,
         ...props
      }: OptionProps<ContentLibraryOption, false>) => {
         const Icon = icons[data.value];
         return (
            <Option
               data={data}
               {...props}
               isSelected={isSelected}
               className={classnames('library-option', {
                  selected: isSelected,
               })}
            >
               <Icon />
               {children}
            </Option>
         );
      },
      SingleValue: ({
         children,
         data,
         ...props
      }: SingleValueProps<ContentLibraryOption, false>) => {
         const Icon = icons[data.value];
         return (
            <SingleValue data={data} {...props} className='library-option'>
               <Icon />
               {children}
            </SingleValue>
         );
      },
   };

   const showFilters = [
      ContentLibraryName.school,
      ContentLibraryName.district,
      ContentLibraryName.global,
   ].includes(activeLibrary);

   const onLibraryChange = (newValue: SingleValueType<ContentLibraryOption>): void => {
      if (newValue) {
         if (newValue.value === ContentLibraryName.district) {
            setLibrary(ContentLibraryName.school);
         } else {
            setLibrary(newValue.value);
         }
      }
   };

   const orderedLibraries = libraries.sort((a) => a.index);

   let selectedContentLibraryOption = undefined;

   if (activeLibrary === ContentLibraryName.school) {
      selectedContentLibraryOption = libraries.find((i) => i.value === ContentLibraryName.school);
      if (selectedContentLibraryOption === undefined) {
         selectedContentLibraryOption = libraries.find(
            (i) => i.value === ContentLibraryName.district,
         );
      }
   } else {
      selectedContentLibraryOption = libraries.find((i) => i.value === activeLibrary);
   }

   return (
      <div className='col-xs-12 col-lg-2'>
         <div className='card filter-container'>
            <div className='filter-search'>
               <AccentTextbox
                  type='search'
                  language={userProfile?.language}
                  value={filters.searchQuery}
                  onChange={(e) => handleFilterChange({ searchQuery: e.target.value })}
               />
            </div>
            <div
               className='filter-search'
               data-test='content-library-select'
               data-tour='content-library-select'
            >
               <Select<ContentLibraryOption>
                  className='react-select'
                  isSearchable={false}
                  onChange={onLibraryChange}
                  theme={selectTheme}
                  components={selectComponents}
                  options={orderedLibraries}
                  value={selectedContentLibraryOption}
               />
            </div>
            {showFilters && (
               <>
                  <ContentLibraryFilter<ContentType>
                     onChange={(value) => handleFilterChange({ contentTypes: value })}
                     value={filters.contentTypes}
                     options={[
                        { value: ContentType.activity, label: 'Activity' },
                        { value: ContentType.lesson, label: 'Lesson' },
                        {
                           value: ContentType.vocabSet,
                           label: 'Vocabulary Set',
                        },
                     ]}
                     heading='Type'
                     icon={<IconNavigationFilterVideo aria-hidden />}
                  />
                  <ContentLibraryFilter<Language>
                     defaultExpanded={false}
                     onChange={(value) => handleFilterChange({ languages: value })}
                     value={filters.languages}
                     options={Object.entries(LanguageLookup).map(
                        ([value, label]) =>
                           ({ value, label }) as { value: Language; label: string },
                     )}
                     heading='Language'
                     icon={<IconNetworkUsers aria-hidden />}
                  />
                  <ContentLibraryFilter
                     defaultExpanded={false}
                     onChange={(value) => handleFilterChange({ proficiencyLevels: value })}
                     value={filters.proficiencyLevels}
                     options={ProficiencyLevelConstants.map((level) => ({
                        value: level,
                        label: level,
                     }))}
                     heading='Level'
                     icon={<DownwardLineTriangle aria-hidden />}
                  />
                  <ContentLibraryFilter
                     defaultExpanded={false}
                     onChange={(value) => handleFilterChange({ proficiencySkills: value })}
                     value={filters.proficiencySkills}
                     options={SkillsConstants.map((level) => ({
                        value: level,
                        label: level,
                     }))}
                     heading='Skill'
                     icon={<IconBookshelf aria-hidden />}
                  />
               </>
            )}
         </div>
      </div>
   );
};

export default ContentLibrarySidebar;
