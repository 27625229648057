import IconWave from '@icons/nova-line/03-Settings/wave.svg';
import IconLock2 from '@icons/nova-line/04-Login/lock-2.svg';
import IconCalendar1 from '@icons/nova-line/05-Time/calendar-1.svg';
import IconATSign from '@icons/nova-line/06-Email/at-sign.svg';
import IconContentBox1 from '@icons/nova-line/18-Content/content-box-1.svg';
import IconLocationGlobe from '@icons/nova-line/55-Locations/location-globe.svg';
import IconUser from '@icons/nova-solid/08-Users-Actions/user.svg';
import IconGraduationHat from '@icons/nova-solid/41-School&Science/graduation-hat.svg';
import IconLocationMapPin from '@icons/nova-solid/55-Locations/location-map-pin.svg';
import { AccountType } from '@models/AccountType';
import { LanguageLookup } from '@models/Language';
import SchoolFundingSource from '@models/SchoolFundingSource';
import SchoolType from '@models/SchoolType';

import { Attribute, PrimitiveType } from '@components/PredicateEditor/Models';

const courseId = {
   icon: IconGraduationHat,
   label: 'Course id',
   identifier: 'course.id',
   type: PrimitiveType.integer,
};

const courseName = {
   icon: IconGraduationHat,
   label: 'Course name',
   identifier: 'course.name',
   type: PrimitiveType.string,
};

const courseLanguage = {
   icon: IconLocationGlobe,
   label: 'Course language',
   identifier: 'course.language',
   type: PrimitiveType.string,
   options: Object.entries(LanguageLookup).map(([value, label]) => ({
      label,
      value,
   })),
};

const courseArchived = {
   icon: IconContentBox1,
   label: 'Course archived',
   identifier: 'course.archived',
   type: PrimitiveType.boolean,
};

const demoCourse = {
   icon: IconContentBox1,
   label: 'Demo course',
   identifier: 'course.demo',
   type: PrimitiveType.boolean,
};

const templateCourse = {
   icon: IconContentBox1,
   label: 'Template course',
   identifier: 'course.template',
   type: PrimitiveType.boolean,
};

const courseCreatedBy = {
   icon: IconUser,
   label: 'Course creator id',
   identifier: 'course.created_by',
   type: PrimitiveType.integer,
};

const courseCreatedOn = {
   icon: IconCalendar1,
   label: 'Course created on',
   identifier: 'course.created_on',
   type: PrimitiveType.date,
};

const courseEnrollmentCount = {
   icon: IconWave,
   label: 'Course enrollment count',
   identifier: 'course.enrollment_count',
   type: PrimitiveType.integer,
};

const courseInvitedCount = {
   icon: IconWave,
   label: 'Course invited count',
   identifier: 'course.invited_count',
   type: PrimitiveType.integer,
};

const organizationId = {
   icon: IconGraduationHat,
   label: 'School id',
   identifier: 'school.id',
   type: PrimitiveType.integer,
};

const schoolName = {
   icon: IconGraduationHat,
   label: 'School name',
   identifier: 'school.name',
   type: PrimitiveType.string,
};

const schoolType = {
   icon: IconGraduationHat,
   label: 'School type',
   identifier: 'school.school_type',
   type: PrimitiveType.string,
   options: [
      { value: SchoolType.higher, label: 'Higher Ed' },
      { value: SchoolType.secondary, label: 'Secondary' },
   ],
};

const schoolFundingSource = {
   icon: IconGraduationHat,
   label: 'School funding source',
   identifier: 'school.funding_source',
   type: PrimitiveType.string,
   options: [
      { value: SchoolFundingSource.public, label: 'Public' },
      { value: SchoolFundingSource.private, label: 'Private' },
   ],
};

const schoolDistrictName = {
   icon: IconGraduationHat,
   label: 'School district name',
   identifier: 'school_district.name',
   type: PrimitiveType.string,
};

const schoolCity = {
   icon: IconLocationMapPin,
   label: 'School city',
   identifier: 'school.city',
   type: PrimitiveType.string,
};

const schoolState = {
   icon: IconLocationMapPin,
   label: 'School state',
   identifier: 'school.state',
   type: PrimitiveType.string,
};

const schoolZipCode = {
   icon: IconLocationMapPin,
   label: 'School zip code',
   identifier: 'school.zip_code',
   type: PrimitiveType.string,
};

const schoolEnrollmentCount = {
   icon: IconWave,
   label: 'School enrollment count',
   identifier: 'school.enrollment_count',
   type: PrimitiveType.integer,
};

const schoolCourseCount = {
   icon: IconWave,
   label: 'Course count',
   identifier: 'school.course_count',
   type: PrimitiveType.integer,
};

const userId = {
   icon: IconUser,
   label: 'User Id',
   identifier: 'user.id',
   type: PrimitiveType.integer,
};

const userName = {
   icon: IconUser,
   label: 'User name',
   identifier: 'user.full_name',
   type: PrimitiveType.string,
};

const userEmail = {
   icon: IconATSign,
   label: 'User email',
   identifier: 'user.email',
   type: PrimitiveType.string,
};

const userAccountType = {
   icon: IconUser,
   label: 'Account type',
   identifier: 'user.account_type',
   type: PrimitiveType.string,
   options: [
      { value: AccountType.instructor, label: 'Instructor' },
      { value: AccountType.student, label: 'Student' },
   ],
};

const userLanguage = {
   icon: IconLocationGlobe,
   label: 'User language',
   identifier: 'user.language',
   type: PrimitiveType.string,
   options: Object.entries(LanguageLookup).map(([value, label]) => ({
      label,
      value,
   })),
};

const demoUser = {
   icon: IconUser,
   label: 'Demo user',
   identifier: 'user.demo',
   type: PrimitiveType.boolean,
};

const userAuthenticated = {
   icon: IconLock2,
   label: 'User authenticated',
   identifier: 'user.authenticated',
   type: PrimitiveType.boolean,
};

const userCourseCount = {
   icon: IconWave,
   label: 'User course count',
   identifier: 'user.course_count',
   type: PrimitiveType.integer,
};

const userSignedUp = {
   icon: IconCalendar1,
   label: 'User signed up',
   identifier: 'user.created_on',
   type: PrimitiveType.date,
};

const userLastSeen = {
   icon: IconCalendar1,
   label: 'User last seen',
   identifier: 'user_login.last_refreshed_on',
   type: PrimitiveType.date,
};

const COURSE_ATTRIBUTES: readonly Attribute[] = [
   courseId,
   courseName,
   courseLanguage,
   courseArchived,
   demoCourse,
   templateCourse,
   courseCreatedBy,
   courseCreatedOn,
   courseEnrollmentCount,
   courseInvitedCount,
];

const SCHOOL_ATTRIBUTES: readonly Attribute[] = [
   organizationId,
   schoolName,
   schoolType,
   schoolFundingSource,
   schoolDistrictName,
   schoolCity,
   schoolState,
   schoolZipCode,
   schoolEnrollmentCount,
   schoolCourseCount,
];

const USER_ATTRIBUTES: readonly Attribute[] = [
   userId,
   userName,
   userEmail,
   userAccountType,
   userLanguage,
   demoUser,
   userAuthenticated,
   userCourseCount,
   userSignedUp,
   userLastSeen,
];

export { COURSE_ATTRIBUTES, SCHOOL_ATTRIBUTES, USER_ATTRIBUTES };
