import * as React from 'react';

import DocumentTitle from '@components/DocumentTitle';
import CursorSelectAreaIcon from '@icons/nova-line/16-Selection&Cursors/cursor-select-area-1.svg';
import PlusIcon from '@icons/nova-line/27-Remove&Add/add-2.svg';

import ButtonPanel from './ButtonPanel';
import { Steps } from './types';

interface CourseCreateTemplateOrNewProps {
   setStep(step: Steps): void;
}

const CourseCreateTemplateOrNew: React.FC<CourseCreateTemplateOrNewProps> = (props) => (
   <>
      <DocumentTitle>Create Course</DocumentTitle>
      <div className='content-main center margin-right-m course-create-template-or-new'>
         <div className='card padding-l'>
            <div className='flex full-height'>
               <div className='half-width border-right padding-s'>
                  <ButtonPanel
                     buttonText='Create From Template'
                     dataTest='create-from-template'
                     description='Start from a template course'
                     themeColor='blue'
                     icon={CursorSelectAreaIcon}
                     onClick={() => props.setStep('select_template')}
                     title='Create Course From Template'
                  />
               </div>
               <div className='half-width padding-s'>
                  <ButtonPanel
                     buttonText='Create New'
                     dataTest='create-new'
                     description='Start with an empty course'
                     themeColor='green'
                     icon={PlusIcon}
                     onClick={() => props.setStep('form')}
                     title='Create New'
                  />
               </div>
            </div>
         </div>
      </div>
   </>
);

export default CourseCreateTemplateOrNew;
