import { snakeCaseKeys } from '@helpers/ModifyKeys';
import Language from '@models/Language';

import HttpService from './HttpService';

const url = '/api/services/translate';

const translate = (text: string, source: Language, target: Language): Promise<string> =>
   HttpService.postWithAuthToken<{ translation: string }>(
      url,
      snakeCaseKeys({ text, source, target }),
   ).then((response) => response.data.translation);

export default { translate };
