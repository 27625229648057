import * as React from 'react';

import parseHtml, { isTag, isText } from '@helpers/ParseHtml';
import { MarkTokensResponse } from '@models/Activity';
import Language from '@models/Language';
import classnames from 'classnames';
import { Element } from 'html-react-parser';

import AudioPlayer, { AudioPlayerSize } from '@components/Core/AudioPlayer';
import InfoTooltip from '@components/InfoTooltip';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface MarkTokensPromptProps extends CommonPromptProps {
   content: string;
   language: Language;
   response: MarkTokensResponse;
   showMissed: boolean;
   setResponse(response: MarkTokensResponse, callback?: () => void): void;
}

const MarkTokensPrompt: React.FC<MarkTokensPromptProps> = ({
   content,
   isClosed,
   response,
   showMissed,
   saveResponse,
   setResponse,
}) => {
   const tokenSelected = (tokenId: string): boolean =>
      !!response.find((i) => i.tokenId === tokenId);

   const handleTokenClick = (event: React.MouseEvent<HTMLSpanElement>): void => {
      if (isClosed) {
         return;
      }
      const target = event.target as HTMLSpanElement;
      const tokenId = target.getAttribute('data-token');
      if (!tokenId) {
         return;
      }
      if (tokenSelected(tokenId)) {
         setResponse(
            response.filter((i) => i.tokenId !== tokenId),
            saveResponse,
         );
      } else {
         setResponse([...response, { tokenId, modified: true, correct: null }], saveResponse);
      }
   };

   const handleAudioTag = (tag: Element) => (
      <AudioPlayer
         src={tag.attribs.src}
         size={tag.attribs['data-player-size'] as AudioPlayerSize}
         className={classnames(tag.attribs.class)}
      />
   );

   const handleTokenTag = (tag: Element) => {
      const child = tag.children[0];
      const text = !!child && isText(child) ? child.data : null;
      const entry = response.find((i) => i.tokenId === tag.attribs['data-token']);
      const selected = !!entry;
      const unmodified = entry?.modified === false;
      const correct = unmodified && entry?.correct === true;
      const incorrect = unmodified && entry?.correct === false;
      const missed = showMissed && !selected && !!tag.attribs['data-correct'];
      const className = classnames({
         correct,
         incorrect,
         selected,
         missed,
      });

      return (
         <span {...tag.attribs} className={className} onClick={handleTokenClick}>
            {text}
         </span>
      );
   };

   const handleHintTag = (tag: Element) => {
      const child = tag.children[0];
      const text = !!child && isText(child) ? child.data : null;
      if (tag.children.length === 1 && text) {
         return <InfoTooltip>{text}</InfoTooltip>;
      }
   };

   const renderTokens = (): React.ReactNode | readonly React.ReactNode[] | string =>
      parseHtml(content, {
         replace: (tag) => {
            if (isTag(tag) && tag.attribs) {
               if (tag.attribs['data-token']) {
                  return handleTokenTag(tag);
               } else if (tag.attribs['data-hint']) {
                  return handleHintTag(tag);
               } else if (tag.name === 'audio' && tag.attribs['data-player-size']) {
                  return handleAudioTag(tag);
               }
            }
            return null;
         },
      });

   return (
      <div
         className={classnames('mark-tokens-form completer', {
            closed: isClosed,
         })}
      >
         {renderTokens()}
      </div>
   );
};

export default MarkTokensPrompt;
