import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import IconChatBubbleSquare3 from '@icons/nova-line/10-Messages&Chat/chat-bubble-square-3.svg';
import { DiscussionBoardResponse } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';
import DateTime from '@services/DateTimeService';
import moment from 'moment';
import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';
import EmptyState from '@components/Core/EmptyState';
import { ActivityCompleterContext } from '@components/Activity/Completer/ActivityCompleter';
import { CommonPromptProps } from '@components/Activity/Completer/Prompt';
import DiscussionBoardResponseEntry from './DiscussionBoardResponseEntry';

interface DiscussionBoardPromptProps extends CommonPromptProps {
   response: DiscussionBoardResponse;
   postDiscussionBoardResponse(entry: string, parentId: Maybe<string | number>): void;
   setResponse(response: DiscussionBoardResponse, callback?: () => void): void;
}

const DiscussionBoardPrompt: React.FC<DiscussionBoardPromptProps> = ({
   isClosed,
   response: { authors, entries },
   postDiscussionBoardResponse,
}) => {
   const [entry, setEntry] = React.useState<string>('');
   const editorRef = React.useRef<Maybe<TinyMCEEditor>>(null);

   const { language } = React.useContext<ActivityCompleterContext>(ActivityCompleterContext);

   const getAuthorProfile = (userId: number): BasicUserProfile => authors[userId];

   const handleSubmit = (): void => {
      postDiscussionBoardResponse(entry, null);
      setEntry('');
      editorRef.current?.resetContent();
   };

   const renderDateDivider = (index: number): React.ReactNode => {
      let dateStr = 'Today';
      const entriesWithoutReplies = entries.filter((i) => !i.parentId);
      const currentCreatedOn = entriesWithoutReplies[index].createdOn;
      const sameDay =
         index === 0
            ? false
            : moment(entriesWithoutReplies[index - 1].createdOn).isSame(currentCreatedOn, 'day');
      if (sameDay) {
         return null;
      } else {
         const yesterday = DateTime.now();
         yesterday.setDate(yesterday.getDate() - 1);
         if (moment(currentCreatedOn).isSame(yesterday, 'day')) {
            dateStr = 'Yesterday';
         } else if (!moment(currentCreatedOn).isSame(DateTime.now(), 'day')) {
            dateStr = moment(currentCreatedOn).format('MMMM Do, YYYY');
         }
      }
      return (
         <div key={`divider-${index}`} className='date-divider'>
            <span>{dateStr}</span>
         </div>
      );
   };
   return (
      <div className='discussion-board-prompt'>
         <div>
            {!entries.length && (
               <EmptyState heading='No posts yet!' icon={<IconChatBubbleSquare3 aria-hidden />} />
            )}
            {entries
               .filter((i) => !i.parentId)
               .map((i, j) => (
                  <React.Fragment key={i.id}>
                     {renderDateDivider(j)}
                     <DiscussionBoardResponseEntry
                        entry={i}
                        allowReplies={!isClosed}
                        replies={entries.filter((k) => k.parentId === i.id)}
                        onReply={(k) => postDiscussionBoardResponse(k, i.id)}
                        getAuthorProfile={getAuthorProfile}
                     />
                  </React.Fragment>
               ))}
         </div>
         {!isClosed && (
            <div className='post-reply-row align-items-center'>
               <div className='flex-1'>
                  <Editor
                     config={{
                        placeholder: 'Write a post...',
                        contextmenu: 'upload-file insert-image record-video',
                        toolbar:
                           'bold italic underline strikethrough | forecolor backcolor | quicklink',
                     }}
                     editorRef={(editor) => (editorRef.current = editor)}
                     disablePlugins={['fixmenus']}
                     language={language}
                     onChange={(text) => setEntry(text)}
                     toolbarAppearance={ToolbarAppearance.floating}
                     value={entry}
                  />
               </div>
               <Button onClick={handleSubmit} className='margin-left-s' disabled={!entry}>
                  Post
               </Button>
            </div>
         )}
      </div>
   );
};

export default DiscussionBoardPrompt;
