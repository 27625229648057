import * as Bowser from 'bowser';
import * as React from 'react';

const bowser = Bowser.getParser(window.navigator.userAgent);

const KeyMap: Record<string, Record<string, string>> = {
   meta: {
      Windows: 'Ctrl',
      macOS: '⌘',
   },
   mod: {
      Windows: 'Ctrl',
      macOS: '⌘',
   },
   alt: {
      Windows: 'Alt',
      macOS: '⌥',
   },
   ctrl: {
      Windows: 'Ctrl',
      macOS: '⌃',
   },
   shift: {
      Windows: 'Shift',
      macOS: '⇧',
   },
   enter: {
      Windows: 'Enter',
      macOS: '↵',
   },
   return: {
      Windows: 'Enter',
      macOS: '↵',
   },
};

interface KeyboardShortcutProps {
   children: string;
   className?: string;
}

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({ children, className = '' }) => {
   const keys = children.toLowerCase().split('+');
   let os = bowser.getOSName();
   if (os !== 'Windows' && os !== 'macOS') {
      os = 'Windows';
   }
   const mappedKeys = keys.map((key) => (KeyMap[key.trim()] ? KeyMap[key.trim()][os] : key.trim()));

   // FIXME: Only show keyboard shortcuts if the user is on macOS
   if (os !== 'macOS') {
      return null;
   }

   return (
      <div className={`keyboard-shortcut ${className}`}>
         {mappedKeys.map((key, index) => (
            <React.Fragment key={key}>
               <kbd>{key}</kbd>
               {index !== mappedKeys.length - 1 && ' + '}
            </React.Fragment>
         ))}
      </div>
   );
};

export default KeyboardShortcut;
