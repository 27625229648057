import * as _ from 'lodash';

import { Block, blocks } from '@components/Core/Editor/data/Blocks';
import {
   COMMON_MISTAKE_TEMPLATE,
   CONJUGATION_TABLE_TEMPLATE,
   CONVERSATION_TEMPLATE,
   EXAMPLE_TEMPLATE,
   INFO_TEMPLATE,
   LEARN_MORE_TEMPLATE,
   makeCalloutTemplate,
   NOTICE_TEMPLATE,
   QUICK_START_TEMPLATE,
   REVEAL_TEMPLATE,
   SOUND_SOLID_ICON_SVG,
   TABLE_TEMPLATE,
   TIP_TEMPLATE,
} from '@components/Core/Editor/data/Templates';
import { createInlineAudio } from '@components/Core/Editor/renderers';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import tinymce, { Editor } from 'tinymce';

const UL = 'ul';
const OL = 'ol';
const TABLE = 'table';
const IMG = 'img';
const EMOJI = 'emoji';
const HR = 'hr';
const BLOCK_AUDIO = 'block-audio';
const VIDEO = 'video';
const INLINE_AUDIO = 'inline-audio';
const QUICK_START_CALLOUT = 'quick-start-callout';
const NOTICE_CALLOUT = 'notice-callout';
const INFO_CALLOUT = 'info-callout';
const TIP_CALLOUT = 'tip-callout';
const MISTAKE_CALLOUT = 'mistake-callout';
const LEARN_MORE_CALLOUT = 'learn-more-callout';

const PRIMARY_CALLOUT = 'primary-callout';
const SECONDARY_CALLOUT = 'secondary-callout';
const SUCCESS_CALLOUT = 'success-callout';
const WARNING_CALLOUT = 'warning-callout';
const DANGER_CALLOUT = 'danger-callout';

const EXAMPLES = 'examples';
const COLUMNS = 'columns';
const REVEAL = 'reveal';
const CONVERSATION = 'conversation';
const CONJUGATION_TABLE = 'conjugation-table';
const LINK = 'link';
const FILE = 'file';

const FORMAT_COMMANDS = ['h1', 'h2', 'h3', 'blockquote', 'p'];

const EMPTY_LINE = '<p><br data-mce-bogus="1"></p>';

interface AutocompleterInstanceApi {
   hide(): void;
   reload(fetchOptions: Record<string, unknown>): void;
}

const isRangeWithinCalloutBody = (range: Range): boolean => {
   let node: Maybe<Node> = range.commonAncestorContainer;

   // If the node is a text node, set it to the parent node
   if (node.nodeType === Node.TEXT_NODE) {
      node = node.parentNode;
   }

   // Check if the node or any of its ancestors has the 'callout-body' class
   return !!(node as Element)?.closest('.callout-body');
};

const renderColumns = (columns = 2): string => {
   let str = '<div class="column-container">';
   for (let i = 0; i < columns; i++) {
      str += '<div class="column"><br></div>';
   }
   str += '</div>';
   str += EMPTY_LINE;
   return str;
};

const insertTable = (
   editor: Editor,
   _autocompleteApi: AutocompleterInstanceApi,
   rng: Range,
): void => {
   if (isRangeWithinCalloutBody(rng)) {
      // Parse the TABLE_TEMPLATE into a Document
      const parser = new DOMParser();
      const doc = parser.parseFromString(TABLE_TEMPLATE, 'text/html');

      // Get the table element from the parsed document
      const table = doc.querySelector('table');

      if (table) {
         // Import the table element into the main document
         const importedTable = editor.getDoc().importNode(table, true);

         // Insert the imported table at the current cursor position (using the rng)
         rng.insertNode(importedTable);

         // Set the focus to the first cell of the inserted table
         const firstCell = importedTable.querySelector('thead tr td');
         if (firstCell) {
            editor.selection.select(firstCell, true);
            editor.selection.collapse(true);
         }
      }
   } else {
      editor.execCommand('mceInsertTable', false, {
         rows: 3,
         columns: 3,
         options: { headerRows: 1 },
      });
   }
};

const insertExamples = (editor: Editor, _autocompleteApi: AutocompleterInstanceApi, rng: Range) => {
   if (isRangeWithinCalloutBody(rng)) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(EXAMPLE_TEMPLATE, 'text/html');

      // Get the table element from the parsed document
      const exampleContainer = doc.querySelector('div.example-container');

      if (exampleContainer) {
         // Import the table element into the main document
         const importedExamples = editor.getDoc().importNode(exampleContainer, true);

         // Insert the imported table at the current cursor position (using the rng)
         rng.insertNode(importedExamples);
         // Set the focus to the first cell of the inserted table
         editor.selection.select(importedExamples);
         editor.selection.collapse(false);
         editor.focus();
      }
   } else {
      editor.execCommand('mceInsertContent', false, EXAMPLE_TEMPLATE);
   }
};

const insertColumns = (
   editor: Editor,
   _autocompleteApi: AutocompleterInstanceApi,
   _rng: Range,
   value: string,
) => {
   const colStr = value.split('-')[1];
   const colCount = parseInt(colStr, 10);
   editor.execCommand('mceInsertContent', false, renderColumns(colCount));
   const currentNode = editor.selection.getNode();
   if (!currentNode) {
      return;
   }
   if ((currentNode?.parentElement?.childElementCount ?? 0) >= 2) {
      const sibling = currentNode?.parentElement?.children
         ? [...currentNode.parentElement.children].at(-2)
         : null;
      if (sibling?.classList.contains('column-container')) {
         const firstColumn = sibling.firstChild;
         if (firstColumn) {
            // create a Range object
            const range = new Range();

            // set the start and end (start = end, as we want the selection to be collapsed; more info in the documentation above)
            range.setStart(firstColumn, 0);
            range.setEnd(firstColumn, 0);

            // Set the editor's selection to our created range
            editor.selection.setRng(range, true); // true means it's selected forward; false for backwards selection
         }
      }
   }
};

const launchLinkContentAutocompleter = (
   editor: Editor,
   autocompleteApi: AutocompleterInstanceApi,
) => {
   autocompleteApi.hide();
   editor.insertContent('@');
   // Create a keydown event for the '@' character (key code 50)
   const keypressEvent = new KeyboardEvent('keypress', {
      bubbles: true,
      cancelable: true,
      keyCode: 50, // '@' character's key code
      charCode: 50,
      key: '@',
      code: 'KeyA', // The physical key on a standard US keyboard
   });
   // Trigger the keypress event
   editor.fire('keypress', keypressEvent);
};

type HandleValueFunction = (
   editor: Editor,
   autocompleteApi: AutocompleterInstanceApi,
   rng: Range,
   value: string,
) => void;

const handleValueActionFunctions: Record<string, HandleValueFunction> = {
   [UL]: (editor: Editor) => editor.execCommand('InsertUnorderedList'),
   [OL]: (editor: Editor) => editor.execCommand('InsertOrderedList'),
   [TABLE]: insertTable,
   [IMG]: (editor: Editor) => {
      if (editor.hasPlugin('insertimage')) {
         editor.execCommand('insertImagePlaceholder', false, { open: true });
      } else if (editor.hasPlugin('image')) {
         editor.execCommand('mceImage');
      }
   },
   [EMOJI]: (editor: Editor) => editor.execCommand('mceEmoticons'),
   [HR]: (editor: Editor) => editor.execCommand('InsertHorizontalRule'),
   [BLOCK_AUDIO]: (editor: Editor) => editor.execCommand('mceMedia'),
   [VIDEO]: (editor: Editor) => editor.execCommand('mceMedia'),
   [INLINE_AUDIO]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, createInlineAudio(SOUND_SOLID_ICON_SVG, '')),
   [QUICK_START_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, QUICK_START_TEMPLATE),
   [NOTICE_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, NOTICE_TEMPLATE),
   [INFO_CALLOUT]: (editor: Editor) => editor.execCommand('mceInsertContent', false, INFO_TEMPLATE),
   [TIP_CALLOUT]: (editor: Editor) => editor.execCommand('mceInsertContent', false, TIP_TEMPLATE),
   [MISTAKE_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, COMMON_MISTAKE_TEMPLATE),
   [LEARN_MORE_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, LEARN_MORE_TEMPLATE),
   [PRIMARY_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, makeCalloutTemplate(Appearance.primary)),
   [SECONDARY_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, makeCalloutTemplate(Appearance.secondary)),
   [SUCCESS_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, makeCalloutTemplate(Appearance.success)),
   [WARNING_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, makeCalloutTemplate(Appearance.warning)),
   [DANGER_CALLOUT]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, makeCalloutTemplate(Appearance.danger)),
   [EXAMPLES]: insertExamples,
   [COLUMNS]: insertColumns,
   [REVEAL]: (editor: Editor) => editor.execCommand('mceInsertContent', false, REVEAL_TEMPLATE),
   [CONVERSATION]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, CONVERSATION_TEMPLATE),
   [LINK]: launchLinkContentAutocompleter,
   [FILE]: (editor: Editor) => editor.execCommand('mceInsertFile'),
   [CONJUGATION_TABLE]: (editor: Editor) =>
      editor.execCommand('mceInsertContent', false, CONJUGATION_TABLE_TEMPLATE),
};

const handleValueAction = (
   editor: Editor,
   autocompleteApi: AutocompleterInstanceApi,
   range: Range,
   value: string,
) => {
   editor.selection.setRng(range);
   editor.insertContent('');

   if (FORMAT_COMMANDS.includes(value)) {
      editor.formatter.apply(value);
   } else if (handleValueActionFunctions[value]) {
      handleValueActionFunctions[value](editor, autocompleteApi, range, value);
   } else {
      console.warn('Unexpected command', value);
   }

   autocompleteApi.hide();
};

tinymce.PluginManager.add('quickinsert', (editor: Editor) => {
   editor.options.register('quickinsert_options', {
      processor: 'string',
      default: blocks.map((i) => i.value).join(' '),
   });

   const availableBlocks = editor.options.get('quickinsert_options').split(' ');

   const getPartialMatches = (unfilteredBlocks: readonly Block[], pattern: string) =>
      unfilteredBlocks
         .filter(
            (block) =>
               block.text.toLowerCase().includes(pattern.toLowerCase()) ||
               block.value.toLowerCase() === pattern.toLowerCase() ||
               pattern === '',
         )
         .filter((i) => availableBlocks.includes(i.value));

   const filterCallouts = (unfilteredBlocks: readonly Block[]) =>
      editor.selection?.getNode()?.closest('.callout')
         ? unfilteredBlocks.filter((i) => !i.value.includes('-callout'))
         : unfilteredBlocks;

   editor.ui.registry.addAutocompleter('quick-insert', {
      trigger: '/',
      minChars: 0,
      columns: 1,
      onAction: (autocompleteApi, rng, value) => {
         handleValueAction(editor, autocompleteApi, rng, value);
      },
      fetch: (pattern) => {
         const matches = _.flow(
            (b, p) => getPartialMatches(b, p),
            (b) => filterCallouts(b),
         )(blocks, pattern);

         return new Promise((resolve) => {
            const results = matches.map((block) => ({
               type: 'cardmenuitem' as const,
               value: block.value,
               label: block.text,
               items: [
                  {
                     type: 'cardcontainer' as const,
                     direction: 'horizontal' as const,
                     items: [
                        {
                           type: 'cardimage' as const,
                           src: block?.img ?? 'https://via.placeholder.com/46',
                           alt: 'My alt text',
                           classes: ['card-image'],
                        },
                        {
                           type: 'cardcontainer' as const,
                           direction: 'vertical' as const,
                           items: [
                              {
                                 type: 'cardtext' as const,
                                 text: block.text,
                                 name: 'char_name',
                                 classes: ['card-header'],
                              },
                              {
                                 type: 'cardtext' as const,
                                 text: block.description,
                                 classes: ['card-description'],
                              },
                           ],
                        },
                     ],
                  },
               ],
            }));
            resolve(results);
         });
      },
   });

   return {
      getMetadata: () => ({
         name: 'Quick insert',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
