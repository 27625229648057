import tinymce from 'tinymce';

import { extractYoutubeVideoId, isYoutubeVideo } from '@components/Core/Editor/utils/media';
import { convertStringToNode } from '@components/Core/Editor/utils/node';

const getOpenGraphData = async (url: string) => {
   // FIXME: Don't use my rachet render service.
   const proxyUrl = 'https://cors-everywhere-frkw.onrender.com/';
   const response = await fetch(proxyUrl + url);
   const html = await response.text();
   const { protocol, hostname } = new URL(url);
   const parser = new DOMParser();
   const doc = parser.parseFromString(html, 'text/html');

   const ogDescription = doc.querySelector('meta[property="og:description"]');
   const ogImage = doc.querySelector('meta[property="og:image"], meta[name="twitter:image"]');
   const ogSiteName = doc.querySelector('meta[property="og:site_name"]');
   const ogTitle = doc.querySelector('meta[property="og:title"]');
   const ogUrl = doc.querySelector('meta[property="og:url"]');
   const author = doc.querySelector('meta[name="author"]');
   const icon = doc.querySelector(
      'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"], link[rel="apple-touch-icon-precomposed"], link[rel="mask-icon"]',
   );

   const openGraphData = {
      title: ogTitle ? ogTitle.getAttribute('content') : doc.title,
      description: ogDescription ? ogDescription.getAttribute('content') : null,
      image: ogImage ? ogImage.getAttribute('content') : null,
      url: ogUrl ? ogUrl.getAttribute('content') : url,
      author: author ? author.getAttribute('content') : null,
      icon: icon ? icon.getAttribute('href') : null,
      siteName: ogSiteName ? ogSiteName.getAttribute('content') : null,
   };
   if (openGraphData.icon?.startsWith('/')) {
      openGraphData.icon = `${protocol}//${hostname}${openGraphData.icon}`;
   }
   if (openGraphData.image?.startsWith('/')) {
      openGraphData.image = `${protocol}//${hostname}${openGraphData.image}`;
   }
   return openGraphData;
};

tinymce.PluginManager.add('webbookmark', (editor) => {
   const replaceNodeWithBookmark = (node: Node) => {
      const { dom } = editor;
      const href = node.textContent;
      if (!href) {
         return;
      }
      getOpenGraphData(href).then(({ title, description, image, url, icon }) => {
         if (!title) {
            return;
         }
         const bookmarkNode = convertStringToNode(
            `
            <div contenteditable="false" class="bookmark-card">
                <div>
                <a rel="noopener noreferrer" href="${url}">
                    <div role="button" tabindex="0" class="inner-anchor-wrapper">
                    <div class="title-description-wrapper">
                        <div class="title">${title}</div>
                        <div class="description">${description}</div>
                        <div class="icon-url-wrapper">
                        <img src="${icon}">
                        <div class="url">${url}</div>
                        </div>
                    </div>
                    <div class="image-wrapper">
                        <div class="inner-image-wrapper">
                        <img src="${image}">
                        </div>
                    </div>
                    </div>
                </a>
                </div>
            </div>`,
         );
         if (bookmarkNode instanceof Node) {
            dom.replace(bookmarkNode, node);
            editor.nodeChanged();
         }
      });
   };

   const replaceNodeWithYoutubeEmbed = (node: Node) => {
      const { dom } = editor;
      const href = node.textContent;
      const videoId = extractYoutubeVideoId(href);
      if (!videoId) {
         return;
      }
      const youTubeNode = convertStringToNode(
         `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/${videoId}"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>`,
      );
      if (youTubeNode) {
         dom.replace(youTubeNode, node);
         editor.nodeChanged();
      }
   };

   editor.on('keyup', (e) => {
      if (e.key === 'Enter' && !e.isDefaultPrevented()) {
         const { selection } = editor;

         const node = selection.getNode();
         const { previousSibling } = node;
         if (
            previousSibling &&
            previousSibling?.nodeName === 'P' &&
            previousSibling?.textContent?.match(/^(https?:\/\/)/) &&
            (previousSibling?.childNodes?.length ?? 0) === 1 // Should only be text node
         ) {
            const url = previousSibling.textContent;
            if (isYoutubeVideo(url)) {
               replaceNodeWithYoutubeEmbed(previousSibling);
            } else {
               replaceNodeWithBookmark(previousSibling);
            }
         }
      }
   });

   return {
      getMetadata: () => ({
         name: 'Web Bookmark',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
