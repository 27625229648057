import * as React from 'react';

import { randomShortId } from '@helpers/RandomStringUtils';
import classnames from 'classnames';

interface IToggleProps {
   className?: string;
   leftLabel?: string;
   leftLabelIcon?: React.ReactNode;
   leftAriaLabel?: string;
   leftValue: string;
   name?: string;
   rightLabel?: string;
   rightLabelIcon?: React.ReactNode;
   rightAriaLabel?: string;
   rightValue: string;
   centerLabel?: string;
   centerLabelIcon?: React.ReactNode;
   centerAriaLabel?: string;
   centerValue?: string;
   value: string;
   onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Toggle: React.FC<IToggleProps> = ({
   className,
   value,
   leftValue,
   leftLabel,
   leftLabelIcon,
   leftAriaLabel,
   centerValue,
   centerLabel,
   centerLabelIcon,
   centerAriaLabel,
   rightValue,
   rightLabel,
   rightLabelIcon,
   rightAriaLabel,
   name: propsName,
   onChange,
}) => {
   const [name, _setName] = React.useState<string>(
      propsName ? propsName : `toggle-${randomShortId()}`,
   );

   const triple = !!centerValue;

   return (
      <div className={classnames('toggle-tab', className, triple ? 'triple' : 'double')}>
         <label
            className={classnames('toggle-tab-left', {
               active: leftValue === value,
            })}
         >
            <input
               type='radio'
               name={name}
               value={leftValue}
               onChange={onChange}
               checked={leftValue === value}
            />
            <span aria-label={leftAriaLabel ?? undefined}>
               {leftLabel}
               {leftLabelIcon}
            </span>
         </label>
         {triple && (
            <label
               className={classnames('toggle-tab-center', {
                  active: centerValue === value,
               })}
            >
               <input
                  type='radio'
                  name={name}
                  value={centerValue}
                  onChange={onChange}
                  checked={centerValue === value}
               />
               <span aria-label={centerAriaLabel ?? undefined}>
                  {centerLabel}
                  {centerLabelIcon}
               </span>
            </label>
         )}
         <label
            className={classnames('toggle-tab-right', {
               active: rightValue === value,
            })}
         >
            <input
               type='radio'
               name={name}
               value={rightValue}
               onChange={onChange}
               checked={rightValue === value}
            />
            <span aria-label={rightAriaLabel ?? undefined}>
               {rightLabel}
               {rightLabelIcon}
            </span>
         </label>
      </div>
   );
};

export default React.memo(Toggle);
