import { Maybe } from '@models/Core';
import { Location } from 'react-router-dom';

export class UrlBuilder {
   private readonly params = new URLSearchParams();
   private readonly baseUrl: string;

   constructor(baseUrl: string) {
      this.baseUrl = baseUrl;
   }

   public addParam(name: string, value: string, valueRequired = true): void {
      if (name === '') {
         throw new Error('Parameter name cannot be empty');
      }

      if (valueRequired && (value || parseInt(value) === 0)) {
         this.params.set(name, value);
      }
   }

   get fullUrl(): string {
      const paramPart = this.params.toString();
      const url = this.baseUrl + (paramPart ? `?${paramPart}` : '');
      return url;
   }
}

export function getQueryParameterByName(location: Location, name: string): Maybe<string>;
export function getQueryParameterByName<T>(
   location: Location,
   name: string,
   defaultValue: T,
): string;
// eslint-disable-next-line func-style
export function getQueryParameterByName(
   location: Location,
   name: string,
   defaultValue?: string,
): Maybe<string> {
   const params = new URLSearchParams(location.search);
   return params.get(name) || defaultValue;
}

export const getQueryParametersByNameAsArray = (
   location: Location,
   name: string,
): readonly string[] => {
   const urlParams = new URLSearchParams(location.search);
   const params = urlParams.getAll(name);
   return params;
};

export const getQueryParametersByNameAsNumberArray = (
   location: Location,
   name: string,
): readonly number[] => getQueryParametersByNameAsArray(location, name).map((i) => parseInt(i));

export function getQueryParameterAsNumber(location: Location, name: string): Maybe<number>;
export function getQueryParameterAsNumber<T>(
   location: Location,
   name: string,
   defaultValue: T,
): number | T;
// eslint-disable-next-line func-style
export function getQueryParameterAsNumber<T>(
   location: Location,
   name: string,
   defaultValue?: T,
): Maybe<number> | T {
   const value = getQueryParameterByName(location, name);
   if (value) {
      return parseInt(value, 10);
   }

   return defaultValue;
}

export const getPathFromUrl = (url: string): string => url.split(/[?#]/)[0];

// Source: https://stackoverflow.com/a/1634841
export const removeQueryParameter = (url: string, parameterName: string): string => {
   // prefer to use l.search if you have a location/link object
   const urlParts = url.split('?');
   if (urlParts.length >= 2) {
      const prefix = encodeURIComponent(parameterName) + '=';
      const pars = urlParts[1].split(/[&;]/g);

      // reverse iteration as may be destructive
      for (let i = pars.length; i-- > 0; ) {
         // idiom for string.startsWith
         if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
         }
      }

      return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
   }
   return url;
};
