import * as React from 'react';

import MicrosoftTeamsIcon from '@icons/general/microsoft-teams.svg';
import BlackboardCircleIcon from '@icons/lms/blackboard-circle.svg';
import BlackboardLineIcon from '@icons/lms/blackboard-line.svg';
import BrightspaceCircleIcon from '@icons/lms/brightspace-circle.svg';
import BrightspaceLineIcon from '@icons/lms/brightspace-line.svg';
import CanvasCircleIcon from '@icons/lms/canvas-circle.svg';
import CanvasLineIcon from '@icons/lms/canvas-line.svg';
import GoogleClassroomCircleIcon from '@icons/lms/google-classroom-circle.svg';
import GoogleClassroomIcon from '@icons/lms/google-classroom.svg';
import MoodleCircleIcon from '@icons/lms/moodle-circle.svg';
import MoodleLineIcon from '@icons/lms/moodle-line.svg';
import SchoologyCircleIcon from '@icons/lms/schoology-circle.svg';
import SchoologyLineIcon from '@icons/lms/schoology-line.svg';
import LMSName from '@models/LMSName';

interface LMSIconProps extends React.SVGProps<SVGElement> {
   lmsName: LMSName;
   circle?: boolean;
   line?: boolean;
}

interface IconMapValue {
   circle?: React.SVGFactory;
   line?: React.SVGFactory;
   default?: React.SVGFactory;
}

const iconMap: Record<LMSName, IconMapValue> = {
   [LMSName.canvas]: { circle: CanvasCircleIcon, line: CanvasLineIcon },
   [LMSName.googleClassroom]: { circle: GoogleClassroomCircleIcon, default: GoogleClassroomIcon },
   [LMSName.schoology]: { circle: SchoologyCircleIcon, line: SchoologyLineIcon },
   [LMSName.brightspace]: { circle: BrightspaceCircleIcon, line: BrightspaceLineIcon },
   [LMSName.blackboard]: { circle: BlackboardCircleIcon, line: BlackboardLineIcon },
   [LMSName.moodle]: { circle: MoodleCircleIcon, line: MoodleLineIcon },
   [LMSName.microsoftTeams]: { default: MicrosoftTeamsIcon },
};

const LMSIcon: React.FC<LMSIconProps> = ({
   lmsName,
   height = 25,
   width = 25,
   circle,
   line,
   ...rest
}) => {
   const iconProps = { height, width, ...rest };
   const IconComponent =
      iconMap[lmsName][circle ? 'circle' : line ? 'line' : 'default'] ?? iconMap[lmsName].default;

   return IconComponent ? <IconComponent {...iconProps} /> : null;
};

export default LMSIcon;
