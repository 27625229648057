import * as React from 'react';

import Constants from '../../Constants';
import DocumentTitle from '@components/DocumentTitle';

interface RegisterSuccessProps {
   email: string;
}

const RegisterSuccess: React.FC<RegisterSuccessProps> = ({ email }) => (
   <div className='card-content-onboarding card padding-bottom-m'>
      <div className='onboarding-title border-bottom text-center'>
         <DocumentTitle>Registration Successful</DocumentTitle>
         <h2 aria-live='polite' className='uppercase no-margin'>
            Registration Successful
         </h2>
      </div>
      <div className='content-form'>
         <div className='padding-l'>
            <img
               className='responsive'
               src={`${Constants.staticUrl}/img/illustration-welcome.png`}
               alt=''
            />
         </div>
         <p className='text-left'>
            To get started, check your email, <strong>{email}</strong>, and click the link we just
            sent you!
         </p>
      </div>
   </div>
);

export default RegisterSuccess;
