/* eslint-disable camelcase */
import tinymce, { Editor } from 'tinymce';

const isInTooltip = (node: HTMLElement) => !!node.closest('span[data-tooltip]');

const isTooltipElement = (node: HTMLElement) => !!node.dataset.tooltip;

const getTooltipElement = (editor: Editor) => {
   const node = editor.selection.getNode();
   return isTooltipElement(node) ? node : null;
};

const removeTooltipFromSelection = (editor: Editor): void => {
   const dom = editor.dom,
      selection = editor.selection;
   const bookmark = selection.getBookmark();
   const rng = selection.getRng().cloneRange();

   // Extend the selection out to the entire anchor element
   const startAnchorElm = dom.getParent(rng.startContainer, 'span[data-tooltip]', editor.getBody());
   const endAnchorElm = dom.getParent(rng.endContainer, 'span[data-tooltip]', editor.getBody());
   if (startAnchorElm) {
      rng.setStartBefore(startAnchorElm);
   }
   if (endAnchorElm) {
      rng.setEndAfter(endAnchorElm);
   }
   selection.setRng(rng);

   // Remove the link
   editor.execCommand('mceRemoveTooltip');
   selection.moveToBookmark(bookmark);
};

const setupMenuItems = (editor: Editor): void => {
   editor.ui.registry.addMenuItem('tooltip', {
      icon: 'comment',
      text: 'Tooltip...',
      shortcut: 'Meta+Shift+Y',
      onAction: () => editor.execCommand('mceTooltip'),
   });

   editor.ui.registry.addMenuItem('removetooltip', {
      icon: 'remove',
      text: 'Remove tooltip',
      onAction: () => removeTooltipFromSelection(editor),
      onSetup: (api) => {
         api.setEnabled(!!getTooltipElement(editor));
         const nodeChangeHandler = () =>
            api.setEnabled(!editor.readonly && !!getTooltipElement(editor));
         editor.on('nodechange', nodeChangeHandler);
         return () => editor.off('nodechange', nodeChangeHandler);
      },
   });
   editor.ui.registry.addButton('tooltip', {
      icon: 'comment',
      tooltip: 'Tooltip',
      onAction: () => editor.execCommand('mceTooltip'),
   });
};

const setupContextMenu = (editor: Editor): void => {
   const inTooltip = 'tooltip removetooltip';
   const noTooltip = 'tooltip';
   editor.ui.registry.addContextMenu('tooltip', {
      update: (element) => {
         const isEditable = editor.dom.isEditable(element);
         const isImage = element.nodeName === 'IMG';
         if (!isEditable || isImage) {
            return '';
         }

         return getTooltipElement(editor) ? inTooltip : noTooltip;
      },
   });
};

tinymce.PluginManager.add('tooltip', (editor) => {
   editor.addCommand('mceTooltip', () => {
      editor.dispatch('contexttoolbar-show', {
         toolbarKey: 'tooltip',
      });
   });

   editor.addCommand('mceRemoveTooltip', () => {
      const currentNode = editor.selection.getNode();
      if (isInTooltip(currentNode)) {
         const tooltipElem = currentNode.closest('span[data-tooltip]') as HTMLElement;
         const text = tooltipElem.innerText;
         tooltipElem.replaceWith(text);
      }
   });

   editor.addShortcut('Meta+Shift+Y', 'Tooltip', () => {
      editor.execCommand('mceTooltip');
   });

   editor.on('init', () => {
      editor.formatter.register('tooltip', {
         inline: 'span',
         clear_child_styles: true,
         block_expand: false,
         split: false,
         attributes: { 'data-tooltip': '%value' },
      });
      setupMenuItems(editor);
      setupContextMenu(editor);
   });

   // editor.on('click', (e) => {
   //    if (isTooltipElement(e.target)) {
   //       editor.execCommand('mceTooltip');
   //    }
   // });

   editor.ui.registry.addContextForm('tooltip', {
      launch: {
         type: 'contextformtogglebutton',
         icon: 'chat-bubble-square-1-line',
         tooltip: 'Tooltip',
      },
      label: 'Tooltip',
      initValue: () => {
         const elm = getTooltipElement(editor);
         return elm ? elm.dataset.tooltip || '' : '';
      },
      scope: 'node',
      position: 'selection',
      commands: [
         {
            type: 'contextformtogglebutton',
            icon: 'comment-add',
            tooltip: 'Set Tooltip',
            primary: true,
            onSetup: (buttonApi) => {
               buttonApi.setActive(!!getTooltipElement(editor));
               const nodeChangeHandler = () =>
                  buttonApi.setActive(!editor.readonly && !!getTooltipElement(editor));
               editor.on('nodechange', nodeChangeHandler);
               return () => editor.off('nodechange', nodeChangeHandler);
            },
            onAction: (formApi) => {
               const value = formApi.getValue();
               editor.formatter.apply('tooltip', { value });
               editor.selection.collapse(false);
               formApi.hide();
            },
         },
         {
            type: 'contextformtogglebutton',
            icon: 'remove',
            tooltip: 'Remove Tooltip',
            active: false,
            onAction: (formApi) => {
               const currentNode = editor.selection.getNode();
               if (isInTooltip(currentNode)) {
                  const tooltipElem = currentNode.closest('span[data-tooltip]') as HTMLElement;
                  const text = tooltipElem.innerText;
                  tooltipElem.replaceWith(text);
               }
               formApi.hide();
            },
         },
      ],
   });

   return {
      getMetadata: () => ({
         name: 'Tooltip',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
