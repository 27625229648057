import * as _ from 'lodash';
import * as React from 'react';

import Table, { Column } from '@components/Common/Table';
import ModalDialog from '@components/Core/ModalDialog';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconRadioTick from '@icons/general/icon-radio-tick-copy.svg';
import Appearance from '@models/Appearance';
import {
   isSkill,
   ProficiencyCanDoStatement,
   ProficiencyCanDoStatementDetailed,
   ProficiencyLevelConstants,
   ProficiencyLevelName,
   Skill,
   SkillsConstants,
} from '@models/Proficiency';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

interface ModuleCanDoStatementModalEditorModalProps {
   courseCanDoStatements: readonly ProficiencyCanDoStatementDetailed[];
   moduleCanDoStatements: readonly ProficiencyCanDoStatementDetailed[];
   closeModal(): void;
   onAddClick(canDoStatement: ProficiencyCanDoStatementDetailed): Promise<void>;
   setModuleCanDoStatements(canDoStatement: ProficiencyCanDoStatement[]): void;
}

const ModuleCanDoStatementModalEditorModal: React.FC<ModuleCanDoStatementModalEditorModalProps> = (
   props,
) => {
   const [searchKeywords, setSearchKeyWords] = React.useState<string>('');
   const [selectedSkill, setSelectedSkill] = React.useState<Skill | undefined>();
   const [selectedLevel, setSelectedLevel] = React.useState<ProficiencyLevelName | undefined>();
   const [filteredCourseCanDoStatements, setFilteredCourseCanDoStatements] = React.useState<
      readonly ProficiencyCanDoStatementDetailed[]
   >(props.courseCanDoStatements);

   React.useEffect(() => {
      setFilteredCourseCanDoStatements(
         props.courseCanDoStatements.filter(
            (x) =>
               (x.name.includes(searchKeywords) &&
                  (!selectedSkill || x.skill.includes(selectedSkill)) &&
                  (!selectedLevel || x.level?.includes(selectedLevel))) ||
               x.id.toString().includes(searchKeywords),
         ),
      );
   }, [selectedLevel, selectedSkill, searchKeywords]);

   const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchKeyWords(event.target.value);
   };

   const handleSkillChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newSkillValue = isSkill(event.target.value) ? event.target.value : undefined;
      setSelectedSkill(newSkillValue);
   };

   const handleLevelChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
      const newLevelValue = (event.target.value as ProficiencyLevelName) || undefined;
      setSelectedLevel(newLevelValue);
   };

   const renderAdd = (canDoStatement: ProficiencyCanDoStatementDetailed): React.ReactNode => {
      const selectedCanDoStatements = props.moduleCanDoStatements || [];
      const isAlreadyInCourse = selectedCanDoStatements.find((x) => x.id === canDoStatement.id);
      if (isAlreadyInCourse) {
         return <IconRadioTick className='icon-green' height='20px' width='20px' />;
      }

      return (
         <Tippy delay={[400, 0]} content='Add to Course'>
            <div className='module-add-button'>
               <IconAddSmall
                  data-test={`${canDoStatement.id}-add`}
                  onClick={() => props.onAddClick(canDoStatement)}
               />
            </div>
         </Tippy>
      );
   };

   const columns: readonly Column<ProficiencyCanDoStatementDetailed>[] = [
      {
         id: 'canDoName',
         header: 'Can-Do Statements',
         cell: (i) => i.name,
         headerClassName: 'text-left',
         cellClassName: 'text-left',
      },
      {
         id: 'levelName',
         header: 'Level',
         cell: (i) => i.level,
         canSort: true,
         sortFunc: (i) => i.level?.toLowerCase() ?? '',
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'skillName',
         header: 'Skill',
         cell: (i) => i.skill,
         canSort: true,
         sortFunc: (i) => i.skill.toLowerCase(),
         cellClassName: 'text-bold text-uppercase',
      },
      {
         id: 'Add',
         header: '',
         cell: renderAdd,
      },
   ];

   return (
      <ModalDialog
         appearance={Appearance.primary}
         className='no-padding'
         bodyClassName='modal-body'
         footerClassName='hide'
         heading='Import Can-Do Statement'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         onClose={props.closeModal}
      >
         <div className='flex items-center margin-s'>
            <input
               autoFocus
               className='mousetrap'
               data-test='search-keywords'
               name='searchKeywords'
               onChange={handleQueryChange}
               placeholder='Search by keyword'
               type='search'
               value={searchKeywords}
            />
         </div>
         <div className='flex margin-left-s margin-right-s margin-bottom-s'>
            <select
               className='col-sm-6'
               data-test='select-skill'
               value={selectedSkill}
               onChange={handleSkillChange}
            >
               <option value={''}>All Skills</option>
               {SkillsConstants.map((x) => (
                  <option key={x} value={x}>
                     {x}
                  </option>
               ))}
            </select>
            <select
               className='col-sm-6'
               data-test='select-level'
               value={selectedLevel}
               onChange={handleLevelChange}
            >
               <option value={''}>All Levels</option>
               {ProficiencyLevelConstants.map((x) => (
                  <option key={x} value={x}>
                     {x}
                  </option>
               ))}
            </select>
         </div>
         <div className='modal-body-scroll'>
            <Table<ProficiencyCanDoStatementDetailed>
               columns={columns}
               rowKey='id'
               rows={filteredCourseCanDoStatements}
            />
         </div>
      </ModalDialog>
   );
};

export default ModuleCanDoStatementModalEditorModal;
