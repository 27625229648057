// @ts-strict-ignore
import * as React from 'react';

import IconDownArrow from '@icons/general/icon-arrow-down.svg';
import IconTagClose from '@icons/general/icon-tag-close.svg';
import Select, { components, Theme } from 'react-select';

import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';

export * from 'react-select';

const { Option, SingleValue, Menu } = components;

export { Option, SingleValue, Menu, Select, AsyncSelect, CreatableSelect };

export const selectTheme = (defaultTheme: Theme): Theme => ({
   ...defaultTheme,
   colors: {
      ...defaultTheme.colors,
      primary: '#007CBB',
      primary25: '#EAF7FF',
      neutral20: '#E7E7E7',
      neutral50: '#413F48',
   },
});

export const MultiValueRemove = (props): JSX.Element => (
   <components.MultiValueRemove {...props}>
      <IconTagClose className='multi-value-remove' />
   </components.MultiValueRemove>
);

export const ClearIndicator = (props): JSX.Element => (
   <components.ClearIndicator {...props}>
      <IconTagClose className='pointer' />
   </components.ClearIndicator>
);

export const DropdownIndicator = (props): JSX.Element => (
   <components.DropdownIndicator {...props}>
      <IconDownArrow fill='#B7B7B7' />
   </components.DropdownIndicator>
);

export const selectComponents = {
   ClearIndicator,
   DropdownIndicator,
   MultiValueRemove,
   IndicatorSeparator: () => null,
   LoadingIndicator: () => null,
};

export const selectStyle = {
   control: (base) => ({
      ...base,
      minHeight: '35px',
      '&:hover': {},
   }),
   option: (styles, { isSelected }) => ({
      ...styles,
      fontFamily: 'Karla',
      '.details': {
         fontSize: '14px',
         color: isSelected ? 'white' : '#807F86',
      },
      ':active': {
         ...styles[':active'],
         backgroundColor: '#007CBB',
         color: 'white',
         '.details': {
            color: 'white',
         },
      },
   }),
   placeholder: (styles) => ({
      ...styles,
      fontWeight: 400,
      fontFamily: 'Karla',
      color: '#807F86',
   }),
   multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {},
   }),
   multiValue: (styles) => ({
      ...styles,
      borderRadius: '4px',
   }),
   multiValueLabel: (styles) => ({
      ...styles,
      padding: '5px',
      paddingLeft: '7px',
   }),
   singleValue: (styles) => ({
      ...styles,
      fontFamily: 'Karla',
      color: '#413f48',
      fontSize: '15px',
   }),
};
