import * as _ from 'lodash';
import * as React from 'react';

import Link from '@components/Common/Link';
import { IOnboardingProps, OnboardingContext } from '@components/Onboarding';
import { GradeActivityStep } from '@components/Onboarding/Walkthroughs/GradeActivity';
import IconBin from '@icons/nova-line/01-Content-Edition/bin.svg';
import IconBinoculars from '@icons/nova-line/01-Content-Edition/binoculars.svg';
import IconDataDownload8 from '@icons/nova-line/23-Data-Transfer/data-download-8.svg';
import IconHighlight from '@icons/nova-line/32-Design/highlight.svg';
import IconPaperGradeA from '@icons/nova-line/41-School&Science/paper-grade-a.svg';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { ModuleItem, ModuleItemType } from '@models/Course';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import Constants from '../../../Constants';
import {
   isActivity,
   isContent,
   isFile,
   isLesson,
   isLink,
   isVideo,
   isVocabSet,
} from './ModuleUtils';

interface ActionIconBarProps {
   canEditModule: boolean;
   canPreviewContent: boolean;
   isInvalid: boolean;
   isSaving: boolean;
   item: ModuleItem;
   openRemoveModal(): void;
}

const ActionIconBar: React.FC<ActionIconBarProps> = ({
   canEditModule,
   canPreviewContent,
   isInvalid,
   isSaving,
   item,
   openRemoveModal,
}) => {
   const { getOnboardingClassName } = React.useContext<IOnboardingProps>(OnboardingContext);

   const {
      activities: { viewAllSubmissions },
      content: {
         bulkVideoUpload,
         editActivity,
         editLesson,
         editVocabSet,
         previewActivity,
         viewLesson,
         viewVideo,
         viewVocabSet,
      },
      vocabSets: { overview: vocabSetOverview },
   } = Constants.routes;

   const renderSubmissionsButton = (): React.ReactNode => {
      const link = (
         item.itemType === ContentType.activity ? viewAllSubmissions : vocabSetOverview
      ).replace(':moduleItemId', item.id.toString());
      const text = item.itemType === ContentType.activity ? 'Submissions' : 'Class Progress';

      return (
         <Tippy content={text}>
            <div className='action-icon'>
               <Link
                  className={classnames(
                     'icon-action',
                     getOnboardingClassName?.(GradeActivityStep.submissionLink),
                  )}
                  to={link}
                  data-test='activity-submissions'
               >
                  <IconPaperGradeA />
               </Link>
            </div>
         </Tippy>
      );
   };

   const renderPreviewButton = (): React.ReactNode => {
      let link = '';
      let tooltip = '';
      let external = false;
      let icon = <IconBinoculars />;
      if (isActivity(item)) {
         link = previewActivity
            .replace(':activityId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id.toString()}`);
         tooltip = 'Preview';
      } else if (isVocabSet(item)) {
         link = viewVocabSet
            .replace(':vocabSetId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id.toString()}`);
         tooltip = 'View';
      } else if (isLesson(item)) {
         link = viewLesson
            .replace(':lessonId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id.toString()}`);
         tooltip = 'View';
      } else if (isVideo(item)) {
         link = viewVideo
            .replace(':videoId', item.itemId.toString())
            .concat(`?moduleItemId=${item.id.toString()}`);
         tooltip = 'View';
      } else if (isFile(item) && _.isString(item.fileUrl)) {
         link = item.fileUrl;
         tooltip = 'Download';
         icon = <IconDataDownload8 />;
         external = true;
      } else if (isLink(item) && _.isString(item.linkUrl)) {
         link = item.linkUrl;
         tooltip = 'Visit';
         external = true;
      }
      return (
         <Tippy content={tooltip}>
            <div className='action-icon'>
               {canPreviewContent && !!link ? (
                  <Link className='icon-action' external={external} to={link}>
                     {icon}
                  </Link>
               ) : (
                  <div className='icon-action disabled'>{icon}</div>
               )}
            </div>
         </Tippy>
      );
   };

   const renderEditButton = (): Maybe<React.ReactNode> => {
      let link = '';
      if (isActivity(item)) {
         link = editActivity.replace(':activityId', item.itemId.toString());
      } else if (isVocabSet(item)) {
         link = editVocabSet.replace(':vocabSetId', item.itemId.toString());
      } else if (isLesson(item)) {
         link = editLesson.replace(':lessonId', item.itemId.toString());
      } else if (isVideo(item)) {
         link = `${bulkVideoUpload}?videoId=${item.itemId}`;
      }

      if (!link) {
         return;
      }

      return (
         <Tippy content='Edit'>
            <div className='action-icon'>
               {isContent(item) && item.canEdit ? (
                  <Link
                     className='icon-action'
                     to={link.concat(`?moduleItemId=${item.id.toString()}`)}
                  >
                     <IconHighlight />
                  </Link>
               ) : (
                  <div className='icon-action disabled'>
                     <IconHighlight />
                  </div>
               )}
            </div>
         </Tippy>
      );
   };

   const renderRemoveButton = (): React.ReactNode => (
      <Tippy content='Remove'>
         <div className='action-icon'>
            <div
               className={classnames('icon-action', {
                  disabled: !canEditModule,
               })}
               onClick={canEditModule ? openRemoveModal : undefined}
            >
               <IconBin />
            </div>
         </div>
      </Tippy>
   );

   const assignableItemTypes: ModuleItemType[] = [ContentType.activity, ContentType.vocabSet];

   const editableItemTypes: ModuleItemType[] = [
      ContentType.activity,
      ContentType.vocabSet,
      ContentType.lesson,
      ContentType.video,
   ];

   const showSubmissionsButton = assignableItemTypes.includes(item.itemType);
   const showEditButton = editableItemTypes.includes(item.itemType);

   return (
      <div className='action-icon-bar'>
         <div className='action-icon-wrapper'>
            {showSubmissionsButton && renderSubmissionsButton()}
            {renderPreviewButton()}
            {showEditButton && renderEditButton()}
            {renderRemoveButton()}
         </div>
         {isInvalid ? (
            <div className='dirty-indication'>Not Saved</div>
         ) : (
            <div className={classnames('save-indication', { saved: !isSaving })}>
               {isSaving ? 'Saving...' : 'Saved'}
            </div>
         )}
      </div>
   );
};

export default ActionIconBar;
