import * as React from 'react';
import * as yup from 'yup';

import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import Appearance from '@models/Appearance';
import AdminNationalExamService, { ExtendEndDatesInput } from '@services/AdminNationalExamService';
import { isAxiosError } from 'axios';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';

import InfoTooltip from '@components/InfoTooltip';

type Props = {
   examId: number;
   closeModal(): void;
};

const ExtendEndDatesInputSchema = yup.object().shape({
   examId: yup.number().required().positive().integer(),
   newEndDate: yup.date().required(),
});

const ExtendEndDatesModal: React.FC<Props> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const {
      handleSubmit,
      control,
      formState: { errors, isDirty },
   } = useForm<ExtendEndDatesInput>({
      resolver: yupResolver<ExtendEndDatesInput>(ExtendEndDatesInputSchema),
      defaultValues: {
         examId: props.examId,
      },
   });

   const onSubmit = async (data: ExtendEndDatesInput): Promise<void> => {
      setIsSaving(true);

      try {
         await AdminNationalExamService.extendExamEndDate(data);
         setIsSaving(false);
         props.closeModal();
      } catch (e) {
         console.error(e);
         if (isAxiosError(e)) {
            alert(e.response?.data.error);
         }
      }
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading={`Extend End Dates for Exam ${props.examId}`}
         onClose={props.closeModal}
         bodyClassName='modal-body'
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            {
               text: 'Close',
               onClick: props.closeModal,
            },
         ]}
      >
         <form>
            <label className='field-title'>
               New End Date
               <InfoTooltip>
                  This will update course end dates for template and cloned exam courses, and module
                  item end dates for official and practice modules in those courses.
               </InfoTooltip>
            </label>
            <Controller
               name='newEndDate'
               control={control}
               render={({ field }) => (
                  <DateTimePicker
                     {...{ ...field, ref: null }}
                     className={classnames({ error: !!errors.newEndDate })}
                  />
               )}
            />
         </form>
      </ModalDialog>
   );
};

export default ExtendEndDatesModal;
