import * as Mousetrap from 'mousetrap';
import * as React from 'react';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import {
   Annotation,
   AnnotationType,
   AudioAnnotation,
   VideoRecordingResponse,
} from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe, MessageResponse } from '@models/Core';
import { uploadAudio } from '@services/AssetService';
import HttpService from '@services/HttpService';
import { diff } from 'deep-object-diff';

import { AppStateContext } from '../../../../AppState';
import { NewAudioAnnotation } from './Annotation';
import AudioWaveform, { AudioWaveformHandles } from './AudioWaveform';

interface VideoPromptProps {
   isActive: boolean;
   responseId: number;
   response: VideoRecordingResponse;
   updateResponse(update: Partial<VideoRecordingResponse>): Promise<void>;
}

const VideoPrompt: React.FC<VideoPromptProps> = ({
   isActive,
   responseId,
   response: { fileUrl, instructorAnnotations = [], annotationAuthors },
   updateResponse,
}) => {
   const videoElement = React.useRef<HTMLVideoElement>(null);

   const { userProfile } = React.useContext<AppStateContext>(AppStateContext);

   if (!userProfile) {
      return;
   }
   const { id: userId, firstName, lastName, profileImageUrl, email } = userProfile;

   const audioWaveformRef = React.useRef<AudioWaveformHandles>(null);
   const [isWaveformRendered, setIsWaveformRendered] = React.useState<boolean>(false);
   const [hasVideoMetadataLoaded, setHasVideoMetadataLoaded] = React.useState<boolean>(false);

   React.useEffect(() => {
      Mousetrap.bind('space', handleSpace);
      return () => {
         Mousetrap.unbind('space');
      };
   }, [isWaveformRendered]);

   React.useEffect(() => {
      if (isActive && !isWaveformRendered && hasVideoMetadataLoaded) {
         audioWaveformRef.current?.loadWaveform();
      }
   }, [hasVideoMetadataLoaded, isActive, isWaveformRendered]);

   const getAuthor = (createdBy?: number): Maybe<BasicUserProfile> => {
      if (!annotationAuthors) {
         return;
      }
      return createdBy && createdBy !== userId
         ? annotationAuthors[createdBy]
         : { id: userId, firstName, lastName, profileImageUrl, email };
   };

   const handleDeleteAnnotation = (annotationId: string | number): Promise<void> => {
      updateResponse({
         instructorAnnotations: instructorAnnotations.filter((i) => i.id !== annotationId),
      });
      return HttpService.deleteWithAuthToken<MessageResponse>(
         `/api/activities/responses/${responseId}/annotations/${annotationId}`,
      ).then();
   };

   const isExistingAudioAnnotation = (
      annotation: NewAudioAnnotation | AudioAnnotation,
   ): annotation is AudioAnnotation => typeof annotation.id === 'number';

   const handleSaveAnnotation = async (
      annotation: NewAudioAnnotation | AudioAnnotation,
   ): Promise<{ id: number } | void> => {
      let storedAudioFilename = '';
      let audioUrl = annotation.audioUrl;
      if (annotation.blob) {
         await uploadAudio(annotation.blob).then((f) => {
            storedAudioFilename = f.filename;
            audioUrl = f.url;
         });
      }

      if (isExistingAudioAnnotation(annotation)) {
         const unfilteredUpdate = { ...annotation, storedAudioFilename, blob: undefined };
         const existingAnnotation = instructorAnnotations.find((i) => i.id === annotation.id);
         if (!existingAnnotation) {
            return Promise.reject();
         }
         const updatedAnnotation: AudioAnnotation = {
            ...existingAnnotation,
            ...unfilteredUpdate,
            audioUrl,
            storedAudioFilename,
            blob: undefined,
         };
         updatedAnnotation.start = +updatedAnnotation.start.toFixed(3);
         updatedAnnotation.end = +updatedAnnotation.end.toFixed(3);

         const annotationDiff = diff(updatedAnnotation, existingAnnotation);

         if (!annotationDiff) {
            return Promise.resolve();
         }
         return HttpService.putWithAuthToken<MessageResponse>(
            `/api/activities/responses/${responseId}/annotations/${annotation.id}`,
            snakeCaseKeys(annotationDiff),
         )
            .then(() => {
               updateResponse({
                  instructorAnnotations: instructorAnnotations.map((i) =>
                     i.id === annotation.id ? updatedAnnotation : i,
                  ),
               });
            })
            .then(() => ({ id: annotation.id }));
      } else {
         const data: NewAudioAnnotation = {
            ...annotation,
            annotationType: AnnotationType.audio,
            start: +annotation.start.toFixed(3),
            end: +annotation.end.toFixed(3),
            id: null,
            blob: undefined,
            audioUrl,
            storedAudioFilename,
         };
         return HttpService.postWithAuthToken<{ msg: string; annotation: Annotation }>(
            `/api/activities/responses/${responseId}/annotations`,
            snakeCaseKeys(data),
         ).then((response) => {
            const {
               annotation: { id, createdBy, createdOn },
            } = response.data;
            updateResponse({
               instructorAnnotations: [
                  ...instructorAnnotations,
                  { ...annotation, id, createdBy, createdOn, audioUrl },
               ],
            });
            return { id };
         });
      }
   };

   const handleSpace = (event: Mousetrap.ExtendedKeyboardEvent): void => {
      // Prevent scroll on space
      event.preventDefault();
      togglePlaying();
   };

   const togglePlaying = (): void => {
      if (isWaveformRendered) {
         audioWaveformRef.current?.playPause();
      }
   };

   return (
      <div className='row'>
         <div className='col-xs-12'>
            {fileUrl ? (
               <div className='video-responsive standard margin-bottom-s'>
                  <video
                     className='elem'
                     src={fileUrl}
                     controls={false}
                     ref={videoElement}
                     onLoadedMetadata={() => setHasVideoMetadataLoaded(true)}
                  />
               </div>
            ) : (
               <p>
                  <i>No Recording</i>
               </p>
            )}
            {videoElement.current && instructorAnnotations && fileUrl && (
               <AudioWaveform
                  annotations={instructorAnnotations}
                  ref={audioWaveformRef}
                  canEdit
                  audioFileSource={fileUrl}
                  getAuthor={getAuthor}
                  loadWaveformOnMount={false}
                  media={videoElement.current}
                  onDeleteAnnotation={handleDeleteAnnotation}
                  onSaveAnnotation={handleSaveAnnotation}
                  onWaveformRendered={() => setIsWaveformRendered(true)}
               />
            )}
         </div>
      </div>
   );
};

export default VideoPrompt;
