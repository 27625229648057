import * as _ from 'lodash';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import tinymce from 'tinymce';

import Language from '@models/Language';

interface Voice {
   country: string;
   languageCode: string;
   name: string;
   ssmlGender: string;
}

tinymce.PluginManager.add('generateaudio', (editor) => {
   let currentLanguage: Maybe<Language> = editor.options.get('content_lang');
   const availableVoices: Voice[] = [];

   const updateVoices = (newLanguage: Maybe<Language>): void => {
      if (newLanguage === currentLanguage) {
         return;
      } else if (!newLanguage) {
         availableVoices.length = 0; // Clear existing voices
      }
      HttpService.getWithAuthToken<{ voices: readonly Voice[] }>(
         `/api/services/audio/google_voices?language=${newLanguage}`,
      ).then((response) => {
         const { voices } = response.data;
         availableVoices.length = 0; // Clear existing voices
         availableVoices.push(...voices.filter((i) => !i.name.includes('-Standard-')));
         availableVoices.forEach((i) => {
            const displayName = voiceNameToDisplayName(i.name);
            if (displayName) {
               editor.ui.registry.addMenuItem(i.name, {
                  text: displayName,
                  onAction: () => {
                     generateAudio(i.name);
                  },
               });
            }
         });

         editor.ui.registry.addNestedMenuItem('generateaudio', {
            text: 'Generate Audio',
            icon: 'speaker-volume-high-line',
            getSubmenuItems: () => availableVoices.map((i) => i.name).join(' '),
         });
         currentLanguage = newLanguage; // Update the current language
      });
   };

   const voiceNameToDisplayName = (voiceName: string): Maybe<string> => {
      const languageMap: Record<string, string> = {
         'en-AU': 'English (Australia)',
         'en-IN': 'English (India)',
         'en-GB': 'English (UK)',
         'en-US': 'English (US)',
         'fr-CA': 'French (Canada)',
         'fr-FR': 'French (France)',
         'de-DE': 'German (Germany)',
         'it-IT': 'Italian (Italy)',
         'pt-BR': 'Portuguese (Brazil)',
         'pt-PT': 'Portuguese (Portugal)',
         'es-ES': 'Spanish (Spain)',
         'es-US': 'Spanish (US)',
      };
      const languageCountyName = languageMap[voiceName.substring(0, 5)];
      if (!languageCountyName) {
         return null;
      }
      const tokens = voiceName.split('-'); // fr-CA-Wavenet-B
      if (tokens.length < 3) {
         return null;
      }
      const voiceType = tokens.at(2);
      const variation = tokens.at(3);
      const { ssmlGender } = availableVoices.find((i) => i.name === voiceName) ?? {};
      if (!ssmlGender) {
         return null;
      }
      const genderText = _.startCase(ssmlGender);
      return `${languageCountyName} ${voiceType} ${variation} (${genderText})`;
   };

   const insertAudioNode = (audioUrl: string) => {
      // Create a new audio element with the given source
      const placeholderNode = document.createElement('audio');
      placeholderNode.setAttribute('src', audioUrl);
      placeholderNode.setAttribute('controls', 'controls');
      placeholderNode.setAttribute('data-player-size', 'full');

      // Insert the audio element below the selected text
      const selectedNode = editor.selection.getNode();
      const parentNode = selectedNode.parentNode;
      if (!parentNode) {
         return;
      }
      parentNode.insertBefore(placeholderNode, selectedNode.nextSibling);

      // Create a custom event object with the node change information
      const event = {
         element: selectedNode,
         parent: selectedNode.parentNode,
         parents: [selectedNode.parentNode], // Array of parent nodes
      };

      // Trigger the node change event
      editor.fire('NodeChange', event);
   };

   const generateAudio = (voiceName: string) => {
      const selectedText = editor.selection.getContent({ format: 'text' });
      const selectedVoice = availableVoices.find((i) => i.name === voiceName);
      if (!selectedText || !selectedVoice) {
         return;
      }
      const { languageCode } = selectedVoice;
      HttpService.postWithAuthToken<{ filename: string; url: string }>(
         '/api/services/audio/synthesize',
         snakeCaseKeys({
            voiceName,
            text: selectedText,
            languageCode,
         }),
         { handleServerError: false },
      )
         .then((response) => {
            const { url } = response.data;
            insertAudioNode(url);
         })
         .catch(() => {
            alert('Unable to generate audio');
         });
   };

   editor.ui.registry.addContextMenu('generateaudio', {
      update: (element) => {
         if (
            ['IMG', 'AUDIO', 'VIDEO', 'DIV', 'TABLE'].includes(element.tagName) ||
            editor.selection.isCollapsed()
         ) {
            return '';
         }
         return 'generateaudio';
      },
   });

   editor.on('contentLangChange', (e: { language: Maybe<Language> }) => {
      updateVoices(e.language);
   });

   return {
      getMetadata: () => ({
         name: 'Generate Audio',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
