import * as _ from 'lodash';
import * as React from 'react';

import Button from '@components/Common/Button';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';
import { getQueryParameterByName } from '@helpers/QueryParameter';
import GoogleIcon from '@icons/general/google.svg';
import SchoolIcon from '@icons/nova-solid/41-School&Science/graduation-hat.svg';
import { IApplicationState, PostLoginRedirect } from '@models/ApplicationState';
import { Maybe } from '@models/Core';
import IUserInfo from '@models/IUserInfo';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import UserService, { isTempPasswordResponse, LoginResponse } from '@services/UserService';
import { isAxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Constants from '../../Constants';

const Login: React.FC<Record<string, never>> = () => {
   const location = useLocation();
   const navigate = useNavigate();

   const {
      routes: { auth, dashboard, forgotPassword, register, resetPassword },
      statusCodes: { unauthorized },
   } = Constants;

   const postLoginRedirect = useSelector<IApplicationState, Maybe<PostLoginRedirect>>(
      (state) => state.postLoginRedirect,
   );

   const user = useSelector<IApplicationState, Maybe<IUserInfo>>((state) => state.user);

   const loggedIn = user?.loggedIn ?? false;

   const [email, setEmail] = React.useState('');
   const [password, setPassword] = React.useState('');
   const [remember, setRemember] = React.useState(false);
   const [error, setError] = React.useState('');
   const [isLoading, setIsLoading] = React.useState(false);
   const [invalidGoogleAccount, setInvalidGoogleAccount] = React.useState(false);
   const [samlName, setSamlName] = React.useState('');
   const [ssoFailure, setSsoFailure] = React.useState(false);

   React.useEffect(() => {
      // Preserve original destination
      let redirect = getQueryParameterByName(location, 'redirect', dashboard);
      redirect = _.get(location.state, 'from.pathname', redirect);
      redirect += _.get(location.state, 'from.search', '');
      UserService.saveLoginRedirectPath(redirect);

      if (loggedIn) {
         handleRedirect();
      } else {
         setEmail(getQueryParameterByName(location, 'email', ''));
         setPassword(getQueryParameterByName(location, 'password', ''));
         setInvalidGoogleAccount(
            getQueryParameterByName(location, 'invalid_google_account') === 'true',
         );
         setSsoFailure(getQueryParameterByName(location, 'sso_failure') === 'true');

         // TODO: If we can find the specific unwanted query parameters, we can strip
         // those and leave the rest.
         const uri = window.location.toString();
         if (uri.indexOf('?') > 0) {
            const cleanUri = uri.substring(0, uri.indexOf('?'));
            window.history.replaceState({}, document.title, cleanUri);
         }
      }

      return () => {
         UserService.deleteLoginRedirectPath();
      };
   }, []);

   const validateForm = (): boolean => {
      const isValid = !!(email.length && password.length);
      setError(isValid ? '' : 'Invalid credentials');
      return isValid;
   };

   const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setEmail(event.target.value);
   };

   const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setPassword(event.target.value);
   };

   const handleRememberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setRemember(event.target.checked);
   };

   const handleRedirect = async (data?: LoginResponse): Promise<void> => {
      let redirect = UserService.getLoginRedirectPath();
      if (isTempPasswordResponse(data)) {
         const resetToken = data?.resetToken;
         if (resetToken) {
            redirect = `${resetPassword}?token=${resetToken}&temp=true`;
         }
      }
      const now = new Date();
      const usePostLoginRedirect =
         postLoginRedirect &&
         ((typeof postLoginRedirect.expiration === 'string' &&
            now < new Date(postLoginRedirect.expiration)) ||
            (postLoginRedirect.expiration instanceof Date && now < postLoginRedirect.expiration));
      if (usePostLoginRedirect) {
         store.dispatch(actionFunctions.clearPostLoginRedirect());
         navigate(postLoginRedirect.path, { replace: true });
      } else if (redirect.startsWith('/')) {
         navigate(redirect, { replace: true });
      } else {
         window.location.replace(redirect);
      }
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (validateForm()) {
         setIsLoading(true);
         setInvalidGoogleAccount(false);
         UserService.login(email, password)
            .then(handleRedirect)
            .catch((loginError) => {
               if (isAxiosError(loginError)) {
                  const { response } = loginError;
                  if (response && response.status === unauthorized) {
                     const { msg: responseError = '', responseSamlName = '' } = response.data;
                     setIsLoading(false);
                     setError(responseError);
                     setSamlName(responseSamlName);
                     return;
                  }
               }
               const unexpectedError =
                  'Something unexpected happened please try again. If the problem persists please reach out to support.';
               setIsLoading(false);
               setError(unexpectedError);
               throw error;
            });
      }
   };

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>Login</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           Login
                        </h2>
                     </div>
                     <form className='content-form' onSubmit={handleSubmit}>
                        <div>
                           <label htmlFor='email' className='margin-top-s'>
                              Your Email Address
                           </label>
                           <input
                              autoComplete='username'
                              className='margin-top-s'
                              data-test='email'
                              id='email'
                              name='email'
                              onChange={handleEmailChange}
                              type='email'
                              value={email}
                           />
                        </div>
                        <div>
                           <label htmlFor='password' className='margin-top-s'>
                              Password
                           </label>
                           <input
                              aria-labelledby='password-label'
                              autoComplete='current-password'
                              className='margin-top-s'
                              data-dd-privacy='input-ignored'
                              data-test='password'
                              id='password'
                              name='password'
                              onChange={handlePasswordChange}
                              type='password'
                              value={password}
                           />
                           {error && (
                              <p className='error' data-test='login-error-message'>
                                 {error}
                              </p>
                           )}
                        </div>
                        {invalidGoogleAccount && (
                           <div className='alert alert-danger margin-top-s'>
                              This Lingco account doesn&apos;t exist. Are you signed in to the
                              correct Google Account?
                           </div>
                        )}
                        {ssoFailure && (
                           <div className='alert alert-danger margin-top-s'>
                              We were unable to log you in using your single sign-on provider.
                           </div>
                        )}
                        {samlName && (
                           <div className='alert alert-primary margin-top-s'>
                              Your school uses a Single Sign On provider.
                              <br />
                              <Link external to={`/auth/saml/${samlName}`}>
                                 Click here
                              </Link>{' '}
                              to login.
                           </div>
                        )}
                        <div className='margin-top-m space-between align-items-center'>
                           <div className='align-items-center'>
                              <input
                                 name='remember'
                                 type='checkbox'
                                 id='remember'
                                 checked={remember}
                                 onChange={handleRememberChange}
                              />
                              <label htmlFor='remember'>Remember Me</label>
                           </div>
                           <Link
                              to={forgotPassword}
                              className='gray text-sm'
                              data-test='forgot-password'
                           >
                              Forgot password?
                           </Link>
                        </div>
                        <Button
                           type='submit'
                           fullWidth
                           loading={isLoading}
                           className='margin-top-m margin-bottom-s'
                           data-test='login-button'
                        >
                           Login
                        </Button>
                     </form>
                     <div className='hr-sect'>or</div>
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <Link
                              external
                              to={`${window.location.origin}/api/auth/google/auth_request_uri?action=login`}
                              className='btn line full-width margin-top-s'
                           >
                              <GoogleIcon aria-hidden />
                              Google
                           </Link>
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <Link to={auth.loginSso} className='btn line full-width margin-top-s'>
                              <SchoolIcon aria-hidden />
                              School Credentials
                           </Link>
                        </div>
                     </div>
                     <div className='margin-top-m padding-top-s border-top space-between align-items-center'>
                        <span className='gray-text text-sm'>Don't have an account yet?</span>
                        <Link className='btn line small' to={register} data-test='sign-up-button'>
                           Sign up
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(Login);
