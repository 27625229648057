import * as React from 'react';

import { LanguageLookup } from '@models/Language';

export default [
   <option key={0} disabled value=''>
      Select
   </option>,
   ...Object.entries(LanguageLookup).map(([code, label]) => (
      <option key={code} value={code}>
         {label}
      </option>
   )),
];
