// @ts-strict-ignore
/* eslint-disable complexity */
import { parseDates } from '@helpers/DateUtils';
import { isNetworkConnectionError } from '@helpers/Error';
import History from '@helpers/History';
import { camelCaseKeys } from '@helpers/ModifyKeys';
import { Maybe } from '@models/Core';
import axios, {
   AxiosError,
   AxiosProgressEvent,
   AxiosPromise,
   AxiosResponse,
   CancelToken,
} from 'axios';
import axiosRetry from 'axios-retry';

import Constants from '../Constants';
import { actionFunctions } from '@redux/Actions';
import { store } from '@redux/Store';
import { IgnorableHttpError } from '../types/IgnorableHttpError';
import AuthService from './AuthService';
import { isCriticalActivityUrl } from './AxiosService';

const {
   statusCodes: {
      forbidden,
      badRequest,
      notFound,
      paymentRequired,
      serverError,
      unauthorized,
      unprocessableEntity,
   },
} = Constants;

export interface HttpServiceRequestOptions {
   camelCaseKeys: boolean;
   cancelToken?: CancelToken | undefined;
   handleForbidden: boolean;
   handleNotFound: boolean;
   handlePaymentRequired: boolean;
   handleServerError: boolean;
   handleNetworkConnectionError: boolean;
   parseDates: boolean;
   onUploadProgress(progressEvent: AxiosProgressEvent): void;
   onDownloadProgress(progressEvent: AxiosProgressEvent): void;
}

type RequestBody = Record<string, unknown> | FormData;

const transformResponse = (
   data: Record<string, unknown>,
   options: Partial<HttpServiceRequestOptions> = {},
): Record<string, unknown> => {
   let result = { ...data };
   const config: Partial<HttpServiceRequestOptions> = {
      camelCaseKeys: true,
      parseDates: true,
      ...options,
   };
   if (config.camelCaseKeys) {
      result = camelCaseKeys(result);
   }
   if (config.parseDates) {
      result = parseDates(result);
   }

   return result;
};

const makeCallWithAuthToken = async <T>(
   callFn: (token: string) => AxiosPromise<T>,
   options: Partial<HttpServiceRequestOptions> = {},
): Promise<AxiosResponse<T>> => {
   const config: Partial<HttpServiceRequestOptions> = {
      handleServerError: true,
      handleNotFound: true,
      handleForbidden: true,
      handlePaymentRequired: true,
      handleNetworkConnectionError: true,
      ...options,
   };

   let token = AuthService.getAccessToken();
   let error: Maybe<AxiosError> = null;
   let fetchedNewToken = false;

   // we'll try and make the API call, and if it fails due to an unauthenticated
   // user we'll fetch a new token and try again
   while (!error) {
      try {
         return await callFn(token);
      } catch (callError) {
         const axiosError = callError as AxiosError<{ msg: string }>;
         const statusCode = axiosError?.response?.status || undefined;
         const errorMessage = axiosError?.response?.data?.msg || undefined;
         const isAuthError =
            statusCode === unauthorized ||
            (statusCode === unprocessableEntity &&
               errorMessage === 'Signature verification failed');

         if (isAuthError && !fetchedNewToken) {
            token = await AuthService.refreshAccessToken();

            if (!token) {
               // if we couldn't refresh the token, we'll pass this 401 error through
               error = axiosError;
               console.error(
                  'Refresh token did not return access token (in makeCallWithAuthToken)',
               );
            } else {
               fetchedNewToken = true;
            }
         } else {
            error = axiosError;
         }
      }
   }

   const registeredHttpErrors = new Map<number, boolean>([
      // HTTP_STATUS_CODE, SHOULD_BE_HANDLED_BY_CALLER
      [serverError, !config.handleServerError],
      [notFound, !config.handleNotFound],
      [forbidden, !config.handleForbidden],
      [paymentRequired, !config.handlePaymentRequired],
      [badRequest, true], // TODO: we shouldn't assume the caller will always handle BAD_REQUESTs
   ]);

   // if we've gotten this far then there must have been a non-401 error
   // we'll handle it appropriately depending on the environment
   let errorHandled = false;
   if (error.response) {
      const isRegisteredError = registeredHttpErrors.has(error.response.status);
      const errorIsHandledByCaller = registeredHttpErrors.get(error.response.status);

      if (isRegisteredError) {
         if (!errorIsHandledByCaller) {
            errorHandled = true;
            store.dispatch(actionFunctions.setNetworkError(error.response));
         }
      } else if (error.response.status === unauthorized) {
         // we're logging the user out in 'refreshToken' too, but that's called from
         errorHandled = true;
         AuthService.logoutRedirect();
      } else {
         errorHandled = true;
         store.dispatch(actionFunctions.setNetworkError(error.response));
      }
   } else if (
      isNetworkConnectionError(error) &&
      isCriticalActivityUrl(error?.config?.url) &&
      error.config
   ) {
      console.error(
         `Network connection error when calling ${String(error.config.method).toUpperCase()} - "${
            error.config.url
         }"`,
      );
      if (config.handleNetworkConnectionError) {
         errorHandled = true;
         // Using a custom HTTP Status code for now to avoid refactoring 'network error' process
         // TODO: Refactor Network Error process to handle non-HTTP-Status errors better
         store.dispatch(actionFunctions.setNetworkError({ status: 900 } as AxiosResponse));
      }
   }

   if (errorHandled) {
      throw new IgnorableHttpError(error.message || undefined);
   } else {
      throw error;
   }
};

const get = <T>(
   url: string,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   axios.get<T, AxiosResponse<T>>(url, {
      transformResponse: [].concat(
         axios.defaults.transformResponse,
         (data: Record<string, unknown>) => transformResponse(data, options),
      ),
      cancelToken: options?.cancelToken,
      onDownloadProgress: options?.onDownloadProgress,
   });

const post = <T>(
   url: string,
   requestBody?: RequestBody,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   axios.post<T, AxiosResponse<T>>(url, requestBody, {
      headers: { 'Content-Type': 'application/json' },
      transformResponse: [].concat(
         axios.defaults.transformResponse,
         (data: Record<string, unknown>) => transformResponse(data, options),
      ),
      cancelToken: options?.cancelToken,
      onUploadProgress: options?.onUploadProgress,
   });

const deleteWithAuthToken = <T>(
   url: string,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   makeCallWithAuthToken(
      (token) =>
         axios.delete<T, AxiosResponse<T>>(url, {
            headers: { Authorization: `Bearer ${token}` },
            transformResponse: [].concat(
               axios.defaults.transformResponse,
               (data: Record<string, unknown>) => transformResponse(data, options),
            ),
            cancelToken: options?.cancelToken,
         }),
      options,
   );

const getWithAuthToken = <T>(
   url: string,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   makeCallWithAuthToken(
      (token) =>
         axios.get<T, AxiosResponse<T>>(url, {
            headers: { Authorization: `Bearer ${token}` },
            transformResponse: [].concat(
               axios.defaults.transformResponse,
               (data: Record<string, unknown>) => transformResponse(data, options),
            ),
            cancelToken: options?.cancelToken,
            onDownloadProgress: options?.onDownloadProgress,
         }),
      options,
   );

const patchWithAuthToken = <T>(
   url: string,
   requestBody?: RequestBody,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   makeCallWithAuthToken(
      (token) =>
         axios.patch<T, AxiosResponse<T>>(url, requestBody, {
            headers: { Authorization: `Bearer ${token}` },
            transformResponse: [].concat(
               axios.defaults.transformResponse,
               (data: Record<string, unknown>) => transformResponse(data, options),
            ),
            cancelToken: options?.cancelToken,
            onUploadProgress: options?.onUploadProgress,
         }),
      options,
   );

const postWithAuthToken = <T>(
   url: string,
   requestBody?: RequestBody,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   makeCallWithAuthToken(
      (token) =>
         axios.post<T, AxiosResponse<T>>(url, requestBody, {
            headers: { Authorization: `Bearer ${token}` },
            transformResponse: [].concat(
               axios.defaults.transformResponse,
               (data: Record<string, unknown>) => transformResponse(data, options),
            ),
            cancelToken: options?.cancelToken,
            onUploadProgress: options?.onUploadProgress,
         }),
      options,
   );

const putWithAuthToken = <T>(
   url: string,
   requestBody?: RequestBody,
   options?: Partial<HttpServiceRequestOptions>,
): Promise<AxiosResponse<T>> =>
   makeCallWithAuthToken(
      (token) =>
         axios.put<T>(url, requestBody, {
            headers: { Authorization: `Bearer ${token}` },
            transformResponse: [].concat(
               axios.defaults.transformResponse,
               (data: Record<string, unknown>) => transformResponse(data, options),
            ),
            cancelToken: options?.cancelToken,
            onUploadProgress: options?.onUploadProgress,
         }),
      options,
   );

// if any API call returns an object what has a 'redirectTo' property we'll do the redirect ourselves
// since the browser intercepts 302s for API calls
axios.interceptors.response.use(
   (response) => {
      const redirectTo = response?.data?.redirectTo || response?.data?.redirect_to;

      if (redirectTo) {
         // Redirect user
         History.push(redirectTo);

         // we don't want the caller to actually get a response of any sort
         // so we'll just delay the response until the redirect (above) causes
         // the page to reload
         return new Promise((resolve) => setTimeout(resolve, 10000));
      }

      return response;
   },
   (error) => Promise.reject(error),
);

axiosRetry(axios, {
   retries: 3,
   retryDelay: axiosRetry.exponentialDelay,
   retryCondition: (error) => {
      if (isNetworkConnectionError(error) && isCriticalActivityUrl(error?.config?.url)) {
         console.warn(
            `Retrying API call due to network connection error for ${String(
               error.config.method,
            ).toUpperCase()} - "${error.config.url}"`,
         );
         return true;
      }
      return false;
   },
});

export default {
   get,
   post,
   deleteWithAuthToken,
   getWithAuthToken,
   patchWithAuthToken,
   postWithAuthToken,
   putWithAuthToken,
};
