import * as _ from 'lodash';

import { snakeCaseKeys } from '@helpers/ModifyKeys';
import {
   Activity,
   ActivityMode,
   CurrentQuestionIdPostData,
   CurrentQuestionIdResponse,
} from '@models/Activity';
import Content from '@models/Content';
import { Maybe } from '@models/Core';
import AxiosService from '@services/AxiosService';
import HttpService from '@services/HttpService';
import { AxiosResponse } from 'axios';

import mixPanelActions from '../Mixpanel';

const BASE_API = '/api/activities';

export interface MessageResponse {
   msg: string;
}

export const resetSubmissions = (
   submissionIds: readonly number[],
   resetStartTimes: boolean,
   clearEvaluations: boolean,
   attempts: Maybe<number>,
): Promise<AxiosResponse<MessageResponse>> => {
   const url = '/api/activities/submissions';

   const newAttempts: Maybe<number> = Number.isInteger(attempts) ? attempts : null;

   const body = {
      attempts: newAttempts,
      submissionIds,
      resetStartTimes,
      clearEvaluations,
   };

   return HttpService.patchWithAuthToken<MessageResponse>(url, snakeCaseKeys(body));
};

export const createActivity = (body: Activity<ActivityMode.create>): Promise<number> => {
   const activitiesRoute = '/api/content/activities';
   return HttpService.postWithAuthToken<{ id: number }>(activitiesRoute, snakeCaseKeys(body)).then(
      (response) => {
         const { id: newActivityId } = response.data;

         mixPanelActions.track('Activity Created', {
            name: body.name,
            id: newActivityId,
            language: body.language,
            items: _.flatten(body.questions.map((i) => i.items)).length,
         });
         return newActivityId;
      },
   );
};

export const updateActivity = (body: Activity<ActivityMode.edit> | Content): Promise<unknown> => {
   const activitiesRoute = `/api/content/activities/${body.id}`;
   return HttpService.putWithAuthToken(activitiesRoute, snakeCaseKeys(body));
};

export const updateCurrentQuestionId = (
   id: number | string,
   data: CurrentQuestionIdPostData = {},
   isPreview = false,
): Promise<CurrentQuestionIdResponse> => {
   const preview = isPreview ? '/preview' : '';
   const url = `/api/activities/${id}${preview}/current_question_id`;

   return HttpService.postWithAuthToken<CurrentQuestionIdResponse>(url, snakeCaseKeys(data), {
      handleNetworkConnectionError: false,
   }).then((response) => response.data);
};

const getScoreReport = (moduleItemId: number | string): Promise<Blob> =>
   AxiosService.get<Blob>(`${BASE_API}/${moduleItemId}/score_report`, {
      downloadFile: true,
   }).then((response) => response.data);

export default {
   getScoreReport,
};
