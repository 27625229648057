import * as _ from 'lodash';
import * as React from 'react';

import Switch from '@components/Common/Switch';
import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';
import { InstructorLicense } from '@generated/gql/graphql';
import { upsertInstructorLicneseMutation as upsertInstructorLicenseMutation } from '@graphql/queries';
import { zodResolver } from '@hookform/resolvers/zod';
import Appearance from '@models/Appearance';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { z } from 'zod';

type Props = {
   closeModal(): void;
   instructorId: string;
   instructorLicense?: InstructorLicense;
   onSave(): void;
};

type InstructorLicenseFormInput = Pick<
   InstructorLicense,
   | 'canManageContentLibrary'
   | 'canManageCourses'
   | 'canViewGlobalLibrary'
   | 'expiresOn'
   | 'notes'
   | 'numberOfSeats'
   | 'quickbooksInvoiceId'
>;

const InstructorLicenseInputSchema = z
   .object({
      canManageContentLibrary: z.boolean(),
      canManageCourses: z.boolean(),
      canViewGlobalLibrary: z.boolean(),
      expiresOn: z.date().nullable(),
      notes: z.string().nullable(),
      numberOfSeats: z.coerce.number().nullable(),
      quickbooksInvoiceId: z.coerce.number().nullable(),
   })
   .superRefine(({ numberOfSeats }, refinementContext) => {
      // Zod has a positive() method that checks if the number is positive, but
      // it doesn't work well with optional values. So we have to do it manually.
      if (numberOfSeats !== null && numberOfSeats !== undefined && numberOfSeats < 0) {
         return refinementContext.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'forms.validations.numberOfSeatsMustBePositive',
            path: ['numberOfSeats'],
         });
      }
   });

const InstructorLicenseModal: React.FC<Props> = (props) => {
   const {
      handleSubmit,
      register,
      control,
      formState: { errors, isDirty },
   } = useForm<InstructorLicenseFormInput>({
      resolver: zodResolver(InstructorLicenseInputSchema),
      defaultValues: {
         canManageContentLibrary: false,
         canManageCourses: false,
         canViewGlobalLibrary: false,
         notes: null,
         numberOfSeats: null,
         quickbooksInvoiceId: null,
         ...props.instructorLicense,
         expiresOn: props.instructorLicense?.expiresOn
            ? new Date(props.instructorLicense.expiresOn)
            : null,
      },
   });

   const [{ fetching: isSaving }, executeUpsertInstructorLicenseMutation] = useMutation(
      upsertInstructorLicenseMutation,
   );

   const onSubmit = async (data: InstructorLicenseFormInput): Promise<void> => {
      await executeUpsertInstructorLicenseMutation({
         instructorLicenseId: props.instructorLicense?.id,
         instructorLicenseInput: { instructorId: props.instructorId, ...data },
      });
      props.onSave();
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         onClose={props.closeModal}
         className='no-padding'
         bodyClassName='modal-body'
         headerClassName={classnames(
            'modal-header',
            'full-width',
            'card-title',
            'full-width',
            Appearance.primary,
         )}
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            { text: 'Close', onClick: props.closeModal },
         ]}
      >
         <div className='text-left margin-left-s padding-m'>
            <form>
               <div className='row'>
                  <div className='col-xs-4'>
                     <label className='field-title'>License ID</label>
                     <input type='number' value={props.instructorLicense?.id} disabled />
                  </div>
                  <div className='col-xs-4'>
                     <label className='field-title'>Created By</label>
                     <input type='number' value={props.instructorLicense?.createdBy} disabled />
                  </div>
                  <div className='col-xs-4'>
                     <label className='field-title'>Created On</label>
                     <DateTimePicker
                        disabled
                        value={
                           props.instructorLicense?.createdOn
                              ? new Date(props.instructorLicense.createdOn)
                              : null
                        }
                     />
                  </div>
               </div>
               <div className='row'>
                  <div className='col-xs-4'>
                     <label className='field-title'>Number of Student Seats</label>
                     <input
                        {...register('numberOfSeats')}
                        type='number'
                        className={classnames({ error: !!errors.numberOfSeats })}
                     />
                  </div>
                  <div className='col-xs-4'>
                     <label className='field-title'>Quickbooks Invoice ID</label>
                     <input
                        {...register('quickbooksInvoiceId')}
                        type='number'
                        className={classnames({ error: !!errors.quickbooksInvoiceId })}
                     />
                  </div>
                  <div className='col-xs-4'>
                     <label className='field-title'>Expires On</label>
                     <Controller
                        name='expiresOn'
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                           <DateTimePicker
                              {...rest}
                              className={classnames({ error: !!errors.expiresOn })}
                           />
                        )}
                     />
                  </div>
               </div>
               <Controller
                  name='canManageCourses'
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                     <Switch
                        {...rest}
                        checked={value}
                        tooltip='Can create and use courses that contain publisher or other content.'
                        className={classnames({ error: !!errors.canManageCourses })}
                     >
                        <label className='field-title'>Can Manage Courses</label>
                     </Switch>
                  )}
               />
               <Controller
                  name='canManageContentLibrary'
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                     <Switch
                        {...rest}
                        checked={value}
                        tooltip='Can navigate to the content library and has access to be able to create and manage content. This does not include the global library.'
                        className={classnames({ error: !!errors.canManageContentLibrary })}
                     >
                        <label className='field-title'>Can Manage Content Library</label>
                     </Switch>
                  )}
               />
               <Controller
                  name='canViewGlobalLibrary'
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                     <Switch
                        {...rest}
                        checked={value}
                        tooltip='Can view the Global Library and use content from it.'
                        className={classnames({ error: !!errors.canViewGlobalLibrary })}
                     >
                        <label className='field-title'>Can View Global Library</label>
                     </Switch>
                  )}
               />
               <label className='field-title'>Notes</label>
               <textarea {...register('notes')} className={classnames({ error: !!errors.notes })} />
            </form>
         </div>
      </ModalDialog>
   );
};

export default InstructorLicenseModal;
