import * as React from 'react';

interface InfiniteScrollProps extends React.HTMLProps<HTMLDivElement> {
   children: React.ReactNode;
   hasMore: boolean;
   threshold?: number;
   onLoadMore(): Promise<void>;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
   children,
   hasMore,
   threshold = 0,
   onLoadMore,
   ...rest
}) => {
   const [isLoading, setIsLoading] = React.useState<boolean>(false);

   const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
      const target = event.target as HTMLDivElement;
      const { scrollTop, clientHeight, scrollHeight } = target;

      if (scrollTop + clientHeight + threshold >= scrollHeight) {
         if (!isLoading && hasMore) {
            setIsLoading(true);
            onLoadMore().then(() => setIsLoading(false));
         }
      }
   };

   return (
      <div {...rest} onScroll={handleScroll}>
         {children}
      </div>
   );
};

export default InfiniteScroll;
