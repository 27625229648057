export interface Block {
   text: string;
   value: string;
   description: string;
   inline?: boolean;
   img?: string;
}

const LESSON_BLOCKS: readonly Block[] = [
   {
      text: 'Quick Start',
      value: 'quick-start-callout',
      description: 'Give a short overview or summary.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='-0.001' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M11%2c22c-0.111%2c0-0.223-0.018-0.331-0.057C10.268%2c21.802%2c10%2c21.424%2c10%2c21v-7H5 c-0.384%2c0-0.735-0.221-0.901-0.567s-0.12-0.756%2c0.121-1.058l8-10c0.265-0.331%2c0.711-0.459%2c1.112-0.318 C13.732%2c2.197%2c14%2c2.575%2c14%2c3v7h5c0.384%2c0%2c0.735%2c0.221%2c0.901%2c0.567s0.12%2c0.757-0.121%2c1.058l-8%2c10C11.587%2c21.866%2c11.298%2c22%2c11%2c22z M7.081%2c12H11c0.552%2c0%2c1%2c0.448%2c1%2c1v5.15L16.919%2c12H13c-0.552%2c0-1-0.448-1-1V5.85L7.081%2c12z'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   }, //
   {
      text: 'Examples',
      value: 'examples',
      description: 'Show usage in context.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0.003' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M20%2c8h-9C9.896%2c8%2c9%2c7.103%2c9%2c6V4c0-1.103%2c0.896-2%2c2-2h9c1.104%2c0%2c2%2c0.897%2c2%2c2v2 C22%2c7.103%2c21.104%2c8%2c20%2c8z M11%2c4v2h8.997L20%2c4H11z'/%3e %3c/g%3e %3cg%3e %3cpath d='M20%2c15h-9c-1.104%2c0-2-0.897-2-2v-2c0-1.103%2c0.896-2%2c2-2h9c1.104%2c0%2c2%2c0.897%2c2%2c2v2 C22%2c14.103%2c21.104%2c15%2c20%2c15z M11%2c11v2h8.997L20%2c11H11z'/%3e %3c/g%3e %3cg%3e %3cpath d='M20%2c22h-9c-1.104%2c0-2-0.897-2-2v-2c0-1.103%2c0.896-2%2c2-2h9c1.104%2c0%2c2%2c0.897%2c2%2c2v2 C22%2c21.103%2c21.104%2c22%2c20%2c22z M11%2c18v2h8.997L20%2c18H11z'/%3e %3c/g%3e %3cg%3e %3cpath d='M6%2c8H4C2.896%2c8%2c2%2c7.103%2c2%2c6V4c0-1.103%2c0.896-2%2c2-2h2c1.104%2c0%2c2%2c0.897%2c2%2c2v2C8%2c7.103%2c7.104%2c8%2c6%2c8z M4%2c4v2h1.997L6%2c4H4z'/%3e %3c/g%3e %3cg%3e %3cpath d='M6%2c15H4c-1.104%2c0-2-0.897-2-2v-2c0-1.103%2c0.896-2%2c2-2h2c1.104%2c0%2c2%2c0.897%2c2%2c2v2 C8%2c14.103%2c7.104%2c15%2c6%2c15z M4%2c11v2h1.997L6%2c11H4z'/%3e %3c/g%3e %3cg%3e %3cpath d='M6%2c22H4c-1.104%2c0-2-0.897-2-2v-2c0-1.103%2c0.896-2%2c2-2h2c1.104%2c0%2c2%2c0.897%2c2%2c2v2 C8%2c21.103%2c7.104%2c22%2c6%2c22z M4%2c18v2h1.997L6%2c18H4z'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Notice',
      value: 'notice-callout',
      description: 'Point out something important.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M21%2c22H3c-0.347%2c0-0.668-0.18-0.851-0.474c-0.183-0.296-0.198-0.663-0.044-0.974l9-18 c0.339-0.677%2c1.45-0.677%2c1.789%2c0l9%2c18c0.154%2c0.311%2c0.139%2c0.677-0.044%2c0.974C21.668%2c21.821%2c21.347%2c22%2c21%2c22z M4.618%2c20h14.764 L12%2c5.237L4.618%2c20z'/%3e %3c/g%3e %3cg%3e %3crect x='11' y='10' width='2' height='6'/%3e %3c/g%3e %3cg%3e %3ccircle cx='12' cy='18' r='1.25'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Info',
      value: 'info-callout',
      description: 'Provide an explanation.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M12%2c22C6.486%2c22%2c2%2c17.515%2c2%2c12C2%2c6.487%2c6.486%2c2%2c12%2c2s10%2c4.486%2c10%2c10C22%2c17.515%2c17.514%2c22%2c12%2c22z M12%2c4c-4.411%2c0-8%2c3.589-8%2c8s3.589%2c8%2c8%2c8s8-3.589%2c8-8S16.411%2c4%2c12%2c4z'/%3e %3c/g%3e %3cpath d='M13%2c15v-4c0-0.552-0.447-1-1-1h-2v2h1v3H9v2h6v-2H13z'/%3e %3cg%3e %3ccircle cx='12' cy='8' r='1.25'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Tip',
      value: 'tip-callout',
      description: 'Give a helpful tip.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect x='0.001' y='0.002' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpolygon points='9%2c23 11%2c23 11%2c24 13%2c24 13%2c23 15%2c23 15%2c21 9%2c21 '/%3e %3cpath d='M12%2c5c-3.859%2c0-7%2c3.14-7%2c7c0%2c2.714%2c1.581%2c5.17%2c4%2c6.319V19c0%2c0.552%2c0.447%2c1%2c1%2c1h4 c0.553%2c0%2c1-0.448%2c1-1v-0.681c2.419-1.148%2c4-3.604%2c4-6.319C19%2c8.14%2c15.859%2c5%2c12%2c5z M13.666%2c16.709 C13.268%2c16.85%2c13%2c17.228%2c13%2c17.651V18h-2v-0.349c0-0.424-0.268-0.801-0.666-0.942C8.34%2c16.003%2c7%2c14.11%2c7%2c12c0-2.757%2c2.243-5%2c5-5 s5%2c2.243%2c5%2c5C17%2c14.11%2c15.66%2c16.003%2c13.666%2c16.709z'/%3e %3crect x='11' width='2' height='4'/%3e %3crect y='11' width='4' height='2'/%3e %3crect x='20' y='11' width='4' height='2'/%3e %3crect x='18.5' y='2.379' transform='matrix(0.7072 0.707 -0.707 0.7072 8.8905 -12.4687)' width='1.999' height='4.243'/%3e %3crect x='2.379' y='3.5' transform='matrix(0.7071 0.7071 -0.7071 0.7071 4.5 -1.864)' width='4.243' height='1.999'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Mistake',
      value: 'mistake-callout',
      description: 'Point out a common error.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cpath d='M19.077%2c4.929C17.188%2c3.04%2c14.676%2c2%2c12.005%2c2C9.334%2c2%2c6.823%2c3.04%2c4.934%2c4.929 c-1.889%2c1.889-2.93%2c4.4-2.93%2c7.07c0%2c2.671%2c1.041%2c5.183%2c2.93%2c7.073C6.823%2c20.96%2c9.334%2c22%2c12.005%2c22s5.182-1.04%2c7.071-2.927 C22.975%2c15.172%2c22.975%2c8.828%2c19.077%2c4.929z M18.319%2c16.902L13.419%2c12L18.32%2c7.1C20.553%2c9.974%2c20.553%2c14.026%2c18.319%2c16.902z M16.907%2c5.686l-4.902%2c4.901L7.104%2c5.686C8.501%2c4.597%2c10.206%2c4%2c12.005%2c4C13.805%2c4%2c15.509%2c4.597%2c16.907%2c5.686z M4.004%2c12 c0-1.799%2c0.597-3.504%2c1.686-4.9L10.591%2c12L5.69%2c16.902C4.601%2c15.504%2c4.004%2c13.8%2c4.004%2c12z M7.104%2c18.315l4.9-4.901l4.9%2c4.901 C15.508%2c19.404%2c13.804%2c20%2c12.005%2c20S8.501%2c19.404%2c7.104%2c18.315z'/%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Conversation',
      value: 'conversation',
      description: 'Show a dialog.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M15.001%2c16c-0.152%2c0-0.306-0.035-0.447-0.105c-0.339-0.169-0.553-0.516-0.553-0.895v-2h-1v-2h2 c0.552%2c0%2c1%2c0.448%2c1%2c1v1l2.4-1.8c0.173-0.13%2c0.384-0.2%2c0.6-0.2h3V4h-10v3h-2V4c0-1.104%2c0.897-2%2c2-2h10c1.103%2c0%2c2%2c0.896%2c2%2c2v7 c0%2c1.103-0.897%2c2-2%2c2h-2.667l-3.733%2c2.8C15.424%2c15.932%2c15.213%2c16%2c15.001%2c16z'/%3e %3c/g%3e %3cg%3e %3cpath d='M8.001%2c16c-2.206%2c0-4-1.794-4-4c0-2.205%2c1.794-4%2c4-4s4%2c1.795%2c4%2c4 C12.001%2c14.206%2c10.207%2c16%2c8.001%2c16z M8.001%2c10c-1.103%2c0-2%2c0.896-2%2c2c0%2c1.103%2c0.897%2c2%2c2%2c2s2-0.897%2c2-2 C10.001%2c10.896%2c9.104%2c10%2c8.001%2c10z'/%3e %3c/g%3e %3cg%3e %3cpath d='M16.001%2c24h-16v-1c0-3.533%2c3.29-6%2c8-6s8%2c2.467%2c8%2c6V24z M2.16%2c22h11.683 c-0.598-1.808-2.833-3-5.841-3S2.758%2c20.192%2c2.16%2c22z'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Learn More',
      value: 'learn-more-callout',
      description: 'Connect to further lessons.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M3.514%2c16.227l1.414-1.414l-0.707-0.707c-0.39-0.39-0.39-1.024%2c0-1.415l8.486-8.485 c0.188-0.189%2c0.439-0.293%2c0.707-0.293s0.518%2c0.104%2c0.708%2c0.293l0.707%2c0.707L16.242%2c3.5l-0.706-0.707 c-1.134-1.134-3.111-1.134-4.243%2c0l-8.485%2c8.484c-1.17%2c1.17-1.17%2c3.073%2c0%2c4.243L3.514%2c16.227z'/%3e %3cpath d='M21.192%2c8.451l-0.708-0.707L19.07%2c9.158l0.708%2c0.707c0.189%2c0.189%2c0.292%2c0.439%2c0.292%2c0.707 c0%2c0.266-0.104%2c0.517-0.292%2c0.706l-8.485%2c8.486c-0.378%2c0.377-1.037%2c0.377-1.415%2c0l-0.707-0.707L7.757%2c20.47l0.707%2c0.707 c0.567%2c0.567%2c1.32%2c0.879%2c2.122%2c0.879c0.801%2c0%2c1.554-0.312%2c2.121-0.879l8.485-8.485c0.566-0.566%2c0.878-1.319%2c0.878-2.12 C22.07%2c9.771%2c21.758%2c9.017%2c21.192%2c8.451z'/%3e %3c/g%3e %3cg%3e %3crect x='15.949' y='2.036' transform='matrix(0.7071 0.7071 -0.7071 0.7071 9.9393 -9.9242)' width='2' height='10'/%3e %3c/g%3e %3cg%3e %3crect x='6.05' y='11.934' transform='matrix(0.7072 0.707 -0.707 0.7072 14.0365 -0.0261)' width='1.999' height='10.001'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Conjugation Table',
      value: 'conjugation-table',
      description: 'Add a verb conjugation table.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M21%2c4.001h-1v13h-6c-0.266%2c0-0.52%2c0.105-0.707%2c0.293L13%2c17.587V7.415l1-1V4.001 c-0.266%2c0-0.52%2c0.105-0.707%2c0.293L12%2c5.587l-1.293-1.293C10.52%2c4.106%2c10.266%2c4.001%2c10%2c4.001H3c-0.553%2c0-1%2c0.448-1%2c1v13 c0%2c0.552%2c0.447%2c1%2c1%2c1h6.586l1.707%2c1.707c0.195%2c0.195%2c0.451%2c0.293%2c0.707%2c0.293s0.512-0.098%2c0.707-0.293l1.707-1.707H21 c0.553%2c0%2c1-0.448%2c1-1v-13C22%2c4.449%2c21.553%2c4.001%2c21%2c4.001z M11%2c17.587l-0.293-0.293c-0.188-0.188-0.441-0.293-0.707-0.293H4v-11 h5.586L11%2c7.415V17.587z'/%3e %3cg%3e %3cpolygon points='19%2c12.001 17%2c10.001 15%2c12.001 15%2c2.001 19%2c2.001 '/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Reveal',
      value: 'reveal',
      description: 'Give a chance to guess and check.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0.001' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M22.707%2c11.294l-6-6C16.512%2c5.099%2c16.256%2c5.001%2c16%2c5.001H2c-0.553%2c0-1%2c0.447-1%2c1v12 c0%2c0.553%2c0.447%2c1%2c1%2c1h14c0.256%2c0%2c0.512-0.098%2c0.707-0.293l6-6C23.098%2c12.317%2c23.098%2c11.685%2c22.707%2c11.294z M15.586%2c17.001H3v-10 h12.586l5%2c5L15.586%2c17.001z'/%3e %3cpolygon points='11.707%2c10.708 10.293%2c9.294 9%2c10.587 7.707%2c9.294 6.293%2c10.708 7.586%2c12.001 6.293%2c13.294 7.707%2c14.708 9%2c13.415 10.293%2c14.708 11.707%2c13.294 10.414%2c12.001 '/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
];

const CALLOUT_BLOCKS: readonly Block[] = [
   {
      text: 'Primary Callout',
      value: 'primary-callout',
      description: 'Make writing stand out.',
      img: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24; fill: %23007cbb' xml:space='preserve'%3E%3Cg id='Frame_-_24px'%3E%3Crect style='fill:none;' width='24' height='24'/%3E%3C/g%3E%3Cg id='Line_Icons'%3E%3Cg%3E%3Cpath d='M18.615,4.211c-0.243-0.189-0.561-0.257-0.857-0.182L5.877,7H3C1.896,7,1,7.896,1,9v4 c0,1.102,0.896,2,2,2h2v2c0,0.217,0.07,0.426,0.2,0.6l3,4L9.8,20.4L7,16.666v-1.385l10.758,2.689C17.838,17.99,17.919,18,18,18 c0.221,0,0.438-0.074,0.615-0.213C18.857,17.598,19,17.308,19,17V5C19,4.691,18.857,4.4,18.615,4.211z M3,9h2v4H3V9z M17,15.719 l-10-2.5V8.781l10-2.5V15.719z'/%3E%3Cg%3E%3Cpath d='M20,8.184v5.631c1.162-0.414,2-1.514,2-2.814C22,9.697,21.162,8.598,20,8.184z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
   },
   {
      text: 'Secondary Callout',
      value: 'secondary-callout',
      description: 'Make writing stand out.',
      img: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24; fill: %23807f86' xml:space='preserve'%3E%3Cg id='Frame_-_24px'%3E%3Crect style='fill:none;' width='24' height='24'/%3E%3C/g%3E%3Cg id='Line_Icons'%3E%3Cg%3E%3Cpath d='M18.615,4.211c-0.243-0.189-0.561-0.257-0.857-0.182L5.877,7H3C1.896,7,1,7.896,1,9v4 c0,1.102,0.896,2,2,2h2v2c0,0.217,0.07,0.426,0.2,0.6l3,4L9.8,20.4L7,16.666v-1.385l10.758,2.689C17.838,17.99,17.919,18,18,18 c0.221,0,0.438-0.074,0.615-0.213C18.857,17.598,19,17.308,19,17V5C19,4.691,18.857,4.4,18.615,4.211z M3,9h2v4H3V9z M17,15.719 l-10-2.5V8.781l10-2.5V15.719z'/%3E%3Cg%3E%3Cpath d='M20,8.184v5.631c1.162-0.414,2-1.514,2-2.814C22,9.697,21.162,8.598,20,8.184z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
   },
   {
      text: 'Success Callout',
      value: 'success-callout',
      description: 'Make writing stand out.',
      img: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24; fill: %234a7d35' xml:space='preserve'%3E%3Cg id='Frame_-_24px'%3E%3Crect style='fill:none;' width='24' height='24'/%3E%3C/g%3E%3Cg id='Line_Icons'%3E%3Cg%3E%3Cpath d='M18.615,4.211c-0.243-0.189-0.561-0.257-0.857-0.182L5.877,7H3C1.896,7,1,7.896,1,9v4 c0,1.102,0.896,2,2,2h2v2c0,0.217,0.07,0.426,0.2,0.6l3,4L9.8,20.4L7,16.666v-1.385l10.758,2.689C17.838,17.99,17.919,18,18,18 c0.221,0,0.438-0.074,0.615-0.213C18.857,17.598,19,17.308,19,17V5C19,4.691,18.857,4.4,18.615,4.211z M3,9h2v4H3V9z M17,15.719 l-10-2.5V8.781l10-2.5V15.719z'/%3E%3Cg%3E%3Cpath d='M20,8.184v5.631c1.162-0.414,2-1.514,2-2.814C22,9.697,21.162,8.598,20,8.184z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
   },
   {
      text: 'Warning Callout',
      value: 'warning-callout',
      description: 'Make writing stand out.',
      img: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24; fill: %23ee592a' xml:space='preserve'%3E%3Cg id='Frame_-_24px'%3E%3Crect style='fill:none;' width='24' height='24'/%3E%3C/g%3E%3Cg id='Line_Icons'%3E%3Cg%3E%3Cpath d='M18.615,4.211c-0.243-0.189-0.561-0.257-0.857-0.182L5.877,7H3C1.896,7,1,7.896,1,9v4 c0,1.102,0.896,2,2,2h2v2c0,0.217,0.07,0.426,0.2,0.6l3,4L9.8,20.4L7,16.666v-1.385l10.758,2.689C17.838,17.99,17.919,18,18,18 c0.221,0,0.438-0.074,0.615-0.213C18.857,17.598,19,17.308,19,17V5C19,4.691,18.857,4.4,18.615,4.211z M3,9h2v4H3V9z M17,15.719 l-10-2.5V8.781l10-2.5V15.719z'/%3E%3Cg%3E%3Cpath d='M20,8.184v5.631c1.162-0.414,2-1.514,2-2.814C22,9.697,21.162,8.598,20,8.184z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
   },
   {
      text: 'Danger Callout',
      value: 'danger-callout',
      description: 'Make writing stand out.',
      img: "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24; fill: %23cd1f1c' xml:space='preserve'%3E%3Cg id='Frame_-_24px'%3E%3Crect style='fill:none;' width='24' height='24'/%3E%3C/g%3E%3Cg id='Line_Icons'%3E%3Cg%3E%3Cpath d='M18.615,4.211c-0.243-0.189-0.561-0.257-0.857-0.182L5.877,7H3C1.896,7,1,7.896,1,9v4 c0,1.102,0.896,2,2,2h2v2c0,0.217,0.07,0.426,0.2,0.6l3,4L9.8,20.4L7,16.666v-1.385l10.758,2.689C17.838,17.99,17.919,18,18,18 c0.221,0,0.438-0.074,0.615-0.213C18.857,17.598,19,17.308,19,17V5C19,4.691,18.857,4.4,18.615,4.211z M3,9h2v4H3V9z M17,15.719 l-10-2.5V8.781l10-2.5V15.719z'/%3E%3Cg%3E%3Cpath d='M20,8.184v5.631c1.162-0.414,2-1.514,2-2.814C22,9.697,21.162,8.598,20,8.184z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A",
   },
];

const blocks: readonly Block[] = [
   {
      text: 'Text',
      value: 'p',
      description: 'Just start writing with plain text.',
      img: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cg id='Page-1' fill='none' fill-rule='evenodd' stroke='none' stroke-width='1'%3e%3cg id='Aa.1'%3e%3cg id='a' fill='%23413F48' fill-rule='nonzero' transform='translate(15.620000%2c 9.800000)'%3e%3cpath id='Shape' d='M0%2c5.66 C0%2c7.36 1.1%2c8.4 2.7%2c8.4 C3.94%2c8.4 4.68%2c7.8 5.14%2c7.38 L5.38%2c7.92 C5.48%2c8.14 5.58%2c8.2 5.72%2c8.2 L6.44%2c8.2 C6.64%2c8.2 6.82%2c8.02 6.82%2c7.82 L6.82%2c3.48 C6.82%2c1.3 6.24%2c0 3.74%2c0 C2.46%2c0 1.18%2c0.32 1.02%2c0.38 C0.84%2c0.44 0.68%2c0.58 0.74%2c0.88 L0.92%2c1.92 C0.96%2c2.18 1.1%2c2.36 1.38%2c2.28 C1.84%2c2.14 2.88%2c1.98 3.62%2c1.98 C4.36%2c1.98 4.68%2c2.34 4.66%2c3.36 C4.66%2c3.36 4%2c3.14 3.2%2c3.14 C1.16%2c3.14 0%2c4.24 0%2c5.66 Z M2.2%2c5.66 C2.2%2c5.02 2.72%2c4.58 3.5%2c4.58 C4.08%2c4.58 4.62%2c4.82 4.62%2c4.82 L4.62%2c5.92 C4.5%2c6.16 3.88%2c6.7 3.24%2c6.7 C2.62%2c6.7 2.2%2c6.26 2.2%2c5.66 Z'/%3e%3c/g%3e%3cg id='A' fill='%23413F48' fill-rule='nonzero' transform='translate(1.699927%2c 3.800000)'%3e%3cpath id='Shape' d='M0.380073258%2c14.2 L2.12007326%2c14.2 C2.44007326%2c14.2 2.62007326%2c14 2.70007326%2c13.8 L3.68007326%2c11.64 L9.66007326%2c11.64 L10.6400733%2c13.8 C10.7800733%2c14.08 10.9200733%2c14.2 11.2200733%2c14.2 L12.9600733%2c14.2 C13.2600733%2c14.2 13.4200733%2c13.94 13.3000733%2c13.68 L7.14007326%2c0.22 C7.08007326%2c0.1 7.00007326%2c5.99749647e-25 6.80007326%2c5.99749647e-25 L6.60007326%2c5.99749647e-25 C6.42007326%2c5.99749647e-25 6.32007326%2c0.1 6.26007326%2c0.22 L0.0400732584%2c13.68 C-0.0799267416%2c13.94 0.0800732584%2c14.2 0.380073258%2c14.2 Z M4.66007326%2c9.42 L6.62007326%2c5.02 L6.68007326%2c5.02 L8.68007326%2c9.42 L4.66007326%2c9.42 Z'/%3e%3c/g%3e%3cpath id='Rectangle' d='M0 0H24V24H0z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e",
   }, //
   {
      text: 'Heading 1',
      value: 'h1',
      description: 'Big section heading.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e %3csvg width='18px' height='19px' viewBox='0 0 18 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3ctitle%3eGroup%3c/title%3e %3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e %3cg id='Group' transform='translate(-1.000000%2c -5.000000)'%3e %3ctext id='H' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='15' font-weight='bold' letter-spacing='-0.240384225' fill='%23413F48'%3e %3ctspan x='0.352692113' y='16'%3eH%3c/tspan%3e %3c/text%3e %3ctext id='1' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='10' font-weight='bold' letter-spacing='-0.16025615' fill='%23413F48'%3e %3ctspan x='12.2951281' y='17'%3e1%3c/tspan%3e %3c/text%3e %3cline x1='2' y1='19' x2='18' y2='19' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='21' x2='15' y2='21' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='23' x2='12' y2='23' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Heading 2',
      value: 'h2',
      description: 'Medium section heading.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e %3csvg width='18px' height='19px' viewBox='0 0 18 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3ctitle%3eGroup%3c/title%3e %3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e %3cg id='Group' transform='translate(-1.000000%2c -5.000000)'%3e %3ctext id='H' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='15' font-weight='bold' letter-spacing='-0.240384225' fill='%23413F48'%3e %3ctspan x='0.352692113' y='16'%3eH%3c/tspan%3e %3c/text%3e %3ctext id='2' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='10' font-weight='bold' letter-spacing='-0.16025615' fill='%23413F48'%3e %3ctspan x='11.4901281' y='17'%3e2%3c/tspan%3e %3c/text%3e %3cline x1='2' y1='19' x2='18' y2='19' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='21' x2='15' y2='21' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='23' x2='12' y2='23' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Heading 3',
      value: 'h3',
      description: 'Small section heading.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e %3csvg width='18px' height='19px' viewBox='0 0 18 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e %3ctitle%3eGroup%3c/title%3e %3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e %3cg id='Group' transform='translate(-1.000000%2c -5.000000)'%3e %3ctext id='H' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='15' font-weight='bold' letter-spacing='-0.240384225' fill='%23413F48'%3e %3ctspan x='0.352692113' y='16'%3eH%3c/tspan%3e %3c/text%3e %3ctext id='3' font-family='BrandonGrotesque-Bold%2c Brandon Grotesque' font-size='10' font-weight='bold' letter-spacing='-0.16025615' fill='%23413F48'%3e %3ctspan x='11.4701281' y='17'%3e3%3c/tspan%3e %3c/text%3e %3cline x1='2' y1='19' x2='18' y2='19' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='21' x2='15' y2='21' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3cline x1='2' y1='23' x2='12' y2='23' id='Line' stroke='%23E7E7E7' stroke-width='0.5' stroke-linecap='square'%3e%3c/line%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Table',
      value: 'table',
      description: 'Add simple tabular content to your page.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cpath d='M22%2c7V5h-3V2h-2v3h-4V2h-2v3H7V2H5v3H2v2h3v4H2v2h3v4H2v2h3v3h2v-3h4v3h2v-3h4v3h2v-3h3v-2h-3v-4h3 v-2h-3V7H22z M7%2c7h4v4H7V7z M7%2c17v-4h4v4H7z M17%2c17h-4v-4h4V17z M17%2c11h-4V7h4V11z'/%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Bulleted List',
      value: 'ul',
      description: 'Create a simple bulleted list.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M5%2c2C3.346%2c2%2c2%2c3.345%2c2%2c5s1.346%2c3%2c3%2c3s3-1.346%2c3-3S6.654%2c2%2c5%2c2z M5%2c6C4.449%2c6%2c4%2c5.551%2c4%2c5 s0.449-1%2c1-1c0.552%2c0%2c1%2c0.449%2c1%2c1S5.552%2c6%2c5%2c6z'/%3e %3cpath d='M5%2c9c-1.654%2c0-3%2c1.346-3%2c3s1.346%2c3%2c3%2c3s3-1.346%2c3-3S6.654%2c9%2c5%2c9z M5%2c13c-0.551%2c0-1-0.449-1-1 s0.449-1%2c1-1c0.552%2c0%2c1%2c0.449%2c1%2c1S5.552%2c13%2c5%2c13z'/%3e %3cpath d='M5%2c16c-1.654%2c0-3%2c1.346-3%2c3s1.346%2c3%2c3%2c3s3-1.346%2c3-3S6.654%2c16%2c5%2c16z M5%2c20c-0.551%2c0-1-0.449-1-1 s0.449-1%2c1-1c0.552%2c0%2c1%2c0.449%2c1%2c1S5.552%2c20%2c5%2c20z'/%3e %3crect x='10' y='4' width='12' height='2'/%3e %3crect x='10' y='11' width='12' height='2'/%3e %3crect x='10' y='18' width='12' height='2'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Numbered List',
      value: 'ol',
      description: 'Create a list with numbering.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3crect x='8' y='4' width='14' height='2'/%3e %3crect x='8' y='11' width='14' height='2'/%3e %3crect x='8' y='18' width='14' height='2'/%3e %3cpath d='M4.32%2c3.869V7.47h1.094V2.548c0-0.221-0.134-0.421-0.338-0.506C4.871%2c1.956%2c4.637%2c2.004%2c4.48%2c2.161 L3%2c3.642l0.773%2c0.773L4.32%2c3.869z'/%3e %3cpath d='M4.5%2c9C3.673%2c9%2c3%2c9.673%2c3%2c10.5V11h1v-0.5C4%2c10.224%2c4.225%2c10%2c4.5%2c10c0.276%2c0%2c0.501%2c0.224%2c0.501%2c0.5 c0%2c0.489-0.963%2c1.754-1.854%2c2.646c-0.143%2c0.143-0.186%2c0.357-0.108%2c0.545C3.116%2c13.879%2c3.298%2c14%2c3.5%2c14H6v-1H4.638 C5.261%2c12.266%2c6%2c11.238%2c6%2c10.5C6%2c9.673%2c5.328%2c9%2c4.5%2c9z'/%3e %3cpath d='M4.5%2c16.442L3%2c16.443l0.001%2c1L4.5%2c17.442c0.275%2c0%2c0.5%2c0.224%2c0.5%2c0.5s-0.225%2c0.5-0.5%2c0.5v0H4v1h0.5 v0c0.275%2c0%2c0.5%2c0.224%2c0.5%2c0.5c0%2c0.277-0.225%2c0.5-0.5%2c0.5l-1.5%2c0l0.001%2c1l1.499%2c0c0.827%2c0%2c1.5-0.673%2c1.5-1.5 c0-0.384-0.145-0.735-0.383-1C5.855%2c18.677%2c6%2c18.327%2c6%2c17.942C6%2c17.115%2c5.327%2c16.442%2c4.5%2c16.442z'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Quote',
      value: 'blockquote',
      description: 'Capture a quote.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M7%2c12c-0.732%2c0-1.409%2c0.212-2%2c0.557V10c0-2.206%2c1.794-4%2c4-4h1V4H9c-3.309%2c0-6%2c2.691-6%2c6v6 c0%2c2.206%2c1.794%2c4%2c4%2c4s4-1.794%2c4-4S9.206%2c12%2c7%2c12z M7%2c18c-1.103%2c0-2-0.897-2-2c0-1.103%2c0.897-2%2c2-2s2%2c0.897%2c2%2c2 C9%2c17.103%2c8.103%2c18%2c7%2c18z'/%3e %3cpath d='M17%2c12c-0.732%2c0-1.409%2c0.212-2%2c0.557V10c0-2.206%2c1.794-4%2c4-4h1V4h-1c-3.309%2c0-6%2c2.691-6%2c6v6 c0%2c2.206%2c1.794%2c4%2c4%2c4s4-1.794%2c4-4S19.206%2c12%2c17%2c12z M17%2c18c-1.103%2c0-2-0.897-2-2c0-1.103%2c0.897-2%2c2-2s2%2c0.897%2c2%2c2 C19%2c17.103%2c18.103%2c18%2c17%2c18z'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Divider',
      value: 'hr',
      description: 'Visually divide blocks.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0.001' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3crect x='3' y='11.001' width='18' height='2'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Link to Page',
      value: 'link',
      description: 'Link to an existing page.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0.001' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M17%2c5.587l-1.414%2c1.414l2%2c2H12c-2.205%2c0-4%2c1.794-4%2c4v1h2v-1c0-1.102%2c0.896-2%2c2-2h5.586l-2%2c2 L17%2c14.416l4.414-4.414L17%2c5.587z'/%3e %3cpolygon points='13%2c18.001 4%2c18.001 4%2c6.001 15%2c6.001 15%2c4.001 2%2c4.001 2%2c20.001 15%2c20.001 15%2c15.001 13%2c15.001 '/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   ...LESSON_BLOCKS,
   ...CALLOUT_BLOCKS,
   {
      text: 'Image',
      value: 'img',
      description: 'Upload or embed with a link.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M17.414%2c4H4C2.897%2c4%2c2%2c4.898%2c2%2c6v12c0%2c1.103%2c0.897%2c2%2c2%2c2h16c1.103%2c0%2c2-0.897%2c2-2V8.586L17.414%2c4z M4%2c18V6h12v4h4l0.001%2c8H4z'/%3e %3cpolygon points='10.006%2c14.475 8.479%2c12.023 6%2c16 18%2c16 13.528%2c8.827 '/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   // {
   //   text: "Web Bookmark",
   //   value: "web_bookmark",
   //   description: "Save a link as a visual bookmark."
   // },
   {
      text: 'Video',
      value: 'video',
      description: 'Embed from YouTube, Vimeo, …',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpolygon points='10%2c15 16%2c12 10%2c9 '/%3e %3cpath d='M21%2c2H3C1.897%2c2%2c1%2c2.896%2c1%2c4v16c0%2c1.103%2c0.897%2c2%2c2%2c2h18c1.103%2c0%2c2-0.897%2c2-2V4 C23%2c2.896%2c22.103%2c2%2c21%2c2z M3%2c20V4h18l0.001%2c16H3z'/%3e %3crect x='5' y='5' width='2' height='2'/%3e %3crect x='9' y='5' width='2' height='2'/%3e %3crect x='13' y='5' width='2' height='2'/%3e %3crect x='17' y='5' width='2' height='2'/%3e %3crect x='5' y='17' width='2' height='2'/%3e %3crect x='9' y='17' width='2' height='2'/%3e %3crect x='13' y='17' width='2' height='2'/%3e %3crect x='17' y='17' width='2' height='2'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Block Audio',
      value: 'block-audio',
      description: 'Embed from Sound Cloud, Spotify, ...',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M11.764%2c3.152c-0.739-0.306-1.615-0.131-2.178%2c0.434L6.479%2c8H4c-1.103%2c0-2%2c0.898-2%2c2v4 c0%2c1.103%2c0.897%2c2%2c2%2c2h2.479l2.995%2c4.28C9.89%2c20.776%2c10.433%2c21%2c11%2c21c0.263%2c0%2c0.521-0.051%2c0.764-0.151 C12.515%2c20.54%2c13%2c19.813%2c13%2c19V5C13%2c4.187%2c12.515%2c3.462%2c11.764%2c3.152z M7.521%2c14H4v-4h3.521L11%2c5.027l0.001%2c13.948L7.521%2c14z'/%3e %3cpath d='M15%2c4v2c2.757%2c0%2c5%2c2.243%2c5%2c5s-2.243%2c5-5%2c5v2c3.86%2c0%2c7-3.14%2c7-7C22%2c7.141%2c18.86%2c4%2c15%2c4z'/%3e %3cpath d='M18%2c11c0-1.654-1.346-3-3-3v2c0.551%2c0%2c1%2c0.449%2c1%2c1c0%2c0.552-0.449%2c1-1%2c1v2 C16.654%2c14%2c18%2c12.654%2c18%2c11z'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'File',
      value: 'file',
      description: 'Upload or embed with a link.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect y='0' style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cg%3e %3cpath d='M11%2c23c-2.897%2c0-5-2.313-5-5.5V9c0-3.991%2c2.794-7%2c6.5-7S19%2c5.009%2c19%2c9v7h-2V9 c0-2.897-1.893-5-4.5-5S8%2c6.103%2c8%2c9v8.5c0%2c1.743%2c0.928%2c3.5%2c3%2c3.5s3-1.757%2c3-3.5V10c0-0.602-0.146-2-1.5-2S11%2c9.398%2c11%2c10v7H9v-7 c0-2.393%2c1.407-4%2c3.5-4S16%2c7.607%2c16%2c10v7.5C16%2c20.687%2c13.897%2c23%2c11%2c23z'/%3e %3c/g%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
   {
      text: 'Emoji',
      value: 'emoji',
      description: 'Search for an emoji to place in text.',
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M17%2c13H7c-0.552%2c0-1%2c0.448-1%2c1c0%2c2.617%2c2.86%2c5%2c6%2c5s6-2.383%2c6-5C18%2c13.448%2c17.552%2c13%2c17%2c13z M12%2c17 c-1.636%2c0-3.094-0.951-3.701-2h7.411C15.119%2c16.052%2c13.684%2c17%2c12%2c17z'/%3e %3cpath d='M12%2c2C6.486%2c2%2c2%2c6.486%2c2%2c12c0%2c5.515%2c4.486%2c10%2c10%2c10s10-4.485%2c10-10C22%2c6.486%2c17.514%2c2%2c12%2c2z M12%2c20 c-4.411%2c0-8-3.589-8-8s3.589-8%2c8-8s8%2c3.589%2c8%2c8S16.411%2c20%2c12%2c20z'/%3e %3crect x='8' y='7' width='2' height='4'/%3e %3crect x='14' y='7' width='2' height='4'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
      inline: true,
   },
   {
      text: 'Inline Audio',
      value: 'inline-audio',
      description: 'Mini audio player next to text.',
      inline: true,
      img: "data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3c!-- Generator: Adobe Illustrator 16.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e %3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e %3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24%3b' xml:space='preserve'%3e %3cg id='Frame_-_24px'%3e %3crect style='fill:none%3b' width='24' height='24'/%3e %3c/g%3e %3cg id='Line_Icons'%3e %3cg%3e %3cpath d='M11.764%2c3.152c-0.739-0.306-1.615-0.131-2.178%2c0.434L6.479%2c8H4c-1.103%2c0-2%2c0.898-2%2c2v4 c0%2c1.103%2c0.897%2c2%2c2%2c2h2.479l2.995%2c4.28C9.89%2c20.776%2c10.433%2c21%2c11%2c21c0.263%2c0%2c0.521-0.051%2c0.764-0.151 C12.515%2c20.54%2c13%2c19.813%2c13%2c19V5C13%2c4.187%2c12.515%2c3.462%2c11.764%2c3.152z M7.521%2c14H4v-4h3.521L11%2c5.027l0.001%2c13.948L7.521%2c14z'/%3e %3cpath d='M15%2c4v2c2.757%2c0%2c5%2c2.243%2c5%2c5s-2.243%2c5-5%2c5v2c3.86%2c0%2c7-3.14%2c7-7C22%2c7.141%2c18.86%2c4%2c15%2c4z'/%3e %3cpath d='M18%2c11c0-1.654-1.346-3-3-3v2c0.551%2c0%2c1%2c0.449%2c1%2c1c0%2c0.552-0.449%2c1-1%2c1v2 C16.654%2c14%2c18%2c12.654%2c18%2c11z'/%3e %3c/g%3e %3c/g%3e %3c/svg%3e",
   },
];

export { blocks };
