import * as React from 'react';

import IconChatBubbleSquare3 from '@icons/nova-line/10-Messages&Chat/chat-bubble-square-3.svg';

import EmptyState from '@components/Core/EmptyState';

const DiscussionBoardPrompt: React.FC = () => (
   <div className='discussion-board-placeholder'>
      <EmptyState
         icon={<IconChatBubbleSquare3 aria-hidden />}
         description='Discussion Board responses will appear here'
      />
   </div>
);

export default DiscussionBoardPrompt;
