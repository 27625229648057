import EncodingUtils from '@helpers/EncodingUtils';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { ID, IdName } from '@models/Core';
import {
   AdminExamOrder,
   AdminNationalExamLevel,
   AdminNationalExamLevelSeatAllocation,
   ExamOrderResponseAlreadyClonedCourses,
} from '@models/NationalExam';
import PagedResponse from '@models/PagedResponse';
import PagedSearchFilters from '@models/PagedSearchFilters';
import { AxiosResponse } from 'axios';

import { SortByEntry } from '@components/Common/Table';
import AxiosService from './AxiosService';
import HttpService, { HttpServiceRequestOptions } from './HttpService';

type GetExamOrderResponse = {
   coursesAlreadyClonedForUser: ExamOrderResponseAlreadyClonedCourses[];
   coursesAvailableForExam: IdName[];
   examLevels: AdminNationalExamLevel[];
   levels: AdminNationalExamLevelSeatAllocation[];
   order: AdminExamOrder;
};

type CloneCourseForUserResponse = {
   course: ExamOrderResponseAlreadyClonedCourses;
};

type ExamOrderResponse = {
   order: AdminExamOrder;
};

const BASE_API = '/api/admin/national_exams';

const getExamOrders = (
   { predicate, ...rest }: PagedSearchFilters,
   sortOrder?: readonly SortByEntry[],
   options?: Partial<HttpServiceRequestOptions>,
): Promise<PagedResponse<AdminExamOrder>> => {
   const encodedQuery = new URLSearchParams(snakeCaseKeys(rest));
   if (sortOrder) {
      const str = JSON.stringify(sortOrder);
      encodedQuery.set('order_by', EncodingUtils.stringToB64(str));
   }
   return HttpService.getWithAuthToken<PagedResponse<AdminExamOrder>>(
      `${BASE_API}/exam_orders?${encodedQuery}`,
      options,
   ).then((response) => response.data);
};

const getExamOrder = (orderId: number | string): Promise<GetExamOrderResponse> =>
   HttpService.getWithAuthToken<GetExamOrderResponse>(`${BASE_API}/exam_orders/${orderId}`).then(
      (response) => response.data,
   );

const deleteExamOrder = (orderId: number): Promise<{ msg: string }> =>
   HttpService.deleteWithAuthToken<{ msg: string }>(`${BASE_API}/exam_orders/${orderId}`).then(
      (response) => response.data,
   );

const connectExamOrderToUser = (orderId: number, userId: ID): Promise<AdminExamOrder> => {
   const data = { userId, orderId };
   return HttpService.postWithAuthToken<ExamOrderResponse>(
      `${BASE_API}/connect_order_to_user`,
      snakeCaseKeys(data),
   ).then((response) => response.data.order);
};

const transferOrderToUser = (orderId: number, userId: ID): Promise<AdminExamOrder> => {
   const data = { userId, orderId };
   return HttpService.postWithAuthToken<ExamOrderResponse>(
      `${BASE_API}/transfer_order_to_user`,
      snakeCaseKeys(data),
   ).then((response) => response.data.order);
};

const updateExamSeatOrder = (
   orderId: number | string,
   data: Partial<AdminNationalExamLevelSeatAllocation>,
): Promise<AxiosResponse> =>
   HttpService.patchWithAuthToken(`${BASE_API}/exam_orders/${orderId}/seats`, snakeCaseKeys(data));

const createExamSeatOrder = (
   orderId: number | string,
   data: Partial<AdminNationalExamLevelSeatAllocation>,
): Promise<AxiosResponse> =>
   HttpService.postWithAuthToken(`${BASE_API}/exam_orders/${orderId}/seats`, snakeCaseKeys(data));

const removeExamSeatOrder = (orderId: number | string, levelId: number): Promise<AxiosResponse> =>
   HttpService.deleteWithAuthToken(`${BASE_API}/exam_orders/${orderId}/seats/${levelId}`);

const cloneCourseForUser = (
   instructorId: number | string,
   courseId: number | string,
): Promise<ExamOrderResponseAlreadyClonedCourses> =>
   AxiosService.post<CloneCourseForUserResponse>(
      `${BASE_API}/instructor/${instructorId}/course/${courseId}`,
   ).then((x) => x.data.course);

const resendOrderInvoice = (orderId: number | string): Promise<void> =>
   AxiosService.post<CloneCourseForUserResponse>(`${BASE_API}/${orderId}/resend_invoice`).then();

export default {
   cloneCourseForUser,
   connectExamOrderToUser,
   createExamSeatOrder,
   deleteExamOrder,
   getExamOrder,
   getExamOrders,
   removeExamSeatOrder,
   resendOrderInvoice,
   transferOrderToUser,
   updateExamSeatOrder,
};
