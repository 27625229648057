import * as React from 'react';

import FileSize from '@helpers/FileSize';
import IconDataUpload8 from '@icons/nova-solid/23-Data-Transfer/data-upload-8.svg';
import { ActivityBuilderMode, UploadedVideoContent, VideoSource } from '@models/Activity';
import Appearance from '@models/Appearance';

import { AppStateContext } from '../../../../../AppState';

interface UploadVideoContentProps {
   item: UploadedVideoContent<ActivityBuilderMode>;
   handleItemUpdate(
      update: Partial<UploadedVideoContent<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const UploadVideoContent: React.FC<UploadVideoContentProps> = ({ item, handleItemUpdate }) => {
   const MAX_FILE_SIZE = FileSize.getNumBytesFromMegabytes(200);
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);

   const [fileSizeError, setFileSizeError] = React.useState<boolean>(false);

   const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newFile = event.target.files?.[0];
      if (!newFile) {
         return;
      }

      const isMP4 = newFile.name.toLowerCase().endsWith('.mp4');
      if (isMP4) {
         if (newFile.size > MAX_FILE_SIZE) {
            setFileSizeError(true);
         } else {
            setFileSizeError(false);
            const reader = new FileReader();
            reader.onloadend = () => {
               const update: Partial<UploadedVideoContent<ActivityBuilderMode>> = {
                  ...item,
                  fileUrl: window.URL.createObjectURL(newFile),
                  file: newFile,
                  source: VideoSource.upload,
               };
               handleItemUpdate(update);
            };
            reader.readAsDataURL(newFile);
         }
      } else {
         dispatchToast({
            title: 'Unsupported File Type',
            message: `${newFile.name} is an unsupported file type. Please upload an MP4.`,
            appearance: Appearance.warning,
         });
      }
   };

   return !item.storedFilename && !item.file ? (
      <>
         <div className='upload-dropzone'>
            <div className='icon-wrapper'>
               <IconDataUpload8 />
            </div>
            <input type='file' accept='video/mp4' onChange={handleFileInputChange} />
            <span>Choose a mp4 video or drag it here.</span>
         </div>
         {fileSizeError && <p className='error padding-top-s'>Max file size of 200MB exceeded</p>}
      </>
   ) : null;
};

export default UploadVideoContent;
