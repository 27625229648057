import * as React from 'react';

import IVocabTerm from '@models/IVocabTerm';

const VocabTermPreview: React.FC<IVocabTerm<true>> = ({
   definition,
   gender,
   index,
   number: termNumber,
   partOfSpeech,
   term,
}) => (
   <div className='vocab-set-row row top-xs'>
      <div className='vocab-set-row-number-wrap'>
         <div className='vocab-set-row-number'>{(index ?? 0) + 1}</div>
      </div>
      <div className='vocab-set-row-content'>
         <div className='col-xs-12'>
            <div className='row'>
               <div className='col-xs-12 col-sm'>
                  <label className='field-title'>Term</label>
                  <p>{term}</p>
               </div>
               <div className='col-xs-12 col-sm'>
                  <label className='field-title'>Definition</label>
                  <p>{definition ? definition : 'None'}</p>
               </div>
               <div className='col-xs-12 col-sm'>
                  <label className='field-title'>Part of Speech</label>
                  <p>{partOfSpeech ? partOfSpeech : 'None'}</p>
               </div>
               <div className='col-xs-12 col-sm'>
                  <label className='field-title'>Gender</label>
                  <p>{gender ? gender : 'None'}</p>
               </div>
               <div className='col-xs-12 col-sm'>
                  <label className='field-title'>Number</label>
                  <p>{termNumber ? termNumber : 'None'}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
);

export default VocabTermPreview;
