import * as React from 'react';

import IconBuilderUploadGray from '@icons/activities/icon-builder-upload-gray.svg';
import { FileResponse } from '@models/Activity';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface FilePromptProps extends CommonPromptProps {
   response: FileResponse;
   setResponse(response: FileResponse, callback?: () => void): void;
}

const FilePrompt: React.FC<FilePromptProps> = ({
   isClosed,
   response: { file, originalFilename },
   setResponse,
   saveResponse,
}) => {
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newFile = event.target.files?.[0];
      if (newFile) {
         const reader = new FileReader();
         reader.onloadend = () => {
            setResponse(
               {
                  file: newFile,
                  storedFilename: '',
                  originalFilename: newFile.name,
               },
               saveResponse,
            );
         };
         reader.readAsDataURL(newFile);
      }
   };

   const filename = (file && file.name) || originalFilename;

   return (
      <div className='upload-dropzone'>
         <div className='icon-wrapper'>
            <IconBuilderUploadGray />
         </div>
         <input type='file' onChange={handleChange} disabled={isClosed} />
         {!isClosed && <span>{filename ? filename : 'Choose a file or drag it here'}</span>}
      </div>
   );
};

export default FilePrompt;
