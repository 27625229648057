import * as _ from 'lodash';
import * as React from 'react';

import indexDeep from '@helpers/IndexDeep';
import keyDeep from '@helpers/KeyDeep';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import omitDeep from '@helpers/OmitDeep';
import { getQueryParameterAsNumber } from '@helpers/QueryParameter';
import useCommands from '@hooks/use-commands';
import Content, { ContentType } from '@models/Content';
import { Maybe, MessageResponse } from '@models/Core';
import IVocabTerm, { PartOfSpeech, TermGender, TermNumber } from '@models/IVocabTerm';
import VocabSet from '@models/VocabSet';
import { uploadImage } from '@services/AssetService';
import ContentService from '@services/ContentService';
import HttpService from '@services/HttpService';
import axios from 'axios';
import { diff } from 'deep-object-diff';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import mixPanelActions from '../../Mixpanel';
import Button from '@components/Common/Button';
import ContentBuilderHeader from '@components/ContentBuilderHeader';
import NavigationPrompt from '@components/Core/NavigationPrompt';
import DocumentTitle from '@components/DocumentTitle';
import Loader from '@components/Loader';
import ImportModal from './ImportModal';
import VocabBuilderBulkEdit from './VocabBuilderBulkEdit';
import { BASE_TERMS, generateTerms, isEmpty, MAX_LEN, MIN_TERMS } from './VocabBuilderUtils';
import VocabTermEdit from './VocabTermEdit';

const VocabBuilder: React.FC = () => {
   const {
      routes: {
         content: { newVocabSet, viewVocabSet },
      },
   } = Constants;

   const [isImportModalOpen, setIsImportModalOpen] = React.useState<boolean>(false);
   const [isDirty, setIsDirty] = React.useState<boolean>(false);
   const [isFetching, setIsFetching] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [origSettings, setOrigSettings] = React.useState<Maybe<Content>>();
   const [origTerms, setOrigTerms] = React.useState<readonly IVocabTerm<true>[]>([]);
   const [selectedTerms, setSelectedTerms] = React.useState<readonly string[]>([]);
   const [isReadyToRedirect, setIsReadyToRedirect] = React.useState<boolean>(false);
   const [settings, setSettings] = React.useState<Content>({
      createdBy: null,
      description: '',
      folderId: null,
      id: null,
      imageUrl: '',
      imageFilename: null,
      language: 'en',
      name: 'Untitled Set',
      styleSheetId: null,
      tags: [],
      type: ContentType.vocabSet,
      createdOn: null,
      modifiedOn: null,
   });
   const [terms, setTerms] = React.useState<readonly IVocabTerm<true>[]>([]);
   const [bulkProperties, setBulkProperties] = React.useState<{
      partOfSpeech: PartOfSpeech;
      gender: TermGender;
      number: TermNumber;
   }>({
      partOfSpeech: '',
      gender: '',
      number: '',
   });

   const { userProfile, ...appStateContext } = React.useContext<AppStateContext>(AppStateContext);
   const location = useLocation();
   const navigate = useNavigate();
   const { vocabSetId: paramVocabSetId } = useParams();

   const mode = location.pathname === newVocabSet ? 'create' : 'edit';

   const isValid = ({ term, definition }: IVocabTerm<true>): boolean =>
      !!((term.trim().length > 0 || settings.language === 'asl') && definition.trim().length > 0);

   const validTermCount = terms.filter(isValid).length;

   if (!userProfile) {
      return null;
   }

   React.useEffect(() => {
      if (isReadyToRedirect && !isDirty && settings.id) {
         const moduleItemId = getQueryParameterAsNumber(location, 'moduleItemId', null);
         const redirectRoute = viewVocabSet.replace(':vocabSetId', settings.id.toString());
         navigate(moduleItemId ? `${redirectRoute}?moduleItemId=${moduleItemId}` : redirectRoute);
      }
   }, [isReadyToRedirect, isDirty, settings.id]);

   React.useEffect(() => {
      if (mode === 'create') {
         const folderId = getQueryParameterAsNumber(location, 'folder', null);
         const { language, id: createdBy } = userProfile;
         setSettings((prevSettings) => ({
            ...prevSettings,
            createdBy,
            folderId,
            language,
         }));
         setTerms(generateTerms(BASE_TERMS));
         setBreadcrumbs();
      } else if (mode === 'edit') {
         fetchSet();
      }
   }, [mode]);

   const closeImportModal = () => setIsImportModalOpen(false);

   const openImportModal = () => setIsImportModalOpen(true);

   const commitThenRedirect = async (): Promise<void> => {
      if (!terms.every((i) => _.isEmpty(i.errors)) || isLoading || isImportModalOpen) {
         return;
      }
      setIsLoading(true);

      // Upload set thumbnail
      const settingsCopy = { ...settings };
      if (settingsCopy.file) {
         await uploadImage(settingsCopy.file).then((f) => {
            settingsCopy.imageFilename = f;
         });
      }

      // Upload term images
      const imagePromises: Promise<void>[] = [];
      const termsCopy = [...terms];

      termsCopy.forEach(({ imageUrl, imageFilename, file }, i) => {
         if (file) {
            imagePromises.push(
               uploadImage(file).then((f) => {
                  termsCopy[i].imageFilename = f;
               }),
            );
         } else if (imageUrl && !imageFilename) {
            imagePromises.push(
               HttpService.postWithAuthToken<{ filename: string }>(
                  '/api/services/upload_image',
                  snakeCaseKeys({ imageUrl }),
               ).then((r) => {
                  termsCopy[i].imageFilename = r.data.filename;
               }),
            );
         }
      });
      await axios.all(imagePromises);

      const keysToOmit = ['errors', 'key', 'file', 'image', 'fileUrl', 'imageUrl'];
      const vocabSetsRoute = '/api/content/vocab_sets';

      const filteredTerms = termsCopy
         .filter(isValid)
         .map((i) => ({ ...i, term: i.term.normalize().trim() }));

      indexDeep(filteredTerms);
      if (mode === 'create') {
         const data = { ...settingsCopy, terms: filteredTerms };
         omitDeep(data, keysToOmit);
         await HttpService.postWithAuthToken<{ msg: string; id: number }>(
            vocabSetsRoute,
            snakeCaseKeys(data),
         ).then((response) => {
            const { id: newVocabSetId } = response.data;
            mixPanelActions.track('Vocab Set Created', {
               id: newVocabSetId,
               language: settings.language,
               name: settings.name,
               terms: terms.length,
            });
            setSettings((prevSettings) => ({ ...prevSettings, id: newVocabSetId }));
            setIsDirty(false);
            setIsReadyToRedirect(true);
         });
         return;
      }

      const requests = [];
      const { id: vocabSetId } = settings;
      if (!vocabSetId) {
         return;
      }
      const vocabSetRoute = `${vocabSetsRoute}/${vocabSetId}`;
      const termsRoute = `${vocabSetRoute}/terms`;

      const updatedSettings: Partial<Content> = _.cloneDeep(diff(origSettings ?? {}, settingsCopy));
      omitDeep(updatedSettings, keysToOmit);
      if (updatedSettings.tags) {
         updatedSettings.tags = settings.tags;
      }
      if (Object.keys(updatedSettings).length) {
         requests.push(
            HttpService.patchWithAuthToken<MessageResponse>(
               vocabSetRoute,
               snakeCaseKeys(updatedSettings),
            ),
         );
      }

      const origTermKeys = origTerms.map((q) => q.key);
      const termKeys = filteredTerms.map((q) => q.key);
      const origTermsObj = _.keyBy(origTerms, 'key');
      const termsObj: Record<string, IVocabTerm<true>> = _.keyBy(filteredTerms, 'key');
      omitDeep(origTermsObj, keysToOmit);
      omitDeep(termsObj, keysToOmit);
      [...termKeys]
         .filter((key) => !origTermKeys.includes(key))
         .forEach((key) => {
            requests.push(
               HttpService.postWithAuthToken<MessageResponse>(
                  termsRoute,
                  snakeCaseKeys(termsObj[key]),
               ),
            );
         });
      [...origTermKeys]
         .filter((key) => !termKeys.includes(key))
         .forEach((key) => {
            requests.push(
               HttpService.deleteWithAuthToken<MessageResponse>(
                  `${termsRoute}/${origTermsObj[key].id}`,
               ),
            );
         });
      [...origTermKeys]
         .filter((key) => termKeys.includes(key) && !_.isEqual(origTermsObj[key], termsObj[key]))
         .forEach((key) => {
            requests.push(
               HttpService.putWithAuthToken<MessageResponse>(
                  `${termsRoute}/${termsObj[key].id}`,
                  snakeCaseKeys(termsObj[key]),
               ),
            );
         });

      await axios.all(requests);
      setIsDirty(false);
      setIsReadyToRedirect(true);
   };

   const editSettings = (update: Partial<Content>): void => {
      setSettings((prevState) => ({
         ...prevState,
         ...update,
      }));
      setIsDirty(true);
   };

   const fetchSet = async (): Promise<void> => {
      if (!paramVocabSetId) {
         return;
      }
      setIsFetching(true);
      const response = await HttpService.getWithAuthToken<VocabSet<false, true>>(
         `/api/content/vocab_sets/${paramVocabSetId}`,
      );
      const {
         createdBy,
         description,
         folderId,
         imageUrl,
         language,
         name,
         tags,
         terms: responseTerms,
         imageFilename,
         createdOn,
         modifiedOn,
         id,
      } = response.data;
      const isCreator = createdBy === userProfile?.id;
      keyDeep(responseTerms);
      responseTerms.forEach((term) => {
         term.errors = {};
      });
      const responseSettings = {
         id,
         createdBy,
         name,
         language,
         tags,
         description,
         imageUrl,
         imageFilename,
         folderId: isCreator ? folderId : null,
         styleSheetId: null,
         createdOn,
         modifiedOn,
         type: ContentType.vocabSet,
      };
      setOrigSettings(_.cloneDeep(responseSettings));
      setOrigTerms(_.cloneDeep(responseTerms));
      setTerms(responseTerms);
      setSettings(responseSettings);
      setIsFetching(false);
      setBreadcrumbs();
   };

   const handleBulkChange = (property: keyof IVocabTerm, value: string): void => {
      setTerms((prevTerms) =>
         prevTerms.map((term) =>
            selectedTerms.includes(term.key) ? { ...term, [property]: value } : term,
         ),
      );
      setBulkProperties((prevBulkProperties) => ({
         ...prevBulkProperties,
         [property]: value,
      }));
      setIsDirty(true);
   };

   const handleTermChange = (key: string, update: Partial<IVocabTerm<true>>): void => {
      setTerms((prevTerms) =>
         prevTerms.map((i) => (i.key === key ? checkTermForErrors({ ...i, ...update }) : i)),
      );
      setIsDirty(true);
   };

   const handleDragEnd = (result: DropResult): void => {
      if (!result.destination) {
         return;
      }
      setTerms((prevTerms) => {
         const updatedTerms = [...prevTerms];
         const [removed] = updatedTerms.splice(result.source.index, 1);
         if (result.destination) {
            updatedTerms.splice(result.destination.index, 0, removed);
         }
         return updatedTerms;
      });
      setIsDirty(true);
   };

   const removeSelectedTerms = (): void => {
      if (terms.length < MIN_TERMS) {
         return;
      }
      setTerms((prevTerms) => prevTerms.filter(({ key }) => !selectedTerms.includes(key)));
      setSelectedTerms((prevSelectedTerms) =>
         prevSelectedTerms.filter((key) => !selectedTerms.includes(key)),
      );
      setIsDirty(true);
   };

   const toggleSelect = (key: string): void => {
      setSelectedTerms((prevSelectedTerms) =>
         prevSelectedTerms.includes(key)
            ? prevSelectedTerms.filter((k) => k !== key)
            : [...prevSelectedTerms, key],
      );
      if (!selectedTerms.length) {
         setBulkProperties({
            partOfSpeech: '',
            gender: '',
            number: '',
         });
      }
   };

   const toggleSelectAll = (): void => {
      if (terms.length === selectedTerms.length) {
         setSelectedTerms([]);
         setBulkProperties({
            partOfSpeech: '',
            gender: '',
            number: '',
         });
      } else {
         setSelectedTerms(terms.map(({ key }) => key));
      }
   };

   const checkTermForErrors = (term: IVocabTerm<true>): IVocabTerm<true> => {
      const MAX_LEN_MSG = `Max. ${MAX_LEN} characters`;
      const updatedTerm = { ...term };
      const errors: Record<string, string> = {};
      if (updatedTerm.term.length > MAX_LEN) {
         errors.term = MAX_LEN_MSG;
      }
      if (updatedTerm.definition.length > MAX_LEN) {
         errors.definition = MAX_LEN_MSG;
      }
      return { ...updatedTerm, errors };
   };

   const importTerms = (importedTerms: readonly IVocabTerm<true>[]): void => {
      if (!importedTerms.length) {
         return;
      }
      setTerms((prevTerms) =>
         [...prevTerms.filter((i) => !isEmpty(i)), ...importedTerms].map((i) =>
            checkTermForErrors(i),
         ),
      );
      setIsDirty(true);
      closeImportModal();
   };

   const addNewTerm = (): void => {
      setTerms((prevTerms) => [...prevTerms, ...generateTerms(1)]);
   };

   const removeContentImage = (): void => {
      setSettings((prevSettings) => ({
         ...prevSettings,
         imageUrl: undefined,
         imageFilename: null,
      }));
   };

   const removeTerm = (key: string): void => {
      if (terms.length < MIN_TERMS) {
         return;
      }
      setTerms((prevTerms) => prevTerms.filter((i) => i.key !== key));
      setIsDirty(true);
   };

   const setBreadcrumbs = (): Promise<void> => {
      if (!userProfile) {
         return Promise.reject('No User Profile');
      }
      const { createdBy = userProfile.id, folderId, name: contentName, id: contentId } = settings;
      return ContentService.getBreadcrumbs(
         {
            accountType: userProfile.accountType,
            canEditContent: true,
            contentId,
            contentName,
            contentType: ContentType.vocabSet,
            createdBy,
            folderId,
            userId: userProfile.id,
         },
         location,
      ).then(appStateContext.setBreadcrumbs);
   };

   const { language } = settings;
   const isNew = mode === 'create';
   const mainButtonText = `${isNew ? 'Create' : 'Save'} Set`;
   const baseGenders = ['masculine', 'feminine'];
   const genderOptions =
      language && ['de', 'ru'].includes(language) ? [...baseGenders, 'neuter'] : baseGenders;

   useCommands(
      [
         {
            id: 'save',
            title: mode === 'create' ? 'Create Set' : 'Save Set',
            action: commitThenRedirect,
            showIf: () => validTermCount >= MIN_TERMS && !isLoading,
         },
         {
            id: 'import_terms',
            scale: 2.0,
            title: 'Import Terms',
            action: openImportModal,
            showIf: () => !isLoading,
         },
      ],
      [paramVocabSetId, mode, validTermCount, isLoading],
   );

   if (isFetching) {
      return <Loader />;
   }

   return (
      <div className='content-main margin-right-m'>
         <DocumentTitle>{`${isNew ? 'Create' : 'Edit'} Vocab Set`}</DocumentTitle>
         <div className='card no-padding'>
            <div className='card-title has-button'>
               <ContentBuilderHeader
                  removeContentImage={removeContentImage}
                  editSettings={editSettings}
                  settings={settings}
               />
               <div className='right-options-wrapper'>
                  {settings.language !== 'asl' && (
                     <Button line onClick={openImportModal} data-test='open-import-modal'>
                        + Import from Word, Excel, Quizlet, etc.
                     </Button>
                  )}
                  <Button line onClick={addNewTerm} data-test='new-term'>
                     + New term
                  </Button>
                  <Button
                     onClick={commitThenRedirect}
                     loading={isLoading}
                     disabled={validTermCount < MIN_TERMS}
                     data-test='primary-vocab-builder-btn'
                     keyboardShortcut='mod+enter'
                  >
                     {mainButtonText}
                  </Button>
               </div>
            </div>
            <VocabBuilderBulkEdit
               bulkProperties={bulkProperties}
               genderOptions={genderOptions}
               handleBulkChange={handleBulkChange}
               onSelectAll={toggleSelectAll}
               language={settings.language}
               removeSelectedTerms={removeSelectedTerms}
               selectedTermCount={selectedTerms.length}
               showOptions={!!selectedTerms.length}
               termCount={terms.length}
            />
            <div className='vocab-builder-content'>
               <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId='vocab-builder'>
                     {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                           {terms.map((term, i) => (
                              <VocabTermEdit
                                 key={term.key}
                                 index={i}
                                 genderOptions={genderOptions}
                                 onChange={(u) => handleTermChange(term.key, u)}
                                 isSelected={selectedTerms.includes(term.key)}
                                 isDragDisabled={!!selectedTerms.length}
                                 label={i + 1}
                                 language={language}
                                 removeTerm={() => removeTerm(term.key)}
                                 term={term}
                                 termsSelected={!!selectedTerms.length}
                                 toggleSelect={() => toggleSelect(term.key)}
                              />
                           ))}
                           {provided.placeholder}
                        </div>
                     )}
                  </Droppable>
               </DragDropContext>
            </div>
            {isDirty && !isReadyToRedirect && (
               <NavigationPrompt
                  when={isDirty}
                  message='Are you sure you want to leave? Changes you made will not be saved.'
               />
            )}
            {isImportModalOpen && (
               <ImportModal importTerms={importTerms} onClose={closeImportModal} />
            )}
         </div>
      </div>
   );
};

export default VocabBuilder;
