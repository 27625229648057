// @ts-strict-ignore
import * as React from 'react';

import { Maybe } from '@models/Core';
import Timer, { TimerSettings } from '@utilities/Timer';

export const TimerContext = React.createContext({
   setTimer: null,
   timer: null,
});
export const TimerProvider = TimerContext.Provider;
export const TimerConsumer = TimerContext.Consumer;

export const TimerValueContext = React.createContext<number>(0);
export const TimerValueProvider = TimerValueContext.Provider;
export const TimerValueConsumer = TimerValueContext.Consumer;

interface TimerWrapperProps {
   isPaused: boolean;
   isReady: boolean;
   children: React.ReactNode;
}

const TimerWrapper: React.FC<TimerWrapperProps> = ({ isReady, isPaused, children }) => {
   const timer = React.useRef<Timer>(new Timer());
   const [seconds, setSeconds] = React.useState<Maybe<number>>(null);

   React.useEffect(() => {
      if (isReady && timer.current && timer.current.duration) {
         if (isPaused) {
            timer.current.stop();
         } else {
            timer.current.start();
         }
      }
   }, [isReady, isPaused]);

   const setTimer = React.useCallback((settings: Partial<TimerSettings>): void => {
      if (timer.current) {
         timer.current.reset({
            callback: (i) => setSeconds(i),
            ...settings,
         });
      }
   }, []);

   const [controlsInstance, _] = React.useState({ timer, setTimer });

   return (
      <TimerProvider value={controlsInstance}>
         <TimerValueProvider value={seconds}>{children}</TimerValueProvider>
      </TimerProvider>
   );
};

export default React.memo(TimerWrapper);
