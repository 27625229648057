import * as React from 'react';

import IconSynchronize4 from '@icons/nova-line/25-Synchronize/synchronize-4.svg';
import IconClose from '@icons/nova-solid/02-Status/close.svg';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

import Button from '@components/Common/Button';
import { IOnboardingStep } from './Models';

interface VideoPointerProps {
   arrowStyle?: React.CSSProperties;
   index: number;
   step: IOnboardingStep;
   size: number;
   style: React.CSSProperties;
   arrowRef(element: HTMLDivElement): void;
   elementRef?(element: HTMLDivElement): void;
   onCloseClick(): void;
   onPrimaryClick(): void;
}

enum VideoState {
   ready = 'ready',
   playing = 'playing',
   paused = 'paused',
   ended = 'ended',
}

const VideoPointer: React.FC<VideoPointerProps> = ({
   arrowStyle,
   index,
   size,
   step,
   style,
   arrowRef,
   elementRef,
   onCloseClick,
   onPrimaryClick,
   ...props
}) => {
   const [isMuted, setIsMuted] = React.useState<boolean>(true);
   const [interactedWith, setInteractedWith] = React.useState<boolean>(false);
   const [videoState, setVideoState] = React.useState<Maybe<VideoState>>(null);
   const videoElement = React.useRef<HTMLVideoElement>(null);

   const handleOverlayClick = (): void => {
      if (videoState === VideoState.playing) {
         if (interactedWith) {
            setIsMuted((prevIsMuted) => !prevIsMuted);
         } else {
            setInteractedWith(true);
            setIsMuted(false);
            if (videoElement.current) {
               videoElement.current.currentTime = 0;
            }
         }
      }
   };

   const handleReplayClick = (): void => {
      setIsMuted(false);
      if (videoElement.current) {
         videoElement.current.play();
      }
   };

   const handlePlaying = (): void => setVideoState(VideoState.playing);

   const handlePaused = (): void => setVideoState(VideoState.paused);

   const handleReady = (): void => setVideoState(VideoState.ready);

   const handleEnded = (): void => setVideoState(VideoState.ended);

   return (
      <div className='onboarding-video-pointer-wrapper' style={style} ref={elementRef} {...props}>
         <div className='video-pointer'>
            <div className='video-element'>
               <video
                  ref={videoElement}
                  autoPlay
                  playsInline
                  loop={!interactedWith}
                  muted={isMuted}
                  aria-label='Play video'
                  src={step.videoUrl}
                  tabIndex={0}
                  onPlaying={handlePlaying}
                  onPause={handlePaused}
                  onCanPlay={handleReady}
                  onEnded={handleEnded}
               />
            </div>
            <div
               className={classnames('video-overlay', interactedWith ? videoState : null)}
               onClick={handleOverlayClick}
            >
               <div className='close'>
                  <IconClose onClick={onCloseClick} />
               </div>
               {videoState === VideoState.ended && (
                  <div className='replay-button-wrapper'>
                     <Button className='replay-button' onClick={handleReplayClick}>
                        <IconSynchronize4 />
                     </Button>
                  </div>
               )}
               <div className='video-overlay-footer'>
                  <div className='step'>{`${index + 1} of ${size}`}</div>
                  <div className='next-button-wrapper'>
                     <Button className='next-button' onClick={onPrimaryClick}>
                        {index + 1 === size ? 'Done' : 'Next'}
                     </Button>
                  </div>
               </div>
            </div>
         </div>
         <svg>
            <defs>
               <clipPath id='video-clip'>
                  <path
                     d='M5,0 C2.23857625,-1.69088438e-16 3.38176876e-16,2.23857625 0,5 L0,199 C3.38176876e-16,201.761424 2.23857625,204 5,204 L257,204 C259.761424,204 262,201.761424 262,199 L262,110.556 L262,102 L262,92.807 L262,5 C262,2.23857625 259.761424,1.69088438e-16 257,0 L5,0 Z'
                     fillRule='nonzero'
                  />
                  <polygon
                     points='262 0 262 17.749 272 8.8745'
                     style={{
                        transform: arrowStyle ? arrowStyle.transform : 'translateX(50px)',
                     }}
                  />
               </clipPath>
            </defs>
         </svg>
         <div
            ref={arrowRef}
            style={{ ...arrowStyle, opacity: 0 }}
            className='onboarding-pointer-arrow'
         />
      </div>
   );
};

export default VideoPointer;
