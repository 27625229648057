import * as React from 'react';

import { ActivityBuilderMode, ActivityItemVisibility, TextContent } from '@models/Activity';

interface TextContentOptionsProps {
   item: TextContent<ActivityBuilderMode>;
   handleItemUpdate(update: Partial<TextContent<ActivityBuilderMode>>, callback?: () => void): void;
}

const TextContentOptions: React.FC<TextContentOptionsProps> = ({ handleItemUpdate, item }) => (
   <div className='row'>
      <div className='col-xs-12'>
         <div className='margin-top-s'>
            <label className='field-title'>Show</label>
            <select
               name='show'
               value={item.show}
               onChange={(e) =>
                  handleItemUpdate({
                     show: e.target.value as ActivityItemVisibility,
                  })
               }
            >
               <option value={ActivityItemVisibility.always}>Always</option>
               <option value={ActivityItemVisibility.onceClosed}>Once Closed</option>
               <option value={ActivityItemVisibility.onceGraded}>Once Graded</option>
            </select>
         </div>
      </div>
   </div>
);

export default TextContentOptions;
