import { ProficiencyCanDoStatementDetailed } from '@models/Proficiency';

import AxiosService from './AxiosService';

const API = '/api/module_can_do_statements';

export type ModuleCanDoStatementResponse = {
   canDoStatements: ProficiencyCanDoStatementDetailed[];
};

const get = (moduleId: number): Promise<readonly ProficiencyCanDoStatementDetailed[]> =>
   AxiosService.get<ModuleCanDoStatementResponse>(`${API}/${moduleId}`).then(
      (response) => response.data.canDoStatements,
   );

const create = (moduleId: number, canDoStatementId: number): Promise<void> =>
   AxiosService.post<void>(
      `${API}/${moduleId}`,
      { canDoStatementId },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then();

const remove = (moduleId: number, canDoStatementId: number): Promise<void> =>
   AxiosService.delete<void>(`${API}/${moduleId}/${canDoStatementId}`, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

export default {
   create,
   get,
   remove,
};
