import * as React from 'react';

import { ActivityMode, ImageLabelingHotspot, ImageLabelingResponse } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';

interface ImageLabelingPropsProps {
   hotspots: readonly ImageLabelingHotspot<ActivityMode.grade>[];
   fileUrl: Maybe<string>;
   response: ImageLabelingResponse;
}

const ImageLabelingProps: React.FC<ImageLabelingPropsProps> = ({ fileUrl, hotspots, response }) => {
   const renderHotspot = (hotspot: ImageLabelingHotspot<ActivityMode.grade>): React.ReactNode => {
      const responseEntry = response[hotspot.id as number];
      const responseStr = responseEntry.response;

      const unmodified = responseEntry.modified === false;
      const correct = unmodified && responseEntry.correct === true;
      const incorrect = unmodified && responseEntry.correct === false;

      return (
         <div
            className={classnames('droppable', { correct, incorrect })}
            style={{ top: `${hotspot.y}%`, left: `${hotspot.x}%` }}
            key={hotspot.id}
         >
            <span className='draggable'>{responseStr}</span>
         </div>
      );
   };

   if (!fileUrl) {
      return null;
   }

   return (
      <div className='row'>
         <div className='col-xs-12'>
            <div className='image-labeling-form'>
               <div className='drag-parent'>
                  {hotspots.map(renderHotspot)}
                  {}
                  <img src={fileUrl} />
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(ImageLabelingProps);
