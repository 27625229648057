import * as _ from 'lodash';
import * as React from 'react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import {
   ProficiencyCanDoStatementDetailedWithActivities,
   ProficiencyCanDoStatementFields,
   ProficiencyCanDoStatementOptions,
   SkillsConstants,
} from '@models/Proficiency';
import { StringOption } from '@models/ReactSelectHelperTypes';
import PerformanceIndicatorService from '@services/PerformanceIndicatorService';
import ProficiencyCanDoStatementService from '@services/ProficiencyCanDoStatementService';
import { updatedDiff } from 'deep-object-diff';
import { SubmitHandler, useForm } from 'react-hook-form';

import ModalDialog from '@components/Core/ModalDialog';
import SelectTypeAhead from '@components/SelectTypeAhead';

interface CanDoStatmentModalEditorModalProps {
   onCancel(): void;
   onSave(): void;
   options: ProficiencyCanDoStatementOptions;
   selectedCanDoStatement: Maybe<ProficiencyCanDoStatementDetailedWithActivities>;
}

type CanDoStatementFormFields = Omit<ProficiencyCanDoStatementFields, 'level' | 'mode'>;

// All of the input coming out of the form are string values.
const createCanDoStatementFormSchema = yup
   .object()
   .shape({
      name: yup.string().required(),
      performanceIndicatorId: yup.number().required(),
      skill: yup.string().oneOf(SkillsConstants).required(),
      themeId: yup.number().nullable().required(),
   })
   .required();

const CanDoStatmentModalEditorModal: React.FC<CanDoStatmentModalEditorModalProps> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);
   const initialPerformanceIndicatorOption: Maybe<StringOption> = props.selectedCanDoStatement
      ? {
           label: props.selectedCanDoStatement.performanceIndicator,
           value: props.selectedCanDoStatement.performanceIndicatorId.toString(),
        }
      : null;
   const [selectedProficiencyIndicator, setProficiencyIndicatorOption] = React.useState<
      Maybe<StringOption>
   >(initialPerformanceIndicatorOption);

   const {
      handleSubmit,
      register,
      control,
      formState: { isDirty, errors },
   } = useForm({
      resolver: yupResolver<CanDoStatementFormFields>(createCanDoStatementFormSchema),
      defaultValues: {
         ...props.selectedCanDoStatement,
         themeId:
            props.selectedCanDoStatement?.themeId === null
               ? -1
               : props.selectedCanDoStatement?.themeId,
      },
   });

   const createCanDoStatement = (data: CanDoStatementFormFields): void => {
      setIsSaving(true);
      ProficiencyCanDoStatementService.create({
         ...data,
         // React hook forms defaults to an empty string for
         // non-selected values therefor I created a -1 option
         // to set a null here
         themeId: data.themeId === -1 ? null : data.themeId,
      })
         .then(() => {
            setIsSaving(false);
            props.onSave();
         })
         .catch(() => {
            setIsSaving(false);
            alert('Failed to save. Ensure the new statement is unique.');
         });
   };

   const updateCanDoStatement = (data: CanDoStatementFormFields): void => {
      // Since theme id is nullable and the default of HTML/ReactHookforms is to give
      // and empty string for an empty text field I set the null value to be -1. Here we
      // null it out again.
      const withNullableThemeId = {
         ...data,
         themeId: data.themeId === -1 ? null : data.themeId,
      };

      const difference = updatedDiff(
         _.omit(props.selectedCanDoStatement, 'id'),
         withNullableThemeId,
      );

      if (!_.isEmpty(difference) && props.selectedCanDoStatement) {
         setIsSaving(true);

         ProficiencyCanDoStatementService.update(props.selectedCanDoStatement.id, difference)
            .then(() => {
               setIsSaving(false);
               props.onSave();
            })
            .catch(() => {
               setIsSaving(false);
               alert('Failed to save. Ensure the new statement is unique.');
            });
      }
   };

   const onSubmit: SubmitHandler<CanDoStatementFormFields> = (data): void => {
      const isUpdate = !!props.selectedCanDoStatement;
      if (isUpdate) {
         updateCanDoStatement(data);
      } else {
         createCanDoStatement(data);
      }
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='no-center padding-bottom-s'
         heading='Create Can-Do Statement'
         onClose={props.onCancel}
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            { text: 'Cancel', onClick: props.onCancel },
         ]}
      >
         <form>
            <div className='row'>
               <div className='col-xs-12'>
                  <label className='field-title'>Statement</label>
                  <input {...register('name', { required: true })} autoFocus type='text' />
               </div>
               <div className='col-xs-12 margin-top-s'>
                  <label className='field-title'>Performance Indicator</label>
                  <SelectTypeAhead
                     placeHolder='Start typing a performance indicator.'
                     rowLoader={PerformanceIndicatorService.search}
                     setSelectedOption={setProficiencyIndicatorOption}
                     selectedOption={selectedProficiencyIndicator}
                     control={control}
                     fieldName='performanceIndicatorId'
                  />
                  <p className='error'>{errors.performanceIndicatorId?.message}</p>
               </div>
            </div>
            <div className='row'>
               <div className='col-xs-6'>
                  <label className='field-title'>Skill</label>
                  <select {...register('skill', { required: true })}>
                     {props.options.skills.map((x) => (
                        <option key={x} value={x}>
                           {x}
                        </option>
                     ))}
                  </select>
               </div>
               <div className='col-xs-6'>
                  <label className='field-title'>Theme</label>
                  <select {...register('themeId')}>
                     <option value={-1}>None</option>
                     {props.options.themes.map((x) => (
                        <option key={x.id} value={x.id}>
                           {x.name}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
         </form>
      </ModalDialog>
   );
};

export default CanDoStatmentModalEditorModal;
