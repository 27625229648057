// Automatic import sorting is turned off on this file due to the
// issue called out in comments below.  See import-sorter.json
// import './wdyr'; // <--- first import
import * as React from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './scripts/polyfills';
import 'tinymce';

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

// Our redux/Store import must come before AppContainer.
// Otherwise bad things happen to 'context'.
import { persistor, store } from './scripts/redux/Store';
import AppContainer from './scripts/AppContainer';

import AppState from './scripts/AppState';
import { setupBrowserConfiguration, setupDatadog, setupMixpanel } from './scripts/AppInit';
import OnboardingProvider from './scripts/components/Onboarding/OnboardingProvider';
import history from './scripts/helpers/History';
import WebSocketConnection from './scripts/WebSocketConnection';

setupBrowserConfiguration();
setupDatadog();
setupMixpanel();

const container = document.getElementById('react-root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
   <Provider store={store}>
      <PersistGate persistor={persistor}>
         <HistoryRouter
            // @ts-expect-error - This is a known issue with the types for HistoryRouter
            // https://github.com/remix-run/react-router/issues/9630#issuecomment-1341643731
            history={history}
         >
            <AppState>
               <OnboardingProvider>
                  <WebSocketConnection>
                     <AppContainer />
                  </WebSocketConnection>
               </OnboardingProvider>
            </AppState>
         </HistoryRouter>
      </PersistGate>
   </Provider>,
);
