import * as React from 'react';

/**
 * `useIsMount` is a custom hook that checks if the current render is the initial mount of the component.
 *  It can be useful for conditionally running code only on the initial mount.
 *
 * @returns {boolean} Returns true if it's the initial mount, false otherwise.
 */
const useIsMount = (): boolean => {
   const isMountRef = React.useRef<boolean>(true);
   React.useEffect(() => {
      isMountRef.current = false;
   }, []);
   return isMountRef.current;
};

export default useIsMount;
