import * as _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flattenObject = (ob: any): Record<string, unknown> => {
   const toReturn: Record<string, unknown> = {};

   for (const i in ob) {
      if (!Object.prototype.hasOwnProperty.call(ob, i)) {
         continue;
      }

      if (_.isObject(ob[i]) && ob[i] !== null) {
         const flatObject = flattenObject(ob[i]);
         for (const x in flatObject) {
            if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
               continue;
            }

            toReturn[i + '.' + x] = flatObject[x];
         }
      } else {
         toReturn[i] = ob[i];
      }
   }
   return toReturn;
};

export default flattenObject;
