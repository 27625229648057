import * as React from 'react';

import useSubscription from '@hooks/use-subscription';
import { DiscussionBoardResponseEntry, DiscussionBoardUpdate } from '@models/Activity';
import BasicUserProfile from '@models/BasicUserProfile';
import { Maybe } from '@models/Core';

import { AppContext } from '../../../App';

interface DiscussionBoardListenerProps {
   moduleItemId: number;
   appendDiscussionBoardResponse(
      promptId: number,
      entry: DiscussionBoardResponseEntry,
      authorProfile: Maybe<BasicUserProfile>,
   ): void;
}

const DiscussionBoardListener: React.FC<DiscussionBoardListenerProps> = ({
   moduleItemId,
   appendDiscussionBoardResponse,
}) => {
   const { socketEventEmitter } = React.useContext(AppContext);

   if (!socketEventEmitter) {
      return null;
   }

   const handleDiscussionBoardUpdate = (update: DiscussionBoardUpdate): void => {
      if (update.moduleItemId === moduleItemId) {
         const authorProfile = update.authorProfile ? update.authorProfile : null;
         appendDiscussionBoardResponse(
            update.promptId,
            { ...update.entry, createdOn: new Date(update.entry.createdOn) },
            authorProfile,
         );
      }
   };

   useSubscription(socketEventEmitter, 'DISCUSSION_BOARD_UPDATE', handleDiscussionBoardUpdate);

   return null;
};

export default DiscussionBoardListener;
