import * as _ from 'lodash';
import * as React from 'react';

import { Column } from '@components/Common/Table';
import { renderDateCell, renderTooltipCell } from '@components/Common/TableCellHelpers';
import Avatar from '@components/Core/Avatar';
import FilterableTable from '@components/FilterableTable';
import { OperatorType } from '@components/PredicateEditor/Models';
import SchoolDetailsPopover from '@components/SchoolDetailsPopover';
import { User, UserLogin, UserReportRowFragment } from '@generated/gql/graphql';
import { randomShortId } from '@helpers/RandomStringUtils';
import UserService from '@services/UserService';

import UserActionsCell from './UserActionsCell';

const Users: React.FC = () => {
   const renderSchoolCell = (row: UserReportRowFragment): React.ReactNode => {
      if (!row.organizations || row.organizations.length === 0) {
         return 'No School';
      }

      return (
         <SchoolDetailsPopover
            organizationId={row.organizations[0].id}
            schoolName={row.organizations[0].name}
         />
      );
   };

   const renderUserActionsCell = (
      row: User,
      onUpdate: (updatedRow: User) => void,
      onDelete: () => void,
   ): React.ReactNode => <UserActionsCell row={row} onUpdate={onUpdate} onDelete={onDelete} />;

   const defaultPredicate = {
      id: randomShortId(),
      type: OperatorType.and,
      predicates: [],
   };

   const getLatestLogin = (logins: UserLogin[]): UserLogin | undefined => {
      const latestId = Math.max(...logins.filter((l) => !l.impersonated).map((l) => Number(l.id)));
      return logins.find((l) => Number(l.id) === latestId);
   };

   const renderAvaterCell = ({
      firstName,
      id,
      lastName,
      profileImageUrl,
   }: User): React.ReactNode => (
      <Avatar
         firstName={firstName}
         lastName={lastName}
         hashValue={parseInt(id)}
         src={profileImageUrl}
         size='large'
      />
   );

   const columns: readonly Column<User>[] = [
      {
         id: 'avatar',
         header: '',
         cell: (i) => renderAvaterCell(i),
         canSort: false,
      },
      {
         id: 'user.id',
         header: 'Id',
         cell: (i) => i.id,
         canSort: true,
      },
      {
         id: 'user.first_name',
         header: 'First Name',
         cell: (i) => i.firstName,
         canSort: true,
      },
      {
         id: 'user.last_name',
         header: 'Last Name',
         cell: (i) => i.lastName,
         canSort: true,
      },

      {
         id: 'user.email',
         header: 'Email address',
         cell: (i) => i.email,
         canSort: true,
      },
      {
         id: 'user.account_type',
         header: 'Account type',
         cell: (i) => i.accountType,
         canSort: true,
      },
      {
         id: 'organization.name',
         header: 'School',
         cell: renderSchoolCell,
         canSort: false,
      },
      {
         id: 'user.language',
         header: 'Language',
         cell: (i) => i.language,
         canSort: true,
      },
      {
         id: 'user_login.last_refreshed_on',
         header: 'Last seen',
         cell: (i) => renderDateCell(getLatestLogin(i.logins)?.lastRefreshedOn),
         canSort: false,
      },
      {
         id: 'user.created_on',
         header: 'Created',
         cell: (i) => renderDateCell(i.createdOn),
         canSort: false,
      },
      {
         id: 'user_login.browser',
         header: 'Browser',
         cell: (i) => {
            const latestLogin = getLatestLogin(i.logins);
            return renderTooltipCell(latestLogin?.browser, latestLogin?.browserVersion);
         },
         canSort: false,
      },
      {
         id: 'actions',
         header: 'Actions',
         cell: renderUserActionsCell,
      },
   ];

   return (
      <FilterableTable<UserReportRowFragment>
         attributes={[]}
         columns={columns}
         defaultPredicate={defaultPredicate}
         defaultSortOrder={[{ id: 'user.id', desc: true }]}
         pageTitle='Users'
         searchInputPlaceholder='Search Users'
         rowsLoader={UserService.getUsers}
      />
   );
};

export default Users;
