import HttpService from '@services/HttpService';
import { AxiosResponse } from 'axios';

export interface Product {
   id: number;
   licenseDurationInMonths: string;
   name: string;
   price: number;
   showBookstoreCodePaymentFirst: boolean;
}

export interface ProductPrice {
   totalPrice: number;
   products: readonly Product[];
}

export interface GetProductsResponse {
   products: readonly ProductPrice[];
}

const getProducts = (courseId: number): Promise<AxiosResponse<GetProductsResponse>> =>
   HttpService.getWithAuthToken<GetProductsResponse>(`/api/courses/${courseId}/products`);

export default {
   getProducts,
};
