import * as _ from 'lodash';

import { ContentType } from '@models/Content';
import { GlobalContentItemProfile } from '@models/ContentItemProfile';
import ContentService from '@services/ContentService';
import tinymce, { Editor } from 'tinymce';

interface AutocompleterInstanceApi {
   hide(): void;
   reload(fetchOptions: Record<string, unknown>): void;
}

const getContentLinkClass = (itemType: ContentType) => _.snakeCase(itemType).replace('_', '-');

const handleValueAction = (
   editor: Editor,
   autocompleteApi: AutocompleterInstanceApi,
   rng: Range,
   value: GlobalContentItemProfile,
) => {
   const viewUrl = ContentService.getViewUrl(value);
   editor.selection.setRng(rng);
   editor.insertContent(
      `<a href="${viewUrl}" class="content-link ${getContentLinkClass(
         value.itemType,
      )}">${value.itemName.trim()}</a>`,
   );
   autocompleteApi.hide();
};

const getImageUri = (itemType: ContentType): string => {
   switch (itemType) {
      case ContentType.activity:
         return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px' viewBox='0 0 16 16' version='1.1' fill='white'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;' d='M 5.332031 8 L 10 8 C 10.367188 8 10.667969 7.703125 10.667969 7.335938 L 10.667969 2.667969 C 10.667969 2.300781 10.367188 2 10 2 L 5.332031 2 C 4.964844 2 4.667969 2.300781 4.667969 2.667969 L 4.667969 7.335938 C 4.667969 7.703125 4.964844 8 5.332031 8 Z M 5.332031 8 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;' d='M 6.667969 8.667969 L 2 8.667969 C 1.632812 8.667969 1.332031 8.964844 1.332031 9.335938 L 1.332031 14 C 1.332031 14.367188 1.632812 14.667969 2 14.667969 L 6.667969 14.667969 C 7.035156 14.667969 7.332031 14.367188 7.332031 14 L 7.332031 9.335938 C 7.332031 8.964844 7.035156 8.667969 6.667969 8.667969 Z M 6.667969 8.667969 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;' d='M 13.332031 8.667969 L 8.667969 8.667969 C 8.296875 8.667969 8 8.964844 8 9.335938 L 8 14 C 8 14.367188 8.296875 14.667969 8.667969 14.667969 L 13.332031 14.667969 C 13.703125 14.667969 14 14.367188 14 14 L 14 9.335938 C 14 8.964844 13.703125 8.667969 13.332031 8.667969 Z M 13.332031 8.667969 '/%3E%3C/g%3E%3C/svg%3E%0A";
      case ContentType.lesson:
         return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px' viewBox='0 0 16 16' version='1.1' fill='white'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill-opacity:1;' d='M 5.332031 1.335938 L 3.332031 1.335938 C 2.964844 1.335938 2.667969 1.632812 2.667969 2 L 2.667969 14 C 2.667969 14.367188 2.964844 14.667969 3.332031 14.667969 L 5.332031 14.667969 Z M 5.332031 1.335938 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill-opacity:1;' d='M 12.667969 1.335938 L 6.667969 1.335938 L 6.667969 14.667969 L 12.667969 14.667969 C 13.035156 14.667969 13.332031 14.367188 13.332031 14 L 13.332031 2 C 13.332031 1.632812 13.035156 1.335938 12.667969 1.335938 Z M 12 6 L 8 6 L 8 4 L 12 4 Z M 12 6 '/%3E%3C/g%3E%3C/svg%3E%0A";
      case ContentType.video:
         return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px' viewBox='0 0 16 16' version='1.1' fill='white'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill-opacity:1;' d='M 13.332031 2.003906 L 2 2.003906 C 1.261719 2.003906 0.667969 2.597656 0.667969 3.335938 L 0.667969 12.667969 C 0.667969 13.40625 1.261719 14.003906 2 14.003906 L 13.332031 14.003906 C 14.070312 14.003906 14.667969 13.40625 14.667969 12.667969 L 14.667969 3.335938 C 14.667969 2.597656 14.070312 2.003906 13.332031 2.003906 Z M 5.332031 3.335938 L 6.667969 3.335938 L 6.667969 4.667969 L 5.332031 4.667969 Z M 3.332031 12.667969 L 2 12.667969 L 2 11.335938 L 3.332031 11.335938 Z M 3.332031 4.667969 L 2 4.667969 L 2 3.335938 L 3.332031 3.335938 Z M 6.667969 12.667969 L 5.332031 12.667969 L 5.332031 11.335938 L 6.667969 11.335938 Z M 10 12.667969 L 8.667969 12.667969 L 8.667969 11.335938 L 10 11.335938 Z M 6 10.003906 L 6 6.003906 L 10 8.003906 Z M 10 4.667969 L 8.667969 4.667969 L 8.667969 3.335938 L 10 3.335938 Z M 13.332031 12.667969 L 12 12.667969 L 12 11.335938 L 13.332031 11.335938 Z M 13.332031 4.667969 L 12 4.667969 L 12 3.335938 L 13.332031 3.335938 Z M 13.332031 4.667969 '/%3E%3C/g%3E%3C/svg%3E%0A";
      case ContentType.vocabSet:
         return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px' viewBox='0 0 16 16' version='1.1' fill='white'%3E%3Cg id='surface1'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill-opacity:1;' d='M 12 14.667969 L 2 14.667969 C 1.632812 14.667969 1.332031 14.367188 1.332031 14 L 1.332031 4 L 2.667969 4 L 2.667969 13.335938 L 12 13.335938 Z M 12 14.667969 '/%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill-opacity:1;' d='M 14 1.335938 L 4.667969 1.335938 C 4.296875 1.335938 4 1.632812 4 2 L 4 11.335938 C 4 11.703125 4.296875 12 4.667969 12 L 14 12 C 14.367188 12 14.667969 11.703125 14.667969 11.335938 L 14.667969 2 C 14.667969 1.632812 14.367188 1.335938 14 1.335938 Z M 10.667969 8.667969 L 6.667969 8.667969 L 6.667969 7.335938 L 10.667969 7.335938 Z M 12 6 L 6.667969 6 L 6.667969 4.667969 L 12 4.667969 Z M 12 6 '/%3E%3C/g%3E%3C/svg%3E%0A";
      default:
         return '';
   }
};

const getBackgroundClass = (itemType: ContentType): string => {
   switch (itemType) {
      case ContentType.activity:
         return 'green-bg';
      case ContentType.vocabSet:
         return 'blue-bg';
      case ContentType.lesson:
         return 'orange-bg';
      case ContentType.video:
         return 'red-bg';
      default:
         return '';
   }
};

tinymce.PluginManager.add('contentlink', (editor) => {
   editor.ui.registry.addAutocompleter('contentlink', {
      trigger: '@',
      minChars: 0,
      columns: 1,
      onAction: (autocompleteApi, rng, value) => {
         handleValueAction(
            editor,
            autocompleteApi,
            rng,
            JSON.parse(value) as GlobalContentItemProfile,
         );
      },
      fetch: (pattern, maxResults) =>
         ContentService.getGlobalContent({
            searchQuery: pattern,
            itemsPerPage: maxResults,
            proficiencyLevels: [],
            proficiencySkills: [],
            languages: [editor.options.get('content_lang')],
            contentTypes: [ContentType.lesson, ContentType.vocabSet],
         }).then((i) =>
            i.rows.map((content) => ({
               type: 'cardmenuitem',
               value: JSON.stringify(content),
               label: content.itemName,
               items: [
                  {
                     type: 'cardcontainer',
                     direction: 'horizontal',
                     items: [
                        {
                           type: 'cardimage',
                           src: getImageUri(content.itemType),
                           classes: [
                              'card-image',
                              getBackgroundClass(content.itemType),
                              'no-border',
                              'medium',
                           ],
                        },
                        {
                           type: 'cardcontainer',
                           direction: 'vertical',
                           items: [
                              {
                                 type: 'cardtext',
                                 text: content.itemName,
                                 name: 'char_name',
                                 classes: ['card-header'],
                              },
                           ],
                        },
                     ],
                  },
               ],
            })),
         ),
   });

   return {
      getMetadata: () => ({
         name: 'Content link',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
