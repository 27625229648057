import * as React from 'react';
import * as yup from 'yup';

import ModalDialog from '@components/Core/ModalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';

import Appearance from '@models/Appearance';
import BasicUserProfile from '@models/BasicUserProfile';
import AdminNationalExamService, {
   NationalExamAdminInput,
} from '@services/AdminNationalExamService';

type Props = {
   examId: number;
   setExamAdmins(admins: BasicUserProfile[]): void;
   closeModal(): void;
};

const NationalExamAdminInputSchema = yup.object().shape({
   examId: yup.number().required().positive().integer(),
   userId: yup.number().required().positive().integer(),
});

const AddExamAdminModal: React.FC<Props> = (props) => {
   const [isSaving, setIsSaving] = React.useState<boolean>(false);

   const {
      handleSubmit,
      register,
      formState: { errors, isDirty },
   } = useForm<NationalExamAdminInput>({
      resolver: yupResolver<NationalExamAdminInput>(NationalExamAdminInputSchema),
      defaultValues: {
         examId: props.examId,
      },
   });

   const onSubmit = async (data: NationalExamAdminInput): Promise<void> => {
      setIsSaving(true);

      try {
         const response = await AdminNationalExamService.addExamAdmin(data);

         props.setExamAdmins(response.updatedAdmins);
         setIsSaving(false);
         props.closeModal();
      } catch (e) {
         console.error(e);
         if (isAxiosError(e)) {
            alert(e.response?.data.error);
         }
      }
   };

   return (
      <ModalDialog
         appearance={Appearance.primary}
         heading={'Add Exam Admin'}
         onClose={props.closeModal}
         bodyClassName='modal-body'
         actions={[
            {
               text: 'Save',
               onClick: handleSubmit(onSubmit),
               loading: isSaving,
               disabled: !isDirty,
            },
            {
               text: 'Close',
               onClick: props.closeModal,
            },
         ]}
      >
         <form>
            <label className='field-title'>User ID</label>
            <input
               {...register('userId')}
               type='number'
               className={classnames({ error: !!errors.userId })}
            />
            {errors.userId && errors.userId.type === 'manual' && errors.userId.message}
         </form>
      </ModalDialog>
   );
};

export default AddExamAdminModal;
