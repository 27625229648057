import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import HttpService from '@services/HttpService';
import TranslationService from '@services/TranslationService';
import tinymce from 'tinymce';

import {
   getPrimaryTextLanguageFromExamples,
   parseExampleContainer,
} from '@components/Core/Editor/parsers';
import { createExample } from '@components/Core/Editor/renderers';
import { Example, ExampleLayout } from '@components/Core/Editor/types/Example';
import { convertStringToNode } from '@components/Core/Editor/utils/node';
import { findNearestTag } from '@components/Core/Editor/utils/tree';

tinymce.PluginManager.add('examples', (editor) => {
   let lastRightClickedExample: Maybe<HTMLElement> = null;

   const getExample = (element?: Element): Maybe<Element> => {
      if (!element) {
         element = editor.selection.getNode();
      }
      return element.closest('.example');
   };

   /** We can't use the editor.selection.getNode() because it's not editable, so we listen for the context menu and store the clicked node */
   editor.on('contextmenu', (event) => {
      if (event.target?.closest('.example')) {
         lastRightClickedExample = event.target;
      } else {
         lastRightClickedExample = null;
      }
   });

   const getExamplesContainer = (element?: Element): Maybe<Element> => {
      if (!element) {
         element = editor.selection.getNode();
      }
      return element.closest('.example-container');
   };

   const deleteComponent = () => {
      const element = getExamplesContainer();
      if (element) {
         const nextChild = element.nextSibling;
         element.remove();
         if (nextChild) {
            editor.selection.setCursorLocation(nextChild, 0);
            editor.focus();
         }
      }
   };

   const canGenerateAdditionalExamples = (): boolean => {
      const element = getExamplesContainer();
      if (!element) {
         return false;
      }
      const nearestH1 = findNearestTag(editor, element, 'h1');
      const language = getPrimaryTextLanguageFromExamples(element);
      const { examples: existingExamples } = parseExampleContainer(element);
      const seedExamples = existingExamples.filter(
         (i) => i.primaryText !== '' && i.secondaryText !== '',
      );
      return !!nearestH1 && !!language && seedExamples.length >= 2;
   };

   const generateExamples = () => {
      const element = getExamplesContainer();
      if (!element) {
         return;
      }
      const nearestH1 = findNearestTag(editor, element, 'h1');
      const language = getPrimaryTextLanguageFromExamples(element);
      const { examples: existingExamples } = parseExampleContainer(element);
      const seedExamples = existingExamples.filter(
         (i) => i.primaryText !== '' && i.secondaryText !== '',
      );
      if (!nearestH1 || !language || seedExamples.length < 2) {
         return;
      }
      const title = nearestH1?.textContent?.trim() || '';
      HttpService.postWithAuthToken<{ examples: readonly Example[] }>(
         '/api/services/generative_ai/generate_examples',
         snakeCaseKeys({ language, title, seedExamples }),
      ).then((response) => {
         const { examples: generatedExamples } = response.data;
         const examplesList = element.querySelector('.examples');
         if (examplesList) {
            editor.dom.setHTML(
               examplesList,
               examplesList.innerHTML +
                  generatedExamples.map((i) => createExample(i, language)).join(''),
            );
         }
      });
   };

   const layoutIsVerical = () =>
      getExamplesContainer()?.classList.contains(ExampleLayout.vertical) || false;

   const setLayout = (layout: ExampleLayout) => {
      const container = getExamplesContainer();
      if (!container) {
         return;
      }
      if (layout === ExampleLayout.vertical) {
         container.classList.remove(ExampleLayout.horizontal);
         container.classList.add(ExampleLayout.vertical);
      } else if (layout === ExampleLayout.horizontal) {
         container.classList.add(ExampleLayout.horizontal);
         container.classList.remove(ExampleLayout.vertical);
      }
   };

   const addExample = () => {
      const container = getExamplesContainer();
      if (!container) {
         return;
      }
      const language = getPrimaryTextLanguageFromExamples(container);
      const examples = container.querySelector('.examples');
      const newExampleNode = convertStringToNode(
         createExample(
            {
               imgSrc: '',
               primaryText: '',
               secondaryText: '',
            },
            language,
         ),
      );
      if (newExampleNode) {
         examples?.appendChild(newExampleNode);
      }
   };

   const deleteExample = () => {
      const element = lastRightClickedExample || getExample();
      element?.remove();
      lastRightClickedExample = null;
   };

   const transatePrimaryToSecondaryText = async (target: Element): Promise<void> => {
      const secondaryTextElement = target;
      const parent = target?.parentElement;
      const primaryTextElement = parent?.querySelector('.primary-text');
      const primaryText = primaryTextElement?.textContent || '';
      const primaryLangAttribute = primaryTextElement?.getAttribute('lang');
      const secondaryLangAttribute = secondaryTextElement?.getAttribute('lang');

      if (primaryText.trim() !== '' && primaryLangAttribute && secondaryLangAttribute) {
         const translatedText = await TranslationService.translate(
            primaryText,
            primaryLangAttribute as Language,
            secondaryLangAttribute as Language,
         );
         secondaryTextElement.textContent = translatedText;
      }
   };

   const translateSecondaryToPrimaryText = async (target: Element): Promise<void> => {
      const primaryTextElement = target;
      const parent = target.parentElement;
      const secondaryTextElement = parent?.querySelector('.secondary-text');
      const secondaryText = secondaryTextElement?.textContent || '';
      const secondaryLangAttribute = secondaryTextElement?.getAttribute('lang');
      const primaryLangAttribute = primaryTextElement?.getAttribute('lang');

      if (
         secondaryText.trim() !== '' &&
         primaryLangAttribute &&
         secondaryLangAttribute &&
         primaryTextElement
      ) {
         const translatedText = await TranslationService.translate(
            secondaryText,
            secondaryLangAttribute as Language,
            primaryLangAttribute as Language,
         );
         primaryTextElement.textContent = translatedText;
      }
   };

   // Transate primary to secondary language on double click
   editor.on('dblclick', async (event) => {
      const target = event.target as HTMLElement;
      if (!target) {
         return;
      }

      if (target.matches('.example-body > .secondary-text') && target.textContent?.trim() === '') {
         transatePrimaryToSecondaryText(target);
      } else if (
         target.matches('.example-body > .primary-text') &&
         target.textContent?.trim() === ''
      ) {
         translateSecondaryToPrimaryText(target);
      }
   });

   editor.ui.registry.addToggleMenuItem('example-layout-vertical', {
      text: 'Vertical',
      onAction: () => {
         setLayout(ExampleLayout.vertical);
      },
      onSetup: (api) => {
         api.setActive(layoutIsVerical());
      },
   });

   editor.ui.registry.addToggleMenuItem('example-layout-horizontal', {
      text: 'Horizontal',
      onAction: () => {
         setLayout(ExampleLayout.horizontal);
      },
      onSetup: (api) => {
         api.setActive(!layoutIsVerical());
      },
   });

   editor.ui.registry.addMenuItem('add-example', {
      text: 'Add Example',
      icon: 'duplicate',
      onAction: () => addExample(),
   });

   editor.ui.registry.addMenuItem('delete-example', {
      text: 'Delete Example',
      icon: 'remove',
      onAction: () => deleteExample(),
   });

   editor.ui.registry.addMenuItem('generate-examples', {
      text: 'Generate Examples',
      icon: 'code-sample',
      onAction: () => generateExamples(),
      onSetup: (api) => {
         api.setEnabled(canGenerateAdditionalExamples());
         return () => undefined;
      },
   });

   editor.ui.registry.addNestedMenuItem('examples-component-layout', {
      text: 'Layout',
      icon: 'orientation',
      getSubmenuItems: () => 'example-layout-vertical example-layout-horizontal',
   });

   editor.ui.registry.addMenuItem('delete-examples-component', {
      text: 'Delete Component',
      icon: 'remove',
      onAction: () => deleteComponent(),
   });

   editor.ui.registry.addContextMenu('examples', {
      update: (element) => {
         const withinExamples = !!getExamplesContainer(element);
         const withinImage = element.tagName === 'IMG';
         if (!withinExamples || withinImage) {
            return '';
         }
         const withinExample = !!getExample(element);
         return `add-example ${
            withinExample ? 'delete-example' : ''
         } | generate-examples examples-component-layout delete-examples-component`;
      },
   });

   return {
      getMetadata: () => ({
         name: 'Examples',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
