import * as React from 'react';

import { createAudioElement } from '@helpers/Audio';
import partOfSpeechAbbreviation from '@helpers/PartOfSpeechAbbreviation';
import IconFemininePlural from '@icons/general/icon-session-feminine-plural.svg';
import IconFeminineSingular from '@icons/general/icon-session-feminine-singular.svg';
import IconMasculinePlural from '@icons/general/icon-session-masculine-plural.svg';
import IconMasculineSingular from '@icons/general/icon-session-masculine-singular.svg';
import IconSessionVolume from '@icons/general/icon-session-volume.svg';
import IconInterfaceInformation from '@icons/nova-solid/22-Interface-Feedback/interface-information.svg';
import { Maybe } from '@models/Core';
import IVocabTerm, { IVocabTermProgress } from '@models/IVocabTerm';
import Language from '@models/Language';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import VocabTermCardProgress from './VocabTermCardProgress';

interface VocabTermCardProps {
   hasImages: boolean;
   index: number;
   language: Language;
   progress?: IVocabTermProgress;
   term: IVocabTerm;
}

const VocabTermCard: React.FC<VocabTermCardProps> = ({
   hasImages,
   index,
   language,
   progress,
   term: { audioUrl, definition, gender, number: termNumber, partOfSpeech, term, imageUrl, notes },
}) => {
   const [audio, setAudio] = React.useState<Maybe<HTMLAudioElement>>(null);
   const canPlayThroughSubscribed = React.useRef<boolean>(false);

   React.useEffect(() => {
      if (audio && !canPlayThroughSubscribed.current) {
         audio.addEventListener('canplaythrough', playAudio);
         canPlayThroughSubscribed.current = true;
      }
      return () => {
         if (audio) {
            audio.removeEventListener('canplaythrough', playAudio);
            audio.pause(); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio#memory_usage_and_management
         }
      };
   }, [audio]);

   const playOrInitializeAudio = (): void => {
      if (audio) {
         playAudio();
      } else if (audioUrl) {
         setAudio(createAudioElement(audioUrl, 'Init vocab term card'));
      }
   };

   const playAudio = (): void => {
      const audioPlayPromise = audio?.play();
      if (!audioPlayPromise) {
         console.error('"audio.play()" did not return a promise');
      }
      if (audioPlayPromise !== undefined) {
         audioPlayPromise.catch((error) => {
            console.error('Failed to play audio (VocabTermCard.tsx)', {
               err: `${error?.name}: ${error?.message}`,
               currentSrc: audio?.currentSrc,
            });
         });
      } else {
         console.error('"audio.play()" did not return a promise');
      }
   };

   const renderGenderNumber = (): React.ReactNode => {
      if (gender === 'masculine') {
         if (termNumber === 'singular') {
            return <IconMasculineSingular />;
         } else if (termNumber === 'plural') {
            return <IconMasculinePlural />;
         }
      } else if (gender === 'feminine') {
         if (termNumber === 'singular') {
            return <IconFeminineSingular />;
         } else if (termNumber === 'plural') {
            return <IconFemininePlural />;
         }
      }
      return null;
   };

   const renderDefinition = (): React.ReactNode => {
      if (term) {
         return <div className='definition'>{definition}</div>;
      } else {
         return <div className='title'>{definition}</div>;
      }
   };

   const renderProgress = () => {
      if (!progress) {
         return null;
      }
      const { level, halfLife, attempts } = progress;
      return (
         <div className='vocab-set-term-bottom-section'>
            <VocabTermCardProgress attempts={attempts} level={level} halfLife={halfLife} />
         </div>
      );
   };

   return (
      <div
         className={classnames('col-xs-12 col-sm-6', language === 'asl' ? 'col-md-3' : 'col-md-4')}
      >
         <div className='card no-padding margin-bottom-m'>
            <div
               className={classnames('vocab-set-term-top-section', {
                  'has-images': hasImages,
               })}
            >
               <div className='vocab-set-row-number-wrap'>
                  <div className='vocab-set-row-number'>{index + 1}</div>
               </div>
               {imageUrl && language !== 'asl' && (
                  <div className='term-image-wrapper-inline'>
                     <img alt={term} src={imageUrl} />
                  </div>
               )}
               <div className='vocab-set-term' data-set-lang={language}>
                  {imageUrl && language === 'asl' && (
                     <div className='term-image-wrapper-large'>
                        <img alt={term} src={imageUrl} />
                     </div>
                  )}
                  {term && (
                     <div className='title' lang={language}>
                        {term}
                     </div>
                  )}
                  {renderDefinition()}
               </div>
               <div className='vocab-set-term-info'>
                  {!!notes && (
                     <Tippy content={notes}>
                        <span className='term-notes'>
                           <IconInterfaceInformation />
                        </span>
                     </Tippy>
                  )}
                  {partOfSpeech && <label>{partOfSpeechAbbreviation(partOfSpeech)}</label>}
                  {(!!gender || !!termNumber) && (
                     <div className='gender-number-wrapper'>{renderGenderNumber()}</div>
                  )}
                  {audioUrl && (
                     <IconSessionVolume className='pointer' onClick={playOrInitializeAudio} />
                  )}
               </div>
            </div>
            {renderProgress()}
         </div>
      </div>
   );
};

export default React.memo(VocabTermCard);
