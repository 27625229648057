import { Maybe } from '@models/Core';
import tinymce from 'tinymce';

tinymce.PluginManager.add('bannerimages', (editor) => {
   editor.on('init', () => {
      const isEditable = (node: Maybe<Node>) => editor.dom.isEditable(node);
      const isInEditableContext = (el: Element) => isEditable(el.parentElement);

      const isImage = (node: Element) =>
         (node.nodeName === 'IMG' ||
            (node.nodeName === 'FIGURE' && /image/i.test(node.className))) &&
         isInEditableContext(node);

      const isBanner = (node: Element) => isImage(node) && node.classList.contains('banner');

      // New predicate function
      const newIsImage = (elem: Element) =>
         isImage(elem) && !elem.parentElement?.className.includes('example-image');

      editor.ui.registry.addToggleButton('repositionbanner', {
         icon: 'drag',
         tooltip: 'Reposition',
         onAction: (api) => {
            const element = editor.selection.getNode();
            if (!element) return;

            element.classList.add('reposition');
            api.setActive(true);
            editor.fire('change');

            let startX = 0;
            let startY = 0;

            const onDragStart = (e: MouseEvent) => {
               startX = e.clientX;
               startY = e.clientY;
               document.addEventListener('mousemove', onDrag);
               document.addEventListener('mouseup', onDragEnd);
            };

            const onDrag = (e: MouseEvent) => {
               e.preventDefault();

               if (!startX || !startY) return;

               const dy = startY - e.clientY;
               const containerHeight = element.parentElement?.offsetHeight;

               if (!containerHeight || containerHeight <= 0) return;

               const style = window.getComputedStyle(element);
               const currentPosition = style.getPropertyValue('object-position').split(' ');
               let yPercent = parseFloat(currentPosition[1]);

               const percentChange = (dy / containerHeight) * 100;
               yPercent += percentChange;
               yPercent = Math.max(0, Math.min(100, yPercent));

               element.style.objectPosition = `center ${yPercent.toFixed(2)}%`;
               startY = e.clientY;
            };

            const onDragEnd = () => {
               document.removeEventListener('mousemove', onDrag);
               document.removeEventListener('mouseup', onDragEnd);
               editor.fire('change');
            };

            const tearDownDrag = () => {
               element.removeEventListener('mousedown', onDragStart);
               document.removeEventListener('mousemove', onDrag);
               document.removeEventListener('mouseup', onDragEnd);
               element.classList.remove('reposition');
               // Remove NodeChange event listener
               editor.off('NodeChange', handleNodeChange);
               api.setActive(false);
            };

            // Define the NodeChange event handler
            const handleNodeChange = () => {
               if (editor.selection.getNode() !== element) {
                  tearDownDrag();
               }
            };

            element.addEventListener('mousedown', onDragStart);

            // Listen for changes in the editor's selection
            editor.on('NodeChange', handleNodeChange);
         },
      });

      editor.ui.registry.addContextToolbar('imageselection-banner', {
         predicate: (i) => newIsImage(i) && isBanner(i),
         items: 'alignleft aligncenter alignright repositionbanner',
         position: 'node',
      });
   });

   editor.ui.registry.addMenuItem('insert-image', {
      icon: 'image',
      text: 'Image',
      onAction(api) {
         if (api.isEnabled()) {
            editor.execCommand('mceImage');
         }
      },
   });

   return {
      getMetadata: () => ({
         name: 'Banner Images',
         url: 'https://example.com/docs/customplugin',
      }),
   };
});
