// @ts-strict-ignore
import * as _ from 'lodash';
import * as React from 'react';

import { Maybe } from '@models/Core';

import {
   formatNumberToMaybeString,
   getFloatValueFromString,
   getIntegerValueFromString,
} from './Helpers';

export interface INumberInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'className'> {
   /** Class Name */
   className?: string | ((value: number) => string);
   /** Values should be integers instead of decimals i.e 1 instead of 1.0 */
   integer?: boolean;
   /** Always show + for positive numbers i.e +5 vs 5 */
   forceSign?: boolean;
   /** Only set the value when the component does not have focus.
    * Used in some advanced formatting situations, like "ActivityGraderSidebar"
    * FIXME: This is confusing and shouldn't be a part of a standard component like this.
    */
   allowSetValueWhenFocused?: boolean;
   /** Value */
   value: Maybe<number>;
   /** Callback function for value changes */
   onValueChange?(value: Maybe<number>): void;
}

export const NumberInput: React.FC<INumberInputProps> = React.forwardRef(
   (
      {
         forceSign = false,
         integer = false,
         placeholder = '0',
         allowSetValueWhenFocused = false,
         value: propsValue,
         className: propsClassName,
         min,
         max,
         type: propsType,
         onValueChange,
         onFocus,
         onBlur,
         ...props
      },
      ref,
   ) => {
      const formattedValue = formatNumberToMaybeString(propsValue, forceSign, !integer);
      const [className, setClassName] = React.useState<string>('');
      const [isFocused, setIsFocused] = React.useState<boolean>(false);
      const [valueString, setValueString] = React.useState<string>(formattedValue);

      React.useEffect(() => {
         if (allowSetValueWhenFocused || !isFocused) {
            setValueString(formatNumberToMaybeString(propsValue, forceSign, !integer));
         }
         if (_.isFunction(propsClassName)) {
            setClassName(propsClassName(propsValue));
         } else if (_.isString(propsClassName)) {
            setClassName(propsClassName);
         }
      }, [propsValue, isFocused]);

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
         setValueString(event.target.value);
         if (integer) {
            onValueChange?.(getIntegerValueFromString(event.target.value));
         } else {
            onValueChange?.(getFloatValueFromString(event.target.value));
         }
      };

      const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
         setIsFocused(false);
         onBlur?.(event);
      };

      const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
         setIsFocused(true);
         onFocus?.(event);
      };

      const inputProps = _.omit(props, ['onBlur', 'onFocus', 'onChange', 'pattern', 'type']);

      return (
         <input
            className={className}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            pattern={integer ? '[0-9]*' : '.*'}
            placeholder={placeholder}
            ref={ref}
            type='text'
            value={valueString}
            {...inputProps}
         />
      );
   },
);

NumberInput.displayName = 'NumberInput';
