// @ts-strict-ignore
export interface TimerSettings {
   /** Duration of the timer in seconds */
   duration: number;
   /** Interval to execute the callback function */
   interval?: number;
   /** Callback on timer update */
   callback(seconds: number): void;
   /** Completion function */
   onCompletion?(): void;
}

class Timer {
   duration: number;
   interval?: number;
   seconds: number;
   timerInterval: NodeJS.Timeout = null;
   callback: (seconds: number) => void;
   onCompletion?: () => void;

   constructor(settings?: TimerSettings) {
      if (settings) {
         const { duration, interval = 1000, callback, onCompletion } = settings;
         this.duration = duration;
         this.seconds = duration;
         this.interval = interval;
         this.callback = callback;
         this.onCompletion = onCompletion;
      }
   }

   get timeSpent(): number {
      return Math.round((this.duration - this.seconds) * 100) / 100;
   }

   handleCompletion(): void {
      this.teardown();
      this.onCompletion?.();
   }

   reset(settings: Partial<TimerSettings>): void {
      if (this.timerInterval) {
         clearInterval(this.timerInterval);
         this.timerInterval = null;
      }

      if (settings) {
         const { duration, interval, callback, onCompletion } = settings;
         if (duration) {
            this.duration = duration;
         }
         if (interval) {
            this.interval = interval;
         }
         if (callback) {
            this.callback = callback;
         }
         if (onCompletion) {
            this.onCompletion = onCompletion;
         }
      }
      this.seconds = this.duration;
   }

   start(): NodeJS.Timeout {
      this.callback(this.seconds);
      if (this.timerInterval === null) {
         this.timerInterval = setInterval(() => {
            this.seconds = Math.max(this.seconds - this.interval / 1000, 0);
            this.callback(this.seconds);
            if (this.seconds === 0) {
               this.handleCompletion();
            }
         }, this.interval);
         return this.timerInterval;
      } else {
         throw new Error(`Cannot set new timeout. ${this.timerInterval} is still set`);
      }
   }

   stop(): NodeJS.Timeout {
      return this.teardown();
   }

   teardown(): NodeJS.Timeout {
      if (this.timerInterval === null) {
         console.warn(`Timer interval ${this.timerInterval} is null.  Cannot tear down.`);
         return undefined;
      }

      const clearedTimeout = this.timerInterval;
      clearInterval(this.timerInterval);
      this.timerInterval = null;
      return clearedTimeout;
   }
}

export default Timer;
