/* eslint-disable @typescript-eslint/no-explicit-any */
import * as _ from 'lodash';

const indexDeepHelper = (o: any): any => {
   for (const prop in o) {
      if (_.isArray(o[prop]) && _.every(o[prop], _.isObject)) {
         o[prop].forEach((i: any, j: any) => {
            o[prop][j].index = j;
            indexDeepHelper(o[prop][j]);
         });
      } else if (_.isObject(o[prop])) {
         indexDeepHelper(o[prop]);
      }
   }
};

/**
 * Recursively adds an index to each item in an array of objects.
 */
const indexDeep = (obj: any): any => {
   if (_.isArray(obj) && _.every(obj, _.isObject)) {
      obj.forEach((i, j) => {
         obj[j].index = j;
         indexDeepHelper(obj);
      });
   } else {
      indexDeepHelper(obj);
   }
};

export default indexDeep;
