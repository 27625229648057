import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import { ActivityBuilderMode, MarkTokensPrompt as MarkTokensPromptType } from '@models/Activity';
import { Maybe } from '@models/Core';
import classnames from 'classnames';
import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';
import { MarkTokensMode } from '@components/Core/Editor/plugins/MarkTokens';
import Toggle from '@components/Core/Toggle';
import { ActivityContext } from '@components/Activity/Builder/ActivityBuilder';

interface MarkTokensPromptProps {
   content: string;
   rtl: boolean;
   onUpdate(
      update: Partial<MarkTokensPromptType<ActivityBuilderMode>>,
      callback?: () => void,
   ): void;
}

const MarkTokensPrompt: React.FC<MarkTokensPromptProps> = ({ content, rtl, onUpdate }) => {
   const { language } = React.useContext<ActivityContext>(ActivityContext);

   const editor = React.useRef<Maybe<TinyMCEEditor>>(null);

   const [uninitialized, setUninitialized] = React.useState<boolean>(true);
   const [mode, setMode] = React.useState<MarkTokensMode>(MarkTokensMode.text);

   const editorLoaded = (editorRef: TinyMCEEditor): void => {
      editor.current = editorRef;
      editor.current?.plugins.marktokens?.setMode(mode);
   };

   const handleModeChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
         const newMode = event.target.value as MarkTokensMode;
         setMode(newMode);
         editor.current?.plugins.marktokens.setMode(newMode);
      },
      [editor.current, setMode, mode],
   );

   const tokenizeText = React.useCallback(() => {
      editor.current?.execCommand('tokenizeText');
   }, [editor.current]);

   const handleFocus = (): void => {
      if (uninitialized) {
         setUninitialized(false);
      }
   };

   const handleInit = () => {
      editor.current?.plugins.marktokens?.setMode(mode);
   };

   const handleChange = (value: string): void => {
      onUpdate({ content: value });
   };

   const editorClassName = React.useMemo(
      () => classnames('no-padding', 'mark-tokens-form', { uninitialized }),
      [uninitialized],
   );
   const footer = React.useMemo(
      () => (
         <div className='mark-tokens-options space-between'>
            <div className='mode-toggle extended'>
               <Toggle
                  leftLabel='Edit Text'
                  leftValue={MarkTokensMode.text}
                  centerLabel='Edit Tokens'
                  centerValue={MarkTokensMode.tokens}
                  rightLabel='Edit Answers'
                  rightValue={MarkTokensMode.answers}
                  value={mode}
                  onChange={handleModeChange}
               />
            </div>
            {mode === MarkTokensMode.tokens && (
               <Button line color='green' onClick={tokenizeText}>
                  Tokenize Text
               </Button>
            )}
         </div>
      ),
      [handleModeChange, tokenizeText, mode],
   );

   return (
      <Editor
         className={editorClassName}
         editorRef={editorLoaded}
         extraPlugins={['marktokens']}
         config={{ contextmenu: 'tooltip marktokens' }}
         footer={footer}
         language={language}
         onChange={handleChange}
         onFocus={handleFocus}
         onInit={handleInit}
         rtl={rtl}
         toolbarAppearance={ToolbarAppearance.fixed}
         value={content}
      />
   );
};

export default MarkTokensPrompt;
