import * as React from 'react';

import { parseStringToNumber } from '@helpers/InputParsingHelpers';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import { resetSubmissions } from '@services/ActivityService';

import { AppStateContext } from '../../../AppState';
import DateTimePicker from '@components/Core/DateTimePicker';
import ModalDialog from '@components/Core/ModalDialog';
import InfoTooltip from '@components/InfoTooltip';

interface IExtensionModalProps {
   endDate: Date;
   heading?: string;
   submissionIds?: readonly number[];
   maxAttempts?: number;
   closeExtensionModal(): void;
   extendAssignment(endDate: Date): void;
}

const ExtensionModal: React.FC<IExtensionModalProps> = ({
   endDate,
   heading,
   maxAttempts,
   submissionIds = [],
   closeExtensionModal,
   extendAssignment,
}) => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const [attempts, setAttempts] = React.useState<string>('');
   const [shouldResetStartTime, setShouldResetStartTime] = React.useState<boolean>(false);
   const [shouldClearEvaluations, setShouldClearEvaluations] = React.useState<boolean>(false);
   const [newEndDate, setNewEndDate] = React.useState<Maybe<Date>>(null);
   const [shouldEnableSubmit, setShouldEnableSubmit] = React.useState<boolean>(false);
   const showResetSubmissionFields = submissionIds?.length > 0;

   const shouldUpdateSubmissions = (): boolean => {
      const newAttempts = parseStringToNumber(attempts);
      return newAttempts !== null || shouldResetStartTime || shouldClearEvaluations;
   };

   const handleSubmit = (): void => {
      const shouldUpdateEndDate = !!newEndDate && newEndDate?.getTime() !== endDate.getTime();

      if (shouldUpdateEndDate) {
         extendAssignment(newEndDate);
      }

      if (submissionIds?.length > 0) {
         if (shouldUpdateSubmissions()) {
            const newAttempts = parseStringToNumber(attempts);
            resetSubmissions(
               submissionIds,
               shouldResetStartTime,
               shouldClearEvaluations,
               newAttempts,
            ).catch(() => {
               dispatchToast({
                  title: 'Error',
                  message: 'Failed to update submissions',
                  appearance: Appearance.danger,
               });
            });
         }
      }
      closeExtensionModal();
   };

   const handleAttemptInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newAttempts = parseStringToNumber(event.target.value) ?? null;
      const value = event.target.value;
      if (maxAttempts === undefined) {
         return;
      }
      const shouldUpdateAttempts =
         value === '' || (newAttempts !== null && newAttempts >= 0 && newAttempts <= maxAttempts);
      if (shouldUpdateAttempts) {
         setAttempts(value);
      }

      if (value && value.includes('-')) {
         setAttempts('');
      }
   };

   const handleResetStartTimeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setShouldResetStartTime(checked);
   };

   const handleClearEvaluations = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      setShouldClearEvaluations(checked);
   };

   React.useEffect(() => {
      const shouldUpdateEndDate = !!newEndDate;
      const enableSubmit = shouldUpdateSubmissions() || shouldUpdateEndDate;
      setShouldEnableSubmit(enableSubmit);
   }, [attempts, shouldResetStartTime, newEndDate, shouldClearEvaluations]);

   return (
      <ModalDialog
         appearance={Appearance.primary}
         bodyClassName='extend-assignment-modal'
         heading={heading ?? 'Extend Assignment'}
         onClose={closeExtensionModal}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[
            {
               text: 'Submit',
               onClick: handleSubmit,
               disabled: !shouldEnableSubmit,
            },
            { text: 'Cancel', onClick: closeExtensionModal },
         ]}
         width='small'
      >
         <div className='row'>
            <div className={showResetSubmissionFields ? 'col-xs-7' : 'col-xs-12'}>
               <label className='field-title' htmlFor='attempts'>
                  New End Date
                  <InfoTooltip>Override an activity end date for a student or section.</InfoTooltip>
               </label>
               <DateTimePicker onChange={setNewEndDate} value={newEndDate} />
            </div>
            {showResetSubmissionFields && (
               <div className='col-xs-5'>
                  <label className='field-title' htmlFor='attempts'>
                     Attempts Used
                     <InfoTooltip>
                        Allow students to submit again by updating their attempts. For example, if a
                        student submitted accidentally and the activity allows for 1 attempt then
                        you would put 0/1 to allow them to submit again.
                     </InfoTooltip>
                  </label>
                  <div>
                     <input
                        autoFocus
                        className='attempts'
                        max={maxAttempts}
                        min={0}
                        name='attempts'
                        onChange={handleAttemptInputChange}
                        type='number'
                        value={attempts}
                     />
                     <span>/ {maxAttempts}</span>
                  </div>
               </div>
            )}
         </div>
         {showResetSubmissionFields && (
            <div className='row'>
               <div className='col-xs-6'>
                  <input
                     checked={shouldResetStartTime}
                     name='reset-start-time'
                     onChange={handleResetStartTimeCheckbox}
                     type='checkbox'
                  />
                  <label className='field-title' htmlFor='reset-start-time'>
                     Reset Start Time
                     <InfoTooltip>
                        Reset the start time for the selected students. They will have the full time
                        the activity allows. If the student submitted their work you may also need
                        to reset their last attempt.
                     </InfoTooltip>
                  </label>
               </div>
               <div className='col-xs-6'>
                  <input
                     checked={shouldClearEvaluations}
                     name='clear-evaluations'
                     onChange={handleClearEvaluations}
                     type='checkbox'
                  />
                  <label className='field-title' htmlFor='clear-evaluations'>
                     Clear Evaluations
                     <InfoTooltip>
                        Clear the Evaluations for the submissions. This will remove all grades and
                        hide whether they correctly or incorrectly answered.
                     </InfoTooltip>
                  </label>
               </div>
            </div>
         )}
      </ModalDialog>
   );
};

export default React.memo(ExtensionModal);
