import * as React from 'react';

import { Editor } from '@components/Core/Editor';
import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { parseHtml } from '@helpers/ParseHtml';
import IconDataSyncing from '@icons/nova-line/23-Data-Transfer/data-syncing.svg';
import IconFileEdit2 from '@icons/nova-line/85-Files-Basic/file-edit-2.svg';
import { TextResponse } from '@models/Activity';
import { Maybe } from '@models/Core';
import HttpService from '@services/HttpService';
import classnames from 'classnames';
import pluralize from 'pluralize';
import { Editor as TinyMCEEditor } from 'tinymce';

import Button from '@components/Common/Button';

interface TextPromptProps {
   isActive: boolean;
   response: TextResponse;
   responseId: number;
   showWordCount: boolean;
   setActive(): void;
   updateResponse(update: Partial<TextResponse>): Promise<void>;
}

interface TextPromptState {
   isEditing: boolean;
   isEditorReady: boolean;
   origEdit: string;
   showOrig: boolean;
}

const TextPrompt: React.FC<TextPromptProps> = ({
   response,
   responseId,
   isActive,
   showWordCount,
   setActive,
   updateResponse,
}) => {
   const [state, setState] = React.useState<TextPromptState>({
      origEdit: '',
      isEditing: false,
      isEditorReady: false,
      showOrig: !(response.edit && response.edit.content),
   });

   const editorRef = React.useRef<Maybe<TinyMCEEditor>>(null);

   const enableEditing = (): void => {
      if (!response.edit) {
         updateResponse({ edit: { content: '', modifiedOn: null } });
      }
      setState((prevState) => ({
         ...prevState,
         origEdit: '',
         isEditing: true,
      }));
   };

   const getWordCount = (): number => {
      const text = response.text?.trim() ?? '';
      return text.length > 0 ? text.split(/\s+/).length : 0;
   };

   const handleEditChange = (updatedEdit: string): void => {
      if (!response.edit) {
         return;
      }
      updateResponse({ edit: { ...response.edit, content: updatedEdit } });
   };

   const setupEditor = (editor: TinyMCEEditor): void => {
      editorRef.current = editor;
      editor.on('init', handleEditorReady);
      editor.on('focus', setActive);
   };

   const handleCancelEditing = (): void => {
      if (!response.edit) {
         return;
      }
      updateResponse({ edit: { ...response.edit, content: state.origEdit } });
      setState((prevState) => ({
         ...prevState,
         isEditorReady: false,
         isEditing: false,
      }));
   };

   const handleEditorReady = ({ target: editor }: { target: TinyMCEEditor }): void => {
      setState((prevState) => ({
         ...prevState,
         origEdit: response.edit?.content ?? '',
         isEditorReady: true,
      }));
      if (response.text && response.edit && !response.edit.content) {
         updateResponse({ edit: { ...response.edit, content: response.text } });
         editor.setContent(response.text);
      }
      editor.focus();
      editor.selection.select(editor.getBody(), true);
      editor.selection.collapse(false);
   };

   const handleSaveEdits = (): void => {
      const url = `/api/activities/responses/${responseId}/edit`;
      HttpService.putWithAuthToken<{ msg: string }>(url, snakeCaseKeys(response.edit)).then(() => {
         setState((prevState) => ({
            ...prevState,
            isEditing: false,
            showOrig: !(response.edit && response.edit.content),
         }));
      });
   };

   // Todo: pass styles to the editor here? we may need to create some sort of current selected style?
   const renderText = (): React.ReactNode => {
      if (response.text && response.edit) {
         if (state.isEditing) {
            return (
               <div
                  className={classnames({
                     'editor-parent-hide-toolbar': !isActive,
                  })}
               >
                  <Editor
                     editorRef={setupEditor}
                     onChange={handleEditChange}
                     value={response.edit.content}
                  />
               </div>
            );
         }
         return (
            <div className='text-prompt-display white-space-pre-wrap'>
               {state.showOrig ? response.text : parseHtml(response.edit.content)}
            </div>
         );
      }
      return (
         <p>
            <i>No Response</i>
         </p>
      );
   };

   const toggleShowOrig = (): void => {
      setState((prevState) => ({
         ...prevState,
         showOrig: !prevState.showOrig,
      }));
   };

   const wordCount = getWordCount();

   return (
      <>
         <div className='row'>
            <div className='col-xs-12'>{renderText()}</div>
         </div>
         {showWordCount && isActive && state.showOrig && !state.isEditing && (
            <div className='word-count'>{`${wordCount} ${pluralize('Word', wordCount)}`}</div>
         )}
         {response.text && (
            <div className='row'>
               <div className='col-xs-12'>
                  <div className='text-prompt-edit-btn-container'>
                     {isActive &&
                        (state.isEditing ? (
                           state.isEditorReady && (
                              <>
                                 <Button line onClick={handleCancelEditing}>
                                    Cancel
                                 </Button>
                                 <Button onClick={handleSaveEdits}>Save</Button>
                              </>
                           )
                        ) : (
                           <>
                              {response.edit?.content && (
                                 <Button
                                    subtle
                                    icon={<IconDataSyncing aria-hidden />}
                                    onClick={toggleShowOrig}
                                 >
                                    {`Show ${state.showOrig ? 'Edits' : 'Original'}`}
                                 </Button>
                              )}
                              <Button
                                 subtle
                                 icon={<IconFileEdit2 aria-hidden />}
                                 onClick={enableEditing}
                              >
                                 Edit
                              </Button>
                           </>
                        ))}
                  </div>
               </div>
            </div>
         )}
      </>
   );
};

export default TextPrompt;
