// @ts-strict-ignore
import * as React from 'react';

import IconVideoControlPause from '@icons/nova-solid/36-Videos/video-control-pause.svg';
import IconVideoControlPlay from '@icons/nova-solid/36-Videos/video-control-play.svg';

import { CircularProgressBar } from '@components/Core/ProgressBar';
import { TimerConsumer, TimerValueConsumer } from '@components/VocabSession/TimerWrapper';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';

interface TimerProps {
   onClick?(event: React.MouseEvent): void;
}

const Timer: React.FC<TimerProps> = ({ onClick }) => {
   const {
      card,
      state: { isPaused },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   return (
      <div className='session-timer' onClick={onClick}>
         <TimerConsumer>
            {({ timer }) => (
               <TimerValueConsumer>
                  {(seconds) => (
                     <CircularProgressBar
                        background
                        backgroundPadding={0}
                        color='yellow'
                        counterClockwise
                        percentage={(card ? seconds / timer.current.duration : 1) * 100}
                        styles={{
                           background: {
                              fill: '#FFF9EC',
                           },
                           trail: {
                              stroke: 'transparent',
                           },
                        }}
                     >
                        {isPaused ? (
                           <IconVideoControlPlay className='play-btn' />
                        ) : (
                           <IconVideoControlPause className='pause-btn' />
                        )}
                     </CircularProgressBar>
                  )}
               </TimerValueConsumer>
            )}
         </TimerConsumer>
      </div>
   );
};

export default React.memo(Timer);
