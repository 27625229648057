import * as React from 'react';

import Appearance from '@models/Appearance';

import { AppStateContext } from '../../../AppState';
import ModalDialog from '@components/Core/ModalDialog';

export enum WarningName {
   UNSUPPORTED_DEVICE = 'UNSUPPORTED_DEVICE',
}

const WarningModal: React.FC = () => {
   const { warningModal, setWarning } = React.useContext<AppStateContext>(AppStateContext);

   if (!warningModal?.show) {
      return null;
   }

   const { name } = warningModal;

   const clearWarning = (): void => setWarning({ show: false });

   const renderHeader = (): string | undefined => {
      if (name === WarningName.UNSUPPORTED_DEVICE) {
         return 'Unsupported Device';
      }
   };

   const renderWarningModal = (): React.ReactNode => {
      if (name === WarningName.UNSUPPORTED_DEVICE) {
         return (
            <p>
               Lingco doesn't fully support mobile devices or tablets yet. Please use a laptop or
               desktop, with the latest version of Chrome, Firefox or Safari for the best
               experience.
            </p>
         );
      }
      return null;
   };

   return (
      <ModalDialog
         appearance={Appearance.warning}
         heading={renderHeader()}
         animations={{ enter: 'animated bounceInDown' }}
         actions={[{ text: 'Dismiss', onClick: clearWarning }]}
      >
         {renderWarningModal()}
      </ModalDialog>
   );
};

export default WarningModal;
