import * as React from 'react';

import { getQueryParameterByName } from '@helpers/QueryParameter';
import { Maybe } from '@models/Core';
import { useLocation } from 'react-router-dom';

import Constants from '../../Constants';
import Link from '@components/Common/Link';
import DocumentTitle from '@components/DocumentTitle';

enum TokenFailureType {
   cleverMissingFields = 'clever_missing_fields',
   ltiInvalidConsumerKey = 'lti_invalid_consumer_key',
   ltiLoginGet = 'lti_login_get',
   ltiMissingConsumerKey = 'lti_missing_consumer_key',
   ltiMissingEmail = 'lti_missing_email',
   ltiMissingFirstName = 'lti_missing_first_name',
   ltiMissingLastName = 'lti_missing_last_name',
   ltiOAuthError = 'lti_oauth_error',
   ltiTokenExpired = 'lti_token_expired',
   ltiTokenUsed = 'lti_token_used',
   tokenExpired = 'token_expired',
   tokenInvalid = 'token_invalid',
   tokenMissing = 'token_missing',
}

const TokenLoginFailure: React.FC = () => {
   const [error, setError] = React.useState<Maybe<TokenFailureType>>(null);
   const location = useLocation();

   React.useEffect(() => {
      const tokenError = getQueryParameterByName(location, 'error') as TokenFailureType;
      setError(tokenError);
   }, []);

   const {
      siteLinks: { helpDocs },
   } = Constants;

   const ltiConfigError =
      error &&
      [
         TokenFailureType.ltiInvalidConsumerKey,
         TokenFailureType.ltiMissingConsumerKey,
         TokenFailureType.ltiMissingEmail,
         TokenFailureType.ltiMissingFirstName,
         TokenFailureType.ltiMissingLastName,
      ].includes(error);

   const tokenExpiredError =
      error && [TokenFailureType.ltiTokenExpired, TokenFailureType.tokenExpired].includes(error);
   const tokenInvalidError =
      error && [TokenFailureType.tokenInvalid, TokenFailureType.tokenMissing].includes(error);
   const ltiLoginGetError = error === TokenFailureType.ltiLoginGet;
   const ltiOAuthError = error === TokenFailureType.ltiOAuthError;
   const ltiTokenUsed = error === TokenFailureType.ltiTokenUsed;
   const cleverMissingFields = error === TokenFailureType.cleverMissingFields;

   return (
      <div className='focused-form-container'>
         <div className='container-fluid'>
            <div className='row'>
               <div className='col-xs-12 col-sm-8 col-md-6 center'>
                  <div className='card-content-onboarding card padding-bottom-m'>
                     <div className='onboarding-title border-bottom text-center'>
                        <DocumentTitle>Login Unsuccessful</DocumentTitle>
                        <h2 aria-live='polite' className='uppercase no-margin'>
                           Login Unsuccessful
                        </h2>
                     </div>
                     {ltiConfigError && (
                        <p>
                           If you are an administrator, please check your configuration and ensure
                           that you are passing both the <strong>name</strong> and{' '}
                           <strong>email</strong> with the correct keys. For help, please visit our
                           integration documents in our{' '}
                           <Link external to={helpDocs}>
                              help center
                           </Link>
                           .
                        </p>
                     )}
                     {tokenExpiredError && (
                        <p>
                           Your token is <strong>expired</strong>. Please try logging in again.
                        </p>
                     )}
                     {tokenInvalidError && (
                        <p>
                           Your token is <strong>invalid</strong>. Please try logging in again.
                        </p>
                     )}
                     {ltiLoginGetError && (
                        <p>
                           This link must be launched as an <strong>External Tool</strong>. For
                           help, please visit our integration documents in our{' '}
                           <Link external to={helpDocs}>
                              help center
                           </Link>
                           .
                        </p>
                     )}
                     {ltiTokenUsed && (
                        <p>
                           There was an error with the authentication request because the token has
                           already been used. Please relaunch the tool.
                        </p>
                     )}
                     {ltiOAuthError && (
                        <p>
                           There was an error with the authentication request. Please verify that
                           you are passing the proper consumer key and consumer secret. For help,
                           please visit our integration documents in our{' '}
                           <Link external to={helpDocs}>
                              help center
                           </Link>
                           .
                        </p>
                     )}
                     {cleverMissingFields && (
                        <p>
                           If you are an administrator, please check your configuration and ensure
                           that you are passing both the <strong>name</strong> and{' '}
                           <strong>email</strong>. For help, please visit our integration documents
                           in our{' '}
                           <Link external to={helpDocs}>
                              help center
                           </Link>
                           .
                        </p>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default TokenLoginFailure;
