import Appearance from '@models/Appearance';
import { ContextInfo } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import { BasicCourseProfile } from '@models/Course';
import { Features } from '@models/Feature';
import CourseService from '@services/CourseService';
import UserService from '@services/UserService';
import pluralize from 'pluralize';
import { Location, NavigateFunction } from 'react-router-dom';

import { AppStateContext } from '../../AppState';
import Constants from '../../Constants';
import { CommandName } from './CommandController';
import { Command, CommandCategory } from './Models';

const {
   routes: {
      activities: { viewAllSubmissions },
      admin: { instructorInvitations, styleSheets, bookstoreCodeGenerator },
      nationalExamAdmin,
      auth: { logout },
      content: {
         bulkVideoUpload,
         newActivity,
         newLesson,
         newVocabSet,
         editActivity,
         editLesson,
         interviewQuestions,
         previewActivity,
         speakers,
         viewLesson,
         viewVocabSet,
         viewVideoMap,
      },
      contentLibrary: { root: contentLibraryRoot, library },
      courses,
      courses: { viewCourseEnrollments, viewCourses },
      licenses: { viewLicenses },
      proficiencyTracking: { canDoStatements },
      schools: { viewSchools, createSchool },
      settings,
      users: { viewUsers },
   },
} = Constants;

const makeDashboardLink = (courseId: number): string =>
   courses.dashboard.replace(':courseId', courseId.toString());
const makeGradebookLink = (courseId: number): string =>
   courses.gradebook.replace(':courseId', courseId.toString());
const makeModulesLink = (courseId: number): string =>
   courses.modules.replace(':courseId', courseId.toString());
const makeRosterLink = (courseId: number): string =>
   courses.roster.replace(':courseId', courseId.toString());

const makeQueryCommands = (
   user: Maybe<{ id: number; firstName: string; lastName: string }>,
   course: Maybe<{ id: number; name: string }>,
   info: ContextInfo,
   navigate: NavigateFunction,
): readonly Command[] => {
   const commands = [];
   if (user && info.isAdmin && !info.isImpersonating) {
      commands.push({
         category: CommandCategory.admin,
         id: `impersonate_${user.id}`,
         overrides: ['impersonate_user'],
         scale: 1.5,
         autoClose: false,
         action: () => {
            UserService.impersonateUser(user.id).then(() => {
               navigate('/');
            });
         },
         title: `Impersonate ${user.firstName} ${user.lastName}`,
      });
   }
   if (course) {
      commands.push({
         category: CommandCategory.navigation,
         id: `switch_to_${course.id}`,
         scale: 1.0,
         link: makeDashboardLink(course.id),
         title: `Jump to ${course.name}`,
      });
   }
   return commands;
};

const makeCurrentCourseRoutes = (
   info: ContextInfo,
   currentCourses: readonly BasicCourseProfile[],
   location: Location,
): readonly Command[] =>
   // Filter out current course context
   currentCourses
      .filter((i) => i.id !== info?.courseId)
      .map((course) => ({
         category: CommandCategory.navigation,
         id: `switch_to_${course.id}`,
         link: (() => {
            if (location.pathname.includes('/modules')) {
               return makeModulesLink(course.id);
            } else if (location.pathname.includes('/gradebook')) {
               return makeGradebookLink(course.id);
            } else if (location.pathname.includes('/roster')) {
               return makeRosterLink(course.id);
            }
            return makeDashboardLink(course.id);
         })(),
         scale: 0.5,
         title: info?.courseId ? `Switch to ${course.name}` : `Jump to ${course.name}`,
      }));
const makeCourseNavigation = (
   info: ContextInfo,
   location: Location,
   appStateContext: AppStateContext,
): readonly Command[] => {
   const makeCourseLink = (link: string): Maybe<string> =>
      info.courseId ? link.replace(':courseId', info.courseId.toString()) : null;
   return [
      {
         category: CommandCategory.navigation,
         id: 'jump_to_modules',
         link: makeCourseLink(courses.modules),
         scale: 1.5,
         showIf: (i) => !!i.courseId,
         title: 'Jump to Modules',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_module',
         link: info.moduleId
            ? makeCourseLink(courses.viewModule)?.replace(':moduleId', info.moduleId.toString()) ??
              null
            : null,
         overrides: ['jump_to_modules'],
         scale: 1.5,
         showIf: (i) => !!i.courseId && !!i.moduleId,
         title: 'Jump to Module',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_submissions',
         link: info.moduleItemId
            ? viewAllSubmissions.replace(':moduleItemId', info.moduleItemId.toString())
            : null,
         showIf: (i) =>
            !!i.moduleItemId &&
            !!i.contentId &&
            location.pathname.includes(
               previewActivity.replace(':activityId', i.contentId.toString()),
            ),
         title: 'Jump to Submissions',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_gradebook',
         link: makeCourseLink(courses.gradebook),
         scale: 1.5,
         showIf: (i) => !!i.courseId,
         title: 'Jump to Gradebook',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_roster',
         link: makeCourseLink(courses.roster),
         scale: 1.4,
         showIf: (i) => !!i.courseId,
         title: 'Jump to Roster',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_dashboard',
         link: makeCourseLink(courses.dashboard),
         overrides: [`switch_to_${info.courseId}`],
         scale: 1.3,
         showIf: (i) => !!i.courseId,
         title: 'Jump to Dashboard',
      },
      {
         id: 'edit_course',
         link: makeCourseLink(courses.edit),
         scale: 0.3,
         showIf: (i) => !!i.courseId && (!!i.courseIsEditable || !!i.isAdmin),
         title: 'Edit Course',
      },
      {
         id: 'clone_course',
         link: makeCourseLink(courses.clone),
         scale: 0.5,
         showIf: (i) => !!i.courseId,
         title: 'Clone Course',
      },
      {
         id: 'archive_course',
         scale: 0.01,
         title: 'Archive Course',
         showIf: (i) =>
            !!i.courseId && i.courseArchived === false && (!!i.courseIsEditable || !!i.isAdmin),
         action: () => {
            if (info.courseId) {
               CourseService.patchCourse(info.courseId, { archived: true }).then((responseData) => {
                  const {
                     courses: { archived: archivedCourses, current: currentCourses },
                  } = responseData;
                  appStateContext.setCourses(currentCourses, archivedCourses);
               });
            }
         },
      },
      {
         id: 'unarchive_course',
         scale: 0.01,
         title: 'Unarchive Course',
         showIf: (i) => !!i.courseId && !!i.courseArchived,
         action: () => {
            if (info.courseId) {
               CourseService.patchCourse(info.courseId, { archived: false }).then(
                  (responseData) => {
                     const {
                        courses: { archived: archivedCourses, current: currentCourses },
                     } = responseData;
                     appStateContext.setCourses(currentCourses, archivedCourses);
                  },
               );
            }
         },
      },
   ];
};

const makeRootNavigation = (info: ContextInfo): readonly Command[] => {
   const rootCommands = [
      {
         id: 'join_course',
         link: courses.join,
         scale: 0.3,
         title: 'Join Course',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_user_settings',
         link: settings,
         scale: 0.2,
         title: 'Jump to User Settings',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_video_map',
         link: viewVideoMap,
         scale: 0.6,
         title: 'Jump to Video Map',
      },
      {
         id: 'logout',
         link: logout,
         scale: 0.1,
         title: 'Logout',
      },
   ];

   if (info.canManageCourses) {
      rootCommands.push({
         id: 'create_course',
         link: courses.create,
         scale: 0.4,
         title: 'Create Course',
      });
   }

   return rootCommands;
};

const makeContentNavigation = (info: ContextInfo): readonly Command[] => {
   const moduleItemIdRef = info.moduleItemId ? `?moduleItemId=${info.moduleItemId}` : '';
   if (info.canManageContentLibrary !== true) {
      return [];
   }

   const contentNavigationCommands: Command[] = [
      {
         id: 'edit_activity',
         link: info.contentId
            ? editActivity.replace(':activityId', info.contentId.toString()).concat(moduleItemIdRef)
            : null,
         scale: 1.5,
         showIf: (i) =>
            !!i.contentId &&
            i.contentType === ContentType.activity &&
            (!!i.isAdmin || !!i.canEditContent),
         title: 'Edit Activity',
      },
      {
         id: 'preview_activity',
         link: info.contentId
            ? previewActivity
                 .replace(':activityId', info.contentId.toString())
                 .concat(moduleItemIdRef)
            : null,
         scale: 1.5,
         showIf: (i) => !!i.contentId && i.contentType === ContentType.activity,
         title: 'Preview Activity',
      },
      {
         id: 'edit_lesson',
         link: info.contentId
            ? editLesson.replace(':lessonId', info.contentId.toString()).concat(moduleItemIdRef)
            : null,
         scale: 1.5,
         showIf: (i) =>
            !!i.contentId &&
            i.contentType === ContentType.lesson &&
            (!!i.isAdmin || !!i.canEditContent),
         title: 'Edit Lesson',
      },
      {
         id: 'view_lesson',
         link: info.contentId
            ? viewLesson.replace(':lessonId', info.contentId.toString()).concat(moduleItemIdRef)
            : null,
         scale: 1.5,
         showIf: (i) => !!i.contentId && i.contentType === ContentType.lesson,
         title: 'View Lesson',
      },
      {
         id: 'view_vocab_set',
         link: info.contentId
            ? viewVocabSet.replace(':vocabSetId', info.contentId.toString()).concat(moduleItemIdRef)
            : null,
         scale: 1.5,
         showIf: (i) => !!i.contentId && i.contentType === ContentType.vocabSet,
         title: 'View Vocab Set',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_content_library',
         link: contentLibraryRoot,
         scale: 1.5,
         title: 'Jump to Content Library',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_school_content',
         link: library.replace(':libraryName', 'school'),
         scale: 1.5,
         title: 'Jump to School Content',
      },
      {
         category: CommandCategory.navigation,
         id: 'jump_to_shared_content',
         link: library.replace(':libraryName', 'shared'),
         scale: 1.5,
         title: 'Jump to Shared Content',
      },
      {
         id: 'create_activity',
         link: newActivity,
         scale: 1.2,
         title: 'Create Activity',
      },
      {
         id: 'create_vocab_set',
         link: newVocabSet,
         scale: 1.2,
         title: 'Create Vocab Set',
      },
      {
         id: 'create_lesson',
         link: newLesson,
         scale: 1.2,
         title: 'Create Lesson',
      },
   ];

   if (info.canViewGlobalLibrary) {
      contentNavigationCommands.push({
         category: CommandCategory.navigation,
         id: 'jump_to_global_content',
         link: library.replace(':libraryName', 'global'),
         scale: 1.5,
         title: 'Jump to Global Content',
      });
   }

   return contentNavigationCommands;
};

const makeVideoCommands = (enabledFeatures: readonly Features[]): readonly Command[] => [
   {
      category: CommandCategory.video,
      id: 'bulk_video_upload',
      link: bulkVideoUpload,
      scale: 1.2,
      showIf: (i) => !!i.isAdmin || enabledFeatures.includes('bulk_video'),
      title: 'Bulk Video Upload',
   },
   {
      category: CommandCategory.video,
      id: 'interview_questions',
      link: interviewQuestions,
      scale: 0.5,
      showIf: (i) =>
         (!!i.isAdmin || enabledFeatures.includes('video_interview_questions')) &&
         !i.isImpersonating,
      title: 'Jump to Interview Questions',
   },
   {
      category: CommandCategory.video,
      id: 'speakers',
      link: speakers,
      scale: 0.5,
      showIf: (i) =>
         (!!i.isAdmin || enabledFeatures.includes('speaker_editor')) && !i.isImpersonating,
      title: 'Jump to Speakers',
   },
];

const makeAdminCommands = (
   info: ContextInfo,
   navigate: NavigateFunction,
   appStateContext: AppStateContext,
   enabledFeatures: readonly Features[],
): readonly Command[] => [
   {
      category: CommandCategory.admin,
      id: 'jump_to_courses',
      link: viewCourses,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Courses',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_schools',
      link: viewSchools,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Schools',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_organizations',
      link: viewSchools,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Organizations',
   },
   {
      category: CommandCategory.admin,
      id: 'create_school',
      link: createSchool,
      scale: 0.4,
      showIf: (i) => !!i.isAdmin,
      title: 'Create School',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_users',
      link: viewUsers,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Users',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_licenses',
      link: viewLicenses,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Licenses',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_course_enrollments',
      link: viewCourseEnrollments,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Course Enrollments',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_national_exam_orders',
      link: Constants.routes.admin.nationalExam.viewExamOrders,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to National Exam Orders',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_national_exams',
      link: Constants.routes.admin.nationalExam.viewExams,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to National Exams',
   },
   {
      category: CommandCategory.admin,
      id: 'jump_to_can_do_statements',
      link: canDoStatements,
      scale: 0.6,
      showIf: (i) => !!i.isAdmin || enabledFeatures.includes('can_do_statement_editor'),
      title: 'Jump to Can-Do Statements',
   },
   {
      action: () => {
         CourseService.clearUserLicensingCache(info.courseId as number).then((deletedCount) => {
            appStateContext.dispatchToast({
               title: 'License Cached Cleared',
               message: `User licensing cache for Course ID ${
                  info.courseId
               } is cleared. ${deletedCount} ${pluralize('key', deletedCount)} deleted.`,
               appearance: Appearance.success,
            });
         });
      },
      category: CommandCategory.admin,
      id: 'clear_course_user_license_cache',
      scale: 0.5,
      showIf: (i) => !!i.isAdmin && !!i.courseId,
      title: 'Clear User Licensing Cache for Course',
   },
   {
      action: () => {
         CourseService.generateMissingSubmissions(info.courseId as number).then(
            (generatedCount) => {
               appStateContext.dispatchToast({
                  title: 'Missing Submissions Generated',
                  message: `Student activity submissions for Course ID ${
                     info.courseId
                  } have been generated. ${generatedCount} ${pluralize(
                     'key',
                     generatedCount,
                  )} submissions generated.`,
                  appearance: Appearance.success,
               });
            },
         );
      },
      category: CommandCategory.admin,
      id: 'generate_missing_course_submissions',
      scale: 0.5,
      showIf: (i) => !!i.isAdmin && !!i.courseId,
      title: 'Generate Missing Submissions for Course',
   },
   {
      category: CommandCategory.admin,
      id: 'impersonate_user',
      scale: 0.5,
      showIf: (i) => !!i.isAdmin && !i.isImpersonating,
      title: 'Impersonate User',
      commandName: CommandName.IMPERSONATE_USER,
   },
   {
      category: CommandCategory.admin,
      id: 'search_bookstore_codes',
      scale: 0.5,
      showIf: (i) => !!i.isAdmin && !i.isImpersonating,
      title: 'Search Bookstore Codes',
      commandName: CommandName.SEARCH_BOOKSTORE_CODES,
   },
   {
      category: CommandCategory.admin,
      id: 'stop_impersonating',
      scale: 2.0,
      showIf: (i) => !!i.isImpersonating,
      title: 'Stop Impersonating',
      action: () => {
         UserService.cancelImpersonation().then(() => navigate('/'));
      },
   },
   {
      category: CommandCategory.admin,
      id: 'invite_instructor',
      link: instructorInvitations,
      scale: 0.5,
      showIf: (i) => !!i.isAdmin,
      title: 'Invite Instructor to Template',
   },
   {
      category: CommandCategory.admin,
      id: 'style_sheets',
      link: styleSheets,
      scale: 0.5,
      showIf: (i) => !!i.isAdmin,
      title: 'Jump to Style Sheets',
   },
   {
      category: CommandCategory.admin,
      id: 'bookstore_code_generator',
      link: bookstoreCodeGenerator,
      scale: 0.5,
      showIf: (i) => !!i.isAdmin && !i.isImpersonating,
      title: 'Generate Bookstore Codes',
   },
];

const makeNationalExamAdminCommands = (): readonly Command[] => [
   {
      category: CommandCategory.nationalExamAdmin,
      id: 'national_exam_admin_course_search',
      link: nationalExamAdmin.courseSearch,
      scale: 0.5,
      showIf: (i) => !!i.isNationalExamAdmin,
      title: 'Jump to National Exam Course Search',
   },
   {
      category: CommandCategory.nationalExamAdmin,
      id: 'national_exam_admin_exam_summary_details',
      link: nationalExamAdmin.exams,
      scale: 0.5,
      showIf: (i) => !!i.isNationalExamAdmin,
      title: 'Jump to National Exams',
   },
];

export {
   makeAdminCommands,
   makeContentNavigation,
   makeCourseNavigation,
   makeCurrentCourseRoutes,
   makeNationalExamAdminCommands,
   makeQueryCommands,
   makeRootNavigation,
   makeVideoCommands,
};
