import * as _ from 'lodash';
import * as React from 'react';

import languageOptions from '@helpers/LanguageOptions';
import IconImageClose from '@icons/general/icon-image-close.svg';
import Appearance from '@models/Appearance';
import { Maybe } from '@models/Core';
import IUserProfile from '@models/IUserProfile';
import { uploadImage } from '@services/AssetService';
import UserService from '@services/UserService';
import classnames from 'classnames';
import { diff } from 'deep-object-diff';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import Button from './Common/Button';
import Link from './Common/Link';
import Switch from './Common/Switch';
import ModalDialog from './Core/ModalDialog';
import DocumentTitle from './DocumentTitle';
import InfoTooltip from './InfoTooltip';
import Loader from './Loader';

export interface IUserSettingsProps {
   updateUserProfile(userInfo: IUserProfile): void;
}

interface IEditableUser extends IUserProfile {
   file?: File;
}

interface UserSettingsForm {
   firstName: string;
   lastName: string;
}

interface PasswordFields {
   currentPassword?: string;
   newPassword?: string;
}

const UserSettings: React.FC<IUserSettingsProps> = ({ updateUserProfile }) => {
   const {
      alphabets,
      minPasswordLength,
      routes: {
         dashboard: dashboardRoute,
         settings: settingsRoute,
         auth: { logout },
      },
      statusCodes,
   } = Constants;

   const navigate = useNavigate();

   const {
      schoolProfile,
      userProfile: contextUserProfile,
      setBreadcrumbs,
   } = React.useContext<AppStateContext>(AppStateContext);

   if (!contextUserProfile) {
      return;
   }
   const { id: userId } = contextUserProfile;

   const {
      register,
      formState: { errors },
      handleSubmit,
   } = useForm<UserSettingsForm>();
   const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [userProfile, setUserProfile] = React.useState<Maybe<IEditableUser>>(null);
   const [newPassword, setNewPassword] = React.useState<string>('');
   const [confirmNewPassword, setConfirmNewPassword] = React.useState<string>('');
   const [currentPassword, setCurrentPassword] = React.useState<string>('');
   const [error, setError] = React.useState<string>('');

   const canUseVirtualKeyboard =
      userProfile?.language &&
      Object.prototype.hasOwnProperty.call(alphabets, userProfile?.language);

   React.useEffect(() => {
      setBreadcrumbs({
         breadcrumbs: [{ link: settingsRoute, text: 'Settings' }],
         next: null,
         prev: null,
      });
      setUserProfile(_.cloneDeep(contextUserProfile));
   }, []);

   const deleteUser = async (): Promise<void> =>
      UserService.deleteUser(userId).then(() => {
         closeDeleteModal();
         navigate(logout);
      });

   const closeDeleteModal = (): void => setDeleteModalOpen(false);

   const handleProfileImageFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const file = event.target.files?.[0];
      if (!file) {
         return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
         const profileImageUrl = reader.result as string;
         setUserProfile((prevUserProfile) =>
            prevUserProfile
               ? {
                    ...prevUserProfile,
                    profileImageUrl,
                    file,
                 }
               : prevUserProfile,
         );
      };
      reader.readAsDataURL(file);
   };

   const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setConfirmNewPassword(event.target.value);

   const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setCurrentPassword(event.target.value);

   const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
      setNewPassword(event.target.value);

   const handleUserChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
   ): void => {
      const { name, value } = event.target;
      setUserProfile((prevUserProfile) =>
         prevUserProfile
            ? {
                 ...prevUserProfile,
                 [name]: value,
              }
            : prevUserProfile,
      );
   };

   const removeProfileImage = (): void => {
      setUserProfile((prevUserProfile) =>
         prevUserProfile
            ? {
                 ...prevUserProfile,
                 profileImageFilename: '',
                 profileImageUrl: '',
              }
            : prevUserProfile,
      );
   };

   const saveChanges = async (userSettingsUpdate: UserSettingsForm): Promise<void> => {
      if (validatePasswords()) {
         const data: Partial<IEditableUser & PasswordFields> = diff(contextUserProfile, {
            ...userProfile,
            ...userSettingsUpdate,
         });
         if (newPassword.length) {
            data.currentPassword = currentPassword;
            data.newPassword = newPassword;
         }
         if (!_.isEmpty(data)) {
            setIsLoading(true);
            if ('profileImageUrl' in data) {
               data.profileImageFilename = data.file ? await uploadImage(data.file) : '';
            }
            const { file, profileImageUrl, features, ...filteredProfile } = data;

            UserService.editUser(userId, filteredProfile)
               .then((updatedUser) => {
                  updateUserProfile(updatedUser);
                  navigate(dashboardRoute);
               })
               .catch((requestError) => {
                  if (requestError?.response?.status === statusCodes.forbidden) {
                     setError(requestError.response.data.msg);
                     setIsLoading(false);
                  }
               });
         }
      }
   };

   const toggleShowVirtualKeyboard = (): void => {
      setUserProfile((prevUserProfile) => {
         if (prevUserProfile) {
            return {
               ...prevUserProfile,
               preferences: {
                  ...prevUserProfile.preferences,
                  virtualKeyboard: !prevUserProfile.preferences.virtualKeyboard,
               },
            };
         }
         return prevUserProfile;
      });
   };

   const validatePasswords = (): boolean => {
      if (newPassword.length) {
         if (currentPassword.length === 0) {
            setError('Please enter current password');
            return false;
         } else if (newPassword !== confirmNewPassword) {
            setError('Passwords must match');
            return false;
         } else if (newPassword.length < minPasswordLength) {
            setError(`Password must be at least ${minPasswordLength} characters`);
            return false;
         }
      }
      return true;
   };

   if (!userProfile) {
      return <Loader />;
   }

   return (
      <>
         <DocumentTitle>User Settings</DocumentTitle>
         <div className='content-main-center'>
            <div className='row center-xs'>
               <form className='col-xs-12 col-md-12 col-lg-8' onSubmit={handleSubmit(saveChanges)}>
                  <div className='card settings-page-card user-settings'>
                     <div className='card-title has-button full-width'>
                        <div className='title'>Settings</div>
                        <div className='action-buttons'>
                           <Link className='btn line' to={dashboardRoute}>
                              Cancel
                           </Link>
                           <Button type='submit' loading={isLoading}>
                              Save
                           </Button>
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title' htmlFor='firstName'>
                              First Name
                           </label>
                           <input
                              {...register('firstName', { required: true })}
                              className={classnames({
                                 error: errors.firstName,
                              })}
                              type='text'
                              id='firstName'
                              defaultValue={userProfile.firstName}
                           />
                           {errors.firstName?.type === 'required' && (
                              <p className='error' aria-live='polite'>
                                 First name is required
                              </p>
                           )}
                        </div>
                        <div className='col-xs-12 col-sm-6'>
                           <label className='field-title' htmlFor='lastName'>
                              Last Name
                           </label>
                           <input
                              {...register('lastName', { required: true })}
                              className={classnames({ error: errors.lastName })}
                              type='text'
                              id='lastName'
                              defaultValue={userProfile.lastName}
                           />
                           {errors.lastName?.type === 'required' && (
                              <p className='error' aria-live='polite'>
                                 Last name is required
                              </p>
                           )}
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12'>
                           <label className='field-title'>Language</label>
                           <select
                              name='language'
                              id='language'
                              value={userProfile.language}
                              onChange={handleUserChange}
                           >
                              {languageOptions}
                           </select>
                        </div>
                     </div>
                     <div className='row'>
                        <div className='col-xs-12 col-sm-9 margin-bottom-s-md'>
                           <label className='field-title' htmlFor='email'>
                              Current Email
                           </label>
                           <input
                              disabled
                              className='disabled'
                              type='email'
                              name='email'
                              id='email'
                              placeholder='Email Address'
                              value={userProfile.email}
                           />
                           <label className='field-title margin-top-s' htmlFor='school'>
                              Your School
                           </label>
                           <input
                              disabled
                              className='disabled'
                              type='text'
                              name='school'
                              id='school'
                              placeholder='School'
                              value={schoolProfile?.name ?? ''}
                           />
                        </div>
                        <div className='col-xs-12 col-sm-3'>
                           <label className='field-title' htmlFor='profileImage'>
                              Profile Image
                           </label>
                           <div className='set-settings-image-wrap'>
                              {userProfile.profileImageUrl && (
                                 <div
                                    className='set-settings-image-close'
                                    onClick={removeProfileImage}
                                    aria-label='Remove Profile image'
                                 >
                                    <IconImageClose className='ignore-click' aria-hidden />
                                 </div>
                              )}
                              <div className='set-settings-image'>
                                 {!userProfile.profileImageUrl && (
                                    <input
                                       className='pointer ignore-click'
                                       type='file'
                                       accept='image/*'
                                       id='profileImage'
                                       onChange={handleProfileImageFileChange}
                                    />
                                 )}
                                 {userProfile.profileImageUrl && (
                                    <img
                                       alt='profile'
                                       className='ignore-click'
                                       src={userProfile.profileImageUrl}
                                    />
                                 )}
                              </div>
                           </div>
                        </div>
                        <div className='col-xs-12'>
                           <div className='margin-top-m padding-top-m margin-top-m border-top'>
                              <div className='title small'>Change your password</div>
                              <div className='row'>
                                 <div className='col-xs-12 col-md-4'>
                                    <label className='field-title' htmlFor='currentPassword'>
                                       Current Password
                                    </label>
                                    <input
                                       type='password'
                                       name='currentPassword'
                                       id='currentPassword'
                                       onChange={handleCurrentPasswordChange}
                                       data-dd-privacy='input-ignored'
                                    />
                                 </div>
                                 <div className='col-xs-12 col-md-4'>
                                    <label className='field-title' htmlFor='newPassword'>
                                       New Password
                                    </label>
                                    <input
                                       type='password'
                                       name='newPassword'
                                       id='newPassword'
                                       onChange={handleNewPasswordChange}
                                       data-dd-privacy='input-ignored'
                                    />
                                 </div>
                                 <div className='col-xs-12 col-md-4'>
                                    <label className='field-title' htmlFor='confirmNewPassword'>
                                       Confirm Password
                                    </label>
                                    <input
                                       type='password'
                                       name='confirmNewPassword'
                                       id='confirmNewPassword'
                                       onChange={handleConfirmNewPasswordChange}
                                       value={confirmNewPassword}
                                       data-dd-privacy='input-ignored'
                                    />
                                 </div>
                              </div>
                           </div>
                           {error && <p className='error'>{error}</p>}
                        </div>
                     </div>
                  </div>
                  {canUseVirtualKeyboard && (
                     <div className='card user-preferences settings-page-card'>
                        <div className='card-title full-width'>
                           <div className='title'>Preferences</div>
                        </div>
                        <div className='row'>
                           <div className='col-xs-12 col-sm-6'>
                              <Switch
                                 checked={userProfile?.preferences?.virtualKeyboard ?? false}
                                 onChange={toggleShowVirtualKeyboard}
                                 name='virtualKeyboard'
                                 id='virtualKeyboard'
                                 className='user-preferences-switch'
                              >
                                 <>
                                    <label className='field-title' htmlFor='virtualKeyboard'>
                                       Virtual Keyboard
                                    </label>
                                    <InfoTooltip>
                                       Shows an onscreen keyboard for typing questions in vocabulary
                                       learning and review sessions
                                    </InfoTooltip>
                                 </>
                              </Switch>
                           </div>
                        </div>
                     </div>
                  )}
                  {/* <div className="card settings-page-card">
                     <div className="card-title full-width">
                        <div className="title">Delete Account</div>
                     </div>
                     <div className="row">
                        <div className="col-xs-8">
                           <p>
                              <strong>Caution: </strong>
                              Deleting your account will also delete all associated content and coursework. This action cannot be undone.
                           </p>
                        </div>
                        <div className="col-xs-4">
                           <div className="delete-account-btn">
                              <Button onClick={openDeleteModal} appearance="danger">Delete Account</Button>
                           </div>
                        </div>
                     </div>
                  </div> */}
               </form>
            </div>
         </div>
         {deleteModalOpen && (
            <ModalDialog
               appearance={Appearance.danger}
               heading='Delete Account'
               onClose={closeDeleteModal}
               animations={{ enter: 'animated bounceInDown' }}
               actions={[
                  { text: 'Delete', onClick: deleteUser },
                  { text: 'Cancel', onClick: closeDeleteModal },
               ]}
            >
               <p>Are you sure that you want to delete your account?</p>
               <p>
                  All user information including content and coursework will be deleted. This action
                  cannot be undone.
               </p>
            </ModalDialog>
         )}
      </>
   );
};

export default UserSettings;
