import { Maybe } from '@models/Core';

const convertStringToNode = (str: string): Maybe<Node> => {
   const result = new DOMParser().parseFromString(str, 'text/html');

   return result.body?.firstChild;
};

const convertStringToNodes = (str: string): NodeListOf<ChildNode> => {
   const result = new DOMParser().parseFromString(str.replaceAll('\n', ''), 'text/html');
   return result.body.childNodes;
};

const getDataAttribute = (node: Node, attributeName: string): Maybe<string> => {
   // Check if the node is an element
   if (node instanceof Element) {
      // Use the getAttribute method to check if the element has the specified data attribute
      return node.getAttribute(attributeName);
   }

   return null;
};

export { convertStringToNode, convertStringToNodes, getDataAttribute };
