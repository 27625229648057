import * as React from 'react';

import { Maybe } from '@models/Core';
import { Course } from '@models/Course';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@components/Common/Button';
import ModalDialog from '@components/Core/ModalDialog';
import CourseCodes from '@components/Course/CourseCodes';
import ResetCourseCodeModal from '@components/Course/Roster/ResetCourseCodeModal';

interface CourseDetailsModalProps {
   course: Course;
   onClose(): void;
   resetStudentCourseCode(): void;
   resetSectionCourseCode(sectionId: number): void;
}

const CourseDetailsModal: React.FC<CourseDetailsModalProps> = ({
   course: { courseCode, id, ltiSecretKey, sections },
   onClose,
   resetStudentCourseCode,
   resetSectionCourseCode,
}) => {
   const [isResetCourseCodeModalOpen, setIsResetCourseCodeModalOpen] =
      React.useState<boolean>(false);
   const [lmsConsumerKeyCopied, setLmsConsumerKeyCopied] = React.useState<boolean>(false);
   const [lmsSecretKeyCopied, setLmsSecretKeyCopied] = React.useState<boolean>(false);
   const [lmsSecretKeyVisible, setLmsSecretKeyVisible] = React.useState<boolean>(false);
   const [selectedResetCodeSectionId, setSelectedResetCodeSectionId] =
      React.useState<Maybe<number>>(null);

   const lmsConsumerKey = `q4w8e8r2t3y-${id}`;

   const openResetCourseCodeModal = (sectionId: Maybe<number> = null): void => {
      setSelectedResetCodeSectionId(sectionId);
      setIsResetCourseCodeModalOpen(true);
   };

   const handleResetCourseCode = (): void => {
      if (selectedResetCodeSectionId) {
         resetSectionCourseCode(selectedResetCodeSectionId);
      } else {
         resetStudentCourseCode();
      }
      closeResetCourseCodeModal();
   };

   const closeResetCourseCodeModal = (): void => {
      setSelectedResetCodeSectionId(null);
      setIsResetCourseCodeModalOpen(false);
   };

   if (isResetCourseCodeModalOpen) {
      return (
         <ResetCourseCodeModal
            instructor={false}
            section={selectedResetCodeSectionId !== null}
            reset={handleResetCourseCode}
            close={closeResetCourseCodeModal}
         />
      );
   }

   return (
      <ModalDialog
         heading='Course Details'
         width='large'
         onClose={onClose}
         bodyClassName='content-row-container'
      >
         <div className='content-row padding-top-s'>
            <CourseCodes
               courseCode={courseCode}
               sections={sections}
               resetStudentCourseCode={openResetCourseCodeModal}
               resetSectionCourseCode={openResetCourseCodeModal}
            />
            <div className='row bottom-xs'>
               <div className='col-xs-12'>
                  <label className='field-title'>LMS Consumer Key</label>
                  <div className='copy-field'>
                     <CopyToClipboard
                        text={lmsConsumerKey}
                        onCopy={() => setLmsConsumerKeyCopied(true)}
                     >
                        <Button color='white' className='copy-button'>
                           {lmsConsumerKeyCopied ? 'Copied' : 'Copy'}
                        </Button>
                     </CopyToClipboard>
                     <input
                        disabled
                        readOnly
                        type='text'
                        className='disabled'
                        value={lmsConsumerKey}
                     />
                  </div>
                  <p>
                     Use this ID to set up your course on a learning management system like Canvas
                     or Desire2Learn. If you are here to set up your course for single sign on, this
                     will be used as your "Consumer Key."
                  </p>
               </div>
            </div>
            <div className='row bottom-xs'>
               <div className='col-xs-12'>
                  <label className='field-title'>LMS Secret Key</label>
                  <div className='copy-field'>
                     {lmsSecretKeyVisible && ltiSecretKey ? (
                        <CopyToClipboard
                           text={ltiSecretKey}
                           onCopy={() => setLmsSecretKeyCopied(true)}
                        >
                           <Button color='white' className='copy-button'>
                              {lmsSecretKeyCopied ? 'Copied' : 'Copy'}
                           </Button>
                        </CopyToClipboard>
                     ) : (
                        <Button
                           color='white'
                           className='copy-button'
                           onClick={() => setLmsSecretKeyVisible(true)}
                        >
                           Show
                        </Button>
                     )}
                     <input
                        disabled
                        readOnly
                        type='text'
                        className='disabled'
                        value={
                           lmsSecretKeyVisible ? ltiSecretKey : '**********-********************'
                        }
                     />
                  </div>
                  <p>
                     Use this secret key to set up your course on a learning management system like
                     Canvas or Desire2Learn. If you are here to set up your course for single sign
                     on, this will be used as your "Secret Key."
                  </p>
               </div>
            </div>
         </div>
      </ModalDialog>
   );
};

export default CourseDetailsModal;
