/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/help/';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* Import custom plugins */
import './plugins/CustomIcons';
import './plugins/Callouts';
import './plugins/Conversations';
import './plugins/Examples';
import './plugins/FillBlanks';
import './plugins/MarkTokens';
import './plugins/InlineAudio';
import './plugins/ContentLink';
import './plugins/QuickInsert';
import './plugins/Tooltip';
import './plugins/WebBookmark';
import './plugins/CaseChange';
import './plugins/Translate';
import './plugins/Transform';
import './plugins/InlineCSS';
import './plugins/RecordVideo';
import './plugins/InsertImage';
import './plugins/UploadFile';
import './plugins/FixMenus';
import './plugins/FixImages';
import './plugins/BannerImages';
import './plugins/GenerateAudio';
import './plugins/CustomTable';
/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';
/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
// import 'tinymce/skins/ui/oxide/skin.css';
/* eslint-disable camelcase */
import * as _ from 'lodash';

import { uploadUserFile } from '@services/AssetService';
import { AxiosProgressEvent } from 'axios';
import { RawEditorOptions } from 'tinymce';

import { BlobInfo } from './types/FileUpload';
import { MediaData } from './types/MediaData';
import { getVideoDimensions } from './utils/media';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCss from 'tinymce/skins/content/default/content.css';
/* content UI CSS is required */
// import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css';

declare type EditorConfig = Partial<
   RawEditorOptions & { selector?: undefined; target?: undefined }
>;

const filePickerCallback = (
   callback: (value: string, meta?: Record<string, string | boolean>) => void,
   value: string,
   meta: Record<string, string | boolean>,
): void => {
   const input = document.createElement('input');
   input.setAttribute('type', 'file');
   if (meta.filetype === 'image') {
      input.setAttribute('accept', 'image/*');
   } else if (meta.filetype === 'media') {
      input.setAttribute('accept', 'video/*,audio/*');
   }

   input.addEventListener('change', (e) => {
      const file = (e.target as HTMLInputElement)?.files?.[0];
      if (!file) {
         return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', async () => {
         const { url } = await uploadUserFile(file);
         const data: Partial<MediaData> = {};
         /* call the callback and populate the Title field with the file name */
         if (file.type.startsWith('audio/')) {
            data.type = 'audio';
            data.embed = `<audio controls src="${url}" data-player-size="full"></audio>`;
         } else if (file.type.startsWith('video/')) {
            const dimensions = await getVideoDimensions(file);
            if (dimensions) {
               const { width, height } = dimensions;
               data.type = 'video';
               data.embed = `<video controls src="${url}" width="${width}" height=${height}></video>`;
               data.height = height.toString();
               data.width = width.toString();
            }
         }
         callback(url, data);
      });
      reader.readAsDataURL(file);
   });

   input.click();
};

const mapColorsArrayToValueTitle = (existingStructure: readonly string[]) =>
   _.chunk(existingStructure, 2).map(([value, title]) => ({
      title,
      value: value.substring(1),
   }));

export const COLOR_MAP_BACKGROUD: string[] = [
   '#472D54',
   'Purple',
   '#CD1F1C',
   'Red',
   '#4A7D35',
   'Green',
   '#007CBB',
   'Blue',
   '#EE592A',
   'Orange',
   '#807F86',
   'Mid-Gray',
   '#80A471',
   'Mid-Green',
   '#E68F8D',
   'Mid-Red',
   '#FFB53E',
   'Yellow',
   '#A396A9',
   'Mid-Purple',
   '#66B0D6',
   'Mid-Blue',
   '#F6AC94',
   'Mid-Orange',
   '#FFD38B',
   'Mid-Yellow',
   '#B2D7EA',
   'Light Blue',
   '#FFF2F2',
   'Light Red',
   '#F2FAEE',
   'Light Green',
   '#FFF9F0',
   'Light Yellow',
   '#FFF7F5',
   'Light Orange',
   '#F4F4F4',
   'Light Gray',
   '#FDF9FF',
   'Light Purple',
   '#FFFFFF',
   'White',
];

export const COLOR_MAP_FOREGROUND: string[] = [
   '#413F48',
   'Dark-Gray',
   '#CD1F1C',
   'Red',
   '#EE592A',
   'Orange',
   '#FFB53E',
   'Yellow',
   '#4A7D35',
   'Green',
   '#007CBB',
   'Blue',
   '#472D54',
   'Purple',
   '#807f86',
   'Mid-Gray',
   '#FFFFFF',
   'White',
];

export const TABLE_CONFIG: EditorConfig = {
   table_class_list: [
      { title: 'Default', value: 'table' },
      { title: 'Striped', value: 'table striped' },
      { title: 'Bordered', value: 'table bordered' },
      { title: 'Borderless', value: 'table borderless' },
      { title: 'Small', value: 'table small' },
   ],
   table_toolbar:
      'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol tableevencolumns | tablecellbackgroundcolor',
   table_default_attributes: {
      class: 'table',
   },
   table_default_styles: {
      width: '100%',
   },
   table_background_color_map: mapColorsArrayToValueTitle(COLOR_MAP_BACKGROUD),
   table_resize_bars: true,
};

export const IMAGE_CONFIG: EditorConfig = {
   automatic_uploads: true,
   image_caption: true,
   image_advtab: true,
   a11y_advanced_options: true,
   images_upload_handler: async (blobInfo: BlobInfo, progress): Promise<string> => {
      const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
         if (progressEvent.total) {
            progress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
         }
      };
      return uploadUserFile(blobInfo.blob(), handleUploadProgress).then((response) => response.url);
   },
   image_class_list: [
      { title: 'None', value: '' },
      { title: 'Responsive', value: 'responsive' },
      { title: 'Banner', value: 'banner' },
   ],
};

export const MEDIA_CONFIG: EditorConfig = {
   audio_template_callback: (data: MediaData): string =>
      `<audio controls src="${data.source}" data-player-size="full"></audio`,
   file_picker_callback: filePickerCallback,
   file_picker_types: 'image media',
   media_live_embeds: true,
};

export const BASE_CONFIG: EditorConfig = {
   branding: false,
   browser_spellcheck: true,
   color_map_background: COLOR_MAP_BACKGROUD,
   color_map_foreground: COLOR_MAP_FOREGROUND,
   content_css: false,
   // content_style: contentUiSkinCss.toString(),
   custom_colors: false,
   end_container_on_empty_block: true,
   extended_valid_elements: 'svg[*],path[*],g[*]',
   font_family_formats: `
      Andale Mono=andale mono,times;
      Arial=arial,helvetica,sans-serif;
      Arial Black=arial black,avant garde;
      Book Antiqua=book antiqua,palatino;
      Brandon Grotesque=Brandon,sans-serif;
      Comic Sans MS=comic sans ms,sans-serif;
      Courier New=courier new,courier;
      Georgia=georgia,palatino;
      Helvetica=helvetica;
      Impact=impact,chicago;
      Karla=Karla,sans-serif;
      Tahoma=tahoma,arial,helvetica,sans-serif;
      Times New Roman=times new roman,times;
      Verdana=verdana,geneva;`,
   inline: true,
   link_quicklink: true,
   menubar: false,
   object_resizing: 'img:not(.example-image > img):not(.responsive),table',
   paste_as_text: true,
   promotion: false,
   quickbars_insert_toolbar: false,
   relative_urls: false,
   skin: false,
   style_formats: [
      { title: 'Heading 1', block: 'h1' },
      { title: 'Heading 2', block: 'h2' },
      { title: 'Heading 3', block: 'h3' },
      { title: 'Text', block: 'p' },
      {
         title: 'Callouts',
         items: [
            {
               title: 'Primary',
               block: 'div',
               classes: 'callout primary',
               wrapper: true,
            },
            {
               title: 'Secondary',
               block: 'div',
               classes: 'callout secondary',
               wrapper: true,
            },
            {
               title: 'Success',
               block: 'div',
               classes: 'callout success',
               wrapper: true,
            },
            {
               title: 'Warning',
               block: 'div',
               classes: 'callout warning',
               wrapper: true,
            },
            {
               title: 'Danger',
               block: 'div',
               classes: 'callout danger',
               wrapper: true,
            },
         ],
      },
   ],
   text_patterns: [
      { start: '*', end: '*', format: 'italic' },
      { start: '**', end: '**', format: 'bold' },
      { start: '#', format: 'h1', trigger: 'space' },
      { start: '##', format: 'h2', trigger: 'space' },
      { start: '###', format: 'h3', trigger: 'space' },
      // { start: '####', format: 'h4', trigger: 'space' }, // only use h1-h3
      // { start: '#####', format: 'h5', trigger: 'space' },
      // { start: '######', format: 'h6', trigger: 'space' },
      { start: '1.', cmd: 'InsertOrderedList', trigger: 'space' },
      { start: '*', cmd: 'InsertUnorderedList', trigger: 'space' },
      { start: '-', cmd: 'InsertUnorderedList', trigger: 'space' },
      { start: '---', cmd: 'InsertHorizontalRule', trigger: 'space' },
      { start: '>', cmd: 'mceBlockQuote', trigger: 'space' },
   ],
   typeahead_urls: false,
   ...IMAGE_CONFIG,
   ...MEDIA_CONFIG,
   ...TABLE_CONFIG,
};
