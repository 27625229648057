import { snakeCaseKeys } from '@helpers/ModifyKeys';
import { IdMessageResponse, MessageResponse } from '@models/Core';
import { Module, ModuleSummary } from '@models/Course';
import { PartialDeep } from 'type-fest';

import HttpService from './HttpService';

const getModulesSummary = (courseId: number): Promise<readonly ModuleSummary[]> =>
   HttpService.getWithAuthToken<{ modules: readonly Module[] }>(
      `/api/courses/${courseId}/modules/summary`,
   ).then((response) => response.data.modules);

const getModules = (courseId: number): Promise<readonly Module[]> =>
   HttpService.getWithAuthToken<{ modules: readonly Module[] }>(
      `/api/courses/${courseId}/modules`,
   ).then((response) => response.data.modules);

const getModule = (
   courseId: number,
   moduleId: number,
   assignmentDefaults = true,
): Promise<Module> => {
   const queryString = assignmentDefaults ? '?assignment_defaults=true' : '';
   return HttpService.getWithAuthToken<Module>(
      `/api/courses/${courseId}/modules/${moduleId}${queryString}`,
   ).then((response) => response.data);
};

const createModule = (courseId: number, module: PartialDeep<Module>): Promise<number> =>
   HttpService.postWithAuthToken<IdMessageResponse>(
      `/api/courses/${courseId}/modules`,
      snakeCaseKeys(module),
   ).then((i) => i.data.id);

const importModule = (courseId: number, moduleId: number): Promise<readonly Module[]> =>
   HttpService.postWithAuthToken<{ addedModules: readonly Module[] }>(
      `/api/courses/${courseId}/modules/import`,
      snakeCaseKeys({ moduleId }),
   ).then((response) => response.data.addedModules);

const updateModule = (
   courseId: number,
   moduleId: number,
   update: PartialDeep<Module>,
): Promise<string> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/${moduleId}`,
      snakeCaseKeys(update),
   ).then((i) => i.data.msg);

const deleteModule = (courseId: number, moduleId: number): Promise<void> =>
   HttpService.deleteWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/modules/${moduleId}`,
   ).then();

const setLastVisitedModule = (courseId: number, moduleId: number): Promise<string> =>
   HttpService.patchWithAuthToken<MessageResponse>(
      `/api/courses/${courseId}/last_visited_module`,
      snakeCaseKeys({ moduleId }),
   ).then((i) => i.data.msg);

export default {
   createModule,
   deleteModule,
   getModulesSummary,
   getModule,
   getModules,
   importModule,
   setLastVisitedModule,
   updateModule,
};
