import * as React from 'react';

import IconHome from '@icons/general/icon-home.svg';
import IconNavigationNext from '@icons/general/icon-navigation-next.svg';
import IconNavigationPrev from '@icons/general/icon-navigation-prev.svg';
import AccountType from '@models/AccountType';
import { BreadcrumbsNavigation } from '@models/Breadcrumbs';
import { ContentType } from '@models/Content';
import { Maybe } from '@models/Core';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { AppStateContext } from '../AppState';
import Constants from '../Constants';
import Link from './Common/Link';

const NavBreadcrumbs: React.FC = () => {
   const {
      routes: {
         dashboard,
         content: { viewLesson, viewVocabSet, previewActivity, viewVideo },
         activities: { completeSubmission },
      },
   } = Constants;

   const {
      breadcrumbs: { breadcrumbs, prev, next },
      userProfile,
   } = React.useContext<AppStateContext>(AppStateContext);

   const makeNavLink = (navData: BreadcrumbsNavigation): Maybe<string> => {
      if (!navData) {
         return null;
      }
      if (navData.linkType === ContentType.lesson && navData.lessonId) {
         return viewLesson
            .replace(':lessonId', navData.lessonId.toString())
            .concat(`?moduleItemId=${navData.moduleItemId}`);
      } else if (navData.linkType === ContentType.vocabSet && navData.vocabSetId) {
         return viewVocabSet
            .replace(':vocabSetId', navData.vocabSetId.toString())
            .concat(`?moduleItemId=${navData.moduleItemId}`);
      } else if (navData.linkType === ContentType.video && navData.videoId) {
         return viewVideo
            .replace(':videoId', navData.videoId.toString())
            .concat(`?moduleItemId=${navData.moduleItemId}`);
      } else if (navData.linkType === ContentType.activity) {
         if (navData.submissionId) {
            return completeSubmission.replace(':submissionId', navData.submissionId.toString());
         } else if (userProfile?.accountType === AccountType.instructor && navData.activityId) {
            return previewActivity
               .replace(':activityId', navData.activityId.toString())
               .concat(`?moduleItemId=${navData.moduleItemId}`);
         }
      }

      return null;
   };

   const nextLink = next ? makeNavLink(next) : null;
   const prevLink = prev ? makeNavLink(prev) : null;

   const getContentName = (contentType: ContentType): Maybe<string> => {
      if (contentType === ContentType.activity) {
         return 'Activity';
      } else if (contentType === ContentType.lesson) {
         return 'Lesson';
      } else if (contentType === ContentType.video) {
         return 'Video';
      } else if (contentType === ContentType.vocabSet) {
         return 'Vocab Set';
      }
      return null;
   };

   const getPrevTooltip = (): Maybe<string> => {
      if (next?.first) {
         return 'Beginning of module';
      } else if (prev) {
         return `Previous ${getContentName(prev.linkType)}`;
      }
      return null;
   };

   const getNextTooltip = (): Maybe<string> => {
      if (prev?.last) {
         return 'End of module';
      } else if (next) {
         return `Next ${getContentName(next.linkType)}`;
      }
      return null;
   };

   const prevTooltip = getPrevTooltip();
   const nextTooltip = getNextTooltip();

   return (
      <div className='nav-breadcrumbs-wrapper'>
         <div className='nav-breadcrumbs'>
            <div className='nav-breadcrumb-icon'>
               <IconHome aria-hidden />
            </div>
            <ul>
               <li>
                  <Link data-test='link-home' to={dashboard}>
                     Home
                  </Link>
               </li>
               {breadcrumbs?.map(({ onClick, link, text }) => {
                  if (link) {
                     return (
                        <li key={text}>
                           <Link to={link}>{text}</Link>
                        </li>
                     );
                  } else if (onClick) {
                     return (
                        <li key={text} onClick={onClick}>
                           {text}
                        </li>
                     );
                  } else {
                     return <li key={text}>{text}</li>;
                  }
               })}
            </ul>
         </div>
         {(nextLink || prevLink) && (
            <div className='prev-next'>
               <Link to={prevLink}>
                  <Tippy content={prevTooltip} disabled={!prevTooltip}>
                     <div
                        className={classnames('nav-breadcrumb-icon', {
                           disabled: !prevLink,
                        })}
                     >
                        <IconNavigationPrev aria-label='Previous' />
                     </div>
                  </Tippy>
               </Link>
               <Link to={nextLink}>
                  <Tippy content={nextTooltip} disabled={!nextTooltip}>
                     <div
                        className={classnames('nav-breadcrumb-icon', {
                           disabled: !nextLink,
                        })}
                     >
                        <IconNavigationNext aria-label='Next' />
                     </div>
                  </Tippy>
               </Link>
            </div>
         )}
      </div>
   );
};

export default NavBreadcrumbs;
