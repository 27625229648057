import * as React from 'react';

import classnames from 'classnames';

interface IBlanketProps {
   /** Whether mouse events can pierce the blanket. If true, onBlanketClicked will not be fired */
   canClickThrough?: boolean;
   /** Open to show transparent instead of gray background */
   transparent?: boolean;
   /** Handler function to be called when the blanket is clicked */
   onBlanketClicked?(event: React.MouseEvent<HTMLDivElement>): void;
}

const Blanket: React.FC<IBlanketProps> = ({
   canClickThrough = false,
   transparent = false,
   onBlanketClicked,
}) => {
   const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
      if (!canClickThrough && onBlanketClicked) {
         onBlanketClicked(event);
      } else if (canClickThrough && onBlanketClicked) {
         onBlanketClicked(event);
      }
   };

   return (
      <div
         className={classnames('blanket', {
            clickable: canClickThrough,
            transparent,
         })}
         onClick={handleClick}
      />
   );
};

export default Blanket;
