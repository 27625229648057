import * as React from 'react';

import EmptyState from '@components/Core/EmptyState/EmptyState';
import DocumentTitle from '@components/DocumentTitle';
import ErrorDisplay from '@components/ErrorDisplay';
import Loader from '@components/Loader';
import { userProfileQuery } from '@graphql/queries';
import AccountType from '@models/AccountType';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { AppStateContext } from '../../../AppState';
import InstructorLicensePanel from './InstructorLicensePanel';
import OrganizationLicensePanel from './OrganizationLicensePanel';
import UserInformationPanel from './UserInformationPanel';

type Params = {
   userId: string;
};

const UserDetails: React.FC = () => {
   const { dispatchToast, userProfile } = React.useContext<AppStateContext>(AppStateContext);
   const disableUpdates = !userProfile?.isAdmin;

   const params = useParams<Params>();
   const parsedUserId = params.userId || '';

   const [{ data, error, fetching }, reExecuteQuery] = useQuery({
      query: userProfileQuery,
      variables: { userId: parsedUserId },
   });

   const refetchQuery = () => {
      reExecuteQuery({ requestPolicy: 'network-only' });
   };

   if (fetching) return <Loader />;
   if (error) return <ErrorDisplay />;
   if (!data?.user) return <EmptyState />;

   return (
      <>
         <DocumentTitle>
            {data.user ? `${data.user.firstName} ${data.user.lastName}` : 'Create User'}
         </DocumentTitle>
         <div className='content-main margin-right-m'>
            <div className='row center-xs'>
               <UserInformationPanel
                  disableUpdates={disableUpdates}
                  dispatchToast={dispatchToast}
                  user={data.user}
               />
               {data.user?.accountType === AccountType.instructor && (
                  <InstructorLicensePanel
                     instructorId={data.user.id}
                     disableUpdates={disableUpdates}
                     value={data.user.licenses}
                     refetch={refetchQuery}
                     panelTitle={`${data.user.fullName}'s Instructor Licenses`}
                  />
               )}
               {data.user.organization?.parent && (
                  <OrganizationLicensePanel
                     organizationId={data.user.organization.parent.id}
                     disableUpdates={disableUpdates}
                     value={data.user.organization.parent.licenses}
                     refetch={refetchQuery}
                     panelTitle={`${data.user.organization.parent?.name} District Licenses`}
                  />
               )}
               {data.user.organization && (
                  <OrganizationLicensePanel
                     organizationId={data.user.organization.id}
                     disableUpdates={disableUpdates}
                     value={data.user.organization.licenses}
                     refetch={refetchQuery}
                     panelTitle={`${data.user.organization.name} Organization Licenses`}
                  />
               )}
            </div>
         </div>
      </>
   );
};
export default UserDetails;
