import * as React from 'react';

import IconClose from '@icons/nova-line/02-Status/close.svg';
import { Maybe } from '@models/Core';
import pluralize from 'pluralize';
import { useNavigate } from 'react-router-dom';

import { VocabSessionType } from '@components/VocabSession/Models';
import { IVocabSessionContext, VocabSessionContext } from '@components/VocabSession/VocabSession';
import Flag from './Flag';
import Lives from './Lives';
import Timer from './Timer';

interface HeaderProps {
   togglePause(): void;
}

const Header: React.FC<HeaderProps> = ({ togglePause }) => {
   const {
      state: {
         sessionType,
         showSummary,
         settings: { language, name },
         cards,
      },
   } = React.useContext<IVocabSessionContext>(VocabSessionContext);

   const navigate = useNavigate();

   const navigateBack = () => navigate(-1);

   const termCount = new Set(cards.map((i) => i.termId)).size;

   const renderTitle = (): Maybe<string> => {
      switch (sessionType) {
         case VocabSessionType.speedReview:
            return `Speed Review - ${name}`;
         case VocabSessionType.review:
            return `Review ${termCount} ${pluralize('term', termCount)} - ${name}`;
         case VocabSessionType.learn:
            return `Learn ${termCount} new ${pluralize('term', termCount)} - ${name}`;
      }
      return null;
   };

   return (
      <div className='top-session-row'>
         <div className='row align-items-center'>
            <div className='col-xs-2 col-md-3'>{language && <Flag language={language} />}</div>
            <div className='col-xs-8 col-md-6 top-session-title '>
               <div className='session-description'>
                  <p>{renderTitle()}</p>
                  {sessionType === VocabSessionType.speedReview ? (
                     <Lives />
                  ) : (
                     !showSummary && <Timer onClick={togglePause} />
                  )}
               </div>
            </div>
            <div className='col-xs-2 col-md-3 top-session-close'>
               <div className='session-close' role='button' onClick={navigateBack}>
                  <IconClose />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Header;
