const elementRemoved = ({
   selector,
   createdCallback,
}: {
   selector: string;
   createdCallback(observer: MutationObserver): void;
}): Promise<boolean> =>
   new Promise((resolve) => {
      const el = document.querySelector(selector);
      if (!el) {
         resolve(true);
      }
      const newObserver = new MutationObserver((mutationRecords, observer) => {
         const instances = Array.from(document.querySelectorAll(selector));
         if (!instances.length) {
            resolve(true);
            observer.disconnect();
         }
      });

      if (createdCallback) {
         createdCallback(newObserver);
      }

      newObserver.observe(document.documentElement, {
         childList: true,
         subtree: true,
      });
   });

export default elementRemoved;
