import { randomShortId } from '@helpers/RandomStringUtils';

import {
   OperatorType,
   PrimitiveType,
   StringComparison,
   StringPredicate,
} from '@components/PredicateEditor/Models';

const notLingcoUserPredicate: StringPredicate = {
   attribute: 'user.email',
   comparison: StringComparison.doesNotContain,
   id: randomShortId(),
   type: PrimitiveType.string,
   value: '@lingco.io',
};

export const defaultPredicate = {
   id: randomShortId(),
   type: OperatorType.and,
   predicates: [notLingcoUserPredicate],
};
