import * as React from 'react';

import BinIcon from '@icons/nova-line/01-Content-Edition/bin.svg';
import Tippy from '@tippyjs/react';
import { isAxiosError } from 'axios';
import classNames from 'classnames';

import BasicUserProfile from '@models/BasicUserProfile';
import AdminNationalExamService from '@services/AdminNationalExamService';

type Props = {
   adminUserId: number;
   examId: number;
   isDisabled: boolean;
   setExamAdmins(admins: BasicUserProfile[]): void;
};

const RemoveAdmin: React.FC<Props> = (props) => {
   const [isDisabled, setIsDisabled] = React.useState<boolean>(props.isDisabled);
   const onClick = async () => {
      if (isDisabled) {
         return;
      }
      setIsDisabled(true);

      try {
         const response = await AdminNationalExamService.removeExamAdmin({
            examId: props.examId,
            userId: props.adminUserId,
         });
         props.setExamAdmins(response.updatedAdmins);
      } catch (e) {
         console.error(e);
         if (isAxiosError(e)) {
            alert(e.response?.data.error);
         }
         setIsDisabled(false);
      }
   };

   return (
      <Tippy content='Remove Admin Privileges for User'>
         <div
            className={classNames('icon-action', {
               disabled: isDisabled,
            })}
            onClick={onClick}
         >
            <BinIcon />
         </div>
      </Tippy>
   );
};

export default RemoveAdmin;
