import * as React from 'react';

import { TextPromptLength } from '@models/Activity';

interface TextPromptProps {
   length: string;
   showWordCount: boolean;
}

const TextPrompt: React.FC<TextPromptProps> = ({ length, showWordCount }) => {
   const props = {
      placeholder: `Student writes a ${
         length === TextPromptLength.short ? 'short' : 'long'
      } response here`,
      disabled: true,
      className: 'disabled flex-grow-1 margin-left-right-xs text-input-placeholder',
   };

   return (
      <>
         <div className='flex'>
            {length === TextPromptLength.short ? (
               <input type='text' {...props} />
            ) : (
               <textarea {...props} />
            )}
         </div>
         {showWordCount && <div className='word-count margin-right-xs'>0 Words</div>}
      </>
   );
};

export default TextPrompt;
