import { Maybe } from '@models/Core';
import { CourseProfile } from '@models/Course';
import { CourseProficiencyCanDoStatement } from '@models/Proficiency';

import AxiosService from './AxiosService';

const API = '/api/course_can_do_statements';

export type CourseCanDoStatementResponse = {
   canDoStatements: CourseProficiencyCanDoStatement[];
   isUsingOrganizationStandard: boolean;
   course: CourseProfile;
};

const get = (courseId: number): Promise<CourseCanDoStatementResponse> =>
   AxiosService.get<CourseCanDoStatementResponse>(`${API}/${courseId}`).then(
      (response) => response.data,
   );

const create = (courseId: number, canDoStatementId: number): Promise<void> =>
   AxiosService.post<void>(
      `${API}/${courseId}`,
      { canDoStatementId },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then();

const remove = (courseId: number, canDoStatementId: number): Promise<void> =>
   AxiosService.delete<void>(`${API}/${courseId}/${canDoStatementId}`, {
      errorHandlerOverrides: {
         handleServerError: false,
      },
   }).then();

const update = (
   courseId: number,
   canDoStatementIds: readonly number[],
   weekIndexOfExpectedCompletion: Maybe<number>,
): Promise<void> =>
   AxiosService.patch<void>(
      `${API}/${courseId}`,
      {
         canDoStatementIds,
         weekIndexOfExpectedCompletion,
      },
      {
         errorHandlerOverrides: {
            handleServerError: false,
         },
      },
   ).then();

export default {
   create,
   get,
   remove,
   update,
};
