import * as _ from 'lodash';
import * as React from 'react';

import autobind from '@helpers/autobind';
import { isChrome } from '@helpers/BrowserUtils';
import IconBuilderRecording from '@icons/activities/icon-builder-recording.svg';
import { SpokenResponseResponse } from '@models/Activity';
import { Maybe } from '@models/Core';
import Language from '@models/Language';
import SpeechEngine, { SpeechEngineParams } from '@services/SpeechEngine';
import classnames from 'classnames';
import { Recorder } from 'vmsg';

import { CommonPromptProps } from '@components/Activity/Completer/Prompt';

interface SpokenResponsePromptProps extends CommonPromptProps {
   acceptedResponses: readonly string[];
   className?: string;
   disabledText?: string;
   language: Language;
   saveRecording?: boolean;
   showFeedback?: boolean;
   text?: string;
   response: SpokenResponseResponse;
   setResponse(response: SpokenResponseResponse, callback?: () => void): void;
}

interface SpokenResponseState {
   isCorrect: boolean;
   isDisabled: boolean;
   isIncorrect: boolean;
   isListening: boolean;
   isPlaying: boolean;
   processingAudio: boolean;
   result: Maybe<string | readonly string[]>;
   speechEngine: Maybe<SpeechEngine>;
}

class SpokenResponsePrompt extends React.Component<SpokenResponsePromptProps, SpokenResponseState> {
   static defaultProps: Partial<SpokenResponsePromptProps> = {
      className: '',
      disabledText: '',
      isClosed: false,
      saveRecording: false,
      showFeedback: true,
   };

   recorder: Recorder;
   audio: Maybe<HTMLAudioElement>;
   holdState: boolean;

   constructor(props: SpokenResponsePromptProps) {
      super(props);
      autobind(this);
      this.state = {
         isCorrect: false,
         isDisabled: false,
         isIncorrect: false,
         isListening: false,
         isPlaying: false,
         processingAudio: false,
         result: null,
         speechEngine: null,
      };
      this.initializeSpeechEngine = _.debounce(this.initializeSpeechEngine, 800);
      this.recorder = new Recorder({
         wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
      });
      this.holdState = false;
   }

   componentDidMount(): void {
      const {
         response: { attempts, accepted },
      } = this.props;
      this.checkDisabled();
      this.setState(
         {
            isCorrect: attempts > 0 && !!accepted,
            isIncorrect: attempts > 0 && !accepted,
         },
         this.initializeSpeechEngine,
      );
   }

   componentDidUpdate(prevProps: SpokenResponsePromptProps): void {
      if (prevProps.acceptedResponses !== this.props.acceptedResponses) {
         this.checkDisabled();
         this.setState({ isListening: false }, this.initializeSpeechEngine);
      }
   }

   checkDisabled(): void {
      const { acceptedResponses, isClosed } = this.props;
      const isDisabled = isClosed || !acceptedResponses.some((i) => i.length) || !isChrome();
      this.setState({ isDisabled });
   }

   initializeAudio(): void {
      const { fileUrl } = this.props.response;
      if (fileUrl) {
         this.audio = new Audio(fileUrl);
         this.audio.onended = () => this.setState({ isPlaying: false });
      }
   }

   async initializeRecorder(): Promise<void> {
      await this.recorder.initAudio();
      await this.recorder.initWorker();
   }

   initializeSpeechEngine(): void {
      const { acceptedResponses, language: inputLanguage } = this.props;
      const filteredAcceptedResponses = acceptedResponses
         .filter((i) => i.length)
         .map(
            (i) =>
               i
                  .toLowerCase()
                  .replace(/[&/\\#,+()$~%.!^";:?[\]<>{}]/g, '') // Remove special characters
                  .replace(/\s+/g, ' ') // Replace multiple whitespace with a single space
                  .trim(), // Trim leading and trailing whitespace
         );
      if (!filteredAcceptedResponses.length) {
         return;
      }
      const params: SpeechEngineParams = {
         inputLanguage,
         acceptedResponses: filteredAcceptedResponses,
         onAnsweredCorrectly: () => this.speechEngineCallback(true),
         onAnsweredWrong: (result) => this.speechEngineCallback(false, result),
      };
      this.setState({ speechEngine: new SpeechEngine(params) });
   }

   renderIcon(): React.ReactNode {
      const { attempts } = this.props.response;
      const { isCorrect, isDisabled, isListening } = this.state;
      const className = classnames('activity-item-icon', {
         correct: isCorrect && !isDisabled,
         disabled: isDisabled,
         // incorrect: isIncorrect && !isDisabled,
         recording: isListening && !isDisabled,
         pointer: !!attempts && this.audio,
      });
      return (
         <div className={className} onClick={this.toggleListening}>
            <IconBuilderRecording />
         </div>
      );
   }

   renderMessage(): React.ReactNode {
      const { disabledText, text } = this.props;
      const { isCorrect, isDisabled, isIncorrect, isListening } = this.state;
      if (isListening) {
         return <p className='recording'>Listening...</p>;
      } else if (isDisabled && !isChrome()) {
         return <p>Spoken Responses are only supported in Google Chrome</p>;
      } else if (isDisabled && disabledText) {
         return <p>{disabledText}</p>;
      } else if (isCorrect) {
         return <p className='correct'>Correct!</p>;
      } else if (isIncorrect) {
         return <p className='incorrect'>Incorrect. Try Again!</p>;
      } else {
         return <p>{text || 'Speak Your Response'}</p>;
      }
   }

   async startListening(): Promise<void> {
      const { saveRecording } = this.props;
      const { isDisabled, speechEngine } = this.state;

      if (isDisabled) {
         return;
      }

      if (saveRecording) {
         await this.initializeRecorder();
         this.recorder.startRecording();
      }
      speechEngine?.init();

      this.setState({
         isCorrect: false,
         isIncorrect: false,
         isListening: true,
      });
   }

   async speechEngineCallback(accepted: boolean, results?: readonly string[]): Promise<void> {
      const { response } = this.props;
      const { attempts } = response;

      const { blob, fileUrl, storedFilename } = await this.stopRecording();

      this.setState({
         isCorrect: accepted,
         isIncorrect: !accepted,
         isListening: false,
         result: results ? results[0] : null,
      });

      this.setResponse({
         accepted,
         attempts: attempts + 1,
         blob,
         fileUrl,
         result: results ? results[0] : null,
         storedFilename,
      });
   }

   async stopRecording(): Promise<{ blob: Maybe<Blob>; fileUrl: null; storedFilename: null }> {
      this.holdState = false;

      if (this.recorder && this.props.saveRecording) {
         const blob = await this.recorder.stopRecording();
         return { blob, fileUrl: null, storedFilename: null };
      }
      return { blob: null, fileUrl: null, storedFilename: null };
   }

   async toggleListening(): Promise<void> {
      if (this.state.isListening) {
         this.state.speechEngine?.destroy();
         await this.stopRecording();
         this.setState({ isListening: false });
      } else if (!this.state.isListening && !this.holdState) {
         this.holdState = true;
         await this.startListening();
      }
   }

   togglePlaying(event: React.MouseEvent<HTMLDivElement>): void {
      if (!this.audio) {
         return;
      }
      if ((event.target as HTMLElement).tagName !== 'SPAN') {
         this.setState(
            (prevState) => ({ isPlaying: !prevState.isPlaying }),
            () => {
               this.state.isPlaying ? this.audio?.play() : this.audio?.pause();
            },
         );
      }
   }

   setResponse(update: Partial<SpokenResponseResponse>): void {
      this.props.setResponse?.(
         {
            ...this.props.response,
            ...update,
         },
         this.props.saveResponse,
      );
   }

   render(): React.ReactNode {
      const { result, isIncorrect } = this.state;
      const icon = this.renderIcon();
      const message = this.renderMessage();
      const className = classnames('activity-builder-audio', this.props.className);
      return (
         <>
            <div className={className}>
               {icon}
               {message}
            </div>
            {result && isIncorrect && (
               <div className='row padding-top-m'>
                  <div className='col-xs-12'>
                     <p className='error'>{`It sounds like you said: ${result}`}</p>
                  </div>
               </div>
            )}
         </>
      );
   }
}

export default SpokenResponsePrompt;
