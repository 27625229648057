import * as React from 'react';

import Tippy from '@tippyjs/react';

import Link from '@components/Common/Link';

interface BreadcrumbsItemProps {
   /** Path which breadcrumbs should act as link to. */
   to?: string;
   /** Text to appear within the breadcrumb as a link. */
   text: string;
   /** Icon to display before the breadcrumb. */
   icon?: React.ReactNode;
   /**
    * The maximum width in pixels that an item can have before it is truncated.
    * If this is not set, truncation will only occur when it cannot fit alone on a line.
    * If there is no truncationWidth, tooltips are not provided on truncation.
    */
   truncationWidth?: number;
   /** Handler to be called on click */
   onClick?(event: React.MouseEvent<HTMLSpanElement>): void;
}

const BreadcrumbsItem: React.FC<BreadcrumbsItemProps> = ({
   icon,
   to,
   text = '',
   truncationWidth = 24,
   onClick,
}) => {
   const truncatedText =
      text.length > truncationWidth ? `${text.slice(0, truncationWidth)}...` : text;

   return (
      <Tippy content={text} disabled={text.length < truncationWidth}>
         <div className='breadcrumbs-item' onClick={onClick}>
            {to ? (
               <Link to={to}>
                  {icon}
                  {truncatedText}
               </Link>
            ) : (
               <>
                  {icon}
                  <span>{truncatedText}</span>
               </>
            )}
         </div>
      </Tippy>
   );
};

export default BreadcrumbsItem;
