import * as React from 'react';

import IconBuilderUploadGrey from '@icons/activities/icon-builder-upload-gray.svg';
import { Maybe } from '@models/Core';
import { uploadUserFile } from '@services/AssetService';

import Button from '@components/Common/Button';

interface UploadTabProps {
   onImageInsertion(src: string): void;
}

const UploadTab: React.FC<UploadTabProps> = ({ onImageInsertion }) => {
   const [file, setFile] = React.useState<Maybe<File>>(null);

   const handleUpload = async () => {
      if (!file) return;
      const { url: imageUrl } = await uploadUserFile(file);
      onImageInsertion(imageUrl);
   };

   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.[0]) return;
      setFile(e.target.files?.[0]);
   };

   return (
      <div className='flex padding-s'>
         <div className='upload-dropzone flex-1'>
            <div className='icon-wrapper'>
               <IconBuilderUploadGrey aria-hidden />
            </div>
            <input type='file' accept='image/*' onChange={handleFileChange} />
            <span className='small-text'>
               {file?.name ? file.name : 'Choose an image or drag it here.'}
            </span>
         </div>
         <Button line onClick={handleUpload} className='margin-left-s' disabled={!file}>
            Upload
         </Button>
      </div>
   );
};

export default UploadTab;
