import * as React from 'react';

import Link from '@components/Common/Link';
import ConfirmModal from '@components/Core/ConfirmModal';
import DeleteAction from '@components/TableActions/DeleteAction';
import IconBankingMoneySend4 from '@icons/nova-line/45-Banking/banking-money-send-4.svg';
import IconMoneyNote from '@icons/nova-line/47-Money/money-note.svg';
import Appearance from '@models/Appearance';
import LicenseService from '@services/LicenseService';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import { AppStateContext } from '../../AppState';
import { License } from './Models';
import { isProductLicense, isStripePayment } from './Utils';

interface LicenseActionsCellProps {
   row: License;
   onUpdate(row: License): void;
   onDelete(): void;
}

const LicenseActionsCell: React.FC<LicenseActionsCellProps> = ({ row, onDelete }) => {
   const { dispatchToast } = React.useContext<AppStateContext>(AppStateContext);
   const [confirmModalOpen, setConfirmModalOpen] = React.useState<boolean>(false);

   const handleLicenseRevoke = (refund: boolean): void => {
      LicenseService.revokeLicense(row.id, refund).then(() => {
         if (refund) {
            dispatchToast({
               title: 'License Refunded',
               message: `License ID ${row.id} has been successfully revoked and refunded.`,
               appearance: Appearance.success,
            });
         } else {
            dispatchToast({
               title: 'License Deleted',
               message: `License ID ${row.id} has been successfully deleted.`,
               appearance: Appearance.success,
            });
         }
         onDelete();
      });
   };

   const stripeLink = isStripePayment(row.payment)
      ? `https://dashboard.stripe.com/payments/${row.payment.stripePaymentIntentId}`
      : null;

   const getProductName = (): string => {
      if (isProductLicense(row)) {
         return row.productName;
      }

      return row.courseName;
   };

   const rowName = `${row.user.firstName} ${row.user.lastName} - ${getProductName()}`;

   return (
      <>
         <div className='table-row-action'>
            <div className='icon-action-wrap'>
               <Tippy content='View on Stripe' disabled={!isStripePayment} delay={[400, 0]}>
                  <div
                     className={classnames('icon-action', {
                        disabled: !isStripePayment,
                     })}
                  >
                     {stripeLink ? (
                        <Link external to={stripeLink}>
                           <IconMoneyNote />
                        </Link>
                     ) : (
                        <IconMoneyNote />
                     )}
                  </div>
               </Tippy>
               <Tippy content='Refund License' delay={[400, 0]}>
                  <div className='icon-action' onClick={() => setConfirmModalOpen(true)}>
                     <IconBankingMoneySend4 />
                  </div>
               </Tippy>
               <DeleteAction onDelete={() => handleLicenseRevoke(false)} objectName={rowName} />
            </div>
         </div>
         {!confirmModalOpen ? null : (
            <ConfirmModal
               header='Are you sure you want to refund this license?'
               message={`Are you sure you want to refund "${rowName}"`}
               onClose={() => setConfirmModalOpen(false)}
               onPrimaryClick={() => handleLicenseRevoke(true)}
               primaryClickText='Refund'
               closeText='Cancel'
            />
         )}
      </>
   );
};

export default LicenseActionsCell;
