type Environment = 'development' | 'test' | 'production';

type Config = {
   environmentType: Environment;
   isLocalDev: boolean;
   mapBoxAccessToken: string;
};

const config: Config = {
   environmentType: (process.env.LINGCO_ENV as Environment) || 'development',
   isLocalDev: process.env.IS_LOCAL === 'true',
   mapBoxAccessToken:
      'pk.eyJ1IjoiamFtZXNsaW5nY28iLCJhIjoiY2xqZDdmaTlqMXM0bjNxczFwZmdyeHV4NCJ9.eTWkbz5_qH5dkkWeGcTz3w',
};

export const isProd = config.environmentType === 'production';

export default Object.freeze(config);
