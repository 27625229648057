import * as React from 'react';

import SchoolProfile from '@models/SchoolProfile';
import { RegistrationError, RegistrationStep } from '@services/RegistrationService';

import Button from '@components/Common/Button';
import DocumentTitle from '@components/DocumentTitle';
import RegisterError from './RegisterError';

interface SingleSchoolSuggestionProps {
   errors: readonly RegistrationError[];
   isLoading: boolean;
   suggestedSchool: SchoolProfile;
   setSchool(school: SchoolProfile, submitRegistration?: boolean): void;
   setStep(step: RegistrationStep): void;
}

const SingleSchoolSuggestion: React.FC<SingleSchoolSuggestionProps> = ({
   errors,
   isLoading,
   suggestedSchool,
   setSchool,
   setStep,
}) => (
   <div className='card-content-onboarding card padding-bottom-m'>
      <div className='onboarding-title border-bottom text-center'>
         <DocumentTitle>School Verification</DocumentTitle>
         <h2 aria-live='polite' className='uppercase no-margin'>
            School Verification
         </h2>
      </div>
      <div className='content-form'>
         <div className='row'>
            <div className='col-xs-12'>
               <p>Based on your email address, it appears that your school is:</p>
               <div className='title text-md margin-top-bottom-m justify-center'>
                  {suggestedSchool.name}
               </div>
            </div>
         </div>
         <div className='flex margin-top-m flex-gap-xs'>
            <Button
               loading={isLoading}
               fullWidth
               data-test='confirm'
               onClick={() => setSchool(suggestedSchool, true)}
            >
               Yes
            </Button>
            <Button
               line
               fullWidth
               data-test='decline'
               disabled={isLoading}
               onClick={() => setStep(RegistrationStep.schoolSearch)}
            >
               No
            </Button>
         </div>
         <RegisterError errors={errors} />
      </div>
   </div>
);

export default SingleSchoolSuggestion;
