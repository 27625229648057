import * as React from 'react';

import { Maybe } from '@models/Core';

/**
 * `usePrevious` is a custom hook that returns the previous value of a given value.
 *
 * @template T - The type of the value being stored.
 * @param {T} value - The current value to be stored.
 * @returns {Maybe<T>} The previous value, or null/undefined if it doesn't exist.
 *
 * @typedef {import('@models/Core').Maybe<T>} Maybe<T> - Maybe type from '@models/Core'.
 *
 * @example
 * const [count, setCount] = useState<number>(0);
 * const previousCount = usePrevious<number>(count);
 *
 * The hook uses the useRef hook to create a mutable ref object that can hold any value. It stores the current value
 * passed as a parameter in the ref object. The value is updated whenever the `value` prop changes. The previous value
 * can then be accessed from the ref object and returned. This allows you to keep track of the previous value of a state
 * variable or any other value within a functional component.
 */
const usePrevious = <T>(value: T): T | undefined | null => {
   // The ref object is a generic container whose current property is mutable ...
   // ... and can hold any value, similar to an instance property on a class
   const ref = React.useRef<Maybe<T>>(null);
   // Store current value in ref

   React.useEffect(() => {
      ref.current = value;
   }, [value]); // Only re-run if value changes

   // Return previous value (happens before update in useEffect above)
   return ref.current;
};

export default usePrevious;
