import * as React from 'react';

import { Editor, ToolbarAppearance } from '@components/Core/Editor';
import { randomShortId } from '@helpers/RandomStringUtils';
import IconAddSmall from '@icons/general/icon-add-small.svg';
import IconCloseSmall from '@icons/general/icon-close-small.svg';
import {
   ActivityBuilderMode,
   OrderingOption,
   OrderingPrompt as OrderingPromptType,
} from '@models/Activity';
import Language from '@models/Language';
import {
   DragDropContext,
   Draggable,
   DraggableProvided,
   Droppable,
   DroppableProvided,
   DropResult,
} from 'react-beautiful-dnd';

import Button from '@components/Common/Button';

const MIN_OPTION_COUNT = 2;
const DEFAULT_OPTION_COUNT = 3;

interface OrderingPromptProps {
   language: Language;
   options: readonly OrderingOption<ActivityBuilderMode>[];
   onUpdate(update: Partial<OrderingPromptType<ActivityBuilderMode>>): void;
}

const OrderingPrompt: React.FC<OrderingPromptProps> = ({ language, options, onUpdate }) => {
   React.useEffect(() => {
      if (!options.length) {
         const defaultOptions = [...Array(DEFAULT_OPTION_COUNT).keys()].map((i) =>
            generateOption(i),
         );
         onUpdate({ options: defaultOptions });
      }
   }, []);

   const generateOption = (index: number): OrderingOption<ActivityBuilderMode> => ({
      id: null,
      key: randomShortId(),
      index,
      data: '',
      feedback: '',
   });

   const removeOption = (key: string): void => {
      if (options.length > MIN_OPTION_COUNT) {
         onUpdate({ options: options.filter((i) => i.key !== key) });
      }
   };

   const editOption = (
      optionKey: string,
      update: Partial<OrderingOption<ActivityBuilderMode>>,
   ): void => {
      onUpdate({ options: options.map((i) => (i.key === optionKey ? { ...i, ...update } : i)) });
   };

   const handleDragEnd = (result: DropResult): void => {
      if (!result.destination) {
         return;
      }
      const updatedOptions = [...options];
      const [removed] = updatedOptions.splice(result.source.index, 1);
      updatedOptions.splice(result.destination.index, 0, removed);
      onUpdate({ options: updatedOptions });
   };

   const addOption = (): void => {
      onUpdate({ options: [...options, generateOption(options.length)] });
   };

   const config = {
      toolbar: 'bold italic underline strikethrough forecolor language tooltip',
   };

   return (
      <>
         <div className='dnd-prompt-drag-context no-drag'>
            <DragDropContext onDragEnd={handleDragEnd}>
               <div className='dnd-prompt-container'>
                  <div className='dnd-column-container'>
                     <Droppable droppableId='CONTAINER'>
                        {(optionDropProvided: DroppableProvided) => (
                           <div
                              className='dnd-column-items-wrapper'
                              ref={optionDropProvided.innerRef}
                              {...optionDropProvided.droppableProps}
                           >
                              <div className='dnd-column-items'>
                                 {options.map((option, index) => (
                                    <Draggable
                                       key={option.key}
                                       draggableId={option.key}
                                       index={index}
                                    >
                                       {(optionDragProvided: DraggableProvided) => (
                                          <div
                                             className='dnd-item'
                                             ref={optionDragProvided.innerRef}
                                             {...optionDragProvided.draggableProps}
                                             {...optionDragProvided.dragHandleProps}
                                          >
                                             <div className='number-wrap'>
                                                <div className='number'>
                                                   <label>{index + 1}</label>
                                                </div>
                                             </div>
                                             <Editor
                                                className='no-p-margins'
                                                config={config}
                                                language={language}
                                                onChange={(v) =>
                                                   editOption(option.key, { data: v })
                                                }
                                                rtl={language === 'ar'}
                                                toolbarAppearance={ToolbarAppearance.floating}
                                                value={option.data}
                                             />
                                             <div className='delete-row-icon'>
                                                <IconCloseSmall
                                                   className='delete'
                                                   onClick={() => removeOption(option.key)}
                                                />
                                             </div>
                                          </div>
                                       )}
                                    </Draggable>
                                 ))}
                              </div>
                              {optionDropProvided.placeholder}
                           </div>
                        )}
                     </Droppable>
                  </div>
               </div>
            </DragDropContext>
         </div>
         <Button
            line
            fullWidth
            className='margin-top-s half-width'
            onClick={addOption}
            icon={<IconAddSmall aria-hidden />}
         >
            Add Option
         </Button>
      </>
   );
};

export default OrderingPrompt;
